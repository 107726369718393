import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, tap, switchMap, finalize, share, catchError, startWith, map } from 'rxjs/operators';
import { muteFirst, GetRouteDeviationsRequestModel } from 'emr-ng-shared';
import {
    RouteDeviationListItem, GetRouteDeviationRequestModel, GetRouteDeviationResponseModel,
    ExportTrackerStateReportHeaders, GetRouteDeviationReportResponseModel
} from 'emr-ng-shared';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { RouteDeviationStateService } from './route-deviation-state.service';
import { IListInfo } from '../models/list-info-state.interface';

@Injectable()
export class RouteDeviationsService {
    constructor(
        private oversightSvc: OversightApiService,
        private routeDeviationStateSvc: RouteDeviationStateService) { }


    // public routeDeviationsList$ = muteFirst(
    //     this.GetRouteDeviations().pipe(startWith(null)),
    //         this.routeDeviationStateSvc.routesList$
    // );

    public GetRouteDeviations(request: GetRouteDeviationsRequestModel): Observable<IListInfo<RouteDeviationListItem>> {
        return of(null).pipe(
            tap(() => this.routeDeviationStateSvc.loadRouteDeviations()),
            switchMap(() => {
                return this.getSavedRouteDeviations(request);
            }),
            tap(
                n => this.routeDeviationStateSvc.loadRouteDeviationsSuccess(),
                e => this.routeDeviationStateSvc.loadRouteDeviationsError(e)
            ),
            finalize(() => this.routeDeviationStateSvc.cancelRouteDeviations()),
            catchError(() => {
                this.routeDeviationStateSvc.cancelRouteDeviations();
                return of(null);
            }),
            share()
        );
    }

    private getSavedRouteDeviations(request: GetRouteDeviationsRequestModel): Observable<IListInfo<RouteDeviationListItem>> {
        return this.oversightSvc.getRouteDeviations(request).pipe(
            map(n => {
                return {
                    list: n.Result,
                    itemCount: n.Result.length,
                    isPaged: false
                };
            })
        );
    }


    public GetRouteDeviationByRoute(request: GetRouteDeviationRequestModel): Observable<GetRouteDeviationResponseModel> {
        return of(null).pipe(
            tap(() => this.routeDeviationStateSvc.getSavedRouteDeviation()),
            switchMap(() => {
                return this.GetRouteDeviationByRouteId(request);
            }),
            tap(
                n => this.routeDeviationStateSvc.getSavedRouteDeviationSuccess(),
                e => this.routeDeviationStateSvc.getSavedRouteDeviationError()
            ),
            finalize(() => this.routeDeviationStateSvc.getSavedRouteDeviationError()),
            catchError(() => {
                this.routeDeviationStateSvc.getSavedRouteDeviationError();
                return of(null);
            }),
        );
    }

    private GetRouteDeviationByRouteId(request: GetRouteDeviationRequestModel): Observable<GetRouteDeviationResponseModel> {
        return this.oversightSvc.getRouteDeviation(request).pipe(
            map(n => n));
    }

    public RouteDeviationExceptions(request, extraHeaders: ExportTrackerStateReportHeaders) {
        return this.oversightSvc.RouteDeviationExceptions(request, extraHeaders);
    }

}


