<emr-modal class="scrollable-modal" autocomplete="off" [bsModalRef]=bsModalRef>
    <ng-container modal-header>
        <span class="col-md-12 w-100 modal-title modal-header-title"
            i18n="@@label_location_contacts"> Location Contacts</span>
    </ng-container>
    <ng-container modal-body>
        <div class="col-md-12 py-2 bg-location-contact">
            <div class="row">
                <div class="col-12 label-css font-14">
                    <span  i18n="@@label_contacts_assigned_to"> Contacts assigned to </span>
                    <span>: &nbsp;</span>
                    <span> {{location?.LocationName }}</span>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-9">
                    <form modal-body name="frmLocationContact" #LocationContactForm="ngForm" emrForm autocomplete="off"
                        [ngClass]="{ 'ng-submitted': request.ContactId && isFormSubmitted }">
                        <ul class="nav justify-content-left">
                            <li class="nav-item width-500 pr-2">
                                <emr-custom-dropdown ngDefaultControl identifier="lstContacts" name="lstContacts"
                                    [options]="contactsList$ | async" [(ngModel)]="request.ContactId" cssClass="default-dropdown"
                                    label="Contacts" i18n-label="@@label_contacts" required [showPlaceHolder]=true
                                    placeholder="Select Contact" i18n-placeholder="@@label_select_contact"
                                    [inline]=true [controlInline]=false labelColumns=2 controlColumns=10  [enableSearchFilter]=true
                                    SearchPlaceHolder="Search Contact" i18n-SearchPlaceHolder="@@label_search_contact">
                                    <emr-validation-message validatorName="required"
                                        message="Please select a contact to assign" i18n-message="@@contact_required">
                                    </emr-validation-message>
                                </emr-custom-dropdown>
                            </li>
                            <li class="nav-item pr-2">
                                <emr-button identifier="btnAddNewContact"  buttonType="button" cssClass="btn-lg btn-primary quick-link btn-block m-0"
                                    buttonText="New Contact" i18n-buttonText="@@label_new_contact" buttonIcon="fa fa-Plus"
                                    (onClick)="openAddContactClick()"></emr-button>
                            </li>
                            <li *ngIf="request.ContactId" class="nav-item">
                                <emr-button identifier="btnAddLocContact"  buttonType="submit" cssClass="btn-lg btn-primary quick-link btn-block m-0"
                                    buttonText="Assign Contact" i18n-buttonText="@@label_assign_contact" buttonIcon="fa fa-Plus"
                                    (onClick)="OnAssignContactClick()"></emr-button>
                            </li>
                        </ul>
                    </form>
                </div>
                <div class="col-3 pr-0 text-right">
                    <ul class="nav justify-content-end">
                        <li class="nav-item">
                            <app-total-records [itemCount]="location?.Contacts?.length" skipThreshold="true"></app-total-records>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mx-0 mt-2 table-data-container">
                <div class="col-12 px-1 text-center">
                    <div class="table-responsive">
                        <table class="table hover-table mb-0 custom-table" #table>
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <p class="">
                                            <span>&nbsp;</span>
                                        </p>
                                    </th>
                                    <th scope="col" class="table-row-header">
                                        <span i18n="@@label_last_name">Last Name</span>
                                    </th>
                                    <th scope="col" class="table-row-header">
                                        <span i18n="@@label_first_name">First Name</span>
                                    </th>
                                    <th scope="col" class="table-row-header">
                                        <span i18n="@@label_mobile">Mobile</span>
                                    </th>
                                    <th scope="col" class="table-row-header">
                                        <span i18n="@@label_email">Email</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="location?.Contacts?.length > 0; else emptyContactsList">
                                <tr *ngFor="let contact of location.Contacts; let j = index;" class="cursor-pointer">
                                    <td>
                                        <div>
                                            <emr-button identifier="btnEditContact"
                                                cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                                                buttonIcon="fa fa-pencil" buttonText="Edit"
                                                i18n-buttonText="@@link_edit" (onClick)="onEditContactClick(contact)">
                                            </emr-button>
                                        </div>
                                        <div>
                                            <emr-button identifier="btnDeleteLocationContact"
                                                cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                                                buttonIcon="fa fa-times" buttonText="Un-Assign"
                                                i18n-buttonText="@@label_un_assign"
                                                (onClick)="onDeleteContactClick(contact)">
                                            </emr-button>
                                        </div>
                                    </td>
                                    <td>
                                        <p class="table-row-data">
                                            <span>{{ contact.LastName }}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="table-row-data">
                                            <span>{{ contact.FirstName }}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="table-row-data">
                                            <span>{{ contact.CellPhone }}</span>
                                        </p>
                                    </td>
                                    <td>
                                        <p class="table-row-data">
                                            <span>{{ contact.EmailAddress }}</span>
                                        </p>
                                    </td>
                                </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #emptyContactsList>
            <tbody id="full-grid-view-reset">
                <tr>
                    <td colspan="14">
                        <p class="table-row-data text-center" i18n="@@no_contacts_assigned">No Contacts Assigned
                        </p>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </ng-container>
</emr-modal>