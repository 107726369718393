import { Inject, Injectable } from '@angular/core';

import { forkJoin, Observable, of } from 'rxjs';
import { filter, tap, switchMap, finalize, share, map, catchError, withLatestFrom , startWith, skip, take } from 'rxjs/operators';

import { IBox, muteFirst } from 'emr-ng-shared';

import { CustomerStateService } from 'app-modules/core/store/services/customer-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { Customer, BusinessRuleType, Fullstory } from 'emr-ng-shared';
import { BusinessRulesService } from '../store/business-rules/business-rules.service';
import { UserSettingsService } from '../store/user-settings/user-settings.service';
import { StoreManager, StoreOpts } from '../utils/store-manager';
import { ShipmentService } from './shipment.service';
import { environment } from 'environments/environment';
import { APP_IN_CHINA } from 'base/regionFinder';
import { InitializationService } from './initialization.service';
import { ShipmentFilterService } from '../store/shipment-filter/shipment-filter.service';

@Injectable()
export class CustomersService {

    public defaultBox : IBox = {
        maxLatitude: 0.5 ,
        maxLongitude: 0.5,
        minLatitude: -0.5,
        minLongitude: -0.5
    };

    public noFenceLocationRadius = 0;

    constructor(
        private custStateSvc: CustomerStateService,
        private userSettingsSvc: UserSettingsService,
        private businessRuleSvc: BusinessRulesService,
        private shpSvc: ShipmentService,
        @Inject(APP_IN_CHINA) private isAppInChina: boolean,
        public fullstory: Fullstory,
        private initSvc: InitializationService,
        private shpFltrSvc: ShipmentFilterService,
        private oversightSvc: OversightApiService
    ) { }

    public customerState$ = this.custStateSvc.customerState$;
    public isLoadRequired$ = this.custStateSvc.isLoadRequired$;
    public isCustomerLoading$ = this.custStateSvc.isLoading$;
    public customerList$: Observable<IListInfo<Customer>> = muteFirst(
        this.initCustomerList().pipe(startWith(null)),
        this.custStateSvc.customerList$
    );
    public isSelected$ = this.custStateSvc.isSelected$;
    public selectedContext$ = this.custStateSvc.selectedContext$;

    public selectCustomer(customerId: number): Observable<Customer> {
        return of(customerId).pipe(
            tap(n => this.custStateSvc.selectCustomer(n)),
            switchMap(n => this.oversightSvc.GetCustomer(n)),
            withLatestFrom(this.findCustomer(customerId, this.custStateSvc.customerList$)),
            tap(
                ([response, customer]) => {
                    let affiliateList: IListInfo<Customer>;

                    if (response.LinkedCustomers) {
                        affiliateList = {
                            list: response.LinkedCustomers,
                            itemCount: response.LinkedCustomers.length,
                            isPaged: false
                        };
                    } else {
                        affiliateList = emptyList();
                    }

                    if (this.isValidCoordinates(response?.DefaultLocation?.Latitude, response?.DefaultLocation?.Longitude)) {
                        this.defaultBox = this.getDefaultBox(response?.DefaultLocation?.Latitude, response?.DefaultLocation?.Longitude);
                    }
                    this.noFenceLocationRadius = response.NoFenceLocationRadius;
                    this.custStateSvc.selectCustomerSuccess({
                        customer: customer,
                        token: response.CustomerAccessToken,
                        noFenceLocationRadius: response.NoFenceLocationRadius,
                        affiliateList: affiliateList
                    });
                },
                e => this.custStateSvc.selectCustomerError('')
            ),
            map(([response, customer]) => customer)
        );
    }

    public getDefaultBox(latitude: number, longitude: number) {
        const padding = 0.01;
        const box = {
            maxLatitude: latitude + padding,
            maxLongitude: longitude + padding,
            minLatitude: latitude - padding,
            minLongitude: longitude - padding,
            center: { latitude: latitude, longitude: longitude }
        };
        return box;
    }

    isValidCoordinates(lat: number, lon: number): boolean {
        if (lat && lon && 
            lat >= -90 && lat <= 90 &&
            lon >= -180 && lon <= 180) {
            return true;
        }
        return false;
    }

    public onCustomerChange(customer: Customer) {
        if (!customer) { return; }
        this.fullstory.setUserVars({
            CustomerID: customer.CustomerId,
            CustomerName: customer.Description
        });
        localStorage.setItem(environment.selectedCustomer, customer.CustomerId.toString());
        this.shpFltrSvc.clearShipmentFilter();
        this.initSvc.InitCustomerLoad();
    }

    public mapViewPreRequisites(isSelected: boolean) {
        return of(isSelected).pipe(
            filter(n => n),
            switchMap(n => forkJoin([
                of(n),
                this.userSettingsSvc.userSettings$.pipe(skip(1), take(1)),
                this.businessRuleSvc.businessRulesList$.pipe(skip(1), take(1))
            ])),
            map(([n, userSettings, businessRules]) => {
                if (businessRules?.list?.length > 0) {
                    const showInProgressOnly =
                        businessRules.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.DefaultListViewResultsInProgressOnly);
                    this.shpSvc.isHighResultCount = false;
                    this.shpSvc.isDefaultInProgressOnly = showInProgressOnly ? showInProgressOnly.Enabled : false;
                }
                return n;
            })
        );
    }

    private initCustomerList(): Observable<IListInfo<Customer>> {
        return this.custStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.custStateSvc.loadCustomers()),
            switchMap(() => this.getApiCustomerList()),
            tap(
                n => this.custStateSvc.loadCustomersSuccess(n),
                e => this.custStateSvc.loadCustomersError('')
            ),
            finalize(() => this.custStateSvc.cancelLoadCustomers()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getApiCustomerList(): Observable<IListInfo<Customer>> {
        return this.oversightSvc.GetCustomerList().pipe(
            map((response) => {
                return {
                    list: response.CustomerList,
                    itemCount: response.CustomerList.length,
                    isPaged: false
                };
            })
        );
    }

    private findCustomer(customerId: number, customerList$: Observable<IListInfo<Customer>>): Observable<Customer> {
        return customerList$.pipe(
            map<IListInfo<Customer>, Customer>(n => n.list.find(a => a.CustomerId === customerId))
        );
    }
}






