<div class="row">
  <app-local-loading-indicator *ngIf="isTraxxLoading$ | async" width="100%" height="100%" overrideLeft="0"
    overrideTop="{{ isFromShowRoute ? '45px' : null}}" applyOverlay="true"></app-local-loading-indicator>

  <!-- #region ForShipment Route alert filtering -->
  <div class="col-md-12 alertFiltering" #alertFilterPanel>
    <div class="row mb-1" *ngIf="!isItFromRouteDeviation && isFromShowRoute"> 
      <div style="display: inline-flex">
        <div class="col-auto text-left" *ngIf="isLocusAdmin">
          <emr-checkbox identifier="chkShowGeoFence" name="chkShowGeoFence" i18n-text="@@label_show_geo_fence"
            text="Show Geo-Fence" [(ngModel)]="ShowGeoFence" [setDisabled]="isTraxxLoading$ | async"
            (onChange)="onGeoFenceChange()">
          </emr-checkbox>
        </div>
        <div class="col-auto text-left" *ngIf="ShowEstimatedCrumbsRule&&!isFromSensorChart">
          <emr-checkbox identifier="chkShowEstimatedCrumb" name="chkShowEstimatedCrumb"
            formGroupCssClass="estimated-color" i18n-text="@@label_show_estimated_crumbs"
            text="Show estimated location for all sensor information on the map" [(ngModel)]="ShowEstimatedCrumb"
            [setDisabled]="isTraxxLoading$ | async" (onChange)="onShowEstimatedCrumbsChange($event)">
          </emr-checkbox>
        </div>
        <div class="col-auto pr-0" *ngIf="!isFromSensorChart">
          <emr-checkbox identifier="chkShowCounts" name="chkShowCounts" formGroupCssClass="estimated-color"
            i18n-text="@@label_show_breadcrumbs_count"
            text="Show counts for the number of breadcrumbs logged on the map" [(ngModel)]="breadCrumbCount"
            [setDisabled]="isTraxxLoading$ | async" (onChange)="onBreadcrumbCountChange($event)">
          </emr-checkbox>
        </div>
        <div class="col-auto pl-0" *ngIf="!isFromSensorChart">
          <emr-button identifier="countInfo" buttonType="button" (onClick)="openInfoModal()"
            cssClass="btn-outline-default font-weight-bolder filter-route-height ml-2 mt-0" buttonIcon="fa fa-info">
          </emr-button>
        </div>
      </div>
    </div>
    <div class="row mb-1 alerts-div h-40 mr-70" *ngIf="isFromShowRoute">
      <form name="frmShipmentRouteSearch" class="col-md-12" #shipmentRouteSearchFrom="ngForm" emrForm autocomplete="off"
        [ngClass]="{'ng-submitted': isSubmitted}">
        <ul class="horizontal-ul-list ul-filter-route mt-2" *ngIf="hasOtherCrumbs || traxxList?.traxx?.itemCount>0">
          <!-- <li>
            <label class="label-css" i18n="@@link_start_date">Start Date</label>
            <span class="label-css">&nbsp;:</span>
          </li> -->
          <li class="width-220 datepickerList">
            <emr-datetimepicker ngDefaultControl name="routeFromDate" identifier="routeFromDate" [(ngModel)]="FromDate"
              required [showPickerOnTop]="true" [showDateTimeInLine]="true" i18n-am="@@time_AM" i18n-pm="@@time_PM"
              am="AM" pm="PM" [dateTimeObject]="dateTimeObject" [CustomizeGrid]="true" placeholder="Start Date"
              i18n-placeholder="@@link_start_date" [showOnlyDate]="true" placement="right top"
              datePickerDivCssClass="width-130 px-0" timePickerDivCssClass="width-90 ml-1 px-0" [maxDate]="maxDate"
              [setExtremeTimes]="true" [minDate]="minDate" [EmrInvalidValidator]="isDateRangeValid"
              labelCssClass="height-10" (ngModelChange)="onDateChanged()" formGroupCssClass="mb-0"
              cssClass="calendar-text px-18">
              <emr-validation-message validatorName="required" message="Start Date/Time Required"
                i18n-message="@@start_date_required"></emr-validation-message>
            </emr-datetimepicker>
          </li>
          <!-- <li>
            <label class="label-css d-inline" i18n="@@link_end_date">End Date</label>
            <span class="label-css">&nbsp;:</span>
          </li> -->
          <li class="width-220 datepickerList pl-0">
            <emr-datetimepicker ngDefaultControl name="routeToDate" identifier="routeToDate" [(ngModel)]="ToDate"
              required [showPickerOnTop]="true" i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM"
              placement="right top" [dateTimeObject]="dateTimeObject" [showDateTimeInLine]="true" [CustomizeGrid]="true"
              placeholder="End Date" i18n-placeholder="@@link_end_date" cssClass="calendar-text px-18"
              [EmrInvalidValidator]="isDateRangeValid" datePickerDivCssClass="width-130 px-0" labelCssClass="height-10"
              [showOnlyDate]="true" timePickerDivCssClass="width-90 ml-1 px-0" [minDate]="FromDate?FromDate:minDate"
              [maxDate]="maxDate" (ngModelChange)="onDateChanged()" [setEndTime]="true" [setExtremeTimes]="true"
              formGroupCssClass="mb-0">
              <emr-validation-message validatorName="required" message="End Date/Time Required"
                i18n-message="@@end_date_required"></emr-validation-message>
              <emr-validation-message validatorName="EmrInvalidValidator" i18n-message="@@greater_end_time"
                message="End time must be greater than Start time">
              </emr-validation-message>
            </emr-datetimepicker>
          </li>
          <li>
            <emr-button identifier="shipmentRouteSearch" buttonType="submit" buttonText="Apply"
              i18n-buttonText="@@link_apply" cssClass="btn-outline-default mt-0 font-weight-bolder filter-route-height"
              (onClick)="onShipmentRouteSearch(traxxListCopy)" buttonIcon="fa fa-search">
            </emr-button>
          </li>
          <li class="pl-0">
            <emr-button identifier="shipmentRouteReset" buttonType="reset" buttonText="Reset"
              i18n-buttonText="@@link_reset" cssClass="btn-outline-default mt-0 filter-route-height"
              (onClick)="onShipmentRouteReset()" buttonIcon="fa fa-repeat">
            </emr-button>
          </li>
          <li class="mr-4" *ngIf="hiddenCrumbCount>0&&!(Shipment?.Is4G&&ShowEstimatedCrumbsRule)">
            <emr-checkbox identifier="chkHiddenCrumbs" class="d-inline-block" name="chkHiddenCrumbs"
              i18n-text="@@label_show_hidden_crumbs" text="Show Hidden Crumbs" [(ngModel)]="showHiddenCrumbs"
              cssClass="color-regular" (onChange)="onShowHiddenCrumbsChange(true)">
            </emr-checkbox>
            <span class="checkbox-count">{{hiddenCrumbCount}}</span>
          </li>
          <li class="pl-0" *ngIf="polylinePath && polylinePath.length > 1">
            <emr-button-dropdown *ngIf="!startedAnimationMovie" buttonText="Replay Traxx"
              i18n-buttonText="@@link_ReplayTraxx" cssClass="btn-outline-default mt-0" cssBtnIconClass="fa fa-play"
              identifier="btnReplayTraxx" [options]="animateSpeedOptions" optionsTextField="text"
              optionsValueField="value" (onClick)="onPlayRoute($event);">
            </emr-button-dropdown>
            <emr-button *ngIf="startedAnimationMovie"
              buttonText="{{animationMovie?.isPlaying() ? replayTraxx_pause: replayTraxx_play}}"
              identifier="btnPauseGoTraxx" cssClass="btn-outline-default mt-0 filter-route-height"
              buttonIcon="fa {{ animationMovie?.isPlaying() ? 'fa-pause' : 'fa-play' }}" (onClick)="onPauseGoRoute();">
            </emr-button>
            <emr-button buttonText="Stop" identifier="btnStopTraxx" i18n-buttonText="@@link_Stop"
              cssClass="btn-outline-default mt-0 filter-route-height" buttonIcon="fa fa-stop" (onClick)="onStopRoute();"
              [setDisabled]='!startedAnimationMovie'>
            </emr-button>
          </li>

        </ul>
        <div class="row w-100"
          *ngIf="isFromShowRoute&&shipment?.serialNumber&&traxxList?.AlertTotals?.AlertCounts?.length>0">
          <div class="pl-15"
            *ngIf="isFromShowRoute&&shipment?.serialNumber&&traxxList?.AlertTotals?.AlertCounts?.length>0">
            <emr-checkbox identifier="chkAlertsOnly" name="chkAlertsOnly" i18n-text="@@label_display_alerts"
              text="Display Alerts" [(ngModel)]="alertOption" cssClass="color-regular filter-route-height"
              (onChange)="onAlertOptionChange()">
            </emr-checkbox>
          </div>
          <div class="pl-15 col-md-10"
            *ngIf="isFromShowRoute&&shipment?.serialNumber&&traxxList?.AlertTotals?.AlertCounts?.length>0">
            <emr-checkboxlist identifier="showAlertOptions" name="showAlertOptions" [(ngModel)]="checkedAlerts"
              [options]="traxxList?.AlertTotals?.AlertCounts" (onChange)="onShipmentRouteSearch(traxxListCopy,true)"
              [inline]=true returnType="array" textCssClass="align-baseline" [optionImageUrl]="'CalcImageURL'"
              [isImage]=true [optionsValueField]="'Type'" [setDisabled]="!alertOption" [inlineCheckbox]=true
              i18n-imageAltText="@@exceptions" imageAltText="Alerts" [showCount]=true [optionCountField]="'Count'"
              [optionsTextField]="'Name'">
            </emr-checkboxlist>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- #endregion  -->

  <div class="col-md-12 bg-map w-100 px-0 text-center {{cssClass}}" [ngClass]="{'fixed': SetFixedHeight}"
    [ngStyle]="{'height': mapHeighCalc}" id="showRoute">
    <emr-map #xmap [Options]="options" [Box]="box" (MapPromise)=InstanceOfMap($event)
      (BoundsChange)="onBoundsChange($event)" (ZoomChange)="onBingMapZoomUpdate($event)" [Zoom]="zoomIndex">
      <emr-map-marker-layer Id=22 [MarkerOptions]="markerOptions" (MarkersCreated)="onMarkersCreated($event)"
        [Visible]="markerLayerVisible" [IsShowInfoBox]=true [ShowInfoBox]="MarkerShowInfoBox"
        [InfoBoxTemplate]="infoBoxTemplate" [InfoBoxPlacement]="InPlaceAutoFit" (MarkerClick)="OnMapMarkerClick($event)"
        (InfoBoxClick)="onViewLocationClick($event)" [HideInfoBox]="hideOtherInfoBoxes" LoadClusterMode=true
        [ListBoxTemplate]="listInfoBoxTemplate" CountProperty="Count"
        [MinimumClusterCount]="minimumClusterCount">
      </emr-map-marker-layer>
      <emr-map-marker-layer Id=45 [MarkerOptions]="sensorMarkerOptions" (MarkersCreated)="onSensorMarkerCreated($event)"
        [Visible]="sensorMarkerLayerVisible && sensorMarkerOptions.length"
        [IsShowInfoBox]=true [InfoBoxPlacement]="InPlaceAutoFit" [InfoBoxTemplate]="infoBoxTemplate"
        (InfoBoxClick)="onInfoBoxClose($event)" [ShowInfoBox]="SensorMarkerShowInfoBox"
        [HideInfoBox]="hideSensorChartInfoBoxes">
      </emr-map-marker-layer>
      <emr-map-marker-layer Id=5022 [MarkerOptions]="closeByMarkers" [Visible]="ShowGeoFence"
        [IsShowInfoBox]=true [InfoBoxPlacement]="InPlaceAutoFit" [InfoBoxTemplate]="nearbyLocationsInfo"
        [HideInfoBox]="hideSensorChartInfoBoxes">
      </emr-map-marker-layer>
      <emr-map-polyline *ngIf="markerOptions && markerOptions.length > 0"
        [Visible]="polylineVisible" [Path]="polylinePath" [StrokeWeight]=2>
      </emr-map-polyline>
      <emr-map-draw-layer *ngFor="let locVM of (ShowGeoFence ? visibleLocationBoundaries : markerBoundary)"
        [Visible]="(ShowGeoFence && locVM.ShowBoundary) || (markerBoundary.length > 0)" [IsEditable]="false"
        [drawOptions]="locVM.BoundaryDrawOptions">
      </emr-map-draw-layer>
    </emr-map>
    <ng-template #infoBoxTemplate let-m>
      <app-breadcrumb-info-box-content [markerInfo]="getTemplateInfo(m)" [isFromSensorChart]="isFromSensorChart"
        [(selectedBreadCrumbRadius)]="selectedRadiusBreadCrumbId" [isZoomedInfoLevel]="isZoomedInfoLevel" [checkAdminRole]="isLocusAdmin"
        [isFromTimeline]="isFromTimeline" [showHiddenCrumbs]="showHiddenCrumbs" [setShowingLocationOrGeoFence]="ShowGeoFence || ShowLocationconfidence"
        [showInfoBoxZoom]="(zoomIndex == previousZoomIndex)" [is4G]="Shipment?.Is4G&&ShowEstimatedCrumbsRule"  [setDistanceUnits]="distanceUnits">
      </app-breadcrumb-info-box-content>
    </ng-template>
    <ng-template #listInfoBoxTemplate let-ml>
      <app-breadcrumb-info-box-list-content [markersInfo]="getListTemplateInfo(ml)" [(selectedBreadCrumbRadius)]="selectedRadiusBreadCrumbId"
        [isFromSensorChart]="isFromSensorChart" [isFromTimeline]="isFromTimeline" [setShowingLocationOrGeoFence]="ShowGeoFence || ShowLocationconfidence"
        [isZoomedInfoLevel]="isZoomedInfoLevel" [showHiddenCrumbs]="showHiddenCrumbs"  [setDistanceUnits]="distanceUnits" [checkAdminRole]="isLocusAdmin"
        [showInfoBoxZoom]="(zoomIndex == previousZoomIndex)" [is4G]="Shipment?.Is4G&&ShowEstimatedCrumbsRule">
      </app-breadcrumb-info-box-list-content>
    </ng-template>
    <ng-template #nearbyLocationsInfo let-m>
      <app-nearby-locations-popup [collisionInfo]="getTemplateInfo(m)">
      </app-nearby-locations-popup>
    </ng-template>
    <emr-button identifier="ResetMapView"
      cssClass="btn-sm btn-toggle btn-toggle-map btnZoomMap btnResetZoomMap btnResetRouteZoomMap {{ isZoomed === true ? '' : 'btn-disable'}}"
      buttonIcon="reset-map-icon" i18n-title="@@reset_zoom" title="Reset Zoom" (onClick)="onResetZoom()">
    </emr-button>
    <emr-button identifier="btnIconInfo" cssClass="btn-sm btn-toggle btn-toggle-map btnZoomMap" buttonText="Icon Info"
      i18n-buttonText="@@link_icon_info" (onClick)="showIconInfo()">
    </emr-button>
  </div>
</div>
<div class="row mt-2 mb-2" *ngIf="isItFromRouteDeviation">
  <div class=" col-md-2">
    <emr-checkbox identifier="chkShowRoute" name="chkShowRoute" i18n-text="@@label_show_defined_route"
      text="Show Defined Route" [(ngModel)]="ShowRoute" cssClass="color-regular"
      (onChange)="onMapCheckboxChange($event)">
    </emr-checkbox>
  </div>
  <div class="col-md-2">
    <emr-checkbox identifier="chkShowDeviation" name="chkShowDeviation" i18n-text="@@label_show_only_deviations"
      text="Show Only Deviations" [(ngModel)]="ShowDeviations" cssClass="color-regular"
      (onChange)="onMapCheckboxChange($event)"></emr-checkbox>
  </div>
</div>
<div *ngIf="displayErrorMessage&&errorMessage?.length>0" class="row mt-2">
  <div class="col-md-12 px-1 pb-2">
    <span class=""> {{this.errorMessage}}</span>
  </div>
</div>

<ng-template #countInfoModal>
  <emr-modal id="modalCountInfo" *ngIf="bsModalRef" class="scrollable-modal" headerTitle="Show Route"
    i18n-headerTitle="@@show_route" [bsModalRef]=bsModalRef>

    <ng-container modal-body>
      <div class="col-md-12 modal-body-message">
        <div class="row my-2">
          <div class="col-md-12">
            <div i18n="@@count_bubble">
              The count bubble represents the number of breadcrumbs logged at the same location.
            </div>
            <div i18n="@@count_bubble_default">
              By default Oversight shows the count bubble if the count is greater than 25 breadcrumbs at the same
              location.
            </div>
            <div i18n="@@count_bubble_checked">
              If this check box is checked then Oversight will show all count bubbles for 3 or more breadcrumbs at the
              same location.
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </emr-modal>
</ng-template>