import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { CarrierInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ICarrierState } from 'app-modules/core/store/models/carrier-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as carrierActions from 'app-modules/core/store/actions/carrier.actions';

@Injectable()
export class CarrierStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public carrierStateSelector = ((state: IAppState) => state.carrier);
    public isLoadRequiredSelector = createSelector(
        this.carrierStateSelector,
        (state: ICarrierState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.carrierStateSelector,
        (state: ICarrierState) => state.isLoading
    );
    public carrierListSelector = createSelector(
        this.carrierStateSelector,
        (state: ICarrierState) => state.carrierList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public carrierList$ = this.store.select(this.carrierListSelector).pipe();

    // Actions
    public loadCarriers() {
        this.store.dispatch(new carrierActions.LoadCarriers());
    }

    public loadCarriersSuccess(carrierList: IListInfo<CarrierInfo>) {
        this.store.dispatch(new carrierActions.LoadCarriersSuccess(carrierList));
    }

    public loadCarriersError(message: string) {
        this.store.dispatch(new carrierActions.LoadCarriersError(message));
    }

    public cancelLoadCarriers() {
        this.store.dispatch(new carrierActions.CancelLoadCarriers());
    }
}


