import { Component, Input, OnInit } from '@angular/core';
import { LocationCollisionInfo } from 'emr-ng-shared';

@Component({
  selector: 'app-nearby-locations-popup',
  templateUrl: './nearby-locations-popup.component.html',
  styleUrls: ['./nearby-locations-popup.component.css']
})
export class NearbyLocationsPopupComponent implements OnInit {

  @Input() collisionInfo: LocationCollisionInfo;
  @Input() isFromSensorChart: boolean = false;
  @Input() isFromTimeline: boolean = false;
  public isZoomInfoLevel: boolean = true;
  public showInfoZoomOption: boolean = true;
  buttonHideBreadcrumbText: string;
  buttonShowBreadcrumbText: string;
  @Input()
  set isZoomedInfoLevel(value) {
    this.isZoomInfoLevel = value;
  }

  @Input()
  set showInfoBoxZoom(value) {
    this.showInfoZoomOption = value;
  }
  @Input() showHiddenCrumbs = false;


  constructor() { }

  ngOnInit() {
  }

}
