import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { TimePeriodInfo } from 'emr-ng-shared';

import * as actionIndex from 'app-modules/core/store/actions';

export class ShipmentDetailLoadTimePeriods implements Action {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TIME_PERIODS;
}

export class ShipmentDetailLoadTimePeriodsSuccess implements Action {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TIME_PERIODS_SUCCESS;
    constructor(public timePeriodList: IListInfo<TimePeriodInfo>) { }
}

export class ShipmentDetailLoadTimePeriodsError implements Action {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TIME_PERIODS_ERROR;
    constructor(public message: string) { }
}

export class ShipmentDetailCancelLoadTimePeriods implements Action {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_TIME_PERIODS;
}

