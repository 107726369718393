import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { AddressSearchRequest, AddressDetailsRequest, Address } from 'emr-ng-shared';

@Component({
    selector: 'app-address-search',
    templateUrl: 'address-search.component.html',
    styleUrls: ['address-search.component.css']
})
export class AddressSearchComponent implements OnInit, OnDestroy {
    public address = '';
    addressSubscription: Subscription;
    formattedAddress: string;

    public requestAddressListCallback: Function;

    @Input() public label: string;
    @Input() public placeholder: string;
    @Input() public identifier: string;
    @Input() public name: string;
    @Input() public helpTip: string;
    @Input() public helpText: string;
    @Input() public labelCssClass = '';
    @Input() public formGroupCssClass: string;
    @Input() public inline = false;
    @Input() public prependIcon = '';
    @Input() public appendIcon = '';
    @Input() public prependIconClass = '';
    @Input() public textBoxDivCssClass = '';
    @Input() public validationMessage = '';
    @Input() public isChinaAddress: boolean;
    @Input() set resetAddress(value: boolean) {
        if (value) {
            this.address = null;
        }
    }

    @Output() addressSelected = new EventEmitter<string | any>();
    @Output() onBlur = new EventEmitter<boolean>();

    private providerId: number;
    private sessionToken: string;
    private GoogleProviderId = 2;
    private BaiduProviderId = 3;
    originalPlaceholder: string;

    public constructor(private apiSvc: OversightApiService) {
        this.sessionToken = uuidv4();
    }

    public ngOnInit() {
        this.requestAddressListCallback = this.requestAddressList.bind(this);
        this.originalPlaceholder = this.placeholder;
    }

    ngOnDestroy() {
        if (this.addressSubscription) {
            this.addressSubscription.unsubscribe();
        }
    }

    fireFocus(e: any): void {
        if (!this.address) {
            this.placeholder = '';
        }
    }

    fireBlur(e: any): void {
        if (!this.address) {
            this.placeholder = this.originalPlaceholder;
        }
        // address not selected from the list
        if (this.address !== this.formattedAddress) {
            this.onBlur.emit(false);
        } else {
            this.onBlur.emit(true);
        }
    }

    public requestAddressList(query: string): Observable<any[]> {
        const request: AddressSearchRequest = new AddressSearchRequest();
        request.Query = query;
        request.SessionKey = this.sessionToken;
        request.ProviderId = this.isChinaAddress ? this.BaiduProviderId : this.GoogleProviderId;
        return this.apiSvc.AddressSearch(request).pipe(
            tap(n => this.providerId = n.ProviderId),
            map(n => {
                return n.AddressList
            })
        );
    }

    public onSuggestionSelected(address) {
        // If address is an Address object
        if (address.FormattedAddress) {
            const request = new AddressDetailsRequest();
            request.ProviderId = this.providerId;
            request.ProviderAddressKey = address.ProviderAddressKey;
            request.SessionToken = this.sessionToken;
            request.Address = address;

            // Regenerate the session token
            this.sessionToken = uuidv4();

            this.addressSubscription = this.apiSvc.AddressDetails(request).subscribe(
                n => {
                    if (n.ErrorCode === 0) {
                        const a = n.Address;
                        this.address = a.FormattedAddress;
                        this.formattedAddress = a.FormattedAddress;
                        this.addressSelected.emit(a);
                        this.onBlur.emit(true);
                    }
                }
            );
        } else { // If address is a string
            this.address = address;
        }
    }

}
