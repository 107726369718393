import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { CarrierInfo } from 'emr-ng-shared';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadCarriers implements Action {
    readonly type = actionIndex.LOAD_CARRIERS;
}

export class LoadCarriersSuccess implements Action {
    readonly type = actionIndex.LOAD_CARRIERS_SUCCESS;
    constructor(public carrierList: IListInfo<CarrierInfo>) { }
}

export class LoadCarriersError implements Action {
    readonly type = actionIndex.LOAD_CARRIERS_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadCarriers implements Action {
    readonly type = actionIndex.CANCEL_LOAD_CARRIERS;
}

