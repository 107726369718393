import { IContactState } from '../models/contact-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as contactActions from './contact.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

import { emptyList } from '../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    contactActions.LoadContacts |
    contactActions.LoadContactsSuccess |
    contactActions.LoadContactsError |
    contactActions.SetContactSuccess |
    contactActions.SetContactError |
    custActions.SelectCustomerSuccess;

export function ContactReducer(state: IContactState = initialState.contactList, action: Actions): IContactState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return {
                ...state,
                contactList: emptyList(),
                isLoadRequired: false,
            };

        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoadRequired: true,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_CONTACT:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_CONTACT_SUCCESS:
            return {
                ...state,
                contactList: action.contactList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_CONTACT_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.SET_CONTACT_SUCCESS :
            const newContactsList = state.contactList.list.splice(0);
            const listInfo = emptyList();
            const contactIndex = newContactsList.findIndex(k => k.ContactId === action.contact.ContactId);
            if (contactIndex > -1) {
                newContactsList[contactIndex] = action.contact
            } else {
                newContactsList.push(action.contact);
            }
            listInfo.itemCount = newContactsList.length;
            listInfo.isPaged = false;
            listInfo.list = newContactsList;
            return {
                ...state,
                contactList: listInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SET_CONTACT_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };
        default:
            return {
                ...state
            };
    }
}
