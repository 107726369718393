<div class="row filter-component-custom" *ngIf="periodName">
    <div class="col-md-12">
        <div class="row mx-1">
            <div class="filter-header-style" i18n="@@label_time_period">Time Period</div>
            <div class="filtered-item position-relative" *ngIf="period !== 8"><span
                    class="filter-content-style">{{periodName}}</span>
                <!-- <span class="filter-count">{{shipmentListCount}}</span>
                <button class="filter-close-button" (click)="clearSelectedFilter()"
                    *ngIf="shipmentFilter?.IsHistorical">
                    <i class="fa fa-close"></i></button> -->
            </div>
            <div *ngIf="period === 8">
                <!-- TODO - Required i18 for Specific Range  -->
                <div class="filtered-item position-relative"><span
                        class="filter-content-style">Specific Range :
                        {{startDateTime}} - {{endDateTime}}</span>
                    <!-- <button class="filter-close-button"
                        *ngIf="shipmentFilter?.IsHistorical" (click)="clearSelectedFilter()"><i
                            class="fa fa-close"></i></button> -->
                </div>
            </div>
        </div>
    </div>
</div>