import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { CarrierService } from 'app-modules/core/services/carrier.service';
import { TimePeriodPageFilter } from 'app-modules/core/store/time-period/time-period.service';
import * as cloneDeep from 'lodash';
import { LinkedCustomersService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.service';
import { LocationService } from 'app-modules/core/services/location.service';
import { CustomerLinkType, LocationTypeCode } from 'emr-ng-shared';
// import {
//     ShipmentFilterPeriodComponent
// } from 'app-modules/shipment-filter/components/shipment-filter-period/shipment-filter-period.component';

@Component({
    selector: 'app-shipment-filter',
    templateUrl: './shipment-filter.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterComponent implements OnInit, OnDestroy {
    // Begin - missing bound variables.
    isOriginFilterCollapsed = true;
    isOriginFilterDirty = false;
    // End - missing bound variables

    isShipmentNameFilterCollapsed = false;
    isShipmentNameFilterDirty = false;

    isSerialNumberFilterCollapsed = false;
    isSerialNumberFilterDirty = false;

    isStatusFilterCollapsed = false;
    isStatusFilterDirty = false;

    isTimePeriodFilterCollapsed = false;
    isPeriodFilterDirty = false;

    isAlertFilterCollapsed = true;
    isAlertFilterDirty = false;

    isDestinationFilterCollapsed = true;
    isDestinationFilterDirty = false;

    isTempFilterCollapsed = true;
    isTempFilterDirty = false;

    carrierListSubscription: Subscription;
    isCarrierEnabled = false;

    isCarrierFilterCollapsed = true;
    isCarrierFilterDirty = false;
    IsUnAuthenticated = false;

    isSupplierFilterCollapsed = true;
    isSupplierFilterDirty = false;

    isDCFilterCollapsed = true;
    isDCFilterDirty = false;
    isDCEnabled = false;
    dcListSubscription: Subscription;
    supplierListSubscription: Subscription;
    isSupplierEnabled = false;
    @Input() shipmentFilter: IShipmentFilterState;

    @Input() showPlaceHolder = false;

    @Output()
    isFilterDirty = new EventEmitter<boolean>();

    @Input() ShowLastNDays: number = null;
    @Input() pageFilter = TimePeriodPageFilter.ListViewFilter

    constructor(
        private shpFltrSvc: ShipmentFilterService,
        private linkedCustSvc: LinkedCustomersService,
        private carrierSvc: CarrierService,
        private locationSvc: LocationService
    ) { }

    ngOnInit() {
        this.carrierListSubscription = this.carrierSvc.carrierList$.subscribe(n => this.isCarrierEnabled = n.itemCount > 0);
        this.supplierListSubscription = this.linkedCustSvc.linkedShipperList$.subscribe(n => this.isSupplierEnabled = n.list.filter(x =>x.ShipperCustomerActive && x.LinkedTypeCode === CustomerLinkType.PUSH).length > 0);
        this.IsUnAuthenticated = this.shpFltrSvc.GetUnAuthenticationToken();
        this.dcListSubscription = this.locationSvc.locationListIncludingCopy$.subscribe(n => {
            this.isDCEnabled = n.list.filter(x => x.TypeCode === LocationTypeCode.DistributionCenter).length > 0;
        });
    }

    ngOnDestroy() {
        this.carrierListSubscription.unsubscribe();
        this.supplierListSubscription?.unsubscribe();
        this.dcListSubscription?.unsubscribe();
    }

    onAccept() {
        this.shpFltrSvc.changeShipmentFilter(this.shipmentFilter);
    }
    // Begin - missing bound method
    public onClearPeriod(): boolean {
        return false;
    }
    // End - missing bound method

    showApplyFilter(): boolean {
        return this.isOriginFilterDirty ||
            this.isShipmentNameFilterDirty ||
            this.isSerialNumberFilterDirty ||
            this.isStatusFilterDirty ||
            this.isPeriodFilterDirty ||
            this.isAlertFilterDirty ||
            this.isDestinationFilterDirty ||
            this.isTempFilterDirty ||
            this.isCarrierFilterDirty ||
            this.isSupplierFilterDirty ||
            this.isDCFilterDirty;
    }

    onFilterMarkedDirty() {
        const isFilterDirty = this.showApplyFilter();
        this.isFilterDirty.emit(isFilterDirty);
    }
}



