
import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { map, tap, count } from 'rxjs/operators';

import { AlertStatus } from 'emr-ng-shared';

import { IPagingState } from 'app-modules/core/store/models/paging-state.interface';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { TrackerHistory } from 'emr-ng-shared';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { of } from 'rxjs';

@Component({
    selector: 'app-historical-shipment',
    templateUrl: './historical-shipment.component.html',
    styleUrls: ['./historical-shipment.component.css']
})

export class HistoricalShipmentComponent implements OnInit {
    historicalShipmentCount$: Observable<number>;
    historicalShipmentPaging$: Observable<IPagingState>;
    minimumCutOffDate = new Date('2010-01-01');

    historicalShipmentList$ = this.detailSvc.historicalShipmentListPage$.pipe(
        map(data => data.list.map((shipment: TrackerHistory) => ({
            ...shipment,
            ActualStartTimeFormatted: new Date(shipment.ActualStartTime) > this.minimumCutOffDate ? shipment.ActualStartTimeFormatted : 'N/A'
        }) as TrackerHistory))
    )

    constructor(private detailSvc: ShipmentDetailService) { }

    ngOnInit() {
        this.historicalShipmentPaging$ = this.detailSvc.historicalShipmentListPaging$;
        this.historicalShipmentCount$ = this.detailSvc.historicalShipmentListPage$.pipe(map(n => n.itemCount));
    }

    onPageSelected(pageNumber: number) {
        this.detailSvc.changeHistoricalShipmentListPage(pageNumber);
    }

    onPageSizeSelected(pageSize: number) {
        this.detailSvc.changeHistoricalShipmentListPageSize(pageSize);
    }
}




