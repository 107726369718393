import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { errors_import_locations, link_import_locations } from 'app-modules/core/consts/localization';
import { BsModalRef } from 'ngx-bootstrap/modal';

export class ImportUpdateStatusResponseOkEventArgs {
  modalRef: BsModalRef;
  closeOnOk: boolean;
}

@Component({
  selector: 'app-import-update-confirmation',
  templateUrl: './import-update-confirmation.component.html',
  styleUrls: ['./import-update-confirmation.component.css']
})
export class ImportUpdateConfirmationComponent implements OnInit {

  @Output() ok = new EventEmitter<ImportUpdateStatusResponseOkEventArgs>();
  closeOnOk: boolean;
  showCloseButton = true;
  hasExceptions = false;
  header = link_import_locations;
  exceptions: number = 0;
  totalRecords: number = 0;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.header = this.hasExceptions ? errors_import_locations : link_import_locations;
  }
  
  onOkClick() {
    const args = new ImportUpdateStatusResponseOkEventArgs();
    args.modalRef = this.bsModalRef;
    args.closeOnOk = this.closeOnOk;

    this.ok.emit(args);
    this.bsModalRef.hide();
  }
}
