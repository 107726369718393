<emr-modal headerTitle="Delete Trip" i18n-headerTitle="@@label_delete_trip" [showCloseButton]=false>
    <div modal-body *ngIf="takeConfirmation else tripDeleteResponseTemplate">
        <div class="col-md-12 m-1">
            <div>
                <p i18n="@@message_delete_trip">Do you want to delete the trip?</p>
            </div>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Yes" i18n-buttonText="@@label_yes"
                (click)='deleteTrip()' cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
            <emr-button identifier="routeResponseOk" buttonType="button" buttonText="No" i18n-buttonText="@@label_no"
                (click)='modalRef.hide()' cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
        </div>
    </div>
</emr-modal>

<ng-template #tripDeleteResponseTemplate>
    <div modal-body>
        <div class="col-md-12 m-1">
            <div *ngIf="errorMessage?.length>0; else noError">
                <p>{{ errorMessage }}</p>
            </div>
            <ng-template #noError>
                <p i18n="@@trip_deleted_successfully">Trip Deleted successfully</p>
            </ng-template>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="tripDeleteResponseOk" buttonType="button" buttonText="Ok"
                i18n-buttonText="@@link_ok" (click)='closeResponse()'
                cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
        </div>
    </div>
</ng-template>