import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ViewLogService } from 'app-modules/core/services/view-log.service';
import { GetHistoryTripRequest, GetHistoryTripResponse } from 'emr-ng-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-show-viewlog',
  templateUrl: './show-viewlog.component.html',
  styleUrls: ['./show-viewlog.component.css']
})
export class ShowViewlogComponent implements OnInit {

  constructor(private viewLogService: ViewLogService) { }
  @Input() getHistoryTripRequest: GetHistoryTripRequest;
  @Output() CancelClick: EventEmitter<void> = new EventEmitter();
  viewLogResponse$: Observable<GetHistoryTripResponse>;

  ngOnInit(): void {
    this.viewLogResponse$ = this.viewLogService.GetHistoryTrip(this.getHistoryTripRequest).pipe(
      map(n => n));
  }

  onCloseClick() {
    this.CancelClick.emit();
  }

}
