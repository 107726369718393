import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { AlertTypeInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IAlertTypeState } from 'app-modules/core/store/models/alert-type-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as alertActions from 'app-modules/core/store/actions/alert-type.actions';

@Injectable()
export class AlertTypeStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public alertTypeStateSelector = ((state: IAppState) => state.alertType);
    public isLoadRequiredSelector = createSelector(
        this.alertTypeStateSelector,
        (state: IAlertTypeState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.alertTypeStateSelector,
        (state: IAlertTypeState) => state.isLoading
    );
    public alertTypeListSelector = createSelector(
        this.alertTypeStateSelector,
        (state: IAlertTypeState) => state.alertTypeList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public alertTypeList$ = this.store.select(this.alertTypeListSelector).pipe();

    // Actions
    public loadAlertTypes() {
        this.store.dispatch(new alertActions.LoadAlertTypes());
    }

    public loadAlertTypesSuccess(alertTypeList: IListInfo<AlertTypeInfo>) {
        this.store.dispatch(new alertActions.LoadAlertTypesSuccess(alertTypeList));
    }

    public loadAlertTypesError(message: string) {
        this.store.dispatch(new alertActions.LoadAlertTypesError(message));
    }

    public cancelLoadAlertTypes() {
        this.store.dispatch(new alertActions.CancelLoadAlertTypes());
    }
}


