import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  isShowBanner = true;
  constructor() { }

  ngOnInit(): void {
    const localStorageData = localStorage.getItem(environment.showBanner);
    if (localStorageData != null && localStorageData == 'false') {
      this.isShowBanner = false;
      document.querySelector("body").style.cssText = "--banner-height: 0px; --total-header-height: 40px";
    }
  }

  OnCloseButtonClick() {
    this.isShowBanner = false;
    localStorage.setItem(environment.showBanner, this.isShowBanner.toString());
    document.querySelector("body").style.cssText = "--banner-height: 0px; --total-header-height: 40px";
  }
}