<emr-modal [bsModalRef]=bsShipmentRouteModalRef>
  <ng-container modal-header>
    <span class="col-md-12 w-100 modal-title modal-header-title" *ngIf="!isItFromRouteDeviation"
      i18n="@@shipment_route"> Shipment Route
    </span>
    <span class="col-md-12 modal-title modal-header-title" *ngIf="isItFromRouteDeviation"
      i18n="@@link_route_deviation">Route
      Deviation</span>
  </ng-container>
  <div modal-body class="col-md-12">
    <app-shipment-route [TraxxList]="TraxxList" [routeDeviation]="getRouteDeviationResponse"
      [isItFromRouteDeviation]="isItFromRouteDeviation" [isFromShowRoute]="!isItFromRouteDeviation"
      [Shipment]="Shipment" (dateRangeChange)="onDateRangeChange($event)" [shipmentList]="shipmentList">
    </app-shipment-route>
  </div>
</emr-modal>