import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CompareTraxxViewComponent } from './components/compare-traxx-view/compare-traxx-view.component';



const routes: Routes = [
    { path: '', component: CompareTraxxViewComponent }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class CompareTraxxRoutingModule { }
