import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { AuthService } from 'app-modules/core/store/auth/auth.service';

@Component({
    selector: 'app-auth-expired',
    templateUrl: './auth-expired.component.html',
    styleUrls: ['./auth-expired.component.css']
})
export class AuthExpiredComponent implements OnInit, AfterViewInit {
    constructor(
        private modalService: BsModalService,
        private authSvc: AuthService
    ) { }

    ngOnInit() {
    }

    public ngAfterViewInit() {
        this.closeAllModals();
        this.authSvc.signOut();
    }

    private closeAllModals() {
        for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
            this.modalService.hide(i);
        }
    }
}
