import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { DualVisibilityLinkCustomer } from 'emr-ng-shared';
import { SensorRangeInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as createActions from 'app-modules/core/store/actions/edit-shipment.actions';
import * as sensorRangeActions from 'app-modules/core/store/actions/edit-shipment-sensor-range.actions';

@Injectable()
export class EditShipmentStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public editShipmentStateSelector = ((state: IAppState) => state.editShipment);
    public selectedLinkedCustomerSelector = createSelector(
        this.editShipmentStateSelector,
        (state) => state.linkedCustomer
    );

    public sensorRangeStateSelector = ((state: IAppState) => state.editShipmentSensorRange);
    public sensorRangeListSelector = createSelector(
        this.sensorRangeStateSelector,
        (state) => state.sensorRangeList
    );

    public isSensorRangeLoadRequiredSelector = createSelector(
        this.sensorRangeStateSelector,
        (state) => state.isLoadRequired
    );

    // Observables
    public selectedLinkedCustomer$ = this.store.select(this.selectedLinkedCustomerSelector).pipe();
    public isSensorRangeLoadRequired$ = this.store.select(this.isSensorRangeLoadRequiredSelector).pipe();
    public sensorRangeList$ = this.store.select(this.sensorRangeListSelector).pipe();

    // Actions
    public selectLinkedCustomer(linkedCustomer: DualVisibilityLinkCustomer) {
        this.store.dispatch(new createActions.SelectLinkedCustomer(linkedCustomer));
    }

    // Sensor Ranges Actions
    public loadSensorRangeList() {
        this.store.dispatch(new sensorRangeActions.LoadSensorRangeList());
    }

    public loadSensorRangeListSuccess(sensorRangeList: IListInfo<SensorRangeInfo>) {
        this.store.dispatch(new sensorRangeActions.LoadSensorRangeListSuccess(sensorRangeList));
    }

    public loadSensorRangeListError(message: string) {
        this.store.dispatch(new sensorRangeActions.LoadSensorRangeListError(message));
    }

    public cancelLoadSensorRangeList() {
        this.store.dispatch(new sensorRangeActions.CancelLoadSensorRangeList());
    }
}

