import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { SetShutDownTrackerRequest } from 'emr-ng-shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';


@Component({
  selector: 'app-shutdown-tracker',
  templateUrl: './shutdown-tracker.component.html',
  styleUrls: ['./shutdown-tracker.component.css']
})
export class ShutdownTrackerComponent implements OnInit {

  @ViewChild('shutdownTrackerResponseTemplate') shutdownTrackerResponseTemplate: TemplateRef<any>;
  errorMessage: string;
  request: SetShutDownTrackerRequest;
  shipment: Shipment;
  isListViewAction: boolean;
  takeConfirmation = true;
  closePopupOnSuccess: BsModalRef;

  constructor(
    public modalRef: BsModalRef,
    private shpSvc: ShipmentService
    ) { }

  ngOnInit(): void {
  }

  shutdownTracker() {
    this.shpSvc.SetShutDownTracker(this.request).pipe(take(1)).subscribe(k => {
      if (k && k.ErrorCode !== 0) {
          this.errorMessage = k.LocalizedErrorMessage;
      }
      this.takeConfirmation = false;
    });
  }

  closeResponse() {
    this.modalRef.hide();
    if (!this.errorMessage) {
      this.shpSvc.postShutdownShipmentUpdate(this.isListViewAction, this.shipment);
      this.closePopupOnSuccess?.hide();
    }
  }

}
