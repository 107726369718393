import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShipmentRouteComponent } from 'app-modules/shipment-route/components/shipment-route/shipment-route.component';
import { ShipmentRoutePopupComponent } from './components/shipment-route-popup/shipment-route-popup.component';

const routes: Routes = [
  { path: '', component: ShipmentRouteComponent },
  { path: '', component: ShipmentRoutePopupComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentRouteRoutingModule { }
