
import { Lookup } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadCarrierList implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER;
    constructor(public linkedCustomerID?: number) { }
}

export class LoadCarrierListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER;
    constructor(public carrierList: Lookup[]) { }
}

export class AddorUpdateCarrierSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_ADDORUPDATE_CARRIER_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER;
    constructor(public carrierlookUpId: number, public carrier: Lookup) { }
}

export class RemoveCarrierSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_REMOVE_CARRIER_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER;
    constructor(public carrierlookUpId: number) { }
}

export class LoadCarrierListError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER;

    constructor(public message: string) { }
}

export class CancelLoadCarrierList implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_CARRIER;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_CARRIER;
}

