import { Action } from '@ngrx/store';

import * as actionIndex from 'app-modules/core/store/actions';

export class FetchingData implements Action {
    readonly type = actionIndex.FETCHING_DATA;
    constructor(public actionType: string) { }
}

export class FetchingDataComplete implements Action {
    readonly type = actionIndex.FETCHING_DATA_COMPLETE;
    constructor(public actionType: string, public callingActionType: string) { }
}

export class CloseSpinner implements Action {
    readonly type = actionIndex.CLOSE_SPINNER;
}
