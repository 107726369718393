import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { EmrNgMapApiModule, EmrNgControlsModule } from 'emr-ng-shared';

import { CoreModule } from 'app-modules/core/core.module';
import { ShipmentFilterModule } from 'app-modules/shipment-filter/shipment-filter.module';

import { MapViewRoutingModule } from './map-view-routing.module';
import { MapViewComponent } from './components/map-view/map-view.component';
import { ShipmentRouteModule } from 'app-modules/shipment-route/shipment-route.module';
import { MapInfoBoxDetailContentComponent } from './components/map-info-box-detail-content/map-info-box-detail-content.component';
import { MapInfoBoxListContentComponent } from './components/map-info-box-list-content/map-info-box-list-content.component'; 

@NgModule({
    declarations: [
        MapViewComponent,
        MapInfoBoxDetailContentComponent,
        MapInfoBoxListContentComponent
    ],
    imports: [
        CommonModule,
        MapViewRoutingModule,
        CoreModule,
        EmrNgControlsModule,
        EmrNgMapApiModule,
        ShipmentFilterModule,
        ShipmentRouteModule,
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot()
    ],
    exports: [
        MapViewRoutingModule,
        MapViewComponent
    ]
})
export class MapViewModule { }
