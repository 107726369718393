<div class="row mt-1">
    <div class="col-md-4">
        <span class="label-css" i18n="@@label_temperature">Temperature</span>
    </div>
    <div class="col-md-8 pl-2">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioTempUnitC" name="radioTempUnit" class="custom-control-input" [value]="'C'"
                [(ngModel)]="uSettings.temperatureUnits">
            <label class="custom-control-label label-css" for="radioTempUnitC" i18n="@@temp_C">°C</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioTempUnitF" name="radioTempUnit" class="custom-control-input" [value]="'F'"
                [(ngModel)]="uSettings.temperatureUnits">
            <label class="custom-control-label label-css" for="radioTempUnitF" i18n="@@temp_F">°F</label>
        </div>
    </div>
</div>
<div class="row mt-1">
    <div class="col-md-4">
        <span class="label-css" i18n="@@Distance">Distance</span>
    </div>
    <div class="col-md-8 pl-2">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioDistUnitKm" name="radioDistUnit" class="custom-control-input" [value]="'K'"
                [(ngModel)]="uSettings.distanceUnits">
            <label class="custom-control-label label-css" for="radioDistUnitKm" i18n="@@dist_km">Km</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioDistUnitMiles" name="radioDistUnit" class="custom-control-input" [value]="'M'"
                [(ngModel)]="uSettings.distanceUnits">
            <label class="custom-control-label label-css" for="radioDistUnitMiles" i18n="@@dist_miles">Miles</label>
        </div>
    </div>
</div>
<div class="row mt-1">
    <div class="col-md-12">
        <emr-custom-dropdown ngDefaultControl identifier="lstDateFormat" name="lstDateFormat"
            [options]="dateFormatList$ | async" [(ngModel)]="uSettings.dateFormatEnum" cssClass="default-dropdown"
            label="Date Format" i18n-label="@@label_dateformat" optionsValueField="Value"
            optionsTextField="LocalizedText" [inline]=true [controlInline]=false labelColumns=4 controlColumns=8
            [isLoading]="isDateFormatLoading$ | async">
        </emr-custom-dropdown>
    </div>
</div>
<div class="row mt-1">
    <div class="col-md-4 pr-0">
        <span class="label-css" i18n="@@label_time_format">Time Format</span>
    </div>
    <div class="col-md-8 pl-2">
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioTimeFormat12" name="radioTimeFormat" class="custom-control-input" [value]="1"
                [(ngModel)]="uSettings.timeFormatEnum">
            <label class="custom-control-label label-css" for="radioTimeFormat12" i18n="@@label_12_hour">12 hour</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
            <input type="radio" id="radioTimeFormat24" name="radioTimeFormat" class="custom-control-input" [value]="2"
                [(ngModel)]="uSettings.timeFormatEnum">
            <label class="custom-control-label label-css" for="radioTimeFormat24" i18n="@@label_24_hour">24 hour</label>
        </div>
    </div>
</div>
<div class="row mt-1">
    <div class="col-md-12">
        <emr-custom-dropdown ngDefaultControl identifier="lstTimeZone" name="lstTimeZone"
            [options]="timezoneList$ | async" [(ngModel)]="uSettings.timeZoneID" cssClass="default-dropdown"
            label="Timezone" i18n-label="@@label_timezone" optionsValueField="TimeZoneId" optionsTextField="FullName"
            [inline]=true [controlInline]=false labelColumns=4 controlColumns=8 [enableSearchFilter]=true
            SearchPlaceHolder="Search Time Zone" i18n-SearchPlaceHolder="@@search_time_zone"
            placeholder="Select Time Zone" i18n-placeholder="@@label_select_time_zone"
            [isLoading]="isTimeZoneLoading$ | async">
        </emr-custom-dropdown>
    </div>
</div>
<div class="row">
    <div class="col-md-12 text-center">
        <emr-button identifier="btnOK" buttonText="OK" i18n-buttonText="@@link_ok"
            cssClass="btn button-text font-14 btn-lg btn-primary quick-link py-1 m-0 mt-2 text-uppercase"
            (onClick)="setUserSettings()">
        </emr-button>
    </div>
</div>