import { IPagingState } from 'app-modules/core/store/models/paging-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as historicalShipmentPgActions from 'app-modules/core/store/actions/shipment-detail-historical-shipment-paging.actions';

type Actions =
    authActions.SignOut |
    historicalShipmentPgActions.ChangeShipmentDetailHistoricalShipmentPage |
    historicalShipmentPgActions.ChangeShipmentDetailHistoricalShipmentPageSize;

export function ShipmentDetailHistoricalShipmentPagingReducer (
    state: IPagingState = initialState.shipmentDetailAlertPaging,
    action: Actions
): IPagingState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailAlertPaging;

        case actionIndex.CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE:
            return {
                ...state,
                pageNumber: action.pageNumber
            };

        case actionIndex.CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.pageSize,
                pageNumber: 1
            };

        default:
            return state;
    }
}
