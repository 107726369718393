import { ILinkedCustomerState } from 'app-modules/core/store/models/linked-customer-state.interface';
import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as actions from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    actions.LoadLinkedCustomersSuccess |
    actions.LoadLinkedCustomersError |
    custActions.SelectCustomerSuccess;

export function LinkedCustomerReducer(state: ILinkedCustomerState = initialState.linkedCustomer, action: Actions): ILinkedCustomerState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return initialState.linkedCustomer;

        case actionIndex.LOAD_LINKED_CUSTOMERS_SUCCESS:
            return {
                ...state,
                linkedCustomerList: action.linkedCustomerList,
                isLoadRequired: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_LINKED_CUSTOMERS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: action.message
            };

        default:
            return state;
    }
}

