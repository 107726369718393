import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { IShipmentFilterAlertType } from 'app-modules/core/store/models/shipment-filter-alert-type.interface';
import { AlertTypeService } from 'app-modules/core/services/alert-type.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { environment } from 'environments/environment';

declare interface SelectableAlertType {
    selected: boolean;
}

@Component({
    selector: 'app-shipment-filter-alert',
    templateUrl: './shipment-filter-alert.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterAlertComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: IShipmentFilterAlertType;
    enableLateFilter = environment.enableLateFilter;
    @Input() get filter() { return this.filterVal; }
    @Output() filterChange = new EventEmitter();
    set filter(val) {
        if (val) {
            this.filterVal = val;
            this.filterChange.emit(this.filterVal);
        }
    }

    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    private alertTypeFilterSubscription: Subscription;

    constructor(
        private alertSvc: AlertTypeService,
        private filterSvc: ShipmentFilterService
    ) { }

    public ngOnInit() {
        this.alertTypeFilterSubscription = this.filterSvc.alertFilter$.subscribe(n => this.onAlertTypeFilterSubscription(n));
    }

    public ngOnDestroy() {
        this.alertTypeFilterSubscription.unsubscribe();
    }

    onAlertTypeFilterSubscription(filter: IShipmentFilterAlertType) {
        this.isDirty = false;
        this.filter = {
            aboveTemp: filter.aboveTemp,
            belowTemp: filter.belowTemp,
            late: filter.late,
            moving: filter.moving,
            notMoving: filter.notMoving
        };
    }

    onSelectionChanged() {
        this.isDirty = true;
    }
}

