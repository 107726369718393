import { InjectionToken } from '@angular/core';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { environment } from 'environments/environment';

export const APP_IN_CHINA = new InjectionToken<boolean>('IS_CHINA_DOMAIN');
export const HOST_NAME = new InjectionToken<boolean>('HOST_NAME');

export function verifyCountry(): [boolean, string] {
    const win = getNativeWindow();
    let isChinaDomain = false;
    let hostName = null;
    if (win &&
        win.location &&
        win.location &&
        win.location.hostname) {
        isChinaDomain = win.location.hostname.endsWith('cn') ? true : false;
        // isChinaDomain = true; // For Testing
        StoreManager.StoreValue(environment.loadBaiduMapsSettings, isChinaDomain ? '1' : '0',
            // tslint:disable-next-line: no-bitwise
            StoreOpts.LocalStorage | StoreOpts.Cookie | StoreOpts.StoreManager);
        hostName = win.location.hostname;

    }
    return [isChinaDomain, hostName];
}

export function getNativeWindow(): any {
    return window;
}

