import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMERSON_TERMS_URL, EMERSON_WARRANTY_URL, STORE_KEYS } from 'app-modules/core/consts/values';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomoUIService } from '../services/domo.ui.service';

@Component({
  selector: 'app-dashboard-terms-message-popup',
  templateUrl: './dashboard-terms-message-popup.component.html',
  styleUrls: ['./dashboard-terms-message-popup.component.css']
})
export class DashboardTermsMessagePopupComponent implements OnInit {
  terms_URL = EMERSON_WARRANTY_URL;
  warranty_URL = EMERSON_TERMS_URL;
  constructor(public bsDashboardTermsModalRef: BsModalRef, private router: Router, private domoUIService: DomoUIService) { }


  ngOnInit(): void {
  }

  onAccept() {
    StoreManager.StoreValue(
      STORE_KEYS.dashboardTermsandConditions, 'true',
      StoreOpts.Cookie | StoreOpts.LocalStorage | StoreOpts.StoreManager);
    this.domoUIService.dashboardTermsAccepted();
    this.bsDashboardTermsModalRef.hide();
  }

  onPopupCancel() {
    this.bsDashboardTermsModalRef.hide();
    this.router.navigate(['/']);
  }
}
