import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

import { Observable, EMPTY, Subscription, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { api } from 'environments/api';

import {
    failoverIf, mapError, EmrOversightService, OversightApiContext, ErrorResponse, LoginRequest, LoginResponse,
    AuthorizeExternalRequest, AuthorizeExternalResponse,
    GetCustomerListResponse, GetLinkedCustomerListResponse, GetCustomerResponse, GetTrackerStatusRequest,
    GetTrackerStatusResponse, GetTrackerSerialsListRequest, GetTrackerSerialsListResponse,
    GetTripTemplateRequest, GetTripTemplateResponse, GetVehicleGroupRequest, GetVehicleGroupResponse,
    GetSensorRangeRequest, GetSensorRangeResponse, GetLocationRequest, GetLocationResponse,
    SetLocationRequest, SetLocationResponse, GetCoolDownTypesResponse, CreateShipmentRequest,
    CreateShipmentResponse, UpdateShipmentRequest, UpdateShipmentResponse, GetAlertTypesRequest,
    GetAlertTypesResponse, GetTripStatesResponse, GetTimePeriodsRequest, GetTimePeriodsResponse,
    GetCarrierListRequest, GetCarrierListResponse, CloseTripRequest, CloseTripResponse,
    GetDistanceToTargetsRequest, GetDistanceToTargetsResponse, GetRouteRequest, GetRouteResponse,
    GetTraxxRequest, GetTraxxResponse, GetShipmentDetailsRequest, GetShipmentDetailsResponse,
    GetAlertStatusRequest, GetAlertStatusResponse, CloseAlertRequest, GetAlertSuspendStatusRequest,
    GetAlertSuspendStatusResponse, SuspendAlertsRequest, SuspendAlertsResponse, GetTimelineRequest,
    GetTimelineResponse, GetLocalizedTimeZonesResponse, SetSensorRangeRequest, USetSensorRangeRequest, SetSensorRangeResponse,
    GetCreateShipmentCarrierRequest, GetCreateShipmentCarrierResponse, FormatLocalizedStringRequest,
    FormatLocalizedStringResponse, GetTrackerStateReportRequest, GetTrackerStateReportResponse, GetShipmentRequest, ShipmentRequest,
    GetShipmentResponse, UserPreferencesResponse, GetSensorDataRequest, GetSensorDataResponse,
    GetUserSettingRequest, GetUserSettingResponse, SetUserSettingRequest, SetUserSettingResponse,
    SensorReportScheduleRequest, SensorReportScheduleResponse, GetReverseGeocodeAddressRequest,
    GetMultiTripRequest, GetMultiTripResponse, GetTrackerSerialsListLookUpResponse,
    GetTrackerSerialsLookUpRequest, ValidateDVShipmentRequest, ValidateDVShipmentResponse,
    ExportTrackerStateReportHeaders, ExportTrackerStateReportRequest, GetSortOptionsRequest,
    GetSortOptionsResponse, GetTripTemplateDetailsResponse, GetTripTemplateDetailsRequest,
    GetTrackerSerialsListWithNoTripResponse, ChangePasswordRequest, ChangePasswordResponse,
    RouteEditorModel, RouteEditorResponseModel, GetRouteDeviationsResponseModel,
    GetRouteDeviationsRequestModel, GetRouteDeviationRequestModel, GetRouteDeviationReportResponseModel,
    GetRouteDeviationResponseModel, SaveRouteRequest, RouteDeviationReportRequest,
    AddressSearchRequest, AddressSearchResponse, GetTripStartOptionResponse, GetTripEndOptionResponse,
    GetBusinessRulesResponse, GetBusinessRulesRequest, GetSavedRoutesRequest,
    GetSavedRoutesResponse, SaveRouteResponse, AddressDetailsRequest, AddressDetailsResponse,
    getLocationColorListResponse, UCreateShipmentRequest, UCreateShipmentResponse, GetTrackerResponse,
    BreadCrumbFlagRequest, BreadCrumbFlagResponse, SetTripSensorRangeRequest, SetTripSensorRangeResponse,
    SetTrackerSensorRangeRequest, SetTrackerSensorRangeResponse, GetContactListResponse,
    ScheduleReportRequest, ScheduleReportResponse, GetScheduledReportsResponse, GetObjectViewsRequest, GetObjectViewsResponse,
    GetObjectViewArgsListRequest, GetObjectViewArgsListResponse, SetTrackerNoteRequest, SetTrackerNoteResponse,
    DeleteGeofenceRequest, DeleteGeofenceResponse, GetLocationBoundaryRequest, GetLocationBoundaryResponse,
    SaveLocationContactRequest, SaveLocationContactResponse, SetContactRequest, SetContactResponse,
    DashhboardSettingsRequest, DashhboardSettingsResponse, DomoAuthResponse, DomoAccessTokenModel,
    GetDashboardResponse, GetCustomerDashboardsResponse, SetDashboardRequest, SetDashboardResponse, SetDashboardCustomerRequest,
    SetCustomerDashboardResponse, DeleteLocationRequest, DeleteLocationResponse, CalibrationStatement,
    SetShutDownTrackerRequest, SetShutDownTrackerResponse, ShareTrackerRequest, ShareTrackerResponse, LoggerReadResponse,
    GetHistoryTripRequest, GetHistoryTripResponse, GetLookUpRequest, LookupResponse, SetLookUpRequest
} from 'emr-ng-shared';

import { AuthStateService } from 'app-modules/core/store/auth/auth-state.service';
import { CustomerStateService } from 'app-modules/core/store/services/customer-state.service';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { toIErrorInfo } from '../models/error-info.interface';
import { ICustomInfoWithErrorResponse } from '../store/models/custom-info-error-state-interface';
import { BaseURLPicker } from './baseurl-picker.service';
import { SetTripStopResponse } from 'emr-ng-shared';
import { SetTripStopRequest, GetTripStopResponse, SetShipmentTemplateResponse } from 'emr-ng-shared';
import { UnAuthStateService } from '../store/un-auth/un-auth-state.service';

@Injectable()
export class OversightApiService implements OnDestroy {
    private locale?: string;
    private langCode?: string;
    private cntryCode?: string;
    private authToken?: string;
    private custToken?: string;
    private custNavToken?: string;
    private rememberMe?: boolean;
    private refreshToken?: string;
    private IsUnAuthenticated = false;
    private IsUserSignedIn = false;
    private authTokenSubscription: Subscription;
    private refreshTokenSubscription: Subscription;
    private rememberMeSubscription: Subscription;
    private userLoginStateSubscription: Subscription;
    private selectedContextSubscription: Subscription;
    private unAuthCustNavTokenSub: Subscription;
    domoAccessToken: string;

    constructor(
        private restSvc: EmrOversightService,
        private authStateSvc: AuthStateService,
        private unAuthStateSvc: UnAuthStateService,
        private custStateSvc: CustomerStateService,
        private router: Router,
        domainPicker: BaseURLPicker
    ) {
        const authenticatedFlowURL = `${domainPicker.apiDomain}${api.oversightApiUrl}`;
        const unAuthenticatedFlowURL = `${domainPicker.apiDomain}${api.uoversightApiUrl}`;
        const auditFlowURL = `${domainPicker.apiDomain}${api.auditApiUrl}`;
        restSvc.setBaseUrl(authenticatedFlowURL);
        this.restSvc.setBaseUnAuthUrl(unAuthenticatedFlowURL);
        this.restSvc.setAuditUrl(auditFlowURL);
        this.restSvc.setDomainUrl(domainPicker.apiDomain);
        const resolver = new LocaleResolver(environment.localesPersistKey);
        this.locale = resolver.resolveLocale();
        if (this.locale === 'th') {
            this.locale = 'en';
        }
        this.parseLocale(this.locale);
        this.authTokenSubscription = authStateSvc.authToken$.subscribe(n => this.authToken = n);
        this.refreshTokenSubscription = authStateSvc.refreshToken$.subscribe(n => this.refreshToken = n);
        this.rememberMeSubscription = authStateSvc.rememberMe$.subscribe(n => this.rememberMe = n);
        this.userLoginStateSubscription = authStateSvc.getUserLoginState().subscribe(n => {
            this.IsUserSignedIn = n
        });
        this.selectedContextSubscription =
            combineLatest(
                custStateSvc.selectedContext$,
                custStateSvc.isSelected$)
                .subscribe(([selectedContext, isSelected]) => {
                    if (isSelected && Boolean(selectedContext)) {
                        this.custToken = selectedContext.token;
                    }
                });
        this.unAuthCustNavTokenSub = unAuthStateSvc.unAuthCustomerNavToken$.subscribe(s => {
            if (this.IsUnAuthenticated) {
                this.custNavToken = s
            }
        });
    }

    ngOnDestroy() {
        if (this.authTokenSubscription) {
            this.authTokenSubscription.unsubscribe();
        }
        if (this.rememberMeSubscription) {
            this.rememberMeSubscription.unsubscribe();
        }
        if (this.refreshTokenSubscription) {
            this.refreshTokenSubscription.unsubscribe();
        }
        if (this.userLoginStateSubscription) {
            this.userLoginStateSubscription.unsubscribe();
        }
        if (this.selectedContextSubscription) {
            this.selectedContextSubscription.unsubscribe();
        }
        if (this.unAuthCustNavTokenSub) {
            this.unAuthCustNavTokenSub.unsubscribe();
        }
    }

    public setUnAuthenticationToken(IsUnAuthenticated: boolean) {
        this.IsUnAuthenticated = IsUnAuthenticated;
        this.restSvc.setIsUnAuthenticated(IsUnAuthenticated);
    }

    public getUnAuthenticationToken() {
        return this.IsUnAuthenticated;
    }

    public Login(request: LoginRequest): Observable<LoginResponse> {
        return this.ExecApiCall<LoginResponse>(
            () => this.restSvc.Login(request, this.GetContext()),
            true
        );
    }

    public saveRoute(request: SaveRouteRequest): Observable<SaveRouteResponse> {
        return this.ExecApiCall<SaveRouteResponse>(
            () => this.restSvc.saveRoute(request, this.GetContext()),
            true
        );
    }

    public getRouteDeviations(request: GetRouteDeviationsRequestModel): Observable<GetRouteDeviationsResponseModel> {
        return this.ExecApiCall<GetRouteDeviationsResponseModel>(
            () => this.restSvc.getRouteDeviations(request, this.GetContext()),
            true
        );
    }

    public getRouteDeviation(request: GetRouteDeviationRequestModel): Observable<GetRouteDeviationResponseModel> {
        return this.ExecApiCall<GetRouteDeviationResponseModel>(
            () => this.restSvc.getRouteDeviation(request, this.GetContext()),
            true
        );
    }


    public AuthorizeExternal(request: AuthorizeExternalRequest): Observable<AuthorizeExternalResponse> {
        return this.ExecApiCall<AuthorizeExternalResponse>(
            () => this.restSvc.AuthorizeExternal(request, this.GetContext()),
            true
        );
    }

    public ForgotPassword(request: LoginRequest): Observable<ErrorResponse> {
        return this.ExecApiCall<ErrorResponse>(
            () => this.restSvc.ForgotPassword(request, this.GetContext()),
            true
        );
    }

    public RefreshToken(): Observable<LoginResponse> {
        return this.ExecApiCall<LoginResponse>(
            () => this.restSvc.RefreshToken(this.GetContext())
        );
    }

    public GetCustomerList(): Observable<GetCustomerListResponse> {
        return this.ExecApiCall<GetCustomerListResponse>(
            () => this.restSvc.GetCustomerList(this.GetContext())
        );
    }

    public GetCustomer(customerId: number): Observable<GetCustomerResponse> {
        return this.ExecApiCall<GetCustomerResponse>(
            () => this.restSvc.GetCustomer(customerId, this.GetContext())
        );
    }

    public PreLoadCustomerCache(customerId: number): Observable<GetCustomerResponse> {
        return this.GetCustomer(customerId);
    }

    public GetUserPreferences(): Observable<UserPreferencesResponse> {
        return this.ExecApiCall<UserPreferencesResponse>(
            () => this.restSvc.GetUserPreferences(this.GetContext())
        );
    }

    public GetTraxx(request: GetTraxxRequest): Observable<GetTraxxResponse> {
        return this.ExecApiCall<GetTraxxResponse>(
            () => this.restSvc.GetTraxx(request, this.GetContext())
        );
    }

    public UGetTraxx(request: GetTraxxRequest): Observable<GetTraxxResponse> {
        return this.ExecApiCall<GetTraxxResponse>(
            () => this.restSvc.UGetTraxx(request, this.GetContext()),
            true
        );
    }

    public MarkBreadCrumb(request: BreadCrumbFlagRequest): Observable<BreadCrumbFlagResponse> {
        return this.ExecApiCall<BreadCrumbFlagResponse>(
            () => this.restSvc.MarkBreadCrumb(request, this.GetContext())
        );
    }

    public UGetTrackerStatus(req: GetTrackerStatusRequest): Observable<GetTrackerStatusResponse> {
        return this.ExecApiCall<GetTrackerStatusResponse>(
            () => this.restSvc.UGetTrackerStatus(req, this.GetContext()),
            true
        );
    }

    public GetTrackerStatus(request: GetTrackerStatusRequest): Observable<GetTrackerStatusResponse> {
        return this.ExecApiCall<GetTrackerStatusResponse>(
            () => this.restSvc.GetTrackerStatus(request, this.GetContext())
        );
    }

    public GetTrackerStateList(request: GetTrackerStatusRequest): Observable<GetTrackerStatusResponse> {
        return this.ExecApiCall<GetTrackerStatusResponse>(
            () => this.restSvc.GetTrackerStateList(request, this.GetContext())
        );
    }

    public GetAlertStatus(request: GetAlertStatusRequest): Observable<GetAlertStatusResponse> {
        return this.ExecApiCall<GetAlertStatusResponse>(
            () => this.restSvc.GetAlertStatus(request, this.GetContext())
        );
    }

    public GetHistoricalShipmentList(request: ShipmentRequest): Observable<GetShipmentResponse> {
        return this.ExecApiCall<GetShipmentResponse>(
            () => this.restSvc.GetHistoricalShipmentList(request, this.GetContext())
        );
    }

    public CloseAlert(request: CloseAlertRequest): Observable<ErrorResponse> {
        return this.ExecApiCall<ErrorResponse>(
            () => this.restSvc.CloseAlert(request, this.GetContext())
        );
    }

    public GetTrackerSerialsList(request: GetTrackerSerialsListRequest): Observable<GetTrackerSerialsListResponse> {
        return this.ExecApiCall<GetTrackerSerialsListResponse>(
            () => this.restSvc.GetTrackerSerialsList(request, this.GetContext())
        );
    }

    public GetTrackerSerialsLookUpList(request: GetTrackerSerialsLookUpRequest): Observable<GetTrackerSerialsListLookUpResponse> {
        return this.ExecApiCall<GetTrackerSerialsListLookUpResponse>(
            () => this.restSvc.GetTrackerSerialsListLookup(request, this.GetContext())
        );
    }

    public GetTripTemplate(request: GetTripTemplateRequest): Observable<GetTripTemplateResponse> {
        return this.ExecApiCall<GetTripTemplateResponse>(
            () => this.restSvc.GetTripTemplate(request, this.GetContext())
        );
    }

    public GetTripTemplateDetails(request: GetTripTemplateDetailsRequest): Observable<GetTripTemplateDetailsResponse> {
        return this.ExecApiCall<GetTripTemplateDetailsResponse>(
            () => this.restSvc.GetTripTemplateDetails(request, this.GetContext())
        );
    }

    public GetVehicleGroup(request: GetVehicleGroupRequest): Observable<GetVehicleGroupResponse> {
        return this.ExecApiCall<GetVehicleGroupResponse>(
            () => this.restSvc.GetVehicleGroup(request, this.GetContext())
        );
    }

    public GetSensorRanges(request: GetSensorRangeRequest): Observable<GetSensorRangeResponse> {
        return this.ExecApiCall<GetSensorRangeResponse>(
            () => this.restSvc.GetSensorRanges(request, this.GetContext())
        );
    }

    public UGetSensorRanges(custID: number): Observable<GetSensorRangeResponse> {
        return this.ExecApiCall<GetSensorRangeResponse>(
            () => this.restSvc.UGetSensorRanges(custID, this.GetContext())
        );
    }

    public GetLocations(request: GetLocationRequest): Observable<GetLocationResponse> {
        return this.ExecApiCall<GetLocationResponse>(
            () => this.restSvc.GetLocations(request, this.GetContext())
        );
    }

    public GetCarriers(request: GetCreateShipmentCarrierRequest): Observable<GetCreateShipmentCarrierResponse> {
        return this.ExecApiCall<GetCreateShipmentCarrierResponse>(
            () => this.restSvc.GetCarriers(request, this.GetContext())
        );
    }

    public SetLocation(request: SetLocationRequest): Observable<SetLocationResponse> {
        return this.ExecApiCall<SetLocationResponse>(
            () => this.restSvc.SetLocation(request, this.GetContext())
        );
    }

    public GetCoolDownTypes(): Observable<GetCoolDownTypesResponse> {
        return this.ExecApiCall<GetCoolDownTypesResponse>(
            () => this.restSvc.GetCoolDownTypes(this.GetContext())
        );
    }

    public CreateShipment(request: CreateShipmentRequest): Observable<CreateShipmentResponse> {
        return this.ExecApiCall<CreateShipmentResponse>(
            () => this.restSvc.CreateShipment(request, this.GetContext())
        );
    }

    public CreateShipmentNew(request: CreateShipmentRequest): Observable<CreateShipmentResponse> {
        return this.ExecApiCall<CreateShipmentResponse>(
            () => this.restSvc.CreateShipmentNew(request, this.GetContext())
        );
    }

    public UpdateShipment(request: UpdateShipmentRequest): Observable<UpdateShipmentResponse> {
        return this.ExecApiCall<UpdateShipmentResponse>(
            () => this.restSvc.UpdateShipment(request, this.GetContext())
        );
    }

    public GetAllAlertTypes(): Observable<GetAlertTypesResponse> {
        return this.ExecApiCall<GetAlertTypesResponse>(
            () => this.restSvc.GetAllAlertTypes(this.GetContext())
        );
    }

    public GetAlertTypes(request: GetAlertTypesRequest): Observable<GetAlertTypesResponse> {
        return this.ExecApiCall<GetAlertTypesResponse>(
            () => this.restSvc.GetAlertTypes(request, this.GetContext())
        );
    }

    public GetTripStates(): Observable<GetTripStatesResponse> {
        return this.ExecApiCall<GetTripStatesResponse>(
            () => this.restSvc.GetTripStates(this.GetContext())
        );
    }

    public GetTimePeriods(request: GetTimePeriodsRequest): Observable<GetTimePeriodsResponse> {
        return this.ExecApiCall<GetTimePeriodsResponse>(
            () => this.restSvc.GetTimePeriods(request, this.GetContext())
        );
    }

    public GetCarrierList(request: GetCarrierListRequest): Observable<GetCarrierListResponse> {
        return this.ExecApiCall<GetCarrierListResponse>(
            () => this.restSvc.GetCarrierList(request, this.GetContext())
        );
    }

    public CloseShipment(request: CloseTripRequest): Observable<CloseTripResponse> {
        return this.ExecApiCall<CloseTripResponse>(
            () => this.restSvc.CloseTrip(request, this.GetContext())
        );
    }

    public GetDistanceToTargets(request: GetDistanceToTargetsRequest): Observable<GetDistanceToTargetsResponse> {
        return this.ExecApiCall<GetDistanceToTargetsResponse>(
            () => this.restSvc.GetDistanceToTargets(request, this.GetContext())
        );
    }

    public GetRoute(request: GetRouteRequest): Observable<GetRouteResponse> {
        return this.ExecApiCall<GetRouteResponse>(
            () => this.restSvc.GetRoute(request, this.GetContext())
        );
    }

    public GetShipmentDetails(request: GetShipmentDetailsRequest): Observable<GetShipmentDetailsResponse> {
        return this.ExecApiCall<GetShipmentDetailsResponse>(
            () => this.restSvc.GetShipmentDetails(request, this.GetContext())
        );
    }

    public GetTrackerStateReport(request: GetTrackerStateReportRequest): Observable<GetTrackerStateReportResponse> {
        return this.ExecApiCall<GetTrackerStateReportResponse>(
            () => this.restSvc.GetTrackerStateReport(request, this.GetContext())
        );
    }

    public GetShipment(request: GetShipmentRequest): Observable<GetShipmentResponse> {
        return this.ExecApiCall<GetShipmentResponse>(
            () => this.restSvc.GetShipment(request, this.GetContext())
        );
    }

    public GetSensorData(request: GetSensorDataRequest): Observable<GetSensorDataResponse> {
        return this.ExecApiCall<GetSensorDataResponse>(
            () => this.restSvc.GetSensorData(request, this.GetContext())
        );
    }


    public GetUserSetting(request: GetUserSettingRequest): Observable<GetUserSettingResponse> {

        return this.ExecApiCall<GetUserSettingResponse>(
            () => this.restSvc.GetUserSetting(request, this.GetContext())
        );
    }

    public GetUserSettings(request: GetUserSettingRequest[]): Observable<GetUserSettingResponse[]> {
        return this.ExecApiCall<GetUserSettingResponse[]>(
            () => this.restSvc.GetUserSettings(request, this.GetContext())
        );
    }

    public SetUserSetting(request: SetUserSettingRequest): Observable<SetUserSettingResponse> {
        return this.ExecApiCall<SetUserSettingResponse>(
            () => this.restSvc.SetUserSetting(request, this.GetContext())
        );
    }

    public SetUserGlobalSetting(request: SetUserSettingRequest): Observable<SetUserSettingResponse> {
        return this.ExecApiCall<SetUserSettingResponse>(
            () => this.restSvc.SetUserGlobalSetting(request, this.GetContext())
        );
    }

    public DeleteUserSetting(request: SetUserSettingRequest): Observable<SetUserSettingResponse> {
        return this.ExecApiCall<SetUserSettingResponse>(
            () => this.restSvc.DeleteUserSetting(request, this.GetContext())
        );
    }

    public ImportLocations(byteArray: any[]): Observable<ICustomInfoWithErrorResponse<string>> {
        return this.ExecApiCall<any>(
            () => this.restSvc.ImportLocations(byteArray, this.GetContext())
        );
    }

    public SaveImportLocations(locations: string): Observable<ICustomInfoWithErrorResponse<string>> {
        return this.ExecApiCall<any>(
            () => this.restSvc.SaveImportLocations(locations, this.GetContext())
        );
    }

    public ExportTrackerStateReport(
        queryParams: ExportTrackerStateReportRequest, extraHeaders: ExportTrackerStateReportHeaders
    ): Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.ExportTrackerStateReport(queryParams, extraHeaders, this.GetContext())
        );
    }

    public ExcelExportLocations(queryParams: any,
        extraHeaders: ExportTrackerStateReportHeaders
    ): Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.ExcelExportLocations(queryParams, extraHeaders, this.GetContext())
        );
    }

    public ExportPDFSensorReport(
        queryParams, extraHeaders: ExportTrackerStateReportHeaders
    ): Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.ExportPDFSensorReport(queryParams, extraHeaders, this.GetContext())
        );
    }

    public ExportExcelSensorReport(
        queryParams, extraHeaders: ExportTrackerStateReportHeaders
    ): Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.ExportExcelSensorReport(queryParams, extraHeaders, this.GetContext())
        );
    }

    public ExportExcelListViewReport(
        queryParams, extraHeaders: ExportTrackerStateReportHeaders
    ): Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.ExportExcelListViewReport(queryParams, this.GetContext(), extraHeaders)
        );
    }

    public GetAlertSuspendStatus(request: GetAlertSuspendStatusRequest): Observable<GetAlertSuspendStatusResponse> {
        return this.ExecApiCall<GetAlertSuspendStatusResponse>(
            () => this.restSvc.GetAlertSuspendStatus(request, this.GetContext())
        );
    }

    public SuspendAlerts(request: SuspendAlertsRequest): Observable<SuspendAlertsResponse> {
        return this.ExecApiCall<SuspendAlertsResponse>(
            () => this.restSvc.SuspendAlerts(request, this.GetContext())
        );
    }

    public GetTimeline(request: GetTimelineRequest): Observable<GetTimelineResponse> {
        return this.ExecApiCall<GetTimelineResponse>(
            () => this.restSvc.GetTimeline(request, this.GetContext())
        );
    }

    public GetTimeZoneList(): Observable<GetLocalizedTimeZonesResponse> {
        return this.ExecApiCall<GetLocalizedTimeZonesResponse>(
            () => this.restSvc.GetTimeZoneList(this.GetContext()),
            true
        );
    }

    public SetSensorRange(request: SetSensorRangeRequest): Observable<SetSensorRangeResponse> {
        return this.ExecApiCall<SetSensorRangeResponse>(
            () => this.restSvc.SetSensorRange(request, this.GetContext())
        );
    }

    public USetSensorRange(request: USetSensorRangeRequest): Observable<SetSensorRangeResponse> {
        return this.ExecApiCall<SetSensorRangeResponse>(
            () => this.restSvc.USetSensorRange(request, this.GetContext())
        );
    }

    public FormatLocalizedString(request: FormatLocalizedStringRequest): Observable<FormatLocalizedStringResponse> {
        return this.ExecApiCall<FormatLocalizedStringResponse>(
            () => this.restSvc.FormatLocalizedString(request, this.GetContext())
        );
    }

    public GetSortOptions(request: GetSortOptionsRequest): Observable<GetSortOptionsResponse> {
        return this.ExecApiCall<GetSortOptionsResponse>(
            () => this.restSvc.GetSortOptions(request, this.GetContext())
        );
    }

    public SensorReportSchedule(request: SensorReportScheduleRequest): Observable<SensorReportScheduleResponse> {
        return this.ExecApiCall<SensorReportScheduleResponse>(
            () => this.restSvc.SensorReportSchedule(request, this.GetContext())
        );
    }

    public GetShipmentData(request: ShipmentRequest): Observable<GetShipmentResponse> {
        return this.ExecApiCall<GetShipmentResponse>(
            () => this.restSvc.GetShipmentData(request, this.GetContext())
        );
    }

    public GetMultiTripData(request: GetMultiTripRequest): Observable<GetMultiTripResponse> {
        return this.ExecApiCall<GetMultiTripResponse>(
            () => this.restSvc.GetMultiTripData(request, this.GetContext())
        );
    }

    public GetReverseGeoCode(request: GetReverseGeocodeAddressRequest): Observable<GetTrackerStatusResponse> {
        if (this.IsUnAuthenticated) {
            request.TrackerIds = sessionStorage.getItem(environment.unAuthTrackers).split(',');
        }
        return this.ExecApiCall<GetTrackerStatusResponse>(
            () => this.restSvc.GetReverseGeoCode(request, this.GetContext())
        );
    }

    public GetShipmentTempInformation(request: ShipmentRequest): Observable<GetShipmentResponse> {
        return this.ExecApiCall<GetShipmentResponse>(
            () => this.restSvc.GetShipmentTempInformation(request, this.GetContext())
        );
    }

    public GetLinkedCustomers(): Observable<GetLinkedCustomerListResponse> {
        return this.ExecApiCall<GetLinkedCustomerListResponse>(
            () => this.restSvc.GetLinkedCustomers(this.GetContext())
        );
    }

    public CopyDVShipmentCheck(request: ValidateDVShipmentRequest): Observable<ValidateDVShipmentResponse> {
        return this.ExecApiCall<ValidateDVShipmentResponse>(
            () => this.restSvc.CopyDVShipmentCheck(request, this.GetContext())
        );
    }

    public GetBusinessRules(request: GetBusinessRulesRequest): Observable<GetBusinessRulesResponse> {
        return this.ExecApiCall<GetBusinessRulesResponse>(
            () => this.restSvc.GetBusinessRules(request, this.GetContext())
        );
    }
    public GetTrackerSerialsListWithNoTrip(): Observable<GetTrackerSerialsListWithNoTripResponse> {
        return this.ExecApiCall<GetTrackerSerialsListWithNoTripResponse>(
            () => this.restSvc.GetTrackerSerialsListWithNoTrip(this.GetContext())
        );
    }

    public ChangePassword(request: ChangePasswordRequest): Observable<ChangePasswordResponse> {
        return this.ExecApiCall<ChangePasswordResponse>(
            () => this.restSvc.ChangePassword(request, this.GetContext())
        );
    }

    public AddressSearch(request: AddressSearchRequest): Observable<AddressSearchResponse> {
        return this.ExecApiCall<AddressSearchResponse>(
            () => this.restSvc.AddressSearch(request, this.GetContext())
        );
    }

    public AddressDetails(request: AddressDetailsRequest): Observable<AddressDetailsResponse> {
        return this.ExecApiCall<AddressDetailsResponse>(
            () => this.restSvc.AddressDetails(request, this.GetContext())
        );
    }

    public EmailShipmentDetail(request: ShipmentRequest): Observable<ChangePasswordResponse> {
        return this.ExecApiCall<ErrorResponse>(
            () => this.restSvc.EmailShipmentDetail(request, this.GetContext())
        );
    }

    public getSavedRoutes(request: GetSavedRoutesRequest): Observable<GetSavedRoutesResponse> {
        return this.ExecApiCall<GetSavedRoutesResponse>(
            () => this.restSvc.getSavedRoutes(request, this.GetContext())
        );
    }

    public GetLocalizedString(request: FormatLocalizedStringRequest): Observable<FormatLocalizedStringResponse> {
        return this.ExecApiCall<FormatLocalizedStringResponse>(
            () => this.restSvc.GetLocalizedString(request, this.GetContext()),
            true
        );
    }

    public GetTripStartOptions(): Observable<GetTripStartOptionResponse> {
        return this.ExecApiCall<GetTripStartOptionResponse>(
            () => this.restSvc.GetTripStartOptions(this.GetContext()),
            true
        );
    }

    public GetTripEndOptions(): Observable<GetTripEndOptionResponse> {
        return this.ExecApiCall<GetTripEndOptionResponse>(
            () => this.restSvc.GetTripEndOptions(this.GetContext()),
            true
        );
    }

    public RouteDeviationExceptions(request: RouteDeviationReportRequest, extraHeaders: ExportTrackerStateReportHeaders)
        : Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.RouteDeviationExceptions(request, extraHeaders, this.GetContext()),
            true
        );
    }

    public GetRouteDeviationReport(request: GetRouteDeviationsRequestModel)
        : Observable<GetRouteDeviationReportResponseModel> {
        return this.ExecApiCall<any>(
            () => this.restSvc.getRouteDeviationReport(request, this.GetContext()),
            true
        );
    }

    public deleteRoute(request: SaveRouteRequest): Observable<SaveRouteResponse> {
        return this.ExecApiCall<SaveRouteResponse>(
            () => this.restSvc.DeleteRoute(request, this.GetContext()),
            true
        );
    }

    public GetLocationColorList(): Observable<getLocationColorListResponse> {
        return this.ExecApiCall<getLocationColorListResponse>(
            () => this.restSvc.GetLocationColorList(this.GetContext())
        );
    }

    public UnAuthCreateShipment(request: UCreateShipmentRequest): Observable<UCreateShipmentResponse> {
        return this.ExecApiCall<UCreateShipmentResponse>(
            () => this.restSvc.UnAuthCreateShipment(request, this.GetContext())
        );
    }

    public GetTrackerSensors(request: ShipmentRequest): Observable<GetTrackerResponse> {
        return this.ExecApiCall<GetTrackerResponse>(
            () => this.restSvc.GetTrackerSensors(request, this.GetContext())
        );
    }

    public EditShipment(request: UpdateShipmentRequest): Observable<UpdateShipmentResponse> {
        return this.ExecApiCall<UpdateShipmentResponse>(
            () => this.restSvc.EditShipment(request, this.GetContext())
        );
    }

    public SetTripSensorRange(request: SetTripSensorRangeRequest): Observable<SetTripSensorRangeResponse> {
        return this.ExecApiCall<SetTripSensorRangeResponse>(
            () => this.restSvc.SetTripSensorRange(request, this.GetContext())
        );
    }

    public DeletePendingTrip(request: CloseTripRequest): Observable<CloseTripResponse> {
        return this.ExecApiCall<CloseTripResponse>(
            () => this.restSvc.DeletePendingTrip(request, this.GetContext())
        );
    }

    public SetTrackerSensorRange(request: SetTrackerSensorRangeRequest): Observable<SetTrackerSensorRangeResponse> {
        return this.ExecApiCall<SetTrackerSensorRangeResponse>(
            () => this.restSvc.SetTrackerSensorRange(request, this.GetContext())
        );
    }

    public GetContactsList(): Observable<GetContactListResponse> {
        return this.ExecApiCall<GetContactListResponse>(
            () => this.restSvc.GetContactsList(this.GetContext())
        );
    }

    public ScheduleReport(request: ScheduleReportRequest): Observable<ScheduleReportResponse> {
        return this.ExecApiCall<ScheduleReportResponse>(
            () => this.restSvc.ScheduleReport(request, this.GetContext())
        );
    }

    public GetScheduledReportList(): Observable<GetScheduledReportsResponse> {
        return this.ExecApiCall<GetScheduledReportsResponse>(
            () => this.restSvc.GetScheduledReportList(this.GetContext())
        );
    }

    public DeleteScheduleReportByID(scheduledReportId: string): Observable<ScheduleReportResponse> {
        return this.ExecApiCall<ScheduleReportResponse>(
            () => this.restSvc.DeleteScheduleReportByID(scheduledReportId, this.GetContext())
        );
    }

    public GetObjectViews(): Observable<GetObjectViewsResponse> {
        return this.ExecApiCall<GetObjectViewsResponse>(
            () => this.restSvc.GetObjectViews(this.GetContext())
        );
    }

    public GetObjectViewArgsList(request: GetObjectViewArgsListRequest): Observable<GetObjectViewArgsListResponse> {
        return this.ExecApiCall<GetObjectViewArgsListResponse>(
            () => this.restSvc.GetObjectViewArgsList(request, this.GetContext())
        );
    }

    public UpdateTripStops(request: SetTripStopRequest): Observable<SetTripStopResponse> {
        return this.ExecApiCall<SetTripStopResponse>(
            () => this.restSvc.UpdateTripStops(request, this.GetContext())
        );
    }

    public GetTripStops(TripId: number): Observable<GetTripStopResponse> {
        return this.ExecApiCall<GetTripStopResponse>(
            () => this.restSvc.GetTripStops(TripId, this.GetContext())
        );
    }

    public SetTrackerNote(request: SetTrackerNoteRequest): Observable<SetTrackerNoteResponse> {
        return this.ExecApiCall<SetTrackerNoteResponse>(
            () => this.restSvc.SetTrackerNote(request, this.GetContext())
        );
    }

    public ShareTracker(request: ShareTrackerRequest): Observable<ShareTrackerResponse> {
        return this.ExecApiCall<ShareTrackerResponse>(
            () => this.restSvc.ShareTracker(request, this.GetContext())
        );
    }

    public GetNearestLocations(request: SetLocationRequest): Observable<SetLocationResponse> {
        return this.ExecApiCall<SetLocationResponse>(
            () => this.restSvc.GetNearestLocations(request, this.GetContext())
        );
    }

    public FindAddressByCoordinates(request: SetLocationRequest): Observable<SetLocationResponse> {
        return this.ExecApiCall<SetLocationResponse>(
            () => this.restSvc.FindAddressByCoordinates(request, this.GetContext())
        );
    }

    public DeleteLocationGeofence(request: DeleteGeofenceRequest): Observable<DeleteGeofenceResponse> {
        return this.ExecApiCall<DeleteGeofenceResponse>(
            () => this.restSvc.DeleteLocationGeofence(request, this.GetContext())
        );
    }

    public GetLocationBoundary(request: GetLocationBoundaryRequest): Observable<GetLocationBoundaryResponse> {
        return this.ExecApiCall<GetLocationBoundaryResponse>(
            () => this.restSvc.GetLocationBoundary(request, this.GetContext())
        );
    }

    public SaveLocationGeofence(request: SetLocationRequest): Observable<SetLocationResponse> {
        return this.ExecApiCall<SetLocationResponse>(
            () => this.restSvc.SaveLocationGeofence(request, this.GetContext())
        );
    }

    public SetContact(request: SetContactRequest): Observable<SetContactResponse> {
        return this.ExecApiCall<SetContactResponse>(
            () => this.restSvc.SetContact(request, this.GetContext())
        );
    }

    public GetLocationContact(request: SaveLocationContactRequest): Observable<GetContactListResponse> {
        return this.ExecApiCall<GetContactListResponse>(
            () => this.restSvc.GetLocationContact(request, this.GetContext())
        );
    }

    public SetLocationContact(request: SaveLocationContactRequest): Observable<SaveLocationContactResponse> {
        return this.ExecApiCall<SaveLocationContactResponse>(
            () => this.restSvc.SetLocationContact(request, this.GetContext())
        );
    }

    public DeleteLocationContact(request: SaveLocationContactRequest): Observable<SaveLocationContactResponse> {
        return this.ExecApiCall<SaveLocationContactResponse>(
            () => this.restSvc.DeleteLocationContact(request, this.GetContext())
        );
    }

    public DeleteLocation(request: DeleteLocationRequest): Observable<DeleteLocationResponse> {
        return this.ExecApiCall<SaveLocationContactResponse>(
            () => this.restSvc.DeleteLocation(request, this.GetContext())
        );
    }

    public GetLinkedShippers(): Observable<GetLinkedCustomerListResponse> {
        return this.ExecApiCall<GetLinkedCustomerListResponse>(
            () => this.restSvc.GetLinkedShippers(this.GetContext())
        );
    }

    public GetShipmentETA(request: ShipmentRequest): Observable<GetShipmentResponse> {
        return this.ExecApiCall<GetShipmentResponse>(
            () => this.restSvc.GetShipmentETA(request, this.GetContext())
        );
    }

    public SetShipmentTemplate(request: CreateShipmentRequest): Observable<SetShipmentTemplateResponse> {
        return this.ExecApiCall<SetShipmentTemplateResponse>(
            () => this.restSvc.SetShipmentTemplate(request, this.GetContext())
        );
    }
    public DeleteShipmentTemplateByID(tripTemplateId: number): Observable<SetShipmentTemplateResponse> {
        return this.ExecApiCall<SetShipmentTemplateResponse>(
            () => this.restSvc.DeleteShipmentTemplateByID(tripTemplateId, this.GetContext())
        );
    }

    public UpdateDashboardTrialSettings(request: DashhboardSettingsRequest): Observable<DomoAuthResponse> {
        return this.ExecApiCall<DomoAuthResponse>(
            () => this.restSvc.UpdateDashboardTrialSettings(request, this.GetContext())
        );
    }

    public UpdateDashboardPremiumSettings(request: DashhboardSettingsRequest): Observable<DashhboardSettingsResponse> {
        return this.ExecApiCall<DashhboardSettingsResponse>(
            () => this.restSvc.UpdateDashboardPremiumSettings(request, this.GetContext())
        );
    }

    public GetDashboards(): Observable<GetDashboardResponse> {
        return this.ExecApiCall<GetDashboardResponse>(
            () => this.restSvc.GetDashboards(this.GetContext())
        );
    }

    public SetDashboard(request: SetDashboardRequest): Observable<SetDashboardResponse> {
        return this.ExecApiCall<SetDashboardResponse>(
            () => this.restSvc.SetDashboard(request, this.GetContext())
        );
    }

    public DeleteDashboard(dashboardId: number): Observable<SetDashboardResponse> {
        return this.ExecApiCall<SetDashboardResponse>(
            () => this.restSvc.DeleteDashboard(dashboardId, this.GetContext())
        );
    }

    public GetCustomerDashboards(): Observable<GetCustomerDashboardsResponse> {
        return this.ExecApiCall<GetCustomerDashboardsResponse>(
            () => this.restSvc.GetCustomerDashboards(this.GetContext())
        );
    }

    public SetCustomerDashboard(request: SetDashboardCustomerRequest): Observable<SetCustomerDashboardResponse> {
        return this.ExecApiCall<SetCustomerDashboardResponse>(
            () => this.restSvc.SetCustomerDashboard(request, this.GetContext())
        );
    }

    public DeleteCustomerDashboard(customerDashboardId: number): Observable<SetCustomerDashboardResponse> {
        return this.ExecApiCall<SetCustomerDashboardResponse>(
            () => this.restSvc.DeleteCustomerDashboard(customerDashboardId, this.GetContext())
        );
    }

    public GetHistoryTrip(request: GetHistoryTripRequest): Observable<GetHistoryTripResponse> {
        return this.ExecApiCall<GetHistoryTripResponse>(
            () => this.restSvc.GetHistoryTrip(request, this.GetContext())
        );
    }

    public GetDomoEmbedToken(access_token: string): Observable<DomoAuthResponse> {
        return this.ExecApiCall<DomoAuthResponse>(
            () => {
                this.domoAccessToken = access_token;
                const context = this.GetContext();
                return this.restSvc.GetDomoEmbedToken(context);
            }
        );
    }

    public GetDomoAccessToken(): Observable<DomoAccessTokenModel> {
        return this.ExecApiCall<DomoAccessTokenModel>(
            () => {
                const context = this.GetContext();
                return this.restSvc.GetDomoAccessToken(context);
            }
        );
    }

    public SetShutDownTracker(request: SetShutDownTrackerRequest): Observable<SetShutDownTrackerResponse> {
        return this.ExecApiCall<SetShutDownTrackerResponse>(
            () => this.restSvc.SetShutDownTracker(request, this.GetContext())
        );
    }

    public GetLoggerReads(deviceSerial: string): Observable<LoggerReadResponse> {
        return this.ExecApiCall<LoggerReadResponse>(
            () => this.restSvc.GetLoggerReads(deviceSerial, this.GetContext())
        );
    }

    public GetLookUp(request: GetLookUpRequest): Observable<LookupResponse> {
        return this.ExecApiCall<LookupResponse>(
            () => this.restSvc.GetLookUp(request, this.GetContext())
        );
    }

    public SetLookUp(request: SetLookUpRequest): Observable<LookupResponse> {
        return this.ExecApiCall<LookupResponse>(
            () => this.restSvc.SetLookUp(request, this.GetContext())
        );
    }

    public GetCalibrationStatement(
        queryParams
    ): Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.getCalibrationStatement(queryParams, this.GetContext())
        );
    }

    public EmailCalibrationStatement(
        request: CalibrationStatement[]
    ): Observable<any> {
        return this.ExecApiCall<any>(
            () => this.restSvc.emailCalibrationStatement(request, this.GetContext())
        );
    }

    private ExecApiCall<T>(
        apiCall: () => Observable<HttpResponse<T>>,
        supressAuthRefresh: boolean = false
    ): Observable<T> {
        if (this.IsUserSignedIn != null && !this.IsUserSignedIn) {
            this.router.navigate(['signin']);
            return EMPTY;
        }
        // to restrict API call when the AuthToken Expires
        else if (!this.IsUnAuthenticated && !supressAuthRefresh && !this.authToken) {
            this.onAuthFailure();
            return EMPTY;
        }

        return apiCall().pipe(
            failoverIf<HttpResponse<T>>(
                n => !this.IsUnAuthenticated && !supressAuthRefresh && !this.authToken,
                e => !supressAuthRefresh && e.status && e.status === 401,
                () => {
                    if (this.IsUserSignedIn) {
                        this.onAuthFailure()
                    } else {
                        this.authStateSvc.signOut();
                        this.router.navigate(['signin']);
                    }
                    return EMPTY;
                },
                this
            ),
            map<HttpResponse<T>, T>(n => n.body),
            mapError<T>(
                e => toIErrorInfo(e)
            )
        );
    }

    private GetContext(): OversightApiContext {
        const context = new OversightApiContext();
        context.locale = this.locale;
        context.languageCode = this.langCode;
        context.countryCode = this.cntryCode;
        context.isUnAuthUserSettings = null;
        const localStorageData = JSON.parse(localStorage.getItem(this.IsUnAuthenticated ? environment.unAuthUserSettings : environment.AuthUserSettings));
        if (localStorageData) {
            context.uTemperatureUnits = localStorageData.uTemperatureUnits;
            context.uDistanceUnits = localStorageData.uDistanceUnits;
            context.uDateFormatEnum = localStorageData.uDateFormatEnum;
            context.uTimeFormatEnum = localStorageData.uTimeFormatEnum;
            context.uTimeZoneID = localStorageData.uTimeZoneID;
            context.isUnAuthUserSettings = "true";
        } else {
            context.isUnAuthUserSettings = "false";
        }
        //}
        if (!this.IsUnAuthenticated) {
            context.authToken = this.authToken;
            context.customerToken = this.custToken;
            context.rememberMe = this.rememberMe;
            context.refreshToken = this.refreshToken;
            context.domoAccessToken = this.domoAccessToken;
        }

        return context;
    }

    private onAuthFailure() {
        this.authStateSvc.signOut();
        window.location.href = '/authexpired';
    }

    private parseLocale(locale: string) {
        this.langCode = null;
        this.cntryCode = null;

        if (locale) {
            const codes = locale.split('-');

            if (codes.length > 1) {
                this.langCode = codes[0];
                this.cntryCode = codes[1];
            } else if (codes.length > 0) {
                this.langCode = codes[0];
                this.cntryCode = null;
            }
        }
    }
}


