import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface'; 
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as pageSizeActions from 'app-modules/core/store/actions/page-size.actions';
import { IPageSizeState } from '../models/page-size-state.interface';
import { FormattedStrings } from 'emr-ng-shared';

@Injectable()
export class PageSizeStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public pageSizeStateSelector = ((state: IAppState) => state.pageSize);
    public isLoadRequiredSelector = createSelector(
        this.pageSizeStateSelector,
        (state: IPageSizeState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.pageSizeStateSelector,
        (state: IPageSizeState) => state.isLoading
    );
    public pageSizeListSelector = createSelector(
        this.pageSizeStateSelector,
        (state: IPageSizeState) => state.pageSizeList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public pageSize$ = this.store.select(this.pageSizeListSelector).pipe();

    // Actions
    public loadPageSize() {
        this.store.dispatch(new pageSizeActions.LoadPageSize());
    }

    public loadPageSizeSuccess(pageSizeList: IListInfo<FormattedStrings>) {
        this.store.dispatch(new pageSizeActions.LoadPageSizeSuccess(pageSizeList));
    }

    public loadPageSizeError(message: string) {
        this.store.dispatch(new pageSizeActions.LoadPageSizeError(message));
    }

    public cancelLoadPageSize() {
        this.store.dispatch(new pageSizeActions.CancelPageSize());
    }
}
