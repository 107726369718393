import { Action } from '@ngrx/store';

import { CoolDownTypeInfo } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadCoolDownTypes implements Action {
    readonly type = actionIndex.LOAD_COOL_DOWN_TYPES;
}

export class LoadCoolDownTypesSuccess implements Action {
    readonly type = actionIndex.LOAD_COOL_DOWN_TYPES_SUCCESS;
    constructor(public coolDownTypeList: IListInfo<CoolDownTypeInfo>) { }
}

export class LoadCoolDownTypesError implements Action {
    readonly type = actionIndex.LOAD_COOL_DOWN_TYPES_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadCoolDownTypes implements Action {
    readonly type = actionIndex.CANCEL_LOAD_COOL_DOWN_TYPES;
}

