import { IPakSenseAuthState } from 'app-modules/core/store/models/paksense-auth-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as pakSenseAuthActions from 'app-modules/core/store/paksense-auth/ps-auth.actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';

type Actions =
        pakSenseAuthActions.PakSenseSignIn |
        pakSenseAuthActions.PakSenseSignInSuccess |
        pakSenseAuthActions.PakSenseSignInError |
        authActions.SignOut;

export function PakSenseAuthReducer(state: IPakSenseAuthState = initialState.pakSenseAuth, action: Actions): IPakSenseAuthState {
    switch (action.type) {
        case (actionIndex.SIGN_OUT):
            return {
                authenticated: false,
                authToken: null,
                username: null,
                displayName: null,
                error: null
            };

        case (actionIndex.PAK_SENSE_SIGN_IN):
            return {
                ...state,
                authenticated: false,
                error: null
            };

        case (actionIndex.PAK_SENSE_SIGN_IN_SUCCESS):
            return {
                ...state,
                authenticated: !!action.authToken,
                authToken: action.authToken,
                username: action.username,
                displayName: action.displayName,
                error: null
            };

        case (actionIndex.PAK_SENSE_SIGN_IN_ERROR):
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
}
