import { NgModule } from '@angular/core';
import { CoreModule } from 'app-modules/core/core.module';
import { CommonModule } from '@angular/common';

import { ShipmentDetailComponent } from 'app-modules/shipment-detail/components/shipment-detail/shipment-detail.component';
import { ShipmentDetailRoutingModule } from 'app-modules/shipment-detail/shipment-detail-routing.module';
import { AlertSummaryComponent } from 'app-modules/shipment-detail/components/alert-summary/alert-summary.component';
import { HistoricalShipmentComponent } from 'app-modules/shipment-detail/components/historical-shipment/historical-shipment.component';
import { TimelineComponent } from 'app-modules/shipment-detail/components/timeline/timeline.component';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { ShipmentRouteModule } from 'app-modules/shipment-route/shipment-route.module';
import { ShipmentChartModule } from 'app-modules/shipment-chart/shipment-chart.module';
import { ShowDistanceModule } from 'app-modules/show-distance/show-distance.module';
import { SensorStateComponent } from 'app-modules/shipment-detail/components/sensor/sensor-chart.component';
import { MinMaxChartValuesComponent } from 'app-modules/shipment-detail/components/set-minmax/minmax-chart-values.component';
import { SensorChartResponseComponent } from './components/sensor/sensor-chart-response/sensor-chart-response.component';
import { SensorChartDataService } from './services/sensor-chart-data.service';
import { TagsComponent } from './components/tags/tags.component';
import { TreeGridModule } from '@syncfusion/ej2-angular-treegrid';

@NgModule({
  imports: [
    CommonModule,
    ShipmentDetailRoutingModule,
    EmrNgControlsModule,
    CoreModule,
    ShipmentRouteModule,
    ShipmentChartModule,
    ShowDistanceModule,
    TreeGridModule
  ],
  providers: [
    SensorChartDataService
  ],
  declarations: [
    ShipmentDetailComponent,
    AlertSummaryComponent,
    HistoricalShipmentComponent,
    TimelineComponent,
    SensorStateComponent,
    MinMaxChartValuesComponent,
    SensorChartResponseComponent,
    TagsComponent
  ],
  exports: [
    ShipmentDetailComponent
  ]
  // entryComponents: [
  //   SensorChartResponseComponent
  // ]
})
export class ShipmentDetailModule { }

