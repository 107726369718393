import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportLocationComponent } from './components/import-locations/import-location.component';

const routes: Routes = [
  { path: '', component: ImportLocationComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocationManagemntRoutingModule { }
