import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GetHistoryTripRequest } from 'emr-ng-shared';
import { ShowViewLogPopup } from 'app-modules/core/models/show-view-logs-popup.model';
import { ShowViewlogPopupComponent } from '../components/show-viewlog-popup/show-viewlog-popup.component';

@Injectable()
export class ShowViewLogService extends ShowViewLogPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenShowViewLogPopup(getHistoryTripRequest: GetHistoryTripRequest): void {
        const initialState = {
            getHistoryTripRequest: getHistoryTripRequest
        };

        this.bsModalRef = this.modalSvc.show(
            ShowViewlogPopupComponent,
            {
                initialState: initialState,
                class: 'min-width-800 modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}


