import { TripStopTypeCode } from "emr-ng-shared";

export class TripStopModel {
    LocationId?: number;
    AirportArrival?: boolean;
    AirportDeparture?: boolean;
    ArrivalLocationId?: number;
    IsEdit = false;
    LocationName?: string;
    ArrivalLocationName?: string;
    IsInValid = false;
    IsAdd?= false;
    IsDelete?= false;
    SequenceID?: number;
    Index?: number;
    TripStopTypeCode?: TripStopTypeCode = 0;
}
