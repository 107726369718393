import { Component, OnInit, TemplateRef, ViewEncapsulation, ViewChild, OnDestroy, Optional, EventEmitter } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import {
    UCreateShipmentRequest, Address, SetLocationRequest, TripState,
    SensorRangeInfo, SensorType, ShipmentRequest, Sensor, LocationInfo, SetLocationResponse, Customer, ILatLong, GetBusinessRulesRequest, BusinessRuleType
} from 'emr-ng-shared';
import { environment } from 'environments/environment';
import { EmrUtilService, DateTimeObject } from 'emr-ng-shared';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';
import { take, tap } from 'rxjs/operators';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import * as _ from 'lodash';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { SensorRangeComponent, SensorRangeEventArgs } from 'app-modules/core/components/sensor-range/components/sensor-range/sensor-range.component';
import { LocationService } from 'app-modules/core/services/location.service';
import {
    label_enter_shipment_name, label_po, label_shipment_name, po_required, select_distinct_origin_destination, shipment_name_required,
    select_origin_from_list, select_destination_from_list
} from 'app-modules/core/consts/localization';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';

export class LocalTracker {
    get text() { return this.serialNumber; }
    constructor(private serialNumber: string) { }
}


@Component({
    selector: 'app-u-create-shipment',
    templateUrl: './u-create-shipment.component.html',
    styleUrls: ['./u-create-shipment.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class UCreateShipmentComponent implements OnInit, OnDestroy {
    errorMessage: string;
    debugErrorMessage: string;
    hasError: boolean;
    modalRef: BsModalRef;
    locModalRef: BsModalRef;
    previousEnteredValue: string;
    showCO2Sensor = false;
    showProbSensor = false;
    showHumiditySensor = false;
    sensorTypes: Sensor[];
    HighCriticalTempC: number;
    HighCriticalHumidity: number;
    HighCriticalCO2: number;
    HighCriticalProbeC: number;
    LowCriticalTempC: number;
    LowCriticalHumidity: number;
    LowCriticalCO2: number;
    LowCriticalProbeC: number;
    HighCriticalTempF: number;
    LowCriticalTempF: number;
    HighCriticalProbeF: number;
    LowCriticalProbeF: number;
    getTrackerSubscription: Subscription;
    unAuthSubscription: Subscription;
    shipmentListSubscription: Subscription;
    prefSubscription: Subscription;
    sensorRangeList$: any;
    isOriginSelected: boolean;
    isDestinationSelected: boolean;

    TemperatureRangeId: number;
    HumiditySensorId: number;
    CarbonDioxideSensorId: number;
    ProbSensorId: number;
    sensorType;
    selectedIndex = null;
    selectedOriginLocationName = null;
    selectedDestinationLocationName = null;
    selectOriginDistinctLocation: string = '';
    selectDestinationDistinctLocation: string = '';
    selectedOriginCoordinates: ILatLong | null = null;
    selectedDestCoordinates: ILatLong | null = null;
    isRequired = false;

    constructor(
        @Optional() public bsMainModalRef: BsModalRef,
        private uService: UnAuthService,
        private createSvc: CreateShipmentService,
        private utilSvc: EmrUtilService,
        private modalService: BsModalService,
        private prfService: PreferenceService,
        private modalSvc: BsModalService,
        private shipSvc: ShipmentService,
        private locationSvc: LocationService,
        private businessRuleSvc: BusinessRulesService
    ) {
        const bingApiKey = environment.bingApiKey;
        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        const locale = localeResolver.resolveLocale();
    }

    @ViewChild(NgForm) uShipmentForm: NgForm;
    @ViewChild('createShipmentResponseTemplate') public createShipmentResponseTemplate: TemplateRef<any>;
    @ViewChild('duplicateLocTemplate') public duplicateLocTemplate: TemplateRef<any>;

    bsModalRef: BsModalRef;
    submitResponseModalRef: BsModalRef;

    shipment = new UCreateShipmentRequest();
    startTime: Date;
    defaultMinTime: Date = new Date(2008, 0, 1);
    endTime: Date;
    serialNumber: string;

    trackerList: any[];
    dateTimeObject: DateTimeObject = new DateTimeObject();
    isInValidSerialNumber = false;
    tempUnitString: string;

    controlColumns = 9;

    validateLocationSubscription: Subscription;
    nearestLocations: LocationInfo[];
    showNearestLocations = false;
    fullAddress = '';
    newLocRequest = new SetLocationRequest();
    uCustomerID: number;
    selectedLocationType: string;
    resetAddressField = false;
    unAuthCustomerSub: Subscription;
    labelShipmentName = label_shipment_name;
    labelEnterShipmentName = label_enter_shipment_name;
    labelShipmentNameRequired = shipment_name_required;

    ngOnInit() {

        this.unAuthCustomerSub = this.uService.unAuthCustomer$.pipe(take(1)).subscribe(k => {
            this.uCustomerID = k.CustomerId;
        });
        this.loadBusinessRules();

        this.sensorRangeList$ = this.createSvc.sensorRangeList$;
        this.prefSubscription = this.prfService.preference$.pipe(take(1)).subscribe(k => {
            const date = new DateTimeObject();
            date.dateFormat = k.dateFormatString.toUpperCase();
            date.timeFormat = k.timeFormatString;
            if (Number(k.timeFormatEnum) === 1 ||
                !date.timeFormat) {
                date.timeFormat = 'hh:mm a';
            }
            date.showMeridian = k.timeFormatEnum !== 2;
            date.dateTimeFormat = date.dateFormat + ', ' + date.timeFormat;
            this.dateTimeObject = date;
            this.tempUnitString = k.temperatureUnits;
        });
        this.trackerList = [];
        const trackers = sessionStorage.getItem(environment.unAuthTrackers);
        if (trackers) {
            const trackersList = trackers.split(',');
            if (trackersList.length > 0) {
                trackersList.map(l => this.trackerList.push(new LocalTracker(l)));
            }
        }
        this.sensorType = SensorType;
        // For create shipment auto suggestion Single trip trackers which don't have trips and
        // Multi trackers which had shipment with activated not assigned status and
        // Multi trackers which has all shipments as completed
        this.shipmentListSubscription = this.shipSvc.shipmentList$.pipe(take(1)).subscribe(k => {
            if (k && k.list && k.list.length === 0) {
                this.isInValidSerialNumber = true;
                return;
            }
            k.list.map(a => {
                this.trackerList = this.trackerList.filter(b => b.serialNumber !== a.trackerId);
                if (!a.tripId && !a.isMultiTrip) {
                    if (a.tripStateCode === TripState.Default) {
                        this.trackerList.push(new LocalTracker(a.trackerId));
                    }
                } else if (a.isMultiTrip && a.tripStateCode === TripState.Default) {
                    this.trackerList.push(new LocalTracker(a.trackerId));
                }
            });
            // Multi trackers which has all shipments as completed
            const list = k.list.filter(a => a.isMultiTrip);
            if (list && list.length > 0) {
                _.chain(list).groupBy('trackerId').map((v, i) => {
                    if (v && v.length > 0) {
                        const data = v.filter(a => a.tripStateCode !== TripState.Completed);
                        if (data && data.length === 0) {
                            this.trackerList.push(new LocalTracker(v[0].trackerId));
                        }
                    }
                }).value();
            }
        });
    }

    ngOnDestroy() {
        if (this.getTrackerSubscription) {
            this.getTrackerSubscription.unsubscribe();
            this.getTrackerSubscription = null;
        }
        if (this.unAuthSubscription) {
            this.unAuthSubscription.unsubscribe();
            this.unAuthSubscription = null;
        }
        if (this.shipmentListSubscription) {
            this.shipmentListSubscription.unsubscribe();
            this.shipmentListSubscription = null;
        }
        if (this.prefSubscription) {
            this.prefSubscription.unsubscribe();
            this.prefSubscription = null;
        }
        if (this.validateLocationSubscription) {
            this.validateLocationSubscription.unsubscribe();
            this.validateLocationSubscription = null;
        }
    }

    onSerialNumberChange(e) {
        if (e.item.trackerId !== undefined && this.serialNumber === e.item.trackerId) {
            this.isInValidSerialNumber = false;
            this.assignSelectedTracker(e.item);
        } else {
            this.isInValidSerialNumber = true;
        }
    }

    assignSelectedTracker(e) {
        this.shipment.TrackerId = e.text;
        this.GetTrackerSensorsBySerial();
    }

    onSerialNumberSelected(e) {
        this.isInValidSerialNumber = false;
        this.assignSelectedTracker(e.item);
    }


    onAddressSelected(address: Address, type: string) {
        this.resetAddressField = false;

        this.newLocRequest = new SetLocationRequest();
        switch (type) {
            case 'destination':
                if (this.shipment.DestinationLocationId) {
                    if (this.shipment.DestinationLocationName === this.selectedDestinationLocationName) {
                        this.shipment.DestinationLocationName = null;
                        this.selectedDestinationLocationName = null;
                    }
                    this.shipment.DestinationLocationId = null;
                };
                this.isDestinationSelected = true;
                this.newLocRequest.Name = this.shipment.DestinationLocationName;
                break;
            case 'origin':
                if (this.shipment.OriginLocationId) {
                    if (this.shipment.OriginLocationName === this.selectedOriginLocationName) {
                        this.shipment.OriginLocationName = null;
                        this.selectedOriginLocationName = null;
                    }
                    this.shipment.OriginLocationId = null;
                }
                this.newLocRequest.Name = this.shipment.OriginLocationName;
                this.isOriginSelected = true;
                break;
        }

        this.showNearestLocations = false;
        this.nearestLocations = [];
        this.selectedLocationType = type;
        this.newLocRequest.FullAddress = address?.FormattedAddress ? address?.FormattedAddress : null;
        this.newLocRequest.Address1 = address?.AddressLine1 ? address?.AddressLine1 : null;
        this.newLocRequest.City = address?.City ? address?.City : null;
        this.newLocRequest.State = address?.StateDescription ? address?.StateDescription : null;
        this.newLocRequest.ZipCode = address?.PostalCode ? address?.PostalCode : null;
        this.newLocRequest.Country = address?.CountryCode ? address?.CountryCode : null;
        this.newLocRequest.Latitude = address?.Latitude ? address?.Latitude : null;
        this.newLocRequest.Longitude = address?.Longitude ? address?.Longitude : null;
        this.newLocRequest.ValidationRequired = false;
        this.newLocRequest.CustomerId = this.uCustomerID;

        this.validateLocationSubscription = this.locationSvc.validateLocation(this.newLocRequest).subscribe(
            n => {
                if (n) {
                    this.validateLocationSuccess(n, type);
                }
            },
            (e: IErrorInfo) => this.openResponsePopUp(e.code, e.message, e.debugMessage)
        );

    }

    validateLocationSuccess(response: SetLocationResponse, type: string) {
        this.validateLocationSubscription?.unsubscribe();
        this.showNearestLocations = false;
        this.nearestLocations = [];
        if (response.LocationId && response.LocationId > 0) {
            switch (type) {
                case 'destination':
                    this.shipment.DestinationLocationId = response.LocationId;
                    this.selectedDestCoordinates = { latitude: this.newLocRequest.Latitude, longitude: this.newLocRequest.Longitude };
                    break;
                case 'origin':
                    this.shipment.OriginLocationId = response.LocationId;
                    this.selectedOriginCoordinates = { latitude: this.newLocRequest.Latitude, longitude: this.newLocRequest.Longitude };
                    break;
            }
        } else if (response.LocationList && response.LocationList.length > 0) {
            this.showNearestLocations = true;
            this.nearestLocations = response.LocationList;
            this.selectedIndex = null;
            switch (type) {
                case 'destination':
                    this.selectedDestCoordinates = null;
                    break;
                case 'origin':
                    this.selectedOriginCoordinates = null;
                    break;
            }
            this.locModalRef = this.modalSvc.show(this.duplicateLocTemplate, {
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true,
                keyboard: false  // Disable Close on ESC
            });
        } else if (response.IsUniqueLocation) {
            this.SetNewLocation(type);
        }
        this.validateDistinctLocation();
    }

    private validateDistinctLocation() {
        this.updateAddressValidation('');
        if (!this.shipment.DestinationLocationId &&
            !this.shipment.OriginLocationId &&
            !this.selectedDestCoordinates &&
            !this.selectedOriginCoordinates) {
            return;
        }

        if (this.shipment.DestinationLocationId &&
            this.shipment.OriginLocationId &&
            this.shipment.DestinationLocationId == this.shipment.OriginLocationId) {
            this.updateAddressValidation(select_distinct_origin_destination);
            return;
        } else if (
            this.selectedDestCoordinates &&
            this.selectedOriginCoordinates &&
            this.CoordEquals(this.selectedDestCoordinates, this.selectedOriginCoordinates)) {
            this.updateAddressValidation(select_distinct_origin_destination);
            return;
        }
    }

    private updateAddressValidation(message: string) {
        this.selectOriginDistinctLocation = message;
        this.selectDestinationDistinctLocation = message;
    }

    private SetNewLocation(type: string) {
        switch (type) {
            case 'destination':
                this.shipment.DestinationLocationAddress = this.newLocRequest.FullAddress;
                this.selectedDestCoordinates = { latitude: this.newLocRequest.Latitude, longitude: this.newLocRequest.Longitude };
                break;
            case 'origin':
                this.shipment.OriginLocationAddress = this.newLocRequest.FullAddress;
                this.selectedOriginCoordinates = { latitude: this.newLocRequest.Latitude, longitude: this.newLocRequest.Longitude };
                break;
        }
    }

    validateLocationFailure(error: IErrorInfo) {
        // this.error = error;
        console.log(error);
        if (this.validateLocationSubscription) {
            this.validateLocationSubscription.unsubscribe();
            this.validateLocationSubscription = null;
        }
    }

    ResetForm() {
        this.shipment = new UCreateShipmentRequest();
        this.uShipmentForm.resetForm(this.shipment);
        setTimeout(() => {
            this.resetAddressField = true;
        }, 200);
        this.serialNumber = null;
        this.isInValidSerialNumber = false;
        this.previousEnteredValue = null;
        this.showCO2Sensor = this.showProbSensor = this.showHumiditySensor = false;
    }



    NoResultsForSerialNumber(e) {
        if (e) {
            this.isInValidSerialNumber = true;
        } else {
            this.isInValidSerialNumber = false;
        }
    }

    onCreateShipmentSave() {
        if (!this.isOriginSelected) {
            this.selectOriginDistinctLocation = select_origin_from_list;
        }
        if (!this.isDestinationSelected) {
            this.selectDestinationDistinctLocation = select_destination_from_list;
        }
        if (this.uShipmentForm.valid && !this.isInValidSerialNumber && !this.selectDestinationDistinctLocation && !this.selectOriginDistinctLocation) {
            this.getCreateShipmentRequest();
            this.unAuthSubscription = this.uService.UnAuthCreateShipment(this.shipment).pipe(take(1)).subscribe(k => {
                if (k?.OriginLocationId) {
                    this.shipment.OriginLocationId = k.OriginLocationId;
                }
                if (k?.DestinationLocationId) {
                    this.shipment.DestinationLocationId = k.DestinationLocationId;
                }
                this.openResponsePopUp(k.ErrorCode, k.LocalizedErrorMessage);
                // this.openResponsePopUp(k.ErrorCode, k.ErrorDescription);
            }, (e: IErrorInfo) => this.openResponsePopUp(e.code, e.message, e.debugMessage));
        }
    }

    getCreateShipmentRequest() {
        this.shipment.TripEstimatedStart = this.utilSvc.DateFormatLocaleChange(this.startTime);
        this.shipment.TripEstimatedEnd = this.utilSvc.DateFormatLocaleChange(this.endTime);
        this.shipment.SensorRangeInfo = [];
        this.sensorTypes.forEach(k => {
            const sensorRangeInfo = new SensorRangeInfo();
            sensorRangeInfo.TypeCode = k.Type;
            switch (k.Type) {
                case SensorType.Temperature:
                    sensorRangeInfo.SensorRangeId = this.TemperatureRangeId ? this.TemperatureRangeId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
                case SensorType.Humidity:
                    sensorRangeInfo.SensorRangeId = this.HumiditySensorId ? this.HumiditySensorId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
                case SensorType.CarbonDioxide:
                    sensorRangeInfo.SensorRangeId = this.CarbonDioxideSensorId ? this.CarbonDioxideSensorId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
                case SensorType.ProbeTemp:
                    sensorRangeInfo.SensorRangeId = this.ProbSensorId ? this.ProbSensorId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
            }
        });
    }

    openResponsePopUp(code: number, errorMessage = null, debugErrorMessage = null) {
        this.hasError = code !== 0;
        this.errorMessage = errorMessage;
        this.debugErrorMessage = debugErrorMessage;
        this.modalRef = this.modalService.show(this.createShipmentResponseTemplate, {
            class: 'modal-sm modal-dialog-centered',
            ignoreBackdropClick: true,
            keyboard: false
        });
    }

    onResponseOkClick() {
        this.modalRef.hide();
        if (!this.hasError) {
            this.bsMainModalRef.hide();
        }
    }

    GetTrackerSensorsBySerial() {
        if (this.serialNumber === this.previousEnteredValue) {
            return;
        }
        this.previousEnteredValue = this.serialNumber;
        this.showCO2Sensor = this.showProbSensor = this.showHumiditySensor = false;
        const request = new ShipmentRequest();
        request.GlobalDeviceId = this.serialNumber;
        this.getTrackerSubscription = this.uService.GetTrackerSensorsBySerial(request).pipe(take(1)).subscribe(n => {
            if (n && n.Sensors && n.Sensors.length > 0) {
                this.sensorTypes = n.Sensors;
                this.showCO2Sensor = n.Sensors.find(k => k.Type === SensorType.CarbonDioxide) !== undefined;
                this.showProbSensor = n.Sensors.find(k => k.Type === SensorType.ProbeTemp) !== undefined;
                this.showHumiditySensor = n.Sensors.find(k => k.Type === SensorType.Humidity) !== undefined;
            }
        });
    }

    C2FConversion(value: number, model: string) {
        if (value) {
            this[model] = Math.round(((value * 9 / 5) + 32) * 100) / 100;
        } else {
            this[model] = null;
        }
    }
    F2CConversion(value: number, model: string) {
        if (value) {
            this[model] = Math.round(((value - 32) * 5 / 9) * 100) / 100;
        } else {
            this[model] = null;
        }
    }

    onNumberPaste(event: any) {
        const value = event.clipboardData.getData('text');
        if (value) {
            setTimeout(() => {
                this.serialNumber = value.trim();
            });
        }
    }

    openAddSensorRangeModal(type) {
        this.bsModalRef = this.modalSvc.show(
            SensorRangeComponent,
            {
                initialState: { SensorType: type },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true,
                keyboard: false
            }
        );

        this.bsModalRef.content.addSensorRange.subscribe(n => this.onAddSensorRange(n));
    }

    onAddSensorRange(args: SensorRangeEventArgs) {
        setTimeout(() => {
            switch (args.request.SensorTypeCode) {
                case SensorType.Temperature:
                    this.TemperatureRangeId = args.request.SensorRangeId;
                    break;
                case SensorType.Humidity:
                    this.HumiditySensorId = args.request.SensorRangeId;
                    break;
                case SensorType.CarbonDioxide:
                    this.CarbonDioxideSensorId = args.request.SensorRangeId;
                    break;
                case SensorType.ProbeTemp:
                    this.ProbSensorId = args.request.SensorRangeId;
                    break;
            }
        }, 50);
        this.bsModalRef.hide();
    }

    onContinueClick() {
        if (this.selectedIndex) {
            let selectedLocation = this.nearestLocations[this.selectedIndex - 1];
            switch (this.selectedLocationType) {
                case 'destination':
                    this.shipment.DestinationLocationName = selectedLocation.LocationName;
                    this.selectedDestinationLocationName = selectedLocation.LocationName;
                    this.shipment.DestinationLocationId = selectedLocation.LocationId;
                    break;
                case 'origin':
                    this.shipment.OriginLocationName = selectedLocation.LocationName;
                    this.selectedOriginLocationName = selectedLocation.LocationName;
                    this.shipment.OriginLocationId = selectedLocation.LocationId;
                    break;
            }
        }
        else {
            this.SetNewLocation(this.selectedLocationType);
        }

        this.validateDistinctLocation();
        this.selectedLocationType = null;
        this.locModalRef.hide();
    }

    private CoordEquals(coord1: ILatLong, coord2: ILatLong) {
        return this.decimalCompare(coord1.latitude, coord2.latitude, 4) &&
            this.decimalCompare(coord1.longitude, coord2.longitude, 4);
    }

    private decimalCompare(d1: number, d2: number, roundOff: number = 4): boolean {
        return (d1.toFixed(roundOff) === d2.toFixed(roundOff));
    }

    loadBusinessRules() {
        const businessRulesListSubscription = this.businessRuleSvc.businessRulesList$.subscribe(n => {
            if (n.list && n.list.length > 0) {
                const value = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowPONumberInsteadOfShipmentName)?.Enabled;
                this.isRequired = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.EnableCreateShipmentOptionalFields)?.Enabled;
                this.updateLabels(value);
                businessRulesListSubscription?.unsubscribe();
            }
        });
    }

    updateLabels(value: boolean) {
        this.labelShipmentName = value ? label_po : label_shipment_name;
        this.labelEnterShipmentName = value ? label_po : label_enter_shipment_name;
        this.labelShipmentNameRequired = value ? po_required : shipment_name_required;
    }

    onAddressBlur(isAddressSelected: boolean, type: string) {
        if (!isAddressSelected) {
            switch (type) {
                case 'destination':
                    this.shipment.DestinationLocationAddress = null;
                    this.selectedDestCoordinates = null;
                    this.isDestinationSelected = false;
                    break;
                case 'origin':
                    this.shipment.OriginLocationAddress = null;
                    this.selectedOriginCoordinates = null;
                    this.isOriginSelected = false;
            }
        }
    }
}
