<div class="row m-0  justify-content-md-center">
    <div class="col-md-6 content-wrapper align-items-right pt-5" *ngIf="!IsFromAuth">
        <app-sign-in-form *ngIf="!externalAuthToken" [settings]="{hideRememberMe: false}"
            (submitted)="onSignInSubmitted($event)" [error]="error"></app-sign-in-form>
    </div>
    <div class="col-md-6 content-wrapper align-items-left pt-5">

        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8 col-8{{IsFromAuth?' offset-md-2':''}}">
                <div class="text-center p-4 padding-sm-1">
                    <h3 class="col-md-12">
                        <span i18n="@@label_trackerlookup_title">Track Your Shipment</span>
                    </h3>
                    <form class="pt-3" name="frmTrackShipment" #trackShipmentForm="ngForm" emrForm autocomplete="off">
                        <div class="row">
                            <div class="col-md-12">
                                <emr-textarea identifier="txtGoNumbers" placeholder="Enter your GO number(s) here"
                                    i18n-placeholder="@@label_trackerlookup_placeholder" [(ngModel)]="UnAuthTrackers"
                                    name="txtGoNumbers" cssClass="sign-in-input" [textAreaRows]="5" required
                                    [emrPattern]="invalidSerialNumberChars" [emrPatternHowToPass]='false'>
                                    <emr-validation-message validatorName="required" message="Copeland Serial # Required"
                                        i18n-message="@@serial_required"></emr-validation-message>
                                    <emr-validation-message validatorName="emrPattern" message="Invalid Serial #"></emr-validation-message>
                                </emr-textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 signinbuttondiv">
                                <p class="mb-0">
                                    <emr-button identifier="Go" buttonType="submit"
                                        cssClass="font-14 btn-lg btn-primary quick-link btn-block m-0 mt-2 text-uppercase"
                                        buttonText="Go" i18n-buttonText="@@label_trackerlookup_go"
                                        buttonIcon="fa fa-search pr-1" (onClick)="onTrackShipment()"></emr-button>
                                </p>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <span class="validationindicator">
                                    <app-error-message [error]="trackerError"></app-error-message>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>