<emr-modal headerTitle="Set Min/Max Y-Axis Chart Values" i18n-headerTitle="@@min_max_chart_title"
    [bsModalRef]=bsMainModalRef>
    <div modal-body class="col-md-12 bg-data-div_MinMax">
        <form modal-body name="frmMinMax" #shipmentForm="ngForm" emrForm autocomplete="off">
            <div class="row">
                <div class="col-md-12 ">
                    <!-- <div class="col-md-12 vmargin-sm"> -->
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                <label class="label-css mb-0" i18n="@@report_min_max_values">Report Minimum/Maximum
                                    Y-Axis Values:</label>

                            </p>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="col-md-12 custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" id="MinMaxRadioAutomatic"
                                    name="MinMaxRadio" ngDefaultControl [value]="true" [(ngModel)]="radAutomatic"
                                    (change)="onChangeRadioButton()" />
                                <label class="custom-control-label label-css" for="MinMaxRadioAutomatic"
                                    i18n="@@radio_set_automatically">
                                    Set Automatically
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="col-md-12 custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" type="radio" id="MinMaxRadioManual"
                                    name="MinMaxRadio" ngDefaultControl [value]="false" [(ngModel)]="radAutomatic" />
                                <label class="custom-control-label label-css" for="MinMaxRadioManual"
                                    i18n="@@radio_set_manually">
                                    Set Manually
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                    <div class="col-12">
                        <emr-radiobutton identifier="max_value" label="Max Value:" name="max_value" [(ngModel)]="max_value" [options]="" [inline]=true
                            labelColumns=3 controlColumns=9> </emr-radiobutton>
                    </div>
                </div> -->
                    <div class="row" *ngIf="!radAutomatic">
                        <div class="col-12 pl-4">
                            <!-- [disabled]="radAutomatic"> -->
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox [setDisabled]="radAutomatic" ngDefaultControl identifier="txtMinValue"
                                        inputType="number" name="txtMinValue" label="Min Value"
                                        i18n-label="@@min_value_label" placeholder="" [(ngModel)]="minValue"
                                        [inline]=true labelColumns="3" textBoxColumns="9" required>
                                        <emr-validation-message validatorName="required" message="Min Value required"
                                            i18n-message="@@min_value_required"></emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox [setDisabled]="radAutomatic" ngDefaultControl identifier="txtMaxValue"
                                        inputType="number" name="txtMaxValue" label="Max Value"
                                        i18n-label="@@max_value_label" [(ngModel)]="maxValue" required
                                        [EmrCompareValidator]="minValue" [inline]=true labelColumns="3"
                                        textBoxColumns="9">
                                        <emr-validation-message validatorName="required" message="Enter Max Value"
                                            i18n-message="@@max_value_required"></emr-validation-message>
                                        <emr-validation-message validatorName="EmrCompareValidator"
                                            message="Max Value must be greater than Min Value."
                                            i18n-message="@@max_value_error"></emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 text-center">
                    <p>
                        <emr-button identifier="chartValuesSave" buttonType="submit" buttonText="Save"
                            i18n-buttonText="@@link_save" cssClass="btn-white btn-outline-default"
                            (onClick)="onChartValuesSave()"></emr-button>
                        <emr-button identifier="chartValuesClose" buttonText="Close"
                            cssClass="btn-white btn-outline-default" (onClick)="onChartValuesClose()"
                            i18n-buttonText="@@close"></emr-button>
                    </p>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <span class="validationindicator">
                            <div class="minmaxchart-validation my-1" *ngIf="error!=null">
                                <div>{{error}}</div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</emr-modal>