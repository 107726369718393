<div class="w-100 col-12 mt-2">
    <div class="row mt-1">
        <div class="col-md-12 col-sm-12 col-12 height-md-40 pr-0">
            <app-pagination [pagingState$]="historicalShipmentPaging$" [itemCount$]="historicalShipmentCount$"
                (PageSelected)="onPageSelected($event)" (PageSizeSelected)="onPageSizeSelected($event)">
            </app-pagination>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 px-0">
            <div class="main-container mb-2">
                <div class="table-data-container table-height">
                    <div class="col-md-12 px-1">
                        <div class="table-responsive">
                            <table class="table table-striped custom-table">
                                <thead>
                                    <tr>
                                        <th scope="col" class="table-row-header font-weight-bold">
                                            <span i18n="@@shipment_name">Shipment Name</span>
                                        </th>
                                        <th scope="col" class="table-row-header font-weight-bold">
                                            <span i18n="@@origin">Origin</span>
                                        </th>
                                        <th scope="col" class="table-row-header font-weight-bold">
                                            <span i18n="@@destination">Destination</span>
                                        </th>
                                        <th scope="col" class="table-row-header font-weight-bold">
                                            <span i18n="@@cargo">Cargo</span>
                                        </th>
                                        <!-- <th scope="col" class="table-row-header font-weight-bold">
                                                <span i18n="@@last_reported_temp">Last Reported Temp</span>
                                        </th> -->
                                        <th scope="col" class="table-row-header font-weight-bold">
                                            <span i18n="@@actual_start_time">Actual Start Time</span>
                                        </th>
                                        <th scope="col" class="table-row-header font-weight-bold">
                                            <span i18n="@@actual_end_time">Actual End Time</span>
                                        </th>
                                        <th scope="col" class="table-row-header font-weight-bold">
                                            <span i18n="@@comments">Comments</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="historicalShipmentList$ | async as historicalShipmentList">
                                    <tr *ngFor="let shipment of historicalShipmentList; let i = index;">
                                        <td>
                                            <p class="table-row-data">
                                                <span
                                                    class="color-header-1">{{ shipment.TripId ? shipment.TripName : 'N/A' }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data serial-number-color">
                                                <span>{{ shipment.TripId ? shipment.StartLocation : 'N/A' }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data">
                                                <span>{{ shipment.TripId ? shipment.TripEndLocationName : 'N/A' }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data">
                                                <span>{{ shipment.SensorRange }}</span>
                                            </p>
                                        </td>
                                        <!-- <td>
                                            <p class="table-row-data notes-color">
                                                <span>{{ shipment.LastReportedTempString }}</span>
                                            </p>
                                        </td> -->
                                        <td>
                                            <p class="table-row-data notes-color">
                                                <span>{{ shipment.TripId ? shipment.ActualStartTimeFormatted : 'N/A' }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data notes-color">
                                                <span>{{ shipment.TripId ? shipment.ActualEndTimeFormatted : 'N/A' }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data">
                                                <span>{{ shipment.Notes ? shipment.Notes : 'N/A' }}</span>
                                            </p>
                                        </td>
                                    </tr>
                                    <tr *ngIf="historicalShipmentList.length === 0">
                                        <td colspan="7">
                                            <p class="table-row-data text-center" i18n="@@no_data_available">No data
                                                available</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>