import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { CustomersService } from 'app-modules/core/services/customer.service';
import { AuthService } from '../store/auth/auth.service';
import { LocaleResolver } from '../utils/locale-resolver';
import { environment } from 'environments/environment';

@Injectable()
export class CustomerGuard implements CanActivate {
    constructor(
        private authSvc: AuthService,
        private custSvc: CustomersService,
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        const locale = localeResolver.resolveLocale();
        return this.custSvc.isSelected$.pipe(
                withLatestFrom(this.authSvc.authenticated$),
            map(([n, isAuthenticated]) => {
                if (!n) {
                    if (isAuthenticated && locale) {
                        this.router.navigate(['selectcustomer']);
                    }
                    return false;
                } else {
                    return true;
                }
            })
        );
    }
}

