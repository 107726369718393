import { Inject, Injectable } from '@angular/core';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import {
  GetTraxxRequest, DateRange, GetTrackerStatusRequest, TraxxInfo, EmrUtilService,
  IMarkerOptions, ILatLong, GetTrackerStatusResponse, CustomMapServiceFactory, MapServiceFactory
} from 'emr-ng-shared';
import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { CompareTraxxModel } from '../models/compare-traxx.model';
import { Store } from '@ngrx/store';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import * as CompareTraxxActions from 'app-modules/core/store/compare-traxx/compare-traxx.actions';
import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { ImageMapService } from 'app-modules/core/services/image-map.service';

const RoutesColorPallette = [
  { color: 'blue', cicleOutline: 'blue', fillCircle: 'purple' },
  { color: 'green', cicleOutline: 'black', fillCircle: '#336699' },
  { color: '#f10f81', cicleOutline: 'purple', fillCircle: '#f16f12' },
  { color: 'red', cicleOutline: 'black', fillCircle: '#3f9efa' },
  { color: 'yellow', cicleOutline: '#336699', fillCircle: '#fdb928' },
  { color: 'violet', cicleOutline: '#f16f12', fillCircle: '#c97d9b' },
  { color: 'cyan', cicleOutline: '#3f9efa', fillCircle: '#ea000f' },
  { color: '#1a5520', cicleOutline: '#fdb928', fillCircle: '#345d95' },
  { color: 'pink', cicleOutline: 'black', fillCircle: '#ffeb3b' },
  { color: 'magenta', cicleOutline: 'purple', fillCircle: '#f59f65' }
];


export const dynamicIconOffset = { x: 12, y: 12 };

@Injectable()
export class ComparetraxxService {

  constructor(
    private oversightSvc: OversightApiService,
    private utilSvc: EmrUtilService,
    private store: Store<IAppState>,
    private imgMapSvc: ImageMapService,
    @Inject(MapServiceFactory) private mapSvc: CustomMapServiceFactory
  ) { }




  public CompareTrackers(trackerIds: string, box, useAPIIcons: boolean, repairCrumbs: boolean): Observable<CompareTraxxModel[]> {
    return of(trackerIds).pipe(
      tap(_ => this.store.dispatch(new CompareTraxxActions.LoadCompareTraxx())),
      map(n => this.getTrackerStatusRequest(n)),
      switchMap(req => this.oversightSvc.UGetTrackerStatus(req)),
      handleErrorResponse(),
      map((n: GetTrackerStatusResponse) => this.GetTraxxRequestModels(n)),
      switchMap(n => {
        const traxxRequests = n.map(r => {
          const req = new GetTraxxRequest();
          req.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
          req.TrackerId = r.TrackerId;
          req.CustomerTrackerId = r.CustomerTrackerId;
          req.TripId = r.TripId;
          req.IsOverridingPrivacy = true;
          req.IsPopulatingGaps = true;
          req.ModelName = r.ModelName;
          req.IsRepairingCrumbs = repairCrumbs;
          return this.getTraxx(req, r);
        });
        return forkJoin(traxxRequests);
      }),
      map(n => {
        this.updateModel(n, box, useAPIIcons);
        return n;
      }),
      tap(
        _ => this.store.dispatch(new CompareTraxxActions.LoadCompareTraxxComplete()),
        _ => this.store.dispatch(new CompareTraxxActions.LoadCompareTraxxComplete()),
        () => this.store.dispatch(new CompareTraxxActions.LoadCompareTraxxComplete())
      )
    );
  }

  private GetTraxxRequestModels(n: GetTrackerStatusResponse) {
    const trackers = new Array<CompareTraxxModel>();
    n.TrackerStatusList.forEach(t => {
      const trac = new CompareTraxxModel();
      trac.TrackerId = t.TrackerId;
      trac.CustomerTrackerId = t.CustomerTrackerId;
      trac.TripId = t.TripId;
      trac.ModelName = t.ModelName;
      trackers.push(trac);
    });
    return trackers;
  }

  private getTrackerStatusRequest(n: string) {
    const trackerStatusRequest = new GetTrackerStatusRequest();
    trackerStatusRequest.TrackerId = n;
    trackerStatusRequest.IsOverridingPrivacy = true;
    return trackerStatusRequest;
  }

  private updateModel(n: CompareTraxxModel[], box: any, useAPIIcons: boolean) {
    n.forEach((t, i) => {
      let color = '';
      let outlineColor = '';
      let circleColor = '';
      const Id = 1001 + i;
      if (i < RoutesColorPallette.length) {
        const palette = RoutesColorPallette[i];
        color = palette.color;
        outlineColor = palette.cicleOutline;
        circleColor = palette.fillCircle;
      } else {
        color = this.getRandomColor();
        outlineColor = this.getRandomColor();
        circleColor = this.getRandomColor();
      }
      t.Id = Id;
      t.Color = color;
      const svgIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                        <circle cx="${dynamicIconOffset.x}" cy="${dynamicIconOffset.y}"
                          r="6" stroke="${outlineColor}" stroke-width="1" fill="${circleColor}" />
                      </svg>`;
      t.pushpinIcon = svgIcon;
      if (t.TraxxState &&
        !t.TraxxState.errorMessage) {
        const m = new Array<IMarkerOptions>();
        const polylinePath = new Array<ILatLong>();
        t.TraxxState.traxx.list.forEach(traxx => {
          this.ConvertToRoute(t, traxx, box, polylinePath, m, svgIcon, useAPIIcons);
        });
        t.polylinePath = polylinePath;
        t.markers = m;
        t.Visible = true;
      } else {
        t.ErrorMessage = t.TraxxState ? t.TraxxState.errorMessage : 'Traxx State was not downloaded';
      }
    });
  }

  private getTraxx(req: GetTraxxRequest, compareTraxx: CompareTraxxModel): Observable<CompareTraxxModel> {
    return of(req).pipe(
      switchMap(n => this.oversightSvc.UGetTraxx(n)),
      map(n => {
        const traxxInfo: ITraxxState = {
          traxx: emptyList(),
          SelectedPeriodRange: n.SelectedPeriodRange,
          dateRange: new DateRange(),
          isLoadRequired: true,
          isLoading: false,
          IsXL:false,
          errorMessage: null
        };
        // let traxx: IListInfo<TraxxInfo> = emptyList();
        if (n.TraxxInfoList) {
          traxxInfo.traxx = {
            list: n.TraxxInfoList,
            itemCount: n.TraxxInfoList.length,
            isPaged: false
          };
        }

        traxxInfo.errorMessage = n.LocalizedErrorMessage;
        compareTraxx.TraxxState = traxxInfo;
        return compareTraxx;
      })
    );
  }

  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ConvertToRoute(traxxModel: CompareTraxxModel, traxx: TraxxInfo, box, polylinePath, m, svgIcon: string, useAPIIcons: boolean) {
    this.utilSvc.updateBoxParams(box, traxx.Latitude, traxx.Longitude);
    this.mapMarkerOption(traxxModel, traxx, m, svgIcon, useAPIIcons);
    polylinePath.push({ latitude: traxx.Latitude, longitude: traxx.Longitude });
  }

  private mapMarkerOption(traxx: CompareTraxxModel, element: TraxxInfo, m: any[], svgIcon: string, useAPIIcons: boolean) {
    const metaData = new Map<string, any>();
    const imageURL = this.imgMapSvc.getImageURL(element.ImageId,  element.ImageUrlSVG, element.ImageUrl);
    let anchor = dynamicIconOffset;
    metaData.set('marker', element);
    metaData.set('trackerId', traxx.TrackerId);
    metaData.set('tripId', traxx.TripId);
    metaData.set('imageURL', imageURL);
    metaData.set('svgURL', svgIcon);
    if (element.ImageAnchorLeft === undefined ||
        element.ImageAnchorLeft === null ||
        element.ImageAnchorTop === undefined ||
        element.ImageAnchorTop === null) {
      metaData.set('imageURLOffset', null);
      if (useAPIIcons) { anchor = null; }
    } else {
      const imageURLOffset = { x: element.ImageAnchorLeft, y: element.ImageAnchorTop };
      metaData.set('imageURLOffset', imageURLOffset);
      if (useAPIIcons) { anchor = imageURLOffset; }
    }
    const icon = useAPIIcons ? imageURL : svgIcon;
    let po = {
      position: {
        latitude: element.Latitude,
        longitude: element.Longitude
      },
      icon,
      metadata: metaData
    } as IMarkerOptions;
    if (anchor) {
      po = { ...po, anchor };
    }
    m.push(po);
  }
}
