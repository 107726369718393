import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { SaveRouteRequest, WaypointInfo, SavedRouteInfo, LocationInfo, EmrFormDirective, IMapOptions, MapTypeId } from 'emr-ng-shared';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { RoutesService } from 'app-modules/core/store/route/route.service';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import { NgForm } from '@angular/forms';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

export class LocationInfoListItem {
  get text() {
    return (this.locationInfo.LocationName ? this.locationInfo.LocationName :
      this.locationInfo.Description) + (this.locationInfo.Address1 ? ' - ' + this.locationInfo.LocationName : '');
  }
  get value() { return this.locationInfo.LocationId; }
  constructor(private locationInfo: LocationInfo) { }
}

@Component({
  selector: 'app-route-editor',
  templateUrl: './route-editor.component.html',
  styleUrls: ['./route-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RouteEditorComponent implements OnInit, OnDestroy {
  routeEditorModel: WaypointInfo[] = [];
  bingMap: Microsoft.Maps.Map;
  directionsManager: Microsoft.Maps.Directions.DirectionsManager;
  polyLine: any;
  routesList$: Observable<SavedRouteInfo[]>;
  originLocations$: Observable<LocationInfoListItem[]>;
  destLocations$: Observable<LocationInfoListItem[]>;

  saveRouteModel: SaveRouteRequest;
  originLocation: any;
  destinationLocation: any;
  directionChanged: any;
  errorHandler: Microsoft.Maps.IHandlerId;
  showNew = false;
  showDirections = false;
  SavedRoute;
  IsEventListenersAdded = false;
  message: string;
  originLocations: any;
  destinationLocations: any;
  centerLocation;
  enableResetZoom = false;
  routeSubscription: Subscription;
  deleteSubscription: Subscription;

  @ViewChild(NgForm) routeForm: NgForm;
  @ViewChild('routeResponseTemplate') routeResponseTemplate: TemplateRef<any>;
  @ViewChild('routeDeleteConfirmationTemplate') routeDeleteConfirmationTemplate: TemplateRef<any>;
  @ViewChild(EmrFormDirective) formDirective;
  isLocationListLoading$: Observable<boolean>;

  hasError = false;
  errorMessage: string = null;
  modalRef: BsModalRef;
  successMessage = false;

  public options: IMapOptions = {
    disableBirdseye: false,
    disableStreetside: false,
    navigationBarMode: 1,
    zoom: 3,
    mapTypeId: MapTypeId.road
  };

  constructor(
    private createSvc: CreateShipmentService,
    private routeSvc: RoutesService,
    private detailStateSvc: ShipmentDetailStateService,
    private modalService: BsModalService) { }

  ngOnInit() {
    this.routesList$ = this.routeSvc.routesList$.pipe(map((k: IListInfo<SavedRouteInfo>) => k.list));
    this.originLocations$ = this.createSvc.originList$.pipe(map(
      n => {
        this.originLocations = n.list;
        return n.list.map(item => new LocationInfoListItem(item));
      }));
    this.destLocations$ = this.createSvc.destinationList$.pipe(map(n => {
      this.destinationLocations = n.list;
      return n.list.map(item => new LocationInfoListItem(item));
    }));
    this.isLocationListLoading$ = this.createSvc.isLoadingLocationsList$;
    this.saveRouteModel = new SaveRouteRequest();
  }

  ngOnDestroy() {
    Microsoft.Maps.Events.removeHandler(this.directionChanged);
    Microsoft.Maps.Events.removeHandler(this.errorHandler);
    this.directionsManager = null;
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.deleteSubscription) {
      this.deleteSubscription.unsubscribe();
    }
  }

  saveRoute() {
    if (this.routeForm.valid) {
      if (this.routeEditorModel.length > 0) {
        const model: SaveRouteRequest = _.cloneDeep(this.saveRouteModel);
        model.Waypoints = this.routeEditorModel;
        model.Polyline = this.polyLine;
        this.routeSubscription = this.routeSvc.SaveRoute(model).subscribe((response) => {
          this.message = response.LocalizedErrorMessage;
          if (response.ErrorCode === 0 && response.RouteId) {
            model.RouteId = response.RouteId;
            this.saveRouteModel = model;
            this.SavedRoute = response.RouteId;
            this.openResponsePopUp();
          } else {
            this.openResponsePopUp(true, response.LocalizedErrorMessage);
          }
        }, error => {
          this.openResponsePopUp(true, error.message);
        });
      } else {
        this.directionsManager.calculateDirections();
      }
    }
  }

  openResponsePopUp(hasError = false, errorMessage = null, isFromSave = true) {
    this.hasError = hasError;
    this.errorMessage = errorMessage;
    this.successMessage = isFromSave;
    this.modalRef = this.modalService.show(this.routeResponseTemplate, { class: 'modal-sm modal-dialog-centered' });
  }

  onSavedRouteChange(d) {
    this.message = null;
    if (d) {
      this.showDirections = true;
      this.message = null;
      this.saveRouteModel = d;
      this.Directions(d.Waypoints);
    } else {
      this.saveRouteModel = new SaveRouteRequest();
      this.saveRouteModel.OriginLocationId = this.saveRouteModel.DestinationLocationId = null;
      this.showDirections = false;
      this.routeEditorModel = [];
      this.directionsManager.clearAll();
    }
  }

  InstanceOfMap(a: Promise<Microsoft.Maps.Map>) {
    a.then(k => {
      this.bingMap = k;
      Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
        this.directionsManager = new Microsoft.Maps.Directions.DirectionsManager(this.bingMap);
      });
    });
  }

  Directions(wayPoints = null) {
    try {
      this.detailStateSvc.loadExportSensorReport();
      this.enableResetZoom = false;
      this.directionsManager.clearAll();
      this.directionsManager.setRequestOptions(
        { routeMode: Microsoft.Maps.Directions.RouteMode.driving, maxRoutes: 1 });
      if (wayPoints && wayPoints.length > 0) {
        wayPoints.forEach(element => {
          this.directionsManager.addWaypoint(
            new Microsoft.Maps.Directions.Waypoint({
              address: element.Address, location: new Microsoft.Maps.Location(element.Latitude, element.Longitude)
            })
          );
        });
      } else {
        const originLocation = this.originLocations.find(k => k.LocationId === this.saveRouteModel.OriginLocationId);
        const waypointSource = new Microsoft.Maps.Directions.Waypoint({
          address: originLocation.LocationName ? originLocation.LocationName : originLocation.City,
          location: new Microsoft.Maps.Location(originLocation.Latitude, originLocation.Longitude)
        });

        const destinationLocation = this.destinationLocations.find(k => k.LocationId === this.saveRouteModel.DestinationLocationId);
        const waypointDestination = new Microsoft.Maps.Directions.Waypoint({
          address: destinationLocation.LocationName ? destinationLocation.LocationName : destinationLocation.City,
          location: new Microsoft.Maps.Location(destinationLocation.Latitude, destinationLocation.Longitude)
        });
        this.directionsManager.addWaypoint(waypointSource);
        this.directionsManager.addWaypoint(waypointDestination);
      }
      this.directionsManager.setRenderOptions({ itineraryContainer: document.getElementById('navigationContent') });
      if (!this.IsEventListenersAdded) {
        this.errorHandler = Microsoft.Maps.Events.addHandler(this.directionsManager, 'directionsError', (args) => {
          this.message = args['message'];
          this.polyLine = [];
          this.routeEditorModel = [];
          this.detailStateSvc.loadExportSensorReportSuccess();
        });

        this.directionChanged = Microsoft.Maps.Events.addHandler(this.directionsManager,
          'directionsUpdated', this.directionCheck.bind(this));
        this.IsEventListenersAdded = true;
      }
      this.directionsManager.calculateDirections();
    } catch (ex) {
      this.routeEditorModel = [];
      this.message = 'Bad data.' + ex.message;
      this.detailStateSvc.loadExportSensorReportSuccess();

    }
  }

  directionCheck(e) {
    const allWaypoints = this.directionsManager.getAllWaypoints();
    if (allWaypoints.length > 25) {
      this.openResponsePopUp(false, null, false);
      allWaypoints.forEach((k, i) => {
        if (k.isViapoint()) {
          this.directionsManager.removeWaypoint(i);
        }
      });
      setTimeout(() => {
        this.directionsManager.calculateDirections();
      });
    } else {
      this.detailStateSvc.loadExportSensorReportSuccess();
      this.enableResetZoom = false;
      const viaPoints = allWaypoints.filter(k => k.isViapoint());
      // if (viaPoints && (viaPoints.length >= 9 || (viaPoints.length > 0 && allWaypoints.length === 25))) {
      if (viaPoints && viaPoints.length > 0) {
        allWaypoints.forEach((k, i) => {
          if (k.isViapoint()) {
            k['_waypointOptions'].isViaPoint = undefined;
            this.directionsManager.removeWaypoint(i);
            this.directionsManager.addWaypoint(k, i);
          }
        });
        setTimeout(() => {
          this.directionsManager.calculateDirections();
        });
      }
      this.polyLine = this.directionsManager.getCurrentRoute()['routePath'];
      const WaypointsCount = allWaypoints.length;
      this.routeEditorModel = [];
      if (WaypointsCount > 1) {
        for (let counter = 0; counter < WaypointsCount; counter++) {
          const routeEditorModel = new WaypointInfo();
          routeEditorModel.Address = allWaypoints[counter].getAddress();
          routeEditorModel.Latitude = allWaypoints[counter].getLocation().latitude;
          routeEditorModel.Longitude = allWaypoints[counter].getLocation().longitude;
          routeEditorModel.IsOrigin = false;
          routeEditorModel.IsDestination = false;
          if (counter === 0) {
            routeEditorModel.IsOrigin = true;
          } else if (counter === WaypointsCount - 1) {
            routeEditorModel.IsDestination = true;
          }
          this.routeEditorModel.push(routeEditorModel);
        }
      }
    }
  }

  selectDistinctLocation(a, b) {
    this.message = null;
    if (this.saveRouteModel.DestinationLocationId && this.saveRouteModel.OriginLocationId) {
      this.Directions();
      this.showDirections = true;
    } else {
      this.routeEditorModel = [];
      this.directionsManager.clearAll();
      this.showDirections = false;
    }
  }

  removeWayPoint(way: WaypointInfo) {
    const currentLocation = new Microsoft.Maps.Location(way.Latitude, way.Longitude);
    const selectedWaypoint = new Microsoft.Maps.Directions.Waypoint({ address: way.Address, location: currentLocation });
    this.directionsManager.removeWaypoint(selectedWaypoint);
    this.directionsManager.calculateDirections();
  }

  clearAll() {
    this.directionsManager.clearAll();
    this.Directions();
  }

  wayPointClick(model) {
    this.enableResetZoom = true;
    const currentLocation = new Microsoft.Maps.Location(model.Latitude, model.Longitude);
    this.bingMap.setView({ center: currentLocation, zoom: 12 });
  }

  resetZoom() {
    this.enableResetZoom = false;
    this.directionsManager.calculateDirections();
  }

  resetRoute() {
    this.saveRouteModel = new SaveRouteRequest();
    this.message = null;
    this.showDirections = false;
    this.routeEditorModel = [];
    this.directionsManager.clearAll();
    this.routeForm.resetForm(this.saveRouteModel);
  }

  onResetZoom() {
    this.resetRoute();
    this.bingMap.setView({ center: this.centerLocation, zoom: 5 });
  }

  onDeleteRouteClick() {
    this.modalRef = this.modalService.show(
      this.routeDeleteConfirmationTemplate,
      {
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true,
        keyboard: false
      },
    );
  }

  deleteRoute() {
    this.modalRef.hide();
    this.deleteSubscription = this.routeSvc.DeleteRoute(this.saveRouteModel).subscribe((response) => {
      this.message = response.LocalizedErrorMessage;
      if (response.ErrorCode === 0 && response.RouteId) {
        this.SavedRoute = null;
        this.resetRoute();
        setTimeout(() => {
          this.saveRouteModel.OriginLocationId = this.saveRouteModel.DestinationLocationId = null;
          this.routeForm.resetForm(this.saveRouteModel);
        });
        this.directionsManager.clearAll();
        this.routeEditorModel = [];
        this.openResponsePopUp();
      } else {
        this.openResponsePopUp(true, response.LocalizedErrorMessage);
      }
    }, error => {
      this.openResponsePopUp(true, error.message);
    });
  }
}
