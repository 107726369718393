<emr-modal headerTitle="{{headerTitle}}" subHeaderTitle="{{subHeaderTitle}}" [bsModalRef]=bsModalRef [showCloseButton]=showCloseButton>
    <div modal-body>
        <div *ngIf="confirmMessage" class="row">
            <div class="col-12">
                <div class="p-1 m-1">
                    <p class=" font-14">{{ confirmMessage }}</p>
                </div>
            </div>
        </div>
        <div *ngIf="confirmMessage" class="row">
            <div class="col-12 text-center ">
                <emr-button identifier="confirmYes" cssClass="btn button-text btn-outline-default mt-0 mb-2"
                    buttonType="button" buttonText="{{yesText}}" (click)='onClickYes()'></emr-button>
                <emr-button identifier="confirmNo" cssClass="btn button-text btn-outline-default mt-0 mb-2"
                    buttonType="button" buttonText="{{noText}}" (click)='onClickNo()'></emr-button>
            </div>
        </div>
    </div>
</emr-modal>