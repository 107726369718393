import { ITripStartOptionsState } from '../../models/trip-start-options-state.interface';

import { initialState } from '../../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as tripStartActions from './trip-start-options.actions';
import { emptyList } from '../../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    tripStartActions.LoadTripStartOptions |
    tripStartActions.LoadTripStartOptionsSuccess |
    tripStartActions.LoadTripStartOptionsError | 
    tripStartActions.CancelLoadTripStartOptions;

export function TripStartOptionsReducer(state: ITripStartOptionsState = initialState.tripStartOptions, action: Actions): ITripStartOptionsState {
    switch (action.type) {
        
        case actionIndex.LOAD_TRIP_START_OPTIONS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_TRIP_START_OPTIONS_SUCCESS:
            return {
                ...state,
                tripStartOptionsList: action.tripStartOptionsList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_TRIP_START_OPTIONS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

            
        case actionIndex.CANCEL_LOAD_TRIP_START_OPTIONS:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false
            };

        default:
            return {
                ...state
            };
    }
}
