import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadTrackers implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS;
}

export class LoadTrackersSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS;
    constructor(public trackerList: IListInfo<Tracker>) { }
}

export class LoadTrackersError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS;
    constructor(public message: string) { }
}

export class CancelLoadTrackers implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_TRACKERS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS;
}

export class LoadPullTrackers implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS;
}

export class LoadPullTrackersSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS;
    constructor(public trackerList: IListInfo<Tracker>) { }
}

export class LoadPullTrackersError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS;
    constructor(public message: string) { }
}

export class CancelLoadPullTrackers implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_PULL_TRACKERS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS;
}
