export interface IPagingState {
    pageSize: number;
    pageNumber: number;
}

export function defaultPaging(): IPagingState {
    return {
        pageSize: 25,
        pageNumber: 1
    };
}
