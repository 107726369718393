<div *ngIf="list|searchFilter:searchModel:textField as filteredList">
    <div class="mb-1" *ngIf="filteredList?.length>200">
        <div class="col-md-12">
            <span i18n="@@label_showing_25_of">Showing 25 of </span>
            <span>{{filteredList?.length}}</span>
        </div>
    </div>
    <div class="w-100 mt-2">
        <emr-textbox identifier="txtSearch_{{identifier}}" name="txtSearchOriginFilter" placeholder="Search"
            i18n-placeholder="@@search" formGroupCssClass="m-0" [(ngModel)]="searchModel" appendIcon="fa fa-search"
            appendIconClass="input-icon">
        </emr-textbox>
    </div>
    <div class="row m-0">
        <div class="col-md-12 mt-2 pl-0 pr-0 boxed"
            *ngIf="filteredList | slice:0:filteredList.length>200?25:200 as filteredData;">
            <emr-checkboxlist identifier="{{identifier}}" name="{{identifier}}" [(ngModel)]="model"
                [options]="filteredData" (onChange)="onFilterChange($event)" [inline]=true returnType="array"
                textCssClass="align-baseline" [optionsTextField]="textField" [optionsValueField]="valueField">
            </emr-checkboxlist>
        </div>
    </div>
</div>