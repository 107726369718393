import { IShipmentState } from 'app-modules/core/store/models/shipment-state.interface';
import { emptyList, IListInfo, setListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as shipActions from 'app-modules/core/store/actions/shipment.actions';
import * as shipFltrActions from 'app-modules/core/store/shipment-filter/shipment-filter.actions';
import * as shipSortActions from 'app-modules/core/store/actions/shipment-sort.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';
import { Shipment } from 'app-modules/core/models/shipment.model';
import * as unAuthActions from 'app-modules/core/store/un-auth/un-auth.actions';
import { TripState } from 'emr-ng-shared';

type Actions =
    authActions.SignOut |
    shipActions.LoadShipments |
    shipActions.LoadShipmentsSuccess |
    shipActions.LoadShipmentsError |
    shipActions.CancelLoadShipments |
    shipActions.LoadShipmentAddress |
    shipActions.LoadShipmentAddressSuccess |
    shipActions.LoadShipmentAddressError |
    shipActions.CreateShipmentSuccess |
    shipActions.CreateShipmentError |
    shipActions.UpdateShipmentSuspendResumeState |
    custActions.SelectCustomer |
    shipFltrActions.ChangeShipmentFilter |
    shipFltrActions.ChangeShipmentFilterWithOutShipmentListUpdate |
    shipSortActions.ChangeShipmentSort |
    shipSortActions.ChangeShipmentSortWithOutListUpdate |
    shipActions.UpdateShipmentSuccess |
    shipActions.UpdateShipmentError |
    shipActions.CloseShipmentSuccess |
    shipActions.LoadShipmentbyIdSuccess |
    shipActions.LoadShipmentbyTrackerIdSuccess |
    shipActions.AutoRefreshShipments |
    shipActions.AutoRefreshShipmentsError |
    shipActions.AutoRefreshShipmentsSuccess |
    shipActions.CancelAutoRefreshShipments |
    shipActions.LoadShipmentBySerial |
    shipActions.LoadShipmentBySerialSuccess |
    shipActions.LoadShipmentBySerialError |
    shipActions.CancelLoadShipmentBySerial |
    unAuthActions.UnAuthCreateShipmentSuccess |
    shipActions.ReloadShipments |
    shipActions.UpdateShipmentByTrackerID |
    shipActions.DeleteShipmentSuccess |
    shipActions.SaveShutdownTrackerSuccess |
    shipActions.UpdateShipmentETA |
    shipActions.UpdateShipmentETASuccess |
    shipActions.UpdateShipmentETAError ;

export function ShipmentReducer(state: IShipmentState = initialState.shipment, action: Actions): IShipmentState {
    let newState: IShipmentState;
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipment;

        case actionIndex.LOAD_SHIPMENTS:
            return {
                ...state,
                isLoading: true
            };

        // case actionIndex.AUTO_REFRESH_SHIPMENTS_SUCCESS:
        case actionIndex.LOAD_SHIPMENTS_SUCCESS:
            return {
                ...state,
                shipmentList: action.shipmentList,
                shipmentListComeFromApi : true,
                isLoadRequired: false,
                isAutoLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.CANCEL_LOAD_SHIPMENT_BY_SERIAL:
        case actionIndex.CANCEL_LOAD_SHIPMENTS:
            return {
                ...state,
                isLoadRequired: false
            };

        case actionIndex.LOAD_SHIPMENT_BY_SERIAL:
        case actionIndex.LOAD_SHIPMENT_ADDRESS:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.UPDATE_SHIPMENT_SUSPEND_RESUME_STATE:
            state.shipmentList.list.forEach((shipment: Shipment) => {
                if (shipment.trackerId === action.trackerId && shipment.tripId === action.tripId) {
                    shipment.alertSuppressed = action.isSuppressed;
                    shipment.CanResumeAlerts = action.isSuppressed;
                    shipment.CanSuspendAlerts = !action.isSuppressed;
                }
            });
            return state;

            
        case actionIndex.UPDATE_SHIPMENT_ETA_SUCCESS:
            state.shipmentList.list.forEach((shipment: Shipment) => {
                if (shipment.trackerId === action?.shipment?.GlobalDeviceId && shipment.tripId === action.shipment?.TripId) {
                    var shipmentETA = action?.shipment?.UpdatedShipmentETA;
                    if(shipmentETA){
                        shipment.TripETADistanceM = shipmentETA.TripETADistanceKM;
                        shipment.TripETADistanceString = shipmentETA.TripETADistanceString; 
                        shipment.TripETAUTC = shipmentETA.TripETAUTC;
                        shipment.TripETA = shipmentETA.TripETA; 
                        shipment.TripETAFormatted= shipmentETA.TripETAFormatted;
                        shipment.TripETAOlderThanThreshold = false; 
                    }
                }
            });
            return state;

        case actionIndex.LOAD_SHIPMENT_ADDRESS_SUCCESS:
            let existingShipment: Shipment;

            newState = {
                ...state,
                isLoading: false
            };

            for (const newShipment of action.shipmentList.list) {
                existingShipment = newState.shipmentList.list.find(a => a.Id === newShipment.Id);

                if (existingShipment) {
                    if (existingShipment.latitude !== newShipment.latitude ||
                        existingShipment.longitude !== newShipment.longitude ||
                        existingShipment.ImageId !== newShipment.ImageId ||
                        existingShipment.ImageUrlSVG !== newShipment.ImageUrlSVG ||
                        existingShipment.ImageUrl !== newShipment.ImageUrl) {
                        
                        newShipment.needsMarkerUpdate = true;
                        existingShipment.latitude = newShipment.latitude;
                        existingShipment.longitude = newShipment.longitude;
                        existingShipment.ImageId = newShipment.ImageId;
                        existingShipment.ImageUrlSVG = newShipment.ImageUrlSVG;
                        existingShipment.ImageUrl = newShipment.ImageUrl;
                        existingShipment.ImageAnchorLeft = newShipment.ImageAnchorLeft;
                        existingShipment.ImageAnchorTop = newShipment.ImageAnchorTop;
                    }
                    existingShipment.AddressGeo = newShipment.AddressGeo;
                    existingShipment.AddressGeoTimestampFormatted = newShipment.AddressGeoTimestampFormatted; // 'debug code'; //
                    existingShipment.AddressOlderThanThreshold = newShipment.AddressOlderThanThreshold;
                    existingShipment.AddressGeoLoading = false;
                }
            }

            return newState;

        case actionIndex.LOAD_SHIPMENT_BY_SERIAL_SUCCESS:
            let eShipment: Shipment;

            newState = {
                ...state,
                isLoading: false
            };

            for (const newShipment of action.shipmentList.list) {
                eShipment = newState.shipmentList.list.find(a => a.Id === newShipment.Id);

                if (eShipment) {
                    const objectKeys = Object.keys(eShipment);
                    objectKeys.forEach(element => {
                        eShipment[element] = newShipment[element];
                    });
                } else {
                    newState.shipmentList.list.push(newShipment);
                }
            }
            newState.shipmentList.itemCount = newState.shipmentList.list.length;

            const list = emptyList();
            list.list = newState.shipmentList.list;
            newState.shipmentList = list;

            return newState;

        case actionIndex.LOAD_SHIPMENT_BY_SERIAL_ERROR:
        case actionIndex.LOAD_SHIPMENT_ADDRESS_ERROR:
            return {
                ...state,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.CREATE_SHIPMENT_ERROR:
        case actionIndex.LOAD_SHIPMENTS_ERROR:
        case actionIndex.UPDATE_SHIPMENT_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.AUTO_REFRESH_SHIPMENTS_ERROR:
            return {
                ...state,
                isAutoLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };
        case actionIndex.AUTO_REFRESH_SHIPMENTS:
            return {
                ...state,
                isAutoLoadRequired: true,
                errorMessage: ''
            };
        case actionIndex.AUTO_REFRESH_SHIPMENTS_SUCCESS:
            newState = {
                ...state,
                isLoading: false,
                isLoadRequired: false,
                isAutoLoadRequired: false,
                errorMessage: ''
            };
            for (const newShipment of action.shipmentList.list) {
                eShipment = newState.shipmentList.list.find(a => a.trackerId === newShipment.trackerId);
                if (eShipment) {
                    const index = newState.shipmentList.list.indexOf(eShipment);
                    newState.shipmentList.list[index] = newShipment;
                }
            }
            const shipments = emptyList();
            shipments.list = newState.shipmentList.list;
            newState.shipmentList = shipments;
            return newState;
        case actionIndex.CHANGE_SHIPMENT_FILTER:
        case actionIndex.CHANGE_SHIPMENT_SORT:
        case actionIndex.SELECT_CUSTOMER:
        case actionIndex.CREATE_SHIPMENT_SUCCESS:
        case actionIndex.UPDATE_SHIPMENT_SUCCESS:
        case actionIndex.RELOAD_SHIPMENTS:
        case actionIndex.UN_AUTH_CREATE_SHIPMENT_SUCCESS:
            // case actionIndex.CLOSE_SHIPMENT_SUCCESS:
            return {
                ...state,
                isLoadRequired: true,
                errorMessage: ''
            };

        case actionIndex.CHANGE_SHIPMENT_FILTER_WITH_OUT_SHIPMENT_LIST_UPDATE:
        case actionIndex.CHANGE_SHIPMENT_SORT_WITH_OUT_LIST_UPDATE:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_SHIPMENTS_BYID_SUCCESS:
            // case actionIndex.CLOSE_SHIPMENT_SUCCESS:
            // const index = state.shipmentList.list.map(x => x.tripId).indexOf(action.shipment.tripId);
            const newStateList = emptyList();
            const shipment = action.shipment.tripId ?
                                state.shipmentList.list.find(list => list.tripId === action.shipment.tripId) :
                                state.shipmentList.list.find(list => list.trackerId === action.shipment.trackerId);
            if (shipment) {
                const objectKeys = Object.keys(shipment);
                objectKeys.forEach(element => {
                    shipment[element] = action.shipment[element];
                });
            }
            newStateList.list = state.shipmentList.list;
            newStateList.itemCount = newStateList.list.length;

            return {
                ...state,
                shipmentList: newStateList,
                isLoadRequired: false,
                errorMessage: ''

            };

        case actionIndex.DELETE_SHIPMENT_SUCCESS:
            {
                const req = action.deleteReq;
                const newShipmentList = emptyList();
                newShipmentList.list = state.shipmentList.list.filter(list => list.trackerId !== req.GlobalDeviceId && list.tripId !== req.TripId);
                newShipmentList.itemCount = newShipmentList.list.length;
                state.shipmentList = newShipmentList;                

                return {
                    ...state,
                    isLoadRequired: false,
                    errorMessage: ''
                };
            }
        case actionIndex.LOAD_SHIPMENTS_BYTRACKERID_SUCCESS:
            action.shipments.forEach(shipment => {
                const existingShipment = state.shipmentList.list.find(list => list.trackerId === shipment.trackerId && list.tripId === shipment.tripId);
                if (existingShipment) {
                    const objectKeys = Object.keys(existingShipment);
                    objectKeys.forEach(element => {
                        existingShipment[element] = shipment[element];
                    });
                }
            });
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: ''
            };

        case actionIndex.Update_SHIPMENT_BY_TRACKERID:
            const shipmentData = state.shipmentList.list.find(list => list.trackerId === action.shipment.trackerId);
            const updateShipment = action.shipment;
            if(shipmentData) {
                const objectKeys = Object.keys(updateShipment);
                objectKeys.forEach(element => {
                    shipmentData[element] = updateShipment[element];
                });
            }

            return {
                ...state,
                isLoadRequired: false,
                errorMessage: ''
            };

        default:
            return {
                ...state
            };
    }
}
