import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

export class PagingUtil {
    public GetPageCount(itemCount: number, pageSize: number): number {
        if (pageSize < 1) {
            return itemCount;
        }

        const modulus = itemCount % pageSize;
        return ((itemCount - modulus) / pageSize) + (modulus > 0 ? 1 : 0);
    }

    public GetPage<T>(listInfo: IListInfo<T>, pageSize: number, pageNumber: number): IListInfo<T> {
        const emptyList: IListInfo<T> = { list: [], itemCount: 0, isPaged: true};
        let start: number;
        let end: number;

        if (listInfo.isPaged) {
            return listInfo;
        }

        if (!(listInfo.list) || listInfo.list.length === 0) {
            return emptyList;
        }

        const pageCount = this.GetPageCount(listInfo.list.length, pageSize);

        if (pageNumber > pageCount) {
            return {
                list: [],
                itemCount: listInfo.list.length,
                isPaged: true
            };
        }

        start = (pageNumber - 1) * pageSize;

        if ((start + pageSize) > (listInfo.list.length - 1)) {
            end = listInfo.list.length;
        } else {
            end = start + pageSize;
        }

        return {
            list: listInfo.list.slice(start, end),
            itemCount: listInfo.list.length,
            isPaged: true
        };
    }
}
