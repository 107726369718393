<emr-modal class="scrollable-modal" autocomplete="off" [bsModalRef]=bsModalRef>
  <ng-container modal-header>
    <span class="col-md-12 w-100 modal-title modal-header-title" >
      {{ headerTitle}}</span>
  </ng-container>
  <ng-container modal-body>
    <div class="col-md-12 contacts">
      <form name="frmCreateContact" #contactForm="ngForm" emrForm autocomplete="off">
        <div class="row">
          <div class="col-md-12 contacts-form-div ">
            <div class="row">
              <div class="col-md-6">
                <emr-textbox identifier="txtFirstName" name="txtFirstName" ngDefaultControl label="First Name"
                  i18n-label="@@label_first_name" placeholder="First Name" i18n-placeholder="@@label_first_name"
                  [(ngModel)]="contact.FirstName" required inputDivClass="px-0" customValidMsgCss="pl-0">
                  <emr-validation-message validatorName="required" message="First Name Required"
                    i18n-message="@@first_name_required"></emr-validation-message>
                </emr-textbox>
              </div>
              <div class="col-md-6">
                <emr-textbox identifier="txtLastName" name="txtLastName" ngDefaultControl label="Last Name"
                  i18n-label="@@label_last_name" placeholder="Last Name" i18n-placeholder="@@label_last_name"
                  [(ngModel)]="contact.LastName" required inputDivClass="px-0" customValidMsgCss="pl-0">
                  <emr-validation-message validatorName="required" message="Last Name Required"
                    i18n-message="@@last_name_required"></emr-validation-message>
                </emr-textbox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <emr-textbox ngDefaultControl identifier="txtEmail" name="txtEmail" ngDefaultControl label="Email"
                  i18n-label="@@label_email" [(ngModel)]="contact.EmailAddress" placeholder="Email"
                  i18n-placeholder="@@label_email" inputDivClass="px-0"
                  [required]="contact.PreferredContactMethod === contactMethod.Email" EmrEmailValueValidator
                  customValidMsgCss="pl-0">
                  <emr-validation-message validatorName="required" i18n-message="@@error_email_required"
                    message="Email is required">
                  </emr-validation-message>
                  <emr-validation-message validatorName="EmrEmailValueValidator" i18n-message="@@error_email_invalid"
                    message="Invalid email">
                  </emr-validation-message>
                </emr-textbox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <emr-textbox ngDefaultControl identifier="txtCellPhone" label="Cell Phone" i18n-label="@@label_cell_phone"
                  placeholder="Cell Phone" i18n-placeholder="@@label_cell_phone" [(ngModel)]="contact.CellPhone"
                  name="txtCellPhone" inputDivClass="px-0" helpText="Country prefix is required (e.g. '1' for U.S.)"
                  i18n-helpText="@@label_country_prefix_required"
                  controlHelpTip="Digits only (no spaces or dashes) & first digit should not be zero"
                  i18n-controlHelpTip="@@label_digits_only_message" customValidMsgCss="pl-0"
                  [required]="contact.PreferredContactMethod === contactMethod.TextMessage" EmrNumericValidator>
                  <emr-validation-message validatorName="required" i18n-message="@@error_cell_number_required"
                    message="Please enter cell phone number.">
                  </emr-validation-message>
                  <emr-validation-message validatorName="EmrNumericValidator" i18n-message="@@error_numeric_phone"
                    message="Phone accepts only numbers">
                  </emr-validation-message>
                </emr-textbox>
              </div>
              <div class="col-md-6">
                <emr-custom-dropdown ngDefaultControl identifier="ddlPreferredContactMethod" name="ddlPreferredContactMethod"
                  [options]="preferredContactMethod" [(ngModel)]="contact.PreferredContactMethod" cssClass="default-dropdown"
                  label="Preferred Contact Method" i18n-label="@@label_contact_method" inlineCss="px-0">
                </emr-custom-dropdown>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 timezone-dll">
                <emr-custom-dropdown ngDefaultControl identifier="lstTimeZone" name="lstTimeZone"
                  [options]="(timeZoneList$ | async)?.list" [(ngModel)]="contact.TimeZoneCode" cssClass="default-dropdown"
                  label="Timezone" i18n-label="@@label_timezone" optionsValueField="TimeZoneId" optionsTextField="FullName"
                  labelColumns=4 controlColumns=8 [enableSearchFilter]=true SearchPlaceHolder="Search Time Zone"
                  i18n-SearchPlaceHolder="@@search_time_zone" placeholder="Select Time Zone"
                  i18n-placeholder="@@label_select_time_zone" [isLoading]="isTimeZoneLoading$ | async" labelColumns=3
                  controlColumns="9" inlineCss="px-0" [isDropup]="true">
                </emr-custom-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-12">
            <emr-button identifier="ContactDetails" buttonType="submit" cssClass="btn-lg btn-primary quick-link btn-block m-0"
              buttonText="{{btnSaveText}}" buttonIcon="fa fa-Plus"
              (onClick)="OnSaveContactClick()"></emr-button>
          </div>
        </div>
        <div *ngIf="error" class="row mb-2">
            <div class="col-md-12 error-message-edit-location">
                <span class="validationindicator">
                    <app-error-message [error]="error"></app-error-message>
                </span>
            </div>
        </div>
      </form>
    </div>
  </ng-container>
</emr-modal>