import { ShipmentInfo } from 'emr-ng-shared';
import { AlertStatus } from 'emr-ng-shared';
import { ChartData } from 'emr-ng-shared';
import { ChartDataInfo } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTracker implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER;
}

export class LoadTrackerSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER;
    constructor(
        public summary: ShipmentInfo,
        public alertInfo: IListInfo<AlertStatus>,
        public chartData: IListInfo<ChartData>,
        public chartDataInfo: ChartDataInfo
    ) { }
}

export class LoadTrackerError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER;

    constructor(public message: string) { }
}

export class CancelLoadTracker implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER;
}

export class LoadExportTrackerStateReport implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT;
}

export class LoadExportTrackerStateReportSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT;
    constructor() { }
}

export class LoadExportTrackerStateReportError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT;
    constructor() { }
}

export class CancelLoadExportTrackerStateReport implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_EXPORT_TRACKER_STATE_REPORT;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT;
    constructor() { }
}

