<div class="w-100">
    <div class="">
        <div class="main regular-text-font p-2">
            <!-- <div class="row">
                <div class="col-md-12 mb-1">
                    <app-shipment-filter-summary (clearPeriod)="onClearPeriod()"></app-shipment-filter-summary>
                </div>
            </div> -->
            <div *ngIf="!IsUnAuthenticated">
                <div class="row mx-0 mb-2 filter-header"
                    (click)="isSerialNumberFilterCollapsed = !isSerialNumberFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@label_serial_number">Serial Number</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isSerialNumberFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 my-1" [collapse]="isSerialNumberFilterCollapsed">
                        <app-shipment-filter-serial-number [(filter)]="shipmentFilter.serialNumber"
                            [(isDirty)]="isSerialNumberFilterDirty" (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-serial-number>
                    </div>
                </div>
            </div>
            <div *ngIf="!IsUnAuthenticated">
                <div class="row mx-0 mb-2 filter-header"
                    (click)="isShipmentNameFilterCollapsed = !isShipmentNameFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@label_shipment_name">Shipment Name</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isShipmentNameFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 my-1" [collapse]="isShipmentNameFilterCollapsed">
                        <app-shipment-filter-shipment-name [(filter)]="shipmentFilter.shipmentName"
                            [(isDirty)]="isShipmentNameFilterDirty" (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-shipment-name>
                    </div>
                </div>
            </div>
            <div class="row mx-0 mb-2 filter-header" (click)="isStatusFilterCollapsed = !isStatusFilterCollapsed">
                <div
                    class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                    <span i18n="@@shipment_status">Shipment Status</span>
                </div>
                <div class="col-md-2 col-2 px-2">
                    <i
                        class="fa  {{isStatusFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-1" [collapse]="isStatusFilterCollapsed">
                    <app-shipment-filter-status [(filter)]="shipmentFilter.status" [(isDirty)]="isStatusFilterDirty"
                        (isDirtyChange)="onFilterMarkedDirty()">
                    </app-shipment-filter-status>
                </div>
            </div>
            <div class="row mx-0 mb-2 filter-header"
                (click)="isTimePeriodFilterCollapsed = !isTimePeriodFilterCollapsed">
                <div
                    class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                    <span i18n="@@label_time_period">Time Period</span>
                </div>
                <div class="col-md-2 col-2 px-2">
                    <i
                        class="fa  {{isTimePeriodFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 my-1" [collapse]="isTimePeriodFilterCollapsed">
                    <app-shipment-filter-period [(filter)]="shipmentFilter.period" [(isDirty)]="isPeriodFilterDirty"
                        (isDirtyChange)="onFilterMarkedDirty()" [ShowLastNDays]="ShowLastNDays"
                        [showPlaceHolder]="showPlaceHolder" [cssClass]="IsUnAuthenticated?'cust-position':''"
                        [pageFilter]="pageFilter">
                    </app-shipment-filter-period>
                </div>
            </div>
            <div *ngIf="!IsUnAuthenticated">
                <div class="row mx-0 mb-2 filter-header" (click)="isAlertFilterCollapsed = !isAlertFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@label_exceptions">Exceptions</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isAlertFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-1" [collapse]="isAlertFilterCollapsed">
                        <app-shipment-filter-alert [(filter)]="shipmentFilter.alert" [(isDirty)]="isAlertFilterDirty"
                            (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-alert>
                    </div>
                </div>
            </div>
            <div *ngIf="!IsUnAuthenticated">
                <div class="row mx-0 mb-2 filter-header" (click)="isOriginFilterCollapsed = !isOriginFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@origins">Origins</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isOriginFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div class="row mb-1" [collapse]="isOriginFilterCollapsed">
                    <app-shipment-filter-origin [(filter)]="shipmentFilter.origin" [(isDirty)]="isOriginFilterDirty"
                        (isDirtyChange)="onFilterMarkedDirty()">
                    </app-shipment-filter-origin>
                </div>
                <div class="row mx-0 mb-2 filter-header"
                    (click)="isDestinationFilterCollapsed = !isDestinationFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@destinations">Destinations</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isDestinationFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-1" [collapse]="isDestinationFilterCollapsed">
                        <app-shipment-filter-destination [(filter)]="shipmentFilter.destination"
                            [(isDirty)]="isDestinationFilterDirty" (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-destination>
                    </div>
                </div>
                <div class="row mx-0 mb-2 filter-header" (click)="isTempFilterCollapsed = !isTempFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@temperature_ranges">Temperature Ranges</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isTempFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mb-1" [collapse]="isTempFilterCollapsed">
                        <app-shipment-filter-temp [(filter)]="shipmentFilter.sensorRange"
                            [(isDirty)]="isTempFilterDirty" (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-temp>
                    </div>
                </div>
            </div>
            <div *ngIf="!IsUnAuthenticated">
                <div *ngIf="isCarrierEnabled" class="row mx-0 mb-2 filter-header"
                    (click)="isCarrierFilterCollapsed = !isCarrierFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@carriers">Carriers</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isCarrierFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div *ngIf="isCarrierEnabled" class="row">
                    <div class="col-md-12 mb-1" [collapse]="isCarrierFilterCollapsed">
                        <app-shipment-filter-carrier [(filter)]="shipmentFilter.carrier"
                            [(isDirty)]="isCarrierFilterDirty" (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-carrier>
                    </div>
                </div>
            </div>
            <div *ngIf="!IsUnAuthenticated">
                <div *ngIf="isSupplierEnabled" class="row mx-0 mb-2 filter-header"
                    (click)="isSupplierFilterCollapsed = !isSupplierFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@label_supplier">Suppliers</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isSupplierFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div *ngIf="isSupplierEnabled" class="row">
                    <div class="col-md-12 mb-1" [collapse]="isSupplierFilterCollapsed">
                        <app-shipment-filter-supplier [(filter)]="shipmentFilter.supplierCustomer"
                            [(isDirty)]="isSupplierFilterDirty" (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-supplier>
                    </div>
                </div>
            </div>
            <div *ngIf="!IsUnAuthenticated">
                <div *ngIf="isDCEnabled" class="row mx-0 mb-2 filter-header"
                    (click)="isDCFilterCollapsed = !isDCFilterCollapsed">
                    <div
                        class="col-md-10 col-10 px-2 roboto-regular-font font-14 color-regular font-weight-bold line-height-20">
                        <span i18n="@@label_distribution_center">Distribution Center</span>
                    </div>
                    <div class="col-md-2 col-2 px-2">
                        <i
                            class="fa  {{isDCFilterCollapsed ? 'fa-plus' : 'fa-minus'}} float-right line-height-20">&nbsp;</i>
                    </div>
                </div>
                <div *ngIf="isDCEnabled" class="row">
                    <div class="col-md-12 mb-1" [collapse]="isDCFilterCollapsed">
                        <app-shipment-filter-distribution-center [(filter)]="shipmentFilter.dcTripStopLocation"
                            [(isDirty)]="isDCFilterDirty" (isDirtyChange)="onFilterMarkedDirty()">
                        </app-shipment-filter-distribution-center>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>