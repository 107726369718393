import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthStateService } from 'app-modules/core/store/auth/auth-state.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { Observable, of } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { environment } from 'environments/environment';
import { InitializationService } from '../services/initialization.service';
import { StoreManager } from '../utils/store-manager';
import { STORE_KEYS } from '../consts/values';
import { PakSenseAuthStateService } from '../store/paksense-auth/ps-auth-state.service';
import { LocaleResolver } from '../utils/locale-resolver';
@Injectable()
export class AuthGuard implements CanActivate {
    private authState$: Observable<IAuthState>;
    public localStorageData;
    public selectedCustomer;
    public isBrowserRefreshed;
    constructor(
        private store: Store<IAppState>,
        private router: Router,
        private authStateSvc: AuthStateService,
        private authService: AuthService,
        private initSvc: InitializationService,
        psAuthStateSvc: PakSenseAuthStateService
    ) {
        this.authState$ = this.store.select('auth');
        this.isBrowserRefreshed = localStorage.getItem(environment.isBrowerRefreshed);
        if (!sessionStorage.getItem(environment.unAuthTrackers)) {
            if (this.isBrowserRefreshed === '1') {
                this.initSvc.InitAppLoad();
                this.localStorageData = JSON.parse(localStorage.getItem(environment.authPersistentData));
                this.authService.updateRequestedParams();
                if (this.localStorageData) {
                    if (!this.authService.getRequestedParams()) {
                        this.authService.setRequestedURL(window.location.pathname);
                    }
                    this.authStateSvc.signInSuccess(
                        this.localStorageData.AccessToken,
                        this.localStorageData.RefreshToken,
                        this.localStorageData.username,
                        this.localStorageData.username,
                        this.localStorageData.TemperatureUnits,
                        this.localStorageData.DistanceUnits,
                        this.localStorageData.DateFormatString, // Should be replaced with DateFormatEnum
                        'MM/dd/yyyy',
                        this.localStorageData.IsEmersonAdmin,
                        this.localStorageData.CanCreateShipment,
                        this.localStorageData.RememberMe,
                        this.localStorageData.ProviderUserKey,
                        this.localStorageData.IsPasswordChangeRequired,
                        this.localStorageData.CanAccessOversight,
                        this.localStorageData.CanAccessPakSense,
                        this.localStorageData.CanEditTripName,
                        this.localStorageData.IsLocusUserSupportAdmin,
                        this.localStorageData.IsLocusUser,
                        this.localStorageData.IsDashboardUser,
                        this.localStorageData.IsAdminUser,
                        this.localStorageData.IsEDIAPIConsumer,
                        this.localStorageData.IsLocusSupport
                    );
                    const psAuthToken = StoreManager.GetValue(STORE_KEYS.psAuthToken);
                    if (psAuthToken && this.localStorageData.CanAccessPakSense) {
                        psAuthStateSvc.signInSuccess(psAuthToken, this.localStorageData.username, this.localStorageData.username);
                    }
                    this.authStateSvc.authenticated$.pipe(skip(1));
                    this.authState$ = this.store.select('auth');
                    this.initSvc.InitAuthLoad();
                    if (!this.localStorageData.ExpiryDate || ((new Date()).getTime() > (new Date(this.localStorageData.ExpiryDate).getTime()))) {
                        this.authService.refreshToken();
                    }
                    else {
                        this.authService.setTokenExpireDate(new Date(this.localStorageData.ExpiryDate));
                    }
                }
            }
        }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        const locale = localeResolver.resolveLocale();
        return locale ? this.authState$.pipe(
            map(n => {
                if (!n.authenticated) {
                    if (!sessionStorage.getItem(environment.unAuthTrackers)) {
                        this.router.navigate(['signin']);
                    } else {
                        this.router.navigate(['/umapview']);
                    }
                    return false;
                } else {
                    if (n.IsPasswordChangeRequired) {
                        if (state.url !== '/changepassword') {
                            this.router.navigate(['changepassword']);
                            return false;
                        } else {
                            return true;
                        }
                    } else {
                        if (!n.isEmersonAdmin &&
                            (n.canAccessOversight === undefined || n.canAccessOversight == null)) {
                            this.authService.signOut();
                            this.router.navigate(['signin']);
                            return false;
                        }
                        const url = route && route.url && route.url.length > 0 ? route.url[0].path : '';
                        const hasAccess = this.authService.validatePageAccess(url, n);
                        if (!hasAccess) {
                            this.router.navigate(['unauthorized']);
                        }
                        return hasAccess;
                    }
                }
            })
        ) : of(false);
    }
}
