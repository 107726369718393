<emr-map #xmap [Options]="options" [Box]="box"
    (BoundsChange)="onBoundsUpdate($event)"
    (CenterChange)="onMapUpdate()" (MapClick)="onMapUpdate()"
    (MapDblClick)="onMapUpdate()" (MapRightClick)="onMapUpdate()"
    (ZoomChange)="onMapZoomUpdate($event)">
    <emr-map-marker-layer [MarkerOptions]="locationMarkers" [Visible]="true"
        [InfoBoxPlacement]="CenterAutoFit" 
        [IsShowInfoBox]="true" [InfoBoxTemplate]="detailInfoBoxTemplate"
        [ShowInfoBox]="ShowInfoBox" [HideInfoBox]="HideInfoBox"
        (MarkersCreated)="onMarkersCreated($event)"
        (InfoBoxClick)="onViewLocationClick($event)"
        (MarkerClick)="onMarkerClick($event)">
    </emr-map-marker-layer>
    <emr-map-draw-layer *ngFor="let locVM of visibleLocationBoundaries;" 
        [Visible]="locVM.HasBoundary" [IsEditable]="false"
        [drawOptions]="locVM.BoundaryDrawOptions">
    </emr-map-draw-layer>
</emr-map>
<emr-button identifier="loc_btnResetMapView"
    cssClass="btn-sm btn-loc-toggle btn-loc-toggle-map btnLocZoomMap btnLocResetZoomMap {{ isZoomed === true ? '' : 'btn-disable'}}"
    buttonIcon="reset-loc-map-icon" i18n-title="@@reset_zoom" title="Reset Zoom" (onClick)="onResetZoom()">
</emr-button>
<div class="d-none">
    <ng-template #detailInfoBoxTemplate let-ml>
        <app-location-info-box [location]="getDetailTemplateInfo(ml,'location')"
            [isZoomedInfoLevel]="isZoomedInfoLevel"
            [showInfoBoxZoom]="(zoomIndex == previousZoomIndex)">
        </app-location-info-box>
    </ng-template>
</div>