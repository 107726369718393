import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertSuppressionPopupComponent } from 'app-modules/alert-suppression/components/alert-suppression-popup/alert-suppression-popup.component';
import { AlertSuppressionPopup } from 'app-modules/core/models/alert-suppression-popup.model';
import { GetAlertSuspendStatusRequest } from 'emr-ng-shared';

@Injectable()
export class AlertSuppressionService extends AlertSuppressionPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenAlertSuppressionPopup(alertSuspendStatusRequest?: GetAlertSuspendStatusRequest): void {
        const initialState = {
            alertSuspendStatusRequest: alertSuspendStatusRequest
        };

        this.bsModalRef = this.modalSvc.show(
            AlertSuppressionPopupComponent,
            {
                initialState: initialState,
                class: 'modal-lg modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}


