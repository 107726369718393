import { DualVisibilityLinkCustomer } from 'emr-ng-shared';

import { ICustomerContextState } from 'app-modules/core/store/models/customer-context-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadLinkedCustomers implements ServerRequestAction {
    readonly type = actionIndex.LOAD_LINKED_CUSTOMERS;
}

export class LoadLinkedCustomersSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_LINKED_CUSTOMERS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_LINKED_CUSTOMERS;
    constructor(public linkedCustomerList: IListInfo<DualVisibilityLinkCustomer>) { }
}

export class LoadLinkedCustomersError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_LINKED_CUSTOMERS_ERROR;
    readonly callingAction = actionIndex.LOAD_LINKED_CUSTOMERS;
    constructor(public message: string) { }
}

export class CancelLoadLinkedCustomers implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.LOAD_LINKED_CUSTOMERS;
    readonly type = actionIndex.CANCEL_LOAD_LINKED_CUSTOMERS;
}

// export class SelectLinkedCustomer implements ServerRequestAction {
//     readonly type = actionIndex.SELECT_LINKED_CUSTOMER;
//     constructor(public customerId: number) { }
// }

// export class SelectLinkedCustomerSuccess implements ServerRequestCompleteAction {
//     readonly type = actionIndex.SELECT_LINKED_CUSTOMER_SUCCESS;
//     readonly callingAction = actionIndex.SELECT_LINKED_CUSTOMER;
//     constructor(public customerContext: ICustomerContextState) { }
// }

// export class SelectLinkedCustomerError implements ServerRequestCompleteAction {
//     readonly type = actionIndex.SELECT_LINKED_CUSTOMER_ERROR;
//     readonly callingAction = actionIndex.SELECT_LINKED_CUSTOMER;
//     constructor(public message: string) { }
// }

export class LoadLinkedShippers implements ServerRequestAction {
    readonly type = actionIndex.LOAD_LINKED_SHIPPERS;
}

export class LoadLinkedShippersSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_LINKED_SHIPPERS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_LINKED_SHIPPERS;
    constructor(public linkedShipperList: IListInfo<DualVisibilityLinkCustomer>) { }
}

export class LoadLinkedShippersError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_LINKED_SHIPPERS_ERROR;
    readonly callingAction = actionIndex.LOAD_LINKED_SHIPPERS;
    constructor(public message: string) { }
}

export class CancelLoadLinkedShippers implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.LOAD_LINKED_SHIPPERS;
    readonly type = actionIndex.CANCEL_LOAD_LINKED_SHIPPERS;
}