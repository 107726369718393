import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, switchMap, tap, catchError, share } from 'rxjs/operators';

import {
    ExportTrackerStateReportHeaders, ShipmentRequest, GetShipmentResponse
} from 'emr-ng-shared';

import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import { ISensorChart } from 'app-modules/core/store/models/sensor-chart-state.interface';
import { GetUserSettingRequest } from 'emr-ng-shared';
import { SetUserSettingRequest } from 'emr-ng-shared';
// import { GetSensorDataRequest } from 'emr-ng-shared/lib/services/emr-oversight/models/get-sensor-data-request.model';

import { SensorChartStateService } from 'app-modules/core/store/sensor-chart/sensor-chart-state.service';
import { UserSettingsService } from '../store/user-settings/user-settings.service';
@Injectable()
export class SensorChartService {
    isChartLoading$ = this.sensorChartStateSvc.isSensorChartLoading$;
    isTempInfoLoading$ = this.sensorChartStateSvc.isTempInfoLoading$;
    userSettings$ = this.userSettingsSvc.userSettings$;

    // public getSensorData$: Observable<ISensorChart> = muteFirst(
    //     this.getSensorChartLoader().pipe(startWith(null)),
    //     this.detailStateSvc.sensorChart$
    // );

    constructor(
        private oversightSvc: OversightApiService,
        private detailStateSvc: ShipmentDetailStateService,
        private sensorChartStateSvc: SensorChartStateService,
        private userSettingsSvc: UserSettingsService,

    ) { }

    public getSensorDataLoader(request) {
        return of(request).pipe(
            tap(n => this.detailStateSvc.loadSensorChart()),
            switchMap(n => this.getSensorData(n)),
            tap(
                (n) => this.detailStateSvc.loadSensorChartSuccess(n.chartData, n.chartDataInfo, n.sensorStateData),
                e => this.detailStateSvc.loadSensorChartError(e)
            ),
            share()
        );
    }

    private getSensorData(request) {
        return this.oversightSvc.GetSensorData(request).pipe(
            map(n => {
                let chartData = emptyList();
                if (n.Data) {
                    chartData = {
                        list: n.Data,
                        itemCount: n.Data.length,
                        isPaged: false
                    };
                }
                let chartDataInfo = {};
                if (n.DataInfo) {
                    chartDataInfo = n.DataInfo;
                }
                let sensorStateData = emptyList();
                if (n.SensorStateData) {
                    sensorStateData = {
                        list: n.SensorStateData,
                        itemCount: n.SensorStateData.length,
                        isPaged: false
                    };
                }
                return { chartData, chartDataInfo, sensorStateData } as ISensorChart;
            }),
            catchError(error => [])
        );
    }

    // Service for Store
    // private getSensorChartLoader(): Observable<ISensorChart> {
    //     return this.detailStateSvc.isSensorChartLoadRequired$.pipe(
    //         filter(isloadRequired => isloadRequired),
    //         tap(() => this.detailStateSvc.loadSensorChart()),
    //         switchMap((a) => this.getSensorChart()),
    //         tap(
    //             n => this.detailStateSvc.loadSensorChartSuccess(n.chartData, n.chartDataInfo, n.sensorStateData),
    //             e => this.detailStateSvc.loadSensorChartError(e)
    //         ),
    //         finalize(() => this.detailStateSvc.cancelLoadSensorChart()),
    //         catchError(() => of({} as ISensorChart)),
    //         share()
    //     );
    // }

    // private getSensorChart(): Observable<ISensorChart> {
    //     return this.detailStateSvc.selectedShipment$.pipe(
    //         switchMap(n => {
    //             // TODO
    //             // const request = new GetSensorDataRequest();
    //             const request = {
    //                 CustomerTrackerId: '',
    //                 Period: null,
    //                 BeginTimestamp: null,
    //                 EndTimestamp: null,
    //                 TagId: null,
    //                 Pin: null,
    //                 Type: null
    //             };
    //             const sensor = n.SensorListInfo[0];
    //             request.CustomerTrackerId = n.customerTrackerId;
    //             request.TagId = sensor.TagId;
    //             request.Pin = sensor.Pin;
    //             request.Type = SensorType.Temperature;
    //             request.Period = TimePeriod.ThisYear;
    //             return this.oversightSvc.GetSensorData(request);
    //         }),
    //         map(n => {
    //             let chartData = emptyList();
    //             if (n.Data) {
    //                 chartData = {
    //                     list: n.Data,
    //                     itemCount: n.Data.length,
    //                     isPaged: false
    //                 };
    //             }
    //             let chartDataInfo = {};
    //             if (n.DataInfo) {
    //                 chartDataInfo = n.DataInfo;
    //             }
    //             let sensorStateData = emptyList();
    //             if (n.SensorStateData) {
    //                 sensorStateData = {
    //                     list: n.SensorStateData,
    //                     itemCount: n.SensorStateData.length,
    //                     isPaged: false
    //                 };
    //             }
    //             return { chartData, chartDataInfo, sensorStateData } as ISensorChart;
    //         })
    //     );
    // }

    public exportPDFSensorReport(queryParams, extraHeaders: ExportTrackerStateReportHeaders) {
        return this.oversightSvc.ExportPDFSensorReport(queryParams, extraHeaders);
    }

    public exportExcelSensorReport(queryParams, extraHeaders: ExportTrackerStateReportHeaders) {
        return this.oversightSvc.ExportExcelSensorReport(queryParams, extraHeaders);
    }

    public setMinMax(request: SetUserSettingRequest) {
        return of(request).pipe(
            map(n => this.setMinMaxRequest(n)),
            share()
        );
    }
    private setMinMaxRequest(request) {
        return this.userSettingsSvc.deleteUserSettings(request);
    }

    public deleteMinMax(request: SetUserSettingRequest) {
        return of(request).pipe(
            map(n => this.deleteMinMaxRequest(n)),
            share()
        );
    }

    private deleteMinMaxRequest(request) {
        return this.userSettingsSvc.deleteUserSettings(request);
    }

    // GetShipmentData
    public GetSensorChart(request: ShipmentRequest): Observable<GetShipmentResponse> {
        return of(request).pipe(
            tap(n => this.sensorChartStateSvc.loadSensorChart()),
            switchMap(n => this.GetSensorChartData(n)),
            tap(
                n => this.sensorChartStateSvc.loadSensorChartSuccess(n),
                e => this.sensorChartStateSvc.loadSensorChartError(e)
            ),
            share()
        );
    }

    private GetSensorChartData(request: ShipmentRequest) {
        return this.oversightSvc.GetShipmentData(request).pipe(
            map(n => this.GetSensorChartDetails(n)),
            catchError(error => [])
        );
    }

    private GetSensorChartDetails(response: GetShipmentResponse) {
        return response;
    }

    public GetShipmentTempInformation(request: ShipmentRequest) {
        return of(request).pipe(
            tap(_ => this.updateTempInfoLoading(true)),
            switchMap(n => this.oversightSvc.GetShipmentTempInformation(n)),
            map(n => {
                this.updateTempInfoLoading(false);
                return this.GetSensorChartDetails(n);
            }),
            catchError(error => {
                this.updateTempInfoLoading(false);
                return [];
            })
        );
    }

    private updateTempInfoLoading(isLoading: boolean) {
        this.sensorChartStateSvc.isTempInfoLoading(isLoading);
    }
}
