import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

import * as actionIndex from 'app-modules/core/store/actions';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

export class LoadAlertSuspenseStatus implements ServerRequestAction {
    readonly type = actionIndex.LOAD_ALERT_SUSPENSE_STATUS;
}

export class LoadAlertSuspenseStatusSuccess implements ServerRequestCompleteAction {
    callingAction = actionIndex.LOAD_ALERT_SUSPENSE_STATUS;
    readonly type = actionIndex.LOAD_ALERT_SUSPENSE_STATUS_SUCCESS;
}

export class LoadAlertSuspenseStatusError implements ServerRequestCompleteAction {
    callingAction = actionIndex.LOAD_ALERT_SUSPENSE_STATUS;
    readonly type = actionIndex.LOAD_ALERT_SUSPENSE_STATUS_ERROR;

    constructor(public error: IErrorInfo) { }
}

export class UpdateAlertSuspenseStatus implements ServerRequestAction {
    readonly type = actionIndex.UPDATE_ALERT_SUSPENSE_STATUS;
}

export class UpdateAlertSuspenseStatusSuccess implements ServerRequestCompleteAction {
    callingAction = actionIndex.UPDATE_ALERT_SUSPENSE_STATUS;
    readonly type = actionIndex.UPDATE_ALERT_SUSPENSE_STATUS_SUCCESS;
}

export class UpdateAlertSuspenseStatusError implements ServerRequestCompleteAction {
    callingAction = actionIndex.UPDATE_ALERT_SUSPENSE_STATUS;
    readonly type = actionIndex.UPDATE_ALERT_SUSPENSE_STATUS_ERROR;

    constructor(public error: IErrorInfo) { }
}
