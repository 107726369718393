import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share , startWith } from 'rxjs/operators';

import { muteFirst, Contact, SetContactRequest, SetContactResponse } from 'emr-ng-shared';

import { ContactStateService } from './contact-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';

export class ContactList {
    get text() {
        return ((this.contact.FullName ? this.contact.FullName  + ' - ' : '') + this.contact.EmailAddress);
    }
    get value() { return this.contact.EmailAddress; }
    constructor(private contact: Contact) { }
}

export class ContactListFromEmails {
    get text() {
        return this.email;
    }
    get value() { return this.email; }
    constructor(private email: string) { }
}

@Injectable()
export class ContactService {
    constructor(
        private contactStateSvc: ContactStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.contactStateSvc.isLoadRequired$;

    public isLoading$ = this.contactStateSvc.isLoading$;

    public contactList$: Observable<IListInfo<Contact>> = muteFirst(
        this.getContactListLoader().pipe(startWith(null)),
        this.contactStateSvc.contactList$
    );

    private getContactListLoader(): Observable<IListInfo<Contact>> {
        return this.contactStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.contactStateSvc.loadContacts()),
            switchMap(() => this.getContactList()),
            tap(
                n => this.contactStateSvc.loadContactsSuccess(n),
                e => this.contactStateSvc.loadContactsError('')
            ),
            finalize(() => this.contactStateSvc.cancelLoadContacts()),
            catchError(() => {
                this.contactStateSvc.cancelLoadContacts();
                return of(emptyList());
            }),
            share()
        );
    }

    private getContactList(): Observable<IListInfo<Contact>> {
        return this.oversightSvc.GetContactsList().pipe(
            map(n => {
                return {
                    list: n.Contacts,
                    itemCount: n.Contacts.length,
                    isPaged: false
                };
            })
        );
    }

    public setContact(request: SetContactRequest) {
        return of(request).pipe(
            tap(n => this.contactStateSvc.setContact(n)),
            switchMap(n => this.oversightSvc.SetContact(n)),
            handleErrorResponse(),
            map<SetContactResponse, SetContactResponse>(n => n),
            tap(
                n => {
                    if (n?.ContactId) {
                        request.ContactId = n.ContactId;
                        const contact = this.getContactInfo(request);
                        this.contactStateSvc.setContactSuccess(contact);
                    } else {
                        this.contactStateSvc.cancelSetContact();
                    }
                },
                e => this.contactStateSvc.setContactError(e)
            )
        );
    }

    public getContactInfo(request: SetContactRequest): Contact {
        return {
            ContactId: request.ContactId,
            Type: request.ContactType,
            FirstName: request.FirstName,
            LastName: request.LastName,
            FullName: request.LastName + ', ' + request.FirstName,
            EmailAddress: request.EmailAddress,
            IsPrimary: request.IsPrimary,
            Address: request.Address,
            WorkPhone: request.WorkPhone,
            CellPhone: request.CellPhone,
            PreferredContactMethod: request.PreferredContactMethod,
            PreferredLanguage: request.PreferredLanguage,
            TimeZoneId: request.TimeZoneCode,
            Notes: request.Notes
        }
    }

  public getContactRequest(contact: Contact) {
    let request = new SetContactRequest();
    request.ContactId = contact.ContactId;
    request.LastName = contact.LastName;
    request.FirstName = contact.FirstName;
    request.Address = contact.Address;
    request.WorkPhone = contact.WorkPhone;
    request.CellPhone = contact.CellPhone;
    request.EmailAddress = contact.EmailAddress;
    request.PreferredContactMethod = contact.PreferredContactMethod;
    request.PreferredLanguage = contact.PreferredLanguage;
    request.TimeZoneCode = contact.TimeZoneId;
    request.ContactType = contact.Type;
    request.Notes = contact.Notes;
    // Set request.IsLocationContact from location contact page
    request.IsPrimary = contact.IsPrimary;
    return request;
  }

}





