import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { IPagingState } from 'app-modules/core/store/models/paging-state.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';

@Component({
    selector: 'app-list-view',
    templateUrl: './list-view.component.html',
    styleUrls: ['./list-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ListViewComponent implements OnInit, OnDestroy {
    constructor(
        private shipSvc: ShipmentService
    ) { }

    shipmentList$: Observable<Shipment[]>;
    shipmentCount$: Observable<number>;
    shipmentPaging$: Observable<IPagingState>;

    isLoadingSubscription: Subscription;

    isLoading: boolean;

    ngOnInit() {
        this.shipmentList$ = this.shipSvc.shipmentListPage$.pipe(map(n => n.list));
        this.shipmentCount$ = this.shipSvc.shipmentListPage$.pipe(map(n => n.itemCount));
        this.shipmentPaging$ = this.shipSvc.shipmentPaging$;

        this.isLoadingSubscription = this.shipSvc.isLoading$.subscribe(n => this.isLoading = n);
    }

    ngOnDestroy() {
        this.isLoadingSubscription.unsubscribe();
    }

    onPageSelected(pageNumber: number) {
        this.shipSvc.changeShipmentListPage(pageNumber);
    }

    onPageSizeSelected(pageSize: number) {
        this.shipSvc.changeShipmentListPageSize(pageSize);
    }
}


