<div class="content-wrapper">
    <div class="row">
        <div class="col-md-12">
            <ul class="bttnUnorderList">
                <li>
                    <div class="file btn btn-sm btn-primary">
                        <span i18n="@@label_select_excel">Select File to Import </span>
                        <input type="file" accept=".xlsx, .xls, .csv" (click)="onSelectExcelLocations($event)"
                            (change)="uploadExcelLocations($event.target.files)" class="btnFile submit-btn"
                            name="file" />
                    </div>
                </li>
                <li *ngIf="isValidImportData">
                    <emr-button identifier="Submit/Save"
                        cssClass="btn button-text submit-btn btn-outline-default mt-0 btnSaveLocations {{(isProcessed || !isValidImportData) ? 'btndisabled' : ''}}"
                        currentToolTip="Close" buttonIcon="fa fa-check" buttonText="Submit Locations"
                        i18n-buttonText="@@label_submit_locations" (onClick)="saveImportLocations()">
                    </emr-button>
                </li>
            </ul>
        </div>
        <div class="col-md-12">
            <a class="templateLink notranslate pl-sm-0" translate="no" href="{{locationTemplateURL}}"
                i18n="@@link_import_locations_template" download>Import Location's Template</a>
        </div>
    </div>
    <div class="row mt-2" *ngIf="error!=null">
        <div class="col-md-12">
            <span class="validationindicator">
                <div class="minmaxchart-validation my-1">
                    <div>{{error}}</div>
                </div>
            </span>
        </div>
    </div>

    <div class="row mt-2 location-table" *ngIf="!isProcessed; else processed">
        <div class="table-responsive common-table">
            <div class="table-data-container table-height col-md-12" *ngIf="importLocations?.length>0">
                <emr-custom-table [columnDefs]="columnDefs" [data]="importLocations" [selectableRow]="true"
                    [showColumnSelection]="false" [tBodyId]="'full-grid-view-reset'" [applyFilter]="false" [sort]="true"
                    [calculateColumnCount]="false" [divHeight]="'calc(100vh - 180px - var(--banner-height))'">
                </emr-custom-table>
            </div>

        </div>
    </div>

    <div class="d-none">
        <ng-template #exceptions let-rowData>
            <div *ngIf="rowData.IsDuplicateLocation||rowData.Skip;else showExceptions;">
                <emr-button identifier="duplicateLocation"
                    cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background" buttonText="Duplicate"
                    i18n-buttonText="@@duplicate" (onClick)="duplicateLocationCheck(rowData)">
                </emr-button>
                <label class="label-css text-danger" *ngIf="rowData.Skip">{{label_will_not_inserted}}</label>
            </div>
            <ng-template #showExceptions>
                <span>{{rowData.Exceptions}}</span>
            </ng-template>
        </ng-template>
    </div>

    <ng-template #processed>
        <div id="details" class="col-md col pl-1">
            <div>
                <div class="nav-tab pb-0" *ngIf="hasException"
                    [ngClass]="{'nav-tab-selected':selectedView === views.EXCEPTIONS}"
                    (click)="onNavTabSelected(views.EXCEPTIONS)" i18n="@@label_exceptions">Exceptions</div>
                <div class="nav-tab pb-0" [ngClass]="{'nav-tab-selected':selectedView === views.SUCCESSFUL}"
                    (click)="onNavTabSelected(views.SUCCESSFUL)" i18n="@@label_successfully_imported">Successfully
                    Imported</div>
            </div>
            <div [ngSwitch]="selectedView">
                <div class="table-responsive common-table mt-1">
                    <div class="table-data-container table-height col-md-12" *ngSwitchCase="views.EXCEPTIONS">
                        <emr-custom-table [columnDefs]="exceptionColumnDefs" [data]="importExceptions"
                            [selectableRow]="true" [showColumnSelection]="false" [tBodyId]="'full-grid-view-reset'"
                            [applyFilter]="false" [sort]="true" [calculateColumnCount]="false"
                            [divHeight]="'calc(100vh - 235px)'">
                        </emr-custom-table>
                    </div>
                    <div class="table-data-container table-height col-md-12" *ngSwitchCase="views.SUCCESSFUL">
                        <emr-custom-table [columnDefs]="successColumnDefs" [data]="importSuccessful"
                            [selectableRow]="true" [showColumnSelection]="false" [tBodyId]="'full-grid-view-reset'"
                            [applyFilter]="false" [sort]="true" [calculateColumnCount]="false"
                            [divHeight]="'calc(100vh - 235px)'">
                        </emr-custom-table>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #nearestLocationsModal>
    <emr-modal headerTitle="Locations" i18n-headerTitle="@@label_locations" [bsModalRef]=modalRef>
        <nearest-locations modal-body [nearestLocations]="nearestLocations" [CurrentLocation]="CurrentLocation"
            [isFromImportLocations]=true (continueClick)="onNearestLocationSelect($event)"></nearest-locations>
    </emr-modal>
</ng-template>