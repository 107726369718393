import { ShipmentInfo2, ChartData, ChartDataInfo } from 'emr-ng-shared';

import { ChartInfo2 } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { SensorStateInfo } from 'emr-ng-shared/lib/services/emr-oversight/models/sensor-state-info.model';

export class LoadSensorChart implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART;
}

export class LoadSensorChartSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART;
    constructor(
        public chartData: IListInfo<ChartData>,
        public chartDataInfo: ChartDataInfo,
        public sensorStateData: IListInfo<SensorStateInfo>
    ) { }
}

export class LoadSensorChartError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART;

    constructor(public message: string) { }
}

export class CancelLoadSensorChart implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_SENSOR_CHART;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART;
}

export class LoadExportSensorReport implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT;
}

export class LoadExportSensorReportSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT;
    constructor() { }
}

export class LoadExportSensorReportError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT;
    constructor() { }
}

export class CancelLoadExportSensorReport implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_EXPORT_SENSOR_REPORT;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT;
    constructor() { }
}
