import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share , startWith } from 'rxjs/operators';

import { muteFirst } from 'emr-ng-shared';

import { GetAlertTypesRequest } from 'emr-ng-shared';
import { AlertTypeInfo } from 'emr-ng-shared';
import { AlertType } from 'emr-ng-shared';

import { AlertTypeStateService } from 'app-modules/core/store/services/alert-type-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class AlertTypeService {
    constructor(
        private alertStateSvc: AlertTypeStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.alertStateSvc.isLoadRequired$;

    public isLoading$ = this.alertStateSvc.isLoading$;

    public alertTypeList$: Observable<IListInfo<AlertTypeInfo>> = muteFirst(
        this.getAlertTypeListLoader().pipe(startWith(null)),
        this.alertStateSvc.alertTypeList$
    );

    private getAlertTypeListLoader(): Observable<IListInfo<AlertTypeInfo>> {
        return this.alertStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.alertStateSvc.loadAlertTypes()),
            switchMap(() => this.getAlertTypeList()),
            tap(
                n => this.alertStateSvc.loadAlertTypesSuccess(n),
                e => this.alertStateSvc.loadAlertTypesError('')
            ),
            finalize(() => this.alertStateSvc.cancelLoadAlertTypes()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getAlertTypeList(): Observable<IListInfo<AlertTypeInfo>> {
        const request = new GetAlertTypesRequest();

        request.RequestedAlertTypes = [
            AlertType.CumulativeTemperatureAboveSensorRange,
            AlertType.CumulativeTemperatureBelowSensorRange,
            AlertType.EtaViolation,
            AlertType.Accelerometer
        ];

        return this.oversightSvc.GetAlertTypes(request).pipe(
            map(n => {
                return {
                    list: n.AlertTypes,
                    itemCount: n.AlertTypes.length,
                    isPaged: false
                };
            })
        );
    }
}





