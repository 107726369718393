import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkedCustomersService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { CustomerLinkType, DualVisibilityLinkCustomer } from 'emr-ng-shared';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
declare type selectableSupplier = DualVisibilityLinkCustomer & { checked: boolean };
@Component({
  selector: 'app-shipment-filter-supplier',
  templateUrl: './shipment-filter-supplier.component.html',
  styleUrls: ['./shipment-filter-supplier.component.css']
})
export class ShipmentFilterSupplierComponent implements OnInit {
  supplierList: selectableSupplier[];
  constructor(private linkedCustSvc: LinkedCustomersService,private filterSvc: ShipmentFilterService) { }

  filterVal: number[];
  isDirtyVal: boolean;
  // bindable isDirty
  @Input() get isDirty() { return this.isDirtyVal; }
  @Output() isDirtyChange = new EventEmitter();
  set isDirty(val) {
    this.isDirtyVal = val;
    this.isDirtyChange.emit(this.isDirtyVal);
  }

  @Input() get filter() { return this.filterVal; }
  @Output() filterChange = new EventEmitter();

  // tslint:disable-next-line: adjacent-overload-signatures
  set filter(val) {
    this.filterVal = val;
    this.filterChange.emit(this.filterVal);
  }
  
  private supplierListSubscription: Subscription;
  private supplierFilterSubscription: Subscription;
 

  public ngOnInit() {
    this.supplierListSubscription = this.linkedCustSvc.linkedShipperList$.subscribe(n => this.onSupplierListChange(n.list.filter(x =>x.ShipperCustomerActive && x.LinkedTypeCode === CustomerLinkType.PUSH)));
    this.supplierFilterSubscription = this.filterSvc.supplierFilter$.subscribe(n => this.onSupplierFilterChange(n));
}

public ngOnDestroy() {
    this.supplierListSubscription?.unsubscribe();
    this.supplierFilterSubscription?.unsubscribe();
}

onSupplierListChange(supplierList: DualVisibilityLinkCustomer[]) {
    this.supplierList = [];

    if (!supplierList) { return; }
    supplierList.forEach(item => {
        this.supplierList.push({ ...item, checked: this.filter && this.filter.indexOf(item.ShipperCustomerID) >= 0 });
    });
}

onSupplierFilterChange(filter: number[]) {
    this.isDirty = false;
    this.filter = filter;
    if (this.filter) {
        this.supplierList.forEach(item => item.checked = this.filter.indexOf(item.ShipperCustomerID) >= 0);
    }
}

}
