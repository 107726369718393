<div class="table-data-container container">
    <div class="row">
        <div class="col-md-12 px-0 pt-3 pb-2">
            <emr-datepicker [placeholder]="placeholder" [identifier]="'dashboard-picker'" [minDate]="" [maxDate]=""
                [helpTip]="" [helpText]="" [cssClass]="'calendar-text'" [name]="'dashboard-picker'"
                [showPickerOnTop]="false" [dateInputFormatString]="dateTimeFormat"
                [disableOtherMonths]="disableOtherMonths" [(ngModel)]="selectedMonth" [isDisabled]="false"
                [displayValidationMessage]="false" [containerValue]="" placement="{{placement}}"
                (onShown)="onOpenCalendar($event)">
            </emr-datepicker>
        </div>
        <div class="col-md-12 pt-1  dashboard-table">
            <custom-table [columnDefs]="columnDefs" [data]="shipmentList$|async" (rowClick)="onRowClick($event)"
                [selectableRow]="true" [showColumnSelection]="false" [divClass]="''" [tBodyId]="'full-grid-view-reset'"
                [divHeight]="dataDivHeight" (filterApply)="onFilterApply($event)" [applyFilter]="true" [sort]="sort"
                [calculateColumnCount]="true" (columnDefsChange)="onColumnChange($event)"
                [isLoading]="isDashboardShipmentsLoading$|async">
            </custom-table>
            <div class="d-none">
                <ng-template #tripStatus let-rowData>
                    <div class="table-row-data" [ngClass]="{
                        'status-completed': rowData.tripStateCode === tripState.Completed,
                        'status-progress': rowData.tripStateCode === tripState.InProgress
                    }">
                        <span>{{ rowData.tripId ? rowData.tripStatus : 'n/a' }}</span>
                    </div>
                </ng-template>
            </div>
            <div class="d-none">
                <ng-template #aggregateTemperature let-value="value" let-align="align" let-rowspan="rowspan"
                    let-width="width">
                    <td class="table-row-data temp-no-Range" [ngClass]="{
                        'temp-above-range': value > aboveTemperatureRange,
                        'temp-below-range': value < belowTemperatureRange,
                        'temp-in-range': value >= belowTemperatureRange && value <= aboveTemperatureRange,
                        'right-aligned': align === 'right',
                    'left-aligned': align === 'left',
                    'center-aligned': align === 'center'
                    }" [attr.rowspan]="rowspan" [style.width.%]="width">
                        <div class="table-row-data">
                            <span>{{ value }}</span>
                        </div>
                    </td>
                </ng-template>
            </div>
        </div>
        <div class="col-md-6 pl-0 mt-4">
            <app-map-chart [title]="'Total Trips Delivered'" [subTitle]="'Total Trips'" [hasCustomLegends]="false"
                [minInterval]="0" [maxInterval]="50" [keyProperty]="'TripDestinationAddressState'" [interval]="10"
                [startColor]="'#ffffff'" [aggregateType]="aggregateCount" [endColor]="'#f00a0a'"
                [seriesName]="'Number of Trips'" [hoverColor]="'#00008B'" [chartData]="shipmentList$|async">
            </app-map-chart>
        </div>
        <div class="col-md-6 pr-0 mt-4">
            <app-map-chart [title]="'Delivery Temperature to Target by state'" [subTitle]="'Average Temperature'"
                [hasCustomLegends]="true" [keyProperty]="'TripDestinationAddressState'"
                [avgProperty]="'MeanKineticTemperature'" [seriesName]="'Average Temperature'" [hoverColor]="'#00008B'"
                [aboveTemperatureRange]="aboveTemperatureRange" [belowTemperatureRange]="belowTemperatureRange"
                [aboveTemperatureRangeColor]="'#fcb64c'" [aggregateType]="aggregateAvg"
                [inTemperatureRangeColor]="'#75d45b'" [belowTemperatureRangeColor]="'#9fe3ee'"
                [chartData]="shipmentList$|async"></app-map-chart>
        </div>
        <div class="col-md-6 px-0 piechart">
            <app-pie-chart [title]="'Total Trips Delivered'" [subTitle]="'Total Trips'" [property]="'TemperatureString'"
                [aboveTemperatureRange]="aboveTemperatureRange" [belowTemperatureRange]="belowTemperatureRange"
                [aggregateType]="aggregateCount" [seriesName]="'Number of Trips'" [chartData]="shipmentList$|async">
            </app-pie-chart>
        </div>
    </div>
</div>