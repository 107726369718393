import { DistanceUnits, IDrawOptions, ILatLong, LocationInfo, PolygonType, TraxxInfo } from "emr-ng-shared";
import { ImageMapService } from "../services/image-map.service";

export class LocationBoundaryViewModel {
    BoundaryDrawOptions: IDrawOptions;
    ShowBoundary: boolean;
    boundaryLocs: ILatLong[];
    locationId: number;
    HasBoundary: boolean;

    constructor(private loc: LocationInfo,
        private imgSvc: ImageMapService,
        private distanceUnits: DistanceUnits,
        noFenceLocationRadius: number = 0,
        isCollision: boolean = false,
        imageId: string = 'dw') {
        this.locationId = loc.LocationId;
        const strokeColor = this.findStrokeColor(imageId);
        if (loc.Boundary?.length > 0) {
            const boundaryLocation = this.loc.Boundary.map(l => {
                return { latitude: l.Latitude, longitude: l.Longitude };
            });
            this.boundaryLocs = boundaryLocation;
            this.BoundaryDrawOptions = {
                strokeColor,
                boundaryLocation,
                polygonType: PolygonType.FreeFlow
            };
            this.HasBoundary = true;
        } else if (!!loc.RadiusKm) {
            this.BoundaryDrawOptions = {
                center: { latitude: loc.Latitude, longitude: loc.Longitude },
                radius: +loc.RadiusKm,
                strokeColor,
                polygonType: PolygonType.Circle,
                units: this.distanceUnits
            };
            this.HasBoundary = true;
        } else if (!!noFenceLocationRadius) {
            this.BoundaryDrawOptions = {
                center: { latitude: loc.Latitude, longitude: loc.Longitude },
                radius: +noFenceLocationRadius,
                strokeColor: 'Teal',
                polygonType: PolygonType.Circle,
                units: this.distanceUnits
            };
            this.HasBoundary = true;
        }
        this.ShowBoundary = this.HasBoundary;
    }

    private findStrokeColor(imageId: string) {

        switch (imageId) {
            case 'dwy':
                return 'yellow';
            case 'dwo':
                return 'orange';
            case 'dwb':
                return 'black';
            case 'OI':
            case 'bcs':
                return 'green';
            case 'DI':
            case 'bce':
                return 'blue';
            case 'dw':
            case 'yr':
                return 'red';
            default:
                return 'grey';
        }
    }
}

export class LocationBoundaryEntity {
    [key: number]: LocationBoundaryViewModel
}