// tslint:disable: quotemark object-literal-key-quotes

export const IMAGE_MAP_VALUE = {
    "ac": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/AlertsCritical.svg",
    "ach": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/AlertsCriticalEnvironmentalHumidity.svg",
    "act": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/AlertsCriticalEnvironmentalTemp.svg",
    "acvd": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/AlertsCriticalYardViolationDwell.svg",
    "as": "/LocusOversight/V2/WebApp/Images/Common/AppImages/Events/AlertSuppressed.png",
    "ash": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/Shock.svg",
    "aw": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/AlertsWarning.svg",
    "awt": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/AlertsWarningEnvironmentalTemp.svg",
    "bce": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/BreadCrumbEnd.svg",
    "bcs": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/BreadCrumbStart.svg",
    "cc": "/LocusOversight/V2/WebApp/Images/Common/AppImages/late/20x20/m/Cold-Crit.svg",
    "dd": "/LocusOversight/V2/WebApp/Images/Common/AppImages/icons/dwell-estimated.svg",
    "de": "/LocusOversight/V2/WebApp/Images/Common/AppImages/20x20/deleted.svg",
    "dw": "/LocusOversight/V2/WebApp/Images/Common/AppImages/DroppedPin.svg",
    "hc": "/LocusOversight/V2/WebApp/Images/Common/AppImages/late/20x20/m/Hot-Crit.svg",
    "l": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/LightOn.svg",
    "ll": "/LocusOversight/V2/WebApp/Images/Common/AppImages/Events/LeaveLocation.png",
    "lo": "/LocusOversight/V2/WebApp/Images/Common/AppImages/late/20x20/m/Safety-Light.svg",
    "loc": "/LocusOversight/V2/WebApp/Images/Common/AppImages/Events/EnterLocation.png",
    "ns": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/TrackersAwol.svg",
    "t": "/LocusOversight/V2/WebApp/Images/Common/AppImages/on-time/LT Icon 512 Truck-OK.svg",
    "t-l-r": "/LocusOversight/V2/WebApp/Images/Common/AppImages/late/LT Icon 512 Truck-Late.svg",
    "t-l-s": "/LocusOversight/V2/WebApp/Images/Common/AppImages/late/LT Icon 512 Truck-Stopped-Late.svg",
    "t-o-s": "/LocusOversight/V2/WebApp/Images/Common/AppImages/on-time/LT Icon 512 Truck-Stopped-OK.svg",
    "y": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/Yard.svg",
    "yr": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/Yard-red.svg",
    "dwb": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-black.svg",
    "dwo": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-orange.png",
    "dwy": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-yellow.png",
    "ebt": "/LocusOversight/V2/WebApp/Images/Logo/copeland_blue_transparent.png",
    "el": "/LocusOversight/V2/WebApp/Images/Logo/copeland-logo.png",
    "nl-p": "/LocusOversight/V2/WebApp/Images/Logo/NoLogo.png",
    "pr-1": "/LocusOversight/V2/WebApp/Images/Product/FX2-PP00.GO Real-Time Flex.png",
    "pr-2": "/LocusOversight/V2/WebApp/Images/Product/RX2-TL00.GO Real-Time Reusable.png",
    "pr-3": "/LocusOversight/V2/WebApp/Images/Product/RX3-TL00.GO Real-Time Reusable 2G3G.png",
    "pr-4": "/LocusOversight/V2/WebApp/Images/Product/SX2-BG00.GO Real-Time Secure.png",
    "pr-5": "/LocusOversight/V2/WebApp/Images/Product/TS2-TL00.GO Real-Time Lux.png",
    "pr-6": "/LocusOversight/V2/WebApp/Images/Product/TS2-TT00.GO Real-Time.png",
    "pr-7": "/LocusOversight/V2/WebApp/Images/Product/TS3-TL00.GO Real-Time Lux 2G3G.png",
    "pr-8": "/LocusOversight/V2/WebApp/Images/Product/TS3-TT00.GO Real-Time 2G3G.png",
    "pr-9": "/LocusOversight/V2/WebApp/Images/Product/TX2-CL00.GO Real-Time CO2.png",
    "pr-10": "/LocusOversight/V2/WebApp/Images/Product/TX2-TL00.GO Real-Time XL.png",
    "pr-11": "/LocusOversight/V2/WebApp/Images/Product/TX3-TL00.GO Real-Time XL 2G3G.png",
    "pr-12": "/LocusOversight/V2/WebApp/Images/Product/282H/FX2-PP00.png",
    "pr-13": "/LocusOversight/V2/WebApp/Images/Product/282H/RX2-TL00.png",
    "pr-14": "/LocusOversight/V2/WebApp/Images/Product/282H/RX3-TL00.png",
    "pr-15": "/LocusOversight/V2/WebApp/Images/Product/282H/RXC-TH00.png",
    "pr-16": "/LocusOversight/V2/WebApp/Images/Product/282H/SX2-BG00.png",
    "pr-17": "/LocusOversight/V2/WebApp/Images/Product/282H/TS2-TL00.png",
    "pr-18": "/LocusOversight/V2/WebApp/Images/Product/282H/TS2-TT00.png",
    "pr-19": "/LocusOversight/V2/WebApp/Images/Product/282H/TS3-TL00.png",
    "pr-20": "/LocusOversight/V2/WebApp/Images/Product/282H/TS3-TT00.png",
    "pr-21": "/LocusOversight/V2/WebApp/Images/Product/282H/TSC-TH00.png",
    "pr-22": "/LocusOversight/V2/WebApp/Images/Product/282H/TX2-CL00.png",
    "pr-23": "/LocusOversight/V2/WebApp/Images/Product/282H/TX2-TL00.png",
    "pr-24": "/LocusOversight/V2/WebApp/Images/Product/282H/TX3-TL00.png",
    "pr-25": "/LocusOversight/V2/WebApp/Images/Product/282H/TXC-TH00.png",
    "pr-26": "/LocusOverSight/V2/WebApp/Images/Product/282H/TS2-TTC7.png",
    "pr-d": "/LocusOverSight/V2/WebApp/Images/Product/282H/DEFAULT.png",
    "OI": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/cte/Origin.svg",
    "DI": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/cte/Destination.svg",
    "acd": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/cte/small/AlertsCriticalTripDoorOpenNotAtDestination.png",
    "cct": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/Cold-Crit.svg",
    "hct": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/big/Hot-Crit.svg",
    "dwg": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-grey.svg",
    "aeh": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/AlertsEnvironmentalHumidity.svg",
    "aec": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/AlertsEnvironmentalCO2.svg",
    "sd": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/SensorDisconnect.svg",
    "sr": "/LocusOversight/V2/WebApp/Images/Common/AppImages/cte/small/SensorReconnect.svg",
    "dwwi" : "/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-wifi.svg",
    "dwwc":"/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-wifi-cell.svg",
    "dwc": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-cell.svg",
    "dwfi":"/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-filled.svg",
    "dwli": "/LocusOverSight/V2/WebApp/Images/Common/AppImages/icons/dwell-lined.svg",
};