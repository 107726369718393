import { Injectable } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith, withLatestFrom } from 'rxjs/operators';

import { muteFirst, CustomerDashboardInfo, SetCustomerDashboardResponse, EmrUtilService, SetDashboardCustomerRequest, DashboardInfo, Customer } from 'emr-ng-shared';

import { CustomerDashboardStateService } from './customer-dashboard-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { label_no, label_yes } from 'app-modules/core/consts/localization';

@Injectable()
export class CustomerDashboardService {
    constructor(
        private custDashboardStateSvc: CustomerDashboardStateService,
        private oversightSvc: OversightApiService,
        private custSvc: CustomersService,
    ) { }

    public isLoadRequired$ = this.custDashboardStateSvc.isLoadRequired$;

    public isLoading$ = this.custDashboardStateSvc.isLoading$;

    public customerDashboardList$: Observable<IListInfo<CustomerDashboardInfo>> = muteFirst(
        this.getCustomerDashboardListLoader().pipe(startWith(null)),
        this.custDashboardStateSvc.customerDashboardList$
    );

    private getCustomerDashboardListLoader(): Observable<IListInfo<CustomerDashboardInfo>> {
        return combineLatest(this.custDashboardStateSvc.isLoadRequired$,
            this.custSvc.isLoadRequired$,
            this.custSvc.isCustomerLoading$,
            (isDBLoadRequired, isCustLoadRequired, isCustLoading) => {
                return isDBLoadRequired && !isCustLoadRequired && !isCustLoading
            }).pipe(
                filter(isloadRequired => isloadRequired),
                tap(() => this.custDashboardStateSvc.loadCustomerDashboards()),
                withLatestFrom(this.custSvc.customerList$),
                switchMap(([isloadRequired, customerList]) => this.getCustomerDashboardList(customerList?.list)),
                tap(
                    n => this.custDashboardStateSvc.loadCustomerDashboardsSuccess(n),
                    e => this.custDashboardStateSvc.loadCustomerDashboardsError('')
                ),
                finalize(() => this.custDashboardStateSvc.cancelLoadCustomerDashboards()),
                catchError(() => {
                    this.custDashboardStateSvc.cancelLoadCustomerDashboards();
                    return of(emptyList());
                }),
                share()
            );
    }

    private getCustomerDashboardList(CustomerList: Customer[]): Observable<IListInfo<CustomerDashboardInfo>> {
        return this.oversightSvc.GetCustomerDashboards().pipe(
            map(n => {
                return {
                    list: this.getCustomerDashboardInfoList(n.CustomerDashboards, CustomerList),
                    itemCount: n.CustomerDashboards.length,
                    isPaged: false
                };
            })
        );
    }

    public setCustomerDashboard(request: SetDashboardCustomerRequest) {
        return of(request).pipe(
            tap(n => this.custDashboardStateSvc.setCustomerDashboard(n)),
            switchMap(n => this.oversightSvc.SetCustomerDashboard(n)),
            handleErrorResponse(),
            map<SetCustomerDashboardResponse, SetCustomerDashboardResponse>(n => n),
            tap(
                n => {
                    if (n?.CustomerDashboardID) {
                        request.DashboardCustomerId = n.CustomerDashboardID;
                        const dashboard = this.getCustomerDashboardInfo(request);
                        this.custDashboardStateSvc.setCustomerDashboardSuccess(dashboard);
                    } else {
                        this.custDashboardStateSvc.cancelSetCustomerDashboard();
                    }
                },
                e => this.custDashboardStateSvc.setCustomerDashboardError(e)
            )
        );
    }

    public getCustomerDashboardInfo(request: SetDashboardCustomerRequest): CustomerDashboardInfo {
        return {
            DashboardCustomerID: request.DashboardCustomerId,
            DashboardID: request.DashboardId,
            CustomerID: request.CustomerId,
            CustomerName: request.CustomerName,
            DashboardName: request?.DashboardName,
            IntegrationID: request?.IntegrationID,
            IsPremium: request?.IsPremium,
            IsPremiumText: request?.IsPremium ? label_yes : label_no
        }
    }

    public getCustomerDashboardInfoList(data: CustomerDashboardInfo[], CustomerList: Customer[]): CustomerDashboardInfo[] {
        return data.map(custDashboardInfo => this.CustomerDashboardData(custDashboardInfo, CustomerList));
    }

    private CustomerDashboardData(data: CustomerDashboardInfo, CustomerList: Customer[]) {
        const custDashboardInfo = new CustomerDashboardInfo();
        custDashboardInfo.DashboardCustomerID = data.DashboardCustomerID;
        custDashboardInfo.DashboardID = data.DashboardID;
        custDashboardInfo.CustomerID = data.CustomerID;
        custDashboardInfo.CustomerName = CustomerList.find(a => a.CustomerId === data.CustomerID)?.Description;
        custDashboardInfo.DashboardName = data?.DashboardName;
        custDashboardInfo.IntegrationID = data?.IntegrationID;
        custDashboardInfo.IsPremium = data?.IsPremium;
        custDashboardInfo.IsPremiumText = data?.IsPremium ? label_yes : label_no;
        return custDashboardInfo;
    }

    public deleteCustomerDashboard(request: SetDashboardCustomerRequest) {
        return of(request).pipe(
            tap(n => this.custDashboardStateSvc.deleteCustomerDashboard(n)),
            switchMap(n => this.oversightSvc.DeleteCustomerDashboard(n.DashboardCustomerId)),
            handleErrorResponse(),
            map<SetCustomerDashboardResponse, SetCustomerDashboardResponse>(n => n),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.custDashboardStateSvc.deleteCustomerDashboardSuccess(n.CustomerDashboardID);
                    } else {
                        this.custDashboardStateSvc.cancelDeleteCustomerDashboard();
                    }
                },
                e => this.custDashboardStateSvc.deleteCustomerDashboardError(e)
            )
        );
    }
}
