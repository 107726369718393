import { IShipmentSortState } from 'app-modules/core/store/models/shipment-sort-state.interface';
import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as sortActions from 'app-modules/core/store/actions/shipment-sort.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    sortActions.LoadShipmentSortList |
    sortActions.LoadShipmentSortListSuccess |
    sortActions.LoadShipmentSortListError |
    sortActions.ChangeShipmentSortWithOutListUpdate |
    sortActions.ChangeShipmentSort |
    custActions.SelectCustomer;

export function ShipmentSortReducer(
    state: IShipmentSortState = initialState.shipmentSort,
    action: Actions
): IShipmentSortState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentSort;

        case actionIndex.LOAD_SHIPMENT_SORT_LIST:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_SHIPMENT_SORT_LIST_SUCCESS:
            return {
                ...state,
                sortList: action.sortList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_SHIPMENT_SORT_LIST_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.CHANGE_SHIPMENT_SORT:
        case actionIndex.CHANGE_SHIPMENT_SORT_WITH_OUT_LIST_UPDATE:
            return {
                ...state,
                selectedSort: action.selectedSort,
                isDescending: action.isDescending
            };

        case actionIndex.SELECT_CUSTOMER:
            return {
                ...state,
                selectedSort: 'TripStateCode',
                isDescending: false
            }

        default:
            return state;

    }
}
