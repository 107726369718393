import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-u-map-view',
  templateUrl: './u-list-view.component.html',
  styleUrls: ['./u-list-view.component.css']
})
export class UMapViewComponent implements OnInit {
  constructor(
    private uService: UnAuthService,
    private routerSvc: Router) {
    if (sessionStorage.getItem(environment.unAuthTrackers)) {
      if (localStorage.getItem(environment.isBrowerRefreshed) === '1') {
        setTimeout(() => {
          this.uService.loadShipmentList();
        });
      }
      this.uService.setUnAuthenticationToken(true);
    } else {
      this.routerSvc.navigate(['/signin']);
    }
  }

  ngOnInit() {
    // this.uService.unAuthState$.subscribe(n => {
    //   if (!n.UnAuthTrackers) {
    //     this.uService.setUnAuthenticationToken(true);
    //   } else {
    //     this.routerSvc.navigate(['/signin']);
    //   }
    // });

    if (!this.uService.validateUSettingsForNull()) {
      this.uService.hideSettingsMenu(false);
    }

  }
}


