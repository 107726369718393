import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';

import { AlertTypeInfo } from 'emr-ng-shared';

import { IShipmentFilterAlertType } from 'app-modules/core/store/models/shipment-filter-alert-type.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { AlertTypeService } from 'app-modules/core/services/alert-type.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import * as _ from 'lodash';

export interface AlertType {
    AlertTypeCode: string;
    Description: string;
}

export enum EnumAlertTypeKey {
    AboveTemp = 'aboveTemp',
    BelowTemp = 'belowTemp',
    Late = 'late',
    Moving = 'moving',
    NotMoving = 'notMoving'
}

export enum EnumAlertTypeDescription {
    AboveTemp = 'Above Temperature',
    BelowTemp = 'Below Temperature',
    Late = 'Late',
    Moving = 'Moving',
    NotMoving = 'Not Moving'
}

@Component({
    selector: 'app-shipment-filter-alert-summary',
    templateUrl: 'shipment-filter-alert-summary.component.html',
    styleUrls: ['shipment-filter-alert-summary.component.css']
}) export class ShipmentFilterAlertSummaryComponent implements OnInit, OnDestroy {
    alertList: AlertTypeInfo[] = [];
    filter: IShipmentFilterAlertType;
    cloneFilter: IShipmentFilterAlertType;
    selectedList: AlertType[] = [];
    EnumAlertTypeDescription = Object.assign({}, EnumAlertTypeDescription);
    EnumAlertTypeKey = Object.assign({}, EnumAlertTypeKey);
    alertFilterSubscription: Subscription;
    @Input() shipmentFilter: IShipmentFilterState;
    @Input() isHugeCustomer: boolean;
    alertType: AlertType;
    @Output() summaryRemove = new EventEmitter();

    constructor(
        private alertSvc: AlertTypeService,
        private shipmentFilterService: ShipmentFilterService
    ) { }

    ngOnInit() {
        const initialAlertTypeValues: AlertType = {
            AlertTypeCode: '',
            Description: ''
        };
        this.alertType = initialAlertTypeValues;

        this.alertFilterSubscription = this.shipmentFilterService.alertFilter$.subscribe(n => this.onAlertFilterSubscription(n));
    }

    ngOnDestroy() {
        this.alertFilterSubscription.unsubscribe();
    }

    onAlertFilterSubscription(filter: IShipmentFilterAlertType) {
        this.filter = filter;
        this.cloneFilter = _.cloneDeep(filter);
    }

    clearSelectedFilter(selectedFilter: string) {
        switch (selectedFilter) {
            case EnumAlertTypeKey.AboveTemp:
                {
                    this.shipmentFilter.alert.aboveTemp = false;
                    break;
                }
            case EnumAlertTypeKey.BelowTemp:
                {
                    this.shipmentFilter.alert.belowTemp = false;
                    break;
                }
            case EnumAlertTypeKey.Late:
                {
                    this.shipmentFilter.alert.late = false;
                    break;
                }
            case EnumAlertTypeKey.Moving:
                {
                    this.shipmentFilter.alert.moving = false;
                    break;
                }
            case EnumAlertTypeKey.NotMoving:
                {
                    this.shipmentFilter.alert.notMoving = false;
                    break;
                }
            default: {
                break;
            }
        }
        this.shipmentFilterService.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        if (this.summaryRemove) {
            this.summaryRemove.emit();
        }
    }
}


