import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ConsoleLogRequestResponseInterceptor } from './console-log-request-response.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  // {
  //   provide: HTTP_INTERCEPTORS, useClass: ConsoleLogRequestResponseInterceptor, multi: true
  // },
];
