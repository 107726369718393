import { Action } from '@ngrx/store';

import { Contact, SetContactRequest } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from '../actions/custom-actions';

export class LoadContacts implements Action {
    readonly type = actionIndex.LOAD_CONTACT;
}

export class LoadContactsSuccess implements Action {
    readonly type = actionIndex.LOAD_CONTACT_SUCCESS;
    constructor(public contactList: IListInfo<Contact>) { }
}

export class LoadContactsError implements Action {
    readonly type = actionIndex.LOAD_CONTACT_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadContacts implements Action {
    readonly type = actionIndex.CANCEL_LOAD_CONTACTS;
}

// Save Contact
export class SetContact implements ServerRequestAction {
    readonly type = actionIndex.SET_CONTACT;
    constructor(public request: SetContactRequest) { }
}

export class SetContactSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_CONTACT_SUCCESS;
    readonly callingAction = actionIndex.SET_CONTACT;
    constructor(public contact: Contact) { }
}

export class SetContactError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_CONTACT_ERROR;
    readonly callingAction = actionIndex.SET_CONTACT;
    constructor(public message: string) { }
}

export class CancelSetContact implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SET_CONTACT;
    readonly callingAction = actionIndex.SET_CONTACT;
}
