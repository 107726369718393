import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { Shipment } from 'app-modules/core/models/shipment.model';
import { IMinMaxChartValuesPopup } from 'app-modules/core/models/minmax-chart-values-popup.interface';
import { MinMaxChartValuesComponent } from 'app-modules/shipment-detail/components/set-minmax/minmax-chart-values.component';

@Injectable()
export class MinMaxChartValuesPopup extends IMinMaxChartValuesPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenMinMaxChartValuesPopup(shipment?: Shipment): void {
        const InitialState = {
            shipment
        };
        this.bsModalRef = this.modalSvc.show(
            MinMaxChartValuesComponent,
            {
                initialState: InitialState,
                class: 'max-width-600 modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}

