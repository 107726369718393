import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IPreferenceState } from 'app-modules/core/store/models/preference-state.interface';

import * as prefActions from 'app-modules/core/store/actions/preference.actions';

@Injectable()
export class PreferenceStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public preferenceStateSelector = ((state: IAppState) => state.preference);
    public isPreferenceLoadRequiredSelector = createSelector(
        this.preferenceStateSelector,
        (state) => state.isLoadRequired
    );
    public isPreferenceLoadingSelector = createSelector(
        this.preferenceStateSelector,
        (state) => state.isLoading
    );
    public tempUnitsSelector = createSelector(
        this.preferenceStateSelector,
        (state: IPreferenceState) => state.temperatureUnits
    );
    public distUnitsSelector = createSelector(
        this.preferenceStateSelector,
        (state: IPreferenceState) => state.distanceUnits
    );

    // Observables
    public prefState$ = this.store.select(this.preferenceStateSelector).pipe();
    public isPreferenceLoadRequired$ = this.store.select(this.isPreferenceLoadRequiredSelector).pipe();
    public isPreferenceLoading$ = this.store.select(this.isPreferenceLoadingSelector).pipe();
    public tempUnits$ = this.store.select(this.tempUnitsSelector).pipe();
    public distUnits$ = this.store.select(this.distUnitsSelector).pipe();

    // Actions
    public loadPreference() {
        this.store.dispatch(new prefActions.LoadPreference());
    }

    public loadPreferenceSuccess(pref: IPreferenceState) {
        this.store.dispatch(new prefActions.LoadPreferenceSuccess(pref));
    }

    public loadPreferenceError(message: string) {
        this.store.dispatch(new prefActions.LoadPreferenceError(message));
    }

    public cancelLoadPreference() {
        this.store.dispatch(new prefActions.CancelLoadPreference());
    }
}
