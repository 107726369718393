<div class="col-md-12 content-wrapper align-items-center">
    <div class="row text-center">
        <div class="col-lg-4 col-md-6 col-sm-6 col-10 mx-auto roboto-regular-font font-18 color-regular font-weight-bold">
            <div class="row mt-4">
                <div class="col-md-12 col-12">
                    <p>
                        <span i18n="@@label_customers">Customers</span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 col-12">
                    <p>
                        <app-customer-selector *ngFor="let customer of (customerList$ | async).list" [customer]="customer" (selected)="onCustomerSelected($event)"></app-customer-selector>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>