import { SetLocationRequest } from 'emr-ng-shared';
import { LocationInfo } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadLocationList implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS;
    constructor(public linkedCustomerID?: number) { }
}

export class LoadLocationListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS;
    constructor(public locationList: IListInfo<LocationInfo>) { }
}

export class LoadLocationListError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS;

    constructor(public message: string) { }
}

export class CancelLoadLocationList implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_LOCATIONS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS;
}

export class CreateLocation implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CREATE_LOCATION;
    constructor(public request: SetLocationRequest) { }
}

export class CreateLocationSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CREATE_LOCATION_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_CREATE_LOCATION;

    constructor(public location: LocationInfo) { }
}

export class CreateLocationError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CREATE_LOCATION_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_CREATE_LOCATION;

    constructor(public message: string) { }
}

