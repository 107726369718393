import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { EmrNgControlsModule, EmrNgMapApiModule } from 'emr-ng-shared';

import { AlertService } from './services/alert.service';
import { AlertTypeService } from './services/alert-type.service';
import { AuthService } from './store/auth/auth.service';
import { CarrierService } from './services/carrier.service';
import { CreateShipmentService } from './services/create-shipment.service';
import { EditShipmentService } from './services/edit-shipment.service';
import { CustomersService } from './services/customer.service';
import { LocaleService } from './services/locale.service';
import { LocationService } from './services/location.service';
import { OversightApiService } from './services/oversight-api.service';
import { SensorRangeService } from './store/sensor-range/sensor-range.service';
import { ShipmentDetailService } from './services/shipment-detail.service';
import { SensorChartService } from './services/sensor-chart.service';
import { ShipmentFilterService } from './store/shipment-filter/shipment-filter.service';
import { ShipmentSortService } from './services/shipment-sort.service';
import { ShipmentService } from './services/shipment.service';
import { ShipmentStatusService } from './services/shipment-status.service';
import { ShipmentTemplateService } from './store/shipment-template/shipment-template.service';
import { ShowDistanceService } from './services/distance-to.service';
import { TimePeriodService } from './store/time-period/time-period.service';
import { TrackerService } from './services/tracker.service';
import { VehicleGroupsService } from './store/vehicle-group/vehicle-groups.service';
import { VehicleGroupsStateService } from './store/vehicle-group/vehicle-groups-state.service';
import { AlertSuppressionService } from './services/alert-suppression.service';
import { TimeZoneService } from './store/time-zone/time-zone.service';
import { PreferenceService } from './services/preference.service';
import { LocalizationService } from './services/localization.service';
import { CoolDownTypeService } from './store/cool-down-type/cool-down-type.service';
import { FormattedStringService } from './store/formatted-strings/formatted-strings.service';
import { TripStartOptionsService } from './store/create-shipment/trip-start-options/trip-start-options.service';
import { TripStartOptionsStateService } from './store/create-shipment/trip-start-options/trip-start-options-state.service';
import { FormattedStringStateService } from './store/formatted-strings/formatted-strings-state.service';
import { AlertStateService } from './store/services/alert-state.service';
import { AuthStateService } from './store/auth/auth-state.service';
import { CustomerStateService } from './store/services/customer-state.service';
import { SensorRangeStateService } from './store/sensor-range/sensor-range-state.service';
import { CarrierStateService } from './store/services/carrier-state.service';
import { LocationStateService } from './store/services/location-state.service';
import { ShipmentStateService } from './store/services/shipment-state.service';
import { ShipmentDetailStateService } from './store/services/shipment-detail-state.service';
import { ShipmentFilterStateService } from './store/shipment-filter/shipment-filter-state.service';
import { ShipmentSortStateService } from './store/services/shipment-sort-state.service';
import { ShipmentPagingStateService } from './store/services/shipment-paging-state.service';
import { TrackerStateService } from './store/services/tracker-state.service';
import { CreateShipmentStateService } from './store/create-shipment/create-shipment-state.service';
import { EditShipmentStateService } from './store/services/edit-shipment-state.service';
import { ShipmentStatusStateService } from './store/services/shipment-status-state.service';
import { TimePeriodStateService } from './store/time-period/time-period-state.service';
import { ShipmentTemplateStateService } from './store/shipment-template/shipment-template-state.service';
import { AlertTypeStateService } from './store/services/alert-type-state.service';
import { TimeZoneStateService } from './store/time-zone/time-zone-state.service';
import { PreferenceStateService } from './store/services/preference-state.service';
import { CoolDownTypeStateService } from './store/cool-down-type/cool-down-type-state.service';

import { PaginationComponent } from './components/pagination/pagination.component';
import { ShipmentSortFilterComponent } from './components/shipment-sort-filter/shipment-sort-filter.component';
import { HeaderComponent } from './components/header/header.component';
import { ShipmentListComponent } from './components/c-shipment-list/shipment-list.component';
import { StopLocationListComponent } from './components/stop-location-list/stop-location-list.component';
import { LocationComponent } from './components/location/location.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { httpInterceptorProviders } from './http-interceptors';


import { SensorChartStateService } from 'app-modules/core/store/sensor-chart/sensor-chart-state.service';

import { LinkedCustomersService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.service';
import { LinkedCustomerStateService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer-state.service';

import {
    CreateShipmentResponseComponent
} from 'app-modules/create-shipment/components/create-shipment-response/create-shipment-response.component';
import {
    EditShipmentResponseComponent
} from 'app-modules/create-shipment/components/edit-shipment-response/edit-shipment-response.component';
import { SensorRangeComponent } from './components/sensor-range/components/sensor-range/sensor-range.component';
import { SensorRangePopupComponent } from './components/sensor-range/components/sensor-range-popup/sensor-range-popup.component';
import { CloseShipmentPopupComponent } from 'app-modules/dashboard/components/close-shipment-popup/close-shipment-popup.component';
import { MinMaxChartValuesComponent } from 'app-modules/shipment-detail/components/set-minmax/minmax-chart-values.component';
import { ngxlocalization } from 'app-modules/core/services/ngx-localization.service';
import { BindingDataStateService } from './store/services/binding-data-state.service';
import { RegisterActivity } from './directives/register-activity.directive';
import { ActivityService } from './services/activity.service';
import { MultiTripService } from './store/multi-trip/multi-trip.service';
import { MultiTripStateService } from './store/multi-trip/multi-trip-state-service';
import { AlertSuspenseStatusStateService } from './store/alert-suspense-status/alert-suspense-state.service';
import { PageSizeService } from './services/page-size-service';
import { PageSizeStateService } from './store/services/page-size.state.service';
import { BusinessRulesService } from './store/business-rules/business-rules.service';
import { BusinessRulesStateService } from './store/business-rules/business-rules-state.service';
import {
    ForgotPasswordResponseComponent
} from 'app-modules/user-account/components/forgot-password-response/forgot-password-response.component';
import { LocalLoadingIndicatorComponent } from './components/local-loading-indicator/local-loading-indicator.component';
import { AddressSearchComponent } from './components/address-search/address-search.component';
import { CloseShipmentResponseComponent } from 'app-modules/dashboard/components/close-shipment-response/close-shipment-response.component';
import { InitializationService } from './services/initialization.service';
import { UserMessageService } from 'app-modules/user-account/services/user-message-popup.service';
import { MenuComponent } from './components/menu/menu/menu.component';
import { MenuLinkComponent } from './components/menu/menu-link/menu-link.component';
import { UnAuthService } from './store/un-auth/un-auth.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UnauthCustomerSettingsComponent } from './components/unauth-customer-settings/unauth-customer-settings.component';
import { ImportLocationsStateService } from './store/services/import-locations-state.service';
import { TotalRecordsComponent } from './components/total-records/total-records.component';
import { IconInfoPopupComponent } from './components/menu/icon-info-content/icon-info-popup.component';
import { RoutesService } from './store/route/route.service';
import { RoutesStateService } from './store/route/route-state.service';
import { RouteDeviationsService } from './store/route-deviation/route-deviation.service';
import { RouteDeviationStateService } from './store/route-deviation/route-deviation-state.service';
import { UserSettingsService } from './store/user-settings/user-settings.service';
import { UserSettingsStateService } from './store/user-settings/user-settings-state.service';
import { UnAuthStateService } from './store/un-auth/un-auth-state.service';
import { TrackerSensorRangesComponent } from './components/sensor-range/components/tracker-sensor-ranges/tracker-sensor-ranges.component';
import { StaticShipmentListComponent } from './components/shipment-list/shipment-list.component';
import { DeleteTripComponent } from './components/delete-trip/delete-trip.component';
import { ImportUpdateConfirmationComponent } from 'app-modules/location-management/components/import-update-confirmation/import-update-confirmation.component';
import { ColSelectionPopupComponent } from './components/col-selection-popup/col-selection-popup.component';
import { ReportSchedulerComponent } from './components/report-scheduler/report-scheduler.component';
import { SearchCheckListComponent } from './components/search-check-list/search-check-list.component';
import { ContactService } from './store/contacts/contact.service';
import { ContactStateService } from './store/contacts/contact-state.service';
import { ScheduleReportStateService } from './store/schedule-report/schedule-report-state.service';
import { ScheduleReportService } from './store/schedule-report/schedule-report.service';
import { EmailScheduleLinkComponent } from './components/report-scheduler/components/email-schedule-link/email-schedule-link.component';
import { ErrorMessageResponseComponent } from './components/error-message-response/error-message-response.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SearchAddressComponent } from './components/address-lookup/search-address/search-address.component';
import { TripEndOptionsStateService } from './store/create-shipment/trip-end-options/trip-end-options-state.service';
import { TripEndOptionsService } from './store/create-shipment/trip-end-options/trip-end-options.service';
import { IEUserMessageComponent } from './components/ie-user-message/ie-user-message.component';
import { DashboardService } from './store/dashboard/dashboard.service';
import { CustomerDashboardService } from './store/customer-dashboard/customer-dashboard.service';
import { DashboardStateService } from './store/dashboard/dashboard-state.service';
import { CustomerDashboardStateService } from './store/customer-dashboard/customer-dashboard-state.service';
import { CreateShipmentTemplateComponent } from './components/create-shipment-template/create-shipment-template.component';
import { ShutdownTrackerComponent } from './components/shutdown-tracker/shutdown-tracker.component';
import { BannerComponent } from './components/banner/banner.component';
import { ViewLogService } from './services/view-log.service';

@NgModule({
    declarations: [
        HeaderComponent,
        ShipmentListComponent,
        StaticShipmentListComponent,
        StopLocationListComponent,
        LocationComponent,
        ContactComponent,
        ContactListComponent,
        PaginationComponent,
        ShipmentSortFilterComponent,
        ErrorMessageComponent,
        SensorRangeComponent,
        SensorRangePopupComponent,
        RegisterActivity,
        LocalLoadingIndicatorComponent,
        UnauthCustomerSettingsComponent,
        AddressSearchComponent,
        MenuComponent,
        MenuLinkComponent,
        TotalRecordsComponent,
        IconInfoPopupComponent,
        TrackerSensorRangesComponent,
        DeleteTripComponent,
        ColSelectionPopupComponent,
        ReportSchedulerComponent,
        SearchCheckListComponent,
        EmailScheduleLinkComponent,
        ErrorMessageResponseComponent,
        ConfirmModalComponent,
        SearchAddressComponent,
        IEUserMessageComponent,
        CreateShipmentTemplateComponent,
        ShutdownTrackerComponent,
        BannerComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        EmrNgControlsModule,
        EmrNgMapApiModule,
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        ButtonsModule.forRoot(),
        PaginationModule.forRoot(),
        TooltipModule.forRoot(),
        ScrollingModule,
        AccordionModule.forRoot()
    ],
    exports: [
        HeaderComponent,
        ShipmentListComponent,
        StaticShipmentListComponent,
        StopLocationListComponent,
        LocationComponent,
        ContactListComponent,
        ContactComponent,
        PaginationComponent,
        ShipmentSortFilterComponent,
        ErrorMessageComponent,
        SensorRangeComponent,
        SensorRangePopupComponent,
        RegisterActivity,
        LocalLoadingIndicatorComponent,
        AddressSearchComponent,
        TotalRecordsComponent,
        MenuComponent,
        EmrNgControlsModule,
        EmrNgMapApiModule,
        ColSelectionPopupComponent,
        TrackerSensorRangesComponent,
        EmailScheduleLinkComponent,
        ErrorMessageResponseComponent,
        ReportSchedulerComponent,
        CreateShipmentTemplateComponent,
        BsDropdownModule,
        ButtonsModule,
        BannerComponent
    ],
    providers: [
        AlertService,
        AlertTypeService,
        AuthService,
        CarrierService,
        CreateShipmentService,
        EditShipmentService,
        CustomersService,
        LocaleService,
        LocationService,
        OversightApiService,
        SensorRangeService,
        ShipmentDetailService,
        ShipmentFilterService,
        ShipmentSortService,
        ShipmentService,
        ShipmentStatusService,
        ShipmentTemplateService,
        TimePeriodService,
        TrackerService,
        VehicleGroupsService,
        VehicleGroupsStateService,
        TimeZoneService,
        PreferenceService,
        LocalizationService,
        CoolDownTypeService,
        SensorChartService,
        MultiTripService,
        PageSizeService,
        ShipmentTemplateService,
        InitializationService,
        FormattedStringService,
        TripStartOptionsService,
        TripEndOptionsService,
        
        AlertStateService,
        AlertTypeStateService,
        AuthStateService,
        BindingDataStateService,
        CarrierStateService,
        CreateShipmentStateService,
        EditShipmentStateService,
        CustomerStateService,
        LocationStateService,
        SensorRangeStateService,
        ShipmentDetailStateService,
        ShipmentFilterStateService,
        ShipmentSortStateService,
        ShipmentPagingStateService,
        ShipmentStateService,
        ShipmentStatusStateService,
        ShipmentTemplateStateService,
        ShowDistanceService,
        TimePeriodStateService,
        TrackerStateService,
        AlertSuppressionService,
        TimeZoneStateService,
        PreferenceStateService,
        CoolDownTypeStateService,
        MultiTripStateService,
        PageSizeStateService,
        ShipmentTemplateStateService,
        FormattedStringStateService,
        ImportLocationsStateService,

        FormattedStringStateService,
        TripStartOptionsStateService,
        TripEndOptionsStateService,

        httpInterceptorProviders,
        ngxlocalization,
        SensorChartStateService,
        AlertSuspenseStatusStateService,
        LinkedCustomersService,
        LinkedCustomerStateService,
        AlertSuspenseStatusStateService,
        ActivityService,
        BusinessRulesService,
        BusinessRulesStateService,
        UserMessageService,
        UnAuthService,
        RoutesService,
        RoutesStateService,
        RouteDeviationsService,
        RouteDeviationStateService,
        UserSettingsService,
        UserSettingsStateService,
        UnAuthStateService,
        ContactService,
        ContactStateService,
        ScheduleReportStateService,
        ScheduleReportService,
        DashboardService,
        CustomerDashboardService,
        DashboardStateService,
        CustomerStateService,
        CustomerDashboardStateService,
        ViewLogService
    ]
    // entryComponents: [
    //     LocationComponent,
    //     CreateShipmentResponseComponent,
    //     EditShipmentResponseComponent,
    //     SensorRangeComponent,
    //     SensorRangePopupComponent,
    //     CloseShipmentPopupComponent,
    //     CloseShipmentResponseComponent,
    //     MinMaxChartValuesComponent,
    //     ForgotPasswordResponseComponent,
    //     IconInfoPopupComponent,
    //     ImportUpdateConfirmationComponent,
    //     EmailScheduleLinkComponent,
    //     ErrorMessageResponseComponent
    // ]
})
export class CoreModule { }
