import { Action } from '@ngrx/store';

import { Shipment } from 'app-modules/core/models/shipment.model';

import * as actionIndex from './index';

export class SelectShipment implements Action {
    readonly type = actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT;

    constructor(public shipment: Shipment) { }
}

export class ResetShipment implements Action {
    readonly type = actionIndex.SHIPMENT_DETAIL_RESET_SHIPMENT;

    constructor(public shipment: Shipment) { }
}
