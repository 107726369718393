<div class="row bg-data-div-alert-suppression div-alert-suppression position-relative">
  <div class="col-md-12">
    <form name="frmAlertSuppression" autocomplete="off">
      <div class="mx-auto {{ bsMainModalRef ? '' : ' bg-no-modal' }}">
        <div class="row">
          <div class="col-md-12">
            <div class="row mt-4">
              <div class="pl-4 col-md-7 col-7 roboto-regular-font font-14" *ngIf="isSuspendAlerts;else suspendedAlerts">
                <i class="fa fa-exclamation-triangle text-warning mr-2" aria-hidden="true"></i>
                <span i18n="@@alerts_not_suspended">Alerts are not suspended</span>
              </div>
              <ng-template #suspendedAlerts>
                <div class="pl-4 col-md-7 col-7 roboto-regular-font font-14">
                  <!-- <i class="fa fa-pause text-primary mr-2" aria-hidden="true"></i> -->
                  <span i18n="@@alerts_suspended">Alerts are currently suspended for this Tracker
                  </span>
                </div>
              </ng-template>
              <div class="col-md-4 col-4 mr-4">
                <label class="GlobalDeviceId-Label mb-0">
                  <span i18n="@@label_serial_number">Serial Number</span>
                  <span> : </span>
                  <span>{{alertSuspendStatusRequest?.GlobalDeviceId}}</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 p-4">
                <!-- <div class="row">
                  <div class="col-md-12 col-12 roboto-regular-font font-16 font-weight-bold">
                    <p>
                      <span i18n="@@select_condition_for_alerts">Select one or both conditions for resuming
                        alerts:</span>
                    </p>
                  </div>
                </div> -->
                <div class="row mb-2">
                  <div class="col-md-12">
                    <emr-checkbox identifier="chkSuspendAllAlerts" name="chkSuspendAllAlerts"
                      i18n-text="@@suspend_all_alerts" class="d-inline-block" text="Suspend All Alerts"
                      [(ngModel)]="checkBoxSuspendAlerts" (onChange)="onSuspendAlertsChange()" cssClass="color-black">
                    </emr-checkbox>
                    <span i18n-tooltip="@@suspend_alerts_info" class="cursor-pointer"
                      tooltip="You resume alerts at anytime by unchecking the box" placement="top">
                      <i class="fa fa-info-circle information-tooltip" aria-hidden="true"></i></span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-auto">
                    <emr-checkbox identifier="chkExpiration" name="chkExpiration"
                      i18n-text="@@label_resume_alerts_at_time" class="d-inline-block"
                      text="Resume alerts at the specified time:" [(ngModel)]="checkBoxExpiration"
                      cssClass="color-black" [setDisabled]="checkBoxSuspendAlerts"></emr-checkbox>
                    <span i18n-tooltip="@@alerts_remain_suspended" class="cursor-pointer"
                      tooltip="Alerts will remain suspended until the date selected" placement="top">
                      <i class="fa fa-info-circle information-tooltip" aria-hidden="true"></i></span>
                  </div>
                  <div class="col-md-auto ml-2" *ngIf="checkBoxExpiration">
                    <emr-datetimepicker ngDefaultControl name="txtDate " [inline]=true [CustomizeGrid]=true
                      timePickerDivCssClass="col-md-2" identifier="txtDate" i18n-am="@@time_AM" i18n-pm="@@time_PM"
                      [(ngModel)]="resumeDate" [minDate]=today [showPickerOnTop]="true" am="AM" pm="PM"
                      controlColumns="10" [isDisabled]="!checkBoxExpiration" [dateTimeObject]=dateTimeObject>
                    </emr-datetimepicker>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <emr-checkbox identifier="chkResumeAlertBasedOnLocation" name="chkResumeAlertBasedOnLocation"
                      i18n-text="@@label_resume_alerts_at_locations" class="d-inline-block"
                      [setDisabled]="checkBoxSuspendAlerts"
                      text="Resume alerts when this tracker enters any of the selected locations:"
                      [(ngModel)]="checkBoxEnterLocation" cssClass="color-black"></emr-checkbox>
                    <span i18n-tooltip="@@suspend_alerts_location_based" class="cursor-pointer"
                      tooltip="You can suspend alerts based on location" placement="top">
                      <i class="fa fa-info-circle information-tooltip" aria-hidden="true"></i></span>
                  </div>
                </div>
                <div class="row" [hidden]="!checkBoxEnterLocation">
                  <div class="col-md-10 ml-4">
                    <emr-checkbox identifier="chkLocations" name="chkLocations" [isChecked]="false"
                      [setDisabled]="!checkBoxEnterLocation" text="Select all locations"
                      [(ngModel)]="resumeAlertLocation" cssClass="color-black"></emr-checkbox>
                  </div>
                  <div class="w-100"
                    *ngIf="locationList$ | async| searchFilter:searchAlertLocations:'text' as locations">
                    <div class="row">

                      <div class="col-md-10 ml-4">
                        <emr-textbox identifier="txtSearchAlertLocations" name="txtSearchAlertLocations"
                          placeholder="Search" i18n-placeholder="@@search" class="d-inline-block"
                          formGroupCssClass="m-0" [(ngModel)]="searchAlertLocations" appendIcon="fa fa-search"
                          appendIconClass="input-icon">
                        </emr-textbox>
                        <span *ngIf="locations?.length>200" class="label-css">
                          <span i18n="@@label_showing_25_of">Showing 25 of </span>
                          <span>{{' '+locations?.length}}</span></span>
                      </div>
                    </div>
                    <div class="col-md-7 ml-2" [collapse]="false"
                      *ngIf="locations|slice:0:locations?.length>200?25:200 as locationData">
                      <div class="max-height overflow-auto">
                        <emr-checkboxlist identifier="chklstLocations" name="chklstLocations"
                          [setDisabled]="!checkBoxEnterLocation" [(ngModel)]="selectedLocations"
                          [options]="locationData" [inlineCheckbox]=false (onChange)="onLocationChange($event)"
                          textCssClass="color-black" [isChecked]="resumeAlertLocation">
                        </emr-checkboxlist>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row pl-2 mb-4">
              <div class="col-md-12 mt-0">
                <emr-button identifier="suspendAlerts" cssClass="btn-primary submit-btn mr-4" buttonText="Submit"
                  i18n-buttonText="@@link_submit" [setDisabled]="false" (onClick)="onSuspendAlertClick()"
                  buttonIcon="fa fa-check">
                </emr-button>

                <emr-button identifier="resumeAlerts" cssClass="btn-success text-white" buttonText="Resume Alerts"
                  i18n-buttonText="@@resume_alerts" (onClick)="onResumeAlertClick()" *ngIf="!isSuspendAlerts">
                </emr-button>
              </div>
              <div class="col-md-12">
                <span class="validationindicator">
                  <div class="my-1" *ngIf="error!=null">
                    <div>{{error}}</div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>