import { ITrackerSummaryState } from 'app-modules/core/store/models/tracker-summary-state.interface';
import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as trackerActions from 'app-modules/core/store/actions/shipment-detail-tracker.actions';
import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';

type Actions =
    authActions.SignOut |
    trackerActions.LoadTracker |
    trackerActions.LoadTrackerSuccess |
    trackerActions.LoadTrackerError |
    detailActions.SelectShipment;

export function ShipmentDetailTrackerReducer(
    state: ITrackerSummaryState = initialState.shipmentDetailTracker,
    action: Actions
): ITrackerSummaryState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailTracker;

        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_SUCCESS:
            return {
                ...state,
                summary: action.summary,
                alertInfo: action.alertInfo,
                chartData: action.chartData,
                chartDataInfo: action.chartDataInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_ERROR:
            return {
                ...state,
                errorMessage: action.message
            };
        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
            return {
                ...state,
                isLoadRequired: true
            };
        default:
            return state;
    }
}
