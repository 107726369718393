import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { OversightApiService } from "./oversight-api.service";
import { downloadCompleted, downloadProgressMessage, downloadProgressTitle } from 'app-modules/core/consts/localization';
import { ExportTrackerStateReportHeaders, responseType } from "emr-ng-shared";
import { formatDate } from "@angular/common";
import * as FileSaver from 'file-saver';
import { IErrorInfo } from "../models/error-info.interface";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LocationsExportService {

    constructor(
        private toastr: ToastrService,
        private oversightSvc: OversightApiService
    ) { }

    private isDownloadInProgressSub = new BehaviorSubject(false);
    public isDownloadInProgress$ = this.isDownloadInProgressSub.asObservable();
    private isInProgress = false;

    public excelExport(onlyCopiedLocations: boolean, locationIds: string) {
        if (!this.isInProgress) {
            const Toast = this.toastr.info(downloadProgressMessage, downloadProgressTitle, {
                disableTimeOut: true, tapToDismiss: false, enableHtml: true,
                progressBar: true, positionClass: 'toast-bottom-right',
                toastClass: 'navigation-popup-dynamic download-toast-info'
            });
            const acceptType = 'application/vnd.ms-excel';
            const extraHeaders = new ExportTrackerStateReportHeaders();
            extraHeaders.Accept = acceptType;
            extraHeaders.responseType = responseType.blob;
            this.setDownloadProgressState(true);
            let queryParams = { onlyCopiedLocations: onlyCopiedLocations, locationIds: locationIds };
            this.oversightSvc.ExcelExportLocations(queryParams, extraHeaders).subscribe((response) => {
                const blob = new Blob([response], {
                    type: acceptType
                });

                const fileName = `locations-${formatDate(new Date(), 'ddMMyyyhhmm', 'en_US')}.xlsx`;
                FileSaver.saveAs(blob, fileName);
                this.toastr.remove(Toast.toastId);
                this.toastr.success(downloadCompleted, '', {
                    positionClass: 'toast-bottom-right', timeOut: 2000
                });
                this.setDownloadProgressState(false);
            }, (e: IErrorInfo) => {
                this.toastr.remove(Toast.toastId);
                this.toastr.success(e.message, '', {
                    positionClass: 'toast-bottom-right', timeOut: 2000
                });
                this.setDownloadProgressState(false);
            });
        }
    }

    private setDownloadProgressState(isInProgress: boolean) {
        this.isInProgress = isInProgress;
        this.isDownloadInProgressSub.next(isInProgress);
    }

}
