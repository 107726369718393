<div class="{{ this.formGroupCssClass }} {{this.inline ? ' form-row' : 'form-group ' }}">
    <label *ngIf="label" for="{{identifier}}" class="label-css {{labelCssClass}}">
        <span class="{{helpTip ? 'cursor-pointer' : ''}} " tooltip="{{helpTip}}" placement="top">{{label}}</span>
    </label>
    <div class="text-input-div {{ textBoxDivCssClass }} ">
        <div class="{{this.inline ? 'row' : '' }}">
            <div class="{{this.inline ? ' col-lg-6 col-md-6' : '' }}">
                <div class="{{ prependIcon || appendIcon ? 'input-group' : '' }}" [ngClass]="{'ng-invalid': validationMessage?.length > 0}" >
                    <div *ngIf="prependIcon.length > 0" class="{{textBoxGroupCssClass}}">
                        <span class="input-group-text icon-css {{prependIconClass}}">
                            <i class="{{prependIcon}}"></i>
                        </span>
                    </div>
                    <input type="text" [id]="identifier" [(ngModel)]="address" [placeholder]="placeholder"
                        [ngClass]="{'invalid': validationMessage?.length > 0}"
                        class="text-input form-control" name="{{name}}" emr-typeahead-2
                        [taListRequestCallback]="requestAddressListCallback" [taItemTpl]="itemTpl"
                        taListItemLabel="FormattedAddress" taDisplayOnFocus="true" taResultsHOffset="10"
                        (blur)="fireBlur($event)" (focus)="fireFocus($event)" taResultsVOffset="30" 
                        emrAutocompleteOff emrAutoUniqueName (taSuggestionSelected)="onSuggestionSelected($event)">
                    <label class="input-append-transparent-icon" [attr.for]="identifier">
                        <i class="{{appendIcon}}"></i>
                    </label>
                </div>
            </div>
            <div class="{{this.inline ? ' col-lg-6 col-md-6' : '' }}">
                <!-- Validation Column -->
                <span class="validation help-text" *ngIf="validationMessage?.length > 0">
                    {{validationMessage}}
                </span>
            </div>
            <div class="{{this.inline ? ' col-md-12' : '' }}">
                <small *ngIf="helpText" class="help-text form-text muted-text">
                    {{helpText}}
                </small>
            </div>
        </div>
    </div>
</div>

<ng-template #itemTpl let-result>
    <div class="suggestion-item">
        <div class="row">
            <div class="col-md-1">
                <i class="fa fa-map-marker map-marker"></i>
            </div>
            <div class="col-md-10">
                {{ result.result }}
            </div>
        </div>
    </div>
</ng-template>