<div class="row filter-component-custom" *ngIf="cloneFilter.aboveTemp||cloneFilter.belowTemp||cloneFilter.late||cloneFilter.notMoving">
    <div class="col-md-12">
        <div class="row ml-1 mr-1">
            <div class="filter-header-style" i18n="@@label_exceptions">Exceptions</div>
            <div class="filtered-item" *ngIf="cloneFilter.aboveTemp">
                <span class="filter-content-style" i18n="@@above_temp">{{EnumAlertTypeDescription.AboveTemp}}</span>
                <button class="filter-close-button" class="filter-close-button" (click)="clearSelectedFilter(EnumAlertTypeKey.AboveTemp)">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div class="filtered-item" *ngIf="cloneFilter.belowTemp">
                <span class="filter-content-style" i18n="@@below_temp">{{EnumAlertTypeDescription.BelowTemp}}</span>
                <button class="filter-close-button" class="filter-close-button" (click)="clearSelectedFilter(EnumAlertTypeKey.BelowTemp)">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div class="filtered-item" *ngIf="cloneFilter.late">
                <span class="filter-content-style" i18n="@@late">{{EnumAlertTypeDescription.Late}}</span>
                <button class="filter-close-button" class="filter-close-button" (click)="clearSelectedFilter(EnumAlertTypeKey.Late)">
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <div class="filtered-item" *ngIf="cloneFilter.notMoving">
                <span class="filter-content-style" i18n="@@not_moving">{{EnumAlertTypeDescription.NotMoving}}</span>
                <button class="filter-close-button" class="filter-close-button" (click)="clearSelectedFilter(EnumAlertTypeKey.NotMoving)">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>
    </div>
</div>