import { IObjectViewState } from '../models/object-view-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as objectViewActions from './object-views.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

import { emptyList } from '../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    objectViewActions.LoadObjectViews |
    objectViewActions.LoadObjectViewsSuccess |
    objectViewActions.LoadObjectViewsError |
    objectViewActions.ReloadObjectViews |
    custActions.SelectCustomerSuccess;

export function ObjectViewsReducer(state: IObjectViewState = initialState.objectViewItemsList, action: Actions): IObjectViewState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return {
                ...state,
                objectViewItemsList: emptyList(),
                isLoadRequired: false,
            };

        case actionIndex.RELOAD_OBJECT_VIEWS:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoadRequired: true,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_OBJECT_VIEWS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_OBJECT_VIEWS_SUCCESS:
            return {
                ...state,
                objectViewItemsList: action.objectViewList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_OBJECT_VIEWS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
