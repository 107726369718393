<div class="row">
    <div class="col-md-12">
      <div class="card alertbox roboto-regular-font font-12">
        <div class="row card-header p-1">
          <div class="col-md-12 left-align">
            <div class="font-14 lh-24">
              <b>
                <span i18n="label_location_info">Location Info</span>
              </b>
              <button translate="no" type="button"
                class="notranslate float-right close btn-modal custom-btn-info-click click-infobox-close infobox-close-img"
                aria-label="Close">
                <span aria-hidden="true" class="custom-btn-info-click click-infobox-close infobox-close-img">
                  <b class="custom-btn-info-click click-infobox-close infobox-close-img">&times;</b>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body p-1 pl-2 text-left">
          <div class="row ">
            <!-- <div class="col-md-12 pt-1">
              <b>
                <span i18n="@@label_serial_number">Serial Number</span>:&ensp;
              </b>
              <span>{{ markerInfo?.TrackerInfo?.TrackerId }}</span>
            </div> -->
            <div class="col-md-12 mb-1">
              <b>
                <span>{{ location?.LocationName}}</span>
              </b>
            </div>
            <!-- <div class="col-md-12">
              <span> {{ markerInfo?.TrackerInfo?.Description}}</span>
            </div> -->
            <div class="col-md-12  mb-1">
              <!-- <b>
                <span i18n="@@label_date_time">Date/Time</span>:&ensp;
              </b> -->
              <span> {{ location?.Address1 }}</span>
            </div>
            <div class="col-md-12  mb-1">
              <span> {{ location?.City }}, {{location?.State}} - {{ location?.Zip }} </span>
            </div>
  
            <div class="col-md-12">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <button translate="no" type="button" *ngIf="isZoomInfoLevel && showInfoZoomOption"
                    class="notranslate float-right close btn-modal btnZoomInfoLevel custom-btn-info-click click-infobox-zoom-in"
                    aria-label="Close">
                    <i class="fa fa-search-plus custom-btn-info-click click-infobox-zoom-in" aria-hidden="true"></i>
                  </button>
                  <button translate="no" type="button" *ngIf="!isZoomInfoLevel"
                    class="notranslate float-right close btn-modal btnZoomInfoLevel custom-btn-info-click click-infobox-zoom-out"
                    aria-label="Close">
                    <i class="fa fa-search-minus custom-btn-info-click click-infobox-zoom-out" aria-hidden="true"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
