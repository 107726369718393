import { Injectable, Inject } from '@angular/core';
import { APP_IN_CHINA } from 'base/regionFinder';
import { environment } from 'environments/environment';
import { api } from 'environments/api';
import { CLIMATE_SITE_URL } from '../consts/values';

@Injectable({ providedIn: 'root'})
export class BaseURLPicker {
    resourceDomain = environment.OversightImageURL;
    apiDomain = api.OversightAPI;
    secondaryDomain = environment.oversightCNSiteUrl;
    climateDomain = CLIMATE_SITE_URL;

    constructor(@Inject(APP_IN_CHINA) isAppInChina: boolean) {
        if (isAppInChina) {
            this.resourceDomain = environment.CNOversightImageURL;
            this.apiDomain = api.CNOversightAPI;
            this.secondaryDomain = environment.oversightV2SiteUrl;
        }
    }
}
