<div class="filter-summary-container" id="filter-summary-container">
    <ul class="horizontal-ul" style="float: inline-start;">
        <li class="buttons-margin float-left">
            <emr-button identifier="resetToDefault" (onClick)="onLoadFilterToInitial()"
                cssClass="btn flt-btn reset-filter-btn summary-buttons filter-button"
                i18n-buttonText="@@label_reset_default" buttonText="Reset to Default" *ngIf="showFilterDefaultButton">
            </emr-button>
            <emr-button identifier="filterResetButton" *ngIf="isFilterDirty"
                cssClass="btn flt-btn reset-filter-btn summary-buttons filter-button"
                i18n-buttonText="@@label_clear_filter" buttonText="Clear Filters" (onClick)="onFilterReset()">
            </emr-button>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated">
            <app-shipment-filter-serial-number-summary [shipmentFilter]="shipmentFilter"
                [isHugeCustomer]="isHugeCustomer" (summaryRemove)="onSummaryRemove($event)">
            </app-shipment-filter-serial-number-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated">
            <app-shipment-filter-shipment-name-summary [shipmentFilter]="shipmentFilter"
                [isHugeCustomer]="isHugeCustomer" (summaryRemove)="onSummaryRemove($event)">
            </app-shipment-filter-shipment-name-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!isSerialShipmentNameSearch">
            <app-shipment-filter-period-summary [filter$]="periodFilter$" [period$]="selectedPeriod$"
                [shipmentFilter]="shipmentFilter" [isHugeCustomer]="isHugeCustomer"
                (summaryRemove)="onSummaryRemove($event)">
            </app-shipment-filter-period-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!isSerialShipmentNameSearch">
            <app-shipment-filter-status-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-status-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated && !isSerialShipmentNameSearch">
            <app-shipment-filter-alert-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-alert-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!isSerialShipmentNameSearch">
            <app-shipment-filter-origin-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-origin-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!isSerialShipmentNameSearch">
            <app-shipment-filter-destination-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-destination-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated && !isSerialShipmentNameSearch">
            <app-shipment-filter-temp-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-temp-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated && !isSerialShipmentNameSearch">
            <app-shipment-filter-carrier-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-carrier-summary>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.originCity?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_origin_city">Origin City</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.originCity"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('originCity',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.originState?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_origin_state">Origin State</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.originState"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('originState',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.originCountry?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_origin_country">Origin Country</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.originCountry"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('originCountry',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.originZip?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_origin_zip">Origin Zip Code</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.originZip"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('originZip',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.destinationCity?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_destination_city">Destination City</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.destinationCity"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('destinationCity',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.destinationState?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_destination_state">Destination State</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.destinationState"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('destinationState',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.destinationCountry?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_destination_country">Destination Country
                            </div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.destinationCountry"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('destinationCountry',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.destinationZip?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_destination_zip">Destination Zip Code</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.destinationZip"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('destinationZip',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated && !isSerialShipmentNameSearch">
            <app-shipment-filter-supplier-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-supplier-summary>
        </li>

        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated && !isSerialShipmentNameSearch">
            <app-shipment-filter-distribution-center-summary [shipmentFilter]="shipmentFilter"
                (summaryRemove)="onSummaryRemove($event)" [isHugeCustomer]="isHugeCustomer">
            </app-shipment-filter-distribution-center-summary>
        </li>
        
        <li class="filter-summary-item" *ngIf="!IsUnAuthenticated &&!isSerialShipmentNameSearch">
            <div>
                <div class="row filter-component-custom" *ngIf="shipmentFilter?.mostRecentPostDC?.length > 0">
                    <div class="col-md-12">
                        <div class="row ml-1 mr-1">
                            <div class="filter-header-style" i18n="@@label_last_geo_fence">Last Geo Fence</div>
                            <div class="filtered-item" *ngFor="let listItem of shipmentFilter?.mostRecentPostDC"><span
                                    class="filter-content-style">{{listItem}}</span><button class="filter-close-button"
                                    (click)="clearSelectedFilter('mostRecentPostDC',listItem)"><i
                                        class="fa fa-close"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</div>