import { ActionReducerMap } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';

import { AuthReducer } from '../auth/auth.reducer';
import { CustomerReducer } from './customer.reducer';
import { ShipmentReducer } from './shipment.reducer';
import { ShipmentsPagingReducer } from './shipment-paging.reducer';
import { TrackerReducer } from './tacker.reducer';
import { LocationReducer } from './location.reducer';
import { ShipmentTemplateReducer } from '../shipment-template/shipment-template.reducer';
import { VehicleGroupsReducer } from 'app-modules/core/store/vehicle-group/vehicle-groups.reducer';
import { LoadingReducer } from './loading.reducer';
import { ShipmentDetailReducer } from './shipment-detail.reducer';
import { ShipmentDetailAlertReducer } from './shipment-detail-alert.reducer';
import { ShipmentDetailAlertPagingReducer } from './shipment-detail-alert-paging.reducer';
import { ShipmentDetailHistoricalShipmentReducer } from './shipment-detail-historical-shipment.reducer';
import { ShipmentDetailHistoricalShipmentPagingReducer } from './shipment-detail-historical-shipment-paging.reducer';
import { ShipmentDetailTrackerReducer } from './shipment-detail-tracker.reducer';
import { ShipmentDetailTrackerStateReportReducer } from './shipment-detail-tracker-state-report.reducer';
import { ShipmentDetailTrackerStateChartReducer } from './shipment-detail-tracker-state-chart.reducer';
import {
    ShipmentDetailTrackerStateChartSelectedTimePeriodReducer
} from './shipment-detail-tracker-state-chart-selected-time-period.reducer';
import { ShipmentDetailTimePeriodReducer } from './shipment-detail-time-period.reducer';
// import { ShipmentDetailSelectedTimePeriodReducer } from './shipment-detail-selected-time-period.reducer';
import { CreateShipmentReducer } from '../create-shipment/create-shipment.reducer';
// import { CreateShipmentLocationReducer } from '../create-shipment/location/create-shipment-location.reducer';
// import { CreateShipmentTemplateReducer } from '../create-shipment/template/create-shipment-template.reducer';
// import { CreateShipmentSensorRangeReducer } from '../create-shipment/sensor-range/create-shipment-sensor-range.reducer';
import { CreateShipmentCarrierReducer } from '../create-shipment/carrier/create-shipment-carrier.reducer';
import { CreateShipmentTrackerReducer } from '../create-shipment/tracker/create-shipment-tracker.reducer';
import { EditShipmentReducer } from './edit-shipment.reducer';
import { EditShipmentSensorRangeReducer } from './edit-shipment-sensor-range.reducer';
import { ShipmentFilterReducer } from '../shipment-filter/shipment-filter.reducer';
import { AlertTypeReducer } from './alert-type.reducer';
import { ShipmentStatusReducer } from './shipment-status.reducer';
import { TimePeriodReducer } from '../time-period/time-period.reducer';
import { SensorRangeReducer } from '../sensor-range/sensor-range.reducer';
import { CarrierReducer } from './carrier.reducer';
import { TimeZoneReducer } from '../time-zone/time-zone.reducer';
import { PreferenceReducer } from './preference.reducer';
import { ShipmentSortReducer } from './shipment-sort.reducer';
import { CoolDownTypeReducer } from '../cool-down-type/cool-down-type.reducer';
import { ShipmentDetailSensorChartReducer } from './shipment-detail-sensor-chart.reducer';
import { PageSizeReducer } from './page-size.reducer';
import { BusinessRulesReducer } from '../business-rules/business-rules.reducer';
import { LinkedCustomerReducer } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.reducer';
import { UnAuthReducer } from '../un-auth/un-auth.reducer';
import { RoutesReducer } from '../route/route.reducer';
import { FormattedStringReducer } from '../formatted-strings/formatted-strings.reducer';
// import { RouteDeviationReducer } from '../route-deviation/route-deviation.reducer';
import { TripStartOptionsReducer } from '../create-shipment/trip-start-options/trip-start-options.reducer';
import { TripEndOptionsReducer } from '../create-shipment/trip-end-options/trip-end-options.reducer';
import { UserSettingsReducer } from '../user-settings/user-settings.reducer';
import { LocationColorListReducer } from './location-color-list.reducer';
import { PakSenseAuthReducer } from '../paksense-auth/ps-auth.reducer';
import { ContactReducer } from '../contacts/contact.reducer';
import { ScheduleReportReducer } from '../schedule-report/schedule-report.reducer';
import { ObjectViewsReducer } from '../object-views/object-views.reducer';
import { ObjectViewArgsReducer } from '../object-views/object-view-args.reducer';
import { LoadingRequestsReducer } from '../loading/load-requests.reducer';
import { LinkedShipperReducer } from '../create-shipment/linked-customers/linked-shipper.reducer';
import { DashboardReducer } from '../dashboard/dashboard.reducer';
import { CustomerDashboardInfo } from 'emr-ng-shared';
import { CustomerDashboardReducer } from '../customer-dashboard/customer-dashboard.reducer';

export const reducers: ActionReducerMap<IAppState> = {
    auth: AuthReducer,
    pakSenseAuth: PakSenseAuthReducer,
    loading: LoadingReducer,
    customer: CustomerReducer,
    shipment: ShipmentReducer,
    shipmentFilter: ShipmentFilterReducer,
    shipmentSort: ShipmentSortReducer,
    shipmentPaging: ShipmentsPagingReducer,
    tracker: TrackerReducer,
    sensorRange: SensorRangeReducer,
    location: LocationReducer,
    shipmentDetail: ShipmentDetailReducer,
    shipmentDetailAlert: ShipmentDetailAlertReducer,
    shipmentDetailAlertPaging: ShipmentDetailAlertPagingReducer,
    shipmentDetailHistoricalShipment: ShipmentDetailHistoricalShipmentReducer,
    shipmentDetailHistoricalShipmentPaging: ShipmentDetailHistoricalShipmentPagingReducer,
    shipmentDetailTracker: ShipmentDetailTrackerReducer,
    shipmentDetailTrackerStateChart: ShipmentDetailTrackerStateChartReducer,
    shipmentDetailTrackerStateChartSelectedTimePeriod: ShipmentDetailTrackerStateChartSelectedTimePeriodReducer,
    shipmentDetailTrackerStateReport: ShipmentDetailTrackerStateReportReducer,
    shipmentDetailSensorChart: ShipmentDetailSensorChartReducer,
    // shipmentDetailTraxx: ShipmentDetailTraxxReducer,
    // shipmentDetailTimeline: ShipmentDetailTimelineReducer,
    shipmentDetailTimePeriod: ShipmentDetailTimePeriodReducer,
    createShipment: CreateShipmentReducer,
    // createShipmentLocation: CreateShipmentLocationReducer,
    // createShipmentTemplate: CreateShipmentTemplateReducer,
    // createShipmentSensorRange: CreateShipmentSensorRangeReducer,
    createShipmentCarrier: CreateShipmentCarrierReducer,
    createShipmentTracker: CreateShipmentTrackerReducer,
    editShipment: EditShipmentReducer,
    editShipmentSensorRange: EditShipmentSensorRangeReducer,
    shipmentTemplate: ShipmentTemplateReducer,
    vehicleGroups: VehicleGroupsReducer,
    alertType: AlertTypeReducer,
    shipmentStatus: ShipmentStatusReducer,
    timePeriod: TimePeriodReducer,
    carrier: CarrierReducer,
    timeZone: TimeZoneReducer,
    preference: PreferenceReducer,
    coolDownType: CoolDownTypeReducer,
    pageSize: PageSizeReducer,
    linkedCustomer: LinkedCustomerReducer,
    businessRules: BusinessRulesReducer,
    unAuthTrackers: UnAuthReducer,
    routes: RoutesReducer,
    // routeDeviations: RouteDeviationReducer,
    formattedStrings: FormattedStringReducer,
    userSettings: UserSettingsReducer,
    tripStartOptions: TripStartOptionsReducer,
    tripEndOptions: TripEndOptionsReducer,
    locationColorList:LocationColorListReducer,
    contactList: ContactReducer,
    scheduledReportList: ScheduleReportReducer,
    objectViewItemsList: ObjectViewsReducer,
    objectViewArgsItemList: ObjectViewArgsReducer,
    pendingRequests: LoadingRequestsReducer,
    linkedShipper: LinkedShipperReducer,
    dashboardList: DashboardReducer,
    customerDashboardList: CustomerDashboardReducer
};
