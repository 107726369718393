<emr-modal id="frmSensorRanges" *ngIf="bsMainModalRef" headerTitle="Sensor Ranges"
    i18n-headerTitle="@@label_sensor_ranges" [bsModalRef]=bsMainModalRef>
    <ng-container modal-body *ngIf="bsMainModalRef; then formModal">
    </ng-container>
</emr-modal>

<ng-template #formModal>
    <form name="frmTrackerSensorRanges" #shipmentForm="ngForm" emrForm autocomplete="off">
        <div class="bg-tracker-sensors p-2 mx-auto {{ bsMainModalRef ? '' : ' bg-no-modal' }}">
            <div class="row">
                <div class="col-md-12 vmargin-sm">
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                <emr-textbox ngDefaultControl identifier="txtSerialNumber" name="txtSerialNumber"
                                    label="Serial Number" [setDisabled]=true i18n-label="@@label_serial_number"
                                    [(ngModel)]="serialNumber" helpTip="Tracker Serial Number"
                                    i18n-helpTip="@@label_tracker_serial_number" [inline]=true [controlInline]=false
                                    labelColumns=3 textBoxColumns=9>
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="sensorRangeList$ | async as sensors">
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstSensorRange"
                                        name="lstSensorRange" [options]="sensors.Temperature" [(ngModel)]="tempRangeID"
                                        cssClass="default-dropdown" label="Temperature Range"
                                        i18n-label="@@label_temperature_range" optionsValueField="SensorRangeId"
                                        optionsTextField="Description" placeholder="Select Temperature Range"
                                        i18n-placeholder="@@select_temperature_range" [inline]=true
                                        [controlInline]=false labelColumns=3 controlColumns=9 [createNewItem]=true
                                        createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Temperature)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="showHumiditySensor">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstHumiditySensorRange"
                                        name="lstHumiditySensorRange" [options]="sensors.Humidity"
                                        [(ngModel)]="humidityRangeID" cssClass="default-dropdown"
                                        label="Humidity" i18n-label="@@label_humidity" optionsValueField="SensorRangeId"
                                        optionsTextField="Description" placeholder="Select Humidity"
                                        i18n-placeholder="@@select_humidity" [inline]=true [controlInline]=false
                                        labelColumns=3 controlColumns=9 [createNewItem]=true
                                        [validationIndicatorRequired]=true createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Humidity)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="showCO2Sensor">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstCO2SensorRange"
                                        name="lstCO2SensorRange" [options]="sensors.CarbonDioxide"
                                        [(ngModel)]="co2RangeID" cssClass="default-dropdown" label="CO2"
                                        i18n-label="@@label_carbondioxide" optionsValueField="SensorRangeId"
                                        optionsTextField="Description" placeholder="Select CO2"
                                        i18n-placeholder="@@select_co2" [inline]=true [controlInline]=false
                                        labelColumns=3 controlColumns=9 [createNewItem]=true
                                        [validationIndicatorRequired]=true createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.CarbonDioxide)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="showProbeSensor">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstProbeSensorRange"
                                        name="lstProbeSensorRange" [options]="sensors.ProbeTemp"
                                        [(ngModel)]="probeRangeID" cssClass="default-dropdown"
                                        label="Prob Temp" i18n-label="@@label_probetemp"
                                        optionsValueField="SensorRangeId" optionsTextField="Description"
                                        placeholder="Select Prob Temp" i18n-placeholder="@@select_probtemp"
                                        [inline]=true [controlInline]=false labelColumns=3 controlColumns=9
                                        [createNewItem]=true [validationIndicatorRequired]=true
                                        createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.ProbeTemp)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p>
                                <emr-button identifier="btnSetTrackerSensors" buttonType="submit"
                                    buttonText="Submit" i18n-buttonText="@@link_submit"
                                    cssClass="btn-outline-default submit-btn my-0"
                                    [setDisabled]="disableSubmitButton" (onClick)="onSetTrackerSensors()"
                                    buttonIcon="fa fa-check">
                                </emr-button>
                            </p>
                        </div>
                    </div>
                    <div *ngIf="error" class="row mt-2 font-12 validationindicator">
                        <div class="col-md-12">
                            <p>{{ error }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>