import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as sensorChartActions from 'app-modules/core/store/actions/shipment-detail-sensor-chart.actions';
import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';
import * as periodActions from 'app-modules/core/store/actions/shipment-detail-tracker-state-chart-selected-time-period.actions';

import { ISensorChart } from 'app-modules/core/store/models/sensor-chart-state.interface';

type Actions =
    authActions.SignOut |
    sensorChartActions.LoadSensorChart |
    sensorChartActions.LoadSensorChartSuccess |
    sensorChartActions.LoadSensorChartError |
    detailActions.SelectShipment |
    periodActions.ChangeShipmentDetailTrackerStateChartTimePeriod;

export function ShipmentDetailSensorChartReducer(
    state: ISensorChart = initialState.shipmentDetailSensorChart,
    action: Actions
): ISensorChart {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailSensorChart;

        case actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART_SUCCESS:
            return {
                ...state,
                chartData: action.chartData,
                chartDataInfo: action.chartDataInfo,
                sensorStateData: action.sensorStateData,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_SENSOR_CHART_ERROR:
            return {
                ...state,
                errorMessage: action.message
            };
        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
        // case actionIndex.SHIPMENT_DETAIL_TRACKER_STATE_CHART_SELECTED_TIME_PERIOD:
            return {
                ...state,
                isLoadRequired: true
            };
        default:
            return state;
    }
}
