<div class="row mx-1 mt-1">
    <div class="col-12">
        <div class="full-width-float-box">
            <emr-typeahead identifier="txtSerial" name="txtSerial" placeholder="Last 4 Digits"
                i18n-placeholder="@@label_last_4_digits" helpTip="Tracker Serial Number"
                i18n-helpTip="@@label_tracker_serial_number" [dataSource]="filteredSerialList"
                typeaheadHideResultsOnBlur=true
                typeaheadOptionFieldName="trackerId" (ngModelChange)="onSerialNumberChanged()"
                [(ngModel)]="filter">
            </emr-typeahead>
        </div>
        <div class="inline-loader-container" *ngIf="trackSvc.isLoading$ | async">
            <app-local-loading-indicator width="35px" height="35px"></app-local-loading-indicator>
        </div>
    </div>
</div>