<div class="">
  <div class="col-md-12">
    <span class="text-input form-control">
      <b>
        <span i18n="@@total_records">Total Records</span> :
      </b>
      <span translate="no" class="notranslate" *ngIf="skipThreshold || itemCount <= itemThreshold; else excessBlock">
        &nbsp;{{itemCount}}
      </span>
      <ng-template #excessBlock>
        <span translate="no" class="notranslate">&nbsp;{{itemThreshold}}&nbsp;</span>
        <span i18n="@@label_of">of</span>
        <span translate="no" class="notranslate">&nbsp;{{itemCount}}</span>
      </ng-template>
    </span>
  </div>
</div>