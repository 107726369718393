<form modal-body name="frmTripReport" #ScheduleReportForm="ngForm" class=" py-2" emrForm autocomplete="off"
    [ngClass]="{ 'ng-submitted': isFormSubmitted}">
    <div class="col-12 report-filter-div">
        <ul class="nav report-div justify-content-left btn-list">
            <li class="nav-item width-300">
                <emr-custom-dropdown identifier="rptTimePeriodFilter" name="rptTimePeriodFilter" label="Time Period"
                    i18n-label="@@label_time_period" [options]="periodList" [(ngModel)]="reportParams.TimePeriod"
                    cssClass="default-dropdown" (ngModelChange)="onPeriodChange()" optionsValueField="Period"
                    optionsTextField="DisplayName" [inline]=true labelColumns=5 controlColumns="7" [controlInline]=false
                    labelCssClass="period-lable-css " inlineCss="pl-0">
                </emr-custom-dropdown>
            </li>
            <li *ngIf="reportParams.TimePeriod === 8" class="nav-item">
                <emr-datetimepicker name="txtStartDate" formGroupCssClass="mb-0" identifier="txtStartDate"
                    ngDefaultControl [(ngModel)]="reportParams.FromDate" placeholder="Start Date"
                    i18n-placeholder="@@link_start_date" required CustomizeGrid="true" [containerValue]="''"
                    datePickerDivCssClass="width-125 px-0" (ngModelChange)="onDateChanged()" [showDateTimeInLine]="true"
                    [dateTimeObject]=dateTimeObject [setExtremeTimes]="true" timePickerDivCssClass="width-100 ml-1 px-0"
                    [isDisabled]="reportParams.TimePeriod !== 8" cssClass="calendar-text" i18n-am="@@time_AM"
                    i18n-pm="@@time_PM" am="AM" pm="PM" [minDate]="minDate" [maxDate]="maxDate"
                    [EmrInvalidValidator]="isDateRangeValid"
                    dateTimePickerDivCss="report-date-div {{ dateTimeObject.showMeridian ? '' : ' margin-r5'}}"
                    [showValidationInNewLine]=true validationCss="px-0">
                    <emr-validation-message validatorName="required" message="Start Date/Time Required"
                        i18n-message="@@start_date_required">
                    </emr-validation-message>
                    <emr-validation-message validatorName="EmrInvalidValidator" message="&nbsp;">
                    </emr-validation-message>
                </emr-datetimepicker>
            </li>
            <li *ngIf="reportParams.TimePeriod === 8" class="nav-item">
                <emr-datetimepicker name="txtEndDate" formGroupCssClass="mb-0" ngDefaultControl identifier="txtEndDate"
                    required [(ngModel)]="reportParams.ThruDate" required placeholder="End Date"
                    i18n-placeholder="@@link_end_date" CustomizeGrid="true" [showPickerOnTop]="true"
                    cssClass="calendar-text" [minDate]="reportParams.FromDate ? reportParams.FromDate : minDate" [maxDate]="maxDate" [dateTimeObject]=dateTimeObject
                    [containerValue]="''" [showDateTimeInLine]="true" i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM"
                    pm="PM" [setEndTime]="true" [setExtremeTimes]="true" (ngModelChange)="onDateChanged()"
                    dateTimePickerDivCss="report-date-div  {{ dateTimeObject.showMeridian ? '' : ' margin-r5'}}"
                    datePickerDivCssClass="width-125 px-0" timePickerDivCssClass="width-100 ml-1 px-0"
                    [isDisabled]="reportParams.TimePeriod !== 8" [EmrInvalidValidator]="isDateRangeValid"
                    [showValidationInNewLine]=true validationCss="px-0">
                    <emr-validation-message validatorName="required" message="End Date/Time Required"
                        i18n-message="@@end_date_required">
                    </emr-validation-message>
                    <emr-validation-message validatorName="EmrInvalidValidator" i18n-message="@@greater_end_time"
                        message="End time must be greater than Start time">
                    </emr-validation-message>
                </emr-datetimepicker>
            </li>
            <li *ngIf="tripStateList" class="nav-item width-300">
                <emr-custom-dropdown identifier="ddlTripStatus" name="ddlTripStatus" label="Shipment Status"
                    i18n-label="@@label_trip_status" [options]="tripStateList" [(ngModel)]="reportParams.TripState"
                    cssClass="default-dropdown" [inline]=true labelColumns=5 controlColumns="7" [controlInline]=false
                    labelCssClass="period-lable-css " inlineCss="pl-0" (ngModelChange)="onTripStateChange()"
                    placeholder="Both" i18n-placeholder="@@label_both">
                </emr-custom-dropdown>
            </li>
        </ul>
    </div>
</form>