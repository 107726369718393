<div class="row mx-1 mt-1">
    <div class="col-12">
        <div class="full-width-float-box shipment-name">
            <emr-typeahead identifier="txtShipmentName" name="txtShipmentName" placeholder="Shipment Name"
                i18n-placeholder="@@label_shipment_name" helpTip="Shipment Name" i18n-helpTip="@@label_shipment_name"
                [dataSource]="shipmentNameOptions$" typeaheadOptionFieldName="shipmentName"
                typeaheadHideResultsOnBlur=true
                (ngModelChange)="onShipmentNameChanged()" [typeaheadAsync]="true" [(ngModel)]="filter">
            </emr-typeahead>
        </div>
        <div class="inline-loader-container" *ngIf="trackSvc.isLoading$ | async">
            <app-local-loading-indicator width="35px" height="35px"></app-local-loading-indicator>
        </div>
    </div>
</div>