<emr-modal headerTitle="Close Shipment" i18n-headerTitle="@@close_shipment" [bsModalRef]=bsModalRef
    [showCloseButton]=showCloseButton>
    <div modal-body class="p-15">
        <div *ngIf="hasError">
            <p>{{ errorMessage }}</p>
        </div>
        <div class="text-center clsShipmentButtons">
            <emr-button identifier="closeShipmentOk" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok" (click)='onOkClick()'>
            </emr-button>
        </div>
    </div>
</emr-modal>