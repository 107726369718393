import { ICreateShipmentState } from 'app-modules/core/store/models/create-shipment-state.interface';
import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as createActions from 'app-modules/core/store/create-shipment/create-shipment.actions';

type Actions =
    authActions.SignOut |
    createActions.SelectLinkedCustomer;

export function CreateShipmentReducer(state: ICreateShipmentState = initialState.createShipment, action: Actions): ICreateShipmentState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.createShipment;

        case actionIndex.CREATE_SHIPMENT_SELECT_LINKED_CUSTOMER:
            return {
                ...state,
                linkedCustomer: action.linkedCustomer
            };
        default:
            return state;
    }
}
