
import { SetSensorRangeRequest } from 'emr-ng-shared';
import { SensorRangeInfo } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadSensorRangeList implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES;
    constructor(public linkedCustomerID?: number) { }
}

export class LoadSensorRangeListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES;
    constructor(public sensorRangeList: IListInfo<SensorRangeInfo>) { }
}

export class LoadSensorRangeListError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES;

    constructor(public message: string) { }
}

export class CancelLoadSensorRangeList implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_SENSOR_RANGES;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES;
}

export class CreateSensorRange implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE;
    constructor(public request: SetSensorRangeRequest) { }
}

export class CreateSensorRangeSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE;

    constructor(public SensorRange: SensorRangeInfo) { }
}

export class CreateSensorRangeError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE;

    constructor(public message: string) { }
}

