import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ShipmentDetailComponent } from 'app-modules/shipment-detail/components/shipment-detail/shipment-detail.component';
import { ShipmentDetailPopup } from 'app-modules/core/models/shipment-detail-popup.model';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { IShipmentDetailsInfo } from 'app-modules/core/models/shipment-detail-info.interface';

@Injectable()
export class ShipmentDetailPopupService extends ShipmentDetailPopup {

    bsModalRef: BsModalRef;

    constructor(
        private modalSvc: BsModalService,
        private detailSvc: ShipmentDetailService
    ) {
        super();
    }

    OpenShipmentDetailPopup(shipment: IShipmentDetailsInfo, isFromUnAuthUrl?: boolean): void {
        // const initialState = {
        //     serialNumber: serialNumber
        // };

        // this.detailSvc.selectShipment(shipment);
        const initialState = { Shipment: shipment, defaultToSensorChart: !!isFromUnAuthUrl };
        this.bsModalRef = this.modalSvc.show(
            ShipmentDetailComponent,
            {
                class: 'min-width-1300  modal-dialog-centered',
                ignoreBackdropClick: true,
                initialState
            }
        );
    }
}

