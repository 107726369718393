
export const STORE_KEYS = {
    bulletinMessage: 'emr-user-message',
    acceptedTermsAndConditions: 'emr-AcceptedTermsAndConditions',
    psAuthToken: 'ps-store-key',
    recentContacts: 'emr-recent-contacts',
    createShipmentOptions: 'emr-cs-options',
    hideTrialEndMessage: 'emr-trial-end-message',
    dashboardTermsandConditions: 'emr-Dashboard-TermsAndConditions'
};
export const MESSAGE_VERSION = 20;
export const BULLETIN_MESSAGE_START_DATE = "08/09/2024 00:01"; // MM/dd/YYYY HH:mm
export const BULLETIN_MESSAGE_EXPIRY_DATE = "08/15/2024 23:59"; // MM/dd/YYYY HH:mm
export const CLIMATE_SITE_URL = 'https://climate.emerson.com';
export const HIDDEN_BREADCRUMB_IMAGEID = "dwb";
export const PAKSENSE_AUTH_URL = '/api/users/loginExternal';
export const PAKSENSE_UI_REDIRECT_URL = '/#!/authorizeExternal';
export const BOOK_A_TRAINING_URL = 'https://event.on24.com/wcc/r/3050444/71D9F1EF1DFED749E47E868018C44289';
export const EMERSON_TERMS_URL = 'https://climate.emerson.com/documents/emerson-s-cold-chain-digital-solutions-unified-terms-of-service-v-93020-en-us-7179678.pdf';
export const EMERSON_WARRANTY_URL = 'https://climate.emerson.com/en-us/training-support/warranty';
export const OVERSIGHT_CARGO_SERVICES_URL = 'https://e360hub.emerson.com/oversight-cargo-services';
// Premium Dashboard Setings
export const DASHBOARD_TRIAL_PERIOD: number = 7; // 7 days
export const LOGGER_USER_VERIFICATION_URL = '/api/loggers/loggers/userverification';
export const LOGGER_ERROR_STATUS_CODE = 5000;
