import { ITimeZoneState } from 'app-modules/core/store/models/time-zone-state.interface';

import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as timeZoneActions from './time-zone.actions';

type Actions =
    authActions.SignOut |
    timeZoneActions.LoadTimeZones |
    timeZoneActions.LoadTimeZonesSuccess |
    timeZoneActions.LoadTimeZonesError |
    timeZoneActions.CancelLoadTimeZones;

export function TimeZoneReducer(state: ITimeZoneState = initialState.timeZone, action: Actions): ITimeZoneState {
    switch (action.type) {

        case actionIndex.LOAD_TIME_ZONES:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: true
            };

        case actionIndex.LOAD_TIME_ZONES_SUCCESS:
            return {
                ...state,
                timeZoneList: action.timeZoneList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_TIME_ZONES_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.CANCEL_LOAD_TIME_ZONES:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false
            };

        default:
            return {
                ...state
            };
    }
}
