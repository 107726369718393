<emr-modal [headerTitle]="header" [bsModalRef]=bsModalRef [showCloseButton]=showCloseButton>
    <div modal-body class="modal-body">
        <div class="content mt-2 d-inline-flex">
            <p i18n="@@message_import_locations_has_exceptions_new" *ngIf="hasExceptions; else warningMessage">
                {{exceptions}} of the {{totalRecords}} locations are invalid. Please fix or remove the invalid locations in your file and reimport.
            </p>
            <ng-template #warningMessage>
                <p i18n="@@message_import_locations_no_exceptions">Please review the list of locations and click Submit Locations to complete the import process.</p>
            </ng-template>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="shipmentResponseOk"
                cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2" buttonType="button"
                buttonText="Ok" i18n-buttonText="@@link_ok" (click)='onOkClick()'></emr-button>
        </div>
    </div>
</emr-modal>