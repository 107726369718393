import { Action } from '@ngrx/store';

import { AlertTypeInfo } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadAlertTypes implements Action {
    readonly type = actionIndex.LOAD_ALERT_TYPES;
}

export class LoadAlertTypesSuccess implements Action {
    readonly type = actionIndex.LOAD_ALERT_TYPES_SUCCESS;
    constructor(public alertTypeList: IListInfo<AlertTypeInfo>) { }
}

export class LoadAlertTypesError implements Action {
    readonly type = actionIndex.LOAD_ALERT_TYPES_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadAlertTypes implements Action {
    readonly type = actionIndex.CANCEL_LOAD_ALERT_TYPES;
}

