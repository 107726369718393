import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DeleteTripComponent } from '../components/delete-trip/delete-trip.component';
import { DeleteTripPopup } from '../models/delete-trip-popup.model';
import { Shipment } from '../models/shipment.model';


@Injectable()
export class DeleteTripService extends DeleteTripPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenDeleteTripConfirmation(shipment: Shipment, closePopupOnSuccess?: BsModalRef): void {
        const initialState = {
            Shipment: shipment,
            closePopupOnSuccess
        };

        this.bsModalRef = this.modalSvc.show(
            DeleteTripComponent,
            {
                initialState,
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}
