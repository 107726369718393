<emr-modal id="btnFrmCreateShipment" *ngIf="bsMainModalRef" class="scrollable-modal"
    headerTitle="NIST Calibration Statements" i18n-headerTitle="@@label_nist_calibration_statements"
    [bsModalRef]=bsMainModalRef>
    <ng-container modal-body>
        <div class="col-12 py-2 bg-nist nist-div-height">
            <div class="row mt-2">
                <div class="col-12 roboto-regular-font font-20 color-regular">
                    <p class="col-md-4 px-0">
                        <span i18n="@@label_nist_calibration_statements">NIST Calibration Statements</span>
                    </p>
                    <span>
                        <a href="https://www.nist.gov/" class="font-14" target="_blank">National Institute of Standards
                            and
                            Technology, U.S Department of Commerce</a>
                    </span>
                    <hr class="mb-1">
                </div>
            </div>
            <div class="row calibration-content mt-2">
                <div class="col-md-5">
                    <form name=" frmSearialNameSearch" #clibrateStatementForm="ngForm" emrForm autocomplete="off"
                        novalidate class="w-100">
                        <div class="col-md-12 pl-0 pr-1 d-block">
                            <emr-textarea identifier="txtSerialNumb" name="txtSerialNumb" placeholder="Serial #"
                                i18n-placeholder="@@serial_number" [(ngModel)]="SerialNumbers" [textAreaRows]="5"
                                required>
                            </emr-textarea>
                        </div>
                        <div class="offset-md-8 px-1 mb-3">
                            <emr-button identifier="nist_submit" buttonType="submit"
                                cssClass="btn btn-primary submit-btn" i18n-buttonText="@@search" buttonText="Search"
                                (onClick)="onSerialNumberSubmit()">
                            </emr-button>
                        </div>
                    </form>
                    <div *ngIf="calibrationStatementList?.length; else no_records">
                        <table class="table table-data-container table-striped table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        <emr-checkbox identifier="chkCollbration" name="chkCollbration"
                                            class="d-inline-block" [(ngModel)]="isSelectedAll"
                                            (onChange)="onSelectAllClick()" cssClass="color-black"><span
                                                class="invisible">.</span></emr-checkbox>
                                    </th>
                                    <th class="text-center" i18n="@@label_model_name">Model Name</th>
                                    <th class="text-center" i18n="@@label_count">Count</th>
                                    <th class="text-center"></th>
                                </tr>
                            </thead>
                            <tr *ngFor="let c of calibrationStatementList;index as i">
                                <td class="text-center">
                                    <emr-checkbox identifier="chkCollbration_{{i}}" name="chkCollbration_{{i}}"
                                        class="d-inline-block" [(ngModel)]="c.IsChecked" cssClass="color-black">
                                        <span class="invisible">.</span></emr-checkbox>
                                </td>
                                <td class="text-center">{{c.ModelName}}</td>
                                <td class="text-center">{{c.Count}}</td>
                                <td class="text-center">
                                    <emr-button identifier="btnDownloadPDF"
                                        cssClass="btn-outline-default ml-2 mt-0 down-btn pdf-btn"
                                        buttonIcon="fa fa-file-pdf-o" (onClick)="onClickExportPDF(c)"
                                        helpTip="Download Calibration Statement in pdf format"
                                        i18n-helpTip="@@tooltip_download_calibration_statement_pdf"
                                        helpTipPosition="left" container="body">
                                    </emr-button>
                                </td>
                            </tr>
                            <tfoot>
                                <tr>
                                    <td colspan="4" class="text-left">
                                        <emr-button identifier="btnSendEmail"
                                            cssClass="btn-outline-default ml-2 mt-0 email-btn"
                                            buttonIcon="fa fa-envelope-o" (onClick)="onSendEmail()"
                                            buttonText="Email Calibration Statement(s) in PDF format"
                                            i18n-buttonText="@@email_calibration_statement_pdf"
                                            [setDisabled]="isSendEmailInprogress">
                                        </emr-button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <ng-template #no_records>
                        <div class="row" *ngIf="noRecords">
                            <div class="col-md-12" i18n="@@serial_number_not_found">
                                The following serial numbers were not found</div>
                        </div>
                    </ng-template>
                </div>
                <div class="col-md-7 px-3" *ngIf="serialNumbersWithOutModel.length">
                    <div class="mb-2"><b>Statement Not Found</b></div>
                    <div class="pl-2" *ngFor="let c of serialNumbersWithOutModel">
                        {{c}}
                    </div>
                    <p class="text-justify mt-2">We're sorry, but we couldn't find a matching NIST Calibration
                        Statement for
                        the
                        part or serial number
                        you entered. This could be due to a few reasons:</p>
                    <ul>
                        <li>
                            The number may have been entered incorrectly. Please double-check and try again.
                        </li>
                        <li>
                            We may not have the statement in our system. It's possible the part or serial number
                            is
                            new, or
                            we're in the process of updating our database.
                        </li>
                        <li>
                            If you've entered a part number, try using the serial number instead, or vice versa.
                        </li>
                    </ul>
                    <div><b>Need further assistance?</b></div>
                    <p>
                        If you continue to encounter issues, please reach out to our support team with the details of
                        the
                        device or part you're inquiring about, and we'll be happy to assist you.
                    </p>
                    <p>
                        <b>Contact Support: </b><a
                            href="mailto:ColdChain.TechnicalServices@copeland.com">ColdChain.TechnicalServices@copeland.com</a>
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
</emr-modal>