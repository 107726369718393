import { TimePeriod } from "emr-ng-shared";

export const time_period_text = [
    { id: TimePeriod.All, text: $localize`:@@period_All:All` },
    { id: TimePeriod.Last30Days, text: $localize`:@@period_Last30Days:Last 30 Days` },
    { id: TimePeriod.Last30DaysOfActivity, text: $localize`:@@period_Last30DaysOfActivity:Last 30 Days of Activity` },
    { id: TimePeriod.Last7Days, text: $localize`:@@period_Last7Days:Last 7 Days` },
    { id: TimePeriod.Last90Days, text: $localize`:@@period_Last90Days:Last 90 Days` },
    { id: TimePeriod.LastEightHours, text: $localize`:@@period_LastEightHours:Last Eight Hours` },
    { id: TimePeriod.LastFourHours, text: $localize`:@@period_LastFourHours:Last Four Hours` },
    { id: TimePeriod.LastMonth, text: $localize`:@@period_LastMonth:Last Month` },
    { id: TimePeriod.LastSixHours, text: $localize`:@@period_LastSixHours:Last Six Hours` },
    { id: TimePeriod.LastTwoHours, text: $localize`:@@period_LastTwoHours:Last Two Hours` },
    { id: TimePeriod.LastTwoWeeks, text: $localize`:@@period_LastTwoWeeks:Last Two Weeks` },
    { id: TimePeriod.LastWeek, text: $localize`:@@period_LastWeek:Last Week` },
    { id: TimePeriod.LastYear, text: $localize`:@@period_LastYear:Last Year` },
    { id: TimePeriod.None, text: $localize`:@@period_None:None` },
    { id: TimePeriod.SpecificRange, text: $localize`:@@period_SpecificRange:Specific Range` },
    { id: TimePeriod.ThisMonth, text: $localize`:@@period_ThisMonth:This Month` },
    { id: TimePeriod.ThisWeek, text: $localize`:@@period_ThisWeek:This Week` },
    { id: TimePeriod.ThisYear, text: $localize`:@@period_ThisYear:This Year` },
    { id: TimePeriod.Today, text: $localize`:@@period_Today:Today` },
    { id: TimePeriod.Trip, text: $localize`:@@period_Trip:Current Shipment` },
    { id: TimePeriod.Yesterday, text: $localize`:@@period_Yesterday:Yesterday` },
    { id: TimePeriod.Last48Hours, text: $localize`:@@period_Last48Hours:Last 48 Hours` }
]
