import { Store } from '@ngrx/store';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import * as bindingActions from 'app-modules/core/store/actions/data-binding.actions';
import { Injectable } from '@angular/core';


@Injectable()
export class BindingDataStateService {
    constructor(private store: Store<IAppState>) { }

    // Actions
    public bindData() {
        this.store.dispatch(new bindingActions.BindData());
    }

    public bindDataComplete(message: string) {
        this.store.dispatch(new bindingActions.BindDataComplete(message));
    }

    public bindDataError(message: string) {
        this.store.dispatch(new bindingActions.BindDataError(message));
    }
}
