<emr-modal [bsModalRef]=bsShowDistanceModalRef customHeaderClass="user-message-header" (onCloseClick)="onPopupCancel()">
  <div modal-body class="col-md-12 user-message">
    <div class="row pt-3 pl-3 pr-3 pb-3">
      <div class="col-md-4">
        <img src="assets/copeland_blue_transparent.png" class="mt-n3 w-100">
      </div>
    </div>
    <div class="pl-3 pr-3 scrollable-user-messsage-modal">
      <p class=" ">
        Dear Valued Customer,
      </p>
      <p class="text-justify">
        Please note a scheduled maintenance for our Oversight servers:
      </p>
      <!-- <p>The following interfaces and services will be impacted:</p> -->
      <ul>
        <li>When: Wed, August 14, 11 PM ET </li>
        <li>Expected Duration: 15 minutes </li>
      </ul>
      <p class="text-justify">
        <b>What to Expect: </b>
      </p>
      <ul>
        <li>Operational: GO Wireless emails, Logger UI. </li>
        <li>Unavailable: Tracker UI, tracker alerts/notifications, EDI/API services. (Data will backfill
          post-maintenance.) </li>
      </ul>
      <p>
        <b>Need Help?</b>
        <!-- If you have any questions, please contact 24/7 Technical Support: 1-877-998-7299 or <a
          href="mailto:ColdChain.TechnicalServices@copeland.com">ColdChain.TechnicalServices@copeland.com</a> -->
      </p>
      <p>24/7 Support: 1-877-998-7299 or <a
          href="mailto:ColdChain.TechnicalServices@copeland.com">ColdChain.TechnicalServices@copeland.com</a> </p>
      <p>We apologize for any inconvenience and thank you for your understanding.</p>
    </div>
    <div class="modal-footer p-0">
      <div class="pb-3">
        <emr-button identifier="closeMessage" buttonType="reset" buttonText="Close" i18n-buttonText="@@close"
          cssClass="btn-outline-default reset-btn" (onClick)="onPopupCancel()">
        </emr-button>
      </div>
    </div>
  </div>
</emr-modal>