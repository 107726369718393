import { Component, OnDestroy, Renderer2, OnInit, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { Store } from '@ngrx/store';
import { ngxlocalization } from 'app-modules/core/services/ngx-localization.service';
import { environment } from 'environments/environment';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { Meta } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { Fullstory } from 'emr-ng-shared';
import packageInfo from 'app-modules/../../../package.json';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { StoreManager } from 'app-modules/core/utils/store-manager';
import { STORE_KEYS, MESSAGE_VERSION, BULLETIN_MESSAGE_EXPIRY_DATE, BULLETIN_MESSAGE_START_DATE } from 'app-modules/core/consts/values';
import { Platform } from '@angular/cdk/platform';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    isLoading$: Observable<boolean>;
    subscription: Subscription;
    hideMenuSubscription: Subscription;
    hideSideMenu = true;
    constructor(
        private store: Store<IAppState>,
        private ngx: ngxlocalization,
        private meta: Meta,
        private router: Router,
        public fullstory: Fullstory,
        private popupSvc: OversightPopupService,
        private authSvc: AuthService,
        private renderer: Renderer2,
        private platform: Platform
    ) {
        if (this.platform.TRIDENT) {
            this.popupSvc.showIEUserMessagePopup();
        }
        this.renderer.listen('window', 'click', (e: Event) => {
            const element = (e.target) as HTMLElement;
            if (!element.classList ||
                (element.classList &&
                    !(element.classList.contains('hamberg-menu')) &&
                    !(element.classList.contains('disable-menu-close')) &&
                    !(element.classList.contains('has-sub-menu')))) {
                if (!this.hideSideMenu) {
                    this.authSvc.updateMenuStatus(true);
                }
            }
        });

        this.fullstory.setUserVars({
            BuildNumber: packageInfo.version + '.' + environment.buildNumber
        });

        this.isLoading$ = this.store.select('pendingRequests').pipe(
            map(val => val.items?.length > 0)
        );
        this.subscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                localStorage.setItem(environment.isBrowerRefreshed, !router.navigated ? '1' : '0');
            }
        });

        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        ngx.UpdateLocale(localeResolver.resolveLocale());
        const today = new Date();
        if (today < new Date(BULLETIN_MESSAGE_EXPIRY_DATE) && today > new Date(BULLETIN_MESSAGE_START_DATE)) {
            const lastReadBuild = StoreManager.GetValue(STORE_KEYS.bulletinMessage);
            if (!lastReadBuild ||
                Number.parseInt(lastReadBuild, 10) < MESSAGE_VERSION) {
                this.popupSvc.showUserMessagePopup();
            }
        }

    }

    ngOnInit() {
        this.hideMenuSubscription = this.authSvc.hideSideMenu.subscribe(k => this.hideSideMenu = k);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        if (this.hideMenuSubscription) {
            this.hideMenuSubscription.unsubscribe();
        }
    }
}
