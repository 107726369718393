import { Injectable, EventEmitter, Inject, OnDestroy } from '@angular/core';

import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, withLatestFrom, take, startWith } from 'rxjs/operators';

import {
    muteFirst, UpdateShipmentResponse, GetTrackerStatusRequest, CreateShipmentRequest, DateRange,
    UpdateShipmentRequest, TrackerStatus, TimePeriod, CloseTripRequest, EmrUtilService, CreateShipmentResponse,
    ExportTrackerStateReportHeaders, TripState, BusinessRuleType,
    SetTripSensorRangeRequest, SetTripSensorRangeResponse, CloseTripResponse,
    CustomMapServiceFactory, MapServiceFactory, Customer, SetTrackerNoteRequest, SetTrackerNoteResponse, GetShipmentRequest, ShipmentRequest,
    SetShutDownTrackerRequest, SetShutDownTrackerResponse, ShareTrackerRequest, ShareTrackerResponse
} from 'emr-ng-shared';

import { ShipmentStateService } from 'app-modules/core/store/services/shipment-state.service';
import { ShipmentFilterStateService } from 'app-modules/core/store/shipment-filter/shipment-filter-state.service';
import { ShipmentSortStateService } from 'app-modules/core/store/services/shipment-sort-state.service';
import { ShipmentPagingStateService } from 'app-modules/core/store/services/shipment-paging-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList, setListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { IShipmentFilterState, getDefaultShipmentFilter } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { handleErrorResponse } from '../rxjs/operators/handle-error-response.operator';
import { IErrorInfo } from '../models/error-info.interface';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {
    CloseShipmentResponseOkEventArgs,
    CloseShipmentResponseComponent
} from 'app-modules/dashboard/components/close-shipment-response/close-shipment-response.component';
import { environment } from 'environments/environment';
import { IListInfoWithErrorResponse, setListInfoWithErrorResponse } from '../store/models/list-info-with-error-state.interface';
import { ITimePeriod } from '../store/models/time-period.interface';
import { ShipmentStatusStateService } from '../store/services/shipment-status-state.service';
import { AuthService } from '../store/auth/auth.service';
import * as _ from 'lodash';
import { ShipmentDetailStateService } from '../store/services/shipment-detail-state.service';
import { BusinessRulesService } from '../store/business-rules/business-rules.service';
import { EditShipmentResponseComponent, EditShipmentResponseOkEventArgs }
    from 'app-modules/create-shipment/components/edit-shipment-response/edit-shipment-response.component';
import { UpdateShipment } from '../models/update-shipment.model';
import { UnAuthStateService } from '../store/un-auth/un-auth-state.service';
import { dateAdd } from '../utils/common-utility';
import { BaseURLPicker } from 'app-modules/core/services/baseurl-picker.service';
import { UnAuthService } from '../store/un-auth/un-auth.service';
import { UnAuthUserSettings } from '../models/unauth-user-settings.model';
import { DomoUIStateService } from 'app-modules/domo/store/domo.state.service';

@Injectable()
export class ShipmentService implements OnDestroy {
    private lastActivity: Date = new Date();
    private lastPageActivity: Date = new Date();
    private isAuthenticated = false;
    private refreshDelay = environment.refreshDelay;
    AutoRefreshShipmentsSubscription: Subscription;
    updateTrackerStatusSubscription: Subscription;
    authSubscription: Subscription;
    closeShipSubscription: Subscription;
    requestShipmentSubscription: Subscription;
    closeShipmentSubscription: Subscription;
    private refreshSubDelay = environment.refreshSubDelay;
    private interval;
    public IsFromUnAuthURL = false;
    public isAutoRefreshed: boolean = false;
    public isRefreshProcessCompleted: boolean = true;
    private appliedShipmentFilter$: Observable<IShipmentFilterState>;
    private readonly shipmentListLoader$ = this.getShipmentListLoader().pipe(startWith(null));
    public isHighResultCount = false;
    public isDefaultInProgressOnly = false;
    public ResultThreshold = 1000;
    // public isShipmentListUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private shipStateSvc: ShipmentStateService,
        private shipFltrStateSvc: ShipmentFilterStateService,
        private shipSortStateSvc: ShipmentSortStateService,
        private shipPgStateSvc: ShipmentPagingStateService,
        private utilSvc: EmrUtilService,
        private oversightSvc: OversightApiService,
        private modalSvc: BsModalService,
        private authSvc: AuthService,
        private unAuthStateSvc: UnAuthStateService,
        private detailStateSvc: ShipmentDetailStateService,
        private shipStatusSvc: ShipmentStatusStateService,
        private domainPicker: BaseURLPicker,
        @Inject(MapServiceFactory) private mapSvc: CustomMapServiceFactory,
        private unAuthService: UnAuthService,
        private domoStateService: DomoUIStateService
    ) {
        this.authSubscription = this.authSvc.authenticated$.subscribe(n => this.isAuthenticated = n);
    }

    public oversightImageURL: string = this.domainPicker.resourceDomain;
    public oversightCommonPath: string = '/LocusOverSight/V2/WebApp/Images/Common/AppImages/';

    public isLoadRequired$ = this.shipStateSvc.isLoadRequired$;
    public isLoading$ = this.shipStateSvc.isLoading$;
    public shipmentListComeFromApi$ = this.shipStateSvc.shipmentListComeFromApi$;

    isEditShipmentLoading$ = this.shipStateSvc.isEditShipmentLoading$;

    public shipmentPaging$ = this.shipPgStateSvc.shipmentPaging$;

    public isInfoBoxVisible: boolean = false;
    public OnShipmentAddressUpdated = new EventEmitter<Shipment[]>(true);

    private isShipmentUpdated$: BehaviorSubject<Shipment> = new BehaviorSubject<Shipment>(null);
    public isDashboardShipmentsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public shipmentList$: Observable<IListInfo<Shipment>> = muteFirst(
        this.shipmentListLoader$,
        this.shipStateSvc.shipmentList$
    );


    public shipmentListPage$: Observable<IListInfo<Shipment>> = muteFirst(
        this.shipmentListLoader$,
        this.shipPgStateSvc.shipmentListPage$
    );

    public closeSpinners = () => this.shipStateSvc.closeSpinners();

    public setShipmentFilter(isAppliedFilter: IShipmentFilterState) {
        this.appliedShipmentFilter$ = of(isAppliedFilter);
    }

    public isinfoBoxVisible(isVisible: boolean) {
        this.isInfoBoxVisible = isVisible;
    }

    public autoRefreshshipmentListLoader() {
        this.AutoRefreshShipmentsSubscription = this.getRefreshShipmentListLoader().subscribe();
    }

    public setShipmentUpdatedValue(value: Shipment): void {
        this.isShipmentUpdated$.next(value);
    }

    public setDashboardShipmentsStatus(value: boolean): void {
        this.isDashboardShipmentsLoading$.next(value);
    }

    public getShipmentUpdatedValue(): Observable<Shipment> {
        return this.isShipmentUpdated$;
    }

    public getUnAuthenticationToken(): boolean {
        return this.oversightSvc.getUnAuthenticationToken();
    }

    ngOnDestroy() {
        if (this.AutoRefreshShipmentsSubscription) {
            this.AutoRefreshShipmentsSubscription.unsubscribe();
        }
        if (this.updateTrackerStatusSubscription) {
            this.updateTrackerStatusSubscription.unsubscribe();
        }
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
        if (this.closeShipSubscription) {
            this.closeShipSubscription.unsubscribe();
        }
        if (this.requestShipmentSubscription) {
            this.requestShipmentSubscription.unsubscribe();
        }
        if (this.closeShipmentSubscription) {
            this.closeShipmentSubscription.unsubscribe();
        }
    }

    // OR-1590 changes
    public createShipment(request: CreateShipmentRequest): Observable<CreateShipmentResponse> {
        // public createShipment(request: CreateShipmentRequest): Observable<number> {
        return of(request).pipe(
            tap(n => this.shipStateSvc.createShipment(n)),
            switchMap(n => this.oversightSvc.CreateShipmentNew(n)),
            handleErrorResponse(),
            tap(
                (n: CreateShipmentResponse) => {
                    // OR-1590 changes
                    if (n.ErrorCode === 0) {
                        this.shipFltrStateSvc.customChangeShipmentFilter();
                        this.shipStateSvc.createShipmentSuccess(n.TripId);
                    }
                },
                e => this.shipStateSvc.createShipmentError(e.message)
            ),
            map(n => n)
        );
    }

    public updateShipment(request: UpdateShipmentRequest): Observable<number> {
        return of(request).pipe(
            tap(n => this.shipStateSvc.updateShipment(n)),
            switchMap(n => {
                return this.oversightSvc.UpdateShipment(n).pipe(
                    handleErrorResponse(),
                    map<UpdateShipmentResponse, UpdateShipmentResponse>(
                        n1 => n1
                    )
                );
            }),
            tap(
                n => this.shipStateSvc.updateShipmentSuccess(n.TripId),
                (e: IErrorInfo) => this.shipStateSvc.updateShipmentError(e.message)
            ),
            map(n => n.TripId)
        );
    }

    public changeShipmentListPage(pageNumber: number) {
        this.shipPgStateSvc.changeShipmentsPage(pageNumber);
    }

    public changeShipmentListPageSize(pageSize: number) {
        this.shipPgStateSvc.changeShipmentsPageSize(pageSize);
    }

    /*
     * @deprecated this method no longer in use
     */
    public loadShipmentAddress(serialNumber: string) {
        const request = new GetTrackerStatusRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.TrackerId = serialNumber;
        request.ReverseGeocode = true;
        this.shipStateSvc.loadShipmentAddress();
        this.requestShipmentSubscription = this.requestShipmentList(request).pipe(
            tap(
                n => this.shipStateSvc.loadShipmentAddressSuccess(n),
                e => this.shipStateSvc.loadShipmentAddressError(e)
            )
        ).pipe(take(1)).subscribe();
    }

    public GetCurrentLocation(serialNumber: string): Observable<Shipment> {
        const request = new GetTrackerStatusRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.TrackerId = serialNumber;
        request.ReverseGeocode = true;
        request.IsHighResultCount = this.isHighResultCount;
        request.IsDefaultInProgressOnly = this.isDefaultInProgressOnly;
        this.shipStateSvc.loadShipmentAddress();
        return this.requestShipmentListNew(request).pipe(
            tap(
                n => this.shipStateSvc.loadShipmentAddressSuccess(n),
                e => this.shipStateSvc.loadShipmentAddressError(e),
            ),
            map(n => {
                if (n && n.list && n.list.length > 0) {
                    return n.list[0];
                } else { return null; }
            })
        ).pipe(take(1));
    }

    public ReLoadShipments() {
        this.shipStateSvc.reloadShipments();
    }

    private getShipmentListLoader(): Observable<IListInfoWithErrorResponse<Shipment>> {
        return this.shipStateSvc.isLoadRequired$.pipe(
            filter(isLoadRequired => isLoadRequired),
            map((n) => {
                this.shipStateSvc.loadShipments()
                return n;
            }),
            withLatestFrom(this.shipFltrStateSvc.shipmentFilter$, (a, b) => b),
            withLatestFrom(this.shipSortStateSvc.sortState$, (a, b) => (
                { filter: a, sort: b.selectedSort, isDescendingSort: b.isDescending })),
            switchMap(a => this.getShipmentList(a.filter, a.sort, a.isDescendingSort)),
            map(
                n => {
                    this.shipStateSvc.loadShipmentsSuccess(n)
                    return n;
                },
                e => {
                    this.shipStateSvc.loadShipmentsError('')
                    return e
                }
            ),
            finalize(() => this.shipStateSvc.cancelLoadShipments()),
            catchError(() => of(emptyList()))
        );
    }

    public getDashboardShipmentListLoader(filters: IShipmentFilterState): Observable<IListInfoWithErrorResponse<Shipment>> {
        return of(null).pipe(
            tap(n => { this.setDashboardShipmentsStatus(true); }),
            switchMap(a => this.getShipmentList(filters, '', false, true)),
            tap(n => { this.setDashboardShipmentsStatus(false); }),
            catchError(() => {
                this.setDashboardShipmentsStatus(false);
                return of(emptyList());
            }),
            share()
        );
    }

    public getRefreshShipmentListLoader(): Observable<IListInfo<Shipment>> {
        return this.shipStateSvc.isAutoLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.shipStateSvc.autoRefreshShipments()),
            withLatestFrom(this.appliedShipmentFilter$, (a, b) => b),
            withLatestFrom(this.shipSortStateSvc.sortState$, (a, b) => (
                { filter: a, sort: b.selectedSort, isDescendingSort: b.isDescending })),
            switchMap(a => {
                const request = new GetTrackerStatusRequest();
                request.TripStates = [TripState.Pending, TripState.InProgress];
                request.IsHighResultCount = this.isHighResultCount;
                request.IsDefaultInProgressOnly = this.isDefaultInProgressOnly;
                request.PeriodReportedInterval = TimePeriod.Last90Days;
                request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
                return this.requestShipmentListNew(request);
                // this.getShipmentList(a.filter, a.sort, a.isDescendingSort)),
            }),
            tap(
                n => {
                    if (this.checkBrowserIdleState()) {
                        this.shipStateSvc.AutoRefreshShipmentsSuccess(n);
                        this.isRefreshProcessCompleted = true;
                        this.restartAutoRefresh(this.refreshDelay);
                    } else {
                        this.checkSubAutoRefresh();
                        this.isRefreshProcessCompleted = false;
                    }
                },
                e => {
                    this.shipStateSvc.AutoRefreshShipmentsError('');
                    this.restartAutoRefresh(this.refreshDelay);
                }
            ),
            finalize(() => this.shipStateSvc.CancelAutoRefreshShipments()),
            catchError(() => of(emptyList()))
        );
    }

    private getShipmentList(filters: IShipmentFilterState, sort: string, isDescendingSort: boolean, skipThreshold: boolean = false):
        Observable<IListInfoWithErrorResponse<Shipment>> {
        const request = this.getShipmentsRequest(filters, sort, isDescendingSort, skipThreshold);
        if (request.IsHistorical && request.PeriodReportedInterval === null && !request.QuickSearch) {
            if (this.checkFilterState(filters)) {
                request.PeriodReportedInterval = TimePeriod.All;
            } else {
                request.PeriodReportedInterval = TimePeriod.None;
                return of(setListInfoWithErrorResponse([], false));
            }
        }

        return this.requestShipmentListNew(request);
    }

    private getShipmentsRequest(filters: IShipmentFilterState, sort: string, isDescendingSort: boolean, skipThreshold: boolean) {
        const request = new GetTrackerStatusRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.IsUserDefaultFilter = filters.IsUserDefaultFilter;
        if (filters.serialNumber && filters.serialNumber !== '') {
            request.TrackerId = filters.serialNumber;
        } else {
            request.TrackerId = null;
        }
        request.ShipmentName = filters.shipmentName;
        request.QuickSearch = filters.quickSearch;

        request.TripStates = filters.status;
        request.PeriodReportedInterval = filters.period.timePeriod;
        if (this.getUnAuthenticationToken() && !request.TrackerId) {
            request.TrackerId = sessionStorage.getItem(environment.unAuthTrackers);
            request.PeriodReportedInterval = TimePeriod.All;
            if ((request.TrackerId.indexOf(',') < 0 &&
                request.TrackerId === request.QuickSearch) ||
                (request.TrackerId.endsWith(',' + request.QuickSearch) ||
                    request.TrackerId.indexOf(request.QuickSearch + ',') >= 0)) {
                request.QuickSearch = null;
            }
        }
        if (request.QuickSearch) {
            request.PeriodReportedInterval = null;
        }
        if (request.PeriodReportedInterval === TimePeriod.SpecificRange) {
            if (filters.period.dateRange.startDateTime) {
                request.BeginReportedInterval =
                    this.utilSvc.DateFormatLocaleChange(filters.period.dateRange.startDateTime);
            }

            if (filters.period.dateRange.endDateTime) {
                request.EndReportedInterval =
                    this.utilSvc.DateFormatLocaleChange(filters.period.dateRange.endDateTime);
            }
        } else if (request.PeriodReportedInterval === TimePeriod.Last7Days) {
            request.PeriodReportedInterval = TimePeriod.SpecificRange;
            const dateRange = this.getTimePeriodDates({ timePeriod: TimePeriod.Last7Days, dateRange: null, isDefaultSelection: true });
            request.BeginReportedInterval =
                this.utilSvc.DateFormatLocaleChange(dateRange.startDateTime);
            request.EndReportedInterval =
                this.utilSvc.DateFormatLocaleChange(dateRange.endDateTime);
        } else if (request.PeriodReportedInterval === TimePeriod.Last48Hours) {
            request.PeriodReportedInterval = TimePeriod.SpecificRange;
            const dateRange = this.getTimePeriodDates({ timePeriod: TimePeriod.Last48Hours, dateRange: null, isDefaultSelection: true });
            request.BeginReportedInterval =
                this.utilSvc.DateFormatLocaleChange(dateRange.startDateTime);
            request.EndReportedInterval =
                this.utilSvc.DateFormatLocaleChange(dateRange.endDateTime);
        }

        if (filters.alert.aboveTemp) { request.AboveTemp = filters.alert.aboveTemp; }
        if (filters.alert.belowTemp) { request.BelowTemp = filters.alert.belowTemp; }
        if (filters.alert.late) { request.Late = filters.alert.late; }
        // if (filters.alert.moving) { request.Moving = filters.alert.moving; }
        if (filters.alert.notMoving) { request.NotMoving = filters.alert.notMoving; }

        request.OriginIds = filters.origin;
        request.DestinationIds = filters.destination;
        request.SensorRangeIds = filters.sensorRange;
        request.CarrierNames = filters.carrier;

        request.OrderBy = sort;
        request.SortDescending = isDescendingSort;
        request.IsHistorical = filters.IsHistorical;
        request.IsHighResultCount = this.isHighResultCount;
        request.IsDefaultInProgressOnly = this.isDefaultInProgressOnly;
        request.SkipThresholdCheck = skipThreshold;
        request.TripStartLocationCity = filters.originCity;
        request.TripStartLocationState = filters.originState;
        request.TripStartLocationZipCode = filters.originZip;
        request.TripStartLocationCountry = filters.originCountry;
        request.TripEndLocationCity = filters.destinationCity;
        request.TripEndLocationState = filters.destinationState;
        request.TripEndLocationCountry = filters.destinationCountry;
        request.TripEndLocationZipCode = filters.destinationZip;
        request.SupplierCompanyIds = filters.supplierCustomer?.join();;
        request.DCTripStopLocationIds = filters.dcTripStopLocation?.join();
        request.MostRecentPostDC = filters.mostRecentPostDC;
        return request;
    }

    requestShipmentList(request: GetTrackerStatusRequest, skipAutoRefresh: boolean = false):
        Observable<IListInfoWithErrorResponse<Shipment>> {
        if (!request) {
            return of(setListInfoWithErrorResponse([], false));
        }
        return this.oversightSvc.GetTrackerStatus(request).pipe(

            map(n => {
                let shipmentList = [];
                if (n.TrackerStatusList) {
                    shipmentList = this.getShipmentListFromTrackerStatusList(n.TrackerStatusList);
                }

                if (this.getUnAuthenticationToken() && n?.Customer) {
                    this.unAuthStateSvc.SaveUnAuthCustomer(n?.Customer);
                    this.unAuthStateSvc.SaveUnAuthCreateShipmentAccess(n?.HasCreateShipmentAccess);
                }

                if (!skipAutoRefresh) {
                    this.restartAutoRefresh(this.refreshDelay);
                    this.isRefreshProcessCompleted = true;
                }
                return {
                    list: shipmentList,
                    itemCount: n.TotalItems,
                    isPaged: false,
                    LocalizedErrorMessage: n.LocalizedErrorMessage,
                    ErrorDescription: n.ErrorDescription,
                    ErrorCode: n.ErrorCode
                };
            })
        );
    }

    requestShipmentListNew(request: GetTrackerStatusRequest, skipAutoRefresh: boolean = false)
        : Observable<IListInfoWithErrorResponse<Shipment>> {
        return this.oversightSvc.GetTrackerStateList(request).pipe(
            map(n => {
                let shipmentList = [];
                if (n.TrackerStatusList) {
                    shipmentList = this.getShipmentListFromTrackerStatusList(n.TrackerStatusList);

                }
                if (this.getUnAuthenticationToken() && n?.Customer) {
                    if (!this.unAuthService.validateUSettingsForNull()) {
                        const customer = n?.Customer;
                        this.unAuthService.setAuthUserSettings({
                            dateFormatEnum: customer.DateFormatEnum,
                            distanceUnits: customer.DistanceUnits,
                            temperatureUnits: customer.TemperatureUnits,
                            timeFormatEnum: customer.TimeFormatEnum,
                            timeZoneID: customer.TimeZoneId
                        } as UnAuthUserSettings)
                    }
                    this.unAuthStateSvc.SaveUnAuthCustomer(n?.Customer);
                    this.unAuthStateSvc.SaveUnAuthCreateShipmentAccess(n?.HasCreateShipmentAccess);
                    this.unAuthStateSvc.SaveUnAuthCustomerLocation({
                        latitude: n?.DefaultLatitude,
                        longitude: n?.DefaultLongitude
                    });
                }
                if (!skipAutoRefresh) {
                    this.restartAutoRefresh(this.refreshDelay);
                    this.isRefreshProcessCompleted = true;
                }
                this.isHighResultCount = n.IsHighResultCount;
                this.ResultThreshold = n.ResultThreshold;
                return {
                    list: shipmentList,
                    itemCount: n.TotalItems,
                    isPaged: false,
                    LocalizedErrorMessage: n.LocalizedErrorMessage,
                    ErrorDescription: n.ErrorDescription,
                    ErrorCode: n.ErrorCode
                };
            })
        );
    }

    public updateTrackerStatusCloseInstanceInState(trackerId: string, customerTrackerId: string, tripId: number) {
        if (this.updateTrackerStatusSubscription) {
            this.updateTrackerStatusSubscription.unsubscribe();
            this.updateTrackerStatusSubscription = null;
        }
        this.updateTrackerStatusSubscription = this.updateTrackerStatusbyIdinState(trackerId,
            customerTrackerId, tripId).pipe(take(1)).subscribe();
    }

    public updateTrackerStatusbyIdinState(trackerId: string, customerTrackerId: string, tripId: number): Observable<Shipment> {
        this.shipStateSvc.loadShipmentbyId();
        const request = new GetTrackerStatusRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.TrackerId = trackerId;
        request.CustomerTrackerId = customerTrackerId;
        request.PeriodReportedInterval = null;
        return this.oversightSvc.GetTrackerStateList(request).pipe(
            map(n => {
                if (n.ErrorCode === 0) {
                    let shipmentTracker: Shipment[];
                    if (n.TrackerStatusList) {
                        const trips = tripId ? n.TrackerStatusList.filter(k => k.TripId === tripId) : n.TrackerStatusList;
                        shipmentTracker = this.getShipmentListFromTrackerStatusList(trips);
                    }
                    // if (updateShutdownTemp && shipmentTracker.length > 0 && shipmentTracker[0].CanShutdownTracker) {
                    //     this.updateLocalStateToShutdownOnSync(shipmentTracker[0]);
                    // }
                    if (shipmentTracker.length === 1) {

                        this.shipStateSvc.loadShipmentbyIdSuccess(shipmentTracker[0]);
                    } else {
                        this.shipStateSvc.loadShipmentbyTrackerIdSuccess(shipmentTracker);
                    }
                    return shipmentTracker.length > 0 ? shipmentTracker[0] : null;
                } else {
                    this.shipStateSvc.loadShipmentbyIdError(n.ErrorDescription);
                    return null;
                }
            }),
            finalize(() => this.shipStateSvc.cancelloadShipmentbyId()),
        );
    }

    public loadObservableShipmentAddress(serialNumber: string): Observable<IListInfo<Shipment>> {
        const request = new GetTrackerStatusRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.TrackerId = serialNumber;
        request.ReverseGeocode = true;
        request.IsHighResultCount = this.isHighResultCount;
        request.IsDefaultInProgressOnly = this.isDefaultInProgressOnly;
        this.shipStateSvc.loadShipmentAddress();
        return this.requestShipmentListNew(request).pipe(
            tap(
                n => this.shipStateSvc.loadShipmentAddressSuccess(n),
                e => this.shipStateSvc.loadShipmentAddressError(e)
            )
        ).pipe(take(1));
    }

    public loadTripETA(shipment: Shipment) {
        const request = new ShipmentRequest();
        request.DeviceId = shipment.customerTrackerId;
        request.TripId = shipment.tripId;
        request.GlobalDeviceId = shipment.trackerId;
        this.shipStateSvc.updateShipmentETA();
        return this.oversightSvc.GetShipmentETA(request).pipe(
            map(n => {
                return n.Shipment;
            }),
            tap(
                n => this.shipStateSvc.updateShipmentETASuccess(n),
                e => this.shipStateSvc.updateShipmentETAError(e)
            )
        ).pipe(take(1));
    }

    updateAddresses(shipments: Shipment[]): Observable<IListInfoWithErrorResponse<Shipment>> {
        const request = new GetTrackerStatusRequest();
        const pendingUpdates = [];
        const updateShipments = [];
        shipments?.forEach(s => {
            if (!s.AddressGeoLoading &&
                !(s.AddressGeoTimestampFormatted != '' && !s.AddressOlderThanThreshold)) {
                updateShipments.push(s);
                s.AddressGeoLoading = true;
                if (!pendingUpdates.some(id => id === s.customerTrackerId)) {
                    pendingUpdates.push(s.customerTrackerId);
                }
            }
        });
        request.CustomerTrackerId = pendingUpdates.join(',');
        request.ReverseGeocode = true;
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        const requestObs = this.isAuthenticated ?
            of(request) :                                                       // Auth
            this.unAuthStateSvc.unAuthCustomer$.pipe(                           // Un Auth
                take(1),
                map(c => {
                    request.CustomerId = c.CustomerId;
                    return request;
                })
            );

        return pendingUpdates.length > 0 ?
            requestObs.pipe(
                switchMap(n => this.requestShipmentListNew(n, false)),
                tap(
                    n => this.shipStateSvc.loadShipmentAddressSuccess(n),
                    e => this.shipStateSvc.clearLoadingAddressLoader(e, updateShipments)
                )
            ) :
            of(emptyList());
    }

    public setTripSensorRange(request: SetTripSensorRangeRequest, tripProperty: string): Observable<SetTripSensorRangeResponse> {
        return of(request).pipe(
            tap(n => this.shipStateSvc.setTripSensorRange(n, tripProperty)),
            switchMap(n => this.oversightSvc.SetTripSensorRange(n)),
            handleErrorResponse(),
            tap(
                (n: SetTripSensorRangeResponse) => this.shipStateSvc.setTripSensorRangeSuccess(tripProperty),
                (e: IErrorInfo) => {
                    this.shipStateSvc.setTripSensorRangeError(e.message, tripProperty);
                    const bsModalRef = this.modalSvc.show(
                        EditShipmentResponseComponent,
                        {
                            initialState: {
                                hasError: true,
                                errorMessage: e.message,
                                closeOnOk: true,
                                debugErrorMessage: e.debugMessage
                            },
                            class: 'max-width-300 modal-sm modal-dialog-centered',
                            ignoreBackdropClick: true
                        }
                    );
                    bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onEditShipmentResponseOk(n));
                }
            ),
            map(n => n)
        );
    }

    //If requested property is carrierEmails then we need to show loader
    public editShipment(request: UpdateShipmentRequest, tripProperty: string): Observable<number> {
        return of(request).pipe(
            tap(n => tripProperty === 'carrierEmails' ? this.domoStateService.DisplayLoader() : this.shipStateSvc.editShipment(n, tripProperty)),
            switchMap(n => this.oversightSvc.EditShipment(n)),
            handleErrorResponse(),
            tap(
                (n: UpdateShipmentResponse) => tripProperty === 'carrierEmails' ? this.domoStateService.CancelLoader() : this.shipStateSvc.editShipmentSuccess(n.TripId, tripProperty),
                (e: IErrorInfo) => {
                    tripProperty === 'carrierEmails' ? this.domoStateService.CancelLoader() : this.shipStateSvc.editShipmentError(e.message, tripProperty);
                    const bsModalRef = this.modalSvc.show(
                        EditShipmentResponseComponent,
                        {
                            initialState: {
                                hasError: true,
                                errorMessage: e.message,
                                closeOnOk: true,
                                debugErrorMessage: e.debugMessage
                            },
                            class: 'max-width-300 modal-sm modal-dialog-centered',
                            ignoreBackdropClick: true
                        }
                    );
                    bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onEditShipmentResponseOk(n));
                }
            ),
            map(n => n.TripId)
        );
    }

    onEditShipmentResponseOk(args: EditShipmentResponseOkEventArgs) {
        args.modalRef.hide();
    }

    CloseShipment(shipment: Shipment, islistViewAction: boolean) {
        this.shipStateSvc.closeShipment();
        const request = new CloseTripRequest();
        request.TripId = shipment.tripId;
        request.EndTripDateTimeUTC = this.utilSvc.DateFormatLocaleChange((new Date()));
        request.isClosed = true;
        request.DeviceId = shipment.customerTrackerId;

        this.closeShipSubscription = this.oversightSvc.CloseShipment(request).pipe(take(1)).subscribe(
            n => {
                if (n && n.ErrorCode === 0) {
                    this.shipStateSvc.closeShipmentSuccess();
                    if (islistViewAction) {
                        this.updateTrackerStatusCloseInstanceInState(shipment.trackerId, shipment.customerTrackerId, shipment.tripId);
                    } else {
                        this.setShipmentUpdatedValue(shipment);
                    }
                } else {
                    this.shipStateSvc.closeShipmentError(n.ErrorDescription);
                    const bsModalRef = this.modalSvc.show(
                        CloseShipmentResponseComponent,
                        {
                            initialState: {
                                hasError: true,
                                errorMessage: n.ErrorDescription,
                                closeOnOk: true
                            },
                            class: 'modal-sm modal-dialog-centered',
                            ignoreBackdropClick: true
                        }
                    );
                    bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onCloseShipmentResponseOk(n));
                }
            });
    }

    onCloseShipmentResponseOk(args: CloseShipmentResponseOkEventArgs) {
        args.modalRef.hide();
    }

    ReopenShipment(shipment: Shipment) {
        const request = new CloseTripRequest();
        request.TripId = shipment.tripId;
        request.EndTripDateTimeUTC = this.utilSvc.DateFormatLocaleChange((new Date()));
        request.isClosed = false;

        this.closeShipmentSubscription = this.oversightSvc.CloseShipment(request)
            .pipe(take(1))
            .subscribe(n => { this.shipStateSvc.closeShipmentSuccess(); });
    }


    private getShipmentListFromTrackerStatusList(trackerStatusList: TrackerStatus[]) {
        return trackerStatusList.map(trackerStatus => this.getShipmentFromTrackerStatus(trackerStatus));
    }

    private getShipmentFromTrackerStatus(trackerStatus: TrackerStatus) {
        const shipment = new Shipment();
        shipment.battery = trackerStatus.BatteryLevelString;
        shipment.batteryLevel = trackerStatus.BatteryLevel;
        shipment.latitude = trackerStatus.Latitude;
        shipment.longitude = trackerStatus.Longitude;
        shipment.alertSuppressed = trackerStatus.IsSuppressed;
        shipment.notes = trackerStatus.Notes;
        shipment.serialNumber = trackerStatus.TrackerId;
        shipment.temperature = trackerStatus.TemperatureString;
        shipment.temperatureNumber = trackerStatus.Temperature;
        shipment.trackerId = trackerStatus.TrackerId;
        shipment.customerTrackerId = trackerStatus.CustomerTrackerId;
        shipment.isMultiTrip = trackerStatus.IsMultiTrip;
        shipment.IsDVShipment = trackerStatus.IsDVShipment;
        shipment.tripId = trackerStatus.TripId;
        shipment.tripName = trackerStatus.TripName;
        shipment.tripStatus = trackerStatus.TripStateString;
        shipment.tripStateCode = trackerStatus.TripState;
        shipment.carrierName = trackerStatus.CarrierName;
        shipment.sensorRange = trackerStatus.SensorRange;
        shipment.scheduledStartTime = trackerStatus.ScheduledStartTimeFormatted;
        shipment.scheduledEndTime = trackerStatus.ScheduledEndTimeFormatted;
        shipment.ScheduledStartTime = trackerStatus.ScheduledStartTime;
        shipment.ScheduledEndTime = trackerStatus.ScheduledEndTime;
        shipment.actualStartTime = trackerStatus.ActualStartTimeFormatted;
        shipment.actualEndTime = trackerStatus.ActualEndTimeFormatted;
        shipment.ActualStartTime = trackerStatus.ActualStartTime;
        shipment.ActualEndTime = trackerStatus.ActualEndTime;
        shipment.origin = trackerStatus.TripStartLocationName || trackerStatus.StartLocation;
        shipment.originId = trackerStatus.TripStartLocationId;
        shipment.destination = trackerStatus.TripEndLocationName;
        shipment.destinationId = trackerStatus.TripEndLocationId;
        shipment.LastReportedTimestamp = trackerStatus.LastReportedTimestamp;
        shipment.LastReportedTimestampFormatted = trackerStatus.LastReportedTimestampFormatted;
        shipment.FirstReportedSatTime = trackerStatus.FirstReportedSatTime;
        shipment.FirstReportedSatTimeFormatted = trackerStatus.FirstReportedSatTimeFormatted;
        shipment.CanSuspendTracker = trackerStatus.CanSuspendTracker;
        shipment.CanResumeTracker = trackerStatus.CanResumeTracker;
        shipment.CanShutdownTracker = trackerStatus.CanShutdownTracker;
        shipment.canCreateShipment = trackerStatus.CanCreateTrip;
        shipment.CanDeleteTrip = trackerStatus.CanDeleteTrip;
        shipment.CanEditShipment = trackerStatus.CanEditTrip;
        shipment.canCloseShipment = trackerStatus.CanCloseTrip;
        shipment.CanReopenShipment = trackerStatus.CanReopenTrip;
        shipment.SensorTempRangeId = trackerStatus.SensorTempRangeId;
        shipment.ModelName = trackerStatus.ModelName;
        shipment.ModelNumber = trackerStatus.ModelNumber;
        shipment.ImageUrl = trackerStatus.ImageUrl;
        shipment.ImageUrlSVG = trackerStatus.ImageUrlSVG;
        shipment.ImageId = trackerStatus.ImageId;
        shipment.ImageAnchorTop = trackerStatus.ImageAnchorTop;
        shipment.ImageAnchorLeft = trackerStatus.ImageAnchorLeft;
        shipment.AddressGeo = trackerStatus.AddressGeo;
        shipment.AddressGeoTimestamp = trackerStatus.AddressGeoTimestamp;
        shipment.AddressGeoTimestampFormatted = trackerStatus.AddressGeoTimestampFormatted;
        shipment.AddressOlderThanThreshold = trackerStatus.AddressOlderThanThreshold;
        shipment.ProductImageUrl = trackerStatus.ProductImageUrl;
        shipment.Description = trackerStatus.Description;
        shipment.TemperatureString = trackerStatus.TemperatureString;
        shipment.SensorListInfo = trackerStatus.SensorListInfo;
        shipment.LastReportedTimestamp = trackerStatus.LastReportedTimestamp;
        shipment.Is4G = trackerStatus.Is4G;
        shipment.ProbeTemperature = trackerStatus.ProbeTemperature;
        shipment.ProbeTemperatureString = trackerStatus.ProbeTemperatureString;
        shipment.HumidityLevel = trackerStatus.HumidityLevel;
        shipment.HumidityLevelString = trackerStatus.HumidityLevelString;
        shipment.CarbonDioxideLevel = trackerStatus.CarbonDioxideLevel;
        shipment.CarbonDioxideLevelString = trackerStatus.CarbonDioxideLevelString;
        shipment.Proximity = trackerStatus.Proximity;
        shipment.ProximityString = trackerStatus.ProximityString;
        shipment.CanResumeAlerts = trackerStatus.CanResumeAlerts;
        shipment.CanSuspendAlerts = trackerStatus.CanSuspendAlerts;
        shipment.AlertIconCode = trackerStatus.AlertIconCode;
        shipment.TripLate = trackerStatus.TripLate;
        shipment.isMoving = trackerStatus.IsMoving;

        shipment.LowWarnAlertCount = trackerStatus.LowWarnAlertCount;
        shipment.LowCriticalAlertCount = trackerStatus.LowCriticalAlertCount;
        shipment.HighWarnAlertCount = trackerStatus.HighWarnAlertCount;
        shipment.HighCriticalAlertCount = trackerStatus.HighCriticalAlertCount;

        shipment.hasTempStats = !!trackerStatus.TempSensorStatistics;
        shipment.TotalTimeBelowRange = trackerStatus.TempSensorStatistics?.TotalMinutesBelowRange;
        shipment.TotalTimeAboveRange = trackerStatus.TempSensorStatistics?.TotalMinutesAboveRange;
        shipment.TotalTimeOutOfRange = trackerStatus.TempSensorStatistics?.TotalMinutesOutOfRange;
        shipment.MaxTimeBelowRange = trackerStatus.TempSensorStatistics?.MaxMinutesBelowRange;
        shipment.MaxTimeAboveRange = trackerStatus.TempSensorStatistics?.MaxMinutesAboveRange;
        shipment.AvgSensorValue = trackerStatus.TempSensorStatistics?.AvgSensorValue;
        shipment.MeanKineticTemperature = trackerStatus.TempSensorStatistics?.MeanKineticTemperature;
        shipment.MinSensorValue = trackerStatus.TempSensorStatistics?.MinSensorValue;
        shipment.MaxSensorValue = trackerStatus.TempSensorStatistics?.MaxSensorValue;
        shipment.SensorStandardDeviation = trackerStatus.TempSensorStatistics?.SensorStandardDeviation;
        shipment.TripOriginAddressState = trackerStatus.TripOriginAddressState;
        shipment.TripDestinationAddressState = trackerStatus.TripDestinationAddressState;

        shipment.TotalTimeBelowRangeString = trackerStatus.TempSensorStatistics?.TotalMinutesBelowRangeString;
        shipment.TotalTimeAboveRangeString = trackerStatus.TempSensorStatistics?.TotalMinutesAboveRangeString;
        shipment.TotalTimeOutOfRangeString = trackerStatus.TempSensorStatistics?.TotalMinutesOutOfRangeString;
        shipment.MaxTimeBelowRangeString = trackerStatus.TempSensorStatistics?.MaxMinutesBelowRangeString;
        shipment.MaxTimeAboveRangeString = trackerStatus.TempSensorStatistics?.MaxMinutesAboveRangeString;
        shipment.AvgSensorValueString = trackerStatus.TempSensorStatistics?.AvgSensorValueString;
        shipment.MeanKineticTemperatureString = trackerStatus.TempSensorStatistics?.MeanKineticTemperatureString;
        shipment.MinSensorValueString = trackerStatus.TempSensorStatistics?.MinSensorValueString;
        shipment.MaxSensorValueString = trackerStatus.TempSensorStatistics?.MaxSensorValueString;
        shipment.SensorStandardDeviationString = trackerStatus.TempSensorStatistics?.SensorStandardDeviationString;

        shipment.AvgHumidity = trackerStatus.HumiditySensorStatistics?.AvgSensorValue;
        shipment.MinHumidityValue = trackerStatus.HumiditySensorStatistics?.MinSensorValue;
        shipment.MaxHumidityValue = trackerStatus.HumiditySensorStatistics?.MaxSensorValue;
        shipment.HumidityStandardDeviation = trackerStatus.HumiditySensorStatistics?.SensorStandardDeviation;
        shipment.AvgHumidityString = trackerStatus.HumiditySensorStatistics?.AvgSensorValueString;
        shipment.MinHumidityValueString = trackerStatus.HumiditySensorStatistics?.MinSensorValueString;
        shipment.MaxHumidityValueString = trackerStatus.HumiditySensorStatistics?.MaxSensorValueString;
        shipment.HumidityStandardDeviationString = trackerStatus.HumiditySensorStatistics?.SensorStandardDeviationString;

        shipment.AvgCarbonDioxide = trackerStatus.CarbonDioxideSensorStatistics?.AvgSensorValue;
        shipment.MinCarbonDioxideValue = trackerStatus.CarbonDioxideSensorStatistics?.MinSensorValue;
        shipment.MaxCarbonDioxideValue = trackerStatus.CarbonDioxideSensorStatistics?.MaxSensorValue;
        shipment.CarbonDioxideStandardDeviation = trackerStatus.CarbonDioxideSensorStatistics?.SensorStandardDeviation;
        shipment.AvgCarbonDioxideString = trackerStatus.CarbonDioxideSensorStatistics?.AvgSensorValueString;
        shipment.MinCarbonDioxideValueString = trackerStatus.CarbonDioxideSensorStatistics?.MinSensorValueString;
        shipment.MaxCarbonDioxideValueString = trackerStatus.CarbonDioxideSensorStatistics?.MaxSensorValueString;
        shipment.CarbonDioxideStandardDeviationString = trackerStatus.CarbonDioxideSensorStatistics?.SensorStandardDeviationString;

        shipment.AvgProbe = trackerStatus.ProbeSensorStatistics?.AvgSensorValue;
        shipment.MinProbeValue = trackerStatus.ProbeSensorStatistics?.MinSensorValue;
        shipment.MaxProbeValue = trackerStatus.ProbeSensorStatistics?.MaxSensorValue;
        shipment.ProbeStandardDeviation = trackerStatus.ProbeSensorStatistics?.SensorStandardDeviation;
        shipment.AvgProbeString = trackerStatus.ProbeSensorStatistics?.AvgSensorValueString;
        shipment.MinProbeValueString = trackerStatus.ProbeSensorStatistics?.MinSensorValueString;
        shipment.MaxProbeValueString = trackerStatus.ProbeSensorStatistics?.MaxSensorValueString;
        shipment.ProbeStandardDeviationString = trackerStatus.ProbeSensorStatistics?.SensorStandardDeviationString;

        shipment.AvgFuelLevel = trackerStatus.FuelLevelSensorStatistics?.AvgSensorValue;
        shipment.MinFuelLevelValue = trackerStatus.FuelLevelSensorStatistics?.MinSensorValue;
        shipment.MaxFuelLevelValue = trackerStatus.FuelLevelSensorStatistics?.MaxSensorValue;
        shipment.FuelLevelStandardDeviation = trackerStatus.FuelLevelSensorStatistics?.SensorStandardDeviation;
        shipment.AvgFuelLevelString = trackerStatus.FuelLevelSensorStatistics?.AvgSensorValueString;
        shipment.MinFuelLevelValueString = trackerStatus.FuelLevelSensorStatistics?.MinSensorValueString;
        shipment.MaxFuelLevelValueString = trackerStatus.FuelLevelSensorStatistics?.MaxSensorValueString;
        shipment.FuelLevelStandardDeviationString = trackerStatus.FuelLevelSensorStatistics?.SensorStandardDeviationString;

        shipment.AvgProximity = trackerStatus.ProximitySensorStatistics?.AvgSensorValue;
        shipment.MinProximityValue = trackerStatus.ProximitySensorStatistics?.MinSensorValue;
        shipment.MaxProximityValue = trackerStatus.ProximitySensorStatistics?.MaxSensorValue;
        shipment.ProximityStandardDeviation = trackerStatus.ProximitySensorStatistics?.SensorStandardDeviation;
        shipment.AvgProximityString = trackerStatus.ProximitySensorStatistics?.AvgSensorValueString;
        shipment.MinProximityValueString = trackerStatus.ProximitySensorStatistics?.MinSensorValueString;
        shipment.MaxProximityValueString = trackerStatus.ProximitySensorStatistics?.MaxSensorValueString;
        shipment.ProximityStandardDeviationString = trackerStatus.ProximitySensorStatistics?.SensorStandardDeviationString;

        shipment.TripETADistanceM = trackerStatus.TripETADistanceM;
        shipment.TripETADistanceString = trackerStatus.TripETADistanceString;
        shipment.TripETAUTC = trackerStatus.TripETAUTC;
        shipment.TripETA = trackerStatus.TripETA;
        shipment.TripETAFormatted = trackerStatus.TripETAFormatted;
        shipment.TripETAOlderThanThreshold = trackerStatus.TripETAOlderThanThreshold;
        shipment.TripEndLocLat = trackerStatus.TripEndLocLat;
        shipment.TripEndLocLon = trackerStatus.TripEndLocLon;
        shipment.PlateNum = trackerStatus.PlateNum;
        shipment.ContainerNum = trackerStatus.ContainerNum;
        shipment.DriverPhoneNum = trackerStatus.DriverPhoneNum;
        shipment.TripStartLocationFullAddress = trackerStatus.TripStartLocationFullAddress;
        shipment.TripStartLocationCity = trackerStatus.TripStartLocationCity;
        shipment.TripStartLocationState = trackerStatus.TripStartLocationState;
        shipment.TripStartLocationCountry = trackerStatus.TripStartLocationCountry;
        shipment.TripStartLocationZipCode = trackerStatus.TripStartLocationZipCode;
        shipment.TripEndLocationFullAddress = trackerStatus.TripEndLocationFullAddress;
        shipment.TripEndLocationCity = trackerStatus.TripEndLocationCity;
        shipment.TripEndLocationState = trackerStatus.TripEndLocationState;
        shipment.TripEndLocationCountry = trackerStatus.TripEndLocationCountry;
        shipment.TripEndLocationZipCode = trackerStatus.TripEndLocationZipCode;
        shipment.SmartTagQualityCode = trackerStatus.SmartTagQualityCode;
        shipment.SmartTagUploadUTC = trackerStatus.SmartTagUploadUTC;
        shipment.SmartTagUpload = trackerStatus.SmartTagUpload;
        shipment.SmartTagUploadFormatted = trackerStatus.SmartTagUploadFormatted;
        shipment.ReceiverPONum = trackerStatus.ReceiverPONum;
        shipment.SupplierName = trackerStatus.SupplierName;
        shipment.DCTripStop = trackerStatus.DCTripStop;
        shipment.MostRecentPostDC = trackerStatus.MostRecentPostDC;
        shipment.DCTripStopLocationID = trackerStatus.DCTripStopLocationID;
        shipment.SupplierCustomerID = trackerStatus.SupplierCustomerID;
        shipment.MostRecentPostDCLocationID = trackerStatus.MostRecentPostDCLocationID;
        shipment.ActivationTime=trackerStatus.ActivationTime;
        shipment.SharedWith=trackerStatus.SharedWith;
        return shipment;
    }

    public getTrackerStatusListFromShipmentList(shipmentList: Shipment[]) {
        return shipmentList.map(shipLst => this.getTrackerStatusFromShipment(shipLst));
    }

    private getTrackerStatusFromShipment(shipment: Shipment) {
        const trackerStatus = new TrackerStatus();
        trackerStatus.BatteryLevelString = shipment.battery;
        trackerStatus.Latitude = shipment.latitude;
        trackerStatus.Longitude = shipment.longitude;
        trackerStatus.Notes = shipment.notes;
        trackerStatus.TrackerId = shipment.serialNumber;
        trackerStatus.TemperatureString = shipment.temperature;
        trackerStatus.TrackerId = shipment.trackerId;
        trackerStatus.CustomerTrackerId = shipment.customerTrackerId;
        trackerStatus.IsMultiTrip = shipment.isMultiTrip;
        trackerStatus.IsDVShipment = shipment.IsDVShipment;
        trackerStatus.TripId = shipment.tripId;
        trackerStatus.TripName = shipment.tripName;
        trackerStatus.TripStateString = shipment.tripStatus;
        trackerStatus.TripState = shipment.tripStateCode;
        trackerStatus.CarrierName = shipment.carrierName;
        trackerStatus.SensorRange = shipment.sensorRange;
        trackerStatus.ScheduledStartTimeFormatted = shipment.scheduledStartTime;
        trackerStatus.ScheduledEndTimeFormatted = shipment.scheduledEndTime;
        trackerStatus.ActualStartTimeFormatted = shipment.actualStartTime;
        trackerStatus.ActualEndTimeFormatted = shipment.actualEndTime;
        trackerStatus.StartLocation = shipment.origin;
        trackerStatus.TripStartLocationName = shipment.origin;
        trackerStatus.TripEndLocationName = shipment.destination;
        trackerStatus.LastReportedTimestampFormatted = shipment.LastReportedTimestampFormatted;
        trackerStatus.CanSuspendTracker = shipment.CanSuspendTracker;
        trackerStatus.CanResumeTracker = shipment.CanResumeTracker;
        trackerStatus.CanShutdownTracker = shipment.CanShutdownTracker;
        trackerStatus.CanCreateTrip = shipment.canCreateShipment;
        trackerStatus.CanDeleteTrip = shipment.CanDeleteTrip;
        trackerStatus.CanEditTrip = shipment.CanEditShipment;
        trackerStatus.CanCloseTrip = shipment.canCloseShipment;
        trackerStatus.CanReopenTrip = shipment.CanReopenShipment;
        trackerStatus.SensorTempRangeId = shipment.SensorTempRangeId;
        trackerStatus.ImageUrl = shipment.ImageUrl;
        trackerStatus.AddressGeo = shipment.AddressGeo;
        trackerStatus.AddressGeoTimestamp = shipment.AddressGeoTimestamp;
        trackerStatus.AddressGeoTimestampFormatted = shipment.AddressGeoTimestampFormatted;
        trackerStatus.AddressOlderThanThreshold = shipment.AddressOlderThanThreshold;
        trackerStatus.ProductImageUrl = shipment.ProductImageUrl;
        trackerStatus.Description = shipment.Description;
        trackerStatus.TemperatureString = shipment.TemperatureString;
        trackerStatus.SensorListInfo = shipment.SensorListInfo;

        trackerStatus.LastReportedTimestamp = shipment.LastReportedTimestamp;
        trackerStatus.Is4G = shipment.Is4G;
        trackerStatus.ProbeTemperature = shipment.ProbeTemperature;
        trackerStatus.ProbeTemperatureString = shipment.ProbeTemperatureString;
        trackerStatus.HumidityLevel = shipment.HumidityLevel;
        trackerStatus.HumidityLevelString = shipment.HumidityLevelString;
        trackerStatus.CarbonDioxideLevel = shipment.CarbonDioxideLevel;
        trackerStatus.CarbonDioxideLevelString = shipment.CarbonDioxideLevelString;
        trackerStatus.AlertIconCode = shipment.AlertIconCode;
        trackerStatus.TripLate = shipment.TripLate;
        trackerStatus.IsMoving = shipment.isMoving;


        trackerStatus.LowWarnAlertCount = shipment.LowWarnAlertCount;
        trackerStatus.LowCriticalAlertCount = shipment.LowCriticalAlertCount;
        trackerStatus.HighWarnAlertCount = shipment.HighWarnAlertCount;
        trackerStatus.HighCriticalAlertCount = shipment.HighCriticalAlertCount;
        trackerStatus.TripStartLocationFullAddress = shipment.TripStartLocationFullAddress;
        trackerStatus.TripStartLocationCity = shipment.TripStartLocationCity;
        trackerStatus.TripStartLocationState = shipment.TripStartLocationState;
        trackerStatus.TripStartLocationCountry = shipment.TripStartLocationCountry;
        trackerStatus.TripStartLocationZipCode = shipment.TripStartLocationZipCode;
        trackerStatus.TripEndLocationFullAddress = shipment.TripEndLocationFullAddress;
        trackerStatus.TripEndLocationCity = shipment.TripEndLocationCity;
        trackerStatus.TripEndLocationState = shipment.TripEndLocationState;
        trackerStatus.TripEndLocationCountry = shipment.TripEndLocationCountry;
        trackerStatus.TripEndLocationZipCode = shipment.TripEndLocationZipCode;

        if (shipment.hasTempStats) {
            trackerStatus.TempSensorStatistics = {
                TotalMinutesBelowRange: shipment.TotalTimeBelowRange,
                TotalMinutesAboveRange: shipment.TotalTimeAboveRange,
                TotalMinutesOutOfRange: shipment.TotalTimeOutOfRange,
                MaxMinutesBelowRange: shipment.MaxTimeBelowRange,
                MaxMinutesAboveRange: shipment.MaxTimeAboveRange,
                AvgSensorValue: shipment.AvgSensorValue,
                MeanKineticTemperature: shipment.MeanKineticTemperature,
                MinSensorValue: shipment.MinSensorValue,
                MaxSensorValue: shipment.MaxSensorValue,
                SensorStandardDeviation: shipment.SensorStandardDeviation,
                TotalMinutesBelowRangeString: shipment.TotalTimeBelowRangeString,
                TotalMinutesAboveRangeString: shipment.TotalTimeAboveRangeString,
                TotalMinutesOutOfRangeString: shipment.TotalTimeOutOfRangeString,
                MaxMinutesBelowRangeString: shipment.MaxTimeBelowRangeString,
                MaxMinutesAboveRangeString: shipment.MaxTimeAboveRangeString,
                AvgSensorValueString: shipment.AvgSensorValueString,
                MeanKineticTemperatureString: shipment.MeanKineticTemperatureString,
                MinSensorValueString: shipment.MinSensorValueString,
                MaxSensorValueString: shipment.MaxSensorValueString,
                SensorStandardDeviationString: shipment.SensorStandardDeviationString
            };
        }

        trackerStatus.TripOriginAddressState = shipment.TripOriginAddressState;
        trackerStatus.TripDestinationAddressState = shipment.TripDestinationAddressState;
        trackerStatus.SmartTagQualityCode = shipment.SmartTagQualityCode;
        trackerStatus.ReceiverPONum = shipment.ReceiverPONum;
        trackerStatus.SmartTagUploadUTC = shipment.SmartTagUploadUTC;
        trackerStatus.SmartTagUpload = shipment.SmartTagUpload;
        trackerStatus.SmartTagUploadFormatted = shipment.SmartTagUploadFormatted;
        trackerStatus.SupplierName = shipment.SupplierName;
        trackerStatus.DCTripStop = shipment.DCTripStop;
        trackerStatus.MostRecentPostDC = shipment.MostRecentPostDC;
        trackerStatus.DCTripStopLocationID = shipment.DCTripStopLocationID;
        trackerStatus.SupplierCustomerID = shipment.SupplierCustomerID;
        trackerStatus.MostRecentPostDCLocationID = shipment.MostRecentPostDCLocationID;
        return trackerStatus;
    }

    public getTrackerStatusListOfSelectedColumns(shipments: Shipment[], properties: any[]): any[] {
        let trackerStatus = [];
        shipments.forEach(shipment => {
            var object = {};
            properties.forEach(property => {
                object[property.value] = this.isValueDefined(shipment[property.value]) ? shipment[property.value] : property.nullValue
            })
            trackerStatus.push(object);
        });
        return trackerStatus;
    }

    isValueDefined(val) {
        return val !== null && val !== undefined && val !== '';
    }


    public startAutoRefresh(isAutoRefreshChecked): void {
        if (isAutoRefreshChecked) {
            this.isAutoRefreshed = true;
            this.restartAutoRefresh(this.refreshDelay);
        } else {
            this.isAutoRefreshed = false;
            this.onDestroy();
            this.clearAutoRefresh();
        }

    }

    private checkSubAutoRefresh(): void {
        this.onDestroy();
        this.clearAutoRefresh();
        this.shipStateSvc.AutoRefreshShipmentsError('');
        this.restartAutoRefresh(this.refreshSubDelay);
    }


    private restartAutoRefresh(delay: number): void {
        this.clearAutoRefresh();
        if (this.isAutoRefreshed) {
            this.interval = setInterval(
                () => this.processAutoRefresh(this),
                delay
            );
        }
    }

    public mapAck(): void {
        this.lastActivity = new Date();
    }

    public ack(): void {
        this.lastPageActivity = new Date();
    }

    public clearAutoRefresh(): void {
        clearInterval(this.interval);
    }

    public onDestroy() {
        if (this.AutoRefreshShipmentsSubscription) {
            this.AutoRefreshShipmentsSubscription.unsubscribe();
        }
    }

    public checkAutoRefresh(): void {
        if (!this.isRefreshProcessCompleted) {
            this.onDestroy();
            this.clearAutoRefresh();
            this.shipStateSvc.AutoRefreshShipmentsError('');
            this.processAutoRefresh(this);
            this.isRefreshProcessCompleted = true;
        }
    }

    private processAutoRefresh(self: ShipmentService) {
        this.clearAutoRefresh();
        if (!this.isAuthenticated) {
            self.restartAutoRefresh(this.refreshDelay);
            return;
        } else {
            if (this.checkBrowserIdleState()) {
                this.onDestroy();
                this.autoRefreshshipmentListLoader();
                this.shipStateSvc.autoRefreshShipments();
            } else {
                self.checkSubAutoRefresh();
            }
        }
    }

    private checkBrowserIdleState(): boolean {
        const currentTime = new Date();
        return ((Math.round((currentTime.getTime() - this.lastPageActivity.getTime()) / 1000) <= environment.autoRefreshIdleTime)
            || (Math.round((currentTime.getTime() - this.lastActivity.getTime()) / 1000) <= environment.autoRefreshIdleTime))

    }

    public getUnAuthShipmentList(): Observable<IListInfoWithErrorResponse<Shipment>> {
        return of(null).pipe(
            tap(() => this.shipStateSvc.loadShipments()),
            withLatestFrom(this.shipFltrStateSvc.shipmentFilter$, (a, b) => b),
            withLatestFrom(this.shipSortStateSvc.sortState$, (a, b) => (
                { filter: a, sort: b.selectedSort, isDescendingSort: b.isDescending })),
            switchMap(a => this.getShipmentList(a.filter, a.sort, a.isDescendingSort)),
            tap(
                n => {
                    this.shipStateSvc.loadShipmentsSuccess(n);
                    this.shipStatusSvc.reLoadShipmentStatuses();
                },
                e => this.shipStateSvc.loadShipmentsError('')
            ),
            finalize(() => this.shipStateSvc.cancelLoadShipments()),
            share()
        );
    }

    public getTimePeriodDates(time: ITimePeriod) {
        const dateRange: DateRange = new DateRange();
        const date = new Date();
        let startDate, endDate;
        switch (time.timePeriod) {
            case TimePeriod.LastTwoWeeks:
                dateRange.startDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 13, 0, 0, 0);
                dateRange.endDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                break;
            case TimePeriod.Last30Days:
                dateRange.startDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29, 0, 0, 0);
                dateRange.endDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                break;
            case TimePeriod.Last90Days:
                dateRange.startDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 89, 0, 0, 0);
                dateRange.endDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                break;
            case TimePeriod.ThisYear:
                dateRange.startDateTime = new Date(date.getFullYear(), 0, 1);
                dateRange.endDateTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
                break;
            case TimePeriod.Last7Days:
                endDate = new Date();
                startDate = dateAdd(endDate, -6);
                dateRange.startDateTime = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
                dateRange.endDateTime = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 0);
                break;
            case TimePeriod.LastMonth:
                dateRange.startDateTime = new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0);
                dateRange.endDateTime = new Date(date.getFullYear(), date.getMonth(), 0, 23, 59, 59);
                break;
            case TimePeriod.Last48Hours:
                endDate = new Date();
                dateRange.startDateTime = dateAdd(endDate, -2);
                dateRange.endDateTime = endDate;
                break;
            case TimePeriod.SpecificRange:
                dateRange.startDateTime = new Date(time.dateRange.startDateTime);
                dateRange.endDateTime = new Date(time.dateRange.endDateTime);
        }
        return dateRange;
    }

    public CheckDateBetweenFilters(data: Shipment, time: ITimePeriod) {
        const dateRange: DateRange = this.getTimePeriodDates(time);
        const scheduleStartTime = new Date(data.ScheduledStartTime).getTime();
        const scheduleEndTime = new Date(data.ScheduledEndTime).getTime();
        const actualStartTime = new Date(data.ActualStartTime).getTime();
        const actualEndTime = new Date(data.ActualEndTime).getTime();
        const lastReportedTime = new Date(data.LastReportedTimestamp).getTime();
        const firstTSReportedTime = new Date(data.FirstReportedSatTime).getTime();
        const from = dateRange?.startDateTime?.getTime();
        const thru = dateRange?.endDateTime?.getTime();
        const isBetween = (!time || !time.timePeriod ||
            (data.tripStateCode !== TripState.Completed && from <= lastReportedTime && lastReportedTime <= thru) ||
            (data.tripStateCode !== TripState.Completed && from <= firstTSReportedTime && firstTSReportedTime <= thru) ||
            //OR-4005 Pending trips that have not reported do NOT show w/o searching
            (data.tripStateCode === TripState.Pending &&
                //If Schedule start is in range
                ((from <= scheduleStartTime && thru >= scheduleStartTime) ||
                    // If schedule end is in the range but start is not in range then also need to show
                    ((from <= scheduleEndTime && thru >= scheduleEndTime)) ||
                    // If schedule start or end or both in future then also need to show
                    (thru <= scheduleStartTime || thru <= scheduleEndTime))
            ) ||
            (data.tripStateCode === TripState.InProgress && !data.LastReportedTimestamp &&
                (from <= actualStartTime && actualStartTime <= thru)
            ) ||
            (data.tripStateCode === TripState.Completed &&
                (from <= actualEndTime && thru >= actualEndTime)
            )
        );
        // if (!isBetween && !environment.production) { // TODO: remove after testing finished
        //     const values = {
        //         tn: data.tripName,
        //         tid: data.trackerId,
        //         hasTime: !time,
        //         hasTimePeriod: !time.timePeriod,
        //         betweenLastReported: from <= lastReportedTime && lastReportedTime <= thru,
        //         betweenFirstReported: from <= firstTSReportedTime && firstTSReportedTime <= thru,
        //         pendingTripScheduled: (data.tripStateCode === TripState.Pending && (from <= scheduleStartTime && thru >= scheduleStartTime)),
        //         inprogressTripActualStart: (data.tripStateCode === TripState.InProgress && !data.LastReportedTimestamp && (from <= actualStartTime && actualStartTime <= thru)),
        //         completedTripActualEnd: (data.tripStateCode === TripState.Completed && (from <= actualEndTime && thru >= actualStartTime)),
        //         data, currentRange: dateRange, issueType: 'Date Range Failed'
        //     }
        //     console.log(values);
        // }
        return isBetween;
    }

    public sortShipmentList(sortBy: string, direction: boolean, list: Shipment[]): Shipment[] {
        // this.detailStateSvc.loadExportSensorReport();
        try {
            const data = list.slice().sort((a, b) => {
                if (direction) {
                    if ((!a[sortBy] || a[sortBy].length === 0) && (b[sortBy] && b[sortBy].length > 0)) {
                        return 1;
                    } else if ((a[sortBy] && a[sortBy].length > 0) && (!b[sortBy] || b[sortBy].length === 0)) {
                        return -1;
                    } else {
                        if (sortBy === 'battery') {
                            if (a[sortBy] && b[sortBy] &&
                                a[sortBy].endsWith('%') &&
                                b[sortBy].endsWith('%')) {
                                const curr = parseFloat(a[sortBy].replace('%', ''));
                                const next = parseFloat(b[sortBy].replace('%', ''));
                                return curr < next ? 1 :
                                    (curr > next ? -1 : 0);
                            }
                        } else if (
                            sortBy === 'ActualStartTimeValue' ||
                            sortBy === 'LastReportedTimestamp' ||
                            sortBy === 'ActualEndTimeValue') {
                            return new Date(a[sortBy]).getTime() < new Date(b[sortBy]).getTime() ? 1 :
                                (new Date(a[sortBy]).getTime() > new Date(b[sortBy]).getTime() ? -1 : 0);
                        } else {
                            if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                                return a[sortBy].trim().toLocaleLowerCase() < b[sortBy].trim().toLocaleLowerCase() ? 1 :
                                    (a[sortBy].trim().toLocaleLowerCase() > b[sortBy].trim().toLocaleLowerCase() ? -1 : 0);
                            } else {
                                return a[sortBy] < b[sortBy] ? 1 : (a[sortBy] > b[sortBy] ? -1 : 0);
                            }
                        }
                    }
                } else {
                    if ((!a[sortBy] || a[sortBy].length === 0) && (b[sortBy] && b[sortBy].length > 0)) {
                        return -1;
                    } else if ((a[sortBy] && a[sortBy].length > 0) && (!b[sortBy] || b[sortBy].length === 0)) {
                        return 1;
                    } else {
                        if (sortBy === 'battery') {
                            if (a[sortBy] && b[sortBy] &&
                                a[sortBy].endsWith('%') &&
                                b[sortBy].endsWith('%')) {
                                const curr = parseFloat(a[sortBy].replace('%', ''));
                                const next = parseFloat(b[sortBy].replace('%', ''));
                                return curr < next ? -1 :
                                    (curr > next ? 1 : 0);
                            }
                        } else if (
                            sortBy === 'actualStartTime' ||
                            sortBy === 'LastReportedTimestampFormatted' ||
                            sortBy === 'actualEndTime') {
                            return new Date(a[sortBy]).getTime() < new Date(b[sortBy]).getTime() ? -1 :
                                (new Date(a[sortBy]).getTime() > new Date(b[sortBy]).getTime() ? 1 : 0);
                        } else {
                            if (typeof a[sortBy] === 'string' && typeof b[sortBy] === 'string') {
                                return a[sortBy].trim().toLocaleLowerCase() < b[sortBy].trim().toLocaleLowerCase() ? -1 :
                                    (a[sortBy].trim().toLocaleLowerCase() > b[sortBy].trim().toLocaleLowerCase() ? 1 : 0);
                            } else {
                                return a[sortBy] < b[sortBy] ? -1 : (a[sortBy] > b[sortBy] ? 1 : 0);
                            }
                        }
                    }
                }
            });
            // this.detailStateSvc.loadExportSensorReportSuccess();
            return data;
        } catch (ex) {
            // this.detailStateSvc.loadExportSensorReportSuccess();
            return [];

        }
    }

    GetShipmentBySerial(serialNumber: string, useQuickSearch: boolean = false): Observable<IListInfoWithErrorResponse<Shipment>> {
        const request = new GetTrackerStatusRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        if (!useQuickSearch) {
            request.TrackerId = serialNumber;
        } else {
            request.QuickSearch = serialNumber;
        }

        request.PeriodReportedInterval = null;
        request.IsHighResultCount = this.isHighResultCount;
        request.IsDefaultInProgressOnly = this.isDefaultInProgressOnly;
        return this.isAuthenticated ?
            this.getShipmentListBySerialLoader(request, this.isAuthenticated) : // Auth
            this.unAuthStateSvc.unAuthCustomer$.pipe(                           // Un Auth
                take(1),
                switchMap(n => {
                    request.CustomerId = n.CustomerId; // include customer id to specify the customer context
                    return this.getShipmentListBySerialLoader(request, this.isAuthenticated)
                })
            );
    }

    GetShipmentBySerialShipmentName(serialNumber: string, shipmentName: string): Observable<IListInfoWithErrorResponse<Shipment>> {
        const request = new GetTrackerStatusRequest();
        request.TrackerId = serialNumber ? serialNumber : null;
        request.ShipmentName = shipmentName ? shipmentName : null;
        request.PeriodReportedInterval = null;
        request.IsHighResultCount = this.isHighResultCount;
        request.IsDefaultInProgressOnly = this.isDefaultInProgressOnly;
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        return this.getShipmentListBySerialLoader(request, this.isAuthenticated);
    }

    GetDCShipmentBySerial(serialNumber: string): Observable<IListInfoWithErrorResponse<Shipment>> {
        const request = new GetTrackerStatusRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.TrackerId = serialNumber;
        request.PeriodReportedInterval = null;
        request.IsHighResultCount = this.isHighResultCount;
        request.IsDefaultInProgressOnly = this.isDefaultInProgressOnly;
        return this.getShipmentListBySerialLoader(request, true, true);
    }

    updateShipmentByTrackerID(shipment: UpdateShipment) {
        this.shipStateSvc.updateShipmentByTrackerID(shipment);
    }

    private getShipmentListBySerialLoader(
        request: GetTrackerStatusRequest,
        skipTrackerIdStorage: boolean = false,
        skipAutoRefresh: boolean = false): Observable<IListInfoWithErrorResponse<Shipment>> {
        return of(request).pipe(
            tap(() => this.shipStateSvc.loadShipmentBySerial()),
            switchMap(a => this.requestShipmentListNew(request, skipAutoRefresh)),
            tap(
                n => {
                    if (n.ErrorCode === 0) {
                        if (!skipTrackerIdStorage) {
                            this.updateUnAuthTrackers(n.list);
                        }
                        this.shipStateSvc.loadShipmentBySerialSuccess(n);
                    } else {
                        this.shipStateSvc.loadShipmentBySerialError(n.LocalizedErrorMessage);
                    }
                },
                e => this.shipStateSvc.loadShipmentBySerialError('')
            ),
            finalize(() => this.shipStateSvc.cancelLoadShipmentBySerial()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private updateUnAuthTrackers(shipmentList: Shipment[]) {
        if (shipmentList?.length > 0) {
            const existingTrackerIds = sessionStorage.getItem(environment.unAuthTrackers).split(',');
            const newTrackerIds = shipmentList.map(s => s.trackerId);
            const finalList = new Set([...Array.from(existingTrackerIds), ...newTrackerIds])
            sessionStorage.setItem(environment.unAuthTrackers, Array.from(finalList).join(','));
        }
    }

    public exportExcelListViewReport(queryParams, extraHeaders: ExportTrackerStateReportHeaders) {
        return this.oversightSvc.ExportExcelListViewReport(queryParams, extraHeaders);
    }

    public getShipmentRequest() {
        return of(null).pipe(
            withLatestFrom(this.shipFltrStateSvc.shipmentFilter$, (a, b) => b),
            withLatestFrom(this.shipSortStateSvc.sortState$, (a, b) => (
                { filter: a, sort: b.selectedSort, isDescendingSort: b.isDescending })),
            map(a => this.getShipmentsRequest(a.filter, a.sort, a.isDescendingSort, true))
        );
    }


    checkFilterState(shipmentFilter) {
        const loadFilter = getDefaultShipmentFilter();
        loadFilter.period.timePeriod = null;
        loadFilter.isDefaultState = shipmentFilter.isDefaultState;
        loadFilter.IsHistorical = true;
        const isDefault = !_.isEqual(shipmentFilter, loadFilter);
        return isDefault;
    }

    public applyLocalFilter(data: Shipment[], fltr: IShipmentFilterState, callMissing: boolean = false): Shipment[] {
        let list = [...data];
        if (fltr &&
            (
                (data && data.length > 0) ||
                (fltr.serialNumber || fltr.shipmentName)
            )) {
            if (fltr.serialNumber || fltr.shipmentName) {
                list = list.filter(k =>
                    // Serial Number
                    (fltr.serialNumber && k.serialNumber === fltr.serialNumber) ||
                    // Shipment name
                    (fltr.shipmentName && k.tripName?.toLowerCase() === fltr.shipmentName.toLowerCase())
                );
                if (callMissing &&
                    (list.length === 0 || list.some(k => k.isMultiTrip))) {
                    this.GetShipmentBySerialShipmentName(fltr.serialNumber, fltr.shipmentName).pipe(take(1)).subscribe();
                }
            } else {
                list = list.filter(k =>
                    // Time Period
                    (!fltr.period || this.CheckDateBetweenFilters(k, fltr.period))
                    // Trip Status
                    && (!(fltr.status?.length > 0) || fltr.status.indexOf(k.tripStateCode) >= 0)
                    // Origin
                    && (!(fltr.origin?.length > 0) || fltr.origin.indexOf(k.originId) >= 0)
                    // Destination
                    && (!(fltr.destination?.length > 0) || fltr.destination.indexOf(k.destinationId) >= 0)
                    // Sensor Range
                    && (!(fltr.sensorRange?.length > 0) || fltr.sensorRange.indexOf(k.SensorTempRangeId) >= 0)
                    // Carrier
                    && (!(fltr.carrier?.length > 0) || fltr.carrier.some(c => c.toLowerCase() === k.carrierName?.toLowerCase()))
                    // Alerts
                    && ((!fltr.alert.aboveTemp && !fltr.alert.belowTemp && !fltr.alert.late && !fltr.alert.notMoving)
                        || (fltr.alert.aboveTemp && (k.AlertIconCode === 103 || k.AlertIconCode === 104))
                        || (fltr.alert.belowTemp && (k.AlertIconCode === 101 || k.AlertIconCode === 102))
                        || (fltr.alert.late && (k.TripLate === 2 || k.TripLate === 3))
                        || (fltr.alert.notMoving && !k.isMoving))
                    // Origin City
                    && (!(fltr.originCity?.length > 0) || fltr.originCity.some(c => c.toLowerCase() === k.TripStartLocationCity?.toLowerCase()))
                    // Origin State
                    && (!(fltr.originState?.length > 0) || fltr.originState.some(c => c.toLowerCase() === k.TripStartLocationState?.toLowerCase()))
                    // Origin Country
                    && (!(fltr.originCountry?.length > 0) || fltr.originCountry.some(c => c.toLowerCase() === k.TripStartLocationCountry?.toLowerCase()))
                    // Origin Zip Code
                    && (!(fltr.originZip?.length > 0) || fltr.originZip.some(c => c.toLowerCase() === k.TripStartLocationZipCode?.toLowerCase()))
                    // destination City
                    && (!(fltr.destinationCity?.length > 0) || fltr.destinationCity.some(c => c.toLowerCase() === k.TripEndLocationCity?.toLowerCase()))
                    // destination State
                    && (!(fltr.destinationState?.length > 0) || fltr.destinationState.some(c => c.toLowerCase() === k.TripEndLocationState?.toLowerCase()))
                    // destination Country
                    && (!(fltr.destinationCountry?.length > 0) || fltr.destinationCountry.some(c => c.toLowerCase() === k.TripEndLocationCountry?.toLowerCase()))
                    // destination Zip Code
                    && (!(fltr.destinationZip?.length > 0) || fltr.destinationZip.some(c => c.toLowerCase() === k.TripEndLocationZipCode?.toLowerCase()))
                    // supplier name
                    && (!(fltr.supplierCustomer?.length > 0) || fltr.supplierCustomer.indexOf(k.SupplierCustomerID) >= 0)
                    // dc Trip Stop
                    && (!(fltr.dcTripStopLocation?.length > 0) || fltr.dcTripStopLocation.indexOf(k.DCTripStopLocationID) >= 0)
                    // most recent post dc
                    && (!(fltr.mostRecentPostDC?.length > 0) || fltr.mostRecentPostDC.some(c => c.toLowerCase() === k.MostRecentPostDC?.toLowerCase()))
                );
            }
        }
        return list;
    }

    public updateShipmentSuspendResumeState(trackerId: string, tripId: number, isSuppressed: boolean) {
        this.shipStateSvc.updateShipmentSuspendResumeState(trackerId, tripId, isSuppressed);
    }

    deletePendingTrip(shipment: Shipment): Observable<CloseTripResponse> {
        this.shipStateSvc.deleteShipment();
        const request = new CloseTripRequest();
        request.TripId = shipment.tripId ? shipment.tripId : 0;
        request.EndTripDateTimeUTC = this.utilSvc.DateFormatLocaleChange((new Date()));
        request.isClosed = true;
        request.DeviceId = shipment.customerTrackerId;
        request.GlobalDeviceId = shipment.trackerId;
        request.IsDVShipment = shipment.IsDVShipment;
        return this.oversightSvc.DeletePendingTrip(request).pipe(map(
            n => {
                if (n && n.ErrorCode === 0) {
                    this.shipStateSvc.deleteShipmentSuccess(request);
                } else {
                    this.shipStateSvc.deleteShipmentError(n.LocalizedErrorMessage);
                }
                return n;
            }));
    }


    SetTrackerNote(request: SetTrackerNoteRequest): Observable<SetTrackerNoteResponse> {
        this.shipStateSvc.saveTrackerNote();
        return this.oversightSvc.SetTrackerNote(request).pipe(map(
            n => {
                if (n && n.ErrorCode === 0) {
                    this.shipStateSvc.saveTrackerNoteSuccess();
                } else {
                    this.shipStateSvc.saveTrackerNoteError(n.LocalizedErrorMessage);
                }
                return n;
            }));
    }

    ShareTracker(request: ShareTrackerRequest): Observable<ShareTrackerResponse> {
        this.shipStateSvc.shareTracker();
        return this.oversightSvc.ShareTracker(request).pipe(map(
            n => {
                if (n && n.ErrorCode === 0) {
                    this.shipStateSvc.shareTrackerSuccess();
                } else {
                    this.shipStateSvc.shareTrackerError(n.LocalizedErrorMessage);
                }
                return n;
            }));
    }

    SetShutDownTracker(request: SetShutDownTrackerRequest): Observable<SetShutDownTrackerResponse> {
        this.shipStateSvc.saveShutdownTracker();
        return this.oversightSvc.SetShutDownTracker(request).pipe(map(
            n => {
                if (n && n.ErrorCode === 0) {
                    this.shipStateSvc.saveShutdownTrackerSuccess(request);
                } else {
                    this.shipStateSvc.saveShutdownTrackerError(n.LocalizedErrorMessage);
                }
                return n;
            }));
    }

    postShutdownShipmentUpdate(isListViewAction: boolean, shipment: Shipment) {
        if (isListViewAction) {
            this.updateTrackerStatusCloseInstanceInState(shipment.trackerId, shipment.customerTrackerId, shipment.tripId);
        } else {
            this.setShipmentUpdatedValue(shipment);
        }
    }

    private updateLocalStateToShutdownOnSync(shipment: Shipment) {
        shipment.CanShutdownTracker = false;
        shipment.ImageId = 'de';
        shipment.ImageUrl = this.oversightImageURL + this.oversightCommonPath + '20x20/deleted.svg';
        shipment.ImageUrlSVG = this.oversightImageURL + this.oversightCommonPath + '20x20/deleted.svg';
    }

    //ss-757 Details button is not available in Dual vis 
    CheckDatesInPast90DaysForDetails(lastReported, actualEndTime, actualStartTime, tripState) {
        const today = new Date();
        const day = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        const past90Days = day.setDate(day.getDate() - 89);
        // For pending trips we are not checking any dates 
        // for completed trips we are checking for actualEndTIme 
        // for remaining trips we are checking for LastreportedTime or actual start time in case last reported not available
        return tripState == TripState.Pending ||
            (tripState == TripState.Completed ? new Date(actualEndTime).getTime() > past90Days :
                (lastReported ? new Date(lastReported).getTime() : new Date(actualStartTime).getTime()) > past90Days);
    }
}
