import { Store, createSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IRouteDeviationState } from 'app-modules/core/store/models/route-deviation-state.interface';
import * as routeDeviationActions from './route-deviation.actions';
import { RouteDeviationListItem } from 'emr-ng-shared';
import { IListInfo } from '../models/list-info-state.interface';

@Injectable()
export class RouteDeviationStateService {
    // public routesStateSelector = ((state: IAppState) => state.routeDeviations);

    // public isLoadRequiredSelector = createSelector(
    //     this.routesStateSelector,
    //     (state: IRouteDeviationState) => state.isLoadRequired
    // );

    // public routesSelector = createSelector(
    //     this.routesStateSelector,
    //     (state: IRouteDeviationState) => state.RouteDeviations
    // );

    // public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector);
    // public routesList$ = this.store.select(this.routesSelector);

    constructor(private store: Store<IAppState>) { }

    public loadRouteDeviations() {
        this.store.dispatch(new routeDeviationActions.LoadSavedRouteDeviation());
    }

    public loadRouteDeviationsSuccess() {
        this.store.dispatch(new routeDeviationActions.LoadSavedRouteDeviationSuccess());
    }

    public loadRouteDeviationsError(message: string) {
        this.store.dispatch(new routeDeviationActions.LoadSavedRouteDeviationError(message));
    }

    public cancelRouteDeviations() {
        this.store.dispatch(new routeDeviationActions.CancelLoadSavedRouteDeviation());
    }

    public getSavedRouteDeviation() {
        this.store.dispatch(new routeDeviationActions.GetSavedRouteDeviation());
    }

    public getSavedRouteDeviationSuccess() {
        this.store.dispatch(new routeDeviationActions.GetSavedRouteDeviationSuccess());
    }

    public getSavedRouteDeviationError() {
        this.store.dispatch(new routeDeviationActions.GetSavedRouteDeviationError());
    }
}
