import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap, map, switchMap, filter, finalize, share, catchError, delay , take , startWith } from 'rxjs/operators';

import { muteFirst, DualVisibilityLinkCustomer } from 'emr-ng-shared';
import { SensorRangeInfo } from 'emr-ng-shared';
import { GetSensorRangeRequest } from 'emr-ng-shared';

import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { EditShipmentStateService } from 'app-modules/core/store/services/edit-shipment-state.service';
import { CustomerStateService } from 'app-modules/core/store/services/customer-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { SensorType } from 'emr-ng-shared';

@Injectable()
export class EditShipmentService {
    constructor(
        private editStateSvc: EditShipmentStateService,
        private custStateSvc: CustomerStateService,
        private oversightSvc: OversightApiService,
    ) { }

    public sensorRangeList$: Observable<IListInfo<SensorRangeInfo>> = muteFirst(
        this.getSensorRangeListLoader().pipe(startWith(null)),
        this.editStateSvc.sensorRangeList$
    );

    public selectLinkedCustomer(linkedCustomer: DualVisibilityLinkCustomer | null = null) {
        this.editStateSvc.selectLinkedCustomer(linkedCustomer);
    }

    private getSensorRangeListLoader(): Observable<IListInfo<SensorRangeInfo>> {
        return this.editStateSvc.isSensorRangeLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.editStateSvc.loadSensorRangeList()),
            switchMap(() => this.getSensorRangeList()),
            tap(
                n => this.editStateSvc.loadSensorRangeListSuccess(n),
                e => this.editStateSvc.loadSensorRangeListError('')
            ),
            finalize(() => this.editStateSvc.cancelLoadSensorRangeList()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getSensorRangeList(): Observable<IListInfo<SensorRangeInfo>> {
        return this.editStateSvc.selectedLinkedCustomer$.pipe(
            switchMap(n => {
                const request = new GetSensorRangeRequest();
                request.RetailerId = Boolean(n) ? n.CustomerId : null;
                request.SensorTypeCodes = [ SensorType.Temperature ];
                return this.oversightSvc.GetSensorRanges(request);
            }),
            map(n => {
                return {
                    list: n.SensorRangeList,
                    itemCount: n.SensorRangeList.length,
                    isPaged: false
                };
            })
        );
    }

}







