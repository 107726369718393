import { Injectable } from '@angular/core';

import { Store} from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';

import * as alertSuspenseStatusActions from 'app-modules/core/store/alert-suspense-status/alert-suspense.actions';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

@Injectable()
export class AlertSuspenseStatusStateService {
    constructor(private store: Store<IAppState>) { }

    // Actions
    public loadAlertSuspenseStatus() {
        this.store.dispatch(new alertSuspenseStatusActions.LoadAlertSuspenseStatus());
    }

    public loadAlertSuspenseStatusSuccess() {
        this.store.dispatch(new alertSuspenseStatusActions.LoadAlertSuspenseStatusSuccess());
    }

    public loadAlertSuspenseStatusError(error: IErrorInfo) {
        this.store.dispatch(new alertSuspenseStatusActions.LoadAlertSuspenseStatusError(error));
    }

    public updateAlertSuspenseStatus() {
        this.store.dispatch(new alertSuspenseStatusActions.UpdateAlertSuspenseStatus());
    }

    public updateAlertSuspenseStatusSuccess() {
        this.store.dispatch(new alertSuspenseStatusActions.UpdateAlertSuspenseStatusSuccess());
    }

    public updateAlertSuspenseStatusError(error: IErrorInfo) {
        this.store.dispatch(new alertSuspenseStatusActions.UpdateAlertSuspenseStatusError(error));
    }
}
