import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-historical-view',
  templateUrl: './historical-view.component.html'
})
export class HistoricalViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
