import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseURLPicker } from 'app-modules/core/services/baseurl-picker.service';

@Component({
  selector: 'app-icon-info',
  templateUrl: './icon-info-popup.component.html',
  styleUrls: ['./icon-info-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IconInfoPopupComponent implements OnInit {
  @Input() isIconInfoOpen: boolean;
  oversightImageURL: string;
  oversightCommonPath: string;
  constructor(
    public bsShowIconInfoModalRef: BsModalRef,
    private domainPicker: BaseURLPicker
  ) { }

  ngOnInit() {
    this.oversightImageURL = this.domainPicker.resourceDomain;
    this.oversightCommonPath = '/LocusOverSight/V2/WebApp/Images/Common/AppImages/';
  }

  onPopupCancel() {
    this.bsShowIconInfoModalRef.hide();
  }
}


