import { Component, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export class ForgotPasswordResponseOkEventArgs {
  modalRef: BsModalRef;
  hasError: boolean;
}

@Component({
    selector: 'app-forgot-password-response',
    templateUrl: './forgot-password-response.component.html'
  })

  export class ForgotPasswordResponseComponent {
    @Output() ok = new EventEmitter<ForgotPasswordResponseOkEventArgs>();

    showCloseButton: false;
    hasError = false;
    errorMessage: string = null;

    constructor(public bsModalRef: BsModalRef) {}

    onOkClick() {
      const args = new ForgotPasswordResponseOkEventArgs();
      args.modalRef = this.bsModalRef;
      args.hasError = this.hasError;

      this.ok.emit(args);

      this.bsModalRef.hide();
    }
  }
