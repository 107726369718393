import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as pageSizeActions from 'app-modules/core/store/actions/page-size.actions';
import { IPageSizeState } from '../models/page-size-state.interface';
import { emptyList } from '../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    authActions.SignIn |
    pageSizeActions.LoadPageSize |
    pageSizeActions.LoadPageSizeSuccess |
    pageSizeActions.LoadPageSizeError;

export function PageSizeReducer(state: IPageSizeState = initialState.pageSize, action: Actions): IPageSizeState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
        case actionIndex.SIGN_IN:
            return {
                pageSizeList: emptyList(),
                isLoadRequired: true,
                isLoading: false,
                errorMessage: ''
            }

        case actionIndex.LOAD_PAGE_SIZE:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_PAGE_SIZE_SUCCESS:
            return {
                ...state,
                pageSizeList: action.pageSizeList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_PAGE_SIZE_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
