import { Component, OnInit, OnDestroy, Input, EventEmitter, ViewChild, Output, ElementRef, TemplateRef } from '@angular/core';

import { combineLatest, Observable, of, Subscription } from 'rxjs';

import {
    MapTypeId, IMapOptions, IBox, ILatLong, TraxxInfo, IMarkerOptions, IMarkerMetadata, Marker,
    MarkerEventArgs, EmrUtilService, GetRouteDeviationResponseModel, TrackerStatus,
    CustomMapServiceFactory, MapServiceFactory, DateTimeObject, BreadCrumbFlagRequest,
    DateRange, BusinessRuleType, BusinessRuleInfo, TrackerHistory, InfoBoxPlacement,
    AnimationService, IBaseAnimation, SensorLocationColorRange, LocationInfo, LocationCollisionInfo, DistanceUnits, PolygonType, MarkerTypeId
} from 'emr-ng-shared';

// import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import { BingRouteDeviationService } from './services/bing-route-deviation-service';
import { BaiduRouteDeviationService } from './services/baidu-route-deviation-service';
import * as _ from 'lodash';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { NgForm } from '@angular/forms';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { environment } from 'environments/environment';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { ShipmentDetailsCommonData } from 'app-modules/shipment-detail/models/ShipmentDetailsCommonData';
import { HIDDEN_BREADCRUMB_IMAGEID } from 'app-modules/core/consts/values';
import { map, take, tap, withLatestFrom } from 'rxjs/operators';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { label_replayTraxx_pause, label_replayTraxx_play, label_replayTraxx_Slow, label_replayTraxx_Medium, label_replayTraxx_Fast, timeline_notentered } from 'app-modules/core/consts/localization';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { LocationService } from 'app-modules/core/services/location.service';
import { LocationBoundaryEntity, LocationBoundaryViewModel } from 'app-modules/core/models/location-boundary.model';
import { LocationCollisionService } from 'app-modules/core/services/location-collisions.service';
import { DomoUIStateService } from 'app-modules/domo/store/domo.state.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';

export function MapRouteServiceFactory(
    mapServiceFactory: MapServiceFactory,
    detailStateSvc: ShipmentDetailStateService,
    utilSvc: EmrUtilService) {
    return (<CustomMapServiceFactory>mapServiceFactory).isLoadBaiduMapsFn() ?
        new BaiduRouteDeviationService(detailStateSvc, utilSvc) :
        new BingRouteDeviationService(detailStateSvc, utilSvc);
}

@Component({
    selector: 'app-shipment-route',
    templateUrl: './shipment-route.component.html',
    styleUrls: ['./shipment-route.component.css'],
    providers: [ //jelax OR-2497
        {
            provide: BingRouteDeviationService,
            deps: [MapServiceFactory, ShipmentDetailStateService, EmrUtilService],
            useFactory: MapRouteServiceFactory
        }
    ]
})

export class ShipmentRouteComponent implements OnInit, OnDestroy {

    public showInfoBox: boolean;
    public infoBoxTop: number;
    public infoBoxLeft: number;
    public infoBoxBeakPosition: number;
    public displayErrorMessage: boolean;
    public isZoomedInfoLevel = true;
    zoomIndex = 0;
    previousZoomIndex = 0;
    public selectedRadiusBreadCrumbId: number = null;
    ShowRoute = true;
    ShowDeviations = false;
    @Input() routeDeviation: GetRouteDeviationResponseModel;
    @Input() isItFromRouteDeviation = false;
    @Input() isFromSensorChart = false;
    @Input() isFromTimeline = true;
    @Input() isFromShowRoute = false;
    @Input() cssClass = '';

    public errorMessage = '';
    public SelectedMarker: TraxxInfo;
    public SelectedMarkersList: TraxxInfo[];
    public text: string;
    traxxList: ITraxxState;
    @Input() displayTimePeriod = true;
    @Input() SetFixedHeight = false;
    parentHeight: string = null;
    isSubmitted: boolean;
    locationsListSub: Subscription;
    distanceUnits: DistanceUnits;
    @Input() set ParentHeight(value: string) {
        this.parentHeight = value;
        this.updateMapHeight();
    }
    MarkerShowInfoBox = new EventEmitter<MarkerEventArgs>();
    LastMarkerEvent: MarkerEventArgs;
    isDirectionsDeleted = true;
    isFirst = true;
    isMapFullyLoaded = false;
    isMapInitializing = false;
    directionChanged: any;
    mapType: string;
    sensorChartClickSubscription: Subscription;
    hideOtherInfoBoxes = new EventEmitter();
    hideSensorChartInfoBoxes = new EventEmitter();
    SensorMarkerShowInfoBox = new EventEmitter<MarkerEventArgs>();
    alertOption = true;
    checkedAlerts: number[];
    FromDate: Date;
    ToDate: Date;
    dateTimeObject: DateTimeObject = new DateTimeObject();
    userPreferenceSubscription: Subscription;
    @ViewChild(NgForm) shipmentRouteSearchFrom: NgForm;
    traxxListCopy: ITraxxState;
    isDateRangeValid: boolean;
    minDate: Date;
    maxDate: Date;
    filteredTraxxData: ITraxxState;
    markBreadCrumbSubscription: Subscription;

    isZoomed = false;
    mapCenterLat: number;
    mapCenterLong: number;
    @Input() isFromDetails = false;

    MarkerList: Array<Marker>;   //A collection of Marker objects created based on this.markerOptions
    SensorMarkerList: Array<Marker>;   //A collection of Marker objects created based on this.sensorMarkerOptions
    replayTraxx_play = label_replayTraxx_play;
    replayTraxx_pause = label_replayTraxx_pause;

    minCrumbDate: Date;
    maxCrumbDate: Date;
    @Input()
    set DateRange(dateRange: DateRange) {
        if (dateRange !== undefined) {
            this.ToDate = dateRange && dateRange.endDateTime !== null ? new Date(dateRange.endDateTime) : null;
            this.FromDate = dateRange && dateRange.startDateTime !== null ? new Date(dateRange.startDateTime) : null;
            this.onDateChanged();
            if (this.traxxListCopy) {
                this.onShipmentRouteSearch(this.traxxListCopy, true);
            }
        }
    }

    @Input()
    set TraxxList(traxx: ITraxxState) {
        if (traxx !== undefined) {
            this.traxxList = traxx;
            this.filteredTraxxData = traxx;
            if (this.traxxList.AlertTotals && this.traxxList.AlertTotals.AlertCounts) {
                this.checkedAlerts = this.traxxList.AlertTotals.AlertCounts.map(k => k.Type);
            }
            this.setMinMaxTo90Days();
            const dates = traxx.traxx && traxx.traxx.list && traxx.traxx.list.map(k =>
                k && new Date(k.SatTime));
            this.minCrumbDate = new Date(Math.min.apply(null, dates));
            this.maxCrumbDate = new Date(Math.max.apply(null, dates));
            //this.maxDate = new Date(maxDate.setHours(23, 59, 59));
            if (traxx?.traxx?.list?.length > 0) {
                this.FromDate = traxx.SelectedPeriodRange?.FromDtm ? new Date(traxx.SelectedPeriodRange?.FromDtm) : this.minCrumbDate;
                this.ToDate = traxx.SelectedPeriodRange?.ToDtm ? new Date(traxx.SelectedPeriodRange.ToDtm) : this.maxCrumbDate;
                this.onDateChanged();
            }
            const hiddenCrumbData = traxx && traxx.traxx && traxx.traxx.list &&
                traxx.traxx.list.filter(k => k.IsHidden);
            let count = 0;
            if (hiddenCrumbData) {
                count = hiddenCrumbData.length;
                hiddenCrumbData.filter(k => k.TrackerInfo.Count > 1).map(k => count = count + k.TrackerInfo.Count - 1)
            }
            this.hiddenCrumbCount = count;
            this.updateShowHiddenCrumbs();
            this.traxxListCopy = _.cloneDeep(traxx);
            if (this.isMapFullyLoaded) {
                this.onTraxxSubscription(traxx, false, this.isMapFullyLoaded);
                this.locations = [];
            }
        }
    }

    mapHeighCalc: string = null;
    isTraxxLoading$ =
        combineLatest(this.detailSvc.isTraxxLoading$,
            this.detailSvc.isHistoricalLoading$,
            (a, b) => a || b).pipe(
                tap(_ => this.updateMapHeight()));

    @Input()
    set Shipment(shipment: Shipment) {
        if (shipment !== undefined) {
            this.polylinePath = [];
            this.markerOptions = [];
            this.markerBoundary = [];
            this.onShipmentSubscription(shipment);
            this.showInitialState();
        }
    }

    get Shipment() {
        return this.shipment;
    }

    @Input()
    shipmentList: TrackerHistory[];

    public markerInfo;
    public options: IMapOptions = {
        disableBirdseye: false,
        disableStreetside: false,
        navigationBarMode: 1,
        zoom: 3,
        mapTypeId: MapTypeId.road
    };

    public box: IBox = this.custSvc?.defaultBox;
    public previousBox: IBox = this.box;
    public intialBox: IBox = this.box;
    public hideCheckBoxBounds: IBox = this.box;
    public bounds: IBox;
    public InPlaceAutoFit = InfoBoxPlacement.InPlaceAutoFit;

    public polylinePath: Array<ILatLong> = new Array<ILatLong>();
    public markerOptions: IMarkerOptions[] = [];
    public closeByMarkers: IMarkerOptions[] = [];
    public sensorMarkerOptions: IMarkerOptions[] = [];
    visibleLocationBoundaries: LocationBoundaryViewModel[] = [];
    markerBoundary: TraxxInfo[] = [];
    markerListBoundaries: TraxxInfo[] = [];
    locations: LocationInfo[] = [];
    hasOtherCrumbs = false;
    locationBoundaries: LocationBoundaryEntity;
    addedLocations: number[] = [];

    private shipment: Shipment;
    ShowEstimatedCrumbsRule = false;
    @Input()
    ShowGeoFence = false;
    IsUnauthenticated = false;
    ShowEstimatedCrumb = true;
    breadCrumbCount = false;
    minimumClusterCount = 25;
    @ViewChild('countInfoModal') countInfoModal: TemplateRef<any>;
    bsModalRef: BsModalRef;
    private shipmentSubscription: Subscription;
    private traxxSubscription: Subscription;
    private reverseGeoCOdeSubscription: Subscription;
    public directionSubscription: Subscription;
    BusinessRuleSubscription: Subscription;
    showHiddenCrumbs = false;
    commonData: ShipmentDetailsCommonData;
    @Input() set detailsCommonData(data: ShipmentDetailsCommonData) {
        if (data) {
            this.commonData = data;
            this.showHiddenCrumbs = data.showHiddenCrumbs;
        }
    }
    hiddenCrumbCount: number;
    @Input() TraxxCopy: ITraxxState;
    ShowLastNDays: number = 90;
    @ViewChild('alertFilterPanel', { static: true }) alertFilterPanel: ElementRef;

    @Output() dateRangeChange = new EventEmitter();

    @Input() set timelineData(data: SensorLocationColorRange) {
        if (data && this.MarkerList?.length) {
            this.hideOtherInfoBoxes.emit();
            this.LastMarkerEvent = null;
            this.SelectedMarker = null;
            this.hideSensorChartInfoBoxes.emit();
            const threshold = 0.0001;
            const markers: Marker[] = this.MarkerList.filter(m => {
                const markerData = m.Metadata?.get('marker')
                const threshold = 0.0001;
                if (markerData) {
                    if ((markerData.Latitude - data.Latitude) <= threshold && (markerData.Latitude - data.Latitude) >= -threshold &&
                        (markerData.Longitude - data.Longitude) <= threshold && (markerData.Longitude - data.Longitude) >= -threshold) {
                        return true;
                    }
                    return false;
                }
                return false;
            });
            if (markers && markers.length > 0) {
                const marker = markers[0];
                this.SelectedMarker = marker?.Metadata?.get('marker')
                this.LastMarkerEvent = { Marker: marker, Location: marker.Location, Pixels: null, Markers: markers };
                this.updateBox(marker.Location.latitude, marker.Location.longitude);
                this.options.center = marker.Location;
                setTimeout(() => {
                    const arg = {
                        Marker: marker,
                        Location: marker.Location,
                        Click: null,
                        Pixels: null,
                        Markers: markers,
                    };
                    this.MarkerShowInfoBox.emit(arg);
                });
            } else {
                const traxxModel = new TraxxInfo();
                traxxModel.Latitude = data.Latitude;
                traxxModel.Longitude = data.Longitude;
                traxxModel.TrackerInfo = new TrackerStatus();
                traxxModel.TrackerInfo.TrackerId = this.shipment.trackerId;
                traxxModel.labels = [{ label: data.LocationName, value: null }];
                if (data.StartSatTimeString) {
                    traxxModel.TrackerInfo.LastReportedTimestampFormatted = data.StartSatTimeString;
                    traxxModel.TrackerInfo.LastReportedDateRange = data.StartSatTimeString;
                } else {
                    traxxModel.labels.push({ label: timeline_notentered, value: null });
                }
                if (data.Latitude && data.Longitude) {
                    this.SelectedMarker = traxxModel;
                    this.openInfoBoxWithCustomData(traxxModel, true);
                }
            }
        }
    }

    markerLayerVisible = true;
    sensorMarkerLayerVisible = false;
    polylineVisible = true;
    private mapPromise: Promise<any>;
    private animationMovie: IBaseAnimation;
    private startedAnimationMovie: boolean = false;
    isLocusAdmin: boolean = false;
    constructor(
        private detailSvc: ShipmentDetailService,
        private utilSvc: EmrUtilService,
        public modalService: BsModalService,
        private routeDevSvc: BingRouteDeviationService,
        private preferenceSvc: PreferenceService,
        private businessRuleSvc: BusinessRulesService,
        private imgMapSvc: ImageMapService,
        private custSvc: CustomersService,
        private animationSvc: AnimationService,
        private showPopups: OversightPopupService,
        private locationSvc: LocationService,
        private locationCollisionSvc: LocationCollisionService,
        private domoStateSvc: DomoUIStateService,
        private authSvc: AuthService
    ) {
        this.directionSubscription = this.routeDevSvc.OnDirectionsLoad.subscribe(() => {
            this.isMapFullyLoaded = true;
            this.onMapCheckboxChange(this.isMapFullyLoaded);
        });
    }

    public ngOnInit() {
        const distUnitsSubscription = this.preferenceSvc.distUnits$.subscribe(units => {
            this.distanceUnits = units?.toLowerCase() === "miles" || units?.toLowerCase() === "m" ? DistanceUnits.Miles : DistanceUnits.Kilometers;
            distUnitsSubscription?.unsubscribe();
        });

        if (this.ShowLastNDays) {
            this.setMinMaxTo90Days();
        }

        this.sensorMarkerOptions = [];
        if (this.isFromSensorChart) {
            this.sensorChartClick();
        }
        if (!this.isItFromRouteDeviation) {
            const data = localStorage.getItem(environment.showEstimatedCrumbs);
            if (data !== null) {
                this.ShowEstimatedCrumb = (data === 'true');
            }
        }
        this.userPreferenceSubscription = this.preferenceSvc.getDateTimeObject().subscribe(k => {
            this.dateTimeObject = k;
        });
        this.IsUnauthenticated = this.detailSvc.getUnAuthenticationToken();
        this.BusinessRuleSubscription = this.businessRuleSvc.businessRulesList$.subscribe((k: IListInfo<BusinessRuleInfo>) => {
            if (k.list && k.list.length > 0) {
                const businessRules = k.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.ShowEstimatedCrumbs);
                if (businessRules) {
                    this.ShowEstimatedCrumbsRule = businessRules.Enabled;
                    if (this.ShowEstimatedCrumbsRule && this.shipment.Is4G) {
                        this.showHiddenCrumbs = true;
                        this.onShowHiddenCrumbsChange(true);
                    }
                }
            }
        });
        const authStateSubscription = this.authSvc.authState$.pipe(take(1)).subscribe(au => {
            this.isLocusAdmin = au ? au.isEmersonAdmin : false;
            authStateSubscription?.unsubscribe();
        });
    }

    public ngOnDestroy() {
        if (this.shipmentSubscription) {
            this.shipmentSubscription.unsubscribe();
        }
        if (this.BusinessRuleSubscription) {
            this.BusinessRuleSubscription.unsubscribe();
        }
        if (this.sensorChartClickSubscription) {
            this.sensorChartClickSubscription.unsubscribe();
        }
        if (this.traxxSubscription) {
            this.traxxSubscription.unsubscribe();
        }
        if (this.reverseGeoCOdeSubscription) {
            this.reverseGeoCOdeSubscription.unsubscribe();
        }
        if (this.isFromSensorChart) {
            this.detailSvc.sensorChartClick(null);
        }
        if (this.directionSubscription) {
            this.directionSubscription.unsubscribe();
        }
        if (this.userPreferenceSubscription) {
            this.userPreferenceSubscription.unsubscribe();
        }
        if (this.locationsListSub) {
            this.locationsListSub.unsubscribe();
        }
        this.polylinePath = [];
        this.markerOptions = [];
        this.markerBoundary = [];
        this.routeDevSvc.cleanup();
    }

    onShipmentSubscription(shipment: Shipment) {
        this.shipment = shipment;
        if (this.traxxList !== undefined) {
            // this.onShipmentRouteSearch(this.traxxList, true);
            this.onTraxxSubscription(this.traxxList, false, true);
        }
    }

    onShowHiddenCrumbsChange(boxReload, keepLastMarkerEvent = false) {
        if (this.commonData) {
            this.commonData.showHiddenCrumbs = this.showHiddenCrumbs;
        }
        this.zoomIndex = 0;
        this.onTraxxSubscription(this.filteredTraxxData, keepLastMarkerEvent, boxReload);
    }

    private updateAlertsCountBasedOnHiddenCrumbsCheckBox() {
        let allAlerts = _.cloneDeep(this.traxxListCopy.traxx.list.filter(k => k.AlertInfo != null));
        let alertCounts = _.cloneDeep(this.traxxListCopy?.AlertTotals?.AlertCounts);
        if (allAlerts?.length > 0 && alertCounts?.length > 0) {
            if (!this.showHiddenCrumbs) {
                var hiddenAlerts = allAlerts.filter(k => k.IsHidden);
                hiddenAlerts.forEach(alert => {
                    let filterAlert = alertCounts.find(obj => obj.Type === alert.AlertInfo.Type);
                    if (filterAlert) {
                        filterAlert.Count -= 1;
                    }
                });
            }
            this.traxxList.AlertTotals.AlertCounts = alertCounts.filter(x => x.Count > 0);
        }
    }

    private onTraxxSubscription(traxxInfo: ITraxxState, keepLastMarkerEvent = false, boxReload = false) {
        if (!traxxInfo || (this.shipment !== undefined && !this.shipment)) {
            return;
        }
        if (!keepLastMarkerEvent) {
            this.LastMarkerEvent = null;
        }
        this.hideOtherInfoBoxes.emit();
        this.polylinePath = [];
        this.markerOptions = [];
        this.markerBoundary = [];
        const list = traxxInfo.traxx.list;
        list.map(k => k.Count = k?.TrackerInfo?.Count);
        const extraTraxx = traxxInfo.extraTraxx;
        const box = this.utilSvc.getNewBoxParams();
        const length = list != null && list.length > 0 ? list.length : 0;
        if ((list != null && length > 0) ||
            (extraTraxx != null && extraTraxx.length > 0)) {
            this.displayErrorMessage = false;
            this.errorMessage = traxxInfo.errorMessage;
            const m: Array<IMarkerOptions> = new Array<IMarkerOptions>();

            if (!this.hasOtherCrumbs) {
                this.hasOtherCrumbs = true;
            }

            list?.forEach(element => {
                if (!this.showHiddenCrumbs && element.IsHidden) {
                    return false;
                }
                if (element &&
                    element.TrackerInfo &&
                    this.shipment) {
                    element = {
                        ...element,
                        TrackerInfo: {
                            ...element.TrackerInfo,
                            TrackerId: this.shipment.trackerId
                        }
                    };
                }
                if (element?.AlertInfo?.AlertId) {
                    element.AlertInfo.Image = this.imgMapSvc.getImageURL(element.AlertInfo.ImageId, element.AlertInfo.ImageUrlSVG, element.AlertInfo.ImageUrl);
                }
                this.utilSvc.updateBoxParams(box, element.Latitude, element.Longitude);
                this.mapMarkerOption(element, m);
                this.polylinePath.push({ latitude: element.Latitude, longitude: element.Longitude });
                this.mapMarkerWifiOption(element);
                // this.fetchGeoFence(element, collisionMarkers);
            });
            if (extraTraxx != null && extraTraxx.length > 0) {
                extraTraxx.forEach(element => {
                    if (element &&
                        element.TrackerInfo &&
                        this.shipment) {
                        element = {
                            ...element,
                            HideSensorValues: true,
                            TrackerInfo: {
                                ...element.TrackerInfo,
                                TrackerId: this.shipment.trackerId
                            }
                        };
                    }
                    this.utilSvc.updateBoxParams(box, element.Latitude, element.Longitude);
                    this.mapMarkerOption(element, m);
                    this.mapMarkerWifiOption(element);
                    // this.fetchGeoFence(element, collisionMarkers);
                });
            }
            this.markerOptions = m;
            if (boxReload) {
                this.box = box;
                this.isMapInitializing = true;
                this.SetMapZoomLevel();
            } else {
                this.box = this.hideCheckBoxBounds;
            }
        } else {
            this.displayErrorMessage = true;
            this.errorMessage = traxxInfo.errorMessage;
        }
        this.updateAlertsCountBasedOnHiddenCrumbsCheckBox();
    }

    fetchGeoFence(element: TraxxInfo, collisionMarkers: IMarkerOptions[]) {
        if (element.LocationId && this.addedLocations.indexOf(element.LocationId) < 0) {
            this.addedLocations.push(element.LocationId);
        }
        if (element.LocationId &&
            !this.locationBoundaries[element.LocationId]) {
            const location = this.locations?.find(l => l.LocationId == element.LocationId);
            if (location) {
                const locationBoundaryVM = new LocationBoundaryViewModel(location, this.imgMapSvc, this.distanceUnits, this.custSvc.noFenceLocationRadius, false, element.ImageId);
                this.locationBoundaries[element.LocationId] = locationBoundaryVM;

                // has some collisions
                if (location.Collisions?.length) {
                    this.addCollisionPoints(location.Collisions, collisionMarkers);
                }
            }
        } else if (!element.LocationId) {
            const center = {
                latitude: element.Latitude,
                longitude: element.Longitude
            };
            const defaultFence = this.locationCollisionSvc.fetchDefaultFence(center);
            const findCollisions = this.locationCollisionSvc.fetchCollisions(defaultFence, this.locations, this.distanceUnits);

            // has some collisions
            if (findCollisions?.length) {
                this.addCollisionPoints(findCollisions, collisionMarkers);
            }
        }
    }

    addCollisionPoints(collisions: LocationCollisionInfo[], collisionMarkers: IMarkerOptions[]) {
        for (let item of collisions) {
            if (this.addedLocations.indexOf(item.LocationId) < 0) {
                this.addCloseByLocation(item, collisionMarkers);
            }
        }
    }

    addCloseByLocation(element: LocationCollisionInfo, collisionMarkers: IMarkerOptions[]) {
        if (this.addedLocations.indexOf(element.LocationId) < 0) {
            this.addedLocations.push(element.LocationId);
        } else {
            return;
        }
        const metaData = new Map<string, any>();
        metaData.set('marker', element);
        const anchor = { x: 15, y: 15 };
        const icon = this.imgMapSvc.getImageURL('yr', null, null);
        metaData.set('pixelOffset', { x: 0, y: 15 });
        metaData.set('defaultOffSet', { x: 0, y: 15 });
        const location = element.Location;
        const markerOptions = {
            position: {
                latitude: location.Latitude,
                longitude: location.Longitude
            },
            icon,
            anchor,
            metadata: metaData,
            priority: 1
        };
        collisionMarkers.push(markerOptions);

        if ((location.HasBoundary ||
            location.RadiusKm > 0) &&
            !this.locationBoundaries[location.LocationId]) {
            const locationBoundaryVM = new LocationBoundaryViewModel(location, this.imgMapSvc, this.distanceUnits, this.custSvc.noFenceLocationRadius, true, 'yr');
            this.locationBoundaries[location.LocationId] = locationBoundaryVM;
        }
    }

    updateVisibleBoundaries() {
        this.visibleLocationBoundaries = [];
        for (var lb in this.locationBoundaries) {
            var boundary = this.locationBoundaries[lb];
            if (boundary.ShowBoundary) {
                this.visibleLocationBoundaries.push(boundary);
            }
        }
    }

    SetMapZoomLevel() {
        const minBoxPadding = 0.05;
        if (
            ((this.box.maxLatitude - this.box.minLatitude) / 2) < minBoxPadding &&
            ((this.box.maxLongitude - this.box.minLongitude) / 2) < minBoxPadding
        ) {
            const centerLat = this.box.minLatitude + (this.box.maxLatitude - this.box.minLatitude);
            const centerLon = this.box.minLongitude + (this.box.maxLongitude - this.box.minLongitude);
            this.box = {
                maxLatitude: centerLat + minBoxPadding,
                maxLongitude: centerLon + minBoxPadding,
                minLatitude: centerLat - minBoxPadding,
                minLongitude: centerLon - minBoxPadding
            };
        }
        this.previousBox = this.box;
        this.intialBox = this.box;
        this.isZoomed = false;
        this.mapCenterLat = null;
        this.mapCenterLong = null;
    }

    onBoundsChange(ibox: IBox) {
        this.bounds = ibox;
        this.mapCenterLat = this.mapCenterLat ? this.mapCenterLat : this.bounds.center.latitude;
        this.mapCenterLong = this.mapCenterLong ? this.mapCenterLong : this.bounds.center.longitude;
        if (this.mapCenterLat !== this.bounds.center.latitude || this.mapCenterLong !== this.bounds.center.longitude) {
            this.isZoomed = true;
        } else {
            this.isZoomed = false;
        }
    }

    onBingMapZoomUpdate(zoom: number) {
        if (this.isMapInitializing) {
            this.previousZoomIndex = zoom;
            this.previousBox = this.bounds;
            this.isMapInitializing = false;
        }
        this.zoomIndex = zoom;
        if (!this.isZoomedInfoLevel && zoom <= this.previousZoomIndex) {
            this.isZoomedInfoLevel = true;
        }
        if (this.LastMarkerEvent) {
            this.MarkerShowInfoBox.emit(this.LastMarkerEvent);
        }
    }

    OnMapMarkerClick(e: MarkerEventArgs) {
        if (e && e.Marker && e.Marker.Metadata) {
            this.hideSensorChartInfoBoxes.emit();
            this.sensorMarkerOptions = [];
            const shipment = e.Marker.Metadata.get('marker');
            this.LastMarkerEvent = e;
            // this.OnMarkerClick(shipment);
            this.SelectedMarker = shipment;
            //For List of markers at same point
            this.SelectedMarkersList = [];
            e.Markers?.map(k => this.SelectedMarkersList.push(k.Metadata.get('marker')));
            if (this.isLocusAdmin && this.SelectedMarker.BreadCrumbId && this.SelectedMarker.CTTRadius) {
                if (this.markerListBoundaries?.length > 0) {
                    const selectedMarkerBoundary = this.markerListBoundaries.find(k => k.BreadCrumbId?.toString() === this.SelectedMarker.BreadCrumbId.toString());
                    if (selectedMarkerBoundary) {
                        this.selectedRadiusBreadCrumbId = selectedMarkerBoundary.BreadCrumbId;
                        this.markerBoundary = [selectedMarkerBoundary];
                        this.zoomInCrumbLevel(this.SelectedMarker.CTTRadius);
                        this.isZoomed = true;
                    }
                }
            } else {
                this.markerBoundary = [];
                this.selectedRadiusBreadCrumbId = null;
            }
        }
    }

    // OnMarkerClick(marker: TraxxInfo) {
    //     this.SelectedMarker = marker;
    // this.box = {
    //     maxLatitude: Math.max.apply(Math, [marker.Latitude + 0.05]),
    //     maxLongitude: Math.max.apply(Math, [marker.Longitude + 0.05]),
    //     minLatitude: Math.min.apply(Math, [marker.Latitude - 0.02]),
    //     minLongitude: Math.min.apply(Math, [marker.Longitude - 0.02])
    // };
    // }

    zoomInCrumbLevel(radius: number = null) {
        if (this.SelectedMarker) {
            let minLatBoxPadding = 0;
            let minLongBoxPadding = 0;
            if (radius > 0) {
                const distanceInUnit = this.distanceUnits === DistanceUnits.Kilometers ? 111 : 69;
                const radiusCalcalatedValue = (this.distanceUnits === DistanceUnits.Kilometers) ? (radius / 1000) : (radius * 0.00062137);
                minLatBoxPadding = (radiusCalcalatedValue) / distanceInUnit;
                minLongBoxPadding = (radiusCalcalatedValue) / distanceInUnit;
            } else {
                minLatBoxPadding = (this.bounds.maxLatitude - this.bounds.minLatitude) / 2 < 0.00001 ? 0.00001 :
                    (this.bounds.maxLatitude - this.bounds.minLatitude) / 128;
                minLongBoxPadding = (this.bounds.maxLongitude - this.bounds.minLongitude) / 2 < 0.00001 ? 0.00001 :
                    (this.bounds.maxLongitude - this.bounds.minLongitude) / 128;
            }
            this.box = {
                maxLatitude: Math.max.apply(Math, [this.SelectedMarker.Latitude + minLatBoxPadding]),
                maxLongitude: Math.max.apply(Math, [this.SelectedMarker.Longitude + minLongBoxPadding]),
                minLatitude: Math.min.apply(Math, [this.SelectedMarker.Latitude - minLatBoxPadding]),
                minLongitude: Math.min.apply(Math, [this.SelectedMarker.Longitude - minLongBoxPadding])
            };
        }
    }

    setMapDefaultLevel() {
        if (this.SelectedMarker) {
            const minLatBoxPadding = ((this.previousBox.maxLatitude - this.previousBox.minLatitude) / (this.previousZoomIndex > 3 ? 4 : 3));
            const minLongBoxPadding = ((this.previousBox.maxLongitude - this.previousBox.minLongitude) /
                (this.previousZoomIndex > 3 ? 4 : 3));
            this.box = {
                maxLatitude: this.SelectedMarker.Latitude + minLatBoxPadding,
                maxLongitude: this.SelectedMarker.Longitude + minLongBoxPadding,
                minLatitude: this.SelectedMarker.Latitude - minLatBoxPadding,
                minLongitude: this.SelectedMarker.Longitude - minLongBoxPadding
            };
        }
    }

    onViewLocationClick(i: any) {
        if (!this.SelectedMarker) { return; }
        if (i.linkClass === 'click-infobox-close') {
            this.LastMarkerEvent = null;
            return;
        }
        if (i.linkClass === 'click-infobox-zoom-in') {
            this.isZoomedInfoLevel = false;
            this.zoomInCrumbLevel();
            this.isZoomed = true;
        } else if (i.linkClass === 'click-infobox-zoom-out') {
            this.isZoomedInfoLevel = true;
            this.setMapDefaultLevel();
        } else if (i.linkClass === 'click-see-radius-crumb') {
            if (this.markerListBoundaries?.length > 0) {
                const selectedMarkerBoundary = this.markerListBoundaries.find(k => k.BreadCrumbId?.toString() === i?.Data);
                if (selectedMarkerBoundary) {
                    this.selectedRadiusBreadCrumbId = selectedMarkerBoundary.BreadCrumbId;
                    this.markerBoundary = [selectedMarkerBoundary];
                    this.MarkerShowInfoBox.emit(this.LastMarkerEvent);
                    this.zoomInCrumbLevel(this.markerBoundary[0].CTTRadius);
                    this.isZoomed = true;
                }
            }
        } else if (i.linkClass === 'click-hide-radius-crumb') {
            if (this.markerBoundary.length > 0) {
                this.markerBoundary = [];
                this.selectedRadiusBreadCrumbId = null;
                this.MarkerShowInfoBox.emit(this.LastMarkerEvent);
                this.setMapDefaultLevel();
                this.isZoomed = false;
            }
        } else if (i.linkClass === 'click-show-hide-bread-crumb') {
            this.GetSelectedCrumbFromList(i);
            this.markCrumb();
        } else {
            this.GetSelectedCrumbFromList(i);
            this.SelectedMarker.AddressGeoLoading = true;
            this.MarkerShowInfoBox.emit(this.LastMarkerEvent);
            const req = { Latitude: this.SelectedMarker.Latitude as any, Longitude: this.SelectedMarker.Longitude as any };
            this.reverseGeoCOdeSubscription =
                this.detailSvc.GetReverseGeoCode(req).subscribe(n => {
                    this.SelectedMarker.TrackerInfo.AddressGeo = n.TrackerStatusList &&
                        n.TrackerStatusList.length > 0 ? n.TrackerStatusList[0].AddressGeo : '';
                    this.SelectedMarker.AddressGeoLoading = false;
                    this.MarkerShowInfoBox.emit(this.LastMarkerEvent);
                    this.reverseGeoCOdeSubscription.unsubscribe();
                    this.reverseGeoCOdeSubscription = null;
                });
        }
    }

    GetSelectedCrumbFromList(i: any) {
        if (this.SelectedMarkersList?.length > 0) {
            const selectedMarker = this.SelectedMarkersList.find(k => k.BreadCrumbId?.toString() === i?.Data);
            if (selectedMarker) {
                this.SelectedMarker = selectedMarker;
            }
        }
    }

    onResetZoom() {
        if (this.isZoomed) {
            this.isZoomed = false;
            this.box = { ...this.intialBox };
            this.isZoomedInfoLevel = true;
            if (this.LastMarkerEvent) {
                this.hideOtherInfoBoxes.emit();
                this.LastMarkerEvent = null;
            }
            this.hideSensorChartInfoBoxes.emit();
            this.sensorMarkerOptions = [];
        }
    }

    private markCrumb() {
        const minLatBoxPadding = ((this.bounds.maxLatitude - this.bounds.minLatitude) / (this.zoomIndex > 3 ? 4 : 3));
        const minLongBoxPadding = ((this.bounds.maxLongitude - this.bounds.minLongitude) / (this.zoomIndex > 3 ? 4 : 3));
        this.hideCheckBoxBounds = {
            maxLatitude: this.bounds.center.latitude + minLatBoxPadding,
            maxLongitude: this.bounds.center.longitude + minLongBoxPadding,
            minLatitude: this.bounds.center.latitude - minLatBoxPadding,
            minLongitude: this.bounds.center.longitude - minLongBoxPadding
        };
        const mark = !this.SelectedMarker.IsHidden;
        const req = {
            IsMarked: mark, BreadCrumbId: this.SelectedMarker.BreadCrumbId,
            GlobalDeviceId: this.shipment.trackerId, SatTime: this.utilSvc.DateFormatLocaleChange(this.SelectedMarker.SatTime)
        } as BreadCrumbFlagRequest;
        this.SelectedMarker.IsCrumbVisibilityUpdating = true;
        const markerEvent = this.LastMarkerEvent;
        this.MarkerShowInfoBox.emit(markerEvent);
        this.markBreadCrumbSubscription =
            this.detailSvc.MarkBreadCrumb(req).subscribe(n => {
                this.SelectedMarker.IsCrumbVisibilityUpdating = false;
                this.SelectedMarker.IsCrumbVisibilityUpdated = true;
                this.SelectedMarker.IsHidden = mark;
                // If it is 4g crumb then need to refresh the route
                if (this.ShowEstimatedCrumbsRule && this.shipment.Is4G) {
                    setTimeout(() => {
                        // TO hide the Info box
                        this.onShowHiddenCrumbsChange(false, this.showHiddenCrumbs);
                        //To refresh the list
                        this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb, this.shipmentList);
                    }, 500)
                } else {
                    this.updateTraxxListHiddenStatus(mark);
                    if (mark) {
                        this.hiddenCrumbCount += this.SelectedMarker.Count;
                    } else {
                        this.hiddenCrumbCount -= this.SelectedMarker.Count;
                    }
                    // this.onTraxxSubscription(this.filteredTraxxData, true);
                    this.MarkerShowInfoBox.emit(markerEvent);
                    const reloadBreak = 800;
                    if (!this.showHiddenCrumbs) {
                        setTimeout(() => {
                            this.onShowHiddenCrumbsChange(false, this.showHiddenCrumbs);
                        }, reloadBreak);
                    } else {
                        setTimeout(() => {
                            if (markerEvent === this.LastMarkerEvent) {
                                this.onShowHiddenCrumbsChange(false, true);
                                this.SelectedMarker.IsCrumbVisibilityUpdated = false;
                                markerEvent.Marker.Metadata.set('pixelOffset', { x: 0, y: 6 });
                                markerEvent.Marker.Metadata.set('defaultOffSet', { x: 0, y: 6 });
                                this.MarkerShowInfoBox.emit(markerEvent);
                            }
                        }, reloadBreak);
                    }
                    this.updateShowHiddenCrumbs();
                }
            },
                (e) => {
                    this.SelectedMarker.IsCrumbVisibilityUpdating = false;
                    if (markerEvent === this.LastMarkerEvent) {
                        this.MarkerShowInfoBox.emit(markerEvent);
                    }
                }
            );
    }

    private updateShowHiddenCrumbs() {
        if (this.hiddenCrumbCount === 0 && !(this.ShowEstimatedCrumbsRule && this.shipment.Is4G)) {
            this.showHiddenCrumbs = false;
            if (this.commonData) {
                this.commonData.showHiddenCrumbs = this.showHiddenCrumbs;
            }
        }
    }

    private updateTraxxListHiddenStatus(mark: boolean) {
        const data = this.filteredTraxxData.traxx.list.find(k =>
            k.BreadCrumbId === this.SelectedMarker.BreadCrumbId && k.SatTime === this.SelectedMarker.SatTime
            && k.ImageUrl === this.SelectedMarker.ImageUrl);
        if (data) {
            data.IsHidden = mark;
        }
        const copyData = this.TraxxCopy?.traxx.list.find(k =>
            k.BreadCrumbId === this.SelectedMarker.BreadCrumbId && k.SatTime === this.SelectedMarker.SatTime
            && k.ImageUrl === this.SelectedMarker.ImageUrl);
        if (copyData) {
            copyData.IsHidden = mark;
        }
    }

    private mapMarkerWifiOption(element: TraxxInfo) {
        let markerElement = _.clone(element);
        markerElement["BoundaryDrawOptions"] = {
            center: { latitude: element.Latitude, longitude: element.Longitude },
            radius: this.distanceUnits === DistanceUnits.Kilometers ? (element.CTTRadius / 1000) : (element.CTTRadius * 0.00062137),
            fillColor: 'rgba(0, 0, 255, 0.1)', // Fill color with opacity
            strokeColor: 'rgba(0, 0, 255, 0.1)', // Stroke color
            strokeThickness: 1, // Stroke thickness
            polygonType: PolygonType.Circle,
            units: this.distanceUnits
        };
        this.markerListBoundaries.push(markerElement);
    }

    private mapMarkerOption(element: any, m: any[]) {
        const metaData = new Map<string, any>();
        metaData.set('marker', element);
        let anchor;
        let icon;
        metaData.set('pixelOffset', { x: 0, y: element.ImageAnchorTop });
        metaData.set('defaultOffSet', { x: 0, y: element.ImageAnchorTop });
        anchor = { x: element.ImageAnchorLeft, y: element.ImageAnchorTop };
        icon = this.imgMapSvc.getImageURL(element.ImageId, element.ImageUrlSVG, element.ImageUrl);

        const markerOptions = {
            position: {
                latitude: element.Latitude,
                longitude: element.Longitude
            },
            icon: icon,
            anchor,
            metadata: metaData,
            priority: element.Priority
        };
        if (element.ImageAnchorLeft === undefined ||
            element.ImageAnchorLeft === null ||
            element.ImageAnchorTop === undefined ||
            element.ImageAnchorTop === null) {
            delete markerOptions.anchor;
        }
        m.push(markerOptions); 
    }

    InstanceOfMap(a) {
        this.routeDevSvc.InstanceOfMap(a);
        this.mapPromise = a;
    }

    onMapCheckboxChange(isFullyLoaded: boolean = false) {
        this.onTraxxSubscription(this.traxxList, false, isFullyLoaded);
        if (!this.isItFromRouteDeviation) { return; }
        this.displayErrorMessage = false;
        if (this.routeDeviation && this.routeDeviation.ErrorCode > 0) {
            this.displayErrorMessage = true;
            this.errorMessage = this.routeDeviation.LocalizedErrorMessage;
            return false;
        }
        if (this.ShowDeviations) {
            this.updatePolyLine(true);
            if (!this.ShowRoute) {
                this.routeDevSvc.deleteDirections();
            }
        } else if (this.ShowRoute) {
            this.updatePolyLine(false);
        } else {
            this.routeDevSvc.deleteDirections();
            this.updatePolyLine(false);
        }
        if (this.routeDevSvc.isDirectionsDeleted && this.ShowRoute) {
            this.routeDevSvc.updateDirections(this.isFirst, this.routeDeviation);
            this.isDirectionsDeleted = false;
        }
    }

    updatePolyLine(showOnlyDeviations = false) {
        const callMethod = (e, m): void => { this.mapMarkerOption(e, m); };
        const polyLineMap = this.routeDevSvc.updatePolyLine(this.routeDeviation, callMethod, showOnlyDeviations, this.isFirst);
        this.markerOptions = polyLineMap.Markers;
        this.polylinePath = polyLineMap.Path;
        this.displayErrorMessage = polyLineMap.DisplayErrorMessage;
        this.errorMessage = polyLineMap.ErrorMessage;
        if (this.isFirst) {
            setTimeout(() => {
                this.box = polyLineMap.Box;
                this.isFirst = false;
            }, 1000);
        }
    }

    sensorChartClick() {
        this.sensorChartClickSubscription = this.detailSvc.sensorChartClick$.subscribe(k => {
            if (k && this.isFromSensorChart) {
                this.LastMarkerEvent = null;
                this.hideOtherInfoBoxes.emit();
                this.SelectedMarker = null;
                this.hideSensorChartInfoBoxes.emit();
                const marker: Marker[] = this.MarkerList.filter(m => {
                    const markerData = m.Metadata?.get('marker')
                    const threshold = 0.0001;
                    if (markerData) {
                        if ((markerData.Latitude - k.Latitude) <= threshold && (markerData.Latitude - k.Latitude) >= -threshold &&
                            (markerData.Longitude - k.Longitude) <= threshold && (markerData.Longitude - k.Longitude) >= -threshold) {
                            return true;
                        }
                        return false;
                    }
                    return false;
                });
                if (marker && marker.length > 0) {
                    this.SelectedMarker = marker[0].Metadata?.get('marker')
                    this.LastMarkerEvent = { Marker: marker[0], Location: marker[0].Location, Pixels: null, Markers: marker };
                    this.updateBox(marker[0].Location.latitude, marker[0].Location.longitude);
                    this.options.center = marker[0].Location;
                    setTimeout(() => {
                        const arg = {
                            Marker: marker[0],
                            Location: marker[0].Location,
                            Click: null,
                            Pixels: null,
                            Markers: marker,
                        };
                        this.MarkerShowInfoBox.emit(arg);
                    });
                }
                else {
                    const traxxModel = new TraxxInfo();
                    traxxModel.Latitude = k.Latitude;
                    traxxModel.Longitude = k.Longitude;
                    traxxModel.TrackerInfo = new TrackerStatus();
                    traxxModel.TrackerInfo.LastReportedTimestampFormatted = k.SatTimeString;
                    traxxModel.TrackerInfo.LastReportedDateRange = k.SatTimeString;
                    traxxModel.TrackerInfo.TrackerId = this.shipment.trackerId;
                    traxxModel.labels = k.labels;
                    this.openInfoBoxWithCustomData(traxxModel, true);
                }
            }
        });
    }

    onInfoBoxClose(i) {
        if (this.sensorMarkerOptions && this.sensorMarkerOptions.length > 0) {
            this.sensorMarkerOptions = [];
            // if (i) {
            //     this.box = this.intialBox;
            // }
        }
    }

    onAlertOptionChange() {
        if (this.alertOption) {
            this.checkedAlerts = this.traxxList.AlertTotals.AlertCounts.map(k => k.Type);
        } else {
            this.checkedAlerts = [];
        }
        this.onShipmentRouteSearch(this.traxxListCopy, true);
    }

    onShipmentRouteSearch(traxx, isFromSearch = false) {
        if (!isFromSearch) {
            this.isSubmitted = true;
        }
        this.reValidationForm();
        if (isFromSearch || this.shipmentRouteSearchFrom.valid) {
            const traxxData = _.cloneDeep(traxx);
            const toDate = new Date(this.ToDate);
            const to = new Date(toDate.setSeconds(59)).setMilliseconds(999);
            const from = new Date(new Date(this.FromDate).setSeconds(0)).setMilliseconds(0);
            const list = traxxData.traxx.list.filter(k => {
                if ((!(this.FromDate && this.ToDate) ||
                    (k.SatTime &&
                        this.checkDateBetweenGivenDates(k.SatTime, from, to)))) {
                    return k;
                }
            });
            if (this.FromDate && this.ToDate &&
                traxxData && traxxData.AlertTotals && traxxData.AlertTotals.AlertCounts) {
                this.traxxList.AlertTotals.AlertCounts.map(k => {
                    const data = list.filter(a => a.AlertInfo && a.AlertInfo.Type === k.Type);
                    if (data) {
                        k.Count = data.length;
                    }
                });
            } else if (this.traxxListCopy && this.traxxListCopy.AlertTotals && this.traxxListCopy.AlertTotals.AlertCounts) {
                this.traxxList.AlertTotals.AlertCounts = _.cloneDeep(this.traxxListCopy.AlertTotals.AlertCounts);
            }
            traxxData.traxx.list = list.filter(k =>
                (!k.AlertInfo || (this.alertOption && this.checkedAlerts.indexOf(k.AlertInfo.Type) >= 0)));
            this.filteredTraxxData.traxx.list = traxxData.traxx.list;
            this.onTraxxSubscription(traxxData, false, true);
        }
        if (!(new Date(this.FromDate).setSeconds(0, 0) >= new Date(this.minCrumbDate).setSeconds(0, 0) &&
            new Date(this.ToDate).setSeconds(0, 0) <= new Date(this.maxCrumbDate).setSeconds(0, 0))) {
            this.dateRangeChange.emit({ fromDate: this.FromDate, thruDate: this.ToDate });
        }
        this.updateMapHeight();
    }

    onMarkersCreated(markers: Array<Marker>) {
        this.MarkerList = markers;
        // console.log(this.MarkerList);
        // console.log(this.markerOptions);
    }

    private findMarkerByMarkerOption(markerOption: IMarkerOptions): Marker {
        if (this.MarkerList?.length > 0) {
            return this.MarkerList.find(m => m.UUID === markerOption.metadata.get("markerUUID"));
        }
        return null;
    }

    animateSpeedOptions = [{ text: label_replayTraxx_Slow, value: 40 }, { text: label_replayTraxx_Medium, value: 20 }, { text: label_replayTraxx_Fast, value: 10 }];
    onPlayRoute(speedInfo: { item: any; value: any }) {
        this.mapPromise.then(map => {
            this.markerLayerVisible = false;
            this.sensorMarkerLayerVisible = false;
            this.polylineVisible = false;
            const box = this.utilSvc.getNewBoxParams();

            const pathPointInfos = [];
            //some point of polylinePath are same
            this.polylinePath.forEach(linePoint => {
                const linePoint_MarkerOptions = this.markerOptions.filter(markerInfo => markerInfo.position.latitude === linePoint.latitude && markerInfo.position.longitude === linePoint.longitude);
                linePoint_MarkerOptions.forEach(p => {
                    if (!pathPointInfos.some(x => x.latLong.latitude === p.position.latitude && x.latLong.longitude === p.position.longitude && x.icon === p.icon)) {
                        this.utilSvc.updateBoxParams(box, p.position.latitude, p.position.longitude);
                        pathPointInfos.push({
                            latLong: p.position, icon: p.icon, priority: p.priority, anchor: p.anchor,
                            metadata: {
                                LastReportedTimestampFormatted: p.metadata.get("marker").TrackerInfo.LastReportedTimestampFormatted
                                // marker: this.findMarkerByMarkerOption(p)
                            }
                        });
                    }
                });
            });

            const pathInfo = { pointInfos: pathPointInfos, showInfoBox: false };
            this.box = box;
            this.animationMovie = this.animationSvc.GraduallyShowPath(pathInfo, 'ReplayTraxx', map, speedInfo.value);
            this.startedAnimationMovie = true;
            this.animationMovie.onStop(() => {
                setTimeout(() => {
                    this.clearAnimationMovie();
                }, 1000);
            });
        });
    }
    onPauseGoRoute() {
        if (this.animationMovie) {
            this.animationMovie.isPlaying() ? this.animationMovie.pause() : this.animationMovie.continue();
        }
    }
    onStopRoute() {
        this.animationMovie && this.animationMovie.stop()
    }

    checkDateBetweenGivenDates(inputDate, from, to) {
        if (new Date(inputDate).getTime() >= from &&
            new Date(inputDate).getTime() <= to) {
            return true;
        } else { return false; }
    }

    onShipmentRouteReset() {
        this.clearAnimationMovie();

        this.isSubmitted = false;
        const alertOption = this.alertOption;
        setTimeout(() => {
            this.onShipmentRouteSearch(this.traxxListCopy, true);
            this.alertOption = alertOption;
        });
    }

    private clearAnimationMovie() {
        this.markerLayerVisible = true;
        this.sensorMarkerLayerVisible = false;
        this.polylineVisible = true;
        this.onResetZoom();
        if (this.animationMovie) {
            this.animationMovie.isPlaying() && this.animationMovie.stop();
            this.animationMovie.clearStages();
            this.animationMovie = null;
            this.startedAnimationMovie = false;
        }
    }

    getTemplateInfo(markerMetadatas: Array<IMarkerMetadata>): TraxxInfo {
        const sourceMarker = markerMetadatas.find(p => p.isSourceMarker);
        return sourceMarker.metadata.get('marker');
    }

    onSensorMarkerCreated(markers: Array<Marker>) {
        this.SensorMarkerList = markers;
    }

    onDateChanged() {
        this.isDateRangeValid = !((this.FromDate != null && this.ToDate != null)
            && ((this.FromDate >= this.ToDate)));
    }

    private reValidationForm = () => {
        if (this.shipmentRouteSearchFrom) {
            for (const key of Object.keys(this.shipmentRouteSearchFrom.controls)) {
                this.shipmentRouteSearchFrom.controls[key].markAsPristine();
                this.shipmentRouteSearchFrom.controls[key].markAsUntouched();
                this.shipmentRouteSearchFrom.controls[key].updateValueAndValidity();
            }
        }
    }

    private setMinMaxTo90Days() {
        const today = new Date();
        let minDate = new Date();
        minDate.setDate(minDate.getDate() - this.ShowLastNDays);
        //Change threshold for displaying crumb data on XL to 90 days for other its need to be 60
        const lastReportedDate = new Date(this.Shipment.LastReportedTimestamp);
        if (lastReportedDate && !this.Shipment.isMultiTrip) {
            let days = this.ShowLastNDays;
            //If it is non XL model then days should be 60
            if (!this.traxxList?.IsXL) {
                days = 60;
            }
            // Caluculation min Last reported date based on model (For xl 90 for other 60)
            const minBasedOnLastReportedDate = new Date(lastReportedDate.setDate(lastReportedDate.getDate() - days));
            //Taking min date or Caluculated min dated which ever is lower
            if (minDate > minBasedOnLastReportedDate) {
                minDate = minBasedOnLastReportedDate;
            }

        }
        this.setMinMaxDateRange(minDate, today);
    }

    setMinMaxDateRange(minDate: Date, maxDate: Date) {
        this.minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate(), 0, 0, 0);
        this.maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 23, 59, 59);
    }

    onShowEstimatedCrumbsChange() {
        localStorage.setItem(environment.showEstimatedCrumbs, this.ShowEstimatedCrumb.toString());
        this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb, this.shipmentList);
    }

    private updateMapHeight() {
        setTimeout(() => { this.setMapHeightCall(); }, 10);
    }

    private setMapHeightCall() {
        const filterPanelHeight = this.alertFilterPanel?.nativeElement?.offsetHeight;
        if (this.parentHeight && filterPanelHeight) {
            const errorPanelHeight = this.displayErrorMessage && this.errorMessage?.length > 0 ? 40 : 0;
            this.mapHeighCalc = `calc(${this.parentHeight} - ${filterPanelHeight + errorPanelHeight}px)`;
        } else {
            this.mapHeighCalc = null;
        }
    }

    private showInitialState() {
        this.FromDate = null;
        this.ToDate = null;
        this.isSubmitted = false;
        this.displayErrorMessage = false;
        this.reValidationForm();
    }
    getListTemplateInfo(markerMetadatas: Array<IMarkerMetadata>): any {
        return markerMetadatas.map(m => {
            return m.metadata.get('marker')
        });
    }

    onBreadcrumbCountChange() {
        if (this.breadCrumbCount) {
            this.minimumClusterCount = 2;
        } else {
            this.minimumClusterCount = 25;
        }
    }

    openInfoModal() {
        this.bsModalRef = this.modalService.show(this.countInfoModal,
            {
                class: 'modal-lg modal-dialog-centered',
                ignoreBackdropClick: true
            })
    }

    private openInfoBoxWithCustomData(data: TraxxInfo, updateBox = false) {
        this.LastMarkerEvent = null;
        this.hideOtherInfoBoxes.emit();
        this.hideSensorChartInfoBoxes.emit();
        // this.markers = [];

        const metaData = new Map<string, any>();
        metaData.set('marker', data);
        metaData.set('defaultOffSet', { x: 0, y: 0 });
        const location = { latitude: data.Latitude, longitude: data.Longitude };
        this.sensorMarkerOptions = [{
            position: location,
            icon: '',   // Without ICon,so don't set pixelOffset of infobox
            metadata: metaData
        }];
        if (updateBox) {
            this.updateBox(data.Latitude, data.Longitude);
            this.options.center = location;
        }
        setTimeout(() => {
            const marker = this.SensorMarkerList[0];
            const arg = {
                Marker: marker,
                Location: marker.Location,
                Click: null,
                Pixels: null,
                Markers: [marker],
            };
            this.SensorMarkerShowInfoBox.emit(arg);
        });
    }

    public updateBox(Latitude: number, Longitude: number) {
        const minLatBoxPadding = ((this.bounds.maxLatitude - this.bounds.minLatitude) / (this.zoomIndex > 3 ? 4 : 3));
        const minLongBoxPadding = ((this.bounds.maxLongitude - this.bounds.minLongitude) / (this.zoomIndex > 3 ? 4 : 3));
        this.box = {
            maxLatitude: Latitude + minLatBoxPadding,
            maxLongitude: Longitude + minLongBoxPadding,
            minLatitude: Latitude - minLatBoxPadding,
            minLongitude: Longitude - minLongBoxPadding
        };
    }
    showIconInfo() {
        this.showPopups.showIconInfoPopup(false);
        return false;
    }

    onGeoFenceChange() {
        this.markerBoundary = [];
        this.hideOtherInfoBoxes.emit();
        this.selectedRadiusBreadCrumbId = null;
        if (this.ShowGeoFence && !this.locations?.length) {
            this.domoStateSvc.DisplayLoader();
            setTimeout(() => {
                this.locationsListSub = this.locationSvc.locationList$.pipe().subscribe((lst) => {
                    const minMaxCoOrdinates = this.findMinMaxCoOrdinates();
                    this.locations = this.locationCollisionSvc.updateLocationCollisions(lst.list, this.distanceUnits, minMaxCoOrdinates);

                    //Geo fence logic
                    this.locationBoundaries = new LocationBoundaryEntity();
                    const collisionMarkers: Array<IMarkerOptions> = new Array<IMarkerOptions>();
                    this.traxxList?.traxx?.list?.forEach(element => {
                        if (!this.showHiddenCrumbs && element.IsHidden) {
                            return false;
                        }
                        this.fetchGeoFence(element, collisionMarkers);
                    });
                    this.traxxList?.extraTraxx?.forEach(element => {
                        this.fetchGeoFence(element, collisionMarkers);
                    });

                    this.closeByMarkers = collisionMarkers;
                    this.updateVisibleBoundaries();
                    this.locationsListSub?.unsubscribe();
                    this.domoStateSvc.CancelLoader();
                });
            }, 200)
        }
    }

    findMinMaxCoOrdinates() {
        let minMaxCoOrdinates = { min: null, max: null }
        let lat, lng: number[];
        if (this.traxxListCopy?.traxx?.list?.length) {
            lat = this.traxxListCopy?.traxx?.list?.map(function (p) { return p.Latitude });
            lng = this.traxxListCopy?.traxx?.list?.map(function (p) { return p.Longitude });
        }
        if (this.traxxListCopy?.extraTraxx?.length) {
            lat = [...lat, ...this.traxxListCopy?.extraTraxx?.map(function (p) { return p.Latitude })];
            lng = [...lng, ...this.traxxListCopy?.extraTraxx?.map(function (p) { return p.Longitude })];
        }
        if (lat?.length && lng?.length) {
            minMaxCoOrdinates.min = {
                lat: Math.min.apply(null, lat) - 1,
                lng: Math.min.apply(null, lng) - 1
            }
            minMaxCoOrdinates.max = {
                lat: Math.max.apply(null, lat) + 1,
                lng: Math.max.apply(null, lng) + 1
            }
        }
        return minMaxCoOrdinates;
    }
}
