import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { CreateShipmentRequest, CloseTripRequest, SetTripSensorRangeRequest, SetShutDownTrackerRequest } from 'emr-ng-shared';
import { UpdateShipmentRequest } from 'emr-ng-shared';
import { Shipment as DBShipment } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IShipmentState } from 'app-modules/core/store/models/shipment-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';

import * as shipActions from 'app-modules/core/store/actions/shipment.actions';
import { IListInfoWithErrorResponse } from '../models/list-info-with-error-state.interface';
import { BehaviorSubject } from 'rxjs';
import { share, startWith } from 'rxjs/operators';
import { UpdateShipment } from 'app-modules/core/models/update-shipment.model';

@Injectable()
export class ShipmentStateService {
    constructor(private store: Store<IAppState>) { }

    private editTripRequests = new Array<string>();
    private setTripSensorRangeRequests = new Array<string>();

    // Selectors
    public shipmentStateSelector = ((state: IAppState) => state.shipment);

    public isLoadRequiredSelector = createSelector(
        this.shipmentStateSelector,
        (state: IShipmentState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.shipmentStateSelector,
        (state: IShipmentState) => state.isLoading
    );
    public shipmentListSelector = createSelector(
        this.shipmentStateSelector,
        (state: IShipmentState) => state.shipmentList
    );
    public shipmentListComeFromApiSelector = createSelector(
        this.shipmentStateSelector,
        (state: IShipmentState) => state.shipmentListComeFromApi
    );

    public isAutoLoadRequiredSelector = createSelector(
        this.shipmentStateSelector,
        (state: IShipmentState) => state.isAutoLoadRequired
    );
    

    // Observables
    public isAutoLoadRequired$ = this.store.select(this.isAutoLoadRequiredSelector).pipe();
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public shipmentList$ = this.store.select(this.shipmentListSelector).pipe();
    public shipmentListComeFromApi$ = this.store.select(this.shipmentListComeFromApiSelector).pipe();

    private isEditShipmentLoadingSub = new BehaviorSubject<Array<String>>([]);
    isEditShipmentLoading$ = this.isEditShipmentLoadingSub.asObservable().pipe(share());

    public closeSpinners() {
        this.editTripRequests = [];
        this.isEditShipmentLoadingSub.next(this.editTripRequests);
    }

    // Actions
    public loadShipments() {
        this.store.dispatch(new shipActions.LoadShipments());
    }

    public loadShipmentsSuccess(shipmentList: IListInfo<Shipment>) {
        this.store.dispatch(new shipActions.LoadShipmentsSuccess(shipmentList));
    }

    public loadShipmentsError(message: string) {
        this.store.dispatch(new shipActions.LoadShipmentsError(message));
    }

    public cancelLoadShipments() {
        this.store.dispatch(new shipActions.CancelLoadShipments());
    }

    public autoRefreshShipments() {
        this.store.dispatch(new shipActions.AutoRefreshShipments());
    }

    public AutoRefreshShipmentsSuccess(shipmentList: IListInfo<Shipment>) {
        this.store.dispatch(new shipActions.AutoRefreshShipmentsSuccess(shipmentList));
    }

    public AutoRefreshShipmentsError(message: string) {
        this.store.dispatch(new shipActions.AutoRefreshShipmentsError(message));
    }

    public CancelAutoRefreshShipments() {
        this.store.dispatch(new shipActions.CancelAutoRefreshShipments());
    }

    public loadShipmentAddress() {
        this.store.dispatch(new shipActions.LoadShipmentAddress());
    }

    public loadShipmentAddressSuccess(shipmentList: IListInfo<Shipment>) {
        this.store.dispatch(new shipActions.LoadShipmentAddressSuccess(shipmentList));
    }

    public loadShipmentAddressError(message: string) {
        this.store.dispatch(new shipActions.LoadShipmentAddressError(message));
    }

    public clearLoadingAddressLoader(e: any, updateShipments: Shipment[]): void {
        updateShipments.forEach(s => s.AddressGeoLoading = false);
        this.store.dispatch(new shipActions.LoadShipmentAddressError(e));
    }

    // Update ETA Loader
    public updateShipmentETA() {
        this.store.dispatch(new shipActions.UpdateShipmentETA());
    }

    public updateShipmentETASuccess(shipmentList: DBShipment) {
        this.store.dispatch(new shipActions.UpdateShipmentETASuccess(shipmentList));
    }

    public updateShipmentETAError(message: string) {
        this.store.dispatch(new shipActions.UpdateShipmentETAError(message));
    }

    public clearUpdateShipmentETALoader(e: any, updateShipments: Shipment[]): void {
        updateShipments.forEach(s => s.TripETALoading = false);
        this.store.dispatch(new shipActions.UpdateShipmentETAError(e));
    }

    public createShipment(request: CreateShipmentRequest) {
        this.store.dispatch(new shipActions.CreateShipment(request));
    }

    public createShipmentSuccess(shipmentId: number) {
        this.store.dispatch(new shipActions.CreateShipmentSuccess(shipmentId));
    }

    public createShipmentError(message: string) {
        this.store.dispatch(new shipActions.CreateShipmentError(message));
    }

    public updateShipment(request: UpdateShipmentRequest) {
        this.store.dispatch(new shipActions.UpdateShipment(request));
    }

    public updateShipmentSuccess(shipmentId: number) {
        this.store.dispatch(new shipActions.UpdateShipmentSuccess(shipmentId));
    }

    public updateShipmentError(message: string) {
        this.store.dispatch(new shipActions.UpdateShipmentError(message));
    }

    public closeShipment() {
        this.store.dispatch(new shipActions.CloseShipment());
    }

    public closeShipmentSuccess() {
        this.store.dispatch(new shipActions.CloseShipmentSuccess());
    }

    public closeShipmentError(message: string) {
        this.store.dispatch(new shipActions.CloseShipmentError(message));
    }

    public loadShipmentbyId() {
        this.store.dispatch(new shipActions.LoadShipmentbyId());
    }

    public loadShipmentbyIdSuccess(shipment: Shipment) {
        this.store.dispatch(new shipActions.LoadShipmentbyIdSuccess(shipment));
    }

    public loadShipmentbyTrackerIdSuccess(shipments: Shipment[]) {
        this.store.dispatch(new shipActions.LoadShipmentbyTrackerIdSuccess(shipments));
    }

    public loadShipmentbyIdError(message: string) {
        this.store.dispatch(new shipActions.LoadShipmentbyIdError(message));
    }

    public cancelloadShipmentbyId() {
        this.store.dispatch(new shipActions.CancelLoadShipmentbyID());
    }

    public cancelLoadShipmentBySerial(): void {
        this.store.dispatch(new shipActions.CancelLoadShipmentBySerial());
    }

    public loadShipmentBySerialError(arg0: string): void {
        this.store.dispatch(new shipActions.LoadShipmentBySerialError(arg0));
    }

    public loadShipmentBySerialSuccess(n: IListInfoWithErrorResponse<Shipment>): void {
        this.store.dispatch(new shipActions.LoadShipmentBySerialSuccess(n));
    }

    public loadShipmentBySerial(): void {
        this.store.dispatch(new shipActions.LoadShipmentBySerial());
    }

    public reloadShipments() {
        this.store.dispatch(new shipActions.ReloadShipments());
    }

    public updateShipmentSuspendResumeState(trackerId: string,tripId: number,isSuppressed : boolean) {
        this.store.dispatch(new shipActions.UpdateShipmentSuspendResumeState(trackerId,tripId, isSuppressed));
    }

    public setTripSensorRange(request: SetTripSensorRangeRequest, tripProperty: string) {
        this.setTripSensorRangeRequests = [...this.setTripSensorRangeRequests, tripProperty];
        this.isEditShipmentLoadingSub.next(this.setTripSensorRangeRequests);
        this.store.dispatch(new shipActions.SetTripSensorRange(request));
    }

    public setTripSensorRangeSuccess(tripProperty: string) {
        this.setTripSensorRangeRequests = this.setTripSensorRangeRequests.filter(s => s !== tripProperty);
        this.isEditShipmentLoadingSub.next(this.setTripSensorRangeRequests);
        this.store.dispatch(new shipActions.SetTripSensorRangeSuccess());
    }

    public setTripSensorRangeError(message: string, tripProperty: string) {
        this.setTripSensorRangeRequests = this.setTripSensorRangeRequests.filter(s => s !== tripProperty);
        this.isEditShipmentLoadingSub.next(this.setTripSensorRangeRequests);
        this.store.dispatch(new shipActions.SetTripSensorRangeError(message));
    }

    public editShipment(request: UpdateShipmentRequest, tripProperty: string) {
        this.editTripRequests = [...this.editTripRequests, tripProperty];
        this.isEditShipmentLoadingSub.next(this.editTripRequests);
        this.store.dispatch(new shipActions.EditShipment(request));
    }

    public editShipmentSuccess(shipmentId: number, tripProperty: string) {
        this.editTripRequests = this.editTripRequests.filter(s => s !== tripProperty);
        this.isEditShipmentLoadingSub.next(this.editTripRequests);
        this.store.dispatch(new shipActions.EditShipmentSuccess(shipmentId));
    }

    public editShipmentError(message: string, tripProperty: string) {
        this.editTripRequests = this.editTripRequests.filter(s => s !== tripProperty);
        this.isEditShipmentLoadingSub.next(this.editTripRequests);
        this.store.dispatch(new shipActions.EditShipmentError(message));
    }

    public updateShipmentByTrackerID(n: UpdateShipment): void {
        this.store.dispatch(new shipActions.UpdateShipmentByTrackerID(n));
    }


    public deleteShipment() {
        this.store.dispatch(new shipActions.DeleteShipment());
    }

    public deleteShipmentSuccess(deleteReq: CloseTripRequest) {
        this.store.dispatch(new shipActions.DeleteShipmentSuccess(deleteReq));
    }

    public deleteShipmentError(message: string) {
        this.store.dispatch(new shipActions.DeleteShipmentError(message));
    }


    public saveTrackerNote() {
        this.store.dispatch(new shipActions.SaveTrackerNote());
    }

    public shareTracker() {
        this.store.dispatch(new shipActions.ShareTracker());
    }

    public saveTrackerNoteSuccess() {
        this.store.dispatch(new shipActions.SaveTrackerNoteSuccess());
    }

    public shareTrackerSuccess() {
        this.store.dispatch(new shipActions.ShareTrackerSuccess());
    }

    public saveTrackerNoteError(message: string) {
        this.store.dispatch(new shipActions.SaveTrackerNoteError(message));
    }

    public shareTrackerError(message: string) {
        this.store.dispatch(new shipActions.ShareTrackerError(message));
    }

    public saveShutdownTracker() {
        this.store.dispatch(new shipActions.SaveShutdownTracker());
    }

    public saveShutdownTrackerSuccess(saveShutDownTrackerReq: SetShutDownTrackerRequest) {
        this.store.dispatch(new shipActions.SaveShutdownTrackerSuccess(saveShutDownTrackerReq));
    }

    public saveShutdownTrackerError(message: string) {
        this.store.dispatch(new shipActions.SaveShutdownTrackerError(message));
    }
}
