import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Customer } from 'emr-ng-shared';
import { environment } from 'environments/environment';

@Component ({
    selector: 'app-customer-selector',
    templateUrl: 'customer-selector.component.html',
    styleUrls: ['customer-selector.component.css']
})
export class CustomerSelectorComponent implements OnInit {
    @Input() customer: Customer;
    @Output() selected = new EventEmitter<number>();

    onSelected(customerId: number) {
        this.selected.emit(customerId);
    }

    ngOnInit() {
        localStorage.setItem(environment.selectedCustomer, null);
    }
}

