import { Action } from '@ngrx/store';

import * as actionIndex from 'app-modules/core/store/actions';

export class ChangeShipmentDetailAlertPage implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_DETAIL_ALERT_PAGE;

    constructor(public pageNumber: number) { }
}

export class ChangeShipmentDetailAlertPageSize implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_DETAIL_ALERT_PAGE_SIZE;

    constructor(public pageSize: number) { }
}
