import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../store/auth/auth.service';

@Injectable()
export class ActivityService implements OnDestroy {
    private refreshIsDelayed = false;
    private lastActivity: Date = new Date();
    private lastRefresh: Date = this.lastActivity;
    private refreshDelay = 360000;
    private isAuthenticated = false;
    private authSubcription: Subscription;
    private userTokenSubscription: Subscription;
    private interval;

    constructor(public authSvc: AuthService) {
        this.delayRefresh();
        this.authSubcription = this.authSvc.authenticated$.subscribe(n => this.isAuthenticated = n);
        this.userTokenSubscription = this.authSvc.userTokenDateExpires$.subscribe(n => {
            if(n){
                this.refreshDelay = ((new Date(n.toString())).getTime() - (new Date()).getTime());
                this.reset();
            }
        });
    }

    ngOnDestroy() {
        if (this.authSubcription) {
            this.authSubcription.unsubscribe();
        }
        if (this.userTokenSubscription) {
            this.userTokenSubscription.unsubscribe();
        }
    }

    public ack(): void {
        this.lastActivity = new Date();
        if (!this.refreshIsDelayed) {
            this.processActivity(this);
        }
    }

    public reset(): void {
        if (this.refreshIsDelayed) {
            clearTimeout(this.interval);
        }
        this.lastRefresh = new Date();
        this.delayRefresh();
    }

    public delayRefresh() {
        this.refreshIsDelayed = true;
        this.interval = setTimeout(
            () => this.processActivity(this),
            this.refreshDelay
        );
    }

    private processActivity(self: ActivityService) {
        if (!this.isAuthenticated) {
            self.reset();
            return;
        }
        if (self.lastActivity > self.lastRefresh) {
            self.authSvc.refreshToken();
            self.reset();
        } else {
            self.refreshIsDelayed = false;
        }
    }
}
