export interface IListInfo<T> {
    list: T[];
    itemCount: number;
    isPaged: boolean;
}

export function emptyList(): IListInfo<any> {
    return { list: [], itemCount: 0, isPaged: true };
}

export function setListInfo(inputList, isPage: boolean ): IListInfo<any> {
    return { list: inputList, itemCount: inputList ? inputList.length : 0 , isPaged: isPage };
}
