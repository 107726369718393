import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import {
    DeleteGeofenceRequest, GetLocationBoundaryRequest, SetLocationRequest, LocationInfo,
    LocationColor,
    SaveLocationContactRequest,
    Contact
} from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as locActions from 'app-modules/core/store/actions/location.actions';
import { ILocationColorState } from '../models/location-color-list-state.interface';
import { DeleteLocationRequest } from 'emr-ng-shared/lib/services/emr-oversight/models/delete-location-request.model';

@Injectable()
export class LocationStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public locationStateSelector = ((state: IAppState) => state.location);
    public isLoadRequiredSelector = createSelector(
        this.locationStateSelector,
        (state) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.locationStateSelector,
        (state) => state.isLoading
    );
    public locationListIncludingCopySelector = createSelector(
        this.locationStateSelector,
        (state) => state.locationList
    );
    public locationListSelector = createSelector(
        this.locationListIncludingCopySelector,
        (state) => {
            const filteredList = state.list.filter(a => !a.CopiedFromCustomerId);

            return <IListInfo<LocationInfo>>{
                list: filteredList,
                itemCount: filteredList.length,
                isPaged: false
            };
        }
    );
    // If locationList is ever paged in the database, originList must become a separate list
    public originListSelector = createSelector(
        this.locationListSelector,
        (state) => {
            const filteredList = state.list.filter(a => a.IsOrigin);

            return <IListInfo<LocationInfo>>{
                list: filteredList,
                itemCount: filteredList.length,
                isPaged: false
            };
        }
    );
    // If locationList is ever paged in the database, stopList must become a separate list
    public stopListSelector = createSelector(
        this.locationListSelector,
        (state) => {
            const filteredList = state.list.filter(a => a.IsOrigin && a.IsDestination);

            return <IListInfo<LocationInfo>>{
                list: filteredList,
                itemCount: filteredList.length,
                isPaged: false
            };
        }
    );
    // If locationList is ever paged in the database, destinationList must become a separate list
    public destinationListSelector = createSelector(
        this.locationListSelector,
        (state) => {
            const filteredList = state.list.filter(a => a.IsDestination);

            return <IListInfo<LocationInfo>>{
                list: filteredList,
                itemCount: filteredList.length,
                isPaged: false
            };
        }
    );
    public locationColorListStateSelector = ((state: IAppState) => state.locationColorList);
    public locationColorListSelector = createSelector(
        this.locationColorListStateSelector,
        (state: ILocationColorState) => state.locationColorList
    );
    public isColorLoadRequiredSelector = createSelector(
        this.locationColorListStateSelector,
        (state) => state.isLoadRequired
    );
    public isColorLoadingSelector = createSelector(
        this.locationColorListStateSelector,
        (state) => state.isLoading
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public locationList$ = this.store.select(this.locationListSelector).pipe();
    public locationListIncludingCopy$ = this.store.select(this.locationListIncludingCopySelector).pipe();
    public originList$ = this.store.select(this.originListSelector).pipe();
    public stopList$ = this.store.select(this.stopListSelector).pipe();
    public destinationList$ = this.store.select(this.destinationListSelector).pipe();
    public locationColorList$ = this.store.select(this.locationColorListSelector).pipe();
    public isColorLoadRequired$ = this.store.select(this.isColorLoadRequiredSelector).pipe();
    public isColorLoading$ = this.store.select(this.isColorLoadingSelector).pipe();

    // Actions
    public loadLocationList() {
        this.store.dispatch(new locActions.LoadLocationList());
    }

    public loadLocationListSuccess(locationList: IListInfo<LocationInfo>) {
        this.store.dispatch(new locActions.LoadLocationListSuccess(locationList));
    }

    public loadLocationListError(message: string) {
        this.store.dispatch(new locActions.LoadLocationListError(message));
    }

    public cancelLoadLocationList() {
        this.store.dispatch(new locActions.CancelLoadLocationList());
    }

    public createLocation(request: SetLocationRequest) {
        this.store.dispatch(new locActions.CreateLocation(request));
    }

    public createLocationSuccess(locationInfo: LocationInfo) {
        this.store.dispatch(new locActions.CreateLocationSuccess(locationInfo));
    }

    public reloadLocations() {
        this.store.dispatch(new locActions.ReloadLocations());
    }

    public createLocationError(message: string) {
        this.store.dispatch(new locActions.CreateLocationError(message));
    }

    public cancelCreateLocation() {
        this.store.dispatch(new locActions.CancelCreateLocation());
    }



    // Actions
    public loadLocationColorList() {
        this.store.dispatch(new locActions.LoadLocationColorList());
    }

    public loadLocationColorListSuccess(locationColorList: IListInfo<LocationColor>) {
        this.store.dispatch(new locActions.LoadLocationColorListSuccess(locationColorList));
    }

    public loadLocationColorListError(message: string) {
        this.store.dispatch(new locActions.LoadLocationColorListError(message));
    }

    public cancelLoadLocationColorList() {
        this.store.dispatch(new locActions.CancelLoadLocationColorList());
    }


    public validateLocation(request: SetLocationRequest) {
        this.store.dispatch(new locActions.ValidateLocation(request));
    }

    public validateLocationSuccess(locationInfo: LocationInfo) {
        this.store.dispatch(new locActions.ValidateLocationSuccess(locationInfo));
    }

    public validateLocationError(message: string) {
        this.store.dispatch(new locActions.ValidateLocationError(message));
    }

    public cancelValidateLocation() {
        this.store.dispatch(new locActions.CancelValidateLocation());
    }


    // Delete Geofence
    public deleteLocationGeofence(request: DeleteGeofenceRequest) {
        this.store.dispatch(new locActions.DeleteLocationGeofence(request));
    }

    public deleteGeofenceSuccess(request: DeleteGeofenceRequest) {
        this.store.dispatch(new locActions.DeleteGeofenceSuccess(request));
    }

    public deleteGeofenceError(message: string) {
        this.store.dispatch(new locActions.DeleteGeofenceError(message));
    }

    public cancelDeleteGeofence() {
        this.store.dispatch(new locActions.CancelDeleteGeofence());
    }


    // Get Location Boundary 
    public getLocationBoundary(request: GetLocationBoundaryRequest) {
        this.store.dispatch(new locActions.GetLocationBoundary(request));
    }

    public getLocationBoundarySuccess(locationInfo: LocationInfo) {
        this.store.dispatch(new locActions.GetLocationBoundarySuccess(locationInfo));
    }

    public getLocationBoundaryError(message: string) {
        this.store.dispatch(new locActions.GetLocationBoundaryError(message));
    }

    public cancelGetLocationBoundary() {
        this.store.dispatch(new locActions.CancelGetLocationBoundary());
    }

    // Save Geofence
    public saveLocationGeofence(request: SetLocationRequest) {
        this.store.dispatch(new locActions.SaveLocationGeofence(request));
    }

    public saveGeofenceSuccess(request: SetLocationRequest) {
        this.store.dispatch(new locActions.SaveGeofenceSuccess(request));
    }

    public saveGeofenceError(message: string) {
        this.store.dispatch(new locActions.SaveGeofenceError(message));
    }

    public cancelSaveGeofence() {
        this.store.dispatch(new locActions.CancelSaveGeofence());
    }

    // Location Contact

    public getLocationContact(request: SaveLocationContactRequest) {
        this.store.dispatch(new locActions.GetLocationContact(request));
    }

    public getLocationContactSuccess(locationID: number, contacts: Contact[]) {
        this.store.dispatch(new locActions.GetLocationContactSuccess(locationID, contacts));
    }

    public getLocationContactError(message: string) {
        this.store.dispatch(new locActions.GetLocationContactError(message));
    }

    public cancelGetLocationContact() {
        this.store.dispatch(new locActions.CancelGetLocationContact());
    }

    // Set Location Contact
    public setLocationContact(request: SaveLocationContactRequest) {
        this.store.dispatch(new locActions.SetLocationContact(request));
    }

    public setLocationContactSuccess(request: SaveLocationContactRequest, contact: Contact) {
        this.store.dispatch(new locActions.SetLocationContactSuccess(request, contact));
    }

    public setLocationContactError(message: string) {
        this.store.dispatch(new locActions.SetLocationContactError(message));
    }

    public cancelSetLocationContact() {
        this.store.dispatch(new locActions.CancelSetLocationContact());
    }

    public updateLocationContact(locationID: number, contact: Contact) {
        this.store.dispatch(new locActions.UpdateLocationContact(locationID, contact));
    }

    // Delete Location Contact
    public deleteLocationContact(request: SaveLocationContactRequest) {
        this.store.dispatch(new locActions.DeleteLocationContact(request));
    }

    public deleteLocationContactSuccess(request: SaveLocationContactRequest) {
        this.store.dispatch(new locActions.DeleteLocationContactSuccess(request));
    }

    public deleteLocationContactError(message: string) {
        this.store.dispatch(new locActions.DeleteLocationContactError(message));
    }

    public cancelDeleteLocationContact() {
        this.store.dispatch(new locActions.CancelDeleteLocationContact());
    }

    // Delete Location
    public deleteLocation(request: DeleteLocationRequest) {
        this.store.dispatch(new locActions.DeleteLocation(request));
    }

    public deleteLocationSuccess(request: DeleteLocationRequest) {
        this.store.dispatch(new locActions.DeleteLocationSuccess(request));
    }

    public deleteLocationError(message: string) {
        this.store.dispatch(new locActions.DeleteLocationError(message));
    }

    public cancelDeleteLocation() {
        this.store.dispatch(new locActions.CancelDeleteLocation());
    }    

    public loadMapLibrary() {
        this.store.dispatch(new locActions.LoadStaticMapLibrary());
    }    

    public loadMapLibrarySuccess() {
        this.store.dispatch(new locActions.LoadStaticMapLibrarySuccess());
    }    

    public loadMapLibraryError() {
        this.store.dispatch(new locActions.LoadStaticMapLibraryError());
    }
}

