import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GetDistanceToTargetsRequest } from 'emr-ng-shared';

@Component({
  selector: 'app-show-distance-popup',
  templateUrl: './show-distance-popup.component.html',
  styleUrls: ['./show-distance-popup.component.css']
})
export class ShowDistancePopupComponent implements OnInit {

  constructor(
    public bsShowDistanceModalRef: BsModalRef
  ) { }

  distanceToTargetsRequest: GetDistanceToTargetsRequest;

  ngOnInit() {
  }

  onPopupCancel() {
    this.bsShowDistanceModalRef.hide();
  }
}


