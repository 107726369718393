
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from 'emr-ng-shared';
import { BsModalRef} from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { ChangePasswordValueEventArgs } from '../change-password-form/change-password-form.component';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { environment } from 'environments/environment';
import { AuthStateService } from 'app-modules/core/store/auth/auth-state.service';
import { Subscription } from 'rxjs';
import { LOGGER_ERROR_STATUS_CODE } from 'app-modules/core/consts/values';

@Component({
    selector: 'app-change',
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnDestroy {
    public error: string;
    bsModalRef: BsModalRef;
    changePassSubscription: Subscription;
    constructor(
        private authSvc: AuthService,
        private authStateSvc: AuthStateService,
        private router: Router
    ) {
    }

    onChangePasswordSubmitted(changePasswordValue: ChangePasswordValueEventArgs) {
        this.error = '';
        this.changePassSubscription = this.authSvc.changePassword(changePasswordValue.password, changePasswordValue.newPassword).pipe().subscribe(
            n => {
                if (n.ErrorCode === 0 || n.ErrorCode === LOGGER_ERROR_STATUS_CODE) {
                    this.onChangePasswordResponse();
                } else {
                    this.onChangePasswordFailure(n);
                }
            },
            error => this.onChangePasswordFailure(error)
        );
    }

    ngOnDestroy(){
        this.changePassSubscription?.unsubscribe();
    }

    private onChangePasswordResponse() {
        const localStorageData = JSON.parse(localStorage.getItem(environment.authPersistentData));
        if (localStorageData) {
            this.authStateSvc.signInSuccess(
                localStorageData.AccessToken,
                localStorageData.RefreshToken,
                localStorageData.username,
                localStorageData.username,
                localStorageData.TemperatureUnits,
                localStorageData.DistanceUnits,
                localStorageData.DateFormatString, // Should be replaced with DateFormatEnum
                'MM/dd/yyyy',
                localStorageData.IsEmersonAdmin,
                localStorageData.CanCreateShipment,
                localStorageData.rememberMe,
                localStorageData.ProviderUserKey,
                false,
                localStorageData.CanAccessOversight,
                localStorageData.CanAccessPakSense,
                localStorageData.CanEditTripName,
                localStorageData.IsLocusUserSupportAdmin,
                localStorageData.IsLocusUser,
                localStorageData.IsDashboardUser,
                localStorageData.IsAdminUser,
                localStorageData.IsEDIAPIConsumer,
                localStorageData.IsLocusSupport);
            localStorageData.IsPasswordChangeRequired = false;
            localStorage.setItem(environment.authPersistentData, JSON.stringify(localStorageData));
        }
        this.router.navigate(['selectcustomer']);
    }

    private onChangePasswordFailure(error: ErrorResponse) {
        this.error = error.LocalizedErrorMessage;
    }
}
