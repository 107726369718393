<emr-modal id="btnFrmCreateShipment" *ngIf="bsMainModalRef" class="scrollable-modal" headerTitle="Create Shipment"
    i18n-headerTitle="@@link_createshipment" [bsModalRef]=bsMainModalRef>
    <ng-container modal-body *ngIf="bsMainModalRef; then formModal">
    </ng-container>
</emr-modal>
<ng-container *ngIf="bsMainModalRef; else formModal">
</ng-container>
<ng-template #addContactModal>
    <emr-modal headerTitle="Add Contact" i18n-headerTitle="@@label_add_contact" [bsModalRef]=bsModalRef>
        <app-emr-contact modal-body (AddContact)="onContactAddComplete($event)"></app-emr-contact>
    </emr-modal>
</ng-template>

<ng-template #formModal>
    <form name="frmCreateShipment" #shipmentForm="ngForm" emrForm autocomplete="off">
        <div class="bg-data-div_CreateShipment mx-auto {{ bsMainModalRef ? '' : ' bg-no-modal' }}">
            <div class="row">
                <div class="col-md-12 vmargin-sm">
                    <!-- <div class="row">
                        <div class="col-md-4 col-12 roboto-regular-font font-18 color-regular font-weight-bold">
                            <p>
                                <span>{{locLoadRequired|async}}</span>
                            </p>
                        </div>
                    </div> -->
                    <div *ngIf="!bsMainModalRef" class="row">
                        <div class="col-12 roboto-regular-font font-20 color-regular">
                            <p class="col-md-4 px-0">
                                <span i18n="@@label_create_shipment_form_header">Create a Shipment</span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 font-15" *ngIf="!this.isTrackerLoadRequired && !this.noValidTrackers">
                            <div class="font-weight-bold">
                                <p class="offset-md-3 col-md-9" style="color:red;font-size: 13px;"
                                    i18n="@@No_Valid_Trackers">
                                    No valid trackers are available for creating new trip.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="(linkedCustomersPushList$ | async)?.length > 0">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown identifier="lstLinkedCustomers" name="lstLinkedCustomers"
                                    [options]="linkedCustomersPushList$ | async" [(ngModel)]="LinkedCustomerIdPush"
                                    cssClass="default-dropdown" label="Share With" i18n-label="@@label_share_with"
                                    helpTip="Allows you to make a copy of the serial number and shipment information in another company's site."
                                    i18n-helpTip="@@label_share_with_description" [validationIndicatorRequired]=true
                                    placeholder="Select Customer" i18n-placeholder="@@label_select_customer"
                                    [inline]=true labelColumns="3" controlColumns="{{controlColumns}}"
                                    [showAlternateText]="isEndLocationUndefined"
                                    i18n-alternateLabel="@@label_inbound_outbound" alternateLabel="Inbound/Outbound"
                                    i18n-alternateHelpTip="@@label_inbound_outbound_description"
                                    alternateHelpTip="Use the Inbound/Outbound feature."
                                    (onChange)="linkedCustomerPushChanged($event)" optionsValueField="CustomerId"
                                    optionsTextField="Description"
                                    [setDisabled]="disableDVPush || isBusinessRuleLoading || isLinkedCustomerUpdating || (isFormLoading$ | async)"
                                    [required]="IsShareWithRequired && !LinkedCustomerIdPull"
                                    [displayValidationIndicator]="IsShareWithRequired">
                                    <emr-validation-message validatorName="required"
                                        message="Share With Customer Required"
                                        i18n-message="@@share_with_customer_required">
                                    </emr-validation-message>
                                </emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row" *ngIf="(linkedCustomersPullList$ | async)?.length > 0">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown identifier="lstLinkedCustomersPull" name="lstLinkedCustomersPull"
                                    [options]="linkedCustomersPullList$ | async" [(ngModel)]="LinkedCustomerIdPull"
                                    cssClass="default-dropdown" label="Copy From" i18n-label="@@label_copy_from"
                                    helpTip="Allows you to use a tracker serial number that is assigned to another company. Please note the customer can still use this serial number and assign their own shipment information in their site."
                                    i18n-helpTip="@@label_copy_from_description" [validationIndicatorRequired]=true
                                    placeholder="Select Customer" i18n-placeholder="@@label_select_customer"
                                    [inline]=true labelColumns="3" controlColumns="{{controlColumns}}"
                                    (onChange)="linkedCustomerPullChanged($event)" optionsValueField="CustomerId"
                                    optionsTextField="Description" [setDisabled]="disableDVPull"></emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row mb-1" *ngIf="!disableDVPush&&!disableDVPull&&EndLocationUndefinedBR">
                        <div class="col-md-2 offset-md-3 pr-0">
                            <emr-checkbox ngDefaultControl identifier="chkInboundOutbound" name="chkInboundOutbound"
                                text="Inbound/Outbound" i18n-text="@@label_inbound_outbound"
                                [(ngModel)]="shipment.IsDCModelTrip">
                            </emr-checkbox>
                        </div>
                        <div class="col-md-7 pl-0">
                            <emr-button identifier="inbound_outbound_description" buttonType="button"
                                i18n-helpTip="@@inbound_outbound_description" helpTipPosition="right"
                                helpTip="Select if you would like the tracker to continue reporting from distribution center to restaurant"
                                cssClass="btn-outline-default font-weight-bolder filter-route-height ml-2 mt-0"
                                buttonIcon="fa fa-info" (onClick)="openInfoModal()">
                            </emr-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 create-shipment">
                            <p>
                                <emr-typeahead *ngIf="!disableDVPush" ngDefaultControl identifier="txtSerial"
                                    name="txtSerial" [dataSource]="serialOptionList$" label="Enter Serial Number"
                                    i18n-label="@@label_enter_serial_number" placeholder="Last 4 Digits"
                                    i18n-placeholder="@@label_last_4_digits" [(ngModel)]="serialNumber"
                                    helpTip="Tracker Serial Number" (paste)="onNumberPaste($event)"
                                    (ngModelChange)="onSearchInputChange()" (keydown.space)="$event.preventDefault();"
                                    i18n-helpTip="@@label_tracker_serial_number" required [inline]="true" labelColumns=3
                                    controlColumns="{{controlColumns}}" typeaheadOptionFieldName="trackerId"
                                    typeaheadGroupFieldName="groupName" (ItemSelected)="onSerialNumberSelected($event)"
                                    (Blur)="onSerialNumberChange($event)" (NoResults)="NoResultsForSerialNumber($event)"
                                    i18n-Invalid_Tracker_Selected="@@Invalid_Tracker_Selected" [typeaheadAsync]="true"
                                    Invalid_Tracker_Selected="Please select Locus Serial from the suggested Serial Numbers List."
                                    [InValidResults]="isInValidSerialNumber" [isLoading]="isTrackerLoading$ | async">
                                    <emr-validation-message validatorName="required"
                                        message="Locus Traxx Serial # Required" i18n-message="@@serial_required">
                                    </emr-validation-message>
                                </emr-typeahead>

                                <emr-textbox *ngIf="disableDVPush" ngDefaultControl identifier="txtSerialPull"
                                    name="txtSerialPull" label="Enter Serial Number"
                                    i18n-label="@@label_enter_serial_number" placeholder="Serial Number"
                                    i18n-placeholder="@@label_serial_number" [(ngModel)]="serialNumberPull"
                                    helpTip="Tracker Serial Number" #dvPull=ngModel
                                    (keydown.space)="$event.preventDefault();"
                                    (onBlur)="onSerialNumberPullChange($event)" (paste)="onNumberPaste($event)"
                                    i18n-helpTip="@@label_tracker_serial_number" required [inline]=true labelColumns=3
                                    textBoxColumns="{{controlColumns}}" [EmrInvalidValidator]="!isInValidSerialNumber">
                                    <emr-validation-message validatorName="required"
                                        message="Locus Traxx Serial # Required" i18n-message="@@serial_required">
                                    </emr-validation-message>
                                    <emr-validation-message validatorName="EmrInvalidValidator"
                                        message="Please enter a valid serial number"
                                        i18n-message="@@invalid_serial_number">
                                    </emr-validation-message>
                                </emr-textbox>
                            </p>
                        </div>
                        <div class="offset-md-7 col-md-5">
                            <p class="col-md-12 pr-0 custom-validator mt-9"
                                *ngIf="serialNumber&&isMultiTripInValidSerialNumber"
                                i18n="@@multitrip_invalid_selected_tracker">
                                This tracker is currently assigned to a trip. Please close the current trip if you would
                                like to use this tracker on a new trip.
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown ngDefaultControl identifier="lstShipmentTemplate"
                                    name="lstShipmentTemplate" [options]="(templateList$  | async)?.list"
                                    [(ngModel)]="shipment.TripTemplateID" [inline]=true labelColumns="3"
                                    controlColumns="{{controlColumns}}" cssClass="default-dropdown"
                                    label="Shipment Template" i18n-label="@@label_shipment_template"
                                    i18n-helpTip="@@label_shipment_template" helpTip="Shipment Template"
                                    i18n-placeholder="@@label_select_shipment_template"
                                    placeholder="Select Shipment Template" [validationIndicatorRequired]=true
                                    optionsValueField="TripTemplateId" optionsTextField="Name"
                                    (onChange)="ShipmentTemplateChange($event)" [enableSearchFilter]=true
                                    SearchPlaceHolder="Search Shipment Template"
                                    i18n-SearchPlaceHolder="@@search_shipment_template"
                                    [isLoading]="isTemplatesListLoading$ | async"></emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row" *ngIf="DisplayVehicleGroupingInCreateShipment">
                        <div class="col-md-12">
                            <p>
                                <emr-multiselect-dropdown identifier="lstVehicleGroup" name="lstVehicleGroup"
                                    [options]="VehicleGroups" label="Vehicle Group" i18n-label="@@label_vehicle_group"
                                    [(ngModel)]="vehicleGroupIds" placeholder="Select Vehicle Group"
                                    i18n-placeholder="@@label_select_vehicle_group" helpTip="Vehicle Group"
                                    i18n-helpTip="@@label_vehicle_group" [inline]=true labelColumns=3
                                    controlColumns={{controlColumns}} optionsValueField="VehicleGroupId"
                                    optionsTextField="Name" selectAllText="Select All" i18n-selectAllText="@@select_all"
                                    unSelectAllText="UnSelect All" i18n-unSelectAllText="@@unselect_all"
                                    searchPlaceholderText="Search" i18n-searchPlaceholderText="@@search"
                                    noDataAvailablePlaceholderText="No data available"
                                    i18n-noDataAvailablePlaceholderText="@@no_data_available">
                                </emr-multiselect-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row" *ngIf="DVReceiverPONumberVisible">
                        <div class="col-md-12">
                            <p>
                                <emr-textbox ngDefaultControl identifier="txtReceiverPO" name="txtReceiverPO"
                                    required="{{!isRequired}}" label="ReceiverPO" i18n-label="@@label_receiver_po"
                                    placeholder="PO#" i18n-placeholder="@@label_po_#"
                                    [(ngModel)]="shipment.ReceiverPONum" helpTip="ReceiverPO" [minlength]="2"
                                    i18n-helpTip="@@label_receiver_po" [inline]=true labelColumns=3
                                    textBoxColumns="{{controlColumns}}">
                                    <emr-validation-message validatorName="required" i18n-message="@@po_required"
                                        message="PO # Required"></emr-validation-message>
                                    <emr-validation-message validatorName="minlength"
                                        i18n-message="@@receiver_po_min_length"
                                        message="Receiver PO must be min 2 characters">
                                    </emr-validation-message>
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="!isBusinessRuleLoading">
                            <p>
                                <emr-textbox ngDefaultControl identifier="txtShipmentName" name="txtShipmentName"
                                    label="{{labelEnterShipmentName}}" placeholder="{{labelShipmentName}}"
                                    [(ngModel)]="shipment.ShipmentTransferName" helpTip="{{labelShipmentName}}"
                                    required="{{!isRequired}}" [inline]=true labelColumns=3
                                    textBoxColumns="{{controlColumns}}">
                                    <emr-validation-message validatorName="required"
                                        message="{{labelShipmentNameRequired}}"></emr-validation-message>
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="this.isVisibleFieldPlateNum">
                            <p>
                                <emr-textbox ngDefaultControl identifier="txtPlateNumber" name="txtPlateNumber"
                                    label="{{labelPlateNumber}}" placeholder="{{labelPlateNumber}}"
                                    [(ngModel)]="shipment.PlateNum" helpTip="{{labelPlateNumber}}" [inline]=true
                                    labelColumns=3 textBoxColumns="{{controlColumns}}" maxLength="20">
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="this.isVisibleFieldContainerNum">
                            <p>
                                <emr-textbox ngDefaultControl identifier="txtContainerNumber" name="txtContainerNumber"
                                    label="{{labelContainerNumber}}" placeholder="{{labelContainerNumber}}"
                                    [(ngModel)]="shipment.ContainerNum" helpTip="{{labelContainerNumber}}" [inline]=true
                                    labelColumns=3 textBoxColumns="{{controlColumns}}" maxLength="50">
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" *ngIf="this.isVisibleFieldDriverPhoneNum">
                            <p>
                                <emr-textbox ngDefaultControl identifier="txtDriverPhoneNumber"
                                    name="txtDriverPhoneNumber" label="{{labelDriverPhoneNumber}}"
                                    placeholder="{{labelDriverPhoneNumber}}" [(ngModel)]="shipment.DriverPhoneNum"
                                    helpTip="{{labelDriverPhoneNumber}}" [inline]=true labelColumns=3
                                    textBoxColumns="{{controlColumns}}" maxLength="20">
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <div class="row section-header">
                        <div class="col-md-12 roboto-regular-font font-16 color-regular">
                            <p>
                                <span i18n="@@label_origin">Origin</span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown ngDefaultControl identifier="lstOriginLocations"
                                    name="lstOriginLocations" [options]="originLocations$ | async"
                                    [(ngModel)]="shipment.OriginLocationId" cssClass="default-dropdown" label="Location"
                                    i18n-label="@@label_location" helpTip="Origin Location Name"
                                    required="{{!isRequired}}" [inline]=true i18n-helpTip="@@label_origin_location_name"
                                    [showPlaceHolder]=false placeholder="Select / Create Location"
                                    [displayValidationIndicator]="!isRequired"
                                    i18n-placeholder="@@label_select_create_location" [inline]=true labelColumns=3
                                    controlColumns="{{controlColumns}}" [createNewItem]=true
                                    createNewItemText="Create New Location"
                                    i18n-createNewItemText="@@label_create_new_location"
                                    (CreateNewItemClick)="openAddLocationModal('origin')"
                                    [validationIndicatorRequired]=true [enableSearchFilter]=true
                                    SearchPlaceHolder="Search Location"
                                    [EmrCompareTwoValuesValidator]="shipment.DestinationLocationId"
                                    [showDropDownCreateToolTip]="isEndLocationUndefined || isCreateOptionsDisbabled"
                                    disabledCreateHelpTip="Cannot create a new when creating a shared shipment"
                                    i18n-disabledCreateHelpTip="@@label_createaddress_disabled_dualvis"
                                    (onChange)="selectDistinctLocation($event,'origin')"
                                    i18n-SearchPlaceHolder="@@label_search_location"
                                    [EmrSecondCompareValues]="child?.selectedStopsLocations"
                                    [isLoading]="isLocationListLoading$ | async">
                                    <emr-validation-message validatorName="required"
                                        message="Start Location Name  Required"
                                        i18n-message="@@start_location_name_required"></emr-validation-message>
                                    <emr-validation-message validatorName="EmrCompareTwoValuesValidator"
                                        i18n-message="@@select_distinct_origin_destination"
                                        message="Origin and Destination can not be the same">
                                    </emr-validation-message>
                                    <emr-validation-message validatorName="EmrSecondCompareValuesValidator"
                                        i18n-message="@@select_distinct_origin_destination_from_stop_locat"
                                        message="Trip Stops location can not be used as Origin/Destination location">
                                    </emr-validation-message>
                                </emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                <emr-datetimepicker ngDefaultControl name="txtStartDate" identifier="txtStartDate"
                                    [(ngModel)]="startTime" [minDate]="defaultMinTime" label="Scheduled Start Time"
                                    [displayValidationIndicator]="!isRequired" required="{{!isRequired}}" [inline]=true
                                    i18n-label="@@scheduled_start_time" [showPickerOnTop]="true"
                                    helpTip="Scheduled Start Time" i18n-helpTip="@@scheduled_start_time"
                                    i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM" [inline]=true labelColumns=3
                                    controlColumns="{{controlColumns}}" [containerValue]="''"
                                    [dateTimeObject]=dateTimeObject>
                                    <emr-validation-message validatorName="required"
                                        message="Departure Date/Time Required"
                                        i18n-message="@@departure_date_time_required"></emr-validation-message>
                                </emr-datetimepicker>
                            </p>
                        </div>
                    </div>
                    <div class="row" *ngIf="DisplayTripStartOptions">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown identifier="lstStartOptions" name="lstStartOptions"
                                    [options]="tripStartOptionsList$ | async" optionsValueField="TripStartOptionID"
                                    optionsTextField="Description" [(ngModel)]="shipment.TripStartOptionID"
                                    cssClass="default-dropdown" label="Start Option" i18n-label="@@label_start_option"
                                    [inline]=true labelColumns=3 controlColumns="{{controlColumns}}"
                                    helpTip="The start option determines how a shipment starts, whether based on location (default) or an estimated start time."
                                    i18n-helpTip="@@tooltip_start_option" [validationIndicatorRequired]=true
                                    [isLoading]="isTripStartOptionsLoading$ | async">
                                </emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row" [collapse]="isOriginContactCollapsed">
                        <div class="col-md-12 section-item">
                            <label class="label-css cursor-pointer"
                                (click)="openAddContactModal(addContactModal, 'origin')">
                                <p>
                                    <u>
                                        <b>+
                                            <span i18n="@@label_add_origin_contact">Add Origin Contact</span>
                                        </b>
                                    </u>
                                </p>
                            </label>
                        </div>
                        <div class="col-md-12 section-item">
                            <app-contact-list [contactList]="originContactList"></app-contact-list>
                        </div>
                    </div>
                    <div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <span i18n="@@label_additional_stops">Additional Stops</span>
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <app-stop-location-list [locations]=stopLocations
                                    [isEndLocationUndefined]="isEndLocationUndefined || isCreateOptionsDisbabled"
                                    [(tripStops)]="stops" [Shipment]="shipment" label="Airport Departure"
                                    i18n-label="@@label_airport_departure"
                                    [selectedOD]="shipment.OriginLocationId+','+shipment.DestinationLocationId"
                                    (openAddLocationModal)="openAddLocationModal($event)"></app-stop-location-list>
                            </div>
                        </div>
                    </div>
                    <div class="row section-header">
                        <div class="col-md-12 roboto-regular-font font-16 color-regular">
                            <p>
                                <span i18n="@@label_destination"> Destination</span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="showNoDestinationRequired()">
                        <div class="col-md-12">
                            <emr-checkbox ngDefaultControl identifier="chkNoDestinationRequired"
                                (change)="noDestinationSelected()" name="chkNoDestinationRequired"
                                text="No Destination Required" i18n-text="@@label_no_destination_required"
                                [(ngModel)]="shipment.NoDestinationRequired">
                            </emr-checkbox>
                        </div>
                    </div>
                    <div *ngIf="!(this.shipment.NoDestinationRequired || this.shipment.EndLocationUndefined)">
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstDestinationLocations"
                                        name="lstDestinationLocations" [options]="destLocations$ | async"
                                        [(ngModel)]="shipment.DestinationLocationId" cssClass="default-dropdown"
                                        label="Location" required="{{!isRequired}}" [inline]=true
                                        i18n-label="@@label_location" helpTip="Destination Location Name"
                                        i18n-helpTip="@@label_destination_location_name" [showPlaceHolder]=false
                                        placeholder="Select / Create Location"
                                        [displayValidationIndicator]="!isRequired"
                                        [showDropDownCreateToolTip]="isEndLocationUndefined || isCreateOptionsDisbabled"
                                        disabledCreateHelpTip="Cannot create a new when creating a shared shipment"
                                        i18n-disabledCreateHelpTip="@@label_createaddress_disabled_dualvis"
                                        i18n-placeholder="@@label_select_create_location" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [createNewItem]=true
                                        createNewItemText="Create New Location"
                                        i18n-createNewItemText="@@label_create_new_location"
                                        (CreateNewItemClick)="openAddLocationModal('destination')"
                                        [validationIndicatorRequired]=true [enableSearchFilter]=true
                                        SearchPlaceHolder="Search Location"
                                        [EmrCompareTwoValuesValidator]="shipment.OriginLocationId"
                                        i18n-SearchPlaceHolder="@@label_search_location"
                                        (onChange)="selectDistinctLocation($event,'destination')"
                                        [EmrSecondCompareValues]="child?.selectedStopsLocations"
                                        [isLoading]="isLocationListLoading$ | async">
                                        <emr-validation-message validatorName="required"
                                            message="Destination Location Name  Required"
                                            i18n-message="@@destination_location_name_required">
                                        </emr-validation-message>
                                        <emr-validation-message validatorName="EmrCompareTwoValuesValidator"
                                            i18n-message="@@select_distinct_origin_destination"
                                            message="Origin and Destination can not be the same">
                                        </emr-validation-message>
                                        <emr-validation-message validatorName="EmrSecondCompareValuesValidator"
                                            i18n-message="@@select_distinct_origin_destination_from_stop_locat"
                                            message="Trip Stops location can not be used as Origin/Destination location">
                                        </emr-validation-message>
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-datetimepicker ngDefaultControl name="txtEndDate" identifier="txtEndDate"
                                        [EmrMinDateValidator]="startTime" [(ngModel)]="endTime"
                                        [displayValidationIndicator]="!isRequired" label="Scheduled End Time"
                                        required="{{!isRequired}}" [inline]=true [dateTimeObject]=dateTimeObject
                                        i18n-label="@@scheduled_end_time"
                                        [minDate]="startTime ? startTime : defaultMinTime" [showPickerOnTop]="true"
                                        helpTip="Scheduled End Time" i18n-helpTip="@@scheduled_end_time"
                                        i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM" [inline]=true
                                        labelColumns=3 controlColumns="{{controlColumns}}" [containerValue]="''">
                                        <emr-validation-message validatorName="required"
                                            message="Arrival Date/Time Required"
                                            i18n-message="@@arrival_date_time_required"></emr-validation-message>
                                        <emr-validation-message validatorName="EmrMinDateValidator"
                                            i18n-message="@@greater_end_time"
                                            message="Scheduled End time must be greater than Start time">
                                        </emr-validation-message>
                                    </emr-datetimepicker>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="DisplayTripEndOptions">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown identifier="lstEndOptions" name="lstEndOptions"
                                        [options]="tripEndOptionsList$ | async" optionsValueField="TripEndOptionID"
                                        optionsTextField="Description" [(ngModel)]="shipment.TripEndOptionID"
                                        cssClass="default-dropdown" label="End Option" i18n-label="@@label_end_option"
                                        [inline]=true labelColumns=3 controlColumns="{{controlColumns}}"
                                        helpTip="The end option determines how a shipment completes, whether based on location (default) or an estimated end time."
                                        i18n-helpTip="@@tooltip_end_option" [validationIndicatorRequired]=true
                                        [isLoading]="isTripEndOptionsLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" [collapse]="isDestinationContactCollapsed">
                            <div class="col-md-12">
                                <label class="label-css cursor-pointer"
                                    (click)="openAddContactModal(addContactModal, 'destination')">
                                    <p>
                                        <u>
                                            <b>+
                                                <span i18n="@@label_add_destination_contact">Add Destination Contact
                                                </span>
                                            </b>
                                        </u>
                                    </p>
                                </label>
                            </div>
                            <div class="col-md-12">
                                <app-contact-list [contactList]="destinationContactList"></app-contact-list>
                            </div>
                        </div>
                    </div>
                    <div class="row section-header">
                        <div class="col-md-12 roboto-regular-font font-16 color-regular">
                            <p>
                                <span i18n="@@label_sensor_ranges">Sensor Ranges</span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div *ngIf="sensorRangeList$ | async as sensors">
                        <div class="row" *ngIf="!shipment.NoTemperatureRequired">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstSensorRange"
                                        name="lstSensorRange" [options]="sensors.Temperature"
                                        [(ngModel)]="TemperatureRangeId" cssClass="default-dropdown"
                                        [displayValidationIndicator]="!isRequired" label="Temperature Range"
                                        i18n-label="@@label_temperature_range" optionsValueField="SensorRangeId"
                                        optionsTextField="Description" helpTip="Temperature Range"
                                        i18n-helpTip="@@label_temperature_range" placeholder="Select Temperature Range"
                                        i18n-placeholder="@@select_temperature_range" required="{{!isRequired}}"
                                        [inline]=true labelColumns=3 controlColumns="{{controlColumns}}"
                                        [validationIndicatorRequired]=true [createNewItem]=true
                                        createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                        disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                        i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Temperature)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        <emr-validation-message validatorName="required"
                                            message="Temperature Range Required"
                                            i18n-message="@@temperature_range_required"></emr-validation-message>
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <emr-checkbox ngDefaultControl identifier="chkNoTemperatureRequired"
                                    name="chkNoTemperatureRequired" text="No Temperature Information Required."
                                    i18n-text="@@label_no_temperature_required"
                                    (change)="onTemperatureRequiredChkChange();"
                                    [(ngModel)]="shipment.NoTemperatureRequired"></emr-checkbox>
                            </div>
                        </div>
                        <div class="row" *ngIf="showHumiditySensor">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstHumiditySensorRange"
                                        name="lstHumiditySensorRange" [options]="sensors.Humidity"
                                        [(ngModel)]="HumiditySensorId" cssClass="default-dropdown" label="Humidity"
                                        i18n-label="@@label_humidity" optionsValueField="SensorRangeId"
                                        optionsTextField="Description" helpTip="Humidity"
                                        i18n-helpTip="@@label_humidity" placeholder="Select Humidity"
                                        i18n-placeholder="@@select_humidity" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [createNewItem]=true
                                        [validationIndicatorRequired]=true createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                        disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                        i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Humidity)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="showCO2Sensor">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstCO2SensorRange"
                                        name="lstCO2SensorRange" [options]="sensors.CarbonDioxide"
                                        [(ngModel)]="CarbonDioxideSensorId" cssClass="default-dropdown" label="CO2"
                                        i18n-label="@@label_carbondioxide" optionsValueField="SensorRangeId"
                                        optionsTextField="Description" helpTip="CO2"
                                        i18n-helpTip="@@label_carbondioxide" placeholder="Select CO2"
                                        i18n-placeholder="@@select_co2" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [createNewItem]=true
                                        [validationIndicatorRequired]=true createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                        disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                        i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.CarbonDioxide)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="showProbSensor">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstProbSensorRange"
                                        name="lstProbSensorRange" [options]="sensors.ProbeTemp"
                                        [(ngModel)]="ProbSensorId" cssClass="default-dropdown" label="Prob Temp"
                                        i18n-label="@@label_probetemp" optionsValueField="SensorRangeId"
                                        optionsTextField="Description" helpTip="Prob Temp"
                                        i18n-helpTip="@@label_probetemp" placeholder="Select Prob Temp"
                                        i18n-placeholder="@@select_probtemp" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [createNewItem]=true
                                        [validationIndicatorRequired]=true createNewItemText="Create New Sensor Range"
                                        i18n-createNewItemText="@@create_new_sensor_range"
                                        [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                        disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                        i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                        (CreateNewItemClick)="openAddSensorRangeModal(sensorType.ProbeTemp)"
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!isMultiTripSerial" class="row mt-1">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown ngDefaultControl identifier="lstCoolDownType"
                                    name="lstCoolDownType" [options]="coolDownTypeList$ | async"
                                    [(ngModel)]="shipment.CooldownTypeCode" cssClass="default-dropdown"
                                    label="Cool Down" i18n-label="@@label_cool_down" helpTip="Cool Down"
                                    i18n-helpTip="@@label_cool_down" placeholder="Select Cool Down"
                                    i18n-placeholder="@@label_select_cool_down" [inline]=true labelColumns=3
                                    controlColumns="{{controlColumns}}" [validationIndicatorRequired]=true
                                    optionsValueField="CooldownTypeCode" optionsTextField="Description"
                                    [isLoading]="isCoolDownLoading$ | async">
                                </emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row section-header">
                        <div class="col-md-12 roboto-regular-font font-16 color-regular"
                            (click)="isAlertContactCollapsed = !isAlertContactCollapsed">
                            <p>
                                <span i18n="@@label_additional_alert_contacts_optional">Additional Alert Contacts
                                    (OPTIONAL)
                                </span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p *ngIf="!IsCarrierDropDown;else CarrierDropdown">
                                <emr-textbox ngDefaultControl identifier="txtCarrierName" name="txtCarrierName"
                                    label="Carrier Name" required="{{IsCarrierFieldRequired}}"
                                    i18n-label="@@label_carrier_name" placeholder="Carrier Name"
                                    [minlength]="IsCarrierFieldRequired ? 2 : 0" i18n-placeholder="@@label_carrier_name"
                                    [(ngModel)]="shipment.CarrierName" name="txtCarrierName" helpTip="Carrier Name"
                                    i18n-helpTip="@@label_carrier_name" [inline]=true labelColumns=3
                                    textBoxColumns="{{controlColumns}}">
                                    <emr-validation-message validatorName="required"
                                        i18n-message="@@carrier_name_required" message="Carrier name required">
                                    </emr-validation-message>
                                    <emr-validation-message validatorName="minlength"
                                        i18n-message="@@carrier_min_length"
                                        message="Carrier value must be min 2 characters">
                                    </emr-validation-message>
                                </emr-textbox>
                            </p>
                            <ng-template #CarrierDropdown>
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstCarrierName"
                                        name="lstCarrierName" [options]="carrierList" [(ngModel)]="shipment.CarrierName"
                                        cssClass="default-dropdown"
                                        [displayValidationIndicator]="IsCarrierFieldRequired" label="Carrier Name"
                                        i18n-label="@@label_carrier_name" optionsValueField="Value"
                                        optionsTextField="Value" helpTip="Carrier Name"
                                        i18n-helpTip="@@label_carrier_name" placeholder="Select Carrier Name"
                                        i18n-placeholder="@@select_carrier_name" required="{{IsCarrierFieldRequired}}"
                                        [inline]=true labelColumns=3 controlColumns="{{controlColumns}}"
                                        [validationIndicatorRequired]=true [createNewItem]=false
                                        [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async"
                                        validationCustomClass="col-lg-5 col-md-5 mlp-5">
                                        <emr-validation-message validatorName="required"
                                            i18n-message="@@carrier_name_required" message="Carrier name required">
                                        </emr-validation-message>
                                    </emr-custom-dropdown>
                                </p>
                                <div class="col-md-6">
                                    <emr-button identifier="managecarrier"
                                        cssClass="bg-primary btn manage-carrier-button" buttonIcon="fa fa-pencil"
                                        container="body" (click)="onManageCarrierData()">
                                    </emr-button>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row" [ngClass]="{'margin-carrier-top':IsCarrierDropDown}">
                        <div class="col-md-12">
                            <p>
                                <emr-textbox ngDefaultControl identifier="txtCarrierEmails" EmrEmailValidator
                                    name="txtCarrierEmails" label="Carrier Email(s)" i18n-label="@@label_carrier_emails"
                                    placeholder="Carrier Email(s)" i18n-placeholder="@@label_carrier_emails"
                                    [(ngModel)]="shipment.EmailAddresses" name="txtCarrierEmails"
                                    helpText="To add multiple emails, type email addresses separated with commas."
                                    i18n-helpText="@@label_carrier_email_help_text" helpTip="Carrier Email(s)"
                                    i18n-helpTip="@@label_carrier_emails" [inline]=true labelColumns=3
                                    textBoxColumns="{{controlColumns}}">
                                    <emr-validation-message validatorName="EmrEmailValidator"
                                        i18n-message="@@validation_email" message="Please enter valid Email	">
                                    </emr-validation-message>
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <label class="label-css col-md-3" for="chklstEscalationLevels">
                            <span class="cursor-pointer" i18n-tooltip="@@label_alert_escalation_levels"
                                tooltip="Alert Escalation Levels" placement="top"
                                i18n="@@label_alert_escalation_levels">
                                Alert Escalation Levels</span>
                        </label>
                        <div class="col-md-9">
                            <div class="row mlr-0">
                                <div class="col-md-2">
                                    <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_1"
                                        name="chklstEscalationLevels_1" text="Level 1"
                                        i18n-text="@@AlertEscalationLevel_1" inline=true [(ngModel)]="shipment.L1">
                                    </emr-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_2"
                                        name="chklstEscalationLevels_2" text="Level 2"
                                        i18n-text="@@AlertEscalationLevel_2" inline=true [(ngModel)]="shipment.L2">
                                    </emr-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_3"
                                        name="chklstEscalationLevels_3" text="Level 3"
                                        i18n-text="@@AlertEscalationLevel_3" inline=true [(ngModel)]="shipment.L3">
                                    </emr-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_4"
                                        name="chklstEscalationLevels_4" text="Level 4"
                                        i18n-text="@@AlertEscalationLevel_4" inline=true [(ngModel)]="shipment.L4">
                                    </emr-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_5"
                                        name="chklstEscalationLevels_5" text="Level 5"
                                        i18n-text="@@AlertEscalationLevel_5" inline=true [(ngModel)]="shipment.L5">
                                    </emr-checkbox>
                                </div>
                                <div class="col-md-2">
                                    <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_6"
                                        name="chklstEscalationLevels_6" text="Level 6"
                                        i18n-text="@@AlertEscalationLevel_6" inline=true [(ngModel)]="shipment.L6">
                                    </emr-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown ngDefaultControl identifier="lstTimeZone" name="lstTimeZone"
                                    [options]="(timeZoneList$ | async).list" [(ngModel)]="shipment.AlertTimeZone"
                                    cssClass="default-dropdown" label="Alert Time Zone"
                                    i18n-label="@@label_alert_time_zone" optionsValueField="TimeZoneId"
                                    optionsTextField="FullName" helpTip="Alert Time Zone"
                                    i18n-helpTip="@@label_alert_time_zone" placeholder="Select Time Zone"
                                    i18n-placeholder="@@label_select_time_zone" [inline]=true labelColumns=3
                                    controlColumns="{{controlColumns}}" [enableSearchFilter]=true
                                    SearchPlaceHolder="Search Time Zone" i18n-SearchPlaceHolder="@@search_time_zone"
                                    [isLoading]="isTimeZoneLoading$ | async">
                                </emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row section-header" *ngIf="!shipment.TripTemplateID">
                        <div class="col-md-12 roboto-regular-font font-16 color-regular shipment-template-header">
                            <p>
                                <span i18n="@@label_shipment_template">Shipment Template</span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div class="row" *ngIf="!shipment.TripTemplateID">
                        <div class="col-md-12">
                            <p *ngIf="isCreateOptionsDisbabled">
                                <span class="height-auto label-css"
                                    i18n="@@label_createtemplate_disabled_dualvis">Please contact
                                    cargoaccountmanagement@copeland.com if you would like to add a new template.</span>
                            </p>
                            <p class="shipment-template-name" *ngIf="!isCreateOptionsDisbabled">
                                <emr-checkbox ngDefaultControl identifier="chkShipmentTemplateName"
                                    [(ngModel)]="shipment.SaveTripTemplate" i18n-text="@@save_shipment_template"
                                    name="chkShipmentTemplateName" [setDisabled]="isCreateOptionsDisbabled"
                                    text="Would you like to add this shipment to your Shipment Templates?">
                                </emr-checkbox>
                            </p>
                        </div>
                    </div>
                    <div class="row" *ngIf="shipment.SaveTripTemplate && !shipment.TripTemplateID">
                        <div class="col-md-12">
                            <p>
                                <emr-textbox identifier="txtShipmentTemplateName" label="Shipment Template Name"
                                    [setDisabled]="!shipment.SaveTripTemplate" i18n-label="@@shipment_template_name"
                                    i18n-placeholder="@@shipment_template_name" i18n-helpTip="@@shipment_template_name"
                                    required placeholder="Shipment Template Name"
                                    [(ngModel)]="shipment.TripTemplateName" name="txtShipmentTemplateName"
                                    helpTip="Shipment Template Name" [inline]=true labelColumns=3
                                    textBoxColumns="{{controlColumns}}">
                                    <emr-validation-message validatorName="required"
                                        message="Shipment Template Name Required"
                                        i18n-message="@@shipment_template_name_required"></emr-validation-message>
                                </emr-textbox>
                            </p>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p>
                                <emr-button identifier="createShipment" buttonType="submit" buttonText="Submit"
                                    i18n-buttonText="@@link_submit" cssClass="btn-outline-default submit-btn"
                                    [setDisabled]="disableSubmitButton" (onClick)="onCreateShipmentClose()"
                                    buttonIcon="fa fa-check"></emr-button>
                                <!-- <emr-button buttonType="submit" buttonText="Submit + Create New" i18n-buttonText="@@link_submit_create_new" cssClass="btn-outline-default"
                                    (onClick)="onCreateShipmentReset()"></emr-button> -->
                                <emr-button identifier="resetShipment" buttonType="reset" buttonText="Reset"
                                    i18n-buttonText="@@link_reset" cssClass="btn-outline-default reset-btn"
                                    (onClick)="ResetForm()" buttonIcon="fa fa-repeat"> </emr-button>
                                <!-- <emr-button buttonType="submit" buttonText="CAUSE ERROR!" cssClass="btn-outline-default" (onClick)="onCauseError()"></emr-button>
                                <app-error-message [error]="errorInfo"></app-error-message> -->
                            </p>
                        </div>
                    </div>
                    <div class="row section-item">
                        <!-- <div class="col-md-12">
                            <p>&nbsp;</p>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>
<ng-template #confirmModal>
    <emr-modal headerTitle="Warning" i18n-headerTitle="@@Common_TypeLists_AlertSeverity_Warning" [bsModalRef]=bsModalRef
        [showCloseButton]=false>
        <ng-container modal-body>
            <div class="row">
                <div class="col-md-12">
                    <p>
                        <span>{{warningMessage}}</span>
                        <span i18n="@@confirm_proceed">Do you still want to proceed?</span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <p>
                        <emr-button identifier="confirmShipment" buttonText="Yes" i18n-buttonText="@@label_yes"
                            cssClass="btn-outline-default" (onClick)="confirmAccept(processValidateTrip)"></emr-button>
                        <emr-button identifier="cancelShipment" buttonText="No" i18n-buttonText="@@label_no"
                            cssClass="btn-outline-default" (onClick)="confirmDecline()"></emr-button>
                    </p>
                </div>
            </div>
        </ng-container>
    </emr-modal>
</ng-template>

<ng-template #inboundOutboundModal>
    <emr-modal id="inboundOutboundModal" *ngIf="bsModalRef" class="scrollable-modal" headerTitle="Inbound/Outbound"
        i18n-headerTitle="@@label_inbound_outbound" [bsModalRef]=bsModalRef>
        <ng-container modal-body>
            <div class="col-md-12 modal-body-message">
                <div class="row my-2">
                    <div class="col-md-12">
                        <div i18n="@@inbound_outbound_description">
                            Select if you would like the tracker to continue reporting from distribution center to
                            restaurant
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </emr-modal>
</ng-template>

<ng-template #sharewithModal>
    <emr-modal headerTitle="Create Shipment" i18n-headerTitle="@@link_createshipment" [bsModalRef]=bsModalRef>
        <div modal-body>
            <div class="m-1 p-1">
                <!-- <div *ngIf="hasError; else noError">
                <p>{{ errorMessage }}</p>
            </div>
            <div *ngIf="hasError && debugErrorMessage">
                <hr>
                <p>{{ debugErrorMessage }}</p>
            </div> -->
                <div>
                    <p i18n="@@message_shipment_share_with_Brule">The selected shared with customer does not support
                        optional fields</p>
                </div>
            </div>
            <div class="col-12 text-center">
                <emr-button identifier="shipmentResponseOk"
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2" buttonType="button"
                    buttonText="Ok" i18n-buttonText="@@link_ok" (click)='onOkClick()'></emr-button>
            </div>
        </div>
    </emr-modal>
</ng-template>

<ng-template #manageCarrierModal>
    <emr-modal headerTitle="Manage Carriers" i18n-headerTitle="@@link_manage_carriers" [bsModalRef]=bsCarrierModalRef>
        <div modal-body>
            <div class="row mx-0 mb-2">
                <div class="col-md-12 mb-3">
                    <div class="pr-3 pt-0 pl-3">
                        <ul class="list-group carrier-list">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                <form class="form-inline" class="form-inline" #createCarrierForm="ngForm" emrForm
                                    autocomplete="off">
                                    <div class="form-group">
                                        <label for="txtAddCarrierName" class="col-form-label mr-2"
                                            i18n="@@label_carrier_name">Carrier
                                            Name</label>
                                        <input type="text" class="form form-control mr-2" id="txtAddCarrierName"
                                            #txtNewCarrierName [(ngModel)]="newCarrier" name="txtAddCarrierName"
                                            value="" helpTip="Carrier Name" i18n-helpTip="@@label_carrier_name">
                                    </div>
                                    <div class="d-inline-block save-icons">
                                        <emr-button identifier="addnewcarrier" buttonType="button"
                                            cssClass="bg-primary mr-2 rounded-button-carrier text-white"
                                            buttonIcon="fa fa-plus" container="body"
                                            [setDisabled]="!createCarrierForm.valid" (click)="addCarrier()">
                                        </emr-button>
                                        <emr-button #resetButtonCarrierForm identifier="resetnewcarrier"
                                            cssClass="bg-danger rounded-button-carrier text-white"
                                            buttonIcon="fa fa-close" container="body"
                                            (click)="createCarrierForm.reset()">
                                        </emr-button>
                                    </div>
                                </form>
                            </li>
                        </ul>
                        <div *ngIf="errorMessage" class="error-message pt-2 text-danger">{{ errorMessage }}</div>
                    </div>
                    <div class="px-3 pt-2 mb-3 carrier-list-group" *ngIf="carrierList.length > 0; else noCarrier">
                        <ul class="list-group carrier-list">
                            <li class="list-group-item d-flex justify-content-between align-items-center"
                                *ngFor="let carrier of carrierList; let i = index">
                                <span *ngIf="editedCarrierId !== carrier.Id; else editCarrier">
                                    {{ carrier.Value }}
                                    <div class="d-inline-block save-icons">
                                        <emr-button identifier="editcarrier" *ngIf="isAdminUser"
                                            cssClass="bg-primary mr-2 rounded-button-carrier text-white"
                                            buttonIcon="fa fa-pencil" container="body"
                                            (click)="startEditing(carrier.Id, carrier.Value)">
                                        </emr-button>
                                        <emr-button identifier="removecarrier"
                                            cssClass="bg-danger rounded-button-carrier text-white"
                                            buttonIcon="fa fa-trash" container="body"
                                            (click)="removeCarrier(carrier.Id)">
                                        </emr-button>
                                    </div>
                                </span>
                                <ng-template #editCarrier>
                                    <form class="form-inline" #editCarrierForm="ngForm" emrForm autocomplete="off"
                                        (ngSubmit)="updateCarrier(carrier.Id)">
                                        <div class="form-group">
                                            <input type="text" class="form form-control mr-2" id="txteditCarrierName"
                                                [(ngModel)]="carrier.Value" name="txteditCarrierName"
                                                value="carrier.Value" required="true" helpTip="Carrier Name"
                                                i18n-helpTip="@@label_carrier_name">
                                        </div>
                                        <div class="d-inline-block save-icons">
                                            <emr-button identifier="updatenewcarrier" buttonType="submit"
                                                cssClass="bg-primary mr-2 rounded-button-carrier text-white"
                                                buttonIcon="fa fa-check" container="body"
                                                [setDisabled]="!editCarrierForm.valid">
                                            </emr-button>
                                            <emr-button identifier="cleareditingcarrier"
                                                cssClass="bg-danger rounded-button-carrier text-white"
                                                buttonIcon="fa fa-close" container="body"
                                                (click)="stopEditing(carrier.Id)">
                                            </emr-button>
                                        </div>
                                    </form>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <ng-template #noCarrier>
                        <div class="p-3 font-italic">No Carrier Records Found</div>
                    </ng-template>
                </div>
                <div class="col-md-12 text-center">
                    <emr-button identifier="managecarrierclose" buttonType="button" buttonText="Cancel"
                        i18n-buttonText="@@label_cancel" (click)='onManageCarrierClose();'
                        cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                    </emr-button>
                </div>
            </div>

        </div>
    </emr-modal>
</ng-template>