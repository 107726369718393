import { Injectable, Injector, ReflectiveInjector, Inject, Optional } from '@angular/core';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';


import { arLocale, bgLocale, caLocale, csLocale, daLocale, deLocale, enGbLocale, esDoLocale, esLocale, esUsLocale, etLocale, fiLocale, frLocale, glLocale, heLocale, hiLocale, huLocale, idLocale, itLocale, jaLocale, koLocale, ltLocale, mnLocale, nbLocale, nlBeLocale, nlLocale, plLocale, ptBrLocale, roLocale, ruLocale, skLocale, slLocale, svLocale, thLocale, trLocale, zhCnLocale,viLocale } from 'ngx-bootstrap/locale';

@Injectable()
export class ngxlocalization {
    constructor(public bss: BsLocaleService) {
    }
    UpdateLocale(key) {
        switch (key) {
            case "zh":
                defineLocale('zh', zhCnLocale);
                this.bss.use('zh');
                break;
            case "pt":
                defineLocale(key, ptBrLocale);
                this.bss.use(key);
                break;
            case "es":
                defineLocale(key, esLocale);
                this.bss.use(key);
                break;
            case "it":
                defineLocale(key, itLocale);
                this.bss.use(key);
                break;
            case "ja":
                defineLocale(key, jaLocale);
                this.bss.use(key);
                break;
            case "de":
                defineLocale(key, deLocale);
                this.bss.use(key);
                break;
            case "tr":
                defineLocale(key, trLocale);
                this.bss.use(key);
                break;
            case "fr":
                defineLocale(key, frLocale);
                this.bss.use(key);
                break;
            case "ko":
                defineLocale(key, koLocale);
                this.bss.use(key);
                break;
            case "ru":
                defineLocale(key, ruLocale);
                this.bss.use(key);
                break;
            case "pl":
                defineLocale(key, plLocale);
                this.bss.use(key);
                break;
            case "vi":
                defineLocale(key, viLocale);
                this.bss.use(key);
                break;
            case "en":
                break;
            default:
                defineLocale(key, esLocale);
                this.bss.use(key);
                break;
        }
    }
}