import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export class SensorChartResponseOkEventArgs {
  modalRef: BsModalRef;
}

@Component({
  selector: 'app-sensor-chart-response',
  templateUrl: './sensor-chart-response.component.html',
  styleUrls: ['./sensor-chart-response.component.css']
})
export class SensorChartResponseComponent {

  @Output() ok = new EventEmitter<SensorChartResponseOkEventArgs>();

    errorMessage: string = null;

    constructor(public bsModalRef: BsModalRef) {}

    onOkClick() {
      const args = new SensorChartResponseOkEventArgs();
      args.modalRef = this.bsModalRef;
      this.ok.emit(args);
      this.bsModalRef.hide();
    }

}
