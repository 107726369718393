<div class="tagDetails d-flex my-3">
    <div class="col-3">
        <p class="header-css"><span i18n="@@SSCC">sssc</span>:&emsp;<span class="color-header">{{read?.sscc}}</span></p>
    </div>
    <div class="col-3">
        <p class="header-css"><span i18n="@@PO">PO</span>:&emsp;<span class="color-header">{{read?.poNumber}}</span></p>
    </div>
    <div class="col-3">
        <p class="header-css"><span i18n="@@StartTime">StartTime</span>:&emsp;<span
                class="color-header">{{read?.startTime|dateTime:dateFormat}}</span></p>
    </div>
    <div class="col-3">
        <p class="header-css"><span i18n="@@UploadTime">UploadTime</span>:&emsp;<span
                class="color-header">{{read?.uploadTime|dateTime:dateFormat}}</span></p>
    </div>
</div>
<div class="tagGrid">
    <ejs-treegrid [dataSource]='readTags' enableVirtualization="false" childMapping='previousTags'
        enableCollapseAll="true" [allowSorting]="true" allowTextWrap='true'>
        <e-columns>
            <e-column field='qualityCode' [allowSorting]="false" headerText='' textAlign='Left' width='100'>
                <ng-template id="qualityCode" #template let-row>
                    <img width="25" src="{{'../../assets/' + getQualityCodeStatusImage(row?.qualityCode)}}" />
                </ng-template>
            </e-column>
            <e-column field='serialNumber' i18n-headerText="@@SerialNumber" [headerText]="'SerialNumber'"
                textAlign='Left' width='160'></e-column>
            <e-column field='qualityCodeStatusDisplay' i18n-headerText="@@Status" [headerText]="'Status'"
                textAlign='Left' width='380'>
                <ng-template id="qualityCodeStatusDisplay" #template let-row>
                    <span>{{row.qualityCodeStatusDisplay}}</span>
                </ng-template>
            </e-column>
            <e-column field='readTime' i18n-headerText="@@ReadTime" [headerText]="'ReadTime'" textAlign='Left'
                width='140'>
                <ng-template id="readTime" #template let-row>
                    <span>{{row.readTime|dateTime:dateFormat}}</span>
                </ng-template>
            </e-column>
            <e-column field='readDeviceSerialNumber' [allowSorting]="false" i18n-headerText="@@SerialNumber"
                [headerText]="'LoggerSN'" textAlign='Left' width='140'>
                <ng-template id="readDeviceSerialNumber" #template let-row>
                    <span>{{row.readDeviceSerialNumber}}</span>
                </ng-template>
            </e-column>
            <e-column></e-column>
        </e-columns>
    </ejs-treegrid>
</div>