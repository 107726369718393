import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CoreModule } from 'app-modules/core/core.module';
import { CreateShipmentRoutingModule } from './create-shipment-routing.module';
import { CreateShipmentComponent } from './components/create-shipment/create-shipment.component';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { CreateShipmentResponseComponent } from './components/create-shipment-response/create-shipment-response.component';
import { EditShipmentComponent } from './components/edit-shipment/edit-shipment.component';
import { EditShipmentResponseComponent } from './components/edit-shipment-response/edit-shipment-response.component';

@NgModule({
  imports: [
    CommonModule,
    CreateShipmentRoutingModule,
    EmrNgControlsModule,
    CoreModule,
    CollapseModule.forRoot(),
    ButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot()
  ],
  declarations: [CreateShipmentComponent, CreateShipmentResponseComponent, EditShipmentComponent, EditShipmentResponseComponent],
  exports: [
    CreateShipmentComponent, CreateShipmentResponseComponent, EditShipmentComponent, EditShipmentResponseComponent
  ]
  // entryComponents: [
  //   CreateShipmentComponent
  // ]
})
export class CreateShipmentModule { }

