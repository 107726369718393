import { Injectable } from '@angular/core';
import { tap, switchMap, share, map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { GetHistoryTripRequest, GetHistoryTripResponse } from 'emr-ng-shared';
 
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';

@Injectable()
export class ViewLogService {
    
    constructor(
        private oversightSvc: OversightApiService, private detailStateSvc: ShipmentDetailStateService
    ) { }

    public GetHistoryTrip(getHistoryTripRequest: GetHistoryTripRequest): Observable<GetHistoryTripResponse> {
        return of(getHistoryTripRequest).pipe(
            tap(n => this.detailStateSvc.loadDistance()),
            switchMap(n => this.GetHistoryTripDetails(n)),
            tap(
                n => {
                    this.detailStateSvc.loadDistancetSuccess();
                },
                e => {
                    this.detailStateSvc.loadDistanceError();
                }
            ),
            share()
        );
    }

    GetHistoryTripDetails(getHistoryTripRequest: GetHistoryTripRequest) {
        return this.oversightSvc.GetHistoryTrip(getHistoryTripRequest).pipe(
            map(n => n),
            catchError(error => [])
        );
    }
}


