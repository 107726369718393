import { Injectable } from '@angular/core';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { IListInfoWithErrorResponse } from 'app-modules/core/store/models/list-info-with-error-state.interface';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class DCEditShipmentService {
    constructor(private shipSvc: ShipmentService) { }

    getShipmentDetails(serialNumber: string): Observable<IListInfoWithErrorResponse<Shipment>> {
        return this.shipSvc.GetDCShipmentBySerial(serialNumber);
    }

}
