<emr-modal class="scrollable-modal" autocomplete="off" [bsModalRef]=bsModalRef>
    <ng-container modal-header>
        <span class="col-md-12 w-100 modal-title modal-header-title" *ngIf="!isEditLocation"
            i18n="@@label_add_location"> Add Location</span>
        <span class="col-md-12 modal-title modal-header-title" *ngIf="isEditLocation" i18n="@@label_edit_location">Edit
            Location</span>
    </ng-container>
    <ng-container modal-body>
        <div class="col-12 {{ isFromManageLocations ? ' location-map-View' : ''}} ">
            <div class="row locationCss">
                <div class="{{ isFromManageLocations ? 'edit-loc col-5 px-0' : ' col-md-12'}}">
                    <ng-container *ngIf="!displayChangeAddress && !setGeofence">
                        <form modal-body name="frmCreateLocation" #locationForm="ngForm" emrForm autocomplete="off"
                            [hidden]="shownearestLocations" class="{{isFromManageLocations? 'edit-form' : ' '}}">
                            <div class="row">
                                <div
                                    class="col-md-12 {{ isFromManageLocations ? (error ? ' edit-loc-fields-error' : ' edit-loc-fields') : ' px-0 overflow-x-hide '}} ">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <emr-textbox identifier="txtLocationName" ngDefaultControl
                                                label="Location Name" i18n-label="@@label_location_name"
                                                [(ngModel)]="request.Name" name="txtLocationName" required
                                                placeholder="Location Name" emrAutoUniqueName="true"
                                                i18n-placeholder="@@label_location_name" labelCssClass="col-12">
                                                <emr-validation-message validatorName="required"
                                                    message="Location Name Required"
                                                    i18n-message="@@location_name_required"></emr-validation-message>
                                            </emr-textbox>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="!isEditLocation">
                                        <div class="col-md-12">
                                            <emr-checkbox *ngIf='inChina' identifier="chkNonChinaAddressRequired"
                                                name="chkNonChinaAddressRequired" (onClick)="checkChinaAddress()"
                                                class="check-box-css" text="Non China Address"
                                                i18n-text="@@label_non_china_address">
                                            </emr-checkbox>
                                            <app-address-search identifier="locationAddressSearch"
                                                name="locationAddressSearch" label="Address Search"
                                                i18n-label="@@label_address_search" placeholder="Address Search"
                                                i18n-placeholder="@@label_address_search" appendIcon="fa fa-search"
                                                [isChinaAddress]="isChinaAddress" formGroupCssClass="col-12"
                                                (addressSelected)="onAddressSelected($event)">
                                            </app-address-search>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="{{ isFromManageLocations ? isEditLocation ? 'col-8 pr-0' : 'col-7 pr-0': 'col-md-12' }}">
                                            <emr-textbox ngDefaultControl identifier="txtLocationAddress1"
                                                label="Address Line 1" i18n-label="@@label_address_line1"
                                                labelCssClass="col-12" name="txtLocationAddress1"
                                                [(ngModel)]="request.Address1" placeholder="Address Line 1"
                                                emrAutoUniqueName="true"
                                                i18n-placeholder="@@label_address_line1"></emr-textbox>
                                        </div>
                                        <div *ngIf="isEditLocation" class="col-4 pl-0">
                                            <div class="col-12 pl-0">
                                                <emr-button identifier="btnChangeAddress" buttonText="Change Address"
                                                    i18n-buttonText="@@label_change_address"
                                                    cssClass="btn btn-link btn-change-add w-100 px-1"
                                                    (onClick)="onChangeAddressClick()" buttonIcon="fa fa-pencil">
                                                </emr-button>
                                            </div>
                                        </div>
                                        <div *ngIf="isFromManageLocations && !isEditLocation" class="col-5 pl-0">
                                            <div class="col-12 pl-0">
                                                <emr-button identifier="btnFindAddress"
                                                    buttonText="Find Address by Coordinates"
                                                    i18n-buttonText="@@label_find_address_coords"
                                                    cssClass="btn btn-link btn-change-add w-100 px-1"
                                                    (onClick)="onChangeAddressClick()" buttonIcon="fa fa-pencil">
                                                </emr-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <emr-textbox ngDefaultControl identifier="txtLocationCity" label="City"
                                                i18n-label="@@label_location_city" [(ngModel)]="request.City"
                                                name="txtLocationCity" placeholder="City" emrAutoUniqueName="true"
                                                i18n-placeholder="@@label_location_city" required
                                                labelCssClass="col-12">
                                                <emr-validation-message validatorName="required"
                                                    message="City Name Required" i18n-message="@@city_name_required">
                                                </emr-validation-message>
                                            </emr-textbox>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 pr-0">
                                            <emr-textbox ngDefaultControl identifier="txtLocationState" label="State"
                                                i18n-label="@@label_location_state" [(ngModel)]="request.State"
                                                name="txtLocationState" placeholder="State" required
                                                emrAutoUniqueName="true" i18n-placeholder="@@label_location_state"
                                                labelCssClass="col-12">
                                                <emr-validation-message validatorName="required"
                                                    message="State Name Required" i18n-message="@@state_name_required">
                                                </emr-validation-message>
                                            </emr-textbox>
                                        </div>
                                        <div class="col-md-4 px-0">
                                            <emr-textbox ngDefaultControl identifier="txtLocationAddressPostalCode"
                                                label="Postal Code" i18n-label="@@label_location_postalcode"
                                                [(ngModel)]="request.ZipCode" name="txtLocationAddressPostalCode"
                                                emrAutoUniqueName="true" labelCssClass="col-12"
                                                placeholder="Postal Code"
                                                i18n-placeholder="@@label_location_postalcode"></emr-textbox>
                                        </div>
                                        <div class="col-md-4 pl-0">
                                            <emr-textbox ngDefaultControl identifier="txtCountry" label="Country"
                                                i18n-label="@@label_location_country" [(ngModel)]="request.Country"
                                                name="txtCountry" placeholder="Country" emrAutoUniqueName="true"
                                                i18n-placeholder="@@label_location_country" labelCssClass="col-12">
                                            </emr-textbox>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="isFromManageLocations">
                                        <div class="col-md-4 pr-0">
                                            <emr-textbox ngDefaultControl inputType=number identifier="txtLocLat"
                                                label="Latitude" i18n-label="@@sensor_report_header_latitude"
                                                [(ngModel)]="request.Latitude" name="txtLocLat" required
                                                labelCssClass="mb-0 col-12" [setDisabled]=true>
                                                <emr-validation-message validatorName="required"
                                                    message="Latitude Required"></emr-validation-message>
                                            </emr-textbox>
                                        </div>
                                        <div class="col-md-4 px-0">
                                            <emr-textbox ngDefaultControl inputType=number identifier="txtLocLong"
                                                label="Longitude" i18n-label="@@sensor_report_header_longitude"
                                                [(ngModel)]="request.Longitude" name="txtLocLong" required
                                                labelCssClass="mb-0 col-12" [setDisabled]=true>
                                                <emr-validation-message validatorName="required"
                                                    message="Longitude Required"></emr-validation-message>
                                            </emr-textbox>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <div class="col-12 pl-0">
                                                <emr-button identifier="btnSetGeofence" buttonText="Set Geofence"
                                                    i18n-buttonText="@@label_set_geoence"
                                                    [setDisabled]="IsLocationValid()"
                                                    cssClass="btn btn-link btn-change-add w-100 px-1"
                                                    (onClick)="onSetGeoFenceClick()" buttonIcon="fa fa-pencil">
                                                </emr-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 pr-0">
                                            <emr-custom-dropdown ngDefaultControl identifier="lstLocationColor"
                                                name="lstLocationColor" [options]="locationColorList$ | async"
                                                [(ngModel)]="request.ColorId" cssClass="default-dropdown"
                                                label="Location Color" [isDropup]="true"
                                                i18n-label="@@label_location_color" placeholder="Select Color"
                                                optionsValueField="ColorId" optionsTextField="Description"
                                                i18n-placeholder="@@label_select_color" [isColorDropdown]="true"
                                                labelCssClass="col-12" [enableSearchFilter]=true
                                                SearchPlaceHolder="Search" i18n-SearchPlaceHolder="@@search">
                                            </emr-custom-dropdown>
                                        </div>
                                        <div class="col-md-6 pl-0">
                                            <emr-custom-dropdown ngDefaultControl identifier="lstLocationType"
                                                name="lstLocationType" [options]="locationTypes"
                                                [(ngModel)]="locationType" cssClass="default-dropdown"
                                                label="Location Category" required [isDropup]="true"
                                                i18n-label="@@label_location_category"
                                                placeholder="Select Location Category"
                                                i18n-placeholder="@@label_select_location_category"
                                                (onChange)="onLocationTypeChange()" labelCssClass="col-12">
                                                <emr-validation-message validatorName="required"
                                                    message="Location Category is required"
                                                    i18n-message="@@message_location_category_required">
                                                </emr-validation-message>
                                            </emr-custom-dropdown>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="DisplayLocationTypeBusinessRule && request.IsDestination;else elseLocationTypeBlock">
                                        <div class="{{isFromManageLocations?'col-md-12':'col-md-6 pr-0'}}">
                                            <emr-custom-dropdown ngDefaultControl identifier="ddlLocationTypeCode"
                                                name="ddlLocationTypeCode" [options]="locationTypeCodes"
                                                [(ngModel)]="request.TypeCode" cssClass="default-dropdown"
                                                label="Location Type" i18n-label="@@label_location_type"
                                                placeholder="Select" i18n-placeholder="@@lable_select"
                                                labelCssClass="col-12" [isDropup]="!isFromManageLocations" required>
                                                <emr-validation-message validatorName="required"
                                                    message="Location Type is required"
                                                    i18n-message="@@message_location_type_required">
                                                </emr-validation-message>
                                            </emr-custom-dropdown>
                                        </div>
                                    </div>
                                    <ng-template #elseLocationTypeBlock>
                                        <ng-container *ngIf="isFromManageLocations">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <emr-custom-dropdown ngDefaultControl identifier="ddlLocationTypeCode"
                                                        name="ddlLocationTypeCode" [options]="locationTypeCodes"
                                                        [(ngModel)]="request.TypeCode" cssClass="default-dropdown"
                                                        label="Location Type" i18n-label="@@label_location_type"
                                                        placeholder="Select" i18n-placeholder="@@lable_select"
                                                        labelCssClass="col-12">
                                                    </emr-custom-dropdown>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                    <!-- Edit Location Fields -->
                                    <ng-container *ngIf="isFromManageLocations">
                                        <div *ngIf="isLocusAdmin" class="row">
                                            <div class="col-md-6 pr-0">
                                                <!-- <emr-textbox ngDefaultControl identifier="txtShipperCustomer" label="Shippers Oversight ID"
                                                    i18n-label="@@label_shipper_oversight" [(ngModel)]="request.ShipperCustomerID" name="txtShipperCustomer"
                                                    labelCssClass="mb-0 col-12">
                                                </emr-textbox> -->
                                                <emr-custom-dropdown ngDefaultControl identifier="lstLinkedShippers"
                                                    name="lstLinkedShippers" [options]="linkedShippersPushList$ | async"
                                                    [(ngModel)]="request.ShipperCustomerID" cssClass="default-dropdown"
                                                    label="Shippers Oversight ID" i18n-label="@@label_shipper_oversight"
                                                    [validationIndicatorRequired]=true placeholder="Select"
                                                    i18n-placeholder="@@lable_select"
                                                    optionsValueField="ShipperCustomerID" optionsTextField="Description"
                                                    labelCssClass="col-12"
                                                    [enableSearchFilter]="(linkedShippersPushList$ | async)?.length >25"
                                                    SearchPlaceHolder="Search" i18n-SearchPlaceHolder="@@search">
                                                </emr-custom-dropdown>
                                            </div>
                                            <div class="col-md-6 pl-0">
                                                <emr-textbox ngDefaultControl identifier="txtMinTrackerInventory"
                                                    label="Minimum Tracker Inventory"
                                                    i18n-label="@@label_minimum_tracker_inventory"
                                                    [(ngModel)]="request.MinTrackerInventory"
                                                    name="txtMinTrackerInventory" labelCssClass="mb-0 col-12">
                                                </emr-textbox>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <emr-checkbox ngDefaultControl identifier="chkSuppressAlerts"
                                                    name="chkSuppressAlerts" formGroupCssClass="col-12"
                                                    text="Suppress alert for vehicles at this location"
                                                    i18n-text="@@suppress_alerts_at_location"
                                                    [(ngModel)]="request.SuppressAlerts">
                                                </emr-checkbox>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <emr-checkbox ngDefaultControl identifier="chkAuthorized"
                                                    name="chkAuthorized" text="Authorized"
                                                    [setDisabled]="!isLocusUserSupportAdmin"
                                                    i18n-text="@@label_authorized" [(ngModel)]="request.Authorized"
                                                    formGroupCssClass="col-12">
                                                </emr-checkbox>
                                            </div>
                                            <div class="col-md-6">
                                                <emr-checkbox ngDefaultControl identifier="chkResetAlertsUponEntry"
                                                    name="chkResetAlertsUponEntry" text="Reset Alerts Upon Entry"
                                                    i18n-text="@@reset_alerts_upon_entry" formGroupCssClass="col-12"
                                                    [(ngModel)]="request.ResetAlertsUponEntry">
                                                </emr-checkbox>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <emr-checkbox ngDefaultControl identifier="chkLocationAlerts"
                                                    name="chkLocationAlerts" text="Location Alerts"
                                                    i18n-text="@@label_location_alerts" formGroupCssClass="col-12"
                                                    [(ngModel)]="request.LocationAlerts">
                                                </emr-checkbox>
                                            </div>
                                            <div class="col-md-6">
                                                <emr-checkbox ngDefaultControl identifier="chkShipTo" name="chkShipTo"
                                                    text="Ship-To?" i18n-text="@@label_ship_to"
                                                    formGroupCssClass="col-12" [(ngModel)]="request.IsShipTo">
                                                </emr-checkbox>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <emr-button identifier="add_location" buttonType="submit"
                                        *ngIf="!request?.LocationId"
                                        cssClass="btn-lg btn-primary quick-link btn-block m-0 my-2"
                                        buttonText="Add Location" i18n-buttonText="@@label_add_location"
                                        buttonIcon="fa fa-Plus" (onClick)="onAddLocation()">
                                    </emr-button>
                                    <emr-button identifier="edit_location" buttonType="submit" *ngIf="isEditLocation"
                                        cssClass="btn-lg btn-primary quick-link btn-block m-0 mt-2" buttonText="Save"
                                        i18n-buttonText="@@link_save" (onClick)="onAddLocation()">
                                    </emr-button>
                                </div>
                            </div>
                            <div *ngIf="error" class="row">
                                <div class="col-md-12 error-message-edit-location">
                                    <span class="validationindicator">
                                        <app-error-message [error]="error"></app-error-message>
                                    </span>
                                </div>
                            </div>
                        </form>
                        <div [hidden]="!shownearestLocations" class="{{isFromManageLocations? 'edit-form' : ' '}}">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="label-css" i18n="@@label_matching_locations">
                                        Matching Location(s) found
                                    </label>
                                    <hr class="m-1">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="label-css" i18n="@@select_location_name_required">
                                        Please select a Location</label>
                                    <hr class="m-1">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 {{isFromManageLocations? ' edit-loc-disp-fields' : ' '}} ">
                                    <div class="card custom-card m-2 {{selectedIndex===i+1?'active':''}}"
                                        *ngFor="let loc of nearestLocations| slice:0:5; let i = index;"
                                        (click)="selectedIndex=i+1">
                                        <div class="card-body p-2 pl-4">
                                            <div class="row">
                                                <div class="ml-n1 mr-1" *ngIf="selectedIndex===i+1">
                                                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                                                </div>
                                                <div>
                                                    <div *ngIf="loc.LocationName?.trim()?.length > 0" class="label-css">
                                                        <span>{{loc.LocationName}}</span>
                                                    </div>
                                                    <div *ngIf="loc.Address1?.trim()?.length > 0" class="label-css">
                                                        <span>{{loc.Address1.trim()+', '}}</span>
                                                    </div>
                                                    <div class="label-css">
                                                        <span *ngIf="loc.City?.trim()?.length > 0">{{loc.City.trim()+',
                                                            '}}</span>
                                                        <span *ngIf="loc.State?.trim()?.length > 0">{{loc.State.trim()+'
                                                            '}}</span>
                                                        <span
                                                            *ngIf="loc.Zip?.trim()?.length > 0">{{loc.Zip.trim()}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!isEditLocation" class="row">
                                <div class="col-md-12">
                                    <hr class="m-1">
                                    <label class="label-css" i18n="@@continue_create_location">
                                        OR continue to create new Location</label>
                                </div>
                            </div>
                            <div *ngIf="request || newLocationRequest"
                                class="card custom-card m-2 {{selectedIndex===0?'active':''}}"
                                (click)="selectedIndex=0">
                                <div class="card-body p-2 pl-4">
                                    <div *ngIf="!isEditLocation && request" class="row">
                                        <div class="ml-n1 mr-1" *ngIf="selectedIndex===0">
                                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                                        </div>
                                        <div>
                                            <div *ngIf="request.Name" class="label-css">
                                                <span>{{request.Name.trim()}}</span>
                                            </div>
                                            <div *ngIf="request.Address1" class="label-css">
                                                <span>{{request.Address1.trim()+', '}}</span>
                                            </div>
                                            <div class="label-css">
                                                <span *ngIf="request.City">{{request.City.trim()+', '}}</span>
                                                <span *ngIf="request.State">{{request.State.trim()+' '}}</span>
                                                <span *ngIf="request.ZipCode">{{request.ZipCode.trim()}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="isEditLocation && newLocationRequest?.FullAddress" class="row">
                                        <div class="ml-n1 mr-1" *ngIf="selectedIndex===0">
                                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                                        </div>
                                        <div>
                                            <div *ngIf="newLocationRequest.FullAddress?.trim()?.length > 0"
                                                class="label-css">
                                                <span>{{newLocationRequest.FullAddress.trim()}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer p-1">
                                    <emr-button identifier="edit_location" buttonType="submit"
                                        cssClass="btn-sm btn-block m-0" buttonText="Edit Location"
                                        i18n-buttonText="@@label_edit_location" buttonIcon="fa fa-pencil-square-o"
                                        (onClick)="onEditNewLocation()"></emr-button>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <emr-button identifier="continue_location" [setDisabled]="selectedIndex===null"
                                        buttonType="submit" cssClass="btn-lg btn-primary quick-link btn-block m-0 mt-2"
                                        buttonText="Continue" i18n-buttonText="@@label_continue" buttonIcon="fa fa-Plus"
                                        (onClick)="onContinueClick()">
                                    </emr-button>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="isFromManageLocations && setGeofence">
                        <div class="edit-form height-fence">
                            <div class="row">
                                <div class="col-10">
                                    <span class="pl-1label-css font-14" i18n="@@label_geofence_creation">Geofence
                                        Creation</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 px-0">
                                    <div class="col-12 ">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" type="radio"
                                                id="geofenceType_flag_posts" name="geofenceType" [value]="3"
                                                [(ngModel)]="request.GeofenceType" (change)="onGeofenceTypeChange()" />
                                            <label class="custom-control-label label-css" for="geofenceType_flag_posts"
                                                i18n="@@label_draw_geo_fence">
                                                Draw fence posts
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 ">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input class="custom-control-input" type="radio" id="geofenceType_circle"
                                                name="geofenceType" [value]="0" [(ngModel)]="request.GeofenceType"
                                                (change)="onGeofenceTypeChange()" />
                                            <label class="custom-control-label label-css" for="geofenceType_circle"
                                                i18n="@@label_provide_radius_for_center_point">
                                                Provide radius for center point
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2">
                                        <div class="col-12 label-css font-11 height-auto">
                                            <span i18n="@@label_instructions">Instructions: </span>
                                            <div *ngIf="request.GeofenceType === fenceType.FlagPosts"
                                                i18n="@@label_custom_fence_draw_info">
                                                Use the left mouse button to define the perimeter of the fence.
                                                Left-click the first fence post to complete the fence.
                                            </div>
                                            <div *ngIf="request.GeofenceType === fenceType.Circle"
                                                i18n="@@label_circle_draw_info">
                                                Provide a radius for the address below.
                                            </div>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="request.GeofenceType === fenceType.Circle">
                                        <div class="row col-12 ">
                                            <div class="col-8 pr-1">
                                                <div class="col-12 pr-0">
                                                    <emr-textbox ngDefaultControl inputType=number
                                                        identifier="txtGeofenceRadius" name="txtGeofenceRadius"
                                                        label="Geofence Radius" i18n-label="@@label_geofence_radius"
                                                        [(ngModel)]="request.RadiusKm"
                                                        (change)="onGeofenceRadiusChange()"
                                                        appendText="{{distanceUnits}}" appendTextDivCssClass="col-6"
                                                        [inline]=true labelColumns="6" textBoxColumns="6"
                                                        inputDivClass="px-0 col-6" cssClass="p-1">
                                                    </emr-textbox>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="col-12 pl-0">
                                                    <emr-button identifier="btnEditGeofence" buttonText="Apply"
                                                        i18n-buttonText="@@link_apply"
                                                        [setDisabled]="disableApplyButton"
                                                        cssClass="btn btn-outline-default m-0 w-100 px-1"
                                                        (onClick)="onDrawCircleClick()">
                                                    </emr-button>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div *ngIf="ExistingGeofence" class="col-12 mt-3">
                                        <div class="row mx-0">
                                            <span class="label-css font-14" i18n="@@label_saved_geofence">Saved
                                                Geofence</span>
                                        </div>
                                        <div class="row mx-0">
                                            <div class="col-2 custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" type="radio"
                                                    id="rbtnShowExistingFence" name="rbtnShowExistingFence"
                                                    [value]="true" [(ngModel)]="showExistingFence" />
                                                <label class="custom-control-label label-css"
                                                    for="rbtnShowExistingFence" i18n="@@label_show">
                                                    Show
                                                </label>
                                            </div>
                                            <div class="col-2 custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" type="radio"
                                                    id="rbtnHideExistingFence" name="rbtnHideExistingFence"
                                                    [value]="false" [(ngModel)]="showExistingFence" />
                                                <label class="custom-control-label label-css"
                                                    for="rbtnHideExistingFence" i18n="@@label_hide">
                                                    Hide
                                                </label>
                                            </div>
                                            <div class="col-4">
                                                <emr-button identifier="btnDeleteGeofence" buttonText="Delete Geofence"
                                                    i18n-buttonText="@@label_delete_geofence"
                                                    cssClass="btn btn-block btn-lg btn-primary button-text m-0 quick-link px-1"
                                                    (onClick)="onDeleteGeofenceClick()">
                                                </emr-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <div class="row mx-0">
                                            <span class="label-css font-14" i18n="@@label_pending_geofence">Pending
                                                Geofence</span>
                                        </div>
                                        <div class="row mt-2 mx-0">
                                            <div class="col-2 custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" type="radio"
                                                    id="rbtnShowEditableFence" name="rbtnShowEditableFence"
                                                    [value]="true" [(ngModel)]="showEditableFence" />
                                                <label class="custom-control-label label-css"
                                                    for="rbtnShowEditableFence" i18n="@@label_show">
                                                    Show
                                                </label>
                                            </div>
                                            <div class="col-2 custom-control custom-radio custom-control-inline">
                                                <input class="custom-control-input" type="radio"
                                                    id="rbtnHideEditableFence" name="rbtnHideEditableFence"
                                                    [value]="false" [(ngModel)]="showEditableFence" />
                                                <label class="custom-control-label label-css"
                                                    for="rbtnHideEditableFence" i18n="@@label_hide">
                                                    Hide
                                                </label>
                                            </div>
                                            <div class="col-4">
                                                <emr-button identifier="btnClearGeofence" buttonText="Clear"
                                                    i18n-buttonText="@@label_clear"
                                                    cssClass="btn flt-btn reset-filter-btn m-0 w-100 px-1"
                                                    (onClick)="onClearGeofenceClick()">
                                                </emr-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6">
                                    <emr-button identifier="btnBack" buttonIcon="fa fa-long-arrow-left"
                                        cssClass="btn btn-block btn-lg btn-primary button-text m-0 quick-link"
                                        buttonText="Back" i18n-buttonText="@@label_back"
                                        (onClick)="onGeofenceBackClick()">
                                    </emr-button>
                                </div>
                                <div class="col-6">
                                    <emr-button identifier="btnSaveGeofence" buttonText="Save"
                                        [setDisabled]="disableSaveButton" i18n-buttonText="@@link_save"
                                        cssClass="btn btn-block btn-lg btn-primary button-text m-0 quick-link"
                                        (onClick)="onSaveGeofenceClick()">
                                    </emr-button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="displayChangeAddress">
                        <div class="edit-form min-height-add">
                            <app-search-address [isFromEditLocation]=true [currentRequest]="request"
                                [updateAddress]="newLocRequest" (updateMap)="onUpdateMap($event)"
                                [displayHeader]="false" [showCloseButton]="false"
                                (validateLocation)="onValidateLocation($event)">
                            </app-search-address>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="isFromManageLocations" class="col-7 px-0 location-map-View">
                    <emr-map #xmap [Options]="options" [Box]="box" (MapClick)="onMapClick($event)">
                        <emr-map-marker-layer [MarkerOptions]="locationMarker" [Visible]="true"
                            (DragEnd)="OnMarkerDragEnd($event)">
                        </emr-map-marker-layer>
                        <emr-map-draw-layer [Visible]="!!(showExistingFence)" [IsEditable]="true"
                            [drawOptions]="drawOptions"
                            (polygonData)="onCoordinatesUpdate($event)"></emr-map-draw-layer>
                        <emr-map-draw-layer [Visible]="!!(showEditableFence)" [IsEditable]="setGeofence"
                            [drawOptions]="tempDrawOptions" (polygonData)="onCoordinatesUpdate($event)"
                            [maxFencePost]="maxFencePost"></emr-map-draw-layer>
                    </emr-map>
                </div>
            </div>
        </div>
    </ng-container>
</emr-modal>