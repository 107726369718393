import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { CloseAlertRequest } from 'emr-ng-shared';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { AlertStateService } from 'app-modules/core/store/services/alert-state.service';
import { ErrorResponse } from 'emr-ng-shared';
import { AlertStatus } from 'emr-ng-shared';
import * as _ from 'lodash';

@Injectable()
export class AlertService {
    constructor(
        private oversightSvc: OversightApiService,
        private alertStateSvc: AlertStateService
    ) { }

    public getCloneAlertList(alertList: AlertStatus[]){
        return _.cloneDeep(alertList);
    }

    public closeAlert(alertId: number): Observable<ErrorResponse> {
        const request = new CloseAlertRequest();
        request.AlertIds = new Array<number>();
        request.AlertIds.push(alertId);
        return of(request).pipe(
            tap(() => this.alertStateSvc.closeAlert()),
            switchMap(n => this.oversightSvc.CloseAlert(n)),
            tap(
                n => {
                    if (n.ErrorCode === 0) {
                        this.alertStateSvc.closeAlertSuccess(alertId);
                    } else {
                        this.alertStateSvc.closeAlertError();
                    }
                },
                e => this.alertStateSvc.closeAlertError()
            )
        );
    }
}




