import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as LoadingActions from 'app-modules/core/store/actions/loading.actions';

type Actions =
    LoadingActions.FetchingData |
    LoadingActions.FetchingDataComplete |
    LoadingActions.CloseSpinner;

export function LoadingReducer(state: boolean = initialState.loading, action: Actions) {
    switch (action.type) {
        case actionIndex.FETCHING_DATA:
            return true;
        case actionIndex.FETCHING_DATA_COMPLETE:
        case actionIndex.CLOSE_SPINNER:
            return false;
        default:
            return state;
    }
}
