import { ISensorRangeState } from 'app-modules/core/store/models/sensor-range-state.interface';
import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as createActions from 'app-modules/core/store/actions/edit-shipment.actions';
import * as sensorRangeActions from 'app-modules/core/store/actions/edit-shipment-sensor-range.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    sensorRangeActions.LoadSensorRangeListSuccess |
    sensorRangeActions.LoadSensorRangeListError |
    createActions.SelectLinkedCustomer |
    custActions.SelectCustomerSuccess;

export function EditShipmentSensorRangeReducer(
    state: ISensorRangeState = initialState.editShipmentSensorRange,
    action: Actions
): ISensorRangeState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.editShipmentSensorRange;

        case actionIndex.EDIT_SHIPMENT_SELECT_LINKED_CUSTOMER:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                sensorRangeList: emptyList(),
                isLoadRequired: true
            };

        case actionIndex.EDIT_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS:
            return {
                ...state,
                sensorRangeList: action.sensorRangeList,
                isLoadRequired: false,
                errorMessage: ''
            };

        case actionIndex.EDIT_SHIPMENT_LOAD_SENSOR_RANGES_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: <string>action.message
            };
        default:
            return state;
    }
}
