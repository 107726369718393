import { Injectable } from '@angular/core';
import { SensorBandColors, SensorChartLineColors } from './chart-constants';
import {
    GetShipmentResponse, SensorCrumb, SensorListInfo, SensorLocationColorRange, LabelValueModel, AlertTypePosition,
    Sensor, ReportBackgroundColorType, EventType, EventTypePosition, AlertStatus, AlertType, EmrUtilService, SensorType,
    SensorCrumb2, SensorValue, isDefined
} from 'emr-ng-shared';
import { AxisRange } from '../components/sensor/models/AxisRange';
import { TrackerStatechartAlertData } from 'app-modules/shipment-chart/models/tracker-state-chart-alertdata.model';
import { TrackerStatechartMarker } from 'app-modules/shipment-chart/models/tracker-state-chart-marker.model';
import { TrackerStatechartChartData } from 'app-modules/shipment-chart/models/tracker-state-chart-chartdata.model';
import { ChartCrumbsDTO } from '../models/ChartCrumbsDTO';
import { BehaviorSubject } from 'rxjs';
import { BaseURLPicker } from 'app-modules/core/services/baseurl-picker.service';
import { description, details } from 'app-modules/core/consts/localization';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { SensorSeriesDTO } from '../models/SensorSeriesDTO';

@Injectable()
export class SensorChartDataService {

    constructor(
        private domainPicker: BaseURLPicker,
        private prefSvc: PreferenceService,
        private imgMapSvc: ImageMapService) { }

    minXValue = null;
    maxXValue = null;
    shipmentChartData: {
        sensorsData: any[];
        plotLines: { sensorPlotLines: any[], TripStart: any, TripEnd: any };
        tempPlotBands: any[];
        locationPlotBands: any[];
        locationLabels: any[];
        leftAxisOptions: any;
        rightAxisOptions: any;
        minXValue: number;
        maxXValue: number;
        updateFlag: boolean;
        noChartData: boolean;
    };
    isChartBindingSub = new BehaviorSubject<boolean>(false);
    isChartBinding$ = this.isChartBindingSub.asObservable();
    tempAxisFormat: string;
    humidityAxisFormat: string;

    initialState() {
        this.shipmentChartData = null;
        this.minXValue = null;
        this.maxXValue = null;
    }


    cleanChartDetails() {
        this.initialState();
    }

    setChartData(data) {
        this.shipmentChartData = data;
    }

    buildSensorChart(shipmentResponse: GetShipmentResponse, tempUnits: string) {
        if (this.shipmentChartData) {
            return this.shipmentChartData;
        }
        this.isChartBindingSub.next(true);
        this.shipmentChartData = this.processSensorChartData(shipmentResponse, tempUnits);
        this.isChartBindingSub.next(false);
    }

    processSensorChartData(shipmentResponse: GetShipmentResponse, tempUnits: string): any {
        let sensorsData: ChartCrumbsDTO[] = [];
        let colorBands = [];
        let finalLocationBands = [];
        const finalLocationLabels = [];
        let plotLines = { sensorPlotLines: [], TripStart: null, TripEnd: null };
        let leftAxisOptions = null;
        let rightAxisOptions = null;
        let noChartData = false;
        let updateMinMaxValues = true;
        if (shipmentResponse?.SelectedPeriodRange &&
            shipmentResponse.SelectedPeriodRange.FromDtm &&
            shipmentResponse.SelectedPeriodRange.ToDtm) {
            this.minXValue = new Date(this.getLocalDateString(shipmentResponse.SelectedPeriodRange.FromDtm)).setMilliseconds(0);
            this.maxXValue = new Date(this.getLocalDateString(shipmentResponse.SelectedPeriodRange.ToDtm)).setMilliseconds(0);
            updateMinMaxValues = false;
        }
        if (shipmentResponse && shipmentResponse.Shipment
            && shipmentResponse.Shipment.Sensors && shipmentResponse.Shipment.Sensors.length > 0) {
            // this.startBindingDataSpinner();
            // const selectedPeriodRange = shipmentResponse.SelectedPeriodRange

            const sensors = shipmentResponse.Shipment.Sensors;
            const leftAxisY: AxisRange = { minValue: null, maxValue: null };
            const rightAxisY: AxisRange = { minValue: null, maxValue: null };
            const leftAxisLabelFormat = `{value:.0f} ${tempUnits}`;
            const rightAxisLabelFormat = `{value:.0f}%`;
            this.tempAxisFormat = leftAxisLabelFormat;
            this.humidityAxisFormat = rightAxisLabelFormat;
            const series = new SensorSeriesDTO();
            sensors?.forEach((sensor: Sensor) => {
                const crumbs = shipmentResponse.Shipment.SensorCrumbs?.map(s => s.Crumbs?.find(s => s.p === sensor.Pin))
                if (!noChartData) {
                    noChartData = crumbs?.length > 0;
                }
                const isSingleSensorOrTemp = sensors.length === 1 || sensor.Pin === "G0";
                const mincritical = isSingleSensorOrTemp ? sensor.LowCritical : null;
                const maxcritical = isSingleSensorOrTemp ? sensor.HighCritical : null;
                const minwarn = isSingleSensorOrTemp ? sensor.LowWarn : null;
                const maxwarn = isSingleSensorOrTemp ? sensor.HighWarn : null;

                let includeSensor = false;
                let IsTrip: boolean = sensor.Pin === 'G0' ? !!(sensor.TripSensorRangeID || sensor.DefaultSensorRangeID) && noChartData : true;
                const sensorRange = {
                    minValue: IsTrip ? (mincritical <= minwarn ? mincritical : minwarn) : null,
                    maxValue: IsTrip ? (maxcritical >= maxwarn ? maxcritical : maxwarn) : null
                } as AxisRange;
                let calculatedAxisRange: AxisRange = sensorRange;
                if (crumbs?.length > 0) {
                    const sensorAxisRange: AxisRange = this.getSensorAxisRange(crumbs);
                    calculatedAxisRange = this.getAxisRange(sensorRange, sensorAxisRange);
                }
                // #show Line Info

                const finalChartInfo: ChartCrumbsDTO = {
                    chartData: [],
                    eventsData: [],
                    probeConnects: [],
                    probeIsolatePoints: [],
                    axis: null,
                    isRight: false,
                    name: sensor.Name,
                    labelname: `<span style="color:${this.getSensorColor(sensor)}">${sensor.Name}</span>`,
                    units: '',
                    type: sensor.Type,
                    pin: sensor.Pin,
                    labelFormat: '{value}',
                    minCrumbValue: null,
                    maxCrumbValue: null
                };
                // TODO Replace when SensorTypeCode Fixed
                // switch (sensor.Type) {
                switch (sensor.Pin) {
                    case 'G0':
                    case 'PRB':
                    case 'SHK':
                        // case SensorType.Temperature:
                        // case SensorType.ProbeTemp:
                        calculatedAxisRange = this.getAxisRange(leftAxisY, calculatedAxisRange);
                        // To Persist the reference please update the objects value
                        leftAxisY.minValue = calculatedAxisRange.minValue;
                        leftAxisY.maxValue = calculatedAxisRange.maxValue;
                        finalChartInfo.axis = leftAxisY;
                        includeSensor = true;
                        break;
                    // case SensorType.Humidity:
                    // case SensorType.CarbonDioxide:
                    case 'HU':
                    case 'CO2':
                        calculatedAxisRange = this.getAxisRange(rightAxisY, calculatedAxisRange);
                        // To Persist the reference please update the objects value
                        rightAxisY.minValue = calculatedAxisRange.minValue;
                        rightAxisY.maxValue = calculatedAxisRange.maxValue;
                        finalChartInfo.axis = rightAxisY;
                        finalChartInfo.isRight = true;
                        includeSensor = true;
                        break;
                }

                // #show Events Info
                // finalChartInfo.eventsData =
                //     this.createEventsData(sensor.Crumbs, calculatedAxisRange.minValue, calculatedAxisRange.maxValue);

                // if (sensor.Type === SensorType.Temperature) {
                if (sensor.Pin === 'G0') {
                    // #show range info
                    colorBands = this.updatePlotBands(sensor);
                }
                // Adding all sensors for drawing plot lines
                // For CO2 and HU sensors there is no Low/High warning values
                plotLines.sensorPlotLines.push({
                    Pin: sensor.Pin,
                    LowWarn: IsTrip && !(sensor.Pin === 'CO2' || sensor.Pin === 'HU') ? sensor.LowWarn : null,
                    HighWarn: IsTrip && !(sensor.Pin === 'CO2' || sensor.Pin === 'HU') ? sensor.HighWarn : null,
                    LowCritical: IsTrip ? sensor.LowCritical : null,
                    HighCritical: IsTrip ? sensor.HighCritical : null,
                    LowWarnString: IsTrip && !(sensor.Pin === 'CO2' || sensor.Pin === 'HU') ? sensor.LowWarnString : null,
                    HighWarnString: IsTrip && !(sensor.Pin === 'CO2' || sensor.Pin === 'HU') ? sensor.HighWarnString : null,
                    LowCriticalString: IsTrip ? sensor.LowCriticalString : null,
                    HighCriticalString: IsTrip ? sensor.HighCriticalString : null,
                })
                if (!includeSensor) {
                    return;
                }
                sensorsData.push(finalChartInfo);
                series[sensor.Pin] = finalChartInfo;
            });

            sensorsData.forEach(sd => {
                const sensorRange = { minValue: sd.minCrumbValue, maxValue: sd.maxCrumbValue };
                if (!sd.isRight) {
                    const calculatedAxisRange = this.getAxisRange(sensorRange, sd.axis);
                    leftAxisY.minValue = calculatedAxisRange.minValue;
                    leftAxisY.maxValue = calculatedAxisRange.maxValue;
                } else {
                    const calculatedAxisRange = this.getAxisRange(sensorRange, sd.axis);
                    rightAxisY.minValue = calculatedAxisRange.minValue;
                    rightAxisY.maxValue = calculatedAxisRange.maxValue;
                }
            });
            if (sensorsData.length > 0 &&
                shipmentResponse.Shipment?.SensorCrumbs?.length > 0) {
                const shockDetected = shipmentResponse.Shipment?.Sensors?.find(k => k.Pin === "SHK");
                let shockDetectedImageUrl;
                if (shockDetected) {
                    shockDetectedImageUrl = this.imgMapSvc.getImageURL(shockDetected.ImageId, shockDetected.ImageUrlSVG, shockDetected.ImageUrl)
                }
                this.createChartData(shipmentResponse.Shipment?.SensorCrumbs, series, shipmentResponse.Shipment?.Sensors);
            }
            sensorsData = sensorsData.filter(sd => sd.chartData && sd.chartData.length > 0);

            finalLocationBands = this.getLocationPlotBand(shipmentResponse.Shipment.SensorLocationColorRange, finalLocationLabels);
            if (sensorsData?.length > 0 &&
                shipmentResponse?.Shipment?.Alerts) {
                this.getAlertsDetails(shipmentResponse.Shipment.Alerts, sensorsData, shipmentResponse.Shipment.Sensors);
            }
            if (shipmentResponse.Shipment.Sensors.filter(x => x.Pin === "PRB").length > 0 && shipmentResponse.Shipment.ProbeAlerts) {
                this.getProbeDisconnectDetails(shipmentResponse.Shipment.ProbeAlerts, sensorsData);
            }
            if (shipmentResponse.Shipment.Sensors.filter(x => x.Pin === "PRB").length > 0 && shipmentResponse.Shipment.ProbeIsolatedPoints) {
                this.getProbeDisconnectIsolatedPointDetails(shipmentResponse.Shipment.ProbeIsolatedPoints, sensorsData);
            }

            // sensorsData.eventsData = shipmentResponse.Shipment.Alerts.filter(k => k.IsCharted);
            const defaultDate = new Date(1970, 0, 1, 23, 59, 59);
            const graceMinutes = 5 * 60 * 1000;
            if (shipmentResponse.Shipment && shipmentResponse.Shipment.ActualStartTime) {
                const tripStartDT = new Date(this.getLocalDateString(shipmentResponse.Shipment.ActualStartTime)).setSeconds(0, 0);
                if (tripStartDT >= defaultDate.setMilliseconds(0) &&
                    (this.minXValue - graceMinutes) <= tripStartDT) {
                    plotLines = { ...plotLines, TripStart: tripStartDT };
                    this.minXValue = tripStartDT < this.minXValue ? tripStartDT : this.minXValue;
                }
            }

            if (shipmentResponse.Shipment && shipmentResponse.Shipment.ActualEndTime) {
                const tripEndDT = new Date(this.getLocalDateString(shipmentResponse.Shipment.ActualEndTime)).getTime();
                if (tripEndDT >= defaultDate.setMilliseconds(0) &&
                    (this.maxXValue + graceMinutes) >= tripEndDT) {
                    plotLines = { ...plotLines, TripEnd: tripEndDT };
                    this.maxXValue = tripEndDT > this.maxXValue ? tripEndDT : this.maxXValue;
                }
            }
            if (leftAxisY.minValue !== null && leftAxisY.maxValue !== null) {
                leftAxisOptions = {
                    min: leftAxisY.minValue,
                    max: leftAxisY.maxValue,
                    label: {
                        format: leftAxisLabelFormat,
                        style: {
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#000000'
                        }
                    }
                };
            } else {
                leftAxisOptions = {
                    // min: rightAxisY.minValue,
                    // max: rightAxisY.maxValue,
                    label: {
                        format: rightAxisLabelFormat,
                        style: {
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#000000'
                        }
                    }
                };
                sensorsData = sensorsData.filter(sd => sd.isRight);
                sensorsData.forEach(sd => {
                    sd.isRight = false;
                });
            }

            if ((leftAxisY.minValue !== null && leftAxisY.maxValue !== null) &&
                (rightAxisY.minValue !== null && rightAxisY.maxValue !== null)) {
                rightAxisOptions = {
                    min: null,
                    max: null,
                    calcMin: rightAxisY.minValue,
                    calcMax: rightAxisY.maxValue,
                    label: {
                        format: rightAxisLabelFormat,
                        style: {
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#000000'
                        }
                    }
                };
            }
            if (sensors.filter(x => x.Pin === 'G0').length === 0 && sensors.filter(x => x.Pin === 'PRB').length === 0) {
                leftAxisOptions.label.format = rightAxisLabelFormat;
                leftAxisOptions.min = rightAxisY.minValue;
                leftAxisOptions.max = rightAxisY.maxValue;
            }
        }
        noChartData = sensorsData && sensorsData.length > 0;
        return {
            sensorsData,
            plotLines,
            noChartData,
            tempPlotBands: colorBands,
            locationPlotBands: finalLocationBands,
            locationLabels: finalLocationLabels,
            leftAxisOptions,
            rightAxisOptions,
            minXValue: this.minXValue,
            maxXValue: this.maxXValue,
            updateFlag: true
        };
    }


    private createChartData(
        crumbs: SensorCrumb2[],
        seriesData: SensorSeriesDTO,
        sensors: Sensor[]) {
        crumbs = crumbs.slice().sort((a, b) => {
            return new Date(a.SatTime).getTime() - new Date(b.SatTime).getTime();
        });
        // if (updateMinMaxXValues) {
        //     const minXValue = new Date(crumbs[0].SatTime).getTime();
        //     const maxXValue = new Date(crumbs[crumbs.length - 1].SatTime).getTime();

        //     this.minXValue = (this.minXValue === null) || (minXValue < this.minXValue) ? minXValue : this.minXValue;
        //     this.maxXValue = (this.maxXValue === null) || (maxXValue > this.maxXValue) ? maxXValue : this.maxXValue;
        // }
        crumbs.forEach((crumb: SensorCrumb2) => {
            if (crumb && crumb.Crumbs?.length > 0) {

                const chartTempObj = new TrackerStatechartChartData();
                chartTempObj.x = new Date(this.getLocalDateString(crumb.SatTime)).getTime();
                chartTempObj.Location = crumb.LocationName;
                chartTempObj.SatTimeString = crumb.SatTimeString;
                chartTempObj.Latitude = crumb.Latitude;
                chartTempObj.Longitude = crumb.Longitude;
                chartTempObj.breadcrumbId = crumb.BreadCrumbId;
                crumb.Crumbs.forEach(v => {
                    if (isDefined(v.v) || v.p == "PRB") {
                        chartTempObj.marker = new TrackerStatechartMarker();
                        if (v.p === 'SHK' && sensors.find(k => k.Pin === 'SHK')) {
                            const sensor = sensors.find(k => k.Pin === v.p)
                            chartTempObj.marker.symbol = 'url(' + sensor?.ImageUrlSVG + ')';
                            chartTempObj.marker.enabled = true;
                        }
                        const series = seriesData[v.p];
                        chartTempObj.labels = series?.name ? [{ label: series.name, value: v.vs ? v.vs : v.v?.toString() }] : null;
                        chartTempObj.displayValue = v.dvs;
                        if (series) {
                            const valueString = v.vs ? v.vs : v.v?.toString();
                            series.chartData.push({ ...chartTempObj, y: v.v, formattedString: valueString });
                            if (!series.minCrumbValue || series.minCrumbValue > v.v) {
                                series.minCrumbValue = v.v;
                            }
                            if (!series.maxCrumbValue || (series.maxCrumbValue < v.v && v.v)) {
                                series.maxCrumbValue = v.v;
                            }
                        }
                    }
                });
            }
        });
    }

    private getLocationPlotBand(data: SensorLocationColorRange[] = [], plotLabel: any[]) {
        if (data) {
            data = data.sort((a, b) => {
                return new Date(a.StartSatTime).getTime() - new Date(b.StartSatTime).getTime();
            });
            let i = 1;
            return data.filter(k => k.StartSatTime && k.EndSatTime && (k.IsDBL || k.IsOrigin || k.IsDestination || (k.IsStop && k.IsForSensorChart))).map(k => {
                if (k.Color && !plotLabel.find(a => a.LocationColor === k.Color && k.LocationName === a.LocationName)) {
                    if (!plotLabel.find(p => p.LocationName.includes(k.LocationName) && p.LocationColor === k.Color)) {
                        plotLabel.push({
                            LocationColor: k.Color,
                            LocationName: i + ') ' + k.LocationName
                        });
                        i++;
                    }
                }
                return {
                    from: new Date(this.getLocalDateString(k.StartSatTime)),
                    to: new Date(this.getLocalDateString(k.EndSatTime)),
                    color: k.Color ? k.Color : '#ffffff'
                };
            });
        } else { return []; }
    }

    private getEnumLength(enumName: any): number {
        let count = 0;
        for (const item in enumName) {
            if (isNaN(Number(item))) {
                count++;
            }
        }
        return count;
    }

    private getAxisRange(calculatedAxisRange: AxisRange, previousAxisRange: AxisRange): AxisRange {
        return {
            minValue: calculatedAxisRange.minValue && calculatedAxisRange.minValue < previousAxisRange.minValue ?
                calculatedAxisRange.minValue : previousAxisRange.minValue,
            maxValue: calculatedAxisRange.maxValue && calculatedAxisRange.maxValue > previousAxisRange.maxValue ?
                calculatedAxisRange.maxValue : previousAxisRange.maxValue
        };
    }

    private existValueInEnum(type: any, value: any): boolean {
        return Object.keys(type).filter(k => isNaN(Number(k))).filter(k => type[k] === value).length > 0;
    }

    private updatePlotBands(sensor: any): any[] {
        const plotBands: any[] = [];
        if (sensor.StateChanges &&
            sensor.StateChanges.length > 0) {
            const states = sensor.StateChanges.slice().sort((a, b) => {
                return new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime();
            });
            for (const item of states) {
                const startTime = item.StartTime ? new Date(this.getLocalDateString(item.StartTime)).getTime() : null;
                const endTime = item.EndTime ? new Date(this.getLocalDateString(item.EndTime)).getTime() : null;
                const sensorState = item.SensorState;
                const plotBand = { from: startTime, to: endTime - 1, color: '#ffffff' };
                switch (sensorState) {
                    case 'LowWarn':
                        plotBand.color = SensorBandColors.LowWarn; // Color.FromArgb(50, 255, 255, 0);
                        break;
                    case 'LowCritical':
                        plotBand.color = SensorBandColors.LowCritical; // Color.FromArgb(50, 255, 0, 0);
                        break;
                    case 'HighCritical':
                        plotBand.color = SensorBandColors.HighCritical; // Color.FromArgb(50, 255, 0, 0);
                        break;
                    case 'HighWarn':
                        plotBand.color = SensorBandColors.HighWarn; // Color.FromArgb(50, 255, 255, 0);
                        break;
                    case 'Normal':
                        plotBand.color = SensorBandColors.Normal; // Color.FromArgb(50, 0, 255, 0);
                        break;
                }
                plotBands.push(plotBand);
            }
        }

        return plotBands;
    }

    private getAlertsDetails(n: any[], sensorsData: any[], sensors: Sensor[]) {
        const sensorData = sensorsData.find(x => x.pin === "G0" || x.pin === "PRB") || sensorsData.find(x => x.axis);
        return n.map(k => {
            //Need to hide shock alerts in sensor chart
            if (k.IsCharted && k.Pin !== "SHK") {
                const alertTempObj = new TrackerStatechartAlertData();
                const iconPositionDelta = (sensorData.axis.maxValue - sensorData.axis.minValue + 1) / this.getEnumLength(AlertType);
                alertTempObj.x = new Date(this.getLocalDateString(k.SatTime)).getTime();
                alertTempObj.Latitude = k.Latitude;
                alertTempObj.Longitude = k.Longitude;
                alertTempObj.SatTimeString = k.SatTimeString;
                alertTempObj.labels = [{ label: description, value: k.Description },
                { label: details, value: k.Sensor?.Type == SensorType.ShockDetected ? k.Sensor?.MostRecentRangeValueString : k.Detail }];
                let yValue = this.getAlertYValue(k.Type, AlertType, sensorData.axis.minValue, iconPositionDelta);
                if (yValue != null && k.Type !== AlertType.ProbeReconnected && k.Type !== AlertType.ProbeDisconnected) {
                    alertTempObj.y = yValue;
                    alertTempObj.marker = new TrackerStatechartMarker();
                    const imagePath = this.imgMapSvc.getImageURL(k.ImageId, k.ImageUrlSVG, k.ImageUrl);
                    if (imagePath) {
                        alertTempObj.marker.symbol = 'url(' + imagePath + ')';
                        alertTempObj.marker.enabled = true;
                        sensorData.eventsData.push(alertTempObj);
                    }
                }
                //return null;
            }
        });
    }

    private getProbeDisconnectIsolatedPointDetails(n: SensorCrumb2[], sensorsData: any[]) {
        const sensorData = sensorsData.find(x => x.pin === "PRB");
        return n.map(k => {
            if (k) {
                const sensorValue = k.Crumbs.filter(x => x.p === "PRB")[0];
                const alertTempObj = new TrackerStatechartAlertData();
                alertTempObj.x = new Date(this.getLocalDateString(k.SatTime)).getTime();
                alertTempObj.Latitude = k.Latitude;
                alertTempObj.Longitude = k.Longitude;
                alertTempObj.SatTimeString = k.SatTimeString;
                alertTempObj.labels = [{ label: description, value: k.SatTime },
                { label: details, value: sensorValue.vs }];
                alertTempObj.y = sensorValue.v;
                alertTempObj.marker = new TrackerStatechartMarker();
                alertTempObj.marker.symbol = 'circle';
                alertTempObj.marker.radius = 2;
                alertTempObj.marker.fillColor = '#62bb46';
                alertTempObj.marker.enabled = true;
                sensorData.probeIsolatePoints.push(alertTempObj);
            }
        });
    }

    private getProbeDisconnectDetails(n: any[], sensorsData: any[]) {
        const sensorData = sensorsData.find(x => x.pin === "PRB");
        return n.map(k => {
            if (k.IsCharted) {
                const alertTempObj = new TrackerStatechartAlertData();
                alertTempObj.x = new Date(this.getLocalDateString(k.NearestCrumbSatTime)).getTime();
                alertTempObj.Latitude = k.Latitude;
                alertTempObj.Longitude = k.Longitude;
                alertTempObj.SatTimeString = k.SatTimeString;
                alertTempObj.labels = [{ label: description, value: k.Description },
                { label: details, value: k.Detail }];
                alertTempObj.y = k.NearestCrumbValue;
                alertTempObj.marker = new TrackerStatechartMarker();
                alertTempObj.marker.symbol = 'circle';
                alertTempObj.marker.radius = 5;
                alertTempObj.marker.fillColor = '#62bb46';
                alertTempObj.marker.enabled = true;
                sensorData.probeConnects.push(alertTempObj);
            }
        });
    }

    private getAlertYValue(alertTypeValue: number, type, minY: number, alertIconPositionDelta: number): number {
        let yValue = null;
        let alertType = type[0];
        if (this.existValueInEnum(type, alertTypeValue)) {
            alertType = type[alertTypeValue];
            if (alertType !== type[0]) {
                const alertTypePosition = AlertTypePosition[alertType];
                const position = parseInt(alertTypePosition, 10);
                yValue = minY + alertIconPositionDelta * position;
            }
        }
        return yValue;
    }


    private getSensorColor(sensor: Sensor) {
        if (sensor.Type === SensorType.Temperature || sensor.Pin === 'G0') {
            return SensorChartLineColors.Temperature;
        } else if (sensor.Type === SensorType.ProbeTemp || sensor.Pin === 'PRB') {
            return SensorChartLineColors.Probe_Temp;
        } else if (sensor.Type === SensorType.Humidity || sensor.Pin === 'HU') {
            return SensorChartLineColors.Humidity;
        } else if (sensor.Type === SensorType.CarbonDioxide || sensor.Pin === 'CO2') {
            return SensorChartLineColors.CO2;
        } else {
            return SensorChartLineColors.Temperature; // Default color...
        }
    }

    private getLocalDateString(date: any): string {
        return date?.toString().replace('Z', '');
    }

    private getSensorAxisRange(crumbs: SensorValue[]): AxisRange {
        return {
            minValue: this.getMinValueOrNull(crumbs, 'v'),
            maxValue: this.getMaxValueOrNull(crumbs, 'v')
        };
    }

    private getMinValueOrNull(data, attr) {
        let minValue = null;
        minValue = Math.min.apply(null, data.filter(item => item != null).map(item => item[attr]));
        return minValue;
    }

    private getMaxValueOrNull(data, attr) {
        let maxValue = null;
        maxValue = Math.max.apply(null, data.filter(item => item != null).map(item => item[attr]));
        return maxValue;
    }
}
