import { IScheduledReportState } from '../models/scheduled-report-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as scheduleReportActions from './schedule-report.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

import { emptyList } from '../models/list-info-state.interface';
import { ScheduledReports } from 'emr-ng-shared';
import { TKeyValuePair } from 'emr-ng-shared';
import { ScheduleReportRequest } from 'emr-ng-shared';

type Actions =
    authActions.SignOut |
    scheduleReportActions.LoadScheduledReports |
    scheduleReportActions.LoadScheduledReportsSuccess |
    scheduleReportActions.LoadScheduledReportsError |
    scheduleReportActions.ReloadScheduledReports |
    scheduleReportActions.ScheduleReportSuccess |
    custActions.SelectCustomerSuccess;

export function ScheduleReportReducer(state: IScheduledReportState = initialState.scheduledReportList, action: Actions): IScheduledReportState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return {
                ...state,
                scheduledReportList: emptyList(),
                isLoadRequired: false,
            };

        case actionIndex.RELOAD_SCHEDULE_REPORTS:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoadRequired: true,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.SCHEDULE_REPORT_SUCCESS:
            let existingReport: ScheduledReports;
            let newState = {
                ...state,
                isLoading: false
            };
            if (action.request) {
                if (action.isDelete) {
                    existingReport = newState.scheduledReportList.list.find(a => a.ScheduledReportsId === action.request?.ScheduledReportsId);
                    existingReport.Deleted = true;
                } else if (action.request.ScheduledReportsId) {
                    const reportsList = emptyList();
                    reportsList.list = newState.scheduledReportList.list.map(a => {
                        if (a.ScheduledReportsId === action.request.ScheduledReportsId) {
                            a = {
                                ...a,
                                ReportName: action.request.ReportName,
                                Description: action.request.ReportName,
                                SendTo: action.request.EmailIDs,
                                DOW: action.request.ReportDays,
                                Hour: action.request.ReportTime,
                                Parameters: getScheduledReportParams(action.request)
                            };
                        }
                        return a;
                    });
                    reportsList.itemCount = reportsList.list.length;
                    newState.scheduledReportList = reportsList;
                } else {
                    newState.isLoadRequired = true;
                }
            }
            return newState;
        case actionIndex.LOAD_SCHEDULE_REPORTS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_SCHEDULE_REPORTS_SUCCESS:
            return {
                ...state,
                scheduledReportList: action.scheduledReportList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_SCHEDULE_REPORTS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}


export function getScheduledReportParams(request: ScheduleReportRequest){
    const params = [];
    
    let paramsProp = {};

    request.ReportParams.forEach(kv => {
        paramsProp[kv.Key] = kv.Value;
    });

    const paramObj = { ...request };
    delete paramObj.ReportParams;

    return JSON.stringify({ ...paramObj, Params: paramsProp, ExportToExcel: request.ReportFormat == 0});
}
