export const alertStatusDesc = {
    100: $localize`:@@alert_action_status_open:Open`, // AlertActionStatus.Open 
    200: $localize`:@@alert_action_status_close:Closed`, //AlertActionStatus.Closed
}

export const alertSeverityDesc = {
    1: $localize`:@@alert_severity_critical:Critical`, // AlertSeverity.Critical
    2: $localize`:@@alert_severity_warning:Warning`, // AlertSeverity.Warning
}

export const alertEscalationLevelDesc = {
    1: $localize`:@@AlertEscalationLevel_1:Level 1`, // AlertEscalationLevel.Level1 
    2: $localize`:@@AlertEscalationLevel_2:Level 2`, // AlertEscalationLevel.Level2 
    3: $localize`:@@AlertEscalationLevel_3:Level 3`, // AlertEscalationLevel.Level3 
    4: $localize`:@@AlertEscalationLevel_4:Level 4`, // AlertEscalationLevel.Level4 
    5: $localize`:@@AlertEscalationLevel_5:Level 5`, // AlertEscalationLevel.Level5 
    6: $localize`:@@AlertEscalationLevel_6:Level 6`, // AlertEscalationLevel.Level6 
}