import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { SetSensorRangeRequest } from 'emr-ng-shared';
import { SensorRangeInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ISensorRangeState } from 'app-modules/core/store/models/sensor-range-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as rangeActions from 'app-modules/core/store/sensor-range/sensor-range.actions';

@Injectable()
export class SensorRangeStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public sensorRangeStateSelector = ((state: IAppState) => state.sensorRange);
    public isLoadRequiredSelector = createSelector(
        this.sensorRangeStateSelector,
        (state: ISensorRangeState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.sensorRangeStateSelector,
        (state: ISensorRangeState) => state.isLoading
    );
    public sensorRangeListSelector = createSelector(
        this.sensorRangeStateSelector,
        (state: ISensorRangeState) => state.sensorRangeList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public sensorRangeList$ = this.store.select(this.sensorRangeListSelector).pipe();

    // Actions
    public loadSensorRanges() {
        this.store.dispatch(new rangeActions.LoadSensorRanges());
    }

    public loadSensorRangesSuccess(sensorRangeList: IListInfo<SensorRangeInfo>) {
        this.store.dispatch(new rangeActions.LoadSensorRangesSuccess(sensorRangeList));
    }

    public loadSensorRangesError(message: string) {
        this.store.dispatch(new rangeActions.LoadSensorRangesError(message));
    }

    public cancelLoadSensorRanges() {
        this.store.dispatch(new rangeActions.CancelLoadSensorRanges());
    }
    public createSensorRange(request: SetSensorRangeRequest) {
        this.store.dispatch(new rangeActions.CreateSensorRange(request));
    }

    public createSensorRangeSuccess(sensorRangeInfo: SensorRangeInfo) {
        this.store.dispatch(new rangeActions.CreateSensorRangeSuccess(sensorRangeInfo));
    }

    public createSensorRangeError(message: string) {
        this.store.dispatch(new rangeActions.CreateSensorRangeError(message));
    }
}


