import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "app-modules/core/store/models/app-state.interface";
import * as domoUIActions from './domo.actions';
@Injectable()
export class DomoUIStateService {
    constructor(private store: Store<IAppState>) { }

    public DisplayLoader() {
        this.store.dispatch(new domoUIActions.DisplayLoader());
    }

    public CancelLoader() {
        this.store.dispatch(new domoUIActions.CancelLoader());
    }
}