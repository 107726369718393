import { Component, OnInit, TemplateRef, Renderer2, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  GetRouteDeviationsRequestModel, GetRouteDeviationsResponseModel, RouteDeviationListItem, ErrorResponse,
  GetRouteDeviationRequestModel, ExportTrackerStateReportHeaders, responseType, RouteDeviationReportRequest,
  EmrUtilService, GetRouteDeviationReportResponseModel
} from 'emr-ng-shared';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import * as FileSaver from 'file-saver';
import { RouteDeviationsService } from 'app-modules/core/store/route-deviation/route-deviation.service';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import * as _ from 'lodash';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-route-deviation',
  templateUrl: './route-deviation.component.html',
  styleUrls: ['./route-deviation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RouteDeviationComponent implements OnInit, OnDestroy {
  selectedDeviationLIstItem: RouteDeviationListItem;

  displayErrorMessage: boolean;
  errorMessage: any;
  selectedTrackerIds: string[] = [];
  CheckAll = false;
  deviationSubscription: Subscription;
  getRouteSubscription: Subscription;
  routeDeviations: RouteDeviationListItem[];
  FromDate: Date;
  ToDate: Date;
  @ViewChild(NgForm) deviationSearchFrom: NgForm;

  constructor(
    private service: RouteDeviationsService,
    private renderer: Renderer2,
    private detailStateSvc: ShipmentDetailStateService,
    private showPopups: OversightPopupService,
    private utilSvc: EmrUtilService) {
    this.renderer.addClass(document.body, 'bodyCss');
  }

  ngOnInit() {
    // const today = new Date();
    // this.ToDate = new Date();
    // this.FromDate = new Date(today.setDate(today.getDate() - 1));
    this.onDeviationSearch();
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bodyCss');
    if (this.deviationSubscription) {
      this.deviationSubscription.unsubscribe();
    }
    if (this.getRouteSubscription) {
      this.getRouteSubscription.unsubscribe();
    }
  }

  onDeviationSearch() {
    if (!this.deviationSearchFrom || this.deviationSearchFrom.valid) {
      this.selectedTrackerIds = [];
      const request = new GetRouteDeviationsRequestModel();
      if (this.FromDate && this.ToDate) {
        request.From = this.utilSvc.DateFormatLocaleChange(this.FromDate, 'MM/dd/yyyy');
        request.Thru = this.utilSvc.DateFormatLocaleChange(this.ToDate, 'MM/dd/yyyy');
      }
      this.deviationSubscription = this.service.GetRouteDeviations(request).subscribe(k => {
        this.routeDeviations = k.list;
        this.CheckAll = false;
        // const data = _.groupBy(k.Deviations, 'GlobalDeviceId');
      }
      );
    }
  }

  onDeviationReset() {
    if (this.FromDate || this.ToDate) {
      this.FromDate = this.ToDate = null;
      this.onDeviationSearch();
    }
  }

  selectRoute(deviationLIstItem: RouteDeviationListItem) {
    const request = new GetRouteDeviationRequestModel();
    request.RouteId = deviationLIstItem.RouteId;
    request.TripId = deviationLIstItem.TripId;
    request.TripStartTime = this.utilSvc.DateFormatLocaleChange(deviationLIstItem.TripStartTime);
    request.TripEndTime = this.utilSvc.DateFormatLocaleChange(deviationLIstItem.TripEndTime);
    this.getRouteSubscription = this.service.GetRouteDeviationByRoute(request).subscribe(response => {
      if (response && response.TraxxInfoList && response.TraxxInfoList.length > 0) {
        response.TraxxInfoList.forEach(k => k.TrackerInfo ? k.TrackerInfo.TrackerId = deviationLIstItem.GlobalDeviceId : k.TrackerInfo);
      }
      this.showPopups.showShipmentRouteDeviationPopup(response);
    });
  }

  onExcelDownloadCheckboxClick(item, list) {
    if (item.IsPdfDownload) {
      this.selectedTrackerIds.push(item.GlobalDeviceId);
      this.CheckAll = true;
      list.forEach(element => {
        if (!element.IsPdfDownload) {
          this.CheckAll = false;
        }
      });
    } else {
      this.CheckAll = false;
      this.selectedTrackerIds = this.selectedTrackerIds.filter(k => k !== item.GlobalDeviceId);
    }
  }

  onDownloadPDFClick() {
    this.detailStateSvc.loadExportSensorReport();
    const acceptType = 'application/pdf';
    const extraHeaders = new ExportTrackerStateReportHeaders();
    extraHeaders.Accept = acceptType;
    extraHeaders.responseType = responseType.blob;
    const request = new RouteDeviationReportRequest();
    request.TrackerIds = this.selectedTrackerIds;
    return this.service.RouteDeviationExceptions(request, extraHeaders).subscribe(response => {
      if (response.type === 'application/pdf') {
        this.displayErrorMessage = false;
        const blob = new Blob([response], {
          type: acceptType
        });
        const fileName = 'Route Deviation Exceptions.pdf';
        FileSaver.saveAs(blob, fileName);
      } else {
        const reader = new FileReader();
        reader.addEventListener('loadend', (e) => {
          // tslint:disable-next-line: deprecation
          const text = e.srcElement['result'];
          this.displayErrorMessage = true;
          if (text) {
            const data: ErrorResponse = JSON.parse(text);
            this.errorMessage = data.LocalizedErrorMessage;
          } else {
            this.errorMessage = 'An unexpected error occured [InternalError]';
          }
        });

        // Start reading the blob as text.
        reader.readAsText(response);
      }
      this.detailStateSvc.loadExportSensorReportSuccess();
      // this.IsDownloadInProgress = false;
    }, (e: IErrorInfo) => {
      this.detailStateSvc.loadExportSensorReportError();
      this.displayErrorMessage = true;
      this.errorMessage = e.message;
      // this.IsDownloadInProgress = false;
    });
  }

  onCheckAll(list: RouteDeviationListItem[]) {
    if (!this.CheckAll) {
      this.selectedTrackerIds = [];
    }
    list.forEach(a => {
      if (this.CheckAll) {
        this.selectedTrackerIds.push(a.GlobalDeviceId);
      }
      a.IsPdfDownload = this.CheckAll;
    });
  }
}
