import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ITrackerState } from 'app-modules/core/store/models/tracker-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';

import * as trackActions from 'app-modules/core/store/actions/tracker.actions';

@Injectable()
export class TrackerStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public trackerStateSelector = ((state: IAppState) => state.tracker);
    public isLoadRequiredSelector = createSelector(
        this.trackerStateSelector,
        (state: ITrackerState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.trackerStateSelector,
        (state: ITrackerState) => state.isLoading
    );
    public trackerListSelector = createSelector(
        this.trackerStateSelector,
        (state: ITrackerState) => state.trackerList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public trackerList$ = this.store.select(this.trackerListSelector).pipe();

    // Actions
    public loadTrackers() {
        this.store.dispatch(new trackActions.LoadTrackers());
    }

    public loadTrackersSuccess(trackerList: IListInfo<Tracker>) {
        this.store.dispatch(new trackActions.LoadTrackersSuccess(trackerList));
    }

    public loadTrackersError(message: string) {
        this.store.dispatch(new trackActions.LoadTrackersError(message));
    }

    public cancelLoadTrackers() {
        this.store.dispatch(new trackActions.CancelLoadTrackers());
    }
}

