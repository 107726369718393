import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShowDistanceRoutingModule } from './show-distance-routing.module';
import { ShowDistanceComponent } from './components/show-distance/show-distance.component';
import { ShowDistancePopupComponent } from './components/show-distance-popup/show-distance-popup.component';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { CoreModule } from 'app-modules/core/core.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ShowDistanceRoutingModule,
    EmrNgControlsModule
  ],
  declarations: [ShowDistanceComponent, ShowDistancePopupComponent],
  exports: [
    ShowDistanceComponent,
    ShowDistancePopupComponent
  ]
})
export class ShowDistanceModule { }

