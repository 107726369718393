import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmrNgControlsModule, EmrNgMapApiModule } from 'emr-ng-shared';
import { ImportLocationComponent } from './components/import-locations/import-location.component';
import { CoreModule } from 'app-modules/core/core.module';
import { LocationManagemntRoutingModule } from './location-management-routing.module';
import { ImportLocationService } from './services/import-locations.service';
import { ImportUpdateConfirmationComponent } from './components/import-update-confirmation/import-update-confirmation.component';
import { ManageLocationComponent } from './location/manage-location.component';
import { LocationContactComponent } from './location-contact/location-contact.component';
import { LocationsMapViewComponent } from './components/locations-map-view/locations-map-view.component';
import { LocationInfoBoxComponent } from './components/location-info-box/location-info-box.component';
import { NearestLocationsComponent } from './components/duplicate-locations/nearest-locations.component';


@NgModule({
    declarations: [
        ImportLocationComponent,
        ImportUpdateConfirmationComponent,
        ManageLocationComponent,
        LocationContactComponent,
        LocationsMapViewComponent,
        LocationInfoBoxComponent,
        NearestLocationsComponent
    ],
    imports: [
        CommonModule,
        LocationManagemntRoutingModule,
        CoreModule,
        EmrNgControlsModule,
        EmrNgMapApiModule
    ],
    providers: [
        ImportLocationService
    ],
    exports: [
        ImportLocationComponent,
        LocationManagemntRoutingModule,
        ManageLocationComponent
    ]
})
export class LocationManagementModule { }



