<emr-modal [bsModalRef]="bsShowIconInfoModalRef" headerTitle="Oversight 2 New Map Icons"
  i18n-headerTitle="@@link_new_icons_header" customHeaderClass="icon-info-header" (onCloseClick)="onPopupCancel()">
  <div modal-body class="col-md-12 icon-info">
    <div class="pl-3 pr-3">
      <div class="row">
        <div class="col-sm-6">
          <!-- <div class="icon-text">
            <p>
              <img src="{{oversightImageURL +  oversightCommonPath + 'late/20x20/s/truck.png'}}">
              <span i18n="@@label_truck_late_tracker_moving">Truck late - tracker moving</span>
            </p>
          </div>
          <div class="icon-text">
            <p>
              <img src="{{oversightImageURL +  oversightCommonPath + 'on-time/20x20/s/truck-stopped.png'}}">
              <span i18n="@@label_truck_late_tracker_stopped">Truck late - tracker stopped</span>
            </p>
          </div> -->
          <div class="icon-text">
            <p><img src="{{oversightImageURL +  oversightCommonPath + 'on-time/LT Icon 512 Truck-OK.png'}}">
              <span i18n="@@label_truck_ontime_tracker_moving">Truck on time - tracker moving</span>
            </p>
          </div>
          <div class="icon-text">
            <p>
              <img src="{{oversightImageURL +  oversightCommonPath + 'on-time/20x20/s/Hot-Crit.png'}}">
              <span i18n="@@label_temperature_above_range">Temperature above range</span>
            </p>
          </div>
          <div class="icon-text">
            <p>
              <img src="{{oversightImageURL +  oversightCommonPath + 'cte/small/AlertsEnvironmentalHumidity.png'}}">
              <span i18n="@@SensorType_Humidity">Humidity</span>
            </p>
          </div>
          <div class="icon-text">
            <p>
              <img src="{{oversightImageURL + oversightCommonPath + 'cte/BreadCrumbStart.png' }}">
              <span i18n="@@label_shipment_started">Shipment started</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + '20x20/deleted.png'}}">
              <span i18n="@@label_trip_completed">Trip completed</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="/assets/images/check.png">
              <span i18n="@@label_tracker_complete_deactivated">Tracker completed device deactivated</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/small/LightOn.png'}}">
              <span i18n="@@label_light_detected_alert">Light detected alert</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/Origin.svg'}}">
              <span i18n="@@label_origin">Origin</span>
            </p>
          </div>

          <div class="icon-text dwell-icon">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'icons/dwell-orange.svg'}}">
              <span i18n="@@label_tracker_reported_location_create_shipment">Tracker reported at a location setup
                through Create Shipment</span>
            </p>
          </div>
          <div class="icon-text dwell-icon">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'icons/dwell-yellow.svg'}}">
              <span i18n="@@label_tracker_reported_location">Tracker reported at a location setup in Oversight </span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/small/SensorReconnect.svg'}}">
              <span i18n="@@label_probe_reconnected">Probe Reconnected
              </span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'icons/dwell-lined.svg'}}">
              <span i18n="@@label_high_accuracy_location_coordinates">High Accuracy Location Coordinates</span>
            </p>
          </div>
        </div>
        <div class="col-sm-6 pl-50">
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'on-time/20x20/s/Truck.png'}}">
              <span i18n="@@label_truck_ontime_tracker_stopped">Truck on time - tracker stopped</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'on-time/20x20/s/Cold-Crit.png'}}">
              <span i18n="@@label_temperature_below_range">Temperature below range</span>
            </p>
          </div>
          
          <div class="icon-text">
            <p>
              <img src="{{oversightImageURL +  oversightCommonPath + 'cte/small/AlertsEnvironmentalCO2.png'}}">
              <span i18n="@@SensorType_CarbonDioxide">Carbon Dioxide</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/BreadCrumbEnd.png'}}">
              <span i18n="@@label_last_reported_info">Last reported info</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/small/AlertsWarning.png'}}">
              <span i18n="@@label_location_alert_detected">Location alert detected</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/small/Yard.png'}}">
              <span i18n="@@label_customer_saved_locations">Customer saved locations</span>
            </p>
          </div>
          <!-- <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'on-time/20x20/s/Safety-Light.png'}}">
              <span i18n="@@label_light_detected_shipment_open">Light detected shipment open</span>
            </p>
          </div> -->
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/small/TrackersAwol.png'}}">
              <span i18n="@@label_unit_cannot_connect_to_tower">Unit cannot connect to cell tower</span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/destination.svg'}}">
              <span i18n="@@label_destination">Destination</span>
            </p>
          </div>


          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'icons/dwell-estimated.svg'}}">
              <span i18n="@@label_tracker_estimated_location_coordinates">Tracker estimated location coordinates
              </span>
            </p>
          </div>

          <div class="icon-text dwell-icon">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'cte/small/SensorDisconnect.svg'}}">
              <span i18n="@@label_probe_disconnected">Probe Disconnected
              </span>
            </p>
          </div>
          <div class="icon-text">
            <p><img src="{{oversightImageURL + oversightCommonPath + 'icons/dwell.svg'}}">
              <span i18n="@@label_location_coordinates">Location Coordinates
              </span>
            </p>
          </div>       
        </div>
      </div>
    </div>
  </div>
</emr-modal>