<emr-modal headerTitle="Reset Password" i18n-headerTitle="@@link_resetpassword" [bsModalRef]=bsModalRef [showCloseButton]=showCloseButton>
    <div modal-body>
        <div *ngIf="hasError; else noError">
            <p>{{ errorMessage }}</p>
        </div>
        <ng-template #noError>
            <p i18n="@@reset_password_successfully">We have sent reset password instructions to the given E-Mail Id, please follow those steps to reset your password.
                <br>Thank you.</p>
        </ng-template>
        <div class="col-md-12 text-center">
            <emr-button identifier="forgotPasswordOk" cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok" (click)='onOkClick()'></emr-button>
        </div>
    </div>
</emr-modal>
