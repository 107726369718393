import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-total-records',
  templateUrl: './total-records.component.html',
  styleUrls: ['./total-records.component.css']
})
export class TotalRecordsComponent implements OnInit {
  @Input() itemCount: number;
  @Input() itemThreshold = 1000;
  @Input() skipThreshold = false;

  constructor() { }

  ngOnInit() {
  }

}
