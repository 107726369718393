import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { SetSensorRangeRequest } from 'emr-ng-shared';
import { SensorType } from 'emr-ng-shared';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { CreateShipmentStateService } from 'app-modules/core/store/create-shipment/create-shipment-state.service';
import { NgForm } from '@angular/forms';
import { SensorRangeService } from 'app-modules/core/store/sensor-range/sensor-range.service';
import {
  tempF, tempC, highCriticalCO2, highCriticalHumidity, highCriticalTemp,
  lowCriticalCO2, lowCriticalHumidity, lowCriticalTemp, ppm
} from 'app-modules/core/consts/localization';

export class SensorRangeEventArgs {
  request: SetSensorRangeRequest;
  context?: any;
}

@Component({
  selector: 'app-sensor-range',
  templateUrl: './sensor-range.component.html',
  styleUrls: ['./sensor-range.component.css']
})
export class SensorRangeComponent implements OnInit, OnDestroy {
  public error: IErrorInfo;
  displayWarnMsg = false;
  displayInvalidHighMsg = false;
  displayInvalidLowMsg = false;
  displayCriticalMsg = false;
  displayInvalidRangeMsg = false;
  @Output() addSensorRange = new EventEmitter<SensorRangeEventArgs>();
  @ViewChild(NgForm) sensorRangeForm: NgForm;
  request = new SetSensorRangeRequest();
  tempUnits: string;
  tempUnitString: string;
  context: any;
  createSensorRangeSubscription: Subscription;
  SensorType: any;
  Sensors;
  highCriticalLabel: string;
  lowCriticalLabel: string;
  units: string;
  preferenceSubscription: Subscription;

  constructor(
    public prefSvc: PreferenceService,
    private createSvc: CreateShipmentService,
    public bsSensorRangeModalRef: BsModalRef,
    private createStateSvc: CreateShipmentStateService,
    private sensorRangeSvc: SensorRangeService,
  ) { }

  ngOnInit() {
    this.Sensors = SensorType;
    // this.prefSvc.tempUnits$.pipe(take(1)).subscribe(n => this.tempUnits = n);
    this.preferenceSubscription = this.prefSvc.preference$.subscribe(n => {
      this.tempUnits = n.temperatureUnits;
      this.tempUnitString = n.temperatureUnitString;
      this.updateLabelsBasedType();
    });
  }

  ngOnDestroy() {
    if (this.preferenceSubscription) {
      this.preferenceSubscription.unsubscribe();
    }
  }

  onAddSensorRange() {
    this.error = null;
    if (this.sensorRangeForm.valid) {
      this.request.SensorTypeCode = this.SensorType;
      this.createSensorRangeSubscription = this.createStateSvc.selectedLinkedCustomer$.pipe(
        map(n => {
          if (n && n.CustomerId) {
            this.request.RetailerId = n.CustomerId;
          }
          return this.request;
        }),
        switchMap(n => {
          if (n && n.RetailerId) {
            return this.createSvc.createShipmentSetSensorRange(n);
          }
          return this.sensorRangeSvc.setSensorRange(this.request);
        }
        )).subscribe(
          n => this.addSensorRangeSuccess(n),
          (e: IErrorInfo) => this.addSensorRangeFailure(e)
        );
    }
  }

  addSensorRangeSuccess(id: number) {
    this.request.SensorRangeId = id;
    this.addSensorRange.emit({
      request: this.request,
      context: this.context
    });
    this.createSensorRangeSubscription.unsubscribe();
  }

  addSensorRangeFailure(error: IErrorInfo) {
    this.error = error;
    this.createSensorRangeSubscription.unsubscribe();
  }

  validateSensorRange() {
    // this.displayCriticalMsg = this.request.LowCritical && this.request.HighCritical &&
    //   Number(this.request.LowCritical) >= Number(this.request.HighCritical);

    // this.displayWarnMsg = this.request.LowWarn && this.request.HighWarn &&
    //   Number(this.request.LowWarn) >= Number(this.request.HighWarn);

    // this.displayInvalidLowMsg = this.request.LowWarn && this.request.LowCritical &&
    //   Number(this.request.LowWarn) < Number(this.request.LowCritical);

    // this.displayInvalidHighMsg = this.request.HighWarn && this.request.HighCritical &&
    //   Number(this.request.HighWarn) > Number(this.request.HighCritical);

    // for (const key in this.sensorRangeForm.controls) {
    //   this.sensorRangeForm.controls[key].markAsDirty();
    //   this.sensorRangeForm.controls[key].updateValueAndValidity();
    // }
    return true; // !(this.displayWarnMsg || this.displayCriticalMsg || this.displayInvalidLowMsg || this.displayInvalidHighMsg);
  }

  // To update the localized labels based on Sensor type
  updateLabelsBasedType() {
    switch (this.SensorType) {
      case SensorType.Temperature:
      case SensorType.ProbeTemp:
        {
          this.highCriticalLabel = highCriticalTemp;
          this.lowCriticalLabel = lowCriticalTemp;
          this.units = this.tempUnitString;
          break;
        }
      case SensorType.Humidity:
        {
          this.highCriticalLabel = highCriticalHumidity;
          this.lowCriticalLabel = lowCriticalHumidity;
          this.units = '%';
          break;
        }
      case SensorType.CarbonDioxide:
        {
          this.highCriticalLabel = highCriticalCO2;
          this.lowCriticalLabel = lowCriticalCO2;
          this.units = ppm;
          break;
        }
    }
  }
}
