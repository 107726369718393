import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, tap, switchMap, finalize, share, catchError, startWith, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { muteFirst } from 'emr-ng-shared';
import { GetSavedRoutesRequest, SaveRouteRequest, SaveRouteResponse } from 'emr-ng-shared';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { RoutesStateService } from './route-state.service';
import { of } from 'rxjs';
import * as actionIndex from 'app-modules/core/store/actions/index';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { GetSavedRoutesResponse, SavedRouteInfo } from 'emr-ng-shared';

@Injectable()
export class RoutesService {
    constructor(
        private store: Store<IAppState>,
        private oversightSvc: OversightApiService,
        private routeStateSvc: RoutesStateService) { }


    // private isLoadRequired$ = this.routeStateSvc.isLoadRequired$.pipe(
    //     filter(isloadRequired => isloadRequired),
    //     tap(() => this.store.dispatch({ type: actionIndex.LOAD_BUSINESS_RULES })),
    //     switchMap(() => this.getBusinessRulesList()),
    //     tap(responseData => this.store.dispatch({ type: actionIndex.LOAD_BUSINESS_RULES_SUCCESS, payload: responseData })),
    //     finalize(() => this.store.dispatch({ type: actionIndex.CANCEL_LOAD_BUSINESS_RULES })),
    //     share()
    // );

    public routesList$ = muteFirst(
        this.GetSavedRoutes().pipe(startWith(null)),
        this.routeStateSvc.routesList$
    );

    public GetSavedRoutes(): Observable<IListInfo<SavedRouteInfo>> {
        return this.routeStateSvc.isLoadRequired$.pipe(
            filter(isLoadRequired => isLoadRequired),
            tap(() => this.routeStateSvc.loadRoutes()),
            switchMap(() => {
                return this.getSavedRoutesList(new GetSavedRoutesRequest());
            }),
            tap(
                n => this.routeStateSvc.loadRoutesSuccess(n),
                e => this.routeStateSvc.loadRoutesError(e)
            ),
            finalize(() => this.routeStateSvc.cancelRoutes()),
            catchError(() => {
                this.routeStateSvc.cancelRoutes();
                return of(emptyList());
            }),
            share()
        );
    }

    private getSavedRoutesList(request: GetSavedRoutesRequest): Observable<IListInfo<SavedRouteInfo>> {
        return this.oversightSvc.getSavedRoutes(request).pipe(
            map(n => {
                return {
                    list: n.Result,
                    itemCount: n.Result.length,
                    isPaged: false
                };
            })
        );
    }

    public SaveRoute(request: SaveRouteRequest): Observable<SaveRouteResponse> {
        return of(null).pipe(
            tap(() => this.routeStateSvc.saveRoute()),
            switchMap(() => {
                return this.save(request);
            }),
            tap(
                n => this.routeStateSvc.saveRouteSuccess(),
                e => this.routeStateSvc.saveRouteError()
            ),
            finalize(() => this.routeStateSvc.saveRouteError()),
            catchError(() => {
                this.routeStateSvc.saveRouteError();
                return of(null);
            }),
        );
    }

    private save(request: SaveRouteRequest): Observable<SaveRouteResponse> {
        return this.oversightSvc.saveRoute(request).pipe(
            map(n => n));
    }

    public DeleteRoute(request: SaveRouteRequest): Observable<SaveRouteResponse> {
        return of(null).pipe(
            tap(() => this.routeStateSvc.deleteRoute()),
            switchMap(() => {
                return this.delete(request);
            }),
            tap(
                n => this.routeStateSvc.deleteRouteSuccess(request),
                e => this.routeStateSvc.deleteRouteError()
            ),
            finalize(() => this.routeStateSvc.deleteRouteError()),
            catchError(() => {
                this.routeStateSvc.deleteRouteError();
                return of(null);
            }),
        );
    }

    private delete(request: SaveRouteRequest): Observable<SaveRouteResponse> {
        return this.oversightSvc.deleteRoute(request).pipe(
            map(n => n));
    }

}


