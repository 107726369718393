<emr-modal id="btnFrmCreateShipment" class="scrollable-modal" headerTitle="Create Shipment"
    i18n-headerTitle="@@link_createshipment" [bsModalRef]=bsMainModalRef>
    <div modal-body>
        <form name="frmUCreateShipment" #uShipmentForm="ngForm" emrForm autocomplete="off">
            <div class="bg-data-div_CreateShipment mx-auto">
                <div class=" row">
                    <div class="col-md-12 vmargin-sm">
                        <div class="row">
                            <div class="col-md-12 create-shipment">
                                <p>
                                    <emr-typeahead ngDefaultControl identifier="txtUnAuthSerialNumber"
                                        name="txtUnAuthSerialNumber" [dataSource]="trackerList"
                                        label="Enter Serial Number" i18n-label="@@label_enter_serial_number"
                                        placeholder="Serial Number" i18n-placeholder="@@label_serial_number"
                                        [(ngModel)]="serialNumber" helpTip="Tracker Serial Number"
                                        (keydown.space)="$event.preventDefault();"
                                        i18n-helpTip="@@label_tracker_serial_number" required [inline]="true"
                                        labelColumns=3 controlColumns="9" typeaheadOptionFieldName="text"
                                        (ItemSelected)="onSerialNumberSelected($event)"
                                        (Blur)="onSerialNumberChange($event)"
                                        (NoResults)="NoResultsForSerialNumber($event)"
                                        i18n-Invalid_Tracker_Selected="@@Invalid_Tracker_Selected"
                                        Invalid_Tracker_Selected="Please select Locus Serial from the suggested Serial Numbers List."
                                        [InValidResults]="isInValidSerialNumber">
                                        <emr-validation-message validatorName="required"
                                            message="Locus Traxx Serial # Required" i18n-message="@@serial_required">
                                        </emr-validation-message>
                                    </emr-typeahead>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox ngDefaultControl identifier="txtShipmentName" name="txtShipmentName"
                                        label="{{labelEnterShipmentName}}" placeholder="{{labelShipmentName}}"
                                        [(ngModel)]="shipment.TripName" helpTip="{{labelShipmentName}}"
                                        required="{{!isRequired}}" [inline]=true labelColumns=3 textBoxColumns="9">
                                        <emr-validation-message validatorName="required"
                                            message="{{labelShipmentNameRequired}}">
                                        </emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <p>
                                    <span i18n="@@label_origin">Origin</span>
                                </p>
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox ngDefaultControl identifier="txtUOriginName" name="txtUOriginName"
                                        label="Enter Origin Name" i18n-label="@@label_enter_origin_name"
                                        placeholder="Origin Name" i18n-placeholder="@@label_origin_name"
                                        [(ngModel)]="shipment.OriginLocationName" helpTip="Origin Name"
                                        i18n-helpTip="@@label_origin_name" required="{{!isRequired}}" [inline]=true
                                        labelColumns=3 textBoxColumns="9">
                                        <emr-validation-message validatorName="required" message="Origin Name Required"
                                            i18n-message="@@origin_name_required"></emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 address-auto-complete" id="txtResultsContainer1">
                                <p>
                                    <!-- <emr-bing-autocomplete identifier="txtSearchLocation"
                                        label="Address Search" i18n-label="@@label_address_search"
                                        name="txtSearchLocation" containerId="txtResultsContainer1"
                                        bingApiKey=bingApiKey bingLocale=locale placeholder="Address Search"
                                        i18n-placeholder="@@label_address_search" (setAddress)="getAddress($event,'origin')"
                                        appendIcon="fa fa-search" cssClass="auto-complete" appendIconClass="input-icon"
                                        [inline]=true labelColumns=3 textBoxColumns="9">
                                    </emr-bing-autocomplete> -->
                                    <app-address-search identifier="originLocationAddressSearch"
                                        name="originLocationAddressSearch" label="Address Search"
                                        i18n-label="@@label_address_search" placeholder="Address Search"
                                        i18n-placeholder="@@label_address_search" appendIcon="fa fa-search" inline=true
                                        labelCssClass="col-md-3" textBoxDivCssClass="col-md-9"
                                        [resetAddress]="resetAddressField"
                                        [validationMessage]="selectOriginDistinctLocation"
                                        (addressSelected)="onAddressSelected($event,'origin')"
                                        (onBlur)="onAddressBlur($event,'origin')">
                                    </app-address-search>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-datetimepicker ngDefaultControl name="txtStartDate" identifier="txtStartDate"
                                        [(ngModel)]="startTime" label="Scheduled Start Time" required="{{!isRequired}}"
                                        [inline]=true [minDate]="defaultMinTime" i18n-label="@@scheduled_start_time"
                                        [displayValidationIndicator]="!isRequired" [showPickerOnTop]="true"
                                        helpTip="Scheduled Start Time" i18n-helpTip="@@scheduled_start_time"
                                        i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM" [inline]=true
                                        labelColumns=3 controlColumns="9" [containerValue]="''"
                                        [dateTimeObject]=dateTimeObject>
                                        <emr-validation-message validatorName="required"
                                            message="Departure Date/Time Required"
                                            i18n-message="@@departure_date_time_required"></emr-validation-message>
                                    </emr-datetimepicker>
                                </p>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <p>
                                    <span i18n="@@label_destination"> Destination</span>
                                </p>
                                <hr>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox ngDefaultControl identifier="txtUDestinationName"
                                        name="txtUDestinationName" label="Enter Destination Name"
                                        i18n-label="@@label_enter_destination_name" placeholder="Destination Name"
                                        i18n-placeholder="@@label_destination_name"
                                        [(ngModel)]="shipment.DestinationLocationName" helpTip="Destination Name"
                                        i18n-helpTip="@@label_destination_name" required="{{!isRequired}}" [inline]=true
                                        labelColumns=3 textBoxColumns="9">
                                        <emr-validation-message validatorName="required"
                                            message="Destination Name Required"
                                            i18n-message="@@destination_name_required"></emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 address-auto-complete" id="txtResultsContainer">
                                <p>
                                    <!-- <emr-bing-autocomplete
                                        identifier="destinationLocationAddressSearch"
                                        label="Address Search" i18n-label="@@label_address_search"
                                        name="destinationLocationAddressSearch"
                                        containerId="txtResultsContainer" bingApiKey=bingApiKey bingLocale=locale
                                        placeholder="Address Search" i18n-placeholder="@@label_address_search"
                                        (setAddress)="getAddress($event,'destination')" appendIcon="fa fa-search"
                                        cssClass="auto-complete" appendIconClass="input-icon" [inline]=true
                                        labelColumns=3 textBoxColumns="9">
                                    </emr-bing-autocomplete> -->
                                    <app-address-search identifier="destinationLocationAddressSearch"
                                        name="destinationLocationAddressSearch" label="Address Search"
                                        i18n-label="@@label_address_search" placeholder="Address Search"
                                        i18n-placeholder="@@label_address_search" appendIcon="fa fa-search" inline=true
                                        labelCssClass="col-md-3" textBoxDivCssClass="col-md-9"
                                        [resetAddress]="resetAddressField"
                                        [validationMessage]="selectDestinationDistinctLocation"
                                        (addressSelected)="onAddressSelected($event,'destination')"
                                        (onBlur)="onAddressBlur($event,'destination')">
                                    </app-address-search>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-datetimepicker ngDefaultControl name="txtEndDate" identifier="txtEndDate"
                                        [EmrMinDateValidator]="startTime" [(ngModel)]="endTime"
                                        [displayValidationIndicator]="!isRequired" label="Scheduled End Time"
                                        required="{{!isRequired}}" [inline]=true [dateTimeObject]=dateTimeObject
                                        i18n-label="@@scheduled_end_time"
                                        [minDate]="startTime ? startTime : defaultMinTime" [showPickerOnTop]="true"
                                        helpTip="Scheduled End Time" i18n-helpTip="@@scheduled_end_time"
                                        i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM" [inline]=true
                                        labelColumns=3 controlColumns="9" [containerValue]="''">
                                        <emr-validation-message validatorName="required"
                                            message="Arrival Date/Time Required"
                                            i18n-message="@@departure_date_time_required"></emr-validation-message>
                                        <emr-validation-message validatorName="EmrMinDateValidator"
                                            i18n-message="@@greater_end_time"
                                            message="Scheduled End time must be greater than Start time">
                                        </emr-validation-message>
                                    </emr-datetimepicker>
                                </p>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <p>
                                    <span i18n="@@label_sensor_ranges">Sensor Ranges</span>
                                </p>
                                <hr>
                            </div>
                        </div>
                        <div *ngIf="sensorRangeList$ | async as sensors">
                            <div class="row" *ngIf="!shipment.NoTemperatureRequired">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstSensorRange"
                                            name="lstSensorRange" [options]="sensors.Temperature"
                                            [(ngModel)]="TemperatureRangeId" cssClass="default-dropdown"
                                            label="Temperature Range" i18n-label="@@label_temperature_range"
                                            optionsValueField="SensorRangeId" optionsTextField="Description"
                                            helpTip="Temperature Range" i18n-helpTip="@@label_temperature_range"
                                            placeholder="Select Temperature Range"
                                            [displayValidationIndicator]="!isRequired"
                                            i18n-placeholder="@@select_temperature_range" required="{{!isRequired}}"
                                            [inline]=true labelColumns=3 controlColumns="{{controlColumns}}"
                                            validationIndicatorRequired="{{!isRequired}}" [createNewItem]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Temperature)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                            <emr-validation-message validatorName="required"
                                                message="Temperature Range Required"
                                                i18n-message="@@temperature_range_required"></emr-validation-message>
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="showHumiditySensor">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstHumiditySensorRange"
                                            name="lstHumiditySensorRange" [options]="sensors.Humidity"
                                            [(ngModel)]="HumiditySensorId" cssClass="default-dropdown" label="Humidity"
                                            i18n-label="@@label_humidity" optionsValueField="SensorRangeId"
                                            optionsTextField="Description" helpTip="Humidity"
                                            i18n-helpTip="@@label_humidity" placeholder="Select Humidity"
                                            i18n-placeholder="@@select_humidity" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [createNewItem]=true
                                            [validationIndicatorRequired]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Humidity)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="showCO2Sensor">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstCO2SensorRange"
                                            name="lstCO2SensorRange" [options]="sensors.CarbonDioxide"
                                            [(ngModel)]="CarbonDioxideSensorId" cssClass="default-dropdown" label="CO2"
                                            i18n-label="@@label_carbondioxide" optionsValueField="SensorRangeId"
                                            optionsTextField="Description" helpTip="CO2"
                                            i18n-helpTip="@@label_carbondioxide" placeholder="Select CO2"
                                            i18n-placeholder="@@select_co2" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [createNewItem]=true
                                            [validationIndicatorRequired]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.CarbonDioxide)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="showProbSensor">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstProbSensorRange"
                                            name="lstProbSensorRange" [options]="sensors.ProbeTemp"
                                            [(ngModel)]="ProbSensorId" cssClass="default-dropdown" label="Prob Temp"
                                            i18n-label="@@label_probetemp" optionsValueField="SensorRangeId"
                                            optionsTextField="Description" helpTip="Prob Temp"
                                            i18n-helpTip="@@label_probetemp" placeholder="Select Prob Temp"
                                            i18n-placeholder="@@select_probtemp" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [createNewItem]=true
                                            [validationIndicatorRequired]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.ProbeTemp)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <p>
                                    <span i18n="@@label_additional_alert_contacts_optional">Additional Alert Contacts
                                        (OPTIONAL)
                                    </span>
                                </p>
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox ngDefaultControl identifier="txtCarrierEmails" EmrEmailValidator
                                        name="txtCarrierEmails" label="Carrier Email(s)"
                                        i18n-label="@@label_carrier_emails" placeholder="Carrier Email(s)"
                                        i18n-placeholder="@@label_carrier_emails" [(ngModel)]="shipment.EmailAddresses"
                                        name="txtCarrierEmails"
                                        helpText="To add multiple emails, type email addresses separated with commas."
                                        i18n-helpText="@@label_carrier_email_help_text" helpTip="Carrier Email(s)"
                                        i18n-helpTip="@@label_carrier_emails" [inline]=true labelColumns=3
                                        textBoxColumns="9">
                                        <emr-validation-message validatorName="EmrEmailValidator"
                                            i18n-message="@@validation_email" message="Please enter valid Email	">
                                        </emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <label class="label-css col-md-3" for="chklstEscalationLevels">
                                <span class="cursor-pointer" i18n-tooltip="@@label_alert_escalation_levels"
                                    tooltip="Alert Escalation Levels" placement="top"
                                    i18n="@@label_alert_escalation_levels">
                                    Alert Escalation Levels</span>
                            </label>
                            <div class="col-md-9">
                                <div class="row mlr-0">
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_1"
                                            name="chklstEscalationLevels_1" text="Level 1"
                                            i18n-text="@@AlertEscalationLevel_1" inline=true [(ngModel)]="shipment.L1">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_2"
                                            name="chklstEscalationLevels_2" text="Level 2"
                                            i18n-text="@@AlertEscalationLevel_2" inline=true [(ngModel)]="shipment.L2">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_3"
                                            name="chklstEscalationLevels_3" text="Level 3"
                                            i18n-text="@@AlertEscalationLevel_3" inline=true [(ngModel)]="shipment.L3">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_4"
                                            name="chklstEscalationLevels_4" text="Level 4"
                                            i18n-text="@@AlertEscalationLevel_4" inline=true [(ngModel)]="shipment.L4">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_5"
                                            name="chklstEscalationLevels_5" text="Level 5"
                                            i18n-text="@@AlertEscalationLevel_5" inline=true [(ngModel)]="shipment.L5">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_6"
                                            name="chklstEscalationLevels_6" text="Level 6"
                                            i18n-text="@@AlertEscalationLevel_6" inline=true [(ngModel)]="shipment.L6">
                                        </emr-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <p>
                                    <emr-button identifier="createShipment" buttonType="submit" buttonText="Submit"
                                        i18n-buttonText="@@link_submit" cssClass="btn-outline-default submit-btn"
                                        (onClick)="onCreateShipmentSave()" buttonIcon="fa fa-check"></emr-button>
                                    <emr-button identifier="resetShipment" buttonType="reset" buttonText="Reset"
                                        i18n-buttonText="@@link_reset" cssClass="btn-outline-default reset-btn"
                                        (onClick)="ResetForm()" buttonIcon="fa fa-repeat"> </emr-button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</emr-modal>
<ng-template #createShipmentResponseTemplate>
    <emr-modal headerTitle="Create Shipment" i18n-headerTitle="@@link_createshipment" [showCloseButton]=false>
        <div modal-body>
            <div class="col-md-12 m-1">
                <div *ngIf="hasError; else noError">
                    <p>{{ errorMessage }}</p>
                </div>
                <div *ngIf="hasError && debugErrorMessage">
                    <hr>
                    <p>{{ debugErrorMessage }}</p>
                </div>
                <ng-template #noError>
                    <!-- <p i18n="@@shipment_created_successfully">Shipment created successfully</p> -->
                    <p i18n="@@message_shipment_created_processing">Shipment created successfully and may take up to two
                        minutes to display in the List View</p>
                </ng-template>
            </div>
            <div class="col-12 text-center">
                <emr-button identifier="unAuthCreateShipmentResponse" buttonType="button" buttonText="Ok"
                    i18n-buttonText="@@link_ok" (click)='onResponseOkClick()'
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                </emr-button>
            </div>
        </div>
    </emr-modal>
</ng-template>
<ng-template #duplicateLocTemplate>
    <emr-modal headerTitle="Warning" i18n-headerTitle="@@alert_severity_warning" [showCloseButton]=false>
        <ng-container modal-body>
            <div class="px-2">
                <div class="row">
                    <div class="col-md-12">
                        <label class="label-css" i18n="@@label_matching_locations">
                            Matching Location(s) found
                        </label>
                        <hr class="m-1">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <label class="label-css" i18n="@@select_location_name_required">
                            Please select a Location</label>
                        <hr class="m-1">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 ">
                        <div class="card custom-card m-2 {{selectedIndex===i+1?'active':''}}"
                            *ngFor="let loc of nearestLocations| slice:0:5; let i = index;" (click)="selectedIndex=i+1">
                            <div class="card-body p-2 pl-4">
                                <div class="row">
                                    <div class="ml-n1 mr-1" *ngIf="selectedIndex===i+1">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                    </div>
                                    <div>
                                        <div *ngIf="loc.LocationName?.trim()?.length > 0" class="label-css">
                                            <span>{{loc.LocationName}}</span>
                                        </div>
                                        <div *ngIf="loc.Address1?.trim()?.length > 0" class="label-css">
                                            <span>{{loc.Address1.trim()+', '}}</span>
                                        </div>
                                        <div class="label-css">
                                            <span *ngIf="loc.City?.trim()?.length > 0">{{loc.City.trim()+',
                                                '}}</span>
                                            <span *ngIf="loc.State?.trim()?.length > 0">{{loc.State.trim()+'
                                                '}}</span>
                                            <span *ngIf="loc.Zip?.trim()?.length > 0">{{loc.Zip.trim()}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <hr class="m-1">
                        <label class="label-css" i18n="@@continue_create_location">
                            OR continue to create new Location</label>
                    </div>
                </div>
                <div class="card custom-card m-2 {{selectedIndex===0?'active':''}}" (click)="selectedIndex=0">
                    <div class="card-body p-2 pl-4">
                        <div class="row">
                            <div class="ml-n1 mr-1" *ngIf="selectedIndex===0">
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </div>
                            <div>
                                <div *ngIf="newLocRequest.Name" class="label-css">
                                    <span>{{newLocRequest.Name.trim()}}</span>
                                </div>
                                <div *ngIf="newLocRequest.Address1" class="label-css">
                                    <span>{{newLocRequest.Address1.trim()+', '}}</span>
                                </div>
                                <div class="label-css">
                                    <span *ngIf="newLocRequest.City">{{newLocRequest.City.trim()+', '}}</span>
                                    <span *ngIf="newLocRequest.State">{{newLocRequest.State.trim()+' '}}</span>
                                    <span *ngIf="newLocRequest.ZipCode">{{newLocRequest.ZipCode.trim()}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-12">
                        <emr-button identifier="continue_location" [setDisabled]="selectedIndex===null"
                            buttonType="submit" cssClass="btn-lg btn-primary quick-link btn-block m-0 mt-2"
                            buttonText="Continue" i18n-buttonText="@@label_continue" buttonIcon="fa fa-Plus"
                            (onClick)="onContinueClick()">
                        </emr-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </emr-modal>
</ng-template>