import { Action } from '@ngrx/store';

import { CloseTripRequest, CreateShipmentRequest, SetShutDownTrackerRequest, SetTripSensorRangeRequest } from 'emr-ng-shared';
import { UpdateShipmentRequest } from 'emr-ng-shared';
import { Shipment as DBShipment } from 'emr-ng-shared';


import { UpdateShipment as UpdateShipmentModel } from 'app-modules/core/models/update-shipment.model';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadShipments implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SHIPMENTS;
}

export class LoadShipmentsSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENTS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHIPMENTS;
    constructor(public shipmentList: IListInfo<Shipment>) { }
}

export class LoadShipmentsError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENTS_ERROR;
    readonly callingAction = actionIndex.LOAD_SHIPMENTS;
    constructor(public message: string) { }
}

export class CancelLoadShipments implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_SHIPMENTS;
    readonly callingAction = actionIndex.LOAD_SHIPMENTS;
}

export class AutoRefreshShipments implements Action {
    readonly type = actionIndex.AUTO_REFRESH_SHIPMENTS;
}

export class AutoRefreshShipmentsSuccess implements Action {
    readonly type = actionIndex.AUTO_REFRESH_SHIPMENTS_SUCCESS;
    constructor(public shipmentList: IListInfo<Shipment>) { }
}

export class AutoRefreshShipmentsError implements Action {
    readonly type = actionIndex.AUTO_REFRESH_SHIPMENTS_ERROR;
    constructor(public message: string) { }
}

export class CancelAutoRefreshShipments implements Action {
    readonly type = actionIndex.CANCEL_AUTO_REFRESH_SHIPMENTS;
}

export class LoadShipmentAddress implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SHIPMENT_ADDRESS;
}

export class LoadShipmentAddressSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_ADDRESS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_ADDRESS;
    constructor(public shipmentList: IListInfo<Shipment>) { }
}

export class LoadShipmentAddressError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_ADDRESS_ERROR;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_ADDRESS;
    constructor(public message: string) { }
}

export class UpdateShipmentETA implements ServerRequestAction {
    readonly type = actionIndex.UPDATE_SHIPMENT_ETA;
}

export class UpdateShipmentETASuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.UPDATE_SHIPMENT_ETA_SUCCESS;
    readonly callingAction = actionIndex.UPDATE_SHIPMENT_ETA;
    constructor(public shipment: DBShipment) { }
}

export class UpdateShipmentETAError implements ServerRequestCompleteAction {
    readonly type = actionIndex.UPDATE_SHIPMENT_ETA_ERROR;
    readonly callingAction = actionIndex.UPDATE_SHIPMENT_ETA;
    constructor(public message: string) { }
}

export class CreateShipment implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT;

    constructor(public request: CreateShipmentRequest) { }
}

export class CreateShipmentSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT;

    constructor(public shipmentId: number) { }
}

export class CreateShipmentError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT;

    constructor(public message: string) { }
}

export class UpdateShipment implements ServerRequestAction {
    readonly type = actionIndex.UPDATE_SHIPMENT;

    constructor(public request: UpdateShipmentRequest) { }
}

export class UpdateShipmentSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.UPDATE_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.UPDATE_SHIPMENT;
    constructor(public shipmentId: number) { }
}

export class UpdateShipmentError implements ServerRequestCompleteAction {
    readonly type = actionIndex.UPDATE_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.UPDATE_SHIPMENT;
    constructor(public message: string) { }
}


export class CloseShipment implements ServerRequestAction {
    readonly type = actionIndex.CLOSE_SHIPMENT;
    constructor() { }
}

export class CloseShipmentSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CLOSE_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.CLOSE_SHIPMENT;
    constructor() { }
}

export class CloseShipmentError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CLOSE_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.CLOSE_SHIPMENT;
    constructor(public message: string) { }
}


export class LoadShipmentbyId implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SHIPMENTS_BYID;
}

export class LoadShipmentbyIdSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENTS_BYID_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHIPMENTS_BYID;
    constructor(public shipment: Shipment) { }
}

export class LoadShipmentbyTrackerIdSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENTS_BYTRACKERID_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHIPMENTS_BYID;
    constructor(public shipments: Shipment[]) { }
}

export class LoadShipmentbyIdError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENTS_BYID_ERROR;
    readonly callingAction = actionIndex.LOAD_SHIPMENTS_BYID;
    constructor(public message: string) { }
}

export class CancelLoadShipmentbyID implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_SHIPMENTS_BYID;
    readonly callingAction = actionIndex.LOAD_SHIPMENTS_BYID;
}
export class LoadShipmentBySerial implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SHIPMENT_BY_SERIAL;
}

export class LoadShipmentBySerialSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_BY_SERIAL_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_BY_SERIAL;
    constructor(public shipmentList: IListInfo<Shipment>) { }
}

export class LoadShipmentBySerialError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_BY_SERIAL_ERROR;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_BY_SERIAL;
    constructor(public message: string) { }
}

export class CancelLoadShipmentBySerial implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_SHIPMENT_BY_SERIAL;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_BY_SERIAL;
}

export class ReloadShipments implements ServerRequestAction {
    readonly type = actionIndex.RELOAD_SHIPMENTS;
}

export class UpdateShipmentSuspendResumeState implements Action {
    readonly type = actionIndex.UPDATE_SHIPMENT_SUSPEND_RESUME_STATE;
    constructor(public trackerId: string,public tripId: number,public isSuppressed : boolean) { }
}

export class SetTripSensorRange implements ServerRequestAction {
    readonly type = actionIndex.SET_TRIP_SENSOR_RANGE;

    constructor(public request: SetTripSensorRangeRequest) { }
}

export class SetTripSensorRangeSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_TRIP_SENSOR_RANGE_SUCCESS;
    readonly callingAction = actionIndex.SET_TRIP_SENSOR_RANGE;
    constructor() { }
}

export class SetTripSensorRangeError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_TRIP_SENSOR_RANGE_ERROR;
    readonly callingAction = actionIndex.SET_TRIP_SENSOR_RANGE;
    constructor(public message: string) { }
}


export class EditShipment implements ServerRequestAction {
    readonly type = actionIndex.EDIT_SHIPMENT;

    constructor(public request: UpdateShipmentRequest) { }
}

export class EditShipmentSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.EDIT_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.EDIT_SHIPMENT;
    constructor(public shipmentId: number) { }
}

export class EditShipmentError implements ServerRequestCompleteAction {
    readonly type = actionIndex.EDIT_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.EDIT_SHIPMENT;
    constructor(public message: string) { }
}

export class UpdateShipmentByTrackerID implements ServerRequestAction {
    readonly type = actionIndex.Update_SHIPMENT_BY_TRACKERID;

    constructor(public shipment: UpdateShipmentModel) { }
}

export class DeleteShipment implements ServerRequestAction {
    readonly type = actionIndex.DELETE_SHIPMENT;
    constructor() { }
}

export class DeleteShipmentSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.DELETE_SHIPMENT;
    constructor(public deleteReq: CloseTripRequest) { }
}

export class DeleteShipmentError implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.DELETE_SHIPMENT;
    constructor(public message: string) { }
}

export class SaveTrackerNote implements ServerRequestAction {
    readonly type = actionIndex.SAVE_TRACKER_NOTE;
    constructor() { }
}

export class SaveTrackerNoteSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_TRACKER_NOTE_SUCCESS;
    readonly callingAction = actionIndex.SAVE_TRACKER_NOTE;
    constructor() { }
}

export class SaveTrackerNoteError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_TRACKER_NOTE_ERROR;
    readonly callingAction = actionIndex.SAVE_TRACKER_NOTE;
    constructor(public message: string) { }
}

export class SaveShutdownTracker implements ServerRequestAction {
    readonly type = actionIndex.SAVE_SHUTDOWN_TRACKER;
    constructor() { }
}
export class SaveShutdownTrackerSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_SHUTDOWN_TRACKER_SUCCESS;
    readonly callingAction = actionIndex.SAVE_SHUTDOWN_TRACKER;
    constructor(public saveShutDownTrackerReq: SetShutDownTrackerRequest) { }
}

export class SaveShutdownTrackerError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_SHUTDOWN_TRACKER_ERROR;
    readonly callingAction = actionIndex.SAVE_SHUTDOWN_TRACKER;
    constructor(public message: string) { }
}
export class ShareTracker implements ServerRequestAction {
    readonly type = actionIndex.SHARE_TRACKER;
    constructor() { }
}

export class ShareTrackerSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHARE_TRACKER_SUCCESS;
    readonly callingAction = actionIndex.SHARE_TRACKER;
    constructor() { }
}

export class ShareTrackerError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHARE_TRACKER_ERROR;
    readonly callingAction = actionIndex.SHARE_TRACKER;
    constructor(public message: string) { }
}