import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as locActions from 'app-modules/core/store/actions/location.actions';
import { ILocationColorState } from '../models/location-color-list-state.interface';

type Actions =
    authActions.SignOut |
    locActions.LoadLocationColorList |
    locActions.LoadLocationColorListError |
    locActions.LoadLocationColorListSuccess |
    locActions.CancelLoadLocationColorList;

export function LocationColorListReducer(state: ILocationColorState = initialState.locationColorList, action: Actions): ILocationColorState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return {
                ...state,
                isLoadRequired: true,
                isLoading: false,
                locationColorList: emptyList(),
            };

        case actionIndex.LOAD_LOCATION_COLOR_LIST:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_LOCATION_COLOR_LIST_SUCCESS:
            return {
                ...state,
                locationColorList: action.locationColorList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_LOCATION_COLOR_LIST_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
