import { ShipmentInfo2 } from 'emr-ng-shared';

import { ChartInfo2 } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTrackerStateChart implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART;
}

export class LoadTrackerStateChartSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART;
    constructor(
        public summary: ShipmentInfo2,
        public chartData: IListInfo<ChartInfo2>
    ) { }
}

export class LoadTrackerStateChartError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART;

    constructor(public message: string) { }
}

export class CancelLoadTrackerStateChart implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER_STATE_CHART;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART;
}

