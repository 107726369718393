import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from 'environments/environment';
import { intl } from 'environments/intl';

import { LocalePersistence } from 'app-modules/core/utils/locale-persistence';
import { AuthService } from 'app-modules/core/store/auth/auth.service';

@Component({
    selector: 'app-locale',
    templateUrl: './locale.component.html',
    styleUrls: ['./locale.component.css']
})
export class LocaleComponent {

    constructor(private route: ActivatedRoute, private authSvc: AuthService) { }

    onLocaleSelected(locale: string) {
        const localePersistence = new LocalePersistence(environment.localesPersistKey);
        localePersistence.persistLocale(locale);

        const qMap = this.route.snapshot.queryParamMap;
        const externalAuthToken = qMap.get('a');

        let redirectUrl = '/';

        if (externalAuthToken) {
            if (!redirectUrl.endsWith('/')) {
                redirectUrl += '/';
            }

            redirectUrl += 'signin/' + encodeURIComponent(externalAuthToken);
            if (qMap.keys.length > 1) {
                const params = qMap.keys.filter((k) => k !== 'a').map((k) => `${k}=${qMap.get(k)}`).join('&');
                redirectUrl += `?${params}`;
            }
        }
        const requestedParams = this.authSvc.getRequestedParams();
        if (requestedParams) {
            if (!redirectUrl.endsWith('/')) {
                redirectUrl += '/';
            }
            redirectUrl += locale + '/createshipment?customer=' + requestedParams.customer + '&id=' + requestedParams.id;
        }

        window.location.href = redirectUrl;
    }
}
