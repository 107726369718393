<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-6 col-10 mx-auto col-10-max">
    <div class="text-left p-4 padding-sm-1">
      <h6>
        <span i18n="@@label_resetpasswordcontent">Please enter your email address to receive an email for instructions to reset
          password.</span>
      </h6>
      <form class="pt-3" modal-body name="frmfrgtpwd" #forgotPasswordForm="ngForm" emrForm autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <emr-textbox identifier="txtUsername" placeholder="Email" i18n-placeholder="@@label_email"
              [(ngModel)]="username" name="txtUsername" cssClass="sign-in-input" appendIcon="fa fa-user"
              appendIconClass="input-icon" inputType="email" required
              pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
              <emr-validation-message validatorName="pattern" message="Please enter valid email"
                i18n-message="@@validation_email"></emr-validation-message>
              <emr-validation-message validatorName="required" message="Please enter username"
                i18n-message="@@validation_username_required"></emr-validation-message>
            </emr-textbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>
              <emr-button identifier="forgotPassword_1" buttonType="submit" cssClass="font-14 btn-lg btn-primary quick-link btn-block m-0 mt-2"
                buttonText="Submit" i18n-buttonText="@@link_submit" buttonIcon=""
                (onClick)="onForgotPasswordClick($event)"></emr-button>
            </p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <span class="validationindicator">
              <div class="forgotpassword-validation my-1" *ngIf="error!=null">
                <div>{{error}}</div>
              </div>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>