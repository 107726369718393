import { Injectable, OnDestroy } from '@angular/core';

import { Observable, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import { of } from 'rxjs';
import { tap, map, switchMap, filter, finalize, share, catchError, take, startWith, shareReplay, withLatestFrom, repeat } from 'rxjs/operators';

import {
    GetLocationRequest, SetLocationResponse, DualVisibilityLinkCustomer, GetSensorRangeResponse, LocationInfo, muteFirst,
    TripTemplateInfo, SensorRangeInfo, GetSensorRangeRequest, GetCreateShipmentCarrierRequest, GetTripTemplateRequest,
    CarrierListInfo, SetSensorRangeRequest, SensorType, GetTrackerSerialsListRequest, SetLocationRequest,
    SetSensorRangeResponse, ValidateDVShipmentRequest, ValidateDVShipmentResponse, GetTripTemplateDetailsResponse,
    GetTripTemplateDetailsRequest, TrackerSerialStatus, ShipmentRequest, GetLocationResponse, TripStop, TripStopTypeCode,
    TripContact, GetTrackerResponse, SetTrackerSensorRangeRequest, SetTrackerSensorRangeResponse,
    LocationTypeCode, GetLookUpRequest, LookupResponse, Lookup, SetLookUpRequest
} from 'emr-ng-shared';

import { IListInfo, emptyList, setListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { CreateShipmentStateService } from 'app-modules/core/store/create-shipment/create-shipment-state.service';
import { CustomerStateService } from 'app-modules/core/store/services/customer-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { ShipmentStateService } from 'app-modules/core/store/services/shipment-state.service';
import { Tracker } from 'app-modules/core/models/tracker.model';
import { handleErrorResponse } from '../rxjs/operators/handle-error-response.operator';
import { SensorRangeService } from 'app-modules/core/store/sensor-range/sensor-range.service';
import { LocationService } from 'app-modules/core/services/location.service';
import { LocationStateService } from 'app-modules/core/store/services/location-state.service';
import { SensorRangeStateService } from 'app-modules/core/store/sensor-range/sensor-range-state.service';
import { ShipmentTemplateService } from '../store/shipment-template/shipment-template.service';
import { ShipmentTemplateStateService } from '../store/shipment-template/shipment-template-state.service';
import { TripStopModel } from '../store/models/trip-stop.model';
import * as _ from 'lodash';
import { UnAuthStateService } from '../store/un-auth/un-auth-state.service';

export class ListInfo {
    List: IListInfo<any>;
    isLoading: boolean;
    constructor(list, isLoad: boolean) {
        this.List = list;
        this.isLoading = isLoad;
    }
}
@Injectable()
export class CreateShipmentService implements OnDestroy {

    private prevCustId = null;
    private unAuthCustomerSub: Subscription;
    private isSensorRangeActivated = true;

    constructor(
        private createStateSvc: CreateShipmentStateService,
        private custStateSvc: CustomerStateService,
        private oversightSvc: OversightApiService,
        private shipStateSvc: ShipmentStateService,
        private sensorSvc: SensorRangeService,
        private locationSvc: LocationService,
        private unAuthStateSvc: UnAuthStateService,
        private locationStateSvc: LocationStateService,
        private sensorStateSvc: SensorRangeStateService,
        private templateSvc: ShipmentTemplateService,
        private shipmentTemplateStateSvc: ShipmentTemplateStateService

    ) {
        this.unAuthCustomerSub = this.unAuthStateSvc.unAuthCustomer$.subscribe(c => {
            if (c && c.CustomerId !== this.prevCustId) {
                this.activateLoadSensors(true);
                this.prevCustId = c.CustomerId;
            }
        });
    }

    private retailLocationSubscription: Subscription;
    private retailsensorSubscription: Subscription;
    public isTrackerLoadRequired$ = this.createStateSvc.isTrackerLoadRequired$;
    private isLoadRequiredSub = new BehaviorSubject(true);
    private isLoadRequiredSensorsSub = new BehaviorSubject(true);
    private isLoadingSensorsSub = new BehaviorSubject(true);
    private isLoadingLocationsSub = new BehaviorSubject(true);
    private isLoadingTemplatesSub = new BehaviorSubject(true);
    private isLoadingTrackerPullListSub = new BehaviorSubject(true);
    private retailerLocationsListSub = new BehaviorSubject<IListInfo<LocationInfo>>(emptyList());
    private retailerSensorRangeListSub = new BehaviorSubject<IListInfo<SensorRangeInfo>>(emptyList());

    public isLoadRequired$ = this.isLoadRequiredSub.asObservable();
    private retailerLocations$ = this.retailerLocationsListSub.asObservable();
    private retailerLocationsRequest$ = this.getRetailerLocationsRequest();

    public isSensorsLoadRequired$ = this.isLoadRequiredSensorsSub.asObservable();
    public isLoadingSensorsList$ = this.isLoadingSensorsSub.asObservable();
    public isLoadingLocationsList$ = this.isLoadingLocationsSub.asObservable();
    public isLoadingTemplatesList$ = this.isLoadingTemplatesSub.asObservable();
    public isLoadingTrackerPullList$ = this.isLoadingTrackerPullListSub.asObservable();

    private retailerLocationsList$ = muteFirst(
        this.retailerLocationsRequest$,
        this.retailerLocations$
    );

    private retailerSensorRanges$ = this.retailerSensorRangeListSub.asObservable();
    private retailerSensorRangesRequest$ = this.getRetailerSensorRangesRequest();

    private retailerSensorRangesList$ = muteFirst(
        this.retailerSensorRangesRequest$,
        this.retailerSensorRanges$
    );

    public locationList$: Observable<IListInfo<LocationInfo>> = this.getLocationListLoader();

    public originList$: Observable<IListInfo<LocationInfo>> = this.getOriginLocationList();

    public stopList$: Observable<IListInfo<LocationInfo>> = this.getStopLocationList();

    public destinationList$: Observable<IListInfo<LocationInfo>> = this.getDestinationLocationList();

    public isTrackerLoading$ = this.createStateSvc.isTrackerLoading$;

    public trackerList$: Observable<IListInfo<Tracker>> = muteFirst(
        this.getTrackerListLoader().pipe(startWith(null)),
        this.createStateSvc.trackerList$
    );

    public sensorRangeList$: Observable<_.Dictionary<SensorRangeInfo[]>> = this.getSensorRangeListLoader().pipe(map(k => {
        return _.groupBy(k.list, 'SensorType');
    }));

    public carrierList$: Observable<Lookup[]> = muteFirst(
        this.getCarrierListLoader().pipe(startWith(null)),
        this.createStateSvc.carrierList$
    );

    public activateLoadSensors(loadRequired: boolean) {
        this.isLoadRequiredSensorsSub.next(loadRequired);
        this.isSensorRangeActivated = loadRequired;
    }

    public initLoadSensors() {
        if (!this.isSensorRangeActivated) {
            this.activateLoadSensors(true);
        }
    }

    private isLoadingSensorsList(isLoading: boolean) {
        this.isLoadingSensorsSub.next(isLoading);
    }

    public activateLoadLocations(loadRequired: boolean) {
        this.isLoadRequiredSub.next(loadRequired);
    }

    private isLoadingLocationsList(isLoading: boolean) {
        this.isLoadingLocationsSub.next(isLoading);
    }

    private isLoadingTemplatesList(isLoading: boolean) {
        this.isLoadingTemplatesSub.next(isLoading);
    }

    private isLoadingTrackerPullList(isLoading: boolean) {
        this.isLoadingTrackerPullListSub.next(isLoading);
    }


    public selectLinkedCustomer(linkedCustomer: DualVisibilityLinkCustomer | null = null) {
        if (linkedCustomer) {
            this.oversightSvc.PreLoadCustomerCache(linkedCustomer.CustomerId).pipe(
                take(1),
                map(() => this.createStateSvc.selectLinkedCustomer(linkedCustomer))
            ).subscribe();
        } else {
            this.createStateSvc.selectLinkedCustomer(linkedCustomer);
        }
    }

    public getUnAuthenticationToken(): boolean {
        return this.oversightSvc.getUnAuthenticationToken();
    }

    public createShipmentLocation(location: SetLocationRequest): Observable<SetLocationResponse> {
        return of(location).pipe(
            tap(n => this.createStateSvc.createLocation(n)),
            switchMap(n => this.oversightSvc.SetLocation(n)),
            handleErrorResponse(),
            map<SetLocationResponse, SetLocationResponse>(
                n => n
            ),
            tap(
                n => {
                    if (n.LocationId === 0) { return; }
                    const newLoc = this.createStateSvc.createLocationSuccess(n.LocationId, location);
                    if (!n.LocationId) { return; }
                    this.retailLocationSubscription = this.retailerLocations$.pipe(
                        take(1),
                        withLatestFrom(this.custStateSvc.selectedContext$)
                    ).subscribe(([lst, context]) => {
                        const newList = emptyList();
                        const newLocations = lst.list.splice(0);
                        if (location.LinkedCustomerId &&
                            location.PassShipperCustomerID) {
                            newLoc.LinkedCustomerId = context.customer.CustomerId;
                        }
                        newLocations.push(newLoc);
                        newList.list = newLocations;
                        newList.itemCount = newLocations.length;
                        this.updateLocationsList(newList);
                    });
                },
                e => this.createStateSvc.createLocationError(e.message)
            )
        );
    }

    public getLocationListLoader(): Observable<IListInfo<LocationInfo>> {
        return this.isLoadRequired$.pipe(
            filter(val => val),
            switchMap(() => this.getLocationList()),
            finalize(() => this.createStateSvc.cancelLoadLocationList()),
            catchError(() => of(emptyList())),
            shareReplay(1)
        );
    }

    private getLocationList(): Observable<IListInfo<LocationInfo>> {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            tap(() => {
                this.createStateSvc.loadLocationList();
                this.activateLoadLocations(false);
                this.isLoadingLocationsList(true);
            }),
            switchMap(n => {
                if (n && n.CustomerId) {
                    return this.retailerLocationsList$.pipe(
                        withLatestFrom(this.isLoadingLocationsList$),
                        map(([l, b]) => new ListInfo(l, b)));
                } else {
                    this.createStateSvc.cancelLoadLocationList();
                    return this.locationSvc.locationList$.pipe(
                        withLatestFrom(this.locationSvc.isLoading$),
                        map(([l, b]) => new ListInfo(l, b)));
                }
            }),
            tap((n: ListInfo) => this.isLoadingLocationsList(n.isLoading)),
            map((n: ListInfo) => {
                return {
                    list: n.List.list ? n.List.list : [],
                    itemCount: n.List.itemCount ? n.List.itemCount : 0,
                    isPaged: n.List.isPaged ? n.List.isPaged : false,
                    isLoading: false
                };
            }),
            tap(
                n => this.createStateSvc.loadLocationListSuccess(n),
                e => this.createStateSvc.loadLocationListError(e.message)
            ),
            tap(n => {
                this.isLoadingLocationsList(n.isLoading);
            }),
            finalize(() => this.createStateSvc.cancelLoadLocationList()),
            share()
        );
    }

    private getRetailerLocationsRequest() {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            filter(n => Boolean(n) && Boolean(n.CustomerId)),
            tap(_ => this.isLoadingLocationsList(true)),
            tap(_ => this.updateLocationsList(emptyList())),
            map((n) => {
                const request = new GetLocationRequest();
                request.RetailerId = n.CustomerId;
                return request;
            }),
            switchMap((request) => this.oversightSvc.GetLocations(request)),
            handleErrorResponse(),
            map((resp: GetLocationResponse) => {
                const list = {
                    list: resp.LocationList,
                    itemCount: resp.LocationList.length,
                    isPaged: false
                };
                return list;
            }),
            tap(_ => this.isLoadingLocationsList(false),
                _ => this.isLoadingLocationsList(false)),
            tap((n) => this.updateLocationsList(n)),
        );
    }

    private updateLocationsList(list: IListInfo<LocationInfo>) {
        this.retailerLocationsListSub.next(list);
    }

    public getOriginLocationList(): Observable<IListInfo<LocationInfo>> {
        return combineLatest(
            this.createStateSvc.selectedLinkedCustomer$,
            this.custStateSvc.selectedContext$,
            this.locationList$,
            (linkedCustomer$, customer$, locations$) => {
                const shipperFilter = (a: LocationInfo) => {
                    return a.IsOrigin &&
                        (a.LinkedCustomerId === null ||
                            a.LinkedCustomerId === undefined ||
                            a.LinkedCustomerId === customer$.customer.CustomerId);
                };
                const originFilter = (a: LocationInfo) => a.IsOrigin;
                const currentFilter = (linkedCustomer$ && linkedCustomer$.CustomerId) ? shipperFilter : originFilter;
                return this.getFilteredList(locations$, currentFilter);
            });
    }

    public getDestinationLocationList(): Observable<IListInfo<LocationInfo>> {
        return combineLatest(
            this.createStateSvc.selectedLinkedCustomer$,
            this.custStateSvc.selectedContext$,
            this.locationList$,
            (linkedCustomer$, customer$, locations$) => {
                const shipperFilter = (a: LocationInfo) => {
                    return a.IsDestination &&
                        a.TypeCode !== +LocationTypeCode.PostDCDelivery &&
                        (a.LinkedCustomerId === null ||
                            a.LinkedCustomerId === undefined ||
                            a.LinkedCustomerId === customer$.customer.CustomerId);
                };
                const destinationFilter = (a: LocationInfo) => a.IsDestination && a.TypeCode !== +LocationTypeCode.PostDCDelivery;
                const currentFilter = (linkedCustomer$ && linkedCustomer$.CustomerId) ? shipperFilter : destinationFilter;
                return this.getFilteredList(locations$, currentFilter);
            });
    }

    public getStopLocationList(): Observable<IListInfo<LocationInfo>> {
        return combineLatest(
            this.createStateSvc.selectedLinkedCustomer$,
            this.custStateSvc.selectedContext$,
            this.locationList$,
            (linkedCustomer$, customer$, locations$) => {
                const shipperFilter = (a: LocationInfo) => {
                    return a.LinkedCustomerId === null || a.LinkedCustomerId === undefined ||
                        a.LinkedCustomerId === customer$.customer.CustomerId;
                };
                const Emptyfilter = (a: LocationInfo) => a.LocationId > 0;
                const currentFilter = (linkedCustomer$ && linkedCustomer$.CustomerId) ? shipperFilter : Emptyfilter;
                return this.getFilteredList(locations$, currentFilter);
            });
    }

    private getFilteredList(locationListInfo: IListInfo<any>, filterFn: (item: any) => boolean) {
        const listInfo = locationListInfo;
        const filteredList = listInfo.list.filter(a => filterFn(a));
        return {
            list: filteredList,
            itemCount: filteredList.length,
            isPaged: false
        };
    }

    public reloadShipmentTemplates() {
        this.templateSvc.ReloadShipmentTemplates();
    }

    public getShipmentTemplateListLoader(): Observable<IListInfo<TripTemplateInfo>> {
        return of(null).pipe(
            switchMap(() => this.getShipmentTemplateList()),
            finalize(() => this.createStateSvc.cancelLoadShipmentTemplates()),
            catchError(() => {
                this.createStateSvc.cancelLoadShipmentTemplates();
                return of(emptyList());
            }),
            share()
        );
    }

    private getShipmentTemplateList(): Observable<IListInfo<TripTemplateInfo>> {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            tap(() => {
                this.createStateSvc.loadShipmentTemplates();
                this.isLoadingTemplatesList(true);
            }),
            switchMap(n => {
                if (n && n.CustomerId) {
                    const request = new GetTripTemplateRequest();
                    request.RetailerId = Boolean(n) ? n.CustomerId : null;
                    return this.oversightSvc.GetTripTemplate(request).pipe(
                        map(l => new ListInfo(setListInfo(l.TripTemplateList, false), false)));
                } else {
                    this.createStateSvc.cancelLoadShipmentTemplates();
                    return this.templateSvc.shipmentTemplateList$.pipe(
                        withLatestFrom(this.templateSvc.isLoading$),
                        map(([l, b]) => new ListInfo(l, b)));
                }
            }),
            map(n => {
                return {
                    list: n.List.list ? n.List.list : [],
                    itemCount: n.List.itemCount ? n.List.itemCount : 0,
                    isPaged: n.List.isPaged ? n.List.isPaged : false,
                    isLoading: false
                };
            }),
            tap(n => this.isLoadingTemplatesList(n.isLoading)),
            tap(
                n => this.createStateSvc.loadShipmentTemplatesSuccess(n),
                e => this.createStateSvc.loadShipmentTemplatesError(e)
            ),
            tap(n => this.isLoadingTemplatesList(n.isLoading)),
            finalize(() => this.createStateSvc.cancelLoadShipmentTemplates()),
            map(n => n)
        );
    }

    private getTrackerListLoader(): Observable<IListInfo<Tracker>> {
        return this.createStateSvc.isTrackerLoadRequired$.pipe(
            filter(isLoadRequired => isLoadRequired),
            tap(() => this.createStateSvc.loadTrackers()),
            switchMap(() => this.getTrackerListWithNoTrip()),
            tap(
                n => this.createStateSvc.loadTrackersSuccess(n),
                e => this.createStateSvc.loadTrackersError('')
            ),
            finalize(() => this.createStateSvc.cancelLoadTrackers()),
            catchError(() => {
                this.createStateSvc.cancelLoadTrackers();
                return of(emptyList());
            }),
            share()
        );
    }

    private getTrackerListWithNoTrip(): Observable<IListInfo<Tracker>> {
        return this.oversightSvc.GetTrackerSerialsListWithNoTrip().pipe(
            map(n => <IListInfo<Tracker>>{
                list: n.TrackerSerialsList.map(a => <Tracker>{
                    trackerId: a.Description,
                    customerTrackerId: a.CustomerTrackerId,
                    groupIndex: a.GroupId,
                    groupName: this.getGroupName(n.TrackerSerialStatus, a.GroupId),
                    color: this.getTrackerColorNoTrip(a.GroupId)
                })
            })
        );
    }


    private getGroupName(list: TrackerSerialStatus[], groupId: number): string {
        return list.find(x => x.GroupId === groupId).Name;
    }

    private getTrackerList(linkedCustomer: DualVisibilityLinkCustomer): Observable<IListInfo<Tracker>> {
        const request = new GetTrackerSerialsListRequest();
        request.HasTrip = false;
        if (linkedCustomer) {
            request.LinkedCustomerWebsiteId = linkedCustomer.WebsiteId ? linkedCustomer.WebsiteId : null;
        }

        return this.oversightSvc.GetTrackerSerialsList(request).pipe(
            map(n => <IListInfo<Tracker>>{
                list: n.TrackerSerialsList.map(a => <Tracker>{
                    trackerId: a.Description,
                    customerTrackerId: a.CustomerTrackerId,
                    groupIndex: this.getGroupIndex(n.GroupNames[a.GroupId]),
                    groupName: n.GroupNames[a.GroupId],
                    color: this.getTrackerColor(a.GroupId)
                })
            })
        );
    }

    getGroupIndex(groupName: string): number {
        let groupIndex: number = null;
        switch (groupName) {
            case 'MultiTrip Serial #s':
                groupIndex = 101;
                break;
            case 'Inactive Serial #s':
                groupIndex = 102;
                break;
            case 'Active Serial #s without a trip assigned':
                groupIndex = 103;
                break;
        }
        return groupIndex;
    }

    public getTrackersListPull(linkedCustomer: DualVisibilityLinkCustomer) {
        return of(null).pipe(
            filter(isLoadRequired => true),
            tap(() => {
                this.createStateSvc.loadPullTrackers();
                this.isLoadingTrackerPullList(true);
            }),
            switchMap(() => this.getTrackerList(linkedCustomer)),
            tap(
                n => this.createStateSvc.loadPullTrackersSuccess(n),
                e => this.createStateSvc.loadPullTrackersError(e)
            ),
            tap(() => this.isLoadingTrackerPullList(false)),
            finalize(() => this.createStateSvc.cancelLoadPullTrackers()),
            catchError(() => {
                this.createStateSvc.cancelLoadPullTrackers();
                return of(emptyList());
            }),
            share()
        );
    }

    private getTrackerColorNoTrip(groupIndex: number): string {
        if (groupIndex === 101) { return 'green'; }
        if (groupIndex === 103) { return 'red'; }
        if (groupIndex === 104) { return 'darkred'; }
        return '#757575de';
    }

    private getTrackerColor(groupIndex: number): string {
        if (groupIndex === 0) { return 'green'; }
        if (groupIndex === 2) { return 'red'; }
        return '#757575de';
    }

    public createShipmentSetSensorRange(request: SetSensorRangeRequest) {
        return of(request).pipe(
            tap(n => this.createStateSvc.createSensorRange(n)),
            switchMap(n => this.oversightSvc.SetSensorRange(n)),
            handleErrorResponse(),
            tap((n: SetSensorRangeResponse) => {
                if (n.SensorRangeId === 0) { return; }
                const sensorRangeInfo = this.createStateSvc.createSensorRangeSuccess(request, n.SensorRangeId, n.Description);
                this.retailsensorSubscription = this.retailerSensorRanges$.pipe(
                    take(1)
                ).subscribe((lst) => {
                    const newList = emptyList();
                    const newSensorRanges = lst.list.splice(0);
                    newSensorRanges.push(sensorRangeInfo);
                    newList.list = newSensorRanges;
                    newList.itemCount = newSensorRanges.length;
                    this.updateSensorRangeList(newList);
                });
            },
                e => this.createStateSvc.createSensorRangeError(e)
            ),
            map((n: SetSensorRangeResponse) => n.SensorRangeId)
        );
    }

    private getSensorRangeListLoader(): Observable<IListInfo<SensorRangeInfo>> {
        return this.isSensorsLoadRequired$.pipe(
            filter(val => val),
            switchMap(() => {
                if (this.getUnAuthenticationToken()) {
                    return muteFirst(this.getUSensorRangeList(), this.sensorSvc.sensorRangeList$);
                } else {
                    return this.getSensorRangeList();
                }
            }),
            finalize(() => this.createStateSvc.cancelLoadSensorRangeList()),
            catchError(() => {
                this.createStateSvc.cancelLoadSensorRangeList();
                return of(emptyList());
            }),
            shareReplay(1)
        );
    }

    private getSensorRangeList(): Observable<IListInfo<SensorRangeInfo>> {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            tap(() => {
                this.createStateSvc.loadSensorRangeList();
                this.activateLoadSensors(false);
                this.isLoadingSensorsList(true);
            }),
            switchMap(n => {
                if (n && n.CustomerId) {
                    return this.retailerSensorRangesList$.pipe(
                        withLatestFrom(this.isLoadingSensorsList$),
                        map(([l, b]) => new ListInfo(l, b))
                    );
                    // const request = new GetSensorRangeRequest();
                    // request.RetailerId = Boolean(n) ? n.CustomerId : null;
                    // // request.SensorTypeCodes = [SensorType.Temperature];
                    // return this.oversightSvc.GetSensorRanges(request).pipe(
                    //     map(l => new ListInfo(setListInfo(l.SensorRangeList, false), false)));
                } else {
                    this.createStateSvc.cancelLoadSensorRangeList();
                    return this.sensorSvc.sensorRangeList$.pipe(
                        withLatestFrom(this.sensorSvc.isLoading$),
                        map(([l, b]) => new ListInfo(l, b)));
                }
            }),
            map((n: ListInfo) => {
                return {
                    list: n.List.list ? n.List.list : [],
                    itemCount: n.List.itemCount ? n.List.itemCount : 0,
                    isPaged: n.List.isPaged ? n.List.isPaged : false,
                    isLoading: false
                };
            }),
            tap(n => this.isLoadingSensorsList(n.isLoading)),
            tap(
                n => this.createStateSvc.loadSensorRangeListSuccess(n),
                e => this.createStateSvc.loadSensorRangeListError(e)
            ),
            tap(n => this.isLoadingSensorsList(n.isLoading)),
            finalize(() => this.createStateSvc.cancelLoadSensorRangeList())
        );
    }

    private getUSensorRangeList(): Observable<IListInfo<SensorRangeInfo>> {
        return of(null).pipe(
            withLatestFrom(this.unAuthStateSvc.unAuthCustomer$),
            tap(() => {
                this.createStateSvc.loadSensorRangeList();
                this.activateLoadSensors(false);
                this.isLoadingSensorsList(true);
            }),
            switchMap(([a, n]) => {
                if (n && n.CustomerId) {
                    return this.oversightSvc.UGetSensorRanges(n.CustomerId).pipe(
                        map((l) => {
                            if (l && l.ErrorCode === 0) {
                                return new ListInfo(setListInfo(l.SensorRangeList, false), false);
                            } else {
                                return new ListInfo(emptyList(), false);
                            }
                        })
                    );
                } else {
                    return of(new ListInfo(emptyList(), false));
                }
            }),
            map((n: ListInfo) => {
                return {
                    list: n.List.list ? n.List.list : [],
                    itemCount: n.List.itemCount ? n.List.itemCount : 0,
                    isPaged: n.List.isPaged ? n.List.isPaged : false,
                    isLoading: false
                };
            }),
            tap(
                n => {
                    this.createStateSvc.loadSensorRangeListSuccess(n);
                    this.sensorSvc.updateSensorRanges(n);
                },
                e => this.createStateSvc.loadSensorRangeListError(e)
            ),
            tap(n => this.isLoadingSensorsList(n.isLoading)),
            finalize(() => this.createStateSvc.cancelLoadSensorRangeList())
        );
    }

    private getRetailerSensorRangesRequest() {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            filter(n => Boolean(n) && Boolean(n.CustomerId)),
            tap(_ => this.isLoadingSensorsList(true)),
            tap(_ => this.updateSensorRangeList(emptyList())),
            map((n) => {
                const request = new GetSensorRangeRequest();
                request.RetailerId = n.CustomerId;
                return request;
            }),
            switchMap((request) => this.oversightSvc.GetSensorRanges(request)),
            handleErrorResponse(),
            map((resp: GetSensorRangeResponse) => {
                let list = emptyList()
                if (resp?.TotalItems > 0) {
                    list = {
                        list: resp.SensorRangeList,
                        itemCount: resp.SensorRangeList.length,
                        isPaged: false
                    };
                }
                return list;
            }),
            tap(_ => this.isLoadingSensorsList(false),
                _ => this.isLoadingSensorsList(false)),
            tap((n) => this.updateSensorRangeList(n)),
        );
    }

    private updateSensorRangeList(list: IListInfo<SensorRangeInfo>) {
        this.retailerSensorRangeListSub.next(list);
    }

    public loadCarrierList(): Observable<Lookup[]> {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            switchMap(n => {
                if (n && n.CustomerId) {
                    return this.makeCarrierListRequest(n.CustomerId);
                } else {
                    return this.carrierList$;
                }
            }));
    }

    private getCarrierListLoader(): Observable<Lookup[]> {
        return this.createStateSvc.isCarrierLoadRequired$.pipe(
            filter(isLoadRequired => isLoadRequired),
            tap(() => this.createStateSvc.loadCarrierList()),
            switchMap(() => this.custStateSvc.selectedContext$),
            switchMap(k => this.makeCarrierListRequest(k.customer.CustomerId)),
            tap(
                n => this.createStateSvc.loadCarrierListSuccess(n),
                e => this.createStateSvc.loadCarrierListError('')
            ),
            finalize(() => this.createStateSvc.cancelLoadCarrierList()),
            catchError(() => of([])),
            share()
        );
    }

    public setCarrierLookUp(request: SetLookUpRequest, pushId: number) {
        return of(request).pipe(             
            tap(() => {
                this.createStateSvc.loadCarrierList()
            }), 
            switchMap(() => this.custStateSvc.selectedContext$),
            switchMap(k => this.oversightSvc.SetLookUp(request)),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        if(!pushId){
                            if (request.Deleted) {
                                this.createStateSvc.RemoveCarrierSuccess(request.LookupId);
                            } else {
                                this.createStateSvc.AddorUpdateCarrierSuccess(n.GetLookupResponses[0].Id, { TypeCode: request.LookupTypeCode, Value: request.LookupValue, CustomerId: request.CustomerId } as Lookup);
                            }    
                        } else {
                            this.createStateSvc.cancelLoadCarrierList();
                        }
                                           
                    } else {
                        this.createStateSvc.cancelLoadCarrierList();
                    }
                },
                e => this.createStateSvc.loadCarrierListError(e)
            )
        );
    } 
            

    private makeCarrierListRequest(customerId: number): Observable<Lookup[]> {
        const request = new GetLookUpRequest();
        request.CustomerId = customerId;
        request.LookupTypeCode = 1;
        return this.oversightSvc.GetLookUp(request).pipe(map(k => k.GetLookupResponses));
    }

    public validateDVShipment(request: ValidateDVShipmentRequest): Observable<ValidateDVShipmentResponse> {
        return of(null).pipe(
            tap(() => this.createStateSvc.validateDVShipment(request)),
            switchMap(() => this.oversightSvc.CopyDVShipmentCheck(request)),
            tap(
                n => this.createStateSvc.validateDVShipmentSuccess(n),
                e => this.createStateSvc.validateDVShipmentError(e)
            ),
            finalize(() => this.createStateSvc.cancelvalidateDVShipment()),
            catchError(() => of(new ValidateDVShipmentResponse())),
            share()
        );
    }

    public getTemplateDetails(templateID: number): Observable<GetTripTemplateDetailsResponse> {
        return of(templateID).pipe(
            tap(() => this.createStateSvc.loadTemplateDetails()),
            switchMap(() => this.GetTripTemplateDetails(templateID)),
            tap(
                n => this.createStateSvc.loadTemplateDetailsSuccess(n),
                e => this.createStateSvc.loadTemplateDetailsError('')
            ),
            finalize(() => this.createStateSvc.cancelLoadTrackers()),
            catchError(() => of(null)),
            share()
        );
    }

    private GetTripTemplateDetails(templateID: number): Observable<GetTripTemplateDetailsResponse> {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            switchMap(n => {
                const request = new GetTripTemplateDetailsRequest();
                request.LinkedCustomerId = Boolean(n) ? n.CustomerId : null;
                request.TripTemplateId = templateID;
                return this.oversightSvc.GetTripTemplateDetails(request);
            }),
            map(n => {
                return n;
            })
        );
    }

    public createTemplateSuccess(templateId: number, templateName: string) {
        this.shipmentTemplateStateSvc.createTripTemplateSuccess(templateId, templateName);
    }

    tripStopModelToStops(stops: TripStopModel[]): TripStop[] {
        const tripStops: TripStop[] = [];
        let i = 1;
        stops.forEach(k => {
            const stop = {
                LocationId: k.LocationId, AirportDeparture: k.AirportDeparture, AirportArrival: false, SequenceID: i,
                TripStopTypeCode: k.AirportDeparture ? TripStopTypeCode.AirportDeparture : 0, IsAdd: k.IsAdd,
                IsDelete: k.IsDelete
            };
            if (!k.AirportDeparture) {
                delete stop.TripStopTypeCode;
            }
            tripStops.push(stop);
            i++;
            if (k.AirportDeparture) {
                tripStops.push({
                    LocationId: k.ArrivalLocationId, AirportArrival: true, AirportDeparture: false, SequenceID: i,
                    TripStopTypeCode: TripStopTypeCode.AirportArrival, IsAdd: k.IsAdd,
                    IsDelete: k.IsDelete
                });
                i++;
            }
        });
        return tripStops;
    }

    tripContactsToStopsModel(data: TripContact[], locations: any) {
        let IsArrivalExist = false;
        let IsDepartureExist = false;
        const stops: TripStopModel[] = [];
        data.forEach((k, index) => {
            if (k.TripStopInfo) {
                if (IsArrivalExist && k.TripStopInfo.AirportArrival ||
                    IsDepartureExist && k.TripStopInfo.AirportDeparture) {
                    return;
                }
                const tripStopModel = new TripStopModel();
                tripStopModel.ArrivalLocationId = k.TripStopInfo.AirportArrival ? k.TripStopInfo.LocationId : null;
                tripStopModel.LocationId = k.TripStopInfo.LocationId;
                tripStopModel.AirportArrival = null;
                tripStopModel.AirportDeparture = null;
                tripStopModel.IsEdit = true;
                if (tripStopModel.LocationId) {
                    const Location = locations.find(a => a.value === tripStopModel.LocationId);
                    if (Location && Location.value) {
                        tripStopModel.LocationName = Location.text;
                    } else {
                        tripStopModel.IsInValid = true;
                    }
                } else {
                    tripStopModel.IsInValid = true;
                }
                if (k.TripStopInfo.AirportArrival) {
                    IsArrivalExist = true;
                    tripStopModel.AirportArrival = true;
                } else if (k.TripStopInfo.AirportDeparture) {
                    IsDepartureExist = true;
                    tripStopModel.AirportDeparture = true;
                }
                if (IsArrivalExist && IsDepartureExist &&
                    (k.TripStopInfo.AirportArrival || k.TripStopInfo.AirportDeparture)) {
                    stops.forEach(a => {
                        if (a.AirportDeparture) {
                            a.ArrivalLocationId = tripStopModel.ArrivalLocationId;
                            a.ArrivalLocationName = tripStopModel.LocationName;
                            a.IsInValid = a.IsInValid || tripStopModel.IsInValid;
                        } else if (a.AirportArrival) {
                            a.LocationId = tripStopModel.LocationId;
                            a.LocationName = tripStopModel.LocationName;
                            a.IsInValid = a.IsInValid || tripStopModel.IsInValid;
                        }
                    });
                } else {
                    stops.push(tripStopModel);
                }
            }
        });
        if ((!IsArrivalExist || !IsDepartureExist) &&
            (IsArrivalExist || IsDepartureExist)) {
            stops.forEach(a => {
                if (a.AirportArrival || a.AirportDeparture) {
                    a.IsInValid = true;
                }
            });
        }
        return stops;
    }

    public GetTrackerSensorsBySerial(request: ShipmentRequest): Observable<GetTrackerResponse> {
        return of(null).pipe(
            tap(() => {
                this.createStateSvc.loadTrackerSensors();
                this.isLoadingTrackerPullList(true);
            }),
            switchMap(() => this.GetTrackerSensors(request)),
            tap(
                n => this.createStateSvc.loadTrackerSensorsSuccess(),
                e => this.createStateSvc.loadTrackerSensorsError()
            ),
            tap(() => this.isLoadingTrackerPullList(false)),
            finalize(() => this.createStateSvc.cancelLoadTrackerSensors()),
            catchError(() => {
                return of(null);
            }),
            share()
        );
    }

    private GetTrackerSensors(request: ShipmentRequest): Observable<GetTrackerResponse> {
        return this.oversightSvc.GetTrackerSensors(request).pipe(
            map(n => n));
    }

    SetTrackerSensorRange(request: SetTrackerSensorRangeRequest): Observable<SetTrackerSensorRangeResponse> {
        return of(null).pipe(
            tap(() => {
                this.createStateSvc.setTrackerSensorRange();
            }),
            switchMap(() => this.SetTrackerSensors(request)),
            tap(
                n => this.createStateSvc.setTrackerSensorRangeSuccess(),
                e => this.createStateSvc.setTrackerSensorRangeError()
            ),
            finalize(() => this.createStateSvc.cancelSetTrackerSensorRange()),
            catchError(() => {
                return of(null);
            }),
            share()
        );
    }

    private SetTrackerSensors(request: SetTrackerSensorRangeRequest): Observable<SetTrackerSensorRangeResponse> {
        return this.oversightSvc.SetTrackerSensorRange(request).pipe(
            map(n => n));
    }

    ngOnDestroy() {
        if (this.retailsensorSubscription) {
            this.retailsensorSubscription.unsubscribe();
        }
        if (this.retailLocationSubscription) {
            this.retailLocationSubscription.unsubscribe();
        }
        if (this.unAuthCustomerSub) {
            this.unAuthCustomerSub.unsubscribe();
        }
    }

    tripStopsToStopModel(data: TripStop[], locations) {
        let IsArrivalExist = false;
        let IsDepartureExist = false;
        const stops: TripStopModel[] = [];
        data = _.orderBy(data, ['SequenceID']);
        data.forEach((k, index) => {
            if (IsArrivalExist && k.TripStopTypeCode === TripStopTypeCode.AirportArrival ||
                IsDepartureExist && k.TripStopTypeCode === TripStopTypeCode.AirportDeparture) {
                return;
            }
            const tripStopModel = new TripStopModel();
            const Location = locations.find(a => a.value === k.LocationId);
            tripStopModel.LocationId = k.AirportArrival ? null : k.LocationId;
            tripStopModel.ArrivalLocationId = null;
            tripStopModel.AirportDeparture = null;
            tripStopModel.IsEdit = true;
            tripStopModel.SequenceID = k.SequenceID;
            if (!k.SequenceID && k.StopNumber) {
                tripStopModel.SequenceID = k.StopNumber;
            }
            switch (k.TripStopTypeCode) {
                case TripStopTypeCode.AirportArrival:
                    IsArrivalExist = true;
                    tripStopModel.AirportArrival = true;
                    tripStopModel.TripStopTypeCode = TripStopTypeCode.AirportArrival;
                    tripStopModel.ArrivalLocationId = k.LocationId;
                    tripStopModel.ArrivalLocationName = Location?.text;
                    break;
                case TripStopTypeCode.AirportDeparture:
                    IsDepartureExist = true;
                    tripStopModel.TripStopTypeCode = TripStopTypeCode.AirportDeparture;
                    tripStopModel.AirportDeparture = true;
                    break;
            }
            if (k.LocationId && !k.AirportArrival) {
                tripStopModel.LocationName = Location?.text;
            } else {
                tripStopModel.IsInValid = true;
            }
            if (IsArrivalExist && IsDepartureExist &&
                (k.TripStopTypeCode === TripStopTypeCode.AirportArrival || k.TripStopTypeCode === TripStopTypeCode.AirportDeparture)) {
                stops.forEach(a => {
                    if (a.AirportDeparture) {
                        a.AirportDeparture = tripStopModel.AirportArrival;
                        a.ArrivalLocationId = tripStopModel.ArrivalLocationId;
                        a.ArrivalLocationName = tripStopModel.ArrivalLocationName;
                        a.IsInValid = a.IsInValid || tripStopModel.IsInValid;
                    } else if (a.AirportArrival) {
                        a.AirportDeparture = tripStopModel.AirportDeparture;
                        a.LocationId = tripStopModel.LocationId;
                        a.LocationName = tripStopModel.LocationName;
                        a.IsInValid = a.IsInValid || tripStopModel.IsInValid;
                    }
                });
            } else {
                stops.push(tripStopModel);
            }


        })
        return stops;
    }
}
