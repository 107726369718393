import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { SensorChartService } from 'app-modules/core/services/sensor-chart.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { SetUserSettingRequest, SetUserSettingResponse } from 'emr-ng-shared';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import { UserSettingsService } from 'app-modules/core/store/user-settings/user-settings.service';
import { USER_SETTING_REQEUST_MAX_YAXIS, USER_SETTING_REQEUST_MIN_YAXIS } from 'app-modules/core/consts/user-setting';
import _ from 'lodash';
enum UserSettingsType {
    Undefined = 0,
    String = 1,
    Int = 2,
    DateTime = 3,
    Bool = 4,
    Double = 5,
    NullableInt = 6
}

export class MinMaxValueEventArgs {
    radAutomatic: boolean;
    minValue = '';
    maxValue = '';
}

@Component({
    selector: 'app-minmax-chart',
    templateUrl: './minmax-chart-values.component.html',
    styleUrls: ['./minmax-chart-values.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MinMaxChartValuesComponent implements OnInit, OnDestroy {
    constructor(
        public bsMainModalRef: BsModalRef,
        private userSettingsSvc: UserSettingsService,
    ) { }

    @ViewChild(NgForm) shipmentForm: NgForm;
    public error: string;
    shipment: Shipment;
    radAutomatic = true;
    minValue: string;
    maxValue: string;
    isAutomatic: boolean;
    setUserSubscription: Subscription;
    setUser2Subscription: Subscription;
    deleteSubscription: Subscription;
    delete2Subscription: Subscription;
    @Output() updateMinMaxValues = new EventEmitter<MinMaxValueEventArgs>();

    ngOnInit() {
        if (this.minValue != null && this.maxValue != null) {
            this.radAutomatic = false;
            this.isAutomatic = false;
        } else {
            this.isAutomatic = true;
        }
    }

    ngOnDestroy() {
        this.clearDeleteSubscription();
        this.clearSettingsSubscription();
    }

    onChartValuesSave() {
        if (this.radAutomatic === false) {
            this.reValidationForm();
            if (!this.shipmentForm.invalid) {
                if (this.minValue && this.maxValue && (Number(this.minValue) < Number(this.maxValue))) {
                    this.minValue = this.minValue;
                    this.maxValue = this.maxValue;
                    this.SetMinMax();
                }
            }
        } else {
            if (!this.isAutomatic) {
                this.DeleteMinMax();
            } else {
                this.onChartValuesClose();
            }
        }
    }

    DeleteMinMax() {
        // merge requests and in response success call emit
        this.clearDeleteSubscription();

        this.error = null;
        const deleteUserSettings = [USER_SETTING_REQEUST_MIN_YAXIS, USER_SETTING_REQEUST_MAX_YAXIS].map(x => this.userSettingsSvc.deleteUserSettings(x));
        this.deleteSubscription = 
            of(null)
            .pipe(switchMap(x => forkJoin(deleteUserSettings)))
            .subscribe((n) => this.onForkJoinSuccess(n, true), (e: IErrorInfo) => this.onMinMaxChartFailure(e));
    }

    SetMinMax() {
        // merge requests and in response success call emit
        this.clearSettingsSubscription();

        this.error = null;
        const minYAxisRequest: SetUserSettingRequest = _.cloneDeep(USER_SETTING_REQEUST_MIN_YAXIS);
        let maxYAxisRequest: SetUserSettingRequest = _.cloneDeep(USER_SETTING_REQEUST_MAX_YAXIS);
        minYAxisRequest.Value = this.minValue;
        maxYAxisRequest.Value = this.maxValue;

        const saveUserSettings = [minYAxisRequest, maxYAxisRequest].map(x => this.userSettingsSvc.saveUserSettings(x));
        this.setUserSubscription = 
            of(null)
            .pipe(switchMap(x => forkJoin(saveUserSettings)))
            .subscribe((n) => this.onForkJoinSuccess(n, false), (e: IErrorInfo) => this.onMinMaxChartFailure(e));

    }

    onForkJoinSuccess(n: SetUserSettingResponse[], isAutomatic: boolean) {
        const errors = [];
        n?.forEach(r => {
            if (r.ErrorCode !== 0) {
                errors.push(r.LocalizedErrorMessage);
            }
        });
        if (errors.length === 0) {
            this.radAutomatic = isAutomatic;
            this.onMinMaxChartSuccess();
        } else {
            // this.onMinMaxChartFailure({ message: errors.join('\r\n') });
            // 18826 In case of error also refreshing the data because we are clearing it from cache            
            this.radAutomatic = isAutomatic;
            this.onMinMaxChartSuccess();
        }
    }

    onMinMaxChartFailure(error: IErrorInfo) {
        this.error = error.message;
    }

    onMinMaxChartSuccess() {
        this.updateMinMaxValues.emit({
            radAutomatic: this.radAutomatic,
            minValue: this.minValue,
            maxValue: this.maxValue
        });
    }

    private clearSettingsSubscription() {
        if (this.setUserSubscription) {
            this.setUserSubscription.unsubscribe();
            this.setUserSubscription = null
        }
    }

    private clearDeleteSubscription() {
        if (this.deleteSubscription) {
            this.deleteSubscription.unsubscribe();
            this.deleteSubscription = null;
        }
    }


    onChartValuesClose() {
        this.bsMainModalRef.hide();
    }

    ResetForm() {
        this.minValue = null;
        this.maxValue = null;
    }

    onChangeRadioButton() {
        if (this.radAutomatic) {
            this.ResetForm();
        }
    }

    private reValidationForm = () => {
        for (const key in this.shipmentForm.controls) {
            this.shipmentForm.controls[key].markAsDirty();
            this.shipmentForm.controls[key].updateValueAndValidity();
        }
    }
}
