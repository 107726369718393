import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { MultiTrip } from 'emr-ng-shared';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as multiTripChartActions from 'app-modules/core/store/multi-trip/multi-trip.actions';

@Injectable()
export class MultiTripStateService {
    constructor(private store: Store<IAppState>) { }

    public loadMultiTrip() {
        this.store.dispatch(new multiTripChartActions.LoadMultiTrips());
    }

    public loadMultiTripSuccess(chartData: IListInfo<MultiTrip>) {
        this.store.dispatch(new multiTripChartActions.LoadMultiTripsSuccess(chartData));
    }

    public loadMultiTripError(message: string) {
        this.store.dispatch(new multiTripChartActions.LoadMultiTripsError(message));
    }

    public cancelLoadMultiTrip() {
        this.store.dispatch(new multiTripChartActions.CancelLoadMultiTrips());
    }
}
