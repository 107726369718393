<emr-modal headerTitle="Sensor Chart" i18n-headerTitle="@@label_sensor_chart" [bsModalRef]=bsModalRef>
  <div modal-body class="row">
    <div class="col-md-12">
      <p class="label-css">{{ errorMessage }}</p>
    </div>
    <div class="col-md-12 text-center">
      <emr-button identifier="sensorResponseOk" cssClass="btn-outline-default" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok"
        (click)='onOkClick()'></emr-button>
    </div>
  </div>
</emr-modal>