import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

export class PakSenseSignIn implements ServerRequestAction {
    readonly type = actionIndex.PAK_SENSE_SIGN_IN;
}

export class PakSenseSignInSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.PAK_SENSE_SIGN_IN_SUCCESS;
    readonly callingAction = actionIndex.PAK_SENSE_SIGN_IN;

    constructor(
        public authToken: string,
        public username: string,
        public displayName: string
    ) { }
}

export class PakSenseSignInError implements ServerRequestCompleteAction {
    readonly type = actionIndex.PAK_SENSE_SIGN_IN_ERROR;
    readonly callingAction = actionIndex.PAK_SENSE_SIGN_IN;

    constructor(public error: IErrorInfo) { }
}

