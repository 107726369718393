import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CollapseModule  } from 'ngx-bootstrap/collapse';

import { CoreModule } from 'app-modules/core/core.module';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { ShipmentFilterComponent } from './components/shipment-filter/shipment-filter.component';
import { ShipmentFilterSummaryComponent } from './components/shipment-filter-summary/shipment-filter-summary.component';
import { ShipmentFilterSerialNumberComponent } from './components/shipment-filter-serial-number/shipment-filter-serial-number.component';
import { ShipmentFilterShipmentNameComponent } from './components/shipment-filter-shipment-name/shipment-filter-shipment-name.component';
import { ShipmentFilterPeriodComponent } from './components/shipment-filter-period/shipment-filter-period.component';
import { ShipmentFilterStatusComponent } from './components/shipment-filter-status/shipment-filter-status.component';
import { ShipmentFilterAlertComponent } from './components/shipment-filter-alert/shipment-filter-alert.component';
import { ShipmentFilterOriginComponent } from './components/shipment-filter-origin/shipment-filter-origin.component';
import { ShipmentFilterDestinationComponent } from './components/shipment-filter-destination/shipment-filter-destination.component';
import { ShipmentFilterTempComponent } from './components/shipment-filter-temp/shipment-filter-temp.component';
import { ShipmentFilterCarrierComponent } from './components/shipment-filter-carrier/shipment-filter-carrier.component';
import { ShipmentFilterPeriodSummaryComponent } from './components/shipment-filter-period-summary/shipment-filter-period-summary.component';
import {
    ShipmentFilterSerialNumberSummaryComponent
} from './components/shipment-filter-serial-number-summary/shipment-filter-serial-number-summary.component';
import {
    ShipmentFilterShipmentNameSummaryComponent
} from './components/shipment-filter-shipment-name-summary/shipment-filter-shipment-name-summary.component';
import { ShipmentFilterStatusSummaryComponent } from './components/shipment-filter-status-summary/shipment-filter-status-summary.component';
import { ShipmentFilterAlertSummaryComponent } from './components/shipment-filter-alert-summary/shipment-filter-alert-summary.component';
import {
    ShipmentFilterOriginSummaryComponent
} from './components/shipment-filter-origin-summary/shipment-filter-origin-summary.component';
import {
    ShipmentFilterDestinationSummaryComponent
} from './components/shipment-filter-destination-summary/shipment-filter-destination-summary.component';
import { ShipmentFilterTempSummaryComponent } from './components/shipment-filter-temp-summary/shipment-filter-temp-summary.component';
import {
    ShipmentFilterCarrierSummaryComponent
} from './components/shipment-filter-carrier-summary/shipment-filter-carrier-summary.component';
import { ShipmentFilterHistoricalComponent
} from 'app-modules/shipment-filter/components/shipment-filter-historical/shipment-filter-historical.component';
import { ShipmentCommonFilterComponent } from './components/shipment-common-filter/shipment-common-filter.component';
import { ShipmentFilterSupplierComponent } from './components/shipment-filter-supplier/shipment-filter-supplier.component';
import { ShipmentFilterSupplierSummaryComponent } from './components/shipment-filter-supplier-summary/shipment-filter-supplier-summary.component';
import { ShipmentFilterDistributionCenterComponent } from './components/shipment-filter-distribution-center/shipment-filter-distribution-center.component';
import { ShipmentFilterDistributionCenterSummaryComponent } from './components/shipment-filter-distribution-center-summary/shipment-filter-distribution-center-summary.component';

@NgModule ({
    declarations: [
        ShipmentFilterComponent,
        ShipmentFilterSummaryComponent,
        ShipmentFilterSerialNumberComponent,
        ShipmentFilterPeriodComponent,
        ShipmentFilterStatusComponent,
        ShipmentFilterAlertComponent,
        ShipmentFilterOriginComponent,
        ShipmentFilterDestinationComponent,
        ShipmentFilterTempComponent,
        ShipmentFilterCarrierComponent,
        ShipmentFilterSerialNumberSummaryComponent,
        ShipmentFilterPeriodSummaryComponent,
        ShipmentFilterStatusSummaryComponent,
        ShipmentFilterAlertSummaryComponent,
        ShipmentFilterOriginSummaryComponent,
        ShipmentFilterDestinationSummaryComponent,
        ShipmentFilterTempSummaryComponent,
        ShipmentFilterCarrierSummaryComponent,
        ShipmentFilterHistoricalComponent,
        ShipmentFilterShipmentNameComponent,
        ShipmentFilterShipmentNameSummaryComponent,
        ShipmentCommonFilterComponent,
        ShipmentFilterSupplierComponent,
        ShipmentFilterSupplierSummaryComponent,
        ShipmentFilterDistributionCenterComponent,
        ShipmentFilterDistributionCenterSummaryComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        CollapseModule.forRoot(),
        EmrNgControlsModule,
        CoreModule,
        RouterModule,
    ],
    exports: [
        ShipmentFilterComponent,
        ShipmentFilterSummaryComponent
    ]
})
export class ShipmentFilterModule {

}

