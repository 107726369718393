import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IRoutesState } from 'app-modules/core/store/models/route-state.interface';
import * as routeActions from './route.actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { BusinessRuleInfo } from 'emr-ng-shared';
import { SavedRouteInfo } from 'emr-ng-shared';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class RoutesStateService {
    public routesStateSelector = ((state: IAppState) => state.routes);

    public isLoadRequiredSelector = createSelector(
        this.routesStateSelector,
        (state: IRoutesState) => state.isLoadRequired
    );

    public routesSelector = createSelector(
        this.routesStateSelector,
        (state: IRoutesState) => state.Routes
    );

    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector);
    public routesList$ = this.store.select(this.routesSelector);

    constructor(private store: Store<IAppState>) { }

    public loadRoutes() {
        this.store.dispatch(new routeActions.LoadSavedRoute());
    }

    public loadRoutesSuccess(rule: IListInfo<SavedRouteInfo>) {
        this.store.dispatch(new routeActions.LoadSavedRouteSuccess(rule));
    }

    public loadRoutesError(message: string) {
        this.store.dispatch(new routeActions.LoadSavedRouteError(message));
    }

    public cancelRoutes() {
        this.store.dispatch(new routeActions.CancelLoadSavedRoute());
    }

    public saveRoute() {
        this.store.dispatch(new routeActions.SaveRoute());
    }

    public saveRouteSuccess() {
        this.store.dispatch(new routeActions.SaveRouteSuccess());
    }

    public saveRouteError() {
        this.store.dispatch(new routeActions.SaveRouteError());
    }

    public deleteRoute() {
        this.store.dispatch(new routeActions.DeleteRoute());
    }

    public deleteRouteSuccess(deletedRoute) {
        this.store.dispatch(new routeActions.DeleteRouteSuccess(deletedRoute));
    }

    public deleteRouteError() {
        this.store.dispatch(new routeActions.DeleteRouteError());
    }
}
