import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class BindData implements ServerRequestAction {
    readonly type = actionIndex.BINDING_DATA;
}

export class BindDataComplete implements ServerRequestCompleteAction {
    readonly type = actionIndex.BINDING_DATA_COMPLETE;
    readonly callingAction = actionIndex.BINDING_DATA;
    constructor(public message: string) { }
}

export class BindDataError implements ServerRequestCompleteAction {
    readonly type = actionIndex.BINDING_DATA_ERROR;
    readonly callingAction = actionIndex.BINDING_DATA;
    constructor(public message: string) { }
}
