import { ICarrierState } from 'app-modules/core/store/models/carrier-state.interface';
import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as carrierActions from 'app-modules/core/store/actions/carrier.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    carrierActions.LoadCarriers |
    carrierActions.LoadCarriersSuccess |
    carrierActions.LoadCarriersError |
    custActions.SelectCustomer;

export function CarrierReducer(state: ICarrierState = initialState.carrier, action: Actions): ICarrierState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.carrier;

        case actionIndex.LOAD_CARRIERS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_CARRIERS_SUCCESS:
            return {
                ...state,
                carrierList: action.carrierList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_CARRIERS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.SELECT_CUSTOMER:
            return {
                ...state,
                carrierList: emptyList(),
                isLoadRequired: true
            }

        default:
            return {
                ...state
            };
    }
}
