import { ICreateShipmentPopup } from 'app-modules/core/models/create-shipment-popup.interface';
import { Inject, Injectable } from '@angular/core';
import { ShipmentDetailPopup } from 'app-modules/core/models/shipment-detail-popup.model';
// import { ShipmentRouteDeviationPopup } from 'app-modules/core/models/shipment-route-deviation-popup.model';
import { ShipmentRoutePopup } from 'app-modules/core/models/shipment-route-popup.model';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShowDistancePopup } from 'app-modules/core/models/show-distance-popup.model';
import { UserMessagePopup } from 'app-modules/core/models/user-message-popup.model';
import { IEditShipmentPopup } from 'app-modules/core/models/edit-shipment-popup.interface';
import { AlertSuppressionPopup } from 'app-modules/core/models/alert-suppression-popup.model';
import { ICloseShipmentPopup } from 'app-modules/core/models/close-shipment-popup.interface';
import { IMinMaxChartValuesPopup } from 'app-modules/core/models/minmax-chart-values-popup.interface';
import { IShipmentDetailsInfo } from 'app-modules/core/models/shipment-detail-info.interface';
import { GetAlertSuspendStatusRequest, GetDistanceToTargetsRequest, GetHistoryTripRequest, GetRouteDeviationResponseModel, SetShutDownTrackerRequest } from 'emr-ng-shared';
import { IconInfoPopup } from '../models/icon-info-popup.model';
import { DeleteTripPopup } from '../models/delete-trip-popup.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IEUserMessagePopup } from 'app-modules/core/models/ie-user-message-popup.model';
import { DashboardTermsMessagePopup } from '../models/dashboard-terms-message-popup.model';
import { ShutdownTrackerPopup } from '../models/shutdown-tracker-popup.model';
import { ShowViewLogPopup } from '../models/show-view-logs-popup.model';
import { CalibrationStatementPopup } from '../models/calibration-statement-popup.model';

@Injectable()
export class OversightPopupService {
    constructor(
        @Inject(ICreateShipmentPopup) private createShipmentFeaturePopups: ICreateShipmentPopup,
        @Inject(CalibrationStatementPopup) private calibrationStatementPopup: CalibrationStatementPopup,
        @Inject(ShipmentDetailPopup) private shipmentDetailPopup: ShipmentDetailPopup,
        @Inject(ShipmentRoutePopup) private shipmentRoutePopup: ShipmentRoutePopup,
        @Inject(IEditShipmentPopup) private editShipmentFeaturePopups: IEditShipmentPopup,
        @Inject(ShowDistancePopup) private showDistancePopup: ShowDistancePopup,
        @Inject(AlertSuppressionPopup) private alertSuppressionPopup: AlertSuppressionPopup,
        @Inject(ICloseShipmentPopup) private closeShipmentPopup: ICloseShipmentPopup,
        @Inject(IMinMaxChartValuesPopup) private minMaxChartValuesPopup: IMinMaxChartValuesPopup,
        @Inject(UserMessagePopup) private userMessagePopup: UserMessagePopup,
        @Inject(IconInfoPopup) private iconInfoPopup: IconInfoPopup,
        @Inject(DeleteTripPopup) private deleteTripPopup: DeleteTripPopup,
        @Inject(ShutdownTrackerPopup) private shutdownTracker : ShutdownTrackerPopup,
        @Inject(IEUserMessagePopup) private ieUserMessagePopup: IEUserMessagePopup,
        @Inject(DashboardTermsMessagePopup) private dashboardTermsMessagePopup: DashboardTermsMessagePopup,
        @Inject(ShowViewLogPopup) private showViewLogPopup: ShowViewLogPopup,
    ) { }

    showCreateShipmentPopup(serialNumber?: string, customerTrackerId?: string, isMultiTripSerial: boolean = false) {
        this.createShipmentFeaturePopups.OpenCreateShipmentPopup(serialNumber, customerTrackerId, isMultiTripSerial);
    }

    showCalibrationStatementPopup() {
        this.calibrationStatementPopup.OpenCalibrationStatementPopup();
    }

    showShipmentDetailPopup(shipment: IShipmentDetailsInfo, isFromUnAuthUrl?: boolean) {
        this.shipmentDetailPopup.OpenShipmentDetailPopup(shipment, isFromUnAuthUrl);
    }

    showShipmentRoutePopup(shipment: Shipment) {
        this.shipmentRoutePopup.OpenShipmentRoutePopup(shipment);
    }

    showShipmentRouteDeviationPopup(getRouteDeviationResponse: GetRouteDeviationResponseModel) {
        this.shipmentRoutePopup.OpenShipmentRouteDeviationPopup(getRouteDeviationResponse);
    }

    showEditShipmentPopup(shipment?: Shipment) {
        this.editShipmentFeaturePopups.OpenEditShipmentPopup(shipment);
    }

    showMinMaxChartValuesPopup(shipment?: Shipment) {
        this.minMaxChartValuesPopup.OpenMinMaxChartValuesPopup(shipment);
    }

    showDistanceToPopup(distanceToTargetsRequest: GetDistanceToTargetsRequest) {
        this.showDistancePopup.OpenShowDistancePopup(distanceToTargetsRequest);
    }

    showAlertSuppressionPopup(alertSuspendStatusRequest?: GetAlertSuspendStatusRequest) {
        this.alertSuppressionPopup.OpenAlertSuppressionPopup(alertSuspendStatusRequest);
    }

    showCloseShipmentPopup(shipment: Shipment, islistViewAction: boolean) {
        this.closeShipmentPopup.OpenCloseShipmentPopup(shipment, islistViewAction);
    }

    showUserMessagePopup() {
        this.userMessagePopup.OpenUserMessagePopup();
    }

    showIconInfoPopup(isIconInfoOpen) {
        this.iconInfoPopup.OpenIconInfoPopup(isIconInfoOpen);
    }

    showDeleteTrip(shipment: Shipment, closePopupOnSuccess?: BsModalRef) {
        this.deleteTripPopup.OpenDeleteTripConfirmation(shipment, closePopupOnSuccess);
    }

    // showRouteDeviationPopup(response: GetRouteDeviationResponseModel) {
    //     this.routeDeviationPopup.OpenRouteDeviationPopup(response);
    // }
    
    showIEUserMessagePopup() {
        this.ieUserMessagePopup.OpenIEUserMessagePopup();
    }

    showDashboardTermsMessagePopup() {
        this.dashboardTermsMessagePopup.OpenDashboardTermsMessagePopup();
    }

    showShutdownTracker(request: SetShutDownTrackerRequest, isListViewAction: boolean, shipment: Shipment, closePopupOnSuccess?: BsModalRef) {
        this.shutdownTracker.OpenShutdownTrackerConfirmation(request, isListViewAction, shipment, closePopupOnSuccess);
    }

    showViewLogs(getHistoryTripRequest: GetHistoryTripRequest) {
        this.showViewLogPopup.OpenShowViewLogPopup(getHistoryTripRequest);
    }

}

