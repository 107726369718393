import { IAlertTypeState } from 'app-modules/core/store/models/alert-type-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as alertActions from 'app-modules/core/store/actions/alert-type.actions';

type Actions =
    authActions.SignOut |
    alertActions.LoadAlertTypes |
    alertActions.LoadAlertTypesSuccess |
    alertActions.LoadAlertTypesError;

export function AlertTypeReducer(state: IAlertTypeState = initialState.alertType, action: Actions): IAlertTypeState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.alertType;

        case actionIndex.LOAD_ALERT_TYPES:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_ALERT_TYPES_SUCCESS:
            return {
                ...state,
                alertTypeList: action.alertTypeList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_ALERT_TYPES_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
