import { Action } from '@ngrx/store';

import * as actionIndex from 'app-modules/core/store/actions';

export class ChangeShipmentsPage implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENTS_PAGE;

    constructor(public pageNumber: number) { }
}

export class ChangeShipmentsPageSize implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENTS_PAGE_SIZE;

    constructor(public pageSize: number) { }
}
