import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})
export class ContactListComponent implements OnInit {

  @Input() public contactList: any[] = [];

  constructor() { }

  ngOnInit() {
  }

  DeleteContact(index) {
    this.contactList.splice(index, 1);
  }

}
