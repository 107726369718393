<emr-modal headerTitle="Shutdown Tracker" i18n-headerTitle="@@label_shutdown_tracker" [showCloseButton]=false>
    <div modal-body *ngIf="takeConfirmation else shutdownTrackerResponseTemplate">
        <div class="col-md-12 m-1">
            <div>
                <p i18n="@@message_shutdown_tracker">Do you want to shutdown tracker?</p>
            </div>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Yes" i18n-buttonText="@@label_yes"
                (click)='shutdownTracker()' cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
            <emr-button identifier="routeResponseOk" buttonType="button" buttonText="No" i18n-buttonText="@@label_no"
                (click)='modalRef.hide()' cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
        </div>
    </div>
</emr-modal>

<ng-template #shutdownTrackerResponseTemplate>
    <div modal-body>
        <div class="col-md-12 m-1">
            <div *ngIf="errorMessage?.length>0; else noError">
                <p>{{ errorMessage }}</p>
            </div>
            <ng-template #noError>
                <p i18n="@@message_tracker_shutdown_successfully">Tracker is shut down successfully</p>
            </ng-template>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="shutdownTrackerResponseOk" buttonType="button" buttonText="Ok"
                i18n-buttonText="@@link_ok" (click)='closeResponse()'
                cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
        </div>
    </div>
</ng-template>