<emr-modal [bsModalRef]=bsDashboardTermsModalRef customHeaderClass="terms-message-header"
    (onCloseClick)="onPopupCancel()">
    <div modal-body class="col-md-12 terms-message">
        <div class="py-3 px-4 scrollable-user-messsage-modal text-justify">
            <span>By clicking agree, you are agreeing to <a href="{{terms_URL}}" target="_blank">Copeland's Terms</a>
                (available at <a href="{{terms_URL}}" target="_blank"><img src="/assets/emerson-climate.png"
                        style="width: 18px; height: 18px;" />Warranty Information | Copeland
                    US</a>), including Copeland's Digital Cold Chain <a href="{{warranty_URL}}" target="_blank">Terms
                    and Conditions of Service</a>, incorporated by this reference. Agreement to these terms by clicking
                below and/or registering for
                the use of this website and Oversight Dashboard is required to proceed. Any different or additional
                terms are hereby objected to and superseded by these Terms.</span>
        </div>
        <div class="modal-footer p-0">
            <div class="pb-3">
                <emr-button identifier="acceptMessage" buttonType="button" buttonText="Agree" i18n-buttonText="@@agree"
                    cssClass="btn-outline-default accept-btn" (onClick)="onAccept()">
                </emr-button>
            </div>
            <div class="pb-3">
                <emr-button identifier="closeMessage" buttonType="reset" buttonText="Close" i18n-buttonText="@@close"
                    cssClass="btn-outline-default reset-btn" (onClick)="onPopupCancel()">
                </emr-button>
            </div>
        </div>
    </div>
</emr-modal>