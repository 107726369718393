<div class="container-fluid">
  <div class="row">
    <div class="col-md-2 full-map-View">
      <form class="pt-3" name="frmTrackShipment" #trackShipmentForm="ngForm" emrForm autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <emr-textarea identifier="txtTraxxNumbers" placeholder="Enter your GO number(s) here"
              i18n-placeholder="@@label_trackerlookup_placeholder" [(ngModel)]="TrackersString" name="txtTraxxNumbers"
              cssClass="vh-45" [textAreaRows]="12" required>
              <emr-validation-message validatorName="required" message="Copeland Serial # Required"
                i18n-message="@@serial_required"></emr-validation-message>
            </emr-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 signinbuttondiv">
            <emr-button identifier="Go" buttonType="submit"
                cssClass="font-14 btn-lg btn-primary quick-link btn-block m-0 mt-2 text-uppercase" buttonText="Go"
                i18n-buttonText="@@label_trackerlookup_go" buttonIcon="fa fa-search pr-1" (onClick)="onTrackShipment()">
              </emr-button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <emr-checkbox identifier="chkRepairCrumbs" name="chkRepairCrumbs" [(ngModel)]="repairCrumbs"
              text="Repair Crumbs">
            </emr-checkbox>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <span class="validationindicator">
              <app-error-message [error]="trackerError"></app-error-message>
            </span>
          </div>
        </div>
      </form>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <emr-custom-dropdown identifier="lstLineWidth" name="lstLocationColor" [options]="lineWidths"
            [(ngModel)]="lineWidth" cssClass="default-dropdown" inlineCss="px-0" label="Line width">
          </emr-custom-dropdown>
        </div>
        <div class="col-md-6">
          <div class="col-12 mt-1">&nbsp;</div>
          <emr-checkbox identifier="chkShowAPIIcons" name="chkShowAPIIcons" [(ngModel)]="showAPIIcons"
            text="Show V2 Icons">
          </emr-checkbox>
        </div>
      </div>
      <div class="vh-50">
        <ul style="list-style: none;">
          <li *ngFor="let item of this.trackers">
            <div>
              <emr-checkbox identifier="{{'chk_' + item.TrackerId + '_' + item.TripId }}"
                name="{{'name_chk_' + item.TrackerId + '_' + item.TripId }}" [disabled]="item.HasError"
                [text]="item.DisplayName" [(ngModel)]="item.Visible" cssClass="color-regular">
              </emr-checkbox>
              <ng-container *ngTemplateOutlet="item.HasError ? hasError :  success; context: { $implicit: item }">
              </ng-container>
            </div>
          </li>
        </ul>
      </div>
      <ng-template #success let-item>
        <ul class="nav">
          <li class="nav-item" style="width: 60%;">
            <div [style.backgroundColor]="item.Color" style="height: 10px;" class="mt-2 mb-2">
              &nbsp;
            </div>
          </li>
          <li class="nav-item" style="height: 10px;" *ngIf="!showAPIIcons">
            <div [innerHTML]="renderSVG(item)"></div>
          </li>
        </ul>
      </ng-template>
      <ng-template #hasError let-item>
        <span style="color: red;" class="pt-2">{{item.ErrorMessage}}</span>
      </ng-template>
    </div>
    <div class="col-md-10 full-map-View m-0 p-0">
      <emr-map #xmap [Options]="options" [Box]="box">
        <ng-container *ngFor="let item of this.trackers">
          <emr-map-marker-layer [MarkerOptions]="item.markers" [Visible]="item.Visible" [IsShowInfoBox]=true
          [ShowInfoBox]="ShowInfoBox" [InfoBoxTemplate]="infoBoxTemplate">
          </emr-map-marker-layer>
          <emr-map-polyline *ngIf="item.markers && item.markers.length > 0" [Visible]="item.Visible"
            [Path]="item.polylinePath" [StrokeWeight]="lineWidth" [StrokeColor]="item.Color">
          </emr-map-polyline>
        </ng-container>
      </emr-map>
      <ng-template #infoBoxTemplate let-m>
        <app-compare-traxx-info-box [markerInfo]="getTemplateInfo(m,'marker')" [trackerId]="getTemplateInfo(m,'trackerId')"
          [tripId]="getTemplateInfo(m,'tripId')"></app-compare-traxx-info-box>
      </ng-template>
    </div>
  </div>
</div>