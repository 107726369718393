import { Component, OnDestroy, OnInit, Optional, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CalibrationStatementsService } from 'app-modules/nist-calibration-statements/services/calibration-statements.service';
import { take } from 'rxjs/operators';
import { CalibrationStatement, EmrUtilService } from 'emr-ng-shared';
import { DomoUIStateService } from 'app-modules/domo/store/domo.state.service';
import { ToastrService } from 'ngx-toastr';
import { user_email_request } from 'app-modules/core/consts/localization';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-calibrationstatements-view',
  templateUrl: './calibration-statements.component.html',
  styleUrls: ['./calibration-statements.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CalibrationStatementsComponent implements OnInit, OnDestroy {

  constructor(
    private calStatementSvc: CalibrationStatementsService,
    private domoStateService: DomoUIStateService,
    private emrUtilSvc: EmrUtilService,
    private toastr: ToastrService,
    private modalSvc: BsModalService,
    @Optional() public bsMainModalRef: BsModalRef,) { }

  SerialNumbers: string;
  @ViewChild(NgForm) clibrateStatementForm: NgForm;
  calibrationStatementList: CalibrationStatement[];
  isSelectedAll: boolean = false;
  isSendEmailInprogress: boolean = false;
  serialNumbersWithOutModel: string[] = [];
  statementNotFoundModalRef: BsModalRef;
  noRecords = false;
  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

  onSerialNumberSubmit() {
    if (this.clibrateStatementForm.valid) {
      this.serialNumbersWithOutModel = [];
      this.domoStateService.DisplayLoader();
      // Replacing new lines with comma
      const serialNumbers = this.SerialNumbers?.replace(/,|\s+/g, '\n').trim().replace(/\n+/g, ',');
      let queryParams = { serialNumbers: serialNumbers }
      const serialNumberList = serialNumbers.split(',');
      this.calStatementSvc.getCalibrationStatement(queryParams).pipe(take(1)).subscribe(k => {
        this.calibrationStatementList = k?.Statements?.filter(k => k.ModelName);
        const processedSerialNumbers = this.getSerialNumbersFromResponse(k?.Statements);
        if (processedSerialNumbers.length < serialNumberList.length) {
          this.serialNumbersWithOutModel = serialNumberList.filter(item => !processedSerialNumbers.includes(item));
        }
        this.noRecords = !(this.calibrationStatementList?.length > 0);
        this.domoStateService.CancelLoader();
      }, error => {
        this.domoStateService.CancelLoader();
        this.toastr.error(error?.message, '', {
          positionClass: 'toast-bottom-right', timeOut: 2000
        });
      })
    }
  }

  getSerialNumbersFromResponse(statements: CalibrationStatement[]): string[] {
    let serialNumbers = [];
    statements.forEach(k => {
      k.SerialNumbers.includes(',') ? serialNumbers.push(...k.SerialNumbers.split(',')) : serialNumbers.push(k.SerialNumbers);
    })
    return serialNumbers;
  }

  onClickExportPDF(data) {
    let file = this.emrUtilSvc.base64ToBlob(data.Data, 'application/pdf');
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }


  onSelectAllClick() {
    this.calibrationStatementList.map(k => k.IsChecked = this.isSelectedAll);
  }
  onSendEmail() {
    this.isSendEmailInprogress = true;
    const selectedItems = this.calibrationStatementList.filter(k => k.IsChecked);
    this.toastr.success(user_email_request, '', {
      positionClass: 'toast-bottom-right', timeOut: 2000
    });
    this.calStatementSvc.emailCalibrationStatement(selectedItems?.length > 0 ? selectedItems : this.calibrationStatementList).pipe(take(1))
      .subscribe(k => {

        this.isSendEmailInprogress = false;
      }, e => {
        this.toastr.success(e.message, '', {
          positionClass: 'toast-bottom-right', timeOut: 2000
        });
        this.isSendEmailInprogress = false;
      });
  }
}
