import { Action } from '@ngrx/store';

import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

import * as actionIndex from 'app-modules/core/store/actions';

export class CloseAlert implements ServerRequestAction {
    readonly type = actionIndex.CLOSE_ALERT;
}

export class CloseAlertSuccess implements Action {
    readonly type = actionIndex.CLOSE_ALERT_SUCCESS;
    readonly callingAction = actionIndex.CLOSE_ALERT;
    constructor(public alertId: number) { }
}

export class CloseAlertError implements Action {
    readonly type = actionIndex.CLOSE_ALERT_ERROR;
    readonly callingAction = actionIndex.CLOSE_ALERT;
}

export class CancelCloseAlert implements Action {
    readonly type = actionIndex.CANCEL_CLOSE_ALERT;
    readonly callingAction = actionIndex.CLOSE_ALERT;
}
