import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { FormattedStrings } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IFormattedStringState } from 'app-modules/core/store/models/formatted-Strings-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as formattedStringActions from 'app-modules/core/store/formatted-strings/formatted-strings.actions';

@Injectable()
export class FormattedStringStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public formattedStringStateSelector = ((state: IAppState) => state.formattedStrings);

    public isLoadRequiredSelector = createSelector(
        this.formattedStringStateSelector,
        (state: IFormattedStringState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.formattedStringStateSelector,
        (state: IFormattedStringState) => state.isLoading
    );
    public formattedStringListSelector = createSelector(
        this.formattedStringStateSelector,
        (state: IFormattedStringState) => state.formattedStringList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public formattedStringList$ = this.store.select(this.formattedStringListSelector).pipe();

    // Actions
    public loadFormattedStrings() {
        this.store.dispatch(new formattedStringActions.LoadFormattedStrings());
    }

    public loadFormattedStringsSuccess(formattedStringList: IListInfo<FormattedStrings>) {
        this.store.dispatch(new formattedStringActions.LoadFormattedStringsSuccess(formattedStringList));
    }

    public loadFormattedStringsError(message: string) {
        this.store.dispatch(new formattedStringActions.LoadFormattedStringsError(message));
    }

    public cancelLoadFormattedStrings() {
        this.store.dispatch(new formattedStringActions.CancelLoadFormattedStrings());
    }
}


