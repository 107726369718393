import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IconInfoPopup } from 'app-modules/core/models/icon-info-popup.model';
import { IconInfoPopupComponent } from '../components/menu/icon-info-content/icon-info-popup.component';

 

@Injectable()
export class IconInfoService extends IconInfoPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenIconInfoPopup(isIconInfoOpen): void {
        const initialState = {
            isIconInfoOpen: isIconInfoOpen
        };

        this.bsModalRef = this.modalSvc.show(
            IconInfoPopupComponent,
            {
                initialState: initialState,
                class: 'modal-lg modal-dialog-centered modal-icon-info',
                ignoreBackdropClick: true
            }
        );
    }
}


