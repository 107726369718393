import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { Observable, Subscription, combineLatest } from 'rxjs';

import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { IShipmentDetailsInfo } from 'app-modules/core/models/shipment-detail-info.interface';
import { ShipmentSortService } from 'app-modules/core/services/shipment-sort.service';
import { BusinessRuleType, GetAlertSuspendStatusRequest, GetDistanceToTargetsRequest, TripState } from 'emr-ng-shared';
import { environment } from 'environments/environment';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { TrackerSensorRangesComponent } from '../sensor-range/components/tracker-sensor-ranges/tracker-sensor-ranges.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';

@Component({
    selector: 'app-static-shipment-list',
    templateUrl: './shipment-list.component.html',
    styleUrls: ['./shipment-list.component.css']
})
export class StaticShipmentListComponent implements OnInit, OnDestroy {
    @Input() public mainDivCssClass: string;
    @Input() public dataDivCssClass: string;
    @Input() public shipments$: Observable<Shipment[]>;
    private loadShipmentAddressSubscription: Subscription;
    SortColumn: string;
    @ViewChild('table', { static: true }) table: ElementRef;

    @Output() rowClick = new EventEmitter();
    @Output() refreshInfoBox = new EventEmitter();
    @Output() showRouteClicked = new EventEmitter<Shipment>();
    @Output() showDetailsClicked = new EventEmitter<Shipment>();
    @Output() deleteShipment = new EventEmitter<Shipment>();
    @Input() isHistorical = false;

    public selectedSort$: Observable<string>;
    public isDescendingSort$: Observable<boolean>;
    selectedShipment: Shipment;
    shipmentSortedList$: Observable<Shipment[]>;
    Past90Days: number;
    showNewAlertIcon = environment.showAlertIconsShipmentList;
    bsModalRef: BsModalRef;
    businessRulesListSubscription: Subscription;
    allowSetSensors: boolean;
    testTrackerSensors: boolean;
    showCreateShipmentInDetails: boolean;
    authStateSub: Subscription;
    tripState = TripState;
    public userCanCreateShipment: boolean;
    isUnAuthenticated = this.shpSvc.getUnAuthenticationToken();

    constructor(
        private shpSvc: ShipmentService,
        private showPopups: OversightPopupService,
        private sortSvc: ShipmentSortService,
        private imgMapSvc: ImageMapService,
        private modalSvc: BsModalService,
        private businessruleSvc: BusinessRulesService,
        private authSvc: AuthService
    ) {
        this.shipmentSortedList$ = null;
    }

    ngOnInit() {

        this.testTrackerSensors = environment.testTrackerSensors;
        this.showCreateShipmentInDetails = environment.showCreateShipmentInDetails;

        const today = new Date();
        const day = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        this.Past90Days = day.setDate(day.getDate() - 89);
        this.selectedSort$ = this.sortSvc.selectedSort$;
        this.isDescendingSort$ = this.sortSvc.isDescending$;
        this.shipmentSortedList$ = combineLatest(
            this.shipments$,
            this.sortSvc.selectedSort$,
            this.sortSvc.isDescending$,
            (apiList, sort, order) => {
                if (this.table) {
                    const ele = this.table.nativeElement.tBodies;
                    if (ele && ele.length > 0) {
                        ele[0].scrollTop = 0;
                    }
                }
                return this.SortColumn ?
                    this.shpSvc.sortShipmentList(this.SortColumn, order, apiList) :
                    apiList;
            });

        this.businessRulesListSubscription = this.businessruleSvc.businessRulesList$.subscribe(k => {
            if (k.list && k.list.length > 0) {
                this.allowSetSensors = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.AllowTempCheckOn3PLGOTrackersWOutTrips)?.Enabled;
            }
        });

        this.authStateSub = this.authSvc.authState$.pipe().subscribe(n => {
            this.userCanCreateShipment = n?.canCreateShipment && (n?.canAccessOversight || n?.isEmersonAdmin);
        });
    }

    public ngOnDestroy() {

        if (this.authStateSub) {
            this.authStateSub.unsubscribe();
            this.authStateSub = null;
        }

        if (this.businessRulesListSubscription) {
            this.businessRulesListSubscription.unsubscribe();
            this.businessRulesListSubscription = null;
        }

        if (this.loadShipmentAddressSubscription) {
            this.loadShipmentAddressSubscription.unsubscribe();
        }
        this.sortSvc.changeShipmentSort('TripStateCode', false);
    }

    onShipmentRouteClick(shipment?: Shipment) {
        this.showRouteClicked.emit(shipment);
    }

    onViewLocationClick(shipment: Shipment) {
        shipment.AddressGeoLoading = true;
        this.loadShipmentAddressSubscription = this.shpSvc.loadObservableShipmentAddress(shipment.trackerId).subscribe(k => {
            shipment.AddressGeoLoading = false;
            this.refreshInfoBox.emit(shipment);
            if (this.loadShipmentAddressSubscription) {
                this.loadShipmentAddressSubscription.unsubscribe();
                this.loadShipmentAddressSubscription = null;
            }
        });
    }

    onShipmentDetailsClick(shipment: Shipment) {
        this.showDetailsClicked.emit(shipment);
        const ShipmentDetails: IShipmentDetailsInfo = {
            tripId: shipment.tripId,
            trackerId: shipment.trackerId,
            customerTrackerId: shipment.customerTrackerId,
            isMultiTrip: shipment.isMultiTrip,
            IsDVShipment: shipment.IsDVShipment,
            tripStateCode: shipment.tripStateCode,
            ModelName: shipment.ModelName,
            ModelNumber: shipment.ModelNumber,
            Is4G: shipment.Is4G,
            ActualStartTime: shipment.ActualStartTime,
            ActualEndTime: shipment.ActualEndTime,
            ScheduledStartTime: shipment.ScheduledStartTime,
            ScheduledEndTime: shipment.ScheduledEndTime,
            LastReportedTimestamp: shipment.LastReportedTimestamp
        };
        this.showPopups.showShipmentDetailPopup(ShipmentDetails);
    }

    onRowClick(shipment: Shipment) {
        if (!this.isHistorical) {
            this.selectedShipment = shipment;
            this.rowClick.emit(shipment);
        }
    }

    sortSelected(selectedSort: string, dbName: string) {
        this.SortColumn = dbName;
        const newSort = this.sortSvc.calculateNewSort(selectedSort);
        this.sortSvc.ChangeShipmentSortWithOutListUpdate(selectedSort, newSort.isDescending);
    }

    CheckDatesInPast90Days(lastReported) {
        return (!lastReported && !this.isUnAuthenticated) || new Date(lastReported).getTime() > this.Past90Days;
    }

    checkForNotReportedUnassignedTracker(shipment: Shipment) {
        return !shipment?.tripId && !shipment?.LastReportedTimestamp;
    }

    onCreateShipmentClick(shipment: Shipment) {
        if (this.userCanCreateShipment) {
            this.showPopups.showCreateShipmentPopup(shipment.trackerId, shipment.customerTrackerId, shipment.isMultiTrip);
        }
    }

    onSetTrackerSensorsClick(shipment: Shipment) {
        this.bsModalRef = this.modalSvc.show(
            TrackerSensorRangesComponent,
            {
                initialState: {
                    serialNumber: shipment.trackerId
                },
                class: 'modal-md modal-dialog-centered max-width-600',
                ignoreBackdropClick: true
            }
        );
    }

    onSuspendResumeAlertsClick(shipment: Shipment) {
        const alertSuspendStatusRequest = new GetAlertSuspendStatusRequest();
        alertSuspendStatusRequest.DeviceId = shipment.customerTrackerId;
        alertSuspendStatusRequest.GlobalDeviceId = shipment.trackerId;
        alertSuspendStatusRequest.TripId = shipment.tripId;

        this.showPopups.showAlertSuppressionPopup(alertSuspendStatusRequest);
    }

    onDistanceToClick(trackerId, customerTrackerId, tripId) {
        const distanceToTargetsRequest = new GetDistanceToTargetsRequest();
        distanceToTargetsRequest.TrackerId = trackerId;
        distanceToTargetsRequest.CustomerTrackerId = customerTrackerId;
        distanceToTargetsRequest.TripId = tripId;

        this.showPopups.showDistanceToPopup(distanceToTargetsRequest);
    }

    onCloseShipmentClick(shipment: Shipment) {
        if ((shipment.canCloseShipment)) {
            this.showPopups.showCloseShipmentPopup(shipment, true);
        }
    }

    onDeleteShipmentClick(shipment: Shipment) {
        if (shipment?.CanDeleteTrip) {
            this.showPopups.showDeleteTrip(shipment);
        }
    }

    calcImageURL(imageId: string, imageUrlSVG: string, imageUrl: string) {
        return this.imgMapSvc.getImageURL(imageId, imageUrlSVG, imageUrl);
    }
}

