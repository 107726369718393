import { Injectable } from '@angular/core';

import { Subject, of, Observable, BehaviorSubject } from 'rxjs';
// import { UnAuthStateService } from 'app-modules/core/store/un-auth/un-auth-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { environment } from 'environments/environment';
import { UnAuthUserSettings } from 'app-modules/core/models/unauth-user-settings.model';
import { UCreateShipmentRequest, ShipmentRequest } from 'emr-ng-shared';
import { tap, switchMap, finalize, catchError, map } from 'rxjs/operators';
import { UnAuthStateService } from './un-auth-state.service';
import { GetTrackerResponse } from 'emr-ng-shared';
import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { CreateShipmentStateService } from '../create-shipment/create-shipment-state.service';
import { UCreateShipmentResponse } from 'emr-ng-shared';


@Injectable()
export class UnAuthService {
    constructor(
        private unAuthStateSvc: UnAuthStateService,
        private oversightSvc: OversightApiService,
        private createStateSvc: CreateShipmentStateService,
    ) { }

    public hideUSettings = new Subject<boolean>();
    public authUserSettings = new BehaviorSubject<UnAuthUserSettings>(null);
    public authUserSettings$ = this.authUserSettings.asObservable();


    public unAuthCustomer$ = this.unAuthStateSvc.unAuthCustomer$;
    public unAuthCreateShipmentAccess$ = this.unAuthStateSvc.unAuthCreateShipmentAccess$;
    public unAuthCustomerLocation$ = this.unAuthStateSvc.unAuthCustomerLocation$;

    // public unAuthState$ = this.unAuthStateSvc.unAuthState$;

    // public SaveUnAuthTrackers(unAuthTrackers: string) {
    //     return this.unAuthStateSvc.SaveUnAuthTrackers(unAuthTrackers);
    // }

    public setUnAuthenticationToken(IsUnAuthenticated: boolean) {
        this.oversightSvc.setUnAuthenticationToken(IsUnAuthenticated);
    }

    public getUnAuthenticationToken(): boolean {
        return this.oversightSvc.getUnAuthenticationToken();
    }

    // private getUnAuthState(): IAuthState {
    //     let unAuthTrackers;
    //     this.unAuthState$.subscribe(a => {
    //         unAuthTrackers = a;
    //     });
    //     return unAuthTrackers;
    // }

    public hideSettingsMenu(value: boolean) {
        this.hideUSettings.next(value);
    }

    public setAuthUserSettings(unAuthUserSettings: UnAuthUserSettings) {
        this.authUserSettings.next(unAuthUserSettings);
    }

    public validateUSettingsForNull(): boolean {
        const localUnAuthSettings = JSON.parse(localStorage.getItem(this.getUnAuthenticationToken() ? environment.unAuthUserSettings : environment.AuthUserSettings));
        return localUnAuthSettings && localUnAuthSettings.uTemperatureUnits &&
            localUnAuthSettings.uDistanceUnits && localUnAuthSettings.uDateFormatEnum &&
            localUnAuthSettings.uTimeZoneID && localUnAuthSettings.uTimeFormatEnum;
    }


    public sameAsPreviousSettings(uSettings: UnAuthUserSettings) {
        const localUnauthSettings = JSON.parse(localStorage.getItem(this.getUnAuthenticationToken() ? environment.unAuthUserSettings : environment.AuthUserSettings));
        return (localUnauthSettings && uSettings &&
            uSettings.temperatureUnits === localUnauthSettings.uTemperatureUnits &&
            uSettings.distanceUnits === localUnauthSettings.uDistanceUnits &&
            uSettings.dateFormatEnum === localUnauthSettings.uDateFormatEnum &&
            uSettings.timeZoneID === localUnauthSettings.uTimeZoneID &&
            uSettings.timeFormatEnum === localUnauthSettings.uTimeFormatEnum);
    }

    public UnAuthCreateShipment(request: UCreateShipmentRequest): Observable<UCreateShipmentResponse> {
        return of(null).pipe(
            tap(() => this.unAuthStateSvc.UnAuthCreateShipment()),
            switchMap(() => this.oversightSvc.UnAuthCreateShipment(request)),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.unAuthStateSvc.UnAuthCreateShipmentSuccess();
                    } else {
                        this.unAuthStateSvc.UnAuthCreateShipmentError();
                    }
                },
                e => this.unAuthStateSvc.UnAuthCreateShipmentError()
            ),
            map(n => n)
        );
    }

    public GetTrackerSensorsBySerial(request: ShipmentRequest): Observable<GetTrackerResponse> {
        return of(null).pipe(
            tap(() => {
                this.createStateSvc.loadTrackerSensors();
            }),
            switchMap(() => this.GetTrackerSensors(request)),
            tap(
                n => this.createStateSvc.loadTrackerSensorsSuccess(),
                e => this.createStateSvc.loadTrackerSensorsError()
            ),
            finalize(() => this.createStateSvc.cancelLoadTrackerSensors()),
            catchError(() => {
                return of(null);
            })
        );
    }


    private GetTrackerSensors(request: ShipmentRequest): Observable<GetTrackerResponse> {
        return this.oversightSvc.GetTrackerSensors(request).pipe(
            map(n => n));
    }

    public loadShipmentList() {
        this.unAuthStateSvc.UnAuthCreateShipmentSuccess();
    }

    public clearUnAuthCustomer() {
        this.unAuthStateSvc.clearUnAuthCustomer();
    }

}


