import { Injectable } from '@angular/core';

import { catchError, switchMap, tap } from 'rxjs/operators';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { GetAlertSuspendStatusRequest } from 'emr-ng-shared';
import { GetAlertSuspendStatusResponse } from 'emr-ng-shared';
import { SuspendAlertsRequest } from 'emr-ng-shared';
import { of, Observable } from 'rxjs';
import { AlertSuspenseStatusStateService } from 'app-modules/core/store/alert-suspense-status/alert-suspense-state.service';

@Injectable()
export class AlertSuppressionService {
    constructor(
        private oversightSvc: OversightApiService,
        private stateSvc: AlertSuspenseStatusStateService
    ) { }

    GetAlertSuspendStatus(alertSuspendStatusRequest: GetAlertSuspendStatusRequest): Observable<GetAlertSuspendStatusResponse> {
        return of(null). pipe(
            tap(() => this.stateSvc.loadAlertSuspenseStatus()),
            switchMap(() => this.oversightSvc.GetAlertSuspendStatus(alertSuspendStatusRequest)),
            tap(
                _ => this.stateSvc.loadAlertSuspenseStatusSuccess(),
                e => this.stateSvc.loadAlertSuspenseStatusError(e)
            )
        );
    }

    GetAlertSuspendStatusDetails(alertSuspendStatusResponse: GetAlertSuspendStatusResponse) {
        const response = new GetAlertSuspendStatusResponse();
        return response;
    }

    SuspendAlerts(suspendAlertsRequest: SuspendAlertsRequest) {
        return of(null). pipe(
            tap(() => this.stateSvc.updateAlertSuspenseStatus()),
            switchMap(() => this.oversightSvc.SuspendAlerts(suspendAlertsRequest)),
            tap(
                _ => this.stateSvc.updateAlertSuspenseStatusSuccess(),
                e => this.stateSvc.updateAlertSuspenseStatusError(e)
            )
        );
    }
}

