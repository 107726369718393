import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShipmentDetailComponent } from 'app-modules/shipment-detail/components/shipment-detail/shipment-detail.component';

const routes: Routes = [
  { path: '', component: ShipmentDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentDetailRoutingModule { }
