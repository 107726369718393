import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { IShipmentFilterLocation } from 'app-modules/core/store/models/shipment-filter-location.interface';
import { LocationInfo } from 'emr-ng-shared';
import { LocationService } from 'app-modules/core/services/location.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';

class SelectableLocationInfoListItem {
    checked: boolean;
    get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
    get value() { return this.locationInfo.LocationId; }
    constructor(public locationInfo: LocationInfo) { }
}

@Component({
    selector: 'app-shipment-filter-origin',
    templateUrl: './shipment-filter-origin.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterOriginComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: number[];
    searchOriginText = '';
    @Input() set filter(val) {
        this.filterVal = val;
        this.filterChange.emit(this.filterVal);
    }
    get filter() {
        // this.filterVal = this.locationList && this.locationList.filter(k => k.checked).map(k => k.value);
        return this.filterVal;
    }
    @Output() filterChange = new EventEmitter();
    // tslint:disable-next-line: adjacent-overload-signatures


    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    // tslint:disable-next-line: adjacent-overload-signatures
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    locationList: SelectableLocationInfoListItem[];

    private originListSubscription: Subscription;
    private originFilterSubscription: Subscription;

    constructor(
        public locationSvc: LocationService,
        private filterSvc: ShipmentFilterService
    ) { }

    public ngOnInit() {
        this.originListSubscription = this.locationSvc.originList$.subscribe(n => this.onOriginListChange(n));
        this.originFilterSubscription = this.filterSvc.originFilter$.subscribe(n => this.onOriginFilterChange(n));
    }

    public ngOnDestroy() {
        this.originListSubscription.unsubscribe();
        this.originFilterSubscription.unsubscribe();
    }

    onOriginListChange(locationList: IListInfo<LocationInfo>) {
        this.locationList = [];
        if (!locationList) { return; }

        locationList.list.forEach(item => {
            const listItem = new SelectableLocationInfoListItem(item);
            if (this.filter) {
                listItem.checked = this.filter.indexOf(item.LocationId) >= 0;
            }
            this.locationList.push(listItem);
        });
    }

    onOriginFilterChange(filter: number[]) {
        this.isDirty = false;
        this.filter = filter;
        if (filter) {
            this.locationList.forEach(item => item.checked = this.filter.indexOf(item.locationInfo.LocationId) >= 0);
        }
    }

}


