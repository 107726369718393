import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GetAlertSuspendStatusRequest } from 'emr-ng-shared';

@Component({
  selector: 'app-alert-suppression-popup',
  templateUrl: './alert-suppression-popup.component.html',
  styleUrls: ['./alert-suppression-popup.component.css']
})
export class AlertSuppressionPopupComponent implements OnInit {

  constructor(
    public bsAlertSuppressionModalRef: BsModalRef
  ) { }

  alertSuspendStatusRequest: GetAlertSuspendStatusRequest;

  ngOnInit() {
  }

  onPopupCancel() {
    this.bsAlertSuppressionModalRef.hide();
  }

}


