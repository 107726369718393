import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { LocaleGuard } from 'app-modules/core/guards/locale-guard';
import { AuthGuard } from 'app-modules/core/guards/auth-guard';
import { CustomerGuard } from 'app-modules/core/guards/customer-guard';

import { SignInComponent } from 'app-modules/user-account/components/sign-in/sign-in.component';
import { SignOutComponent } from 'app-modules/user-account/components/sign-out/sign-out.component';
import { AuthExpiredComponent } from 'app-modules/user-account/components/auth-expired/auth-expired.component';
import { UnauthorizedComponent } from 'app-modules/user-account/components/unauthorized/unauthorized.component';
// import { DashboardComponent } from 'app-modules/manage/dashboard/dashboard.component';

import { LocaleComponent } from 'app-modules/locale/components/locale/locale.component';
import { SelectCustomerComponent } from 'app-modules/customer/components/select-customer/select-customer.component';
import { MapViewComponent } from 'app-modules/map-view/components/map-view/map-view.component';
import { ListViewComponent } from 'app-modules/list-view/components/list-view/list-view.component';
import { ChangePasswordComponent } from 'app-modules/user-account/components/change-password/change-password.component.';
import { ForgotPasswordComponent } from 'app-modules/user-account/components/forgot-password/forgot-password.component';

// export const localeModuleRoute = './modules/locale/locale.module#LocaleModule';
// export const mapViewModuleRoute = './modules/map-view/map-view.module#MapViewModule';
// export const listViewModuleRoute = './modules/list-view/list-view.module#ListViewModule';
export const createShipmentModuleRoute = 'app-modules/create-shipment/create-shipment.module#CreateShipmentModule';
import { CreateShipmentComponent } from 'app-modules/create-shipment/components/create-shipment/create-shipment.component';
import { RouteEditorComponent } from 'app-modules/route-editor/components/route-editor/route-editor.component';
import { RouteDeviationComponent } from 'app-modules/route-editor/components/route-deviation/route-deviation.component';
import { ImportLocationComponent } from 'app-modules/location-management/components/import-locations/import-location.component';
import { CompareTraxxViewComponent } from 'app-modules/compare-traxx/components/compare-traxx-view/compare-traxx-view.component';
import { CompareTraxxGuard } from 'app-modules/core/guards/compare-traxx-guard';
import { ShipmentViewComponent } from 'app-modules/dc-edit-shipment/components/shipment-view/shipment-view.component';
import { HistoricalViewComponent } from 'app-modules/historical-view/historical-view.component';
import { DocumentTableComponent } from 'app-modules/dashboard/components/document-table/document-table.component';
import { TripReportComponent } from 'app-modules/reports/components/shipment-report/trip-report.component';
import { ScheduledReportsComponent } from 'app-modules/reports/components/scheduled-reports/scheduled-reports.component';
import { EmbedDashboardComponent } from 'app-modules/domo/embed-dashboard/embed-dashboard.component';
import { ManageLocationComponent } from 'app-modules/location-management/location/manage-location.component';
// import { ManageModule } from 'app-modules/manage/manage.module';
import { ShipmentTemplateComponent } from 'app-modules/shipment-template/components/shipment-template/shipment-template.component';
import { CalibrationStatementsComponent } from 'app-modules/nist-calibration-statements/components/calibration-statements/calibration-statements.component';
import { SigmaEmbedDashboardComponent } from 'app-modules/domo/sigma-embed-dashboard/sigma-embed-dashboard.component';

const appRoutes: Routes = [
  // Default route
  { path: '', redirectTo: '/mapview', pathMatch: 'full' },

  // Eagerly loaded routes
  { path: 'signin/:externalAuthToken', component: SignInComponent, canActivate: [LocaleGuard] },
  { path: 'signin', component: SignInComponent, canActivate: [LocaleGuard] },
  // { path: 'dashboard', component: DashboardComponent, canActivate: [LocaleGuard, c, CustomerGuard] },
  { path: 'changepassword', component: ChangePasswordComponent, canActivate: [LocaleGuard, AuthGuard] },
  { path: 'forgotpassword', component: ForgotPasswordComponent, canActivate: [LocaleGuard] },
  { path: 'signout', component: SignOutComponent },
  { path: 'authexpired', component: AuthExpiredComponent, canActivate: [LocaleGuard] },
  { path: 'dashboard', component: DocumentTableComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'locale', component: LocaleComponent },
  { path: 'selectcustomer', component: SelectCustomerComponent, canActivate: [LocaleGuard, AuthGuard] },
  { path: 'mapview', component: MapViewComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  // { path: 'mapview', component: MapViewComponent, canActivate: [LocaleGuard, AuthGuard] },
  // { path: 'listview', component: ListViewComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },

  // Lazily loaded routes
  // { path: 'mapview', loadChildren: mapViewModuleRoute, canActivate: [LocaleGuard, AuthGuard] },
  // { path: 'listview', loadChildren: listViewModuleRoute, canActivate: [LocaleGuard, AuthGuard] },
  { path: 'createshipment', component: CreateShipmentComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'routeedit', component: RouteEditorComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  // { path: 'createshipmentpage', component: CreateShipmentComponent, canActivate: [LocaleGuard, CustomerGuard]},
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [LocaleGuard, AuthGuard] },
  { path: 'historical', component: HistoricalViewComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'compare-traxx', component: CompareTraxxViewComponent },
  // { path: 'locale', loadChildren: localeModuleRoute }
  { path: 'importlocations', component: ImportLocationComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'updateshipment', component: ShipmentViewComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'tripreport', component: TripReportComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'reports', component: ScheduledReportsComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'scorecard', component: SigmaEmbedDashboardComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'locations', component: ManageLocationComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'manage', loadChildren: () => import('./modules/manage/manage.module').then(m => m.ManageModule), canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  // { path: 'manage/dashboard', component: DashboardComponent  }
  { path: 'templates', component: ShipmentTemplateComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },  
  { path: 'soa', component: CalibrationStatementsComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] },
  { path: 'recycling', canActivate: [LocaleGuard, AuthGuard, CustomerGuard],
    children: [
      {
        path: 'customer',
        loadChildren: () => import('app-modules/recycling/dashboards/customer/customer-recycling-dashboard.module').then(m => m.CustomerRecyclingDashboardModule)
      },
    ]}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  providers: [
    LocaleGuard,
    AuthGuard,
    CustomerGuard,
    CompareTraxxGuard
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

}
