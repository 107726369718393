import { AlertActionStatus, AlertEscalationLevel, AlertSeverity, Any, ObjectViewType, TimePeriod } from 'emr-ng-shared';

export class AlertReportParams {
    TimePeriod: TimePeriod;
    FromDate: Date;
    ThruDate: Date;
    EscalationLevels: Any[];
    Severity: AlertSeverity;
    Status: AlertActionStatus;
    ExpandAll: boolean;
    Escalations: string;
}

export class AlertViewArgs {
    Period: number;
    From: Date;
    Thru: Date;
    Status: number[];
    VehicleDescriptionLikeList: string;
    LocationId: number;
    TripId: number;
    AlertId: number;
    AlertZoneIds: number[];
    EscalationLevels: number[];
    OrderBy: string;
    SortDescending: boolean;
    Severity: number[];
    AlertType: number[];
    Category: number[];
    IsTrackerAwol: boolean;
    IsGpsAwol: boolean;
    IsSensorAwol: boolean;
    AlertReportType: boolean;
    IsPeriodOverridable: boolean;
    IsVehicleOverridable: boolean;
    OversightColumnOrderList: string;
    OversightColumnVisibilityList: string;
    OversightColumnAliasList: string;
}