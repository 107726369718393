import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { UserSetting } from 'app-modules/core/models/user-setting.model';

export class LoadUserSettings implements Action {
    readonly type = actionIndex.LOAD_USER_SETTINGS;
}

export class LoadUserSettingsSuccess implements Action {
    readonly type = actionIndex.LOAD_USER_SETTINGS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_USER_SETTINGS;
    constructor() { }
}

export class LoadUserSettingsError implements Action {
    readonly type = actionIndex.LOAD_USER_SETTINGS_ERROR;
    readonly callingAction = actionIndex.LOAD_USER_SETTINGS;
    constructor() { }
}

export class LoadUserSettingsWithStore implements Action {
    readonly type = actionIndex.LOAD_USER_SETTINGS_WITH_STORE;
}

export class LoadUserSettingsSuccessWithStore implements Action {
    readonly type = actionIndex.LOAD_USER_SETTINGS_SUCCESS_WITH_STORE;
    readonly callingAction = actionIndex.LOAD_USER_SETTINGS_WITH_STORE;
    constructor(public value: UserSetting[]) { }
}

export class LoadUserSettingsWithStoreError implements Action {
    readonly type = actionIndex.LOAD_USER_SETTINGS_WITH_STORE_ERROR;
    readonly callingAction = actionIndex.LOAD_USER_SETTINGS_WITH_STORE;
    constructor(public message: string) { }
}

export class CancelLoadUserSettings implements Action {
    readonly type = actionIndex.CANCEL_LOAD_USER_SETTINGS;
    readonly callingAction = actionIndex.LOAD_USER_SETTINGS;
}

export class SaveUserSettings implements Action {
    readonly type = actionIndex.SAVE_USER_SETTINGS;
}

export class SaveUserSettingsSuccess implements Action {
    readonly type = actionIndex.SAVE_USER_SETTINGS_SUCCESS;
    readonly callingAction = actionIndex.SAVE_USER_SETTINGS;
    constructor(public value: UserSetting) { }
}

export class SaveUserSettingsError implements Action {
    readonly type = actionIndex.SAVE_USER_SETTINGS_ERROR;
    readonly callingAction = actionIndex.SAVE_USER_SETTINGS;
    constructor(public message: string) { }
}

