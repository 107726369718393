<emr-modal customHeaderClass="user-message-header" [bsModalRef]=bsIEUserMessageModalRef (onCloseClick)="onCloseClick()">
    <div modal-body class="col-12 user-message">
        <div class="row p-3">
            <div class="col-md-4">
                <img src="assets/copeland_blue_transparent.png" class="w-100">
            </div>
        </div>

        <div class="px-3 scrollable-user-messsage-modal">
            <p>
                Dear Valued Customer,
            </p>
            <p>
                Starting on August 2nd, Oversight will no longer be supported on Internet Explorer. We recommend using
                Chrome when navigating Oversight.
            </p>
            <p>
                Please contact our 24/7 Technical Support team via email, live chat, or phone if you have any questions.
                <a href="mailto:cargosupport@copeland.com">cargosupport@copeland.com</a> or Toll Free 1 (877) 998-7299.
            </p>
            <p>Sincerely,</p>
            <h6 class="custom-color">
                COPELAND
            </h6>
        </div>
        <div class="modal-footer p-0">
            <div class="pb-3">
                <emr-button identifier="closeMessage" buttonType="reset" buttonText="Close" i18n-buttonText="@@close"
                    cssClass="btn-outline-default reset-btn" (onClick)="onCloseClick()">
                </emr-button>
            </div>
        </div>
    </div>
</emr-modal>