import { Customer } from 'emr-ng-shared';

import { ICustomerState } from 'app-modules/core/store/models/customer-state.interface';
import { ICustomerContextState } from 'app-modules/core/store/models/customer-context-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    custActions.LoadCustomersSuccess |
    custActions.LoadCustomersError |
    custActions.SelectCustomerSuccess |
    custActions.SelectCustomerError;

export function CustomerReducer(state: ICustomerState = initialState.customer, action: Actions): ICustomerState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.customer;

        case actionIndex.LOAD_CUSTOMERS_SUCCESS:
            let context: ICustomerContextState;
            let customer: Customer;

            context = null;
            customer = null;

            if (state.selectedContext != null) {
                customer = action.customerList.list.find(a => a.CustomerId === state.selectedContext.customer.CustomerId);

                if (customer != null) {
                    context = {
                        customer: customer,
                        token: state.selectedContext.token,
                        affiliateList: state.selectedContext.affiliateList
                    };
                }
            }

            return {
                ...state,
                customerList: action.customerList,
                isLoadRequired: false,
                selectedContext: context,
                errorMessage: ''
            };

        case actionIndex.LOAD_CUSTOMERS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: action.message
            };

        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            state = {
                ...state,
                selectedContext: action.customerContext
            };
            return state;

        case actionIndex.SELECT_CUSTOMER_ERROR:
            return {
                ...state,
                errorMessage: action.message
            };

        default:
            return state;
    }
}

