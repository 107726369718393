import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-link-expired-response',
  templateUrl: './link-expired-response.component.html',
  styleUrls: ['./link-expired-response.component.css']
})
export class LinkExpiredResponseComponent {

  @Output() ok = new EventEmitter();

  showCloseButton = false;

  constructor(public bsModalRef: BsModalRef) { }

  onOkClick() {
    this.ok.emit();
    this.bsModalRef.hide();
  }

}
