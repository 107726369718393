import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'app-modules/core/core.module';
import { FormsModule } from '@angular/forms';
import { EmrNgControlsModule, EmrNgMapApiModule } from 'emr-ng-shared';
import { CalibrationStatementsComponent } from './components/calibration-statements/calibration-statements.component';
import { CalibrationStatementsService } from './services/calibration-statements.service';



@NgModule({
  declarations: [
    CalibrationStatementsComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    EmrNgControlsModule,
    EmrNgMapApiModule
  ],
  providers: [
    CalibrationStatementsService
  ]
})
export class CalibrationStatementsModule { }
