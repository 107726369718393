import { StoreManager } from './store-manager';

export class Locale {
    constructor(public id: string, public formalName: string, public nativeName: string) { }
}

export class LocaleMapping {
    constructor(public id: string, public supportedLocale: Locale) { }
}

export class LocaleResolver {
    private supportedLocales: Locale[];
    private localeMap: LocaleMapping[];

    constructor(private key: string) {
        this.supportedLocales = [
            // new Locale('bn', 'Bangla', 'বাংলা'),
            // new Locale('bn-IN', 'Bangla (India)', 'বাংলা (ভারত)'),
            new Locale('de', 'German', 'Deutsch'),
            new Locale('en', 'English', 'English'),
            new Locale('en-CA', 'English (Canada)', 'English (Canada)'),
            new Locale('en-HK', 'English (Hong Kong SAR China)', 'English (Hong Kong SAR China)'),
            // new Locale('en-IN', 'English (India)', 'English (India)'),
            new Locale('en-US', 'English (United States)', 'English (United States)'),
            new Locale('es', 'Spanish', 'español'),
            new Locale('es-MX', 'Spanish (Mexico)', 'español (México)'),
            new Locale('es-US', 'Spanish (United States)', 'español (Estados Unidos)'),
            new Locale('fr', 'French', 'français'),
            // new Locale('fr-CA', 'French (Canada)', 'français (Canada)'),
            // new Locale('gu', 'Gujarati', 'ગુજરાતી'),
            // new Locale('gu-IN', 'Gujarati (India)', 'ગુજરાતી (ભારત)'),
            // new Locale('hi', 'Hindi', 'हिन्दी'),
            // new Locale('hi-IN', 'Hindi (India)', 'हिन्दी (भारत)'),
            new Locale('it', 'Italian', 'Italiano'),
            new Locale('ja', 'Japanese', '日本語'),
            // new Locale('ja-JP', 'Japanese (Japan)', '日本語 (日本)'),
            // new Locale('kn', 'Kannada', 'ಕನ್ನಡ'),
            // new Locale('kn-IN', 'Kannada (India)', 'ಕನ್ನಡ (ಭಾರತ)'),
            new Locale('ko', 'Korean', '한국어'),
            // new Locale('ko-KR', 'Korean (South Korea)', '한국어(대한민국)'),
            // new Locale('ml', 'Malayalam', 'മലയാളം'),
            // new Locale('ml-IN', 'Malayalam (India)', 'മലയാളം (ഇന്ത്യ)'),
            // new Locale('mr', 'Marathi', 'मराठी'),
            // new Locale('mr-IN', 'Marathi (India)', 'मराठी (भारत)'),
            new Locale('pl', 'Polish', 'Polski'),
            new Locale('pt', 'Portuguese', 'Português'),
            new Locale('ru', 'Russian', 'русский'),
            // new Locale('ta', 'Tamil', 'தமிழ்'),
            // new Locale('ta-IN', 'Tamil (India)', 'தமிழ் (இந்தியா)'),
            // new Locale('te', 'Telugu', 'తెలుగు'),
            // new Locale('te-IN', 'Telugu (India)', 'తెలుగు (భారతదేశం)'),
            new Locale('th', 'Thai (Thailand)', 'ไทย (ไทย)'),
            new Locale('tr', 'Turkish', 'Türk'),
            // new Locale('ur', 'Urdu', 'اردو'),
            // new Locale('ur-IN', 'Urdu (India)', 'اردو (بھارت)'),
            new Locale('zh', 'Chinese', '中文'),
            new Locale('zh-CN', 'Chinese (China)', '中文（中国）'),
            // new Locale('zh-hans', 'Chinese (Simplified)', '中文（简体）'),
            // new Locale('zh-hans-CN', 'Chinese (Simplified, China)', '中文（简体，中国）'),
            // new Locale('zh-hans-HK', 'Chinese (Simplified, Hong Kong SAR China)', '中文（简体，中国香港特别行政区）'),
            // new Locale('zh-hans-MO', 'Chinese (Simplified, Macau SAR China)', '中文（简体，中国澳门特别行政区）'),
            // new Locale('zh-hans-SG', 'Chinese (Simplified, Singapore)', '中文（简体，新加坡）'),
            // new Locale('zh-hant', 'Chinese (Traditional)', '中文（繁體）'),
            // new Locale('zh-hant-HK', 'Chinese (Traditional, Hong Kong SAR China)', '中文（繁體字，中國香港特別行政區）'),
            // new Locale('zh-hant-MO', 'Chinese (Traditional, Macau SAR China)', '中文（繁體字，中國澳門特別行政區）'),
            // new Locale('zh-hant-TW', 'Chinese (Traditional, Taiwan)', '中文（繁體，台灣）'),
            new Locale('zh-HK', 'Chinese (Hong Kong SAR China)', '中文（中国香港特别行政区）'),
            new Locale('ar', 'Arabic (Saudi Arabia)', 'عربى'),
            // new Locale('zh-TW', 'Chinese (Taiwan)', '中文（台灣）')
            new Locale('vi', 'Vietnamese', 'Tiếng Việt')
        ];

        this.localeMap = [
            // new LocaleMapping('bn', this.supportedLocales.find(a => a.id === 'bn')),
            // new LocaleMapping('bn-in', this.supportedLocales.find(a => a.id === 'bn-IN')),
            new LocaleMapping('de', this.supportedLocales.find(a => a.id === 'de')),
            new LocaleMapping('en', this.supportedLocales.find(a => a.id === 'en')),
            new LocaleMapping('en-ca', this.supportedLocales.find(a => a.id === 'en')),
            new LocaleMapping('en-hk', this.supportedLocales.find(a => a.id === 'en')),
            // new LocaleMapping('en-in', this.supportedLocales.find(a => a.id === 'en-IN')),
            new LocaleMapping('en-us', this.supportedLocales.find(a => a.id === 'en')),
            new LocaleMapping('es', this.supportedLocales.find(a => a.id === 'es')),
            new LocaleMapping('es-mx', this.supportedLocales.find(a => a.id === 'es')),
            new LocaleMapping('es-us', this.supportedLocales.find(a => a.id === 'es')),
            new LocaleMapping('fr', this.supportedLocales.find(a => a.id === 'fr')),
            // new LocaleMapping('fr-ca', this.supportedLocales.find(a => a.id === 'fr-CA')),
            // new LocaleMapping('gu', this.supportedLocales.find(a => a.id === 'gu')),
            // new LocaleMapping('gu-in', this.supportedLocales.find(a => a.id === 'gu-IN')),
            // new LocaleMapping('hi', this.supportedLocales.find(a => a.id === 'hi')),
            // new LocaleMapping('hi-in', this.supportedLocales.find(a => a.id === 'hi-IN')),
            new LocaleMapping('it', this.supportedLocales.find(a => a.id === 'it')),
            new LocaleMapping('ja', this.supportedLocales.find(a => a.id === 'ja')),
            // new LocaleMapping('ja-jp', this.supportedLocales.find(a => a.id === 'ja-JP')),
            // new LocaleMapping('kn', this.supportedLocales.find(a => a.id === 'kn')),
            // new LocaleMapping('kn-in', this.supportedLocales.find(a => a.id === 'kn-IN')),
            new LocaleMapping('ko', this.supportedLocales.find(a => a.id === 'ko')),
            // new LocaleMapping('ko-kr', this.supportedLocales.find(a => a.id === 'ko-KR')),
            // new LocaleMapping('ml', this.supportedLocales.find(a => a.id === 'ml')),
            // new LocaleMapping('ml-in', this.supportedLocales.find(a => a.id === 'ml-IN')),
            // new LocaleMapping('mr', this.supportedLocales.find(a => a.id === 'mr')),
            // new LocaleMapping('mr-in', this.supportedLocales.find(a => a.id === 'mr-IN')),
            new LocaleMapping('pl', this.supportedLocales.find(a => a.id === 'pl')),
            new LocaleMapping('pt', this.supportedLocales.find(a => a.id === 'pt')),
            new LocaleMapping('ru', this.supportedLocales.find(a => a.id === 'ru')),
            // new LocaleMapping('ta', this.supportedLocales.find(a => a.id === 'ta')),
            // new LocaleMapping('ta-in', this.supportedLocales.find(a => a.id === 'ta-IN')),
            // new LocaleMapping('te', this.supportedLocales.find(a => a.id === 'te')),
            // new LocaleMapping('te-in', this.supportedLocales.find(a => a.id === 'te-IN')),
            new LocaleMapping('th', this.supportedLocales.find(a => a.id === 'th')),
            // new LocaleMapping('th-th', this.supportedLocales.find(a => a.id === 'th')),
            new LocaleMapping('tr', this.supportedLocales.find(a => a.id === 'tr')),
            // new LocaleMapping('ur', this.supportedLocales.find(a => a.id === 'ur')),
            // new LocaleMapping('ur-in', this.supportedLocales.find(a => a.id === 'ur-IN')),
            new LocaleMapping('zh', this.supportedLocales.find(a => a.id === 'zh')),
            new LocaleMapping('zh-cn', this.supportedLocales.find(a => a.id === 'zh')),
            new LocaleMapping('zh-hans', this.supportedLocales.find(a => a.id === 'zh')),
            new LocaleMapping('zh-hans-cn', this.supportedLocales.find(a => a.id === 'zh')),
            new LocaleMapping('zh-hans-hk', this.supportedLocales.find(a => a.id === 'zh')),
            // new LocaleMapping('zh-hans-mo', this.supportedLocales.find(a => a.id === 'zh-hans-MO')),
            // new LocaleMapping('zh-hans-sg', this.supportedLocales.find(a => a.id === 'zh-hans-SG')),
            // new LocaleMapping('zh-hant', this.supportedLocales.find(a => a.id === 'zh-hant')),
            // new LocaleMapping('zh-hant-hk', this.supportedLocales.find(a => a.id === 'zh-hant-HK')),
            // new LocaleMapping('zh-hant-mo', this.supportedLocales.find(a => a.id === 'zh-hant-MO')),
            // new LocaleMapping('zh-hant-tw', this.supportedLocales.find(a => a.id === 'zh-hant-TW')),
            new LocaleMapping('zh-hk', this.supportedLocales.find(a => a.id === 'zh')),
            new LocaleMapping('ar', this.supportedLocales.find(a => a.id === 'ar')),
            // new LocaleMapping('zh-tw', this.supportedLocales.find(a => a.id === 'zh-TW'))
            new LocaleMapping('vi', this.supportedLocales.find(a => a.id === 'vi'))
        ];
    }

    public resolveLocale(): string {
        let candidates: string[];
        let locale: string;

        // Querystring ("locales")
        try {
            candidates = this.resolveCandidatesFromQueryString();
            if (candidates) {
                if (candidates[0] === 'none') {
                    return null;
                }

                locale = this.resolveLocaleFromCandidates(candidates);
                if (locale) {
                    return locale;
                }
            }
        } catch (e) { console.log(e); }

        // Local Storage
        try {
            [candidates] = StoreManager.resolveCandidatesFromLocalStorage(this.key);
            if (candidates) {
                locale = this.resolveLocaleFromCandidates(candidates);
                if (locale) {
                    return locale;
                }
            }
        } catch (e) { console.log(e); }

        // Cookie
        try {
            [candidates] = StoreManager.resolveCandidatesFromCookie(this.key);
            if (candidates) {
                locale = this.resolveLocaleFromCandidates(candidates);
                if (locale) {
                    return locale;
                }
            }
        } catch (e) { console.log(e); }

        // No locale found in URL, cookie or local storage.
        // defaulting to null.
        locale = null;
        return locale;
    }

    public resolveCandidatesFromQueryString(): string[] {
        const candidates: string[] = [];
        // LocaleResolver-resolveCandidatesFromQueryString, for now commenting out this code for OR-1102
        // TBD, does it require? if yes, URLSearchParams is throwing error on IE-11, & required to change code accordingly.
        // const urlParams = new URLSearchParams(window.location.search);

        // for (const locales of urlParams.getAll('locales')) {
        //     for (const locale of locales.split(',')) {
        //         candidates.push(locale);
        //     }
        // }

        return candidates;
    }

    public resolveLocaleFromCandidates(candidates: string[]): string {
        // Find the first candidate that resolves to non-null
        let locale: string;

        for (const candidate of candidates) {
            locale = this.resolveLocaleFromCandidate(candidate);
            if (locale) {
                return locale;
            }
        }

        return null;
    }

    public resolveLocaleFromCandidate(candidate: string): string {
        // Return a supported locale or null
        let id: string;
        let mapping: LocaleMapping;

        if (candidate == null || candidate === '') {
            return null;
        }

        // Get the 'BCP 47' locale id for angular i18n compatibility
        id = candidate.trim().replace(/_/g, '-').toLowerCase();

        mapping = this.localeMap.find(a => a.id === id);

        return mapping ? mapping.supportedLocale.id : null;
    }
}
