import { Component, OnInit, TemplateRef, ViewEncapsulation, ViewChild, OnDestroy, Optional, EventEmitter, ElementRef } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { Observable, Subscription, combineLatest, forkJoin, of } from 'rxjs';
import { map, take, tap, mergeMap } from 'rxjs/operators';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import {
    CreateShipmentRequest, CoolDownTypeInfo, DualVisibilityLinkCustomer, ValidateDVShipmentRequest,
    ValidateDVShipmentResponse, TripStartOptions, ShipmentRequest, Sensor, TripEndOptions, SetLookUpRequest
} from 'emr-ng-shared';
import { Customer } from 'emr-ng-shared';
import { TripTemplateInfo } from 'emr-ng-shared';
import { VehicleGroupInfo, GetBusinessRulesRequest } from 'emr-ng-shared';
import { TripStop } from 'emr-ng-shared';
import { LocationInfo } from 'emr-ng-shared';
import { TimeZoneInfo } from 'emr-ng-shared';
import { SensorRangeInfo } from 'emr-ng-shared';
import { SetLocationRequest } from 'emr-ng-shared';
import { filter } from 'rxjs/operators';
import { SensorType } from 'emr-ng-shared';
import { environment } from 'environments/environment';

import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { VehicleGroupsService } from 'app-modules/core/store/vehicle-group/vehicle-groups.service';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { Tracker } from 'app-modules/core/models/tracker.model';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { TimeZoneService } from 'app-modules/core/store/time-zone/time-zone.service';
import { AddLocationEventArgs, LocationComponent } from 'app-modules/core/components/location/location.component';
import {
    CreateShipmentResponseComponent,
    CreateShipmentResponseOkEventArgs
} from 'app-modules/create-shipment/components/create-shipment-response/create-shipment-response.component';
import {
    SensorRangeComponent, SensorRangeEventArgs
} from 'app-modules/core/components/sensor-range/components/sensor-range/sensor-range.component';
import { GetTripTemplateDetailsResponse } from 'emr-ng-shared';
import { CarrierListInfo, EmrUtilService, DateTimeObject, GetLookUpRequest, Lookup } from 'emr-ng-shared';
import { SetSensorRangeRequest } from 'emr-ng-shared';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { CoolDownTypeService } from 'app-modules/core/store/cool-down-type/cool-down-type.service';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { SensorRangeService } from 'app-modules/core/store/sensor-range/sensor-range.service';
import { LinkedCustomersService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.service';
import { CreateShipmentStateService } from 'app-modules/core/store/create-shipment/create-shipment-state.service';
import { ShipmentTemplateService } from 'app-modules/core/store/shipment-template/shipment-template.service';
import { StopLocationListComponent } from 'app-modules/core/components/stop-location-list/stop-location-list.component'
import { location } from 'ngx-bootstrap/utils/facade/browser';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { BusinessRuleType, TripStopTypeCode } from 'emr-ng-shared';
import { TripStartOptionsService } from 'app-modules/core/store/create-shipment/trip-start-options/trip-start-options.service';
import { StopLocation } from 'app-modules/core/models/stop-location.model';
import { TripStopModel } from 'app-modules/core/store/models/trip-stop.model';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { Origin_Destination, Origin, Destination, label_no_program, label_shipment_name, label_enter_shipment_name, label_po, po_required, shipment_name_required, plateNumber, containerNumber, driverPhoneNumber, label_transfer } from 'app-modules/core/consts/localization';
import { TripEndOptionsService } from 'app-modules/core/store/create-shipment/trip-end-options/trip-end-options.service';
import { CustomerStateService } from 'app-modules/core/store/services/customer-state.service';


export class LocationInfoListItem {
    get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
    get value() { return this.locationInfo.LocationId; }
    constructor(private locationInfo: LocationInfo) { }
}

export enum ShipmentGroup {
    Multitrip = 101,
    InActive = 102,
    Active = 103,
    InProgressMultiTrip = 104
}

@Component({
    selector: 'app-create-shipment',
    templateUrl: './create-shipment.component.html',
    styleUrls: ['./create-shipment.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class CreateShipmentComponent implements OnInit, OnDestroy {
    public errorInfo: IErrorInfo; // JVG
    shipmentNameRequired = false;
    @ViewChild(StopLocationListComponent) child: StopLocationListComponent;
    disableSubmitButton = false;
    updatesSub: Subscription;
    isLinkedCustomerUpdating: boolean = false;
    editedCarrierId: number = null;
    public newCarrier: string = '';
    public editCarrier: string = '';
    errorMessage: string | null = null;

    constructor(
        private authSvc: AuthService, // JVG
        private createSvc: CreateShipmentService,
        private sensorRangeSvc: SensorRangeService,
        private modalSvc: BsModalService,
        @Optional() public bsMainModalRef: BsModalRef,
        private customersSvc: CustomersService,
        private vehicleGroupSvc: VehicleGroupsService,
        private utilSvc: EmrUtilService,
        private shipSvc: ShipmentService,
        private timeZoneSvc: TimeZoneService,
        private coolDownSvc: CoolDownTypeService,
        private linkedCustSvc: LinkedCustomersService,
        private businessRuleSvc: BusinessRulesService,
        private tripStartSvc: TripStartOptionsService,
        private tripEndSvc: TripEndOptionsService,
        private preferenceSvc: PreferenceService,
        private createStateSvc: CreateShipmentStateService,
        private custStateSvc: CustomerStateService,
        private custSvc: CustomersService
    ) { }

    @ViewChild(NgForm) shipmentForm: NgForm;
    @ViewChild(NgForm) editCarrierForm: NgForm;
    @ViewChild(NgModel) dvPullControl: NgModel;
    @ViewChild('confirmModal') public confirmModalTemplate: TemplateRef<any>;
    @ViewChild('inboundOutboundModal') inboundOutboundModal: TemplateRef<any>;
    @ViewChild('sharewithModal') sharewithModal: TemplateRef<any>;
    @ViewChild('manageCarrierModal') manageCarrierModal: TemplateRef<any>;

    // locLoadRequired = this.createSvc.isLoadRequired$;
    isDestinationCollapsed = false;
    isDestinationContactCollapsed = true;
    isOriginContactCollapsed = true;
    isAlertContactCollapsed = true;
    bsModalRef: BsModalRef;
    bsCarrierModalRef: BsModalRef;
    submitResponseModalRef: BsModalRef;
    submitResponseMessage = 'Submission successful';
    createShipmentFailed = true;
    showDepartureDetails = false;
    isMultiTripSerial = false;
    distinctLocationFromOD = true;
    stopLocations: LocationInfoListItem[];
    isInValidSerialNumber = false;
    isMultiTripInValidSerialNumber = false;
    revalidateStop = new EventEmitter<StopLocation>();
    // isValidSerialNumberPull = true;
    noValidTrackers = true;
    isTrackerLoadRequired = true;
    public locale: string = null;
    warningMessage: string = null;
    processValidateTrip = false;
    validateDVResponse: ValidateDVShipmentResponse = null;
    tripTemplateDetails = new GetTripTemplateDetailsResponse();
    DisplayVehicleGroupingInCreateShipment: boolean = false;
    EndLocationUndefinedBR: boolean = false;
    IsCarrierFieldRequired = false;
    DisplayTripStartOptions = false;
    DisplayTripEndOptions = false;
    isCreateOptionsDisbabled = false;
    IsShareWithRequired = false;
    isVisibleFieldPlateNum = false;
    isVisibleFieldContainerNum = false;
    isVisibleFieldDriverPhoneNum = false;
    DVReceiverPONumberVisible: boolean = false;

    isBusinessRuleLoading = false;
    isFormLoading$: Observable<Boolean>;

    trackerListPullSubscription: Subscription;
    templateListSubscription: Subscription;
    templateDetailsSubscription: Subscription;
    stopLocationsSubscription: Subscription;
    getTrackerSubscription: Subscription;
    createShipmentSubscription: Subscription;
    createshipSubscription: Subscription;
    validateSubscription: Subscription;
    forkSubscription: Subscription;
    TrackerSubscription: Subscription;
    controlColumns = 9;

    contactType = '';

    originContactList: any = [];

    destinationContactList: any = [];
    serialOptionList$: Observable<Tracker[]>;

    shipment = new CreateShipmentRequest();
    noDestinationRequired: boolean;
    startTime: Date;
    defaultMinTime: Date = new Date(2008, 0, 1);
    endTime: Date;
    serialNumber: string;
    serialNumberPull: string;
    customerTrackerId: string;

    isDistinctOriginStopLocation = true;
    isDistinctDestinationStopLocation = true;

    shipmentCarrierList = [
        { text: 'CHR', value: 1 }
    ];

    AlertEscalationLevelList = [
        { text: 'Level 1', value: 1 },
        { text: 'Level 2', value: 2 },
        { text: 'Level 3', value: 3 },
        { text: 'Level 4', value: 4 },
        { text: 'Level 5', value: 5 },
        { text: 'Level 6', value: 6 }
    ];

    vehicleGroupIds: VehicleGroupInfo[];
    VehicleGroups: VehicleGroupInfo[];

    // Dual Visibility
    disableDVPush = false;
    disableDVPull = false;
    LinkedCustomerIdPush: number;
    LinkedCustomerIdPull: number;
    isEndLocationUndefined = false;
    trackerList$: Observable<Tracker[]>;
    trackerListPull: Tracker[];
    templateList$: Observable<IListInfo<TripTemplateInfo>>;
    originLocations$: Observable<LocationInfoListItem[]>;
    stopLocations$: Observable<LocationInfoListItem[]>;
    destLocations$: Observable<LocationInfoListItem[]>;
    timeZoneList$: Observable<IListInfo<TimeZoneInfo>>;
    sensorRangeList$: any;
    carrierList: Lookup[];
    coolDownTypeList$: Observable<CoolDownTypeInfo[]>;
    linkedCustomersPushList$: Observable<DualVisibilityLinkCustomer[]>;
    linkedCustomersPullList$: Observable<DualVisibilityLinkCustomer[]>;
    businessRulesListSubscription: Subscription;
    isSensorListLoading$: Observable<Boolean>;
    isLocationListLoading$: Observable<Boolean>;
    isTemplatesListLoading$: Observable<Boolean>;
    isLoadingTrackerPullList$: Observable<Boolean>;
    isCoolDownLoading$: Observable<boolean>;
    isTimeZoneLoading$: Observable<boolean>;
    isTrackerLoading$: Observable<boolean>;
    isTripStartOptionsLoading$: Observable<boolean>;
    isTripEndOptionsLoading$: Observable<boolean>;
    tripStartOptionsList$: Observable<TripStartOptions[]>;
    tripEndOptionsList$: Observable<TripEndOptions[]>;

    private trackerLoadRequiredSubscription: Subscription;
    private trackerListSubscription: Subscription;
    vehicleGroupListSubscription: Subscription;
    userPreferenceSubscription: Subscription;
    carrierListSubscription: Subscription;

    dateTimeObject: DateTimeObject = new DateTimeObject();
    stops: TripStopModel[] = [];
    userName: string = "";
    isAdminUser: boolean = false;

    // To support Multi sensors
    showCO2Sensor = false;
    showProbSensor = false;
    showHumiditySensor = false;

    TemperatureRangeId: number;
    HumiditySensorId: number;
    CarbonDioxideSensorId: number;
    ProbSensorId: number;
    sensorTypes: Sensor[];
    sensorType;
    previousEnteredValue: string;
    // End To support Multi sensors

    selectedLinkedCustomerSub: Subscription;
    labelShipmentName = label_shipment_name;
    labelEnterShipmentName = label_enter_shipment_name;
    labelShipmentNameRequired = shipment_name_required;

    labelPlateNumber = plateNumber;
    labelContainerNumber = containerNumber;
    labelDriverPhoneNumber = driverPhoneNumber;
    isRequired = false;
    isRequiredShareWith = false;
    IsCarrierDropDown = false;
    DisplaySiteTransferNoProgram = false;
    authStateSub: Subscription;
    selectedCustomer: string;


    ngOnInit() {
        this.authStateSub = this.authSvc.authState$.subscribe(au => {
            this.userName = au.username;
            this.isAdminUser = au.isAdminUser;
            this.authStateSub?.unsubscribe();
        });
        this.custSvc.selectedContext$.pipe(take(1)).subscribe(k => { 
            this.selectedCustomer = k?.customer?.Description;
        });
        this.getBusinessRules();
        this.shipment.CustomerTrackerId = this.customerTrackerId;
        this.shipment.TripStops = [];
        this.TrackerSubscription = this.createSvc.trackerList$.subscribe(n => {
            let Trackers: Tracker[] = [];
            if (n && n.list && n.list.length > 0) {
                this.noValidTrackers = true;
                Trackers = n.list;
            } else {
                this.noValidTrackers = false;
                Trackers = [];
            }
            const requestedParams = this.authSvc.getRequestedParams();
            if (requestedParams) {
                this.serialNumber = requestedParams.id;
                if (Trackers?.length > 0) {
                    const tracker = Trackers.find(k => k.trackerId === requestedParams.id);
                    if (tracker) {
                        this.assignSelectedTracker(tracker);
                    }
                    else {
                        this.isInValidSerialNumber = true;
                    }
                    this.authSvc.setRequestedParams(null);
                }
            }
            this.trackerList$ = of(Trackers);
        });

        // DV Customer List
        this.linkedCustomersPushList$ = this.linkedCustSvc.linkedCustomersPushList$.pipe(map(n => {
            let dvCutomerList = n.list;
            if (this.IsShareWithRequired) {
                if (!dvCutomerList.find(c => c.CustomerId === -1)) {
                    let dvCustomer = new DualVisibilityLinkCustomer();
                    dvCustomer.Description = (this.DisplaySiteTransferNoProgram && this.selectedCustomer) ? this.selectedCustomer + ' ' + label_transfer : label_no_program;
                    dvCustomer.CustomerId = -1;
                    dvCutomerList.push(dvCustomer);
                }
            } else {
                dvCutomerList = dvCutomerList.filter(c => c.CustomerId != -1);
            }
            return dvCutomerList;
        }));
        this.linkedCustomersPullList$ = this.linkedCustSvc.linkedCustomersPullList$.pipe(map(n => n.list));
        this.sensorType = SensorType;

        if (!this.bsMainModalRef) { this.controlColumns = 8; }

        this.templateList$ = this.createSvc.getShipmentTemplateListLoader();
        this.vehicleGroupListSubscription = this.vehicleGroupSvc.getVehicleGroupList().subscribe(k => {
            this.VehicleGroups = k;
        });

        this.originLocations$ = this.createSvc.originList$.pipe(map(n => n.list.map(item => new LocationInfoListItem(item))));
        this.stopLocationsSubscription =
            this.createSvc.stopList$.subscribe(n => this.stopLocations = n.list.map(item => new LocationInfoListItem(item)));


        // this.timeZoneList$ = this.timeZoneSvc.timeZoneList$;
        this.isLocationListLoading$ = this.createSvc.isLoadingLocationsList$;
        this.isSensorListLoading$ = this.createSvc.isLoadingSensorsList$;
        this.isTemplatesListLoading$ = this.createSvc.isLoadingTemplatesList$;
        this.isLoadingTrackerPullList$ = this.createSvc.isLoadingTrackerPullList$;
        this.isCoolDownLoading$ = this.coolDownSvc.isLoading$;
        this.isTimeZoneLoading$ = this.timeZoneSvc.isLoading$;
        this.isTrackerLoading$ = this.createSvc.isTrackerLoading$;
        this.sensorRangeList$ = this.createSvc.sensorRangeList$;
        this.timeZoneList$ = this.timeZoneSvc.timeZoneList$;
        this.carrierListSubscription = this.createSvc.loadCarrierList().subscribe(k => {
            this.carrierList = k;
        });
        this.coolDownTypeList$ = this.coolDownSvc.coolDownTypeList$.pipe(map(n => n.list));


        this.isTripStartOptionsLoading$ = this.tripStartSvc.isLoading$;
        this.tripStartOptionsList$ = this.tripStartSvc.tripStartOptionsList$.pipe(map(n => {
            this.setBusinessRuleValues(0);
            return n.list;
        }));

        this.isTripEndOptionsLoading$ = this.tripEndSvc.isLoading$;
        this.tripEndOptionsList$ = this.tripEndSvc.TripEndOptionsList$.pipe(map(n => {
            this.setBusinessRuleDestinationValues(0);
            return n.list;
        }));

        this.destLocations$ = this.createSvc.destinationList$.pipe(map(n => {
            return n.list.map(item => new LocationInfoListItem(item));
        }));
        // locale Settings
        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        this.locale = localeResolver.resolveLocale();
        // this.createSvc.selectRetailer(null);
        // this.trackerListSubscription = this.trackerList$.subscribe(a => {
        //     if (a) {
        //         if (a.length > 0) {
        //             this.noValidTrackers = true;
        //         } else {
        //             this.noValidTrackers = false;
        //         }
        //     }
        // });
        this.trackerLoadRequiredSubscription = this.createSvc.isTrackerLoadRequired$.subscribe(a => this.isTrackerLoadRequired = a);

        this.isFormLoading$ = combineLatest(
            this.isSensorListLoading$,
            this.isLocationListLoading$,
            this.isTemplatesListLoading$,
            this.isCoolDownLoading$,
            this.isTimeZoneLoading$,
            this.isTripStartOptionsLoading$,
            this.isTripEndOptionsLoading$,
            (isSensorListLoading, isLocationListLoading, isTemplatesListLoading,
                isCoolDownLoading, isTimeZoneLoading, isTripStartOptionsLoading, isTripEndOptionsLoading) => {
                return isSensorListLoading || isLocationListLoading || isTemplatesListLoading ||
                    isCoolDownLoading || isTimeZoneLoading || isTripStartOptionsLoading || isTripEndOptionsLoading;
            });
        this.userPreferenceSubscription = this.preferenceSvc.getDateTimeObject().subscribe(k => {
            this.dateTimeObject = k;
        });
        if (this.serialNumber) {
            this.GetTrackerSensorsBySerial(false);
        }
    }

    ngOnDestroy() {
        if (this.selectedLinkedCustomerSub) {
            this.selectedLinkedCustomerSub.unsubscribe();
            this.selectedLinkedCustomerSub = null;
        }

        this.clearUpdatesSub();
        this.linkedCustomerChanged(null);
        if (this.templateDetailsSubscription) {
            this.templateDetailsSubscription.unsubscribe();
        }
        if (this.trackerLoadRequiredSubscription) {
            this.trackerLoadRequiredSubscription.unsubscribe();
        }
        if (this.businessRulesListSubscription) {
            this.businessRulesListSubscription.unsubscribe();
        }
        if (this.vehicleGroupListSubscription) {
            this.vehicleGroupListSubscription.unsubscribe()
        }
        if (this.stopLocationsSubscription) {
            this.stopLocationsSubscription.unsubscribe()
        }
        if (this.userPreferenceSubscription) {
            this.userPreferenceSubscription.unsubscribe();
        }
        if (this.TrackerSubscription) {
            this.TrackerSubscription.unsubscribe();
        }
        if (this.getTrackerSubscription) {
            this.getTrackerSubscription.unsubscribe();
        }
        if (this.createShipmentSubscription) {
            this.createShipmentSubscription.unsubscribe();
        }
        if (this.createshipSubscription) {
            this.createshipSubscription.unsubscribe();
        }
        if (this.validateSubscription) {
            this.validateSubscription.unsubscribe();
        }
        if (this.forkSubscription) {
            this.forkSubscription.unsubscribe();
        }
        this.carrierListSubscription?.unsubscribe();
    }

    // onCauseError() {
    //     this.requestError(
    //         this,
    //         this.onSuccess,
    //         this.onFailure
    //     );
    //         // this.shipSvc.createShipment(this.getCreateShipmentRequest()).pipe(take(1)).subscribe(
    //         //     n => this.openSubmitResponse(false, null, true),
    //         //     e => this.openSubmitResponse(true, e, false)
    //         // );

    // }


    // onDestinationListChange(locationList: LocationInfo[]) {
    // }

    onSerialNumberChange(e) {
        this.showCO2Sensor = this.showProbSensor = this.showHumiditySensor = false;
        if (e.item.trackerId !== undefined && this.serialNumber === e.item.trackerId) {
            this.isInValidSerialNumber = false;
            this.assignSelectedTracker(e.item);
        } else {
            this.isMultiTripInValidSerialNumber = false;
            this.isInValidSerialNumber = true;
            this.shipment.CustomerTrackerId = undefined;
            this.isMultiTripSerial = false;
        }
        this.shipment.NoDestinationRequired = false;
    }

    CheckMultiTripInProgress(data: any) {
        if (data.groupIndex === 104) {
            this.isMultiTripInValidSerialNumber = true;
        } else { this.isMultiTripInValidSerialNumber = false; }
    }

    NoResultsForSerialNumber(e) {
        this.isInValidSerialNumber = false;
        this.isMultiTripSerial = false;
        this.shipment.NoDestinationRequired = false;
        this.isMultiTripInValidSerialNumber = false;
        if (e) {
            this.isInValidSerialNumber = true;
            this.shipment.CustomerTrackerId = undefined;
            this.isMultiTripSerial = false;
        }
    }

    assignSelectedTracker(e) {
        if (e?.trackerId && this.serialNumber !== e.trackerId) {
            return;
        }
        this.CheckMultiTripInProgress(e);
        this.shipment.CustomerTrackerId = e.customerTrackerId;
        if (e.groupIndex === ShipmentGroup.Multitrip || e.groupIndex === ShipmentGroup.InProgressMultiTrip) {
            this.isMultiTripSerial = true;
            this.shipment.CooldownTypeCode = null;
        } else {
            this.isMultiTripSerial = false;
        }
        this.shipment.NoDestinationRequired = false;

        if (!this.isMultiTripInValidSerialNumber) {
            this.GetTrackerSensorsBySerial();
        }
    }

    showNoDestinationRequired() {
        const isDestinationOptional = this.isMultiTripSerial &&
            !this.disableDVPull &&
            !this.disableDVPush;
        if (!isDestinationOptional && this.shipment.NoDestinationRequired) {
            this.shipment.NoDestinationRequired = false;
        }
        return isDestinationOptional;
    }

    onSerialNumberSelected(e) {
        this.isInValidSerialNumber = false;
        this.assignSelectedTracker(e.item);
    }

    linkedCustomerChanged(linkedCustomer: DualVisibilityLinkCustomer) {
        this.isLinkedCustomerUpdating = true;
        this.clearUpdatesSub();
        this.updatesSub = this.createStateSvc.selectedLinkedCustomer$.pipe(
            map(n => {
                if (n === linkedCustomer || n?.CustomerId === linkedCustomer?.CustomerId) {
                    this.updateOnLinkedCustomerChange(linkedCustomer);
                    this.clearUpdatesSub();
                    this.isLinkedCustomerUpdating = false;
                }
                else if (linkedCustomer?.CustomerId == -1) {
                    this.isLinkedCustomerUpdating = false;
                }
            })).subscribe();
        this.createSvc.selectLinkedCustomer(linkedCustomer && linkedCustomer.CustomerId > 0 ? linkedCustomer : null);
    }

    private updateOnLinkedCustomerChange(linkedCustomer: DualVisibilityLinkCustomer) {
        if (linkedCustomer && linkedCustomer.CustomerId > 0) {
            this.DisplayTripStartOptions = false;
            this.DisplayTripEndOptions = false;
            this.DisplayVehicleGroupingInCreateShipment = false;
            this.isRequiredShareWith = false;
            this.IsCarrierFieldRequired = false;
            this.IsCarrierDropDown = false;
            const setDisplayTripStartOption = (value: boolean) => {
                this.DisplayTripStartOptions = value;
            };

            const setDisplayTripEndOption = (value: boolean) => {
                this.DisplayTripEndOptions = value;
            };

            const setDisplayVehicleGroup = (value: boolean) => {
                this.DisplayVehicleGroupingInCreateShipment = value;
            };

            const setPONumberInsteadOfShipmentName = (value: boolean) => {
                this.updateLabels(value);
            };

            const setEnableCreateShipmentOptionalFields = (value: boolean) => {
                this.isRequiredShareWith = value;
                this.checkIfSharewithBruleOptionalFieldsEnabled(this.isRequired, this.isRequiredShareWith);
            };

            const setEnableDVReceiverPONumberVisible = (value: boolean) => {
                this.DVReceiverPONumberVisible = value;
            };

            const setIsCarrierFieldRequired = (value: boolean) => {
                this.IsCarrierFieldRequired = value;
            };

            const setIsCarrierDropdown = (value: boolean) => {
                this.IsCarrierDropDown = value;
            };

            const brVehicleGroup = this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.DisplayVehicleGroupingInCreateShipment, setDisplayVehicleGroup);
            const brTripStart = this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.DisplayTripStartOptions, setDisplayTripStartOption);
            const brTripEnd = this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.DisplayTripEndOptions, setDisplayTripEndOption);
            const brPONumberInsteadOfShipmentName = this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.ShowPONumberInsteadOfShipmentName, setPONumberInsteadOfShipmentName);
            const brEnableCreateShipmentOptionalFields = this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.EnableCreateShipmentOptionalFields, setEnableCreateShipmentOptionalFields);
            const brEnableDVReceiverPONumberVisible = this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.DVReceiverPONumberVisible, setEnableDVReceiverPONumberVisible);
            const brCarrierNameRequired = !!this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.CarrierNameRequired, setIsCarrierFieldRequired);
            //need to change the  for Showinng carrier name as dropdown  
            const brCarrierDropdown = !!this.getBusinessRuleByType(linkedCustomer.CustomerId,
                BusinessRuleType.CarrierNameLookupDropdown, setIsCarrierDropdown);

            this.isBusinessRuleLoading = true;
            this.forkSubscription = forkJoin([brVehicleGroup, brTripStart, brTripEnd, brPONumberInsteadOfShipmentName, brEnableCreateShipmentOptionalFields, brEnableDVReceiverPONumberVisible, brCarrierNameRequired, brCarrierDropdown])
                .subscribe(
                    n => { this.isBusinessRuleLoading = false; },
                    e => { this.isBusinessRuleLoading = false; },
                    () => { this.isBusinessRuleLoading = false; }
                );

        } else {
            this.isBusinessRuleLoading = true;
            this.DVReceiverPONumberVisible = false;
            this.getBusinessRules();
            setTimeout(() => {
                this.isBusinessRuleLoading = false;
            })
        }
    }

    getBusinessRuleByType(retailerId: number, businessRuleType: BusinessRuleType, setVariable: (item: boolean) => void) {
        const request = new GetBusinessRulesRequest();
        request.RetailerId = retailerId;
        request.BusinessRuleTypeCode = businessRuleType;
        return this.businessRuleSvc.GetBusinessRuleByEnum(request)
            .pipe(take(1)).subscribe(n => setVariable(n.list && n.list.length > 0 && n.list.find(k => k.BusinessRuleTypeCode === businessRuleType).Enabled));
    }

    getBusinessRules() {
        this.businessRulesListSubscription = this.businessRuleSvc.businessRulesList$.subscribe(n => {
            if (n.list && n.list.length > 0) {
                this.DisplayVehicleGroupingInCreateShipment = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.DisplayVehicleGroupingInCreateShipment)?.Enabled;
                this.DisplayTripStartOptions = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.DisplayTripStartOptions)?.Enabled;
                this.DisplayTripEndOptions = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.DisplayTripEndOptions)?.Enabled;
                this.IsShareWithRequired = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.RequireShareWithInCreateShipment)?.Enabled;
                this.isVisibleFieldPlateNum = n.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldPlateNum)?.Enabled;
                this.isVisibleFieldContainerNum = n.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldContainerNum)?.Enabled;
                this.isVisibleFieldDriverPhoneNum = n.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldDriverPhoneNum)?.Enabled;
                this.EndLocationUndefinedBR = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.EndLocationUndefined)?.Enabled;
                this.isRequired = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.EnableCreateShipmentOptionalFields)?.Enabled;
                const value = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowPONumberInsteadOfShipmentName)?.Enabled;
                this.IsCarrierFieldRequired = !!n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.CarrierNameRequired)?.Enabled;
                this.IsCarrierDropDown = !!n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.CarrierNameLookupDropdown)?.Enabled;
                this.DisplaySiteTransferNoProgram = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.DisplaySiteTransferInsteadofNoProgram)?.Enabled;
                this.updateLabels(value);
            }
        });
    }

    // Initialize values based on BusinessRule
    setBusinessRuleValues(value) {
        setTimeout(() => {
            this.shipment.TripStartOptionID = this.DisplayTripStartOptions ? (value ? value : 0) : null;
        }, 200);
    }

    // Initialize values based on BusinessRule
    setBusinessRuleDestinationValues(value) {
        setTimeout(() => {
            this.shipment.TripEndOptionID = this.DisplayTripEndOptions ? (value ? value : 0) : null;
        }, 200);
    }

    linkedCustomerPushChanged(linkedCustomer: DualVisibilityLinkCustomer) {
        this.selectedLinkedCustomerSub = this.createStateSvc.selectedLinkedCustomer$.pipe(
            take(1),
            map(n => {
                if (n?.CustomerId > 0 || linkedCustomer?.CustomerId > 0) {
                    this.resetDVFields(true);
                }
            })).subscribe();
        this.linkedCustomerChanged(linkedCustomer);
        this.disableDVPull = linkedCustomer?.CustomerId > 0 ? true : false;
        this.isEndLocationUndefined = linkedCustomer && linkedCustomer.IsEndLocationUndefinedEnabled;
        this.isCreateOptionsDisbabled = linkedCustomer && linkedCustomer.IsCreateOptionsDisbabled;
        if (this.isCreateOptionsDisbabled) {
            this.shipment.SaveTripTemplate = false;
        }
        this.setLinkedCustomerDetails(linkedCustomer);
    }

    checkIfSharewithBruleOptionalFieldsEnabled(isRequired, isRequiredShareWith) {
        if (isRequired === true && isRequiredShareWith === false) {
            this.bsModalRef = this.modalSvc.show(this.sharewithModal,
                {
                    class: 'modal-lg modal-dialog-centered',
                    ignoreBackdropClick: true
                })
        }
    }

    linkedCustomerPullChanged(linkedCustomer: DualVisibilityLinkCustomer) {
        this.showCO2Sensor = this.showProbSensor = this.showHumiditySensor = false;
        // this.linkedCustomerChanged(null);
        this.disableDVPush = linkedCustomer ? true : false;
        this.resetDVFields(false);
        this.setLinkedCustomerDetails(linkedCustomer);

    }

    setLinkedCustomerDetails(linkedCustomer: DualVisibilityLinkCustomer) {
        if (linkedCustomer?.CustomerId > 0) {
            this.shipment.LinkedCustomerLinkageType = linkedCustomer.LinkedTypeCode;
            this.shipment.LinkCustomerWebsiteId = linkedCustomer.WebsiteId;
            this.shipment.LinkedCustomerId = linkedCustomer.CustomerId;
        } else {
            this.shipment.LinkedCustomerLinkageType = null;
            this.shipment.LinkCustomerWebsiteId = null;
            this.shipment.LinkedCustomerId = null;
        }
    }

    resetDVFields(reset: boolean) {
        this.shipment.LinkedCustomerLinkageType = null;
        this.trackerListPull = [];
        if (reset) {
            this.shipment.TripTemplateID = null;
            this.bindTripTemplateDetails(new GetTripTemplateDetailsResponse());
        } else {
            this.serialNumber = null;
            this.serialNumberPull = null;
            this.isMultiTripSerial = false;
            this.shipment.CustomerTrackerId = null;
            this.vehicleGroupIds = null;
            this.isInValidSerialNumber = false;
            this.isMultiTripInValidSerialNumber = false;
        }
    }

    onSerialNumberPullChange(e: any) {
        // this.isValidSerialNumberPull = true;

        let selectedTracker = null;
        const dvPullControl = this.shipmentForm.controls['txtSerialPull'];
        this.GetTrackerSensorsBySerial(true);
        // if (this.trackerListPull && this.trackerListPull.length > 0) {
        //     selectedTracker = this.trackerListPull.filter(n => {
        //         return n.trackerId === this.serialNumberPull;
        //     });
        //     if (selectedTracker && selectedTracker.length > 0) {
        //         this.assignSelectedTracker(selectedTracker[0]);
        //     } else {
        //         this.isInValidSerialNumber = true;
        //         this.isMultiTripSerial = false;
        //         // this.isValidSerialNumberPull = false;
        //     }
        // }

        if (dvPullControl) {
            dvPullControl.updateValueAndValidity();
            dvPullControl.setErrors(this.dvPullControl.errors);
        }
    }

    openAddLocationModal(locationType: any) {
        let locationTypes: any[];
        let passShipperCustomerID = null;
        let i: number;
        let IsArrival: boolean;
        if (typeof locationType === 'object') {
            i = locationType['i'];
            IsArrival = locationType['IsArrival'];
            locationType = locationType['data']
        }
        // OR-1350  -- Black
        const originItem = { text: Origin, value: 'Origin' };
        const bothItem = { text: Origin_Destination, value: 'Both' };
        const destItem = { text: Destination, value: 'Destination' };

        switch (locationType) {
            case 'origin':
                locationTypes = [
                    bothItem,
                    originItem
                ];
                passShipperCustomerID = true;
                break;
            case 'tripstop':
                locationTypes = [
                    bothItem,
                    originItem,
                    destItem
                ];
                break;
            case 'destination':
                locationTypes = [
                    bothItem,
                    destItem
                ];
                passShipperCustomerID = true;
                break;
        }

        this.bsModalRef = this.modalSvc.show(
            LocationComponent,
            {
                initialState: {
                    locationTypes,
                    context: locationType,
                    passShipperCustomerID
                },
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        this.bsModalRef.content.addLocation.pipe(take(1)).subscribe(n => this.onAddLocation(n, i, IsArrival));
    }

    onAddLocation(args: AddLocationEventArgs, i: number, IsArrival: boolean) {
        switch (args.context) {
            case 'origin':
                this.shipment.OriginLocationId = args.request.LocationId;
                break;
            case 'destination':
                this.shipment.DestinationLocationId = args.request.LocationId;
                break;
            case 'tripstop':
                if (!this.stops ||
                    i < 0 ||
                    this.stops.length < i) {
                    return;
                }
                const stop = this.stops[i];
                const selectedLocation = this.stopLocations ? this.stopLocations.find(k => k.value === args.request.LocationId) : null;
                const locationName = selectedLocation ? selectedLocation.text : null;
                if (IsArrival) {
                    stop.ArrivalLocationId = args.request.LocationId;
                    stop.ArrivalLocationName = locationName;
                } else {
                    stop.LocationId = args.request.LocationId;
                    stop.LocationName = locationName;
                }
                this.revalidateStop.emit({ Stop: stop, Index: i, IsArrival });
                break;
        }

        this.bsModalRef.hide();
    }

    openAddContactModal(template: TemplateRef<any>, contactType: string) {
        this.contactType = contactType;
        this.bsModalRef = this.modalSvc.show(template, { class: 'modal-sm modal-dialog-centered', ignoreBackdropClick: true });
    }

    onContactAddComplete(result: any) {
        switch (this.contactType) {
            case 'origin':
                this.originContactList.push(result);
                break;
            case 'destination':
                this.destinationContactList.push(result);
                break;
        }

        this.contactType = '';
        this.bsModalRef.hide();
    }

    CollapseDestination() {
        this.isDestinationCollapsed = this.shipment.NoDestinationRequired || this.shipment.EndLocationUndefined;
    }

    selectDistinctLocation(selectedLocation: any, locationType: string) {
        this.isDistinctOriginStopLocation = true;
        this.isDistinctDestinationStopLocation = true;
    }

    getCreateShipmentRequest() {
        this.shipment.ShipmentTransferName = this.shipment.ShipmentTransferName?.trim() != null ? this.shipment.ShipmentTransferName?.trim() : null;
        this.shipment.GlobalDeviceID = this.disableDVPush && this.LinkedCustomerIdPull ? this.serialNumberPull : this.serialNumber;
        this.shipment.LinkedCustomerId = this.LinkedCustomerIdPull ? this.LinkedCustomerIdPull : (
            this.LinkedCustomerIdPush && this.LinkedCustomerIdPush > 0 ? this.LinkedCustomerIdPush : null);
        this.shipment.TripEstimatedStart = this.utilSvc.DateFormatLocaleChange(this.startTime);
        this.shipment.TripEstimatedEnd = this.utilSvc.DateFormatLocaleChange(this.endTime);
        if (this.vehicleGroupIds != null) {
            this.shipment.VehicleGroupIds = this.vehicleGroupIds.map(i => i.VehicleGroupId);
        }
        else {
            this.vehicleGroupIds = null;
        }
        this.shipment.SensorRangeId = this.shipment.NoTemperatureRequired ? null : this.shipment.SensorRangeId;
        this.shipment.SensorRangeId = this.shipment.SensorRangeId != null ? this.shipment.SensorRangeId : null;
        this.shipment.SensorRangeInfo = [];
        this.sensorTypes.forEach(k => {
            const sensorRangeInfo = new SensorRangeInfo();
            sensorRangeInfo.TypeCode = k.Type;
            switch (k.Type) {
                case SensorType.Temperature:
                    sensorRangeInfo.SensorRangeId = this.TemperatureRangeId && !this.shipment.NoTemperatureRequired ?
                        this.TemperatureRangeId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
                case SensorType.Humidity:
                    sensorRangeInfo.SensorRangeId = this.HumiditySensorId ? this.HumiditySensorId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
                case SensorType.CarbonDioxide:
                    sensorRangeInfo.SensorRangeId = this.CarbonDioxideSensorId ? this.CarbonDioxideSensorId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
                case SensorType.ProbeTemp:
                    sensorRangeInfo.SensorRangeId = this.ProbSensorId ? this.ProbSensorId : 0;
                    this.shipment.SensorRangeInfo.push(sensorRangeInfo);
                    break;
            }
        });
        if (this.shipment.NoDestinationRequired) {
            this.shipment.DestinationLocationId = null;
            this.shipment.TripEstimatedEnd = null;
        }
        if (this.shipment.EmailAddresses != null) {
            this.shipment.EmailAddresses = this.shipment.EmailAddresses.split(',').map(k => k.trim()).join(',');
        }
        else {
            this.shipment.EmailAddresses = null;
        }

        this.shipment.PlateNum = this.shipment.PlateNum?.trim() != null ? this.shipment.PlateNum?.trim() : null;
        this.shipment.ContainerNum = this.shipment.ContainerNum?.trim() != null ? this.shipment.ContainerNum?.trim() : null;
        this.shipment.DriverPhoneNum = this.shipment.DriverPhoneNum?.trim() != null ? this.shipment.DriverPhoneNum?.trim() : null;
        return this.shipment;
    }

    getValidateTrackerRequest(createShipmentRequest: CreateShipmentRequest): ValidateDVShipmentRequest {
        const request = new ValidateDVShipmentRequest();
        request.CustomerTrackerId = createShipmentRequest.CustomerTrackerId;
        request.TripName = createShipmentRequest.ShipmentTransferName;
        request.ShipmentTransferName = createShipmentRequest.ShipmentTransferName;
        request.LinkedCustomerId = createShipmentRequest.LinkedCustomerId;
        request.LinkCustomerWebsiteId = createShipmentRequest.LinkCustomerWebsiteId;
        request.LinkedCustomerLinkageType = createShipmentRequest.LinkedCustomerLinkageType;
        return request;
    }

    onCreateShipmentClose() {
        if (this.disableSubmitButton) { return; }
        if (this.shipmentForm.valid && (!this.child || this.child.stopsForm.valid)) {
            if (this.shipment.CustomerTrackerId && !this.isInValidSerialNumber && !this.isMultiTripInValidSerialNumber) {
                if (!this.shipment.SaveTripTemplate) {
                    this.shipment.TripTemplateName = null;
                }
                this.disableSubmitButton = true;


                if (this.shipment?.LinkedCustomerId > 0) {
                    const request = this.getValidateTrackerRequest(this.getCreateShipmentRequest());
                    this.validateSubscription = this.createSvc.validateDVShipment(request).pipe(take(1)).subscribe(
                        n => {
                            if (n.ErrorCode === 0) {
                                this.validateDVResponse = n;
                                this.validateTracker(n);
                            } else {
                                this.openSubmitResponse(true, n.ErrorCode === 100 ? n.ErrorDescription : n.LocalizedErrorMessage, false);
                            }
                            // this.openSubmitResponse(false, null, true);
                        },
                        (e: IErrorInfo) => this.openSubmitResponse(true, e.message, false)
                    );
                } else { this.createShipment(); }
            } else {
                if (!this.isMultiTripInValidSerialNumber) {
                    this.isInValidSerialNumber = true;
                }
            }
        }
    }

    validateTracker(response: ValidateDVShipmentResponse) {
        switch (response.CopyTrackerCheckErrorCode) {
            case 0:
                this.validateTrip(response);
                break;
            case 1:
                this.warningMessage = response.CopyTrackerCheckErrorMessage;
                this.openConfirmWindow(true);
                break;
            case 2:
                this.openSubmitResponse(true, response.CopyTrackerCheckErrorMessage, false);
                break;
            case 3:
                this.openSubmitResponse(true, response.CopyTrackerCheckErrorMessage, true);
                break;
            default:
                this.openSubmitResponse(true, response.CopyTrackerCheckErrorMessage, true);
                break;

        }
    }

    validateTrip(response: ValidateDVShipmentResponse) {
        switch (response.CopyTripCheckErrorCode) {
            case 0:
                this.createShipment();
                break;
            case 1:
                this.warningMessage = response.CopyTripCheckErrorMessage;
                this.openConfirmWindow(false);
                break;
            case 2:
                this.openSubmitResponse(true, response.CopyTripCheckErrorMessage, false);
                break;
            case 3:
                this.openSubmitResponse(true, response.CopyTripCheckErrorMessage, true);
                break;
            default:
                this.openSubmitResponse(true, response.CopyTripCheckErrorMessage, true);
                break;
        }
    }

    createShipment() {
        this.getCreateShipmentRequest();
        if (this.stops && this.stops.length > 0) {
            this.shipment.TripStops = this.createSvc.tripStopModelToStops(this.stops);
        }
        this.createshipSubscription = this.shipSvc.createShipment(this.shipment).pipe(take(1)).subscribe(
            n => {
                const templateName = this.shipment.TripTemplateName;
                // OR-1590 changes
                if (n.ErrorCode === 0) {
                    if (this.shipment.SaveTripTemplate && this.shipment.LinkedCustomerLinkageType !== 1) {
                        if (n.TripTemplateId) {
                            this.createSvc.createTemplateSuccess(n.TripTemplateId, templateName);
                        } else {
                            this.createSvc.reloadShipmentTemplates();
                        }
                    }
                } else {
                    if (this.shipment.SaveTripTemplate && n.TripTemplateId) {
                        this.shipment.TripTemplateName = null;
                        this.shipment.TripTemplateID = n.TripTemplateId;
                        if (this.shipment.LinkedCustomerLinkageType === 1) {
                            this.templateList$ = this.createSvc.getShipmentTemplateListLoader();
                        } else {
                            this.createSvc.createTemplateSuccess(n.TripTemplateId, templateName);
                        }
                        this.shipment.SaveTripTemplate = false;
                    }
                }
                this.openSubmitResponse(n.ErrorCode > 0 ? true : false, n.LocalizedErrorMessage, n.ErrorCode > 0 ? false : true);
            },
            (e: IErrorInfo) => this.openSubmitResponse(true, e.message, false, e.debugMessage)
        );
    }

    onCreateShipmentReset() {
        if (this.shipmentForm.valid && (!this.child || this.child.stopsForm.valid)) {
            if (this.shipment.CustomerTrackerId !== undefined && !this.isInValidSerialNumber && !this.isMultiTripInValidSerialNumber) {
                this.getCreateShipmentRequest();
                if (this.stops && this.stops.length > 0) {
                    this.shipment.TripStops = this.createSvc.tripStopModelToStops(this.stops);
                }
                this.createShipmentSubscription = this.shipSvc.createShipment(this.shipment).pipe(take(1)).subscribe(
                    n => {
                        if (this.shipment.SaveTripTemplate && this.shipment.LinkedCustomerLinkageType !== 1) {
                            this.createSvc.createTemplateSuccess(n.TripTemplateId, this.shipment.TripTemplateName);
                        }
                        this.openSubmitResponse(false, null, false);
                    },
                    (e: IErrorInfo) => this.openSubmitResponse(true, e.message, false, e.debugMessage)
                );
            } else {
                this.isInValidSerialNumber = true;
            }
        }

    }

    openConfirmWindow(validateTrip: boolean) {
        this.processValidateTrip = validateTrip;
        this.bsModalRef = this.modalSvc.show(this.confirmModalTemplate, {
            class: 'modal-sm modal-dialog-centered',
            ignoreBackdropClick: true
        });
    }

    confirmAccept(validateTrip: boolean) {
        this.bsModalRef.hide();
        if (!validateTrip) {
            this.createShipment();
        } else {
            this.validateTrip(this.validateDVResponse);
        }
    }

    confirmDecline() {
        this.warningMessage = null;
        this.bsModalRef.hide();
        this.disableSubmitButton = false;
    }

    openSubmitResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, debugErrorMessage: string = null) {
        // if (this.child) {
        //     this.child.checkStopValidation(false);
        // }
        this.disableSubmitButton = false;
        const bsModalRef = this.modalSvc.show(
            CreateShipmentResponseComponent,
            {
                initialState: {
                    hasError,
                    errorMessage,
                    closeOnOk,
                    debugErrorMessage
                },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onCreateShipmentResponseOk(n));
    }

    onCreateShipmentResponseOk(args: CreateShipmentResponseOkEventArgs) {
        args.modalRef.hide();

        if (!args.hasError) {
            if (args.closeOnOk && this.bsMainModalRef) {
                this.bsMainModalRef.hide();
            } else {
                this.ResetForm();
            }
        }
    }

    ResetForm() {
        this.shipment = new CreateShipmentRequest();
        this.shipmentForm.resetForm(this.shipment);
        this.resetDVFields(true);
        this.serialNumber = null;
        this.linkedCustomerChanged(null);
        this.showDepartureDetails = false;
        this.isInValidSerialNumber = false;
        this.isMultiTripInValidSerialNumber = false;
        this.noValidTrackers = true;
        this.isMultiTripSerial = false;
        this.disableDVPush = false;
        this.disableDVPull = false;
        this.stops = [];
        this.showCO2Sensor = this.showProbSensor = this.showHumiditySensor = false;
        this.previousEnteredValue = null;

        this.getBusinessRules();
        // this.serialNumber = '';
        // this.createSvc.activateLoadLocations(true);
        this.disableSubmitButton = false;
        // this.markFormPristine(this.shipmentForm);
    }

    private markFormPristine(form: NgForm): void {
        Object.keys(form.controls).forEach(control => {
            let formControl = form.controls[control];
            formControl.setErrors(null);
            formControl.markAsPristine();
            formControl.markAsUntouched();
        });
    }

    bindTripTemplateDetails(templateDetails: GetTripTemplateDetailsResponse) {
        if (templateDetails) {
            this.shipment.TripStops = [];
            // let IsArrivalExist = false;
            // let IsDepartureExist = false;
            // this.shipment = cloneDeep(this.shipment);
            // this.shipment.TripStops = templateDetails.Contacts ? templateDetails.Contacts : null,
            if (templateDetails.VehicleGroupIds && this.DisplayVehicleGroupingInCreateShipment) {
                this.vehicleGroupIds = [];
                templateDetails.VehicleGroupIds.forEach(element => {
                    const isVehicle = this.VehicleGroups.find(k => k.VehicleGroupId === element);
                    if (isVehicle) {
                        const vehicle = new VehicleGroupInfo();
                        vehicle.VehicleGroupId = element;
                        vehicle.Name = isVehicle.Name;
                        this.vehicleGroupIds.push(vehicle);
                    }
                });
            } else {
                this.vehicleGroupIds = null;
            }

            const templateInfo = templateDetails.TemplateInfo ? templateDetails.TemplateInfo : null;

            const tripStartOptionID = templateInfo && templateInfo.TripStartOptionID !== undefined &&
                templateInfo.TripStartOptionID !== null && this.DisplayTripStartOptions ?
                templateInfo.TripStartOptionID : null;
            this.setBusinessRuleValues(tripStartOptionID);

            const tripEndOptionID = templateInfo && templateInfo.TripEndOptionID !== undefined &&
                templateInfo.TripEndOptionID !== null && this.DisplayTripEndOptions ?
                templateInfo.TripEndOptionID : null;
            this.setBusinessRuleDestinationValues(tripEndOptionID);

            this.shipment.TripTemplateID = templateInfo && templateInfo.TripTemplateId ? templateInfo.TripTemplateId : null;
            this.shipment.OriginLocationId = templateInfo && templateInfo.OriginLocationId ?
                templateInfo.OriginLocationId : null;
            this.shipment.DestinationLocationId = templateInfo && templateInfo.DestinationLocationId ?
                templateInfo.DestinationLocationId : null;
            if (this.shipment.DestinationLocationId === this.shipment.OriginLocationId && this.shipment.DestinationLocationId !== null) {
                setTimeout(() => { this.shipment.OriginLocationId = null; }, 30);
            }
            this.TemperatureRangeId = this.HumiditySensorId = this.CarbonDioxideSensorId = this.ProbSensorId = null;
            this.TemperatureRangeId = templateInfo && templateInfo.SensorRangeId ? templateInfo.SensorRangeId : null;
            if (templateInfo && templateInfo.SensorRangeList && templateInfo.SensorRangeList.length > 0) {
                templateInfo.SensorRangeList.forEach(a => {
                    switch (a.TypeCode) {
                        case (SensorType.Temperature):
                            this.TemperatureRangeId = a.SensorRangeId;
                            break;
                        case (SensorType.Humidity):
                            this.HumiditySensorId = a.SensorRangeId;
                            break;
                        case (SensorType.CarbonDioxide):
                            this.CarbonDioxideSensorId = a.SensorRangeId;
                            break;
                        case (SensorType.ProbeTemp):
                            this.ProbSensorId = a.SensorRangeId;
                            break;
                    }
                });
            }
            this.shipment.NoTemperatureRequired = false;
            this.onTemperatureRequiredChkChange();
            this.shipment.CooldownTypeCode = templateInfo && templateInfo.CooldownTypeCode !== undefined ? templateInfo.CooldownTypeCode : null;
            this.shipment.AlertTimeZone = templateInfo && templateInfo.AlertTimeZone ? templateInfo.AlertTimeZone : null;
            this.shipment.CarrierName = templateInfo && templateInfo.CarrierName ? templateInfo.CarrierName : null;
            this.shipment.EmailAddresses = templateInfo && templateInfo.EmailAddresses ? templateInfo.EmailAddresses : null;
            this.shipment.L1 = templateInfo ? templateInfo.L1 : false;
            this.shipment.L2 = templateInfo ? templateInfo.L2 : false;
            this.shipment.L3 = templateInfo ? templateInfo.L3 : false;
            this.shipment.L4 = templateInfo ? templateInfo.L4 : false;
            this.shipment.L5 = templateInfo ? templateInfo.L5 : false;
            this.shipment.L6 = templateInfo ? templateInfo.L6 : false;
            this.shipment.EstimatedFlightMinutes = templateInfo &&
                templateInfo.EstimatedFlightMinutes ? templateInfo.EstimatedFlightMinutes : null;
            this.shipment.EstimatedLoadingMinutes = templateInfo &&
                templateInfo.EstimatedLoadingMinutes ? templateInfo.EstimatedLoadingMinutes : null;
            this.shipment.TripStops = [];
            this.stops = [];
            if (templateDetails.Contacts) {
                this.stops = this.createSvc.tripContactsToStopsModel(templateDetails.Contacts, this.stopLocations);
            }
            // if (this.child) {
            //     this.child.checkStopValidation(false);
            // }
        }
        if (this.templateDetailsSubscription) {
            this.templateDetailsSubscription.unsubscribe();
        }
    }

    ShipmentTemplateChange(selectedTemplateData: TripTemplateInfo) {
        if (selectedTemplateData != null) {
            this.shipment.SaveTripTemplate = false;
            this.templateDetailsSubscription = this.createSvc.getTemplateDetails(selectedTemplateData.TripTemplateId)
                .subscribe(n => this.bindTripTemplateDetails(n));
        } else {
            this.bindTripTemplateDetails(new GetTripTemplateDetailsResponse());
        }
    }

    openAddSensorRangeModal(type) {
        this.bsModalRef = this.modalSvc.show(
            SensorRangeComponent,
            {
                initialState: { SensorType: type },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        this.bsModalRef.content.addSensorRange.subscribe(n => this.onAddSensorRange(n));
    }

    onAddSensorRange(args: SensorRangeEventArgs) {
        setTimeout(() => {
            switch (args.request.SensorTypeCode) {
                case SensorType.Temperature:
                    this.TemperatureRangeId = args.request.SensorRangeId;
                    break;
                case SensorType.Humidity:
                    this.HumiditySensorId = args.request.SensorRangeId;
                    break;
                case SensorType.CarbonDioxide:
                    this.CarbonDioxideSensorId = args.request.SensorRangeId;
                    break;
                case SensorType.ProbeTemp:
                    this.ProbSensorId = args.request.SensorRangeId;
                    break;
            }
        }, 50);
        // setTimeout(() => { this.shipment.SensorRangeId = args.request.SensorRangeId; }, 50);
        this.bsModalRef.hide();
        // request.RetailerId = this.shipment.LinkedCustomerId;
    }

    onTemperatureRequiredChkChange() {
    }
    noDestinationSelected() {
        this.shipment.DestinationLocationId = null;
    }

    onSearchInputChange() {
        if (!this.serialNumber || this.serialNumber.length === 0) {
            return this.trackerList$;
        }
        this.serialOptionList$ = Observable.create(sub => {
            sub.next(this.serialNumber);
        }).pipe(
            mergeMap((token: string) => {
                return this.trackerList$.pipe(
                    map(trackers => {
                        const pattern = new RegExp(`${this.serialNumber.trim()}`, 'i');
                        return trackers.filter(n => pattern.test(n.trackerId));
                    }));
            })
        );
    }

    onNumberPaste(event: any) {
        const value = event.clipboardData.getData('text');
        if (value) {
            setTimeout(() => {
                if (this.disableDVPush) {
                    this.serialNumberPull = value.trim();
                } else {
                    this.serialNumber = value.trim();
                }
            });
        }
    }

    GetTrackerSensorsBySerial(isFromPull = false) {
        if ((this.LinkedCustomerIdPull > 0 && this.previousEnteredValue === this.serialNumberPull) ||
            (this.disableDVPull && this.serialNumber === this.previousEnteredValue)) {
            return;
        }
        if (isFromPull) {
            this.isInValidSerialNumber = false;
            this.shipment.CustomerTrackerId = undefined;
        }
        this.previousEnteredValue = this.LinkedCustomerIdPull ? this.serialNumberPull : this.serialNumber;
        this.showCO2Sensor = this.showProbSensor = this.showHumiditySensor = false;
        const request = new ShipmentRequest();
        request.LinkedCustomerId = this.LinkedCustomerIdPull ? this.LinkedCustomerIdPull : null;
        if (request.LinkedCustomerId) {
            request.GlobalDeviceId = this.serialNumberPull;
        } else {
            request.GlobalDeviceId = this.serialNumber;
        }

        if (!request.GlobalDeviceId) {
            return;
        }
        request.DeviceId = this.shipment.CustomerTrackerId;

        this.getTrackerSubscription = this.createSvc.GetTrackerSensorsBySerial(request).subscribe(n => {
            if (n && n.Sensors && n.Sensors.length > 0) {
                this.sensorTypes = n.Sensors;
                this.showCO2Sensor = n.Sensors.find(k => k.Type === SensorType.CarbonDioxide) !== undefined;
                this.showProbSensor = n.Sensors.find(k => k.Type === SensorType.ProbeTemp) !== undefined;
                this.showHumiditySensor = n.Sensors.find(k => k.Type === SensorType.Humidity) !== undefined;
            } else {
                if (n.ErrorCode > 0 && this.serialNumberPull) {
                    this.isInValidSerialNumber = true;
                    this.isMultiTripSerial = false;
                }
            }
            if (isFromPull && n && n.Tracker && !this.isInValidSerialNumber) {
                this.shipment.CustomerTrackerId = n.Tracker.DeviceId;
                this.isMultiTripSerial = false;
                this.noDestinationRequired = false;
                if (n.Tracker.IsMultiTrip) {
                    this.isMultiTripSerial = true;
                    this.shipment.CooldownTypeCode = null;
                }
            }
        });
    }

    updateLabels(value: boolean) {
        this.labelShipmentName = value ? label_po : label_shipment_name;
        this.labelEnterShipmentName = value ? label_po : label_enter_shipment_name;
        this.labelShipmentNameRequired = value ? po_required : shipment_name_required;
    }

    private clearUpdatesSub() {
        if (this.updatesSub) {
            this.updatesSub.unsubscribe();
            this.updatesSub = null;
        }
    }

    openInfoModal() {
        this.bsModalRef = this.modalSvc.show(this.inboundOutboundModal,
            {
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            })
    }

    onOkClick() {
        this.ResetForm();
        this.isEndLocationUndefined = false;
        this.bsModalRef.hide();
    }

    onManageCarrierData() {
        this.stopEditing();
        this.bsCarrierModalRef = this.modalSvc.show(this.manageCarrierModal,
            {
                class: 'modal-md modal-dialog-centered carrier-modal',
                ignoreBackdropClick: true
            })
    }

    onManageCarrierClose() {
        this.bsCarrierModalRef.hide();
        this.stopEditing()
        this.newCarrier = null;
    }

    startEditing(index: number, value: string): void {
        this.editCarrier = value;
        this.editedCarrierId = index;
        this.errorMessage = null;
    }

    stopEditing(id?: number): void {
        if (id) {
            const carrier = this.carrierList.filter(x => x.Id === id)[0];
            carrier.Value = this.editCarrier;
        }
        this.editedCarrierId = null;
        this.errorMessage = null;
        this.editCarrier = '';
    }

    updateCarrier(id: number): void {
        const editedCarrier = this.carrierList.filter(x => x.Id === id)[0];
        if (editedCarrier.Value.trim() !== '') {
            const newSubscription = this.createSvc.setCarrierLookUp({ Deleted: false, LookupValue: editedCarrier.Value, CustomerId: editedCarrier.CustomerId, LookupId: editedCarrier.Id, LookupTypeCode: editedCarrier.TypeCode, Username: this.userName } as SetLookUpRequest, this.LinkedCustomerIdPush).subscribe(k => {
                if (k.ErrorDescription !== null) {
                    this.errorMessage = k.LocalizedErrorMessage;
                }
                else {
                    this.errorMessage = null;
                    this.editCarrierForm.resetForm();
                    this.stopEditing();
                }
                newSubscription?.unsubscribe();
            });
        }
    }

    addCarrier(): void {
        const carrierValue = this.newCarrier.trim();
        if (carrierValue !== '') {
            const contextSubscription = this.custStateSvc.selectedContext$.pipe(
                take(1),
            ).subscribe(context => {
                const customerId = this.LinkedCustomerIdPush ? this.LinkedCustomerIdPush : context.customer.CustomerId;
                const newSubscription = this.createSvc.setCarrierLookUp({ Deleted: false, LookupValue: carrierValue, CustomerId: customerId, LookupId: null, LookupTypeCode: 1, Username: this.userName } as SetLookUpRequest, this.LinkedCustomerIdPush).subscribe(k => {
                    if (k.ErrorDescription !== null) {
                        this.errorMessage = k.LocalizedErrorMessage;
                    }
                    else {
                        this.errorMessage = null;
                        this.newCarrier = "";
                        if (this.LinkedCustomerIdPush) {
                            this.carrierList.push({ Id: k.GetLookupResponses[0].Id, Value: carrierValue, CustomerId: customerId, TypeCode: 1 } as Lookup);
                        }
                    }
                    newSubscription?.unsubscribe();
                    contextSubscription?.unsubscribe();
                });
            });
        }
    }

    removeCarrier(id: number): void {
        const item = this.carrierList.filter(x => x.Id === id)[0];
        if (item) {
            const removeSubscription = this.createSvc.setCarrierLookUp({ Deleted: true, LookupValue: item.Value, CustomerId: item.CustomerId, LookupId: id, LookupTypeCode: item.TypeCode, Username: this.userName } as SetLookUpRequest, this.LinkedCustomerIdPush).subscribe(k => {
                if (k.ErrorDescription !== null) {
                    this.errorMessage = k.LocalizedErrorMessage;
                } else {
                    this.errorMessage = null;
                    this.stopEditing();
                    if (this.LinkedCustomerIdPush) {
                        this.carrierList = this.carrierList.filter(x => x.Id !== k.GetLookupResponses[0].Id);
                    }
                }
                removeSubscription?.unsubscribe();
            });
        }
    }
}
