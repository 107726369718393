import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import '@angular/compiler';
import { verifyCountry as verifyDomain, APP_IN_CHINA, HOST_NAME } from './regionFinder';

if (environment.production) {
  enableProdMode();
}

const [isAppInChina, hostName] = verifyDomain();
const providers = [
    { provide: APP_IN_CHINA, useValue: isAppInChina },
    { provide: HOST_NAME, useValue: hostName }
];
platformBrowser(providers).bootstrapModule(AppModule);
