import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { LocationService } from 'app-modules/core/services/location.service';
import { Address, DateTime, LocationInfo, SetLocationRequest, SetLocationResponse } from 'emr-ng-shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { of, Subscription } from 'rxjs';
import { FillLocationResponse } from '../models/fill-location-response';
import { FindAddressRequest } from '../models/find-address-request';

export class ValidateLocationEventArgs {
  isUniqueLocation?: Boolean;
  newLocation?: Address;
  newLocationID?: number;
  cancelUpdate?: boolean;
  nearestLocations?: LocationInfo[];
  shownearestLocations? = false;
  newLocRequest?: SetLocationRequest;
}

@Component({
  selector: 'app-search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchAddressComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild(NgForm) addressChangeForm: NgForm;
  @Input() currentRequest: SetLocationRequest;
  newLocRequest = new SetLocationRequest();
  @Input() updateAddress: FindAddressRequest;
  @Input() isFromEditLocation = false;
  @Input() displayHeader = true;
  @Input() showCloseButton = true;
  public error: IErrorInfo;
  isChinaAddress: boolean = false;
  context: any;
  address: Address;
  validateLocationSubscription: Subscription;
  nearestLocations: LocationInfo[];
  showNearestLocations = false;

  @Output() validateLocation = new EventEmitter<ValidateLocationEventArgs>();
  @Output() onUpdateLocationClick = new EventEmitter<FillLocationResponse>();
  @Output() updateMap = new EventEmitter<SetLocationRequest>();
   
  resetAddressField: boolean = false;
  disableClick: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
    private locationSvc: LocationService,) {}

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if(this.validateLocationSubscription){
      this.validateLocationSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.updateAddress) {
      this.updateControlValues(this.updateAddress)
    }
  }

  updateControlValues(findAddress: FindAddressRequest) {
    if (findAddress && findAddress.location) {
      const newLocReq = new SetLocationRequest();
      newLocReq.Latitude = findAddress.location.latitude;
      newLocReq.Longitude = findAddress.location.longitude;
      newLocReq.FullAddress = findAddress.fullAddress;
      this.newLocRequest = newLocReq;
      if (findAddress.isAddressLoadRequired) {
        this.resetValues();
        this.onfindAddressClick();
      } else {
        setTimeout(() => {
          this.address = new Address();
          this.address.FormattedAddress = findAddress.fullAddress;
          this.address.AddressLine1 = findAddress.AddressLine1;
          this.address.City = findAddress.City;
          this.address.StateDescription = findAddress.StateDescription;
          this.address.PostalCode= findAddress.PostalCode;
          this.address.CountryCode = findAddress.CountryCode;
          this.address.Latitude = findAddress.location.latitude;
          this.address.Longitude = findAddress.location.longitude;
        })
      }      
    }
  }

  onAddressSelected(address: Address) {
    this.resetValues();
    this.error = null;
    this.address = address;
    this.newLocRequest.FullAddress = address?.FormattedAddress ? address?.FormattedAddress : null;
    this.newLocRequest.Address1 = address?.AddressLine1 ? address?.AddressLine1 : null;
    this.newLocRequest.City = address?.City ? address?.City : null;
    this.newLocRequest.State = address?.StateDescription? address?.StateDescription : null;
    this.newLocRequest.ZipCode = address?.PostalCode ? address?.PostalCode : null;
    this.newLocRequest.Country = address?.CountryCode ? address?.CountryCode : null;
    this.newLocRequest.Latitude = address?.Latitude ? address?.Latitude : null;
    this.newLocRequest.Longitude = address?.Longitude ? address?.Longitude : null;
    this.newLocRequest.ValidationRequired = false;
    this.updateMap.emit(this.newLocRequest);
  }

  onUpdateLocation() {
    this.reValidationForm();
    this.error = null;
    if (this.addressChangeForm.valid) {
      this.showNearestLocations = false;
      this.nearestLocations = [];
        this.validateLocationSubscription = this.locationSvc.validateLocation(this.newLocRequest).subscribe(
          n => {
            if (n) {
              this.validateLocationSuccess(n);
            }
          },
          (e: IErrorInfo) => this.validateLocationFailure(e)
        );
    } 
  }

  validateLocationSuccess(response: SetLocationResponse) {
    this.validateLocationSubscription?.unsubscribe();
    this.showNearestLocations = false;
    this.nearestLocations = [];
    const matchingLocations = response.LocationList?.filter(l => l.LocationId !== this.currentRequest.LocationId);
    if(matchingLocations && matchingLocations.length > 0) {
      this.showNearestLocations = true;
      this.nearestLocations = matchingLocations;
    } else {
      response.IsUniqueLocation = true;

      this.validateLocation.emit({
        isUniqueLocation: response.IsUniqueLocation,
        newLocation: response.IsUniqueLocation ? this.address : null,
        shownearestLocations: this.showNearestLocations,
        nearestLocations: this.nearestLocations,
        newLocRequest: this.newLocRequest
      });
    }
  }

  validateLocationFailure(error: IErrorInfo) {
    this.error = error;
    this.validateLocationSubscription.unsubscribe();
  }

  onCoordinatesChange() {
    this.newLocRequest.ValidationRequired = true;
    this.resetAddressField = true;
    this.address = null;
    this.error = null;
    this.disableClick = true;

    this.showNearestLocations = false;
    this.nearestLocations = [];
  }

  resetValues(){
    this.showNearestLocations = false;
    this.nearestLocations = [];

  }
  
  onCoordinatesChanged(){
    this.updateMap.emit(this.newLocRequest);
    setTimeout(() => {
      this.disableClick = false;
      this.resetAddressField = false;
    }, 200);
  }

  // onContiueClick() {
  //   if (this.selectedIndex) {
  //     let locationId = this.nearestLocations[this.selectedIndex - 1]?.LocationId;
  //     this.validateLocation.emit({
  //       newLocationID: locationId
  //     });
  //     this.validateLocationSubscription.unsubscribe();
  //   }
  // }

  // disableContinue(selectedIndex?: number){
  //   return selectedIndex <= 0 ;
  // }

  onfindAddressClick(){
    this.reValidationForm();
    this.error = null;
    if(this.addressChangeForm.valid){
      this.validateLocationSubscription = this.locationSvc.findLocation(this.newLocRequest).subscribe(
        n => {
          if (n) {
              this.onAddressSelected(getNewLocation(n));
          }
        },
        (e: IErrorInfo) => this.validateLocationFailure(e)
      );
    }

  }

  onConfirmClick(){
    this.validateLocation.emit({
      isUniqueLocation: true,
      newLocation: this.address
    });

  }

  private reValidationForm = () => {
    if (this.addressChangeForm) {
      for (const key of Object.keys(this.addressChangeForm.controls)) {
        this.addressChangeForm.controls[key].markAsDirty();
        this.addressChangeForm.controls[key].updateValueAndValidity();
      }
    }
  }

  onBackClick(){
    this.validateLocation.emit({
    cancelUpdate: true,
  });

  }
}
export function getNewLocation(response: SetLocationResponse): any {
  return {
    AddressLine1: response.DefaultLocation.Address1,
    City: response.DefaultLocation.City,
    StateDescription: response.DefaultLocation.State,
    CountryCode: response.DefaultLocation.Country,
    PostalCode: response.DefaultLocation.Zip,
    Latitude: response.DefaultLocation.Latitude,
    Longitude: response.DefaultLocation.Longitude,
    FormattedAddress: response.DefaultLocation.FullAddress,
  };
}

