import { Injectable } from '@angular/core';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';

@Injectable()
export class UnAuhtenticationService {
    constructor(
        private oversightSvc: OversightApiService
    ) { }
    public setUnAuthenticationToken(IsUnAuthenticated: boolean) {
        this.oversightSvc.setUnAuthenticationToken(IsUnAuthenticated);
    }
}


