import {
    Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, TemplateRef, Input, Optional, Inject
} from '@angular/core';
import { Subscription, of, Observable, BehaviorSubject } from 'rxjs';
import { map, tap, skip } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import {
    GetDistanceToTargetsRequest, TimePeriodInfo, ShipmentRequest, GetShipmentResponse,
    GetAlertSuspendStatusRequest, SensorType, SensorRangeInfo, BusinessRuleType, SetTripStopRequest,
    TripState, MultiTrip, EmrUtilService, TrackerHistory, LocationInfo, DateTimeObject,
    DateRange, TimePeriod, ReportBackgroundColorType, UpdateShipmentRequest, SetTripSensorRangeRequest, Any, CustomMapServiceFactory, MapServiceFactory, CreateShipmentRequest, TripStopTypeCode, DualVisibilityLinkCustomer,
    SetShutDownTrackerRequest, TripStop, LoggerReadResponse, GetHistoryTripRequest, Lookup
} from 'emr-ng-shared';
import { isNullTripAvoidTripPeriod } from 'app-modules/core/rxjs/operators/is-null-trip-avoid-trip-period';
import { TimePeriodService } from 'app-modules/core/store/time-period/time-period.service';
import { SensorChartService } from 'app-modules/core/services/sensor-chart.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { MinMaxValueEventArgs } from 'app-modules/shipment-detail/components/set-minmax/minmax-chart-values.component';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import { MultiTripService } from 'app-modules/core/store/multi-trip/multi-trip.service';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import { environment } from 'environments/environment';
import { SensorChartDataService } from 'app-modules/shipment-detail/services/sensor-chart-data.service';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { ShipmentDetailsCommonData } from 'app-modules/shipment-detail/models/ShipmentDetailsCommonData';
import * as _ from 'lodash';
import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { AddLocationEventArgs, LocationComponent } from 'app-modules/core/components/location/location.component';
import { Destination, Origin_Destination, labelDefault, Origin } from 'app-modules/core/consts/localization';
import { SensorRangeComponent } from 'app-modules/core/components/sensor-range/components/sensor-range/sensor-range.component';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { TripStopModel } from 'app-modules/core/store/models/trip-stop.model';
import { GetTripStopResponse } from 'emr-ng-shared';
import { UserSettingsService } from 'app-modules/core/store/user-settings/user-settings.service';
import { USER_SETTING_KEY_MAX_YAXIS, USER_SETTING_KEY_MIN_YAXIS, USER_SETTING_SECTION_REPORT_SENSOR } from 'app-modules/core/consts/user-setting';
import { CustomersService } from 'app-modules/core/services/customer.service';

export enum ShipmentDetailViews {
    TIMELINE,
    TRACKER_STATE,
    ALERT_SUMMARY,
    CONTACTS,
    HISTORICAL_SHIPMENT,
    SENSOR_CHART,
    TAGS
}

// TODO LocationInfoListItem: Can we move this to a common place ?
export class LocationInfoListItem {
    get text() {
        return (this.locationInfo.LocationName ? this.locationInfo.LocationName :
            this.locationInfo.Description) + (this.locationInfo.Address1 ? ' - ' + this.locationInfo.Address1 : '');
    }
    get value() { return this.locationInfo.LocationId; }
    constructor(private locationInfo: LocationInfo) { }
}

export class SensorItem {
    get text() { return this.sensor?.CustomSensorRange ? this.sensor?.RangeName : 'n/a'; }
    get value() { return this.sensor?.TripSensorRangeID; }
    constructor(private sensor: { RangeName: string, TripSensorRangeID: number, CustomSensorRange: boolean }) { }
}

export class DateTimeFormat {
    DateFormat: number;
    TimeFormat: number;
    constructor(dateFormat, timeFormat) {
        this.DateFormat = dateFormat;
        this.TimeFormat = timeFormat;
    }
}

@Component({
    selector: 'app-shipment-detail',
    templateUrl: './shipment-detail.component.html',
    styleUrls: ['./shipment-detail.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ShipmentDetailComponent implements OnInit, OnDestroy {
    resetSensors = true;
    public error: string;
    @Input()
    public Shipment: Shipment;
    minvalueYOverride = null;
    maxvalueYOverride = null;
    isSensorFilterDirty = false;
    showTimeLine = false;
    chartDateTimeFormat: DateTimeFormat;
    historicalShipmentListSubscription: Subscription;
    multiTripShipments: TrackerHistory[];
    initialTripId: number;
    newTripEndId: number;
    newTripStartId: number;
    newtempSensorID: number;
    newHumiditySensorID: number;
    newCO2SensorID: number;
    newProbeSensorID: number;
    newShockSensorID: number;
    userCanEditTripName: boolean;
    PendingSaveRequestsSub: Subscription;
    internalFilterApplied: boolean;
    IsDefaultToLast14Days: boolean;
    defaultTempRangeId: any;
    sensorType: typeof SensorType;
    hasDatesOutsideOfDefault: boolean = false;
    newTripStop: number;
    matchingStops: boolean;
    userSettingsSub: Subscription;
    showTiltAndTiltEvents = false;
    showDSTAndDSTEvents = false;
    ShowShockSensor = false;
    ShowDriveByLocationsInTimeLine = false;
    set ShowTimeLine(value: boolean) {
        this.showTimeLine = value;
        this.loadShipmentTimeLine();
    }
    selectedMultiTrip: TrackerHistory = null;
    isCurrentShipment: boolean;
    getShipmentResponseData: GetShipmentResponse;
    isSensorChartSubmitted = false;
    @ViewChild(NgForm) sensorChartForm: NgForm;
    public views = ShipmentDetailViews;
    public selectedView = ShipmentDetailViews.TIMELINE;
    public userCanCreateShipment: boolean;
    timePeriodList$: Observable<TimePeriodInfo[]>;
    timelineData = null;
    TraxxList: ITraxxState;
    activeTrip = false;
    // private selectedShipmentSubscription: Subscription;
    sensorDataLoaderSubscription: Subscription;
    getTraxxSubscription: Subscription;
    isShipmentReloadedSubscription: Subscription;
    private shipmentTimelineSubscription: Subscription;
    temperatureInfoSubscription: Subscription;
    temperatureInfo: GetShipmentResponse;
    mktString: string;
    preferenceSubscription: Subscription;
    dateTimePreferenceSubscription: Subscription;
    locationSubscription: Subscription;
    tempUnits = '';
    lastSetTimeout: any;
    customerId: number;
    isSensorChartLoading$ = this.sensorSvc.isChartLoading$;
    isHistoryLoading = false;
    isHistoryLoadingSubscription: Subscription;
    IsUnAuthenticated = false;
    selectedDateRange: DateRange = null;
    chartDateRange: DateRange = null;
    isFilterDirty = false;

    latestShipmentRequest: ShipmentRequest = null;

    sensorChartClickSubscription: Subscription;
    tripState = TripState;
    modalRef: BsModalRef;
    errorMessage: string;
    businessRuleSub: Subscription;

    originLocations$: Observable<LocationInfoListItem[]>;
    destLocations$: Observable<LocationInfoListItem[]>;
    stopLocations: LocationInfoListItem[];
    locationList: LocationInfoListItem[];
    userPreferenceSubscription: Subscription;
    sensorRangeList$: Any;
    sensorRangeList: _.Dictionary<SensorRangeInfo[]>; //IListInfo<SensorRangeInfo>;
    isShipmentEdited = false;
    isVisibleFieldPlateNum = false;
    isVisibleReceiverPONum = false;
    isVisibleFieldContainerNum = false;
    isVisibleFieldDriverPhoneNum = false;

    @ViewChild('sensorChartDetails') sensorChartDetails: ElementRef;
    @ViewChild('tripDeleteConfirmationTemplate') tripDeleteConfirmationTemplate: TemplateRef<any>;
    @ViewChild('tripDeleteResponseTemplate') tripDeleteResponseTemplate: TemplateRef<any>;

    @ViewChild(NgForm) sensorDateRangeForm: NgForm;
    internalFilter = false;
    public isDateRangeValid = true;
    displayReset = false;
    ShowLastNDays: number = 90;
    minDate: Date;
    maxDate: Date;
    dateRange: DateRange = new DateRange();
    displayDateFilter = false;
    canFilterShipment = false;
    dateTimeObject = new DateTimeObject();
    specificDateRangeApplied = false;
    shipmentDetailsCommonData: ShipmentDetailsCommonData = {
        showAlertsAndEvents: false, selectedSensors: [], selectedColor: ReportBackgroundColorType.None,
        showHiddenCrumbs: false
    };
    TraxxListCopy: ITraxxState;

    authState: IAuthState;
    authStateSub: Subscription;
    tripSensorSubscription: Subscription;
    editShipmentSubscription: Subscription;
    getUser1Subscription: Subscription;
    getUser2Subscription: Subscription;
    getMultiSubscription: Subscription;
    isSensorListLoading$: Observable<Boolean>;
    isLocationListLoading$: Observable<Boolean>;
    // To support Multi sensors
    tempSensor: SensorItem = null;
    humiditySensor: SensorItem = null;
    co2Sensor: SensorItem = null;
    probeSensor: SensorItem = null;
    tiltSensor: SensorItem = null;
    dstSensor: SensorItem = null;
    shkSensor: SensorItem = null;
    showAllTempValues = environment.showAllTempValues;
    userSettings$ = this.userSettingsSvc.userSettings$;

    bsModalRef: BsModalRef;
    stopsModalRef: BsModalRef;
    successControl: string;
    errorControl: string[] = [];
    pendingSaveRequests = [];
    @Input() defaultToSensorChart = false;
    @ViewChild('editStopsModal') editStopsModal: TemplateRef<any>;
    public tripStops: TripStopModel[] = [];
    public createShipmentRequest: CreateShipmentRequest;
    public tripStopsCopy: TripStopModel[] = [];
    isStopsFormSubmitted = false;
    defaultMinTime: Date = new Date(2008, 0, 1);
    Past90Days: number;
    additionalAlertContacts: BsModalRef;
    @ViewChild('editAlertContactsModal') editAlertContactsModal: TemplateRef<any>;
    editShipmentRequest: UpdateShipmentRequest;
    @ViewChild('addressInfoModal') addressInfoModal: TemplateRef<any>;
    addressInfoPopup: BsModalRef;
    address: string;
    addressName: string;
    loggerRead: LoggerReadResponse = null;
    dateFormat: string;
    loggerReadSubscription: Subscription;
    showScannedTags: boolean = false;
    ShowSupplierField: boolean = false;
    isEndLocationUndefined: boolean = false;
    isUserLocusSupport: boolean = false;
    IsCarrierDropDown = false;
    carrierList: Lookup[];
    carrierListSubscription: Subscription;

    constructor(
        @Optional() public bsShipmentDetailModalRef: BsModalRef,
        private detailStateSvc: ShipmentDetailStateService,
        private shpSvc: ShipmentService,
        private popupSvc: OversightPopupService,
        private authSvc: AuthService,
        private periodSvc: TimePeriodService,
        private sensorSvc: SensorChartService,
        private sensorChartSvc: SensorChartDataService,
        private utilSvc: EmrUtilService,
        private oversightSvc: OversightApiService,
        private detailSvc: ShipmentDetailService,
        private prefSvc: PreferenceService,
        private createSvc: CreateShipmentService,
        private modalSvc: BsModalService,
        private businessRuleSvc: BusinessRulesService,
        private userSettingsSvc: UserSettingsService,
        private custSvc: CustomersService,
        @Inject(MapServiceFactory) private mapSvc: CustomMapServiceFactory
    ) { }

    ngOnInit() {
        const customerSubscription = this.custSvc.selectedContext$.subscribe(a => {
            this.customerId = a && a.customer && a.customer.CustomerId ? a.customer.CustomerId : 0;
            customerSubscription?.unsubscribe();
        });
        const today = new Date();
        const day = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        this.Past90Days = day.setDate(day.getDate() - 89);
        this.sensorType = SensorType;
        // this.internalFilter = !!(this.Shipment && this.Shipment.tripId && !(this.Shipment.isMultiTrip));
        this.initialTripId = this.Shipment.tripId;
        if (this.ShowLastNDays) {
            this.setMinMaxTo90Days();
        }
        this.carrierListSubscription = this.createSvc.loadCarrierList().subscribe(k => {
            this.carrierList = k;
        });
        this.businessRuleSub = this.businessRuleSvc.businessRulesList$.pipe(take(1)).subscribe(n => {
            if (n.list && n.list.length > 0) {
                this.IsDefaultToLast14Days = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.DefaultToLast14Days)?.Enabled;

                this.showTiltAndTiltEvents = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowTiltAndTiltEvents)?.Enabled;

                this.showDSTAndDSTEvents = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowDSTAndDSTEvents)?.Enabled;
                this.ShowDriveByLocationsInTimeLine = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowDriveByLocationsInTimeLine)?.Enabled;

                this.isVisibleFieldPlateNum = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldPlateNum)?.Enabled;
                this.isVisibleReceiverPONum = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.DVReceiverPONumberVisible)?.Enabled;
                this.isVisibleFieldContainerNum = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldContainerNum)?.Enabled;
                this.isVisibleFieldDriverPhoneNum = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.IsVisibleFieldDriverPhoneNum)?.Enabled;
                this.showScannedTags = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowScannedTags)?.Enabled;
                this.ShowSupplierField = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.ShowSupplierField)?.Enabled;
                this.isEndLocationUndefined = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.EndLocationUndefined)?.Enabled;
                this.ShowShockSensor = n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.DisplayShockSensorValues)?.Enabled;
                this.IsCarrierDropDown = !!n.list.find(k => k.BusinessRuleTypeCode ===
                    BusinessRuleType.CarrierNameLookupDropdown)?.Enabled;
            }
        });

        this.IsUnAuthenticated = this.shpSvc.getUnAuthenticationToken();
        this.authStateSub = this.authSvc.authState$.subscribe(n => {
            this.authState = n;
            this.userCanCreateShipment = this.authState?.canCreateShipment &&
                (this.authState?.canAccessOversight || this.authState?.isEmersonAdmin);
            this.userCanEditTripName = this.authState?.canEditTripName;
            this.isUserLocusSupport = this.authState?.isLocusSupport;
        });
        this.registerPendingReqSubscription();

        this.isHistoryLoadingSubscription = this.detailSvc.isHistoricalLoading$.subscribe(n => this.isHistoryLoading = n);
        this.onLoad();
        this.sensorChartClick();
        this.isShipmentReloadedSubscription = this.shpSvc.getShipmentUpdatedValue().subscribe(n => {
            if (n) {
                this.Shipment = n;
                this.isFilterDirty = !!this.selectedDateRange;
                this.detailSvc.getTraxxRequest(true);
                this.onLoad();
                this.shpSvc.updateTrackerStatusCloseInstanceInState(this.Shipment.trackerId,
                    this.Shipment.customerTrackerId, this.Shipment.tripId);
                this.shpSvc.setShipmentUpdatedValue(null);
            }
        });
        this.originLocations$ = this.createSvc.originList$.pipe(map(n => {
            return n.list.map(item => new LocationInfoListItem(item));
        }));
        this.destLocations$ = this.createSvc.destinationList$.pipe(map(n => {
            return n.list.map(item => new LocationInfoListItem(item));
        }));

        const stopsSubscription =
            this.createSvc.stopList$.subscribe(n => {
                this.stopLocations = n.list.map(item => new LocationInfoListItem(item));
                stopsSubscription?.unsubscribe();
            });

        this.locationSubscription = this.createSvc.locationList$.subscribe(n => {
            return this.locationList = n.list.map(item => new LocationInfoListItem(item));
        });


        this.sensorRangeList$ = this.createSvc.sensorRangeList$.pipe(tap(n => {
            this.sensorRangeList = n;
        }));

        this.isSensorListLoading$ = this.createSvc.isLoadingSensorsList$;
        this.isLocationListLoading$ = this.createSvc.isLoadingLocationsList$;
    }

    private registerPendingReqSubscription() {
        this.PendingSaveRequestsSub = this.shpSvc.isEditShipmentLoading$.subscribe(v => {
            if (!v) {
                this.pendingSaveRequests = [];
            } else {
                this.pendingSaveRequests = v;
            }
        });
    }

    private setMinMaxTo90Days() {
        const today = new Date();
        let minDate = new Date();
        minDate.setDate(minDate.getDate() - this.ShowLastNDays);
        //Change threshold for displaying crumb data on XL to 90 days for other its need to be 60
        const lastReportedDate = new Date(this.Shipment.LastReportedTimestamp);
        if (lastReportedDate && !this.Shipment.isMultiTrip) {
            let days = this.ShowLastNDays;
            //If it is non XL model then days should be 60
            if (!this.getShipmentResponseData?.Shipment?.IsXL) {
                days = 60;
            }
            // Caluculation min Last reported date based on model (For xl 90 for other 60)
            const minBasedOnLastReportedDate = new Date(lastReportedDate.setDate(lastReportedDate.getDate() - days));
            //Taking min date or Caluculated min dated which ever is lower
            if (minDate > minBasedOnLastReportedDate) {
                minDate = minBasedOnLastReportedDate;
            }

        }
        if (this.getShipmentResponseData?.SelectedPeriodRange?.FromDtm && minDate > new Date(this.getShipmentResponseData?.SelectedPeriodRange?.FromDtm)) {
            minDate = new Date(this.getShipmentResponseData?.SelectedPeriodRange?.FromDtm);
        }
        this.setMinMaxDateRange(minDate, today);
    }

    onLoad() {
        if (!this.IsUnAuthenticated) {
            this.GetMinMax();
        }

        this.detailSvc.selectShipment(this.Shipment);
        // this.selectedShipmentSubscription = this.detailSvc.selectedShipment$.subscribe(n => this.shipment = n);
        // this.userCanCreateShipment = this.authSvc.canCreateShipment$;
        this.timePeriodList$
            = isNullTripAvoidTripPeriod(this.periodSvc.sensorTimePeriodList$, of(this.Shipment));
        // this.selectedTimePeriod.timePeriod =
        //     this.Shipment && this.Shipment.tripId ?
        //         TimePeriod.Trip :
        //         TimePeriod.Last30DaysOfActivity;


        this.isCurrentShipment = !!(
            this.Shipment &&
            this.Shipment.tripStateCode === TripState.InProgress
        );
        this.shipmentFeatures();

        this.dateTimePreferenceSubscription = this.prefSvc.getDateTimeObject().pipe(take(1)).subscribe(k => {
            this.dateTimeObject = k;
        });
        this.preferenceSubscription = this.prefSvc.preference$.subscribe(n => {
            this.dateFormat = n.dateFormatString + ' ' + n.timeFormatString.replace('tt', 'aa');
            this.tempUnits = n.temperatureUnitString;
            this.chartDateTimeFormat = new DateTimeFormat(n.dateFormatEnum, n.timeFormatEnum);
        });
        this.sensorChartSvc.cleanChartDetails();
        if (this.Shipment && this.Shipment.isMultiTrip && this.Shipment.tripId) {
            const isCompletedTrip = this.Shipment.tripStateCode === TripState.Completed;
            if (isCompletedTrip) {
                this.onGenerateData();
            }
            this.loadHistoricalData(isCompletedTrip);
        } else {
            this.onGenerateData();
        }
        this.setDefaultNav();
        this.getTraxxData();
    }

    ngOnDestroy() {
        this.createSvc.selectLinkedCustomer(null);
        this.clearSettingsSubscription();
        if (this.isShipmentEdited && this.bsShipmentDetailModalRef) {
            this.shpSvc.updateTrackerStatusCloseInstanceInState(this.Shipment.trackerId,
                this.Shipment.customerTrackerId, !this.Shipment.isMultiTrip ? this.Shipment.tripId : null);
        }

        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }

        if (this.PendingSaveRequestsSub) {
            this.PendingSaveRequestsSub.unsubscribe();
            this.PendingSaveRequestsSub = null;
        }

        this.shpSvc.closeSpinners();
        this.authStateSub.unsubscribe();

        if (this.isHistoryLoadingSubscription) {
            this.isHistoryLoadingSubscription.unsubscribe();
        }

        if (this.businessRuleSub) {
            this.businessRuleSub.unsubscribe();
            this.businessRuleSub = null;
        }
        if (this.carrierListSubscription) {
            this.carrierListSubscription.unsubscribe();
            this.carrierListSubscription = null;
        }

        this.detailSvc.resetSelectedShipment();
        this.detailSvc.getTraxxRequest(true);
        if (this.preferenceSubscription) {
            this.preferenceSubscription.unsubscribe();
        }
        // if (this.selectedShipmentSubscription) {
        // this.selectedShipmentSubscription.unsubscribe();
        // }
        if (this.getTraxxSubscription) {
            this.getTraxxSubscription.unsubscribe();
        }
        this.clearAlertsData();
        if (this.isShipmentReloadedSubscription) {
            this.isShipmentReloadedSubscription.unsubscribe();
        }
        this.clearHistoricalShipmentListSubscription();
        if (this.sensorChartClickSubscription) {
            this.sensorChartClickSubscription.unsubscribe();
        }
        if (this.userPreferenceSubscription) {
            this.userPreferenceSubscription.unsubscribe();
        }
        if (this.tripSensorSubscription) {
            this.tripSensorSubscription.unsubscribe();
        }
        if (this.editShipmentSubscription) {
            this.editShipmentSubscription.unsubscribe();
        }
        if (this.getUser1Subscription) {
            this.getUser1Subscription.unsubscribe();
        }
        if (this.getUser2Subscription) {
            this.getUser2Subscription.unsubscribe();
        }
        if (this.getMultiSubscription) {
            this.getMultiSubscription.unsubscribe();
        }

        this.clearGetShipmentDataSubscription();
        if (this.lastSetTimeout) {
            clearTimeout(this.lastSetTimeout);
        }
        this.sensorChartSvc.cleanChartDetails();
    }

    private clearHistoricalShipmentListSubscription() {
        if (this.historicalShipmentListSubscription) {
            this.historicalShipmentListSubscription.unsubscribe();
            this.historicalShipmentListSubscription = null;
        }
    }

    private clearTimeLineSubscription() {
        if (this.shipmentTimelineSubscription) {
            this.shipmentTimelineSubscription.unsubscribe();
            this.shipmentTimelineSubscription = null;
        }
    }

    private clearGetShipmentDataSubscription() {
        if (this.sensorDataLoaderSubscription) {
            this.sensorDataLoaderSubscription.unsubscribe();
            this.sensorDataLoaderSubscription = null;
        }
    }

    onTimePeriodChange(event: any) {
        this.isSensorFilterDirty = true;
        // if (this.selectedTimePeriod.timePeriod !== 8) {
        //     this.selectedTimePeriod.dateRange.startDateTime = null;
        //     this.selectedTimePeriod.dateRange.endDateTime = null;
        // } else {
        //     this.isSensorChartSubmitted = false;
        // }
    }

    onMultiTripChange(event: any) {
        this.ResetDateRange();
        this.isSensorFilterDirty = true;
        if (this.Shipment.isMultiTrip && this.multiTripShipments) {
            this.isCurrentShipment = this.checkCurrentShipment();
            this.onGenerateData();
            // if (!this.isCurrentShipment) {
            //     this.selectedTimePeriod.timePeriod = TimePeriod.Trip;
            //     this.selectedTimePeriod.dateRange.startDateTime = null;
            //     this.selectedTimePeriod.dateRange.endDateTime = null;
            // }
        }
    }

    private loadHistoricalData(isCompletedTrip: boolean) {
        this.clearHistoricalShipmentListSubscription();
        this.historicalShipmentListSubscription = this.detailSvc.historicalShipmentList$.subscribe(n => {
            this.multiTripShipments = n.list.filter(x => this.CheckDatesInPast90Days(x.ActualEndTime) === true);
            this.isCurrentShipment = n.list.length > 0 ? this.checkCurrentShipment() : false;
            if (!this.isHistoryLoading && !isCompletedTrip) {
                this.onGenerateData();
            }
        });
    }

    CheckDatesInPast90Days(satTime) {
        return (!satTime || new Date(satTime).getTime() > this.Past90Days);
    }

    private loadShipmentTimeLine() {
        if (this.showTimeLine) {
            if (this.Shipment) {
                this.timelineData = _.cloneDeep(this.getShipmentResponseData?.Shipment?.SensorLocationColorRange);
            }
        } else if (this.selectedView === ShipmentDetailViews.TIMELINE) {
            this.onNavTabSelected(ShipmentDetailViews.SENSOR_CHART);
        } // TODO: Hide until timeline  supports multiTrip
    }

    public onNavTabSelected(selectedView: ShipmentDetailViews) {
        this.selectedView = selectedView;
        this.updateTraxxData();
    }

    onDistanceToClick(trackerId, customerTrackerId, tripId) {
        const distanceToTargetsRequest = new GetDistanceToTargetsRequest();
        distanceToTargetsRequest.TrackerId = trackerId;
        distanceToTargetsRequest.CustomerTrackerId = customerTrackerId;
        distanceToTargetsRequest.TripId = tripId;

        this.popupSvc.showDistanceToPopup(distanceToTargetsRequest);
    }

    onCreateShipmentClick(shipment: Shipment) {
        if (this.getShipmentResponseData.Shipment.CanCreate && (this.userCanCreateShipment)) {
            this.bsShipmentDetailModalRef.hide();
            this.popupSvc.showCreateShipmentPopup(shipment.trackerId, shipment.customerTrackerId, shipment.isMultiTrip);
        }
    }

    onEditShipmentClick(shipment: Shipment) {
        this.popupSvc.showEditShipmentPopup(shipment);
    }

    onCloseShipmentClick(shipment: Shipment) {
        // this.shpSvc.CloseShipment(shipment);
        if ((this.getShipmentResponseData.Shipment.CanClose)) {
            this.popupSvc.showCloseShipmentPopup(shipment, false);
        }
    }

    onShutdownTrackerClick(shipment: Shipment) {
        if ((this.getShipmentResponseData.Shipment.CanShutdownTracker)) {
            const request = new SetShutDownTrackerRequest();
            request.GlobalDeviceId = shipment?.trackerId;
            this.popupSvc.showShutdownTracker(request, false, shipment);
        }
    }

    onReopenShipmentClick(shipment: Shipment) {
        this.shpSvc.ReopenShipment(shipment);
    }

    onSuspendResumeAlertsClick(shipment: Shipment) {
        const alertSuspendStatusRequest = new GetAlertSuspendStatusRequest();
        alertSuspendStatusRequest.DeviceId = shipment.customerTrackerId;
        alertSuspendStatusRequest.GlobalDeviceId = shipment.trackerId;
        this.popupSvc.showAlertSuppressionPopup(alertSuspendStatusRequest);
    }
    ;
    GetMinMax() {
        // merge requests Srinivas
        // const request = new GetUserSettingRequest(); import not working? works in service file
        this.error = null;
        this.clearSettingsSubscription();
        this.userSettingsSub = this.userSettings$.subscribe(m => {
            const minYAxis = m.find(n => n.Section === USER_SETTING_SECTION_REPORT_SENSOR && n.Key === USER_SETTING_KEY_MIN_YAXIS);
            const maxYAxis = m.find(n => n.Section === USER_SETTING_SECTION_REPORT_SENSOR && n.Key === USER_SETTING_KEY_MAX_YAXIS);
            if (minYAxis && maxYAxis) {
                this.minvalueYOverride = minYAxis.Value;
                this.maxvalueYOverride = maxYAxis.Value;
            }
        });


        // this.getUser1Subscription = this.oversightSvc.GetUserSetting(request).subscribe(minres => {
        //     if (minres.ErrorCode === 0) {
        //         minResValue = minres.Value;
        //         request.Key = 'MaxYAxis';
        //         this.getUser2Subscription = this.oversightSvc.GetUserSetting(request).subscribe(maxres => {
        //             if (maxres.ErrorCode === 0) {
        //                 maxResValue = maxres.Value;
        //                 if (minResValue && maxResValue && Number(minResValue) !== Number(maxResValue)) {
        //                     this.minvalueYOverride = minResValue;
        //                     this.maxvalueYOverride = maxResValue;
        //                 }
        //             } else {
        //                 this.error = maxres.LocalizedErrorMessage;
        //             }
        //         });
        //     } else {
        //         this.error = minres.LocalizedErrorMessage;
        //     }
        // });
    }

    private clearSettingsSubscription() {
        if (this.userSettingsSub) {
            this.userSettingsSub.unsubscribe();
            this.userSettingsSub = null;
        }
    }

    setChartMinMax(n: MinMaxValueEventArgs) {
        if (n.radAutomatic === false) {
            this.minvalueYOverride = n.minValue;
            this.maxvalueYOverride = n.maxValue;
        } else {
            // automatic
            this.minvalueYOverride = null;
            this.maxvalueYOverride = null;
        }
    }

    getTraxxData() {
        this.getTraxxSubscription = this.detailSvc.getTraxx$.subscribe(n => {
            this.TraxxList = n;
            this.TraxxListCopy = _.cloneDeep(n);
        });
        // this.detailSvc.initDefaultTraxxRequest(this.Shipment, null, this.shipmentList, this.Shipment.tripStateCode);
    }

    updateTraxxData() {
        if (this.TraxxList && this.TraxxList.traxx && this.TraxxList.traxx.list) {
            this.TraxxList.traxx.list = this.TraxxListCopy.traxx.list;
        }
    }

    checkCurrentShipment(): boolean {
        const selectedTrip = this.multiTripShipments.find(n => n.TripId === this.Shipment.tripId);
        this.selectedMultiTrip = selectedTrip;
        const selectedTripCode = selectedTrip ? selectedTrip.TripStateCode : TripState.Default;
        this.updateShowTemperatureInfo(selectedTripCode);
        return selectedTripCode as TripState === TripState.InProgress;
    }

    onGenerateData(isDateFilter = false) {
        this.reValidationForm();
        if (this.Shipment) {
            this.error = null;
            if (!this.sensorChartForm ||
                this.sensorChartForm.valid || !isDateFilter) {
                let isDateRangeSet = false;
                const request = new ShipmentRequest();
                request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
                request.IsNew = true;
                request.DeviceId = this.Shipment.customerTrackerId;
                request.GlobalDeviceId = this.Shipment.trackerId;
                request.Pins = ''; // this.selectedSensors.map(a => a.Pin).join(',')'';
                // request.Period = this.selectedTimePeriod.timePeriod;
                request.TripId = this.Shipment.tripId;
                request.IsMultiTrip = this.Shipment.isMultiTrip;
                request.TripState = request.IsMultiTrip && this.selectedMultiTrip ?
                    this.selectedMultiTrip.TripStateCode : this.Shipment.tripStateCode;

                request.LastReportedDtmString = this.Shipment.LastReportedTimestamp;
                request.LastReportedDtm = EmrUtilService.parseDateWithoutTimezone(this.Shipment.LastReportedTimestamp);
                request.ModelNumber = this.Shipment.ModelNumber;
                request.ModelName = this.Shipment.ModelName;
                request.IsDefaultToLast14Days = this.IsDefaultToLast14Days;

                request.Period = request.TripId ? TimePeriod.Trip : TimePeriod.Last90Days;
                if (this.initialTripId === this.Shipment.tripId) {
                    request.ActualStartDtmString = this.Shipment.ActualStartTime;
                    request.ActualStartDtm = EmrUtilService.parseDateWithoutTimezone(this.Shipment.ActualStartTime);

                    request.ScheduledStartTimeString = this.Shipment.ScheduledStartTime;
                    request.ScheduledStartDtm = EmrUtilService.parseDateWithoutTimezone(this.Shipment.ScheduledStartTime);

                    request.ScheduledEndTimeString = this.Shipment.ScheduledEndTime;
                    request.ScheduledEndDtm = EmrUtilService.parseDateWithoutTimezone(this.Shipment.ScheduledEndTime);
                    if (request.TripState === TripState.Completed) {
                        request.ActualEndDtmString = this.Shipment.ActualEndTime;
                        request.ActualEndDtm = EmrUtilService.parseDateWithoutTimezone(this.Shipment.ActualEndTime);
                    }
                }
                if (this.selectedDateRange && this.isFilterDirty) {
                    isDateRangeSet = true;
                    request.HasDatesOutsideOfDefault = this.hasDatesOutsideOfDefault;
                    request.Period = TimePeriod.SpecificRange;
                    request.From =
                        this.utilSvc.DateFormatLocaleChange(this.selectedDateRange.startDateTime);
                    request.Thru =
                        this.utilSvc.DateFormatLocaleChange(this.selectedDateRange.endDateTime);
                } else if (!this.internalFilter && !this.Shipment.tripId) {
                    request.Period = TimePeriod.Last90Days;
                }

                if (this.Shipment.isMultiTrip && this.multiTripShipments && this.multiTripShipments.length > 0) {
                    const sortedShipmentList = this.multiTripShipments.sort((a, b) => b.TripId - a.TripId);
                    const index = sortedShipmentList.map(x => x.TripId).indexOf(this.Shipment.tripId);
                    if (index + 1 < sortedShipmentList.length) {
                        request.PreviousTripEndDtm = sortedShipmentList[index + 1].ActualEndTime;
                        request.PreviousTripEndDtmString = this.utilSvc.DateFormatLocaleChange(request.PreviousTripEndDtm);
                    }

                    if (index > 0) {
                        request.FutureTripEndDtm = sortedShipmentList[index - 1].ActualEndTime;
                        request.FutureTripEndDtmString = this.utilSvc.DateFormatLocaleChange(request.FutureTripEndDtm);
                    }

                    switch (request.TripState) {
                        case TripState.Pending:
                            break;
                        case TripState.InProgress:
                            if (index >= 0) {
                                request.ActualStartDtm = sortedShipmentList[index].ActualStartTime;
                                request.ActualStartDtmString = this.utilSvc.DateFormatLocaleChange(request.ActualStartDtm);
                            }
                            break;
                        case TripState.Completed:
                            if (index >= 0) {
                                request.ActualStartDtm = sortedShipmentList[index].ActualStartTime;
                                request.ActualEndDtm = sortedShipmentList[index].ActualEndTime;
                                request.ActualStartDtmString = this.utilSvc.DateFormatLocaleChange(request.ActualStartDtm);
                                request.ActualEndDtmString = this.utilSvc.DateFormatLocaleChange(request.ActualEndDtm);
                            }
                            break;
                    }
                }

                // Get Traxx request
                this.detailSvc.initDefaultTraxxRequest(this.Shipment, null, null, request);


                // Saving the latest Request for Chart Download
                this.latestShipmentRequest = request;
                this.tripStops = null;
                this.sensorDataLoaderSubscription = this.sensorSvc.GetSensorChart(request).subscribe(n => {
                    if (isDateRangeSet) {
                        this.chartDateRange = this.selectedDateRange;
                    }
                    if (n.ErrorCode === 0) {
                        if (n?.Shipment
                            && n?.Shipment?.IsDVShipment && n?.Shipment?.RetailerID) {
                            let dvCustomer = new DualVisibilityLinkCustomer();
                            dvCustomer.CustomerId = n?.Shipment?.RetailerID;
                            this.createSvc.selectLinkedCustomer(dvCustomer);
                        }
                        // this.sensorChartData = n;

                        const filterEvents = ['TLD']; // Not sure which SensorEvent it is
                        const allowedSensors = ['G0', 'HU', 'PRB', 'CO2'];

                        if (this.showTiltAndTiltEvents) {
                            allowedSensors.push('TLT');
                        } else {
                            filterEvents.push('TLT');
                        }

                        if (this.showDSTAndDSTEvents) {
                            allowedSensors.push('DST');
                        } else {
                            filterEvents.push('DST');
                        }
                        if (this.ShowShockSensor) {
                            allowedSensors.push('SHK');
                        }

                        let alerts = n?.Shipment?.Alerts?.filter(x => filterEvents.indexOf(x.Pin) === -1);
                        this.handleTripStopsResponse(n?.Shipment?.TripStops);
                        if (!isDateRangeSet) {
                            this.getShipmentResponseData = n;
                            if (this.getShipmentResponseData?.Shipment?.TrackerState?.SmartTagQualityCode) {
                                this.GenerateTagsData(this.Shipment?.trackerId);
                            }
                            if (n && n.Shipment && n.Shipment.Sensors && n.Shipment.Sensors.length > 0) {
                                let sensor = null;

                                sensor = n.Shipment.Sensors.find(k => k.Type === SensorType.Temperature || k.Pin === 'G0');
                                this.tempSensor = sensor ? new SensorItem(sensor) : null;
                                this.defaultTempRangeId = sensor.DefaultSensorRangeID;

                                sensor = n.Shipment.Sensors.find(k => k.Type === SensorType.CarbonDioxide || k.Pin === 'CO2');
                                this.co2Sensor = sensor ? new SensorItem(sensor) : null;

                                sensor = n.Shipment.Sensors.find(k => k.Type === SensorType.ProbeTemp || k.Pin === 'PRB');
                                this.probeSensor = sensor ? new SensorItem(sensor) : null;

                                sensor = n.Shipment.Sensors.find(k => k.Type === SensorType.Humidity || k.Pin === 'HU');
                                this.humiditySensor = sensor ? new SensorItem(sensor) : null;

                                sensor = n.Shipment.Sensors.find(k => k.Type === SensorType.ShockDetected || k.Pin === 'SHK');
                                this.shkSensor = sensor ? new SensorItem(sensor) : null;

                                if (this.showTiltAndTiltEvents) {
                                    sensor = n.Shipment.Sensors.find(k => k.Type === SensorType.TiltSwitch || k.Pin === 'TLT');
                                    this.tiltSensor = sensor ? new SensorItem(sensor) : null;
                                }

                                if (this.showDSTAndDSTEvents) {
                                    sensor = n.Shipment.Sensors.find(k => k.Type === SensorType.Proximity || k.Pin === 'DST');
                                    this.dstSensor = sensor ? new SensorItem(sensor) : null;
                                }

                                this.getShipmentResponseData.Shipment.Sensors = n.Shipment.Sensors.filter(x => allowedSensors.indexOf(x.Pin) > -1);
                            }
                        } else if (this.getShipmentResponseData?.Shipment) {
                            this.getShipmentResponseData = n;
                            // this.getShipmentResponseData.Shipment.Alerts = alerts;
                            // this.getShipmentResponseData.Shipment.TripDurationString = n.Shipment.TripDurationString;
                            // this.getShipmentResponseData.Shipment.TemperatureInfo = n.Shipment.TemperatureInfo;
                        }

                        this.isSensorFilterDirty = false;
                        this.isSensorChartSubmitted = false;
                        if (n && n.Shipment) {
                            this.detailStateSvc.loadAlertListSuccess({
                                list: alerts,
                                itemCount: alerts != null ? alerts.length : 0,
                                isPaged: false
                            });
                        } else {
                            this.clearAlertsData();
                        }
                        this.detailStateSvc.changeAlertListPage(1);
                        this.lastSetTimeout = setTimeout(() => {
                            this.sensorChartSvc.cleanChartDetails();
                            this.sensorChartSvc.buildSensorChart(n, this.tempUnits);
                            this.isFilterDirty = false;
                            this.lastSetTimeout = null;
                            this.setTripDateRange();
                        }, 0);
                        this.detailStateSvc.selectShipment(this.Shipment);
                        if (!isDateFilter) {
                            this.ShowTimeLine =
                                !!( // feature switch for timeline
                                    (this.Shipment && this.Shipment.tripId) && this.activeTrip);
                        }
                        this.clearGetShipmentDataSubscription();
                    }
                });
            }
        }

    }

    private loadShipmentTempInfo(request: ShipmentRequest) {
        this.temperatureInfoSubscription = this.sensorSvc.GetShipmentTempInformation(request).subscribe(n => {
            if (n.ErrorCode === 0) {
                // this.temperatureInfo = n;
                if (n.Shipment && n.Shipment.Sensors && n.Shipment.Sensors.length > 0) {
                    this.mktString = n.Shipment.MktString;
                    n.Shipment.Sensors.map(s => {
                        if (s.Pin === 'G0') {
                            this.temperatureInfo = s;
                        }
                    });
                }
                this.temperatureInfoSubscription.unsubscribe();
            }
        });
    }

    private clearAlertsData() {
        this.detailStateSvc.loadAlertListSuccess({
            list: [],
            itemCount: 0,
            isPaged: false
        });
        this.detailStateSvc.changeAlertListPage(1);
    }

    private shipmentFeatures() {
        const tripStateCode = this.Shipment ? this.Shipment.tripStateCode : null;
        this.updateShowTemperatureInfo(tripStateCode);
        this.showTimeLine =
            !!(// feature switch for timeline
                (this.Shipment && this.Shipment.tripId) && this.activeTrip);
    }

    private updateShowTemperatureInfo(tripStateCode: TripState) {
        this.activeTrip = (tripStateCode &&
            !(tripStateCode as TripState === TripState.Default ||
                tripStateCode as TripState === TripState.Pending));
    }

    private setDefaultNav() {
        if (this.showTimeLine && !this.defaultToSensorChart) {
            this.onNavTabSelected(ShipmentDetailViews.TIMELINE);
        } else {
            this.onNavTabSelected(ShipmentDetailViews.SENSOR_CHART);
        }
    }

    sensorChartClick() {
        this.sensorChartClickSubscription = this.detailSvc.sensorChartClick$.subscribe(k => {
            if (k) {
                const ele = this.sensorChartDetails.nativeElement;
                if (ele) {
                    ele.scrollTop = 1000;
                }
            }
        });
    }

    onSaveSuccess(value, property) {
        switch (property) {
            case 'tripName':
                this.getShipmentResponseData.Shipment.Name = value;
                break;
            case 'carrierName':
                this.getShipmentResponseData.Shipment.CarrierName = value;
                break;
            case 'carrierEmails':
                this.getShipmentResponseData.Shipment.CarrierEmails = value;
                this.getShipmentResponseData.Shipment.CarrierImpactCode1 = this.editShipmentRequest.L1;
                this.getShipmentResponseData.Shipment.CarrierImpactCode2 = this.editShipmentRequest.L2;
                this.getShipmentResponseData.Shipment.CarrierImpactCode3 = this.editShipmentRequest.L3;
                this.getShipmentResponseData.Shipment.CarrierImpactCode4 = this.editShipmentRequest.L4;
                this.getShipmentResponseData.Shipment.CarrierImpactCode5 = this.editShipmentRequest.L5;
                this.getShipmentResponseData.Shipment.CarrierImpactCode6 = this.editShipmentRequest.L6;
                break;
            case 'tripStart':
                this.getShipmentResponseData.Shipment.ScheduledStartTimeString = this.prefSvc.getUserFormattedDate(value, false);
                this.getShipmentResponseData.Shipment.ScheduledStartTime = value;
                break;
            case 'tripEnd':
                this.getShipmentResponseData.Shipment.ScheduledEndTimeString = this.prefSvc.getUserFormattedDate(value, false);
                this.getShipmentResponseData.Shipment.ScheduledEndTime = value;
                break;
            case 'tripDestination':
            case 'tripOrigin':
                this.loadShipmentTimeLine();
                this.onAddLocation(property, value);
                break;
            case 'plateNum':
                this.getShipmentResponseData.Shipment.PlateNum = value;
                break;
            case 'containerNum':
                this.getShipmentResponseData.Shipment.ContainerNum = value;
                break;
            case 'driverPhoneNum':
                this.getShipmentResponseData.Shipment.DriverPhoneNum = value;
                break;
            case 'receiverPONum':
                this.getShipmentResponseData.Shipment.ReceiverPONum = value;
                break;

        }
    }

    onChangeClick(data: { value: any, isMandatory: boolean }, property) {
        this.onSaveClick(data, property, true);
    }

    onSaveClick(data: { value: any, isMandatory: boolean }, property, isOnchange = false) {
        this.successControl = null;
        let invalidValue = data.isMandatory && !data.value;
        let value = (/\S/.test(data.value)) ? data.value : null;
        let request = new UpdateShipmentRequest();
        request.TripId = this.Shipment.tripId;

        if (!invalidValue) {
            this.matchingStops = false;
            switch (property) {
                case 'tripName':
                    request.TripName = value;
                    break;
                case 'carrierName':
                    request.CarrierName = value ?? '';
                    break;
                case 'carrierEmails':
                    if (value && !EmrUtilService.validEmails(value)) {
                        invalidValue = true;
                    } else {
                        request.CarrierEmails = value ?? '';
                    }
                    break;
                case 'tripStart':
                    if (this.getShipmentResponseData?.Shipment?.ScheduledEndTime &&
                        new Date(value).getTime() >= new Date(this.getShipmentResponseData.Shipment.ScheduledEndTime).getTime()) {
                        invalidValue = true;
                    } else {
                        request.TripEstimatedStart = this.utilSvc.DateFormatLocaleChange(value);
                    }
                    break;
                case 'tripEnd':
                    if (this.getShipmentResponseData?.Shipment?.ScheduledStartTime &&
                        new Date(value).getTime() <= new Date(this.getShipmentResponseData.Shipment.ScheduledStartTime).getTime()) {
                        invalidValue = true;
                    } else {
                        request.TripEstimatedEnd = this.utilSvc.DateFormatLocaleChange(value);
                    }
                    break;
                case 'tripDestination':
                    this.newTripEndId = value
                    if ((this.getShipmentResponseData?.Shipment?.TripStartLocationId &&
                        value === this.getShipmentResponseData.Shipment.TripStartLocationId) ||
                        (this.newTripStartId && this.newTripStartId === value)) {
                        invalidValue = true;
                    } else if (this.tripStops.length > 0 &&
                        this.tripStops.some(s => s.LocationId === value)) {
                        this.matchingStops = true;
                        invalidValue = true;
                    } else {
                        request.DestinationLocationId = value;
                    }
                    break;
                case 'tripOrigin':
                    this.newTripStartId = value
                    if ((this.getShipmentResponseData?.Shipment?.TripEndLocationId &&
                        value === this.getShipmentResponseData.Shipment.TripEndLocationId) ||
                        (this.newTripEndId && this.newTripEndId === value)) {
                        invalidValue = true;
                    } else if (this.tripStops.length > 0 &&
                        this.tripStops.some(s => s.LocationId === value)) {
                        this.matchingStops = true;
                        invalidValue = true;
                    } else {
                        request.OriginLocationId = value;
                    }
                    break;
                case 'plateNum':
                    request.PlateNum = value;
                    break;
                case 'containerNum':
                    request.ContainerNum = value;
                    break;
                case 'driverPhoneNum':
                    request.DriverPhoneNum = value;
                    break;
                case 'receiverPONum':
                    request.ReceiverPONum = value ?? '';
            }

            if (!invalidValue) {
                if (!isOnchange) {
                    this.editShipmentSubscription = this.shpSvc.editShipment(request, property).pipe(take(1)).subscribe(
                        n => {
                            this.successControl = property;
                            this.removeErrorControl(property);
                            this.onSaveSuccess(value, property);
                            this.isShipmentEdited = true;
                        },
                        (e: IErrorInfo) => {
                        });
                } else {
                    this.removeErrorControl(property);
                }
            }
            else {
                this.addErrorControl(property);
            }
        }
        else {
            this.removeErrorControl(property);
        }
    }

    addErrorControl(property) {
        if (this.errorControl.indexOf(property) === -1) {
            this.errorControl.push(property);
        }
    }

    removeErrorControl(property) {
        this.errorControl.splice(this.errorControl.indexOf(property), 1);
    }

    onCancelClick(value, property) {
        this.removeErrorControl(property);
        switch (property) {
            case 'tripDestination':
                this.newTripEndId = null;
                break;
            case 'tripOrigin':
                this.newTripStartId = null;
                break;
        }
    }

    onSaveSensorRangeClick(data: { value: any, isMandatory: boolean }, property) {
        this.successControl = null;
        let request = new SetTripSensorRangeRequest();
        request.SensorRangeInfo = [];
        const sensorRangeInfo = new SensorRangeInfo();
        sensorRangeInfo.SensorRangeId = data?.value ? data.value : 0;
        request.TripId = this.Shipment.tripId;
        switch (property) {
            case 'temperature':
                sensorRangeInfo.TypeCode = SensorType.Temperature
                break;
            case 'humidity':
                sensorRangeInfo.TypeCode = SensorType.Humidity
                break;
            case 'co2':
                sensorRangeInfo.TypeCode = SensorType.CarbonDioxide
                break;
            case 'probe':
                sensorRangeInfo.TypeCode = SensorType.ProbeTemp
                break;
        }
        request.SensorRangeInfo.push(sensorRangeInfo);
        this.tripSensorSubscription = this.shpSvc.setTripSensorRange(request, property).pipe(take(1)).subscribe(
            n => {
                this.successControl = property;
                this.errorControl.splice(this.errorControl.indexOf(property), property);
                this.shpSvc.setShipmentUpdatedValue(this.Shipment);
                this.onAddSensorRange(sensorRangeInfo.TypeCode, data.value);
                if (!this.Shipment.isMultiTrip) {
                    this.isShipmentEdited = false;
                }
            },
            (e: IErrorInfo) => {
            }
        );
    }

    GenerateShipmentChart(clearDateRange: boolean) {
        if (clearDateRange) {
            this.isFilterDirty = false;
            this.displayReset = false;
            this.isSensorChartSubmitted = false;
            this.setTripDateRange();
        } else {
            this.isSensorChartSubmitted = true;
        }
        this.reValidationForm();

        if (this.sensorDateRangeForm.valid || clearDateRange) {
            if (clearDateRange) {
                this.selectedDateRange = null;
                this.chartDateRange = null;
                this.specificDateRangeApplied = false;
            } else {
                this.displayReset = true;
                this.selectedDateRange = { ...this.dateRange };
                this.specificDateRangeApplied = true;
            }

            this.resetSensors = false;

            let crumbStartDate = null;
            let crumbEndDate = null;

            if (this.sensorChartSvc.shipmentChartData.noChartData) {
                crumbStartDate = new Date(this.sensorChartSvc.shipmentChartData.minXValue).setSeconds(0, 0);
                crumbEndDate = new Date(this.sensorChartSvc.shipmentChartData.maxXValue).setSeconds(0, 0);
            } else if (this.getShipmentResponseData?.Shipment?.SensorCrumbStartDate &&
                this.getShipmentResponseData?.Shipment?.SensorCrumbEndDate) {
                crumbStartDate = new Date(this.getShipmentResponseData.Shipment.SensorCrumbStartDate).setSeconds(0, 0);
                crumbEndDate = new Date(this.getShipmentResponseData.Shipment.SensorCrumbEndDate).setSeconds(0, 0);
            }
            // New Raw crumbs will send crumbs until today for non multi trips and not completed trips
            if (this.getShipmentResponseData?.Shipment?.TripStateCode !== TripState.Completed && !this.getShipmentResponseData?.Shipment?.IsMultiTrip) {
                crumbEndDate = new Date(this.getShipmentResponseData?.CurrentDate);
            }
            if (this.selectedDateRange &&
                new Date(this.selectedDateRange.startDateTime) >= crumbStartDate &&
                new Date(this.selectedDateRange.endDateTime) <= crumbEndDate) {
                setTimeout(() => this.isFilterDirty = false, 0);
                this.chartDateRange = this.selectedDateRange;
            } else {
                this.hasDatesOutsideOfDefault = clearDateRange ? false : true;
                this.onGenerateData(true);
            }
        }
    }

    private reValidationForm = () => {
        if (this.sensorChartForm) {
            for (const key of Object.keys(this.sensorDateRangeForm.controls)) {
                const ctrl = this.sensorDateRangeForm.controls[key];
                ctrl.markAsPristine();
                ctrl.markAsUntouched();
                ctrl.updateValueAndValidity();
            }
        }
    }

    private onDateChanged(skipFilterDirty = false) {
        if (!skipFilterDirty) {
            this.isFilterDirty = true;
        }

        if (this.dateRange.startDateTime && isNaN(this.dateRange.startDateTime.getTime())) {
            this.dateRange.startDateTime = new Date(this.minDate);
        }

        if (this.dateRange.endDateTime && isNaN(this.dateRange.endDateTime.getTime())) {
            this.dateRange.endDateTime = new Date(this.maxDate);
        }

        this.isDateRangeValid = !((this.dateRange.endDateTime != null && this.dateRange.startDateTime != null)
            && ((this.dateRange.startDateTime >= this.dateRange.endDateTime)));
    }

    setMinMaxDateRange(minDate: Date, maxDate: Date) {
        this.minDate = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate(), 0, 0, 0);
        this.maxDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 23, 59, 50);
    }

    setDateRange(startDate: Date, endDate: Date) {
        this.dateRange.startDateTime = startDate;
        this.dateRange.endDateTime = endDate;
    }

    private setTripDateRange() {
        this.setMinMaxTo90Days();
        let datesApplied = false;
        // Logic to set crumb start and end for setting dateRange min and max for single trip
        if (this.sensorChartSvc.shipmentChartData.noChartData) {
            let startDateTime = new Date(new Date(this.sensorChartSvc.shipmentChartData.minXValue).setSeconds(0, 0));
            let endDateTime = new Date(new Date(this.sensorChartSvc.shipmentChartData.maxXValue).setSeconds(0, 0));

            if (this.minDate <= startDateTime && this.maxDate >= endDateTime) {
                this.setDateRange(startDateTime, endDateTime);
                datesApplied = true;
            }
        }
        if (!this.specificDateRangeApplied && !datesApplied) {
            this.setDateRange(null, null);
        }
        this.onDateChanged(true);
    }

    private ResetDateRange() {
        this.isFilterDirty = false;
        this.displayReset = false;
        this.isSensorChartSubmitted = false;
        this.selectedDateRange = null;
        this.specificDateRangeApplied = false;
        this.setTripDateRange();
        this.reValidationForm();
    }


    // Add New Location
    openAddLocationModal(locationType: any) {
        let i: number;
        let IsArrival: boolean;
        if (typeof locationType === 'object') {
            i = locationType['i'];
            IsArrival = locationType['IsArrival'];
            locationType = locationType['data']
        }
        let locationTypes: any[];
        const bothItem = { text: Origin_Destination, value: 'Both' };
        const destItem = { text: Destination, value: 'Destination' };
        const originItem = { text: Origin, value: 'Origin' };

        switch (locationType) {
            case 'tripDestination':
                locationTypes = [
                    bothItem,
                    destItem
                ];
                break;
            case 'tripOrigin':
                locationTypes = [
                    bothItem,
                    originItem
                ];
                break;
            case 'tripstop':
                locationTypes = [
                    bothItem,
                    originItem,
                    destItem
                ];
                break;

        }

        this.bsModalRef = this.modalSvc.show(
            LocationComponent,
            {
                initialState: {
                    locationTypes,
                    context: locationType,
                },
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        this.bsModalRef.content.addLocation.pipe(take(1)).subscribe(n => this.onAddLocationPopup(n, i, IsArrival));
    }

    onAddLocationPopup(args: AddLocationEventArgs, i: number, IsArrival: boolean) {
        switch (args.context) {
            case 'tripDestination':
                this.newTripEndId = args.request.LocationId;
                break;
            case 'tripOrigin':
                this.newTripStartId = args.request.LocationId;
                break;
            case 'tripstop':
                if (!this.tripStops ||
                    i < 0 ||
                    this.tripStops.length < i) {
                    return;
                }
                const stop = this.tripStops[i];
                const selectedLocation = this.stopLocations ? this.stopLocations.find(k => k.value === args.request.LocationId) : null;
                const locationName = selectedLocation ? selectedLocation.text : null;
                if (IsArrival) {
                    stop.ArrivalLocationId = args.request.LocationId;
                    stop.ArrivalLocationName = locationName;
                } else {
                    stop.LocationId = args.request.LocationId;
                    stop.LocationName = locationName;
                }
                break;
        }
        if (this.bsModalRef) {
            this.bsModalRef.hide();
        }
    }

    onAddLocation(context: string, locationID: number) {
        if (this.getShipmentResponseData && this.getShipmentResponseData.Shipment) {
            const selectedLocation = this.locationList.find(loc => loc.value === locationID);
            const locationName = selectedLocation?.text ? selectedLocation.text?.split('-')[0] : null;
            switch (context) {
                case 'tripOrigin':
                    this.newTripStartId = null
                    this.getShipmentResponseData.Shipment.TripStartLocationId = locationID;
                    this.getShipmentResponseData.Shipment.TripStartLocationName = locationName;
                    break;
                case 'tripDestination':
                    this.newTripEndId = null
                    this.getShipmentResponseData.Shipment.TripEndLocationId = locationID;
                    this.getShipmentResponseData.Shipment.TripEndLocationName = locationName;
                    break;
            }
        }
    }


    // Add new Sensor Range
    openAddSensorRangeModal(type) {
        this.bsModalRef = this.modalSvc.show(
            SensorRangeComponent,
            {
                initialState: { SensorType: type },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        this.bsModalRef.content.addSensorRange.subscribe(n => this.onAddSensorRangePopup(n?.request?.SensorTypeCode, n?.request?.SensorRangeId));
    }

    onAddSensorRangePopup(sensorType: SensorType, sensorRangeId: number) {
        switch (sensorType) {
            case SensorType.Temperature:
                this.newtempSensorID = sensorRangeId;
                break;
            case SensorType.Humidity:
                this.newHumiditySensorID = sensorRangeId;
                break;
            case SensorType.CarbonDioxide:
                this.newCO2SensorID = sensorRangeId;
                break;
            case SensorType.ProbeTemp:
                this.newProbeSensorID = sensorRangeId;
                break;
            case SensorType.ShockDetected:
                this.newShockSensorID = sensorRangeId;
                break;
        }
        if (this.bsModalRef) {
            this.bsModalRef.hide();
        }
    }

    onAddSensorRange(sensorType: SensorType, sensorRangeId: number) {
        const selectedSensorRange = this.sensorRangeList[SensorType[sensorType]].find(s => s.SensorRangeId === sensorRangeId);

        const sensorItem = selectedSensorRange ?
            new SensorItem({ RangeName: selectedSensorRange.Description, TripSensorRangeID: selectedSensorRange.SensorRangeId, CustomSensorRange: sensorRangeId > 0 })
            : new SensorItem({ RangeName: sensorRangeId ? null : labelDefault, TripSensorRangeID: sensorRangeId, CustomSensorRange: sensorRangeId > 0 });

        switch (sensorType) {
            case SensorType.Temperature:
                this.tempSensor = sensorItem;
                break;
            case SensorType.Humidity:
                this.humiditySensor = sensorItem;
                break;
            case SensorType.CarbonDioxide:
                this.co2Sensor = sensorItem;
                break;
            case SensorType.ProbeTemp:
                this.probeSensor = sensorItem;
                break;
            case SensorType.ShockDetected:
                this.shkSensor = sensorItem;
                break;
        }
    }

    shipmentDetailsText(value: any) {
        return value ? value : 'n/a';
    }

    onDeleteShipmentClick(shipment: Shipment) {
        this.popupSvc.showDeleteTrip(shipment, this.bsShipmentDetailModalRef);
    }

    onEditStops() {
        if (this.getShipmentResponseData?.Shipment?.TripId > 0) {
            this.createShipmentRequest = new CreateShipmentRequest();
            this.createShipmentRequest.EstimatedFlightMinutes = this.getShipmentResponseData?.Shipment?.EstimatedFlightMinutes;
            this.createShipmentRequest.EstimatedLoadingMinutes = this.getShipmentResponseData?.Shipment?.EstimatedLoadingMinutes;
            this.tripStops = _.cloneDeep(this.tripStopsCopy);
            this.stopsModalRef = this.modalSvc.show(
                this.editStopsModal,
                {
                    class: 'modal-lg modal-dialog-centered max-width-1000',
                    ignoreBackdropClick: true
                }
            );
        }
    }

    handleTripStopsResponse(resp?: TripStop[]) {
        if (resp?.length > 0) {
            const stops = this.createSvc.tripStopsToStopModel(resp, this.stopLocations);
            stops.map((a, i) => {
                a.Index = i;
                if (a.AirportArrival || a.AirportDeparture) {
                    if (!a.ArrivalLocationId || !a.LocationId)
                        a.IsInValid = true;
                    else
                        a.IsInValid = false;
                }
                if (a.AirportArrival && !a.AirportDeparture) {
                    a.AirportDeparture = true;
                }
            });
            this.tripStops = stops;
            this.tripStopsCopy = _.cloneDeep(stops);
        } else {
            this.tripStops = [];
        }
    }

    updateTripStops(data: any) {
        if (!data || data.stopsForm.valid) {
            const request: SetTripStopRequest = new SetTripStopRequest();
            request.TripId = this.getShipmentResponseData?.Shipment?.TripId;
            request.Stops = this.detailSvc.getUpdatedTripStops(this.tripStops, this.tripStopsCopy);
            if (request.Stops?.length > 0) {
                this.detailSvc.UpdateTripStops(request).subscribe(k => {
                    this.stopsModalRef.hide();
                    this.tripStopsCopy = _.cloneDeep(this.tripStops);
                })
            } else {
                this.stopsModalRef.hide();
            }
            // To Update Estimated minutes
            if (this.getShipmentResponseData?.Shipment?.EstimatedLoadingMinutes !== this.createShipmentRequest.EstimatedLoadingMinutes ||
                this.getShipmentResponseData?.Shipment?.EstimatedFlightMinutes !== this.createShipmentRequest.EstimatedFlightMinutes) {
                const property = 'Estimated minutes';
                let request = new UpdateShipmentRequest();
                request.TripId = this.Shipment.tripId;
                request.EstimatedFlightMinutes = this.createShipmentRequest.EstimatedFlightMinutes ? this.createShipmentRequest.EstimatedFlightMinutes : -1;
                request.EstimatedLoadingMinutes = this.createShipmentRequest.EstimatedLoadingMinutes ? this.createShipmentRequest.EstimatedLoadingMinutes : -1;
                this.editShipmentSubscription = this.shpSvc.editShipment(request, property).pipe(take(1)).subscribe(
                    n => {
                        this.getShipmentResponseData.Shipment.EstimatedFlightMinutes = this.createShipmentRequest.EstimatedFlightMinutes;
                        this.getShipmentResponseData.Shipment.EstimatedLoadingMinutes = this.createShipmentRequest.EstimatedLoadingMinutes;
                    },
                    (e: IErrorInfo) => {
                    });
            }
        } else {
            this.isStopsFormSubmitted = true;
        }
    }

    cancelEditTripStops() {
        this.stopsModalRef.hide();
        // this.tripStops = _.cloneDeep(this.tripStopsCopy);
    }

    checkStopsFormState() {
        this.isStopsFormSubmitted = false;
    }

    // Open the Alert contacts modal
    onEditAlertContacts() {
        if (this.getShipmentResponseData?.Shipment && this.getShipmentResponseData.Shipment.CanEdit
            && this.userCanCreateShipment && (this.getShipmentResponseData.Shipment.TripStateCode === 1 ||
                this.getShipmentResponseData.Shipment.TripStateCode === 2)) {
            this.editShipmentRequest = new UpdateShipmentRequest();
            this.editShipmentRequest.TripId = this.Shipment.tripId;
            this.editShipmentRequest.CarrierEmails = this.getShipmentResponseData.Shipment.CarrierEmails;
            this.editShipmentRequest.L1 = this.getShipmentResponseData.Shipment.CarrierImpactCode1;
            this.editShipmentRequest.L2 = this.getShipmentResponseData.Shipment.CarrierImpactCode2;
            this.editShipmentRequest.L3 = this.getShipmentResponseData.Shipment.CarrierImpactCode3;
            this.editShipmentRequest.L4 = this.getShipmentResponseData.Shipment.CarrierImpactCode4;
            this.editShipmentRequest.L5 = this.getShipmentResponseData.Shipment.CarrierImpactCode5;
            this.editShipmentRequest.L6 = this.getShipmentResponseData.Shipment.CarrierImpactCode6;
            this.additionalAlertContacts = this.modalSvc.show(
                this.editAlertContactsModal,
                {
                    class: 'modal-lg modal-dialog-centered max-width-1000',
                    ignoreBackdropClick: true
                }
            );
        }
    }

    // TO save the Alert contacts to DB
    updateAlertContacts() {
        if (EmrUtilService.validEmails(this.editShipmentRequest.CarrierEmails) && !this.isAlertContactsUpdated()) {
            this.editShipmentRequest.CarrierEmails = this.editShipmentRequest.CarrierEmails ?? '';
            this.editShipmentSubscription = this.shpSvc.editShipment(this.editShipmentRequest, 'carrierEmails').pipe(take(1)).subscribe(
                n => {
                    this.onSaveSuccess(this.editShipmentRequest.CarrierEmails, 'carrierEmails');
                    this.isShipmentEdited = true;
                    this.cancelAlertContacts();
                },
                (e: IErrorInfo) => {
                });
        }
    }

    // returns Date updated status
    isAlertContactsUpdated(): boolean {
        return this.editShipmentRequest.CarrierEmails === this.getShipmentResponseData?.Shipment?.CarrierEmails &&
            this.editShipmentRequest.L1 === this.getShipmentResponseData.Shipment.CarrierImpactCode1 &&
            this.editShipmentRequest.L2 === this.getShipmentResponseData.Shipment.CarrierImpactCode2 &&
            this.editShipmentRequest.L3 === this.getShipmentResponseData.Shipment.CarrierImpactCode3 &&
            this.editShipmentRequest.L4 === this.getShipmentResponseData.Shipment.CarrierImpactCode4 &&
            this.editShipmentRequest.L5 === this.getShipmentResponseData.Shipment.CarrierImpactCode5 &&
            this.editShipmentRequest.L6 === this.getShipmentResponseData.Shipment.CarrierImpactCode6;
    }

    // To close the Alerts contact modal
    cancelAlertContacts() {
        this.editShipmentRequest = new UpdateShipmentRequest();
        this.additionalAlertContacts.hide();
    }

    openAddressInfo(address: string, name: string) {
        this.addressInfoPopup = this.modalSvc.show(
            this.addressInfoModal,
            {
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
        this.address = address;
        this.addressName = name;
    }

    private GenerateTagsData(deviceId) {
        this.loggerReadSubscription = this.detailSvc.getLoggerTagsListLoader(deviceId).subscribe(reads => {
            if (reads) {
                this.loggerRead = reads;
                this.loggerReadSubscription?.unsubscribe();
            }
        })
    }

    showViewLog(customerTrackerId, tripId) {
        const getHistoryTripRequest = new GetHistoryTripRequest();
        getHistoryTripRequest.TripId = tripId;
        getHistoryTripRequest.CustomerId = this.customerId;

        this.popupSvc.showViewLogs(getHistoryTripRequest);
    }
}
