import { TripTemplateInfo } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadShipmentTemplates implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES;
}

export class LoadShipmentTemplatesSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES;

    constructor(public shipmentTemplateList: IListInfo<TripTemplateInfo>) { }
}

export class LoadShipmentTemplatesError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES;

    constructor(public message: string) { }
}

export class CancelLoadShipmentTemplates implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_SHIPMENT_TEMPLATES;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES;
}

