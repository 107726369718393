import { Injectable } from '@angular/core';

import { Store, createSelector, Selector } from '@ngrx/store';

import { Customer } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ICustomerContextState } from 'app-modules/core/store/models/customer-context-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as custActions from 'app-modules/core/store/actions/customer.actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class CustomerStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public customerStateSelector = ((state: IAppState) => state.customer);
    public isLoadRequiredSelector = createSelector(
        this.customerStateSelector,
        (state) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.customerStateSelector,
        (state) => state.isLoading
    );
    public customersSelector = createSelector(
        this.customerStateSelector,
        (state) => state.customerList
    );
    public isSelectedSelector = createSelector(
        this.customerStateSelector,
        (state) => Boolean(state.selectedContext)
    );
    public selectedContextSelector = createSelector(
        this.customerStateSelector,
        (state) => state.selectedContext
    );

    // Observables
    public customerState$ = this.store.select(this.customerStateSelector).pipe();
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public customerList$ = this.store.select(this.customersSelector).pipe();
    public isSelected$ = this.store.select(this.isSelectedSelector).pipe();
    public selectedContext$ = this.store.select(this.selectedContextSelector).pipe();

    // Actions
    public loadCustomers() {
        this.store.dispatch(new custActions.LoadCustomers());
    }

    public loadCustomersSuccess(customerList: IListInfo<Customer>) {
        this.store.dispatch(new custActions.LoadCustomersSuccess(customerList));
    }

    public loadCustomersError(message: string) {
        this.store.dispatch(new custActions.LoadCustomersError(message));
    }

    public cancelLoadCustomers() {
        this.store.dispatch(new custActions.CancelLoadCustomers());
    }

    public selectCustomer(customerId: number) {
        this.store.dispatch(new custActions.SelectCustomer(customerId));
    }

    public selectCustomerSuccess(context: ICustomerContextState) {
        this.store.dispatch(new custActions.SelectCustomerSuccess(context));
    }

    public selectCustomerError(message: string) {
        this.store.dispatch(new custActions.SelectCustomerError(message));
    }
}

