import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ShowDistancePopupComponent } from 'app-modules/show-distance/components/show-distance-popup/show-distance-popup.component';
import { ShowDistancePopup } from 'app-modules/core/models/show-distance-popup.model';
import { GetDistanceToTargetsRequest } from 'emr-ng-shared';

@Injectable()
export class ShowDistanceService extends ShowDistancePopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenShowDistancePopup(distanceToTargetsRequest: GetDistanceToTargetsRequest): void {
        const initialState = {
            distanceToTargetsRequest: distanceToTargetsRequest
        };

        this.bsModalRef = this.modalSvc.show(
            ShowDistancePopupComponent,
            {
                initialState: initialState,
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}


