import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { TimePeriod } from 'emr-ng-shared';
import { TimePeriodInfo } from 'emr-ng-shared';

import { ITimePeriod } from 'app-modules/core/store/models/time-period.interface';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { PreferenceService } from 'app-modules/core/services/preference.service';

@Component({
    selector: 'app-shipment-filter-period-summary',
    templateUrl: 'shipment-filter-period-summary.component.html',
    styleUrls: ['shipment-filter-period-summary.component.css']
})
export class ShipmentFilterPeriodSummaryComponent implements OnInit, OnDestroy {
    @Input() filter$: Observable<ITimePeriod>;
    @Input() period$: Observable<TimePeriodInfo>;

    period: TimePeriod;
    periodName: string;
    startDateTime: Date;
    endDateTime: Date;

    filterSubscription: Subscription;
    periodSubscription: Subscription;
    @Input() shipmentFilter: IShipmentFilterState;
    @Input() isHugeCustomer: boolean;
    constructor(
        private shipmentFilterService: ShipmentFilterService,
        private prefSvc: PreferenceService
    ) { }
    ngOnInit() {
        this.filterSubscription = combineLatest(
            this.prefSvc.isPreferenceLoading$,
            this.prefSvc.isPreferenceLoadRequired$,
            this.filter$
        ).subscribe(([loading, loadRequired, n]) => {
            if (!loading && !loadRequired) {
                this.onFilterChanged(n);
            }
        });
        this.periodSubscription = this.period$.subscribe(n => this.onPeriodChanged(n));
    }

    ngOnDestroy() {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
        if (this.periodSubscription) {
            this.periodSubscription.unsubscribe();
        }
    }

    onFilterChanged(filter: ITimePeriod) {
        this.period = filter.timePeriod;
        this.startDateTime = filter.dateRange.startDateTime ? this.prefSvc.getUserFormattedDate(filter.dateRange.startDateTime) : filter.dateRange.startDateTime;
        this.endDateTime = filter.dateRange.endDateTime ? this.prefSvc.getUserFormattedDate(filter.dateRange.endDateTime) : filter.dateRange.endDateTime;
    }

    onPeriodChanged(period: TimePeriodInfo) {
        if (period) {
            this.periodName = period.DisplayName;
        } else {
            this.periodName = '';
        }
    }


    clearSelectedFilter() {
        this.shipmentFilter.period.timePeriod = null;
        this.shipmentFilterService.changeShipmentFilter(this.shipmentFilter);
    }
}
