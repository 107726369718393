import { ITrackerState } from 'app-modules/core/store/models/tracker-state.interface';

import { initialState } from '../../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as trackActions from 'app-modules/core/store/create-shipment/tracker/create-shipment-tracker.actions';
import * as customerActions from 'app-modules/core/store/actions/customer.actions';
import * as shipmentActions from 'app-modules/core/store/actions/shipment.actions';
import { emptyList } from '../../models/list-info-state.interface';

type Actions =
    shipmentActions.CreateShipmentSuccess |
    shipmentActions.CloseShipmentSuccess |
    customerActions.SelectCustomerSuccess |
    authActions.SignOut |
    trackActions.LoadTrackers |
    trackActions.LoadTrackersSuccess |
    trackActions.LoadTrackersError;

export function CreateShipmentTrackerReducer(state: ITrackerState = initialState.createShipmentTracker, action: Actions): ITrackerState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
        case actionIndex.CREATE_SHIPMENT_SUCCESS:
        case actionIndex.CLOSE_SHIPMENT_SUCCESS:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                trackerList: emptyList(),
                isLoadRequired: true
            };

        case actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS_SUCCESS:
            return {
                ...state,
                trackerList: action.trackerList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
