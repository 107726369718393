import { Action } from '@ngrx/store';

import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class ChangeShipmentFilter implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_FILTER;
    constructor(public filter: IShipmentFilterState) { }
}

export class CustomChangeShipmentFilter implements Action {
    readonly type = actionIndex.CUSTOM_CHANGE_SHIPMENT_FILTER;
    constructor() { }
}

export class ChangeShipmentFilterWithOutShipmentListUpdate implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_FILTER_WITH_OUT_SHIPMENT_LIST_UPDATE;
    constructor(public filter: IShipmentFilterState) { }
}

export class UpdateIsDefaultFilterState implements Action {
    readonly type = actionIndex.UPDATE_IS_DEFAULT_SHIPMENT_FILTER;
    constructor(public isDefault: boolean) { }
}
