import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { filter, tap, switchMap, finalize, share, map, catchError , startWith } from 'rxjs/operators';

import { CreateShipmentRequest, muteFirst, SetShipmentTemplateResponse } from 'emr-ng-shared';

import { GetTripTemplateRequest } from 'emr-ng-shared';
import { TripTemplateInfo } from 'emr-ng-shared';

import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';

import { ShipmentTemplateStateService } from 'app-modules/core/store/shipment-template/shipment-template-state.service';

@Injectable()
export class ShipmentTemplateService {
    constructor(
        private shipmemtTemplateStateSvc: ShipmentTemplateStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.shipmemtTemplateStateSvc.isLoadRequired$;
    public isLoading$ = this.shipmemtTemplateStateSvc.isLoading$;

    public ReloadShipmentTemplates() {
        this.shipmemtTemplateStateSvc.reloadShipmentTemplates()
    }

    public shipmentTemplateList$: Observable<IListInfo<TripTemplateInfo>> = muteFirst(
        this.getShipmentTemplateListLoader().pipe(startWith(null)),
        this.shipmemtTemplateStateSvc.shipmentTemplateList$
    );

    private getShipmentTemplateListLoader(): Observable<IListInfo<TripTemplateInfo>> {
        return this.shipmemtTemplateStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.shipmemtTemplateStateSvc.loadShipmentTemplates()),
            switchMap(() => this.getShipmentTemplateList()),
            tap(
                n => this.shipmemtTemplateStateSvc.loadShipmentTemplatesSuccess(n),
                e => this.shipmemtTemplateStateSvc.loadShipmentTemplatesError('')
            ),
            finalize(() => this.shipmemtTemplateStateSvc.cancelLoadShipmentTemplates()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getShipmentTemplateList(): Observable<IListInfo<TripTemplateInfo>> {
        const request = new GetTripTemplateRequest();
        return this.oversightSvc.GetTripTemplate(request).pipe(
            map((response) => {
                return {
                    list: response.TripTemplateList,
                    itemCount: response.TripTemplateList.length,
                    isPaged: false
                };
            })
        );
    }

    UpdateShipmentTemplate(request: CreateShipmentRequest): Observable<SetShipmentTemplateResponse> {
        return of(null).pipe(
            tap(() => {
                this.shipmemtTemplateStateSvc.setShipmentTemplate();
            }),
            switchMap(() => this.SetShipmentTemplate(request)),
            tap(
                n => this.shipmemtTemplateStateSvc.setShipmentTemplateSuccess(request, false),
                e => this.shipmemtTemplateStateSvc.setShipmentTemplateError()
            ),
            finalize(() => this.shipmemtTemplateStateSvc.cancelSetShipmentTemplate()),
            catchError(() => {
                return of(null);
            })
        );
    }

    private SetShipmentTemplate(request: CreateShipmentRequest): Observable<SetShipmentTemplateResponse> {
        return this.oversightSvc.SetShipmentTemplate(request).pipe(
            map(n => n));
    }

    DeleteShipmentTemplate(tripTemplateId: number): Observable<SetShipmentTemplateResponse> {
        return of(null).pipe(
            tap(() => {
                this.shipmemtTemplateStateSvc.setShipmentTemplate();
            }),
            switchMap(() => this.DeleteShipmentTemplateByID(tripTemplateId)),
            tap(
                n => {
                    if (n.ErrorCode === 0) {
                        let request = new CreateShipmentRequest();
                        request.TripTemplateID = tripTemplateId;
                        this.shipmemtTemplateStateSvc.setShipmentTemplateSuccess(request, true);
                    }
                },
                e => this.shipmemtTemplateStateSvc.setShipmentTemplateError()
            ),
            finalize(() => this.shipmemtTemplateStateSvc.cancelSetShipmentTemplate()),
            catchError(() => {
                return of(null);
            })
        );
    }

    DeleteShipmentTemplateByID(tripTemplateId: number): Observable<SetShipmentTemplateResponse> {
        return this.oversightSvc.DeleteShipmentTemplateByID(tripTemplateId).pipe(
            map(n => n));
    }
}






