import { ICustomerDashboardState } from '../models/customer-dashboard-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as custDashboardActions from './customer-dashboard.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

import { emptyList } from '../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    custDashboardActions.LoadCustomerDashboards |
    custDashboardActions.LoadCustomerDashboardsSuccess |
    custDashboardActions.LoadCustomerDashboardsError |
    custDashboardActions.SetCustomerDashboardSuccess |
    custDashboardActions.SetCustomerDashboardError |
    custDashboardActions.DeleteCustomerDashboardSuccess;

export function CustomerDashboardReducer(state: ICustomerDashboardState = initialState.customerDashboardList, action: Actions): ICustomerDashboardState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.customerDashboardList;
        case actionIndex.LOAD_CUSTOMER_DASHBOARD:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_CUSTOMER_DASHBOARD_SUCCESS:
            return {
                ...state,
                customerDashboardList: action.customerDashboardList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_CUSTOMER_DASHBOARD_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.SET_CUSTOMER_DASHBOARD_SUCCESS:
            const newDashboardsList = state.customerDashboardList.list.splice(0);
            const listInfo = emptyList();
            const dashboardIndex = newDashboardsList.findIndex(k => k.DashboardCustomerID === action.customerDashboard.DashboardCustomerID);
            if (dashboardIndex > -1) {
                newDashboardsList[dashboardIndex] = action.customerDashboard
            } else {
                newDashboardsList.push(action.customerDashboard);
            }
            listInfo.itemCount = newDashboardsList.length;
            listInfo.isPaged = false;
            listInfo.list = newDashboardsList;
            return {
                ...state,
                customerDashboardList: listInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SET_CUSTOMER_DASHBOARD_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.DELETE_CUSTOMER_DASHBOARD_SUCCESS:
            {
                const newcustDashboardList = emptyList();
                newcustDashboardList.list = state.customerDashboardList.list.filter(list => list.DashboardCustomerID !== action.dashboardCustomerId );
                newcustDashboardList.itemCount = newcustDashboardList.list.length;
                state.customerDashboardList = newcustDashboardList;                

                return {
                    ...state,
                    isLoadRequired: false,
                    errorMessage: ''
                };
            }
        default:
            return {
                ...state
            };
    }
}