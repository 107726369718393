<emr-modal headerTitle="Create Shipment" i18n-headerTitle="@@link_createshipment" [bsModalRef]=bsModalRef
    [showCloseButton]=showCloseButton>
    <div modal-body>
        <div class="m-1 p-1">
            <div *ngIf="hasError; else noError">
                <p>{{ errorMessage }}</p>
            </div>
            <div *ngIf="hasError && debugErrorMessage">
                <hr>
                <p>{{ debugErrorMessage }}</p>
            </div>
            <ng-template #noError>
                <!-- <p i18n="@@shipment_created_successfully">Shipment created successfully</p> -->
                <p i18n="@@message_shipment_created_processing">Shipment created successfully and may take up to two minutes to display in the List View</p>
            </ng-template>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="shipmentResponseOk"
                cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2" buttonType="button"
                buttonText="Ok" i18n-buttonText="@@link_ok" (click)='onOkClick()'></emr-button>
        </div>
    </div>
</emr-modal>