import { IRoutesState } from 'app-modules/core/store/models/route-state.interface';

import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as groupsActions from './route.actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';
import { emptyList } from '../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    groupsActions.CancelLoadSavedRoute |
    groupsActions.LoadSavedRoute |
    groupsActions.LoadSavedRouteError |
    groupsActions.LoadSavedRouteSuccess |
    groupsActions.SaveRouteSuccess |
    groupsActions.DeleteRouteSuccess |
    custActions.SelectCustomerSuccess;

export function RoutesReducer(
    state: IRoutesState = initialState.routes,
    action: Actions
): IRoutesState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.routes;

        case actionIndex.SELECT_CUSTOMER_SUCCESS:
        case actionIndex.SAVE_ROUTE_SUCCESS:
            return {
                ...state,
                Routes: emptyList(),
                isLoadRequired: true,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.DELETE_ROUTE_SUCCESS:
            let newRouteList = state.Routes.list.splice(0);
            const listInfo = emptyList();
            if (action.routes.RouteId) {
                newRouteList = newRouteList.filter(k => k.RouteId !== action.routes.RouteId);
            }
            listInfo.list = newRouteList;
            listInfo.itemCount = newRouteList.length;
            listInfo.isPaged = false;
            return {
                ...state,
                Routes: listInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_SAVED_ROUTE:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.LOAD_SAVED_ROUTE_SUCCESS:
            return {
                ...state,
                Routes: action.routes,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_SAVED_ROUTE_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: action.payload
            };

        default:
            return state;
    }
}
