<div class="main-summary-details">
    <!-- <div class="row"> 
         <div class="col-md-2">
            <emr-button cssClass="btn-sm btn-style label-text text-12 btn-background" buttonText="Edit Shipment"></emr-button>
        </div>
        <div class="col-md-2">
            <emr-button cssClass="btn-sm btn-style label-text text-12 btn-background" buttonText="End Shipment"></emr-button>
        </div> 
    </div> -->
    <div class="row trip-map-div mx-0">
        <div *ngIf="shipment && shipment.tripId; else timelineNoTrip" class="col-md-4 pt-2 pl-1 timeline-div">
            <div class="pb-1">
                <b><span i18n="@@last_reported_location">Last Reported Location</span>: </b>
            </div>
            <div class="pb-4">
                <ng-container
                    *ngIf="!(isSensorChartLoading$ | async) && latestShipmentDetails?.TrackerState && latestShipmentDetails?.TrackerState?.AddressGeoTimestampFormatted != '' && !latestShipmentDetails?.TrackerState?.AddressOlderThanThreshold; else viewCurrentLocation">
                    <span class="pr-1 color-header"><i class="fa fa-circle-o"></i></span>
                    <span>{{ latestShipmentDetails?.TrackerState?.AddressGeo }}</span>
                </ng-container>
                <ng-template #viewCurrentLocation>
                    <button *ngIf="!(isSensorChartLoading$ | async) && latestShipmentDetails && !viewLocationsLoading"
                        type="button" class="btn btn-link py-0" style="font-size: 12px;" i18n="@@view_current_location"
                        (click)="onViewLocationClick(latestShipmentDetails?.GlobalDeviceId)">View Current
                        Location</button>
                </ng-template>
                <div class="full-width-center-spinner"
                    *ngIf="((isSensorChartLoading$ | async) || (isTimeLineLoading$ | async)) || viewLocationsLoading">
                    <app-local-loading-indicator width="50%" height="20px">
                    </app-local-loading-indicator>
                </div>
            </div>
            <ng-container *ngIf="!(isTimeLineLoading$ | async) && timelineData">
                <div class="row {{c.Latitude&&c.Longitude?'cursor-pointer':''}}" *ngFor="let c of timelineData"
                    (click)="onTimelineClick(c)">
                    <div *ngIf="!c.IsForSensorChart||c.IsOrigin||c.IsDestination||c.IsDBL">
                        <div class="col-md-12">
                            <span class="tl-date mr-10p">
                                <ng-container *ngIf="c.IsOrigin;else destination">
                                    <img src="{{originUrl}}" alt="Origin" i18n-alt="@@label_origin" class="ml-n9">
                                </ng-container>
                                <ng-template #destination>
                                    <ng-container *ngIf="c.IsDestination;else stops">
                                        <img src="{{destinationUrl}}" alt="Destination" i18n-alt="@@label_destination"
                                            class="ml-n9">
                                    </ng-container>
                                </ng-template>
                                <ng-template #stops>
                                    <i
                                        class="fa fa-circle mr-10p {{(c.StartSatTime||c.EndSatTime)?(c.IsStop?'color-orange':'color-yellow'):'color-grey'}} "></i>
                                </ng-template>
                                <span class="label-css">{{c.LocationName}}</span>
                            </span>
                        </div>
                        <div class="col-md-12">
                            <ng-container *ngIf="c.IsOrigin;else destinationInfo">
                                <div class="bl-1g display-inlb height-30 ml-6p mr-2">
                                    <div class="col-md-12">
                                        <span class="label-css color-header"><span i18n="@@shipment_started">
                                                Shipment Started</span>:</span>
                                        {{latestShipmentDetails?.ActualStartTimeString}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #destinationInfo>
                                <ng-container *ngIf="c.IsDestination;else stopsInfo">
                                    <div class="col-md-12">
                                        <span *ngIf="latestShipmentDetails?.ActualEndTimeString && c.IsDestinationReached;else noDestination">
                                            <span class="label-css color-header"><span i18n="@@shipment_end">
                                                    Shipment End</span>:</span>
                                            {{latestShipmentDetails?.ActualEndTimeString}}
                                        </span>
                                        <ng-template #noDestination>
                                            <span class="label-css" i18n="@@timeline_notentered">
                                                Not Entered
                                            </span>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </ng-template>
                            <ng-template #stopsInfo>
                                <ng-container *ngIf="c.StartSatTime||c.EndSatTime;else notEntered">
                                    <div class="bl-1g display-inlb height-50 ml-6p mr-2">
                                        <div class="col-md-12">
                                            <span class="label-css color-header"> <span
                                                    i18n="@@timeline_entered">Entered</span>:
                                            </span>
                                            <span class="float-right pl-1">
                                                {{c.StartSatTime?c.StartSatTimeString:label_n_a}}</span>
                                        </div>
                                        <div class="col-md-12">
                                            <span class="label-css color-header"><span
                                                    i18n="@@timeline_exited">Exited</span>:
                                            </span>
                                            <span
                                                class="{{c.EndSatTime?'float-right':'pl-2'}}  pl-1">{{c.EndSatTime?c.EndSatTimeString:label_n_a}}</span>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #notEntered>
                                    <div class="bl-1g display-inlb height-30 ml-6p mr-2">
                                        <div class="col-md-12">
                                            <span class="label-css" i18n="@@timeline_notentered">
                                                Not Entered
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>
                    </div>
                </div>

            </ng-container>
        </div>
        <div class="col-md-8 pl-2">
            <div id="tripMap">
                <div class="col-md-12 pr-0 ">
                    <app-shipment-route [SetFixedHeight]=true [Shipment]="shipment" [isFromDetails]=true
                        [displayTimePeriod]=false [detailsCommonData]="shipmentDetailsCommonData" [isFromTimeline]=true
                        [timelineData]="selectedTimeline" [TraxxList]="TraxxList" [TraxxCopy]="TraxxCopy">
                    </app-shipment-route>
                </div>
            </div>
            <!-- <div id="tripNote">
                <div class="mt-2">
                    <form>
                        <div class="form-group">
                            <emr-textbox identifier="Notes" label="Notes"
                                        placeholder="Enter notes" [(ngModel)]="notes" name="Notes" helpTip="Notes"
                                        [inline]=false labelColumns=3 textBoxColumns=9></emr-textbox>
                        </div>
                    </form>
                </div>
            </div> -->
        </div>
    </div>
</div>
<ng-template #timelineNotEntered>
    <span i18n="@@timeline_notentered">Not Entered</span>
</ng-template>
<ng-template #timelineNoTrip>
    <div class="col-md-4 mt-4 pl-0 pt-2 timeline-div">
        <span *ngIf="shipment" i18n="@@not_applicable">Not Applicable</span>
    </div>
    <!-- <div *ngIf="userCanCreateShipment | async" class="col-md-4 mt-4 pt-2">
    <emr-button cssClass="btn-sm btn-style label-text text-12 btn-background" buttonText="Create Shipment" i18n-buttonText="@@create_shipment"
    (onClick)="onCreateShipmentClick(shipment.GlobalDeviceId, shipment.DeviceId)"></emr-button>        
    </div> -->
</ng-template>