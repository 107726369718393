import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { CustomerDashboardInfo, SetDashboardCustomerRequest } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ICustomerDashboardState } from '../models/customer-dashboard-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as custDashboardActions from './customer-dashboard.actions';

@Injectable()
export class CustomerDashboardStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public customerDashboardStateSelector = ((state: IAppState) => state.customerDashboardList);
    public isLoadRequiredSelector = createSelector(
        this.customerDashboardStateSelector,
        (state: ICustomerDashboardState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.customerDashboardStateSelector,
        (state: ICustomerDashboardState) => state.isLoading
    );
    public custDashboardListSelector = createSelector(
        this.customerDashboardStateSelector,
        (state: ICustomerDashboardState) => state.customerDashboardList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public customerDashboardList$ = this.store.select(this.custDashboardListSelector).pipe();

    // Actions
    public loadCustomerDashboards() {
        this.store.dispatch(new custDashboardActions.LoadCustomerDashboards());
    }

    public loadCustomerDashboardsSuccess(customerDashboardList: IListInfo<CustomerDashboardInfo>) {
        this.store.dispatch(new custDashboardActions.LoadCustomerDashboardsSuccess(customerDashboardList));
    }

    public loadCustomerDashboardsError(message: string) {
        this.store.dispatch(new custDashboardActions.LoadCustomerDashboardsError(message));
    }

    public cancelLoadCustomerDashboards() {
        this.store.dispatch(new custDashboardActions.CancelLoadCustomerDashboards());
    }

    // Set Customer Dashboards
    public setCustomerDashboard(request: SetDashboardCustomerRequest) {
        this.store.dispatch(new custDashboardActions.SetCustomerDashboard(request));
    }

    public setCustomerDashboardSuccess(dashboard: CustomerDashboardInfo) {
        this.store.dispatch(new custDashboardActions.SetCustomerDashboardSuccess(dashboard));
    }

    public setCustomerDashboardError(message: string) {
        this.store.dispatch(new custDashboardActions.SetCustomerDashboardError(message));
    }

    public cancelSetCustomerDashboard() {
        this.store.dispatch(new custDashboardActions.CancelSetCustomerDashboard());
    }

    // Delete Customer Dashboard
    public deleteCustomerDashboard(request: SetDashboardCustomerRequest) {
        this.store.dispatch(new custDashboardActions.DeleteCustomerDashboard(request));
    }

    public deleteCustomerDashboardSuccess(dashboardCustomerId: number) {
        this.store.dispatch(new custDashboardActions.DeleteCustomerDashboardSuccess(dashboardCustomerId));
    }

    public deleteCustomerDashboardError(message: string) {
        this.store.dispatch(new custDashboardActions.DeleteCustomerDashboardError(message));
    }

    public cancelDeleteCustomerDashboard() {
        this.store.dispatch(new custDashboardActions.CancelDeleteCustomerDashboard());
    }
}


