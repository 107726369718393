import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share , startWith } from 'rxjs/operators';

import { muteFirst, CoolDownTypeInfo } from 'emr-ng-shared';

import { CoolDownTypeStateService } from './cool-down-type-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class CoolDownTypeService {
    constructor(
        private coolDownTypeStateSvc: CoolDownTypeStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.coolDownTypeStateSvc.isLoadRequired$;

    public isLoading$ = this.coolDownTypeStateSvc.isLoading$;

    public coolDownTypeList$: Observable<IListInfo<CoolDownTypeInfo>> = muteFirst(
        this.getCoolDownTypeListLoader().pipe(startWith(null)),
        this.coolDownTypeStateSvc.coolDownTypeList$
    );

    private getCoolDownTypeListLoader(): Observable<IListInfo<CoolDownTypeInfo>> {
        return this.coolDownTypeStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.coolDownTypeStateSvc.loadCoolDownTypes()),
            switchMap(() => this.getCoolDownTypeList()),
            tap(
                n => this.coolDownTypeStateSvc.loadCoolDownTypesSuccess(n),
                e => this.coolDownTypeStateSvc.loadCoolDownTypesError('')
            ),
            finalize(() => this.coolDownTypeStateSvc.cancelLoadCoolDownTypes()),
            catchError(() => {
                this.coolDownTypeStateSvc.cancelLoadCoolDownTypes();
                return of(emptyList());
            }),
            share()
        );
    }

    private getCoolDownTypeList(): Observable<IListInfo<CoolDownTypeInfo>> {
        return this.oversightSvc.GetCoolDownTypes().pipe(
            map(n => {
                return {
                    list: n.CoolDownTypes,
                    itemCount: n.CoolDownTypes.length,
                    isPaged: false
                };
            })
        );
    }
}





