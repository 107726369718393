import { TimePeriod } from 'emr-ng-shared';
import { ShipmentInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { DateRange } from 'emr-ng-shared';
import { ChartDataInfo } from 'emr-ng-shared';
import { ShipmentInfo2 } from 'emr-ng-shared';
import { defaultPaging } from 'app-modules/core/store/models/paging-state.interface';
import { getDefaultShipmentFilter } from 'app-modules/core/store/models/shipment-filter-state.interface';

export const initialState: IAppState = {
    auth: {
        authenticated: false,
        authToken: null,
        refreshToken: null,
        username: null,
        displayName: null,
        isEmersonAdmin: null,
        canCreateShipment: null,
        rememberMe: true,
        canAccessOversight: false,
        isLocusUserSupportAdmin: false,
        isLocusUser: false,
        error: null
    },
    pakSenseAuth: {
        authenticated: false,
        authToken: null,
        username: null,
        displayName: null,
        error: null
    },
    loading: false,
    customer: {
        customerList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        selectedContext: null,
        errorMessage: ''
    },
    shipment: {
        shipmentList: emptyList(),
        shipmentListComeFromApi:false,
        isLoadRequired: true,
        isAutoLoadRequired: false,
        isLoading: false,
        errorMessage: ''
    },
    shipmentFilter: getDefaultShipmentFilter(),
    shipmentSort: {
        sortList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: '',
        selectedSort: 'TripStateCode',
        isDescending: false
    },
    shipmentPaging: defaultPaging(),
    tracker: {
        trackerList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: null
    },
    sensorRange: {
        sensorRangeList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    location: {
        locationList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentDetail: {
        selectedShipment: null,
    },
    shipmentDetailAlert: {
        alertList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentDetailAlertPaging: defaultPaging(),
    shipmentDetailHistoricalShipment: {
        shipmentList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentDetailHistoricalShipmentPaging: defaultPaging(),
    shipmentDetailTracker: {
        summary: new ShipmentInfo(),
        alertInfo: emptyList(),
        chartData: emptyList(),
        chartDataInfo: new ChartDataInfo(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentDetailTrackerStateReport: {
        summary: new ShipmentInfo(),
        chartData: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentDetailTrackerStateChart: {
        summary: new ShipmentInfo2(),
        chartData: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentDetailSensorChart: {
        chartData: emptyList(),
        chartDataInfo: new ChartDataInfo(),
        sensorStateData: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentDetailTrackerStateChartSelectedTimePeriod: {
        isDefaultSelection: true,
        timePeriod: TimePeriod.All,
        dateRange: {
            startDateTime: null,
            endDateTime: null
        }
    },
    shipmentDetailTimePeriod: {
        timePeriodList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    createShipment: {
        linkedCustomer: null
    },
    // createShipmentLocation: {
    //     locationList: emptyList(),
    //     isLoadRequired: true,
    //     isLoading: false,
    //     errorMessage: ''
    // },
    // createShipmentTemplate: {
    //     shipmentTemplateList: emptyList(),
    //     isLoadRequired: true,
    //     isLoading: false,
    //     errorMessage: ''
    // },
    createShipmentTracker: {
        trackerList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: null
    },
    shipmentTemplate: {
        shipmentTemplateList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    vehicleGroups: {
        vehicleGroups: [],
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    alertType: {
        alertTypeList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    shipmentStatus: {
        shipmentStatusList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    timePeriod: {
        timePeriodList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    carrier: {
        carrierList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    timeZone: {
        timeZoneList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    // createShipmentSensorRange: {
    //     sensorRangeList: emptyList(),
    //     isLoadRequired: true,
    //     isLoading: false,
    //     errorMessage: ''
    // },
    createShipmentCarrier: {
        carrierList: [],
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    editShipment: {
        linkedCustomer: null
    },
    editShipmentSensorRange: {
        sensorRangeList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    preference: {
        temperatureUnits: 'C',
        temperatureUnitString: 'C',
        distanceUnits: 'miles',
        dateFormatEnum: 1,
        dateFormatString: 'MM/dd/yyyy',
        timeFormatEnum: 1,
        timeFormatString: 'HourFormat12',
        timeZoneId:'UTC',
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    coolDownType: {
        coolDownTypeList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    linkedCustomer: {
        linkedCustomerList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    pageSize: {
        pageSizeList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    businessRules: {
        BusinessRules: emptyList(),
        isLoadRequired: false,
        isLoading: false,
        errorMessage: ''
    },
    unAuthTrackers: {
        UnAuthTrackers: null,
        Customer: null
    },
    routes: {
        Routes: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    formattedStrings: {
        formattedStringList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    // routeDeviations: {
    //     RouteDeviations: emptyList(),
    //     isLoadRequired: true,
    //     isLoading: false,
    //     errorMessage: ''
    // },
    tripStartOptions: {
        tripStartOptionsList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    tripEndOptions: {
        tripEndOptionsList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    userSettings: {
        Value: null,
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    locationColorList: {
        locationColorList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    contactList: {
        contactList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    scheduledReportList: {
        scheduledReportList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    objectViewItemsList: {
        objectViewItemsList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    objectViewArgsItemList: [],
    pendingRequests: {
        items: []
    },
    linkedShipper: {
        linkedShipperList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''
    },
    dashboardList: {
        dashboardList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''

    },
    customerDashboardList: {
        customerDashboardList: emptyList(),
        isLoadRequired: true,
        isLoading: false,
        errorMessage: ''

    }
};

