
import { ServerRequestAction, ServerRequestCompleteAction } from "app-modules/core/store/actions/custom-actions";
import { DomoAccessTokenModel } from "../models/domo-access-token.model";
import * as actionIndex from 'app-modules/core/store/actions';

export const LOAD_DOMO_ACCESS_TOKEN = 'LOAD_ACCESS_TOKEN';
export const LOAD_DOMO_ACCESS_TOKEN_SUCCESS = 'LOAD_ACCESS_TOKEN_SUCCESS';
export const LOAD_DOMO_ACCESS_TOKEN_ERROR = 'LOAD_ACCESS_TOKEN_ERROR';
export const CANCEL_LOAD_DOMO_ACCESS_TOKEN = 'CANCEL_LOAD_ACCESS_TOKEN';

export class LoadDomoAccessToken implements ServerRequestAction {
    readonly type = LOAD_DOMO_ACCESS_TOKEN;
}

export class LoadDomoAccessTokenSuccess implements ServerRequestCompleteAction {
    readonly type = LOAD_DOMO_ACCESS_TOKEN_SUCCESS;
    readonly callingAction = LOAD_DOMO_ACCESS_TOKEN;
    constructor(
        public accessToken: DomoAccessTokenModel
    ) { }
}

export class LoadDomoAccessTokenError implements ServerRequestCompleteAction {
    readonly type = LOAD_DOMO_ACCESS_TOKEN_ERROR;
    readonly callingAction = LOAD_DOMO_ACCESS_TOKEN;
    constructor(
        public error: string
    ) { }
}

export class CancelDomoAccessToken implements ServerRequestCompleteAction {
    readonly type = CANCEL_LOAD_DOMO_ACCESS_TOKEN;
    readonly callingAction = LOAD_DOMO_ACCESS_TOKEN;
}

// Common Loader

export class DisplayLoader implements ServerRequestAction {
    readonly type = actionIndex.DISPLAY_LOADER;
}

export class CancelLoader implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOADER;
    readonly callingAction = actionIndex.DISPLAY_LOADER;
}

