import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ISensorChart } from 'app-modules/core/store/models/sensor-chart-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as sensorChartActions from 'app-modules/core/store/sensor-chart/sensor-chart.actions';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SensorChartStateService {
    constructor(private store: Store<IAppState>) { }

    private isSensorChartLoadingSub = new BehaviorSubject<boolean>(false);
    isSensorChartLoading$ = this.isSensorChartLoadingSub.asObservable().pipe();

    private isTempInfoLoadingSub = new BehaviorSubject<boolean>(false);
    isTempInfoLoading$ = this.isTempInfoLoadingSub.asObservable().pipe();

    // Selectors
    // // public sensorChartSensorChartSelector = ((state: IAppState) => state.sensorChart);
    // // public isSensorChartLoadRequiredSelector = createSelector(
    // //     this.sensorChartSensorChartSelector,
    // //     (state) => state.isLoadRequired
    // // );

    // Observables
    // // public sensorChart$ = this.store.select(this.sensorChartSensorChartSelector).pipe();
    // // public isSensorChartLoadRequired$ = this.store.select(this.isSensorChartLoadRequiredSelector).pipe();

    // Actions
    public loadSensorChart() {
        this.isSensorChartLoadingSub.next(true);
        this.store.dispatch(new sensorChartActions.LoadSensorChart());
    }

    public loadSensorChartSuccess(chartData: IListInfo<ISensorChart>) {
        this.isSensorChartLoadingSub.next(false);
        this.store.dispatch(new sensorChartActions.LoadSensorChartSuccess(chartData));
    }

    public loadSensorChartError(message: string) {
        this.isSensorChartLoadingSub.next(false);
        this.store.dispatch(new sensorChartActions.LoadSensorChartError(message));
    }

    public cancelLoadSensorChart() {
        this.isSensorChartLoadingSub.next(false);
        this.store.dispatch(new sensorChartActions.CancelLoadSensorChart());
    }

    public isTempInfoLoading(isLoading: boolean) {
        this.isTempInfoLoadingSub.next(isLoading);
    }
}
