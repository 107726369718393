<emr-modal headerTitle="Link Expired" i18n-headerTitle="@@link_expired" [bsModalRef]=bsModalRef [showCloseButton]=showCloseButton>
  <div modal-body>
      <div class="col-12 mt-2">
        <p i18n="@@link_expired_message">
          This link has expired, please login or enter the 10-digit serial number in the Track your shipment box below. Contact <a href="mailto:cargosupport@copeland.com">CargoSupport@copeland.com</a> if you need further assistance.
        </p>
      </div>
      <div class="col-12 text-center">
          <emr-button identifier="linkExpiredOK" cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok" (click)='onOkClick()'></emr-button>
      </div>
  </div>
</emr-modal>
