import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { AlertActionStatus, AlertEscalationLevel, Any, BusinessRuleType, muteFirst, ReportCategory, 
    ReportFormat, ReportType, ScheduledReports, ScheduleReportRequest, ScheduleReportResponse, 
    TripState, AlertSeverity } from 'emr-ng-shared';

import { ScheduleReportStateService } from './schedule-report-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { format_excel, format_pdf, in_progress, label_completed,
     label_fri, label_mon, label_sat, label_sun, label_thu, label_tue, label_wed, localeAM, localePM } from 'app-modules/core/consts/localization';
import { catchError, filter, finalize, map, share, startWith, switchMap, take, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { emptyList, IListInfo } from '../models/list-info-state.interface';
import * as _ from 'lodash';
import { reportTypeDesc } from 'app-modules/core/consts/report-type';
import { BusinessRulesService } from '../business-rules/business-rules.service';
import { alertEscalationLevelDesc, alertSeverityDesc, alertStatusDesc } from 'app-modules/core/consts/alert-type';
import { trackerTypesDesc, tripStatesDesc, tripStatusDesc } from 'app-modules/core/consts/trip-type';

export enum FleetTrakerType {
    Is3PL = 1,
    Non3PL = 0,
}

export enum TripActionStatus {
    TripStatus = 0,
    Unassigned = 1,
    Closed = 2
}

@Injectable()
export class ScheduleReportService implements OnDestroy {
    constructor(
        private ScheduleReportStateSvc: ScheduleReportStateService,
        private oversightSvc: OversightApiService,
    ) { }

    public DaysList = [
        { text: label_sun, value: 1 },
        { text: label_mon, value: 2 },
        { text: label_tue, value: 3 },
        { text: label_wed, value: 4 },
        { text: label_thu, value: 5 },
        { text: label_fri, value: 6 },
        { text: label_sat, value: 7 },
    ];

    exportFormats = [
        { text: format_excel, value: 0 },
        { text: format_pdf, value: 1 }
    ];

    reportTripStates = [
        { text: in_progress, value: TripState.InProgress },
        { text: label_completed, value: TripState.Completed }
    ];

    fleetTrackerType = [
        FleetTrakerType.Is3PL,
        FleetTrakerType.Non3PL
    ]

    tripStates = [
        TripState.Default,
        TripState.Pending,
        TripState.InProgress,
        TripState.Completed
    ]

    tripActionStatus = [
        TripActionStatus.TripStatus,
        TripActionStatus.Unassigned,
        TripActionStatus.Closed
    ]

    alertStatusList = [
        AlertActionStatus.Open ,
        AlertActionStatus.Closed 
    ];

    alertSeverityList = [
        AlertSeverity.Critical,
        AlertSeverity.Warning 
    ];

    alertEscalationsList = [
        AlertEscalationLevel.Level1,
        AlertEscalationLevel.Level2,
        AlertEscalationLevel.Level3,
        AlertEscalationLevel.Level4,
        AlertEscalationLevel.Level5,
        AlertEscalationLevel.Level6,
    ];

    private scheduledReportTypes = [
        ReportType.TripEndSummary,
        ReportType.ComprehensiveTripReport,
        ReportType.TripStopSummaryExtendedReport,
        ReportType.AlertStatus,
        ReportType.AlertTrackerFleet,
        ReportType.FleetCurrentStatus,
    ];

    ngOnDestroy() {
    }

    public fleetTrackerTypeList: any[] = this.getfleetTrackerTypeList(
        this.fleetTrackerType);

    getfleetTrackerTypeList(trackerTypes: FleetTrakerType[]){
        let trackerTypeList = [];
        trackerTypes.forEach(t => {
            trackerTypeList.push({ text: trackerTypesDesc[t], value: t })
        });
        return trackerTypeList;
    }

    public tripStateList: any[] = this.getTripStateList(
        this.tripStates);

    getTripStateList(states: TripState[]){
        let statesList = [];
        states.forEach(t => {
            statesList.push({ text: tripStatesDesc[t], value: t })
        });
        return statesList;
    }

    public tripStateCodeList: any[] = this.getTripStateCodeList(
        this.tripActionStatus);

    getTripStateCodeList(status: TripActionStatus[]){
        let statusList = [];
        status.forEach(t => {
            statusList.push({ text: tripStatusDesc[t], value: t })
        });
        return statusList;
    }

    public alertActionStatusList: any[] = this.getAlertStatusList(
        this.alertStatusList);

    getAlertStatusList(status: AlertActionStatus[]){
        let statusList = [];
        status.forEach(t => {
            statusList.push({ text: alertStatusDesc[t], value: t })
        });
        return statusList;

    }

    public severityList: any[] = this.getSeverityList(
        this.alertSeverityList);

    getSeverityList(severity: AlertSeverity[]) {
        let severityList = [];
        severity.forEach(t => {
            severityList.push({ text: alertSeverityDesc[t], value: t })
        });
        return severityList;
    }
    public escalationLevelList: any[] = this.getEscalationLevelList(
        this.alertEscalationsList);

    getEscalationLevelList(esclLevels: AlertEscalationLevel[]) {
        let escalationsList = [];
        esclLevels.forEach(t => {
            escalationsList.push({ text: alertEscalationLevelDesc[t], value: t })
        });
        return escalationsList;
    }

    public scheduledReportTypesList: any[] = this.getScheduledReportTypesList(
        this.scheduledReportTypes);

    getScheduledReportTypesList(reportTypes: ReportType[]) {
        let reportTypeList = [];
        reportTypes.forEach(t => {
            reportTypeList.push({ text: reportTypeDesc[t], value: t })
        });
        return reportTypeList;
    }

    getReportCategory(reportType: ReportType) {
        let reportCategory = null;
        switch (reportType) {
            case ReportType.ComprehensiveTripReport:
            case ReportType.TripEndSummary:
            case ReportType.TripStopSummaryExtendedReport:
            case ReportType.InboundOutboundReport:
                reportCategory = ReportCategory.TripReport.toString();
                break;
            case ReportType.AlertTrackerFleet:
            case ReportType.AlertStatus:
                reportCategory = ReportCategory.AlertReport.toString();
                break;
            case ReportType.FleetCurrentStatus:
            // case ReportType.FleetCurrentStatusAggregate:
            case ReportType.InProgressShipment:
                reportCategory = ReportCategory.FleetReport.toString();
                break;
        }
        return reportCategory;
    }

    getScheduleReportRequestDefaultParams(reportType: ReportType): ScheduleReportRequest {
        let request = new ScheduleReportRequest();
        if (reportType) {
            request.ReportType = reportType;
            request.ReportCategory = this.getReportCategory(reportType);
            request.EmailSubject =  reportTypeDesc[reportType];

            switch (reportType) {
                case ReportType.ComprehensiveTripReport:
                    request.ReportFormat = ReportFormat.Excel;
                    request.ReportParams = [
                        { Key: "fileName", Value: "ComprehensiveTripReport" },
                    ];
                    break;

                case ReportType.TripEndSummary:
                    break;
                case ReportType.TripStopSummaryExtendedReport:
                    request.ReportFormat = ReportFormat.Excel;
                    request.ReportParams = [
                        { Key: "fileName", Value: "TripStopSummaryExtendedReport" },
                    ];
                    break;
                case ReportType.InboundOutboundReport:
                    request.ReportFormat = ReportFormat.Excel;
                    break;
                case ReportType.AlertTrackerFleet:
                case ReportType.AlertStatus:
                    break;
                case ReportType.FleetCurrentStatus:
                    request.ReportParams = [
                        { Key: "fileName", Value: "CurrentStatusReport" },
                        { Key: "IsEmailedReport", Value: true },
                    ];
                    request.ReportFormat = ReportFormat.Excel;
                    break;
                // case ReportType.FleetCurrentStatusAggregate:
                //     request.ReportParams = [
                //         { Key: "IsEmailedReport", Value: true },
                //     ];
                //     break;
                case ReportType.InProgressShipment:
                    break;

            }
        }
        return request;
    }

    getScheduleReportReq(scheduledReport: ScheduledReports): ScheduleReportRequest {
        let request = new ScheduleReportRequest();
        request.ReportType = scheduledReport.ReportType;

        // OR-3880 Get Default Report Format
        let defaultFormat = this.getScheduleReportRequestDefaultParams(scheduledReport.ReportType)?.ReportFormat;

        request.ScheduledReportsId = scheduledReport.ScheduledReportsId;
        request.ReportName = scheduledReport.Description;
        request.ReportDays = scheduledReport.DOW;
        request.ReportTime = scheduledReport.Hour;
        request.IsValid = true;
        const reportParams = [];
        const reportParamsObj = JSON.parse(scheduledReport?.Parameters);
        
        request.ReportFormat = reportParamsObj?.ExportToExcel ? ReportFormat.Excel :
        ( defaultFormat == 0 ? defaultFormat : ReportFormat.PDF);
        const paramsObj = reportParamsObj?.Params;
        for (const [Key, Value] of Object.entries(paramsObj)) {
            reportParams.push({ Key, Value });
        }
        request.ReportParams = reportParams;
        request.EmailSubject = reportParamsObj?.EmailSubject;
        request.EmailBody = reportParamsObj?.EmailBody;
        return request;
    }

    public GetHoursList(showMeridian = false): Any[] {
        let hoursList = [];
        for (let i = 0; i < 24; i++) {
            const text = showMeridian ?
                ((i % 12 === 0) ? 12 : (i > 12 ? (i - 12) : i)) + ' ' + (i < 12 ? localeAM : localePM) :
                i;
            hoursList.push({ text, value: i });
        }
        return hoursList;
    }

    public isLoadRequired$ = this.ScheduleReportStateSvc.isLoadRequired$;

    public isLoading$ = this.ScheduleReportStateSvc.isLoading$;

    public ReloadScheduledReports() {
        this.ScheduleReportStateSvc.reloadScheduledReports();
    }

    ScheduleOrEmailReport(request: ScheduleReportRequest): Observable<ScheduleReportResponse> {
        return of(null).pipe(
            tap(() => {
                this.ScheduleReportStateSvc.scheduleReport();
            }),
            switchMap(() => this.ScheduleReport(request)),
            tap(
                n => this.ScheduleReportStateSvc.scheduleReportSuccess(request, false),
                e => this.ScheduleReportStateSvc.scheduleReportError()
            ),
            finalize(() => this.ScheduleReportStateSvc.cancelScheduleReport()),
            catchError(() => {
                return of(null);
            })
        );
    }

    private ScheduleReport(request: ScheduleReportRequest): Observable<ScheduleReportResponse> {
        return this.oversightSvc.ScheduleReport(request).pipe(
            map(n => n));
    }

    public scheduledReportList$: Observable<IListInfo<ScheduledReports>> = muteFirst(
        this.getScheduledReportListLoader().pipe(startWith(null)),
        this.ScheduleReportStateSvc.scheduledReportList$
    );

    public getFilteredReports(list: ScheduledReports[], reportTypes: ReportType[]): ScheduledReports[] {
        let filteredList  = [];
        if (reportTypes?.length > 0 && list) {
            reportTypes.forEach(t => {
                Array.prototype.push.apply(filteredList,  (list.filter(l => l.ReportType === t)));
                filteredList = _.sortBy(filteredList, ['Deleted', 'CreatedDate'], ['asc', 'desc'])
            });
        }
        return filteredList;
    }

    public groupedScheduledReportList$: Observable<any> =
        this.scheduledReportList$.pipe(map(k => {
            var groups = _.groupBy(k.list, 'ReportType');
            var result = [];
            Object.keys(groups).forEach(g => {
                const groupedItems = _.orderBy(groups[g], 'Deleted');
                result.push({
                    Group: g,
                    Values: groupedItems,
                    ActiveCount: _.takeWhile(groupedItems, ['Deleted', false])?.length
                });
            });
            result = _.orderBy(result, 'ActiveCount', 'desc');
            result = _.orderBy(result, 'CreatedDate', 'desc');
            return { groupArray: result, count: k.itemCount };
        })
        );

    private getScheduledReportListLoader(): Observable<IListInfo<ScheduledReports>> {
        return this.ScheduleReportStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.ScheduleReportStateSvc.loadScheduledReports()),
            switchMap(() => this.getScheduledReportList()),
            tap(
                n => this.ScheduleReportStateSvc.loadScheduledReportsSuccess(n),
                e => this.ScheduleReportStateSvc.loadScheduledReportsError('')
            ),
            finalize(() => this.ScheduleReportStateSvc.cancelLoadScheduledReports()),
            catchError(() => {
                this.ScheduleReportStateSvc.cancelLoadScheduledReports();
                return of(emptyList());
            }),
            share()
        );
    }

    private getScheduledReportList(): Observable<IListInfo<ScheduledReports>> {
        return this.oversightSvc.GetScheduledReportList().pipe(
            map(n => {
                return {
                    list: n?.ScheduledReportList,
                    itemCount: n?.ScheduledReportList?.length,
                    isPaged: false
                };
            })
        );
    }

    DeleteScheduleReport(scheduledReportId: string): Observable<ScheduleReportResponse> {
        return of(null).pipe(
            tap(() => {
                this.ScheduleReportStateSvc.scheduleReport();
            }),
            switchMap(() => this.DeleteScheduleReportByID(scheduledReportId)),
            tap(
                n => {
                    let request = new ScheduleReportRequest();
                    request.ScheduledReportsId = scheduledReportId;
                    this.ScheduleReportStateSvc.scheduleReportSuccess(request, true);
                },
                e => this.ScheduleReportStateSvc.scheduleReportError()
            ),
            finalize(() => this.ScheduleReportStateSvc.cancelScheduleReport()),
            catchError(() => {
                return of(null);
            })
        );
    }

    DeleteScheduleReportByID(scheduledReportId: string): Observable<ScheduleReportResponse> {
        return this.oversightSvc.DeleteScheduleReportByID(scheduledReportId).pipe(
            map(n => n));
    }
}
