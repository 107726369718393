import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomoUIService } from '../services/domo.ui.service';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import { BusinessRuleInfo, BusinessRuleType, CustomerDashboardInfo } from 'emr-ng-shared';
import { SIGMA_CLIENT_ID, SIGMA_EMBED_DASHBOARD_TOKEN, SIGMA_EMBED_PATH } from '../models/domo-config-consts';
import { CustomerDashboardService } from 'app-modules/core/store/customer-dashboard/customer-dashboard.service';
import { Subscription } from 'rxjs';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

@Component({
  selector: 'app-sigma-embed-dashboard',
  templateUrl: './sigma-embed-dashboard.component.html',
  styleUrls: ['./sigma-embed-dashboard.component.css']
})
export class SigmaEmbedDashboardComponent implements OnInit, OnDestroy {
  sigmaUrl: string = "";
  customerId: string;
  integrationID: string;
  customerDashboardListSub: Subscription;
  constructor(
    private domoUIService: DomoUIService,
    private businessRulesService: BusinessRulesService,
    private router: Router,
    private custDBSvc: CustomerDashboardService
  ) { }

  ngOnDestroy(): void {
    this.sigmaUrl = "";
    this.customerDashboardListSub?.unsubscribe();
  }
  ngOnInit(): void {
    this.customerId = JSON.parse(localStorage.getItem(environment.selectedCustomer));
    this.integrationID = SIGMA_EMBED_DASHBOARD_TOKEN;
    this.customerDashboardListSub = this.custDBSvc.customerDashboardList$.subscribe(k => {
      if(k.list.length > 0){
        const customerDashboardInfo = k?.list?.find(x => x.CustomerID === Number(this.customerId));
        if(customerDashboardInfo?.IntegrationID){
          this.integrationID = customerDashboardInfo.IntegrationID;
        }
        this.CheckBusinessRule();
      }
    });
  }

  CheckBusinessRule() {
    this.businessRulesService.businessRulesList$.pipe(take(1)).subscribe((businessRuleInfo: IListInfo<BusinessRuleInfo>) => {
      const hasDashboardLink = environment.showDashboardLink;
      const displayScorecardRule = businessRuleInfo.list?.find(
        br => br.BusinessRuleTypeCode === BusinessRuleType.DisplayScorecard
      )?.Enabled;

      if (hasDashboardLink && displayScorecardRule) {
        this.loadDashboard();
      } else {
        this.router.navigate(['']);
      }
    });
  }

  loadDashboard() {
    const clientId = SIGMA_CLIENT_ID;
    const localStorageData = JSON.parse(localStorage.getItem(environment.authPersistentData));
    const email = 'sam@test.com';
    const external_user_id = 'sam@test.com';
    const external_user_team = 'cargo_users';
    const account_type = 'EmbedEssential';
    const mode = 'userbacked';
    const hide_folder_navigation = true;
    const show_footer = false;
    const temperature = localStorageData ? localStorageData.TemperatureUnits : 'C';
    const urlWithParams = this.domoUIService.getEmbedURLwithParameters(this.integrationID, clientId, temperature, this.customerId, email, external_user_id, external_user_team, account_type, mode, hide_folder_navigation, show_footer);
    this.sigmaUrl = `${SIGMA_EMBED_PATH}${this.integrationID}${urlWithParams}`;
  }
}
