<div class="row">
  <div class="offset-md-4 col-md-8 br-r-1">
    <div class="text-center p-4 padding-sm-1">
      <h3 class="col-md-12">
        <span i18n="@@label_requestsignin">Please Sign In</span>
        <i class="fa fa-sign-in" aria-hidden="true"></i>
      </h3>
      <form class="pt-3">
        <div class="row">
          <div class="col-md-12">
            <emr-textbox identifier="txtUsername" placeholder="Username" i18n-placeholder="@@label_username"
              [(ngModel)]="username" name="txtUsername" cssClass="sign-in-input" appendIcon="fa fa-user"
              appendIconClass="input-icon" required>
              <emr-validation-message validatorName="required" message="Please enter username"
                i18n-message="@@validation_username_required"></emr-validation-message>
            </emr-textbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <emr-textbox identifier="txtPassword" placeholder="Password" i18n-placeholder="@@label_password"
              inputType="password" [(ngModel)]="password" name="txtPassword" cssClass="sign-in-input"
              appendIcon="fa fa-key" appendIconClass="input-icon" required minlength="4">
              <emr-validation-message validatorName="required" message="Please enter password."
                i18n-message="@@validation_password_required"></emr-validation-message>
              <emr-validation-message validatorName="minlength" message="Please enter at least 8 characters with one special character"
                i18n-message="@@validation_password_minlength"></emr-validation-message>
            </emr-textbox>
          </div>
        </div>
        <!-- <div class="row" *ngIf="settings == null || settings.hideRememberMe == null || settings.hideRememberMe != true">
          <div class="col-md-12">
            <emr-checkbox identifier="chkRememberMe" cssClass="sign-in-input" name="chkRememberMe" text="Remember me" i18n-text="@@label_rememberme"
              [(ngModel)]="rememberMe"></emr-checkbox>
          </div>
        </div>  -->
        <div class="row">
          <div class="col-md-12">
            <emr-checkbox identifier="termsConditions" name="termsConditions"  [(ngModel)]="isAcceptedTermsAndConditions" [setDisabled]="disabledTermsConditions" (onClick)="onClickTermsConditions()">
              <span i18n="@@link_terms_conditions">I agree to Copeland <a href='{{acceptedTermsAndConditionsURL}}' target='_blank'>Terms and Conditions</a></span>
            </emr-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 signinbuttondiv">
            <p class="col-md-12 mb-0">
              <emr-button identifier="signIn" buttonType="submit" cssClass="font-14 text-uppercase btn-lg btn-primary quick-link btn-block m-0 mt-2"
                buttonText="Sign In" i18n-buttonText="@@link_signin" buttonIcon="fa fa-unlock-alt" [setDisabled]="disabledSignIn"  [helpTip]="signInHelpTip"
                (onClick)="onSignInClick($event)"></emr-button>
            </p>
            <p><a class="forgotpasswordlink" i18n="@@link_forgotpassword" [routerLink]="['/forgotpassword']"
                routerLinkActive="active">Forgot Password</a></p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <span class="validationindicator">
              <app-error-message [error]="error"></app-error-message>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>