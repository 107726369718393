import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as actions from 'app-modules/core/store/actions/shipment-detail-tracker-state-chart-selected-time-period.actions';
import { ITimePeriod } from '../models/time-period.interface';
import * as shipmentDetailActions from 'app-modules/core/store/actions/shipment-detail.actions';
import { TimePeriod } from 'emr-ng-shared';

type Actions =
    authActions.SignOut |
    actions.ChangeShipmentDetailTrackerStateChartTimePeriod |
    shipmentDetailActions.SelectShipment;

export function ShipmentDetailTrackerStateChartSelectedTimePeriodReducer(
    state: ITimePeriod = initialState.shipmentDetailTrackerStateChartSelectedTimePeriod,
    action: Actions
): ITimePeriod {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailTrackerStateChartSelectedTimePeriod;

        case actionIndex.SHIPMENT_DETAIL_TRACKER_STATE_CHART_SELECTED_TIME_PERIOD:
            return {
                isDefaultSelection: false,
                timePeriod: action.filter.timePeriod,
                dateRange: {
                    startDateTime: action.filter.dateRange.startDateTime,
                    endDateTime: action.filter.dateRange.endDateTime
                },
            };

        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
            return {
                isDefaultSelection: true,
                timePeriod: TimePeriod.All,
                dateRange: {
                    startDateTime: null,
                    endDateTime: null
                },
            };

        default:
            return state;

    }
}

