import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith, take } from 'rxjs/operators';

import { muteFirst, GetTimePeriodsRequest, TimePeriodInfo, TimePeriod } from 'emr-ng-shared';

import { TimePeriodStateService } from 'app-modules/core/store/time-period/time-period-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { time_period_text } from 'app-modules/core/consts/time-period';

export enum TimePeriodPageFilter {
    ListViewFilter = 1,
    HistoricalFilter = 2
}

@Injectable()
export class TimePeriodService {
    constructor(
        private periodStateSvc: TimePeriodStateService,
        private oversightSvc: OversightApiService
    ) { }

    private shipmentFilterPeriods = [
        TimePeriod.LastTwoWeeks,
        TimePeriod.Last7Days,
        TimePeriod.Last90Days,
        TimePeriod.SpecificRange
        // Removing Last 30 days and this year as per Voss OR-2224 for Map view and Historical View
        // TimePeriod.Last30Days,
        // TimePeriod.ThisYear,

        // TimePeriod.SpecificRange OR-2261
    ];

    private historicalFilterPeriods = [
        TimePeriod.Last48Hours,
        TimePeriod.Last7Days,
        TimePeriod.LastTwoWeeks,
        TimePeriod.LastMonth,
        TimePeriod.SpecificRange
    ];

    private traxxTimePeriods = [
        TimePeriod.Trip,
        TimePeriod.All,
        TimePeriod.LastTwoWeeks,
        TimePeriod.Last30Days,
        TimePeriod.ThisYear,
        TimePeriod.SpecificRange
    ];

    private sensorChartTimePeriodsFilter = [
        TimePeriod.Trip,
        TimePeriod.Last30DaysOfActivity,
        TimePeriod.Today,
        TimePeriod.Yesterday,
        TimePeriod.ThisWeek,
        TimePeriod.LastWeek,
        TimePeriod.ThisMonth,
        TimePeriod.Last30Days,
        TimePeriod.LastMonth,
        TimePeriod.SpecificRange
    ];

    private reportTimePeriods = [
        TimePeriod.All,
        TimePeriod.Today,
        TimePeriod.Yesterday,
        TimePeriod.ThisWeek,
        TimePeriod.LastWeek,
        TimePeriod.ThisMonth,
        TimePeriod.Last30Days,
        TimePeriod.LastMonth,
        TimePeriod.SpecificRange
    ];

    public isLoadRequired$ = this.periodStateSvc.isLoadRequired$;
    // public isFullListLoadRequired$ = this.periodStateSvc.isFullListLoadRequired$;

    public isLoading$ = this.periodStateSvc.isLoading$;

    public timePeriodList$: Observable<IListInfo<TimePeriodInfo>> = muteFirst(
        this.getAllTimePeriodListLoader().pipe(startWith(null)),
        this.periodStateSvc.timePeriodList$
    );

    public shipmentFilterTimePeriodList$: Observable<IListInfo<TimePeriodInfo>> = this.getTimePeriodListLoader(
        this.shipmentFilterPeriods);

    public sensorTimePeriodList$: Observable<IListInfo<TimePeriodInfo>> = this.getTimePeriodListLoader(
        this.sensorChartTimePeriodsFilter);

    public traxxTimePeriods$: Observable<IListInfo<TimePeriodInfo>> = this.getTimePeriodListLoader(
        this.traxxTimePeriods);

    public reportTimePeriodList$: Observable<IListInfo<TimePeriodInfo>> = this.getTimePeriodListLoader(
        this.reportTimePeriods);

    public GetTimePeriodFilterOptions(pageFilter: TimePeriodPageFilter): Observable<IListInfo<TimePeriodInfo>> {
        let filter = [];
        switch (+pageFilter) {
            case TimePeriodPageFilter.ListViewFilter:
                filter = this.shipmentFilterPeriods;
                break;
            case TimePeriodPageFilter.HistoricalFilter:
                filter = this.historicalFilterPeriods;
                break;
        }
        return this.getTimePeriodListLoader(filter);
    }

    private getTimePeriodListLoader(timePeriods?: TimePeriod[]): Observable<IListInfo<TimePeriodInfo>> {
        return this.timePeriodList$.pipe(
            map(periods => {
                const filteredPeriods = emptyList();
                if (periods && periods.list) {
                    const filterCheck = timePeriods ? timePeriods : this.shipmentFilterPeriods;
                    filterCheck.forEach(element => {
                        const timePeriod = periods.list.find((tp) => tp.Period === element);
                        if (timePeriod) {
                            filteredPeriods.list.push(timePeriod);
                        }
                    });
                    filteredPeriods.itemCount = filteredPeriods.list.length;
                }
                return filteredPeriods;
            })
        );
    }

    private getAllTimePeriodListLoader(): Observable<IListInfo<TimePeriodInfo>> {
        return this.periodStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            switchMap(() => {
                this.periodStateSvc.loadTimePeriods();
                return this.getAllTimePeriodList();
            }),
            tap(
                n => this.periodStateSvc.loadTimePeriodsSuccess(n),
                e => this.periodStateSvc.loadTimePeriodsError(e)
            ),
            finalize(() => this.periodStateSvc.cancelLoadTimePeriods()),
            catchError(() => of(emptyList()))
        );
    }

    public getAllTimePeriodList(): Observable<IListInfo<TimePeriodInfo>> {
        const request = new GetTimePeriodsRequest();
        return this.GetTimePeriods(request);
    }
    
    public GetTimePeriods(request: GetTimePeriodsRequest): Observable<IListInfo<TimePeriodInfo>> {
        return of(null).pipe(map(n => {
                return this.timePeriodInfoList();
            }),
            share()
        );
    }

    timePeriodInfoList(): IListInfo<TimePeriodInfo> {
        const list = time_period_text.map(p => {
            return {
                Period: p.id,
                DisplayName: p.text
            };
        })

        return { list: <any>list, itemCount: list.length, isPaged: false };
    }

    // public GetTimePeriods(request: GetTimePeriodsRequest): Observable<IListInfo<TimePeriodInfo>> {
    //     return this.oversightSvc.GetTimePeriods(request).pipe(
    //         map(n => {
    //             return {
    //                 list: n.TimePeriodList,
    //                 itemCount: n.TimePeriodList.length,
    //                 isPaged: false
    //             };
    //         }),
    //         share()
    //     );
    // }

}





