<div class="col-12 my-2 mx-10">
    <form name="frmUpdateShipmentReq" #updateShipmentReq="ngForm" class="row" emrForm autocomplete="off">
        <div class="search-box">
            <emr-textbox ngDefaultControl identifier="txtUpdateShipmentReq"
                name="txtUpdateShipmentReq" label="Enter Serial Number"
                i18n-label="@@label_enter_serial_number" placeholder="Serial Number"
                i18n-placeholder="@@label_serial_number" [(ngModel)]="serialNumberReq"
                helpTip="Tracker Serial Number" i18n-helpTip="@@label_tracker_serial_number" required [inline]="true"
                [controlInline]="false" customValidMsgCss="offset-md-6 customValidMsgCss"
                minlength=10 [validationFullWidth]="true" (onFocus)="showErrorMessage = false"
                labelColumns=6 textBoxColumns="6">
                <emr-validation-message validatorName="required" message="Locus Traxx Serial # Required"
                    i18n-message="@@serial_required">
                </emr-validation-message>
                <emr-validation-message validatorName="minlength" message="Enter a valid Traxx Serial #"
                    i18n-message="@@serial_required">
                </emr-validation-message>
            </emr-textbox>
            <div class="row" *ngIf="showErrorMessage">
                <div class="offset-md-6 customValidMsgCss col-12">
                    <span class="validationindicator validation">
                        <app-error-message [error]="error"></app-error-message>
                      </span>
                </div>
            </div>
        </div>
        <div class="col-3 text-left pl-1">
            <emr-button identifier="btnSubmitShipmentReq" buttonType="submit" buttonText="Submit"
                i18n-buttonText="@@link_submit" cssClass="btn-outline-default mt-0 submit-btn" buttonIcon="fa fa-check"
                [setDisabled]="disableSubmitButton || !updateShipmentReq.valid" (onClick)="onSerialNumberSubmit()">
            </emr-button>
            <emr-button identifier="resetShipment" buttonType="reset" buttonText="Reset"
                i18n-buttonText="@@link_reset" cssClass="btn-outline-default mt-0 reset-btn"
                (onClick)="ResetForm()" buttonIcon="fa fa-repeat">
            </emr-button>
        </div>
    </form>
    <div class="row" *ngIf="selectedShipment">        
        <div class="col-12 shipmentDetails">
            <app-shipment-detail [Shipment]="selectedShipment"></app-shipment-detail>
        </div>
    </div>
</div>