<div class="w-100">
    <fieldset [disabled]="(isChartLoading$ | async)">
        <div class="row mt-2">
            <div class="col-md-12 ml-2 displayInherit">
                <div class="width-percent-80 displayInherit"
                    [ngClass]="{ 'd-none': 'shipmentSensorList&&shipmentSensorList.length>1 || getShipmentResponseData?.Shipment?.Alerts?.length > 0' }">
                    <div class="m-w-p-35" *ngIf="shipmentSensorList&&shipmentSensorList.length>1">
                        <emr-checkboxlist identifier="chkListSensorList" label="Sensors" i18n-label="@@label_sensors"
                            name="chkListSensorList" [(ngModel)]="detailsCommonData.selectedSensors"
                            labelCssClass="pr-3" [options]="shipmentSensorList" [showCheckBoxCount]=true
                            optionCountField="count" [inline]=true [removeAlignmentGap]=true
                            (onChange)="onSensorRangeChange($event)">
                        </emr-checkboxlist>
                    </div>
                    <div *ngIf="getShipmentResponseData?.Shipment?.Alerts?.length > 0&&!isSHKSelected">
                        <emr-checkbox identifier="DisplayAlerts" class="d-inline-block display-alerts"
                            name="DisplayAlerts" i18n-text="@@label_display_alerts" text="Display Alerts"
                            [(ngModel)]="detailsCommonData.showAlertsAndEvents" cssClass="color-regular"></emr-checkbox>
                        <span class="checkbox-count">{{alertCount}}</span>
                    </div>
                    <!-- <div class="d-flex align-items-end" *ngIf="shockAlertCount > 0&&isSHKSelected">
                        <emr-checkbox identifier="DisplayAlerts" class="d-inline-block display-alerts"
                            name="DisplayAlerts" i18n-text="@@label_display_shock_alerts" text="Display Shock Alerts"
                            [(ngModel)]="detailsCommonData.showAlertsAndEvents" cssClass="color-regular">
                            <span class="checkbox-count">{{shockAlertCount}}</span></emr-checkbox>
                    </div> -->
                </div>
                <div class="width-percent-20">
                    <ul class="nav justify-content-end btn-list">
                        <li class="nav-item">
                            <emr-button identifier="minMaxChart" cssClass="btn btn-link px-1"
                                buttonText="Min/Max Chart Values" i18n-buttonText="@@label_min_max_chart_values"
                                (onClick)="onMinMaxChartValuesClick()" *ngIf="!IsUnAuthenticated">
                            </emr-button>
                        </li>
                        <li class="nav-item">
                            <div class="label-css py-1">
                                <ng-container *ngIf="(this.minvalueYOverride && maxvalueYOverride);">
                                    <span>({{inputMinvalueYOverride}}{{tempUnits}} &ndash;
                                        {{inputMaxvalueYOverride}}{{tempUnits}})</span>
                                </ng-container>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="ml-3 mt-25">
                <span *ngIf="noSensorsMessage()" class="label-css" i18n="@@label_No_Sensors_Attached">
                    No sensors
                </span>
            </div>
            <div class="ml-3" *ngIf="shipmentSensorList&&shipmentSensorList.length>1">
                <span *ngIf="shipmentSensorList  && detailsCommonData?.selectedSensors?.length === 0"
                    class="sensorchart-validation pl-0" i18n="@@label_select_min_one_sensor">
                    Please select at least one sensor
                </span>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-10">
                <span class="mr-2 label-css" i18n="@@label_background_color">Background Color: </span>
                <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" id=background_color name="background_color"
                        [value]="0" [(ngModel)]="detailsCommonData.selectedColor" />
                    <label class="custom-control-label" for="background_color" i18n="@@period_None">None</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline" *ngIf="isTemperatureSelected">
                    <input class="custom-control-input" type="radio" id="background_color1" name="background_color"
                        [value]="1" [(ngModel)]="detailsCommonData.selectedColor" />
                    <label class="custom-control-label" for="background_color1"
                        i18n="@@label_show_sensor_range_background_color">
                        Show Sensor Range Background Color
                    </label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input class="custom-control-input" type="radio" id="background_color2" name="background_color"
                        [value]="2" [(ngModel)]="detailsCommonData.selectedColor" />
                    <label class="custom-control-label" for="background_color2"
                        i18n="@@label_show_location_background_color">
                        Show Location Background Color
                    </label>
                </div>

            </div>

            <!-- <div class="col-1"> // Hiding for MVP
            <div *ngIf="shipmentSensorList && shipmentSensorList.length > 0">
                <input class="exp-img" type="image" style="float: right;height: 30px;width:30px;border-width:0px;"
                    title="PDF" i18n-title="@@label_pdf"
                    (click)="onClickExportPDF()" />
            </div>
        </div>-->
            <div class="col-2">
                <div *ngIf="shipmentSensorList && shipmentSensorList.length > 0" class="report-buttons text-right">
                    <div>
                        <emr-button identifier="DownloadPDF"
                            cssClass="btn-outline-default mt-0 custom-padding down-btn reset-filter-btn pdf-btn"
                            buttonIcon="fa fa-file-pdf-o" (onClick)="onClickExportPDF()"
                            helpTip="Download sensor data in pdf format"
                            i18n-helpTip="@@tooltip_download_sensordata_pdf" helpTipPosition="left" container="body"
                            [setDisabled]="(detailsCommonData?.selectedSensors?.length == 0)||IsPDFDownloadInProgress">
                        </emr-button>
                    </div>
                    <div>
                        <emr-button identifier="DownloadExcel"
                            cssClass="btn-outline-default mt-0 custom-padding down-btn reset-filter-btn"
                            buttonIcon="fa fa-file-excel-o" (onClick)="onExcelExportClick()"
                            helpTip="Download sensor data in excel format"
                            i18n-helpTip="@@tooltip_downlaod_sensordata_excel" helpTipPosition="left" container="body"
                            [setDisabled]="(detailsCommonData?.selectedSensors?.length == 0)||IsDownloadInProgress">
                        </emr-button>
                    </div>
                    <div>
                        <emr-button identifier="SendEmail" cssClass="btn-outline-default mt-0 custom-padding down-btn"
                            buttonIcon="fa fa-envelope-o" i18n-buttonText="@@label_email_report"
                            (onClick)="onSendEmailReport()" helpTip="Email Trip State report" helpTipPosition="left"
                            container="body" i18n-helpTip="@@tooltip_tripstate_email" *ngIf="!IsUnAuthenticated"
                            [setDisabled]="detailsCommonData?.selectedSensors?.length == 0">
                        </emr-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12 text-center">
                <app-local-loading-indicator *ngIf="((isChartBinding) || (isChartLoading$ | async))" width="100%"
                    height="100%" overrideLeft="0" applyOverlay="true"></app-local-loading-indicator>
                <div class="chart-div">
                    <div *ngIf="!((isChartBinding) || (isChartLoading$ | async))" class="row my-1">
                        <div class="col-12 text-center clearfix ">
                            <ng-container
                                *ngIf="(detailsCommonData?.selectedSensors?.length > 0 && !noChartDataMsg && !((isChartBinding) || (isChartLoading$ | async))); else tempRange">
                                <span i18n="@@no_data_available">No data available</span>
                            </ng-container>
                        </div>
                        <ng-template #tempRange>
                            <div *ngIf="this.minvalueYOverride && maxvalueYOverride && (showTemp || showProbe)"
                                class="col-12 text-center">
                                <span i18n="@@label_temperature_range">Temperature Range</span>
                                (<span i18n="@@label_min_max">Min/Max</span>):
                                <b><span *ngIf="this.minvalueYOverride && maxvalueYOverride">
                                        {{inputMinvalueYOverride}}{{tempUnits}} &mdash;
                                        {{inputMaxvalueYOverride}}{{tempUnits}}</span></b>
                            </div>
                        </ng-template>
                        <div *ngIf="error!=null" class="col-12 clearfix text-center">
                            <div class="col-md-12">
                                <span class="validationindicator">
                                    <div class="sensorchart-validation my-1">
                                        <div>{{error}}</div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>

                    <app-sensor-chart [SeriesVisibility]="detailsCommonData?.selectedSensors"
                        [SelectPlotBands]="detailsCommonData?.selectedColor" [chartDateTimeFormat]="chartDateTimeFormat"
                        [setTempAxis]="hasTempAxis" [showEvents]='detailsCommonData?.showAlertsAndEvents'
                        [LeftYAxisMinMax]="overrideAxisRange" [chartData]="shipmentChartData"
                        [locationLocale]="'Location'" [DateRange]="chartDataRange"
                        i18n-locationLocale="@@label_location" noLatLon="No lat/lon values"
                        i18n-noLatLon="@@message_no_latlon" translatedStartTime="Trip Actual Start Time"
                        i18n-translatedStartTime="@@trip_actual_start_time"  translatedEndTime="Trip Actual End Time"
                        i18n-translatedEndTime="@@trip_actual_end_time">
                    </app-sensor-chart>
                    <div class="legend-box">
                        <div *ngIf="detailsCommonData?.selectedSensors?.length > 0" class="row legend-div">
                            <ul class="nav justify-content-left btn-list">
                                <li class="nav-item">
                                    <div *ngIf="showPlotLines && plotLines?.LowCriticalString"
                                        class="legend-padding text-left">
                                        <div class="legend-color-style dotted" style="border-color: #00008b;"></div>
                                        <span i18n="@@low_critical">Low Critical</span>
                                        <span *ngIf="plotLines?.LowCriticalString" class="legend-value">
                                            &nbsp;{{plotLines?.LowCriticalString}}</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showPlotLines && plotLines?.LowWarnString" class="legend-padding text-left">
                                        <div class="legend-color-style dotted" style="border-color: #add8e6;"></div>
                                        <span i18n="@@low_warning">Low Warning</span>
                                        <span *ngIf="plotLines?.LowWarnString" class="legend-value">
                                            &nbsp;{{plotLines?.LowWarnString}}</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showPlotLines && plotLines?.HighCriticalString"
                                        class="legend-padding text-left">
                                        <div class="legend-color-style dotted" style="border-color: #ff0000;"></div>
                                        <span i18n="@@high_critical">High Critical</span>
                                        <span *ngIf="plotLines?.HighCriticalString" class="legend-value">
                                            &nbsp;{{plotLines?.HighCriticalString}}</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showPlotLines && plotLines?.HighWarnString" class="legend-padding text-left">
                                        <div class="legend-color-style dotted" style="border-color: #ffa500;"></div>
                                        <span i18n="@@high_warning">High Warning</span>
                                        <span *ngIf="plotLines?.HighWarnString" class="legend-value">
                                            &nbsp;{{plotLines?.HighWarnString}}</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showTemp" class="legend-padding text-left">
                                        <div class="legend-color-style" style="background-color:#000000;"></div>
                                        <span i18n="@@label_temperature">Temperature</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showHumidity" class="legend-padding text-left">
                                        <div class="legend-color-style" style="background-color:#0000ff;"></div>
                                        <span i18n="@@label_humidity">Humidity</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showProbe" class="legend-padding text-left">
                                        <div class="legend-color-style" style="background-color:#62bb46;"></div>
                                        <span i18n="@@label_probetemp">Probe Temp</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showCO2" class="legend-padding text-left">
                                        <div class="legend-color-style" style="background-color:#ff00ff;"></div>
                                        <span i18n="@@label_carbondioxide"> CO2</span>
                                    </div>
                                </li>
                                <li class="nav-item">
                                    <div *ngIf="showSHK" class="legend-padding text-left">
                                        <div class="legend-color-dot-style" style="background-color:#434348;"></div>
                                        <span i18n="@@label_shockdetected">Shock Detected</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="row legend-div px-2 mt-2"
                            *ngIf="detailsCommonData?.selectedSensors?.length > 0 && detailsCommonData?.selectedColor === 2 && plotLabels?.length > 0">
                            <div *ngFor="let label of plotLabels" class="col-auto pl-2 pr-4 text-left">
                                <div class="legend-color-style"
                                    [style.background-color]='label.LocationColor | lowercase'>
                                </div>
                                <span>{{label.LocationName}}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </fieldset>
    <div class="col-md-12 mt-2">
        <app-shipment-route [Shipment]="shipment" [displayTimePeriod]=false [ShowGeoFence]="ShowGeoFence"
            [TraxxList]="TraxxList" [TraxxCopy]="TraxxCopy" [detailsCommonData]="detailsCommonData"
            [DateRange]="selectedDateRange" [isFromSensorChart]=true [isFromTimeline]=false cssClass="height-400">
        </app-shipment-route>
    </div>
</div>

<ng-template #errorModal>
    <emr-modal headerTitle="Download" i18n-headerTitle="@@link_download" [bsModalRef]=bsModalRef>
        <div modal-body>
            <div>
                <p>{{ errorMessage }}</p>
            </div>
            <emr-button identifier="sensorChartOk" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok"
                (click)='onOkClick()'>
            </emr-button>
        </div>
    </emr-modal>
</ng-template>