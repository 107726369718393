/// <reference path='../../../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts' />
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'app-modules/core/core.module';

import { RouteEditorRoutingModule } from './route-editor-routing.module';
import { RouteEditorComponent } from './components/route-editor/route-editor.component';
import { EmrNgControlsModule, EmrNgMapApiModule } from 'emr-ng-shared';
import { RouteDeviationComponent } from './components/route-deviation/route-deviation.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [
    RouteEditorComponent,
    RouteDeviationComponent
  ],
  imports: [
    CommonModule,
    RouteEditorRoutingModule,
    EmrNgControlsModule,
    CoreModule,
    EmrNgMapApiModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot()
  ],
  exports: [
  ]
})
export class RouteEditorModule { }
