import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { default_email_message, label_comprehensive_trip_report, label_email_report, label_schedule_report, link_save } from 'app-modules/core/consts/localization';
import { STORE_KEYS } from 'app-modules/core/consts/values';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { ScheduledReportParams } from 'app-modules/core/models/scheduled-report-params.model';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { ContactList, ContactListFromEmails, ContactService } from 'app-modules/core/store/contacts/contact.service';
import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { ScheduleReportService } from 'app-modules/core/store/schedule-report/schedule-report.service';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { Any, DateTimeObject, ReportFormat, ScheduleReportRequest } from 'emr-ng-shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ErrorMessageResponseComponent, EventArgs } from '../error-message-response/error-message-response.component';

@Component({
  selector: 'app-report-scheduler',
  templateUrl: './report-scheduler.component.html',
  styleUrls: ['./report-scheduler.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportSchedulerComponent implements OnInit {
  @ViewChild(NgForm) frmScheduleReport: NgForm;

  // modal
  @Input() public sendNow: boolean = null;
  @Input() public showFormatButton = true;
  showEmailDetails = true;

  private _request;
  @Input() public set request(val: ScheduleReportRequest) {
    if(this.reportParams){
      val.EmailSubject = this.reportParams.EmailSubject;
    }
    this._request = val;
  }
  public get request(): ScheduleReportRequest {
    return this._request;
  }

  @Input() public isNewScheduleReport = false;

  private _reportParams;
  @Input() public set reportParams(val: ScheduledReportParams) {
    if(val?.EmailSubject && this.request) {
      this.request.EmailSubject = val.EmailSubject;
    }
    this._reportParams = val;
  }
  public get reportParams(): ScheduledReportParams {
    return this._reportParams;
  }

  btnScheduleText: string;
  @Input() selectedEmails: string;
  selectedRecentContacts: ContactListFromEmails[];
  selectedContacts: ContactListFromEmails[];
  isRecentContactsCollapsed = true;
  recentContactsList: ContactListFromEmails[];
  isContactsCollapsed = true;
  contactsList$: Observable<ContactList[]>;
  HoursList: Any[];
  DaysList: Any[];
  exportFormats: Any[];
  dateTimeObject: DateTimeObject = new DateTimeObject();
  userPreferenceSubscription: Subscription;
  error: string;
  public isValidEmail: boolean = false;
  headerTitle: string;
  authStateSub: Subscription;

  @Output() public onValidate = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public contactSvc: ContactService,
    public reportSvc: ScheduleReportService,
    public preferenceSvc: PreferenceService,
    private modalSvc: BsModalService,
    private authSvc: AuthService,
  ) { }

  ngOnInit() {
    this.headerTitle = this.sendNow ? label_email_report : label_schedule_report;
    // this.request = new ScheduleReportRequest();
    this.exportFormats = this.reportSvc.exportFormats;
    this.DaysList = this.reportSvc.DaysList;
    this.request.EmailNow = this.sendNow;

    if (this.isNewScheduleReport && this.showFormatButton && !this.request.ReportFormat) {
      this.request.ReportFormat = ReportFormat.PDF;
    }

    this.btnScheduleText = !this.isNewScheduleReport ? link_save : label_schedule_report;
    if(this.showEmailDetails && this.isNewScheduleReport) {
      this.request.EmailBody = default_email_message;
    }
    if(!this.request?.ScheduledReportsId){
      this.authStateSub = this.authSvc.authState$.pipe((take(1))).subscribe(n => {
        this.selectedEmails = n?.username;
      });
    }

    const recentContacts = StoreManager.GetArrayValue(STORE_KEYS.recentContacts);
    if (recentContacts) {
      this.recentContactsList = recentContacts.map(e => new ContactListFromEmails(e));
    }

    this.userPreferenceSubscription = this.preferenceSvc.getDateTimeObject().pipe(take(1)).subscribe(k => {
      this.HoursList = this.reportSvc.GetHoursList(k?.showMeridian);
    });
    this.contactsList$ = this.contactSvc.contactList$.pipe(map(n => n.list.map(item => new ContactList(item))));
  }

  ngOnDestroy() {
    if (this.authStateSub) {
      this.authStateSub.unsubscribe();
    }
    if (this.userPreferenceSubscription) {
      this.userPreferenceSubscription.unsubscribe();
    }
  }
  validateEmail() {
    this.isValidEmail = false;

    this.isValidEmail = !!(this.selectedEmails?.length > 0 || (this.selectedRecentContacts?.length > 0) || this.selectedContacts?.length > 0);

    return this.isValidEmail;
  }

  private reValidationForm = () => {
    for (const key in this.frmScheduleReport?.controls) {
      this.frmScheduleReport.controls[key].markAsDirty();
      this.frmScheduleReport.controls[key].updateValueAndValidity();
    }
  }

  onScheduleReportClick(sendEmailNow = false) {
    this.reValidationForm();
    if (this.reportParams) {
      this.request.IsValid= this.reportParams.IsValid;
      this.request.ReportParams = this.reportParams.ReportParams;
    }

    if (!this.reportParams?.IsValid) {
      this.onValidate.emit(this.reportParams?.IsValid);
    }

    let emailIDs = new Set<string>();
    if (this.request.IsValid && this.validateEmail() && this.frmScheduleReport.valid ) {
      if (this.selectedEmails?.length > 0) {
        this.selectedEmails.trim().split(',').forEach(e => emailIDs.add(e));
      }

      if (this.selectedRecentContacts?.length > 0) {
        this.selectedRecentContacts.forEach(e => emailIDs.add(e.value));
      }

      if (this.selectedContacts?.length > 0) {
        this.selectedContacts.forEach(e => emailIDs.add(e.value));
      }

      this.request.EmailIDs = Array.from(emailIDs).join(',');
      const recentContacts = StoreManager.GetArrayValue(STORE_KEYS.recentContacts);
      let prevContacts = [];
      if (recentContacts) {
        prevContacts = recentContacts;
      }
      const storeContacts = new Set([...Array.from(emailIDs), ...prevContacts]);
      StoreManager.StoreValue(STORE_KEYS.recentContacts, Array.from(storeContacts).join(','),
        // tslint:disable-next-line: no-bitwise
        StoreOpts.LocalStorage | StoreOpts.Cookie | StoreOpts.StoreManager);


      this.reportSvc.ScheduleOrEmailReport(this.request).pipe(take(1)).subscribe((n) => {
        if (n) {
          this.openSubmitResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage, n.ErrorCode == 0 ? true : false, this.headerTitle);
        }
      }, (e: IErrorInfo) => {
        this.openSubmitResponse(true, e.message, false, this.headerTitle)
      });
    }
  }

  onCancelClick() {
    this.bsModalRef.hide();
  }

  openSubmitResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null, ) {
    const bsModalRef = this.modalSvc.show(
      ErrorMessageResponseComponent,
      {
        initialState: {
          hasError,
          errorMessage,
          closeOnOk,
          debugErrorMessage,
          sucessMessage: !hasError ? errorMessage : null,
          headerTitle: headerTitle
        },
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onResponseOk(n));
  }

  onResponseOk(args: EventArgs) {
    args.modalRef.hide();

    if (!args.hasError) {
      if (args.closeOnOk && this.bsModalRef) {
        this.bsModalRef.hide();
      } else {
        // Do Nothing
      }
    }
  }
}
