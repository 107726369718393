import { Action } from '@ngrx/store';

import { Contact, DeleteGeofenceRequest, GetLocationBoundaryRequest, SaveLocationContactRequest, SetLocationRequest } from 'emr-ng-shared';
import { LocationInfo } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions'; 
import { LocationColor } from 'emr-ng-shared/lib/services/emr-oversight/models/location-color-model';
import { DeleteLocationRequest } from 'emr-ng-shared/lib/services/emr-oversight/models/delete-location-request.model';

export class LoadLocationList implements ServerRequestAction {
    readonly type = actionIndex.LOAD_LOCATIONS;
}

export class LoadLocationListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_LOCATIONS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_LOCATIONS;
    constructor(public locationList: IListInfo<LocationInfo>) { }
}

export class LoadLocationListError implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.LOAD_LOCATIONS;
    readonly type = actionIndex.LOAD_LOCATIONS_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadLocationList implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.LOAD_LOCATIONS;
    readonly type = actionIndex.CANCEL_LOAD_LOCATIONS;
}

export class CreateLocation implements ServerRequestAction {
    readonly type = actionIndex.CREATE_LOCATION;
    constructor(public request: SetLocationRequest) { }
}

export class CreateLocationSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_LOCATION_SUCCESS;
    readonly callingAction = actionIndex.CREATE_LOCATION;
    constructor(public location: LocationInfo) { }
}

export class CreateLocationError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_LOCATION_ERROR;
    readonly callingAction = actionIndex.CREATE_LOCATION;
    constructor(public message: string) { }
}

export class CancelCreateLocation implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_CREATE_LOCATION;
    readonly callingAction = actionIndex.CREATE_LOCATION;
}

export class ReloadLocations {
    readonly type = actionIndex.RELOAD_LOCATIONS;
}

export class LoadLocationColorList implements Action {
    readonly type = actionIndex.LOAD_LOCATION_COLOR_LIST;
}

export class LoadLocationColorListSuccess implements Action {
    readonly type = actionIndex.LOAD_LOCATION_COLOR_LIST_SUCCESS;
    constructor(public locationColorList: IListInfo<LocationColor>) { }
}

export class LoadLocationColorListError implements Action {
    readonly type = actionIndex.LOAD_LOCATION_COLOR_LIST_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadLocationColorList implements Action {
    readonly type = actionIndex.CANCEL_LOAD_LOCATION_COLOR_LIST;
}

export class ValidateLocation implements ServerRequestAction {
    readonly type = actionIndex.VALIDATE_LOCATION;
    constructor(public request: SetLocationRequest) { }
}

export class ValidateLocationSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.VALIDATE_LOCATION_SUCCESS;
    readonly callingAction = actionIndex.VALIDATE_LOCATION;
    constructor(public location: LocationInfo) { }
}

export class ValidateLocationError implements ServerRequestCompleteAction {
    readonly type = actionIndex.VALIDATE_LOCATION_ERROR;
    readonly callingAction = actionIndex.VALIDATE_LOCATION;
    constructor(public message: string) { }
}

export class CancelValidateLocation implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_VALIDATE_LOCATION;
    readonly callingAction = actionIndex.VALIDATE_LOCATION;
}

// Delete Location Geofence
export class DeleteLocationGeofence implements ServerRequestAction {
    readonly type = actionIndex.DELETE_LOCATION_GEOFENCE;
    constructor(public request: DeleteGeofenceRequest) { }
}

export class DeleteGeofenceSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_LOCATION_GEOFENCE_SUCCESS;
    readonly callingAction = actionIndex.DELETE_LOCATION_GEOFENCE;
    constructor(public location: DeleteGeofenceRequest) { }
}

export class DeleteGeofenceError implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_LOCATION_GEOFENCE_ERROR;
    readonly callingAction = actionIndex.DELETE_LOCATION_GEOFENCE;
    constructor(public message: string) { }
}

export class CancelDeleteGeofence implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_DELETE_LOCATION_GEOFENCE;
    readonly callingAction = actionIndex.DELETE_LOCATION_GEOFENCE;
}

// Get Location Boundary
export class GetLocationBoundary implements ServerRequestAction {
    readonly type = actionIndex.Get_LOCATION_BOUNDARY;
    constructor(public request: GetLocationBoundaryRequest) { }
}

export class GetLocationBoundarySuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.Get_LOCATION_BOUNDARY_SUCCESS;
    readonly callingAction = actionIndex.Get_LOCATION_BOUNDARY;
    constructor(public location: LocationInfo) { }
}

export class GetLocationBoundaryError implements ServerRequestCompleteAction {
    readonly type = actionIndex.Get_LOCATION_BOUNDARY_ERROR;
    readonly callingAction = actionIndex.Get_LOCATION_BOUNDARY;
    constructor(public message: string) { }
}

export class CancelGetLocationBoundary implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_Get_LOCATION_BOUNDARY;
    readonly callingAction = actionIndex.Get_LOCATION_BOUNDARY;
}


// Save Location Geofence
export class SaveLocationGeofence implements ServerRequestAction {
    readonly type = actionIndex.SAVE_LOCATION_GEOFENCE;
    constructor(public request: SetLocationRequest) { }
}

export class SaveGeofenceSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_LOCATION_GEOFENCE_SUCCESS;
    readonly callingAction = actionIndex.SAVE_LOCATION_GEOFENCE;
    constructor(public location: SetLocationRequest) { }
}

export class SaveGeofenceError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_LOCATION_GEOFENCE_ERROR;
    readonly callingAction = actionIndex.SAVE_LOCATION_GEOFENCE;
    constructor(public message: string) { }
}

export class CancelSaveGeofence implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SAVE_LOCATION_GEOFENCE;
    readonly callingAction = actionIndex.SAVE_LOCATION_GEOFENCE;
}


// Location Contact
export class GetLocationContact implements ServerRequestAction {
    readonly type = actionIndex.GET_LOCATION_CONTACT;
    constructor(public request: SaveLocationContactRequest) { }
}

export class GetLocationContactSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.GET_LOCATION_CONTACT_SUCCESS;
    readonly callingAction = actionIndex.GET_LOCATION_CONTACT;
    constructor(public locationID: number, public contactsList: Contact[]) { }
}

export class GetLocationContactError implements ServerRequestCompleteAction {
    readonly type = actionIndex.GET_LOCATION_CONTACT_ERROR;
    readonly callingAction = actionIndex.GET_LOCATION_CONTACT;
    constructor(public message: string) { }
}

export class CancelGetLocationContact implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_GET_LOCATION_CONTACT;
    readonly callingAction = actionIndex.GET_LOCATION_CONTACT;
}

// Delete Location Contact
export class DeleteLocationContact implements ServerRequestAction {
    readonly type = actionIndex.DELETE_LOCATION_CONTACT;
    constructor(public request: SaveLocationContactRequest) { }
}

export class DeleteLocationContactSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_LOCATION_CONTACT_SUCCESS;
    readonly callingAction = actionIndex.DELETE_LOCATION_CONTACT;
    constructor(public request: SaveLocationContactRequest) { }
}

export class DeleteLocationContactError implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_LOCATION_CONTACT_ERROR;
    readonly callingAction = actionIndex.DELETE_LOCATION_CONTACT;
    constructor(public message: string) { }
}

export class CancelDeleteLocationContact implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_DELETE_LOCATION_CONTACT;
    readonly callingAction = actionIndex.DELETE_LOCATION_CONTACT;
}

// Set Location Contact

export class SetLocationContact implements ServerRequestAction {
    readonly type = actionIndex.SET_LOCATION_CONTACT;
    constructor(public request: SaveLocationContactRequest) { }
}

export class SetLocationContactSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_LOCATION_CONTACT_SUCCESS;
    readonly callingAction = actionIndex.SET_LOCATION_CONTACT;
    constructor(public request: SaveLocationContactRequest, public contact: Contact) { }
}

export class SetLocationContactError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_LOCATION_CONTACT_ERROR;
    readonly callingAction = actionIndex.SET_LOCATION_CONTACT;
    constructor(public message: string) { }
}

export class CancelSetLocationContact implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SET_LOCATION_CONTACT;
    readonly callingAction = actionIndex.SET_LOCATION_CONTACT;
}

export class  UpdateLocationContact implements ServerRequestCompleteAction {
    readonly type = actionIndex.UPDATE_LOCATION_CONTACT_SUCCESS;
    readonly callingAction = actionIndex.UPDATE_LOCATION_CONTACT;
    constructor(public locationID: number, public contact: Contact) { }
}


// Delete Location
export class DeleteLocation implements ServerRequestAction {
    readonly type = actionIndex.DELETE_LOCATION;
    constructor(public request: DeleteLocationRequest) { }
}

export class DeleteLocationSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_LOCATION_SUCCESS;
    readonly callingAction = actionIndex.DELETE_LOCATION;
    constructor(public request: DeleteLocationRequest) { }
}

export class DeleteLocationError implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_LOCATION_ERROR;
    readonly callingAction = actionIndex.DELETE_LOCATION;
    constructor(public message: string) { }
}

export class CancelDeleteLocation implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_DELETE_LOCATION;
    readonly callingAction = actionIndex.DELETE_LOCATION;
}

export class LoadStaticMapLibrary implements ServerRequestAction {
    readonly type =  actionIndex.LOAD_STATIC_MAP_LIBRARY;
    constructor() { }
}

export class LoadStaticMapLibrarySuccess implements ServerRequestCompleteAction {
    readonly type =  actionIndex.LOAD_STATIC_MAP_LIBRARY_SUCCESS;
    readonly callingAction =  actionIndex.LOAD_STATIC_MAP_LIBRARY;
    constructor() { }
}

export class LoadStaticMapLibraryError implements ServerRequestCompleteAction {
    readonly type =  actionIndex.LOAD_STATIC_MAP_LIBRARY_ERROR;
    readonly callingAction =  actionIndex.LOAD_STATIC_MAP_LIBRARY;
    constructor() { }
}
