import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IVehicleGroupsState } from 'app-modules/core/store/models/vehicle-groups-state.interface';
import * as vehicleGroupActions from 'app-modules/core/store/vehicle-group/vehicle-groups.actions';
import { VehicleGroupInfo } from 'emr-ng-shared';
@Injectable()
export class VehicleGroupsStateService {
    public vehicleGroupsStateSelector = ((state: IAppState) => state.vehicleGroups);

    public isLoadRequiredSelector = createSelector(
        this.vehicleGroupsStateSelector,
        (state: IVehicleGroupsState) => state.isLoadRequired
    );

    public isLoadingSelector = createSelector(
        this.vehicleGroupsStateSelector,
        (state: IVehicleGroupsState) => state.isLoading
    );

    public vehicleGroupsSelector = createSelector(
        this.vehicleGroupsStateSelector,
        (state: IVehicleGroupsState) => state.vehicleGroups
    );

    
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector);
    public vehicleGroupList$ = this.store.select(this.vehicleGroupsSelector);

    public loadVehicleGroups() {
        this.store.dispatch(new vehicleGroupActions.LoadVehicleGroups());
    }

    public loadVehicleGroupsSuccess(listInfo: VehicleGroupInfo[]) {
        this.store.dispatch(new vehicleGroupActions.LoadVehicleGroupsSuccess(listInfo));
    }

    public loadVehicleGroupsError(message: string) {
        this.store.dispatch(new vehicleGroupActions.LoadVehicleGroupsError(message));
    }

    public cancelLoadVehicleGroups() {
        this.store.dispatch(new vehicleGroupActions.CancelLoadVehicleGroups());
    }

    constructor(private store: Store<IAppState>) { }
}
