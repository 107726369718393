<div class="row filter-component-custom" *ngIf="selectedList.length > 0">
    <div class="col-md-12">
        <div class="row ml-1 mr-1">
            <div class="filter-header-style" i18n="@@shipment_status">Shipment Status</div>
            <div class="filtered-item position-relative" *ngFor="let status of selectedList">
                <span class="filter-content-style">{{status.Description}}</span>
                <button class="filter-close-button" class="filter-close-button"
                    (click)="clearSelectedFilter(status.TripStateCode)"><i class="fa fa-close"></i></button></div>
        </div>
    </div>
</div>