import { Action } from '@ngrx/store';

import { CustomerDashboardInfo, SetDashboardCustomerRequest } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from '../actions/custom-actions';

export class LoadCustomerDashboards implements ServerRequestAction {
    readonly type = actionIndex.LOAD_CUSTOMER_DASHBOARD;
}

export class LoadCustomerDashboardsSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_CUSTOMER_DASHBOARD_SUCCESS;
    readonly callingAction = actionIndex.LOAD_CUSTOMER_DASHBOARD;
    constructor(public customerDashboardList: IListInfo<CustomerDashboardInfo>) { }
}

export class LoadCustomerDashboardsError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_CUSTOMER_DASHBOARD_ERROR;
    readonly callingAction = actionIndex.LOAD_CUSTOMER_DASHBOARD;
    constructor(public message: string) { }
}

export class CancelLoadCustomerDashboards implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_CUSTOMER_DASHBOARD;
    readonly callingAction = actionIndex.LOAD_CUSTOMER_DASHBOARD;
}

// Save Customer Dashboard
export class SetCustomerDashboard implements ServerRequestAction {
    readonly type = actionIndex.SET_CUSTOMER_DASHBOARD;
    constructor(public request: SetDashboardCustomerRequest) { }
}

export class SetCustomerDashboardSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_CUSTOMER_DASHBOARD_SUCCESS;
    readonly callingAction = actionIndex.SET_CUSTOMER_DASHBOARD;
    constructor(public customerDashboard: CustomerDashboardInfo) { }
}

export class SetCustomerDashboardError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_CUSTOMER_DASHBOARD_ERROR;
    readonly callingAction = actionIndex.SET_CUSTOMER_DASHBOARD;
    constructor(public message: string) { }
}

export class CancelSetCustomerDashboard implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SET_CUSTOMER_DASHBOARD;
    readonly callingAction = actionIndex.SET_CUSTOMER_DASHBOARD;
}

// Delete Customer Dashboard
export class DeleteCustomerDashboard implements ServerRequestAction {
    readonly type = actionIndex.DELETE_CUSTOMER_DASHBOARD;
    constructor(public request: SetDashboardCustomerRequest) { }
}

export class DeleteCustomerDashboardSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_CUSTOMER_DASHBOARD_SUCCESS;
    readonly callingAction = actionIndex.DELETE_CUSTOMER_DASHBOARD;
    constructor(public dashboardCustomerId: number) { }
}

export class DeleteCustomerDashboardError implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_CUSTOMER_DASHBOARD_ERROR;
    readonly callingAction = actionIndex.DELETE_CUSTOMER_DASHBOARD;
    constructor(public message: string) { }
}

export class CancelDeleteCustomerDashboard implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_DELETE_CUSTOMER_DASHBOARD;
    readonly callingAction = actionIndex.DELETE_CUSTOMER_DASHBOARD;
}