import { split } from "lodash";

export const api = {
  CNOversightAPI: '##__trackers.CNOversightAPI__##',
  OversightAPI: '##__trackers.OversightAPI__##',
  oversightApiUrl: '##__trackers.oversightApiUrl__##',
  uoversightApiUrl: '##__trackers.uoversightApiUrl__##',
  auditApiUrl: '##__trackers.auditApiUrl__##',
  localizationUrl: '##__trackers.localizationUrl__##',
  requiredConnects: split('##__trackers.requiredConnects__##', ','),
  authConfig: '##__trackers.authConfig__##',
};
