// Loading (loading.actions.ts)
export const FETCHING_DATA = 'FETCHING_DATA';
export const FETCHING_DATA_COMPLETE = 'FETCHING_DATA_COMPLETE';
export const CLOSE_SPINNER = 'CLOSE_SPINNER';

// Data Binding Actions (data-binding.actions.ts)
export const BINDING_DATA = 'BINDING_DATA';
export const BINDING_DATA_COMPLETE = 'BINDING_DATA_COMPLETE';
export const BINDING_DATA_ERROR = 'BINDING_DATA_ERROR';

// Auth (auth.actions.ts)
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const CLEAR_SIGN_IN_ERROR = 'CLEAR_SIGN_IN_ERROR';
export const SIGN_OUT = 'SIGN_OUT';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

// PakSense Auth

export const PAK_SENSE_SIGN_IN = 'PAK_SENSE_SIGN_IN';
export const PAK_SENSE_SIGN_IN_SUCCESS = 'PAK_SENSE_SIGN_IN_SUCCESS';
export const PAK_SENSE_SIGN_IN_ERROR = 'PAK_SENSE_SIGN_IN_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CANCEL_CHANGE_PASSWORD = 'CANCEL_CHANGE_PASSWORD';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const CANCEL_FORGOT_PASSWORD = 'CANCEL_FORGOT_PASSWORD';

// Customer (customer.actions.ts)
export const LOAD_CUSTOMERS = 'LOAD_CUSTOMERS';
export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS';
export const LOAD_CUSTOMERS_ERROR = 'LOAD_CUSTOMERS_ERROR';
export const CANCEL_LOAD_CUSTOMERS = 'CANCEL_LOAD_CUSTOMERS';

export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const SELECT_CUSTOMER_SUCCESS = 'SELECT_CUSTOMER_SUCCESS';
export const SELECT_CUSTOMER_ERROR = 'SELECT_CUSTOMER_ERROR';

// LinkedCustomer (linked-customer.actions.ts)
export const LOAD_LINKED_CUSTOMERS = 'LOAD_LINKED_CUSTOMERS';
export const LOAD_LINKED_CUSTOMERS_SUCCESS = 'LOAD_LINKED_CUSTOMERS_SUCCESS';
export const LOAD_LINKED_CUSTOMERS_ERROR = 'LOAD_LINKED_CUSTOMERS_ERROR';
export const CANCEL_LOAD_LINKED_CUSTOMERS = 'CANCEL_LOAD_LINKED_CUSTOMERS';

// export const SELECT_LINKED_CUSTOMER = 'SELECT_LINKED_CUSTOMER';
// export const SELECT_LINKED_CUSTOMER_SUCCESS = 'SELECT_LINKED_CUSTOMER_SUCCESS';
// export const SELECT_LINKED_CUSTOMER_ERROR = 'SELECT_LINKED_CUSTOMER_ERROR';

// Preference (preference.action.ts)
export const LOAD_PREFERENCE = 'LOAD_PREFERENCE';
export const LOAD_PREFERENCE_SUCCESS = 'LOAD_PREFERENCE_SUCCESS';
export const LOAD_PREFERENCE_ERROR = 'LOAD_PREFERENCE_ERROR';
export const CANCEL_LOAD_PREFERENCE = 'CANCEL_LOAD_PREFERENCE';

// Shipment (shipment.actions.ts)
export const LOAD_SHIPMENTS = 'LOAD_SHIPMENTS';
export const LOAD_SHIPMENTS_SUCCESS = 'LOAD_SHIPMENTS_SUCCESS';
export const LOAD_SHIPMENTS_ERROR = 'LOAD_SHIPMENTS_ERROR';
export const CANCEL_LOAD_SHIPMENTS = 'CANCEL_LOAD_SHIPMENTS';
export const UPDATE_SHIPMENT_SUSPEND_RESUME_STATE = "UPDATE_SHIPMENT_SUSPEND_RESUME_STATE";
export const Update_SHIPMENT_BY_TRACKERID = "Update_SHIPMENT_BY_TRACKERID";

export const RELOAD_SHIPMENTS = 'RELOAD_SHIPMENTS';
export const AUTO_REFRESH_SHIPMENTS = 'AUTO_REFRESH_SHIPMENTS';
export const AUTO_REFRESH_SHIPMENTS_SUCCESS = 'AUTO_REFRESH_SHIPMENTS_SUCCESS';
export const AUTO_REFRESH_SHIPMENTS_ERROR = 'AUTO_REFRESH_SHIPMENTS_ERROR';
export const CANCEL_AUTO_REFRESH_SHIPMENTS = 'CANCEL_AUTO_REFRESH_SHIPMENTS';


export const LOAD_SHIPMENT_BY_SERIAL = 'LOAD_SHIPMENT_BY_SERIAL';
export const LOAD_SHIPMENT_BY_SERIAL_SUCCESS = 'LOAD_SHIPMENT_BY_SERIAL_SUCCESS';
export const LOAD_SHIPMENT_BY_SERIAL_ERROR = 'LOAD_SHIPMENT_BY_SERIAL_ERROR';
export const CANCEL_LOAD_SHIPMENT_BY_SERIAL = 'CANCEL_LOAD_SHIPMENT_BY_SERIAL';

export const LOAD_COMPARE_TRAXX = 'LOAD_COMPARE_TRAXX';
export const LOAD_COMPARE_TRAXX_COMPLETE = 'LOAD_COMPARE_TRAXX_COMPLETE';

export const LOAD_SHIPMENT_ADDRESS = 'LOAD_SHIPMENT_ADDRESS';
export const LOAD_SHIPMENT_ADDRESS_SUCCESS = 'LOAD_SHIPMENT_ADDRESS_SUCCESS';
export const LOAD_SHIPMENT_ADDRESS_ERROR = 'LOAD_SHIPMENT_ADDRESS_ERROR';

export const CREATE_SHIPMENT = 'CREATE_SHIPMENT';
export const CREATE_SHIPMENT_SUCCESS = 'CREATE_SHIPMENT_SUCCESS';
export const CREATE_SHIPMENT_ERROR = 'CREATE_SHIPMENT_ERROR';

export const UPDATE_SHIPMENT = 'UPDATE_SHIPMENT';
export const UPDATE_SHIPMENT_SUCCESS = 'UPDATE_SHIPMENT_SUCCESS';
export const UPDATE_SHIPMENT_ERROR = 'UPDATE_SHIPMENT_ERROR';

export const EDIT_SHIPMENT = 'EDIT_SHIPMENT';
export const EDIT_SHIPMENT_SUCCESS = 'EDIT_SHIPMENT_SUCCESS';
export const EDIT_SHIPMENT_ERROR = 'EDIT_SHIPMENT_ERROR';

export const SET_TRIP_SENSOR_RANGE = 'SET_TRIP_SENSOR_RANGE';
export const SET_TRIP_SENSOR_RANGE_SUCCESS = 'SET_TRIP_SENSOR_RANGE_SUCCESS';
export const SET_TRIP_SENSOR_RANGE_ERROR = 'SET_TRIP_SENSOR_RANGE_ERROR';

export const SET_TRACKER_SENSOR_RANGE = 'SET_TRACKER_SENSOR_RANGE';
export const SET_TRACKER_SENSOR_RANGE_SUCCESS = 'SET_TRACKER_SENSOR_RANGE_SUCCESS';
export const SET_TRACKER_SENSOR_RANGE_ERROR = 'SET_TRACKER_SENSOR_RANGE_ERROR';
export const CANCEL_SET_TRACKER_SENSOR_RANGE = 'CANCEL_SET_TRACKER_SENSOR_RANGE';

export const CLOSE_SHIPMENT = 'CLOSE_SHIPMENT';
export const CLOSE_SHIPMENT_SUCCESS = 'CLOSE_SHIPMENT_SUCCESS';
export const CLOSE_SHIPMENT_ERROR = 'CLOSE_SHIPMENT_ERROR';

// Shipment Filter (shipment-filter.actions.ts)
export const CHANGE_SHIPMENT_FILTER = 'CHANGE_SHIPMENT_FILTER';
export const CUSTOM_CHANGE_SHIPMENT_FILTER = 'CUSTOM_CHANGE_SHIPMENT_FILTER';
export const UPDATE_IS_DEFAULT_SHIPMENT_FILTER = 'UPDATE_IS_DEFAULT_SHIPMENT_FILTER';
export const CHANGE_SHIPMENT_FILTER_WITH_OUT_SHIPMENT_LIST_UPDATE = 'CHANGE_SHIPMENT_FILTER_WITH_OUT_SHIPMENT_LIST_UPDATE';

// Shipment Sort (shipment-sort.actions.ts)
export const LOAD_SHIPMENT_SORT_LIST = 'LOAD_SHIPMENT_SORT_LIST';
export const LOAD_SHIPMENT_SORT_LIST_SUCCESS = 'LOAD_SHIPMENT_SORT_LIST_SUCCESS';
export const LOAD_SHIPMENT_SORT_LIST_ERROR = 'LOAD_SHIPMENT_SORT_LIST_ERROR';
export const CANCEL_LOAD_SHIPMENT_SORT_LIST = 'CANCEL_LOAD_SHIPMENT_SORT_LIST';
export const CHANGE_SHIPMENT_SORT = 'CHANGE_SHIPMENT_SORT';
export const CHANGE_SHIPMENT_SORT_WITH_OUT_LIST_UPDATE = 'CHANGE_SHIPMENT_SORT_WITH_OUT_LIST_UPDATE';


// Shipment Paging (shipment-paging.actions.ts)
export const CHANGE_SHIPMENTS_PAGE = 'CHANGE_SHIPMENTS_PAGE';
export const CHANGE_SHIPMENTS_PAGE_SIZE = 'CHANGE_SHIPMENTS_PAGE_SIZE';


// Create Shipment (create-shipment.actions.ts)
export const CREATE_SHIPMENT_SELECT_LINKED_CUSTOMER = 'CREATE_SHIPMENT_SELECT_LINKED_CUSTOMER';


// Create Shipment Location (create-shipment-location.actions.ts)
export const CREATE_SHIPMENT_LOAD_LOCATIONS = 'CREATE_SHIPMENT_LOAD_LOCATIONS';
export const CREATE_SHIPMENT_LOAD_LOCATIONS_SUCCESS = 'CREATE_SHIPMENT_LOAD_LOCATIONS_SUCCESS';
export const CREATE_SHIPMENT_LOAD_LOCATIONS_ERROR = 'CREATE_SHIPMENT_LOAD_LOCATIONS_ERROR';
export const CREATE_SHIPMENT_CANCEL_LOAD_LOCATIONS = 'CREATE_SHIPMENT_CANCEL_LOAD_LOCATIONS';

export const CREATE_SHIPMENT_CREATE_LOCATION = 'CREATE_SHIPMENT_CREATE_LOCATION';
export const CREATE_SHIPMENT_CREATE_LOCATION_SUCCESS = 'CREATE_SHIPMENT_CREATE_LOCATION_SUCCESS';
export const CREATE_SHIPMENT_CREATE_LOCATION_ERROR = 'CREATE_SHIPMENT_CREATE_LOCATION_ERROR';


// Create Shipment Template (create-shipment-template.actions.ts)
export const CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES = 'CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES';
export const CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_SUCCESS = 'CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_SUCCESS';
export const CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_ERROR = 'CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_ERROR';
export const CREATE_SHIPMENT_CANCEL_LOAD_SHIPMENT_TEMPLATES = 'CREATE_SHIPMENT_CANCEL_LOAD_SHIPMENT_TEMPLATES';


// Create Shipment Sensor Range (create-shipment-sensor-range.actions.ts)
export const CREATE_SHIPMENT_LOAD_SENSOR_RANGES = 'CREATE_SHIPMENT_LOAD_SENSOR_RANGES';
export const CREATE_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS = 'CREATE_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS';
export const CREATE_SHIPMENT_LOAD_SENSOR_RANGES_ERROR = 'CREATE_SHIPMENT_LOAD_SENSOR_RANGES_ERROR';
export const CREATE_SHIPMENT_CANCEL_LOAD_SENSOR_RANGES = 'CREATE_SHIPMENT_CANCEL_LOAD_SENSOR_RANGES';

export const CREATE_SHIPMENT_CREATE_SENSOR_RANGE = 'CREATE_SHIPMENT_CREATE_SENSOR_RANGE';
export const CREATE_SHIPMENT_CREATE_SENSOR_RANGE_SUCCESS = 'CREATE_SHIPMENT_CREATE_SENSOR_RANGE_SUCCESS';
export const CREATE_SHIPMENT_CREATE_SENSOR_RANGE_ERROR = 'CREATE_SHIPMENT_CREATE_SENSOR_RANGE_ERROR';

// Create Shipment Carrier (create-shipment-carrier.actions.ts)
export const CREATE_SHIPMENT_LOAD_CARRIER = 'CREATE_SHIPMENT_LOAD_CARRIER';
export const CREATE_SHIPMENT_LOAD_CARRIER_SUCCESS = 'CREATE_SHIPMENT_LOAD_CARRIER_SUCCESS';
export const CREATE_SHIPMENT_LOAD_CARRIER_ERROR = 'CREATE_SHIPMENT_LOAD_CARRIER_ERROR';
export const CREATE_SHIPMENT_CANCEL_LOAD_CARRIER = 'CREATE_SHIPMENT_CANCEL_LOAD_CARRIER';
export const CREATE_SHIPMENT_ADDORUPDATE_CARRIER_SUCCESS = 'CREATE_SHIPMENT_ADDORUPDATE_CARRIER_SUCCESS';
export const CREATE_SHIPMENT_REMOVE_CARRIER_SUCCESS = 'CREATE_SHIPMENT_REMOVE_CARRIER_SUCCESS';

// Create Shipment Tracker (create-shipment-tracker.actions.ts)
export const CREATE_SHIPMENT_LOAD_TRACKERS = 'CREATE_SHIPMENT_LOAD_TRACKERS';
export const CREATE_SHIPMENT_LOAD_TRACKERS_SUCCESS = 'CREATE_SHIPMENT_LOAD_TRACKERS_SUCCESS';
export const CREATE_SHIPMENT_LOAD_TRACKERS_ERROR = 'CREATE_SHIPMENT_LOAD_TRACKERS_ERROR';
export const CREATE_SHIPMENT_CANCEL_LOAD_TRACKERS = 'CREATE_SHIPMENT_CANCEL_LOAD_TRACKERS';

// Create Shipment PULL Tracker (create-shipment-tracker.actions.ts)
export const CREATE_SHIPMENT_LOAD_PULL_TRACKERS = 'CREATE_SHIPMENT_LOAD_PULL_TRACKERS';
export const CREATE_SHIPMENT_LOAD_PULL_TRACKERS_SUCCESS = 'CREATE_SHIPMENT_LOAD_PULL_TRACKERS_SUCCESS';
export const CREATE_SHIPMENT_LOAD_PULL_TRACKERS_ERROR = 'CREATE_SHIPMENT_LOAD_PULL_TRACKERS_ERROR';
export const CREATE_SHIPMENT_CANCEL_LOAD_PULL_TRACKERS = 'CREATE_SHIPMENT_CANCEL_LOAD_PULL_TRACKERS';

// Create Shipment Sensor Range (edit-shipment-sensor-range.actions.ts)
export const EDIT_SHIPMENT_SELECT_LINKED_CUSTOMER = 'EDIT_SHIPMENT_SELECT_LINKED_CUSTOMER';
export const EDIT_SHIPMENT_LOAD_SENSOR_RANGES = 'EDIT_SHIPMENT_LOAD_SENSOR_RANGES';
export const EDIT_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS = 'EDIT_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS';
export const EDIT_SHIPMENT_LOAD_SENSOR_RANGES_ERROR = 'EDIT_SHIPMENT_LOAD_SENSOR_RANGES_ERROR';
export const EDIT_SHIPMENT_CANCEL_LOAD_SENSOR_RANGES = 'EDIT_SHIPMENT_CANCEL_LOAD_SENSOR_RANGES';


// Shipment Detail (shipment-detail.actions.ts)
export const SHIPMENT_DETAIL_SELECT_SHIPMENT = 'SHIPMENT_DETAIL_SELECT_SHIPMENT';
export const SHIPMENT_DETAIL_RESET_SHIPMENT = 'SHIPMENT_DETAIL_RESET_SHIPMENT';


// Shipment Detail Alert (shipment-detail-alert.actions.ts)
export const SHIPMENT_DETAIL_LOAD_ALERTS = 'SHIPMENT_DETAIL_LOAD_ALERTS';
export const SHIPMENT_DETAIL_LOAD_ALERTS_SUCCESS = 'SHIPMENT_DETAIL_LOAD_ALERTS_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_ALERTS_ERROR = 'SHIPMENT_DETAIL_LOAD_ALERTS_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_ALERTS = 'SHIPMENT_DETAIL_CANCEL_LOAD_ALERTS';


// Shipment Detail Alert Paging (shipment-detail-alert-paging.actions.ts)
export const CHANGE_SHIPMENT_DETAIL_ALERT_PAGE = 'CHANGE_SHIPMENT_DETAIL_ALERT_PAGE';
export const CHANGE_SHIPMENT_DETAIL_ALERT_PAGE_SIZE = 'CHANGE_SHIPMENT_DETAIL_ALERT_PAGE_SIZE';

// Shipment Detail Historical Shipment (shipment-detail-historical-shipment.actions.ts)
export const SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT = 'SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT';
export const SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_SUCCESS = 'SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_ERROR = 'SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_HISTORICAL_SHIPMENT = 'SHIPMENT_DETAIL_CANCEL_LOAD_HISTORICAL_SHIPMENT';

// Shipment Detail Historical Shipment Paging (shipment-detail-historical-shipment-paging.actions.ts)
export const CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE = 'CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE';
export const CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE_SIZE = 'CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE_SIZE';

// Shipment Detail Tracker (shipment-detail-tracker.actions.ts)
export const SHIPMENT_DETAIL_LOAD_TRACKER = 'SHIPMENT_DETAIL_LOAD_TRACKER';
export const SHIPMENT_DETAIL_LOAD_TRACKER_SUCCESS = 'SHIPMENT_DETAIL_LOAD_TRACKER_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_TRACKER_ERROR = 'SHIPMENT_DETAIL_LOAD_TRACKER_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER = 'SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER';

// Shipment Detail Tracker State Report (shipment-detail-tracker-state-report.actions.ts)
export const SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT = 'SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT';
export const SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_SUCCESS = 'SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_ERROR = 'SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER_STATE_REPORT = 'SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER_STATE_REPORT';

// Shipment Detail Tracker State Chart (shipment-detail-tracker-state-chart.actions.ts)
export const SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART = 'SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART';
export const SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_SUCCESS = 'SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_ERROR = 'SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER_STATE_CHART = 'SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER_STATE_CHART';

// Tracker State chart Selected Time Period (shipment-detail-tracker-state-chart-time-period.actions.ts)
export const SHIPMENT_DETAIL_TRACKER_STATE_CHART_SELECTED_TIME_PERIOD = 'SHIPMENT_DETAIL_TRACKER_STATE_CHART_SELECTED_TIME_PERIOD';

// Shipment Detail Tracker State Report Export (shipment-detail-tracker.actions.ts)
export const SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT = 'SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT';
export const SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_SUCCESS = 'SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_ERROR = 'SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_EXPORT_TRACKER_STATE_REPORT = 'SHIPMENT_DETAIL_CANCEL_LOAD_EXPORT_TRACKER_STATE_REPORT';

// Shipment Detail Sensor Chart (shipment-detail-sensor-chart.actions.ts)
export const SHIPMENT_DETAIL_LOAD_SENSOR_CHART = 'SHIPMENT_DETAIL_LOAD_SENSOR_CHART';
export const SHIPMENT_DETAIL_LOAD_SENSOR_CHART_SUCCESS = 'SHIPMENT_DETAIL_LOAD_SENSOR_CHART_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_SENSOR_CHART_ERROR = 'SHIPMENT_DETAIL_LOAD_SENSOR_CHART_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_SENSOR_CHART = 'SHIPMENT_DETAIL_CANCEL_LOAD_SENSOR_CHART';

// Shipment Detail Sensor Report Export (shipment-detail-sensor-chart.actions.ts)
export const SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT = 'SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT';
export const SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_SUCCESS = 'SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_ERROR = 'SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_EXPORT_SENSOR_REPORT = 'SHIPMENT_DETAIL_CANCEL_LOAD_EXPORT_SENSOR_REPORT';

// Shipment Detail Traxx (shipment-detail-traxx.actions.ts)
export const SHIPMENT_DETAIL_LOAD_TRAXX = 'SHIPMENT_DETAIL_LOAD_TRAXX';
export const SHIPMENT_DETAIL_LOAD_TRAXX_SUCCESS = 'SHIPMENT_DETAIL_LOAD_TRAXX_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_TRAXX_ERROR = 'SHIPMENT_DETAIL_LOAD_TRAXX_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_TRAXX = 'SHIPMENT_DETAIL_CANCEL_LOAD_TRAXX';
export const SHIPMENT_DETAIL_UPDATE_DATE_RANGE = 'SHIPMENT_DETAIL_UPDATE_DATE_RANGE';

// Shipment Detail Timeline (shipment-detail-timeline.actions.ts)
export const SHIPMENT_DETAIL_LOAD_TIMELINE = 'SHIPMENT_DETAIL_LOAD_TIMELINE';
export const SHIPMENT_DETAIL_LOAD_TIMELINE_SUCCESS = 'SHIPMENT_DETAIL_LOAD_TIMELINE_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_TIMELINE_ERROR = 'SHIPMENT_DETAIL_LOAD_TIMELINE_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_TIMELINE = 'SHIPMENT_DETAIL_CANCEL_LOAD_TIMELINE';

// Shipment Detail Time Period (shipment-detial-time-period.actions.ts)
export const SHIPMENT_DETAIL_LOAD_TIME_PERIODS = 'SHIPMENT_DETAIL_LOAD_TIME_PERIODS';
export const SHIPMENT_DETAIL_LOAD_TIME_PERIODS_SUCCESS = 'SHIPMENT_DETAIL_LOAD_TIME_PERIODS_SUCCESS';
export const SHIPMENT_DETAIL_LOAD_TIME_PERIODS_ERROR = 'SHIPMENT_DETAIL_LOAD_TIME_PERIODS_ERROR';
export const SHIPMENT_DETAIL_CANCEL_LOAD_TIME_PERIODS = 'SHIPMENT_DETAIL_CANCEL_LOAD_TIME_PERIODS';

// Shipment Selected Time Period (shipment-detial-selected-time-period.actions.ts)
export const SHIPMENT_DETAIL_SELECTED_TIME_PERIOD = 'SHIPMENT_DETAIL_SELECTED_TIME_PERIOD';


// Alert (alert.actions.ts)
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const CLOSE_ALERT_SUCCESS = 'CLOSE_ALERT_SUCCESS';
export const CLOSE_ALERT_ERROR = 'CLOSE_ALERT_ERROR';
export const CANCEL_CLOSE_ALERT = 'CANCEL_CLOSE_ALERT';


// Tracker (tracker.actions.ts)
export const LOAD_TRACKERS = 'LOAD_TRACKERS';
export const LOAD_TRACKERS_SUCCESS = 'LOAD_TRACKERS_SUCCESS';
export const LOAD_TRACKERS_ERROR = 'LOAD_TRACKERS_ERROR';
export const CANCEL_LOAD_TRACKERS = 'CANCEL_LOAD_TRACKERS';


// Location (location.actions.ts)
export const LOAD_LOCATIONS = 'LOAD_LOCATION';
export const LOAD_LOCATIONS_SUCCESS = 'LOAD_LOCATION_SUCCESS';
export const LOAD_LOCATIONS_ERROR = 'LOAD_LOCATION_ERROR';
export const CANCEL_LOAD_LOCATIONS = 'CANCEL_LOAD_LOCATION';

export const IMPORT_LOCATIONS = 'IMPORT_LOCATIONS';
export const IMPORT_LOCATIONS_SUCCESS = 'IMPORT_LOCATIONS_SUCCESS';
export const IMPORT_LOCATIONS_ERROR = 'IMPORT_LOCATIONS_ERROR';
export const CANCEL_IMPORT_LOCATIONS = 'CANCEL_IMPORT_LOCATIONS';

export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const RELOAD_LOCATIONS = 'RELOAD_LOCATIONS';
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR';
export const CANCEL_CREATE_LOCATION = 'CANCEL_CREATE_LOCATION';

export const SAVE_IMPORT_LOCATIONS = 'SAVE_IMPORT_LOCATIONS';
export const SAVE_IMPORT_LOCATIONS_SUCCESS = 'SAVE_IMPORT_LOCATIONS_SUCCESS';
export const SAVE_IMPORT_LOCATIONS_ERROR = 'SAVE_IMPORT_LOCATIONS_ERROR';
export const CANCEL_SAVE_IMPORT_LOCATIONS = 'CANCEL_SAVE_IMPORT_LOCATIONS';

export const LOAD_LOCATION_COLOR_LIST = 'LOAD_LOCATION_COLOR_LIST';
export const LOAD_LOCATION_COLOR_LIST_SUCCESS = 'LOAD_LOCATION_COLOR_LIST_SUCCESS';
export const LOAD_LOCATION_COLOR_LIST_ERROR = 'LOAD_LOCATION_COLOR_LIST_ERROR';
export const CANCEL_LOAD_LOCATION_COLOR_LIST = 'CANCEL_LOAD_LOCATION_COLOR_LIST';

// Shipment Template (shipment-template.actions.ts)
export const RELOAD_SHIPMENT_TEMPLATES = 'RELOAD_SHIPMENT_TEMPLATES';
export const LOAD_SHIPMENT_TEMPLATES = 'LOAD_SHIPMENT_TEMPLATES';
export const LOAD_SHIPMENT_TEMPLATES_SUCCESS = 'LOAD_SHIPMENT_TEMPLATES_SUCCESS';
export const LOAD_SHIPMENT_TEMPLATES_ERROR = 'LOAD_SHIPMENT_TEMPLATES_ERROR';
export const CANCEL_LOAD_SHIPMENT_TEMPLATES = 'CANCEL_LOAD_SHIPMENT_TEMPLATES';
export const CREATE_TRIP_TEMPLATE_SUCCESS = 'CREATE_TRIP_TEMPLATE_SUCCESS';

export const SET_SHIPMENT_TEMPLATE = 'SET_SHIPMENT_TEMPLATE';
export const SET_SHIPMENT_TEMPLATE_SUCCESS = 'SET_SHIPMENT_TEMPLATE_SUCCESS';
export const SET_SHIPMENT_TEMPLATE_ERROR = 'SET_SHIPMENT_TEMPLATE_ERROR';
export const CANCEL_SET_SHIPMENT_TEMPLATE = 'CANCEL_SET_SHIPMENT_TEMPLATE';


// Alert Type Info (alert-type-info.actions.ts)
export const LOAD_ALERT_TYPES = 'LOAD_ALERT_TYPES';
export const LOAD_ALERT_TYPES_SUCCESS = 'LOAD_ALERT_TYPES_SUCCESS';
export const LOAD_ALERT_TYPES_ERROR = 'LOAD_ALERT_TYPES_ERROR';
export const CANCEL_LOAD_ALERT_TYPES = 'CANCEL_LOAD_ALERT_TYPES';


// Shipment Status (shipment-status.actions.ts)
export const LOAD_SHIPMENT_STATUSES = 'LOAD_SHIPMENT_STATUSES';
export const LOAD_SHIPMENT_STATUSES_SUCCESS = 'LOAD_SHIPMENT_STATUSES_SUCCESS';
export const LOAD_SHIPMENT_STATUSES_ERROR = 'LOAD_SHIPMENT_STATUSES_ERROR';
export const CANCEL_LOAD_SHIPMENT_STATUSES = 'CANCEL_LOAD_SHIPMENT_STATUSES';
export const RE_LOAD_SHIPMENT_STATUSES = 'RE_LOAD_SHIPMENT_STATUSES';


// Time Period (time-period.actions.ts)
export const LOAD_TIME_PERIODS = 'LOAD_TIME_PERIODS';
export const LOAD_TIME_PERIODS_SUCCESS = 'LOAD_TIME_PERIODS_SUCCESS';
export const LOAD_TIME_PERIODS_ERROR = 'LOAD_TIME_PERIODS_ERROR';
export const CANCEL_LOAD_TIME_PERIODS = 'CANCEL_LOAD_TIME_PERIODS';


// Sensor Range (sensor-range.actions.ts)
export const LOAD_SENSOR_RANGES = 'LOAD_SENSOR_RANGES';
export const LOAD_SENSOR_RANGES_SUCCESS = 'LOAD_SENSOR_RANGES_SUCCESS';
export const LOAD_SENSOR_RANGES_ERROR = 'LOAD_SENSOR_RANGES_ERROR';
export const CANCEL_LOAD_SENSOR_RANGES = 'CANCEL_LOAD_SENSOR_RANGES';

export const CREATE_SENSOR_RANGE = 'CREATE_SENSOR_RANGE';
export const CREATE_SENSOR_RANGE_SUCCESS = 'CREATE_SENSOR_RANGE_SUCCESS';
export const CREATE_SENSOR_RANGE_ERROR = 'CREATE_SENSOR_RANGE_ERROR';


// Carrier (carrier.actions.ts)
export const LOAD_CARRIERS = 'LOAD_CARRIERS';
export const LOAD_CARRIERS_SUCCESS = 'LOAD_CARRIERS_SUCCESS';
export const LOAD_CARRIERS_ERROR = 'LOAD_CARRIERS_ERROR';
export const CANCEL_LOAD_CARRIERS = 'CANCEL_LOAD_CARRIERS';

// Time Zone (time-zone.actions.ts)
export const LOAD_TIME_ZONES = 'LOAD_TIME_ZONES';
export const LOAD_TIME_ZONES_SUCCESS = 'LOAD_TIME_ZONES_SUCCESS';
export const LOAD_TIME_ZONES_ERROR = 'LOAD_TIME_ZONES_ERROR';
export const CANCEL_LOAD_TIME_ZONES = 'CANCEL_LOAD_TIME_ZONES';

// Distance to(shipment-detail-distance-to.action.ts)
export const LOAD_DISTANCE_TO = 'LOAD_DISTANCE_TO';
export const LOAD_DISTANCE_TO_SUCCESS = 'LOAD_DISTANCE_TO_SUCCESS';
export const LOAD_DISTANCE_TO_ERROR = 'LOAD_DISTANCE_TO_ERROR';
export const CANCEL_DISTANCE_TO = 'CANCEL_DISTANCE_TO';


// View Log (shipment-detail-distance-to.action.ts)
export const LOAD_VIEW_LOG = 'LOAD_VIEW_LOG';
export const LOAD_VIEW_LOG_SUCCESS = 'LOAD_VIEW_LOG_SUCCESS';
export const LOAD_VIEW_LOG_ERROR = 'LOAD_VIEW_LOG_ERROR';
export const CANCEL_VIEW_LOG = 'CANCEL_VIEW_LOG';

// Show Distance (shipment-detail-show-distance.action.ts)
export const LOAD_SHOW_DISTANCE = 'LOAD_SHOW_DISTANCE';
export const LOAD_SHOW_DISTANCE_SUCCESS = 'LOAD_SHOW_DISTANCE_SUCCESS';
export const LOAD_SHOW_DISTANCE_ERROR = 'LOAD_SHOW_DISTANCE_ERROR';
export const CANCEL_SHOW_DISTANCE = 'CANCEL_SHOW_DISTANCE';


// Cool Down Type Info (cool-down-type-info.actions.ts)
export const LOAD_COOL_DOWN_TYPES = 'LOAD_COOL_DOWN_TYPES';
export const LOAD_COOL_DOWN_TYPES_SUCCESS = 'LOAD_COOL_DOWN_TYPES_SUCCESS';
export const LOAD_COOL_DOWN_TYPES_ERROR = 'LOAD_COOL_DOWN_TYPES_ERROR';
export const CANCEL_LOAD_COOL_DOWN_TYPES = 'CANCEL_LOAD_COOL_DOWN_TYPES';

// Multi Trips (multi-trip.action.ts)
export const LOAD_MULTI_TRIPS = 'LOAD_MULTI_TRIPS';
export const LOAD_MULTI_TRIPS_SUCCESS = 'LOAD_MULTI_TRIPS_SUCCESS';
export const LOAD_MULTI_TRIPS_ERROR = 'LOAD_MULTI_TRIPS_ERROR';
export const CANCEL_LOAD_MULTI_TRIPS = 'CANCEL_LOAD_MULTI_TRIPS';

// Alert Suspense Actions
export const LOAD_ALERT_SUSPENSE_STATUS = 'LOAD_ALERT_SUSPENSE_STATUS';
export const LOAD_ALERT_SUSPENSE_STATUS_SUCCESS = 'LOAD_ALERT_SUSPENSE_STATUS_SUCCESS';
export const LOAD_ALERT_SUSPENSE_STATUS_ERROR = 'LOAD_ALERT_SUSPENSE_STATUS_ERROR';

export const UPDATE_ALERT_SUSPENSE_STATUS = 'UPDATE_ALERT_SUSPENSE_STATUS';
export const UPDATE_ALERT_SUSPENSE_STATUS_SUCCESS = 'UPDATE_ALERT_SUSPENSE_STATUS_SUCCESS';
export const UPDATE_ALERT_SUSPENSE_STATUS_ERROR = 'UPDATE_ALERT_SUSPENSE_STATUS_ERROR';

// Page size actions (page-size.actions.ts)
export const LOAD_PAGE_SIZE = 'LOAD_PAGE_SIZE';
export const LOAD_PAGE_SIZE_SUCCESS = 'LOAD_PAGE_SIZE_SUCCESS';
export const LOAD_PAGE_SIZE_ERROR = 'LOAD_PAGE_SIZE_ERROR';
export const CANCEL_LOAD_PAGE_SIZE = 'CANCEL_LOAD_PAGE_SIZE';

export const VALIDATE_SHIPMENT = 'VALIDATE_SHIPMENT';
export const VALIDATE_SHIPMENT_SUCCESS = 'VALIDATE_SHIPMENT_SUCCESS';
export const VALIDATE_SHIPMENT_ERROR = 'VALIDATE__SHIPMENT_ERROR';
export const CANCEL_VALIDATE_SHIPMENT = 'CANCEL_VALIDATE__SHIPMENT';

// Create shipment Template Details(create-shipment-template-details.actions.ts)
export const CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS = 'CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS';
export const CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_SUCCESS = 'CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_SUCCESS';
export const CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_ERROR = 'CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_ERROR';
export const CREATE_SHIPMENT_CANCEL_LOAD_TEMPLATE_DETAILS = 'CREATE_SHIPMENT_CANCEL_LOAD_TEMPLATE_DETAILS';

// Business Rules Actions
export const LOAD_BUSINESS_RULES = 'LOAD_BUSINESS_RULES';
export const LOAD_BUSINESS_RULES_SUCCESS = 'LOAD_BUSINESS_RULES_SUCCESS';
export const LOAD_BUSINESS_RULES_ERROR = 'LOAD_BUSINESS_RULES_ERROR';
export const CANCEL_LOAD_BUSINESS_RULES = 'CANCEL_LOAD_BUSINESS_RULES';
export const CHECK_BUSINESS_RULES_STATE = 'CHECK_BUSINESS_RULES_STATE';

// Vehicle Group Actions
export const LOAD_VEHICLE_GROUPS = 'LOAD_VEHICLE_GROUPS';
export const LOAD_VEHICLE_GROUPS_SUCCESS = 'LOAD_VEHICLE_GROUPS_SUCCESS';
export const LOAD_VEHICLE_GROUPS_ERROR = 'LOAD_VEHICLE_GROUPS_ERROR';
export const CANCEL_LOAD_VEHICLE_GROUPS = 'CANCEL_LOAD_VEHICLE_GROUPS';
export const LOAD_SHIPMENTS_BYID = 'LOAD_SHIPMENTS_BYID';
export const LOAD_SHIPMENTS_BYID_SUCCESS = 'LOAD_SHIPMENTS_BYID_SUCCESS';
export const LOAD_SHIPMENTS_BYTRACKERID_SUCCESS = 'LOAD_SHIPMENTS_BYTRACKERID_SUCCESS';
export const LOAD_SHIPMENTS_BYID_ERROR = 'LOAD_SHIPMENTS_BYID_ERROR';
export const CANCEL_LOAD_SHIPMENTS_BYID = 'CANCEL_LOAD_SHIPMENTS_BYID';

// Un Auth (un-auth.actions.ts)
export const SAVE_UN_AUTH_TRACKERS = 'SAVE_UN_AUTH_TRACKERS';
export const SAVE_UN_AUTH_CUSTOMER = 'SAVE_UN_AUTH_CUSTOMER';
export const UN_AUTH_CREATE_SHIPMENT_ACCESS = 'UN_AUTH_CREATE_SHIPMENT_ACCESS';
export const SAVE_UN_AUTH_CUSTOMER_LOCATION = 'SAVE_UN_AUTH_CUSTOMER_LOCATION';
export const CLEAR_UN_AUTH_CUSTOMER = 'CLEAR_UN_AUTH_CUSTOMER';


// Routes actions (route.actions.ts)
export const LOAD_SAVED_ROUTE = 'LOAD_SAVED_ROUTE';
export const LOAD_SAVED_ROUTE_SUCCESS = 'LOAD_SAVED_ROUTE_SUCCESS';
export const LOAD_SAVED_ROUTE_ERROR = 'LOAD_SAVED_ROUTE_ERROR';
export const CANCEL_LOAD_SAVED_ROUTE = 'CANCEL_LOAD_SAVED_ROUTE';

export const SAVE_ROUTE = 'SAVE_ROUTE';
export const SAVE_ROUTE_SUCCESS = 'SAVE_ROUTE_SUCCESS';
export const SAVE_ROUTE_ERROR = 'SAVE_ROUTE_ERROR';

export const DELETE_ROUTE = 'DELETE_ROUTE';
export const DELETE_ROUTE_SUCCESS = 'DELETE_ROUTE_SUCCESS';
export const DELETE_ROUTE_ERROR = 'DELETE_ROUTE_ERROR';

// Formatted Strings (formatted-strings.actions.ts)
export const LOAD_FORMATTED_STRINGS = 'LOAD_FORMATTED_STRINGS';
export const LOAD_FORMATTED_STRINGS_SUCCESS = 'LOAD_FORMATTED_STRINGS_SUCCESS';
export const LOAD_FORMATTED_STRINGS_ERROR = 'LOAD_FORMATTED_STRINGS_ERROR';
export const CANCEL_LOAD_FORMATTED_STRINGS = 'CANCEL_LOAD_FORMATTED_STRINGS';

// Route Deviation actions (route-deviation.actions.ts)
export const LOAD_SAVED_ROUTE_DEVIATION = 'LOAD_SAVED_ROUTE_DEVIATION';
export const LOAD_SAVED_ROUTE_DEVIATION_SUCCESS = 'LOAD_SAVED_ROUTE_DEVIATION_SUCCESS';
export const LOAD_SAVED_ROUTE_DEVIATION_ERROR = 'LOAD_SAVED_ROUTE_DEVIATION_ERROR';
export const CANCEL_LOAD_SAVED_ROUTE_DEVIATION = 'CANCEL_LOAD_SAVED_ROUTE_DEVIATION';

export const GET_SAVED_ROUTE_DEVIATION = 'GET_SAVED_ROUTE_DEVIATION';
export const GET_SAVED_ROUTE_DEVIATION_SUCCESS = 'GET_SAVED_ROUTE_DEVIATION_SUCCESS';
export const GET_SAVED_ROUTE_DEVIATION_ERROR = 'GET_SAVED_ROUTE_DEVIATION_ERROR';

// Trip Start Options  (trip-start-options.actions.ts)
export const LOAD_TRIP_START_OPTIONS = 'LOAD_TRIP_START_OPTIONS';
export const LOAD_TRIP_START_OPTIONS_SUCCESS = 'LOAD_TRIP_START_OPTIONS_SUCCESS';
export const LOAD_TRIP_START_OPTIONS_ERROR = 'LOAD_TRIP_START_OPTIONS_ERROR';
export const CANCEL_LOAD_TRIP_START_OPTIONS = 'CANCEL_LOAD_TRIP_START_OPTIONS';

// Trip End Options  (trip-end-options.actions.ts)
export const LOAD_TRIP_END_OPTIONS = 'LOAD_TRIP_END_OPTIONS';
export const LOAD_TRIP_END_OPTIONS_SUCCESS = 'LOAD_TRIP_END_OPTIONS_SUCCESS';
export const LOAD_TRIP_END_OPTIONS_ERROR = 'LOAD_TRIP_END_OPTIONS_ERROR';
export const CANCEL_LOAD_TRIP_END_OPTIONS = 'CANCEL_LOAD_TRIP_END_OPTIONS';

// user Settings (user-settings.actions.ts)
export const LOAD_USER_SETTINGS = 'LOAD_USER_SETTINGS';
export const LOAD_USER_SETTINGS_SUCCESS = 'LOAD_USER_SETTINGS_SUCCESS';
export const LOAD_USER_SETTINGS_ERROR = 'LOAD_USER_SETTINGS_ERROR';
export const CANCEL_LOAD_USER_SETTINGS = 'CANCEL_LOAD_USER_SETTINGS';
export const LOAD_USER_SETTINGS_WITH_STORE = 'LOAD_USER_SETTINGS_WITH_STORE';
export const LOAD_USER_SETTINGS_SUCCESS_WITH_STORE = 'LOAD_USER_SETTINGS_SUCCESS_WITH_STORE';
export const LOAD_USER_SETTINGS_WITH_STORE_ERROR = 'LOAD_USER_SETTINGS_WITH_STORE_ERROR';

// Save user Settings (user-settings.actions.ts)
export const SAVE_USER_SETTINGS = 'SAVE_USER_SETTINGS';
export const SAVE_USER_SETTINGS_SUCCESS = 'SAVE_USER_SETTINGS_SUCCESS';
export const SAVE_USER_SETTINGS_ERROR = 'SAVE_USER_SETTINGS_ERROR';

// Un auth actions (un-auth.actions.ts)
export const UN_AUTH_CREATE_SHIPMENT = 'UN_AUTH_CREATE_SHIPMENT';
export const UN_AUTH_CREATE_SHIPMENT_SUCCESS = 'UN_AUTH_CREATE_SHIPMENT_SUCCESS';
export const UN_AUTH_CREATE_SHIPMENT_ERROR = 'UN_AUTH_CREATE_SHIPMENT_ERROR';

// Tracker Sensors
export const LOAD_TRACKER_SENSORS = 'LOAD_TRACKER_SENSORS';
export const LOAD_TRACKER_SENSORS_SUCCESS = 'LOAD_TRACKER_SENSORS_SUCCESS';
export const LOAD_TRACKER_SENSORS_ERROR = 'LOAD_TRACKER_SENSORS_ERROR';
export const CANCEL_LOAD_TRACKER_SENSORS = 'CANCEL_LOAD_TRACKER_SENSORS';

export const DELETE_SHIPMENT = 'DELETE_SHIPMENT';
export const DELETE_SHIPMENT_SUCCESS = 'DELETE_SHIPMENT_SUCCESS';
export const DELETE_SHIPMENT_ERROR = 'DELETE_SHIPMENT_ERROR';


// Contact (contact.actions.ts)
export const LOAD_CONTACT = 'LOAD_CONTACT';
export const LOAD_CONTACT_SUCCESS = 'LOAD_CONTACT_SUCCESS';
export const LOAD_CONTACT_ERROR = 'LOAD_CONTACT_ERROR';
export const CANCEL_LOAD_CONTACTS = 'CANCEL_LOAD_CONTACTS';

// SCHEDULE_REPORT
export const SCHEDULE_REPORT = 'SCHEDULE_REPORT';
export const SCHEDULE_REPORT_SUCCESS = 'SCHEDULE_REPORT_SUCCESS';
export const SCHEDULE_REPORT_ERROR = 'SCHEDULE_REPORT_ERROR';
export const CANCEL_SCHEDULE_REPORT = 'CANCEL_SCHEDULE_REPORT';

export const RELOAD_SCHEDULE_REPORTS = 'RELOAD_SCHEDULE_REPORTS';
export const LOAD_SCHEDULE_REPORTS = 'LOAD_SCHEDULE_REPORTS';
export const LOAD_SCHEDULE_REPORTS_SUCCESS = 'LOAD_SCHEDULE_REPORTS_SUCCESS';
export const LOAD_SCHEDULE_REPORTS_ERROR = 'LOAD_SCHEDULE_REPORTS_ERROR';
export const CANCEL_LOAD_SCHEDULE_REPORTS = 'CANCEL_LOAD_SCHEDULE_REPORTS';


// OBJECT_VIEWS
export const CHECK_OBJECT_VIEW_ARGS = 'CHECK_OBJECT_VIEW_ARGS';
export const LOAD_OBJECT_VIEW_ARGS = 'LOAD_OBJECT_VIEW_ARGS';
export const LOAD_OBJECT_VIEW_ARGS_SUCCESS = 'LOAD_OBJECT_VIEW_ARGS_SUCCESS';
export const LOAD_OBJECT_VIEW_ARGS_ERROR = 'LOAD_OBJECT_VIEW_ARGS_ERROR';
export const CANCEL_LOAD_OBJECT_VIEW_ARGS = 'CANCEL_LOAD_OBJECT_VIEW_ARGS';

export const RELOAD_OBJECT_VIEWS = 'RELOAD_OBJECT_VIEWS';
export const LOAD_OBJECT_VIEWS = 'LOAD_OBJECT_VIEWS';
export const LOAD_OBJECT_VIEWS_SUCCESS = 'LOAD_OBJECT_VIEWS_SUCCESS';
export const LOAD_OBJECT_VIEWS_ERROR = 'LOAD_OBJECT_VIEWS_ERROR';
export const CANCEL_LOAD_OBJECT_VIEWS = 'CANCEL_LOAD_OBJECT_VIEWS';

export const SAVE_TRACKER_NOTE = 'SAVE_TRACKER_NOTE';
export const SAVE_TRACKER_NOTE_SUCCESS = 'SAVE_TRACKER_NOTE_SUCCESS';
export const SAVE_TRACKER_NOTE_ERROR = 'SAVE_TRACKER_NOTE_ERROR';

export const SHARE_TRACKER = 'SHARE_TRACKER';
export const SHARE_TRACKER_SUCCESS = 'SHARE_TRACKER_SUCCESS';
export const SHARE_TRACKER_ERROR = 'SHARE_TRACKER_ERROR';

//Validate and Get Nearest Location
export const VALIDATE_LOCATION = 'VALIDATE_LOCATION';
export const VALIDATE_LOCATION_SUCCESS = 'VALIDATE_LOCATION_SUCCESS';
export const VALIDATE_LOCATION_ERROR = 'VALIDATE_LOCATION_ERROR';
export const CANCEL_VALIDATE_LOCATION = 'CANCEL_VALIDATE_LOCATION';

// Delete Location Geofence
export const DELETE_LOCATION_GEOFENCE = 'DELETE_LOCATION_GEOFENCE';
export const DELETE_LOCATION_GEOFENCE_SUCCESS = 'DELETE_LOCATION_GEOFENCE_SUCCESS';
export const DELETE_LOCATION_GEOFENCE_ERROR = 'DELETE_LOCATION_GEOFENCE_ERROR';
export const CANCEL_DELETE_LOCATION_GEOFENCE = 'CANCEL_DELETE_LOCATION_GEOFENCE';

//Validate and Get Nearest Location
export const Get_LOCATION_BOUNDARY = 'Get_LOCATION_BOUNDARY';
export const Get_LOCATION_BOUNDARY_SUCCESS = 'Get_LOCATION_BOUNDARY_SUCCESS';
export const Get_LOCATION_BOUNDARY_ERROR = 'Get_LOCATION_BOUNDARY_ERROR';
export const CANCEL_Get_LOCATION_BOUNDARY = 'CANCEL_Get_LOCATION_BOUNDARY';

// Save Location Geofence
export const SAVE_LOCATION_GEOFENCE = 'SAVE_LOCATION_GEOFENCE';
export const SAVE_LOCATION_GEOFENCE_SUCCESS = 'SAVE_LOCATION_GEOFENCE_SUCCESS';
export const SAVE_LOCATION_GEOFENCE_ERROR = 'SAVE_LOCATION_GEOFENCE_ERROR';
export const CANCEL_SAVE_LOCATION_GEOFENCE = 'CANCEL_SAVE_LOCATION_GEOFENCE';

// Get Location Contact
export const GET_LOCATION_CONTACT = 'GET_LOCATION_CONTACT';
export const GET_LOCATION_CONTACT_SUCCESS = 'GET_LOCATION_CONTACT_SUCCESS';
export const GET_LOCATION_CONTACT_ERROR = 'GET_LOCATION_CONTACT_ERROR';
export const CANCEL_GET_LOCATION_CONTACT = 'CANCEL_GET_LOCATION_CONTACT';

// Set Contact
export const SET_CONTACT = 'SET_CONTACT';
export const SET_CONTACT_SUCCESS = 'SET_CONTACT_SUCCESS';
export const SET_CONTACT_ERROR = 'SET_CONTACT_ERROR';
export const CANCEL_SET_CONTACT = 'CANCEL_SET_CONTACT';


// Set Location Contact
export const SET_LOCATION_CONTACT = 'SET_LOCATION_CONTACT';
export const SET_LOCATION_CONTACT_SUCCESS = 'SET_LOCATION_CONTACT_SUCCESS';
export const SET_LOCATION_CONTACT_ERROR = 'SET_LOCATION_CONTACT_ERROR';
export const CANCEL_SET_LOCATION_CONTACT = 'CANCEL_SET_LOCATION_CONTACT';
export const UPDATE_LOCATION_CONTACT = 'UPDATE_LOCATION_CONTACT';
export const UPDATE_LOCATION_CONTACT_SUCCESS = 'UPDATE_LOCATION_CONTACT_SUCCESS';


// Delete Location Contact
export const DELETE_LOCATION_CONTACT = 'DELETE_LOCATION_CONTACT';
export const DELETE_LOCATION_CONTACT_SUCCESS = 'DELETE_LOCATION_CONTACT_SUCCESS';
export const DELETE_LOCATION_CONTACT_ERROR = 'DELETE_LOCATION_CONTACT_ERROR';
export const CANCEL_DELETE_LOCATION_CONTACT = 'CANCEL_DELETE_LOCATION_CONTACT';

// Delete Location Contact
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';
export const CANCEL_DELETE_LOCATION = 'CANCEL_DELETE_LOCATION';



// LinkedShipper (linked-customer.actions.ts)
export const LOAD_LINKED_SHIPPERS = 'LOAD_LINKED_SHIPPERS';
export const LOAD_LINKED_SHIPPERS_SUCCESS = 'LOAD_LINKED_SHIPPERS_SUCCESS';
export const LOAD_LINKED_SHIPPERS_ERROR = 'LOAD_LINKED_SHIPPERS_ERROR';
export const CANCEL_LOAD_LINKED_SHIPPERS = 'CANCEL_LOAD_LINKED_SHIPPERS';

// UPDATE_SHIPMENT_ETA
export const UPDATE_SHIPMENT_ETA = 'UPDATE_SHIPMENT_ETA';
export const UPDATE_SHIPMENT_ETA_SUCCESS = 'UPDATE_SHIPMENT_ETA_SUCCESS';
export const UPDATE_SHIPMENT_ETA_ERROR = 'UPDATE_SHIPMENT_ETA_ERROR';


// DISPLAY_LOADER

export const DISPLAY_LOADER = 'DISPLAY_LOADER';
export const CANCEL_LOADER = 'CANCEL_LOADER';

// DASHBOARDS (dashboard.actions.ts)
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';
export const LOAD_DASHBOARD_SUCCESS = 'LOAD_DASHBOARD_SUCCESS';
export const LOAD_DASHBOARD_ERROR = 'LOAD_DASHBOARD_ERROR';
export const CANCEL_LOAD_DASHBOARD = 'CANCEL_LOAD_DASHBOARD';

// Set Dashboard
export const SET_DASHBOARD = 'SET_DASHBOARD';
export const SET_DASHBOARD_SUCCESS = 'SET_DASHBOARD_SUCCESS';
export const SET_DASHBOARD_ERROR = 'SET_DASHBOARD_ERROR';
export const CANCEL_SET_DASHBOARD = 'CANCEL_SET_DASHBOARD';


// Delete Dashboard
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const DELETE_DASHBOARD_SUCCESS = 'DELETE_DASHBOARD_SUCCESS';
export const DELETE_DASHBOARD_ERROR = 'DELETE_DASHBOARD_ERROR';
export const CANCEL_DELETE_DASHBOARD = 'CANCEL_DELETE_DASHBOARD';



// CUSTOMER DASHBOARDS (dashboard.actions.ts)
export const LOAD_CUSTOMER_DASHBOARD = 'LOAD_CUSTOMER_DASHBOARD';
export const LOAD_CUSTOMER_DASHBOARD_SUCCESS = 'LOAD_CUSTOMER_DASHBOARD_SUCCESS';
export const LOAD_CUSTOMER_DASHBOARD_ERROR = 'LOAD_CUSTOMER_DASHBOARD_ERROR';
export const CANCEL_LOAD_CUSTOMER_DASHBOARD = 'CANCEL_LOAD_CUSTOMER_DASHBOARD';

// Set Dashboard
export const SET_CUSTOMER_DASHBOARD = 'SET_CUSTOMER_DASHBOARD';
export const SET_CUSTOMER_DASHBOARD_SUCCESS = 'SET_CUSTOMER_DASHBOARD_SUCCESS';
export const SET_CUSTOMER_DASHBOARD_ERROR = 'SET_CUSTOMER_DASHBOARD_ERROR';
export const CANCEL_SET_CUSTOMER_DASHBOARD = 'CANCEL_SET_CUSTOMER_DASHBOARD';


// Delete Dashboard
export const DELETE_CUSTOMER_DASHBOARD = 'DELETE_CUSTOMER_DASHBOARD';
export const DELETE_CUSTOMER_DASHBOARD_SUCCESS = 'DELETE_CUSTOMER_DASHBOARD_SUCCESS';
export const DELETE_CUSTOMER_DASHBOARD_ERROR = 'DELETE_CUSTOMER_DASHBOARD_ERROR';
export const CANCEL_DELETE_CUSTOMER_DASHBOARD = 'CANCEL_DELETE_CUSTOMER_DASHBOARD';



export const LOAD_STATIC_MAP_LIBRARY = 'LOAD_STATIC_MAP_LIBRARY';
export const LOAD_STATIC_MAP_LIBRARY_SUCCESS = 'LOAD_STATIC_MAP_LIBRARY_SUCCESS';
export const LOAD_STATIC_MAP_LIBRARY_ERROR = 'LOAD_STATIC_MAP_LIBRARY_ERROR';
//Shutdown Tracker
export const SAVE_SHUTDOWN_TRACKER = 'SAVE_SHUTDOWN_TRACKER';
export const SAVE_SHUTDOWN_TRACKER_SUCCESS = 'SAVE_SHUTDOWN_TRACKER_SUCCESS';
export const SAVE_SHUTDOWN_TRACKER_ERROR = 'SAVE_SHUTDOWN_TRACKER_ERROR';
