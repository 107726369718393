import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { CreateShipmentRequest } from 'emr-ng-shared';
import { UpdateShipmentRequest } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IShipmentState } from 'app-modules/core/store/models/shipment-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';

import * as alertActions from 'app-modules/core/store/actions/alert.actions';

@Injectable()
export class AlertStateService {
    constructor(private store: Store<IAppState>) { }

    // Actions
    public closeAlert() {
        this.store.dispatch(new alertActions.CloseAlert());
    }

    public closeAlertSuccess(alertId: number) {
        this.store.dispatch(new alertActions.CloseAlertSuccess(alertId));
    }

    public closeAlertError() {
        this.store.dispatch(new alertActions.CloseAlertError());
    }

    public cancelCloseAlert() {
        this.store.dispatch(new alertActions.CancelCloseAlert());
    }
}


