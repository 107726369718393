import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IShipmentFilterShipmentName } from 'app-modules/core/store/models/shipment-filter-shipment-name.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { TrackerService } from 'app-modules/core/services/tracker.service';

@Component({
    selector: 'app-shipment-filter-shipment-name',
    templateUrl: 'shipment-filter-shipment-name.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterShipmentNameComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: string;
    @Input() get filter() { return this.filterVal; }
    @Output() filterChange = new EventEmitter();
    set filter(val) {
        this.filterVal = val;
        this.filterChange.emit(this.filterVal);
    }

    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    public shipmentNameOptions$: Observable<Tracker[]>;

    shipmentNameFilterSubscription: Subscription;
    
    constructor(
        private shpFltrSvc: ShipmentFilterService,
        public trackSvc: TrackerService
    ) { }

    ngOnInit() {
        const trackers$ = this.trackSvc.trackerList$.pipe(map(n => n.list));
        this.shipmentNameFilterSubscription = this.shpFltrSvc.shipmentNameFilter$.subscribe(n => this.onShipmentNameFilterSubscription(n));
        this.shipmentNameOptions$ = Observable.create(sub => {
            sub.next(this.filter);
        }).pipe(
            mergeMap((token: string) => {
                return trackers$.pipe(
                    map(trackers => {
                        return trackers.filter(n => n.shipmentName !== null &&
                            n.shipmentName.toLowerCase().indexOf(token.toLowerCase().trim()) >= 0);
                    })
                );
            })
        );
    }

    ngOnDestroy() {
        this.shipmentNameFilterSubscription.unsubscribe();
    }

    onShipmentNameFilterSubscription(filter: string) {
        this.filter = filter;
        this.isDirty = false;
    }

    onShipmentNameChanged() {
        this.isDirty = true;
    }
}


