import { ViewEncapsulation } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { SortService, VirtualScrollService } from '@syncfusion/ej2-angular-treegrid';
import { LoggerReadResponse, DateTimePipe } from 'emr-ng-shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [SortService, VirtualScrollService, DateTimePipe]
})
export class TagsComponent implements OnInit {

  preferenceSubscription: Subscription;
  read: LoggerReadResponse = null;
  @Input() dateFormat: string = 'dd/MM/yyyy h:mm tt';
  readTags: any;
  @Input()
  set loggerRead(read: LoggerReadResponse) {
    if (read) {
      this.bindReads(read);
    }
  };
  constructor() {
  }

  ngOnInit(): void {    
  }

  bindReads(read: LoggerReadResponse) {
    this.read = read;
    this.readTags = read?.readTags.map(obj => ({ ...obj, isParent: true }));
  }

  getQualityCodeStatusImage(qualityCode: number): string {
    var imageName = "";
    switch (qualityCode) {
      case 1:
      case 2:
        imageName = "PLMTagGreen.png";
        break;
      case 3:
        imageName = "PLMTagYellow.png";
        break;
      case 4:
        imageName = "PLMTagRed.png";
        break;
    }
    return imageName;
  }
}
