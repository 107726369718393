import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

import { FormattedStrings } from 'emr-ng-shared';

import { IPagingState } from 'app-modules/core/store/models/paging-state.interface';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { LocalizationService } from 'app-modules/core/services/localization.service';
import { PageSizeService } from 'app-modules/core/services/page-size-service';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class PaginationComponent implements OnInit, OnDestroy {
    @Input() itemCount$: Observable<number>;
    @Input() pagingState$: Observable<IPagingState>;
    @Input() showBoundaryLinks = true;
    @Input() IsUnAuthenticated = false;
    @Input()
    set shipmentList(value) {
        if (this.IsUnAuthenticated && value) {
            this.itemCount = value.length;
        }
    }

    @Input() isDropUp = false;

    itemCountSubscription: Subscription;
    pagerStateSubscription: Subscription;
    pageSizeSubscription: Subscription;

    numPages = 0;
    itemCount: number;
    pageSize: number;
    pageNumber: number;
    pageSizeList: IListInfo<FormattedStrings> = emptyList();

    @Output() PageSelected = new EventEmitter<number>();
    @Output() PageSizeSelected = new EventEmitter<number>();

    // DisplayList = [
    //     { text: '25 per page', value: 25 },
    //     { text: '50 per page', value: 50 },
    //     { text: '100 per page', value: 100 },
    // ];

    constructor(private pageSizeSvc: PageSizeService) { }

    ngOnInit() {
        this.itemCountSubscription = this.itemCount$.subscribe(a => {
            this.itemCount = a;
        });

        this.pagerStateSubscription = this.pagingState$.subscribe(a => {
            this.pageSize = a.pageSize;
            this.pageNumber = a.pageNumber;
        });
        if (!this.IsUnAuthenticated) {
            this.pageSizeSubscription = this.pageSizeSvc.pageSizeList$.subscribe(a => {
                this.pageSizeList = a;
            });
        }

        this.showBoundaryLinks = Math.round(this.itemCount / this.pageSize) <= 1;
    }

    ngOnDestroy() {
        if (this.itemCountSubscription) {
            this.itemCountSubscription.unsubscribe();
        }
        if (this.pagerStateSubscription) {
            this.pagerStateSubscription.unsubscribe();
        }
        if (this.pageSizeSubscription) {
            this.pageSizeSubscription.unsubscribe();
        }
    }

    onPageChanged(event: PageChangedEvent) {
        this.PageSelected.emit(event.page);
    }

    onPageSizeChanged() {
        this.PageSizeSelected.emit(this.pageSize);
    }
}



