import { Injectable, Inject, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { of } from 'rxjs';
import { tap, switchMap, map, skip, take, finalize, catchError } from 'rxjs/operators';

import { muteFirst, Fullstory } from 'emr-ng-shared';
import { LoginRequest, ChangePasswordRequest, AuthorizeExternalRequest } from 'emr-ng-shared';
import { LoginResponse, ChangePasswordResponse, ErrorResponse, AuthorizeExternalResponse } from 'emr-ng-shared';

import { AuthStateService } from 'app-modules/core/store/auth/auth-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';

import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { Store } from '@ngrx/store';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { environment } from 'environments/environment';
import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { intl } from 'environments/intl';
import { BASE_LOCALE_ID } from 'app-modules/core/consts/locale-id';
import { PakSenseAuthService } from '../paksense-auth/ps-auth.service';
import { PAKSENSE_UI_REDIRECT_URL, STORE_KEYS } from 'app-modules/core/consts/values';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { CustomerStateService } from '../services/customer-state.service';
import { GoogleTagManager } from 'app-modules/core/services/google-tag-manager.service';


@Injectable()
export class AuthService implements OnDestroy {
    constructor(
        private authStateSvc: AuthStateService,
        private oversightSvc: OversightApiService,
        private store: Store<IAppState>,
        public fullstory: Fullstory,
        private pakSenseAuthSvc: PakSenseAuthService,
        private custStateSvc: CustomerStateService,
        @Inject(BASE_LOCALE_ID) private locale: string,
        private googleTagManager: GoogleTagManager
    ) { }

    requestedURL: string;
    private authSubscription: Subscription;
    private refreshTokenSubscription: Subscription;
    public authState$ = this.authStateSvc.authState$;
    public authenticated$ = this.authStateSvc.authenticated$;
    public authToken$ = this.authStateSvc.authToken$;
    public isEmersonAdmin$ = this.authStateSvc.isEmersonAdmin$;
    public canCreateShipment$ = this.authStateSvc.canCreateShipment$;
    public isPasswordChangeRequired$ = this.authStateSvc.isPasswordChangeRequired$;
    public canEditTripName$ = this.authStateSvc.canEditTripName$;
    public isDashboardUser$ = this.authStateSvc.isDashboardUser$;
    public isAdminUser$ = this.authStateSvc.isAdminUser$;
    public isLocusUserSupportAdmin$ = this.authStateSvc.isLocusUserSupportAdmin$;
    public hideSideMenu = new Subject<boolean>();
    private userTokenDateExpireSub = new BehaviorSubject<Date>(null);
    userTokenDateExpires$ = this.userTokenDateExpireSub.asObservable().pipe();
    public isEDIAPIConsumer$ = this.authStateSvc.isEDIAPIConsumer$;
    shipmentParams: any;

    ngOnDestroy() {
        if (this.refreshTokenSubscription) {
            this.refreshTokenSubscription.unsubscribe();
        }
        if (this.authSubscription) {
            this.authSubscription.unsubscribe();
        }
    }

    public signIn(username: string, password: string, rememberMe: boolean): Observable<boolean> {
        return muteFirst(
            this.invokeSignIn(username, password, rememberMe),
            this.authStateSvc.authenticated$.pipe(skip(1))
        );
    }

    public signInExternal(externalAuthToken: string): Observable<boolean> {
        return muteFirst(
            this.invokeSignInExternal(externalAuthToken),
            this.authStateSvc.authenticated$.pipe(skip(1))
        );
    }

    public getUnAuthenticationToken() {
        return this.oversightSvc.getUnAuthenticationToken();
    }

    public signOut() {
        this.authStateSvc.signOut();
    }

    public refreshToken() {
        this.refreshTokenSubscription = this.invokeApiRefreshToken().pipe(take(1)).subscribe();
    }

    private invokeSignIn(username: string, password: string, rememberMe: boolean): Observable<LoginResponse> {
        return of(null).pipe(
            tap(n => this.authStateSvc.signIn()),
            switchMap(n => this.invokeApiSignIn(username, password)),
            tap(
                n => {
                    this.processLoginResponse(n, username, rememberMe);
                },
                e => this.authStateSvc.signInError(e)
            ),
            switchMap(n => {
                return of(n);
            })
        );
    }

    public redirectPakSenseAuth(authToken: string, userName: string) {
        const pakSenseURL = `${environment.psUI_URL}${PAKSENSE_UI_REDIRECT_URL}?apiToken=${authToken}&userName=${userName}`;
        this.signOut();
        window.location.href = pakSenseURL;
    }

    private hasOversightAccess(auth: LoginResponse) {
        return auth && (auth.CanAccessOversight ||
            auth.CanCreateShipment ||
            auth.CanCreateShipment ||
            auth.IsEmersonAdmin);
    }

    private invokeSignInExternal(externalAuthToken: string): Observable<AuthorizeExternalResponse> {
        return of(null).pipe(
            tap(n => this.authStateSvc.signIn()),
            switchMap(n => this.invokeApiSignInExternal(externalAuthToken)),
            tap(
                n => {
                    this.processLoginResponse(n, n.Username, false);
                    if (n.CanAccessPakSense && n.PSAuthToken) {
                        if (!this.hasOversightAccess(n)) {
                            this.redirectPakSenseAuth(n.PSAuthToken, n.Username);
                        } else {
                            this.pakSenseAuthSvc.processLoginResponse({ ApiToken: n.PSAuthToken }, n.Username);
                        }
                    }
                },
                e => this.authStateSvc.signInError(e)
            )
        );
    }

    private processLoginResponse(response: LoginResponse, username: string, rememberMe: boolean) {
        // Dispatch signing success
        this.authStateSvc.signInSuccess(
            response.AccessToken,
            response.RefreshToken,
            username,
            username,
            response.TemperatureUnits,
            response.DistanceUnits,
            response.DateFormatString, // Should be replaced with DateFormatEnum
            'MM/dd/yyyy',
            response.IsEmersonAdmin,
            response.CanCreateShipment,
            rememberMe,
            response.ProviderUserKey,
            response.IsPasswordChangeRequired,
            response.CanAccessOversight,
            response.CanAccessPakSense,
            response.CanEditTripName,
            response.IsLocusUserSupportAdmin,
            response.IsLocusUser,
            response.IsDashboardUser,
            response.IsAdminUser,
            response.IsEDIAPIConsumer,
            response.IsLocusSupport);

        // Store in local storage
        const localStoreLoginData = {
            AccessToken: response.AccessToken,
            RefreshToken: response.RefreshToken,
            'username': username,
            TemperatureUnits: response.TemperatureUnits,
            DistanceUnits: response.DistanceUnits,
            DateFormatString: response.DateFormatString, // Should be replaced with DateFormatEnum
            DateFormat: 'MM/dd/yyyy',
            IsEmersonAdmin: response.IsEmersonAdmin,
            IsDashboardUser: response.IsDashboardUser,
            CanCreateShipment: response.CanCreateShipment,
            RememberMe: rememberMe,
            ProviderUserKey: response.ProviderUserKey,
            IsPasswordChangeRequired: response.IsPasswordChangeRequired,
            CanAccessOversight: response.CanAccessOversight,
            CanAccessPakSense: response.CanAccessPakSense,
            CanEditTripName: response.CanEditTripName,
            IsLocusUserSupportAdmin: response.IsLocusUserSupportAdmin,
            IsLocusUser: response.IsLocusUser,
            ExpiryDate: response.ExpiryDate,
            IsAdminUser: response.IsAdminUser,
            IsEDIAPIConsumer: response.IsEDIAPIConsumer,
            IsLocusSupport: response.IsLocusSupport
        };
        localStorage.setItem(environment.authPersistentData, JSON.stringify(localStoreLoginData));
        this.setTokenExpireDate(response.ExpiryDate);
    }

    public forgotPassword(username: string): Observable<ErrorResponse> {
        return of(null).pipe(
            tap(n => this.authStateSvc.forgotPassword()),
            switchMap(n => this.invokeApiForgotPassword(username)),
            tap(
                n => this.authStateSvc.forgotPasswordSuccess(),
                e => this.authStateSvc.forgotPasswordError(e)
            ),
            finalize(() => this.authStateSvc.cancelForgotPassword()),
            catchError(() => of(null)),
        );
    }

    private invokeApiForgotPassword(username: string): Observable<ErrorResponse> {
        const request = ({
            UserName: username,
            Password: 'null'
        }) as LoginRequest;

        return this.oversightSvc.ForgotPassword(request);
    }

    private invokeApiSignIn(username: string, password: string): Observable<LoginResponse> {
        const request = ({
            UserName: username,
            Password: password
        }) as LoginRequest;

        return this.oversightSvc.Login(request).pipe(
            handleErrorResponse(),
            map<LoginResponse, LoginResponse>(
                n => n
            )
        );
    }

    private invokeApiSignInExternal(externalAuthToken: string): Observable<AuthorizeExternalResponse> {

        const request = ({
            ExternalAuthToken: externalAuthToken,
        }) as AuthorizeExternalRequest;

        return this.oversightSvc.AuthorizeExternal(request).pipe(
            handleErrorResponse(),
            map<AuthorizeExternalResponse, AuthorizeExternalResponse>(
                n => n
            )
        );
    }

    public changePassword(currentPassword, NewPassword): Observable<ChangePasswordResponse> {
        return of(null).pipe(
            tap(n => this.authStateSvc.changePassword()),
            switchMap(n => this.invokeApiChangePassword(currentPassword, NewPassword)),
            tap(
                n => this.authStateSvc.changePasswordSuccess(),
                e => this.authStateSvc.changePasswordError(e)
            ),
            finalize(() => this.authStateSvc.cancelChangePassword()),
            catchError(() => of(null)),
        );
    }

    private invokeApiChangePassword(currentPassword: string, NewPassword: string): Observable<ChangePasswordResponse> {
        const request = ({
            EncryptedCurrentPassword: currentPassword,
            EncryptedNewPassword: NewPassword,
            IsSSL: false
        }) as ChangePasswordRequest;
        return this.oversightSvc.ChangePassword(request);
    }

    private invokeApiRefreshToken(): Observable<LoginResponse> {
        return this.oversightSvc.RefreshToken().pipe(
            handleErrorResponse(),
            map<LoginResponse, LoginResponse>(
                n => n
            ),
            tap(
                n => {
                    this.authStateSvc.RefreshToken(
                        n.AccessToken);

                    const localStorageData = JSON.parse(localStorage.getItem(environment.authPersistentData));
                    if (localStorageData) {
                        localStorageData.AccessToken = n.AccessToken;
                        localStorageData.IsEmersonAdmin = n.IsEmersonAdmin;
                        localStorageData.CanCreateShipment = n.CanCreateShipment;
                        localStorageData.CanAccessOversight = n.CanAccessOversight;
                        localStorageData.CanAccessPakSense = n.CanAccessPakSense;
                        localStorageData.CanEditTripName = n.CanEditTripName;
                        localStorageData.IsDashboardUser = n.IsDashboardUser;
                        localStorageData.IsLocusUserSupportAdmin = n.IsLocusUserSupportAdmin;
                        localStorageData.IsLocusUser = n.IsLocusUser;
                        localStorageData.ExpiryDate = n.ExpiryDate;
                        localStorageData.IsAdminUser = n.IsAdminUser;
                        localStorageData.IsEDIAPIConsumer = n.IsEDIAPIConsumer;
                        localStorage.setItem(environment.authPersistentData, JSON.stringify(localStorageData));
                    }
                    this.setTokenExpireDate(n.ExpiryDate);
                },
                e => this.authStateSvc.signInError(e)
            )
        );
    }

    public validatePageAccess(url: string, authState: IAuthState): boolean {
        let hasAccess = false;
        switch (url) {
            case 'mapview':
            case 'soa':
                hasAccess = authState.canAccessOversight || authState.isEmersonAdmin;
                break;
            case 'selectcustomer':
                hasAccess = authState.isEmersonAdmin || authState.canAccessOversight ||
                    authState.canCreateShipment || authState.canEditTripName || authState.isEDIAPIConsumer;
                break;
            case 'createshipment':
                hasAccess = !authState.isEmersonAdmin && !authState.canAccessOversight && authState.canCreateShipment;
                break;
            case 'updateshipment':
                hasAccess = !authState.isEmersonAdmin && !authState.canAccessOversight && authState.canEditTripName;
                break;
            case 'importlocations':
            case 'tripreport':
                hasAccess = authState.isEmersonAdmin && authState.canAccessOversight;
                break;
            case 'reports':
            case 'locations':
            case 'templates':
                hasAccess = authState.canAccessOversight;
                break;
            case 'manage':
                hasAccess = true;
                break;
            case 'routedeviation':
            case 'routeedit':
            case 'unauthorized':
            case 'changepassword':
                hasAccess = authState.authenticated;
                break;
            case 'dashboard':
            case 'scorecard':
                hasAccess = authState.authenticated && (authState.isEmersonAdmin || authState.isDashboardUser || authState.isAdminUser);
                break;
            case 'historical':
                hasAccess = authState.canAccessOversight || authState.isEmersonAdmin;
                break;
            case '':
            case '/':
                hasAccess = true;
                break;
            case 'ediapitest':
                hasAccess = authState.authenticated && (authState.isEmersonAdmin || authState.isEDIAPIConsumer || authState.isAdminUser);
                break;
            case 'recycling':
                hasAccess = authState.authenticated;
                break;
        }
        return hasAccess;
    }

    public getDefaultRoute(): string {
        let url = '/';
        const authState = this.getAuthState();
        const isCustomerSelected = this.getCustomerState();
        if (authState && authState.authenticated) {
            if (intl.isInternationalized) {
                if (this.requestedURL && this.requestedURL.indexOf(this.locale + '/') !== -1) {
                    this.requestedURL = this.requestedURL.slice(3);
                }
            }
            if (authState.canAccessOversight || authState.isEmersonAdmin) {
                if (this.requestedURL && this.requestedURL !== '/' && this.requestedURL !== '/selectcustomer') {
                    url = this.requestedURL;
                } else {
                    url = 'mapview';
                }
            } else if (!authState.isEmersonAdmin && !authState.canAccessOversight && authState.canCreateShipment) {
                url = 'createshipment';
            } else if (!authState.isEmersonAdmin && !authState.canAccessOversight && authState.canEditTripName) {
                url = 'updateshipment';
            } else if (authState.isEDIAPIConsumer && !isCustomerSelected) {
                url = '/selectcustomer';
            } else if (authState.isEDIAPIConsumer && isCustomerSelected) {
                url = '/manage/ediapitest';
            } else {
                url = 'unauthorized';
            }
        } else {
            url = 'signin';
        }
        return url;
    }

    private getAuthState(): IAuthState {
        let authState;
        this.authSubscription = this.authState$.pipe(take(1)).subscribe(a => {
            authState = a;
        });
        return authState;
    }
    private getCustomerState(): boolean {
        let isCustomerSelected: boolean = false;
        this.custStateSvc.isSelected$.pipe(take(1)).subscribe(k => {
            isCustomerSelected = k;
        })
        return isCustomerSelected;
    }

    public updateMenuStatus(value: boolean) {
        this.hideSideMenu.next(value);
    }

    public setRequestedURL(url) {
        this.requestedURL = url;
    }
    public getRequestedURL() {
        return this.requestedURL;
    }

    public setUserLoginState(isUserLoggedIn) {
        this.authStateSvc.setUserLoginState(isUserLoggedIn);
    }

    public setTokenExpireDate(expiryTime: Date) {
        this.userTokenDateExpireSub.next(expiryTime);
    }

    public updateRequestedParams() {
        if (!this.shipmentParams) {
            var queryDict = {}
            location.search?.toLowerCase()?.substr(1).split("&").forEach(function (item) { queryDict[item.split("=")[0]] = item.split("=")[1] });
            if (window.location.pathname.endsWith('/createshipment') && queryDict['customer'] && queryDict['id']) {
                this.setRequestedParams(queryDict);
            } else {
                // maintain old state and only clear from map view once loaded.
                this.setRequestedParams(this.getRequestedParams());
            }
        }
    }

    public setRequestedParams(params) {
        this.shipmentParams = params;
        if (params) {
            StoreManager.StoreValue(STORE_KEYS.createShipmentOptions, JSON.stringify(params),
                // tslint:disable-next-line: no-bitwise
                StoreOpts.LocalStorage | StoreOpts.Cookie | StoreOpts.StoreManager);
        } else {
            StoreManager.ClearValue(STORE_KEYS.createShipmentOptions);
        }
    }
    public getRequestedParams() {
        if (!this.shipmentParams) {
            const paramVal = StoreManager.ReadStoreValue(STORE_KEYS.createShipmentOptions);
            if (paramVal) {
                this.shipmentParams = JSON.parse(paramVal);
            }
        }
        return this.shipmentParams;
    }
}


