import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ShowDistanceService } from 'app-modules/core/services/distance-to.service';
import { LocationInfo } from 'emr-ng-shared';
import { GetDistanceToTargetsRequest } from 'emr-ng-shared';
import { DistanceToTargetDetails } from 'app-modules/core/models/distance-to-target-details.model';
import { GetRouteRequest } from 'emr-ng-shared';
import { GetRouteResponse } from 'emr-ng-shared';
import { RouteRequestInfo } from 'emr-ng-shared';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-show-distance',
  templateUrl: './show-distance.component.html',
  styleUrls: ['./show-distance.component.css']
})
export class ShowDistanceComponent implements OnInit {

  // Begin - missing bound variabels:
  Location: any;
  VehicleLocation: any;
  Address: any;
  // End - missing bound variables.
  constructor(
    private showDistanceSvc: ShowDistanceService,
    private sanitizer: DomSanitizer) { }

  @Input() distanceToTargetsRequest: GetDistanceToTargetsRequest;
  @Output() CancelClick: EventEmitter<void> = new EventEmitter();
  distanceToTargetDetails$: Observable<DistanceToTargetDetails>;
  distanceTargetDetails: DistanceToTargetDetails;
  routeDetails$: Observable<GetRouteResponse>;
  originLocation: RouteRequestInfo;
  showDetails = false;
  shipmentName: string;
  fullAddress: string;
  IsUnAuthenticated = false;

  ngOnInit() {
    this.distanceToTargetDetails$ = this.showDistanceSvc.GetDistanceTo(this.distanceToTargetsRequest).pipe(
      map(details => {
        if (details.ErrorCode === 0) {
          this.distanceTargetDetails = this.showDistanceSvc.GetDistanceDetails(details);
          if (this.distanceTargetDetails) {
            if (this.distanceTargetDetails.StartDestination) {
              this.shipmentName = this.distanceTargetDetails.StartDestination.ShipmentName;
              this.fullAddress = this.distanceTargetDetails.StartDestination.FullAddress;
              this.originLocation = new RouteRequestInfo(this.distanceTargetDetails.StartDestination);
            }
            this.Address = this.distanceTargetDetails.DefaultLocation ? this.distanceTargetDetails.DefaultLocation.FullAddress : null;
          }
        }
        return details;
      })
    );
    this.IsUnAuthenticated = this.showDistanceSvc.getUnAuthToken();

  }

  tripDestinationShowDistanceClick() {
    if (this.distanceTargetDetails && this.originLocation) {
      this.updateRouteDetails(new RouteRequestInfo(this.distanceTargetDetails.TripDestination));
    }
  }

  locationShowDistanceClick(destination: LocationInfo) {
    if (destination && this.originLocation) {
      this.updateRouteDetails(new RouteRequestInfo(destination));
    }
  }

  shipmentShowDistanceClick(destination: LocationInfo) {
    if (destination && this.originLocation) {
      const destinationObj = new LocationInfo();
      destinationObj.FullAddress = destination.Description;
      this.updateRouteDetails(new RouteRequestInfo(destinationObj));
    }
  }

  addressShowDistanceClick(destinationAddress: string) {
    if (destinationAddress && this.originLocation) {
      const destination = new LocationInfo();
      destination.FullAddress = destinationAddress;
      this.updateRouteDetails(new RouteRequestInfo(destination));
    }
  }

  onCancelClick() {
    this.CancelClick.emit();
  }

  onCloseClick() {
    this.showDetails = false;
    this.routeDetails$ = new Observable<GetRouteResponse>();
  }

  private updateRouteDetails(destination: RouteRequestInfo) {
    this.routeDetails$ = new Observable<GetRouteResponse>();
    const routeRequest = new GetRouteRequest();
    routeRequest.Origin = this.originLocation;
    routeRequest.Destination = destination;
    this.routeDetails$ = this.showDistanceSvc.GetShowDistanceDetails(routeRequest);
    this.showDetails = true;
  }

  getHTMLValue(val: string) {
    if (val) {
      val = val
        .replace(/&lt;/gi, '<')
        .replace(/&gt;/gi, '>');
    }
    return this.sanitizer.bypassSecurityTrustHtml(val);
  }
}


