<div class="inset">
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('en')">English</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('pt')">Portuguese</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('es')">Español (Spanish)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('it')">Italiano (Italian)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('de')">Deutsch (German)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('tr')">Türk (Turkish)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('zh')">中文 (Chinese)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('ru')">русский (Russian)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('fr')">Français (French)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('ko')">한국어 (Korean)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('pl')">Polski (Polish)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('th')">ไทย (Thai)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('ar')">عربى (Arabic)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('ja')">日本語 (Japanese)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('vi')">Tiếng Việt (Vietnamese)</button>
    <!-- <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('zh-hans')">简体中文 (Chinese - Simplified)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('zh-hant')">繁體中文 (Chinese - Traditional)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('hi')">हिंदी (Hindi)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('bn')">বাংলা (Bangla)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('te')">తెలుగు (Telugu)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('mr')">मराठी (Marathi)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('ta')">தமிழ் (Tamil)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('ur')">اردو (Urdu)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('kn')">ಕನ್ನಡ (Kannada)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('gu')">ગુજરાતી (Gujarati)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('ml')">മലയാളം (Malayalam)</button>
    <button class="btn btn-lg btn-outline-primary btn-block" type="button" (click)="onLocaleSelect('th')">ไทย (Thai)</button> -->
</div>
    