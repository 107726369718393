import { Component, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Shipment } from 'app-modules/core/models/shipment.model';

export class CloseShipmentPopupEventArgs {
    modalRef: BsModalRef;
    hasError: boolean;
    buttonType: boolean;
}

@Component({
    selector: 'app-close-shipment-popup',
    templateUrl: './close-shipment-popup.component.html',
    styleUrls: ['./close-shipment-popup.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class CloseShipmentPopupComponent {
    @Output() ok = new EventEmitter<CloseShipmentPopupEventArgs>();

    hasError = false;
    errorMessage: string = null;
    shipment: Shipment;
    showCloseButton: boolean;

    constructor(public bsModalRef: BsModalRef) { }

    onClickYes() {
        const args = new CloseShipmentPopupEventArgs();
        args.modalRef = this.bsModalRef;
        args.hasError = this.hasError;
        args.buttonType = true;
        this.ok.emit(args);
    }
    onClickNo() {
        const args = new CloseShipmentPopupEventArgs();
        args.modalRef = this.bsModalRef;
        args.hasError = this.hasError;
        args.buttonType = false;
        this.ok.emit(args);
    }
}
