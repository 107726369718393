import { IMarkerOptions, ILatLong } from 'emr-ng-shared';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';

export class CompareTraxxModel {
    TrackerId: string;
    CustomerTrackerId?: string;
    TripId?: number;
    ModelName?: string;
    markers: Array<IMarkerOptions>;
    polylinePath: Array<ILatLong>;
    Id: number;
    Visible: boolean;
    TraxxState: ITraxxState;
    Color: string;
    pushpinIcon: string;
    ErrorMessage: string;
    get DisplayName(): string {
        return this.TripId ? `${this.TrackerId}-(${this.TripId})` : this.TrackerId;
    }
    get HasError(): boolean {
        return this.ErrorMessage && this.ErrorMessage.length > 0;
    }
}
