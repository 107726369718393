import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IEUserMessageComponent } from '../components/ie-user-message/ie-user-message.component';
import { IEUserMessagePopup } from '../models/ie-user-message-popup.model';


@Injectable()
export class IEUserMessageService extends IEUserMessagePopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenIEUserMessagePopup(): void {
        const initialState = {};

        this.bsModalRef = this.modalSvc.show(
            IEUserMessageComponent,
            {
                initialState,
                class: 'modal-lg modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}
