import { IFormattedStringState } from 'app-modules/core/store/models/formatted-Strings-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as formattedStringsActions from 'app-modules/core/store/formatted-strings/formatted-strings.actions';

type Actions =
    authActions.SignOut |
    formattedStringsActions.LoadFormattedStrings |
    formattedStringsActions.LoadFormattedStringsSuccess |
    formattedStringsActions.LoadFormattedStringsError;

export function FormattedStringReducer(state: IFormattedStringState = initialState.formattedStrings, action: Actions): IFormattedStringState {
    switch (action.type) {
        case actionIndex.LOAD_FORMATTED_STRINGS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_FORMATTED_STRINGS_SUCCESS:
            return {
                ...state,
                formattedStringList: action.formattedStringList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_FORMATTED_STRINGS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
