import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import {
    SetLocationRequest, DualVisibilityLinkCustomer, Customer, TripTemplateInfo, LocationInfo, GetTripTemplateDetailsResponse,
    SetSensorRangeRequest, SensorRangeInfo, CarrierListInfo, ValidateDVShipmentRequest, ValidateDVShipmentResponse, Lookup
} from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';

import * as createActions from 'app-modules/core/store/create-shipment/create-shipment.actions';
import * as locActions from 'app-modules/core/store/create-shipment/location/create-shipment-location.actions';
import * as templateActions from 'app-modules/core/store/create-shipment/template/create-shipment-template.actions';
import * as sensorRangeActions from 'app-modules/core/store/create-shipment/sensor-range/create-shipment-sensor-range.actions';
import * as carrierActions from 'app-modules/core/store/create-shipment/carrier/create-shipment-carrier.actions';
import * as trackActions from 'app-modules/core/store/create-shipment/tracker/create-shipment-tracker.actions';
import * as trackerSensorActions from 'app-modules/core/store/actions/set-tracker-sensor-ranges';
import * as templateDetailsActions from 'app-modules/core/store/create-shipment/template-details/create-shipment-template-details-action';


import { CustomerStateService } from 'app-modules/core/store/services/customer-state.service';
import { LocationService } from 'app-modules/core/services/location.service';
import { SensorRangeService } from '../sensor-range/sensor-range.service';
@Injectable()
export class CreateShipmentStateService {
    constructor(
        private store: Store<IAppState>,
        private custStateSvc: CustomerStateService,
        private locationSvc: LocationService,
        private sensorRangeSvc: SensorRangeService
    ) { }

    // Selectors
    public createShipmentStateSelector = ((state: IAppState) => state.createShipment);
    public selectedLinkedCustomerSelector = createSelector(
        this.createShipmentStateSelector,
        (state) => state.linkedCustomer
    );

    // public locationStateSelector = ((state: IAppState) => state.createShipmentLocation);
    // public isLocationLoadRequiredSelector = createSelector(
    //     this.locationStateSelector,
    //     (state) => state.isLoadRequired
    // );
    // public isLoadingSelector = createSelector(
    //     this.locationStateSelector,
    //     (state) => state.isLoading
    // );
    // public locationListSelector = createSelector(
    //     this.locationStateSelector,
    //     (state) => state.locationList
    // );
    // If locationList is ever paged in the database, originList must become a separate list
    // public originListSelector = createSelector(
    //     this.locationListSelector,
    //     this.selectedLinkedCustomerSelector,
    //     (state: IListInfo<LocationInfo>, selectedLinkedCustomer?: DualVisibilityLinkCustomer) => {
    //         let filteredList = state.list.filter(a => a.IsOrigin);
    //         if (selectedLinkedCustomer !== undefined && selectedLinkedCustomer !== null && selectedLinkedCustomer.CustomerId !== null) {
    //             filteredList = filteredList.filter((loc) => {
    //                 return loc.LinkedCustomerId !== undefined && loc.LinkedCustomerId !== null;
    //             });
    //         }

    //         return <IListInfo<LocationInfo>>{
    //             list: filteredList,
    //             itemCount: filteredList.length,
    //             isPaged: false
    //         };
    //     }
    // );
    // // If locationList is ever paged in the database, stopList must become a separate list
    // public stopListSelector = createSelector(
    //     this.locationListSelector,
    //     (state) => {
    //         const filteredList = state.list; // No filter needed on stops //.filter(a => a.IsOrigin && a.IsDestination);

    //         return <IListInfo<LocationInfo>>{
    //             list: filteredList,
    //             itemCount: filteredList.length,
    //             isPaged: false
    //         };
    //     }
    // );
    // // If locationList is ever paged in the database, destinationList must become a separate list
    // public destinationListSelector = createSelector(
    //     this.locationListSelector,
    //     this.selectedLinkedCustomerSelector,
    //     (state: IListInfo<LocationInfo>, selectedLinkedCustomer?: DualVisibilityLinkCustomer) => {
    //         let filteredList  = state.list.filter(a => a.IsDestination);
    //         if (selectedLinkedCustomer !== undefined && selectedLinkedCustomer !== null && selectedLinkedCustomer.CustomerId !== null) {
    //             filteredList = filteredList.filter((loc) => {
    //                 return loc.LinkedCustomerId === undefined || loc.LinkedCustomerId === null;
    //             });
    //         }

    //         return <IListInfo<LocationInfo>>{
    //             list: filteredList,
    //             itemCount: filteredList.length,
    //             isPaged: false
    //         };
    //     }
    // );


    // public shipmentTemplateStateSelector = ((state: IAppState) => state.createShipmentTemplate);
    // public isShipmentTemplateLoadRequiredSelector = createSelector(
    //     this.shipmentTemplateStateSelector,
    //     (state) => state.isLoadRequired
    // );
    // public shipmentTemplatesSelector = createSelector(
    //     this.shipmentTemplateStateSelector,
    //     (state) => state.shipmentTemplateList
    // );


    // public sensorRangeStateSelector = ((state: IAppState) => state.createShipmentSensorRange);
    // public isSensorRangeLoadRequiredSelector = createSelector(
    //     this.sensorRangeStateSelector,
    //     (state) => state.isLoadRequired
    // );
    // public sensorRangeListSelector = createSelector(
    //     this.sensorRangeStateSelector,
    //     (state) => state.sensorRangeList
    // );


    public carrierStateSelector = ((state: IAppState) => state.createShipmentCarrier);
    public isCarrierLoadRequiredSelector = createSelector(
        this.carrierStateSelector,
        (state) => state.isLoadRequired
    );
    public carrierListSelector = createSelector(
        this.carrierStateSelector,
        (state) => state.carrierList
    );

    public trackerStateSelector = ((state: IAppState) => state.createShipmentTracker);
    public isTrackerLoadRequiredSelector = createSelector(
        this.trackerStateSelector,
        (state) => state.isLoadRequired
    );
    public isTrackerLoadingSelector = createSelector(
        this.trackerStateSelector,
        (state) => state.isLoading
    );
    public trackerListSelector = createSelector(
        this.trackerStateSelector,
        (state) => state.trackerList
    );

    // Observables
    public selectedLinkedCustomer$ = this.store.select(this.selectedLinkedCustomerSelector).pipe();

    // public isLocationLoadRequired$ = this.store.select(this.isLocationLoadRequiredSelector).pipe();
    // public locationList$ = this.store.select(this.locationListSelector).pipe();
    // public originList$ = this.store.select(this.originListSelector).pipe();
    // public stopList$ = this.store.select(this.stopListSelector).pipe();
    // public destinationList$ = this.store.select(this.destinationListSelector).pipe();

    // public isSensorRangeLoadRequired$ = this.store.select(this.isSensorRangeLoadRequiredSelector).pipe();
    // public sensorRangeList$ = this.store.select(this.sensorRangeListSelector).pipe();

    // public isShipmentTemplateLoadRequired$ = this.store.select(this.isShipmentTemplateLoadRequiredSelector).pipe();
    // public shipmentTemplateList$ = this.store.select(this.shipmentTemplatesSelector).pipe();

    public isCarrierLoadRequired$ = this.store.select(this.isCarrierLoadRequiredSelector).pipe();
    public carrierList$ = this.store.select(this.carrierListSelector).pipe();

    public isTrackerLoading$ = this.store.select(this.isTrackerLoadingSelector).pipe();
    public isTrackerLoadRequired$ = this.store.select(this.isTrackerLoadRequiredSelector).pipe();
    public trackerList$ = this.store.select(this.trackerListSelector).pipe();

    // Actions
    public selectLinkedCustomer(linkedCustomer: DualVisibilityLinkCustomer) {
        this.store.dispatch(new createActions.SelectLinkedCustomer(linkedCustomer));
    }

    public loadLocationList() {
        this.store.dispatch(new locActions.LoadLocationList());
    }

    public loadLocationListSuccess(locationList: IListInfo<LocationInfo>) {
        this.store.dispatch(new locActions.LoadLocationListSuccess(locationList));
    }

    public loadLocationListError(message: string) {
        this.store.dispatch(new locActions.LoadLocationListError(message));
    }

    public cancelLoadLocationList() {
        this.store.dispatch(new locActions.CancelLoadLocationList());
    }

    public createLocation(request: SetLocationRequest) {
        this.store.dispatch(new locActions.CreateLocation(request));
    }

    public createLocationSuccess(locationId: number, locationRequest: SetLocationRequest): LocationInfo {
        const locationInfo = this.locationSvc.getLocationInfo(locationRequest, locationId);
        this.store.dispatch(new locActions.CreateLocationSuccess(locationInfo));
        return locationInfo;
    }

    public createLocationError(message: string) {
        this.store.dispatch(new locActions.CreateLocationError(message));
    }

    public loadShipmentTemplates() {
        this.store.dispatch(new templateActions.LoadShipmentTemplates());
    }

    public loadShipmentTemplatesSuccess(listInfo: IListInfo<TripTemplateInfo>) {
        this.store.dispatch(new templateActions.LoadShipmentTemplatesSuccess(listInfo));
    }

    public loadShipmentTemplatesError(message: string) {
        this.store.dispatch(new templateActions.LoadShipmentTemplatesError(message));
    }

    public cancelLoadShipmentTemplates() {
        this.store.dispatch(new templateActions.CancelLoadShipmentTemplates());
    }

    // Sensor Ranges Actions
    public loadSensorRangeList() {
        this.store.dispatch(new sensorRangeActions.LoadSensorRangeList());
    }

    public loadSensorRangeListSuccess(sensorRangeList: IListInfo<SensorRangeInfo>) {
        this.store.dispatch(new sensorRangeActions.LoadSensorRangeListSuccess(sensorRangeList));
    }

    public loadSensorRangeListError(message: string) {
        this.store.dispatch(new sensorRangeActions.LoadSensorRangeListError(message));
    }

    public cancelLoadSensorRangeList() {
        this.store.dispatch(new sensorRangeActions.CancelLoadSensorRangeList());
    }

    public createSensorRange(request: SetSensorRangeRequest) {
        this.store.dispatch(new sensorRangeActions.CreateSensorRange(request));
    }

    public createSensorRangeSuccess(request: SetSensorRangeRequest, sensorRangeId: number, description: string): SensorRangeInfo {
        const sensorRangeInfo = this.sensorRangeSvc.getSensorRangeInfo(request, sensorRangeId, description);
        this.store.dispatch(new sensorRangeActions.CreateSensorRangeSuccess(sensorRangeInfo));
        return sensorRangeInfo;
    }

    public createSensorRangeError(message: string) {
        this.store.dispatch(new sensorRangeActions.CreateSensorRangeError(message));
    }

    // Carrier Actions
    public loadCarrierList() {
        this.store.dispatch(new carrierActions.LoadCarrierList());
    }

    public loadCarrierListSuccess(carrierList: Lookup[]) {
        this.store.dispatch(new carrierActions.LoadCarrierListSuccess(carrierList));
    }

    public AddorUpdateCarrierSuccess(carrierlookUpId: number, carrier: Lookup) { 
        this.store.dispatch(new carrierActions.AddorUpdateCarrierSuccess(carrierlookUpId, carrier));
    }

    public RemoveCarrierSuccess(carrierlookUpId: number) { 
        this.store.dispatch(new carrierActions.RemoveCarrierSuccess(carrierlookUpId));
    }

    public loadCarrierListError(message: string) {
        this.store.dispatch(new carrierActions.LoadCarrierListError(message));
    }

    public cancelLoadCarrierList() {
        this.store.dispatch(new carrierActions.CancelLoadCarrierList());
    }

    // Load Trackers
    public loadTrackers() {
        this.store.dispatch(new trackActions.LoadTrackers());
    }

    public loadTrackersSuccess(trackerList: IListInfo<Tracker>) {
        this.store.dispatch(new trackActions.LoadTrackersSuccess(trackerList));
    }

    public loadTrackersError(message: string) {
        this.store.dispatch(new trackActions.LoadTrackersError(message));
    }

    public cancelLoadTrackers() {
        this.store.dispatch(new trackActions.CancelLoadTrackers());
    }

    // Load Trackers PULL
    public loadPullTrackers() {
        this.store.dispatch(new trackActions.LoadPullTrackers());
    }

    public loadPullTrackersSuccess(trackerList: IListInfo<Tracker>) {
        this.store.dispatch(new trackActions.LoadPullTrackersSuccess(trackerList));
    }

    public loadPullTrackersError(message: string) {
        this.store.dispatch(new trackActions.LoadPullTrackersError(message));
    }

    public cancelLoadPullTrackers() {
        this.store.dispatch(new trackActions.CancelLoadPullTrackers());
    }

    // Validate DV Shipment
    public validateDVShipment(request: ValidateDVShipmentRequest) {
        this.store.dispatch(new createActions.ValidateDVShipment(request));
    }

    public validateDVShipmentSuccess(response: ValidateDVShipmentResponse) {
        this.store.dispatch(new createActions.ValidateDVShipmentSuccess(response));
    }

    public validateDVShipmentError(message: string) {
        this.store.dispatch(new createActions.ValidateDVShipmentError(message));
    }

    public cancelvalidateDVShipment() {
        this.store.dispatch(new createActions.CancelValidateDVShipment());
    }

    //Template Details
    public loadTemplateDetails() {
        this.store.dispatch(new templateDetailsActions.LoadTemplateDetails());
    }

    public loadTemplateDetailsSuccess(listInfo: GetTripTemplateDetailsResponse) {
        this.store.dispatch(new templateDetailsActions.LoadTemplateDetailsSuccess(listInfo));
    }

    public loadTemplateDetailsError(message: string) {
        this.store.dispatch(new templateDetailsActions.LoadTemplateDetailsError(message));
    }

    public cancelLoadTemplateDetails() {
        this.store.dispatch(new templateDetailsActions.CancelLoadTemplateDetails());
    }


    // Get Tracker Sensors
    public loadTrackerSensors() {
        this.store.dispatch(new createActions.LoadTrackerSensors());
    }

    public loadTrackerSensorsSuccess() {
        this.store.dispatch(new createActions.LoadTrackerSensorsSuccess());
    }

    public loadTrackerSensorsError() {
        this.store.dispatch(new createActions.LoadTrackerSensorsError());
    }

    public cancelLoadTrackerSensors() {
        this.store.dispatch(new createActions.CancelLoadTrackerSensors());
    }

    cancelSetTrackerSensorRange(): void {
        this.store.dispatch(new trackerSensorActions.CancelSetTrackerSensorRange());
    }
    setTrackerSensorRangeError(): void {
        this.store.dispatch(new trackerSensorActions.SetTrackerSensorRangeError());
    }
    setTrackerSensorRangeSuccess(): void {
        this.store.dispatch(new trackerSensorActions.SetTrackerSensorRangeSuccess());
    }
    setTrackerSensorRange() {
        this.store.dispatch(new trackerSensorActions.SetTrackerSensorRange());
    }
}

