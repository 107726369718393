import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { Subscription } from 'rxjs';

import { LocationInfo } from 'emr-ng-shared';

import { IShipmentFilterLocation } from 'app-modules/core/store/models/shipment-filter-location.interface';

import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { LocationService } from 'app-modules/core/services/location.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { origin } from 'app-modules/core/consts/localization';

class LocationInfoListItem {
    get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
    get value() { return this.locationInfo.LocationId; }
    constructor(public locationInfo: LocationInfo) { }
}

@Component({
    selector: 'app-shipment-filter-origin-summary',
    templateUrl: 'shipment-filter-origin-summary.component.html',
    styleUrls: ['shipment-filter-origin-summary.component.css']
}) export class ShipmentFilterOriginSummaryComponent implements OnInit, OnDestroy {
    originList: LocationInfo[] = [];
    filter: number[];
    selectedList: LocationInfoListItem[] = [];

    originListSubscription: Subscription;
    originFilterSubscription: Subscription;
    @Input() shipmentFilter: IShipmentFilterState;
    @Input() isHugeCustomer: boolean;
    @Output() summaryRemove = new EventEmitter();

    constructor(
        private locationSvc: LocationService,
        private filterSvc: ShipmentFilterService
    ) { }

    ngOnInit() {
        this.originListSubscription = this.locationSvc.originList$.subscribe(n => this.onOriginListChange(n.list));
        this.originFilterSubscription = this.filterSvc.originFilter$.subscribe(n => this.onOriginFilterChange(n));
    }

    ngOnDestroy() {
        this.originListSubscription.unsubscribe();
        this.originFilterSubscription.unsubscribe();
    }

    onOriginListChange(originList: LocationInfo[]) {
        this.originList = originList;
        this.filterOriginList();
    }

    onOriginFilterChange(filter: number[]) {
        this.filter = filter;
        this.filterOriginList();
    }

    filterOriginList() {
        this.selectedList = [];

        if (this.filter) {
            this.originList.forEach(item => {
                if (this.filter.indexOf(item.LocationId) >= 0) {
                    this.selectedList.push(new LocationInfoListItem(item));
                }
            });
        }
    }

    clearSelectedFilter(LocationId: number) {
        this.shipmentFilter.origin = this.shipmentFilter.origin.filter(k => k !== LocationId);
        this.filterSvc.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        if (this.summaryRemove) {
            this.summaryRemove.emit({ title: origin, value: this.shipmentFilter.origin });
        }
    }
}


