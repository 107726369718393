import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sigma-dashboard',
  templateUrl: './sigma-dashboard.component.html',
  styleUrls: ['./sigma-dashboard.component.css']
})
export class SigmaDashboardComponent implements OnInit {

  @Input() embedUrl: string;
  public hasError: boolean = false;

  constructor() {}

  ngOnInit() {}

  onIframeLoad() {
    this.hasError = false;
  }

  onIframeError() {
    this.hasError = true;
  }

}
