import { Action } from '@ngrx/store';
import * as actionIndex from 'app-modules/core/store/actions';

import { ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';


export class LoadShowDistance implements Action {
    readonly type = actionIndex.LOAD_SHOW_DISTANCE;
    constructor() { }
}

export class LoadShowDistanceError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHOW_DISTANCE_ERROR;
    readonly callingAction = actionIndex.LOAD_SHOW_DISTANCE;
    constructor() { }
}

export class LoadShowDistancetStateSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHOW_DISTANCE_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHOW_DISTANCE;
    constructor() { }
}

export class CancelLoadShowDistance implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SHOW_DISTANCE;
    readonly callingAction = actionIndex.LOAD_SHOW_DISTANCE;
}
