import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ElementRef, TemplateRef, ViewEncapsulation } from '@angular/core';

import { CustomTableColumnDefs, Shipment } from 'emr-ng-shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { groupBy } from 'rxjs/operators';

export enum AggregateType {
    AVG,
    MIN,
    MAX,
    SUM,
    COUNT
}

@Component({
    selector: 'custom-table',
    templateUrl: './custom-table.component.html',
    styleUrls: ['./custom-table.component.css']
})
export class CustomTableComponent implements OnInit, OnDestroy {
    @Input() public columnDefs: CustomTableColumnDefs[];
    groupedColumnDef: CustomTableColumnDefs[] = [];
    nonGroupedColumnDef: CustomTableColumnDefs[] = [];
    isShipmentsLoading: boolean = false;
    list: any;
    groupedList: any;
    groupArray: string[] = [];//, 'tripStatus', 'sensorRange','origin'
    shipmentList: any;
    @Input() set isLoading(value) {
        this.isShipmentsLoading = value;;
    }
    @Input() set data(data) {
        if (data) {
            this.list = data;
            if (this.sort) {
                this.sortColumn = this.sort.column;
                this.direction = this.sort.direction;
                this.list = _.orderBy(this.list, [this.sort.sortableColumn], [this.sort.direction ? 'asc' : 'desc'])
            }
            this.groupArray = this.getGroupList();
            if (this.groupArray.length > 0) {
                this.groupedList = this.groupByMulti(this.list, this.groupArray);
            }
            this.copiedList = data;
        }
    };
    @Input() divClass = 'full-height';
    @Input() tBodyId = null;
    @Input() divHeight = null;
    @Input() showColumnSelection = true;
    @Input() public selectableRow = false;
    @Input() calculateColumnCount = true;
    selectedRow: any;
    selectedColumns: string[];
    LastAppliedFilter: string;

    @Output() rowClick = new EventEmitter();
    @Output() buttonClick = new EventEmitter();
    @ViewChild('columnSelectionModal') columnSelectionModal: TemplateRef<any>;
    @Output() filterApply = new EventEmitter();
    modalRef: BsModalRef;
    copiedList: any;
    sortColumn: string;
    direction: boolean;
    columnCheckboxes: any;
    @Input() applyFilter = false;
    @Output() columnDefsChange = new EventEmitter();
    @Input() sort: any;
    // @Input() isGrouped: boolean = true;

    constructor(
        private modalService: BsModalService
    ) {
    }

    ngOnInit() {
        this.groupedColumnDef = this.columnDefs.filter(x => x.groupOrder > 0);
        this.nonGroupedColumnDef = this.columnDefs.filter(x => x.groupOrder == null);
        this.selectedColumns = [];
        this.columnCheckboxes = [];
        if (this.list) {
            this.setFilterCounts();
        }
    }

    setFilterCounts() {
        if (this.columnDefs && this.columnDefs.length > 0) {
            const filterCols = this.columnDefs.filter(k => k.isFilter);
            if (this.list.length > 0) {
                this.list.forEach(a => {
                    filterCols.forEach(b => {
                        if (a[b.value]) {
                            const valueMatcher = new RegExp(`^${a[b.valueField]}$`, 'i');
                            const listData = b.list.find(k => valueMatcher.test(k.value));
                            if (!listData) {
                                b.list.push({ text: a[b.value], value: a[b.valueField], count: 1, checked: false });
                            } else {
                                listData.count = listData.count + 1;
                            }
                        }
                    });
                });
            } else {
                filterCols.forEach(b => { b.list = []; });
            }
            this.columnDefs.map(k => {
                if (k.isVisible) {
                    this.selectedColumns.push(k.title);
                } if (k.title) {
                    this.columnCheckboxes.push(k)
                }
            });
            if (this.calculateColumnCount) {
                setTimeout(() => {
                    this.bindFilterList();
                    this.calculateFilterCounts(this.list, false);
                }, 10);
            }

        }
    }

    calculateFilterCounts(data: Shipment[], isFiltered: boolean) {
        let filteredColumnDef = [];
        if (isFiltered) {
            filteredColumnDef = [...this.columnDefs, this.columnDefs.filter(s => s.title === this.LastAppliedFilter).map(s => s.filteredList = null)];
        } else {
            filteredColumnDef = [...this.columnDefs];
        }
        filteredColumnDef.forEach(b => {
            if (!b.isFilter) { return; }
            if (b.value) {
                if (!((b.title === this.LastAppliedFilter) && isFiltered)) {
                    b.list.forEach(a => {
                        const valueMatcher = new RegExp(`^${a.value}$`, 'i');
                        a.count = data.filter(k => valueMatcher.test(k[b.valueField]))?.length;
                    });
                    b.filteredList = _.orderBy(b.list.filter(i => i.checked || i.count > 0), ['count', 'checked'], ['desc', 'desc']);
                }
            }
        });
    }

    getGroupList() {
        var arr = [];
        var sortOrder = this.columnDefs.sort(x => x.groupOrder);
        sortOrder.map(column => {
            if (column.groupOrder > 0)
                arr.push(column.value)
        })
        return arr;
    }

    groupByMulti(list, values) {
        var array = [];
        if (!values.length)
            return list;
        var byFirst = _.groupBy(list, values[0]),
            rest = values.slice(1);
        for (var prop in byFirst) {
            const result = this.groupByMulti(byFirst[prop], rest);
            const length = rest.length === 0 ? result.length : _.sumBy(result, 'length');
            const newVal = { property: prop, list: result, isLastGroup: rest.length === 0, length };
            array.push(newVal);
        }
        return array;
    };

    public ngOnDestroy() {
    }

    onRowClick(data) {
        this.selectedRow = data;
        this.rowClick.emit(data);
    }

    onButtonClick(data) {
        this.buttonClick.emit(data);
    }

    openColumnSelectionModal() {
        this.modalRef = this.modalService.show(
            this.columnSelectionModal,
            {
                class: 'modal-xl modal-dialog-centered',
                ignoreBackdropClick: true,
                keyboard: false
            },
        );
    }

    onColumnChangeAccept() {
        this.modalRef.hide();
        this.columnDefs.map(a => {
            if (a.title) {
                a.isVisible = false
            }
        });
        this.selectedColumns.map(k => this.columnDefs.find(a => a.title === k).isVisible = true);
        this.columnDefsChange.emit({ columns: this.columnDefs, sort: null, isColumnChange: true });
    }

    onDropComplete(dragInfo, dropInfo) {
        const prevIndex = dragInfo.dragData.columnOrder;
        const nextIndex = dropInfo.columnOrder;
        const isMovedRight = prevIndex < nextIndex;
        for (let i = nextIndex; isMovedRight ? i > prevIndex : i < prevIndex; isMovedRight ? i-- : i++) {
            const col = this.columnDefs.find(k => k.columnOrder === i);
            if (col) {
                col.newOrder = i + (isMovedRight ? -1 : 1);
            }
        }
        this.columnDefs.find(k => k === dragInfo.dragData).columnOrder = nextIndex;
        this.columnDefs.forEach(k => {
            if (k.newOrder) {
                k.columnOrder = k.newOrder;
            }
            k.newOrder = null;
        });
        this.columnDefs = _.orderBy(this.columnDefs, ['columnOrder'], ['asc']);
        this.columnDefsChange.emit({ columns: this.columnDefs, sort: null, isColumnChange: true });
    }

    onFilterCheckboxChange(column) {
        column.filterModel = [];
        column.selectAll = false;
        column.list.map(k => {
            if (k.checked) {
                column.filterModel.push(k.value)
                // column.filteredList.filter(x => x.value === k.value)[0].checked = true;
            } else {
                // column.filteredList.filter(x => x.value === k.value)[0].checked = false;
            }
        });
    }

    onFilterApply(isClear: boolean, map: CustomTableColumnDefs) {
        this.list = _.cloneDeep(this.copiedList);
        if (isClear) {
            map.filterModel = [];
            map.list.map(k => k.checked = false);
            map.selectAll = false;
            if (this.LastAppliedFilter === map.title) {
                this.LastAppliedFilter = null;
            }
        } else {
            this.LastAppliedFilter = map.title;
        }
        map.filterAppliedModel = map.filterModel;
        map.filteredList = _.orderBy(map.list.filter(i => i.checked || i.count > 0), ['checked', 'count'], ['desc', 'desc']);
        if (this.applyFilter) {
            // this.applyFilterForAll();
            this.columnDefs.map(a => {
                if (a.filterModel && a.filterModel?.length > 0) {
                    let data: any[] = [];
                    if (Array.isArray(a.filterModel)) {
                        a.filterModel.map(filter => {
                            data = _.union(data, this.list.filter(k => k[a.valueField] === filter));
                        })
                        this.list = data;
                    } else {
                        this.list = this.list.filter(k => k[a.valueField] === a.filterModel);
                    }
                }
                // a.isFilterListUpdated = true;
            });
        }
        this.groupedList = this.groupByMulti(this.list, this.groupArray);
        if (this.calculateColumnCount) {
            setTimeout(() => {
                this.bindFilterList();
                this.calculateFilterCounts(this.list, true);
            })
        }
        if (this.filterApply) {
            this.filterApply.emit(map);
        }
    }


    onSelectAll(map: CustomTableColumnDefs) {
        if (map.selectAll) {
            map.filterModel = map.filteredList.map(k => k.value);
            map.filteredList.map(m => m.checked = true);
        } else {
            map.filterModel = [];
            map.filteredList.map(m => m.checked = false);
        }
    }

    onSort(map, element) {
        const elementClasses = element.target.classList;
        if (!map.isSortable || elementClasses.contains('fa-filter') || elementClasses.contains('table-filter-header')) {
            return false;
        } else {
            this.sortList(map.value, map.sortableColumn);
        }
    }

    sortList(sortBy: string, sortableColumn: string) {
        // const order: string[] = this.direction ?  : ['desc'];
        this.direction = !this.direction;
        this.sortColumn = sortBy;
        const colSort = sortableColumn || sortBy;
        if (this.direction) {
            this.list = _.orderBy(this.list, [colSort], ['asc']);
        } else {
            this.list = _.orderBy(this.list, [colSort], ['desc']);
        }
        this.groupedList = this.groupByMulti(this.list, this.groupArray);
        const sort = { column: this.sortColumn, sortableColumn: colSort, direction: this.direction };
        this.columnDefsChange.emit(
            { columns: this.columnDefs, sort, isColumnChange: false });
    }

    onDropDownStatusChange(column: CustomTableColumnDefs) {
        column.filterModel = column.filterAppliedModel;
        column.list.map(k => {
            const valueMatcher = new RegExp(`^${k.value}$`, 'i');
            if (column.filterModel?.some(v => valueMatcher.test(v))) {
                k.checked = true;
            } else {
                k.checked = false;
            }
        });
        column.filteredList = _.orderBy(column.list?.filter(i => i.checked || i.count > 0), ['count', 'checked'], ['desc', 'desc']);
        column.selectAll = column.filterModel && (column.filterModel.length > 0 && column.filterModel.length === column.filteredList.length);
    }

    bindFilterList() {
        this.columnDefs.filter(k => k.isFilter).map(b => {
            if (b.title !== this.LastAppliedFilter) {
                b.list.map(a => {
                    if (b.value) {
                        const valueMatcher = new RegExp(`^${a[b.valueField]}$`, 'i');
                        a.count = this.list.filter(k => valueMatcher.test(k[b.valueField]))?.length;
                    }
                })
                b.filteredList = _.orderBy(b.list.filter(i => i.checked || i.count > 0), ['count', 'checked'], ['desc', 'desc']);
            }
        })
    }

    getNonNullableValue(value: string, property: string) {
        const definedNullValue = this.columnDefs.filter(x => x.value === property)[0].nullValue;
        if (!value || value === "null" || value === "undefined" || value === undefined || value.trim().length === 0) {
            return definedNullValue ? definedNullValue : ''
        } else {
            return value;
        }
    }

    getAggregatedValue(property: string, aggregateType: number, aggregateUnit: string, expectedCustomValue: any, list: object[]) {
        let calculatedValue = 0;
        let propType = typeof list[0][property];
        let listed = []; 
        listed = list.map(a => { return a[property] ? a[property] : 0 });
        if (propType != 'number') {
            listed = listed.map(x => Number(x.toString().replaceAll(/[^0-9&&^.&&^-]/g, "")));
        }
        switch (aggregateType) {
            case AggregateType.AVG:
                calculatedValue = (_.sum(listed) / listed.length);
                break;
            case AggregateType.MAX:
                calculatedValue = Math.max(...listed);
                break;
            case AggregateType.MIN:
                calculatedValue = Math.min(...listed);
                break;
            case AggregateType.SUM:
                calculatedValue = listed.reduce(function (a, b) {
                    return a + b;
                }, 0);
                break;
            case AggregateType.COUNT:
                if (expectedCustomValue) {
                    listed = listed.filter(a => a === expectedCustomValue);
                }
                calculatedValue = listed.length;
                break;
        }
        return calculatedValue.toString().indexOf('.') > 0 ? Number(calculatedValue).toFixed(2) + aggregateUnit : calculatedValue + aggregateUnit;
    }
}

