import { Injectable } from '@angular/core';
import { Shipment } from './shipment.model';
import { ITraxxState } from '../store/models/traxx-state.interface';
import { GetRouteDeviationResponseModel } from 'emr-ng-shared';

@Injectable()
export abstract class ShipmentRoutePopup {
    abstract OpenShipmentRoutePopup(shipment?: Shipment): void;
    abstract OpenShipmentRouteDeviationPopup(getRouteDeviationResponse?: GetRouteDeviationResponseModel): void;
}
