import { GetUserSettingRequest, UserSettingsType } from "emr-ng-shared";

export const USER_SETTING_SECTION_SETTINGS = 'Settings';
export const USER_SETTING_SECTION_REPORT_SENSOR = 'ReportSensor';
export const USER_SETTING_KEY_CUSTOM_FILTERS = 'CustomFilters';
export const USER_SETTING_KEY_MIN_YAXIS = 'MinYAxis';
export const USER_SETTING_KEY_MAX_YAXIS = 'MaxYAxis';
export const USER_SETTING_KEY_DASHBOARD_TRIAL_START= 'TrialStartDtm';
export const USER_SETTING_KEY_DASHBOARD_TRIAL_DECLINED= 'TrialDeclined';
export const USER_SETTING_KEY_DASHBOARD_PREMIUM_DECLINED= 'PremiumDeclined';

export const USER_SETTING_REQEUST: GetUserSettingRequest[]=
[
   { Section: USER_SETTING_SECTION_SETTINGS, Key: USER_SETTING_KEY_CUSTOM_FILTERS, SettingType: UserSettingsType.String },
   { Section: USER_SETTING_SECTION_REPORT_SENSOR, Key: USER_SETTING_KEY_MIN_YAXIS, SettingType: UserSettingsType.Double },
   { Section: USER_SETTING_SECTION_REPORT_SENSOR, Key: USER_SETTING_KEY_MAX_YAXIS, SettingType:  UserSettingsType.Double },

];

export const USER_SETTING_REQEUST_MIN_YAXIS = USER_SETTING_REQEUST.find(x=>x.Key ===USER_SETTING_KEY_MIN_YAXIS);
export const USER_SETTING_REQEUST_MAX_YAXIS = USER_SETTING_REQEUST.find(x=>x.Key ===USER_SETTING_KEY_MAX_YAXIS);
export const USER_SETTING_REQEUST_CUSTOM_FILTERS = USER_SETTING_REQEUST.find(x=>x.Key ===USER_SETTING_KEY_CUSTOM_FILTERS);

export const USER_SETTING_REQUEST_DASHBOARD_TRIAL_START = { Section: USER_SETTING_SECTION_SETTINGS, Key: USER_SETTING_KEY_DASHBOARD_TRIAL_START, SettingType: UserSettingsType.String };
export const USER_SETTING_REQUEST_DASHBOARD_TRIAL_DECLINED = { Section: USER_SETTING_SECTION_SETTINGS, Key: USER_SETTING_KEY_DASHBOARD_TRIAL_DECLINED, SettingType: UserSettingsType.Bool };
export const USER_SETTING_REQUEST_DASHBOARD_PREMIUM_DECLINED ={ Section: USER_SETTING_SECTION_SETTINGS, Key: USER_SETTING_KEY_DASHBOARD_PREMIUM_DECLINED, SettingType: UserSettingsType.Bool };

