import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { TimeZoneInfo, FormattedStrings, FormatString, TimeFormatEnum } from 'emr-ng-shared';
import { TimeZoneService } from 'app-modules/core/store/time-zone/time-zone.service';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';
import { FormattedStringService } from 'app-modules/core/store/formatted-strings/formatted-strings.service';
import { UnAuthUserSettings } from 'app-modules/core/models/unauth-user-settings.model'
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { Router } from '@angular/router';
import { CustomersService } from 'app-modules/core/services/customer.service';

export enum DateFormatString {
  DateFormatMMDDYYYY = 'MM/dd/yyyy',
  DateFormatDDMMYYYY = 'dd/MM/yyyy'
}

export enum TimeFormatString {
  TimeFormat12Hour = 'h:mm tt',
  TimeFormat12HourWithSeconds = 'h:mm:ss tt',
  TimeFormat24Hour = 'HH:mm',
  TimeFormat24HourWithSeconds = 'HH:mm:ss',
}


@Component({
  selector: 'app-unauth-customer-settings',
  templateUrl: './unauth-customer-settings.component.html',
  styleUrls: ['./unauth-customer-settings.component.css']
})
export class UnauthCustomerSettingsComponent implements OnInit, OnDestroy {
  uSettings: UnAuthUserSettings = {
    dateFormatEnum: 1,
    timeZoneID: 'UTC',
    temperatureUnits: 'C',
    distanceUnits: 'M',
    timeFormatEnum: 1
  };
  timezoneList$: Observable<TimeZoneInfo[]>;
  dateFormatList$: Observable<FormattedStrings[]>;

  isTimeZoneLoading$: Observable<boolean>;
  isDateFormatLoading$: Observable<boolean>;
  unAuthUserSubscription: Subscription;
  customerSelectionSubscription: Subscription;

  tempCLocalized = $localize`:@@temp_C:°C`;
  tempFLocalized = $localize`:@@temp_F:°F`;

  constructor(
    private timeZoneSvc: TimeZoneService,
    private formatStringSvc: FormattedStringService,
    private unAuthSvc: UnAuthService,
    private shipSvc: ShipmentService,
    private prefSvc: PreferenceService,
    private custSvc: CustomersService) {
    this.unAuthUserSubscription = this.unAuthSvc.authUserSettings$.subscribe(x => {
      if (x) {
        this.uSettings.temperatureUnits = x.temperatureUnits;
        this.uSettings.distanceUnits = this.getDistanceUnits(x.distanceUnits);
        this.uSettings.dateFormatEnum = Number(x.dateFormatEnum);
        this.uSettings.timeZoneID = x.timeZoneID;
        this.uSettings.timeFormatEnum = Number(x.timeFormatEnum);
        this.setCustomerPreferenceSettings();
      }
    })
  }

  ngOnInit() {
    const localStorageData = JSON.parse(localStorage.getItem(this.unAuthSvc.getUnAuthenticationToken() ? environment.unAuthUserSettings : environment.AuthUserSettings));
    if (localStorageData) {
      this.uSettings.temperatureUnits = localStorageData.uTemperatureUnits;
      this.uSettings.distanceUnits = localStorageData.uDistanceUnits;
      this.uSettings.dateFormatEnum = Number(localStorageData.uDateFormatEnum);
      this.uSettings.timeZoneID = localStorageData.uTimeZoneID;
      this.uSettings.timeFormatEnum = Number(localStorageData.uTimeFormatEnum);
    } else if (!this.unAuthSvc.getUnAuthenticationToken()) {
      this.customerSelectionSubscription = combineLatest(this.custSvc.isSelected$,
        this.prefSvc.isPreferenceLoadRequired$).pipe(
          filter(([isSelected, isLoading]) => isSelected && !isLoading),
          switchMap(() => this.prefSvc.preference$.pipe(take(1)))).subscribe(k => {
            const authLocalStorageData = JSON.parse(localStorage.getItem(environment.AuthUserSettings));
            if (!authLocalStorageData) {
              this.prefSvc.preference$.pipe(take(1)).subscribe(k => {
                this.uSettings.temperatureUnits = k.temperatureUnits;
                this.uSettings.distanceUnits = this.getDistanceUnits(k.distanceUnits);
                this.uSettings.dateFormatEnum = k.dateFormatEnum;
                this.uSettings.timeZoneID = k.timeZoneId;
                this.uSettings.timeFormatEnum = k.timeFormatEnum;
              })
            }
          })
    }

    this.isTimeZoneLoading$ = this.timeZoneSvc.isLoading$;
    this.isDateFormatLoading$ = this.formatStringSvc.isLoading$;
    this.timezoneList$ = this.timeZoneSvc.timeZoneList$.pipe(map(n => n.list));
    this.dateFormatList$ = this.formatStringSvc.DateFormattedStringList$.pipe(map(n => n.list));
  }

  ngOnDestroy() {
    // if (this.timezoneListSub) {
    //   this.timezoneListSub.unsubscribe();
    // }
    // if (this.dateFormatListSub) {
    //   this.dateFormatListSub.unsubscribe();
    // }
    if (this.unAuthUserSubscription) {
      this.unAuthUserSubscription.unsubscribe();
    }
    this.customerSelectionSubscription?.unsubscribe();
  }

  getDateFormatString(dateFormat?: number): string {
    let dateFormatString: string = null;
    switch (dateFormat) {
      case 1:
        dateFormatString = DateFormatString.DateFormatMMDDYYYY;
        break;
      case 2:
        dateFormatString = DateFormatString.DateFormatDDMMYYYY;
        break;
    }
    return dateFormatString;
  }

  getTimeFormatString(timeFormat?: number): string {
    let timeFormatString: string = null;
    switch (timeFormat) {
      case 1:
        timeFormatString = TimeFormatString.TimeFormat12Hour;
        break;
      case 2:
        timeFormatString = TimeFormatString.TimeFormat24Hour;
        break;
    }
    return timeFormatString;
  }

  getDistanceUnits(distanceUnit?: string): string {
    let distUnit: string = null;
    switch (distanceUnit) {
      case "miles":
      case "m":
        distUnit = 'M';
        break;
      case "km":
      case "k":
      case "kilometers":
        distUnit = 'K';
        break;
    }
    return distUnit;
  }

  getTempUnitsString(tempUnits: string) {
    let tempUnitsString: string = this.tempCLocalized;
    switch (tempUnits) {
      case 'F':
        tempUnitsString = this.tempFLocalized;
        break;
      default:
        tempUnitsString = this.tempCLocalized;
    }
    return tempUnitsString;
  }

  setCustomerPreferenceSettings() {
    if (!this.unAuthSvc.sameAsPreviousSettings(this.uSettings)) {
      const localStoreUSettingsData = this.getLocalUserSettingData();
      this.prefSvc.updatePreference(localStoreUSettingsData);
    }
  }

  getLocalUserSettingData(): any {
    return {
      uTemperatureUnits: this.uSettings.temperatureUnits,
      uTemperatureUnitString: this.getTempUnitsString(this.uSettings.temperatureUnits),
      uDistanceUnits: this.uSettings.distanceUnits,
      uDateFormatEnum: this.uSettings.dateFormatEnum.toString(),
      uDateFormatString: this.getDateFormatString(this.uSettings.dateFormatEnum),
      uTimeZoneID: this.uSettings.timeZoneID,
      uTimeFormatEnum: this.uSettings.timeFormatEnum.toString(),
      uTimeFormatString: this.getTimeFormatString(this.uSettings.timeFormatEnum)
    };
  }

  async setUserSettings() {
    this.unAuthSvc.hideSettingsMenu(true);
    if (!this.unAuthSvc.sameAsPreviousSettings(this.uSettings)) {
      const localStoreUSettingsData = this.getLocalUserSettingData();
      localStorage.setItem(this.unAuthSvc.getUnAuthenticationToken() ? environment.unAuthUserSettings : environment.AuthUserSettings, JSON.stringify(localStoreUSettingsData));
      this.prefSvc.updatePreference(localStoreUSettingsData);
      if (this.unAuthSvc.getUnAuthenticationToken()) {
        this.shipSvc.ReLoadShipments();
      } else {
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // const currentUrl = this.router.url;
        // this.router.navigateByUrl('/', { skipLocationChange: true });
        // this.router.navigate([currentUrl]);
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        window.location.reload();
      }
    }
  }
}
