import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-u-shipment-details',
  templateUrl: './u-shipment-details.component.html',
  styleUrls: ['./u-shipment-details.component.css']
})
export class UShipmentDetailsComponent implements OnInit {

  selectedShipment: Shipment;
  constructor(private r: ActivatedRoute) {
    this.r.paramMap
      .pipe(map(_ => window.history.state))
      .subscribe(s => {
        this.selectedShipment = s?.shipment;
      });
   }

  ngOnInit(): void {
  }



}
