import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { FormattedStrings } from 'emr-ng-shared';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadFormattedStrings implements Action {
    readonly type = actionIndex.LOAD_FORMATTED_STRINGS;
}

export class LoadFormattedStringsSuccess implements Action {
    readonly type = actionIndex.LOAD_FORMATTED_STRINGS_SUCCESS;
    constructor(public formattedStringList: IListInfo<FormattedStrings>) { }
}

export class LoadFormattedStringsError implements Action {
    readonly type = actionIndex.LOAD_FORMATTED_STRINGS_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadFormattedStrings implements Action {
    readonly type = actionIndex.CANCEL_LOAD_FORMATTED_STRINGS;
}

