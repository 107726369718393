import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { reportTypeDesc } from 'app-modules/core/consts/report-type';
import { ScheduledReportParams } from 'app-modules/core/models/scheduled-report-params.model';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { ScheduleReportService } from 'app-modules/core/store/schedule-report/schedule-report.service';
import { TimePeriodService } from 'app-modules/core/store/time-period/time-period.service';
import { AlertReportParams } from 'app-modules/reports/models/alert-report.model';
import { AlertActionStatus, AlertEscalationLevel, AlertSeverity, DateTimeObject, EmrUtilService, ReportType, 
  ScheduleReportRequest, TimePeriod, TimePeriodInfo, TKeyValuePair, UserSettingsType, EmrNumericValidator } from 'emr-ng-shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert-report',
  templateUrl: './alert-report.component.html',
  styleUrls: ['./alert-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AlertReportComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm, { static: true }) frmAlertReport: NgForm;

  @Input() selectedReportType: ReportType;
  reportParams: AlertReportParams;
  dateTimeObject = new DateTimeObject();
  dateTimePreferenceSubscription: Subscription;
  isValidNumeric = EmrUtilService.validateNumeric;
  public isDateRangeValid = true;

  public isFormSubmitted = false;

  private _validate = false;
  isLocusAdmin: boolean;
  alertStatusList: any[];
  alertSeverityList: any[];
  alertEscalationsList: any[];
  prevEsLvls: any;

  @Input() public set validate(val: any) {
    this.reValidationForm();
    this.isFormSubmitted = !val?.validate;
    this._validate = val;
  };
  public get validate(): any {
    return this._validate;
  }

  minDate: Date = new Date(2008,0,1);
  maxDate: Date = new Date(9999,11,31);
  public periodList: TimePeriodInfo[];
  private periodListSubscription: Subscription;
  private authStateSub: Subscription;

  @Input() public isNewScheduleReport = false;

  @Output() reportRequestParamsChange = new EventEmitter();
  reportRequest: ScheduleReportRequest;
  @Input() set reportRequestParams(val) {
    this.reportRequest = val;
  }
  @Input()
  set savedParams(val: TKeyValuePair[]) {
    this.setFormReportParams(val);
  }

  constructor(
    private prefSvc: PreferenceService,
    private renderer: Renderer2,
    public periodSvc: TimePeriodService,
    private utilSvc: EmrUtilService,
    private authSvc: AuthService,
    public datepipe: DatePipe,
    public reportSvc: ScheduleReportService,
  ) {

    this.renderer.addClass(document.body, 'bg-trip-report');
    this.reportParams = new AlertReportParams();
    this.alertStatusList = this.reportSvc.alertActionStatusList;
    this.alertSeverityList = this.reportSvc.severityList;
    this.alertEscalationsList = this.reportSvc.escalationLevelList;
  }

  ngAfterViewInit() {
    this.initParams();
    if (!this.isNewScheduleReport) {
      this.reportParams.EscalationLevels = this.prevEsLvls;
    }
  }

  initParams() {
    if (this.isNewScheduleReport) {
      this.setInitValues();
      this.ParamsChange(true);
    }
  }

  ngOnInit() {
    this.isLocusAdmin = false;
    this.dateTimePreferenceSubscription = this.prefSvc.getDateTimeObject().subscribe(k => {
      this.dateTimeObject = k;
    });
    this.periodListSubscription = this.periodSvc.reportTimePeriodList$.subscribe(n => {
      this.onPeriodListSubscription(n.list);
      this.initParams();
    });

    this.authStateSub = this.authSvc.authState$.subscribe(au => {
        this.isLocusAdmin = au ? au.isEmersonAdmin : false;
        const arrayContains = this.alertEscalationsList.find(l => l.value === AlertEscalationLevel.LocusInternalOnly);
        if(au?.isLocusUserSupportAdmin && !arrayContains) {
          this.alertEscalationsList.push({ text: '*', value: AlertEscalationLevel.LocusInternalOnly })
        }
        else {
          if(arrayContains){
            this.alertEscalationsList.pop();
          }
        }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-trip-report');
    if (this.dateTimePreferenceSubscription) {
      this.dateTimePreferenceSubscription.unsubscribe();
    }
    if (this.periodListSubscription) {
      this.periodListSubscription.unsubscribe();
    }
    if(this.authStateSub) {
      this.authStateSub.unsubscribe();
    }
  }

  onPeriodChange() {
    this.isFormSubmitted = false;
    if (this.reportParams.TimePeriod !== TimePeriod.SpecificRange) {
      this.reportParams.FromDate = null;
      this.reportParams.ThruDate = null;
      this.frmAlertReport.controls['txtStartDate']?.clearValidators();
      this.frmAlertReport.controls['txtEndDate']?.clearValidators();
      this.isDateRangeValid = true;
    } else {
      this.isDateRangeValid = false;
    }
    this.reValidationForm();
    this.ParamsChange(true);
  }

  ParamsChange(resetSubject = false) {
    const request = this.getScheduledReportParams(resetSubject);
    this.reValidationForm();
    this.reportRequestParamsChange.emit(request);
  }

  public onDateChanged() {
    if (this.reportParams.FromDate && isNaN(this.reportParams.FromDate.getTime())) {
      this.reportParams.FromDate = new Date(this.minDate);
    }

    if (this.reportParams.ThruDate && isNaN(this.reportParams.ThruDate.getTime())) {
      this.reportParams.ThruDate = new Date(this.maxDate);
    }

    this.isDateRangeValid = !((this.reportParams.ThruDate != null && this.reportParams.FromDate != null)
      && ((this.reportParams.FromDate >= this.reportParams.ThruDate)));

    this.ParamsChange(true);
  }

  private reValidationForm = () => {
    for (const key in this.frmAlertReport?.controls) {
      this.frmAlertReport.controls[key].markAsDirty();
      this.frmAlertReport.controls[key].updateValueAndValidity();
    }
  }

  public getScheduledReportParams(resetSubject = false): ScheduledReportParams {
    this.reValidationForm();
    let requestParams = new ScheduledReportParams();
    requestParams.ReportParams = [];
    requestParams.IsValid = this.frmAlertReport.valid && this.isDateRangeValid;

    if (resetSubject) {
      requestParams.EmailSubject = reportTypeDesc[this.selectedReportType];
    }

    if (this.reportParams.TimePeriod === TimePeriod.SpecificRange) {
      if (this.reportParams.FromDate && this.reportParams.ThruDate) {
        let dateFormat = this.dateTimeObject ? (this.dateTimeObject?.dateFormatString + ', ' + (this.dateTimeObject.showMeridian ? 'h:mm a' : 'HH:mm')) : 'MM/dd/yyyy, h:mm a';
        if (resetSubject) {
          requestParams.EmailSubject = requestParams.EmailSubject + ': ' +
            this.utilSvc.DateFormatLocaleChange(this.reportParams.FromDate, dateFormat) + ' - ' +
            this.utilSvc.DateFormatLocaleChange(this.reportParams.ThruDate, dateFormat);
        }
        requestParams.ReportParams.push({ Key: "ReportFromDate", Value: this.utilSvc.DateFormatLocaleChange(this.reportParams.FromDate), Type: UserSettingsType.DateTime });
        requestParams.ReportParams.push({ Key: "ReportThruDate", Value: this.utilSvc.DateFormatLocaleChange(this.reportParams.ThruDate), Type: UserSettingsType.DateTime });
      }
    }
    else {
      let timePeriod = this.periodList?.find(p => p.Period === this.reportParams.TimePeriod);
      if (resetSubject) {
        requestParams.EmailSubject = requestParams.EmailSubject + (timePeriod?.DisplayName ? ' - ' + timePeriod?.DisplayName : '');
      }
      requestParams.ReportParams.push({ Key: "NamedDateRange", Value: timePeriod?.Period });
    }
      
      requestParams.ReportParams.push({ Key: "SelectedTimePeriod", Value: this.isValidNumeric(this.reportParams.TimePeriod?.toString()) ? this.reportParams.TimePeriod : null });

      let escLevels = new Set<any>();
      if (this.reportParams.EscalationLevels?.length > 0) {
        this.reportParams.EscalationLevels.forEach(e => escLevels.add(e.value));
      }

      requestParams.ReportParams.push({ Key: "ReportAlertEscalationLevels", Value: this.reportParams.EscalationLevels?.length > 0 ? Array.from(escLevels) : null });
      requestParams.ReportParams.push({ Key: "ReportAlertSeverityCodes", Value: this.reportParams.Severity ? [this.reportParams.Severity] : this.reportSvc.alertSeverityList});
      requestParams.ReportParams.push({ Key: "ReportAlertStatuses", Value: this.reportParams.Status ? [this.reportParams.Status] : this.reportSvc.alertStatusList });
      requestParams.ReportParams.push({ Key: "isLocusAdmin", Value: this.isLocusAdmin });
      requestParams.ReportParams.push({ Key: "IsExpandAll", Value: false });

      //Set Default or unUsed params value to null
      requestParams.ReportParams.push({ Key: "alertIds", Value: [] });
      requestParams.ReportParams.push({ Key: "deviceId", Value: null });
      requestParams.ReportParams.push({ Key: "ReportAlertType", Value: null });
      requestParams.ReportParams.push({ Key: "ReportTripID", Value: null });
      requestParams.ReportParams.push({ Key: "ReportLocationID", Value: null });
      requestParams.ReportParams.push({ Key: "ReportCategory", Value: null });
      requestParams.ReportParams.push({ Key: "ReportVehicleDescriptions", Value: null });
    
    return requestParams;
  }

  onAlertStatusChange() {
    this.ParamsChange();
  }

  onEscalationChange(){
    this.ParamsChange();
  }

  onAlertSeverityChange() {
    this.ParamsChange();
  }

  onExpandAllChange() {
    this.ParamsChange();
  }

  private setInitValues() {
      this.reportParams.Status = AlertActionStatus.Open;
      this.reportParams.TimePeriod = TimePeriod.Last30Days;
      this.ParamsChange(true);
  }

  onPeriodListSubscription(periodList: TimePeriodInfo[]) {
    this.periodList = periodList;
  }

  setFormReportParams(val: TKeyValuePair[]) {
    if (this.isNewScheduleReport ||
      !val ||
      val.length <= 0) { return; }

      const namedDateRange = val.find(kv => kv.Key === "NamedDateRange");
      if (namedDateRange) {
        if (isNaN(parseInt(namedDateRange.Value))) {
          this.reportParams.TimePeriod = parseInt(TimePeriod[namedDateRange.Value]);
        } else {
          this.reportParams.TimePeriod = parseInt(namedDateRange.Value);
        }
      } else {
        this.reportParams.TimePeriod = TimePeriod.SpecificRange;
        const fromParam = val.find(kv => kv.Key === "ReportFromDate");
        const thruParam = val.find(kv => kv.Key === "ReportThruDate");
        this.reportParams.FromDate = new Date(Date.parse(fromParam.Value));
        this.reportParams.ThruDate = new Date(Date.parse(thruParam.Value));
      }

    let escLevels = val.find(kv => kv.Key === "ReportAlertEscalationLevels")?.Value;
    if (escLevels?.length > 0) {
      this.reportParams.EscalationLevels = [];
      this.prevEsLvls = [];
      escLevels?.forEach(e => {
        let level = this.reportSvc?.escalationLevelList?.find(l => l.value == e);
        if (level) {
          this.prevEsLvls.push(level)
        }
      })

      setTimeout(() => {
        this.reportParams.EscalationLevels = this.prevEsLvls;
      }, 500);
    }

      const severity = val.find(kv => kv.Key === "ReportAlertSeverityCodes")?.Value
      this.reportParams.Severity = severity?.length > 1 ? null : (severity?.length > 0 ? severity[0] : null) ;

      const status = val.find(kv => kv.Key === "ReportAlertStatuses")?.Value;
      this.reportParams.Status = status?.length > 1 ? null : (status?.length > 0 ? status[0] : null);
      this.reportParams.ExpandAll = val.find(kv => kv.Key === "IsExpandAll")?.Value;

      setTimeout(() => {
        this.ParamsChange();
      }, 500);
  }
}
