import { ITrackerStateReport } from 'app-modules/core/store/models/tracker-state-report.interface';
import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as trackerStateReportActions from 'app-modules/core/store/actions/shipment-detail-tracker-state-report.actions';
import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';

type Actions =
    authActions.SignOut |
    trackerStateReportActions.LoadTrackerStateReport |
    trackerStateReportActions.LoadTrackerStateReportSuccess |
    trackerStateReportActions.LoadTrackerStateReportError |
    detailActions.SelectShipment;

export function ShipmentDetailTrackerStateReportReducer(
    state: ITrackerStateReport = initialState.shipmentDetailTrackerStateReport,
    action: Actions
): ITrackerStateReport {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailTrackerStateReport;

        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_SUCCESS:
            return {
                ...state,
                summary: action.summary,
                chartData: action.chartData,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_ERROR:
            return {
                ...state,
                errorMessage: action.message
            };
        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
            return {
                ...state,
                isLoadRequired: true
            };
        default:
            return state;
    }
}
