import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-delete-trip',
  templateUrl: './delete-trip.component.html',
  styleUrls: ['./delete-trip.component.css']
})
export class DeleteTripComponent implements OnInit {

  @ViewChild('tripDeleteResponseTemplate') tripDeleteResponseTemplate: TemplateRef<any>;
  errorMessage: string;
  Shipment: Shipment;
  takeConfirmation = true;
  closePopupOnSuccess: BsModalRef;

  constructor(
    public modalRef: BsModalRef,
    private shpSvc: ShipmentService
    ) { }

  ngOnInit(): void {
  }

  deleteTrip() {
    this.shpSvc.deletePendingTrip(this.Shipment).pipe(take(1)).subscribe(k => {
      if (k && k.ErrorCode !== 0) {
          this.errorMessage = k.LocalizedErrorMessage;
      }
      this.takeConfirmation = false;
    });
  }

  closeResponse() {
    this.modalRef.hide();
    if (!this.errorMessage) {
      this.closePopupOnSuccess?.hide();
    }
  }

}
