import { ICoolDownTypeState } from '../models/cool-down-type-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as coolDownActions from './cool-down-type.actions';
import { emptyList } from '../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    coolDownActions.LoadCoolDownTypes |
    coolDownActions.LoadCoolDownTypesSuccess |
    coolDownActions.LoadCoolDownTypesError;

export function CoolDownTypeReducer(state: ICoolDownTypeState = initialState.coolDownType, action: Actions): ICoolDownTypeState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.coolDownType;

        case actionIndex.LOAD_COOL_DOWN_TYPES:
            return {
                ...state,
                isLoading: false
            };

        case actionIndex.LOAD_COOL_DOWN_TYPES_SUCCESS:
            return {
                ...state,
                coolDownTypeList: action.coolDownTypeList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_COOL_DOWN_TYPES_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
