import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith } from 'rxjs/operators';

import { muteFirst, FormatString, FormattedStrings, FormatLocalizedStringRequest } from 'emr-ng-shared';

import { FormattedStringStateService } from './formatted-strings-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class FormattedStringService {
    constructor(
        private FormattedStringStateSvc: FormattedStringStateService,
        private oversightSvc: OversightApiService
    ) { }

    private DateFormatString: FormatLocalizedStringRequest = {
        FormatStringType: FormatString.DateFormat,
        Parameters: null
    };

    public isLoadRequired$ = this.FormattedStringStateSvc.isLoadRequired$;

    public isLoading$ = this.FormattedStringStateSvc.isLoading$;

    public DateFormattedStringList$: Observable<IListInfo<FormattedStrings>> = muteFirst(
        this.getFormattedStringListLoader(this.DateFormatString).pipe(startWith(null)),
        this.FormattedStringStateSvc.formattedStringList$
    );

    public getFormattedStringListLoader(request: FormatLocalizedStringRequest): Observable<IListInfo<FormattedStrings>> {
        return this.FormattedStringStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.FormattedStringStateSvc.loadFormattedStrings()),
            switchMap(() => this.getFormattedStringList(request)),
            tap(
                n => this.FormattedStringStateSvc.loadFormattedStringsSuccess(n),
                e => this.FormattedStringStateSvc.loadFormattedStringsError('')
            ),
            finalize(() => this.FormattedStringStateSvc.cancelLoadFormattedStrings()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getFormattedStringList(request: FormatLocalizedStringRequest): Observable<IListInfo<FormattedStrings>> {

        return this.oversightSvc.GetLocalizedString(request).pipe(
            map(n => {
                return {
                    list: n.Strings,
                    itemCount: n.Strings.length,
                    isPaged: false
                };
            }),
            share()
        );
    }
}