<div class="row mt-4 mb-2" *ngIf="showColumnSelection">
    <div class="col-md-12 text-right">
        <span class="text-primary cursor-pointer" (click)="openColumnSelectionModal()">
            <i class="fa fa-filter"></i>
        </span>
        <span class="text-success pl-2 cursor-pointer">
            <i class="fa fa-file-excel-o"></i>
        </span>
    </div>
</div>
<div class="row table-data-container custom-data-container">
    <div class="col-md-12 px-0">
        <app-local-loading-indicator *ngIf="isShipmentsLoading" width="100%" height="100%" overrideLeft="0"
            overrideTop="0px" applyOverlay="true">
        </app-local-loading-indicator>
        <div class="table-responsive sticky-header-table {{divClass}}" [ngStyle]="{maxHeight: divHeight}">
            <table class="table custom-table mb-0">
                <thead>
                    <tr>
                        <ng-container *ngFor="let map of columnDefs;">
                            <ng-container [ngTemplateOutlet]="header" [ngTemplateOutletContext]="{map:map}">
                            </ng-container>
                        </ng-container>
                    </tr>
                </thead>
                <tbody id="{{tBodyId}}" *ngIf="list?.length>0; else emptyList">
                    <ng-container *ngIf="groupArray.length > 0">
                        <ng-container [ngTemplateOutlet]="common" [ngTemplateOutletContext]="{link:groupedList}">
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="groupArray.length === 0">
                        <ng-container [ngTemplateOutlet]="shipmentRow" [ngTemplateOutletContext]="{link:list}">
                        </ng-container>
                    </ng-container>
                </tbody>
                <ng-template #emptyList>
                    <tbody id="{{tBodyId}}">
                        <tr>
                            <td [attr.colspan]="columnDefs?.length">
                                <p class="table-row-data text-center" i18n="@@no_data_available">No data available</p>
                            </td>
                        </tr>
                    </tbody>
                </ng-template>
            </table>
        </div>
    </div>
</div>

<ng-template #common let-link='link'>
    <ng-container *ngIf="groupArray.length === 3">
        <ng-container *ngFor="let level1 of link; let i=index;">
            <ng-container *ngFor="let level2 of level1.list; let x=index;">
                <ng-container *ngFor="let level3 of level2.list; let y=index;">
                    <ng-container *ngFor="let rowData of level3.list; let z=index;">
                        <tr class="tableRow" [ngClass]="{
                            'row-seperator': x===0 && y === 0 && z === 0,
                            'row-sub-seperator': x > 0 && y === 0 && z=== 0,
                            'row-sub-level-seperator': x > 0 && y > 0 && z=== 0}">
                            <ng-container *ngIf="x===0 && y === 0 && z === 0">
                                <td class="table-row-data" [attr.rowspan]="level1.length" [ngClass]="{
                                    'row-align-top': level1.length > 1}">
                                    {{getNonNullableValue(level1.property,groupArray[0])}}
                                </td>
                            </ng-container>
                            <ng-container *ngIf="y === 0 && z=== 0">
                                <td class="table-row-data" [attr.rowspan]="level2.length" [ngClass]="{
                                    'row-align-top': level2.length > 1}">
                                    {{getNonNullableValue(level2.property,groupArray[1])}}
                                </td>
                            </ng-container>
                            <ng-container *ngIf="z=== 0">
                                <td class="table-row-data" [attr.rowspan]="level3.length" [ngClass]="{
                                    'row-align-top': level3.length > 1}">
                                    {{getNonNullableValue(level3.property,groupArray[2])}}
                                </td>
                            </ng-container>
                            <ng-container [ngTemplateOutlet]="shipmentCommonRow"
                                [ngTemplateOutletContext]="{rowData:rowData,link:level3.list,index:z}">
                            </ng-container>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="groupArray.length === 2">
        <ng-container *ngFor="let level1 of link; let i=index;">
            <ng-container *ngFor="let level2 of level1.list; let y=index;">
                <ng-container *ngFor="let rowData of level2.list; let z=index;">
                    <tr class="tableRow" [ngClass]="{
                        'row-seperator': y === 0 && z === 0,
                        'row-sub-seperator': y > 0 && z === 0}">
                        <ng-container *ngIf="y === 0 && z === 0">
                            <td class="table-row-data" [attr.rowspan]="level1.length" [ngClass]="{
                                'row-align-top': level1.length > 1}">
                                {{getNonNullableValue(level1.property,groupArray[0])}}
                            </td>
                        </ng-container>
                        <ng-container *ngIf="z === 0">
                            <td class="table-row-data" [attr.rowspan]="level2.length" [ngClass]="{
                                'row-align-top': level2.length > 1}">
                                {{getNonNullableValue(level2.property,groupArray[1])}}
                            </td>
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="shipmentCommonRow"
                            [ngTemplateOutletContext]="{rowData:rowData,link:level2.list,index:z}">
                        </ng-container>
                    </tr>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="groupArray.length === 1">
        <ng-container *ngFor="let level1 of link; let i=index;">
            <ng-container *ngFor="let rowData of level1.list; let z=index;">
                <tr class="tableRow" [ngClass]="{
                    'row-seperator': z === 0}">
                    <ng-container *ngIf="z === 0">
                        <td class="table-row-data" [attr.rowspan]="level1.length" [ngClass]="{
                            'row-align-top': level1.length > 1}">
                            {{getNonNullableValue(level1.property,groupArray[0])}}
                        </td>
                    </ng-container>
                    <ng-container [ngTemplateOutlet]="shipmentCommonRow"
                        [ngTemplateOutletContext]="{rowData:rowData,link:level1.list,index:z}">
                    </ng-container>
                </tr>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #shipmentCommonRow let-rowData='rowData' let-link='link' let-index='index'>
    <ng-container *ngFor="let map of nonGroupedColumnDef">
        <ng-container *ngIf="map.isCustomAggregateHtml; else rowBinding">
            <ng-container *ngIf="map.isVisible && (!map.shouldAggregate || (map.shouldAggregate && index === 0))">
                <ng-container
                    *ngTemplateOutlet="map.customHTML;context:{$implicit:rowData,value: map.shouldAggregate ? getAggregatedValue(map.value,map.aggregateType,map.aggregateUnit,map.expectedCustomValue, link) : rowData[map.value],align:map.align,rowspan:map.shouldAggregate ?  link.length : ''}">
                </ng-container>
            </ng-container>
        </ng-container>
        <ng-template #rowBinding>
            <td class="testing {{map.rowClass}}"
                *ngIf="map.isVisible && (!map.shouldAggregate || (map.shouldAggregate && index === 0))"
                [ngStyle]="{'width':map.width}" [attr.rowspan]="map.shouldAggregate ?  link.length : ''" [ngClass]="{
                'right-aligned': map.align === 'right',
                'left-aligned': map.align === 'left',
                'center-aligned': map.align === 'center'}">
                <span *ngIf="map.isCustomHtml; else normalBinding">
                    <ng-container
                        *ngTemplateOutlet="map.customHTML;context:{$implicit:rowData,value: map.shouldAggregate ? getAggregatedValue(map.value,map.aggregateType,map.aggregateUnit,map.expectedCustomValue, link) : rowData[map.value]}">
                    </ng-container>
                </span>
                <ng-template #normalBinding>
                    <span
                        *ngIf="map.shouldAggregate && index === 0">{{getAggregatedValue(map.value,map.aggregateType,map.aggregateUnit,map.expectedCustomValue,
                        link)}}</span>
                    <ng-container *ngIf="!map.shouldAggregate">
                        {{ getNonNullableValue(level1.property,map.value) }}
                    </ng-container>
                </ng-template>
            </td>
        </ng-template>
    </ng-container>
</ng-template>

<ng-template #shipmentRow let-link='link'>
    <tr *ngFor="let rowData of link; index as i" (click)="onRowClick(rowData)"
        class="{{selectableRow?'cursor-pointer':''}}" [class.selected]="selectableRow&&rowData === selectedRow">
        <ng-container [ngTemplateOutlet]="shipmentCommonRow"
            [ngTemplateOutletContext]="{rowData:rowData,link:link,index:i}">
        </ng-container>
    </tr>
</ng-template>

<ng-template #header let-map='map' let-width="width">
    <th class="position-relative p-0  {{map.headerClass}} z-index-1000" draggable droppable
        (onDrop)="onDropComplete($event,map)" [dragData]="map" dragOverClass="dropped-th" *ngIf="map.isVisible"
        [ngStyle]="{'width':width ? width : map.width}" (click)="onSort(map,$event)">
        <div class="d-inline-block">
            {{ map.title }}
            <span *ngIf="sortColumn === map.value" class="header-item-segment">
                <i *ngIf="direction" class="fa fa-sort-asc" aria-hidden="true"></i>
                <i *ngIf="!direction" class="fa fa-sort-desc" aria-hidden="true"></i>
            </span>
        </div>
        <div class="btn-group px-2 my-1 cursor-pointer table-filter-header" dropdown #dropdown="bs-dropdown"
            *ngIf="map.isFilter" [autoClose]="true" [insideClick]="true" container="body"
            (onShown)="onDropDownStatusChange(map)" dropdownToggle>
            <i class=" fa fa-fc-20 fa-filter pt-1 {{map?.filterAppliedModel?.length>0?'text-primary':''}}"></i>
            <!-- <span class="top-count" *ngIf="map?.filterAppliedModel?.length">
            {{map?.filterAppliedModel?.length}}
        </span> -->
            <ul id="dropdown-triggers-manual" *dropdownMenu class="dropdown-menu" role="menu"
                aria-labelledby="button-triggers-manual">
                <div class="scrollable-content table-filter">
                    <li role="menuitem" class="px-1">
                        <table class="w-100">
                            <tr>
                                <td></td>
                                <td>
                                    <emr-checkbox identifier="{{'checkAll'+map.value}}" name="{{'checkAll'+map.value}}"
                                        [isChecked]="false" text="Select all" i18n-text="@@select_all"
                                        [(ngModel)]="map.selectAll" cssClass="color-black"
                                        (onChange)="onSelectAll(map)">
                                    </emr-checkbox>
                                </td>
                            </tr>
                            <tr *ngFor="let d of map.filteredList;let i=index;">
                                <td class="align-top text-right pr-1">
                                    <label class="height-auto label-css line-height-25 mb-c-1">{{d.count}}</label>
                                </td>
                                <td>
                                    <emr-checkbox identifier="{{'lstFilter_'+d.value}}"
                                        class=" d-inline-block  col-md-10 px-0" name="{{'lstFilter_'+d.value}}"
                                        text="{{d.text}}" [(ngModel)]="d.checked"
                                        (onChange)="onFilterCheckboxChange(map)">
                                    </emr-checkbox>
                                </td>
                            </tr>
                        </table>
                        <!-- <emr-checkboxlist identifier="{{'lstFilter_'+map.value}}"
                        name="{{'lstFilter_'+map.value}}" [(ngModel)]="map.filterModel"
                        [options]="map.list" returnType="array" [isChecked]="map.selectAll"
                        textCssClass="align-baseline">
                    </emr-checkboxlist> -->
                    </li>
                </div>
                <li role="menuitem" class="col-md-12">
                    <span class="label-css">{{map?.filterModel?.length?map?.filterModel?.length:0}}
                    </span>
                    <span i18n="@@label_items_selected" class="label-css">Items Selected</span>
                </li>
                <li role="menuitem" class="float-left col-md-12">
                    <emr-button identifier="{{'filterApply_'+map.value}}"
                        cssClass="btn-primary submit-btn mr-2 text-white" buttonText="Filter"
                        i18n-buttonText="@@link_filter" (onClick)="dropdown.isOpen=false;onFilterApply(false,map)"
                        buttonIcon="fa fa-check">
                    </emr-button>

                    <emr-button identifier="{{'filterCancel_'+map.value}}" cssClass="btn-success mr-2 text-white"
                        buttonText="Clear" i18n-buttonText="@@link_clear_all"
                        (onClick)="dropdown.isOpen=false;onFilterApply(true,map)">
                    </emr-button>

                    <emr-button identifier="{{'filterCloseList_'+map.value}}" cssClass="btn-success text-white"
                        buttonText="Close List" i18n-buttonText="@@link_close_list" (onClick)="dropdown.isOpen=false;">
                    </emr-button>
                </li>
            </ul>
        </div>
    </th>
</ng-template>

<ng-template #columnSelectionModal>
    <emr-modal headerTitle="Select Columns" i18n-headerTitle="@@label_select_columns" [showCloseButton]=true
        [bsModalRef]=modalRef>
        <div modal-body>
            <div class="col-md-12 m-1">
                <div>
                    <emr-checkboxlist identifier="columnSelection" name="columnSelection" [options]="columnCheckboxes"
                        returnType="array" textCssClass="align-baseline" [optionsTextField]="'title'"
                        [optionsValueField]="'title'" [(ngModel)]="selectedColumns">
                    </emr-checkboxlist>
                </div>
            </div>
            <div class="col-12 text-center">
                <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Save"
                    i18n-buttonText="@@link_save" (click)='onColumnChangeAccept()'
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                </emr-button>
                <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Cancel"
                    i18n-buttonText="@@label_cancel" (click)='modalRef.hide()'
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                </emr-button>
            </div>
        </div>
    </emr-modal>
</ng-template>