import { ICloseShipmentPopup } from 'app-modules/core/models/close-shipment-popup.interface';
import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShipmentService } from 'app-modules/core/services/shipment.service';

import {
    CloseShipmentPopupComponent
} from 'app-modules/dashboard/components/close-shipment-popup/close-shipment-popup.component';
import { Shipment } from 'app-modules/core/models/shipment.model';

@Injectable()
export class CloseShipmentPopup extends ICloseShipmentPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService, private shpSvc: ShipmentService) {
        super();
    }

    OpenCloseShipmentPopup(shipment: Shipment, islistViewAction: boolean): void {
        const closeShipmentInitialState = {
            shipment: shipment,
            showCloseButton: true
        };

        this.bsModalRef = this.modalSvc.show(
            CloseShipmentPopupComponent,
            {
                initialState: closeShipmentInitialState,
                class: 'modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        this.bsModalRef.content.ok.subscribe(n => {
            if (n.buttonType) {
                n.modalRef.hide();
                this.shpSvc.CloseShipment(shipment,islistViewAction);
            } else {
                n.modalRef.hide();
            }
        });
    }
}

