<div class="col-12 mx-10">
    <div class="row" *ngIf="selectedShipment; else noShipmentSelected">        
        <div class="col-12 only-shipmentDetails">
            <app-shipment-detail [Shipment]="selectedShipment" [defaultToSensorChart]="true"></app-shipment-detail>
        </div>
    </div>
    <ng-template #noShipmentSelected>
        <div class="col-12">
            <span class="validationindicator validation">No Shipment details provided</span>
        </div>
    </ng-template>
</div>
