import { AlertStatus } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadAlertList implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS;
}

export class LoadAlertListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS;
    constructor(public alertList: IListInfo<AlertStatus>) { }
}

export class LoadAlertListError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS;

    constructor(public message: string) { }
}

export class CancelLoadAlertList implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_ALERTS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS;
}

