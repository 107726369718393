import { TraxxInfo } from 'emr-ng-shared';
import { DateRange } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTraxx implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRAXX;
}

export class LoadTraxxSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRAXX_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRAXX;
    constructor(public traxx: IListInfo<TraxxInfo>) { }
}

export class LoadTraxxError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRAXX_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRAXX;

    constructor(public message: string) { }
}

export class CancelLoadTraxx implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_TRAXX;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRAXX;
}

export class UpdateDateRange implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_UPDATE_DATE_RANGE;
    constructor(public dateRange: DateRange) { }
}

