import { initialState } from '../reducers/initial-state';
import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as objectViewActions from './object-views.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

import { emptyList } from '../models/list-info-state.interface';
import { IObjectViewArgsState } from '../models/object-view-args-state.interface';
import { ObjectViewType } from 'emr-ng-shared';

type Actions =
    authActions.SignOut |
    objectViewActions.CheckObjectViewArgs |
    objectViewActions.LoadObjectViewArgs |
    objectViewActions.CancelLoadObjectViewArgs |
    objectViewActions.LoadObjectViewArgsSuccess |
    objectViewActions.LoadObjectViewArgsError |
    custActions.SelectCustomerSuccess;

export function findObjectViewArgs(state: IObjectViewArgsState[], objViewType: ObjectViewType, name: string) {
    if (state) {
        return state.find(k => k.objectViewType === objViewType && k.name === name);
    }
    return null;
}

export function partialUpdate(state: IObjectViewArgsState[], partialState: Partial<IObjectViewArgsState>):IObjectViewArgsState[] {
    const newState = state.map(s => {
        if (s.objectViewType === partialState.objectViewType && s.name === partialState.name) {
            const newObj = {
                ...s,
                ...partialState
            };
            return newObj;
        }
        return s;
    });
    return newState;
}

export function ObjectViewArgsReducer(state: IObjectViewArgsState[] = initialState.objectViewArgsItemList, action: Actions): IObjectViewArgsState[] {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return [];

        case actionIndex.CHECK_OBJECT_VIEW_ARGS:
            {
                const newState = [...state];
                const objViewArgs = findObjectViewArgs(state, action.objectViewType, action.name);
                if (!objViewArgs) {
                    newState.push({
                        name: action.name,
                        objectViewType: action.objectViewType,
                        objectViewArgsItemList: emptyList(),
                        isLoadRequired: true,
                        isLoading: false,
                        errorMessage: ''
                    });
                } else {
                    return partialUpdate(state, { ...objViewArgs });
                }
                return newState;
            }
        case actionIndex.LOAD_OBJECT_VIEW_ARGS:
            {
                const partialSate = {
                    objectViewType: action.objectViewType,
                    name: action.name,
                    isLoadRequired: false,
                    isLoading: true,
                    errorMessage: ''
                };
                return partialUpdate(state, partialSate);
            }
        

        case actionIndex.LOAD_OBJECT_VIEW_ARGS_SUCCESS:
            {
                const partialSate = {
                    objectViewType: action.objectViewType,
                    name: action.name,
                    objectViewArgsItemList: action.objectViewArgsItemList,
                    isLoadRequired: false,
                    isLoading: false,
                    errorMessage: ''
                };
                return partialUpdate(state, partialSate);
            }

        case actionIndex.LOAD_OBJECT_VIEW_ARGS_ERROR:
            {
                const partialSate = {
                    objectViewType: action.objectViewType,
                    name: action.name,
                    objectViewArgsItemList: emptyList(),
                    isLoadRequired: false,
                    isLoading: false,
                    errorMessage: action.message
                };
                return partialUpdate(state, partialSate);
            }

        case actionIndex.CANCEL_LOAD_OBJECT_VIEW_ARGS:
            {
                const partialSate = {
                    objectViewType: action.objectViewType,
                    name: action.name,
                    objectViewArgsItemList: emptyList(),
                    isLoadRequired: false,
                    isLoading: false,
                    errorMessage: ''
                };
                return partialUpdate(state, partialSate);
            }

        case actionIndex.LOAD_OBJECT_VIEW_ARGS_ERROR:
            {
                const partialSate = {
                    objectViewType: action.objectViewType,
                    name: action.name,
                    objectViewArgsItemList: emptyList(),
                    isLoadRequired: false,
                    isLoading: false,
                    errorMessage: action.message
                };
                return partialUpdate(state, partialSate);
            }

        default:
            return [
                ...state
            ];
    }
}
