import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith } from 'rxjs/operators';

import { muteFirst, SetDashboardRequest, SetDashboardResponse, EmrUtilService, DashboardInfo } from 'emr-ng-shared';

import { DashboardStateService } from './dashboard-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { label_no, label_yes } from 'app-modules/core/consts/localization';

@Injectable()
export class DashboardService {
    constructor(
        private dashboardStateSvc: DashboardStateService,
        private oversightSvc: OversightApiService,
        private utilSvc: EmrUtilService,
    ) { }

    public isLoadRequired$ = this.dashboardStateSvc.isLoadRequired$;

    public isLoading$ = this.dashboardStateSvc.isLoading$;

    public dashboardList$: Observable<IListInfo<DashboardInfo>> = muteFirst(
        this.getDashboardListLoader().pipe(startWith(null)),
        this.dashboardStateSvc.dashboardList$
    );

    private getDashboardListLoader(): Observable<IListInfo<DashboardInfo>> {
        return this.dashboardStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.dashboardStateSvc.loadDashboards()),
            switchMap(() => this.getDashboardList()),
            tap(
                n => this.dashboardStateSvc.loadDashboardsSuccess(n),
                e => this.dashboardStateSvc.loadDashboardsError('')
            ),
            finalize(() => this.dashboardStateSvc.cancelLoadDashboards()),
            catchError(() => {
                this.dashboardStateSvc.cancelLoadDashboards();
                return of(emptyList());
            }),
            share()
        );
    }

    private getDashboardList(): Observable<IListInfo<DashboardInfo>> {
        return this.oversightSvc.GetDashboards().pipe(
            map(n => {
                return {
                    list: this.getDashboardInfoList(n.Dashboards),
                    itemCount: n.Dashboards.length,
                    isPaged: false
                };
            })
        );
    }

    public getDashboardInfoList(data: DashboardInfo[]): DashboardInfo[] {
        return data.map(dashboardInfo => this.getDashboardData(dashboardInfo));
    }

    private getDashboardData(data: DashboardInfo): DashboardInfo {
        const dashboardInfo: DashboardInfo = {
            DashboardID: data.DashboardID,
            DashboardName: data?.DashboardName,
            IntegrationID: data?.IntegrationID,
            IsPremium: data?.IsPremium,
            IsPremiumText: data?.IsPremium ? label_yes : label_no,
        }
        return dashboardInfo;
    }

    public setDashboard(request: SetDashboardRequest) {
        return of(request).pipe(
            tap(n => this.dashboardStateSvc.setDashboard(n)),
            switchMap(n => this.oversightSvc.SetDashboard(n)),
            handleErrorResponse(),
            map<SetDashboardResponse, SetDashboardResponse>(n => n),
            tap(
                n => {
                    if (n?.DashboardID) {
                        request.DashboardID = n.DashboardID;
                        const dashboard = this.getDashboardInfo(request);
                        this.dashboardStateSvc.setDashboardSuccess(dashboard);
                    } else {
                        this.dashboardStateSvc.cancelSetDashboard();
                    }
                },
                e => this.dashboardStateSvc.setDashboardError(e)
            )
        );
    }

    public getDashboardInfo(request: SetDashboardRequest): DashboardInfo {
        return {
            DashboardID: request.DashboardID,
            DashboardName: request.DashboardName,
            IntegrationID: request.IntegrationID,
            IsPremium: request.IsPremium,
            IsPremiumText: request.IsPremium ? label_yes : label_no
        }
    }

    public deleteDashboard(dashboardID: number) {
        return of(dashboardID).pipe(
            tap(n => this.dashboardStateSvc.deleteDashboard(n)),
            switchMap(n => this.oversightSvc.DeleteDashboard(n)),
            handleErrorResponse(),
            map<SetDashboardResponse, SetDashboardResponse>(n => n),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.dashboardStateSvc.deleteDashboardSuccess(dashboardID);
                    } else {
                        this.dashboardStateSvc.cancelDeleteDashboard();
                    }
                },
                e => this.dashboardStateSvc.deleteDashboardError(e)
            )
        );
    }
}





