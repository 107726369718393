import { Component, Input, OnInit } from '@angular/core';
import { LocationInfo } from 'emr-ng-shared';

@Component({
  selector: 'app-location-info-box',
  templateUrl: './location-info-box.component.html',
  styleUrls: ['./location-info-box.component.css']
})
export class LocationInfoBoxComponent implements OnInit {

  public isZoomInfoLevel: boolean = true;
  public showInfoZoomOption: boolean = true;
  @Input() location: LocationInfo;
  @Input()
  set isZoomedInfoLevel(value) {
    this.isZoomInfoLevel = value;
  }
  @Input()
  set showInfoBoxZoom(value) {
    this.showInfoZoomOption = value;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
