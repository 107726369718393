import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CoreModule } from 'app-modules/core/core.module';
import { EmrNgControlsModule } from 'emr-ng-shared';

import { SignInComponent } from 'app-modules/user-account/components/sign-in/sign-in.component';
import { SignInFormComponent } from 'app-modules/user-account/components/sign-in-form/sign-in-form.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { AuthExpiredComponent } from 'app-modules/user-account/components/auth-expired/auth-expired.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component.';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotPasswordFormComponent } from './components/forgot-password-form/forgot-password-form.component';
import { ForgotPasswordResponseComponent } from './components/forgot-password-response/forgot-password-response.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UserMessagePopupComponent } from './components/user-message/user-message-popup.component';
import { LinkExpiredResponseComponent } from './components/link-expired-response/link-expired-response.component';

@NgModule({
    declarations: [
        SignInComponent,
        SignInFormComponent,
        ForgotPasswordComponent,
        ForgotPasswordFormComponent,
        ForgotPasswordResponseComponent,
        LinkExpiredResponseComponent,
        SignOutComponent,
        AuthExpiredComponent,
        ChangePasswordFormComponent,
        ChangePasswordComponent,
        UnauthorizedComponent,
        UserMessagePopupComponent,
        LinkExpiredResponseComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CoreModule,
        FormsModule,
        EmrNgControlsModule
    ]
    // entryComponents: [
    //     UserMessagePopupComponent,
    //     LinkExpiredResponseComponent
    // ]
})
export class UserAccountModule {

}

