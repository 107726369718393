import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { IShipmentFilterSerialNumber } from 'app-modules/core/store/models/shipment-filter-serial-number.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { serialNumber } from 'app-modules/core/consts/localization';

@Component({
    selector: 'app-shipment-filter-serial-number-summary',
    templateUrl: 'shipment-filter-serial-number-summary.component.html',
    styleUrls: ['shipment-filter-serial-number-summary.component.css']
})
export class ShipmentFilterSerialNumberSummaryComponent {
    trackerSubscription: Subscription;
    @Input() shipmentFilter: IShipmentFilterState;
    @Input() isHugeCustomer: boolean;
    @Output() summaryRemove = new EventEmitter();

    constructor(
        private shipmentFilterService: ShipmentFilterService
    ) { }

    clearSelectedFilter() {
        this.shipmentFilter.serialNumber = null;
        this.shipmentFilterService.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        if (this.summaryRemove) {
            this.summaryRemove.emit({ title: serialNumber, value: this.shipmentFilter.serialNumber });
        }
    }
}


