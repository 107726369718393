import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';
import { DualVisibilityLinkCustomer, CustomerLinkType } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as linkedCustomerActions from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.actions';

@Injectable()
export class LinkedCustomerStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public linkedCustomerStateSelector = ((state: IAppState) => state.linkedCustomer);
    public isLoadRequiredSelector = createSelector(
        this.linkedCustomerStateSelector,
        (state) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.linkedCustomerStateSelector,
        (state) => state.isLoading
    );

    public linkedCustomersSelector = createSelector(
        this.linkedCustomerStateSelector,
        (state) => state.linkedCustomerList
    );

    public linkedCustomersPushSelector = createSelector(
        this.linkedCustomersSelector,
        (state: IListInfo<DualVisibilityLinkCustomer>) => {
            const filteredList = state.list.filter(a => a.LinkedTypeCode ===  CustomerLinkType.PUSH);
            return <IListInfo<DualVisibilityLinkCustomer>>{
                list: filteredList,
                itemCount: filteredList.length,
                isPaged: false
            };
        }
    );

    public linkedCustomersPullSelector = createSelector(
        this.linkedCustomersSelector,
        (state: IListInfo<DualVisibilityLinkCustomer>) => {
            const filteredList = state.list.filter(a => a.LinkedTypeCode === CustomerLinkType.PULL && !a.IsEndLocationUndefinedEnabled);
            return <IListInfo<DualVisibilityLinkCustomer>>{
                list: filteredList,
                itemCount: filteredList.length,
                isPaged: false
            };
        }
    );

    public linkedShipperStateSelector = ((state: IAppState) => state.linkedShipper);
    public isLoadShipperRequiredSelector = createSelector(
        this.linkedShipperStateSelector,
        (state) => state.isLoadRequired
    );
    public isShipperLoadingSelector = createSelector(
        this.linkedShipperStateSelector,
        (state) => state.isLoading
    );

    public linkedShipperSelector = createSelector(
        this.linkedShipperStateSelector,
        (state) => state.linkedShipperList
    );

    public linkedShipperPushSelector = createSelector(
        this.linkedShipperSelector,
        (state: IListInfo<DualVisibilityLinkCustomer>) => {
            const filteredList = state.list.filter(a => a.LinkedTypeCode ===  CustomerLinkType.PUSH);
            return <IListInfo<DualVisibilityLinkCustomer>>{
                list: filteredList,
                itemCount: filteredList.length,
                isPaged: false
            };
        }
    );

    // Observables
    public linkedCustomerState$ = this.store.select(this.linkedCustomerStateSelector).pipe();
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public linkedCustomerList$ = this.store.select(this.linkedCustomersSelector).pipe();

    public linkedCustomersPushListSelector$ = this.store.select(this.linkedCustomersPushSelector).pipe();
    public linkedCustomersPullListSelector$ = this.store.select(this.linkedCustomersPullSelector).pipe();

    
    public linkedShipperState$ = this.store.select(this.linkedShipperStateSelector).pipe();
    public isLoadShipperRequired$ = this.store.select(this.isLoadShipperRequiredSelector).pipe();
    public isShipperLoading$ = this.store.select(this.isShipperLoadingSelector).pipe();
    public linkedShipperList$ = this.store.select(this.linkedShipperSelector).pipe();
    public linkedShipperPushListSelector$ = this.store.select(this.linkedShipperPushSelector).pipe();

    // Actions
    public loadLinkedCustomers() {
        this.store.dispatch(new linkedCustomerActions.LoadLinkedCustomers());
    }

    public loadLinkedCustomersSuccess(customerList: IListInfo<DualVisibilityLinkCustomer>) {
        this.store.dispatch(new linkedCustomerActions.LoadLinkedCustomersSuccess(customerList));
    }

    public loadLinkedCustomersError(message: string) {
        this.store.dispatch(new linkedCustomerActions.LoadLinkedCustomersError(message));
    }

    public cancelLoadLinkedCustomers() {
        this.store.dispatch(new linkedCustomerActions.CancelLoadLinkedCustomers());
    }

    // public selectLinkedCustomer(customerId: number) {
    //     this.store.dispatch(new custActions.SelectLinkedCustomer(customerId));
    // }

    // public selectLinkedCustomerSuccess(context: ICustomerContextState) {
    //     this.store.dispatch(new custActions.SelectLinkedCustomerSuccess(context));
    // }

    // public selectLinkedCustomerError(message: string) {
    //     this.store.dispatch(new custActions.SelectLinkedCustomerError(message));
    // }

    public loadLinkedShippers() {
        this.store.dispatch(new linkedCustomerActions.LoadLinkedShippers());
    }

    public loadLinkedShipperSuccess(customerList: IListInfo<DualVisibilityLinkCustomer>) {
        this.store.dispatch(new linkedCustomerActions.LoadLinkedShippersSuccess(customerList));
    }

    public loadLinkedShipperError(message: string) {
        this.store.dispatch(new linkedCustomerActions.LoadLinkedShippersError(message));
    }

    public cancelLoadLinkedShippers() {
        this.store.dispatch(new linkedCustomerActions.CancelLoadLinkedShippers());
    }
}

