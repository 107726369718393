import { initialState } from '../../reducers/initial-state';
import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as tripEndActions from './trip-end-options.actions';
import { emptyList } from '../../models/list-info-state.interface';
import { ITripEndOptionsState } from '../../models/trip-end-options-state.interface';

type Actions =
    authActions.SignOut |
    tripEndActions.LoadTripEndOptions |
    tripEndActions.LoadTripEndOptionsSuccess |
    tripEndActions.LoadTripEndOptionsError |
    tripEndActions.CancelLoadTripEndOptions;

export function TripEndOptionsReducer(state: ITripEndOptionsState = initialState.tripEndOptions, action: Actions): ITripEndOptionsState {
    switch (action.type) {

        case actionIndex.LOAD_TRIP_END_OPTIONS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_TRIP_END_OPTIONS_SUCCESS:
            return {
                ...state,
                tripEndOptionsList: action.TripEndOptionsList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_TRIP_END_OPTIONS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };


        case actionIndex.CANCEL_LOAD_TRIP_END_OPTIONS:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false
            };

        default:
            return {
                ...state
            };
    }
}
