import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith } from 'rxjs/operators';
import { of } from 'rxjs';

import { muteFirst } from 'emr-ng-shared';
import { GetTrackerSerialsLookUpRequest } from 'emr-ng-shared';

import { TrackerStateService } from 'app-modules/core/store/services/tracker-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';
import { ShipmentService } from './shipment.service';

@Injectable()
export class TrackerService {
    constructor(
        private trackStateSvc: TrackerStateService,
        private shipSvc: ShipmentService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.trackStateSvc.isLoadRequired$;

    public isLoading$ = this.trackStateSvc.isLoading$;

    public trackerList$: Observable<IListInfo<Tracker>> = muteFirst(
        this.getTrackerListLoader().pipe(startWith(null)),
        this.trackStateSvc.trackerList$
    );

    private getTrackerListLoader(): Observable<IListInfo<Tracker>> {
        return this.trackStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.trackStateSvc.loadTrackers()),
            switchMap(() => this.getTrackerList()),
            tap(
                n => this.trackStateSvc.loadTrackersSuccess(n),
                e => this.trackStateSvc.loadTrackersError('')
            ),
            finalize(() => this.trackStateSvc.cancelLoadTrackers()),
            catchError(() => {
                this.trackStateSvc.cancelLoadTrackers();
                return of(emptyList());
            }),
            share()
        );
    }

    private getTrackerList(): Observable<IListInfo<Tracker>> {
        const request = new GetTrackerSerialsLookUpRequest();
        request.IncludedDeleted = true;
        return this.oversightSvc.GetTrackerSerialsLookUpList(request).pipe(
            map(n => <IListInfo<Tracker>>{
                list: n.Items.map(a => <Tracker>{
                    trackerId: a.GlobalDeviceId,
                    lastFourChars: this.GetLastFourDigits(a.GlobalDeviceId),
                    shipmentName: a.ShipmentName,
                    customerTrackerId: a.GlobalDeviceId + (a.ShipmentName ? ' - ' + a.ShipmentName : ''),
                    receiverPO : null
                })
            })
        );
    }

    public GetLastFourDigits(value: string) {
        if (value && value.length >= 4) {
            const lastHiphen = value.lastIndexOf('-');
            const startPos = (lastHiphen < 5 ? value.length : lastHiphen - 1) - 4;
            return value.substr(startPos, 4);
        }
        return value;
    }

    private getTrackerColor(groupIndex: number): string {
        if (groupIndex === 0) { return 'green'; }
        if (groupIndex === 2) { return 'red'; }
        return '#757575de';
    }

    public reloadTrackerLookUp() {
        return this.shipSvc.shipmentList$.pipe(map(n => {
            const Trackers = [];
            n.list.forEach(obj => {
                const track: Tracker = new Tracker();
                track.trackerId = obj.trackerId;
                track.customerTrackerId = obj.trackerId + (obj.tripName ? ' - ' + obj.tripName : '');
                track.shipmentName = obj.tripName;
                track.receiverPO = obj.ReceiverPONum;
                track.lastFourChars = this.GetLastFourDigits(track.trackerId);
                Trackers.push(track);
            });
            // this.trackStateSvc.loadTrackersSuccess(setListInfo(Trackers, false));
            return Trackers;
        }));
    }
}





