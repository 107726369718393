import { IVehicleGroupsState } from 'app-modules/core/store/models/vehicle-groups-state.interface';

import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as groupsActions from './vehicle-groups.actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';
import * as createActions from 'app-modules/core/store/create-shipment/create-shipment.actions';

type Actions =
    authActions.SignOut |
    groupsActions.LoadVehicleGroups |
    groupsActions.LoadVehicleGroupsSuccess |
    groupsActions.LoadVehicleGroupsError |
    groupsActions.CancelLoadVehicleGroups |
    custActions.SelectCustomerSuccess ;

export function VehicleGroupsReducer(
    state: IVehicleGroupsState = initialState.vehicleGroups,
    action: Actions
): IVehicleGroupsState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.vehicleGroups;

        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                vehicleGroups: [],
                isLoadRequired: true
            };

        case actionIndex.LOAD_VEHICLE_GROUPS:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.LOAD_VEHICLE_GROUPS_SUCCESS:
            return {
                ...state,
                vehicleGroups: action.payload,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_VEHICLE_GROUPS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: action.payload
            };

        default:
            return state;
    }
}
