import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { TimePeriodInfo, DateTimeObject } from 'emr-ng-shared';
import { TimePeriod } from 'emr-ng-shared';

import { initialState } from 'app-modules/core/store/reducers/initial-state';
import { ITimePeriod } from 'app-modules/core/store/models/time-period.interface';
import { TimePeriodService, TimePeriodPageFilter } from 'app-modules/core/store/time-period/time-period.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import * as _ from 'lodash';
import { PreferenceService } from 'app-modules/core/services/preference.service';

@Component({
    selector: 'app-shipment-filter-period',
    templateUrl: './shipment-filter-period.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterPeriodComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: ITimePeriod;
    public isValid: boolean;
    IsUnAuthenticated = false;
    @Input() get filter() { return this.filterVal; }
    @Input() cssClass: string;
    @Output() filterChange = new EventEmitter();
    // tslint:disable-next-line: adjacent-overload-signatures
    set filter(val) {
        if (val) {
            this.filterVal = val;
            this.filterChange.emit(this.filterVal);
        }
    }
    @Input() ShowLastNDays: number = null;

    minDate: Date = new Date(1753,1,1);
    maxDate: Date = new Date(9999,12,31);

    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Input() showPlaceHolder = false;
    @Output() isDirtyChange = new EventEmitter();
    // tslint:disable-next-line: adjacent-overload-signatures
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    @Input() pageFilter = TimePeriodPageFilter.ListViewFilter;
    pageFilters = TimePeriodPageFilter;
    public periodList: TimePeriodInfo[];
    private periodListSubscription: Subscription;
    private periodFilterSubscription: Subscription;
    userPreferenceSubscription: Subscription;
    dateTimeObject = new DateTimeObject();

    constructor(
        public periodSvc: TimePeriodService,
        private filterSvc: ShipmentFilterService,
        private preferenceSvc: PreferenceService
    ) { }

    public ngOnInit() {
        this.IsUnAuthenticated = this.filterSvc.getUnAuthenticationToken();
        this.isValid = true;
        if (this.ShowLastNDays) {
            const today = new Date();
            const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            this.maxDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 50);
            this.minDate = new Date(startDate.getTime() - (1000 * 3600 * 24 * (this.ShowLastNDays - 1)));
        }
        this.periodListSubscription = this.periodSvc.GetTimePeriodFilterOptions(this.pageFilter).subscribe(n =>
            this.onPeriodListSubscription(n.list));
        this.periodFilterSubscription = this.filterSvc.periodFilter$.subscribe(n => this.onPeriodFilterSubscription(n));
        this.userPreferenceSubscription = this.preferenceSvc.getDateTimeObject().subscribe(k => {
            this.dateTimeObject = k;
        });
    }

    public ngOnDestroy() {
        this.periodListSubscription.unsubscribe();
        this.periodFilterSubscription.unsubscribe();
        if (this.userPreferenceSubscription) {
            this.userPreferenceSubscription.unsubscribe();
        }
    }

    onPeriodListSubscription(periodList: TimePeriodInfo[]) {
        this.periodList = periodList;
    }

    onPeriodFilterSubscription(filter: ITimePeriod) {
        this.filter = _.cloneDeep(filter);

        this.isDirty = false;
    }
    // onStartDateChange() {
    //     if (this.filter.dateRange.endDateTime == undefined || this.filter.dateRange.startDateTime > this.filter.dateRange.endDateTime) {
    //         this.filter.dateRange.endDateTime = undefined;
    //     }
    // }

    public reset() {
        this.filter.timePeriod = initialState.shipmentFilter.period.timePeriod;
        this.filter.dateRange.startDateTime = null;
        this.filter.dateRange.endDateTime = null;

        this.isDirty = false;
        this.isValid = true;
    }

    public onSelectedPresetChanged() {
        this.isValid = true;
        this.isDirty = true;
        if (this.filter.timePeriod !== TimePeriod.SpecificRange) {
            this.filter.dateRange.startDateTime = null;
            this.filter.dateRange.endDateTime = null;
        } else {
            const today = new Date();
            this.filter.dateRange.startDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            this.filter.dateRange.endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);
        }
    }

    private onDateChanged() {
        if (this.filter.dateRange.endDateTime == null && this.filter.dateRange.startDateTime == null) {
            this.isDirty = false;
            this.isValid = true;
        } else {
            if ((this.filter.dateRange.endDateTime != null && this.filter.dateRange.startDateTime != null)
                && ((this.filter.dateRange.startDateTime >= this.filter.dateRange.endDateTime) ||
                    (this.ShowLastNDays && (this.filter.dateRange.startDateTime < this.minDate
                        || this.filter.dateRange.endDateTime > this.maxDate)))) {
                this.isDirty = false;
                this.isValid = false;
            } else {
                this.isDirty = true;
                this.isValid = true;
            }
        }

    }



}


