<emr-modal headerTitle="Shipment Details" *ngIf="bsShipmentDetailModalRef;" i18n-headerTitle="@@shipment_details"
    [bsModalRef]=bsShipmentDetailModalRef>
    <ng-container modal-body *ngIf="bsShipmentDetailModalRef; then shipmentDetailModal">
    </ng-container>
</emr-modal>
<ng-container *ngIf="bsShipmentDetailModalRef; else shipmentDetailModal">
</ng-container>
<ng-template #shipmentDetailModal>
    <form name="frmSensorChart" #sensorChartForm="ngForm" emrForm autocomplete="off"
        [ngClass]="{ 'ng-submitted': isSensorChartSubmitted}">
        <div class="row bg-shipment-detail mx-auto">
            <div id="summary" class="col-md-auto col-auto sidebar pr-1">
                <app-local-loading-indicator *ngIf="(isSensorChartLoading$ | async) || isHistoryLoading" width="100%"
                    height="889px" overrideLeft="0" overrideTop="10px" applyOverlay="true">
                </app-local-loading-indicator>
                <div id="header" class="pt-1 main-summary-div mb-3">
                    <div class="row">
                        <div class="col-6 text-center pl-4 pr-1">
                            <div class="text-center display_block">
                                <div class="tracker-img">
                                    <img [hidden]="!getShipmentResponseData"
                                        src="{{ getShipmentResponseData?.Shipment?.ProductImageUrl ? getShipmentResponseData.Shipment.ProductImageUrl : '' }}"
                                        alt="Tracker Image" class="img-fluid model-img" />
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <a *ngIf="Shipment.tripId  && isUserLocusSupport"
                                class="color-header cursor-pointer view-log" id="shipment_view_logs"
                                (click)="showViewLog(Shipment && Shipment.trackerId, Shipment && Shipment.tripId)">
                                <span i18n="@@view_logs">View Logs</span>
                            </a>
                            <div class="py-45 px-2">
                                <ul class="nav flex-column">
                                    <li class="nav-item  color-header-1">
                                        {{ getShipmentResponseData?.Shipment?.GlobalDeviceId ?
                                        getShipmentResponseData.Shipment.GlobalDeviceId : '' }}
                                    </li>
                                    <li class="nav-item color-header-1" *ngIf="getShipmentResponseData">
                                        <span i18n="@@label_battery">Battery</span>:&emsp;
                                        <span>{{ getShipmentResponseData?.Shipment?.TrackerState?.BatteryLevelString ?
                                            getShipmentResponseData.Shipment.TrackerState.BatteryLevelString : ''
                                            }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="row my-1 text-center buttons">
                        <div class="col-md-6 col-12 divbtn">
                            <emr-button identifier="distanceTo" cssClass="btn button-text btn-outline-default mt-0"
                                buttonText="Distance To" i18n-buttonText="@@distance_to"
                                (onClick)="onDistanceToClick(Shipment && Shipment.trackerId, Shipment && Shipment.customerTrackerId, Shipment && Shipment.tripId)">
                            </emr-button>
                        </div>
                        <div class="col-md-6 col-12 divbtn res_suspend_btn"
                            *ngIf="getShipmentResponseData?.Shipment?.IsSuspendAlertsVisible">
                            <emr-button identifier="suspendResumeAlerts"
                                cssClass="btn button-text btn-outline-default mt-0" buttonText="Suspend/Resume Alerts"
                                i18n-buttonText="@@suspend_resume_alerts"
                                (onClick)="onSuspendResumeAlertsClick(Shipment)">
                            </emr-button>
                        </div>
                        <div class="col-md-6 col-12 divbtn" *ngIf="getShipmentResponseData?.Shipment?.IsCreateVisible">
                            <emr-button identifier="createShipment_1"
                                cssClass="btn button-text btn-outline-default mt-0 {{getShipmentResponseData?.Shipment?.IsCreateDisabled ? 'btndisabled' : ''}}"
                                [helpTip]="getShipmentResponseData?.Shipment?.CreateInformation"
                                buttonText="Create Shipment" i18n-buttonText="@@create_shipment"
                                *ngIf="getShipmentResponseData?.Shipment?.IsCreateVisible"
                                (onClick)="onCreateShipmentClick(Shipment)">
                            </emr-button>
                        </div>
                        <div class="col-md-6 col-12 divbtn" *ngIf="getShipmentResponseData?.Shipment?.IsCloseVisible">
                            <emr-button identifier="closeShipment_1"
                                cssClass="btn button-text btn-outline-default mt-0 {{getShipmentResponseData?.Shipment?.IsCloseDisabled ? 'btndisabled' : ''}}"
                                currentToolTip="Close" buttonText="Close"
                                [helpTip]="getShipmentResponseData?.Shipment?.CloseInformation"
                                i18n-buttonText="@@close" *ngIf="getShipmentResponseData?.Shipment?.IsCloseVisible"
                                (onClick)="onCloseShipmentClick(Shipment)">
                            </emr-button>
                        </div>
                        <div class="col-md-6 col-12 divbtn"
                            *ngIf="getShipmentResponseData?.Shipment?.CanShutdownTracker">
                            <emr-button identifier="canShutdownTracker"
                                cssClass="btn button-text btn-outline-default mt-0" currentToolTip="Shutdown Tracker"
                                buttonText="Shutdown" i18n-buttonText="@@shutdown_tracker"
                                *ngIf="getShipmentResponseData?.Shipment?.CanShutdownTracker"
                                (onClick)="onShutdownTrackerClick(Shipment)">
                            </emr-button>
                        </div>
                        <div class="col-md-6 col-12 divbtn" *ngIf="getShipmentResponseData?.Shipment?.CanDeleteTrip">
                            <emr-button identifier="deleteShipment_2"
                                cssClass="btn button-text btn-outline-default mt-0" currentToolTip="Delete"
                                buttonText="Delete Shipment" i18n-buttonText="@@label_delete_shipment"
                                (onClick)="onDeleteShipmentClick(Shipment)">
                            </emr-button>
                        </div>
                    </div>
                    <hr class="mx-2 mt-0">
                    <div class="details-div">
                        <div class="row mb-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsName" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.Name ? getShipmentResponseData?.Shipment?.Name : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.Name" label="Name"
                                        i18n-label="@@label_name" showColon="true" editableLabelClass="color-header"
                                        [maxLength]="50"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && (userCanEditTripName || userCanCreateShipment) &&
                                                                (getShipmentResponseData?.Shipment?.TripStateCode === 1 ||
                                                                    getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                        (saveClick)="onSaveClick($event, 'tripName')" [isMandatory]="true"
                                        [closeEditMode]="!(errorControl?.indexOf('tripName') > -1) && this.successControl === 'tripName'"
                                        [showInEditMode]="(errorControl?.indexOf('tripName') > -1)"
                                        (cancelClick)="onCancelClick($event, 'tripName')"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('tripName') > -1)">
                                    </emr-inline-edit>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <span i18n="@@status">Status</span>:&emsp;
                                    <span class="color-header">
                                        {{ getShipmentResponseData?.Shipment?.TripStateCodeString ?
                                        getShipmentResponseData.Shipment.TripStateCodeString : 'n/a' }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-2" *ngIf="this.isVisibleReceiverPONum">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsReceiverPONum" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.ReceiverPONum ? getShipmentResponseData?.Shipment?.ReceiverPONum : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.ReceiverPONum"
                                        label="ReceiverPO#" i18n-label="@@label_receiver_po" showColon="true"
                                        editableLabelClass="color-header" [maxLength]="50"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && (userCanEditTripName || userCanCreateShipment) &&
                                                                (getShipmentResponseData?.Shipment?.TripStateCode === 1 ||
                                                                    getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                        (saveClick)="onSaveClick($event, 'receiverPONum')" [isMandatory]="false"
                                        [closeEditMode]="!(errorControl?.indexOf('receiverPONum') > -1) && this.successControl === 'receiverPONum'"
                                        [showInEditMode]="(errorControl?.indexOf('receiverPONum') > -1)"
                                        (cancelClick)="onCancelClick($event, 'receiverPONum')"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('receiverPONum') > -1)">
                                    </emr-inline-edit>
                                </div>
                            </div>
                        </div>

                        <div class="row mb-2" *ngIf="this.isVisibleFieldPlateNum">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsPlateNum" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.PlateNum ? getShipmentResponseData?.Shipment?.PlateNum : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.PlateNum"
                                        label="Plate Number" i18n-label="@@plate_number" showColon="true"
                                        editableLabelClass="color-header" [maxLength]="50"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && (userCanEditTripName || userCanCreateShipment) &&
                                                                (getShipmentResponseData?.Shipment?.TripStateCode === 1 ||
                                                                    getShipmentResponseData?.Shipment?.TripStateCode === 2)" (saveClick)="onSaveClick($event, 'plateNum')"
                                        [isMandatory]="true"
                                        [closeEditMode]="!(errorControl?.indexOf('plateNum') > -1) && this.successControl === 'plateNum'"
                                        [showInEditMode]="(errorControl?.indexOf('plateNum') > -1)"
                                        (cancelClick)="onCancelClick($event, 'plateNum')"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('plateNum') > -1)">
                                    </emr-inline-edit>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2" *ngIf="this.isVisibleFieldContainerNum">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsContainerNum" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.ContainerNum ? getShipmentResponseData?.Shipment?.ContainerNum : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.ContainerNum"
                                        label="Container Number" i18n-label="@@container_number" showColon="true"
                                        editableLabelClass="color-header" [maxLength]="50"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && (userCanEditTripName || userCanCreateShipment) &&
                                                                (getShipmentResponseData?.Shipment?.TripStateCode === 1 ||
                                                                    getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                        (saveClick)="onSaveClick($event, 'containerNum')" [isMandatory]="true"
                                        [closeEditMode]="!(errorControl?.indexOf('containerNum') > -1) && this.successControl === 'containerNum'"
                                        [showInEditMode]="(errorControl?.indexOf('containerNum') > -1)"
                                        (cancelClick)="onCancelClick($event, 'containerNum')"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('containerNum') > -1)">
                                    </emr-inline-edit>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2" *ngIf="this.isVisibleFieldDriverPhoneNum">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsDriverPhoneNum" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.DriverPhoneNum ? getShipmentResponseData?.Shipment?.DriverPhoneNum : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.DriverPhoneNum"
                                        label="Driver Phone Number" i18n-label="@@driver_phone_number" showColon="true"
                                        editableLabelClass="color-header" [maxLength]="50"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && (userCanEditTripName || userCanCreateShipment) &&
                                                                (getShipmentResponseData?.Shipment?.TripStateCode === 1 ||
                                                                    getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                        (saveClick)="onSaveClick($event, 'driverPhoneNum')" [isMandatory]="true"
                                        [closeEditMode]="!(errorControl?.indexOf('driverPhoneNum') > -1) && this.successControl === 'driverPhoneNum'"
                                        [showInEditMode]="(errorControl?.indexOf('driverPhoneNum') > -1)"
                                        (cancelClick)="onCancelClick($event, 'driverPhoneNum')"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('driverPhoneNum') > -1)">
                                    </emr-inline-edit>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsOrigin" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.TripStartLocationName ? getShipmentResponseData?.Shipment?.TripStartLocationName : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.TripStartLocationId"
                                        editableLabelClass="color-header" [NewValue]="newTripStartId" label="Origin"
                                        i18n-label="@@origin" showColon="true" type="dropdown" enableSearchFilter=true
                                        searchPlaceholderText="Search" i18n-searchPlaceholderText="@@search"
                                        [options]="originLocations$|async" placeholder="Select"
                                        i18n-placeholder="@@lable_select" [createNewItem]=true
                                        createNewItemText="Create New Location"
                                        [showInEditMode]="(errorControl?.indexOf('tripOrigin') > -1)"
                                        [closeEditMode]="!(errorControl?.indexOf('tripOrigin') > -1) && this.successControl === 'tripOrigin'"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('tripOrigin') > -1)"
                                        i18n-createNewItemText="@@label_create_new_location"
                                        [isLoading]="isLocationListLoading$ | async"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1) && !getShipmentResponseData?.Shipment?.IsDVShipment"
                                        (cancelClick)="onCancelClick($event, 'tripOrigin')"
                                        (saveClick)="onSaveClick($event, 'tripOrigin')" [isMandatory]="true"
                                        (createNewItemClick)="openAddLocationModal('tripOrigin')"
                                        (onChange)="onChangeClick($event, 'tripOrigin')" isLink="true"
                                        [labelTooltip]="getShipmentResponseData?.Shipment?.TripStartLocationFullAddress"
                                        (onLinkClick)="openAddressInfo(getShipmentResponseData?.Shipment?.TripStartLocationFullAddress,getShipmentResponseData?.Shipment?.TripStartLocationName)">
                                    </emr-inline-edit>
                                </div>
                            </div>
                            <div class="col-12 validationindicator help-text mt-1"
                                *ngIf="(errorControl?.indexOf('tripOrigin') > -1)">
                                <div class="pl-2">
                                    <span id="tripOrigin_Validation_Distinct" *ngIf="!matchingStops"
                                        i18n="@@select_distinct_location_name_required">Please select distinct Origin
                                        Location </span>
                                    <span id="tripOrigin_Validation_Distinct_Stops" *ngIf="matchingStops"
                                        i18n="@@select_distinct_origin_destination_from_stop_locat">
                                        Trip Stops location can not be used as Origin/Destination location
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2" *ngIf="userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                    getShipmentResponseData?.Shipment?.TripStateCode === 2)">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <div class="d-inline-block">
                                        <i role="button" id="shipment_stops_edit_icon" class="fa fa-pencil mr-1"
                                            *ngIf="!getShipmentResponseData?.Shipment?.IsDVShipment"
                                            (click)="onEditStops()"></i>
                                        <span i18n="@@label_stops">Stops</span>
                                        <span class="">: </span>
                                        <span class="color-header"
                                            *ngIf="getShipmentResponseData?.Shipment?.IsDVShipment;else stops_edit">
                                            {{tripStopsCopy?.length}}
                                        </span>
                                        <ng-template #stops_edit>
                                            <a class="color-header cursor-pointer" id="shipment_stops_edit"
                                                (click)="onEditStops()">
                                                <span class="color-header">{{tripStopsCopy?.length}}</span>
                                            </a>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsDestination" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.TripEndLocationName ? getShipmentResponseData?.Shipment?.TripEndLocationName : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.TripEndLocationId"
                                        editableLabelClass="color-header" [NewValue]="newTripEndId" label="Destination"
                                        i18n-label="@@destination" showColon="true" type="dropdown"
                                        enableSearchFilter=true searchPlaceholderText="Search"
                                        i18n-searchPlaceholderText="@@search" [options]="destLocations$|async"
                                        placeholder="Select" i18n-placeholder="@@lable_select" [createNewItem]=true
                                        createNewItemText="Create New Location"
                                        [showInEditMode]="(errorControl?.indexOf('tripDestination') > -1)"
                                        [closeEditMode]="!(errorControl?.indexOf('tripDestination') > -1) && this.successControl === 'tripDestination'"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('tripDestination') > -1)"
                                        i18n-createNewItemText="@@label_create_new_location"
                                        [isLoading]="isLocationListLoading$ | async"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                    getShipmentResponseData?.Shipment?.TripStateCode === 2) && !getShipmentResponseData?.Shipment?.IsDVShipment"
                                        (cancelClick)="onCancelClick($event, 'tripDestination')"
                                        (saveClick)="onSaveClick($event, 'tripDestination')"
                                        [isMandatory]="!this.Shipment.isMultiTrip"
                                        (createNewItemClick)="openAddLocationModal('tripDestination')"
                                        (onChange)="onChangeClick($event, 'tripDestination')" isLink="true"
                                        [labelTooltip]="getShipmentResponseData?.Shipment?.TripEndLocationFullAddress"
                                        (onLinkClick)="openAddressInfo(getShipmentResponseData?.Shipment?.TripEndLocationFullAddress,getShipmentResponseData?.Shipment?.TripEndLocationName)">
                                    </emr-inline-edit>
                                </div>
                            </div>
                            <div class="col-12 validationindicator help-text mt-1"
                                *ngIf="(errorControl?.indexOf('tripDestination') > -1)">
                                <div class="pl-2" *ngIf="!matchingStops; else ">
                                    <span id="tripDest_Validation_Distinct"
                                        i18n="@@select_distinct_location_name_required">Please select distinct Origin
                                        Destination </span>
                                </div>
                                <span id="tripDest_Validation_Distinct_Stops" *ngIf="matchingStops"
                                    i18n="@@select_distinct_origin_destination_from_stop_locat">
                                    Trip Stops location can not be used as Origin/Destination location
                                </span>
                            </div>
                        </div>
                        <div class="row my-2" *ngIf="ShowSupplierField">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <span i18n="@@label_supplier">Supplier</span>:&emsp;
                                    <span class="color-header">
                                        {{ getShipmentResponseData?.Shipment?.SupplierName ?
                                        getShipmentResponseData.Shipment.SupplierName : 'n/a' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2" *ngIf="isEndLocationUndefined">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <span i18n="@@label_distribution_center">Distribution Center</span>:&emsp;
                                    <span class="color-header">
                                        {{ getShipmentResponseData?.Shipment?.DCTripStop ?
                                        getShipmentResponseData.Shipment.DCTripStop : 'n/a' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2" *ngIf="isEndLocationUndefined">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <span i18n="@@label_last_geo_fence">Last Geo Fence</span>:&emsp;
                                    <span class="color-header">
                                        {{ getShipmentResponseData?.Shipment?.MostRecentPostDC ?
                                        getShipmentResponseData.Shipment.MostRecentPostDC : 'n/a' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2" *ngIf="!IsCarrierDropDown;else CarrierDropdown">
                                    <emr-inline-edit identifier="shipmentDetailsCarrierName" editIcon="fa fa-pencil"
                                        displayValue="{{ getShipmentResponseData?.Shipment?.CarrierName ? getShipmentResponseData.Shipment.CarrierName : 'n/a' }}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.CarrierName"
                                        editableLabelClass="color-header" label="Carrier Name"
                                        i18n-label="@@carrier_name" showColon="true" [isMandatory]="false"
                                        [maxLength]="200" [showInEditMode]="(errorControl?.indexOf('carrierName') > -1)"
                                        [MinDate]="getShipmentResponseData?.Shipment?.ScheduledStartTime"
                                        [closeEditMode]="!(errorControl?.indexOf('carrierName') > -1) && this.successControl === 'carrierName'"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('carrierName') > -1)"
                                        (saveClick)="onSaveClick($event, 'carrierName')"
                                        (onChange)="onChangeClick($event, 'carrierName')" [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                        (cancelClick)="onCancelClick($event, 'carrierName')">
                                    </emr-inline-edit>
                                </div>
                                <ng-template #CarrierDropdown>
                                    <div class="pl-2">
                                        <emr-inline-edit identifier="shipmentDetailsCarrier" editIcon="fa fa-pencil"
                                            displayValue="{{getShipmentResponseData?.Shipment?.CarrierName ? getShipmentResponseData?.Shipment?.CarrierName : 'n/a'}}"
                                            [EditableValue]="getShipmentResponseData?.Shipment?.CarrierName"
                                            editableLabelClass="color-header" label="Carrier Name"
                                            i18n-label="@@label_carrier_name" showColon="true" type="dropdown"
                                            enableSearchFilter=true searchPlaceholderText="Search"
                                            i18n-searchPlaceholderText="@@search" [options]="carrierList"
                                            optionsValueField="Value" optionsTextField="Value" placeholder="Select"
                                            i18n-placeholder="@@lable_select"
                                            [showInEditMode]="(errorControl?.indexOf('carrierName') > -1)"
                                            [closeEditMode]="!(errorControl?.indexOf('carrierName') > -1) && this.successControl === 'carrierName'"
                                            [displayLoader]="(pendingSaveRequests?.indexOf('carrierName') > -1)"
                                            [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                            getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                            (cancelClick)="onCancelClick($event, 'carrierName')"
                                            (saveClick)="onSaveClick($event, 'carrierName')" [isMandatory]="false"
                                            (onChange)="onChangeClick($event, 'carrierName')" isLink="true"
                                            [labelTooltip]="getShipmentResponseData?.Shipment?.CarrierName">
                                        </emr-inline-edit>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <div class="d-inline-block">
                                        <i role="button" id="shipment_alert_contatcs_edit_icon"
                                            class="fa fa-pencil mr-1" *ngIf="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                            getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                            (click)="onEditAlertContacts()"></i>
                                        <span i18n="@@label_additional_alert_contacts">Carrier Emails</span>
                                        <span class="">: </span>
                                        <a class="color-header" [ngClass]="{
                                            'cursor-pointer': getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                            getShipmentResponseData?.Shipment?.TripStateCode === 2)}"
                                            id="shipment_alert_contact" (click)="onEditAlertContacts()">
                                            <span
                                                class="color-header">{{getShipmentResponseData?.Shipment?.CarrierEmails
                                                ? getShipmentResponseData.Shipment.CarrierEmails : 'n/a' }}</span>
                                        </a>
                                    </div>
                                    <!-- <emr-inline-edit identifier="shipmentDetailsCarrierEmails" editIcon="fa fa-pencil"
                                        displayValue="{{ getShipmentResponseData?.Shipment?.CarrierEmails ? getShipmentResponseData.Shipment.CarrierEmails : 'n/a' }}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.CarrierEmails"
                                        editableLabelClass="color-header" label="Carrier Emails"
                                        i18n-label="@@label_additional_alert_contacts" showColon="true"
                                        [isMandatory]="false"
                                        [showInEditMode]="(errorControl?.indexOf('carrierEmails') > -1)"
                                        [MinDate]="getShipmentResponseData?.Shipment?.ScheduledStartTime"
                                        [closeEditMode]="!(errorControl?.indexOf('carrierEmails') > -1) && this.successControl === 'carrierEmails'"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('carrierEmails') > -1)"
                                        (saveClick)="onSaveClick($event, 'carrierEmails')"
                                        (onChange)="onChangeClick($event, 'carrierEmails')" [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                        (cancelClick)="onCancelClick($event, 'carrierEmails')"
                                        helpText="To add multiple emails, type email addresses separated with commas."
                                        i18n-helpText="@@label_carrier_email_help_text">
                                    </emr-inline-edit> -->
                                </div>
                            </div>
                            <div class="col-12 validationindicator help-text mt-1"
                                *ngIf="(errorControl?.indexOf('carrierEmails') > -1)">
                                <div class="pl-2">
                                    <span id="tripStartTime_Validation" i18n="@@validation_email">Please enter valid
                                        Email
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <span i18n="@@link_start_date">Start Date</span>:&emsp;
                                    <span class="color-header">
                                        {{ getShipmentResponseData?.Shipment?.ActualStartTimeString ?
                                        getShipmentResponseData?.Shipment?.ActualStartTimeString : 'n/a' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsTripStart" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.ScheduledStartTimeString ? getShipmentResponseData?.Shipment?.ScheduledStartTimeString : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.ScheduledStartTime"
                                        editableLabelClass="color-header" label="Scheduled Start"
                                        [MaxDate]="getShipmentResponseData?.Shipment?.ScheduledEndTime"
                                        [MinDate]="defaultMinTime" [dateTimeObject]=dateTimeObject
                                        i18n-label="@@link_scheduled_start"
                                        [closeEditMode]="!(errorControl?.indexOf('tripStart') > -1) && this.successControl === 'tripStart'"
                                        showColon="true" type="datetimepicker"
                                        [showInEditMode]="(errorControl?.indexOf('tripStart') > -1)"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('tripStart') > -1)"
                                        (saveClick)="onSaveClick($event, 'tripStart')" [isMandatory]="true"
                                        i18n-AM="@@time_AM" i18n-PM="@@time_PM" AM="AM" PM="PM"
                                        (cancelClick)="onCancelClick($event, 'tripStart')"
                                        [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1)"
                                        (onChange)="onChangeClick($event, 'tripStart')">
                                    </emr-inline-edit>
                                </div>
                            </div>
                            <div class="col-12 validationindicator help-text mt-1"
                                *ngIf="(errorControl?.indexOf('tripStart') > -1)">
                                <div class="pl-2">
                                    <span id="tripStartTime_Validation" i18n="@@greater_end_time"> Scheduled End time
                                        must be greater than Start time
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <span i18n="@@link_end_date">End Date</span>:&emsp;
                                    <span class="color-header">
                                        {{ getShipmentResponseData?.Shipment?.ActualEndTimeString ?
                                        getShipmentResponseData?.Shipment?.ActualEndTimeString : 'n/a' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <emr-inline-edit identifier="shipmentDetailsTripEnd" editIcon="fa fa-pencil"
                                        displayValue="{{getShipmentResponseData?.Shipment?.ScheduledEndTimeString ? getShipmentResponseData?.Shipment?.ScheduledEndTimeString : 'n/a'}}"
                                        [EditableValue]="getShipmentResponseData?.Shipment?.ScheduledEndTime"
                                        editableLabelClass="color-header" label="Scheduled End"
                                        [dateTimeObject]=dateTimeObject i18n-label="@@link_scheduled_end"
                                        showColon="true" type="datetimepicker" [isMandatory]="true"
                                        [showInEditMode]="(errorControl?.indexOf('tripEnd') > -1)"
                                        [MinDate]="getShipmentResponseData?.Shipment?.ScheduledStartTime ? getShipmentResponseData?.Shipment?.ScheduledStartTime : defaultMinTime"
                                        [closeEditMode]="!(errorControl?.indexOf('tripEnd') > -1) && this.successControl === 'tripEnd'"
                                        [displayLoader]="(pendingSaveRequests?.indexOf('tripEnd') > -1)"
                                        (saveClick)="onSaveClick($event, 'tripEnd')" i18n-AM="@@time_AM"
                                        i18n-PM="@@time_PM" AM="AM" PM="PM"
                                        (onChange)="onChangeClick($event, 'tripEnd')" [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                    getShipmentResponseData?.Shipment?.TripStateCode === 2)"
                                        (cancelClick)="onCancelClick($event, 'tripEnd')">
                                    </emr-inline-edit>
                                </div>
                            </div>
                            <div class="col-12 validationindicator help-text mt-1"
                                *ngIf="(errorControl?.indexOf('tripEnd') > -1)">
                                <div class="pl-2">
                                    <span id="tripEndTime_Validation" i18n="@@greater_end_time"> Scheduled End time must
                                        be greater than Start time
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row my-2">
                            <div class="col-md-12 col-12">
                                <div class="pl-2">
                                    <span i18n="@@shipment_duration">Shipment Duration</span>:&emsp;
                                    <span class="color-header">
                                        {{ getShipmentResponseData?.Shipment?.TripDurationString ?
                                        getShipmentResponseData.Shipment.TripDurationString : 'n/a' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="sensorRangeList$|async as sensors">
                            <div class="row my-2">
                                <div class="col-md-12 col-12">
                                    <div class="pl-2">
                                        <emr-inline-edit identifier="lstTempSensorRange" editIcon="fa fa-pencil"
                                            displayValue="{{tempSensor?.text ? tempSensor?.text : 'n/a'}}"
                                            [EditableValue]="tempSensor?.value"
                                            [showInEditMode]="(errorControl?.indexOf('temperature') > -1)"
                                            [closeEditMode]="!(errorControl?.indexOf('temperature') > -1) && this.successControl === 'temperature'"
                                            [displayLoader]="(pendingSaveRequests?.indexOf('temperature') > -1)"
                                            editableLabelClass="color-header" label="Temperature"
                                            i18n-label="@@label_temperature" showColon="true" type="dropdown"
                                            enableSearchFilter=true searchPlaceholderText="Search"
                                            i18n-searchPlaceholderText="@@search"
                                            [isLoading]="isSensorListLoading$ | async" [options]="sensors.Temperature"
                                            optionsValueField="SensorRangeId" optionsTextField="Description"
                                            placeholder="Select" i18n-placeholder="@@lable_select" [createNewItem]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                        getShipmentResponseData?.Shipment?.TripStateCode === 2) && !getShipmentResponseData?.Shipment?.IsDVShipment"
                                            (saveClick)="onSaveSensorRangeClick($event, 'temperature')"
                                            (cancelClick)="onCancelClick($event, 'temperature')"
                                            (createNewItemClick)="openAddSensorRangeModal(sensorType.Temperature)"
                                            [NewValue]="newtempSensorID">
                                        </emr-inline-edit>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-2" *ngIf="humiditySensor">
                                <div class="col-md-12 col-12">
                                    <div class="pl-2">
                                        <emr-inline-edit identifier="lstHUSensorRange" editIcon="fa fa-pencil"
                                            displayValue="{{humiditySensor?.text ? humiditySensor?.text : 'n/a'}}"
                                            [EditableValue]="humiditySensor?.value"
                                            [showInEditMode]="(errorControl?.indexOf('humidity') > -1)"
                                            [closeEditMode]="!(errorControl?.indexOf('humidity') > -1) && this.successControl === 'humidity'"
                                            [displayLoader]="(pendingSaveRequests?.indexOf('humidity') > -1)"
                                            editableLabelClass="color-header" label="Humidity"
                                            i18n-label="@@label_humidity" showColon="true" type="dropdown"
                                            enableSearchFilter=true searchPlaceholderText="Search"
                                            i18n-searchPlaceholderText="@@search"
                                            [isLoading]="isSensorListLoading$ | async" [options]="sensors.Humidity"
                                            optionsValueField="SensorRangeId" optionsTextField="Description"
                                            placeholder="Select" i18n-placeholder="@@lable_select" [createNewItem]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 ||
                                                        getShipmentResponseData?.Shipment?.TripStateCode === 2) && !getShipmentResponseData?.Shipment?.IsDVShipment"
                                            (saveClick)="onSaveSensorRangeClick($event, 'humidity')"
                                            (cancelClick)="onCancelClick($event, 'humidity')"
                                            (createNewItemClick)="openAddSensorRangeModal(sensorType.Humidity)"
                                            [NewValue]="newHumiditySensorID">
                                        </emr-inline-edit>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-2" *ngIf="co2Sensor">
                                <div class="col-md-12 col-12">
                                    <div class="pl-2">
                                        <emr-inline-edit identifier="lstCO2SensorRange" editIcon="fa fa-pencil"
                                            displayValue="{{co2Sensor?.text ? co2Sensor?.text : 'n/a'}}"
                                            [EditableValue]="co2Sensor?.value"
                                            [showInEditMode]="(errorControl?.indexOf('co2') > -1)"
                                            [closeEditMode]="!(errorControl?.indexOf('co2') > -1) && this.successControl === 'co2'"
                                            [displayLoader]="(pendingSaveRequests?.indexOf('co2') > -1)"
                                            editableLabelClass="color-header" label="CO2"
                                            i18n-label="@@label_carbondioxide" showColon="true" type="dropdown"
                                            enableSearchFilter=true searchPlaceholderText="Search"
                                            i18n-searchPlaceholderText="@@search"
                                            [isLoading]="isSensorListLoading$ | async" [options]="sensors.CarbonDioxide"
                                            optionsValueField="SensorRangeId" optionsTextField="Description"
                                            placeholder="Select" i18n-placeholder="@@lable_select" [createNewItem]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                        getShipmentResponseData?.Shipment?.TripStateCode === 2) && !getShipmentResponseData?.Shipment?.IsDVShipment"
                                            (saveClick)="onSaveSensorRangeClick($event, 'co2')"
                                            (cancelClick)="onCancelClick($event, 'co2')"
                                            (createNewItemClick)="openAddSensorRangeModal(sensorType.CarbonDioxide)"
                                            [NewValue]="newCO2SensorID">
                                        </emr-inline-edit>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-2" *ngIf="probeSensor">
                                <div class="col-md-12 col-12">
                                    <div class="pl-2">
                                        <emr-inline-edit identifier="lstProbSensorRange" editIcon="fa fa-pencil"
                                            displayValue="{{probeSensor?.text ? probeSensor?.text : 'n/a'}}"
                                            [EditableValue]="probeSensor?.value"
                                            [showInEditMode]="(errorControl?.indexOf('probe') > -1)"
                                            [closeEditMode]="!(errorControl?.indexOf('probe') > -1) && this.successControl === 'probe'"
                                            [displayLoader]="(pendingSaveRequests?.indexOf('probe') > -1)"
                                            editableLabelClass="color-header" label="Probe Temp"
                                            i18n-label="@@label_probetemp" showColon="true" type="dropdown"
                                            enableSearchFilter=true searchPlaceholderText="Search"
                                            i18n-searchPlaceholderText="@@search"
                                            [isLoading]="isSensorListLoading$ | async" [options]="sensors.ProbeTemp"
                                            optionsValueField="SensorRangeId" optionsTextField="Description"
                                            placeholder="Select" i18n-placeholder="@@lable_select" [createNewItem]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                        getShipmentResponseData?.Shipment?.TripStateCode === 2) && !getShipmentResponseData?.Shipment?.IsDVShipment"
                                            (saveClick)="onSaveSensorRangeClick($event, 'probe')"
                                            (cancelClick)="onCancelClick($event, 'probe')"
                                            (createNewItemClick)="openAddSensorRangeModal(sensorType.ProbeTemp)"
                                            [NewValue]="newProbeSensorID">
                                        </emr-inline-edit>
                                    </div>
                                </div>
                            </div>
                            <div class="row my-2" *ngIf="shkSensor">
                                <div class="col-md-12 col-12">
                                    <div class="pl-2">
                                        <emr-inline-edit identifier="lstShockSensorRange" editIcon="fa fa-pencil"
                                            displayValue="{{shkSensor?.text ? shkSensor?.text : 'n/a'}}"
                                            [EditableValue]="shkSensor?.value"
                                            [showInEditMode]="(errorControl?.indexOf('shock') > -1)"
                                            [closeEditMode]="!(errorControl?.indexOf('shock') > -1) && this.successControl === 'shock'"
                                            [displayLoader]="(pendingSaveRequests?.indexOf('shock') > -1)"
                                            editableLabelClass="color-header" label="Shock Detected"
                                            i18n-label="@@label_shock" showColon="true" type="dropdown"
                                            enableSearchFilter=true searchPlaceholderText="Search"
                                            i18n-searchPlaceholderText="@@search"
                                            [isLoading]="isSensorListLoading$ | async" [options]="sensors.ShockDetected"
                                            optionsValueField="SensorRangeId" optionsTextField="Description"
                                            placeholder="Select" i18n-placeholder="@@lable_select" [createNewItem]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [isEditable]="getShipmentResponseData?.Shipment?.CanEdit && userCanCreateShipment && (getShipmentResponseData?.Shipment?.TripStateCode === 1 || 
                                                        getShipmentResponseData?.Shipment?.TripStateCode === 2) && !getShipmentResponseData?.Shipment?.IsDVShipment"
                                            (saveClick)="onSaveSensorRangeClick($event, 'shock')"
                                            (cancelClick)="onCancelClick($event, 'shock')"
                                            (createNewItemClick)="openAddSensorRangeModal(sensorType.ShockDetected)"
                                            [NewValue]="newShockSensorID">
                                        </emr-inline-edit>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="getShipmentResponseData?.Shipment?.TemperatureInfo as tInfo">
                                <div class="row my-2">
                                    <div class="col-md-12 col-12">
                                        <div class="pl-2">
                                            <span i18n="@@mean_kinetic_temperature">Mean Kinetic
                                                Temperature</span>:&emsp;
                                            <span class="color-header">
                                                {{ shipmentDetailsText(tInfo?.MKTString) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-md-12 col-12">
                                        <div class="pl-2">
                                            <span i18n="@@max_value_above_range">Max High Temp</span>:&emsp;
                                            <span class="color-header">
                                                {{ shipmentDetailsText(tInfo?.MaxHighValueString) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-md-12 col-12">
                                        <div class="pl-2">
                                            <span i18n="@@max_value_below_range">Max Low Temp</span>:&emsp;
                                            <span class="color-header">
                                                {{ shipmentDetailsText(tInfo?.MinLowValueString) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="" *ngIf="showAllTempValues && (tempSensor?.value || defaultTempRangeId)">
                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@total_time_out_of_range">Total Time Out Of
                                                    Range</span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.TotalTimeOutOfRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@total_time_above_critical">Total Time Above Critical
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.TotalTimeAboveCriticalRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@total_time_above_warning">Total Time Above Warning
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.TotalTimeAboveWarningRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@total_time_below_critical">Total Time Below Critical
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.TotalTimeBelowCriticalRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@total_time_below_warning">Total Time Below Warning
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.TotalTimeBelowWarningRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@max_time_above_critical">Max Time Above Critical
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.MaxTimeAboveCriticalRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@max_time_above_warning">Max Time Above Warning
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.MaxTimeAboveWarningRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@max_time_below_critical">Max Time Below Critical
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.MaxTimeBelowCriticalRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row my-2">
                                        <div class="col-md-12 col-12">
                                            <div class="pl-2">
                                                <span i18n="@@max_time_below_warning">Max Time Below Warning
                                                </span>:&emsp;
                                                <span class="color-header">
                                                    {{ shipmentDetailsText(tInfo?.MaxTimeBelowWarningRangeString) }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div id="details" class="col-md col pl-1">
                <div>
                    <div *ngIf="showTimeLine" class="nav-tab pb-0"
                        [ngClass]="{'nav-tab-selected':selectedView === views.TIMELINE}"
                        (click)='onNavTabSelected(views.TIMELINE)' i18n="@@timeline">Timeline</div>
                    <div class="nav-tab pb-0" [ngClass]="{'nav-tab-selected':selectedView === views.SENSOR_CHART}"
                        (click)="onNavTabSelected(views.SENSOR_CHART)" i18n="@@label_sensor_chart">Sensor Chart
                    </div>
                    <div class="nav-tab pb-0" [ngClass]="{'nav-tab-selected':selectedView === views.ALERT_SUMMARY}"
                        (click)="onNavTabSelected(views.ALERT_SUMMARY)" i18n="@@alerts_summary">Alerts Summary</div>
                    <div class="nav-tab pb-0" *ngIf="Shipment?.isMultiTrip"
                        [ngClass]="{'nav-tab-selected':selectedView === views.HISTORICAL_SHIPMENT}"
                        (click)="onNavTabSelected(views.HISTORICAL_SHIPMENT)" i18n="@@historical_shipments">
                        Historical
                        Shipments</div>
                    <div class="nav-tab pb-0"
                        *ngIf="getShipmentResponseData?.Shipment?.TrackerState?.SmartTagQualityCode&&showScannedTags"
                        [ngClass]="{'nav-tab-selected':selectedView === views.TAGS}"
                        (click)="onNavTabSelected(views.TAGS)" i18n="@@tags">Tags</div>
                </div>

                <div class="shipment-switch-div" [ngSwitch]="selectedView">
                    <app-local-loading-indicator
                        *ngIf="(selectedView === views.ALERT_SUMMARY) && isSensorChartLoading$ | async" width="100%"
                        height="860px" overrideLeft="0" overrideTop="50px" applyOverlay="true">
                    </app-local-loading-indicator>
                    <app-local-loading-indicator
                        *ngIf="(selectedView === views.HISTORICAL_SHIPMENT) && isHistoryLoading" width="100%"
                        height="860px" overrideLeft="0" overrideTop="50px" applyOverlay="true">
                    </app-local-loading-indicator>
                    <div class="sensorchart-details" #sensorChartDetails>
                        <div
                            *ngIf="selectedView === views.SENSOR_CHART || selectedView === views.ALERT_SUMMARY || selectedView === views.TIMELINE">
                            <div *ngIf="error!=null" class="row my-2">
                                <div class="col-md-12">
                                    <span class="validationindicator">
                                        <div class="sensorchart-validation my-1">
                                            <div>{{error}}</div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div [hidden]="selectedView !== views.SENSOR_CHART && selectedView !== views.ALERT_SUMMARY"
                                class="row mx-0 mt-2">
                                <div
                                    class="{{ dateTimeObject.showMeridian ? 'width-234' : 'width-210 hide-meridian ' }}  ml-3 date-time-picker">
                                    <emr-datetimepicker name="txtDateRangeStartPicker" formGroupCssClass="mb-0"
                                        identifier="txtDateRangeStartPicker" ngDefaultControl
                                        [(ngModel)]="dateRange.startDateTime" placeholder="Start Date"
                                        i18n-placeholder="@@link_start_date" required CustomizeGrid="true"
                                        datePickerDivCssClass="width-130 px-0" (ngModelChange)="onDateChanged()"
                                        [showDateTimeInLine]="true" [dateTimeObject]=dateTimeObject
                                        [setExtremeTimes]="true" timePickerDivCssClass="width-90 ml-1 px-0"
                                        labelCssClass="height-10" [showOnlyDate]=true
                                        [isDisabled]="(isSensorChartLoading$ | async) || isHistoryLoading"
                                        [showPickerOnTop]="true" cssClass="calendar-text px-18" i18n-am="@@time_AM"
                                        i18n-pm="@@time_PM" am="AM" pm="PM" [minDate]="minDate" [maxDate]="maxDate"
                                        [EmrInvalidValidator]="isDateRangeValid">
                                        <emr-validation-message validatorName="required"
                                            message="Start Date/Time Required" i18n-message="@@start_date_required">
                                        </emr-validation-message>
                                        <emr-validation-message validatorName="EmrInvalidValidator" message="&nbsp;">
                                        </emr-validation-message>
                                    </emr-datetimepicker>
                                </div>
                                <div
                                    class="{{ dateTimeObject.showMeridian ? 'width-234' : 'width-210 hide-meridian' }} ml-3 date-time-picker">
                                    <emr-datetimepicker name="txtDateRangEndePicker" formGroupCssClass="mb-0"
                                        ngDefaultControl identifier="txtDateRangeEndPicker"
                                        [(ngModel)]="dateRange.endDateTime" required placeholder="End Date"
                                        i18n-placeholder="@@link_end_date" CustomizeGrid="true"
                                        labelCssClass="height-10" [showPickerOnTop]="true"
                                        cssClass="calendar-text px-18" [minDate]="minDate" [maxDate]="maxDate"
                                        [dateTimeObject]=dateTimeObject [showDateTimeInLine]="true" i18n-am="@@time_AM"
                                        i18n-pm="@@time_PM" am="AM" pm="PM" [setEndTime]="true" [setExtremeTimes]="true"
                                        (ngModelChange)="onDateChanged()" datePickerDivCssClass="width-130 px-0"
                                        timePickerDivCssClass="width-90 ml-1 px-0" [showOnlyDate]=true
                                        [isDisabled]="(isSensorChartLoading$ | async) || isHistoryLoading"
                                        [EmrInvalidValidator]="isDateRangeValid">
                                        <emr-validation-message validatorName="required"
                                            message="End Date/Time Required" i18n-message="@@end_date_required">
                                        </emr-validation-message>
                                        <emr-validation-message validatorName="EmrInvalidValidator"
                                            i18n-message="@@greater_end_time"
                                            message="End time must be greater than Start time">
                                        </emr-validation-message>
                                    </emr-datetimepicker>
                                </div>
                                <div class="width-185 text-left pl-1 z-index-2" *ngIf="isFilterDirty || displayReset">
                                    <emr-button *ngIf="isFilterDirty" identifier="btnApplyFilter" buttonType="submit"
                                        cssClass="btn mr-1 flt-btn apply-filter-btn mb-1" i18n-buttonText="@@link_apply"
                                        (onClick)="GenerateShipmentChart()" buttonText="Apply"
                                        [setDisabled]="(isSensorChartLoading$ | async) || isHistoryLoading">
                                    </emr-button>
                                    <emr-button *ngIf="displayReset" identifier="btnResetFilter" buttonType="reset"
                                        cssClass="btn mr-1 flt-btn reset-filter-btn mb-1"
                                        [setDisabled]="(isSensorChartLoading$ | async) || isHistoryLoading"
                                        i18n-buttonText="@@link_reset" (onClick)="GenerateShipmentChart(true)"
                                        buttonText="Reset"></emr-button>
                                </div>
                            </div>

                            <div class="row mx-0 mt-2">
                                <div *ngIf=" multiTripShipments?.length > 1" class="col-5 px-0">
                                    <div *ngIf="Shipment?.isMultiTrip">
                                        <emr-custom-dropdown ngDefaultControl identifier="lstMultiTripFilter"
                                            name="lstMultiTripFilter" [options]="multiTripShipments" label="Shipments"
                                            i18n-label="@@label_shipments" [(ngModel)]="Shipment.tripId"
                                            cssClass="default-dropdown" [inline]="true" [controlInline]="false"
                                            labelColumns=3 controlColumns=9 (onChange)="onMultiTripChange($event)"
                                            optionsValueField="TripId" optionsTextField="TripName"
                                            [setDisabled]="(isSensorChartLoading$ | async) || isHistoryLoading">
                                        </emr-custom-dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ng-container *ngIf="showTimeLine">
                                <app-timeline *ngSwitchCase="views.TIMELINE"
                                    [shipmentDetailsCommonData]="shipmentDetailsCommonData"
                                    [latestShipmentDetails]="getShipmentResponseData ? getShipmentResponseData.Shipment : null"
                                    [shipment]="Shipment" [TraxxList]="TraxxList" [TraxxCopy]="TraxxListCopy"
                                    [ShowDriveByLocationsInTimeLine]="ShowDriveByLocationsInTimeLine"
                                    [timelineInfo]="timelineData">
                                </app-timeline>
                            </ng-container>
                            <app-sensor-state [TraxxList]="TraxxList" [TraxxCopy]="TraxxListCopy"
                                [inputMinvalueYOverride]="minvalueYOverride"
                                [shipmentDetailsCommonData]="shipmentDetailsCommonData"
                                [inputMaxvalueYOverride]="maxvalueYOverride"
                                (updateMinMaxValues)="setChartMinMax($event)" [mktString]="mktString"
                                [tempUnits]="tempUnits" [DateRange]="chartDateRange"
                                [ShipmentResponseData]="getShipmentResponseData" [resetSensors]="this.resetSensors"
                                [chartDateTimeFormat]="chartDateTimeFormat" [selectedShipment]="Shipment"
                                *ngSwitchCase="views.SENSOR_CHART"
                                download_progress_message="Depending on the amount of data it could take up to a minute to complete."
                                i18n-download_progress_message="@@download_progress_message"
                                downloadCompleted="Download finished" [latestShipmentRequest]="latestShipmentRequest"
                                download_progress_title="Your requested download has started."
                                i18n-download_progress_title="@@download_progress_title"
                                i18n-downloadCompleted="@@download_complete" [shipment]="Shipment"
                                emailSent="Your request has been submitted and your email should arrive shortly."
                                i18n-emailSent="@@user_email_request">
                            </app-sensor-state>
                            <!-- <app-tracker-state *ngSwitchCase="views.TRACKER_STATE "></app-tracker-state>-->
                            <app-alert-summary *ngSwitchCase="views.ALERT_SUMMARY" [Shipment]="Shipment"
                                [DateRange]="selectedDateRange">
                            </app-alert-summary>
                            <app-historical-shipment *ngSwitchCase="views.HISTORICAL_SHIPMENT">
                            </app-historical-shipment>
                            <app-tags [dateFormat]="dateFormat" [loggerRead]="loggerRead" *ngSwitchCase="views.TAGS">
                            </app-tags>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #editStopsModal>
    <emr-modal headerTitle="Trip Stops" i18n-headerTitle="@@trip_stops" [bsModalRef]=stopsModalRef>
        <form modal-body emrForm name="frmEditStops" [ngClass]="{ 'ng-submitted': isStopsFormSubmitted}">
            <div class="col-md-12 bg-gray">
                <div class="col-md-12">
                    <app-stop-location-list [locations]=stopLocations #stopComponent [(tripStops)]="tripStops"
                        [Shipment]="createShipmentRequest" label="Airport Departure"
                        i18n-label="@@label_airport_departure" (onCreateNewLocation)="checkStopsFormState()"
                        [selectedOD]="getShipmentResponseData?.Shipment?.TripStartLocationId+','+getShipmentResponseData?.Shipment?.TripEndLocationId"
                        (openAddLocationModal)="openAddLocationModal($event)"></app-stop-location-list>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <p>
                            <emr-button identifier="updateStops" buttonType="submit" buttonText="Update"
                                i18n-buttonText="@@link_update" cssClass="btn-outline-default submit-btn"
                                (onClick)="updateTripStops(stopComponent)" buttonIcon="fa fa-check"></emr-button>
                            <emr-button identifier="cancelStops" buttonType="reset" buttonText="Cancel"
                                i18n-buttonText="@@link_cancel" cssClass="btn-outline-default reset-btn"
                                (onClick)="cancelEditTripStops()" buttonIcon="fa fa-repeat"> </emr-button>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </emr-modal>
</ng-template>

<ng-template #editAlertContactsModal>
    <emr-modal headerTitle="Additional Alert Contacts" i18n-headerTitle="@@label_additional_alert_contacts"
        [bsModalRef]=additionalAlertContacts>
        <form modal-body emrForm name="frmAlertContacts">
            <div class="col-md-12 bg-gray py-3">
                <div class="row">
                    <div class="col-md-12">
                        <p>
                            <emr-textbox ngDefaultControl identifier="txtCarrierEmail" EmrEmailValidator
                                name="txtCarrierEmail" label="Carrier Email(s)" i18n-label="@@label_carrier_emails"
                                placeholder="Carrier Email(s)" i18n-placeholder="@@label_carrier_emails"
                                [(ngModel)]="editShipmentRequest.CarrierEmails" name="txtCarrierEmails"
                                helpText="To add multiple emails, type email addresses separated with commas."
                                i18n-helpText="@@label_carrier_email_help_text" helpTip="Carrier Email(s)"
                                i18n-helpTip="@@label_carrier_emails" [inline]=true labelColumns=3
                                textBoxColumns="{{controlColumns}}">
                                <emr-validation-message validatorName="EmrEmailValidator"
                                    i18n-message="@@validation_email" message="Please enter valid Email	">
                                </emr-validation-message>
                            </emr-textbox>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <label class="label-css col-md-3" for="chklstEscalationLevels">
                        <span class="cursor-pointer" i18n-tooltip="@@label_alert_escalation_levels"
                            tooltip="Alert Escalation Levels" placement="top" i18n="@@label_alert_escalation_levels">
                            Alert Escalation Levels</span>
                    </label>
                    <div class="col-md-9">
                        <div class="row mlr-0">
                            <div class="col-md-2">
                                <emr-checkbox ngDefaultControl identifier="chklstEscalationLevel_1"
                                    name="chklstEscalationLevel_1" text="Level 1" i18n-text="@@AlertEscalationLevel_1"
                                    inline=true [(ngModel)]="editShipmentRequest.L1">
                                </emr-checkbox>
                            </div>
                            <div class="col-md-2">
                                <emr-checkbox ngDefaultControl identifier="chklstEscalationLevel_2"
                                    name="chklstEscalationLevel_2" text="Level 2" i18n-text="@@AlertEscalationLevel_2"
                                    inline=true [(ngModel)]="editShipmentRequest.L2">
                                </emr-checkbox>
                            </div>
                            <div class="col-md-2">
                                <emr-checkbox ngDefaultControl identifier="chklstEscalationLevel_3"
                                    name="chklstEscalationLevel_3" text="Level 3" i18n-text="@@AlertEscalationLevel_3"
                                    inline=true [(ngModel)]="editShipmentRequest.L3">
                                </emr-checkbox>
                            </div>
                            <div class="col-md-2">
                                <emr-checkbox ngDefaultControl identifier="chklstEscalationLevel_4"
                                    name="chklstEscalationLevel_4" text="Level 4" i18n-text="@@AlertEscalationLevel_4"
                                    inline=true [(ngModel)]="editShipmentRequest.L4">
                                </emr-checkbox>
                            </div>
                            <div class="col-md-2">
                                <emr-checkbox ngDefaultControl identifier="chklstEscalationLevel_5"
                                    name="chklstEscalationLevel_5" text="Level 5" i18n-text="@@AlertEscalationLevel_5"
                                    inline=true [(ngModel)]="editShipmentRequest.L5">
                                </emr-checkbox>
                            </div>
                            <div class="col-md-2">
                                <emr-checkbox ngDefaultControl identifier="chklstEscalationLevel_6"
                                    name="chklstEscalationLevel_6" text="Level 6" i18n-text="@@AlertEscalationLevel_6"
                                    inline=true [(ngModel)]="editShipmentRequest.L6">
                                </emr-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <p>
                            <emr-button identifier="saveAlertContact" buttonType="submit" buttonText="Update"
                                i18n-buttonText="@@link_update" cssClass="btn-outline-default submit-btn"
                                (onClick)="updateAlertContacts()" buttonIcon="fa fa-check"></emr-button>
                            <emr-button identifier="cancelStops" buttonType="reset" buttonText="Cancel"
                                i18n-buttonText="@@link_cancel" cssClass="btn-outline-default reset-btn"
                                (onClick)="cancelAlertContacts()" buttonIcon="fa fa-repeat"> </emr-button>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </emr-modal>
</ng-template>

<ng-template #addressInfoModal>
    <emr-modal id="addressInfoModa" class="scrollable-modal" headerTitle="Show Address"
        i18n-headerTitle="@@link_show_address" [bsModalRef]=addressInfoPopup>

        <ng-container modal-body>
            <div class="col-md-12 modal-body-message">
                <div class="row my-2">
                    <div class="col-md-12">
                        {{addressName}}
                    </div>
                    <div class="col-md-12">
                        {{address}}
                    </div>
                </div>
            </div>
        </ng-container>
    </emr-modal>
</ng-template>