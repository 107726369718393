import { Injectable } from '@angular/core';
import { tap, switchMap, share, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { GetDistanceToTargetsRequest } from 'emr-ng-shared';
import { GetDistanceToTargetsResponse } from 'emr-ng-shared';
import { GetRouteRequest } from 'emr-ng-shared';
import { GetRouteResponse } from 'emr-ng-shared';
import { DistanceToTargetDetails, LocationInfoListItem } from 'app-modules/core/models/distance-to-target-details.model';
import { LocationInfo } from 'emr-ng-shared';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';

@Injectable()
export class ShowDistanceService {
    tempSetTripDestinationLatLong(tripDestination: LocationInfo) {
        if (tripDestination && tripDestination.Description.indexOf(',') >= 0) {
            const commaDescription = tripDestination.Description.split(',');
            const lat = (commaDescription[1]).replace('(', '');
            const long = (commaDescription[2]).replace(')', '');
            tripDestination.Latitude = Number(lat);
            tripDestination.Longitude = Number(long);
        }
    }
    constructor(
        private oversightSvc: OversightApiService, private detailStateSvc: ShipmentDetailStateService
    ) { }

    public GetDistanceTo(distanceToTargetsRequest: GetDistanceToTargetsRequest) {
        return of(distanceToTargetsRequest).pipe(
            tap(n => this.detailStateSvc.loadDistance()),
            switchMap(n => this.GetDistanceToTargets(n)),
            tap(
                n => {
                    this.detailStateSvc.loadDistancetSuccess();
                },
                e => {
                    this.detailStateSvc.loadDistanceError();
                }
            ),
            share()
        );
    }

    GetDistanceToTargets(distanceToTargetsRequest: GetDistanceToTargetsRequest) {
        return this.oversightSvc.GetDistanceToTargets(distanceToTargetsRequest).pipe(
            map(n => n),
            catchError(error => [])
        );
    }

    GetDistanceDetails(distanceToTargetsResponse: GetDistanceToTargetsResponse) {
        const response = new DistanceToTargetDetails();
        if (distanceToTargetsResponse) {
            response.DefaultLocation = distanceToTargetsResponse.DefaultLocation;
            if (distanceToTargetsResponse.Locations) {
                response.Locations = distanceToTargetsResponse.Locations.map((l) => new LocationInfoListItem(l));
            }
            if (distanceToTargetsResponse.Shipments) {
                response.Shipments = distanceToTargetsResponse.Shipments.map((l) => new LocationInfoListItem(l));
            }
            response.TripDestination = this.SetLocationDetails(distanceToTargetsResponse.TripDestination);
            response.StartDestination = this.SetLocationDetails(distanceToTargetsResponse.StartDestination);

            // Remove below line once the Latitude & Longitude are sent from API
            // this.tempSetTripDestinationLatLong(response.TripDestination);
        }
        return response;
    }

    SetLocationDetails(location: LocationInfo) {
        const newLocation = new LocationInfo();
        newLocation.Address = location ? location.Description : null;
        newLocation.FullAddress = location ? location.FullAddress : null;
        newLocation.Latitude = location ? location.Latitude : null;
        newLocation.Longitude = location ? location.Longitude : null;
        newLocation.Description = location ? location.Description : null;
        newLocation.ShipmentName = location ? location.ShipmentName : null;
        return newLocation;
    }

    public GetShowDistanceDetails(routeRequest: GetRouteRequest) {
        return of(routeRequest).pipe(
            tap(n => this.detailStateSvc.loadShowDistance()),
            switchMap(n => this.GetRoute(n)),
            tap(
                n => {
                    this.detailStateSvc.loadShowDistancetSuccess();
                },
                e => {
                    this.detailStateSvc.loadShowDistanceError();
                }
            ),
            share()
        );
    }

    GetRoute(routeRequest: GetRouteRequest) {
        return this.oversightSvc.GetRoute(routeRequest).pipe(
            catchError(error => [])
        );
    }

    GetRouteDetails(routeResponse: GetRouteResponse) {
        const response = new GetRouteResponse();
        return response;
    }

    getUnAuthToken() {
        return this.oversightSvc.getUnAuthenticationToken();
    }

}


