<div id="divBanner" *ngIf="isShowBanner">
    <a class="bg-banner banner-css p-1 p-t-4 container-fluid text-decoration-none"
        href="https://e360hub.emerson.com/oversight-cargo-services/oversight-reports-scorecards-datasheet" target="_blank">
        <span class="banner-text">
            <i class="fa fa-edit banner-color f-s-16"></i>
            <span class="banner-color f-s-16">|</span>
            <span class="f-s-14 text-dark">
                How can I get more out of Reports & Scorecards?
            </span>
        </span>
        <span class="bg-banner-color banner-search-icon">
            <i class="fa fa-search text-white"></i>
        </span>
    </a>
    <a class="topcorner" (click)="OnCloseButtonClick()">
        <span>
            <i class="fa fa-times text-white"></i>
        </span>
    </a>
</div>