import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { MultiTrip } from 'emr-ng-shared';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from '../actions/custom-actions';

export class LoadMultiTrips implements ServerRequestAction {
    readonly type = actionIndex.LOAD_MULTI_TRIPS;
}

export class LoadMultiTripsSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_MULTI_TRIPS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_MULTI_TRIPS;
    constructor(
        public chartData: IListInfo<MultiTrip>
    ) { }
}

export class LoadMultiTripsError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_MULTI_TRIPS_ERROR;
    readonly callingAction = actionIndex.LOAD_MULTI_TRIPS;

    constructor(public message: string) { }
}

export class CancelLoadMultiTrips implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_MULTI_TRIPS;
    readonly callingAction = actionIndex.LOAD_MULTI_TRIPS;
}

