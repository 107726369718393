import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share , startWith } from 'rxjs/operators';

import { muteFirst } from 'emr-ng-shared';

import { GetCarrierListRequest } from 'emr-ng-shared';
import { CarrierInfo } from 'emr-ng-shared';

import { CarrierStateService } from 'app-modules/core/store/services/carrier-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class CarrierService {
    constructor(
        private carrierStateSvc: CarrierStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.carrierStateSvc.isLoadRequired$;

    public isLoading$ = this.carrierStateSvc.isLoading$;

    public carrierList$: Observable<IListInfo<CarrierInfo>> = muteFirst(
        this.getCarrierListLoader().pipe(startWith(null)),
        this.carrierStateSvc.carrierList$
    );

    private getCarrierListLoader(): Observable<IListInfo<CarrierInfo>> {
        return this.carrierStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.carrierStateSvc.loadCarriers()),
            switchMap(() => this.getCarrierList()),
            tap(
                n => this.carrierStateSvc.loadCarriersSuccess(n),
                e => this.carrierStateSvc.loadCarriersError('')
            ),
            finalize(() => this.carrierStateSvc.cancelLoadCarriers()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getCarrierList(): Observable<IListInfo<CarrierInfo>> {
        const request = new GetCarrierListRequest();

        return this.oversightSvc.GetCarrierList(request).pipe(
            map(n => {
                return {
                    list: n.CarrierList,
                    itemCount: n.CarrierList.length,
                    isPaged: false
                };
            })
        );
    }
}





