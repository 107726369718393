import { Action } from '@ngrx/store';

import { DualVisibilityLinkCustomer } from 'emr-ng-shared';

import * as actionIndex from './index';

export class SelectLinkedCustomer implements Action {
    readonly type = actionIndex.EDIT_SHIPMENT_SELECT_LINKED_CUSTOMER;

    constructor(public linkedCustomer: DualVisibilityLinkCustomer) { }
}

