import { Component, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export class CloseShipmentResponseOkEventArgs {
  modalRef: BsModalRef;
  hasError: boolean;
  closeOnOk: boolean;
}

@Component({
    selector: 'app-close-shipment-response',
    templateUrl: './close-shipment-response.component.html',
    styleUrls: ['./close-shipment-response.component.css']
  })

  export class CloseShipmentResponseComponent {
    @Output() ok = new EventEmitter<CloseShipmentResponseOkEventArgs>();

    showCloseButton: false;
    hasError = false;
    errorMessage: string = null;
    closeOnOk: boolean;

    constructor(public bsModalRef: BsModalRef) {}

    onOkClick() {
      const args = new CloseShipmentResponseOkEventArgs();
      args.modalRef = this.bsModalRef;
      args.hasError = this.hasError;
      args.closeOnOk = this.closeOnOk;

      this.ok.emit(args);

      this.bsModalRef.hide();
    }
  }
