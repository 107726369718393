<div class="locale-selector">
    <app-language-selector (localeSelected)="onLocaleSelected($event)"></app-language-selector>
</div>
<!-- <div class="row">
    <div class="col-sm-6">
        <div class="header-icon">
            <img src="assets/language.png" alt="" />
        </div>
        <div class="locale-selector">
                <app-language-selector (localeSelected)="onLocaleSelected($event)"></app-language-selector>
            </div>
        </div>
    <div class="col-sm-6">
        <div class="header-icon">
            <img src="assets/locale.png" alt="" />
        </div>
        <div class="locale-selector">
            <app-locale-selector (localeSelected)="onLocaleSelected($event)"></app-locale-selector>
        </div>
    </div>
</div> -->
