<div class="row filter-component-custom" *ngIf="selectedList.length > 0">
    <div class="col-md-12">
        <div class="row ml-1 mr-1">
            <div class="filter-header-style" i18n="@@temperature_ranges">Temperature Ranges</div>
            <div class="filtered-item position-relative" *ngFor="let listItem of selectedList"><span
                    class="filter-content-style">{{listItem.Description}}</span>
                <!-- <span class="filter-count">{{listItem?.count>0?listItem?.count:0}}</span> -->
                <button class="filter-close-button" (click)="clearSelectedFilter(listItem.SensorRangeId)"><i
                        class="fa fa-close"></i></button></div>
        </div>
    </div>
</div>