import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as trackerStateChartActions from 'app-modules/core/store/actions/shipment-detail-tracker-state-chart.actions';
import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';
import * as periodActions from 'app-modules/core/store/actions/shipment-detail-tracker-state-chart-selected-time-period.actions';

import { ITrackerStateChart } from 'app-modules/core/store/models/tracker-state-chart.interface';

type Actions =
    authActions.SignOut |
    trackerStateChartActions.LoadTrackerStateChart |
    trackerStateChartActions.LoadTrackerStateChartSuccess |
    trackerStateChartActions.LoadTrackerStateChartError |
    detailActions.SelectShipment |
    periodActions.ChangeShipmentDetailTrackerStateChartTimePeriod;

export function ShipmentDetailTrackerStateChartReducer(
    state: ITrackerStateChart = initialState.shipmentDetailTrackerStateChart,
    action: Actions
): ITrackerStateChart {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailTrackerStateChart;

        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_SUCCESS:
            return {
                ...state,
                summary: action.summary,
                chartData: action.chartData,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_ERROR:
            return {
                ...state,
                errorMessage: action.message
            };
        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
        case actionIndex.SHIPMENT_DETAIL_TRACKER_STATE_CHART_SELECTED_TIME_PERIOD:
            return {
                ...state,
                isLoadRequired: true
            };
        default:
            return state;
    }
}
