import { ILocationState } from 'app-modules/core/store/models/locations-state.interface';
import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as locActions from 'app-modules/core/store/actions/location.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';
import { label_yes } from 'app-modules/core/consts/localization';

type Actions =
    authActions.SignOut |
    locActions.LoadLocationListSuccess |
    locActions.LoadLocationListError |
    locActions.CreateLocationSuccess |
    locActions.CreateLocationError |
    locActions.ReloadLocations |
    locActions.GetLocationBoundarySuccess |
    locActions.DeleteGeofenceSuccess |
    locActions.SaveGeofenceSuccess |
    locActions.GetLocationContactSuccess |
    locActions.SetLocationContactSuccess |
    locActions.DeleteLocationContactSuccess |
    locActions.UpdateLocationContact |
    custActions.SelectCustomerSuccess |
    locActions.DeleteLocationSuccess;

export function LocationReducer(state: ILocationState = initialState.location, action: Actions): ILocationState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return {
                ...state,
                locationList: emptyList(),
            };

        case actionIndex.CREATE_LOCATION_SUCCESS:
            const newLocationsList = state.locationList.list.splice(0);
            const listInfo = emptyList();
            const locationIndex = newLocationsList.findIndex(k => k.LocationId === action.location.LocationId);
            if (locationIndex > -1) {
                newLocationsList[locationIndex] = action.location
            } else {
                newLocationsList.push(action.location);
            }
            listInfo.itemCount = newLocationsList.length;
            listInfo.isPaged = false;
            listInfo.list = newLocationsList;
            return {
                ...state,
                locationList: listInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.RELOAD_LOCATIONS:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                locationList: emptyList(),
                isLoadRequired: true,
                isLoading: true
            };

        case actionIndex.LOAD_LOCATIONS_SUCCESS:
            let locs = action.locationList?.list;
            if (locs) {
                locs = locs.map(l => {
                    return { ...l, 
                        HasBoundary: l.Boundary?.length > 0 || l.RadiusKm > 0
                    };
                });
            }
            return {
                ...state,
                locationList: { ...action.locationList, list: locs},
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_LOCATIONS_ERROR:
        case actionIndex.CREATE_LOCATION_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.Get_LOCATION_BOUNDARY_SUCCESS: {
            const locUpdate = action.location;
            const lst = emptyList();
            lst.list = state.locationList.list.map(l => {
                if (l.LocationId === locUpdate.LocationId) {
                    return { 
                        ...l, 
                        Boundary: locUpdate.Boundary, 
                        Latitude: locUpdate.Latitude,
                        Longitude: locUpdate.Longitude,
                        RadiusKm:locUpdate.RadiusKm,
                        BoundaryLoaded: true,
                        HasBoundary: locUpdate.Boundary?.length > 0  || locUpdate.RadiusKm > 0
                    };
                }
                return l;
            })
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };
        }
        case actionIndex.DELETE_LOCATION_GEOFENCE_SUCCESS: 
        {
            const locUpdate = action.location;
            const lst = emptyList();
            lst.list = state.locationList.list.map(l => {
                if (l.LocationId === locUpdate.LocationId) {
                    return { 
                        ...l, 
                        Boundary: null,
                        RadiusKm: null,
                        BoundaryLoaded: true,
                        HasBoundary: false
                    };
                }
                return l;
            })
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };
        }
        case actionIndex.SAVE_LOCATION_GEOFENCE_SUCCESS: 
        {
            const locUpdate = action.location;
            const lst = emptyList();
            lst.list = state.locationList.list.map(l => {
                if (l.LocationId === locUpdate.LocationId) {
                    return { 
                        ...l, 
                        Boundary: locUpdate.Boundary,
                        RadiusKm: locUpdate.RadiusKm,
                        BoundaryLoaded: true,
                        HasBoundary: locUpdate.Boundary?.length > 0 || locUpdate.RadiusKm > 0
                    };
                }
                return l;
            })
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };
        }

        case actionIndex.GET_LOCATION_CONTACT_SUCCESS:
        {
            const locContacts = action.contactsList;
            const lst = emptyList();
            lst.list = state.locationList.list.map(l => {
                if (l.LocationId === action.locationID) {
                    return { 
                        ...l, 
                        Contacts: locContacts,
                        ContactLoaded: true
                    };
                }
                return l;
            })
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };
        }

        case actionIndex.DELETE_LOCATION_SUCCESS:
        {
            const lst = emptyList();
            lst.list = state.locationList.list.filter(l => l.LocationId !== action.request.LocationId);
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };
        }

        case actionIndex.SET_LOCATION_CONTACT_SUCCESS: 
        {
            const lst = emptyList();
            lst.list = state.locationList.list.map(l => {
                if (l.LocationId === action.request.LocationId) {
                    let newContactList = l?.Contacts?.filter(k => k.ContactId !== action.request.ContactId);
                    if (action.contact) {
                        newContactList.push(action.contact);
                        
                    }
                    
                    l.Contacts = newContactList;
                    l.ContactLoaded = true;
                }
                return l;
            })
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };
        }

        case actionIndex.DELETE_LOCATION_CONTACT_SUCCESS: 
        {
            const lst = emptyList();
            lst.list = state.locationList.list.map(l => {
                if (l.LocationId === action.request.LocationId) {
                    let newContactList = l?.Contacts;
                    if (l?.Contacts.length > 0 && action.request.ContactId) {
                        newContactList = l?.Contacts?.filter(k => k.ContactId !== action.request.ContactId);
                    }
                    l.Contacts = newContactList;
                    l.ContactLoaded = true;
                }
                return l;
            })
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };
        }

        case actionIndex.UPDATE_LOCATION_CONTACT_SUCCESS: {
            const lst = emptyList();
            lst.list = state.locationList.list.map(l => {
                if (l.LocationId === action.locationID) {
                    let newContactList = l?.Contacts;
                    if (action.contact?.ContactId) {
                        const existingContact = newContactList?.find(k => k.ContactId === action.contact.ContactId);
                        if (existingContact) {
                            

                            const objectKeys = Object.keys(existingContact);
                            objectKeys.forEach(element => {
                                existingContact[element] = action.contact[element];
                            });
                            
                        }
                    }
                    l.Contacts = newContactList;

                    l.ContactLoaded = true;
                } else {
                    l.ContactLoaded = false;
                }
                return l;
            })
            lst.itemCount = lst.list.length;
            lst.isPaged = false;
            return {
                ...state,
                locationList: lst
            };

        }

        default:
            return state;
    }
}
