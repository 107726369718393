import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { filter, tap, switchMap, finalize, catchError, share, map } from 'rxjs/operators';
import { emptyList, IListInfo, setListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { ImportLocationsStateService } from 'app-modules/core/store/services/import-locations-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { ICustomInfoWithErrorResponse, setCustomInfoWithErrorResponse } from 'app-modules/core/store/models/custom-info-error-state-interface';
import { LocationStateService } from 'app-modules/core/store/services/location-state.service';
import { SetLocationRequest, SetLocationResponse } from 'emr-ng-shared';
import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { DomoUIStateService } from 'app-modules/domo/store/domo.state.service';

@Injectable()
export class ImportLocationService {

    constructor(
        private impLocStateSvc: ImportLocationsStateService,
        private oversightSvc: OversightApiService,
        private locStateSvc: LocationStateService,
        private domoUIStateSvc: DomoUIStateService
    ) { }

    public uploadExcelLocations(byteArray: any[]): Observable<ICustomInfoWithErrorResponse<string>> {
        return of(true).pipe(
            tap(() => this.impLocStateSvc.importLocationList()),
            switchMap(a => this.requestImportLocationsList(byteArray)),
            tap(
                n => this.impLocStateSvc.importLocationListSuccess(n.Info),
                e => this.impLocStateSvc.importLocationListError('')
            ),
            finalize(() => this.impLocStateSvc.cancelImportLocationList()),
            catchError((e) => of(null))
        );
    }

    requestImportLocationsList(byteArray): Observable<ICustomInfoWithErrorResponse<string>> {
        return this.oversightSvc.ImportLocations(byteArray).pipe(
            map(n => {
                return setCustomInfoWithErrorResponse((n && n.ErrorCode) ? null : n, (n && n.ErrorCode) ? n.ErrorCode : 0, n.ErrorDescription, n.LocalizedErrorMessage);
            })
        );
    }

    public saveExcelLocations(locations: string): Observable<ICustomInfoWithErrorResponse<string>> {
        return of(true).pipe(
            tap(() => this.impLocStateSvc.saveImportLocationList()),
            switchMap(a => this.requestSaveImportLocationsList(locations)),
            tap(
                n => {
                    this.impLocStateSvc.saveImportLocationSuccess(n.Info);
                    this.locStateSvc.reloadLocations();
                },
                e => this.impLocStateSvc.saveImportLocationError('')
            ),
            finalize(() => this.impLocStateSvc.cancelSaveImportLocationList()),
            catchError((e) => of(null))
        );
    }

    requestSaveImportLocationsList(locations): Observable<ICustomInfoWithErrorResponse<string>> {
        return this.oversightSvc.SaveImportLocations(locations).pipe(
            map(n => {
                return setCustomInfoWithErrorResponse((n && n.ErrorCode) ? null : n, (n && n.ErrorCode) ? n.ErrorCode : 0, n.ErrorDescription, n.LocalizedErrorMessage);
            })
        );
    }

    public getNearestLocation(request: any): Observable<SetLocationResponse> {
        return of(request).pipe(
            tap(n => this.domoUIStateSvc.DisplayLoader()),
            switchMap(n => this.oversightSvc.GetNearestLocations(n)),
            handleErrorResponse(),
            map<SetLocationResponse, SetLocationResponse>(n => n),
            tap(
                n => {
                    this.domoUIStateSvc.CancelLoader();
                },
                e => this.domoUIStateSvc.CancelLoader()
            ),
            finalize(() => this.domoUIStateSvc.CancelLoader()),
            catchError((e) => of(null))
        );
    }

}
