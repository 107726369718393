import { Action } from '@ngrx/store';
import { SavedRouteInfo } from 'emr-ng-shared';
import * as actionIndex from 'app-modules/core/store/actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadSavedRoute implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SAVED_ROUTE;
}

export class LoadSavedRouteSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SAVED_ROUTE_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SAVED_ROUTE;
    constructor(public routes: IListInfo<SavedRouteInfo>) { }
}

export class LoadSavedRouteError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SAVED_ROUTE_ERROR;
    readonly callingAction = actionIndex.LOAD_SAVED_ROUTE;
    constructor(public payload: string) { }
}

export class CancelLoadSavedRoute implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_SAVED_ROUTE;
    readonly callingAction = actionIndex.LOAD_SAVED_ROUTE;
}

export class SaveRoute implements ServerRequestAction {
    readonly type = actionIndex.SAVE_ROUTE;
}

export class SaveRouteSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_ROUTE_SUCCESS;
    readonly callingAction = actionIndex.SAVE_ROUTE;
}

export class SaveRouteError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_ROUTE_ERROR;
    readonly callingAction = actionIndex.SAVE_ROUTE;
    constructor() { }
}

export class DeleteRoute implements ServerRequestAction {
    readonly type = actionIndex.DELETE_ROUTE;
}

export class DeleteRouteSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_ROUTE_SUCCESS;
    readonly callingAction = actionIndex.DELETE_ROUTE;
    constructor(public routes) { }
}

export class DeleteRouteError implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_ROUTE_ERROR;
    readonly callingAction = actionIndex.DELETE_ROUTE;
}
