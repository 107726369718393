import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, tap, switchMap, finalize, share, catchError, startWith, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { muteFirst } from 'emr-ng-shared';

import { GetBusinessRulesRequest } from 'emr-ng-shared';
import { GetBusinessRulesResponse } from 'emr-ng-shared';
import { BusinessRuleInfo } from 'emr-ng-shared';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { BusinessRulesStateService } from './business-rules-state.service';
import { of } from 'rxjs';
import * as actionIndex from 'app-modules/core/store/actions/index';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { BusinessRuleType } from 'emr-ng-shared';
import { UnAuthStateService } from '../un-auth/un-auth-state.service';

@Injectable()
export class BusinessRulesService {
    constructor(
        private store: Store<IAppState>,
        private oversightSvc: OversightApiService,
        private businessRulesStatesvc: BusinessRulesStateService,
        private unAuthStateSvc: UnAuthStateService) { }


    // private isLoadRequired$ = this.businessRulesStatesvc.isLoadRequired$.pipe(
    //     filter(isloadRequired => isloadRequired),
    //     tap(() => this.store.dispatch({ type: actionIndex.LOAD_BUSINESS_RULES })),
    //     switchMap(() => this.getBusinessRulesList()),
    //     tap(responseData => this.store.dispatch({ type: actionIndex.LOAD_BUSINESS_RULES_SUCCESS, payload: responseData })),
    //     finalize(() => this.store.dispatch({ type: actionIndex.CANCEL_LOAD_BUSINESS_RULES })),
    //     share()
    // );

    public businessRulesList$ = muteFirst(
        this.GetBusinessRules().pipe(startWith(null)),
        this.businessRulesStatesvc.BusinessRulesList$
    );

    public GetBusinessRules() {
        return this.businessRulesStatesvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            switchMap(() => {
                this.businessRulesStatesvc.loadBusinessRules();  //jelax OR-2497
                const n = new GetBusinessRulesRequest();
                if (this.oversightSvc.getUnAuthenticationToken()) {
                    return this.unAuthStateSvc.unAuthCustomer$.pipe(
                        map(c => {
                            return { ...n, CustomerId: c?.CustomerId }
                        }),
                        switchMap(n => this.getBusinessRulesList(n))
                    );
                } else {
                    return this.getBusinessRulesList(n);
                }
            }),
            tap(
                n => this.businessRulesStatesvc.loadBusinessRulesSuccess(n),
                e => this.businessRulesStatesvc.loadBusinessRulesError(e)
            ),
            finalize(() => this.businessRulesStatesvc.cancelBusinessRule()),
            catchError(() => {
                this.businessRulesStatesvc.cancelBusinessRule();
                return of(emptyList());
            }),
            share()
        );
    }

    public GetBusinessRuleByEnum(request: GetBusinessRulesRequest) {
        return of(null).pipe(
            switchMap(() => this.getBusinessRulesList(request)),
            tap(
                n => this.businessRulesStatesvc.cancelBusinessRule(),
                e => this.businessRulesStatesvc.cancelBusinessRule()
            ),
            finalize(() => this.businessRulesStatesvc.cancelBusinessRule()),
            catchError(() => {
                this.businessRulesStatesvc.cancelBusinessRule();
                return of(emptyList());
            }),
            share()
        );
    }

    public getBusinessRulesList(request: GetBusinessRulesRequest): Observable<IListInfo<BusinessRuleInfo>> {
        return this.oversightSvc.GetBusinessRules(request).pipe(
            map((response: GetBusinessRulesResponse) => <IListInfo<BusinessRuleInfo>>{
                list: response.BusinessRules
            })
        );
    }
}


