import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { TimeZoneInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as userSettingsActions from './user-settings.actions';
import { UserSetting } from 'app-modules/core/models/user-setting.model';
import { USER_SETTING_KEY_CUSTOM_FILTERS, USER_SETTING_SECTION_SETTINGS } from 'app-modules/core/consts/user-setting';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserSettingsStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public userSettingsStateSelector = ((state: IAppState) => state.userSettings);
    public isLoadRequiredSelector = createSelector(
        this.userSettingsStateSelector,
        (state) => state.isLoadRequired
    );

    public isLoadingSelector = createSelector(
        this.userSettingsStateSelector,
        (state) => state.isLoading
    );

    public userSettingsSelector = createSelector(
        this.userSettingsStateSelector,
        (state) => state.Value
    );

    public shipmentFilterSelector = createSelector(
        this.userSettingsSelector,
        (state) => state?.find(x=>x.Section === USER_SETTING_SECTION_SETTINGS && x.Key ===USER_SETTING_KEY_CUSTOM_FILTERS)?.Value
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public userSettingsList$ = this.store.select(this.userSettingsSelector).pipe();
    public shipmentFilterSetting$ = this.store.select(this.shipmentFilterSelector).pipe();

    // Actions
    public loadUserSettings() {
        this.store.dispatch(new userSettingsActions.LoadUserSettings());
    }

    public loadUserSettingsSuccess() {
        this.store.dispatch(new userSettingsActions.LoadUserSettingsSuccess());
    }

    public loadUserSettingsError() {
        this.store.dispatch(new userSettingsActions.LoadUserSettingsError());
    }

    public loadUserSettingsWithStore() {
        this.store.dispatch(new userSettingsActions.LoadUserSettingsWithStore());
    }

    public loadUserSettingsSuccessWithStore(value: UserSetting[]) {
        this.store.dispatch(new userSettingsActions.LoadUserSettingsSuccessWithStore(value));
    }

    public loadUserSettingsWithStoreError(message: string) {
        this.store.dispatch(new userSettingsActions.LoadUserSettingsWithStoreError(message));
    }

    public cancelLoadUserSettings() {
        this.store.dispatch(new userSettingsActions.CancelLoadUserSettings());
    }

    public saveUserSettings() {
        this.store.dispatch(new userSettingsActions.SaveUserSettings());
    }

    public saveUserSettingsSuccess(value: UserSetting) {
        this.store.dispatch(new userSettingsActions.SaveUserSettingsSuccess(value));
    }

    public saveUserSettingsError(message: string) {
        this.store.dispatch(new userSettingsActions.SaveUserSettingsError(message));
    }
}


