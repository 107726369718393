import { IUnAuthState } from 'app-modules/core/store/models/un-auth-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as unauthActions from 'app-modules/core/store/un-auth/un-auth.actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';

type Actions =
    unauthActions.SaveUnAuthTrackers |
    unauthActions.SaveUnAuthCustomer |
    unauthActions.SaveUnAuthCreateShipmentAccess |
    unauthActions.SaveUnAuthCustomerLocation |
    unauthActions.ClearUnAuthCustomer |
    authActions.SignIn |
    authActions.SignInSuccess |
    authActions.SignInError |
    authActions.SignOut;

export function UnAuthReducer(state: IUnAuthState = initialState.unAuthTrackers, action: Actions): IUnAuthState {
    switch (action.type) {
        case actionIndex.CLEAR_UN_AUTH_CUSTOMER:
        case (actionIndex.SIGN_IN):
        case (actionIndex.SIGN_IN_SUCCESS):
        case (actionIndex.SIGN_IN_ERROR):
            return {
                ...state,
                UnAuthTrackers: null,
                Customer: null,
                HasCreateShipmentAccess: false,
                DefaultLocation: null
            };
        case (actionIndex.SAVE_UN_AUTH_TRACKERS):
            return {
                ...state,
                UnAuthTrackers: action.UnAuthTrackers
            };
        case (actionIndex.UN_AUTH_CREATE_SHIPMENT_ACCESS):
            return {
                ...state,
                HasCreateShipmentAccess: action.hasAccess
            };

        case (actionIndex.SAVE_UN_AUTH_CUSTOMER):
            return {
                ...state,
                Customer: action.UnAuthCustomer
            };

            
        case (actionIndex.SAVE_UN_AUTH_CUSTOMER_LOCATION):
            return {
                ...state,
                DefaultLocation: action.location
            };


        default:
            return state;
    }
}
