<div class="col-md-12 manage-templates">
    <div class="row mt-2">
        <div class="col-sm-5">
            <form name=" frmTemplateSearch" (keyup.enter)="onSearchSubmit()" (keyup.tab)="onSearchSubmit()"
                #TemplateSearch="ngForm" novalidate autocomplete="off">
                <div class="full-width-float-box template-SearchBox default-dropdown">
                    <emr-typeahead identifier="txtTemplateSearch" name="txtTemplateSearch_{{randomNumber}}"
                        placeholder="Shipment Template" i18n-placeholder="@@label_shipment_template"
                        [dataSource]="templateList" typeaheadOptionFieldName="text" [(ngModel)]="searchInput"
                        (fireElementBlur)="customBlur()" [typeaheadOptionsCountInScrollableView]=5
                        [typeaheadIsFirstItemActive]=false [typeaheadSelectFirstItem]=false
                        (ItemSelected)="onSearchInputSelect($event)" typeaheadHideResultsOnBlur=true>
                    </emr-typeahead>
                    <emr-button identifier="resetQuickSearch" (onClick)="resetQuickSearch()"
                        identifier="filterQuickResetButton"
                        cssClass="btn flt-btn reset-filter-btn mb-1 close-btn-quick-search filter-button"
                        *ngIf="searchInput?.length>0&&showQuickReset" i18n-buttonText="@@link_reset" buttonText="Reset">
                    </emr-button>
                </div>
                <div class="inline-loader-container" *ngIf="loading$ | async">
                    <app-local-loading-indicator width="35px" height="35px">
                    </app-local-loading-indicator>
                </div>
            </form>
        </div>
        <div class="add-btn col-2 px-1 text-left">
            <emr-button identifier="btnAddNewTemplate" cssClass=" btn-outline-primary bg-white"
                buttonText="Create New Template" i18n-buttonText="@@label_create_new_template"
                (onClick)="openAddTemplateModal()">
            </emr-button>
        </div>
        <div class="col-sm-5 pr-0 text-right">
            <ul class="nav justify-content-end">
                <li class="nav-item">
                    <app-total-records [itemCount]="filteredList" skipThreshold="true"></app-total-records>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mx-0">
        <div class="col-md-12 manage-templates">
            <emr-custom-table [columnDefs]="columnDefs" [data]="templates" [calculateColumnCount]="false"
                [showColumnSelection]="false" [applyFilter]="true" trackBy="TemplateID" 
                [clearFilter]="resetFilter" (filterApply)="onFilterApply($event)">
            </emr-custom-table>
            <div class="d-none">
                <ng-template #button let-rowData>
                    <div>
                        <emr-button identifier="btnEditTemplate"
                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                            buttonText="Edit Template" i18n-buttonText="@@label_edit_template"
                            (onClick)="onTemplateEdit(rowData)">
                        </emr-button>
                    </div>
                    <div>
                        <emr-button identifier="btnDeleteTemplate"
                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                            buttonText="Delete" i18n-buttonText="@@link_delete"
                            (onClick)="onDeleteClick(rowData)">
                        </emr-button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>