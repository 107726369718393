import { IShipmentTemplateState } from 'app-modules/core/store/models/shipment-template-state.interface';

import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as templateActions from 'app-modules/core/store/shipment-template/shipment-template.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';
import { TripTemplateInfo } from 'emr-ng-shared';

type Actions =
    authActions.SignOut |
    templateActions.LoadShipmentTemplates |
    templateActions.LoadShipmentTemplatesSuccess |
    templateActions.LoadShipmentTemplatesError |
    templateActions.CancelLoadShipmentTemplates |
    templateActions.CreateTripTemplateSuccess |
    templateActions.ReloadShipmentTemplates |
    templateActions.SetShipmentTemplateSuccess |
    custActions.SelectCustomerSuccess;

export function ShipmentTemplateReducer(
    state: IShipmentTemplateState = initialState.shipmentTemplate,
    action: Actions
): IShipmentTemplateState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentTemplate;

        case actionIndex.CREATE_TRIP_TEMPLATE_SUCCESS:
            const newShipmentTemplateList = state.shipmentTemplateList.list.splice(0);
            const listInfo = emptyList();
            newShipmentTemplateList.push(action.ShipmentTemplate);
            listInfo.list = newShipmentTemplateList;
            listInfo.itemCount = newShipmentTemplateList.length;
            listInfo.isPaged = false;
            return {
                ...state,
                shipmentTemplateList: listInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.RELOAD_SHIPMENT_TEMPLATES:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                shipmentTemplateList: emptyList(),
                isLoadRequired: true,
                isLoading: true
            };
        case actionIndex.LOAD_SHIPMENT_TEMPLATES_SUCCESS:
            return {
                ...state,
                shipmentTemplateList: action.shipmentTemplateList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_SHIPMENT_TEMPLATES_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoadRequired: false,
                errorMessage: <string>action.message
            };
        case actionIndex.CANCEL_LOAD_SHIPMENT_TEMPLATES:
            return {
                ...state,
                isLoading: false,
                isLoadRequired: false
            };

        case actionIndex.SET_SHIPMENT_TEMPLATE_SUCCESS:
            let newState = {
                ...state,
                isLoading: false
            };
            if (action.request) {
                if (action.isDelete) {
                    const newShipmentTemplateList = state?.shipmentTemplateList?.list.filter(k => 
                        k.TripTemplateId !== action.request.TripTemplateID);
                    let listInfo = emptyList();
                    listInfo.list = newShipmentTemplateList;
                    listInfo.itemCount = newShipmentTemplateList.length;
                    listInfo.isPaged = false;
                    return {
                        ...state,
                        shipmentTemplateList: listInfo,
                        isLoadRequired: false,
                        isLoading: false,
                        errorMessage: ''
                    };
                } else if (action.request.TripTemplateID) {
                    const templatesList = emptyList();
                    templatesList.list = newState.shipmentTemplateList.list.map(a => {
                        if (a.TripTemplateId === action.request.TripTemplateID) {
                            a = {
                                ...a,
                                Name: action.request.TripTemplateName,
                                OriginLocationId: action.request.OriginLocationId,
                                OriginName: action.request.OriginName,
                                DestinationLocationId: action.request.DestinationLocationId,
                                DestinationName: action.request.DestinationName,
                                SensorRangeId: action.request.SensorRangeId
                            };
                        }
                        return a;
                    });
                    templatesList.itemCount = templatesList.list.length;
                    newState.shipmentTemplateList = templatesList;

                } else {
                    newState.isLoadRequired = true;
                    newState.isLoading = true;
                }
            }

            return newState;
        default:
            return state;
    }
}
