import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { TripEndOptions } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ITripEndOptionsState } from '../../models/trip-end-options-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as tripEndOptionActions from 'app-modules/core/store/create-shipment/trip-end-options/trip-end-options.actions';

@Injectable()
export class TripEndOptionsStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public TripEndOptionsStateSelector = ((state: IAppState) => state.tripEndOptions);
    public isLoadRequiredSelector = createSelector(
        this.TripEndOptionsStateSelector,
        (state: ITripEndOptionsState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.TripEndOptionsStateSelector,
        (state: ITripEndOptionsState) => state.isLoading
    );
    public TripEndOptionsListSelector = createSelector(
        this.TripEndOptionsStateSelector,
        (state: ITripEndOptionsState) => state.tripEndOptionsList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public TripEndOptionsList$ = this.store.select(this.TripEndOptionsListSelector).pipe();

    // Actions
    public loadTripEndOptions() {
        this.store.dispatch(new tripEndOptionActions.LoadTripEndOptions());
    }

    public loadTripEndOptionsSuccess(TripEndOptionsList: IListInfo<TripEndOptions>) {
        this.store.dispatch(new tripEndOptionActions.LoadTripEndOptionsSuccess(TripEndOptionsList));
    }

    public loadTripEndOptionsError(message: string) {
        this.store.dispatch(new tripEndOptionActions.LoadTripEndOptionsError(message));
    }

    public cancelLoadTripEndOptions() {
        this.store.dispatch(new tripEndOptionActions.CancelLoadTripEndOptions());
    }
}


