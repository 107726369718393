import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { CoolDownTypeInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ICoolDownTypeState } from '../models/cool-down-type-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as coolDownActions from './cool-down-type.actions';

@Injectable()
export class CoolDownTypeStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public coolDowntypeStateSelector = ((state: IAppState) => state.coolDownType);
    public isLoadRequiredSelector = createSelector(
        this.coolDowntypeStateSelector,
        (state: ICoolDownTypeState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.coolDowntypeStateSelector,
        (state: ICoolDownTypeState) => state.isLoading
    );
    public coolDownTypeListSelector = createSelector(
        this.coolDowntypeStateSelector,
        (state: ICoolDownTypeState) => state.coolDownTypeList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public coolDownTypeList$ = this.store.select(this.coolDownTypeListSelector).pipe();

    // Actions
    public loadCoolDownTypes() {
        this.store.dispatch(new coolDownActions.LoadCoolDownTypes());
    }

    public loadCoolDownTypesSuccess(coolDownTypeList: IListInfo<CoolDownTypeInfo>) {
        this.store.dispatch(new coolDownActions.LoadCoolDownTypesSuccess(coolDownTypeList));
    }

    public loadCoolDownTypesError(message: string) {
        this.store.dispatch(new coolDownActions.LoadCoolDownTypesError(message));
    }

    public cancelLoadCoolDownTypes() {
        this.store.dispatch(new coolDownActions.CancelLoadCoolDownTypes());
    }
}


