import { IShipmentDetailState } from 'app-modules/core/store/models/shipment-detail.interface';
import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';

type Actions =
    authActions.SignOut |
    detailActions.SelectShipment|
    detailActions.ResetShipment;

export function ShipmentDetailReducer(state: IShipmentDetailState = initialState.shipmentDetail, action: Actions): IShipmentDetailState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetail;

        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
        case actionIndex.SHIPMENT_DETAIL_RESET_SHIPMENT:
            return {
                ...state,
                selectedShipment: action.shipment
            };
        default:
            return state;
    }
}
