import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BsDropdownModule  } from 'ngx-bootstrap/dropdown';
import { ListViewRoutingModule } from './list-view-routing.module';
import { ListViewComponent } from './components/list-view/list-view.component';
import { CoreModule } from 'app-modules/core/core.module';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { ShipmentFilterModule } from 'app-modules/shipment-filter/shipment-filter.module';

@NgModule({
    declarations: [
        ListViewComponent
    ],
    imports: [
        CommonModule,
        ListViewRoutingModule,
        CoreModule,
        EmrNgControlsModule,
        ShipmentFilterModule,
        BsDropdownModule.forRoot()
    ],
    exports: []
})
export class ListViewModule { }

