import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, filter, tap, switchMap, finalize, catchError, share } from 'rxjs/operators';

import { FormatLocalizedStringRequest } from 'emr-ng-shared';
import { FormattedStrings } from 'emr-ng-shared';
import { FormatString } from 'emr-ng-shared';
import { muteFirst } from 'emr-ng-shared';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { startWith } from 'rxjs/operators';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { PageSizeStateService } from '../store/services/page-size.state.service';

const items = [25, 50, 100];
@Injectable()
export class PageSizeService {

    constructor(private oversightSvc: OversightApiService,private pageStateSvc: PageSizeStateService) { };

    public pageSizeList$: Observable<IListInfo<FormattedStrings>> = muteFirst(
        this.getPageSizeLoader().pipe(startWith(null)),
        this.pageStateSvc.pageSize$
    );

    private getPageSizeLoader(): Observable<IListInfo<FormattedStrings>> {
        return this.pageStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.pageStateSvc.loadPageSize()),
            switchMap(() => this.getPageSize()),
            tap(
                n => this.pageStateSvc.loadPageSizeSuccess(n),
                e => this.pageStateSvc.loadPageSizeError('')
            ),
            finalize(() => this.pageStateSvc.cancelLoadPageSize()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getPageSize(): Observable<IListInfo<FormattedStrings>> {
        const request = new FormatLocalizedStringRequest();
        request.FormatStringType = FormatString.PerPageFormat;
        request.Parameters = items;
        return this.oversightSvc.FormatLocalizedString(request).pipe(
            map(n => {
                return {
                    list: n.Strings,
                    itemCount: n.Strings.length,
                    isPaged: false
                };
            })
        );
    }
}


