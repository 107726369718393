import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { Subscription, Observable, combineLatest } from 'rxjs';
import { map, withLatestFrom, filter, share } from 'rxjs/operators';
import { ILatLong, GetRouteDeviationResponseModel, BusinessRuleType, BusinessRuleInfo, TrackerHistory, ShipmentRequest, TimePeriod, EmrUtilService } from 'emr-ng-shared';
import { environment } from 'environments/environment';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';

@Component({
  selector: 'app-shipment-route-popup',
  templateUrl: './shipment-route-popup.component.html',
  styleUrls: ['./shipment-route-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentRoutePopupComponent implements OnInit, OnDestroy {

  Shipment: Shipment;
  TraxxList: ITraxxState;
  getTraxxSubscription: Subscription;
  isItFromRouteDeviation = false;
  itraxxState$: Observable<ITraxxState>;
  getRouteDeviationResponse: GetRouteDeviationResponseModel;
  IsUnauthenticated = false;
  isLoading$: Observable<boolean>;
  shipmentList: TrackerHistory[];
  historicalShipmentsSub: Subscription;
  ShowEstimatedCrumb: boolean = null;

  constructor(
    public bsShipmentRouteModalRef: BsModalRef,
    private detailSvc: ShipmentDetailService,
    private detailStateSvc: ShipmentDetailStateService,
    private utilSvc: EmrUtilService,
  ) { }

  ngOnInit() {
    if (!this.isItFromRouteDeviation) {
      if (this.Shipment.Is4G) {
        const data = localStorage.getItem(environment.showEstimatedCrumbs);
        if (data !== null) {
          this.ShowEstimatedCrumb = (data === 'true');
        }
      }
      this.IsUnauthenticated = this.detailSvc.getUnAuthenticationToken();
      if (this.Shipment && this.Shipment.isMultiTrip) {
        this.detailSvc.selectShipment(this.Shipment);
        this.historicalShipmentsSub = this.detailSvc.historicalShipmentList$.pipe(
          withLatestFrom(this.detailStateSvc.isHistoricalShipmentListLoadRequired$,
            this.detailStateSvc.isHistoricalLoading$),
          filter(([n, loading, loadRequired]) => !(loading || loadRequired))
        )
        .subscribe(([n]) => {
          this.shipmentList = n.list;
          this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb, n.list);
          this.clearHistoricalSub();
        });
      } else {
        this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb);
      }
      this.getTraxxData();
    }
  }

  getTraxxData() {
    this.getTraxxSubscription = this.detailSvc.getTraxx$.subscribe(iTraxx => {
      this.TraxxList = iTraxx;
    });
  }

  public ngOnDestroy() {
    this.clearHistoricalSub();
    if (this.getTraxxSubscription) {
      this.detailSvc.getTraxxRequest(true);
      this.getTraxxSubscription.unsubscribe();
    }
  }

  private clearHistoricalSub() {
    if (this.historicalShipmentsSub) {
      this.historicalShipmentsSub.unsubscribe();
      this.historicalShipmentsSub = null;
    }
  }

  onShowEstimatedCrumbsChange() {
    localStorage.setItem(environment.showEstimatedCrumbs, this.ShowEstimatedCrumb.toString());
    this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb, this.shipmentList);
  }

  onDateRangeChange(data: { fromDate: Date, thruDate: Date}){
    if (data) {
      let fromDate = null;
      let thruDate = null;
      if (data.fromDate && data.thruDate) {
        fromDate = this.utilSvc.DateFormatLocaleChange(data.fromDate);
        thruDate = this.utilSvc.DateFormatLocaleChange(data.thruDate);
      }
      this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb, this.shipmentList, null, fromDate, thruDate);
      this.getTraxxData();
    }
  }
}

