<div class="col-md-12 mt-4 route-deviations">
  <div class="row mb-2">
    <div class="col-md-12">
      <form name="frmDeviationSearch" #deviationSearchFrom="ngForm" emrForm autocomplete="off">
        <ul class="bttnUnorderList">
          <li>
            <emr-button identifier="routeDeviationPDF" cssClass="btn btn-success mt-0 custom-padding text-white"
              buttonIcon="fa fa-file-pdf-o" (onClick)="onDownloadPDFClick()" buttonText="Export to PDF"
              i18n-buttonText="@@label_export_to_pdf" helpTip="Download Route Deviation Exception in pdf format"
              i18n-helpTip="@@tooltip_download_route_deviation_pdf" helpTipPosition="left" container="body"
              [setDisabled]="(selectedTrackerIds && selectedTrackerIds.length == 0)">
            </emr-button>
          </li>
          <li>
            <label class="label-css" i18n="@@actual_end_time">Actual End Time</label>
            <span class="label-css">&nbsp;:</span>
          </li>
          <li>
            <emr-datepicker cssClass="w-90" identifier="routeStartDate" name="routeStartDate" required
              [(ngModel)]="FromDate">
              <emr-validation-message validatorName="required" message="Start Date/Time Required"
                i18n-message="@@start_date_required">
              </emr-validation-message>
            </emr-datepicker>
          </li>
          <li>
            <label class="label-css d-inline" i18n="@@To">To</label>
          </li>
          <li>
            <emr-datepicker cssClass="w-90" identifier="routeEndDate" required [minDate]="FromDate" name="routeEndDate"
              [(ngModel)]="ToDate">
              <emr-validation-message validatorName="required" message="End Date/Time Required"
                i18n-message="@@end_date_required">
              </emr-validation-message>
            </emr-datepicker>
          </li>
          <li>
            <emr-button identifier="deviationSearch" buttonType="submit" buttonText="Search" i18n-buttonText="@@search"
              cssClass="btn-outline-default mt-0" (onClick)="onDeviationSearch()" buttonIcon="fa fa-search">
            </emr-button>
          </li>
          <li class="pl-0">
            <emr-button identifier="deviationReset" buttonType="reset" buttonText="Reset"
              i18n-buttonText="@@link_reset" cssClass="btn-outline-default mt-0" (onClick)="onDeviationReset()"
              buttonIcon="fa fa-repeat">
            </emr-button>
          </li>
        </ul>
      </form>
    </div>
  </div>
  <div class="row mb-1" *ngIf="displayErrorMessage">
    <div class="col-md-12">
      {{errorMessage}}</div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="main-container mb-2">
        <div class="table-data-container table-height pt-1">
          <div class="col-md-12 px-1">
            <div class="table-responsive">
              <table class="table table-striped deviation-table">
                <thead>
                  <tr>
                    <th scope="col" class="custom-table-header font-weight-bold" width="5%">
                      <div class="form-group  mb-0">
                        <div class="checkbox-control custom-control custom-checkbox p-0">
                          <input id="chkExcelDownload" disabled="{{routeDeviations?.length === 0}}"
                            name="chkExcelDownload" class="custom-control-input" type="checkbox" [(ngModel)]="CheckAll"
                            (change)="onCheckAll(routeDeviations)">
                          <label class="pl-22 line-height-25 custom-control-label label-css height-auto"
                            for="chkExcelDownload"><span class="invisible">.</span>
                          </label>
                        </div>
                      </div>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@label_serial_number">Serial Number</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@shipment_name">Shipment Name</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@label_trip_status">Shipment Status</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@label_route_name">Route Name</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@label_delivery_status">Delivery Status</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@scheduled_end_time">Scheduled End Time</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@actual_end_time">Actual End Time</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@label_temperature_range">Temperature Range</span>
                    </th>
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span i18n="@@carrier">Carrier</span>
                    </th>
                    <!-- <th scope="col" class="custom-table-header font-weight-bold  text-center">
                      <span i18n="@@label_deviation_count">Deviation Count</span>
                    </th> -->
                    <th scope="col" class="custom-table-header font-weight-bold">
                      <span></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of routeDeviations; let i = index;">
                    <td  width="5%">
                      <div class="form-group  mb-0">
                        <div class="checkbox-control custom-control custom-checkbox p-0">
                          <input id="chkExcelDownload_{{i}}" name="chkExcelDownload_{{i}}" class="custom-control-input"
                            type="checkbox" [(ngModel)]="item.IsPdfDownload"
                            (change)="onExcelDownloadCheckboxClick(item,routeDeviations)">
                          <label class="pl-22 line-height-25 custom-control-label label-css height-auto"
                            for="chkExcelDownload_{{i}}"><span class="invisible">.</span>
                          </label>
                        </div>
                      </div>

                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.GlobalDeviceId }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.TripDescription}}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.TripStateDescription }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.RouteName }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.DeliveryStatusString }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.ScheduledEndTimeString }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.TripEndTimeString }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.TemperatureRange }}</span>
                      </p>
                    </td>
                    <td>
                      <p class="table-row-data">
                        <span>{{ item.CarrierName }}</span>
                      </p>
                    </td>
                    <!-- <td>
                      <p class="table-row-data text-center">
                        <span>{{ item.DeviationCount}}</span>
                      </p>
                    </td> -->
                    <td>
                      <p class="table-row-data">
                        <emr-button identifier="pinFilter"
                          cssClass="btn button-text btn button-text btn-outline-default mt-0"
                          (onClick)="selectRoute(item)" i18n-buttonText="@@show_route" buttonText="Show Route">
                        </emr-button>
                      </p>
                    </td>
                  </tr>
                  <tr *ngIf="routeDeviations?.length === 0">
                    <td colspan="9">
                      <p class="table-row-data text-center" i18n="@@no_data_available">No data available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="offset-md-10 col-md-2">
      <p class="table-row-data">
        {{selectedDeviationLIstItem ? 'Selected Tracker Id => ' + selectedDeviationLIstItem.TrackerId : ''}}
      </p>
    </div>
  </div> -->
</div>