import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { SensorRangeInfo } from 'emr-ng-shared';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

import { SetSensorRangeRequest } from 'emr-ng-shared';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadSensorRanges implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SENSOR_RANGES;
}

export class LoadSensorRangesSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SENSOR_RANGES_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SENSOR_RANGES;
    constructor(public sensorRangeList: IListInfo<SensorRangeInfo>) { }
}

export class LoadSensorRangesError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SENSOR_RANGES_ERROR;
    readonly callingAction = actionIndex.LOAD_SENSOR_RANGES;
    constructor(public message: string) { }
}

export class CancelLoadSensorRanges implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.LOAD_SENSOR_RANGES;
    readonly type = actionIndex.CANCEL_LOAD_SENSOR_RANGES;
}

export class CreateSensorRange implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SENSOR_RANGE;
    constructor(public request: SetSensorRangeRequest) { }
}

export class CreateSensorRangeSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SENSOR_RANGE_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SENSOR_RANGE;

    constructor(public SensorRange: SensorRangeInfo) { }
}

export class CreateSensorRangeError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SENSOR_RANGE_ERROR;
    readonly callingAction = actionIndex.CREATE_SENSOR_RANGE;

    constructor(public message: string) { }
}

