import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith } from 'rxjs/operators';

import { muteFirst, SensorType } from 'emr-ng-shared';

import { GetSensorRangeRequest } from 'emr-ng-shared';
import { SetSensorRangeRequest } from 'emr-ng-shared';
import { SensorRangeInfo } from 'emr-ng-shared';

import { SensorRangeStateService } from 'app-modules/core/store/sensor-range/sensor-range-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { handleErrorResponse } from '../../rxjs/operators/handle-error-response.operator';
import { SetSensorRangeResponse } from 'emr-ng-shared';
import { UnAuthStateService } from '../un-auth/un-auth-state.service';

@Injectable()
export class SensorRangeService {
    constructor(
        private sensorRangeStateSvc: SensorRangeStateService,
        private unAuthStateSvc: UnAuthStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.sensorRangeStateSvc.isLoadRequired$;

    public isLoading$ = this.sensorRangeStateSvc.isLoading$;

    public sensorRangeList$: Observable<IListInfo<SensorRangeInfo>> = muteFirst(
        this.getSensorRangeListLoader().pipe(startWith(null)),
        this.sensorRangeStateSvc.sensorRangeList$
    );

    private getSensorRangeListLoader(): Observable<IListInfo<SensorRangeInfo>> {
        return this.sensorRangeStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.sensorRangeStateSvc.loadSensorRanges()),
            switchMap(() => this.getSensorRangeList()),
            tap(
                n => this.sensorRangeStateSvc.loadSensorRangesSuccess(n),
                e => this.sensorRangeStateSvc.loadSensorRangesError(e.message)
            ),
            finalize(() => this.sensorRangeStateSvc.cancelLoadSensorRanges()),
            catchError(() => {
                this.sensorRangeStateSvc.cancelLoadSensorRanges();
                return of(emptyList());
            }),
            share()
        );
    }

    private getSensorRangeList(): Observable<IListInfo<SensorRangeInfo>> {
        const request = new GetSensorRangeRequest();

        return this.oversightSvc.GetSensorRanges(request).pipe(
            map(n => {
                return {
                    list: n.SensorRangeList,
                    itemCount: n.SensorRangeList.length,
                    isPaged: false
                };
            })
        );
    }

    public updateSensorRanges(n: IListInfo<SensorRangeInfo>): void {
        this.sensorRangeStateSvc.loadSensorRangesSuccess(n);
    }

    public setSensorRange(request: SetSensorRangeRequest) {
        return of(request).pipe(
            tap(n => this.sensorRangeStateSvc.createSensorRange(n)),
            switchMap(n => { 
                if (this.oversightSvc.getUnAuthenticationToken()) {
                    return this.unAuthStateSvc.unAuthCustomer$.pipe(
                        map((cust) => {
                            return { ...n, CustomerId: cust?.CustomerId }
                        }),
                        switchMap(n => this.oversightSvc.USetSensorRange(n))
                    );
                } else {
                    return this.oversightSvc.SetSensorRange(n);
                }
            }),
            handleErrorResponse(),
            tap((n: SetSensorRangeResponse) => {
                const sensorRangeInfo = this.getSensorRangeInfo(request, n.SensorRangeId, n.Description);
                this.sensorRangeStateSvc.createSensorRangeSuccess(sensorRangeInfo);
            },
                e => this.sensorRangeStateSvc.createSensorRangeError(e)
            ),
            map(n => n.SensorRangeId)
        );
    }

    public getSensorRangeInfo(request: SetSensorRangeRequest, sensorRangeId: number, desc?: string): SensorRangeInfo {
        return {
            SensorRangeId: sensorRangeId,
            Name: request.Name,
            Description: desc,
            SensorType: SensorType[request.SensorTypeCode],
            TypeCode: request.SensorTypeCode,
            LowCritical: request.LowCritical,
            LowWarn: request.LowWarn,
            HighWarn: request.HighWarn,
            HighCritical: request.HighCritical,
            LowCriticalMaxTime: null,
            LowWarnMaxTime: request.LowWarnMaxTime,
            HighWarnMaxTime: request.HighWarnMaxTime,
            HighCriticalMaxTime: null
        };
    }
}





