import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share , startWith } from 'rxjs/operators';

import { muteFirst, TripStartOptions } from 'emr-ng-shared';

import { TripStartOptionsStateService } from 'app-modules/core/store/create-shipment/trip-start-options/trip-start-options-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class TripStartOptionsService {
    constructor(
        private tripStartOptionsStateSvc: TripStartOptionsStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.tripStartOptionsStateSvc.isLoadRequired$;

    public isLoading$ = this.tripStartOptionsStateSvc.isLoading$;

    public tripStartOptionsList$: Observable<IListInfo<TripStartOptions>> = muteFirst(
        this.getTripStartOptionsListLoader().pipe(startWith(null)),
        this.tripStartOptionsStateSvc.tripStartOptionsList$
    );

    private getTripStartOptionsListLoader(): Observable<IListInfo<TripStartOptions>> {
        return this.tripStartOptionsStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.tripStartOptionsStateSvc.loadTripStartOptions()),
            switchMap(() => this.getTripStartOptionsList()),
            tap(
                n => this.tripStartOptionsStateSvc.loadTripStartOptionsSuccess(n),
                e => this.tripStartOptionsStateSvc.loadTripStartOptionsError('')
            ),
            finalize(() => this.tripStartOptionsStateSvc.cancelLoadTripStartOptions()),
            catchError(() => {
                this.tripStartOptionsStateSvc.cancelLoadTripStartOptions();
                return of(emptyList());
            }),
            share()
        );
    }

    private getTripStartOptionsList(): Observable<IListInfo<TripStartOptions>> {
        return this.oversightSvc.GetTripStartOptions().pipe(
            map(n => {
                return {
                    list: n.TripStartOptions.sort((a: TripStartOptions, b: TripStartOptions) => {
                        if (a.Description.toLocaleLowerCase() < b.Description.toLocaleLowerCase()) {
                            return -1;
                        }
                    }),
                    itemCount: n.TripStartOptions.length,
                    isPaged: false
                };
            })
        );
    }
}





