import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IErrorInfo, toIErrorInfo } from 'app-modules/core/models/error-info.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { IListInfoWithErrorResponse } from 'app-modules/core/store/models/list-info-with-error-state.interface';
import { DCEditShipmentService } from 'app-modules/dc-edit-shipment/services/dc-edit-shipment.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-shipment-view',
  templateUrl: './shipment-view.component.html',
  styleUrls: ['./shipment-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentViewComponent implements OnInit, OnDestroy {
  serialNumberReq: string;
  
  disableSubmitButton = false;
  selectedShipment: Shipment = null;
  error: IErrorInfo;
  showErrorMessage = false;
  getShipmentSubscription: Subscription;
  @ViewChild(NgForm) updateShipmentReqForm: NgForm;

  defaultMessage = `Unable to find the tracker`;

  constructor(private dcEdShpSvc: DCEditShipmentService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.getShipmentSubscription?.unsubscribe();
  }

  onSerialNumberSubmit() {
    if (!this.updateShipmentReqForm.valid) {
      return;
    }
    this.disableSubmitButton = true;
    this.ResetForm();
    this.getShipmentSubscription = this.dcEdShpSvc.getShipmentDetails(this.serialNumberReq)
      .pipe(take(1))
      .subscribe((n) => {
        if (n && n.ErrorCode === 0) {
          this.selectedShipment = n.list.length > 0 ? n.list[0] : null;
          if (!this.selectedShipment) {
            this.showErrorMessage = true;
            this.error = { message: this.defaultMessage };
          }
        } else {
          this.showErrorMessage = true;
          this.error = toIErrorInfo(n);
        }
        this.disableSubmitButton = false;
      },
        (e) => {
          this.disableSubmitButton = false;
          this.showErrorMessage = true;
          this.error = toIErrorInfo(e);
        });
  }

  ResetForm() {
    this.selectedShipment = null;
    this.showErrorMessage = false;
    this.error = null;
  }
}
