import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { EditShipmentComponent } from 'app-modules/create-shipment/components/edit-shipment/edit-shipment.component';
import { IEditShipmentPopup } from 'app-modules/core/models/edit-shipment-popup.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';

@Injectable()
export class EditShipmentPopup extends IEditShipmentPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenEditShipmentPopup(shipment?: Shipment): void {
        const updateShipmentInitialState = {
            shipment: shipment
          };
        this.bsModalRef = this.modalSvc.show(
            EditShipmentComponent,
            {
                initialState: updateShipmentInitialState,
                class: 'max-width-1300 modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}

