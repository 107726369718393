<app-local-loading-indicator *ngIf="periodSvc.isLoading$ | async" width="90%" height="100%" applyOverlay="true">
</app-local-loading-indicator>
<div class="row mx-1 mt-1">
    <div class="col-12">
        <div class="full-width-float-box">
            <emr-custom-dropdown identifier="timePeriodFilter" name="timePeriodFilter" [options]="periodList"
                [(ngModel)]="filter.timePeriod" i18n-placeholder="@@label_select_time_period"
                placeholder="Select Time Period" [showPlaceHolder]="showPlaceHolder"
                cssClass="default-dropdown {{cssClass}}" inlineCss="px-0" labelCssClass="height-10"
                (ngModelChange)="onSelectedPresetChanged()" optionsValueField="Period" optionsTextField="DisplayName">
            </emr-custom-dropdown>
        </div>
    </div>
    <div *ngIf="+pageFilter === pageFilters.ListViewFilter && !this.IsUnAuthenticated" class="col-12 mb-3">
        <a [routerLink]="['/historical']" class="show_text_decoration" i18n="@@label_switchto_historical">Click
            Historical to view trackers older
            than 90 days</a>
    </div>
    <ng-container *ngIf="filter.timePeriod === 8">
        <div class="col-12 date-time-picker">
            <emr-datetimepicker name="txtDateRangeStartPicker" identifier="txtDateRangeStartPicker"
                [(ngModel)]="filter.dateRange.startDateTime" label="Start Date" i18n-label="@@link_start_date"
                CustomizeGrid="true" datePickerDivCssClass="w-63 mt-1 px-0" [showDateTimeInLine]="true"
                [dateTimeObject]=dateTimeObject timePickerDivCssClass="w-37 mt-1 px-0" labelCssClass="height-10"
                [showPickerOnTop]="true" cssClass="calendar-text" [isDisabled]="filter.timePeriod !== 8"
                (ngModelChange)="onDateChanged()" i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM"
                [minDate]="minDate" [maxDate]="maxDate" placement="right">
            </emr-datetimepicker>
        </div>
        <div class="col-12 date-time-picker">
            <emr-datetimepicker name="txtDateRangEndePicker" identifier="txtDateRangeEndPicker"
                [(ngModel)]="filter.dateRange.endDateTime" label="End Date" i18n-label="@@link_end_date"
                CustomizeGrid="true" labelCssClass="height-10" [showPickerOnTop]="true" cssClass="calendar-text"
                [minDate]="minDate" [maxDate]="maxDate" [isDisabled]="filter.timePeriod !== 8"
                [dateTimeObject]=dateTimeObject [showDateTimeInLine]="true" (ngModelChange)="onDateChanged()"
                i18n-am="@@time_AM" i18n-pm="@@time_PM" am="AM" pm="PM" datePickerDivCssClass="w-63 mt-1 px-0"
                timePickerDivCssClass="w-37 mt-1 px-0" placement="right">
            </emr-datetimepicker>
            <div class="error text-danger" *ngIf="!isValid" id="EmrMinDateValidator" i18n-message="@@greater_end_time">
                End time must be greater than Start time
            </div>
        </div>
        <div class="mb-2" *ngIf="ShowLastNDays">
            <span i18n="@@label_selectdate_message" class="text-danger">Date is restricted to 90 days in the past .
                .
            </span>
        </div>
    </ng-container>
</div>