import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormatLocalizedStringRequest } from 'emr-ng-shared';
import { FormattedStrings } from 'emr-ng-shared';
import { FormatString } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';

@Injectable()
export class LocalizationService {

    constructor(private oversightSvc: OversightApiService) { }

    public pageSizeList$(items: number[]): Observable<IListInfo<FormattedStrings>> {
        const request = new FormatLocalizedStringRequest();
        request.FormatStringType = FormatString.PerPageFormat;
        request.Parameters = items;
        return this.oversightSvc.FormatLocalizedString(request).pipe(
            map(n => {
                return {
                    list: n.Strings,
                    itemCount: n.Strings.length,
                    isPaged: false
                };
            })
        );
    }
}


