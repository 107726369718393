import { Injectable, EventEmitter } from '@angular/core';
import { GetRouteDeviationResponseModel, ILatLong, IMarkerOptions, EmrUtilService } from 'emr-ng-shared';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
export class PolyLineMap {
    Markers: IMarkerOptions[];
    Path: ILatLong[];
    DisplayErrorMessage: boolean;
    Box: any;
    ErrorMessage: string;
}

@Injectable()
export class BingRouteDeviationService {
    bingMap: Microsoft.Maps.Map;
    directionsManager: Microsoft.Maps.Directions.DirectionsManager;
    directionChanged: Microsoft.Maps.IHandlerId;
    isDirectionsDeleted = true;
    bounds: Microsoft.Maps.LocationRect;
    zoomLevel: number;
    OnDirectionsLoad = new EventEmitter();
    constructor(
        private detailStateSvc: ShipmentDetailStateService,
        private utilSvc: EmrUtilService) { }

    // Start Code related to Route deviation
    InstanceOfMap(a: Promise<Microsoft.Maps.Map>) {
        a.then(k => {
            this.bingMap = k;
            Microsoft.Maps.loadModule('Microsoft.Maps.Directions', () => {
                this.directionsManager = new Microsoft.Maps.Directions.DirectionsManager(this.bingMap);
                this.OnDirectionsLoad.emit();
            });
        });
    }

    updatePolyLine(
        routeDeviation: GetRouteDeviationResponseModel,
        mapMarkerOption: (element: any, m: any[]) => void,
        showOnlyDeviations = false, isFirst = false): PolyLineMap {
        const polyMap = new PolyLineMap();
        if (routeDeviation && routeDeviation.TraxxInfoList &&
            routeDeviation.TraxxInfoList.length > 0) {
            polyMap.Box = this.utilSvc.getNewBoxParams();
            polyMap.Markers = new Array<IMarkerOptions>();
            polyMap.Path = new Array<ILatLong>();
            routeDeviation.TraxxInfoList.forEach(k => {
                if (!showOnlyDeviations || k.IsRouteDeviation) {
                    mapMarkerOption(k, polyMap.Markers);
                    polyMap.Path.push({ latitude: k.Latitude, longitude: k.Longitude });
                    setTimeout(() => {
                        if (isFirst) {
                            this.utilSvc.updateBoxParams(polyMap.Box, k.Latitude, k.Longitude);
                        }
                    });
                }
            });
        } else {
            polyMap.DisplayErrorMessage = true;
            polyMap.ErrorMessage = routeDeviation ? routeDeviation.LocalizedErrorMessage : 'Unexpected Error';
        }
        return polyMap;
    }

    directionCheck() {
        this.bingMap.setView({ bounds: this.bounds });
        this.bingMap.setView({ zoom: this.zoomLevel });
        this.detailStateSvc.loadExportSensorReportSuccess();
    }


    updateDirections(isFirst, routeDeviation) {
        this.detailStateSvc.loadExportSensorReport();
        this.bounds = this.bingMap.getBounds();
        this.zoomLevel = this.bingMap.getZoom();
        if (isFirst) {
            this.directionChanged = Microsoft.Maps.Events.addHandler(this.directionsManager,
                'directionsUpdated', this.directionCheck.bind(this));
            this.directionsManager.clearAll();
            this.directionsManager.setRequestOptions({
                routeMode: Microsoft.Maps.Directions.RouteMode.driving, maxRoutes: 1, routeDraggable: false,

            });
            this.directionsManager.setRenderOptions({
                waypointPushpinOptions: { visible: false }, displayRouteSelector: false, displayManeuverIcons: false
            });
            if (routeDeviation && routeDeviation.Route && routeDeviation.Route.Waypoints &&
                routeDeviation.Route.Waypoints.length > 0) {
                routeDeviation.Route.Waypoints.forEach(element => {
                    this.directionsManager.addWaypoint(
                        new Microsoft.Maps.Directions.Waypoint({
                            address: element.Address,
                            location: new Microsoft.Maps.Location(element.Latitude, element.Longitude)
                        })
                    );
                });
                this.directionsManager.calculateDirections();
                this.detailStateSvc.loadExportSensorReportSuccess();
            }
        } else {
            this.directionsManager.calculateDirections();
            this.detailStateSvc.loadExportSensorReportSuccess();
        }
    }

    deleteDirections() {
        this.isDirectionsDeleted = true;
        this.directionsManager.clearDisplay();
        // this.directionsManager.calculateDirections();
    }

    cleanup() {
        Microsoft.Maps.Events.removeHandler(this.directionChanged);
        if (this.directionsManager) {
            this.directionsManager = null;
        }
    }
    // End Code related to Route deviation
}
