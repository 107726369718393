import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, OnDestroy, TemplateRef } from '@angular/core';
import { ImportLocationService } from 'app-modules/location-management/services/import-locations.service';
import { CustomTableColumnDefs, ImportLocation, loadColumnDef, SetLocationRequest } from 'emr-ng-shared';
import { environment } from 'environments/environment';
import { BaseURLPicker } from 'app-modules/core/services/baseurl-picker.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { ImportUpdateConfirmationComponent, ImportUpdateStatusResponseOkEventArgs } from '../import-update-confirmation/import-update-confirmation.component';
import { Subscription } from 'rxjs';
import { destination, label_address, label_color_code, label_delete, label_exceptions, label_location_city, label_location_country, label_location_name, label_location_state, label_location_type, label_location_zip, label_minimum_tracker_inventory, label_record_no, label_shipper_oversight, label_suppressed_alerts, label_trigger_distance, label_update_description, label_update_type_deleted, label_update_type_inserted, label_update_type_updated, label_warnings, location_number, location_ship_to_inventory_tool, Origin, sensor_report_header_latitude, sensor_report_header_longitude, label_will_not_inserted } from 'app-modules/core/consts/localization';

export enum ProcessedViews {
    EXCEPTIONS = 1,
    SUCCESSFUL = 2
}

@Component({
    selector: 'import-location',
    templateUrl: './import-location.component.html',
    styleUrls: ['./import-location.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class ImportLocationComponent implements OnInit, OnDestroy {
    selectedView: ProcessedViews;
    byteArray: any[];
    excelHTMLTable = '';
    public error: string;
    responseData: ImportLocation[];
    isProcessed = false;
    isValidImportData = false;
    importLocations: ImportLocation[];
    locationTemplateURL = '';
    uploadSubscription: Subscription;
    saveSubscription: Subscription;
    columnDefs: CustomTableColumnDefs[]
    hasException = false;
    views: typeof ProcessedViews;
    importExceptions: ImportLocation[];
    importSuccessful: ImportLocation[];
    successColumnDefs: CustomTableColumnDefs[];
    exceptionColumnDefs: CustomTableColumnDefs[];
    totalRecords: number;
    exceptionCount: number;
    @ViewChild('exceptions', { static: true }) exceptions;
    nearestLocations: any;
    @ViewChild('nearestLocationsModal') nearestLocationsModal: TemplateRef<any>;
    modalRef: BsModalRef;
    CurrentLocation: any;
    label_will_not_inserted = label_will_not_inserted;

    constructor(
        private addLocSvc: ImportLocationService,
        private domainPicker: BaseURLPicker,
        private modalSvc: BsModalService) { }

    ngOnInit() {
        this.responseData = [];
        this.error = null;
        this.isProcessed = false;
        this.isValidImportData = false;
        this.importLocations = null;
        this.locationTemplateURL = `${this.domainPicker.resourceDomain}${environment.locationsTemplateURL}`;
        this.columnDefs = this.loadColumnDefinition();
        this.successColumnDefs = this.loadColumnDefinition(ProcessedViews.SUCCESSFUL);
        this.exceptionColumnDefs = this.loadColumnDefinition(ProcessedViews.EXCEPTIONS);
        this.views = ProcessedViews;
    }

    ngOnDestroy() {
        if (this.uploadSubscription) {
            this.uploadSubscription.unsubscribe();
        }
        if (this.saveSubscription) {
            this.saveSubscription.unsubscribe();
        }
    }

    uploadExcelLocations(files: File[]) {
        this.responseData = [];
        this.importLocations = null;
        this.error = null;
        this.isProcessed = false;
        this.isValidImportData = false;
        this.uploadAndProgress(files);
    }

    onSelectExcelLocations(event) {
        event.target.value = '';
    }

    uploadAndProgress(files: File[]) {
        this.getBase64(files[0]).then(
            data => {
                const parts = String(data).split(';base64,');
                // const contentType = parts[0].replace('data:', '');
                const base64 = parts[1];
                this.byteArray = this.base64ToByteArray(base64);
                this.UploadExcel();
            }
        );
    }

    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    base64ToByteArray(base64String) {
        try {
            const byteCharacters = atob(base64String);
            const byteArrays = [];
            for (const ch of byteCharacters) {
                byteArrays.push(ch.charCodeAt(0));
            }
            return byteArrays;
        } catch (e) {
            this.error = e;
            return undefined;
        }
    }

    UploadExcel() {
        if (this.byteArray) {
            this.uploadSubscription = this.addLocSvc.uploadExcelLocations(this.byteArray).subscribe(n => {
                if (n.ErrorCode === 0) {
                    this.importLocations = JSON.parse(n.Info);
                    this.responseData = this.importLocations;
                    const errorRecords = this.importLocations.filter(loc => loc.Exceptions != null);
                    const errorRecordsCount = errorRecords?.length;
                    this.isValidImportData = errorRecordsCount > 0 ? false : true;
                    this.totalRecords = this.importLocations.length;
                    this.exceptionCount = errorRecordsCount;
                    this.showUpdateConfirmation(true);
                    if (errorRecordsCount > 0) {
                        this.importLocations = errorRecords;
                    }
                } else {
                    this.isValidImportData = false;
                    this.error = n.LocalizedErrorMessage;
                }
            });
        }
    }

    showUpdateConfirmation(closeOnOk: boolean) {
        const bsModalRef = this.modalSvc.show(
            ImportUpdateConfirmationComponent,
            {
                initialState: {
                    hasExceptions: !this.isValidImportData,
                    exceptions: this.exceptionCount,
                    totalRecords: this.totalRecords,
                    closeOnOk
                },
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
        bsModalRef.content.ok.pipe(take(1)).subscribe(n => { n.modalRef.hide(); });
    }

    saveImportLocations() {
        this.error = null;
        this.hasException = false;
        if (this.isValidImportData && this.responseData?.length > 0) {
            this.saveSubscription = this.addLocSvc.saveExcelLocations(JSON.stringify(this.responseData)).subscribe(n => {
                if (n.ErrorCode === 0) {
                    this.isProcessed = true;
                    this.isValidImportData = false;
                    this.importLocations = JSON.parse(n.Info);
                    this.responseData = this.importLocations;
                    this.importExceptions = [];
                    this.importSuccessful = [];
                    this.importLocations?.forEach(loc => {
                        if (loc.Exceptions?.length > 0) {
                            this.importExceptions.push(loc);
                        } else {
                            loc.Priority = 99;
                            loc.UpdateDescription = label_update_type_updated;
                            if (this.parseBool(loc.Delete)) {
                                loc.Priority = 1;
                                loc.UpdateDescription = label_update_type_deleted;
                            } else if (!loc.LocationNumber) {
                                loc.Priority = 2;
                                loc.UpdateDescription = label_update_type_inserted;
                            }
                            this.importSuccessful.push(loc);
                        }
                    });
                    this.hasException = (this.importExceptions.length > 0);
                    this.importSuccessful.sort((a, b) => a.Priority - b.Priority);
                    this.SetDefaultProcessedView();
                } else {
                    this.error = n.LocalizedErrorMessage;
                }
            });
        }
    }

    SetDefaultProcessedView() {
        this.selectedView = this.hasException ? ProcessedViews.EXCEPTIONS : ProcessedViews.SUCCESSFUL;
    }

    parseBool(val) {
        if (typeof val === 'string') {
            return val && val.trim().toLowerCase() === "true";
        } else {
            return val;
        }
    }

    clearSelectedImport() {
        this.responseData = [];
        this.error = null;
        this.isProcessed = false;
        this.isValidImportData = false;
        this.importLocations = null;
    }
    loadColumnDefinition(viewType?: ProcessedViews | null) {
        const emptyValue = '';
        const isProcessedView = !!viewType;
        const isExceptionsView = viewType === ProcessedViews.EXCEPTIONS;
        const isSuccessfulView = viewType === ProcessedViews.SUCCESSFUL;
        const columnDefs = [
            // loadColumnDef({
            //     title: '', value: '', isVisible: !this.isHistorical, actionName: 'details', width: '128px',
            //     isSortable: false, isCustomHtml: true,
            //     customHTML: this.detailsButton, columnOrder: 1
            // }),
            loadColumnDef({ title: label_update_description, value: 'UpdateDescription', isVisible: isProcessedView && isSuccessfulView, width: '150px', columnOrder: 0, nullValue: emptyValue }),
            loadColumnDef({ title: label_exceptions, value: 'Exceptions', isVisible: !isProcessedView || isExceptionsView, width: '150px', columnOrder: 0, nullValue: emptyValue, customHTML: this.exceptions, isCustomHtml: true }),
            loadColumnDef({ title: label_warnings, value: 'Warnings', isVisible: !isProcessedView, width: '150px', columnOrder: 1, nullValue: emptyValue }),
            loadColumnDef({
                title: location_number, value: 'LocationNumber', columnOrder: 2, width: '120px', nullValue: emptyValue,
            }),
            loadColumnDef({
                title: label_location_name, value: 'LocationName', width: '140px', columnOrder: 3, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_address, value: 'Address', width: '180px', columnOrder: 4, nullValue: emptyValue
            }),
            loadColumnDef({ title: label_location_city, value: 'City', width: '100px', columnOrder: 5, nullValue: emptyValue }),
            loadColumnDef({ title: label_location_state, value: 'State', width: '100px', columnOrder: 6, nullValue: emptyValue }),
            loadColumnDef({ title: label_location_zip, value: 'Zip', width: '100px', columnOrder: 7, nullValue: emptyValue }),
            loadColumnDef({ title: sensor_report_header_latitude, value: 'Latitude', width: '100px', columnOrder: 8, nullValue: emptyValue }),
            loadColumnDef({
                title: sensor_report_header_longitude, value: 'Longitude', width: '130px', columnOrder: 9, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_location_country, value: 'Country', width: '120px', columnOrder: 10, nullValue: emptyValue
            }),
            loadColumnDef({
                title: Origin, value: 'Origin', width: '120px', columnOrder: 11, nullValue: emptyValue
            }),
            loadColumnDef({
                title: destination, value: 'Destination', width: '120px', columnOrder: 12, nullValue: emptyValue
            }),
            // loadColumnDef({
            //     title: label_trigger_distance, value: 'EndofTripTriggerDistance', width: '140px', columnOrder: 13, nullValue: emptyValue
            // }),
            loadColumnDef({
                title: label_color_code, value: 'LocationColorCode', width: '130px', columnOrder: 14, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_shipper_oversight, value: 'ShippersOversightID', width: '100px', columnOrder: 15, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_location_type, value: 'LocationType', width: '100px', columnOrder: 16, nullValue: emptyValue
            }),
            loadColumnDef({
                title: location_ship_to_inventory_tool, value: 'IsShipTo', width: '140px', columnOrder: 17, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_suppressed_alerts, value: 'SuppressAlerts', width: '120px', columnOrder: 18, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_minimum_tracker_inventory, value: 'MinTrackerInventory', width: '140px', columnOrder: 19, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_delete, value: 'Delete', width: '100px', columnOrder: 20, nullValue: emptyValue
            }),
            loadColumnDef({
                title: label_record_no, value: 'RecordNo', width: '100px', columnOrder: 21, nullValue: emptyValue
            })
        ];

        return columnDefs;
    }

    onNavTabSelected(view: ProcessedViews) {
        this.selectedView = view;
    }

    duplicateLocationCheck(data: any) {
        const request: any = { Latitude: data.Latitude, Longitude: data.Longitude };
        this.addLocSvc.getNearestLocation(request).pipe(take(1)).subscribe(k => {
            const locationList = k.LocationList?.filter(k => k.LocationId != data.LocationNumber);
            if (locationList?.length > 0) {
                this.nearestLocations = locationList;
                this.CurrentLocation = data;
                this.CurrentLocation.ZipCode = data.Zip;
                this.CurrentLocation.Address1 = data.Address;
                this.CurrentLocation.Name = data.LocationName;
                this.openNearestLocationModal();
            }

        })
    }

    openNearestLocationModal() {
        this.modalRef = this.modalSvc.show(
            this.nearestLocationsModal,
            {
                class: 'modal-lg modal-dialog-centered',
                ignoreBackdropClick: true,
                keyboard: false
            },
        );
    }

    onNearestLocationSelect(IsSkip: boolean) {
        this.CurrentLocation.Skip = !IsSkip;
        this.CurrentLocation.IsDuplicateLocation = !IsSkip;
        this.CurrentLocation.Exceptions = null;
        this.CurrentLocation.Message = label_will_not_inserted;
        const errorRecordsCount = this.importLocations.filter(loc => loc.Exceptions != null).length;
        this.isValidImportData = errorRecordsCount > 0 ? false : true;
        this.modalRef.hide();
    }
}



