import { Injectable } from '@angular/core';
import { TimeZoneService } from '../store/time-zone/time-zone.service';
import { Subscription, Observable } from 'rxjs';
import { CoolDownTypeService } from '../store/cool-down-type/cool-down-type.service';
import { ShipmentTemplateService } from '../store/shipment-template/shipment-template.service';
import { LocationService } from './location.service';
import { CoolDownTypeStateService } from '../store/cool-down-type/cool-down-type-state.service';
import { CreateShipmentService } from './create-shipment.service';
import { VehicleGroupsService } from '../store/vehicle-group/vehicle-groups.service';
import { BusinessRulesService } from '../store/business-rules/business-rules.service';
import { LinkedCustomersService } from '../store/create-shipment/linked-customers/linked-customer.service';
import { FormattedStringService } from 'app-modules/core/store/formatted-strings/formatted-strings.service';
import { TripStartOptionsService } from 'app-modules/core/store/create-shipment/trip-start-options/trip-start-options.service';
import { PreferenceService } from './preference.service';
import { TripEndOptionsService } from '../store/create-shipment/trip-end-options/trip-end-options.service';

@Injectable()
export class InitializationService {

    private timeZoneSubscription: Subscription;
    private coolDownSubscription: Subscription;
    private shipmentTemplateSubscription: Subscription;
    private locationSubscription: Subscription;
    private vehicleGroupListSubscription: Subscription;
    private businessRulesListSubscription: Subscription;
    private linkedCustomerListSubscription: Subscription;
    private tripStartOptionsSubscription: Subscription;
    private tripEndOptionsSubscription: Subscription;
    private dateFormatListSubscription: Subscription;
    private userPreferenceSubscription: Subscription;
    private locationColorSubscription: Subscription;

    constructor(
        public timeZoneSvc: TimeZoneService,
        public coolDownSvc: CoolDownTypeService,
        public coolDownStateSvc: CoolDownTypeStateService,
        public shipmentTemplateSvc: ShipmentTemplateService,
        public locationSvc: LocationService,
        public createShipmentSvc: CreateShipmentService,
        public vehicleGroupSvc: VehicleGroupsService,
        public businessruleSvc: BusinessRulesService,
        public linkedCustomerSvc: LinkedCustomersService,
        public tripStartOptionsSvc: TripStartOptionsService,
        public tripEndOptionsSvc: TripEndOptionsService,
        private formatStringSvc: FormattedStringService,
        private preferenceSvc: PreferenceService,) {
    }

    InitAppLoad() {
        this.timeZoneSubscription = this.timeZoneSvc.timeZoneList$.subscribe(n => {
            if (n) {
                if (this.timeZoneSubscription) {
                    this.timeZoneSubscription.unsubscribe();
                }
            }
        });
        this.dateFormatListSubscription = this.formatStringSvc.DateFormattedStringList$.pipe().subscribe(n => {
            if (n) {
                if (this.dateFormatListSubscription) {
                    this.dateFormatListSubscription.unsubscribe();
                }
            }
        });
        this.tripStartOptionsSubscription = this.tripStartOptionsSvc.tripStartOptionsList$.subscribe(n => {
            if (n) {
                if (this.tripStartOptionsSubscription) {
                    this.tripStartOptionsSubscription.unsubscribe();
                }
            }
        });
        this.tripEndOptionsSubscription = this.tripEndOptionsSvc.TripEndOptionsList$.subscribe(n => {
            if (n) {
                if (this.tripEndOptionsSubscription) {
                    this.tripEndOptionsSubscription.unsubscribe();
                }
            }
        });
        
    }

    InitAuthLoad() {
        this.coolDownSubscription = this.coolDownSvc.coolDownTypeList$.subscribe(n => {
            if (n) {
                if (this.coolDownSubscription) {
                    this.coolDownSubscription.unsubscribe();
                }
            }
        }); 
         
        this.locationColorSubscription = this.locationSvc.locationColorList$.subscribe(n => {
            if (n) {
                if (this.locationColorSubscription) {
                    this.locationColorSubscription.unsubscribe();
                }
            }
        });
        
    }

    InitCustomerLoad() {

        this.shipmentTemplateSubscription = this.createShipmentSvc.getShipmentTemplateListLoader().subscribe(n => {
            if (n) {
                if (this.shipmentTemplateSubscription) {
                    this.shipmentTemplateSubscription.unsubscribe();
                }
            }
        });

        this.locationSubscription = this.locationSvc.locationListIncludingCopy$.subscribe(n => {
            if (n) {
                if (this.locationSubscription) {
                    this.locationSubscription.unsubscribe();
                }
            }
        });

        this.vehicleGroupListSubscription = this.vehicleGroupSvc.getVehicleGroupList().subscribe(n => {
            if (n) {
                if (this.vehicleGroupListSubscription) {
                    this.vehicleGroupListSubscription.unsubscribe();
                }
            }
        });

        this.businessRulesListSubscription = this.businessruleSvc.businessRulesList$.subscribe(n => {
            if (n) {
                if (this.businessRulesListSubscription) {
                    this.businessRulesListSubscription.unsubscribe();
                }
            }
        });

        this.linkedCustomerListSubscription = this.linkedCustomerSvc.linkedCustomerList$.subscribe(n => {
            if (n) {
                if (this.linkedCustomerListSubscription) {
                    this.linkedCustomerListSubscription.unsubscribe();
                }
            }
        });

        this.userPreferenceSubscription = this.preferenceSvc.preference$.subscribe(n => {
            if (n && !n.isLoadRequired && !n.isLoading) {
                if (this.userPreferenceSubscription) {
                    this.userPreferenceSubscription.unsubscribe();
                }
            }
        });
        this.createShipmentSvc.initLoadSensors();
    }
}