import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { DashboardInfo, SetDashboardRequest } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IDashboardState } from '../models/dashboard-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as dashboardActions from './dashboard.actions';

@Injectable()
export class DashboardStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public dashboardStateSelector = ((state: IAppState) => state.dashboardList);
    public isLoadRequiredSelector = createSelector(
        this.dashboardStateSelector,
        (state: IDashboardState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.dashboardStateSelector,
        (state: IDashboardState) => state.isLoading
    );
    public dashboardListSelector = createSelector(
        this.dashboardStateSelector,
        (state: IDashboardState) => state.dashboardList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public dashboardList$ = this.store.select(this.dashboardListSelector).pipe();

    // Actions
    public loadDashboards() {
        this.store.dispatch(new dashboardActions.LoadDashboards());
    }

    public loadDashboardsSuccess(dashboardList: IListInfo<DashboardInfo>) {
        this.store.dispatch(new dashboardActions.LoadDashboardsSuccess(dashboardList));
    }

    public loadDashboardsError(message: string) {
        this.store.dispatch(new dashboardActions.LoadDashboardsError(message));
    }

    public cancelLoadDashboards() {
        this.store.dispatch(new dashboardActions.CancelLoadDashboards());
    }

    // setDashboard
    public setDashboard(request: SetDashboardRequest) {
        this.store.dispatch(new dashboardActions.SetDashboard(request));
    }

    public setDashboardSuccess(dashboard: DashboardInfo) {
        this.store.dispatch(new dashboardActions.SetDashboardSuccess(dashboard));
    }

    public setDashboardError(message: string) {
        this.store.dispatch(new dashboardActions.SetDashboardError(message));
    }

    public cancelSetDashboard() {
        this.store.dispatch(new dashboardActions.CancelSetDashboard());
    }

    // Delete Dashboard
    public deleteDashboard(dashboardID: number) {
        this.store.dispatch(new dashboardActions.DeleteDashboard(dashboardID));
    }

    public deleteDashboardSuccess(dashboardID: number) {
        this.store.dispatch(new dashboardActions.DeleteDashboardSuccess(dashboardID));
    }

    public deleteDashboardError(message: string) {
        this.store.dispatch(new dashboardActions.DeleteDashboardError(message));
    }

    public cancelDeleteDashboard() {
        this.store.dispatch(new dashboardActions.CancelDeleteDashboard());
    }
}


