import { TrackerStatechartMarker } from './tracker-state-chart-marker.model';
import { LabelValueModel } from 'emr-ng-shared';

export class TrackerStatechartAlertData {
    x: number;
    y: number;
    marker: TrackerStatechartMarker;
    Latitude: number;
    Longitude: number;
    SatTimeString: string;
    formattedString: string;
    name: string;
    labels: LabelValueModel[];
}
