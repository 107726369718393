import { Component, OnInit } from '@angular/core';
import { ScheduledReportParams } from 'app-modules/core/models/scheduled-report-params.model';
import { ScheduleReportService } from 'app-modules/core/store/schedule-report/schedule-report.service';
import { ReportType, ScheduleReportRequest } from 'emr-ng-shared';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-schedule-new-report',
  templateUrl: './schedule-new-report.component.html',
  styleUrls: ['./schedule-new-report.component.css']
})
export class ScheduleNewReportComponent implements OnInit {
  showFormatButton = true;
  headerTitle: string;
  public request: ScheduleReportRequest;
  reportParams: ScheduledReportParams;
  editParams = null;
  selectedEmails = null;
  public isFormSubmitted = null;
  public isNewReport = false;
  public isEmailReport = false;

  selectedReport: ReportType;
  public reportType = ReportType;

  constructor(
    public bsModalRef: BsModalRef,
    public reportSvc: ScheduleReportService,
  ) { }

  ngOnInit() {
    if (this.selectedReport) {
      if (this.isNewReport) {
        this.request = this.reportSvc.getScheduleReportRequestDefaultParams(this.selectedReport);
      } else {
        this.editParams = this.request?.ReportParams;
        this.reportParams = new ScheduledReportParams();
        this.reportParams.ReportParams = this.editParams;
        this.reportParams.EmailSubject = this.request.EmailSubject;
        this.reportParams.IsValid = true;
        this.request.ReportCategory = this.reportSvc.getReportCategory(this.selectedReport);
      }
      this.showFormatButton = !(this.selectedReport === ReportType.ComprehensiveTripReport ||
        this.selectedReport === ReportType.InboundOutboundReport ||
        this.selectedReport === ReportType.TripStopSummaryExtendedReport || 
        this.selectedReport === ReportType.FleetCurrentStatus);
    }
  }

  onReportSubmit(isValid: boolean) {
    this.isFormSubmitted = { validate: isValid };
  }

}
