import { Component, OnInit, Input, Output, EventEmitter, Optional, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertSuppressionService } from 'app-modules/core/services/alert-suppression.service';
import { GetAlertSuspendStatusRequest, LocationObject, DateTimeObject } from 'emr-ng-shared';
import { GetAlertSuspendStatusResponse, EmrUtilService } from 'emr-ng-shared';
import { SuspendAlertsRequest } from 'emr-ng-shared';
import { isNullOrUndefined } from 'util';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { ShipmentService } from 'app-modules/core/services/shipment.service';

export class LocationInformation {
  checked: boolean;
  get text() {
    return this.locationObject.Name;
  }
  get value() {
    return this.locationObject.Id;
  }
  constructor(
    private locationObject: LocationObject) { }
}

@Component({
  selector: 'app-alert-suppression',
  templateUrl: './alert-suppression.component.html',
  styleUrls: ['./alert-suppression.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AlertSuppressionComponent implements OnInit, OnDestroy {

  resumeAlertLocation = false;
  resumeDate: Date;
  checkBoxExpiration = false;
  checkBoxEnterLocation: boolean;
  checkBoxSuspendAlerts: boolean;
  selectedLocations: number[] = [];
  isLocationsCollapsed = true;
  locationList$: Observable<LocationInformation[]>;
  alertSuspendStatus$: Observable<GetAlertSuspendStatusResponse>;
  alertSuspendStatusDetails: GetAlertSuspendStatusResponse;
  locations: LocationInformation[];
  @Input() alertSuspendStatusRequest: GetAlertSuspendStatusRequest;
  @Output() CloseClick: EventEmitter<void> = new EventEmitter();
  userPreferenceSubscription: Subscription;
  dateTimeObject = new DateTimeObject();
  isSuspendAlerts = false;
  searchAlertLocations: string;
  today: Date;
  public error: string = null;

  constructor(
    private alertSuppressionSvc: AlertSuppressionService,
    @Optional() public bsMainModalRef: BsModalRef,
    private utilSvc: EmrUtilService,
    private preferenceSvc: PreferenceService,
    private shpmntSvc: ShipmentService) { }

  ngOnInit() {
    this.locationList$ = this.alertSuppressionSvc.GetAlertSuspendStatus(this.alertSuspendStatusRequest).pipe(
      map(details => {
        this.isSuspendAlerts = details.Data.HasSuspendedAlerts;
        if (details.Data && isNullOrUndefined(details.Data.EndTime)) {
          this.resumeDate = null;
          this.checkBoxExpiration = false;
        } else {
          this.resumeDate = new Date(details.Data.EndTime);
          this.checkBoxExpiration = true;
        }
        if (details.Data.IsSuppressedForever) {
          this.checkBoxSuspendAlerts = true;
          this.onSuspendAlertsChange();
        }
        this.locations = details.Data.Locations && details.Data.Locations.sort(locationTemp => locationTemp.IsSuppressed ? -1 : 1)
          && details.Data.Locations.map((item: LocationObject) => new LocationInformation(item));
        return this.locations;
      }),
      tap(details => {
        details.filter((locationTemp: any) => locationTemp.locationObject.IsSuppressed).forEach((location: any) => {
          this.selectedLocations.push(+location.locationObject.Id);
        });

        if (this.selectedLocations && this.selectedLocations.length > 0) {
          this.checkBoxEnterLocation = true;
        } else {
          this.checkBoxEnterLocation = false;
        }
      })
    );
    this.userPreferenceSubscription = this.preferenceSvc.getDateTimeObject().subscribe(k => {
      this.dateTimeObject = k;
    });
    this.today = new Date();
  }

  ngOnDestroy() {
    if (this.userPreferenceSubscription) {
      this.userPreferenceSubscription.unsubscribe();
    }
  }

  onSuspendAlertClick() {
    this.error = null;
    const suspendAlertRequest = new SuspendAlertsRequest();
    suspendAlertRequest.DeviceId = this.alertSuspendStatusRequest.DeviceId;
    suspendAlertRequest.SuspendForever = false;
    if (this.checkBoxExpiration) {
      suspendAlertRequest.SuspendUntil =
        this.utilSvc.DateFormatLocaleChange(this.resumeDate);
    } else {
      suspendAlertRequest.SuspendUntil = null;
    }

    if (this.checkBoxEnterLocation) {
      if (this.selectedLocations && this.selectedLocations.length > 0) {
        suspendAlertRequest.SuspendLocations = this.selectedLocations;
      } else {
        suspendAlertRequest.SuspendLocations = null;
      }
    }
    if (this.checkBoxSuspendAlerts) {
      suspendAlertRequest.SuspendForever = true;
    }

    const suspendAlertsSub = this.alertSuppressionSvc.SuspendAlerts(suspendAlertRequest).subscribe(n => {
      if (n) {
        this.CloseClick.emit();
        const isSuppressed = this.checkBoxSuspendAlerts ||
                            this.checkBoxEnterLocation ||
                            this.checkBoxExpiration;
        this.shpmntSvc.updateShipmentSuspendResumeState(
            this.alertSuspendStatusRequest.GlobalDeviceId,
            this.alertSuspendStatusRequest.TripId, isSuppressed);
        suspendAlertsSub.unsubscribe();
      }
    },
      e => {
        this.error = e.message;
        suspendAlertsSub.unsubscribe();
      });
  }

  onResumeAlertClick() {
    this.error = null;
    const suspendAlertRequest = new SuspendAlertsRequest();
    suspendAlertRequest.DeviceId = this.alertSuspendStatusRequest.DeviceId;
    suspendAlertRequest.SuspendUntil = null;

    suspendAlertRequest.SuspendLocations = [];
    suspendAlertRequest.SuspendForever = false;

    const suspendAlertsSub = this.alertSuppressionSvc.SuspendAlerts(suspendAlertRequest).subscribe(n => {
      if (n) {
        this.CloseClick.emit();
        this.shpmntSvc.updateShipmentSuspendResumeState(
          this.alertSuspendStatusRequest.GlobalDeviceId,
          this.alertSuspendStatusRequest.TripId, false);
        suspendAlertsSub.unsubscribe();
      }
    },
      e => {
        this.error = e.message;
        suspendAlertsSub.unsubscribe();
      });
  }


  onCancelClick() {
    this.CloseClick.emit();
  }

  onCloseClick() {
    this.CloseClick.emit();
  }


  onSuspendAlertsChange() {
    if (this.checkBoxSuspendAlerts) {
      this.checkBoxEnterLocation = false;
      this.checkBoxExpiration = null;
      this.selectedLocations = [];
      this.resumeDate = null;
      this.error = null;
    }
  }

  onLocationChange() {
    this.selectedLocations = this.locations.filter(k => k.checked).map(k => k.value);
    this.error = null;
  }
}


