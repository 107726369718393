import * as actionIndex from '../actions/index';
import { ServerRequestAction, ServerRequestCompleteAction } from './custom-actions';

// Tracker Sensors

export class SetTrackerSensorRange implements ServerRequestAction {
    readonly type = actionIndex.SET_TRACKER_SENSOR_RANGE;
}

export class SetTrackerSensorRangeSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_TRACKER_SENSOR_RANGE_SUCCESS;
    readonly callingAction = actionIndex.SET_TRACKER_SENSOR_RANGE;
}

export class SetTrackerSensorRangeError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_TRACKER_SENSOR_RANGE_ERROR;
    readonly callingAction = actionIndex.SET_TRACKER_SENSOR_RANGE;
}

export class CancelSetTrackerSensorRange implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SET_TRACKER_SENSOR_RANGE;
    readonly callingAction = actionIndex.SET_TRACKER_SENSOR_RANGE;
}
