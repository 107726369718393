<div class="row">
    <div class="col-md-12">
        <div class="card alertbox roboto-regular-font font-12">
            <div class="row card-header p-1">
                <!-- <a style="float:left; width:210px;">
                    <img title="shipment?.Description" [src]="shipment?.ImageUrl" height=" 150px " />
                </a> -->
                <div class="col-md-12 center-align">
                    <div class="font-14 lh-24">
                        <b>
                            <i *ngIf="goBack"
                                class="fa fa-long-arrow-left custom-btn-info-click click-infobox-back-to-list">&nbsp;&nbsp;&nbsp;</i>
                            <span>{{ shipment?.trackerId }}</span>
                        </b>
                        <button translate="no" type="button"
                            class="notranslate float-right close btn-modal custom-btn-info-click click-infobox-close infobox-close-img"
                            aria-label="Close">
                            <span aria-hidden="true"
                                class="custom-btn-info-click click-infobox-close infobox-close-img">
                                <b class="custom-btn-info-click click-infobox-close infobox-close-img">&times;</b>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-1 pl-2 text-left">
                <div class="row ">
                    <div class="col-md-12 pt-1 pb-1" *ngIf="shipment?.tripName">
                        <b>
                            <!-- <span i18n="@@shipment_name">Shipment Name</span>:&ensp; -->
                            <!-- <span *ngIf="!shipment?.tripName" i18n="@@no_shipment_defined">No Shipment Defined </span> -->
                            <span *ngIf="shipment?.tripName">{{ shipment?.tripName }}</span>
                        </b>
                    </div>
                    <div class="col-md-12  pb-1">
                        <span> {{ shipment?.LastReportedTimestampFormatted}}</span>
                    </div>
                    <div *ngIf="!shipment?.AddressGeoLoading; else addressLoader" class="col-md-12 pb-1">
                        <span
                            *ngIf="shipment?.AddressGeoTimestampFormatted != '' && !shipment?.AddressOlderThanThreshold; else viewCurrentLocation">
                            <span>{{shipment?.AddressGeo}}</span>
                        </span>
                        <ng-template #viewCurrentLocation>
                            <button translate="no" type="button"
                                class="notranslate btn btn-link custom-btn-info-click p-0" style="font-size: 8pt;"
                                i18n="@@view_current_location"
                                *ngIf="CheckDatesInPast90Days(shipment?.LastReportedTimestamp,shipment?.ActualEndTime,shipment?.tripStateCode)">
                                View Current Location
                            </button>
                        </ng-template>
                    </div>
                    <ng-template #addressLoader>
                        <div class="col-md-12">
                            <app-local-loading-indicator width="50%" height="28px">
                            </app-local-loading-indicator>
                        </div>
                    </ng-template>
                    <div class="col-md-12 pb-1" *ngIf="shipment?.ProbeTemperatureString">
                        <span i18n="@@label_probetemp">Probe Temp</span>:&ensp;
                        <span> {{ shipment?.ProbeTemperatureString}}</span>
                    </div>
                    <div class="col-md-12 pb-1">
                        <span i18n="@@label_temperature">Temperature</span>:&ensp;
                        <span> {{ shipment?.TemperatureString}}</span>
                    </div>
                    <div class="col-md-12 pb-1" *ngIf="shipment?.HumidityLevelString">
                        <span i18n="@@label_humidity">Humidity</span>:&ensp;
                        <span> {{ shipment?.HumidityLevelString}}</span>
                    </div>

                    <div class="col-md-12 pb-1" *ngIf="shipment?.CarbonDioxideLevelString"  >
                        <span i18n="@@label_carbondioxide">CO2</span>:&ensp;
                        <span> {{ shipment?.CarbonDioxideLevelString}}</span>
                    </div>
                </div>
                <div class="col-md-12">
                    <button translate="no" type="button" *ngIf="isZoomInfoLevel && showInfoZoomOption"
                        class="notranslate float-right close btn-modal btnZoomInfoLevel custom-btn-info-click click-infobox-zoom-in"
                        aria-label="Close">
                        <i class="fa fa-search-plus custom-btn-info-click click-infobox-zoom-in" aria-hidden="true"></i>
                    </button>
                    <button translate="no" type="button" *ngIf="!isZoomInfoLevel"
                        class="notranslate float-right close btn-modal btnZoomInfoLevel custom-btn-info-click click-infobox-zoom-out"
                        aria-label="Close">
                        <i class="fa fa-search-minus custom-btn-info-click click-infobox-zoom-out"
                            aria-hidden="true"></i>
                    </button>
                </div>
            </div>
            <div class="card-footer p-1"
                *ngIf="shpSvc.CheckDatesInPast90DaysForDetails(shipment?.LastReportedTimestamp,shipment?.ActualEndTime,shipment?.ActualStartTime,shipment?.tripStateCode)">
                <emr-button identifier="mapShowRoute"
                    cssClass="notranslate btn-sm btn-style label-text text-12 btn-background custom-btn-info-click click-show-route"
                    buttonText="Show Route" i18n-buttonText="@@show_route">
                </emr-button>
                <emr-button identifier="mapshowDetails"
                    cssClass="notranslate btn-sm btn-style label-text text-12 btn-background custom-btn-info-click click-show-details"
                    buttonText="Details" i18n-buttonText="@@details">
                </emr-button>
                <emr-button identifier="mapShare"
                    cssClass="notranslate btn-sm btn-style label-text text-12 btn-background custom-btn-info-click click-show-share"
                    buttonText="Share Location" i18n-buttonText="@@label_share_location">
                </emr-button>
            </div>
        </div>

    </div>
</div>