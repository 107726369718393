import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, switchMap, filter, tap, finalize, catchError, share, withLatestFrom, take, startWith } from 'rxjs/operators';

import { muteFirst, DateTimeObject, EmrUtilService } from 'emr-ng-shared';

import { PreferenceStateService } from 'app-modules/core/store/services/preference-state.service';
import { IPreferenceState } from 'app-modules/core/store/models/preference-state.interface';

import { environment } from 'environments/environment';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { initialState } from 'app-modules/core/store/reducers/initial-state';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';

@Injectable()
export class PreferenceService {
    constructor(
        private prefStateSvc: PreferenceStateService,
        private oversightSvc: OversightApiService,
        private unAuthSvc: UnAuthService,
        private utilSvc: EmrUtilService,
    ) { }

    public prefState$ = this.prefStateSvc.prefState$;
    public tempUnits$ = this.prefStateSvc.tempUnits$;
    public distUnits$ = this.prefStateSvc.distUnits$;

    public isPreferenceLoading$ = this.prefStateSvc.isPreferenceLoading$;
    public isPreferenceLoadRequired$ = this.prefStateSvc.isPreferenceLoadRequired$;
    public preferences;

    public preference$: Observable<IPreferenceState> = muteFirst(
        this.getPreferenceLoader().pipe(startWith(null)),
        this.prefStateSvc.prefState$
    );

    private getPreferenceLoader(): Observable<IPreferenceState> {
        return this.prefStateSvc.isPreferenceLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.prefStateSvc.loadPreference()),
            switchMap(() => this.getPreference()),
            tap(
                n => {
                    this.preferences = n;
                    this.prefStateSvc.loadPreferenceSuccess(n);
                },
                e => this.prefStateSvc.loadPreferenceError(e)
            ),
            finalize(() => this.prefStateSvc.cancelLoadPreference()),
            catchError(() => of({} as IPreferenceState)),
            share()
        );
    }

    private getPreference(): Observable<IPreferenceState> {
        const localUnAuthSettings = JSON.parse(localStorage.getItem(this.unAuthSvc.getUnAuthenticationToken() ? environment.unAuthUserSettings : environment.AuthUserSettings));
        if (localUnAuthSettings) {
            return of({
                temperatureUnits: localUnAuthSettings.uTemperatureUnits,
                temperatureUnitString: localUnAuthSettings.uTemperatureUnitString,
                distanceUnits: localUnAuthSettings.uDistanceUnits,
                dateFormatEnum: localUnAuthSettings.uDateFormatEnum,
                dateFormatString: localUnAuthSettings.uDateFormatString,
                timeFormatEnum: localUnAuthSettings.uTimeFormatEnum,
                timeFormatString: localUnAuthSettings.uTimeFormatString,
                timeZoneId: localUnAuthSettings.uTimeZoneID
            } as IPreferenceState);
        } else if (!this.unAuthSvc.getUnAuthenticationToken()) {
            return this.oversightSvc.GetUserPreferences().pipe(
                map(n => {
                    return {
                        temperatureUnits: n.TemperatureUnits,
                        temperatureUnitString: n.TemperatureUnitString,
                        distanceUnits: n.DistanceUnits,
                        dateFormatEnum: n.DateFormatEnum,
                        dateFormatString: n.DateFormatString,
                        timeFormatEnum: n.TimeFormatEnum,
                        timeFormatString: n.TimeFormatString,
                        timeZoneId: n.TimeZoneId
                    } as IPreferenceState;
                })
            );
        } else {
            return of(initialState.preference);
        }
    }

    public getDateTimeObject(): Observable<DateTimeObject> {
        return this.preference$.pipe(map(k => {
            const data = new DateTimeObject();
            data.dateFormat = k.dateFormatString.toUpperCase();
            data.dateFormatString = k.dateFormatString;
            data.timeFormat = k.timeFormatString;
            if (Number(k.timeFormatEnum) === 1 ||
                !data.timeFormat) {
                data.timeFormat = 'hh:mm a';
            }
            data.showMeridian = Number(k.timeFormatEnum) !== 2;
            data.dateTimeFormat = data.dateFormat + ', ' + data.timeFormat;
            return data;
        }));
    }

    public getUserFormattedDate(value: any, appendComma = true): any {
        if (!this.preferences &&
            this.unAuthSvc.getUnAuthenticationToken()) {
            this.preferences = JSON.parse(localStorage.getItem(environment.unAuthUserSettings));
        }
        if (parseInt(this.preferences.timeFormatEnum) === 1 ||
            !this.preferences.timeFormatString) {
            this.preferences.timeFormatString = 'hh:mm a';
        }
        const userFormattedDate =
            this.utilSvc.DateFormatLocaleChange(value, this.preferences.dateFormatString + (appendComma ? ', ' : ' ') + this.preferences.timeFormatString);
        return userFormattedDate;
    }

    updatePreference(uSettings: any) {
        // if (this.unAuthSvc.getUnAuthenticationToken()) {
        const preference = {
            temperatureUnits: uSettings.uTemperatureUnits,
            temperatureUnitString: uSettings.uTemperatureUnitString,
            distanceUnits: uSettings.uDistanceUnits,
            dateFormatEnum: uSettings.uDateFormatEnum,
            dateFormatString: uSettings.uDateFormatString,
            timeFormatEnum: uSettings.uTimeFormatEnum,
            timeFormatString: uSettings.uTimeFormatString,
        } as IPreferenceState;
        this.prefStateSvc.loadPreferenceSuccess(preference);
        // }
    }
}
