import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith } from 'rxjs/operators';

import { muteFirst } from 'emr-ng-shared';

import { TimeZoneInfo } from 'emr-ng-shared';
import { GetLocalizedTimeZonesResponse } from 'emr-ng-shared';

import { TimeZoneStateService } from './time-zone-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class TimeZoneService {
    constructor(
        private timeZoneStateSvc: TimeZoneStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.timeZoneStateSvc.isLoadRequired$;

    public isLoading$ = this.timeZoneStateSvc.isLoading$;

    public timeZoneList$: Observable<IListInfo<TimeZoneInfo>> = muteFirst(
        this.getTimeZoneListLoader().pipe(startWith(null)),
        this.timeZoneStateSvc.timeZoneList$
    );

    public getTimeZoneListLoader(): Observable<IListInfo<TimeZoneInfo>> {
        return this.timeZoneStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.timeZoneStateSvc.loadTimeZones()),
            switchMap(() => this.getTimeZoneList()),
            tap(
                n => this.timeZoneStateSvc.loadTimeZonesSuccess(n),
                e => this.timeZoneStateSvc.loadTimeZonesError('')
            ),
            finalize(() => this.timeZoneStateSvc.cancelLoadTimeZones()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getTimeZoneList(): Observable<IListInfo<TimeZoneInfo>> {

        return this.oversightSvc.GetTimeZoneList().pipe(
            map(n => {
                return {
                    list: n.TimeZoneList.sort((a: TimeZoneInfo, b: TimeZoneInfo) => {
                        if (a.FullName.toLocaleLowerCase() < b.FullName.toLocaleLowerCase()) {
                            return -1;
                        }
                    }),
                    itemCount: n.TimeZoneList.length,
                    isPaged: false
                };
            })
        );
    }
}





