import { ICreateShipmentCarrierState } from 'app-modules/core/store/models/create-shipment-carrier-state.interface';
import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from '../../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as createActions from 'app-modules/core/store/create-shipment/create-shipment.actions';
import * as carrierActions from 'app-modules/core/store/create-shipment/carrier/create-shipment-carrier.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    carrierActions.LoadCarrierListSuccess |
    carrierActions.LoadCarrierListError |
    createActions.SelectLinkedCustomer |
    carrierActions.AddorUpdateCarrierSuccess |
    carrierActions.RemoveCarrierSuccess |
    custActions.SelectCustomerSuccess;

export function CreateShipmentCarrierReducer(
    state: ICreateShipmentCarrierState = initialState.createShipmentCarrier,
    action: Actions
): ICreateShipmentCarrierState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.createShipmentCarrier;

        // case actionIndex.CREATE_SHIPMENT_SELECT_LINKED_CUSTOMER:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                carrierList: [],
                isLoadRequired: true
            };
        case actionIndex.CREATE_SHIPMENT_LOAD_CARRIER_SUCCESS:
            return {
                ...state,
                carrierList: action.carrierList,
                isLoadRequired: false,
                errorMessage: ''
            };
        case actionIndex.CREATE_SHIPMENT_ADDORUPDATE_CARRIER_SUCCESS:
            const carrierList = state.carrierList;
            const carrierIndex = carrierList.findIndex(k => k.Id === action.carrierlookUpId);
            if (carrierIndex > -1) {
                carrierList[carrierIndex] = { ...carrierList[carrierIndex], Value: action.carrier.Value }

            } else {
                carrierList.push({ ...action.carrier, Id: action.carrierlookUpId });
            }
            return {
                ...state,
                carrierList: carrierList,
                isLoadRequired: false,
                errorMessage: ''
            };

        case actionIndex.CREATE_SHIPMENT_REMOVE_CARRIER_SUCCESS:
            const currentList = state.carrierList;
            const updatedCarrierList = currentList.filter(k => k.Id !== action.carrierlookUpId);

            return {
                ...state,
                carrierList: updatedCarrierList,
                isLoadRequired: false,
                errorMessage: ''
            };
        case actionIndex.CREATE_SHIPMENT_LOAD_CARRIER_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: <string>action.message
            };
        default:
            return state;
    }
}
