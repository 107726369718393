import { GetTripTemplateDetailsResponse } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadTemplateDetails implements ServerRequestAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS;
}

export class LoadTemplateDetailsSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_SUCCESS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS;

    constructor(public shipmentTemplateDetails: GetTripTemplateDetailsResponse) { }
}

export class LoadTemplateDetailsError implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_ERROR;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS;

    constructor(public message: string) { }
}

export class CancelLoadTemplateDetails implements ServerRequestCompleteAction {
    readonly type = actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_TEMPLATE_DETAILS;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS;
}

