import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { SortOptions } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IShipmentSortState } from 'app-modules/core/store/models/shipment-sort-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as sortActions from 'app-modules/core/store/actions/shipment-sort.actions';

@Injectable()
export class ShipmentSortStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public shipmentSortStateSelector = ((state: IAppState) => state.shipmentSort);
    public isLoadRequiredSelector = createSelector(
        this.shipmentSortStateSelector,
        (state: IShipmentSortState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.shipmentSortStateSelector,
        (state: IShipmentSortState) => state.isLoading
    );
    public sortListSelector = createSelector(
        this.shipmentSortStateSelector,
        (state: IShipmentSortState) => state.sortList
    );
    public selectedSortSelector = createSelector(
        this.shipmentSortStateSelector,
        (state: IShipmentSortState) => state.selectedSort
    );
    public isDescendingSelector = createSelector(
        this.shipmentSortStateSelector,
        (state: IShipmentSortState) => state.isDescending
    );

    // Observables
    public sortState$ = this.store.select(this.shipmentSortStateSelector).pipe();
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public sortList$ = this.store.select(this.sortListSelector).pipe();
    public selectedSort$ = this.store.select(this.selectedSortSelector).pipe();
    public isDescending$ = this.store.select(this.isDescendingSelector).pipe();

    // Actions
    public loadShipmentSortList() {
        this.store.dispatch(new sortActions.LoadShipmentSortList());
    }

    public loadShipmentSortListSuccess(shipmentList: IListInfo<SortOptions>) {
        this.store.dispatch(new sortActions.LoadShipmentSortListSuccess(shipmentList));
    }

    public loadShipmentSortListError(message: string) {
        this.store.dispatch(new sortActions.LoadShipmentSortListError(message));
    }

    public cancelLoadShipmentSortList() {
        this.store.dispatch(new sortActions.CancelLoadShipmentSortList());
    }

    public changeShipmentSort(selectedSort: string, isDescending: boolean) {
        this.store.dispatch(new sortActions.ChangeShipmentSort(selectedSort, isDescending));
    }

    public ChangeShipmentSortWithOutListUpdate(selectedSort: string, isDescending: boolean) {
        this.store.dispatch(new sortActions.ChangeShipmentSortWithOutListUpdate(selectedSort, isDescending));
    }
}


