import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadTrackers implements ServerRequestAction {
    readonly type = actionIndex.LOAD_TRACKERS;
}

export class LoadTrackersSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_TRACKERS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_TRACKERS;
    constructor(public trackerList: IListInfo<Tracker>) { }
}

export class LoadTrackersError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_TRACKERS_ERROR;
    readonly callingAction = actionIndex.LOAD_TRACKERS;
    constructor(public message: string) { }
}

export class CancelLoadTrackers implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_TRACKERS;
    readonly callingAction = actionIndex.LOAD_TRACKERS;
}
