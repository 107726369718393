<div class="row">
  <div *ngFor="let contact of contactList; let i = index;" class="col-lg-3 col-md-5 col-sm-6 col-12">
    <div class="data-outer-box p-2 mb-4 w-100">
      <div class="row mb-2">
        <div class="col-lg-1 col-md-1 col-sm-1 col-1 pl-4">
          <span>
            <i class="far fa-square"></i>
          </span>
        </div>
        <div class="col-lg-9 col-md-10 col-sm-10 col-9 pl-2">
          <div class="data-box">
            <span>{{contact.FirstName}} {{contact.LastName}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-1 col-md-1 col-sm-1 col-1 pl-4">
          <span>
          </span>
        </div>
        <div class="col-lg-9 col-md-10 col-sm-10 col-9 pl-2">
          <div class="data-box">
            <span>{{contact.Address1}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-1 col-md-1 col-sm-1 col-1 pl-4">
          <span>
          </span>
        </div>
        <div class="col-lg-9 col-md-10 col-sm-10 col-9 pl-2">
          <div class="data-box">
            <span>{{contact.Address2}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-1 col-md-1 col-sm-1 col-1 pl-4">
          <span>
            <i class="far fa-square"></i>
          </span>
        </div>
        <div class="col-lg-9 col-md-10 col-sm-10 col-9 pl-2">
          <div class="data-box">
            <span>{{contact.Email}}</span>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-1 col-md-1 col-sm-1 col-1 pl-4">
          <span>
            <i class="far fa-square"></i>
          </span>
        </div>
        <div class="col-lg-9 col-md-10 col-sm-10 col-9 pl-2">
          <div class="data-box">
            <span>{{contact.Phone}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <emr-button identifier="deleteContact" cssClass="btn-lg btn-basic btn-block" buttonText="Delete Contact" i18n-buttonText="@@link_delete_contact" buttonIcon="fa fa-trash-o"
            (onClick)="DeleteContact(i)"></emr-button>
        </div>
      </div>
    </div>
  </div>
</div>