import {
  Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation,
  OnDestroy, ViewChild, OnChanges, SimpleChanges
} from '@angular/core';

import { Observable } from 'rxjs';

import { LocationInfo } from 'emr-ng-shared';
import { CreateShipmentRequest } from 'emr-ng-shared';

import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { NgForm } from '@angular/forms';
import { TripStopModel } from 'app-modules/core/store/models/trip-stop.model';

class LocationInfoListItem {
  get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
  get value() { return this.locationInfo.LocationId; }
  constructor(private locationInfo: LocationInfo) { }
}

@Component({
  selector: 'app-stop-location-list',
  templateUrl: './stop-location-list.component.html',
  styleUrls: ['./stop-location-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class StopLocationListComponent implements OnInit, OnDestroy, OnChanges {

  isLocationListLoading$: Observable<boolean>;
  showDropDownCreateToolTip: boolean;
  isStopsFormSubmitted = false;
  @Input() label: string;
  @Input()
  set isEndLocationUndefined(value: boolean) {
    this.showDropDownCreateToolTip = value;
  }
  @Input() set locations(value: LocationInfoListItem[]) {
    if (!value || value.length === 0) {
      value = [];
      this.stopLocations = [];
    } else {
      this.setLocationStops(value);
    }
  }

  @Output() public openAddLocationModal = new EventEmitter();
  Stops: TripStopModel[];
  @Input() public Shipment: CreateShipmentRequest;

  @Input() tripStops: TripStopModel[];
  @Output() tripStopsChange = new EventEmitter<TripStopModel[]>();

  @Input() public selectedOD: string;
  @ViewChild(NgForm) stopsForm: NgForm;
  @Output() onCreateNewLocation = new EventEmitter();

  constructor(private createSvc: CreateShipmentService) { }

  stopLocations: LocationInfoListItem[];
  AirportDeparture = false;
  selectedStopsLocations: string;
  editMode = false;
  Index: number = 0;
  // AirFreightDetails = [{ text: 'Airport Arrival', value: 'Airport/Arrival' }, { text: 'Airport Departure', value: 'AirportDeparture' }];

  ngOnInit() {
    this.isLocationListLoading$ = this.createSvc.isLoadingLocationsList$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tripStops) {
      this.updateStops(this.tripStops);
      this.Index = this.tripStops.length;
    }
  }

  ngOnDestroy() {
  }

  setLocationStops(value) {
    this.stopLocations = value;
    // this.checkStopValidation();
  }


  AirFreightChange(value: TripStopModel, index: number) {
    if (value.AirportDeparture) {
      this.AirportDeparture = true;
    } else {
      this.AirportDeparture = false;
      value.ArrivalLocationId = null;
      value.AirportArrival = false;
      this.Shipment.EstimatedLoadingMinutes = null;
      this.Shipment.EstimatedFlightMinutes = null;
    }
  }

  DeleteStop(data: TripStopModel, index) {
    data.IsDelete = true;
    if (data.AirportDeparture) {
      this.AirportDeparture = false;
      this.Shipment.EstimatedFlightMinutes = null;
      this.Shipment.EstimatedLoadingMinutes = null;
    }
    this.Stops.splice(index, 1);
    this.getSelectedLocations();
    if (!data.IsEdit ||
      this.Stops.length === 0) {
      Object.keys(this.stopsForm.controls).forEach(k => this.stopsForm.controls[k].updateValueAndValidity());
      this.editMode = false;
    }
  }

  openAddStopLocationModal(data, i: number, IsArrival: boolean) {
    this.openAddLocationModal.emit({ data, i, IsArrival });
  }

  formSubmit() {
    Object.keys(this.stopsForm.controls).forEach(k => this.stopsForm.controls[k].updateValueAndValidity());
    if (this.stopsForm.valid) {
      this.Stops.map(k => k.IsEdit = true);
      this.editMode = false;
      this.getSelectedLocations();
    } else {
      this.isStopsFormSubmitted = true;
    }
  }

  createNewStop() {
    const location = {
      LocationId: null, AirportDeparture: false, AirportArrival: null, ArrivalLocationName: null, TripStopTypeCode: 0,
      ArrivalLocationId: null, LocationName: null, IsEdit: false, IsInValid: false,Index: this.Index
    };
    this.isStopsFormSubmitted = false;
    this.Index++;
    this.Stops.push(location);
    this.editMode = true;
    this.onCreateNewLocation?.emit();
  }


  editStop(location) {
    this.editMode = true;
    location.IsEdit = false;
    if (this.selectedStopsLocations) {
      this.selectedStopsLocations =
        this.selectedStopsLocations.split(',').filter(k => k.toString() !== location.LocationId.toString()).join(',');
      if (location.ArrivalLocationId) {
        this.selectedStopsLocations =
          this.selectedStopsLocations.split(',').filter(k => k.toString() !== location.ArrivalLocationId.toString()).join(',');
      }
    }
  }

  getSelectedLocations() {
    if (this.Stops && this.Stops.length) {
      const locations: number[] = this.Stops.filter(x => x.IsEdit).map(k => k.LocationId);
      const arrival = this.Stops.filter(x => x.IsEdit).find(k => k.AirportDeparture);
      if (arrival && arrival.ArrivalLocationId) {
        locations.push(arrival.ArrivalLocationId);
      }
      this.selectedStopsLocations = locations.join(',');
    } else {
      this.selectedStopsLocations = '';
    }
  }

  onLocationSelect(a: TripStopModel, isArrival: boolean = false) {
    if (this.stopLocations && a.LocationId) {
      const location = this.stopLocations.find(k => k.value === a.LocationId);
      if (location && location.text) {
        a.LocationName = location.text;
      }
    }
    if (a.AirportDeparture && this.stopLocations && a.ArrivalLocationId && isArrival) {
      const ArrivalLocation = this.stopLocations.find(k => k.value === a.ArrivalLocationId);
      if (ArrivalLocation && ArrivalLocation.text) {
        a.ArrivalLocationName = ArrivalLocation.text;
      }
    }
    if (a.IsInValid && this.stopsForm.valid) {
      a.IsInValid = false;
    }
  }

  formBlur() {
    if (this.stopsForm.valid) {
      this.Stops.map(k => k.IsEdit = true);
      this.editMode = false;
    }
  }

  private updateStops(value: TripStopModel[]) {
    let isChanged = false;
    if (value && value.length > 0) {
      this.Stops = value;
      if (value.find(k => k.AirportDeparture || k.AirportArrival)) {
        this.AirportDeparture = true;
      }
      this.editMode = !!value.find((a) => a.IsInValid);
      setTimeout(() => {
        this.getSelectedLocations();
        if (this.editMode) {
          value.filter(a => a.IsInValid).forEach(a => this.editStop(a));
          this.stopsForm.ngSubmit.next();
        }
      });
      isChanged = true;
    } else if (value !== this.Stops) {
      this.Stops = [];
      this.AirportDeparture = false;
      this.editMode = false;
      setTimeout(() => {
        this.selectedStopsLocations = null;
      });
      isChanged = true;
    }
    if (isChanged) {
      this.tripStopsChange.emit(this.Stops);
    }
  }

  estimateChange(location: TripStopModel) {
    if (location.IsInValid && this.stopsForm.valid) {
      location.IsInValid = false;
    }
  }
}


