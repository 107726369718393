import { Component, OnInit, Optional } from '@angular/core';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { UpdateShipment } from 'app-modules/core/models/update-shipment.model';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Sensor, SensorRangeInfo, SensorType, ShipmentRequest, SetTrackerSensorRangeRequest } from 'emr-ng-shared';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take, tap } from 'rxjs/operators';
import { SensorRangeComponent, SensorRangeEventArgs } from '../sensor-range/sensor-range.component';

@Component({
  selector: 'app-tracker-sensor-ranges',
  templateUrl: './tracker-sensor-ranges.component.html',
  styleUrls: ['./tracker-sensor-ranges.component.css']
})
export class TrackerSensorRangesComponent implements OnInit {
  public error:string = null;
  serialNumber: string;
  showCO2Sensor: boolean;
  showProbeSensor: boolean;
  showHumiditySensor: boolean;

  sensorTypes: Sensor[];
  SensorRangeInfo: SensorRangeInfo[];
  sensorRangeList$: any;

  tempRangeID: number;
  humidityRangeID: number;
  co2RangeID: number;
  probeRangeID: number;

  bsModalRef: BsModalRef;
  sensorType: typeof SensorType;

  initTempRangeID: number;
  initHumidityRangeID: number;
  initCO2RangeID: number;
  initProbeRangeID: number;
  sensorRangeList: _.Dictionary<SensorRangeInfo[]>; //IListInfo<SensorRangeInfo>;

  constructor(
    private createSvc: CreateShipmentService,
    private modalSvc: BsModalService,
    private shpSvc: ShipmentService,
    @Optional() public bsMainModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.sensorRangeList$ = this.createSvc.sensorRangeList$.pipe(tap(n => {
      this.sensorRangeList = n;
    }));
    this.sensorType = SensorType;
    if (this.serialNumber) {
      this.GetTrackerSensors();
    }
  }

  GetTrackerSensors() {
    this.showCO2Sensor = this.showProbeSensor = this.showHumiditySensor = false;
    const request = new ShipmentRequest();
    request.GlobalDeviceId = this.serialNumber;
    this.createSvc.GetTrackerSensorsBySerial(request).subscribe(n => {
      if (n && n.Sensors && n.Sensors.length > 0) {
        this.sensorTypes = n.Sensors;

        const tempSensorData = n.Sensors.find(k => k.Type === SensorType.Temperature);
        if (tempSensorData !== undefined) {
          this.tempRangeID = tempSensorData?.DefaultSensorRangeID;
          this.initTempRangeID = this.tempRangeID;
        }

        const co2SensorData = n.Sensors.find(k => k.Type === SensorType.CarbonDioxide);
        if (co2SensorData !== undefined) {
          this.showCO2Sensor = true;
          this.co2RangeID = co2SensorData?.DefaultSensorRangeID;
          this.initCO2RangeID = this.co2RangeID;
        }

        const probeSensorData = n.Sensors.find(k => k.Type === SensorType.ProbeTemp);
        if (probeSensorData !== undefined) {
          this.showProbeSensor = true;
          this.probeRangeID = probeSensorData?.DefaultSensorRangeID;
          this.initProbeRangeID = this.probeRangeID;
        }

        const humiditySensorData = n.Sensors.find(k => k.Type === SensorType.Humidity);
        if (humiditySensorData !== undefined) {
          this.showHumiditySensor = true;
          this.humidityRangeID = humiditySensorData?.DefaultSensorRangeID;
          this.initHumidityRangeID = this.humidityRangeID;
        }

      } else {
        if (n.ErrorCode > 0) {
        }
      }
    });
  }

  refactorValue(value) {
    return value && (/\S/.test(value)) ? value : 0;
  }

  openAddSensorRangeModal(type) {
    this.bsModalRef = this.modalSvc.show(
      SensorRangeComponent,
      {
        initialState: { SensorType: type },
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    this.bsModalRef.content.addSensorRange.subscribe(n => this.onAddSensorRange(n));
  }

  onAddSensorRange(args: SensorRangeEventArgs) {
    setTimeout(() => {
      switch (args.request.SensorTypeCode) {
        case SensorType.Temperature:
          this.tempRangeID = args.request.SensorRangeId;
          break;
        case SensorType.Humidity:
          this.humidityRangeID = args.request.SensorRangeId;
          break;
        case SensorType.CarbonDioxide:
          this.co2RangeID = args.request.SensorRangeId;
          break;
        case SensorType.ProbeTemp:
          this.probeRangeID = args.request.SensorRangeId;
          break;
      }
    }, 50);
    this.bsModalRef.hide();
  }

  onSetTrackerSensors() {
    this.setTrackerSensors();
    if (this.SensorRangeInfo?.length > 0) {
      const req = new SetTrackerSensorRangeRequest();
      req.TrackerId = this.serialNumber;
      req.SensorRangeInfo = this.SensorRangeInfo;
      this.createSvc.SetTrackerSensorRange(req).pipe(take(1)).subscribe((n) => {
        if (n.ErrorCode > 0) {
          this.error = n.LocalizedErrorMessage;
        } else {
          this.shpSvc.updateShipmentByTrackerID(this.getUpdatedShipmentInfo(req));
          this.bsMainModalRef.hide();
        }
      }, (e: IErrorInfo) => {
        this.error = e.message;
      });
    }
  }

  getUpdatedShipmentInfo(request: SetTrackerSensorRangeRequest) {
    let shipment = new UpdateShipment();
    shipment.trackerId  = request.TrackerId;
    request.SensorRangeInfo.forEach(k => {
      const selectedSensorRange = this.sensorRangeList[SensorType[k.TypeCode]].find(s => s.SensorRangeId === k.SensorRangeId);
      switch (k.TypeCode) {
        case SensorType.Temperature:
          shipment.SensorTempRangeId = k.SensorRangeId;
          shipment.sensorRange = selectedSensorRange?.Name;
          break;
        case SensorType.Humidity:
          break;
        case SensorType.CarbonDioxide:
          shipment.SensorCO2RangeId = k.SensorRangeId;
          break;
        case SensorType.ProbeTemp:
          break;
      }
    });
    return shipment;
  }

  setTrackerSensors() {
    this.SensorRangeInfo = [];
    this.sensorTypes.forEach(k => {
      const sensorRangeInfo = new SensorRangeInfo();
      sensorRangeInfo.TypeCode = k.Type;
      switch (k.Type) {
        case SensorType.Temperature:
          this.tempRangeID = this.refactorValue(this.tempRangeID);
          if (this.initTempRangeID !== this.tempRangeID) {
            sensorRangeInfo.SensorRangeId = this.tempRangeID ? this.tempRangeID : 0;
          }
          break;
        case SensorType.Humidity:
          this.humidityRangeID = this.refactorValue(this.humidityRangeID);
          if (this.initHumidityRangeID !== this.humidityRangeID) {
            sensorRangeInfo.SensorRangeId = this.humidityRangeID ? this.humidityRangeID : 0;
          }
          break;
        case SensorType.CarbonDioxide:
          this.co2RangeID = this.refactorValue(this.co2RangeID);
          if (this.initCO2RangeID !== this.co2RangeID) {
            sensorRangeInfo.SensorRangeId = this.co2RangeID ? this.co2RangeID : 0;
          }
          break;
        case SensorType.ProbeTemp:
          this.probeRangeID = this.refactorValue(this.probeRangeID);
          if (this.initProbeRangeID !== this.probeRangeID) {
            sensorRangeInfo.SensorRangeId = this.probeRangeID ? this.probeRangeID : 0;
          }
          break;
      }
      if (sensorRangeInfo?.SensorRangeId >= 0) {
        this.SensorRangeInfo.push(sensorRangeInfo);
      }
    });
  }

}
