import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { LoadShipmentAddressSuccess, LoadShipmentBySerialSuccess } from '../actions/shipment.actions';
import * as actionIndex from 'app-modules/core/store/actions/index';
import { delay, tap, map } from 'rxjs/operators';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { ShipmentStateService } from '../services/shipment-state.service';

@Injectable()
export class ShipmentAddressUpdatedEffect {

    
    showSpinner = createEffect(() => this.actions.pipe(
        ofType<LoadShipmentAddressSuccess>(actionIndex.LOAD_SHIPMENT_ADDRESS_SUCCESS),
        delay(10),
        tap((a: LoadShipmentAddressSuccess) => {
            if (a && a.shipmentList) {
                this.shipSvc.OnShipmentAddressUpdated.emit(a.shipmentList.list);
            }
        })
    ), { dispatch: false });

    // @Effect({ dispatch: false })
    // loadShipmentBySerialSuccess = this.actions.pipe(
    //     ofType<LoadShipmentBySerialSuccess>(actionIndex.LOAD_SHIPMENT_BY_SERIAL_SUCCESS),
    //     delay(10),
    //     tap((a: LoadShipmentBySerialSuccess) => {
    //         if (a && a.shipmentList) {
    //             this.shipStateSvc.loadShipmentsSuccess(a.shipmentList);
    //         }
    //     })
    // );

    constructor(private actions: Actions, private shipSvc: ShipmentService, private shipStateSvc: ShipmentStateService) { }

}
