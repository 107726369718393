import { Component, OnInit, Input } from '@angular/core';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { TripState } from 'emr-ng-shared';

@Component({
  selector: 'app-map-info-box-detail-content',
  templateUrl: './map-info-box-detail-content.component.html',
  styleUrls: ['./map-info-box-detail-content.component.css']
})
export class MapInfoBoxDetailContentComponent implements OnInit {

  @Input() shipment: Shipment;
  public isZoomInfoLevel: boolean = true;
  public showInfoZoomOption: boolean = true;
  @Input() IsUnAuthenticated = false;
  @Input()
  set showInfoBoxZoom(value) {
    this.showInfoZoomOption = value;
  }
  @Input() goBack:boolean;
  Past90Days: number;

  @Input()
  set isZoomedInfoLevel(value) {
    this.isZoomInfoLevel = value;
  }
  isUnAuthenticated = this.shpSvc.getUnAuthenticationToken();
  constructor(
    public shpSvc: ShipmentService) { }

  ngOnInit() {
    const today = new Date();
    const day = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    this.Past90Days = day.setDate(day.getDate() - 89);
  }


  CheckDatesInPast90Days(lastReported, actualEndTime, tripState) {
    // For pending trips we are not checking but for completed trips we are checking for actualEndTIme for other trips we are checking for LastreportedTime
    return tripState == TripState.Pending || (tripState == TripState.Completed ? new Date(actualEndTime).getTime() > this.Past90Days : new Date(lastReported).getTime() > this.Past90Days);
}
}
