import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerRoutingModule } from './customer-routing.module';
import { SelectCustomerComponent } from './components/select-customer/select-customer.component';
import { CustomerSelectorComponent } from 'app-modules/customer/components/customer-selector/customer-selector.component';

@NgModule({
    imports: [
      CommonModule,
      CustomerRoutingModule
    ],
    declarations: [
        SelectCustomerComponent,
        CustomerSelectorComponent
    ]
  })
  export class CustomerModule { }
