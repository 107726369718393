import { errorUnknown } from 'app-modules/core/consts/localization';

export interface IErrorInfo {
    code?: number;
    message?: string;
    debugCode?: number;
    debugMessage?: string;
}

export function isIErrorInfo(value: IErrorInfo | any): value is IErrorInfo {
    return (value as IErrorInfo).code !== undefined;
}

export function toIErrorInfo(value): IErrorInfo {
    if (isIErrorInfo(value)) {
        return value;
    } else if (typeof value === 'string') {
        return {
            code: 9999,
            debugMessage: value
        } as IErrorInfo;
    } else if ((typeof value === 'object')) {
        return {
            code: value.status,
            message: value.status + ' - ' + errorUnknown
        } as IErrorInfo;
    } else {
        return {
            code: 9999
        } as IErrorInfo;
    }
}

/*

////---------- Code Reference ----------\\\\

//----- HTTP Codes -----\\
500 - internal server error
503 - service unavailable

//----- Oversight Codes -----\\
1000 - login error
2001 - server timeout
9999 - unknown error

*/
