import { ICreateShipmentState } from 'app-modules/core/store/models/create-shipment-state.interface';
import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as createActions from 'app-modules/core/store/actions/edit-shipment.actions';

type Actions =
    authActions.SignOut |
    createActions.SelectLinkedCustomer;

export function EditShipmentReducer(state: ICreateShipmentState = initialState.editShipment, action: Actions): ICreateShipmentState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.editShipment;

        case actionIndex.EDIT_SHIPMENT_SELECT_LINKED_CUSTOMER:
            return {
                ...state,
                linkedCustomer: action.linkedCustomer
            };
        default:
            return state;
    }
}
