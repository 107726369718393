
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Subscription, Observable } from 'rxjs';

import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';

import { TimelineInfo, Shipment, EmrUtilService, SensorLocationColorRange } from 'emr-ng-shared';
import { Shipment as CurrentShipment } from 'app-modules/core/models/shipment.model';
import { TimelineData } from 'app-modules/core/models/timeline-data.model';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import { SensorChartService } from 'app-modules/core/services/sensor-chart.service';
import { ShipmentDetailsCommonData } from 'app-modules/shipment-detail/models/ShipmentDetailsCommonData';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { label_n_a } from 'app-modules/core/consts/localization';

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.css']
})

export class TimelineComponent implements OnInit {
    @Input() latestShipmentDetails: Shipment;
    viewLocationsLoading = false;
    @Input()
    set timelineInfo(value: SensorLocationColorRange[]) {
        if (value) {
            this.getTimeline(value);
        }
    }
    @Input() shipment: CurrentShipment;
    @Input() shipmentDetailsCommonData: ShipmentDetailsCommonData;
    @Input() ShowDriveByLocationsInTimeLine: boolean = false;
    timelineData: SensorLocationColorRange[] = null;

    private selectedShipmentSubscription: Subscription;
    public userCanCreateShipment: Observable<boolean>;
    isSensorChartLoading$ = this.sensorSvc.isChartLoading$;
    isTimeLineLoading$ = this.detailSvc.isTimeLineLoading$;
    selectedTimeline: SensorLocationColorRange;
    label_n_a = label_n_a;

    constructor(
        private shpSvc: ShipmentService,
        private detailSvc: ShipmentDetailService,
        private popupSvc: OversightPopupService,
        private authSvc: AuthService,
        private sensorSvc: SensorChartService,
        private utilSvc: EmrUtilService,
        private imgMapSvc: ImageMapService
    ) { }

    @Input() TraxxList: ITraxxState;
    @Input() TraxxCopy: ITraxxState;
    originUrl = this.imgMapSvc.getImageURL('OI', null, null);
    destinationUrl = this.imgMapSvc.getImageURL('DI', null, null);

    ngOnInit() {
        this.userCanCreateShipment = this.authSvc.canCreateShipment$;
    }

    onViewLocationClick(serialNumber: string) {
        this.showCurrentLocationLoading(true);
        this.selectedShipmentSubscription = this.shpSvc.GetCurrentLocation(serialNumber).subscribe(n => {
            if (n) {
                this.latestShipmentDetails.TrackerState.AddressGeo = n.AddressGeo;
                this.latestShipmentDetails.TrackerState.AddressGeoTimestampFormatted = n.AddressGeoTimestampFormatted;
                this.latestShipmentDetails.TrackerState.AddressOlderThanThreshold = n.AddressOlderThanThreshold;
            }
            this.showCurrentLocationLoading(false);
        }, e => {
            this.showCurrentLocationLoading(false);
        });
    }

    onCreateShipmentClick(serialNumber?: string, customerTrackerId?: string) {
        this.popupSvc.showCreateShipmentPopup(serialNumber, customerTrackerId);
    }

    getTimeline(timelineInfo: SensorLocationColorRange[]) {
        if (timelineInfo) {
            this.timelineData = timelineInfo.filter(k => k.ShowInTimeline);
        }
    }

    private unSubscribeLocationUpdate() {
        if (this.selectedShipmentSubscription) {
            this.selectedShipmentSubscription.unsubscribe();
            this.selectedShipmentSubscription = null;
        }
    }

    private showCurrentLocationLoading(startLoading: boolean) {
        this.viewLocationsLoading = startLoading;
        this.unSubscribeLocationUpdate();
    }

    public onTimelineClick(data: SensorLocationColorRange) {
        this.selectedTimeline = { ...data };
    }
}
