import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocaleRoutingModule } from './locale-routing.module';
import { LocaleComponent } from 'app-modules/locale/components/locale/locale.component';
import { LocaleSelectorComponent } from 'app-modules/locale/components/locale-selector/locale-selector.component';
import { LanguageSelectorComponent } from 'app-modules/locale/components/language-selector/language-selector.component';

@NgModule ({
    imports: [
        CommonModule,
        LocaleRoutingModule
    ],
    declarations: [
        LocaleComponent,
        LocaleSelectorComponent,
        LanguageSelectorComponent,
    ],
    exports: [
        LocaleComponent,
        LocaleSelectorComponent,
        LanguageSelectorComponent
    ]
})
export class LocaleModule {

}
