import { Component, OnInit, Input, ViewEncapsulation, ElementRef, OnChanges, ViewChild } from '@angular/core';
// TODO remove when common loader is implemented
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import * as Highcharts from 'highcharts';
import { AxisRange } from 'app-modules/shipment-detail/components/sensor/models/AxisRange';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { DateTimeFormat } from 'app-modules/shipment-detail/components/shipment-detail/shipment-detail.component';
import { localeAM, localePM } from 'app-modules/core/consts/localization';
import { SensorChartDataService } from 'app-modules/shipment-detail/services/sensor-chart-data.service';
import _ from 'lodash';

export enum TripRangeColors {
    LowCritical = '#00008b',
    LowWarn = '#add8e6',
    HighWarn = '#ffa500',
    HighCritical = '#ff0000'
}

@Component({
    selector: 'app-sensor-chart',
    templateUrl: './sensor-chart.component.html',
    styleUrls: ['./sensor-chart.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class SensorChartComponent implements OnInit, OnChanges {
    static MMDDYYYYTWLFormat = '%m/%d/%Y <br/> %I:%M %p';
    static MMDDYYYYTWFRFormat = '%m/%d/%Y <br/> %H:%M';
    static DDMMYYYYTWLFormat = '%d/%m/%Y <br/> %I:%M %p';
    static DDMMYYYYTWFRFormat = '%d/%m/%Y <br/> %H:%M';

    chart: any;
    Highcharts = Highcharts;
    updateValue = false;
    temperatureUnit = '';
    temperatureUnitString = '';
    private visibleSeries = [];
    showEvent = false;
    dateRange: { min: number, max: number } = null;
    defaultDateRange: { min: number, max: number } = null;

    @Input() locationLocale = 'Location';
    @Input() noLatLon = 'No lat/lon values';
    @Input() timeFormatEnum;
    shockAxisRange: AxisRange;
    leftAxisShockLabel: string = `{value:.0f}g`;
    @Input()
    set SeriesVisibility(value: any[]) {
        this.visibleSeries = value;
        if (value?.length == 1 && value.find(k => k.Pin === "SHK")) {
            const shockSensor = value.find(k => k.Pin === "SHK");
            this.shockAxisRange = { minValue: shockSensor.MinValue, maxValue: shockSensor.MaxValue }
        } else {
            this.shockAxisRange = null;
        }
        if (this.chartOptions &&
            this.chartOptions.series &&
            this.chartOptions.series.length > 0) {

            this.setAxisRange();

            this.updateVisibleSeries();
            this.setAxisRange();
            //  this.updateVisibleEventSeries();
            this.updateChart();
        }
    }

    @Input()
    set showEvents(isShowEvent: boolean) {
        this.showEvent = isShowEvent;
        this.updateVisibleSeries();
        this.updateVisibleEventSeries();
        this.updateChart();
    }

    hasTempAxis = false;
    @Input() set setTempAxis(hasTempAxis: boolean) {
        this.hasTempAxis = hasTempAxis;
        this.setAxisRange();
        this.updateChart();
    }

    @Input() chartDateTimeFormat: DateTimeFormat;

    overrideAxisRange: AxisRange;

    @Input()
    set LeftYAxisMinMax(value: AxisRange) {
        this.overrideAxisRange = value;
        if (this.chartOptions &&
            this.chartOptions.yAxis &&
            this.chartOptions.yAxis.length > 0) {
            this.setAxisRange();
            this.updateChart();
        }
    }

    plotBandsSelection = 0;

    @Input()
    set SelectPlotBands(value: number) {
        this.plotBandsSelection = value;
        if (this.chartOptions &&
            this.chartOptions.xAxis) {
            this.setPlotBands();
            this.updateChart();
        }
    }

    @Input()
    set DateRange(axisRange: { min: number, max: number }) {
        this.dateRange = axisRange;
        setTimeout(() => this.setXAxisRange(), 0);
    }

    locationBands = [];
    tempPlotBands = [];

    @Input() public translatedStartTime: string;
    @Input() public translatedEndTime: string;

    @Input() chartDateFormat: number;
    @Input()
    set chartData(value) {
        if (value.updateFlag) {
            this.defaultDateRange = null;
            this.locationBands = value.locationPlotBands;
            this.tempPlotBands = value.tempPlotBands;
            const plotLines = [];
            const xPlotLines = [];
            value.plotLines?.sensorPlotLines?.map(sp => {
                if (sp?.HighWarn != null) {
                    plotLines.push({ dashStyle: 'Dash', value: sp.HighWarn, color: '#ffa500', width: 0, plotLineType: sp.Pin });
                }
                if (sp?.LowWarn != null) {
                    plotLines.push({ dashStyle: 'Dash', value: sp.LowWarn, color: '#add8e6', width: 0, plotLineType: sp.Pin });
                }
                if (sp?.HighCritical != null) {
                    plotLines.push({ dashStyle: 'Dash', value: sp.HighCritical, color: '#ff0000', width: 0, plotLineType: sp.Pin });
                }
                if (sp?.LowCritical != null) {
                    plotLines.push({ dashStyle: 'Dash', value: sp.LowCritical, color: '#00008b', width: 0, plotLineType: sp.Pin });
                }
            })
            if (value.plotLines.TripStart) {
                const tripStartFormatter = { value: value.plotLines.TripStart, format: this.getXAxisFormatter() };
                const formattedDate = tripStartFormatter.format.apply(tripStartFormatter).replace("<br/> ", "&#013;");
                xPlotLines.push({
                    color: 'black',
                    width: 3,
                    zIndex: 3,
                    value: value.plotLines.TripStart,
                    label: {
                        text: `<img src='https://staging-res.locustraxx.com/LocusOverSight/V2/WebApp/Images/Common/AppImages/cte/Origin.svg' title='${formattedDate}' />`,
                        useHTML: true,
                        rotation: 0,
                        verticalAlign: 'top',
                        x: -15,
                        y: 10,
                        style: {
                            color: 'black',
                            fontWeight: 'bold'
                        }
                    }
                });
            }
            if (value.plotLines.TripEnd) {
                const tripEndFormatter = { value: value.plotLines.TripEnd, format: this.getXAxisFormatter() };
                const formattedDate = tripEndFormatter.format.apply(tripEndFormatter).replace("<br/> ", "&#013;");
                xPlotLines.push({
                    color: 'black',
                    width: 3,
                    zIndex: 3,
                    value: value.plotLines.TripEnd,
                    label: {
                        text: `<img src='https://staging-res.locustraxx.com/LocusOverSight/V2/WebApp/Images/Common/AppImages/cte/Destination.svg' title='${formattedDate}' />`,
                        useHTML: true,
                        rotation: 0,
                        verticalAlign: 'top',
                        x: -15,
                        y: 10,
                        style: {
                            color: 'black',
                            fontWeight: 'bold'
                        }
                    }
                });
            }
            this.chartOptions.series = [];
            this.chartOptions.yAxis = [];
            this.chartOptions.xAxis.min = value.minXValue;
            this.chartOptions.xAxis.max = value.maxXValue;
            this.defaultDateRange = { min: value.minXValue, max: value.maxXValue };
            if (value.sensorsData && value.sensorsData.length > 0) {
                value.sensorsData.forEach(sensorChartData => {

                    let scatterPlotOptions = null;
                    if (sensorChartData.eventsData && sensorChartData.eventsData.length > 0) {
                        const partialScatterOptions = {
                            data: sensorChartData.eventsData
                        };
                        scatterPlotOptions = {
                            ...this.scatterPlotOptions,
                            ...partialScatterOptions,
                            yAxis: sensorChartData.isRight ? 1 : 0,
                            name: sensorChartData.labelname + '_Events',
                            linkedTo: sensorChartData.labelname,
                            pinData: 'alerts',
                            id: sensorChartData.pin + '_Alerts'
                        };
                    }

                    let probeScatterPlotOptions = null;
                    let probeIsolatePlotOptions = null;
                    if (sensorChartData.pin === "PRB") {
                        if (sensorChartData.probeConnects && sensorChartData.probeConnects.length > 0) {
                            const partialProbeScatterOptions = {
                                data: sensorChartData.probeConnects
                            };
                            probeScatterPlotOptions = {
                                ...this.probeScatterPlotOptions,
                                ...partialProbeScatterOptions,
                                yAxis: sensorChartData.isRight ? 1 : 0,
                                name: sensorChartData.name + '_Events',
                                linkedTo: sensorChartData.labelname,
                                pinData: sensorChartData.pin,
                                id: sensorChartData.pin + 'disconnected'+ '_Alerts'
                            };
                        }

                        if (sensorChartData.probeIsolatePoints && sensorChartData.probeIsolatePoints.length > 0) {
                            const partialIsolateScatterOptions = {
                                data: sensorChartData.probeIsolatePoints
                            };
                            probeIsolatePlotOptions = {
                                ...this.probeIsolatePlotOptions,
                                ...partialIsolateScatterOptions,
                                yAxis: sensorChartData.isRight ? 1 : 0,
                                name: "<span style=\"color:#62bb46\">Probe Temperature</span>",
                                linkedTo: sensorChartData.labelname,
                                pinData: sensorChartData.pin,
                                id: sensorChartData.pin + '_Series'
                            };
                        }
                    }

                    if (sensorChartData.chartData && sensorChartData.chartData.length > 0) {
                        const minYAxis = Math.min.apply(Math, sensorChartData.chartData.map(function (o) { return o.y; }));
                        const maxYAxis = Math.max.apply(Math, sensorChartData.chartData.map(function (o) { return o.y; }));
                        // this.chartOptions.xAxis.min = (this.chartOptions.xAxis.min && this.chartOptions.xAxis.min < minXaxis)
                        //     ? this.chartOptions.xAxis.min : minXaxis;
                        // this.chartOptions.xAxis.max = this.chartOptions.xAxis.max < maxXaxis ? maxXaxis : this.chartOptions.xAxis.max;
                        const partialChartOptions = {
                            name: sensorChartData.labelname, data: sensorChartData.chartData,
                            yAxis: sensorChartData.isRight ? 1 : 0,
                            pinData: sensorChartData.pin,
                            calcMinVal: minYAxis,
                            calcMaxVal: maxYAxis,
                            id: sensorChartData.pin
                        };
                        let chartDataOptions;
                        // TODO Replace when SensorTypeCode Fixed
                        // switch (sensorChartData.type) {
                        switch (sensorChartData.pin) {
                            case 'G0':
                                // case SensorType.Temperature:
                                chartDataOptions = { ...this.temperatureLineOptions, ...partialChartOptions };
                                break;
                            case 'PRB':
                                // case SensorType.ProbeTemp:
                                chartDataOptions = { ...this.temperatureProbeLineOptions, ...partialChartOptions };
                                break;
                            case 'HU':
                                // case SensorType.Humidity:
                                chartDataOptions = { ...this.humidityLineOptions, ...partialChartOptions };
                                break;
                            case 'CO2':
                                // case SensorType.CarbonDioxide:
                                chartDataOptions = { ...this.co2LineOptions, ...partialChartOptions };
                                break;
                            case 'SHK':
                                chartDataOptions = { ...this.scatterPlotOptions, ...partialChartOptions };
                                break;
                            default:
                                chartDataOptions = null;
                                scatterPlotOptions = null;
                                break;
                        }

                        if (chartDataOptions) {
                            this.chartOptions.series.push(chartDataOptions);
                        }

                        if (scatterPlotOptions) {
                            this.chartOptions.series.push(scatterPlotOptions);
                        }

                        if (probeScatterPlotOptions) {
                            this.chartOptions.series.push(probeScatterPlotOptions);
                        }

                        if (probeIsolatePlotOptions) {
                            this.chartOptions.series.push(probeIsolatePlotOptions);
                        }
                    }
                });

                if (value.leftAxisOptions) {
                    const cmpt = this;
                    const minVal = value.leftAxisOptions.min !== null ? this.getRoundedValue(value.leftAxisOptions.min) : null;
                    const maxVal = value.leftAxisOptions.max !== null ? this.getRoundedValue(value.leftAxisOptions.max) : null;
                    const tickinterval = Math.ceil((maxVal - minVal) / 7);
                    const lowerBandwidth = Math.ceil(0.35 * tickinterval);
                    const upperBandwidth = Math.ceil(0.75 * tickinterval);
                    const option = {
                        ...this.yAxisOptions,
                        min: minVal,
                        max: maxVal,
                        defaultMinVal: minVal,
                        defaultMaxVal: maxVal,
                        tickinterval,
                        labels: value.leftAxisOptions.label,
                        title: value.leftAxisOptions.title,
                        plotLines: value.sensorsData.length === 1 ? plotLines.filter(p => p.plotLineType == value.sensorsData[0].pin) : plotLines.filter(p => p.plotLineType == 'G0' || p.plotLineType == 'PRB'),
                        startOnTick: true,
                        endOnTick: true,
                        gridLineWidth: value.sensorsData.length > 1 ? 1 : 0,
                        tickPositioner: function () {
                            const min = this.min;
                            const max = this.max;
                            var finalTicks = [];
                            var value = 0;
                            finalTicks.push(min - lowerBandwidth);
                            value = cmpt.setCustomLabelstoAxis(max + upperBandwidth, min - lowerBandwidth, tickinterval, finalTicks);
                            return finalTicks
                        }
                    };
                    this.chartOptions.yAxis.push(option);
                }

                if (value.rightAxisOptions) {
                    const cmpt = this;
                    const minVal = value.rightAxisOptions.calcMin !== null ? this.getRoundedValue(value.rightAxisOptions.calcMin) : null;
                    const maxVal = value.rightAxisOptions.calcMax !== null ? this.getRoundedValue(value.rightAxisOptions.calcMax) : null;
                    const diffVal = maxVal - minVal;
                    const tickinterval = Math.ceil(diffVal / 7);
                    const lowerBandwidth = Math.ceil(0.35 * tickinterval);
                    const upperBandwidth = Math.ceil(0.75 * tickinterval);
                    const option2 = {
                        ...this.yAxisOptions,
                        min: minVal,
                        max: maxVal,
                        defaultMinVal: minVal,
                        defaultMaxVal: maxVal,
                        tickPositioner: function () {
                            const min = this.min;
                            const max = this.max;
                            var finalTicks = [];
                            var value = 0;
                            finalTicks.push(min - lowerBandwidth);
                            value = cmpt.setCustomLabelstoAxis(max + upperBandwidth, min - lowerBandwidth, tickinterval, finalTicks);
                            return finalTicks
                        },
                        title: value.rightAxisOptions.title,
                        labels: value.rightAxisOptions.label,
                        plotLines: plotLines.filter(p => !(p.plotLineType == 'G0' || p.plotLineType == 'PRB')),
                        defaultLabels: value.rightAxisOptions.label,
                        startOnTick: true,
                        endOnTick: true,
                        opposite: true,
                        defaultOpposite: true
                    };
                    this.chartOptions.yAxis.push(option2);
                }
            }
            if (value.plotBands) {
                this.chartOptions.xAxis.plotBands = value.plotBands;
            }
            if (value.plotLines.TripStart && this.chartOptions.xAxis.min > value.plotLines.TripStart) {
                this.chartOptions.xAxis.min = value.plotLines.TripStart;
            }
            if (value.plotLines.TripEnd && this.chartOptions.xAxis.max < value.plotLines.TripEnd) {
                this.chartOptions.xAxis.max = value.plotLines.TripEnd;
            }

            this.defaultDateRange = { min: this.chartOptions.xAxis.min, max: this.chartOptions.xAxis.max };

            this.chartOptions.xAxis.plotLines = xPlotLines;
            this.setPlotBands();
            this.updateVisibleSeries();
            this.setAxisRange();
            this.chartOptions.xAxis.labels.formatter = this.getXAxisFormatter();
            // this.chartOptions.xAxis.tickPositioner =
            //     this.getXAxisOptions(this.chartOptions.xAxis.min, this.chartOptions.xAxis.max);
            this.chartOptions.tooltip.useHTML = true;
            this.chartOptions.tooltip.formatter = this.getToolTipFormatter();
            this.chartOptions.plotOptions.pointStart = this.chartOptions.xAxis.min;
            this.updateChart(true);
            this.updateValue = true;
        } else if (value.clearChart) {
            this.updateValue = true;
            this.updateChart(true);
        }
    }

    temperatureLineOptions = {
        name: 'Temperature',
        data: [],
        marker: {
            states: {
                hover: {
                    fillColor: '#000000',
                }
            }
        },
        color: '#000000',
    };

    temperatureProbeLineOptions = {
        name: 'TemperatureProbe',
        data: [],
        marker: {
            states: {
                hover: {
                    fillColor: '#62bb46',
                }
            }
        },
        color: '#62bb46',
    };

    humidityLineOptions = {
        name: 'Humidity',
        data: [],
        marker: {
            states: {
                hover: {
                    fillColor: '#0000ff',
                }
            }
        },
        color: '#0000ff',
    };

    co2LineOptions = {
        name: 'CO2',
        data: [],
        marker: {
            states: {
                hover: {
                    fillColor: '#ff00ff',
                }
            }
        },
        color: '#ff00ff',
    };

    yAxisOptions = {
        min: null,
        max: null,
        tickLength: 10,
        tickWidth: 1,
        tickColor: '#000000',
        plotLines: [],
        gridLineWidth: 0,
        title: {
            text: ''
        },
        labels: {
            format: '{value:.2f}'
        },
        startOnTick: true,
        endOnTick: true
    };

    scatterPlotOptions =
        {
            data: [],
            type: 'scatter',
            lineWidth: 0,
            marker: {
                enabled: true
            },
            states: {
                hover: {
                    enabled: false
                },
            },
            point: {
                events: {
                    click: (e) => {
                        if (e.point.options.Latitude && e.point.options.Longitude) {
                            this.detailSvc.sensorChartClick(e.point.options);
                        }
                    }
                }
            },
            enableMouseTracking: true,
            tooltip: {
                valueSuffix: undefined
            },
            showInLegend: false,
        };

    probeScatterPlotOptions =
        {
            data: [],
            type: 'scatter',
            lineWidth: 0,
            marker: {
                enabled: true
            },
            states: {
                hover: {
                    enabled: false
                },
            },
            point: {
                events: {
                    click: (e) => {
                        if (e.point.options.Latitude && e.point.options.Longitude) {
                            this.detailSvc.sensorChartClick(e.point.options);
                        }
                    }
                }
            },
            enableMouseTracking: true,
            tooltip: {
                valueSuffix: undefined
            },
            showInLegend: false,
        };

    probeIsolatePlotOptions =
        {
            data: [],
            type: 'scatter',
            lineWidth: 0,
            marker: {
                states: {
                    hover: {
                        fillColor: '#62bb46',
                    }
                }
            },
            states: {
                hover: {
                    enabled: false
                },
            },
            point: {
                events: {
                    click: (e) => {
                        if (e.point.options.Latitude && e.point.options.Longitude) {
                            this.detailSvc.sensorChartClick(e.point.options);
                        }
                    }
                }
            },
            enableMouseTracking: true,
            tooltip: {
                valueSuffix: undefined
            },
            showInLegend: false,
        };

    chartOptions = {
        title: {
            text: null
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                turboThreshold: 0,
                animation: {
                    duration: 0
                },
                marker: {
                    enabled: false,
                    // radius: 0.7,
                    // states: {
                    //     hover: {
                    //         radius: 4
                    //     }
                    // }
                },
                pointPlacement: 'on',
                lineWidth: 2,
                step: true,
                states: {
                    hover: {
                        lineWidthPlus: 0,
                        enabled: true,
                        halo: {
                            size: 0
                        }
                    }
                },
                stickyTracking: false,
                enableMouseTracking: true,
                point: {
                    events: {
                        click: (e) => {
                            if (e.point.Latitude && e.point.Longitude) {
                                if (e.point.displayValue && e.point.labels?.length > 0) {
                                    e.point.labels[0].value += " / " + e.point.displayValue;
                                }
                                this.detailSvc.sensorChartClick(e.point);
                            }
                        }
                    }
                },
            },
            pointStart: 0
        },
        credits: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        tooltip: {
            hideDelay: 1,
            useHTML: true,
            formatter: function (): any {
                let Location = '';
                if (this.point.Location) {
                    Location = '<br/> Location: ' + this.point.Location;
                }
                return this.point.SatTimeString +
                    '<br/> ' + this.point.series.name + ' : ' + this.point.formattedString + Location;
            },
            snap: 0,
        },
        chart: {
            zoomType: 'xy',
            renderTo: 'container',
            plotBorderWidth: 1,
            plotBorderColor: '#3F4044',
            height: 330,
            width: 932,
            animation: false
        },
        xAxis: {
            min: null,
            max: null,
            startOnTick: false,
            endOnTick: false,
            showFirstLabel: true,
            showLastLabel: true,
            tickPositioner: function () {
                const positions = [];
                let tick = Math.floor(this.min);
                let intervals = 5;
                const increment = Math.ceil((this.max - this.min) / (intervals + 1));
                positions.push(tick);
                for (intervals; intervals >= 0; intervals--) {
                    tick = intervals === 0 ? this.max : tick + increment;
                    positions.push(tick);
                }
                return positions;
            },
            tickmarkPlacement: 'on',
            tickAmount: 0,
            minPadding: 0,
            maxPadding: 0,
            type: 'datetime',
            tickColor: '#000000',
            plotLines: [],
            labels: {
                style: {
                    fontSize: '11px',
                    color: '#000000',
                    fontWeight: 'bold'
                },
                formatter: function () {
                    return Highcharts.dateFormat(SensorChartComponent.MMDDYYYYTWLFormat, this.value);
                }
            },
            visible: true,
            plotBands: []
        },
        yAxis: [],
        series: []
    };

    constructor(
        private el: ElementRef,
        private detailSvc: ShipmentDetailService,
        private sensorService: SensorChartDataService
    ) { }

    ngOnInit() {
    }

    ngOnChanges(a) {
    }

    chartCallBack(a) {
    }


    private getRoundedValue(value: number): number {
        return Math.abs(value - Math.trunc(value)) * 100 == 50 ? Math.ceil(value) : Math.round(value);
    }


    private setPlotBands() {
        const value = this.plotBandsSelection;
        if (value === 1) {
            this.chartOptions.xAxis.plotBands = this.tempPlotBands;
        } else if (value === 2) {
            this.chartOptions.xAxis.plotBands = this.locationBands;
        } else {
            this.chartOptions.xAxis.plotBands = [];
        }
    }

    private setXAxisRange() {
        this.chart.zoomOut();
        if (this.defaultDateRange) {
            if (this.dateRange) {
                this.chartOptions.xAxis.min = this.dateRange.min;
                this.chartOptions.xAxis.max = this.dateRange.max;
            } else {
                this.chartOptions.xAxis.min = this.defaultDateRange.min;
                this.chartOptions.xAxis.max = this.defaultDateRange.max;
            }
            this.updateChart(true);
        }
    }

    private setAxisRange() {
        const value = this.overrideAxisRange ? this.overrideAxisRange : (this.shockAxisRange ? this.shockAxisRange : null);
        if (!(this.chartOptions &&
            this.chartOptions.yAxis &&
            this.chartOptions.yAxis.length > 0)) {
            return;
        }
        if (value && this.hasTempAxis) {
            const tickInterval = Math.round((value.maxValue - value.minValue) / 5);
            this.chartOptions.yAxis[0].tickinterval = tickInterval;
            //As yaxis not taking min and max we are manually giving yaxis tick positions
            const tickPosition: number[] = [value.minValue,
            value.minValue + tickInterval,
            value.minValue + tickInterval * 2,
            value.minValue + tickInterval * 3,
            value.minValue + tickInterval * 4,
            value.maxValue];
            setTimeout(() => {
                this.chart.yAxis[0].update({
                    tickPositions: tickPosition
                });

            })
        } else {
            const minVal = this.chartOptions.yAxis[0].defaultMinVal;
            const maxVal = this.chartOptions.yAxis[0].defaultMaxVal;
            const tickInterval = Math.round((maxVal - minVal) / 5);
            if (this.chart.yAxis.length > 0) {
                this.chartOptions.yAxis[0].tickinterval = tickInterval;
                this.chart.yAxis[0].tickPositions = undefined;
                this.chart.yAxis[0].update({
                    tickPositions: this.chart.yAxis[0].tickPositions
                });
            }
        }
        if (this.shockAxisRange) {
            this.chartOptions.yAxis[0].labels.format = this.leftAxisShockLabel;
        } else {
            this.chartOptions.yAxis[0].labels.format = this.visibleSeries.filter(x => x.Pin === "PRB" || x.Pin == "G0").length > 0 ? this.sensorService.tempAxisFormat : this.sensorService.humidityAxisFormat;
        }
    }

    private updateVisibleEventSeries() {
        //   this.chartOptions.series.push(scatterPlotOptions);
        // const value = this.visibleSeries;
        //  if (this.visibleSeries) {
        this.chartOptions.series.forEach(ser => {
            if (ser.pinData === 'alerts') {
                ser.visible = this.showEvent;
            }
        });
        //  }

    }

    private updateVisibleSeries() {
        if (this.visibleSeries) {
            this.chartOptions.series.forEach(ser => {
                if (ser.pinData === 'alerts') {
                    ser.visible = this.showEvent;// && !!this.visibleSeries.find(sensor => sensor.Pin === 'G0');
                } else {
                    const isSeriesSelected = !!this.visibleSeries.find(sensor => sensor.Pin === ser.pinData);
                    ser.visible = isSeriesSelected;
                }
            });
            this.chartOptions.xAxis.visible = this.visibleSeries.length !== 0;
        } else {
            this.chartOptions.series.forEach(ser => {
                ser.visible = true;
            });
            this.chartOptions.xAxis.visible = true;
        }
        this.updateVisiblePlotLines();
        this.updateVisibleYAxis();
        this.updateYAxisLabel();
    }

    private updateVisiblePlotLines() {
        const isTempAxisVisible = this.visibleSeries && !!this.visibleSeries.find(sensor => sensor.Pin === 'G0');
        if (this.chartOptions &&
            this.chartOptions.yAxis &&
            this.chartOptions.yAxis.length > 0 &&
            this.chartOptions.yAxis[0].plotLines &&
            this.chartOptions.yAxis[0].plotLines.length > 0) {

            this.chartOptions.yAxis.map(y => {
                y.plotLines.forEach(pl => {
                    if (this.visibleSeries?.length === 1 && this.visibleSeries[0].Pin == pl.plotLineType) {
                        pl.width = 2;
                    } else {
                        pl.width = isTempAxisVisible && pl.plotLineType == 'G0' ? 2 : 0;
                    }
                });
            })
        }
    }

    private updateVisibleYAxis() {
        if (this.chartOptions &&
            this.chartOptions.series) {
            const hasMultiAxis = (this.chartOptions.yAxis && this.chartOptions.yAxis.length > 1);
            const isAnyLeftVisible = this.chartOptions.series.filter(ser => ser.visible && ser.yAxis === 0).length > 0;
            const visibleRightSeries = this.chartOptions.series.filter(ser => ser.visible && ser.yAxis === 1);
            const isAnyRightVisible = visibleRightSeries.length > 0;
            if (this.chartOptions.yAxis &&
                this.chartOptions.yAxis.length > 0) {
                this.chartOptions.yAxis[0].visible = isAnyLeftVisible;
            }
            if (hasMultiAxis) {
                this.chartOptions.yAxis[1].visible = isAnyRightVisible;
                this.chartOptions.yAxis[1].opposite = this.chartOptions.yAxis[1].defaultOpposite;
                if (!isAnyLeftVisible &&
                    isAnyRightVisible) {
                    this.chartOptions.yAxis[1].opposite = false;
                    this.chartOptions.yAxis[0].plotLines = this.chartOptions.yAxis[1].plotLines;
                }
                this.chartOptions.yAxis[1].labels = {
                    ...this.chartOptions.yAxis[1].defaultLabels
                };

                const calcMinVal = Math.min.apply(Math, visibleRightSeries.map(function (o) { return o.calcMinVal; }));
                const calcMaxVal = Math.max.apply(Math, visibleRightSeries.map(function (o) { return o.calcMaxVal; }));
                const delta = (calcMaxVal - calcMinVal);
                if (calcMinVal && calcMaxVal &&
                    (delta < 5)) {
                    this.chartOptions.yAxis[1].labels = {
                        ...this.chartOptions.yAxis[1].defaultLabels,
                        format: `{value:.2f}%`
                    };
                }
            }
        }
    }

    private updateYAxisLabel() {
        if (this.chartOptions &&
            this.chartOptions.series) {
            const leftAxisLabels = [];
            const rightAxisLabels = [];

            this.chartOptions.series.forEach(series => {
                if (!series.linkedTo &&
                    series.visible) {
                    if (series.yAxis === 0) {
                        leftAxisLabels.push(series.name);
                    } else if (series.yAxis === 1) {
                        rightAxisLabels.push(series.name);
                    }
                }
            });

            if (this.chartOptions.yAxis) {
                const separator = '/';
                if (this.chartOptions.yAxis.length > 0) {
                    const axisLabel = leftAxisLabels.join(separator);
                    this.chartOptions.yAxis[0].title = {
                        text: axisLabel,
                        style: {
                            fontSize: '13px',
                            fontWeight: 'bold',
                            color: '#000000'
                        }
                    };
                }
                if (this.chartOptions.yAxis.length > 1) {
                    const axisLabel = rightAxisLabels.join(separator);
                    this.chartOptions.yAxis[1].title = {
                        text: axisLabel,
                        style: {
                            fontSize: '13px',
                            fontWeight: 'bold',
                            color: '#000000'
                        }
                    };
                }
            }
        }
    }




    getXAxisFormatter() {
        const formatString = Number(this.chartDateTimeFormat.DateFormat) === 2 ? Number(this.chartDateTimeFormat.TimeFormat) === 1 ? SensorChartComponent.DDMMYYYYTWLFormat : SensorChartComponent.DDMMYYYYTWFRFormat
            : Number(this.chartDateTimeFormat.TimeFormat) === 1 ? SensorChartComponent.MMDDYYYYTWLFormat : SensorChartComponent.MMDDYYYYTWFRFormat;
        return function () {
            let formattedDate = Highcharts.dateFormat(formatString, this.value);
            if (localeAM) {
                formattedDate = formattedDate.replace("AM", localeAM);
            }
            if (localePM) {
                formattedDate = formattedDate.replace("PM", localePM);
            }
            return formattedDate;
        };
    }


    getToolTipFormatter() {
        const locationLocale = this.locationLocale;
        const noLatLon = this.noLatLon;
        return function () {
            if (!this.series.name.includes('_Events')) {
                let Location = '', displayValue = '';
                if (this.point.Location) {
                    Location = '<br/> ' + locationLocale + ' : ' + this.point.Location;
                }
                if (!(this.point.Latitude && this.point.Longitude)) {
                    Location += '<br/> <span style="color:red">' + noLatLon + '</span>';
                }
                if (this.point.displayValue) {
                    displayValue = ' / ' + this.point.displayValue
                }

                if (!this.point.formattedString && this.point.labels?.length > 1 && this.point.labels[1].label === "Details" && this.point.labels[1].value) {
                    this.point.formattedString = this.point.labels[1].value
                }
                return this.point.SatTimeString +
                    '<br/> ' + this.point.series.name + ' : ' + this.point.formattedString + displayValue + Location;
            } else {
                let Description = '';
                if (this.point.labels.length > 1 && this.point.labels[0].label === "Description" && this.point.labels[0].value) {
                    Description = '<br/> ' + this.point.labels[0].value;
                }
                let Details = '';
                if (this.point.labels.length > 1 && this.point.labels[1].label === "Details" && this.point.labels[1].value) {
                    Details = '<br/> ' + this.point.series.name.replace("_Events", "") + ' : ' + this.point.labels[1].value;
                }

                let Location = '';
                if (this.point.Location) {
                    Location = '<br/> ' + locationLocale + ' : ' + this.point.Location;
                }
                else if (!(this.point.Latitude && this.point.Longitude)) {
                    Location += '<br/> <span style="color:red">' + noLatLon + '</span>';
                }
                return this.point.SatTimeString + Details + Description + Location;
            }
        };
    }

    updateChart = function (isChartUpdate: boolean = false) {
        this.Highcharts.setOptions({
            time: {
                useUTC: false
            }
        });
        // this.detailStateSvc.loadExportSensorReport();        
        // Adding one minute time chart max data
        let bufferMilliSeconds = 0;
        if (isChartUpdate) {
            bufferMilliSeconds = 1 * 60000;
        }
        this.chartOptions.xAxis.max = this.chartOptions.xAxis.max + bufferMilliSeconds;
        if (this.chart && this.chart.update) {
            this.chart.update(this.chartOptions, true, true);
        } else {
            this.chart = this.Highcharts['chart'](this.el.nativeElement, this.chartOptions, this.chartCallBack || null);
        }
        // this.detailStateSvc.loadExportSensorReportSuccess();
    };

    setCustomLabelstoAxis = function (rangeValue: number, value: number, offset: number, finalTicks: number[], isOverHighCritical: boolean = false) {
        var range = (rangeValue - value) / offset;
        range = Math.floor((range - Math.trunc(range)) * 100 > 60 ? range : range - 1);
        if (range >= 1) {
            for (var i = 0; i <= range - 1; i++) {
                value = value + offset;
                finalTicks.push(this.getRoundedValue(value));
            }
        }
        if (!isOverHighCritical) {
            value = rangeValue;
            finalTicks.push(this.getRoundedValue(rangeValue));
        }
        return value;
    }
}
