import { Inject, Injectable, OnDestroy } from '@angular/core';

import { Observable, of, BehaviorSubject, Subscription } from 'rxjs';
import {
    map, switchMap, filter, tap, finalize, catchError, share, withLatestFrom, take, startWith,
    distinctUntilChanged
} from 'rxjs/operators';

import {
    muteFirst, EmrUtilService, GetAlertStatusRequest, AlertStatus, GetShipmentDetailsRequest, ShipmentInfo, ChartData, GetTraxxRequest,
    DateRange, ShipmentRequest, TrackerHistory, ExportTrackerStateReportHeaders, ExportTrackerStateReportRequest, TimelineInfo,
    GetTimelineRequest, TimePeriodInfo, GetTimePeriodsRequest, TimePeriod, GetTrackerStateReportRequest, GetShipmentRequest,
    GetReverseGeocodeAddressRequest, GetTrackerStatusResponse, Coordinate, IDateTimeFilterPeriod, BusinessRuleType,
    BreadCrumbFlagRequest, BreadCrumbFlagResponse, TripState, CustomMapServiceFactory, MapServiceFactory,
    SetTripStopRequest, SetTripStopResponse, GetTripStopResponse, TripStopTypeCode, LoggerReadResponse
} from 'emr-ng-shared';

import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { ITrackerSummaryState } from 'app-modules/core/store/models/tracker-summary-state.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import { ITimePeriod } from '../store/models/time-period.interface';
import { ITrackerStateReport } from 'app-modules/core/store/models/tracker-state-report.interface';
import { ITrackerStateChart } from 'app-modules/core/store/models/tracker-state-chart.interface';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import { environment } from 'environments/environment';
import { BusinessRulesService } from '../store/business-rules/business-rules.service';
import { handleErrorResponse } from '../rxjs/operators/handle-error-response.operator';
import { IErrorInfo } from '../models/error-info.interface';
import { ImageMapService } from './image-map.service';
import { TripStopModel } from '../store/models/trip-stop.model';

@Injectable()
export class ShipmentDetailService implements OnDestroy {

    constructor(
        private oversightSvc: OversightApiService,
        private detailStateSvc: ShipmentDetailStateService,
        // private alertStateSvc: AlertStateService,
        private utilSvc: EmrUtilService,
        private businessRuleService: BusinessRulesService,
        private imgMapSvc: ImageMapService,
        @Inject(MapServiceFactory) private mapSvc: CustomMapServiceFactory
    ) { }

    ngOnDestroy() {
        if (this.selectShipmentSubscription) {
            this.selectShipmentSubscription.unsubscribe();
        }
        if (this.bussinessRuleSubscription) {
            this.bussinessRuleSubscription.unsubscribe();
        }
    }

    public selectedShipment$ = this.detailStateSvc.selectedShipment$;
    private selectShipmentSubscription: Subscription;
    private bussinessRuleSubscription: Subscription;

    private getTraxxRequestSub = new BehaviorSubject<GetTraxxRequest>(null);
    private getTraxxRequest$ = this.getTraxxRequestSub.asObservable();
    private SensorChartClickSub = new BehaviorSubject<any>(null);
    public sensorChartClick$ = this.SensorChartClickSub.asObservable();

    private lastTraxxRequest: GetTraxxRequest;

    private lastTraxxStateRespSub = new BehaviorSubject<ITraxxState>(null);
    private lastTraxxStateResponse$: Observable<ITraxxState>;

    public getTraxx$ = this.getTraxx();
    public isTraxxLoading$ = this.detailStateSvc.isTraxxLoading$;
    public isTimeLineLoading$ = this.detailStateSvc.isTimeLineLoading$;
    public isHistoricalLoading$ = this.detailStateSvc.isHistoricalLoading$;

    public alertList$: Observable<IListInfo<AlertStatus>> = muteFirst(
        this.getAlertListLoader().pipe(startWith(null)),
        this.detailStateSvc.alertList$
    );

    public alertListNoPage$: Observable<IListInfo<AlertStatus>> = this.detailStateSvc.alertList$;

    public alertListPaging$ = this.detailStateSvc.alertListPaging$;
    public alertListPage$: Observable<IListInfo<AlertStatus>> = this.detailStateSvc.alertListPage$;

    // Commented as we will be using alert data from GetShipmentData API call
    // public alertListPage$: Observable<IListInfo<AlertStatus>> = muteFirst(
    //     this.getAlertListLoader().pipe(startWith(null)),
    //     this.detailStateSvc.alertListPage$
    // );

    public historicalShipmentList$: Observable<IListInfo<TrackerHistory>> = muteFirst(
        this.getHistoricalShipmentListLoader().pipe(startWith(null)),
        this.detailStateSvc.fullShipmentsList$
    );
    public historicalShipmentListPaging$ = this.detailStateSvc.historicalShipmentListPaging$;

    public historicalShipmentListPage$: Observable<IListInfo<TrackerHistory>> = this.detailStateSvc.historicalShipmentListPage$;

    public tracker$: Observable<ITrackerSummaryState> = muteFirst(
        this.getTrackerLoader().pipe(startWith(null)),
        this.detailStateSvc.tracker$
    );

    public trackerSummary$: Observable<ShipmentInfo> = muteFirst(
        this.tracker$.pipe(startWith(null)),
        this.detailStateSvc.trackerSummary$
    );

    public trackerAlertInfo$: Observable<IListInfo<AlertStatus>> = muteFirst(
        this.tracker$.pipe(startWith(null)),
        this.detailStateSvc.trackerAlertInfo$
    );

    public trackerChartData$: Observable<IListInfo<ChartData>> = muteFirst(
        this.tracker$.pipe(startWith(null)),
        this.detailStateSvc.trackerChartData$
    );

    public timePeriodList$: Observable<IListInfo<TimePeriodInfo>> = muteFirst(
        this.getShipmentDetailTimePeriodListLoader().pipe(startWith(null)),
        this.detailStateSvc.timePeriodList$
    );

    public trackerStateReport$: Observable<ITrackerStateReport> = muteFirst(
        this.getTrackerStateReportLoader().pipe(startWith(null)),
        this.detailStateSvc.trackerStateReport$
    );

    public trackerStateReportSummary$: Observable<ShipmentInfo> = muteFirst(
        this.trackerStateReport$.pipe(startWith(null)),
        this.detailStateSvc.trackerStateReportSummary$
    );

    public trackerStateReportChartData$: Observable<IListInfo<ChartData>> = muteFirst(
        this.trackerStateReport$.pipe(startWith(null)),
        this.detailStateSvc.trackerStateReportChartData$
    );

    public getShipment$: Observable<ITrackerStateChart> = muteFirst(
        this.getTrackerStateChartLoader().pipe(startWith(null)),
        this.detailStateSvc.trackerStateChart$
    );

    public getUnAuthenticationToken(): boolean {
        return this.oversightSvc.getUnAuthenticationToken();
    }

    public changeTrackerStateChartTimePeriod(timePeriod: ITimePeriod) {
        this.detailStateSvc.changeTrackerStateChartTimePeriod(timePeriod);
    }

    public selectShipment(shipment: Shipment) {
        this.detailStateSvc.selectShipment(shipment);
    }

    public resetSelectedShipment() {
        this.detailStateSvc.resetSelectedShipment();
    }

    public changeAlertListPage(pageNumber: number) {
        this.detailStateSvc.changeAlertListPage(pageNumber);
    }

    public changeAlertListPageSize(pageSize: number) {
        this.detailStateSvc.changeAlertListPageSize(pageSize);
    }

    public changeHistoricalShipmentListPage(pageNumber: number) {
        this.detailStateSvc.changeHistoricalShipmentListPage(pageNumber);
    }

    public changeHistoricalShipmentListPageSize(pageSize: number) {
        this.detailStateSvc.changeHistoricalShipmentListPageSize(pageSize);
    }

    private getAlertListLoader(): Observable<IListInfo<AlertStatus>> {
        return this.detailStateSvc.isAlertListLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.detailStateSvc.loadAlertList()),
            switchMap(() => this.getAlertList()),
            tap(
                n => this.detailStateSvc.loadAlertListSuccess(n),
                e => this.detailStateSvc.loadAlertListError(e)
            ),
            finalize(() => this.detailStateSvc.cancelLoadAlertList()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getAlertList(): Observable<IListInfo<AlertStatus>> {
        return this.detailStateSvc.selectedShipment$.pipe(
            switchMap(n => {
                const request = new GetAlertStatusRequest();
                request.CustomerTrackerId = n.customerTrackerId;
                return this.oversightSvc.GetAlertStatus(request);
            }),
            map(n => {
                let alertList: IListInfo<AlertStatus> = emptyList();
                if (n.AlertStatusList) {
                    alertList = {
                        list: n.AlertStatusList,
                        itemCount: n.AlertStatusList.length,
                        isPaged: false
                    };
                }

                return alertList;
            })
        );
    }

    private getHistoricalShipmentListLoader(): Observable<IListInfo<TrackerHistory>> {
        return this.getHistoricalShipmentList().pipe(
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getHistoricalShipmentList(): Observable<IListInfo<TrackerHistory>> {
        return this.detailStateSvc.selectedShipment$.pipe(
            filter(n => n.isMultiTrip),
            distinctUntilChanged((a, b) => (!a) || (a.trackerId === b.trackerId && a.customerTrackerId === b.customerTrackerId)),
            tap(() => this.detailStateSvc.loadHistoricalShipmentList()),
            switchMap(n => {
                const request = new ShipmentRequest();
                request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
                request.DeviceId = n.customerTrackerId;
                request.GlobalDeviceId = n.trackerId;
                request.IsIncludingHistory = true;
                request.IsIncludingCurrentTrip = true;
                return this.oversightSvc.GetHistoricalShipmentList(request);
            }),
            map(n => {
                let historicalShipmentList: IListInfo<TrackerHistory> = emptyList();
                if (n.TrackerHistory) {
                    historicalShipmentList = {
                        list: n.TrackerHistory,
                        itemCount: n.TrackerHistory.length,
                        isPaged: false
                    };
                }
                return historicalShipmentList;
            }),
            tap(
                n => this.detailStateSvc.loadHistoricalShipmentListSuccess(n),
                e => this.detailStateSvc.loadHistoricalShipmentListError(e)
            ),
            finalize(() => this.detailStateSvc.cancelLoadHistoricalShipmentList())
        );
    }

    private getTrackerLoader(): Observable<ITrackerSummaryState> {
        return this.detailStateSvc.isTrackerLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.detailStateSvc.loadTracker()),
            switchMap(() => this.getTracker()),
            tap(
                n => this.detailStateSvc.loadTrackerSuccess(n.summary, n.alertInfo, n.chartData, n.chartDataInfo),
                e => this.detailStateSvc.loadTrackerError(e)
            ),
            finalize(() => this.detailStateSvc.cancelLoadTracker()),
            catchError(() => of({} as ITrackerSummaryState)),
            share()
        );
    }

    private getTracker(): Observable<ITrackerSummaryState> {
        return this.detailStateSvc.selectedShipment$.pipe(
            switchMap(n => {
                const request = new GetShipmentDetailsRequest();
                request.TrackerId = n.trackerId;
                request.CustomerTrackerId = n.customerTrackerId;
                return this.oversightSvc.GetShipmentDetails(request);
            }),
            map(n => {
                let summary = {};
                if (n.Summary) {
                    summary = n.Summary;
                }
                let alertInfo = emptyList();
                if (n.AlertInfo) {
                    alertInfo = {
                        list: n.AlertInfo,
                        itemCount: n.AlertInfo.length,
                        isPaged: false
                    };
                }
                let chartData = emptyList();
                if (n.Data) {
                    chartData = {
                        list: n.Data,
                        itemCount: n.Data.length,
                        isPaged: false
                    };
                }
                let chartDataInfo = {};
                if (n.DataInfo) {
                    chartDataInfo = n.DataInfo;
                }
                return {
                    summary,
                    alertInfo,
                    chartData,
                    chartDataInfo
                } as ITrackerSummaryState;
            })
        );
    }

    private getTrackerStateReportLoader(): Observable<ITrackerStateReport> {
        return this.detailStateSvc.isTrackerStateReportLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.detailStateSvc.loadTrackerStateReport()),
            switchMap(() => this.getTrackerStateReport()),
            tap(
                n => this.detailStateSvc.loadTrackerStateReportSuccess(n.summary, n.chartData),
                e => this.detailStateSvc.loadTrackerStateReportError(e)
            ),
            finalize(() => this.detailStateSvc.cancelLoadTrackerStateReport()),
            catchError(() => of({} as ITrackerStateReport)),
            share()
        );
    }

    private getTrackerStateReport(): Observable<ITrackerStateReport> {
        return this.detailStateSvc.selectedShipment$.pipe(
            switchMap(n => {
                const request = new GetTrackerStateReportRequest();
                const sensor = n.SensorListInfo[0];
                request.CustomerTrackerId = n.customerTrackerId;
                request.Type = sensor.Type;
                request.TagId = sensor.TagId;
                request.Pin = sensor.Pin;
                request.GraphData = true;
                request.ShowLuxAlerts = true;
                return this.oversightSvc.GetTrackerStateReport(request);
            }),
            map(n => {
                let summary = {};
                if (n.Summary) {
                    summary = n.Summary;
                }
                let chartData = emptyList();
                if (n.Data) {
                    chartData = {
                        list: n.Data,
                        itemCount: n.Data.length,
                        isPaged: false
                    };
                }

                return {
                    summary,
                    chartData
                } as ITrackerStateReport;
            })
        );
    }

    private getTrackerStateChartLoader(): Observable<ITrackerStateChart> {
        return this.detailStateSvc.isTrackerStateChartLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.detailStateSvc.loadTrackerStateChart()),
            withLatestFrom(this.detailStateSvc.tsChartSelectedTimePeriod$, (a, b) => b),
            switchMap((a) => this.getTrackerStateChart(a)),
            tap(
                n => this.detailStateSvc.loadTrackerStateChartSuccess(n.summary, n.chartData),
                e => this.detailStateSvc.loadTrackerStateChartError(e)
            ),
            finalize(() => this.detailStateSvc.cancelLoadTrackerStateChart()),
            catchError(() => of({} as ITrackerStateChart)),
            share()
        );
    }

    private getTrackerStateChart(tp: ITimePeriod): Observable<ITrackerStateChart> {
        return this.detailStateSvc.selectedShipment$.pipe(
            switchMap(n => {
                const request = new GetShipmentRequest();
                const sensor = n.SensorListInfo[0];
                request.CustomerTrackerId = n.customerTrackerId;
                request.TripId = n.tripId;
                request.GraphData = true;
                request.TagId = sensor.TagId;
                request.Pin = sensor.Pin;
                request.Period = tp.isDefaultSelection ? (n.tripId ? TimePeriod.Trip : TimePeriod.All) : tp.timePeriod;
                if (tp.timePeriod === TimePeriod.SpecificRange) {
                    request.From =
                        this.utilSvc.DateFormatLocaleChange(tp.dateRange.startDateTime);
                    request.Thru =
                        this.utilSvc.DateFormatLocaleChange(tp.dateRange.endDateTime);
                } else {
                    request.From = null;
                    request.Thru = null;
                }
                // request.Period = TimePeriod.SpecificRange;
                // request.From = '2019-03-21T21:11:19Z';
                // request.Thru = '2019-03-22T21:45:41Z';
                return this.oversightSvc.GetShipment(request);
            }),
            map(n => {
                let summary = {};
                if (n.Summary) {
                    summary = n.Summary;
                }
                let chartData = emptyList();
                if (n.Data) {
                    chartData = {
                        list: n.Data,
                        itemCount: n.Data.length,
                        isPaged: false
                    };
                }

                return { summary, chartData } as ITrackerStateChart;
            })
        );
    }

    public updateDateRange(dateRange: DateRange | null = null) {
        this.selectShipmentSubscription = this.detailStateSvc.selectedShipment$.pipe(
            tap(n => this.detailStateSvc.updateRange(dateRange))
        ).pipe(take(1)).subscribe();
    }
    /*
        public getTraxxLoader(timePeriod: ITimePeriod): Observable<ITraxxState> {
            return this.detailStateSvc.selectedShipment$.pipe(
                tap(n => this.detailStateSvc.loadTraxx()),
                switchMap(() => this.getTraxx(timePeriod)),
                tap(
                    n => this.detailStateSvc.loadTraxxSuccess(n.traxx),
                    e => this.detailStateSvc.loadTraxxError(e)
                ),
                finalize(() => this.detailStateSvc.cancelLoadTraxx()),
                catchError(() => of({
                    traxx: emptyList(),
                    dateRange: new DateRange(),
                    isLoadRequired: true,
                    isLoading: false,
                    errorMessage: 'An unexpected error occured'
                })),
                share()
            );
        }*/

    public getLatestTraxxPeriod(timePeriod: IDateTimeFilterPeriod): IDateTimeFilterPeriod {
        if (this.lastTraxxRequest && timePeriod && this.lastTraxxRequest.Period !== timePeriod.timePeriod &&
            this.lastTraxxRequest.BeginTimestamp !== this.utilSvc.DateFormatLocaleChange(timePeriod.dateRange.startDateTime) &&
            this.lastTraxxRequest.EndTimestamp !== this.utilSvc.DateFormatLocaleChange(timePeriod.dateRange.endDateTime)) {
            return {
                timePeriod: this.lastTraxxRequest.Period,
                dateRange: {
                    startDateTime: new Date(Date.parse(this.lastTraxxRequest.BeginTimestamp)),
                    endDateTime: new Date(Date.parse(this.lastTraxxRequest.EndTimestamp))
                }
            };
        }
        return timePeriod;

    }

    public getTraxxRequest(
        clearData: boolean, shipment?: Shipment, timePeriod?: ITimePeriod, ShowEstimatedCrumb?: boolean,
        list?: TrackerHistory[], shipmentRequest?: ShipmentRequest, isDefaultToLast14Days: boolean = false,
        fromDate: string = null, thruDate: string = null, isRepairingCrumbs: boolean = null) {
        // Clean the old response
        if (clearData) {
            this.getTraxxRequestSub.next(null);
            return;
        }

        // Validate if the request same as old data

        const tripId = shipment ? shipment.tripId : null;
        const trackerId = shipment ? shipment.trackerId : null;
        const customerTrackerId = shipment ? shipment.customerTrackerId : null;
        const modelName = shipment ? shipment.ModelName : null;
        const modelNumber = shipment ? shipment.ModelNumber : null;
        const is4G = shipment ? shipment.Is4G : null;

        if (this.lastTraxxRequest &&
            this.lastTraxxRequest.TripId === tripId &&
            this.lastTraxxRequest.TrackerId === trackerId &&
            this.lastTraxxRequest.CustomerTrackerId === customerTrackerId &&
            this.lastTraxxRequest.IsEstimatingCrumbs === ShowEstimatedCrumb &&
            (!shipmentRequest || shipmentRequest.Period !== TimePeriod.SpecificRange ||
                (this.lastTraxxRequest.Period === shipmentRequest.Period &&
                    this.lastTraxxRequest.BeginTimestamp === shipmentRequest.From &&
                    this.lastTraxxRequest.EndTimestamp === shipmentRequest.Thru))
            && (this.lastTraxxRequest.Period === TimePeriod.SpecificRange &&
                this.lastTraxxRequest.BeginTimestamp === fromDate &&
                this.lastTraxxRequest.EndTimestamp === thruDate)) {
            return;
        }

        // Create a new request
        const request = new GetTraxxRequest();
        request.IsRepairingCrumbs = isRepairingCrumbs;
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.TripId = tripId;
        request.TrackerId = trackerId;
        request.CustomerTrackerId = customerTrackerId;
        request.ModelName = modelName;
        request.ModelNumber = modelNumber;
        request.Is4G = is4G;
        request.IsEstimatingCrumbs = ShowEstimatedCrumb;
        request.IsNew = true;
        request.IsMultiTrip = shipment.isMultiTrip;
        request.TripState = shipment.tripStateCode;
        if (tripId) {
            request.ActualStartDtm = EmrUtilService.parseDateWithoutTimezone(shipment.ActualStartTime);

            request.ScheduledStartDtm = EmrUtilService.parseDateWithoutTimezone(shipment.ScheduledStartTime);
            request.ScheduledEndDtm = EmrUtilService.parseDateWithoutTimezone(shipment.ScheduledEndTime);

            if (request.TripState === TripState.Completed) {
                request.ActualEndDtm = EmrUtilService.parseDateWithoutTimezone(shipment.ActualEndTime);
            }
        }
        if (shipmentRequest) {
            if (shipmentRequest.Period === TimePeriod.SpecificRange) {
                request.Period = shipmentRequest.Period;
                request.BeginTimestamp = shipmentRequest.From;
                request.EndTimestamp = shipmentRequest.Thru;
                request.From = EmrUtilService.parseDateWithoutTimezone(shipmentRequest.From);
                request.Thru = EmrUtilService.parseDateWithoutTimezone(shipmentRequest.Thru);
            }
            if (shipment.isMultiTrip) {
                request.PreviousTripEndDtm = shipmentRequest.PreviousTripEndDtm;
                request.FutureTripEndDtm = shipmentRequest.FutureTripEndDtm;
            }
            request.TripState = shipmentRequest.TripState;
            request.ActualStartDtm = shipmentRequest.ActualStartDtm;
            request.ActualEndDtm = shipmentRequest.ActualEndDtm;
            request.ScheduledStartDtm = shipmentRequest.ScheduledStartDtm;
            request.ScheduledEndDtm = shipmentRequest.ScheduledEndDtm;
            request.LastReportedDtm = shipmentRequest.LastReportedDtm;
            request.IsDefaultToLast14Days = shipmentRequest.IsDefaultToLast14Days;
        } else if (list && list.length > 0 && shipment.isMultiTrip) {
            // If we comes from shipment route page then we will get Historical Shipment List from there for multi trip
            const sortedShipmentList = list.sort((a, b) => b.TripId - a.TripId);
            const index = sortedShipmentList.map(x => x.TripId).indexOf(tripId);
            if (index + 1 < sortedShipmentList.length) {
                request.PreviousTripEndDtm = sortedShipmentList[index + 1].ActualEndTime;
            }
            if (index > 0) {
                request.FutureTripEndDtm = sortedShipmentList[index - 1].ActualEndTime;
            }
            if (index >= 0) {
                switch (request.TripState) {
                    case TripState.InProgress:
                        request.ActualStartDtm = sortedShipmentList[index].ActualStartTime;
                        break;
                    case TripState.Completed:
                        request.ActualStartDtm = sortedShipmentList[index].ActualStartTime;
                        request.ActualEndDtm = sortedShipmentList[index].ActualEndTime;
                        break;
                }
            }
        }
        if (!shipmentRequest) {
            request.LastReportedDtm = EmrUtilService.parseDateWithoutTimezone(shipment.LastReportedTimestamp);
            request.IsDefaultToLast14Days = isDefaultToLast14Days;
        }

        if (fromDate && thruDate) {
            request.Period = TimePeriod.SpecificRange;
            request.BeginTimestamp = fromDate;
            request.EndTimestamp = thruDate;
            request.From = EmrUtilService.parseDateWithoutTimezone(fromDate);
            request.Thru = EmrUtilService.parseDateWithoutTimezone(thruDate);
        }

        this.getTraxxRequestSub.next(request);
    }

    public initDefaultTraxxRequest(
        shipment: Shipment, ShowEstimatedCrumb: boolean = null, list?: TrackerHistory[], request?: ShipmentRequest,
        fromDate: string = null, thruDate: string = null) {
        if (ShowEstimatedCrumb === null) {
            const data = localStorage.getItem(environment.showEstimatedCrumbs);
            if (data !== null) {
                ShowEstimatedCrumb = (data === 'true');
            }
        }
        this.bussinessRuleSubscription = this.businessRuleService.businessRulesList$.pipe(take(1)).subscribe(k => {
            const businessRules = k.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.ShowEstimatedCrumbs);
            let EstimatedCrumbRule = false;
            if (businessRules) {
                EstimatedCrumbRule = businessRules.Enabled;
                if (localStorage.getItem(environment.showEstimatedCrumbs) === null) {
                    localStorage.setItem(environment.showEstimatedCrumbs, 'true');
                }
            }
            const last14DaysRule = k.list.find(br => br.BusinessRuleTypeCode === BusinessRuleType.DefaultToLast14Days)?.Enabled;

            const isRepairCrumbBizRule = k.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.EnableRepairRogueCrumbs)?.Enabled;
            this.getTraxxRequest(null, shipment, null, EstimatedCrumbRule ? !!ShowEstimatedCrumb : false, list, request, last14DaysRule, fromDate, thruDate, isRepairCrumbBizRule);
        });
    }

    private getTraxx(): Observable<ITraxxState> {
        return this.getTraxxRequest$.pipe(
            distinctUntilChanged(),
            tap(req => this.lastTraxxRequest = req),
            filter(req => !!req),
            tap(n => this.detailStateSvc.loadTraxx()),
            switchMap(n => this.oversightSvc.GetTraxx(n)),
            map(n => {
                const traxxInfo: ITraxxState = {
                    traxx: emptyList(),
                    SelectedPeriodRange: n.SelectedPeriodRange,
                    dateRange: new DateRange(),
                    isLoadRequired: true,
                    isLoading: false,
                    IsXL: false,
                    errorMessage: null
                };
                // let traxx: IListInfo<TraxxInfo> = emptyList();
                if (n.TraxxInfoList) {
                    traxxInfo.traxx = {
                        list: n.TraxxInfoList,
                        itemCount: n.TraxxInfoList.length,
                        isPaged: false
                    };
                    traxxInfo.AlertTotals = n.AlertTotals;
                    if (traxxInfo.AlertTotals?.AlertCounts?.length > 0) {
                        traxxInfo.AlertTotals.AlertCounts
                            .forEach(k => k.CalcImageURL = this.imgMapSvc.getImageURL(k.ImageId, k.ImageUrl, null));
                    }
                }
                traxxInfo.extraTraxx = n.ExtraTraxxInfoList;
                traxxInfo.errorMessage = n.LocalizedErrorMessage;
                traxxInfo.IsXL = n.IsXL;
                return traxxInfo;
            }),
            tap(
                n => {
                    this.detailStateSvc.loadTraxxSuccess(n.traxx);
                    this.lastTraxxStateRespSub.next(n);
                },
                e => {
                    this.detailStateSvc.loadTraxxError(e);
                    this.lastTraxxStateRespSub.next(null);
                }
            ),
            finalize(() => {
                this.detailStateSvc.cancelLoadTraxx();
                this.lastTraxxStateRespSub.next(null);
            }),
            // catchError(() => {
            //     traxx: emptyList(),
            //     dateRange: new DateRange(),
            //     isLoadRequired: true,
            //     isLoading: false,
            //     errorMessage: 'An unexpected error occured'
            // } as ITraxxState),
            share()
        );
    }

    MarkBreadCrumb(req: BreadCrumbFlagRequest): Observable<BreadCrumbFlagResponse> {
        return of(null).pipe(
            switchMap(() => this.updateBreadCrumbStatus(req)),
            handleErrorResponse()
        );
    }

    private updateBreadCrumbStatus(request: BreadCrumbFlagRequest): Observable<BreadCrumbFlagResponse> {
        return this.oversightSvc.MarkBreadCrumb(request);
    }

    public getTimelineLoader(): Observable<IListInfo<TimelineInfo>> {
        return this.detailStateSvc.selectedShipment$.pipe(
            switchMap(n => this.getTimelineList(n)),
            finalize(() => this.detailStateSvc.cancelLoadTimeline()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    public getTimelineList(shipment: Shipment): Observable<IListInfo<TimelineInfo>> {
        return of(null).pipe(
            tap(() => this.detailStateSvc.loadTimeline()),
            switchMap(() => this.getTimeline(shipment)),
            tap(
                n => this.detailStateSvc.loadTimelineSuccess(n),
                e => this.detailStateSvc.loadTimelineError(e)
            ),
            finalize(() => this.detailStateSvc.cancelLoadTimeline()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getTimeline(shipment: Shipment): Observable<IListInfo<TimelineInfo>> {
        const request = new GetTimelineRequest();
        request.TripId = shipment.tripId;
        request.TrackerId = shipment.trackerId;
        request.CustomerTrackerId = shipment.customerTrackerId;

        return this.oversightSvc.GetTimeline(request).pipe(
            map(n => {
                let timeline: IListInfo<TimelineInfo> = emptyList();
                if (n.Timelines) {
                    timeline = {
                        list: n.Timelines,
                        itemCount: n.Timelines.length,
                        isPaged: false
                    };
                }
                return timeline;
            })
        );
    }

    private getShipmentDetailTimePeriodListLoader(): Observable<IListInfo<TimePeriodInfo>> {
        return this.detailStateSvc.isTimePeriodLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.detailStateSvc.loadTimePeriods()),
            withLatestFrom(this.detailStateSvc.selectedShipment$, (a, b) => b),
            switchMap(a => this.getShipmentDetailTimePeriodList(a)),
            tap(
                n => this.detailStateSvc.loadTimePeriodsSuccess(n),
                e => this.detailStateSvc.loadTimePeriodsError('')
            ),
            finalize(() => this.detailStateSvc.cancelLoadTimePeriods()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getShipmentDetailTimePeriodList(shipment: Shipment): Observable<IListInfo<TimePeriodInfo>> {
        const request = new GetTimePeriodsRequest();

        // only include CurrentShipment/Trip when there is a valid TripId
        if (shipment.tripId) {
            request.RequestedTimePeriods = [
                TimePeriod.All,
                TimePeriod.Trip,
                TimePeriod.LastTwoWeeks,
                TimePeriod.Last30Days,
                TimePeriod.ThisYear,
                TimePeriod.SpecificRange
            ];
        } else {
            request.RequestedTimePeriods = [
                TimePeriod.All,
                TimePeriod.LastTwoWeeks,
                TimePeriod.Last30Days,
                TimePeriod.ThisYear,
                TimePeriod.SpecificRange
            ];
        }

        return this.oversightSvc.GetTimePeriods(request).pipe(
            map(n => {
                return {
                    list: n.TimePeriodList,
                    itemCount: n.TimePeriodList.length,
                    isPaged: false
                };
            })
        );
    }

    public exportTrackerStateReport(queryParams: ExportTrackerStateReportRequest, extraHeaders: ExportTrackerStateReportHeaders) {
        return this.oversightSvc.ExportTrackerStateReport(queryParams, extraHeaders);
    }

    public GetReverseGeoCode(coordinate: Coordinate): Observable<GetTrackerStatusResponse> {
        const request = new GetReverseGeocodeAddressRequest();
        request.Coordinates = [coordinate];
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        return this.oversightSvc.GetReverseGeoCode(request).pipe(
            map(n => {
                return n;
            })
        );
    }

    public sensorChartClick(data) {
        this.SensorChartClickSub.next(data);
        // this.sensorChartClickedData = data;
    }

    public UpdateTripStops(request: SetTripStopRequest): Observable<SetTripStopResponse> {
        return of(request).pipe(
            tap(_ => this.detailStateSvc.loadExportTrackerStateReport()),
            switchMap(n => this.oversightSvc.UpdateTripStops(n)),
            map(n => {
                this.detailStateSvc.loadExportTrackerStateReportSuccess();
                return n;
            }),
            finalize(() => this.detailStateSvc.loadExportTrackerStateReportSuccess())
        );
    }

    public GetTripStops(TripId: number): Observable<GetTripStopResponse> {
        return this.oversightSvc.GetTripStops(TripId);
    }

    public getUpdatedTripStops(tripStops: TripStopModel[], tripStopsCopy: TripStopModel[]) {
        const updatedStops: TripStopModel[] = [];
        // Loop for trip stops
        tripStops.map(k => {
            if (!k.IsEdit) {
                k.IsEdit = true;
            }
            // Checking that stop already existed or not in DB
            const dbLocation = tripStopsCopy.find(a => a.Index === k.Index);
            if (!k.AirportDeparture) {
                k.TripStopTypeCode = 0;
            }
            //If that stop doesn't exist in DB
            if (!dbLocation) {
                // Adding that stop to stop list
                updatedStops.push({ ...k, IsAdd: true, TripStopTypeCode: k.AirportDeparture ? TripStopTypeCode.AirportDeparture : 0 })
                if (k.AirportDeparture) {
                    //If stop is airport departure then adding arrival to stop list
                    updatedStops.push({ ...k, LocationId: k.ArrivalLocationId, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportArrival })
                }
            }
            // if stop is already existed in DB
            else {
                //If DB stop and stop list ite location both are different
                if (dbLocation.LocationId !== k.LocationId) {
                    if (dbLocation.LocationId) {
                        // Adding that location to stop list to delete
                        updatedStops.push({ ...dbLocation, IsDelete: true });
                    }
                    if (dbLocation.AirportDeparture) {
                        // If Stop list item is airport departure
                        if (k.AirportDeparture) {
                            //Adding stop list item
                            updatedStops.push({ ...k, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportDeparture });
                            // If both DB and stop list item are different Arrival locations
                            if (k.ArrivalLocationId !== dbLocation.ArrivalLocationId) {
                                if (dbLocation.ArrivalLocationId) {
                                    //Deleting that arrival location 
                                    updatedStops.push({ ...dbLocation, LocationId: dbLocation.ArrivalLocationId, IsDelete: true });
                                }
                                // Adding new arrival location
                                updatedStops.push({ ...k, LocationId: k.ArrivalLocationId, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportArrival });
                            }
                        } else {
                            //Deleting arrival location
                            updatedStops.push({ ...dbLocation, LocationId: dbLocation.ArrivalLocationId, IsDelete: true });
                            //Adding normal location
                            updatedStops.push({ ...k, IsAdd: true, TripStopTypeCode: 0 });
                        }
                    }
                    // If DB location is not airport departure
                    else {
                        // If stop list item is airport departure
                        if (k.AirportDeparture) {
                            //Adding both arrival and departure
                            updatedStops.push({ ...k, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportDeparture });
                            updatedStops.push({ ...k, LocationId: k.ArrivalLocationId, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportArrival });
                        } else {
                            // Adding that location
                            updatedStops.push({ ...k, IsAdd: true, TripStopTypeCode: 0 });
                        }
                    }
                } else {
                    // If db is airport departure and stop list item is not airport departure
                    if (dbLocation.AirportDeparture && !k.AirportDeparture) {
                        updatedStops.push({ ...k, IsDelete: true });
                        updatedStops.push({ ...k, LocationId: dbLocation.ArrivalLocationId, IsDelete: true });
                        updatedStops.push({ ...k, IsAdd: true, TripStopTypeCode: 0 });
                    }
                    // If db is not airport departure and stop list item is airport departure
                    else if (!dbLocation.AirportDeparture && k.AirportDeparture) {
                        updatedStops.push({ ...k, IsDelete: true });
                        updatedStops.push({ ...k, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportDeparture });
                        updatedStops.push({ ...k, LocationId: k.ArrivalLocationId, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportArrival });
                    } else if (dbLocation.AirportDeparture && k.AirportDeparture) {
                        if (k.ArrivalLocationId !== dbLocation.ArrivalLocationId) {
                            if (dbLocation.ArrivalLocationId) {
                                //Deleting that arrival location 
                                updatedStops.push({ ...dbLocation, LocationId: dbLocation.ArrivalLocationId, IsDelete: true });
                            }
                            // Adding new arrival location
                            updatedStops.push({ ...k, LocationId: k.ArrivalLocationId, IsAdd: true, TripStopTypeCode: TripStopTypeCode.AirportArrival });
                        }
                    }
                }
            }
        })
        // Checking is there any deleted stops which are not existed in trip list
        tripStopsCopy.map(a => {
            if (!tripStops.find(k => k.Index === a.Index)) {
                updatedStops.push({ ...a, IsDelete: true });
                if (a.AirportDeparture) {
                    updatedStops.push({ ...a, LocationId: a.ArrivalLocationId, IsDelete: true });
                }
            }
        });
        // Calculating max sequence number from already available list
        let sequenceId = Math.max.apply(Math, tripStopsCopy.map(function (o) { return o.SequenceID; }));
        if (isNaN(sequenceId)) {
            sequenceId = 0;
        }
        // updating the sequence 
        updatedStops.filter(k => k.IsAdd).map((a, i) => { if (!a.SequenceID) { a.SequenceID = sequenceId + 1 + i } });
        return updatedStops;
    }

    public getAlertsBetweenSelectedDates(alerts: AlertStatus[], selectedDateRange: DateRange): AlertStatus[] {
        let list = alerts;
        if (selectedDateRange) {
            const toDate = new Date(selectedDateRange.endDateTime);
            const to = toDate.setSeconds(59);
            const from = new Date(selectedDateRange.startDateTime).getTime();
            list = alerts.filter(k => {
                if ((!(selectedDateRange && selectedDateRange.startDateTime && selectedDateRange.endDateTime) ||
                    (k.SatTime &&
                        this.checkDateBetweenGivenDates(k.SatTime, from, to)))) {
                    return k;
                }
            });
        }
        return list;
    }


    private checkDateBetweenGivenDates(inputDate, from, to) {
        if (new Date(inputDate).getTime() >= from &&
            new Date(inputDate).getTime() <= to) {
            return true;
        } else { return false; }
    }

    public getLoggerTagsListLoader(deviceSerial: string): Observable<LoggerReadResponse> {
        return this.getLoggerTagsList(deviceSerial).pipe(
            catchError(() => of(null)),
            share()
        );
    }

    private getLoggerTagsList(deviceSerial: string): Observable<LoggerReadResponse> {
        return this.oversightSvc.GetLoggerReads(deviceSerial).pipe(
            map(n => {
                return n;
            })
        );
    }
}
