import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as fltrActions from 'app-modules/core/store/shipment-filter/shipment-filter.actions';
import { TimePeriod } from 'emr-ng-shared';

type Actions =
    authActions.SignOut |
    fltrActions.CustomChangeShipmentFilter |
    fltrActions.ChangeShipmentFilter |
    fltrActions.ChangeShipmentFilterWithOutShipmentListUpdate |
    fltrActions.UpdateIsDefaultFilterState;

export function ShipmentFilterReducer(
    state: IShipmentFilterState = initialState.shipmentFilter,
    action: Actions
): IShipmentFilterState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentFilter;

        case actionIndex.CUSTOM_CHANGE_SHIPMENT_FILTER:
            return {
                ...state,
                serialNumber: null,
                shipmentName: null,
                quickSearch: null
            };

        case actionIndex.UPDATE_IS_DEFAULT_SHIPMENT_FILTER:
            return {
                ...state,
                isDefaultState: action.isDefault
            };

        case actionIndex.CHANGE_SHIPMENT_FILTER:
        case actionIndex.CHANGE_SHIPMENT_FILTER_WITH_OUT_SHIPMENT_LIST_UPDATE:
            return {
                serialNumber: action.filter.serialNumber ? action.filter.serialNumber.trim() : null,
                shipmentName: action.filter.shipmentName ? action.filter.shipmentName.trim() : null,
                status: action.filter.status && action.filter.status.length > 0 ? action.filter.status : null,
                period: {
                    isDefaultSelection: true,
                    timePeriod: action.filter.period.timePeriod,
                    dateRange: {
                        startDateTime: action.filter.period.dateRange.startDateTime,
                        endDateTime: action.filter.period.dateRange.endDateTime
                    }
                },
                alert: {
                    aboveTemp: action.filter.alert.aboveTemp,
                    belowTemp: action.filter.alert.belowTemp,
                    late: action.filter.alert.late,
                    moving: action.filter.alert.moving,
                    notMoving: action.filter.alert.notMoving
                },
                origin: action.filter.origin && action.filter.origin.length > 0 ? action.filter.origin : null,
                destination: action.filter.destination && action.filter.destination.length > 0 ? action.filter.destination : null,
                sensorRange: action.filter.sensorRange && action.filter.sensorRange.length > 0 ? action.filter.sensorRange : null,
                carrier: action.filter.carrier && action.filter.carrier.length > 0 ? action.filter.carrier : null,
                quickSearch: action.filter.quickSearch ? action.filter.quickSearch.trim() : null,
                isDefaultState: action.filter.isDefaultState,
                IsUserDefaultFilter: action.filter.IsUserDefaultFilter,
                IsHistorical: action.filter.IsHistorical,
                originCity: action.filter?.originCity?.length > 0 ? action.filter.originCity : null,
                originState: action.filter?.originState?.length > 0 ? action.filter.originState : null,
                originCountry: action.filter?.originCountry?.length > 0 ? action.filter.originCountry : null,
                originZip: action.filter?.originZip?.length > 0 ? action.filter.originZip : null,
                destinationCity: action.filter?.destinationCity?.length > 0 ? action.filter.destinationCity : null,
                destinationState: action.filter?.destinationState?.length > 0 ? action.filter.destinationState : null,
                destinationCountry: action.filter?.destinationCountry?.length > 0 ? action.filter.destinationCountry : null,
                destinationZip: action.filter?.destinationZip?.length > 0 ? action.filter.destinationZip : null,
                supplierCustomer: action.filter?.supplierCustomer?.length > 0 ? action.filter.supplierCustomer : null,
                dcTripStopLocation: action.filter?.dcTripStopLocation?.length > 0 ? action.filter.dcTripStopLocation : null,
                mostRecentPostDC: action.filter?.mostRecentPostDC?.length > 0 ? action.filter.mostRecentPostDC : null,
            };

        default:
            return state;

    }
}
