import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CompareTraxxViewComponent } from './components/compare-traxx-view/compare-traxx-view.component';
import { CompareTraxxInfoBoxComponent } from './components/compare-traxx-info-box/compare-traxx-info-box.component';
import { CoreModule } from 'app-modules/core/core.module';
import { EmrNgMapApiModule, EmrNgControlsModule } from 'emr-ng-shared';
import { CompareTraxxRoutingModule } from './compare-traxx-routing.module';
import { ComparetraxxService } from './services/comparetraxx.service';

@NgModule({
  declarations: [
    CompareTraxxViewComponent,
    CompareTraxxInfoBoxComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    EmrNgControlsModule,
    EmrNgMapApiModule,
    CompareTraxxRoutingModule
  ],
  exports: [
    CompareTraxxViewComponent,
    CompareTraxxRoutingModule
  ],
  providers: [
    ComparetraxxService
  ]
})
export class CompareTraxxModule { }
