<emr-modal headerTitle="Select Columns" i18n-headerTitle="@@label_select_columns" [showCloseButton]=true
    [bsModalRef]="modalRef">
    <div modal-body>
        <div class="col-md-12 m-1 ml-n1">
            <div>
                <emr-button identifier="selectAll"
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2"
                    i18n-buttonText="@@select_all" (onClick)="updateColumnsSelection(true)" buttonText="Select All">
                </emr-button>
                <emr-button identifier="applyFilter"
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2"
                    i18n-buttonText="@@unselect_all" (onClick)="updateColumnsSelection(false)"
                    buttonText="Unselect All">
                </emr-button>
            </div>
        </div>
        <div class="row m-2">
            <div
                class="{{ completedTripColumnCheckboxes?.length > 0 ? 'col-md-auto wd-column-sec pr-0' : 'col-md-12' }}">
                <div>
                    <emr-checkboxlist identifier="columnSelection" name="columnSelection" [options]="columnCheckboxes"
                        returnType="array" textCssClass="align-baseline" [optionsTextField]="'title'"
                        itemsCssClass="col-md-6 col-sm-12 pr-1" [inlineCheckbox]="true" [optionsValueField]="'title'"
                        [(ngModel)]="selectedColumns" customeCheckboxPosition="true" numberOfColumns="3">
                    </emr-checkboxlist>
                </div>
            </div>
            <div class="col-md-auto pr-0 wd-completed-sec border" *ngIf="completedTripColumnCheckboxes?.length > 0">
                <div class="w-100 mb-1" i18n="@@label_completed_only">Completed Trips only</div>
                <div class="row">
                    <div class="wd-completed">
                        <div *ngIf="temperatureColumnCheckboxes?.length > 0" class="col-md-auto">
                            <fieldset>
                                <div class="w-100" i18n="@@label_temperature">Temperature</div>
                                <emr-checkboxlist identifier="temperatureColumnselection"
                                    name="temperatureColumnselection" [options]="temperatureColumnCheckboxes"
                                    itemsCssClass="col-md-12 col-sm-12" returnType="array" textCssClass="align-baseline"
                                    [optionsTextField]="'title'" [inlineCheckbox]="true" [optionsValueField]="'title'"
                                    [(ngModel)]="selectedTempTripColumns">
                                </emr-checkboxlist>
                            </fieldset>
                        </div>
                        <div *ngIf="completedTripColumnCheckboxes?.length > 0" class="col-md-auto">
                            <fieldset>
                                <emr-checkboxlist identifier="completedTripColumnSelection"
                                    name="completedTripColumnSelection" [options]="completedTripColumnCheckboxes"
                                    itemsCssClass="col-md-12 col-sm-12" returnType="array" textCssClass="align-baseline"
                                    [optionsTextField]="'title'" [inlineCheckbox]="true" [optionsValueField]="'title'"
                                    [(ngModel)]="selectedCompletedTripColumns">
                                </emr-checkboxlist>
                            </fieldset>
                        </div>
                    </div>
                    <div class="wd-sensors">
                        <div class="row">
                            <div *ngIf="humidityColumnCheckboxes?.length > 0" class="col-md-12">
                                <fieldset>
                                    <div class="w-100" i18n="@@label_humidity">Humidity</div>
                                    <emr-checkboxlist identifier="humidityColumnselection"
                                        name="humidityColumnselection" [options]="humidityColumnCheckboxes"
                                        itemsCssClass="col-md-12 col-sm-12" returnType="array"
                                        textCssClass="align-baseline" [optionsTextField]="'title'"
                                        [inlineCheckbox]="true" [optionsValueField]="'title'"
                                        [(ngModel)]="selectedHumidityTripColumns">
                                    </emr-checkboxlist>
                                </fieldset>
                            </div>
                            <div *ngIf="carbonColumnCheckboxes?.length > 0" class="col-md-12">
                                <fieldset>
                                    <div class="w-100" i18n="@@label_carbondioxide">CO2</div>
                                    <emr-checkboxlist identifier="carbonColumnselection" name="carbonColumnselection"
                                        [options]="carbonColumnCheckboxes" itemsCssClass="col-md-12 col-sm-12"
                                        returnType="array" textCssClass="align-baseline" [optionsTextField]="'title'"
                                        [inlineCheckbox]="true" [optionsValueField]="'title'"
                                        [(ngModel)]="selectedCarbonTripColumns">
                                    </emr-checkboxlist>
                                </fieldset>
                            </div>
                            <div *ngIf="probeColumnCheckboxes?.length > 0" class="col-md-12">
                                <fieldset>
                                    <div class="w-100" i18n="@@label_probetemp">Probe Temp</div>
                                    <emr-checkboxlist identifier="probeColumnselection" name="probeColumnselection"
                                        [options]="probeColumnCheckboxes" itemsCssClass="col-md-12 col-sm-12"
                                        returnType="array" textCssClass="align-baseline" [optionsTextField]="'title'"
                                        [inlineCheckbox]="true" [optionsValueField]="'title'"
                                        [(ngModel)]="selectedProbeTripColumns">
                                    </emr-checkboxlist>
                                </fieldset>
                            </div>
                            <!-- <div *ngIf="fuelLevelColumnCheckboxes?.length > 0" class="col-md-6">
                                <fieldset>
                                    <div class="w-100" i18n="@@label_fuellevel">Fuel Level</div>
                                    <emr-checkboxlist identifier="fuelLevelColumnselection"
                                        name="fuelLevelColumnselection" [options]="fuelLevelColumnCheckboxes"
                                        itemsCssClass="col-md-12 col-sm-12" returnType="array"
                                        textCssClass="align-baseline" [optionsTextField]="'title'"
                                        [inlineCheckbox]="true" [optionsValueField]="'title'"
                                        [(ngModel)]="selectedFuelLevelTripColumns">
                                    </emr-checkboxlist>
                                </fieldset>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Save" i18n-buttonText="@@link_save"
                (click)='onColumnChangeAccept()'
                cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
            <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Cancel"
                i18n-buttonText="@@label_cancel" (click)='modalRef.hide()'
                cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
            </emr-button>
        </div>
    </div>
</emr-modal>