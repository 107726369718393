import { Action } from '@ngrx/store';

import * as actionIndex from 'app-modules/core/store/actions';

export class ChangeShipmentDetailHistoricalShipmentPage implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE;

    constructor(public pageNumber: number) { }
}

export class ChangeShipmentDetailHistoricalShipmentPageSize implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_DETAIL_HISTORICAL_SHIPMENT_PAGE_SIZE;

    constructor(public pageSize: number) { }
}
