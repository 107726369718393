import { Action } from '@ngrx/store';

import { TripEndOptions } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTripEndOptions implements Action {
    readonly type = actionIndex.LOAD_TRIP_END_OPTIONS;
}

export class LoadTripEndOptionsSuccess implements Action {
    readonly type = actionIndex.LOAD_TRIP_END_OPTIONS_SUCCESS;
    constructor(public TripEndOptionsList: IListInfo<TripEndOptions>) { }
}

export class LoadTripEndOptionsError implements Action {
    readonly type = actionIndex.LOAD_TRIP_END_OPTIONS_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadTripEndOptions implements Action {
    readonly type = actionIndex.CANCEL_LOAD_TRIP_END_OPTIONS;
}

