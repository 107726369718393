import { ITimePeriod } from './time-period.interface';
import { IShipmentFilterAlertType } from './shipment-filter-alert-type.interface';
import { TimePeriod, TripState } from 'emr-ng-shared';

export interface IShipmentFilterState {
    serialNumber?: string;
    status?: number[];
    period?: ITimePeriod;
    alert?: IShipmentFilterAlertType;
    origin?: number[];
    destination?: number[];
    sensorRange?: number[];
    carrier?: string[];
    shipmentName?: string;
    isDefaultState?: boolean;
    quickSearch?: string;
    IsHistorical?: boolean;
    IsUserDefaultFilter?: boolean;
    plateNumber?: string;
    containerNumber?: string;
    driverPhoneNumber?: string;
    originCity?: string[];
    originState?: string[];
    originCountry?: string[];
    originZip?: string[];
    destinationCity?: string[];
    destinationState?: string[];
    destinationCountry?: string[];
    destinationZip?: string[];
    supplierCustomer?: number[];
    dcTripStopLocation?: number[];
    mostRecentPostDC?: string[];
}

export function getDefaultShipmentFilter() {
    return {
        serialNumber: null,
        shipmentName: null,
        plateNumber: null,
        containerNumber: null,
        driverPhoneNumber: null,
        status: null,
        period: {
            isDefaultSelection: true,
            timePeriod: TimePeriod.LastTwoWeeks,
            dateRange: {
                startDateTime: null,
                endDateTime: null
            }
        },
        alert: {
            aboveTemp: false,
            belowTemp: false,
            late: false,
            moving: false,
            notMoving: false
        },
        origin: null,
        destination: null,
        sensorRange: null,
        carrier: null,
        quickSearch: null,
        isDefaultState: true,
        IsHistorical: false,
        originCity: null,
        originState: null,
        originCountry: null,
        originZip: null,
        destinationCity: null,
        destinationState: null,
        destinationCountry: null,
        destinationZip: null,
        supplierCustomer: null,
        dcTripStopLocation: null,
        mostRecentPostDC: null
    };
}

export function getDefaultUnAuthShipmentFilter() {
    return {
        serialNumber: null,
        shipmentName: null,
        plateNumber: null,
        containerNumber: null,
        driverPhoneNumber: null,
        status: null,
        period: {
            isDefaultSelection: true,
            timePeriod: null,
            dateRange: {
                startDateTime: null,
                endDateTime: null
            }
        },
        alert: {
            aboveTemp: false,
            belowTemp: false,
            late: false,
            moving: false,
            notMoving: false
        },
        origin: null,
        destination: null,
        sensorRange: null,
        carrier: null,
        quickSearch: null,
        isDefaultState: true,
        IsHistorical: false,
        originCity: null,
        originState: null,
        originCountry: null,
        originZip: null,
        destinationCity: null,
        destinationState: null,
        destinationCountry: null,
        destinationZip: null,
        supplierCustomer: null,
        dcTripStopLocation: null,
        mostRecentPostDC: null
    };
}

export function getMapViewDefaultShipmentFilter(defaultInProgressOnly: boolean = false) {
    const filterStatus = defaultInProgressOnly ?
        [TripState.InProgress] : [TripState.Default, TripState.InProgress];
    // const map = new Map<number, boolean>();
    // map.set(0, true);
    // map.set(2, true);
    return {
        serialNumber: null,
        shipmentName: null,
        plateNumber: null,
        containerNumber: null,
        driverPhoneNumber: null,
        status: filterStatus,
        period: {
            isDefaultSelection: true,
            timePeriod: TimePeriod.LastTwoWeeks,
            dateRange: {
                startDateTime: null,
                endDateTime: null
            }
        },
        alert: {
            aboveTemp: false,
            belowTemp: false,
            late: false,
            moving: false,
            notMoving: false
        },
        origin: null,
        destination: null,
        sensorRange: null,
        carrier: null,
        quickSearch: null,
        isDefaultState: true,
        IsHistorical: false,
        originCity: null,
        originState: null,
        originCountry: null,
        originZip: null,
        destinationCity: null,
        destinationState: null,
        destinationCountry: null,
        destinationZip: null,
        supplierCustomer: null,
        dcTripStopLocation: null,
        mostRecentPostDC: null
    };
}

export function setDefaultShipmentFilter(shipmentFilter: IShipmentFilterState) {
    return JSON.stringify({
        serialNumber: shipmentFilter.serialNumber,
        shipmentName: shipmentFilter.shipmentName,
        plateNumber: shipmentFilter.plateNumber,
        containerNumber: shipmentFilter.containerNumber,
        driverPhoneNumber: shipmentFilter.driverPhoneNumber,
        status: shipmentFilter.status,
        period: {
            isDefaultSelection: shipmentFilter.period.isDefaultSelection,
            timePeriod: shipmentFilter.period.timePeriod,
            dateRange: {
                startDateTime: shipmentFilter.period.dateRange.startDateTime,
                endDateTime: shipmentFilter.period.dateRange.endDateTime
            }
        },
        alert: {
            aboveTemp: shipmentFilter.alert.aboveTemp,
            belowTemp: shipmentFilter.alert.belowTemp,
            late: shipmentFilter.alert.late,
            moving: shipmentFilter.alert.moving,
            notMoving: shipmentFilter.alert.notMoving
        },
        origin: shipmentFilter.origin,
        destination: shipmentFilter.destination,
        sensorRange: shipmentFilter.sensorRange,
        carrier: shipmentFilter.carrier,
        quickSearch: shipmentFilter.quickSearch,
        isDefaultState: shipmentFilter.isDefaultState,
        IsHistorical: false,
        supplierCustomer: shipmentFilter.supplierCustomer,
        dcTripStopLocation: shipmentFilter.dcTripStopLocation
    });
}

export function getSelectedDefaultShipmentFilter(shipmentFilter: any): IShipmentFilterState {

    if (!shipmentFilter) {
        return null;
    }

    const serialNumber = parserFilterWithString(shipmentFilter.serialNumber, 'serialNumber');
    const shipmentName = parserFilterWithString(shipmentFilter.shipmentName, 'shipmentName');
    const plateNumber = parserFilterWithString(shipmentFilter.plateNumber, 'plateNumber');
    const containerNumber = parserFilterWithString(shipmentFilter.containerNumber, 'containerNumber');
    const driverPhoneNumber = parserFilterWithString(shipmentFilter.driverPhoneNumber, 'driverPhoneNumber');
    const status = parseFilterWithArray(shipmentFilter.status, 'selectedStatuses');
    const origin = parseFilterWithArray(shipmentFilter.origin, 'selectedLocations');
    const destination = parseFilterWithArray(shipmentFilter.destination, 'selectedLocations');
    const sensorRange = parseFilterWithArray(shipmentFilter.sensorRange, 'selectedRanges');
    const carrier = parseFilterWithArray(shipmentFilter.carrier, 'selectedCarriers');
    const supplierCustomer = parseFilterWithArray(shipmentFilter.supplierCustomer, 'supplierCustomerID');
    const dcTripStopLocation = parseFilterWithArray(shipmentFilter.dcTripStopLocation, 'dCTripStopLocationID');

    // tslint:disable-next-line: no-string-literal
    return {
        serialNumber,
        shipmentName,
        plateNumber,
        containerNumber,
        driverPhoneNumber,
        status,
        period: {
            isDefaultSelection: shipmentFilter['period'].isDefaultSelection,
            timePeriod: shipmentFilter['period'].timePeriod,
            dateRange: {
                startDateTime: GetDate(shipmentFilter['period'].dateRange.startDateTime),
                endDateTime: GetDate(shipmentFilter['period'].dateRange.endDateTime)
            }
        },
        alert: {
            aboveTemp: shipmentFilter['alert'].aboveTemp,
            belowTemp: shipmentFilter['alert'].belowTemp,
            late: shipmentFilter['alert'].late,
            moving: shipmentFilter['alert'].moving,
            notMoving: shipmentFilter['alert'].notMoving
        },
        origin,
        destination,
        sensorRange,
        carrier,
        quickSearch: shipmentFilter['quickSearch'],
        isDefaultState: shipmentFilter['isDefaultState'],
        IsHistorical: shipmentFilter['IsHistorical'],
        supplierCustomer,
        dcTripStopLocation
    };
}

export function parserFilterWithString(filter, secondLevelProp) {
    let convertedFilter = null;
    if (typeof filter === 'object') {
        const obj = filter;
        if (obj &&
            obj[secondLevelProp] &&
            obj[secondLevelProp][secondLevelProp]) {
            convertedFilter = obj[secondLevelProp][secondLevelProp];
        }
    } else {
        convertedFilter = filter;
    }

    if (!convertedFilter) {
        convertedFilter = null;
    }

    return convertedFilter;
}

export function parseFilterWithArray(filter, secondLevelProp) {
    let convertedFilter = [];

    // Old Format
    if (filter &&
        !Array.isArray(filter) &&
        typeof filter === 'object') {
        const ob1 = filter[secondLevelProp];
        if (ob1) {
            let selectedItems = [];
            try {
                selectedItems = JSON.parse(ob1);
            } catch { }
            if (selectedItems &&
                selectedItems.length > 0) {
                convertedFilter = selectedItems[0].filter(a => !!a && a.length > 0).map(a => a[0]);
            }
        }
    } else {
        try {
            if (filter === 'null') {
                convertedFilter = null;
            } else {
                const obj = Array.from(filter);
                if (obj) {
                    convertedFilter = obj;
                }
            }
        } catch { }
    }

    if (!(convertedFilter &&
        convertedFilter.length > 0)) {
        convertedFilter = null;
    }

    return convertedFilter;
}


export function GetDate(v: any) {
    let date = null;
    if (v) {
        date = new Date(v);
    }
    return date;

}