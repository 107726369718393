import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { Contact, SetContactRequest } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IContactState } from '../models/contact-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as contactActions from './contact.actions';

@Injectable()
export class ContactStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public contactStateSelector = ((state: IAppState) => state.contactList);
    public isLoadRequiredSelector = createSelector(
        this.contactStateSelector,
        (state: IContactState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.contactStateSelector,
        (state: IContactState) => state.isLoading
    );
    public contactListSelector = createSelector(
        this.contactStateSelector,
        (state: IContactState) => state.contactList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public contactList$ = this.store.select(this.contactListSelector).pipe();

    // Actions
    public loadContacts() {
        this.store.dispatch(new contactActions.LoadContacts());
    }

    public loadContactsSuccess(contactList: IListInfo<Contact>) {
        this.store.dispatch(new contactActions.LoadContactsSuccess(contactList));
    }

    public loadContactsError(message: string) {
        this.store.dispatch(new contactActions.LoadContactsError(message));
    }

    public cancelLoadContacts() {
        this.store.dispatch(new contactActions.CancelLoadContacts());
    }

    // setContact
    public setContact(request: SetContactRequest) {
        this.store.dispatch(new contactActions.SetContact(request));
    }

    public setContactSuccess(contact: Contact) {
        this.store.dispatch(new contactActions.SetContactSuccess(contact));
    }

    public setContactError(message: string) {
        this.store.dispatch(new contactActions.SetContactError(message));
    }

    public cancelSetContact() {
        this.store.dispatch(new contactActions.CancelSetContact());
    }
}


