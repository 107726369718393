import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { IShipmentFilterLocation } from 'app-modules/core/store/models/shipment-filter-location.interface';
import { LocationInfo } from 'emr-ng-shared';
import { LocationService } from 'app-modules/core/services/location.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';

@Component({
    selector: 'app-common-filter',
    templateUrl: './shipment-common-filter.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentCommonFilterComponent {
    // bindable filter
    filterVal: any;
    searchOriginText = '';
    @Input() list: any[];
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    // tslint:disable-next-line: adjacent-overload-signatures
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }
    model: any;
    searchModel: any;

    @Input() set filter(val) {
        this.filterVal = val;
        this.model = val;
        this.filterChange.emit(this.filterVal);
    }
    get filter() {
        // this.filterVal = this.locationList && this.locationList.filter(k => k.checked).map(k => k.value);
        return this.filterVal;
    }

    // @Input() searchModel;
    @Input() textField;
    @Input() valueField;
    @Output() filterChange = new EventEmitter();
    @Input() identifier;

    onFilterChange(data) {
        this.isDirty = true;
        this.filter = this.list.filter(k => k.checked).map(k => k[this.valueField]);
    }
}


