import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, switchMap, catchError, share, startWith, finalize } from 'rxjs/operators';
import { muteFirst, SetUserSettingResponse, SetUserSettingRequest, ErrorResponse, DashhboardSettingsRequest, UserSettingsType, DashhboardSettingsResponse } from 'emr-ng-shared';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { UserSettingsStateService } from './user-settings-state.service';
import { UserSetting } from 'app-modules/core/models/user-setting.model';
import { USER_SETTING_KEY_DASHBOARD_TRIAL_DECLINED, USER_SETTING_KEY_DASHBOARD_TRIAL_START, USER_SETTING_REQEUST, USER_SETTING_SECTION_SETTINGS } from 'app-modules/core/consts/user-setting';
import _, { tap } from 'lodash';
import { emptyList } from '../models/list-info-state.interface';

@Injectable()
export class UserSettingsService {
    constructor(
        private userSettingsStateSvc: UserSettingsStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.userSettingsStateSvc.isLoadRequired$;
    public isLoading$ = this.userSettingsStateSvc.isLoading$;

    public userSettings$: Observable<UserSetting[]> = muteFirst(
        this.getUserSettingsLoader().pipe(startWith(null)),
        this.userSettingsStateSvc.userSettingsList$
    );

    public shipmentFilterSettings$: Observable<any> = muteFirst(
        this.userSettings$,
        this.userSettingsStateSvc.shipmentFilterSetting$
    );

    public getUserSettingsLoader(): Observable<UserSetting[]> {
        const userSettings: UserSetting[] = _.cloneDeep(USER_SETTING_REQEUST);
        return this.userSettingsStateSvc.isLoadRequired$.pipe(
            filter(isLoadRequired => isLoadRequired),
            map(() => this.userSettingsStateSvc.loadUserSettingsWithStore()),
            switchMap(() => this.oversightSvc.GetUserSettings(USER_SETTING_REQEUST).pipe(map(k => k))),
            map(
                n => {
                    n.forEach((x, index) => { userSettings[index].Value = x.Value; });
                    this.userSettingsStateSvc.loadUserSettingsSuccessWithStore(userSettings);
                    return n;
                },
                e => this.userSettingsStateSvc.loadUserSettingsWithStoreError('')
            ),
            catchError(() => {
                this.userSettingsStateSvc.loadUserSettingsWithStoreError('');
                return of(null);
            }),
            share()
        );
    }

    public saveUserSettings(request: SetUserSettingRequest): Observable<SetUserSettingResponse> {
        return of(null).pipe(
            map(() => this.userSettingsStateSvc.saveUserSettings()),
            switchMap(() => this.oversightSvc.SetUserSetting(request)),
            map(
                n => {
                    if (n && n.ErrorCode === 0) {
                        this.userSettingsStateSvc.saveUserSettingsSuccess(
                            {
                                SettingType: request.SettingType,
                                Section: request.Section,
                                Key: request.Key,
                                Value: request.Value
                            });
                    };
                    return n;
                },
                e => this.userSettingsStateSvc.saveUserSettingsError('')
            ),
            catchError(() => {
                this.userSettingsStateSvc.saveUserSettingsError('');
                return of(null);
            })
        );
    }


    public deleteUserSettings(request: SetUserSettingRequest): Observable<SetUserSettingResponse> {
        return of(null).pipe(
            map(() => this.userSettingsStateSvc.saveUserSettings()),
            switchMap(() => this.oversightSvc.DeleteUserSetting(request)),
            map(
                n => {
                    if (n && n.ErrorCode === 0) {
                        this.userSettingsStateSvc.saveUserSettingsSuccess(
                            {
                                SettingType: request.SettingType,
                                Section: request.Section,
                                Key: request.Key,
                                Value: null
                            })
                    } else {
                        this.userSettingsStateSvc.saveUserSettingsError('');
                    };
                    return n;
                },
                e => this.userSettingsStateSvc.saveUserSettingsError('')
            ),
            catchError(() => {
                this.userSettingsStateSvc.saveUserSettingsError('');
                return of(null);
            })
        );
    }

    public saveUserGlobalSettings(request: SetUserSettingRequest): Observable<SetUserSettingResponse> {
        // TODO: please reevaluate if we need to use new actions for Global settings
        return of(null).pipe(
            map(() => this.userSettingsStateSvc.saveUserSettings()),
            switchMap(() => this.oversightSvc.SetUserGlobalSetting(request)),
            map(
                n => console.warn('No Success is called'), // this.userSettingsStateSvc.saveUserSettingsSuccess(request.Value),
                e => this.userSettingsStateSvc.saveUserSettingsError('')
            ),
            catchError(() => {
                this.userSettingsStateSvc.saveUserSettingsError('');
                return of(null);
            })
        );
    }

}
