import { Component, OnInit, Input, OnDestroy, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { Subscription, combineLatest } from 'rxjs';
import { withLatestFrom, filter } from 'rxjs/operators';
import { EmrUtilService, TrackerHistory } from 'emr-ng-shared';
import { environment } from 'environments/environment';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';

@Component({
  selector: 'app-shipment-map-view',
  templateUrl: './shipment-route-map-view.component.html',
  styleUrls: ['./shipment-route-map-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentRouteMapViewComponent implements OnInit, OnDestroy {
  ShowEstimatedCrumb: boolean = null;
  showRestoreMaximized = false;
  isMaximized = false;
  historicalShipmentsSub: Subscription;
  ngOnInitCallStarted = false;
  @Input() isFilterPinned = false;
  @Input()
  set Maximized(value: boolean) {
    this.isMaximized = value;
    this.showRestoreMaximized = !value;
  }

  get Maximized() { return this.isMaximized; }

  @Input()
  set Shipment(val: Shipment) {
    this.shipment = val;
    if (!this.isItFromRouteDeviation) {
      this.updateGetTraxxCall();
    }
  }

  get Shipment(): Shipment {
    return this.shipment;
  }
  mapViewHeight: string;
  @Input()
  set MapviewHeight(value: string) {
    this.mapViewHeight = value;
    this.shipmentRouteHeight = value;
  }

  @Output()
  CloseShowRoute = new EventEmitter();

  TraxxList: ITraxxState;
  getTraxxSubscription: Subscription;
  isItFromRouteDeviation = false;
  shipmentList: TrackerHistory[];
  shipmentRouteHeight: string;

  private shipment: Shipment;
  maximize: 'Maximize';
  restore: 'Restore';
  isTraxxLoading$ =
        combineLatest(this.detailSvc.isTraxxLoading$,
                    this.detailSvc.isHistoricalLoading$,
                    (a, b) => a || b);

  constructor(
    private detailSvc: ShipmentDetailService,
    private detailStateSvc: ShipmentDetailStateService,
    private utilSvc: EmrUtilService
  ) { }

  ngOnInit() {
    if (!this.isItFromRouteDeviation) {
      this.ngOnInitCallStarted = true;
      this.updateGetTraxxCall();
      this.getTraxxData();
    }
  }

  private updateGetTraxxCall() {
    if (!this.shipment || !this.ngOnInitCallStarted) {
      return;
    }
    this.updateEstimatedCrumbs();
    if (this.Shipment && this.Shipment.isMultiTrip) {
      this.clearHistoricalSub();
      this.detailSvc.selectShipment(this.Shipment);
      this.historicalShipmentsSub = this.detailSvc.historicalShipmentList$.pipe(
        withLatestFrom(this.detailStateSvc.isHistoricalShipmentListLoadRequired$,
          this.detailStateSvc.isHistoricalLoading$),
        filter(([n, loading, loadRequired]) => !(loading || loadRequired)))
        .subscribe(([n]) => {
          this.shipmentList = n.list;
          this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb, n.list);
          this.clearHistoricalSub();
        });
    } else {
      this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb);
    }
  }

  private updateEstimatedCrumbs() {
    if (this.Shipment?.Is4G) {
      const data = localStorage.getItem(environment.showEstimatedCrumbs);
      if (data !== null) {
        this.ShowEstimatedCrumb = (data === 'true');
      }
    }
  }

  getTraxxData() {
    this.getTraxxSubscription = this.detailSvc.getTraxx$.subscribe(iTraxx => {
      this.TraxxList = iTraxx;
    });
  }

  public ngOnDestroy() {
    this.clearHistoricalSub();
    if (this.getTraxxSubscription) {
      this.detailSvc.getTraxxRequest(true);
      this.getTraxxSubscription.unsubscribe();
    }
  }

  toggleZoom() {
    if (this.Maximized) {
      this.MapviewHeight = this.mapViewHeight;
    } else {
      this.shipmentRouteHeight = 'calc(100vh - 40px)';
    }
    this.isMaximized = !this.Maximized;
  }

  closeShowRoute() {
    this.CloseShowRoute.emit();
  }

  private clearHistoricalSub() {
    if (this.historicalShipmentsSub) {
      this.historicalShipmentsSub.unsubscribe();
      this.historicalShipmentsSub = null;
    }
  }

  onDateRangeChange(data: { fromDate: Date, thruDate: Date}){
    if (data) {
      let fromDate = null;
      let thruDate = null;
      if (data.fromDate && data.thruDate) {
        fromDate = this.utilSvc.DateFormatLocaleChange(data.fromDate);
        thruDate = this.utilSvc.DateFormatLocaleChange(data.thruDate);
      }
      this.updateEstimatedCrumbs();
      this.detailSvc.initDefaultTraxxRequest(this.Shipment, this.ShowEstimatedCrumb, this.shipmentList, null, fromDate, thruDate);
      this.getTraxxData();
    }
  }
}

