import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { IShipmentFilterSerialNumber } from 'app-modules/core/store/models/shipment-filter-serial-number.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { TrackerService } from 'app-modules/core/services/tracker.service';

@Component({
    selector: 'app-shipment-filter-serial-number',
    templateUrl: 'shipment-filter-serial-number.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ShipmentFilterSerialNumberComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: string;
    @Input() get filter() { return this.filterVal; }
    @Output() filterChange = new EventEmitter();
    set filter(val) {
        this.filterVal = val;
        this.filterChange.emit(this.filterVal);
    }

    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    public serialOptions$: Observable<Tracker[]>;
    public fullSerialList: Tracker[];
    public filteredSerialList: Tracker[];

    serialFilterSubscription: Subscription;
    serialListsSubscription: Subscription;
    
    constructor(
        private shpFltrSvc: ShipmentFilterService,
        public trackSvc: TrackerService
    ) { }

    ngOnInit() {
        this.serialListsSubscription = this.trackSvc.trackerList$.subscribe(n => this.fullSerialList = n.list);
        this.serialFilterSubscription = this.shpFltrSvc.serialNumberFilter$.subscribe(n => {
            this.onSerialFilterSubscription(n);
        });
    }

    findSerialNumbers(list: Tracker[], findNumber: string) {
        if (!findNumber || findNumber.length === 0) {
            return list;
        }
        try {
            const pattern = new RegExp(`${findNumber}`, 'i');
            const trackerIdsFromBeginning = list.filter(n => pattern.test(n.trackerId));
            if (findNumber.length <= 4) {
                const findLastFourDigits = list.filter(n => pattern.test(n.lastFourChars));
                return Array.from(new Set(findLastFourDigits.concat(trackerIdsFromBeginning)));
            }
            return trackerIdsFromBeginning;
        } catch (e) {
            return null;
        }
    }

    ngOnDestroy() {
        this.serialFilterSubscription?.unsubscribe();
        this.serialListsSubscription?.unsubscribe();
    }

    onSerialFilterSubscription(filter: string) {
        this.filter = filter;
        this.isDirty = false;
    }

    onSerialNumberChanged() {
        this.isDirty = true;
        this.filteredSerialList = this.findSerialNumbers(this.fullSerialList, this.filter);
    }
}


