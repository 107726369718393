<div class="col-12 py-2 bg-scheduled-report report-div-height">
    <div class="row mt-2">
        <div class="col-12 roboto-regular-font font-20 color-regular">
            <p class="col-md-4 px-0">
                <span i18n="@@link_schedule_reports">Scheduled Reports</span>
            </p>
            <hr class="mb-1">
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-7 text-left">
            <form modal-body name="frmScheduleNewReport" #ScheduleNewReportForm="ngForm" emrForm
                autocomplete="off" [ngClass]="{ 'ng-submitted': selectedReportType && isFormSubmitted }">
                <ul class="nav justify-content-left">
                    <li class="nav-item width-380 pr-2">
                        <emr-custom-dropdown identifier="ddlReportType" name="ddlReportType" label="Report Type"
                            i18n-label="@@label_report_type" [options]="reportTypeList" [(ngModel)]="selectedReportType"
                            cssClass="default-dropdown" [inline]=true labelColumns=3 controlColumns="9"
                            [controlInline]=false placeholder="All" i18n-placeholder="@@label_all" 
                            (ngModelChange)="onReportTypeChange()" >
                        </emr-custom-dropdown>
                    </li>
                    <ng-container *ngIf="selectedReportType">
                        <li class="nav-item">
                            <emr-button identifier="btnScheduleNewReport" buttonType="submit"
                                cssClass="btn-outline-default mt-0 height-md-30" buttonIcon="fa fa-calendar-check-o"
                                buttonText="Schedule New Report" i18n-buttonText="@@link_schedule_new_report"
                                (onClick)="onNewReportClick(false)">
                            </emr-button>
                        </li>
                        <li class="nav-item">
                            <emr-button identifier="btnEmailReport" buttonType="submit" cssClass="btn-outline-default mt-0  height-md-30"
                                buttonIcon="fa fa-envelope-o" i18n-buttonText="@@label_email_report" buttonText="Email Report"
                                (onClick)="onNewReportClick(true)">
                            </emr-button>
                        </li>
                    </ng-container>
                </ul>
            </form>
        </div>
        <div class="col-5 text-right pl-0">
            <ul class="nav justify-content-end">
                <li class="nav-item">
                    <app-total-records [itemCount]="reportListCount"></app-total-records>
                </li>
                <li class="nav-item">
                    <emr-button identifier="btnReloadReport"
                        cssClass="btn btn-outline-default button-text mt-0 height-md-30" buttonIcon="fa fa-refresh"
                        buttonText="Refresh" i18n-buttonText="@@link_refresh" (onClick)="reloadScheduledReports()">
                    </emr-button>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-12 text-center report-grid-height">
            <div class="table-responsive">
                <table class="table hover-table mb-0 custom-table" #table  *ngIf="scheduledReportsList$ | async as reports;">
                    <thead>
                        <tr>
                            <th *ngIf="!this.selectedReportType" scope="col" class="table-row-header">
                                <span i18n="@@label_report_type">Report Type</span>
                            </th>
                            <th scope="col" class="table-row-header">
                                <span i18n="@@label_created_date">Created Date</span>
                            </th>
                            <th scope="col" class="table-row-header">
                                <span i18n="@@label_report_name">Report Name</span>
                            </th>
                            <th scope="col" class="table-row-header">
                                <span i18n="@@label_recipient">Recipient</span>
                            </th>
                            <th scope="col" class="table-row-header">
                                <span i18n="@@label_days">Days</span>
                            </th>
                            <th scope="col" class="table-row-header">
                                <span i18n="@@label_hours">Hours</span>
                            </th>
                            <th scope="col" class="table-row-header">
                                <span i18n="@@status">Status</span>
                            </th>
                            <th scope="col">
                                <p class="">
                                    <span>&nbsp;</span>
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="reports?.length > 0; else emptyReportList">
                        <tr *ngFor="let report of reports; let j = index;"
                            class="cursor-pointer" [class.selected]="report === selectedReport"
                            (click)="onRowClick(report)">
                            <td *ngIf="!this.selectedReportType">
                                <div class="table-row-data">
                                    <span>{{ getScheduleReportTypeId(report.ReportType) }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data">
                                    <span>{{ report.CreatedDateFormatted }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data">
                                    <span>{{ report.Description }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data">
                                    <span>{{ report.SendTo }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data">
                                    <span>{{ getDays(report.DOW) }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data">
                                    <span>{{ getTime(report.Hour) }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data" *ngIf="report.Deleted; else active">
                                    <span i18n="@@label_deleted">Deleted</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <emr-button *ngIf="report.DOW && !report.Deleted"
                                        identifier="btnEditReport"
                                        cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                                        buttonIcon="fa fa-pencil" buttonText="Edit"
                                        i18n-buttonText="@@link_edit"
                                        (onClick)="onEditReportClick(report)">
                                    </emr-button>
                                    <emr-button *ngIf="!report.Deleted" identifier="btnDeleteReport"
                                        cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                                        buttonIcon="fa fa-times" buttonText="Delete"
                                        i18n-buttonText="@@label_delete"
                                        (onClick)="onDeleteReportClick(report)">
                                    </emr-button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #active>
    <div class="table-row-data">
        <span i18n="@@label_active">Active</span>
    </div>
</ng-template>

<ng-template #emptyReportList>
    <tbody id="full-grid-view-reset">
        <tr>
            <td colspan="14">
                <p class="table-row-data text-center" i18n="@@no_reports_scheduled">No Reports Scheduled
                </p>
            </td>
        </tr>
    </tbody>
</ng-template>