import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import { DistanceUnits, TraxxInfo } from 'emr-ng-shared';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { buttonHideBreadcrumb, buttonShowBreadcrumb, buttonRepairCrumb, buttonUnRepairCrumb, dist_km, dist_miles, dist_feet, dist_meters } from 'app-modules/core/consts/localization';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumb-info-box-list-content',
  templateUrl: './breadcrumb-info-box-list-content.component.html',
  styleUrls: ['./breadcrumb-info-box-list-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbInfoBoxListContentComponent implements OnInit, OnChanges {

  @Input() markersInfo: TraxxInfo[];
  markerCountInfo: TraxxInfo;
  @Input() isFromSensorChart: boolean = false;
  public distanceUnits: DistanceUnits;
  public isShowingLocationOrGeoFence: boolean = false;
  public isZoomInfoLevel: boolean = true;
  public showInfoZoomOption: boolean = true;
  public selectedRadiusBreadCrumbId: number;
  buttonHideBreadcrumbText: string;
  buttonShowBreadcrumbText: string;
  public isUserLocusAdminSupport: boolean = false;
  public isAdmin: boolean = false;
  @Input() is4G: boolean = false;
  authStateSub: Subscription;
  @Input()
  set isZoomedInfoLevel(value) {
    this.isZoomInfoLevel = value;
  }

  @Input()
  set checkAdminRole(value) {
    this.isAdmin = value;
  }

  @Input()
  set setDistanceUnits(value) {
    this.distanceUnits = value;
  }

  @Input()
  set setShowingLocationOrGeoFence(value) {
    this.isShowingLocationOrGeoFence = value;
  }

  @Input()
  set selectedBreadCrumbRadius(value) {
    this.selectedRadiusBreadCrumbId = value;
  }

  @Input()
  set showInfoBoxZoom(value) {
    this.showInfoZoomOption = value;
  }
  @Input() showHiddenCrumbs = false;

  isAuthenticated = false;
  canEditTripName: boolean = false;
  isLocusAdmin: boolean = false;


  constructor(
    private shpSvc: ShipmentService,
    private authSvc: AuthService
  ) { }

  ngOnInit() {
    this.authStateSub = this.authSvc.authState$.pipe().subscribe(n => {
      this.isUserLocusAdminSupport = (n?.isLocusSupport || n?.isEmersonAdmin);
      this.isLocusAdmin = n ? n.isEmersonAdmin : false;
      this.canEditTripName = n ? n.canEditTripName : false;
      this.authStateSub?.unsubscribe();
    });
    this.isAuthenticated = !this.shpSvc.getUnAuthenticationToken();
    this.buttonHideBreadcrumbText = buttonRepairCrumb;
    this.buttonShowBreadcrumbText = buttonUnRepairCrumb;
    this.markerCountInfo = this.markersInfo?.filter(x => !x.AlertInfo?.AlertId)[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (JSON.stringify(changes.markersInfo?.currentValue) != JSON.stringify(changes.markersInfo?.previousValue) && this.markersInfo?.length > 0)
      if (this.markersInfo[0]?.TrackerInfo && !this.markersInfo[0]?.TrackerInfo?.AddressGeo) {
        this.markersInfo[0].TrackerInfo.AddressGeo = this.getAddressGeo(this.markersInfo);
      }
  }

  getAddressGeo(markers: TraxxInfo[]) {
    let locationName = '';
    const markersWithAddressGeo = markers?.filter(k => k?.TrackerInfo?.AddressGeo)
    if (markersWithAddressGeo?.length > 0) {
      locationName = markersWithAddressGeo[0].TrackerInfo?.AddressGeo
    }
    return locationName;
  }

  getDataByUnits(value): string {
    let accuracyRadius = this.distanceUnits === DistanceUnits.Kilometers ? value / 1000 : (value * 0.00062137);
    let units = "";
    let calculatedValue = "";
    if (this.distanceUnits === DistanceUnits.Kilometers) {
      units = accuracyRadius >= 0.1 ? dist_km : dist_meters;
      calculatedValue = accuracyRadius >= 0.1 ? (this.getRoundOfValue(accuracyRadius)) : this.getRoundOfValue(value, true);
    } else {
      units = accuracyRadius >= 0.1 ? dist_miles : dist_feet;
      calculatedValue = accuracyRadius >= 0.1 ? this.getRoundOfValue(accuracyRadius) : this.getRoundOfValue(value / 0.3048, true);
    }
    return calculatedValue + ' ' + units;
  }

  getRoundOfValue(value, isNonDecimal: boolean = false): string {
    let returnValue;
    if (value == Math.floor(value) || isNonDecimal) {
      returnValue = value.toFixed();
    } else {
      returnValue = value.toFixed(1);
    }
    return Number(returnValue).toString();
  }
}

