import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { STORE_KEYS, MESSAGE_VERSION } from 'app-modules/core/consts/values';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { BaseURLPicker } from 'app-modules/core/services/baseurl-picker.service';
import { FILE_NAMES } from 'app-modules/core/consts/file-names';

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message-popup.component.html',
  styleUrls: ['./user-message-popup.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserMessagePopupComponent implements OnInit {

  newFeaturesURL = `${this.domainPicker.resourceDomain}${FILE_NAMES.recentReleases}`;
  constructor(
    public bsShowDistanceModalRef: BsModalRef,
    private domainPicker: BaseURLPicker
  ) { }

  ngOnInit() {
  }

  onPopupCancel() {
    StoreManager.StoreValue(
      STORE_KEYS.bulletinMessage, MESSAGE_VERSION.toString(),
      // tslint:disable-next-line: no-bitwise
      StoreOpts.Cookie | StoreOpts.LocalStorage | StoreOpts.StoreManager);
    this.bsShowDistanceModalRef.hide();
  }
}


