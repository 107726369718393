import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { IAppState } from '../models/app-state.interface';
import * as scheduleReportActions from 'app-modules/core/store/schedule-report/schedule-report.actions';
import { IListInfo } from '../models/list-info-state.interface';
import { ScheduledReports, ScheduleReportRequest } from 'emr-ng-shared';
import { IScheduledReportState } from '../models/scheduled-report-state.interface';

@Injectable()
export class ScheduleReportStateService {
    constructor(
        private store: Store<IAppState>,) { }

    // Selectors
    public scheduledReportStateSelector = ((state: IAppState) => state.scheduledReportList);
    public isLoadRequiredSelector = createSelector(
        this.scheduledReportStateSelector,
        (state: IScheduledReportState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.scheduledReportStateSelector,
        (state: IScheduledReportState) => state.isLoading
    );
    public scheduledReportListSelector = createSelector(
        this.scheduledReportStateSelector,
        (state: IScheduledReportState) => state.scheduledReportList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public scheduledReportList$ = this.store.select(this.scheduledReportListSelector).pipe();

    // Actions

    // SetScheduledReports List
    cancelScheduleReport(): void {
        this.store.dispatch(new scheduleReportActions.CancelScheduleReport());
    }
    scheduleReportError(): void {
        this.store.dispatch(new scheduleReportActions.ScheduleReportError());
    }
    scheduleReportSuccess(request: ScheduleReportRequest, isDelete: boolean = false): void {
        this.store.dispatch(new scheduleReportActions.ScheduleReportSuccess(request, isDelete));
    }
    scheduleReport() {
        this.store.dispatch(new scheduleReportActions.ScheduleReport());
    }

    // GetScheduledReports List
    
    public reloadScheduledReports() {
        this.store.dispatch(new scheduleReportActions.ReloadScheduledReports());
    }

    public loadScheduledReports() {
        this.store.dispatch(new scheduleReportActions.LoadScheduledReports());
    }

    public loadScheduledReportsSuccess(scheduledReportList: IListInfo<ScheduledReports>) {
        this.store.dispatch(new scheduleReportActions.LoadScheduledReportsSuccess(scheduledReportList));
    }

    public loadScheduledReportsError(message: string) {
        this.store.dispatch(new scheduleReportActions.LoadScheduledReportsError(message));
    }

    public cancelLoadScheduledReports() {
        this.store.dispatch(new scheduleReportActions.CancelLoadScheduledReports());
    }
}


