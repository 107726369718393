export const tempF = $localize`:@@temp_F:°F`;
export const tempC = $localize`:@@temp_C:°C`;
export const highCriticalHumidity = $localize`:@@high_critical_humidity:High Critical Humidity`;
export const highCriticalCO2 = $localize`:@@high_critical_co2:High Critical CO2`;
export const highCriticalTemp = $localize`:@@high_critical_temperature:High Critical Temperature`;
export const ppm = $localize`:@@label_ppm:ppm`;
export const lowCriticalHumidity = $localize`:@@low_critical_humidity:Low Critical Humidity`;
export const lowCriticalCO2 = $localize`:@@low_critical_co2:Low Critical CO2`;
export const lowCriticalTemp = $localize`:@@low_critical_temperature:Low Critical Temperature`;

// OR-1350  -- Black
export const Origin = $localize`:@@origin:Origin`;
export const Destination = $localize`:@@destination:Destination`;
export const Origin_Destination = `${Origin}/${Destination}`;

// OR-2616
export const localeAM = $localize`:@@time_AM:AM`;
export const localePM = $localize`:@@time_PM:PM`;

// OR-2502
export const shipmentName = $localize`:@@shipment_name:Shipment Name`;
export const serialNumber = $localize`:@@label_serial_number:Serial Number`;
export const shipmentStatus = $localize`:@@label_trip_status:Shipment Status`;
export const temperatureRange = $localize`:@@label_temperature_range:Temperature Range`;
export const origin = $localize`:@@origin:Origin`;
export const destination = $localize`:@@destination:Destination`;
export const carrier = $localize`:@@carrier:Carrier`;
export const lastReportedTemp = $localize`:@@last_reported_temp:Last Reported Temperature`;
export const lastReportedLocation = $localize`:@@last_reported_location:Last Reported Location`;
export const lastReportedTime = $localize`:@@last_reported_time:Last Reported Time`;
export const actualStartTime = $localize`:@@actual_start_time:Actual Start Time`;
export const actualEndTime = $localize`:@@actual_end_time:Actual End Time`;
export const battery = $localize`:@@label_battery:Battery`;
export const notes = $localize`:@@label_notes:Notes`;
export const scheduledStart = $localize`:@@link_scheduled_start:Scheduled Start`;
export const scheduledEnd = $localize`:@@link_scheduled_end:Scheduled End Time	`;
export const lastReportedHumidity = $localize`:@@last_reported_humidity:Last Reported Humidity`;
export const lastReportedProbeTemperature = $localize`:@@last_reported_probe_temperature:Last Reported Probe Temperature`;
export const lastReportedCO2 = $localize`:@@last_reported_co2:Last Reported CO2`;
export const lastReportedProximity = $localize`:@@last_reported_proximity:Last Reported Distance`;
export const destinationETA = $localize`:@@destination_eta:Destination ETA`;
export const distanceToDestination = $localize`:@@distance_to_destination:Distance To Destination`;

export const lowWarnAlertCount = $localize`:@@ct_report_label_low_warning_alert_count:Low Warning Alert Count`;
export const lowCriticalAlertCount = $localize`:@@low_critical_alert_count:Low Critical Alert Count`;
export const highWarnAlertCount = $localize`:@@ct_report_label_high_warning_alert_count:High Warning Alert Count`;
export const highCriticalAlertCount = $localize`:@@high_critical_alert_count:High Critical Alert Count`;

export const totalTimeBelowRange = $localize`:@@total_time_below_range:Total Minutes Below Range`;
export const totalTimeAboveRange = $localize`:@@total_time_above_range:Total Minutes Above Range`;
export const totalTimeOutOfRange = $localize`:@@total_time_out_of_range:Total Minutes Out of Range`;
export const maxTimeBelowRange = $localize`:@@max_time_below_range:Maximum Minutes Below Range`;
export const maxTimeAboveRange = $localize`:@@max_time_above_range:Maximum Minutes Above Range`;
export const avgSensorValue = $localize`:@@avg_temp_header:Avg Temp Value`;
export const avgHumidityValue = $localize`:@@avg_humidity_header:Avg Humidity`;
export const avgCarbonValue = $localize`:@@avg_carbon_header:Avg CO2`;
export const avgprobeValue = $localize`:@@avg_probe_header:Avg Probe Temp`;
export const avgFuelLevelValue = $localize`:@@avg_fuel_level_header:Avg Fuel Level`;

export const meanKineticTemperature = $localize`:@@mean_kinetic_temperature_header:MKT`;
export const minSensorValue = $localize`:@@minimum_temp_value_header:Minimum Temp Value`;
export const maxSensorValue = $localize`:@@maximum_temp_value_header:Maximum Temp Value`;
export const minHumidityValue = $localize`:@@minimum_humidity_header:Minimum Humidity`;
export const maxHumidityValue = $localize`:@@maximum_humidity_header:Maximum Humidity`;
export const maxcarbonValue = $localize`:@@maximum_carbon_header:CO2`;
export const mincarbonValue = $localize`:@@minimum_carbon_header:Minimum CO2`;
export const maxProbeValue = $localize`:@@maximum_probe_header:Maximum Probe Temp`;
export const minProbeValue = $localize`:@@minimum_probe_header:Minimum Probe Temp`;
export const maxFuelLevelValue = $localize`:@@maximum_fuel_level_header:Maximum Fuel Level`;
export const minFuelLevelValue = $localize`:@@minimum_fuel_level_header:Minimum Fuel Level`;
export const sensorStandardDeviation = $localize`:@@standard_dev_temp_header:Temp Std Dev`;
export const humidityStandardDeviation = $localize`:@@standard_dev_humidity_header:Humidity Std Dev`;
export const carbonStandardDeviation = $localize`:@@standard_dev_carbon_header:CO2 Std Dev`;
export const probeStandardDeviation = $localize`:@@standard_dev_probe_header:Probe Temp Std Dev`;
export const fuelLevelStandardDeviation = $localize`:@@standard_dev_fuel_level_header:Fuel Level Std Dev`;

// OR-2501
export const labelDefault = $localize`:@@TripStartOption_Default:Default`;
export const errorUnknown = $localize`:@@error_unknown:An unexpected error occurred`;

// OR-2650
export const buttonHideBreadcrumb = $localize`:@@button_hide_breadcrumb:Hide Breadcrumb`;
export const buttonShowBreadcrumb = $localize`:@@button_show_breadcrumb:Show Breadcrumbs`;
export const buttonRepairCrumb = $localize`:@@button_repair_crumb:Repair Crumb`;
export const buttonUnRepairCrumb = $localize`:@@button_un_repair_crumb:Un Repair Crumb`;

export const signin = $localize`:@@link_signin:Sign In`;
export const signinTermsAndConditionsHelpTip =
    $localize`:@@signin_termsAndConditionsHelpTip:Please accept terms and conditions to proceed!`;
export const description = $localize`:@@label_description:Description`;
export const details = $localize`:@@label_details:Details`;

export const downloadProgress = $localize`:@@download_progress:Download has been started. It will be downloaded in few minutes...`;
export const downloadProgressTitle = $localize`:@@download_progress_title:Your requested download has started.`;
export const downloadProgressMessage = $localize`:@@download_progress_message:Depending on the amount of data it could take up to a minute to complete.`;
export const downloadCompleted = $localize`:@@download_complete:Download finished`;

// OR-2331
export const format_pdf = $localize`:@@label_pdf:PDF`;
export const format_excel = $localize`:@@label_excel:Excel`;

export const label_email_report = $localize`:@@label_email_report:Email Report`;
export const label_schedule_report = $localize`:@@label_schedule_report:Schedule Report`;
export const link_save = $localize`:@@link_save:Save`;

export const label_yes = $localize`:@@label_yes:Yes`;
export const label_no = $localize`:@@label_no:No`;
export const link_cancel = $localize`:@@link_cancel:Cancel`;
export const label_confirm_message = $localize`:@@label_confirm_message:Are you sure you want to delete this?`;

export const label_un_assign_message = $localize`:@@label_un_assign_message:Are you sure you want to un-assign this contact?`;

export const label_sun = $localize`:@@label_sun:Sun`;
export const label_mon = $localize`:@@label_mon:Mon`;
export const label_tue = $localize`:@@label_tue:Tue`;
export const label_wed = $localize`:@@label_wed:Wed`;
export const label_thu = $localize`:@@label_thu:Thu`;
export const label_fri = $localize`:@@label_fri:Fri`;
export const label_sat = $localize`:@@label_sat:Sat`;

export const default_email_message = $localize`:@@default_email_message:The report you requested is attached to this email.`;

// Report Type
export const label_comprehensive_trip_report = $localize`:@@report_type_ComprehensiveTripReport:Comprehensive Trip Report`;
export const label_alert_report = $localize`:@@label_alert_report:Alert Report`;

export const in_progress = $localize`:@@in_progress:In Progress`;
export const label_completed = $localize`:@@label_completed:Completed`;
export const label_both = $localize`:@@label_both:Both`;

// Alert Action Status
export const alert_action_status_open = $localize`:@@alert_action_status_open:Open`;
export const alert_action_status_close = $localize`:@@alert_action_status_close:Closed`;

// Alert Escalation Levels
export const alertEscalationLevel_1 = $localize`:@@AlertEscalationLevel_1:Level 1`;
export const alertEscalationLevel_2 = $localize`:@@AlertEscalationLevel_2:Level 2`;
export const alertEscalationLevel_3 = $localize`:@@AlertEscalationLevel_3:Level 3`;
export const alertEscalationLevel_4 = $localize`:@@AlertEscalationLevel_4:Level 4`;
export const alertEscalationLevel_5 = $localize`:@@AlertEscalationLevel_5:Level 5`;
export const alertEscalationLevel_6 = $localize`:@@AlertEscalationLevel_6:Level 6`;

// Alert Severity
export const alert_severity_critical = $localize`:@@alert_severity_critical:Critical`;
export const alert_severity_warning = $localize`:@@alert_severity_warning:Warning`;


export const name = $localize`:@@label_name:Name`;
export const geoFence = $localize`:@@label_geo_fence:Geo-Fence`;
export const addressLine1 = $localize`:@@label_address_line1:Address Line 1`;

export const city = $localize`:@@label_location_city:City`;
export const state = $localize`:@@label_location_state:State`;
export const zip = $localize`:@@label_location_zip:Zip`;


export const dist_km = $localize`:@@dist_km:Km`;
export const dist_miles = $localize`:@@dist_miles:Miles`;
export const dist_feet = $localize`:@@dist_feet:Feet`;
export const dist_meters = $localize`:@@dist_meters:Meters`;

export const label_delete_geofence = $localize`:@@label_delete_geofence: Delete Geofence`;
export const label_save_geofence = $localize`:@@label_save_geofence: Save Geofence`;

// contacts
export const label_add_contact = $localize`:@@label_add_contact: Add Contact`;
export const label_edit_contact = $localize`:@@label_edit_contact: Edit Contact`;
export const label_update_contact = $localize`:@@label_update_contact: Update Contact`;
export const label_location_Contact = $localize`:@@label_location_Contact: Location Contact`;
export const label_un_assign_location_contact = $localize`:@@label_un_assign_location_contact: Un-Assign Location Contact`;
export const label_delete_location_confirm = $localize`:@@label_delete_location_confirm: Do you want to delete the location?`;
export const label_delete_location = $localize`:@@label_delete_location: Delete Location`;

export const label_shipment_template = $localize`:@@label_shipment_template: Shipment Template`;
export const add_template = $localize`:@@add_template: Add Template`;
export const save_template = $localize`:@@save_template: Save Template`;
export const edit_template = $localize`:@@edit_template: Edit Template`;
// Import Locations
export const label_exceptions = $localize`:@@label_exceptions: Exceptions`;
export const label_warnings = $localize`:@@label_warnings: Warnings`;
export const location_number = $localize`:@@location_number: Location Number`;
export const label_location_name = $localize`:@@label_location_name: Location Name`;
export const label_address = $localize`:@@label_address: Address`;
export const label_location_city = $localize`:@@label_location_city: City`;
export const label_location_state = $localize`:@@label_location_state: State`;
export const label_location_zip = $localize`:@@label_location_zip: Zip`;
export const sensor_report_header_latitude = $localize`:@@sensor_report_header_latitude: Latitude`;
export const sensor_report_header_longitude = $localize`:@@sensor_report_header_longitude: Longitude`;
export const label_location_country = $localize`:@@label_location_country: Country`;
export const label_trigger_distance = $localize`:@@label_trigger_distance: End of Trip Trigger Distance(M)`;
export const label_color_code = $localize`:@@label_color_code: Location ColorCode`;
export const label_shipper_oversight = $localize`:@@label_shipper_oversight: Shippers Oversight ID`;
export const label_location_type = $localize`:@@label_location_type: Location Type`;
export const location_ship_to_inventory_tool = $localize`:@@location_ship_to_inventory_tool: Ship To - Inventory Tool`;
export const label_suppressed_alerts = $localize`:@@label_suppressed_alerts: Suppressed Alerts`;
export const label_minimum_tracker_inventory = $localize`:@@label_minimum_tracker_inventory: Minimum Tracker Inventory`;
export const label_delete = $localize`:@@label_delete: Delete`;
export const label_record_no = $localize`:@@label_record_no: RecordNo`;
export const label_no_program = $localize`:@@label_no_program:No Program`;
export const label_transfer = $localize`:@@label_transfer:Transfer`;

export const label_free_trial = $localize`:@@label_free_trial:Unlock a richer data set with our 7-day free trial`;
export const label_free_trial_end = $localize`:@@label_free_trial_end:Your Premium Trial has ended. Upgrade to Premium`;
export const message_premium_request_success = $localize`:@@message_premium_request_success:Thank you for upgrading to our Premium Dashboard. Someone from our Cargo Analytics team will reach out to you within 48 business hours. Please contact CargoAccountManagement@copeland.com if you have any questions.`;
export const header_premium_request = $localize`:@@label_premium_dashboard: Premium Dashboard`;

export const label_replayTraxx_play = $localize`:@@label_replayTraxx_play: Play`;
export const label_replayTraxx_pause = $localize`:@@label_replayTraxx_pause: Pause`;
export const label_replayTraxx_Slow = $localize`:@@label_replayTraxx_Slow: Slow`;
export const label_replayTraxx_Medium = $localize`:@@label_replayTraxx_Medium: Medium`;
export const label_replayTraxx_Fast = $localize`:@@label_replayTraxx_Fast: Fast`;

export const link_import_locations = $localize`:@@link_import_locations:Import Locations`;
// export const errors_import_locations = $localize`:@@errors_import_locations:Exceptions - Import Locations`;

export const label_update_description = $localize`:@@label_update_description:Update Description`;
export const label_update_type_updated = $localize`:@@label_update_type_updated:Updated`;
export const label_update_type_inserted = $localize`:@@label_update_type_inserted:New Location Added`;
export const label_update_type_deleted = $localize`:@@label_update_type_deleted:Deleted`;
export const label_will_not_inserted = $localize`:@@label_will_not_inserted:Will not be inserted`;

export const label_dashboard = $localize`:@@label_dashboard:Dashboard`;
export const warning_import_locations = $localize`:@@warning_import_locations:Warning - Import Locations`;
export const errors_import_locations = $localize`:@@errors_import_locations:Exceptions - Import Locations`;


export const label_dashboard_name = $localize`:@@label_dashboard_name:Dashboard Name`;
export const label_integration_id = $localize`:@@label_integration_id:Integration Id`;
export const label_is_premium = $localize`:@@label_is_premium:Is Premium`;
export const label_customer = $localize`:@@label_customer: Customer`;

export const label_add_dashboard = $localize`:@@label_add_dashboard:Add Dashboard`;
export const label_update_dashboard = $localize`:@@label_update_dashboard:Update Dashboard`;
export const label_edit_dashboard = $localize`:@@label_edit_dashboard:Edit Dashboard`;

export const label_assign_dashboard = $localize`:@@label_assign_dashboard:Assign Dashboard`;
export const label_unassign_dashboard = $localize`:@@label_unassign_dashboard:Un-Assign Dashboard`;
export const link_update = $localize`:@@link_update:Update`;
export const label_edit_customer_dashboard = $localize`:@@label_edit_customer_dashboard:Edit Customer Dashboard`;
export const select_distinct_location_name_required = $localize`:@@select_distinct_location_name_required:Please select distinct Origin Location`;
export const select_distinct_origin_destination = $localize`:@@select_distinct_origin_destination:Origin and Destination can not be the same`;

export const label_un_assign_dashboard_message = $localize`:@@label_un_assign_dashboard_message:Are you sure you want to un-assign the dashboard?`;
export const timeline_notentered = $localize`:@@timeline_notentered:Not Entered`;

export const label_geo_fence_overlapped = $localize`:@@label_geo_fence_overlapped:Overlapped`;
export const label_geo_fence_geo_overlapped = $localize`:@@label_geo_fence_geo_overlapped:Geo Overlapped`;
export const label_shipment_name = $localize`:@@label_shipment_name:Shipment Name`;
export const label_enter_shipment_name = $localize`:@@label_enter_shipment_name:Enter Shipment Name`;

export const plateNumber = $localize`:@@plate_number:Plate Number`;
export const containerNumber = $localize`:@@container_number:Container Number`;
export const driverPhoneNumber = $localize`:@@driver_phone_number:Driver Phone Number`;

export const label_po = $localize`:@@label_po_#:PO #`;
export const shipment_name_required = $localize`:@@shipment_name_required:Shipment Name Required`;
export const po_required = $localize`:@@po_required:PO # Required`;
export const label_n_a = $localize`:@@label_n_a:n/a`;

export const geo_fence = $localize`:@@label_geo_fence:Geofence`;
export const maximum_geo_fence = $localize`:@@maximum_geo_fence:The maximum number of fence posts supported is 15`;
export const label_login = $localize`:@@label_login:Login`;
export const label_define_trip = $localize`:@@label_define_trip:Define Trip`;
export const label_get_trip_status = $localize`:@@label_get_trip_status:Get Trip Status`;
export const invalid_api_key = $localize`:@@invalid_api_key:Entered API Key is invalid, please check`;
export const message_shipment_share_with_Brule = $localize`:@@message_shipment_share_with_Brule: The selected shared with customer does not support optional fields.`;

export const label_origin_address = $localize`:@@ct_report_label_origin_address:Origin Address`;
export const label_destination_address = $localize`:@@ct_report_label_destination_address:Destination Address`;

export const label_origin_city = $localize`:@@ct_report_label_origin_city:Origin City`;
export const label_destination_city = $localize`:@@ct_report_label_destination_city:Destination City`;

export const label_origin_state = $localize`:@@ct_report_label_origin_state:Origin State`;
export const label_destination_state = $localize`:@@ct_report_label_destination_state:Destination State`;

export const label_origin_country = $localize`:@@label_origin_country:Origin Country`;
export const label_destination_country = $localize`:@@label_destination_country:Destination Country`;

export const label_origin_zip = $localize`:@@label_origin_zip:Origin Zip Code`;
export const label_destination_zip = $localize`:@@label_destination_zip:Destination Zip Code`;

//OR-3313
export const scan_Reported = $localize`:@@scan_Reported: Scan Reported`;
export const scan_Code = $localize`:@@scan_Code: Scan Code`;
export const select_origin_from_list = $localize`:@@select_origin_from_list:Select Origin from populated list`;
export const select_destination_from_list = $localize`:@@select_destination_from_list:Select Destination from populated list`;
export const label_receiver_po = $localize`:@@label_receiver_po:Receiver PO#`;
export const label_supplier = $localize`:@@label_supplier:Supplier`;
export const label_distribution_center = $localize`:@@label_distribution_center:Distribution Center`;
export const label_last_geo_fence = $localize`:@@label_last_geo_fence:Last Geo Fence`;
export const user_email_request = $localize`:@@user_email_request:Your request has been submitted and your email should arrive shortly.`;