<div class="row bg-data-div-show-distance">
  <div class="col-md-12">
    <form name="frmShowDistance" autocomplete="off">
      <div class="" [hidden]="this.showDetails" *ngIf="(distanceToTargetDetails$|async) as distance">
        <div class="row div-min-height" [hidden]="distance?.ErrorCode>0">
          <div class="col-md-12 ">
            <div class="row">
              <div class="col-md-12 col-12 roboto-regular-font font-18 color-regular font-weight-bold">
                <p>
                  <!-- <span i18n="@@show_distance">Show Distance</span> -->
                  <span>{{shipmentName}}</span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 col-12 roboto-regular-font font-16 font-weight-bold">
                        <p>
                          <span i18n="@@From">From</span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 col-12 roboto-regular-font font-12 font-weight-bold">
                        <span>{{fullAddress}}</span>
                      </div>
                    </div>
                    <!-- <div class="row">
                    <div class="col-md-12 col-12 roboto-regular-font font-12">
                      <span>(35.4662, -97.6279)</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-12 roboto-regular-font font-12">
                      <span>10/01/2018 3:15 PM</span>
                    </div>
                  </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div *ngIf="distanceToTargetsRequest.TripId">
                      <div class="row">
                        <div class="col-md-12 col-12 roboto-regular-font font-16 font-weight-bold">
                          <p>
                            <span i18n="@@To">To</span>
                          </p>
                        </div>
                      </div>
                      <div class="row">

                        <div class="col-md-7">
                          <div class="form-row">
                            <div class="col-md-12 roboto-regular-font font-12">
                              <span>{{ distanceTargetDetails?.TripDestination?.Description }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-5">
                          <emr-button identifier="tripShowDistance" cssClass="btn button-text btn-outline-default mt-0"
                            buttonText="Show Distance" i18n-buttonText="@@show_distance"
                            (onClick)="tripDestinationShowDistanceClick()">
                          </emr-button>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="!IsUnAuthenticated">
                      <div class="col-md-7">
                        <p>
                          <emr-custom-dropdown identifier="lstLocations" name="lstLocations"
                            [options]="distanceTargetDetails?.Locations" [(ngModel)]="Location" labelCssClass="mb-0"
                            cssClass="default-dropdown" label="Location:" i18n-label="@@label_location"
                            helpTip=" Location Name" i18n-helpTip="@@label_location_name" placeholder="Select Location"
                            i18n-placeholder="@@label_select_location" [enableSearchFilter]=true
                            SearchPlaceHolder="Search Location" i18n-SearchPlaceHolder="@@label_search_location">
                          </emr-custom-dropdown>
                        </p>
                      </div>
                      <div class="col-md-5  mt-4">
                        <emr-button identifier="locationShowDistance"
                          cssClass="btn button-text btn-outline-default mt-0" buttonText="Show Distance"
                          i18n-buttonText="@@show_distance" (onClick)="locationShowDistanceClick(Location)">
                        </emr-button>
                      </div>
                    </div>
                    <!-- <div class="row">
                    <div class="col-md-7">
                      <p>
                        <emr-custom-dropdown identifier="lstVehicleLocations" name="lstVehicleLocations"
                          [options]="distanceTargetDetails?.Shipments" [(ngModel)]="VehicleLocation"
                          labelCssClass="mb-0" cssClass="default-dropdown" label="Shipment:" i18n-label="@@Vehicle"
                          helpTip="Shipment" i18n-helpTip="@@Vehicle" placeholder="Select Shipment"
                          i18n-placeholder="@@select_vehicle" [enableSearchFilter]=true
                          SearchPlaceHolder="Search Shipment Location"
                          i18n-SearchPlaceHolder="@@search_vehicle_location"></emr-custom-dropdown>
                      </p>
                    </div>
                    <div class="col-md-5  mt-4">
                      <emr-button cssClass="btn button-text btn-outline-default mt-0" buttonText="Show Distance"
                        i18n-buttonText="@@show_distance" (onClick)="shipmentShowDistanceClick(VehicleLocation)">
                      </emr-button>
                    </div>
                  </div> -->
                    <div class="row">
                      <div class="col-md-7">
                        <p>
                          <emr-textbox identifier="txtAddress" name="txtAddress" label="Address:"
                            i18n-label="@@label_address" placeholder="Address" i18n-placeholder="@@label_address"
                            [(ngModel)]="Address" helpTip="Address" i18n-helpTip="@@label_address" labelCssClass="mb-0">
                          </emr-textbox>
                        </p>
                      </div>
                      <div class="col-md-5  mt-4">
                        <emr-button identifier="addressShowDistance" cssClass="btn button-text btn-outline-default mt-0"
                          buttonText="Show Distance" i18n-buttonText="@@show_distance"
                          (onClick)="addressShowDistanceClick(Address)">
                        </emr-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row mt-2">
            <div class="col-md-4">
              <emr-button cssClass="btn-sm btn-style label-text text-12 btn-background" buttonText="Cancel" i18n-buttonText="@@link_cancel"
                (onClick)="onCancelClick()">
              </emr-button>
            </div>
          </div> -->
          </div>
        </div>
        <div class="row" [hidden]="!(distance?.ErrorCode>0)">
          <div class="col-md-12 col-12 roboto-regular-font font-18 color-regular font-weight-bold">
            <p>
              <!-- <span i18n="@@show_distance">Show Distance</span> -->
              <span>{{distance?.LocalizedErrorMessage}}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="this.showDetails&&(routeDetails$ | async) as routeDetails;">
        <div class="col-md-12">
          <div *ngIf="!routeDetails.ErrorDescription else routeDetailsError">
            <div class="row">
              <div class="col-md-4 col-4 roboto-regular-font font-14 font-weight-bold pt-2">
                <span i18n="@@From">From</span>
              </div>
              <div class="col-md-8 col-8 roboto-regular-font font-14 font-weight-bold pt-2">
                <span i18n="@@Vehicle">Shipment</span>
                <span>: {{ this.distanceToTargetsRequest.TrackerId }}</span>
              </div>
            </div>
            <div class="row">
              <div class="offset-md-4 col-md-8 col-8 roboto-regular-font font-12 pt-2">
                <span>{{ routeDetails.Origin?.FullAddress }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-4 roboto-regular-font font-14 font-weight-bold pt-2">
                <span i18n="@@To">To</span>
              </div>
              <div class="col-md-8 col-8 roboto-regular-font font-14 font-weight-bold pt-2">
                <span i18n="@@label_address">Address:</span>
              </div>
            </div>
            <div class="row">
              <div class="offset-md-4 col-md-8 col-8 roboto-regular-font font-12 pt-2">
                <span>{{ routeDetails.Destination?.FullAddress }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-4 roboto-regular-font font-14 font-weight-bold pt-2">
                <span i18n="@@Distance">Distance:</span>
              </div>
              <div class="col-md-8 col-8 roboto-regular-font font-12 pt-2">
                <span>{{ routeDetails.DistanceToDestinationString }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-4 roboto-regular-font font-14 font-weight-bold pt-2">
                <span i18n="@@duration">Duration:</span>
              </div>
              <div class="col-md-8 col-8 roboto-regular-font font-12 pt-2">
                <span>
                  <b>{{ routeDetails.DurationString }}</b>
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-4 roboto-regular-font font-14 font-weight-bold pt-2">
                <span i18n="@@directions">Directions</span>:&ensp;
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row overflowDiv">
                  <ol>
                    <li *ngFor="let leg of routeDetails.Legs" [innerHTML]="getHTMLValue(leg.Description)"
                      class="roboto-regular-font"></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <ng-template #routeDetailsError>
            <div class="div-min-height-375">
              {{routeDetails.LocalizedErrorMessage}}
            </div>
          </ng-template>

          <div class="row mt-2">
            <div class="col-md-12 text-center">
              <!-- <emr-button identifier="closeButton" cssClass="btn button-text btn button-text btn-outline-default mt-0"
                buttonText="Back" i18n-buttonText="@@label_back" (onClick)="onCloseClick()" *ngIf="showDetails"
                buttonIcon="fa fa-arrow-circle-left">
              </emr-button> -->
              <emr-button identifier="closeButton" cssClass="btn button-text btn button-text btn-outline-default mt-0"
                buttonText="Close" i18n-buttonText="@@close" (onClick)="onCloseClick()">
              </emr-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>