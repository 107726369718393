import { IAlertStatusState } from 'app-modules/core/store/models/alert-status-state.interface';
import { initialState } from './initial-state';
import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';
import * as alertActions from 'app-modules/core/store/actions/shipment-detail-alert.actions';
import * as altActions from 'app-modules/core/store/actions/alert.actions';
import { AlertActionStatus } from 'emr-ng-shared';

type Actions =
    authActions.SignOut |
    alertActions.LoadAlertList |
    alertActions.LoadAlertListSuccess |
    alertActions.LoadAlertListError |
    detailActions.SelectShipment |
    altActions.CloseAlertSuccess;

export function ShipmentDetailAlertReducer(
    state: IAlertStatusState = initialState.shipmentDetailAlert,
    action: Actions
): IAlertStatusState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailAlert;

        case actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS_SUCCESS:
            return {
                ...state,
                alertList: action.alertList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.CLOSE_ALERT_SUCCESS:
            const newState: IAlertStatusState = {
                ...state,
                isLoading: false
            };
            const finalAlertList = { ...newState.alertList };
            const alertData = finalAlertList.list.find(a => a.Id === action.alertId);
            if (alertData) {
                alertData.Status = AlertActionStatus.Closed;
                alertData.StatusString = AlertActionStatus[AlertActionStatus.Closed];
            }
            newState.alertList = finalAlertList;
            return newState;
        case actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS_ERROR:
            return {
                ...state,
                errorMessage: action.message
            };
        default:
            return state;
    }
}
