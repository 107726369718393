import { ITimeZoneState } from 'app-modules/core/store/models/time-zone-state.interface';

import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as userSettingActions from './user-settings.actions';
import * as customerActions from 'app-modules/core/store/actions/customer.actions';
import { IUserSettings } from '../models/user-settings-state.interface';
import { UserSetting } from 'app-modules/core/models/user-setting.model';
import _ from 'lodash';

type Actions =
    authActions.SignOut |
    userSettingActions.LoadUserSettingsWithStore |
    userSettingActions.LoadUserSettingsSuccessWithStore |
    userSettingActions.LoadUserSettingsWithStoreError |
    userSettingActions.CancelLoadUserSettings |
    userSettingActions.SaveUserSettingsSuccess |
    customerActions.SelectCustomerSuccess;

export function UserSettingsReducer(state: IUserSettings = initialState.userSettings, action: Actions): IUserSettings {
    switch (action.type) {

        case actionIndex.LOAD_USER_SETTINGS_WITH_STORE:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: true
            };
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                Value: null,
                isLoadRequired: true,
                isLoading: true
            };
        case actionIndex.SAVE_USER_SETTINGS_SUCCESS:
            const old: UserSetting[] = state.Value;
            let isDataUpdated = false;
            old.forEach(x => {
                if (x.Section === action.value.Section && x.Key === action.value.Key) {
                    isDataUpdated = true;
                    x.Value = action.value.Value;
                }
            });
            if (!isDataUpdated) {
                old.push(action.value);
            }

            return { 
                ...state,
                Value: [...old]
            };
        case actionIndex.LOAD_USER_SETTINGS_SUCCESS_WITH_STORE:
            {
                const old: UserSetting[] = state.Value;
                const newValues: UserSetting[] = action.value;
                const newSettings = [];
                newValues.forEach(n => {
                    let setting = old?.find(x => x.Section === n.Section && x.Key === n.Key);
                    if (setting) {
                        setting.Value = n.Value;
                    } else {
                        setting = n;
                    }
                    newSettings.push(setting);
                });

                return { 
                    ...state,
                    Value: [...newSettings]
                };
            }

        case actionIndex.LOAD_USER_SETTINGS_WITH_STORE_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.CANCEL_LOAD_USER_SETTINGS:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false
            };

        default:
            return {
                ...state
            };
    }
}
