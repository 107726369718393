import { Component, EventEmitter, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { IMapOptions, MapTypeId, IBox, EmrUtilService, IMarkerOptions, IMarkerMetadata, MarkerEventArgs } from 'emr-ng-shared';
import { CompareTraxxModel } from 'app-modules/compare-traxx/models/compare-traxx.model';
import { ComparetraxxService, dynamicIconOffset } from 'app-modules/compare-traxx/services/comparetraxx.service';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { Subscription } from 'rxjs';
import { CustomersService } from 'app-modules/core/services/customer.service';

@Component({
  selector: 'app-compare-traxx-view',
  templateUrl: './compare-traxx-view.component.html',
  styleUrls: ['./compare-traxx-view.component.css']
})
export class CompareTraxxViewComponent implements OnInit, OnDestroy {
  TrackersString = '';
  trackers: CompareTraxxModel[];
  trackerError: IErrorInfo;
  compareTraxxSubscription: Subscription;
  public options: IMapOptions = {
    disableBirdseye: false,
    disableStreetside: false,
    navigationBarMode: 1,
    zoom: 3,
    mapTypeId: MapTypeId.road
  };

  private defaultBox = this.custSvc?.defaultBox;

  public box: IBox = this.defaultBox;

  lineWidths = [
    { text: '2', value: 2 },
    { text: '3', value: 3 },
    { text: '4', value: 4 },
    { text: '5', value: 5 },
    { text: '6', value: 6 },
    { text: '7', value: 7 }
  ];

  lineWidth = 2;
  @ViewChild(NgForm) form: NgForm;

  private useAPIIcons = true;
  set showAPIIcons(v: boolean) {
    this.useAPIIcons = v;
    // setTimeout(_ => this.updatePushPins(), 0);
    this.updatePushPins();
  }
  get showAPIIcons(): boolean {
    return this.useAPIIcons;
  }

  repairCrumbs = false;

  ShowInfoBox = new EventEmitter<MarkerEventArgs>(); 

  constructor(
    private traxxSvc: ComparetraxxService,
    private utilSvc: EmrUtilService,
    private custSvc: CustomersService,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.compareTraxxSubscription?.unsubscribe();
  }

  onTrackShipment() {
    if (!this.form.valid) {
      this.trackers = [];
      this.box = this.defaultBox;
      return;
    }
    let enteredValue = this.TrackersString ? this.TrackersString.trim() : '';
    enteredValue = enteredValue.replace(/,|\s+/g, '\n').trim().replace(/\n+/g, ',');
    const box = this.utilSvc.getNewBoxParams();
    this.compareTraxxSubscription =
      this.traxxSvc.CompareTrackers(enteredValue, box, this.useAPIIcons, this.repairCrumbs)
        .pipe(take(1))
        .subscribe(n => {
          this.trackerError = null;
          this.trackers = n;
          this.box = box;
        },
        (e: IErrorInfo) => {
          this.trackerError = e;
          this.trackers = [];
          this.box = this.defaultBox;
        });
  }

  renderSVG(model: CompareTraxxModel) {
    return this.sanitizer.bypassSecurityTrustHtml(model.pushpinIcon);
  }


  getTemplateInfo(markerMetadatas:Array<IMarkerMetadata> ,flag:string):any{
    const sourceMarker = markerMetadatas.find(p=>p.isSourceMarker);
    return sourceMarker.metadata.get(flag);
  }

  private updatePushPins() {
    if (this.trackers && this.trackers.length > 0) {
      this.trackers.forEach(t => {
        if (!t.HasError &&
            t.markers &&
            t.markers.length > 0) {
          const markers = new Array<IMarkerOptions>();
          t.markers.forEach(m => {
            const icon = this.useAPIIcons ? m.metadata.get('imageURL') : m.metadata.get('svgURL');
            const anchor = this.useAPIIcons ? m.metadata.get('imageURLOffset') : dynamicIconOffset;
            if (this.useAPIIcons) {
              delete m.anchor;
            }
            let newPushPin = { ...m, icon };
            if (anchor) {
              newPushPin = { ...newPushPin, anchor };
            }
            markers.push(newPushPin);
          });
          t.markers = markers;
        }
      });
    }
  }

}
