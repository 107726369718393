import * as actionIndex from 'app-modules/core/store/actions';
import { ScheduledReports, ScheduleReportRequest } from 'emr-ng-shared';
import { ServerRequestAction, ServerRequestCompleteAction } from '../actions/custom-actions';
import { IListInfo } from '../models/list-info-state.interface';

export class ScheduleReport implements ServerRequestAction {
    readonly type = actionIndex.SCHEDULE_REPORT;
}

export class ScheduleReportSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SCHEDULE_REPORT_SUCCESS;
    readonly callingAction = actionIndex.SCHEDULE_REPORT;
    constructor(public request: ScheduleReportRequest, public isDelete: boolean) { }
}

export class ScheduleReportError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SCHEDULE_REPORT_ERROR;
    readonly callingAction = actionIndex.SCHEDULE_REPORT;
}

export class CancelScheduleReport implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SCHEDULE_REPORT;
    readonly callingAction = actionIndex.SCHEDULE_REPORT;
}

export class ReloadScheduledReports implements ServerRequestAction {
    readonly type = actionIndex.RELOAD_SCHEDULE_REPORTS;
}

export class LoadScheduledReports implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SCHEDULE_REPORTS;
}

export class LoadScheduledReportsSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SCHEDULE_REPORTS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SCHEDULE_REPORTS;
    constructor(public scheduledReportList: IListInfo<ScheduledReports>) { }
}

export class LoadScheduledReportsError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SCHEDULE_REPORTS_ERROR;
    readonly callingAction = actionIndex.LOAD_SCHEDULE_REPORTS;
    constructor(public message: string) { }
}

export class CancelLoadScheduledReports implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.LOAD_SCHEDULE_REPORTS;
    readonly type = actionIndex.CANCEL_LOAD_SCHEDULE_REPORTS;
}