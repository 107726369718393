import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params, convertToParamMap } from '@angular/router';
import { take, skip, switchMap } from 'rxjs/operators';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { environment } from 'environments/environment';

import { Observable, Subscription } from 'rxjs';
import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { InitializationService } from 'app-modules/core/services/initialization.service';
import { Fullstory, LoginResponse } from 'emr-ng-shared';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LinkExpiredResponseComponent } from '../link-expired-response/link-expired-response.component';

@Component({
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SignInComponent implements OnInit, OnDestroy {
    public error: IErrorInfo;
    public trackerError: IErrorInfo;
    private authState$: Observable<IAuthState>;
    unAuthShipmentListSubscription: Subscription;
    selectCustomerSubscription: Subscription;
    isSelectedSubscription: Subscription;
    signIn2Subscription: Subscription;
    signIn1Subscription: Subscription;
    UnAuthTrackers: string;
    IsFromAuth = false;
    showDetail = false;
    @ViewChild(NgForm, { static: true }) trackShipmentForm: NgForm;
    public externalAuthToken;
    bsModalRef: any;
    invalidSerialNumberChars = environment.production
        ? /\d(?!\d|-\d|\s|,|$)|-(?!\d)|\s(?!(\s|\d|$))|,(?!\s|\d|\s+\d)|\d+-\d+-|^(,|\D)/                       // in PROD, serial numbers form is digitals or digitals-digitals
        : /\d(?!\d|-\d|\s|,|$)|-(?!\d)|\s(?!(\s|[TGX0-9]|$))|^(?!(\s|[TGX0-9]|$))|,(?!\s|[TGX0-9])|\d+-\d+-|TGX(?!\d)/;   // in non-PROD，serial numbers form is digitals or digitals-digitals or 'T'digitals
    // validSerialNumberChars = environment.production ? 
    //                             /^((\s|,)*(\d+-?\d+)*)*$/ : // Do not allow any characters in PROD
    //                             /^((\s|,)*(\bT?\d+-?\d+)*)*$/; // Allow 'T' in serial numbers in non-PROD

    constructor(
        private shipSvc: ShipmentService,
        private authSvc: AuthService,
        private custSvc: CustomersService,
        private initSvc: InitializationService,
        private routerSvc: Router,
        private unAuthSvc: UnAuthService,
        private toastr: ToastrService,
        private modalSvc: BsModalService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.unAuthSvc.setUnAuthenticationToken(false);
        this.unAuthSvc.clearUnAuthCustomer();
        this.authSvc.setUserLoginState(null);
        this.initSvc.InitAppLoad();
        this.externalAuthToken = this.route.snapshot.paramMap.get('externalAuthToken');
        if (this.externalAuthToken !== 'trackerlookup') {
            this.IsFromAuth = false;
            if (this.externalAuthToken && this.externalAuthToken.length > 0) {
                this.processSignInExternal(
                    this,
                    this.externalAuthToken,
                    false,
                    this.onSignInSuccess,
                    this.onSignInFailure
                );
                this.externalAuthToken = false;
            }
        } else {
            this.IsFromAuth = true;

            const queryParams = this.toLowerQueryParamMap(this.route.snapshot.queryParamMap);
            const goNumbers = queryParams.get('txtgonumbers');
            const now = new Date();
            const currentDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            let expDate = currentDate;
            const exp = queryParams.get('expire');
            if (exp) {
                expDate = new Date(exp);
            }
            if (!exp || currentDate <= expDate) {
                if (goNumbers && goNumbers.trim()) {
                    this.UnAuthTrackers = goNumbers.trim().replace(/,|\s+/g, '\n').replace(/\n+/g, '\n');
                    this.shipSvc.IsFromUnAuthURL = true;
                    const openDetail = queryParams.get('isdetail');
                    this.showDetail = openDetail === '1';
                    this.onTrackShipment();
                } else if (!this.unAuthSvc.validateUSettingsForNull()) {
                    this.unAuthSvc.hideSettingsMenu(false);
                }
            } else if (exp) {
                this.showLinkExpiredMessage();
            }
        }
    }

    ngOnDestroy() {
        if (this.unAuthShipmentListSubscription) {
            this.unAuthShipmentListSubscription.unsubscribe();
        }
        if (this.selectCustomerSubscription) {
            this.selectCustomerSubscription.unsubscribe();
        }
        if (this.isSelectedSubscription) {
            this.isSelectedSubscription.unsubscribe();
        }
        if (this.signIn2Subscription) {
            this.signIn2Subscription.unsubscribe();
        }
        if (this.signIn1Subscription) {
            this.signIn1Subscription.unsubscribe();
        }
    }

    onSignInSubmitted(credentials: { username: string, password: string, rememberMe: boolean }) {
        this.unAuthSvc.setUnAuthenticationToken(false);
        sessionStorage.removeItem(environment.unAuthTrackers);
        this.requestSignIn(
            this,
            credentials.username,
            credentials.password,
            credentials.rememberMe,
            this.onSignInSuccess,
            this.onSignInFailure
        );
    }

    onTrackShipment() {
        // localStorage.removeItem(environment.unAuthUserSettings);
        let enteredValue = this.UnAuthTrackers ? this.UnAuthTrackers.trim() : '';
        if (this.trackShipmentForm.valid && enteredValue && !this.invalidSerialNumberChars.test(enteredValue)) {
            this.unAuthSvc.setUnAuthenticationToken(true);
            // this.shipSvc.isLoadRequired$ = of(true);
            sessionStorage.removeItem(environment.unAuthTrackers);
            // enteredValue = enteredValue.replace(/\s*,\s*|\s+,/g, ' ').replace(/(\s\s+|\n|\r\n|\r|,)/g, ' ');
            // enteredValue = enteredValue.trim().replace(/ /g, ',');
            enteredValue = enteredValue.replace(/,|\s+/g, '\n').trim().replace(/\n+/g, ',');
            const trackers = new Set(enteredValue.split(','));
            enteredValue = Array.from(trackers).join(',');
            sessionStorage.setItem(environment.unAuthTrackers, enteredValue);
            this.unAuthShipmentListSubscription = this.shipSvc.getUnAuthShipmentList().subscribe(n => {
                if (n.ErrorCode === 0) {
                    if (!this.showDetail || trackers.size > 1) {
                        this.routerSvc.navigate(['/umapview']);
                    } else {
                        this.routerSvc.navigate(['/uShipment'], { state: { shipment: n.list[0] } });
                    }
                } else {
                    sessionStorage.removeItem(environment.unAuthTrackers);
                    this.shipSvc.IsFromUnAuthURL = false;
                    // this.unAuthSvc.setUnAuthenticationToken(false);
                    this.trackerError = { code: n.ErrorCode, message: n.LocalizedErrorMessage };
                }
            },
                e => {
                    this.trackerError = e;
                });
        }
    }

    private requestSignIn(
        component: SignInComponent,
        username: string,
        password: string,
        rememberMe: boolean,
        successCallback,
        failureCallback) {

        this.signIn1Subscription = this.authSvc.signIn(username, password, rememberMe).pipe(take(1)).subscribe(
            isAuthenticated => {
                if (isAuthenticated) {
                    this.authSvc.setUserLoginState(true);
                    successCallback(component, rememberMe);
                } else {
                    failureCallback();
                }
            },
            error => failureCallback(component, error)
        );
    }

    private processSignInExternal(
        component: SignInComponent,
        externalAuthToken: string,
        rememberMe: boolean,
        successCallback,
        failureCallback) {

        this.signIn2Subscription = this.authSvc.signInExternal(externalAuthToken).pipe(take(1)).subscribe(
            isAuthenticated => {
                if (isAuthenticated) {
                    successCallback(component, rememberMe);
                } else {
                    failureCallback();
                }
            },
            error => failureCallback(component, error)
        );
    }

    private onSignInSuccess(component: SignInComponent, rememberMe: boolean) {
        component.initSvc.InitAuthLoad();

        if (rememberMe) {
            // Persist refresh token
        }
        
        // component.routerSvc.navigate(['/']); 
        component.custSvc.customerList$.pipe(skip(1), take(1)).subscribe(a => {
            if (a.list.length > 1) {
                component.routerSvc.navigate(['/selectcustomer']);
            } else {
                const customerId = a.list.find(b => Boolean(b.CustomerAccessToken)).CustomerId;
                // this.custSvc.selectCustomer(customerId).pipe(take(1)).subscribe();
                component.selectCustomer(component, customerId, component.onSelectCustomerSuccess, component.onSelectCustomerFailure);
            }
        });
        component.authSvc.isEDIAPIConsumer$.pipe(take(1)).subscribe(k => {
            if (k) {
                component.routerSvc.navigate([component.authSvc.getDefaultRoute()]);
            }
        })
    }

    private onSignInFailure(component: SignInComponent, error: IErrorInfo) {
        // console.log(error.debugMessage);
        component.error = error;
    }

    private selectCustomer(
        component: SignInComponent,
        customerId: number,
        successCallback,
        failureCallback) {

        this.selectCustomerSubscription = this.custSvc.selectCustomer(customerId).pipe(take(1)).subscribe(b => {
            this.isSelectedSubscription = this.custSvc.isSelected$.pipe(
                take(1),
                switchMap(n => this.custSvc.mapViewPreRequisites(n))
            ).subscribe(a => {
                if (a) {
                    component.custSvc.onCustomerChange(b);
                    successCallback(component);
                } else {
                    failureCallback();
                }
            });
        });
    }

    private onSelectCustomerSuccess(component: SignInComponent) {
        component.routerSvc.navigate([component.authSvc.getDefaultRoute()]);
    }

    private onSelectCustomerFailure() { }

    private toLowerQueryParamMap(queryParamMap: ParamMap) {
        const lowerQueryParamMap: Params = {};
        queryParamMap.keys.forEach(key => {
            lowerQueryParamMap[key.toLowerCase()] = queryParamMap.get(key);
        });
        return convertToParamMap(lowerQueryParamMap);
    }

    private showLinkExpiredMessage() {
        this.IsFromAuth = false;
        this.externalAuthToken = false;
        this.bsModalRef = this.modalSvc.show(
            LinkExpiredResponseComponent,
            {
                initialState: {
                    showCloseButton: true
                },
                class: 'modal-md modal-dialog-centered'
            }
        );
    }
}
