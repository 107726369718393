import { Injectable } from '@angular/core'; 
import { Store } from '@ngrx/store'; 
import { IAppState } from 'app-modules/core/store/models/app-state.interface'; 
import * as importLocActions from 'app-modules/core/store/actions/import-locations.actions';

@Injectable()
export class ImportLocationsStateService {
    constructor(private store: Store<IAppState>) { }

    // Actions
    public importLocationList() {
        this.store.dispatch(new importLocActions.ImportLocationList());
    }

    public importLocationListSuccess(data: string) {
        this.store.dispatch(new importLocActions.ImportLocationListSuccess(data));
    }

    public importLocationListError(message: string) {
        this.store.dispatch(new importLocActions.ImportLocationListError(message));
    }

    public cancelImportLocationList() {
        this.store.dispatch(new importLocActions.CancelImportLocationList());
    }

    public saveImportLocationList() {
        this.store.dispatch(new importLocActions.SaveImportLocationList());
    }

    public saveImportLocationSuccess(data: string) {
        this.store.dispatch(new importLocActions.SaveImportLocationListSuccess(data));
    }

    public saveImportLocationError(message: string) {
        this.store.dispatch(new importLocActions.SaveImportLocationListError(message));
    }

    public cancelSaveImportLocationList() {
        this.store.dispatch(new importLocActions.CancelSaveImportLocationList());
    }
}


