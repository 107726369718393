import { SensorListInfo, AlertIconType, TripLateStatus } from 'emr-ng-shared';

export class Shipment {
    trackerId: string;
    customerTrackerId: string;
    isMultiTrip: boolean;
    IsDVShipment: boolean;
    tripId: number;
    tripStatus: string;
    tripStateCode: number;
    LastReportedTimestamp: string;
    LastReportedTimestampFormatted: string;
    FirstReportedSatTime: string;
    FirstReportedSatTimeFormatted: string;
    tripName: string;
    serialNumber: string;
    temperature: string;
    temperatureNumber: number;
    battery: string;
    batteryLevel: number;
    notes: string;
    latitude: number;
    longitude: number;
    carrierName: string;
    StartLocation: string;
    TripEndLocationName: string;
    sensorRange: string;
    // Begin - new sensor range variables
    SensorTempRangeId: number;
    // End - new sensor range variables
    scheduledStartTime: string;
    scheduledEndTime: string;
    ScheduledStartTime: string;
    ScheduledEndTime: string;
    actualStartTime: string;
    actualEndTime: string;
    ActualStartTime: string;
    ActualEndTime: string;
    origin: string;
    originId: number;
    destination: string;
    destinationId: number;
    // Begin - missing bound variables
    message: string;
    alertSuppressed: boolean;
    NoTemperatureRequired: boolean;
    // End - missing bound variables
    CanSuspendTracker: boolean;
    CanResumeTracker: boolean;
    CanShutdownTracker: boolean;
    canCreateShipment: boolean;
    CanEditShipment: boolean;
    CanDeleteTrip: boolean;
    canCloseShipment: boolean;
    CanReopenShipment: boolean;
    ModelName: string;
    ModelNumber: string;
    ImageUrl: string;
    ImageUrlSVG: string;
    ImageId: string;
    ImageAnchorTop: number;
    ImageAnchorLeft: number;
    needsMarkerUpdate: boolean;
    AddressGeo: string;
    AddressGeoTimestamp: string;
    AddressGeoLoading?: boolean;
    AddressGeoTimestampFormatted: string;
    AddressOlderThanThreshold: boolean;
    ProductImageUrl: string;
    Description: string;
    TemperatureString: string;
    ProbeTemperatureString: string;
    ProbeTemperature: number;
    CarbonDioxideLevelString: string;
    CarbonDioxideLevel: number;
    HumidityLevelString: string;
    HumidityLevel: number;
    ProximityString: string;
    Proximity: number;
    SensorListInfo: SensorListInfo[];
    Is4G: boolean;
    CanSuspendAlerts: boolean;
    CanResumeAlerts: boolean;
    AlertIconCode: AlertIconType;
    TripLate: TripLateStatus;
    isMoving: boolean;

    LowWarnAlertCount: number;
    LowCriticalAlertCount: number;
    HighWarnAlertCount: number;
    HighCriticalAlertCount: number;
    TotalTimeBelowRange: number;
    TotalTimeAboveRange: number;
    TotalTimeOutOfRange: number;
    MaxTimeBelowRange: number;
    MaxTimeAboveRange: number;
    AvgSensorValue: number;
    MeanKineticTemperature: number;
    MinSensorValue: number;
    MaxSensorValue: number;
    SensorStandardDeviation: number;

    TotalTimeBelowRangeString: string;
    TotalTimeAboveRangeString: string;
    TotalTimeOutOfRangeString: string;
    MaxTimeBelowRangeString: string;
    MaxTimeAboveRangeString: string;
    AvgSensorValueString: string;
    MeanKineticTemperatureString: string;
    MinSensorValueString: string;
    MaxSensorValueString: string;
    SensorStandardDeviationString: string;

    AvgHumidityString: string;
    MinHumidityValueString: string;
    MaxHumidityValueString: string;
    HumidityStandardDeviationString: string;
    AvgHumidity: number;
    MinHumidityValue: number;
    MaxHumidityValue: number;
    HumidityStandardDeviation: number;

    AvgCarbonDioxideString: string;
    MinCarbonDioxideValueString: string;
    MaxCarbonDioxideValueString: string;
    CarbonDioxideStandardDeviationString: string;
    AvgCarbonDioxide: number;
    MinCarbonDioxideValue: number;
    MaxCarbonDioxideValue: number;
    CarbonDioxideStandardDeviation: number;

    AvgProbeString: string;
    MinProbeValueString: string;
    MaxProbeValueString: string;
    ProbeStandardDeviationString: string;
    AvgProbe: number;
    MinProbeValue: number;
    MaxProbeValue: number;
    ProbeStandardDeviation: number;

    AvgFuelLevelString: string;
    MinFuelLevelValueString: string;
    MaxFuelLevelValueString: string;
    FuelLevelStandardDeviationString: string;
    AvgFuelLevel: number;
    MinFuelLevelValue: number;
    MaxFuelLevelValue: number;
    FuelLevelStandardDeviation: number;

    AvgProximityString: string;
    MinProximityValueString: string;
    MaxProximityValueString: string;
    ProximityStandardDeviationString: string;
    AvgProximity: number;
    MinProximityValue: number;
    MaxProximityValue: number;
    ProximityStandardDeviation: number;

    hasTempStats: boolean;
    TripOriginAddressState: string;
    TripDestinationAddressState: string;

    TripETADistanceM?: number;
    TripETADistanceString: string;
    TripETAUTC?: Date;
    TripETA: string;
    TripETAFormatted: string;
    TripETAOlderThanThreshold: boolean;
    TripETALoading?: boolean;
    TripEndLocLat?: number;
    TripEndLocLon?: number;
    PlateNum: string;
    ContainerNum: string;
    DriverPhoneNum: string;
    emailAddresses: string;
    TripStartLocationFullAddress: string;
    TripStartLocationCity: string;
    TripStartLocationState: string;
    TripStartLocationCountry: string;
    TripStartLocationZipCode: string;
    TripEndLocationFullAddress: string;
    TripEndLocationCity: string;
    TripEndLocationState: string;
    TripEndLocationCountry: string;
    TripEndLocationZipCode: string;
    SmartTagUploadUTC?: Date;
    SmartTagUpload?: string;
    SmartTagUploadFormatted?: string;
    SmartTagQualityCode?: number;
    ReceiverPONum: string;
    SupplierName: string;
    DCTripStop: string;
    MostRecentPostDC: string;
    SupplierCustomerID?: number;
    DCTripStopLocationID?: number;
    MostRecentPostDCLocationID?: number;
    ActivationTime: Date;
    SharedWith: string;

    get groupName(): string {
        if (this.isMultiTrip) { return 'MultiTrip Serial #s'; }
        if (this.tripId) { return 'Inactive Serial #s'; }
        return 'Active Serial #s without a trip assigned';
    }
    get color(): string {
        if (this.isMultiTrip) { return 'green'; }
        if (this.tripId) { return '#757575de'; }
        return 'red';
    }
    get Id(): string {
        if (this.tripId) {
            return `${this.trackerId}_${this.tripId}`;
        } else {
            return `${this.trackerId}`;
        }
    }
}

