import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { TripStateInfo } from 'emr-ng-shared';

import { IShipmentFilterStatus } from 'app-modules/core/store/models/shipment-filter-status.interface';
import { ShipmentStatusService } from 'app-modules/core/services/shipment-status.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';

declare type selectableStatus = TripStateInfo & { checked: boolean };

@Component({
    selector: 'app-shipment-filter-status',
    templateUrl: './shipment-filter-status.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterStatusComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: number[];
    @Input() get filter() { return this.filterVal; }
    @Output() filterChange = new EventEmitter();
    set filter(val) {
        this.filterVal = val;
        this.filterChange.emit(this.filterVal);
    }

    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    statusList: selectableStatus[];

    private statusListSubscription: Subscription;
    private statusFilterSubscription: Subscription;

    constructor(
        public statusSvc: ShipmentStatusService,
        private filterSvc: ShipmentFilterService
    ) { }

    public ngOnInit() {
        this.statusListSubscription = this.statusSvc.shipmentStatusList$.subscribe(n => this.onStatusListSubscription(n.list));
        this.statusFilterSubscription = this.filterSvc.statusFilter$.subscribe(n => this.onStatusFilterSubscription(n));
    }

    public ngOnDestroy() {
        this.statusListSubscription.unsubscribe();
        this.statusFilterSubscription.unsubscribe();
    }

    onStatusListSubscription(statusList: TripStateInfo[]) {
        this.statusList = [];
        if (statusList) {
            statusList.forEach(item =>
                this.statusList.push({ ...item, checked: this.filter && this.filter.indexOf(item.TripStateCode) >= 0 })
            );
        }
    }

    onStatusFilterSubscription(filter: number[]) {
        this.isDirty = false;
        this.filter = filter;
        if (this.filter) {
            this.statusList.forEach(item => item.checked = this.filter.indexOf(item.TripStateCode) >= 0);
        }
    }
}


