import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TotalsComponent } from './components/totals/totals.component';
import { QuickLinksComponent } from './components/quick-links/quick-links.components';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { DashboardShipmentListComponent } from './components/dashboard-shipment-list/dashboard-shipment-list.component';
import { CoreModule } from 'app-modules/core/core.module';
import {
  DashboardShipmentListItemComponent
} from 'app-modules/dashboard/components/dashboard-shipment-list-item/dashboard-shipment-list-item.component';
import { ShipmentFilterModule } from 'app-modules/shipment-filter/shipment-filter.module';
import { CloseShipmentPopupComponent } from 'app-modules/dashboard/components/close-shipment-popup/close-shipment-popup.component';
import { CloseShipmentResponseComponent } from './components/close-shipment-response/close-shipment-response.component';
import { DocumentTableComponent } from './components/document-table/document-table.component';
import { CustomTableComponent } from './components/custom-table/custom-table.component';
import { ShipmentChartModule } from 'app-modules/shipment-chart/shipment-chart.module';
@NgModule({
  declarations: [
    DashboardComponent,
    TotalsComponent,
    QuickLinksComponent,
    DashboardShipmentListComponent,
    DashboardShipmentListItemComponent,
    CloseShipmentPopupComponent,
    CloseShipmentResponseComponent,
    DocumentTableComponent,
    CustomTableComponent
  ],
  imports: [
    CommonModule,
    EmrNgControlsModule,
    CoreModule,
    BsDropdownModule.forRoot(),
    ShipmentFilterModule,
    ShipmentChartModule
  ],
  exports: [
    DashboardComponent,
    DocumentTableComponent,
    CustomTableComponent
  ]
})
export class DashboardModule { }

