<div class="row">
    <div class="col-md-12">
      <div class="card alertbox roboto-regular-font font-12">
        <div class="row card-header p-1">
          <div class="col-md-12 left-align">
            <div class="font-14 lh-24">
              <b>
                <span>{{ collisionInfo?.Location?.LocationName }}</span>
              </b>
              <button translate="no" type="button"
                class="notranslate float-right close btn-modal custom-btn-info-click click-infobox-close infobox-close-img"
                aria-label="Close">
                <span aria-hidden="true" class="custom-btn-info-click click-infobox-close infobox-close-img">
                  <b class="custom-btn-info-click click-infobox-close infobox-close-img">&times;</b>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body p-1 pl-2 text-left">
            <div class="row">
                <div class="col-12">
                    <span>Location Name :</span>
                    <span>{{ collisionInfo?.Location?.LocationName }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Is In others fence:</span>
                    <span>{{ collisionInfo?.InOthersFence }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Fence Collided:</span>
                    <span>{{ collisionInfo?.FenceCollided }}</span>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>