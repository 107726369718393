import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { intl } from 'environments/intl';

@Injectable()
export class CompareTraxxGuard implements CanActivate {
    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (environment.allowCompareTraxx) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
}
