import { Component, EventEmitter, Input, Output, OnInit, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';

import { Observable } from 'rxjs';

import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { BaseURLPicker } from 'app-modules/core/services/baseurl-picker.service';
import { FILE_NAMES } from 'app-modules/core/consts/file-names';
import { signin, signinTermsAndConditionsHelpTip } from 'app-modules/core/consts/localization';
import { STORE_KEYS } from 'app-modules/core/consts/values';
@Component({
    selector: 'app-sign-in-form',
    templateUrl: './sign-in-form.component.html',
    styleUrls: ['./sign-in-form.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SignInFormComponent implements OnInit, OnChanges {
    @Input() settings: { hideRememberMe: boolean };
    @Input() error: IErrorInfo;
    @Output() submitted = new EventEmitter<{ username: string, password: string, rememberMe: boolean }>();

    authState: Observable<IAuthState>;
    username = '';
    password = '';
    private rememberMe = true;

    acceptedTermsAndConditionsURL = `${this.domainPicker.climateDomain}${FILE_NAMES.termsAndConditions}`;
    isAcceptedTermsAndConditions = false;
    disabledTermsConditions = false;
    disabledSignIn = false;
    signInHelpTip = '';

    constructor(private authSvc: AuthService, private domainPicker: BaseURLPicker) { }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.error && !changes.error.firstChange && changes.error.currentValue) {
            this.disabledSignIn = false;
        }
    }

    ngOnInit() {
        this.authState = this.authSvc.authState$;
        if (StoreManager.GetValue(STORE_KEYS.acceptedTermsAndConditions)) {
            this.changeTermsConditionsStatus(true);
        } else {
            this.changeTermsConditionsStatus(false);
        }
    }

    onSignInClick(event) {
        this.error = null;
        this.disabledSignIn = true;
        this.submitted.emit({ username: this.username, password: this.password, rememberMe: this.rememberMe });
        // this.password = '';
    }

    onClickTermsConditions() {
        StoreManager.StoreValue(STORE_KEYS.acceptedTermsAndConditions, 'Accepted',
            // tslint:disable-next-line: no-bitwise
            StoreOpts.LocalStorage | StoreOpts.Cookie | StoreOpts.StoreManager);
        this.changeTermsConditionsStatus(true);
    }

    private changeTermsConditionsStatus(disable: boolean) {
        if (disable) {
            this.isAcceptedTermsAndConditions = true;
            this.disabledTermsConditions = true;
            this.disabledSignIn = false;
            this.signInHelpTip = signin;
        } else {
            this.isAcceptedTermsAndConditions = false;
            this.disabledTermsConditions = false;
            this.disabledSignIn = true;
            this.signInHelpTip = signinTermsAndConditionsHelpTip;
        }
    }
}

