import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardTermsMessagePopup } from 'app-modules/core/models/dashboard-terms-message-popup.model';
import { DashboardTermsMessagePopupComponent } from '../dashboard-terms-message-popup/dashboard-terms-message-popup.component';

@Injectable()
export class DashboardTermsMessageService extends DashboardTermsMessagePopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenDashboardTermsMessagePopup(): void {
        const initialState = {};

        this.bsModalRef = this.modalSvc.show(
            DashboardTermsMessagePopupComponent,
            {
                initialState,
                class: 'terms-message modal-lg modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}


