<emr-modal headerTitle="Close Shipment"  i18n-headerTitle="@@close_shipment" [bsModalRef]=bsModalRef [showCloseButton]=showCloseButton>
    <div modal-body class="p-15">
        <div *ngIf="hasError; else noError">
            <p>{{ errorMessage }}</p>
        </div>
        <ng-template #noError>
            <div>
                <p *ngIf="shipment.isMultiTrip" i18n="@@message_closeshipment_multitrip">Are you sure you want to Close the {{ shipment.tripName }} Shipment?</p>
                <p *ngIf="!shipment.isMultiTrip" i18n="@@message_closeshipment_nonmultitrip">Are you sure you want to Close the {{ shipment.tripName }} Shipment?</p>
                <!-- <p>Are you sure you want to Close the {{ shipment.tripName }} Shipment?</p> -->
            </div>
            <div class="text-center clsShipmentButtons">
                <emr-button identifier="closeShipmentYes" cssClass="btn-background mr-20" buttonType="button" buttonText="Yes" (click)='onClickYes()'></emr-button>
                <emr-button identifier="closeShipmentNo" cssClass="btn-background" buttonType="button" buttonText="No" (click)='onClickNo()'></emr-button> 
            </div>
        </ng-template>
        
    </div>
</emr-modal>
