import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { TimeZoneInfo } from 'emr-ng-shared';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTimeZones implements Action {
    readonly type = actionIndex.LOAD_TIME_ZONES;
}

export class LoadTimeZonesSuccess implements Action {
    readonly type = actionIndex.LOAD_TIME_ZONES_SUCCESS;
    constructor(public timeZoneList: IListInfo<TimeZoneInfo>) { }
}

export class LoadTimeZonesError implements Action {
    readonly type = actionIndex.LOAD_TIME_ZONES_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadTimeZones implements Action {
    readonly type = actionIndex.CANCEL_LOAD_TIME_ZONES;
}

