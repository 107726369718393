import { RouteDeviationListItem } from 'emr-ng-shared';
import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from '../models/list-info-state.interface';

export class LoadSavedRouteDeviation implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SAVED_ROUTE_DEVIATION;
}

export class LoadSavedRouteDeviationSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SAVED_ROUTE_DEVIATION_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SAVED_ROUTE_DEVIATION;
    constructor() { }
}

export class LoadSavedRouteDeviationError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SAVED_ROUTE_DEVIATION_ERROR;
    readonly callingAction = actionIndex.LOAD_SAVED_ROUTE_DEVIATION;
    constructor(public payload: string) { }
}

export class CancelLoadSavedRouteDeviation implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_SAVED_ROUTE_DEVIATION;
    readonly callingAction = actionIndex.LOAD_SAVED_ROUTE_DEVIATION;
}

export class GetSavedRouteDeviation implements ServerRequestAction {
    readonly type = actionIndex.GET_SAVED_ROUTE_DEVIATION;
}

export class GetSavedRouteDeviationSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.GET_SAVED_ROUTE_DEVIATION_SUCCESS;
    readonly callingAction = actionIndex.GET_SAVED_ROUTE_DEVIATION;
}

export class GetSavedRouteDeviationError implements ServerRequestCompleteAction {
    readonly type = actionIndex.GET_SAVED_ROUTE_DEVIATION_ERROR;
    readonly callingAction = actionIndex.GET_SAVED_ROUTE_DEVIATION;
    constructor() { }
}
