import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, finalize, share, catchError, startWith } from 'rxjs/operators';

import { Contact, DeleteGeofenceRequest, DeleteGeofenceResponse, GetLocationBoundaryRequest, GetLocationBoundaryResponse, muteFirst, SaveLocationContactRequest, SaveLocationContactResponse } from 'emr-ng-shared';

import { GetLocationRequest } from 'emr-ng-shared';
import { SetLocationRequest, SetLocationResponse } from 'emr-ng-shared';
import { LocationInfo } from 'emr-ng-shared';

import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { LocationStateService } from 'app-modules/core/store/services/location-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { handleErrorResponse } from '../rxjs/operators/handle-error-response.operator';
import { LocationColor } from 'emr-ng-shared/lib/services/emr-oversight/models/location-color-model';
import { LOCATION_COLORS } from '../consts/location-colors';
import { DeleteLocationRequest } from 'emr-ng-shared/lib/services/emr-oversight/models/delete-location-request.model';
import { DeleteLocationResponse } from 'emr-ng-shared/lib/services/emr-oversight/models/delete-location-response.model';

@Injectable()
export class LocationService {

    constructor(
        private locStateSvc: LocationStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.locStateSvc.isLoadRequired$;

    public isLoading$ = this.locStateSvc.isLoading$;

    public locationListIncludingCopy$: Observable<IListInfo<LocationInfo>> = muteFirst(
        this.getLocationListLoader().pipe(startWith(null)),
        this.locStateSvc.locationListIncludingCopy$
    );

    public locationList$: Observable<IListInfo<LocationInfo>> = muteFirst(
        this.locationListIncludingCopy$.pipe(startWith(null)),
        this.locStateSvc.locationList$
    );

    public originList$: Observable<IListInfo<LocationInfo>> = muteFirst(
        this.locationList$.pipe(startWith(null)),
        this.locStateSvc.originList$
    );

    public stopList$: Observable<IListInfo<LocationInfo>> = muteFirst(
        this.locationList$.pipe(startWith(null)),
        this.locStateSvc.stopList$
    );

    public destinationList$: Observable<IListInfo<LocationInfo>> = muteFirst(
        this.locationList$.pipe(startWith(null)),
        this.locStateSvc.destinationList$
    );

    private getLocationListLoader(): Observable<IListInfo<LocationInfo>> {
        return this.locStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.locStateSvc.loadLocationList()),
            switchMap(() => this.getLocationList()),
            tap(
                n => this.locStateSvc.loadLocationListSuccess(n),
                e => this.locStateSvc.loadLocationListError('')
            ),
            finalize(() => this.locStateSvc.cancelLoadLocationList()),
            catchError(() => {
                this.locStateSvc.cancelLoadLocationList();
                return of(emptyList());
            }),
            share()
        );
    }

    private getLocationList(): Observable<IListInfo<LocationInfo>> {
        const request = new GetLocationRequest();
        request.ReturnBoundary = true;
        request.IncludeCopiedFrom = true;
        return this.oversightSvc.GetLocations(request).pipe(
            map(n => {
                return {
                    list: n.LocationList.map(l => {
                        l.BoundaryLoaded = true;
                        return l;
                    }),
                    itemCount: n.LocationList.length,
                    isPaged: false
                };
            })
        );
    }

    public createLocation(request: SetLocationRequest, copiedFromCustomerId: number = null) {
        return of(request).pipe(
            tap(n => this.locStateSvc.createLocation(n)),
            switchMap(n => this.oversightSvc.SetLocation(n)),
            handleErrorResponse(),
            map<SetLocationResponse, SetLocationResponse>(n => n),
            tap(
                n => {
                    if (n.LocationId) {
                        const locationInfo = this.getLocationInfo(request, n.LocationId);
                        if (request.ShipperCustomerID) {
                            // In edit case we should set Linked Customer Id as well..
                            locationInfo.LinkedCustomerId = request.ShipperCustomerID;
                        }
                        if (copiedFromCustomerId) {
                            locationInfo.CopiedFromCustomerId = copiedFromCustomerId;
                        }
                        this.locStateSvc.createLocationSuccess(locationInfo);
                    } else {
                        this.locStateSvc.cancelCreateLocation();
                    }
                },
                e => this.locStateSvc.createLocationError(e)
            )
        );
    }

    public validateLocation(request: SetLocationRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.validateLocation(n)),
            switchMap(n => this.oversightSvc.GetNearestLocations(n)),
            handleErrorResponse(),
            map<SetLocationResponse, SetLocationResponse>(n => n),
            tap(
                n => {
                    if (n.LocationId) {
                        const locationInfo = this.getLocationInfo(request, n.LocationId);
                        if (request.LocationId && request.LinkedCustomerId) {
                            // In edit case we should set Linked Customer Id as well..
                            locationInfo.LinkedCustomerId = request.LinkedCustomerId;
                        }
                        this.locStateSvc.validateLocationSuccess(locationInfo);
                    } else {
                        this.locStateSvc.cancelValidateLocation();
                    }
                },
                e => this.locStateSvc.validateLocationError(e)
            )
        );
    }

    public findLocation(request: SetLocationRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.validateLocation(n)),
            switchMap(n => this.oversightSvc.FindAddressByCoordinates(n)),
            handleErrorResponse(),
            map<SetLocationResponse, SetLocationResponse>(n => n),
            tap(
                n => {
                    if (n?.DefaultLocation) {
                        const locationInfo = n.DefaultLocation;
                        this.locStateSvc.validateLocationSuccess(locationInfo);
                    } else {
                        this.locStateSvc.cancelValidateLocation();
                    }
                },
                e => this.locStateSvc.validateLocationError(e)
            )
        );
    }

    public deleteLocationGeofence(request: DeleteGeofenceRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.deleteLocationGeofence(n)),
            switchMap(n => this.oversightSvc.DeleteLocationGeofence(n)),
            handleErrorResponse(),
            map<DeleteGeofenceResponse, DeleteGeofenceResponse>(n => n),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.locStateSvc.deleteGeofenceSuccess(request);
                    } else {
                        this.locStateSvc.cancelDeleteGeofence();
                    }
                },
                e => this.locStateSvc.deleteGeofenceError(e)
            )
        );
    }

    public saveLocationGeofence(request: SetLocationRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.saveLocationGeofence(n)),
            switchMap(n => this.oversightSvc.SaveLocationGeofence(n)),
            handleErrorResponse(),
            map<SetLocationResponse, SetLocationResponse>(n => n),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.locStateSvc.saveGeofenceSuccess(request);
                    } else {
                        this.locStateSvc.cancelSaveGeofence();
                    }
                },
                e => this.locStateSvc.saveGeofenceError(e)
            )
        );
    }

    public getLocationBoundary(request: GetLocationBoundaryRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.getLocationBoundary(n)),
            switchMap(n => this.oversightSvc.GetLocationBoundary(n)),
            handleErrorResponse(),
            map<GetLocationBoundaryResponse, GetLocationBoundaryResponse>(n => n),
            tap(
                n => {
                    if (n?.LocationInfo) {
                        const locationInfo = n.LocationInfo;
                        this.locStateSvc.getLocationBoundarySuccess(locationInfo);
                    } else {
                        this.locStateSvc.cancelGetLocationBoundary();
                    }
                },
                e => this.locStateSvc.getLocationBoundaryError(e)
            )
        );
    }

    public getLocationContact(request: SaveLocationContactRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.getLocationContact(n)),
            switchMap(n => this.getLocationContactList(n)),
            tap(
                n => this.locStateSvc.getLocationContactSuccess(request.LocationId, n.list),
                e => this.locStateSvc.getLocationContactError(e)
            ),
            finalize(() => this.locStateSvc.cancelGetLocationContact()),
            catchError(() => {
                this.locStateSvc.cancelGetLocationContact();
                return of(emptyList());
            }),
            share()
        );
    }

    public saveLocationContact(request: SaveLocationContactRequest, contact: Contact) {
        return of(request).pipe(
            tap(n => this.locStateSvc.setLocationContact(n)),
            switchMap(n => this.oversightSvc.SetLocationContact(n)),
            handleErrorResponse(),
            map<SaveLocationContactResponse, SaveLocationContactResponse>(n => n),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.locStateSvc.setLocationContactSuccess(request, contact);
                    } else {
                        this.locStateSvc.cancelSetLocationContact();
                    }
                },
                e => this.locStateSvc.setLocationContactError(e)
            )
        );
    }

    public updateLocationContact(locationID: number, contact: Contact) {
        this.locStateSvc.updateLocationContact(locationID, contact);
    }

    public deleteLocationContact(request: SaveLocationContactRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.deleteLocationContact(n)),
            switchMap(n => this.oversightSvc.DeleteLocationContact(n)),
            handleErrorResponse(),
            map<SaveLocationContactResponse, SaveLocationContactResponse>(n => n),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.locStateSvc.deleteLocationContactSuccess(request);
                    } else {
                        this.locStateSvc.cancelDeleteLocationContact();
                    }
                },
                e => this.locStateSvc.deleteLocationContactError(e)
            )
        );
    }

    public deleteLocation(request: DeleteLocationRequest) {
        return of(request).pipe(
            tap(n => this.locStateSvc.deleteLocation(n)),
            switchMap(n => this.oversightSvc.DeleteLocation(n)),
            handleErrorResponse(),
            map<DeleteLocationResponse, DeleteLocationResponse>(n => n),
            tap(
                n => {
                    if (n?.ErrorCode === 0) {
                        this.locStateSvc.deleteLocationSuccess(request);
                    } else {
                        this.locStateSvc.cancelDeleteLocation();
                    }
                },
                e => this.locStateSvc.deleteLocationError(e)
            )
        );
    }

    private getLocationContactList(request: SaveLocationContactRequest): Observable<IListInfo<Contact>> {
        return this.oversightSvc.GetLocationContact(request).pipe(
            map(n => {
                return {
                    list: n.Contacts,
                    itemCount: n.Contacts.length,
                    isPaged: false
                };
            })
        );
    }

    public getLocationInfo(locationRequest: SetLocationRequest, locationId: number, copyFromCustomerId: number = null): LocationInfo {
        if (!locationRequest.IsOrigin && !locationRequest.IsDestination) {
            locationRequest.IsOrigin = locationRequest.IsDestination = true;
        }
        return {
            LocationId: locationId,
            ShipmentName: '',
            LocationName: locationRequest.Name,
            Description: locationRequest.Description,
            Address: locationRequest.FullAddress,
            FullAddress: locationRequest.FullAddress,
            Address1: locationRequest.Address1,
            Address2: locationRequest.Address2,
            City: locationRequest.City,
            State: locationRequest.State,
            Zip: locationRequest.ZipCode,
            Country: locationRequest.Country,
            TimeZone: locationRequest.TimeZoneCode,
            Boundary: locationRequest.Boundary,
            ContactName: '',
            ContactPhone: '',
            IsOrigin: locationRequest.IsOrigin,
            IsDestination: locationRequest.IsDestination,
            ColorId: locationRequest.ColorId,
            ColorValue: '',
            Latitude: locationRequest.Latitude,
            Longitude: locationRequest.Longitude,
            TypeCode: locationRequest.TypeCode,
            SuppressAlerts: locationRequest.SuppressAlerts,
            Authorized: locationRequest.Authorized,
            ResetAlertsUponEntry: locationRequest.ResetAlertsUponEntry,
            LocationAlerts: locationRequest.LocationAlerts,
            IsShipTo: locationRequest.IsShipTo,
            MinTrackerInventory: locationRequest.MinTrackerInventory,
            BoundaryLoaded: locationRequest.Boundary?.length > 0 || locationRequest.RadiusKm > 0,
            RadiusKm: locationRequest.RadiusKm,
            HasBoundary: locationRequest.Boundary?.length > 0 || locationRequest.RadiusKm > 0,
            CopiedFromCustomerId: copyFromCustomerId
        };
    }


    public locationColorList$: Observable<IListInfo<LocationColor>> = muteFirst(
        this.getLocationColorListLoader().pipe(startWith(null)),
        this.locStateSvc.locationColorList$
    );

    private getLocationColorListLoader(): Observable<IListInfo<LocationColor>> {
        return this.locStateSvc.isColorLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.locStateSvc.loadLocationColorList()),
            switchMap(() => this.GetLocationColorList()),
            tap(
                n => this.locStateSvc.loadLocationColorListSuccess(n),
                e => this.locStateSvc.loadLocationListError('')
            ),
            finalize(() => this.locStateSvc.cancelLoadLocationColorList()),
            catchError(() => {
                this.locStateSvc.cancelLoadLocationColorList();
                return of(emptyList());
            }),
            share()
        );
    }

    private GetLocationColorList(): Observable<IListInfo<LocationColor>> {
        return of(LOCATION_COLORS).pipe(
            map(n => {
                return {
                    list: n.ColorList,
                    itemCount: n.ColorList.length,
                    isPaged: false
                };
            })
        );
    }

    public loadMapStaticLibrary() {
        this.locStateSvc.loadMapLibrary();
    }

    public loadMapStaticLibrarySuccess() {
        this.locStateSvc.loadMapLibrarySuccess();
    }

    public loadMapStaticLibraryError() {
        this.locStateSvc.loadMapLibraryError();
    }

}
