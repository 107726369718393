<div class="col-md-12 bg-manage-location">
    <div class="row mt-2">
        <div class="col-sm-3">
            <form name="frmSearialNameSearch" (keyup.enter)="onSerialNameSubmit()" (keyup.tab)="onSerialNameSubmit()"
                #SearialNameSearch="ngForm" novalidate>
                <div class="full-width-float-box locSearchBox default-dropdown">
                    <emr-typeahead identifier="txtLocationNameSearch" name="txtLocationNameSearch"
                        placeholder="Location" i18n-placeholder="@@label_location" [dataSource]="locationList"
                        typeaheadOptionFieldName="text" [(ngModel)]="searchInput" (fireElementBlur)="customBlur()"
                        [typeaheadOptionsCountInScrollableView]=5 [typeaheadIsFirstItemActive]=false
                        [typeaheadSelectFirstItem]=false (ItemSelected)="onSearchInputSelect($event)"
                        typeaheadHideResultsOnBlur=true>
                    </emr-typeahead>
                    <emr-button identifier="resetQuickSearch" (onClick)="resetQuickSearch()"
                        identifier="filterQuickResetButton"
                        cssClass="btn flt-btn reset-filter-btn mb-1 close-btn-quick-search filter-button"
                        *ngIf="searchInput?.length>0&&showQuickReset" i18n-buttonText="@@link_reset" buttonText="Reset">
                    </emr-button>
                </div>
                <div class="inline-loader-container" *ngIf="loading$ | async">
                    <app-local-loading-indicator width="35px" height="35px">
                    </app-local-loading-indicator>
                </div>
            </form>
        </div>
        <div class="add-location-btn col-sm-5 px-1 text-left">
            <ul class="nav">
                <li class="nav-item mr-2">
                    <emr-button identifier="btnAddNewLocation" cssClass=" btn-outline-primary bg-white"
                        buttonText="Create New Location" i18n-buttonText="@@label_create_new_location"
                        (onClick)="openAddLocationModal()">
                    </emr-button>
                </li>
                <li class="nav-item mr-2">
                    <div class="col-auto text-left">
                        <emr-checkbox identifier="copiedLocations" name="copiedLocations"
                            i18n-text="@@label_copiedlocations" text="Copied Locations"
                            [(ngModel)]="showCopyFromLocations" (onChange)="loadCopiedLocations()">
                        </emr-checkbox>
                    </div>
                </li>
                <li class="nav-item mr-2" *ngIf="isLocusAdmin$">
                    <div class="col-auto text-left">
                        <emr-checkbox identifier="chkShowGeoFence1" name="chkShowGeoFence1"
                            i18n-text="@@label_show_geo_fence" text="Show Geo-Fence" [(ngModel)]="ShowGeoFence"
                            [setDisabled]="!locations?.length" (onChange)="updateLocations()">
                        </emr-checkbox>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-sm-4 pr-0 text-right">
            <ul class="nav justify-content-end">
                <li *ngIf="SelectedLocation" class="nav-item">
                    <emr-button identifier="locations_CloseMapButton" buttonIcon="fa fa-times"
                        cssClass="close-btn btn-outline btn-sm btn-style label-text text-12 btn-background"
                        buttonText="Close" i18n-buttonText="@@close" (onClick)="SelectedLocation = null">
                    </emr-button>
                </li>
                <li class="nav-item">
                    <app-total-records [itemCount]="locations?.length" skipThreshold="true"></app-total-records>
                </li>
                <li class="nav-items">
                    <emr-button identifier="DownloadExcelLocations"
                        cssClass="btn-outline-default mt-0 custom-padding down-btn reset-filter-btn"
                        buttonIcon="fa fa-file-excel-o" (onClick)="onLocationsExport()"
                        helpTip="Download locations in excel format" i18n-helpTip="@@tooltip_download_locations_excel"
                        helpTipPosition="left" container="body" [setDisabled]="isLocationExportInprogress$ | async">
                    </emr-button>
                </li>
            </ul>
        </div>
    </div>
    <div class="row mx-0">
        <div [ngClass]="{ 'map-view-grid': SelectedLocation, 'px-3': SelectedLocation, 'col-md-12': !SelectedLocation }"
            class="manage-locations">
            <emr-custom-table [columnDefs]="columnDefs" #locTable [data]="locations" [calculateColumnCount]="false"
                [showColumnSelection]="false" [applyFilter]="true" trackBy="LocationId" [selectableRow]="true"
                (rowClick)="onRowClick($event)" (filterApply)="onFilterApply($event)">
            </emr-custom-table>
            <div class="d-none">
                <ng-template #button let-rowData>
                    <div class="btn-butongroup btn-group" dropdown>
                        <emr-button identifier="editLocation"
                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background edit-location-btn"
                            buttonText="Edit Location" i18n-buttonText="@@label_edit_location"
                            (onClick)="onLocationEdit(rowData)">
                        </emr-button>
                        <button id="button-split" type="button" dropdownToggle
                            class="btn btn-outline btn-sm btn-style dropdown-toggle dropdown-toggle-split"
                            style="position: static;" aria-controls="dropdown-split" data-bs-toggle="dropdown">
                            <span class="caret"></span>
                            <span class="sr-only">Split button!</span>
                        </button>
                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu"
                            aria-labelledby="button-split">
                            <li role="menuitem">
                                <emr-button identifier="deleteLocation"
                                    cssClass="btn btn-link dropdown-item color-black" buttonText="Delete"
                                    i18n-buttonText="@@label_delete" (onClick)="onLocationDelete(rowData)">
                                </emr-button>
                            </li>
                            <li role="menuitem">
                                <emr-button identifier="btnSetContacts"
                                    cssClass="btn btn-link dropdown-item color-black" buttonText="Contacts"
                                    i18n-buttonText="@@label_contacts" (onClick)="onSetLocationContact(rowData)">
                                </emr-button>
                            </li>
                        </ul>
                    </div>
                </ng-template>
                <ng-template #geoFence let-rowData>
                    <div class="table-row-data">
                        <span *ngIf="rowData.HasBoundary" i18n="@@label_yes">Yes</span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div *ngIf="SelectedLocation" class="map-view-container pl-3">
            <app-locations-map-view [SelectedLocation]="SelectedLocation" [distanceUnits]="distanceUnits">
            </app-locations-map-view>
        </div>
    </div>
</div>