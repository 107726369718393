import { IPagingState } from 'app-modules/core/store/models/paging-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as shipPgActions from 'app-modules/core/store/actions/shipment-paging.actions';
import * as shipSortActions from 'app-modules/core/store/actions/shipment-sort.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    shipPgActions.ChangeShipmentsPage |
    shipPgActions.ChangeShipmentsPageSize |
    shipSortActions.ChangeShipmentSort |
    custActions.SelectCustomer;

export function ShipmentsPagingReducer (state: IPagingState = initialState.shipmentPaging, action: Actions): IPagingState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentPaging;

        case actionIndex.CHANGE_SHIPMENTS_PAGE:
            return {
                ...state,
                pageNumber: action.pageNumber
            };

        case actionIndex.CHANGE_SHIPMENTS_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.pageSize,
                pageNumber: 1
            };

        case actionIndex.CHANGE_SHIPMENT_SORT:
        case actionIndex.SELECT_CUSTOMER:
            return {
                ...state,
                pageNumber: 1
            }

        default:
            return state;
    }
}
