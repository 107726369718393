import { Action } from '@ngrx/store';

import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

export class SignIn implements ServerRequestAction {
    readonly type = actionIndex.SIGN_IN;
}

export class SignInSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SIGN_IN_SUCCESS;
    readonly callingAction = actionIndex.SIGN_IN;

    constructor(
        public authToken: string,
        public refreshToken: string,
        public username: string,
        public displayName: string,
        public temperatureUnits: string,
        public distanceUnits: string,
        public dateFormatEnum: number,
        public dateFormatString: string,
        public isEmersonAdmin: boolean,
        public canCreateShipment: boolean,
        public rememberMe: boolean,
        public providerUserKey: string,
        public IsPasswordChangeRequired: boolean,
        public canAccessOversight?: boolean,
        public canAccessPakSense?: boolean,
        public canEditTripName?: boolean,
        public isLocusUserSupportAdmin?: boolean,
        public isLocusUser?: boolean,
        public isDashboardUser?: boolean,
        public isAdminUser?:boolean,
        public isEDIAPIConsumer?:boolean,
        public isLocusSupport?: boolean
    ) { }
}

export class SignInError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SIGN_IN_ERROR;
    readonly callingAction = actionIndex.SIGN_IN;

    constructor(public error: IErrorInfo) { }
}

export class SignOut implements Action {
    readonly type = actionIndex.SIGN_OUT;
}

export class RefreshToken implements Action {
    readonly type = actionIndex.REFRESH_TOKEN;

    constructor(public authToken: string) { }
}

export class ChangePassword implements ServerRequestAction {
    readonly type = actionIndex.CHANGE_PASSWORD;
}

export class ChangePasswordSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.CHANGE_PASSWORD_SUCCESS;
    readonly callingAction = actionIndex.CHANGE_PASSWORD;
    constructor() { }
}

export class ChangePasswordError implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.CHANGE_PASSWORD;
    readonly type = actionIndex.CHANGE_PASSWORD_ERROR;
    constructor(public message: string) { }
}

export class CancelChangePassword implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.CHANGE_PASSWORD;
    readonly type = actionIndex.CANCEL_CHANGE_PASSWORD;
}

export class ForgotPassword implements ServerRequestAction {
    readonly type = actionIndex.FORGOT_PASSWORD;
}

export class ForgotPasswordSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.FORGOT_PASSWORD_SUCCESS;
    readonly callingAction = actionIndex.FORGOT_PASSWORD;
    constructor() { }
}

export class ForgotPasswordError implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.FORGOT_PASSWORD;
    readonly type = actionIndex.FORGOT_PASSWORD_ERROR;
    constructor(public message: string) { }
}

export class CancelForgotPassword implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.FORGOT_PASSWORD;
    readonly type = actionIndex.CANCEL_FORGOT_PASSWORD;
}





