import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { AlertStatus } from 'emr-ng-shared';
import { ShipmentInfo } from 'emr-ng-shared';
import { ChartData } from 'emr-ng-shared';
import { TraxxInfo } from 'emr-ng-shared';
import { TrackerStatus } from 'emr-ng-shared';
import { TimelineInfo } from 'emr-ng-shared';
import { TimePeriodInfo } from 'emr-ng-shared';
import { DateRange } from 'emr-ng-shared';
import { ITimePeriod } from 'app-modules/core/store/models/time-period.interface';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IListInfo, setListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { PagingUtil } from 'app-modules/core/utils/paging-util';

import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';
import * as alertActions from 'app-modules/core/store/actions/shipment-detail-alert.actions';
import * as alertPgActions from 'app-modules/core/store/actions/shipment-detail-alert-paging.actions';
import * as historicalShipmentActions from 'app-modules/core/store/actions/shipment-detail-historical-shipment.actions';
import * as historicalShipmentPgActions from 'app-modules/core/store/actions/shipment-detail-historical-shipment-paging.actions';
import * as trackerActions from 'app-modules/core/store/actions/shipment-detail-tracker.actions';
import * as trackerStateReportActions from 'app-modules/core/store/actions/shipment-detail-tracker-state-report.actions';
import * as trackerStateChartActions from 'app-modules/core/store/actions/shipment-detail-tracker-state-chart.actions';
import * as sensorChartActions from 'app-modules/core/store/actions/shipment-detail-sensor-chart.actions';
import * as tsChartTimePeriodActions from 'app-modules/core/store/actions/shipment-detail-tracker-state-chart-selected-time-period.actions';
import * as traxxActions from 'app-modules/core/store/actions/shipment-detail-traxx.actions';
import * as timelineActions from 'app-modules/core/store/actions/shipment-detail-timeline.actions';
import * as periodActions from 'app-modules/core/store/actions/shipment-detail-time-period.actions';
import * as distanceActions from 'app-modules/core/store/actions/shipment-detail-distance-to.actions';
import * as viewLogActions from 'app-modules/core/store/actions/shipment-detail-viewlog.actions';
import * as showDistanceActions from 'app-modules/core/store/actions/shipment-detail-show-distance.actions';
import { ChartDataInfo } from 'emr-ng-shared';
import { ShipmentInfo2 } from 'emr-ng-shared';
import { ChartInfo2 } from 'emr-ng-shared';
import { SensorStateInfo, TrackerHistory } from 'emr-ng-shared';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShipmentDetailStateService {
    private pagingUtil = new PagingUtil();
    private isTraxxLoadingSub = new BehaviorSubject<boolean>(false);
    private isTimeLineLoadingSub = new BehaviorSubject<boolean>(false);
    private isHistoricalLoadingSub = new BehaviorSubject<boolean>(false);
    private lastShipment: Shipment = null;

    constructor(private store: Store<IAppState>) { }

    // Selectors
    public shipmentDetailStateSelector = ((state: IAppState) => state.shipmentDetail);
    public selectedShipmentSelector = createSelector(
        this.shipmentDetailStateSelector,
        (state) => state.selectedShipment
    );

    public shipmentDetailAlertSelector = ((state: IAppState) => state.shipmentDetailAlert);
    public isAlertListLoadRequiredSelector = createSelector(
        this.shipmentDetailAlertSelector,
        (state) => state.isLoadRequired
    );
    public isAlertListLoadingSelector = createSelector(
        this.shipmentDetailAlertSelector,
        (state) => state.isLoading
    );
    public alertListSelector = createSelector(
        this.shipmentDetailAlertSelector,
        (state) => state.alertList
    );
    public alertListPagingStateSelector = ((state: IAppState) => state.shipmentDetailAlertPaging);
    public alertListPageSelector = createSelector(
        this.alertListSelector,
        this.alertListPagingStateSelector,
        (listInfo, paging) => {
            return this.pagingUtil.GetPage(listInfo, paging.pageSize, paging.pageNumber);
        }
    );

    public shipmentDetailHistoricalShipmentSelector = ((state: IAppState) => state.shipmentDetailHistoricalShipment);
    public isHistoricalShipmentListLoadRequiredSelector = createSelector(
        this.shipmentDetailHistoricalShipmentSelector,
        (state) => state.isLoadRequired
    );
    public isHistoricalShipmentListLoadingSelector = createSelector(
        this.shipmentDetailHistoricalShipmentSelector,
        (state) => state.isLoading
    );

    public fullHistoricalShipmentListSelector = createSelector(
        this.shipmentDetailHistoricalShipmentSelector,
        (state) => state.shipmentList
    );

    public historicalShipmentListSelector = createSelector(
        this.shipmentDetailHistoricalShipmentSelector,
        (state) => {
            const onlyHistoricalShipments = state.shipmentList?.list?.filter(s => !s.IsLatestTrip);
            return setListInfo(onlyHistoricalShipments, true);
        }
    );

    public historicalShipmentListPagingStateSelector = ((state: IAppState) => state.shipmentDetailHistoricalShipmentPaging);
    public historicalShipmentListPageSelector = createSelector(
        this.historicalShipmentListSelector,
        this.historicalShipmentListPagingStateSelector,
        (listInfo, paging) => {
            return this.pagingUtil.GetPage(listInfo, paging.pageSize, paging.pageNumber);
        }
    );

    public shipmentDetailTrackerSelector = ((state: IAppState) => state.shipmentDetailTracker);
    public isTrackerLoadRequiredSelector = createSelector(
        this.shipmentDetailTrackerSelector,
        (state) => state.isLoadRequired
    );
    public isTrackerLoadingSelector = createSelector(
        this.shipmentDetailTrackerSelector,
        (state) => state.isLoading
    );
    public trackerSummarySelector = createSelector(
        this.shipmentDetailTrackerSelector,
        (state) => state.summary
    );
    public trackerAlertInfoSelector = createSelector(
        this.shipmentDetailTrackerSelector,
        (state) => state.alertInfo
    );
    public trackerChartDataSelector = createSelector(
        this.shipmentDetailTrackerSelector,
        (state) => state.chartData
    );

    public shipmentDetailTrackerStateReportSelector = ((state: IAppState) => state.shipmentDetailTrackerStateReport);
    public isTrackerStateReportLoadRequiredSelector = createSelector(
        this.shipmentDetailTrackerStateReportSelector,
        (state) => state.isLoadRequired
    );
    public isTrackerStateReportLoadingSelector = createSelector(
        this.shipmentDetailTrackerStateReportSelector,
        (state) => state.isLoading
    );
    public trackerStateReportSummarySelector = createSelector(
        this.shipmentDetailTrackerStateReportSelector,
        (state) => state.summary
    );
    public trackerStateReportChartDataSelector = createSelector(
        this.shipmentDetailTrackerStateReportSelector,
        (state) => state.chartData
    );

    public shipmentDetailTrackerStateChartSelector = ((state: IAppState) => state.shipmentDetailTrackerStateChart);
    public isTrackerStateChartLoadRequiredSelector = createSelector(
        this.shipmentDetailTrackerStateChartSelector,
        (state) => state.isLoadRequired
    );
    public isTrackerStateChartLoadingSelector = createSelector(
        this.shipmentDetailTrackerStateChartSelector,
        (state) => state.isLoading
    );
    public tsChartSelectedTimePeriodStateSelector = ((state: IAppState) => state.shipmentDetailTrackerStateChartSelectedTimePeriod);

    public shipmentDetailSensorChartSelector = ((state: IAppState) => state.shipmentDetailSensorChart);
    public isSensorChartLoadRequiredSelector = createSelector(
        this.shipmentDetailSensorChartSelector,
        (state) => state.isLoadRequired
    );


    public timePeriodStateSelector = ((state: IAppState) => state.shipmentDetailTimePeriod);
    public isTimePeriodLoadRequiredSelector = createSelector(
        this.timePeriodStateSelector,
        (state) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.timePeriodStateSelector,
        (state) => state.isLoading
    );
    public timePeriodListSelector = createSelector(
        this.timePeriodStateSelector,
        (state) => state.timePeriodList
    );

    // Observables
    public selectedShipment$ = this.store.select(this.selectedShipmentSelector).pipe();
    public isAlertListLoadRequired$ = this.store.select(this.isAlertListLoadRequiredSelector).pipe();
    public isAlertListLoading$ = this.store.select(this.isAlertListLoadingSelector).pipe();
    public alertList$ = this.store.select(this.alertListSelector).pipe();
    public alertListPaging$ = this.store.select(this.alertListPagingStateSelector).pipe();
    public alertListPage$ = this.store.select(this.alertListPageSelector).pipe();
    public isHistoricalShipmentListLoadRequired$ = this.store.select(this.isHistoricalShipmentListLoadRequiredSelector).pipe();
    public isHistoricalShipmentListLoading$ = this.store.select(this.isHistoricalShipmentListLoadingSelector).pipe();
    public historicalShipmentList$ = this.store.select(this.historicalShipmentListSelector).pipe();
    public fullShipmentsList$ = this.store.select(this.fullHistoricalShipmentListSelector).pipe();
    public historicalShipmentListPaging$ = this.store.select(this.historicalShipmentListPagingStateSelector).pipe();
    public historicalShipmentListPage$ = this.store.select(this.historicalShipmentListPageSelector).pipe();
    public tracker$ = this.store.select(this.shipmentDetailTrackerSelector).pipe();
    public isTrackerLoadRequired$ = this.store.select(this.isTrackerLoadRequiredSelector).pipe();
    public isTrackerLoading$ = this.store.select(this.isTrackerLoadingSelector).pipe();
    public trackerSummary$ = this.store.select(this.trackerSummarySelector).pipe();
    public trackerAlertInfo$ = this.store.select(this.trackerAlertInfoSelector).pipe();
    public trackerChartData$ = this.store.select(this.trackerChartDataSelector).pipe();
    public trackerStateReport$ = this.store.select(this.shipmentDetailTrackerStateReportSelector).pipe();
    public isTrackerStateReportLoadRequired$ = this.store.select(this.isTrackerStateReportLoadRequiredSelector).pipe();
    public isTrackerStateReportLoading$ = this.store.select(this.isTrackerStateReportLoadingSelector).pipe();
    public trackerStateReportSummary$ = this.store.select(this.trackerStateReportSummarySelector).pipe();
    public trackerStateReportChartData$ = this.store.select(this.trackerStateReportChartDataSelector).pipe();
    public trackerStateChart$ = this.store.select(this.shipmentDetailTrackerStateChartSelector).pipe();
    public isTrackerStateChartLoadRequired$ = this.store.select(this.isTrackerStateChartLoadRequiredSelector).pipe();
    public sensorChart$ = this.store.select(this.shipmentDetailSensorChartSelector).pipe();
    public isSensorChartLoadRequired$ = this.store.select(this.isSensorChartLoadRequiredSelector).pipe();
    public isTrackerStateChartLoading$ = this.store.select(this.isTrackerStateChartLoadingSelector).pipe();
    public tsChartSelectedTimePeriod$ = this.store.select(this.tsChartSelectedTimePeriodStateSelector).pipe();
    public isTimePeriodLoadRequired$ = this.store.select(this.isTimePeriodLoadRequiredSelector).pipe(); // JVG
    public isTimePeriodLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public timePeriodList$ = this.store.select(this.timePeriodListSelector).pipe();
    public isTraxxLoading$ = this.isTraxxLoadingSub.asObservable().pipe();
    public isTimeLineLoading$ = this.isTimeLineLoadingSub.asObservable().pipe();
    public isHistoricalLoading$ = this.isHistoricalLoadingSub.asObservable().pipe();
    // Actions
    public selectShipment(shipment: Shipment) {
        if (!this.lastShipment ||
            !shipment ||
            this.lastShipment.tripId !== shipment.tripId ||
            this.lastShipment.trackerId !== shipment.trackerId ||
            this.lastShipment.customerTrackerId !== shipment.customerTrackerId) {
            this.store.dispatch(new detailActions.SelectShipment(shipment));
        }
        this.lastShipment = shipment;
    }

    public resetSelectedShipment() {
        this.lastShipment = null;
        this.store.dispatch(new detailActions.ResetShipment(null));
    }

    public loadAlertList() {
        this.store.dispatch(new alertActions.LoadAlertList());
    }

    public loadAlertListSuccess(alertList: IListInfo<AlertStatus>) {
        this.store.dispatch(new alertActions.LoadAlertListSuccess(alertList));
    }

    public loadAlertListError(message: string) {
        this.store.dispatch(new alertActions.LoadAlertListError(message));
    }

    public cancelLoadAlertList() {
        this.store.dispatch(new alertActions.CancelLoadAlertList());
    }

    public changeAlertListPage(pageNumber: number) {
        this.store.dispatch(new alertPgActions.ChangeShipmentDetailAlertPage(pageNumber));
    }

    public changeAlertListPageSize(pageSize: number) {
        this.store.dispatch(new alertPgActions.ChangeShipmentDetailAlertPageSize(pageSize));
    }

    public loadHistoricalShipmentList() {
        this.isHistoricalLoadingSub.next(true);
        this.store.dispatch(new historicalShipmentActions.LoadHistoricalShipmentList());
    }

    public loadHistoricalShipmentListSuccess(historicalShipmentList: IListInfo<TrackerHistory>) {
        this.isHistoricalLoadingSub.next(false);
        this.store.dispatch(new historicalShipmentActions.LoadHistoricalShipmentListSuccess(historicalShipmentList));
    }

    public loadHistoricalShipmentListError(message: string) {
        this.isHistoricalLoadingSub.next(false);
        this.store.dispatch(new historicalShipmentActions.LoadHistoricalShipmentListError(message));
    }

    public cancelLoadHistoricalShipmentList() {
        this.isHistoricalLoadingSub.next(false);
        this.store.dispatch(new historicalShipmentActions.CancelLoadHistoricalShipmentList());
    }

    public changeHistoricalShipmentListPage(pageNumber: number) {
        this.store.dispatch(new historicalShipmentPgActions.ChangeShipmentDetailHistoricalShipmentPage(pageNumber));
    }

    public changeHistoricalShipmentListPageSize(pageSize: number) {
        this.store.dispatch(new historicalShipmentPgActions.ChangeShipmentDetailHistoricalShipmentPageSize(pageSize));
    }

    public loadTracker() {
        this.store.dispatch(new trackerActions.LoadTracker());
    }

    public loadTrackerSuccess(
        summary: ShipmentInfo,
        alertInfo: IListInfo<AlertStatus>,
        chartData: IListInfo<ChartData>,
        chartDataInfo: ChartDataInfo
    ) {
        this.store.dispatch(new trackerActions.LoadTrackerSuccess(summary, alertInfo, chartData, chartDataInfo));
    }

    public loadTrackerError(message: string) {
        this.store.dispatch(new trackerActions.LoadTrackerError(message));
    }

    public cancelLoadTracker() {
        this.store.dispatch(new trackerActions.CancelLoadTracker());
    }

    public loadTrackerStateReport() {
        this.store.dispatch(new trackerStateReportActions.LoadTrackerStateReport());
    }

    public loadTrackerStateReportSuccess(summary: ShipmentInfo, chartData: IListInfo<ChartData>
    ) {
        this.store.dispatch(new trackerStateReportActions.LoadTrackerStateReportSuccess(summary, chartData));
    }

    public loadTrackerStateReportError(message: string) {
        this.store.dispatch(new trackerStateReportActions.LoadTrackerStateReportError(message));
    }

    public cancelLoadTrackerStateReport() {
        this.store.dispatch(new trackerStateReportActions.CancelLoadTrackerStateReport());
    }

    public loadTrackerStateChart() {
        this.store.dispatch(new trackerStateChartActions.LoadTrackerStateChart());
    }

    public loadTrackerStateChartSuccess(summary: ShipmentInfo2, chartData: IListInfo<ChartInfo2>
    ) {
        this.store.dispatch(new trackerStateChartActions.LoadTrackerStateChartSuccess(summary, chartData));
    }

    public loadTrackerStateChartError(message: string) {
        this.store.dispatch(new trackerStateChartActions.LoadTrackerStateChartError(message));
    }

    public cancelLoadTrackerStateChart() {
        this.store.dispatch(new trackerStateChartActions.CancelLoadTrackerStateChart());
    }

    public loadSensorChart() {
        this.store.dispatch(new sensorChartActions.LoadSensorChart());
    }

    public loadSensorChartSuccess(chartData: IListInfo<ChartData>, chartDataInfo: ChartDataInfo, sensorStateData: IListInfo<SensorStateInfo>
    ) {
        this.store.dispatch(new sensorChartActions.LoadSensorChartSuccess(chartData, chartDataInfo, sensorStateData));
    }

    public loadSensorChartError(message: string) {
        this.store.dispatch(new sensorChartActions.LoadSensorChartError(message));
    }

    public cancelLoadSensorChart() {
        this.store.dispatch(new sensorChartActions.CancelLoadSensorChart());
    }


    public changeTrackerStateChartTimePeriod(timePeriod: ITimePeriod) {
        this.store.dispatch(new tsChartTimePeriodActions.ChangeShipmentDetailTrackerStateChartTimePeriod(timePeriod));
    }

    public updateRange(dateRange: DateRange) {
        this.store.dispatch(new traxxActions.UpdateDateRange(dateRange));
    }

    public loadTraxx() {
        this.isTraxxLoadingSub.next(true);
        this.store.dispatch(new traxxActions.LoadTraxx());
    }

    public loadTraxxSuccess(traxx: IListInfo<TraxxInfo>) {
        this.isTraxxLoadingSub.next(false);
        this.store.dispatch(new traxxActions.LoadTraxxSuccess(traxx));
    }

    public loadTraxxError(message: string) {
        this.isTraxxLoadingSub.next(false);
        this.store.dispatch(new traxxActions.LoadTraxxError(message));
    }

    public cancelLoadTraxx() {
        this.isTraxxLoadingSub.next(false);
        this.store.dispatch(new traxxActions.CancelLoadTraxx());
    }

    public loadTimeline() {
        this.isTimeLineLoadingSub.next(true);
        this.store.dispatch(new timelineActions.LoadTimeline());
    }

    public loadTimelineSuccess(timelineList: IListInfo<TimelineInfo>) {
        this.isTimeLineLoadingSub.next(false);
        this.store.dispatch(new timelineActions.LoadTimelineSuccess(timelineList));
    }

    public loadTimelineError(message: string) {
        this.isTimeLineLoadingSub.next(false);
        this.store.dispatch(new timelineActions.LoadTimelineError(message));
    }

    public cancelLoadTimeline() {
        this.isTimeLineLoadingSub.next(false);
        this.store.dispatch(new timelineActions.CancelLoadTimeline());
    }

    // ShipmentDetail Timer Period Actions
    public loadTimePeriods() {
        this.store.dispatch(new periodActions.ShipmentDetailLoadTimePeriods());
    }

    public loadTimePeriodsSuccess(timePeriodList: IListInfo<TimePeriodInfo>) {
        this.store.dispatch(new periodActions.ShipmentDetailLoadTimePeriodsSuccess(timePeriodList));
    }

    public loadTimePeriodsError(message: string) {
        this.store.dispatch(new periodActions.ShipmentDetailLoadTimePeriodsError(message));
    }

    public cancelLoadTimePeriods() {
        this.store.dispatch(new periodActions.ShipmentDetailCancelLoadTimePeriods());
    }

    public loadExportTrackerStateReport() {
        this.store.dispatch(new trackerActions.LoadExportTrackerStateReport());
    }

    public loadExportTrackerStateReportSuccess() {
        this.store.dispatch(new trackerActions.LoadExportTrackerStateReportSuccess());
    }

    public loadExportTrackerStateReportError() {
        this.store.dispatch(new trackerActions.LoadExportTrackerStateReportError());
    }

    public loadExportSensorReport() {
        this.store.dispatch(new sensorChartActions.LoadExportSensorReport());
    }

    public loadExportSensorReportSuccess() {
        this.store.dispatch(new sensorChartActions.LoadExportSensorReportSuccess());
    }

    public loadExportSensorReportError() {
        this.store.dispatch(new sensorChartActions.LoadExportSensorReportError());
    }

    public loadDistance() {
        this.store.dispatch(new distanceActions.LoadDistancetTo());
    }

    public loadDistancetSuccess() {
        this.store.dispatch(new distanceActions.LoadDistancetToStateSuccess());
    }

    public loadDistanceError() {
        this.store.dispatch(new distanceActions.LoadDistanceToError());
    }

    public loadViewLog() {
        this.store.dispatch(new viewLogActions.LoadViewLog());
    }

    public loadViewLogSuccess() {
        this.store.dispatch(new viewLogActions.LoadViewLogStateSuccess());
    }

    public loadViewLogError() {
        this.store.dispatch(new viewLogActions.LoadViewLogError());
    }

    public cancelDistanceTo() {
        this.store.dispatch(new distanceActions.CancelLoadDistancetTo());
    }

    public loadShowDistance() {
        this.store.dispatch(new showDistanceActions.LoadShowDistance());
    }

    public loadShowDistancetSuccess() {
        this.store.dispatch(new showDistanceActions.LoadShowDistancetStateSuccess());
    }

    public loadShowDistanceError() {
        this.store.dispatch(new showDistanceActions.LoadShowDistanceError());
    }

    public cancelShowDistance() {
        this.store.dispatch(new showDistanceActions.CancelLoadShowDistance());
    }

}

