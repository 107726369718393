import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { CarrierInfo } from 'emr-ng-shared';

import { IShipmentFilterCarrier } from 'app-modules/core/store/models/shipment-filter-carrier.interface';
import { CarrierService } from 'app-modules/core/services/carrier.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';

declare type selectableCarrier = CarrierInfo & { checked: boolean };
@Component({
    selector: 'app-shipment-filter-carrier',
    templateUrl: './shipment-filter-carrier.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterCarrierComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: string[];
    searchCarrierText = '';
    isDirtyVal: boolean;
    carrierList: selectableCarrier[];
    private carrierListSubscription: Subscription;
    private carrierFilterSubscription: Subscription;

    // bindable isDirty
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    @Input() get filter() { return this.filterVal; }
    @Output() filterChange = new EventEmitter();

    // tslint:disable-next-line: adjacent-overload-signatures
    set filter(val) {
        this.filterVal = val;
        this.filterChange.emit(this.filterVal);
    }

    // tslint:disable-next-line: adjacent-overload-signatures
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }


    constructor(
        public carrierSvc: CarrierService,
        private filterSvc: ShipmentFilterService
    ) { }

    public ngOnInit() {
        this.carrierListSubscription = this.carrierSvc.carrierList$.subscribe(n => this.onCarrierListChange(n.list));
        this.carrierFilterSubscription = this.filterSvc.carrierFilter$.subscribe(n => this.onCarrierFilterChange(n));
    }

    public ngOnDestroy() {
        this.carrierListSubscription.unsubscribe();
        this.carrierFilterSubscription.unsubscribe();
    }

    onCarrierListChange(carrierList: CarrierInfo[]) {
        this.carrierList = [];

        if (!carrierList) { return; }
        carrierList.forEach(item => {
            this.carrierList.push({ ...item, checked: this.filter && this.filter.indexOf(item.Description) >= 0 });
        });
    }

    onCarrierFilterChange(filter: string[]) {
        this.isDirty = false;
        this.filter = filter;
        if (this.filter) {
            this.carrierList.forEach(item => item.checked = this.filter.indexOf(item.Description) >= 0);
        }
    }

    // onSelectionChanged(carrier: selectableCarrier) {
    //     if (carrier.selected) {
    //         this.filter.selectedCarriers.set(carrier.Description, carrier.selected);
    //     } else {
    //         this.filter.selectedCarriers.delete(carrier.Description);
    //     }
    //     this.isDirty = true;
    // }
}


