import { IPreferenceState } from 'app-modules/core/store/models/preference-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as customerActions from 'app-modules/core/store/actions/customer.actions';
import * as prefActions from 'app-modules/core/store/actions/preference.actions';

type Actions =
    authActions.SignOut |
    authActions.SignInSuccess |
    customerActions.SelectCustomerSuccess |
    prefActions.LoadPreference |
    prefActions.LoadPreferenceSuccess |
    prefActions.LoadPreferenceError;

export function PreferenceReducer(state: IPreferenceState = initialState.preference, action: Actions): IPreferenceState {
    switch (action.type) {
        // case actionIndex.SIGN_OUT:
        //     return initialState.preference;

        // case (actionIndex.SIGN_IN_SUCCESS):
        //     return {
        //         ...state,
        //         temperatureUnits: action.temperatureUnits,
        //         distanceUnits: action.distanceUnits,
        //         dateFormatEnum: action.dateFormatEnum,
        //         dateFormatString: action.dateFormatString
        //     };

        // default:
        //     return state;
        case actionIndex.SIGN_OUT:
            return initialState.preference;

        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoadRequired: true
            };

        case actionIndex.LOAD_PREFERENCE:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_PREFERENCE_SUCCESS:
            return {
                ...state,
                temperatureUnits: action.preference.temperatureUnits,
                temperatureUnitString: action.preference.temperatureUnitString,
                distanceUnits: action.preference.distanceUnits,
                dateFormatEnum: action.preference.dateFormatEnum,
                dateFormatString: action.preference.dateFormatString,
                timeFormatEnum: action.preference.timeFormatEnum,
                timeFormatString: action.preference.timeFormatString,
                timeZoneId:action.preference.timeZoneId,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_PREFERENCE_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
