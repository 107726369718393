export enum SensorBandColors {
    Normal = '#1cff1c', // Color.FromArgb(50, 0, 255, 0);
    LowWarn = '#ffff17', // Color.FromArgb(50, 255, 255, 0);
    LowCritical = '#ff1c1c', // Color.FromArgb(50, 255, 0, 0);
    HighCritical = '#ff1c1c', // Color.FromArgb(50, 255, 0, 0);
    HighWarn = '#ffff17' // Color.FromArgb(50, 255, 255, 0);
}

export enum SensorChartLineColors {
    Temperature = '#000000',
    Humidity = '#0000ff',
    Probe_Temp = '#62bb46',
    CO2 = '#ff00ff'
}
