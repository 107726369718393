<div class="route-edit">
    <div class="row m-0 route-edit">
        <div class="sidebar">
            <div class="w-100">
                <div class="row">
                    <form name="frmRouteEdit" #routeForm="ngForm" class="w-100" emrForm autocomplete="off">
                        <div class="col-md-12">
                            <div class="pt-1 map-options pb-2">
                                <div>
                                    <div class="row ml-0 mr-0" *ngIf="message">
                                        <div class="col-md-11">
                                            <div class="text-error">{{message}}</div>
                                        </div>
                                        <div class="col-md-1 p-0">
                                            <a class="text-error" (click)="message=''"><i class="fa fa-times"
                                                    aria-hidden="true"></i></a>
                                        </div>
                                    </div>

                                    <!-- <div class="row">
                                    <div class="col-md-12 mb-2">
                                        <a class="collapse-panel-header collapse-panel-border pb-10" *ngIf="!showNew">Saved
                                            Route
                                            <button
                                                class="btn button-text btn button-text btn-outline-default mt-0 float-right"
                                                (click)="switchSaveNew()">Create Route</button>
                                        </a>
                                        <a class="collapse-panel-header collapse-panel-border pb-10" *ngIf="showNew">Create
                                            Route
                                            <button
                                                class="btn button-text btn button-text btn-outline-default mt-0 float-right"
                                                (click)="switchSaveNew()">Saved Routes</button>
                                        </a>
                                    </div>
                                </div> -->
                                    <div class="row">
                                        <div class="col-md-12">
                                            <emr-custom-dropdown ngDefaultControl identifier="lstSavedRoutes"
                                                name="lstSavedRoutes" [options]="routesList$ | async"
                                                [(ngModel)]="SavedRoute" cssClass="default-dropdown"
                                                placeholder="Select Route"
                                                label="Select Route for Edit (or continue with new route)"
                                                i18n-label="@@label_edit_route" labelCssClass="col-md-12"
                                                i18n-placeholder="@@label_select_route" controlColumns="12"
                                                [enableSearchFilter]=true SearchPlaceHolder="Search Route"
                                                (onChange)="onSavedRouteChange($event)" [optionsTextField]="'Name'"
                                                [optionsValueField]="'RouteId'"
                                                i18n-SearchPlaceHolder="@@label_search_route">
                                            </emr-custom-dropdown>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <emr-textbox ngDefaultControl identifier="txtRouteName" name="txtRouteName"
                                                placeholder="Enter Route Name"
                                                i18n-placeholder="@@label_enter_route_name"
                                                [(ngModel)]="saveRouteModel.Name" required textBoxColumns="12"
                                                label="Route Name" i18n-label="@@label_route_name"
                                                labelCssClass="col-md-12">
                                                <emr-validation-message validatorName="required"
                                                    message="Route Name Required" i18n-message="@@route_name_required">
                                                </emr-validation-message>
                                            </emr-textbox>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <emr-custom-dropdown ngDefaultControl identifier="lstOriginLocations"
                                                name="lstOriginLocations" [options]="originLocations$ | async"
                                                [(ngModel)]="saveRouteModel.OriginLocationId"
                                                cssClass="default-dropdown" placeholder="Select Location" label="Origin"
                                                i18n-label="@@label_origin" required labelCssClass="col-md-12"
                                                i18n-placeholder="@@label_select_location" controlColumns="12"
                                                [enableSearchFilter]=true SearchPlaceHolder="Search Location"
                                                (onChange)="selectDistinctLocation($event,'origin')"
                                                i18n-SearchPlaceHolder="@@label_search_location"
                                                [isLoading]="isLocationListLoading$ | async">
                                                <emr-validation-message validatorName="required"
                                                    message="Start Location Name Required"
                                                    i18n-message="@@start_location_name_required">
                                                </emr-validation-message>
                                            </emr-custom-dropdown>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <emr-custom-dropdown ngDefaultControl identifier="lstDestinationLocations"
                                                name="lstDestinationLocations" [options]="destLocations$ | async"
                                                [(ngModel)]="saveRouteModel.DestinationLocationId"
                                                labelCssClass="col-md-12" cssClass="default-dropdown"
                                                placeholder="Select Location" label="Destination"
                                                i18n-label="@@label_destination" required
                                                i18n-placeholder="@@label_select_location" controlColumns="12"
                                                [enableSearchFilter]=true SearchPlaceHolder="Search Location"
                                                i18n-SearchPlaceHolder="@@label_search_location"
                                                (onChange)="selectDistinctLocation($event,'destination')"
                                                [isLoading]="isLocationListLoading$ | async">
                                                <emr-validation-message validatorName="required"
                                                    message="Destination Location Name Required"
                                                    i18n-message="@@destination_location_name_required">
                                                </emr-validation-message>
                                            </emr-custom-dropdown>
                                        </div>
                                    </div>
                                    <div class="row ml-0 mr-0">
                                        <div class="col-md-12 text-right">
                                            <emr-button identifier="deleteRoute" *ngIf="saveRouteModel.RouteId"
                                                cssClass="btn button-text btn-outline-default  float-left mt-0 mr-2 mb-2"
                                                buttonText="Delete Route" i18n-buttonText="@@label_delete_route"
                                                (onClick)="onDeleteRouteClick()" buttonType="button">
                                            </emr-button>
                                            <emr-button identifier="resetRoute"
                                                cssClass="btn button-text btn-outline-default mt-0 mr-2 mb-2"
                                                buttonText="Reset Route" i18n-buttonText="@@label_reset_route"
                                                (onClick)="resetRoute()" buttonType="reset">
                                            </emr-button>
                                            <emr-button buttonType="submit" identifier="saveRoute"
                                                cssClass="btn button-text btn-outline-default mt-0 mb-2"
                                                buttonText="Save" i18n-buttonText="@@link_save" (onClick)="saveRoute()">
                                            </emr-button>
                                        </div>
                                    </div>
                                    <div class="row" [hidden]="!showDirections">
                                        <div class="col-md-12">
                                            <div class="col-md-12 mb-2" id="navigationContent"></div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="col-md-12 mb-2">
                                                <!-- <emr-button cssClass="btn button-text btn-outline-default mr-2 mt-0"
                                                    buttonText="Reset Map Zoom" (onClick)="resetZoom()"
                                                    i18n-buttonText="@@link_reset_map_zoom"
                                                    *ngIf="routeEditorModel.length>2">
                                                </emr-button> -->
                                                <emr-button identifier="clearAll"
                                                    cssClass="btn button-text btn-outline-default mt-0"
                                                    buttonText="Clear All" (onClick)="clearAll()"
                                                    i18n-buttonText="@@link_clear_all"
                                                    *ngIf="routeEditorModel.length>2">
                                                </emr-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showDirections" class="scrollable">
                                    <div class="row" *ngFor="let model of routeEditorModel; let i = index">
                                        <div class="col-md-12">
                                            <div class="col-md-12 waypoints pb-1 {{model.IsDestination?'mb-4':''}}">
                                                <span class="waypoint-header" (click)="wayPointClick(model)">
                                                    <span
                                                        class="float-left {{model.IsOrigin?'w-96':model.IsDestination?'w-128':'w-66'}}">
                                                        <span class="pl-2 pr-2 mr-2 custom-label w-27 float-left">
                                                            {{(i + 10).toString(36).toUpperCase()}}</span>
                                                        <span class="pl-2 pr-2 mr-2 custom-label" *ngIf="model.IsOrigin"
                                                            i18n="@@label_origin">Origin</span>
                                                        <span class="pl-2 pr-2 mr-2 custom-label"
                                                            *ngIf="model.IsDestination"
                                                            i18n="@@label_destination">Destination</span>
                                                        <button id="removeWaypoint_{{i}}"
                                                            class="btn btn-outline-light mr-2 mh-10 custom-delete"
                                                            *ngIf="!model.IsOrigin && !model.IsDestination"
                                                            (click)="removeWayPoint(model)"><i class="fa fa-times"
                                                                aria-hidden="true"></i></button>
                                                    </span>
                                                    <span>{{ model.Address }}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="route-map-container">
            <emr-map #xmap [Options]="options" (MapPromise)=InstanceOfMap($event)></emr-map>
        </div>
        <emr-button identifier="ResetMapView"
            cssClass="btn-sm btn-toggle btn-toggle-map btnZoomMap btnResetZoomMap {{ enableResetZoom ? '' : ''}}"
            buttonIcon="reset-map-icon" i18n-title="@@reset_zoom" title="Reset Zoom" (onClick)="resetZoom()">
        </emr-button>
        <!-- <emr-button identifier="mapView" cssClass="btn-sm btn-toggle btn-toggle-map btnZoomMap" buttonIcon="expand-icon"
            buttonText="Map View" i18n-buttonText="@@link_mapview" [routerLink]="['/mapview']"></emr-button> -->
    </div>
</div>

<ng-template #routeResponseTemplate>
    <emr-modal headerTitle="Route Editor" i18n-headerTitle="@@link_route_editor" [showCloseButton]=false>
        <div modal-body>
            <div class="col-md-12 m-1">
                <div *ngIf="hasError; else noError">
                    <p>{{ errorMessage }}</p>
                </div>
                <ng-template #noError>
                    <p i18n="@@route_created_successfully" *ngIf="successMessage&&saveRouteModel.RouteId">Route created
                        successfully</p>
                    <p i18n="@@route_deleted_successfully" *ngIf="successMessage&&!saveRouteModel.RouteId">Route Deleted
                        successfully</p>
                    <p i18n="@@label_max_way_points" *ngIf="!successMessage">You can add up to 25 way points only.</p>
                </ng-template>
            </div>
            <div class="col-12 text-center">
                <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok"
                    (click)='modalRef.hide()' cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                </emr-button>
            </div>
        </div>
    </emr-modal>
</ng-template>

<ng-template #routeDeleteConfirmationTemplate>
    <emr-modal headerTitle="Delete Route" i18n-headerTitle="@@label_delete_route" [showCloseButton]=false>
        <div modal-body>
            <div class="col-md-12 m-1">
                <div>
                    <p i18n="@@message_delete_route">Do you want to delete the route?</p>
                </div>
            </div>
            <div class="col-12 text-center">
                <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Yes"
                    i18n-buttonText="@@label_yes" (click)='deleteRoute()'
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                </emr-button>
                <emr-button identifier="routeResponseOk" buttonType="button" buttonText="No"
                    i18n-buttonText="@@label_no" (click)='modalRef.hide()'
                    cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                </emr-button>
            </div>
        </div>
    </emr-modal>
</ng-template>
