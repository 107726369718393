import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, TemplateRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertStatus, DateRange, DateTimeObject, GetTraxxAlertCounts } from 'emr-ng-shared';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { AlertService } from 'app-modules/core/services/alert.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { SensorChartService } from 'app-modules/core/services/sensor-chart.service';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';

@Component({
    selector: 'app-alert-summary',
    templateUrl: './alert-summary.component.html',
    styleUrls: ['./alert-summary.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class AlertSummaryComponent implements OnInit, OnDestroy {
    alertList: AlertStatus[] = [];
    alertListCopy: AlertStatus[] = [];
    private alertListSubscription: Subscription;
    private userPreferenceSubscription: Subscription;
    private closeSubscription: Subscription;
    hasError = false;
    errorMessage: string = null;
    modalRef: BsModalRef;
    checkedAlerts: number[];
    dateTimeObject: DateTimeObject = new DateTimeObject();
    alertCounts?: GetTraxxAlertCounts[] = [];
    isAuthenticated$ = this.authSvc.authenticated$;
    @Input() Shipment: Shipment;
    selectedDateRange: DateRange;
    canEditTripName: boolean = false;
    isLocusAdmin: boolean = false;
    authStateSub: Subscription;
    @Input()
    set DateRange(dateRange: DateRange) {
        const isDateRangeUpdated = (dateRange != this.selectedDateRange);
        this.selectedDateRange = dateRange;
        if (isDateRangeUpdated) {
            this.onAlertSearch(this.alertListCopy);
        }
    };
    @ViewChild('alertResponseTemplate') alertResponseTemplate: TemplateRef<any>;
    constructor(
        private detailSvc: ShipmentDetailService,
        private alertService: AlertService,
        private modalService: BsModalService,
        private sensorChartService: SensorChartService,
        private detailStateSvc: ShipmentDetailStateService,
        private imgMapSvc: ImageMapService,
        private preferenceSvc: PreferenceService,
        private authSvc: AuthService) { }

    ngOnInit() {
        this.authStateSub = this.authSvc.authState$.pipe().subscribe(n => {
            this.isLocusAdmin = n ? n.isEmersonAdmin : false;
            this.canEditTripName = n ? n.canEditTripName : false;
            this.authStateSub?.unsubscribe();
        });
        this.userPreferenceSubscription = this.preferenceSvc.getDateTimeObject().subscribe(k => {
            this.dateTimeObject = k;
        });
        this.alertListSubscription = this.detailSvc.alertListNoPage$.subscribe(n => {
            this.alertListCopy = n.list ? this.alertService.getCloneAlertList(n.list) : [];
            this.BindAlertsCount(this.alertListCopy);
            this.checkedAlerts = this.alertListCopy.map(k => k.Type);
            this.onAlertSearch(this.alertListCopy);
        });
    }

    public ngOnDestroy() {
        this.alertListSubscription.unsubscribe();
        if (this.userPreferenceSubscription) {
            this.userPreferenceSubscription.unsubscribe();
        }
        if (this.closeSubscription) {
            this.closeSubscription.unsubscribe();
        }
    }

    BindAlertsCount(alertList: AlertStatus[]) {
        this.alertCounts = [];
        alertList.forEach(element => {
            let alertCount = this.alertCounts && this.alertCounts.filter(k => k.Type === element.Type);
            if (alertCount && alertCount.length > 0) {
                alertCount[0].Count = alertCount[0].Count + 1;
            } else {
                const getTraxxAlertCounts: GetTraxxAlertCounts = {
                    Name: element.TypeString,
                    Type: element.Type,
                    Count: 1,
                    checked: true,
                    ImageUrl: element.ImageUrl,
                    ImageId: element.ImageId,
                    CalcImageURL: this.imgMapSvc.getImageURL(element.ImageId, element.ImageUrlSVG, element.ImageUrl)
                }
                this.alertCounts.push(getTraxxAlertCounts);
            }
        });
    }

    UpdateAlertsCount(alertList) {
        this.alertCounts.forEach(element => {
            element.Count = alertList.filter(k => k.Type === element.Type).length;;
        });
    }

    onCloseAlertClick(alert: AlertStatus) {
        this.closeSubscription = this.alertService.closeAlert(alert.Id).pipe(take(1)).subscribe(k => {
            if (k.ErrorCode > 0) {
                this.modalRef = this.modalService.show(this.alertResponseTemplate, { class: 'modal-sm modal-dialog-centered' });
                this.hasError = true;
                this.errorMessage = k.LocalizedErrorMessage;
            } else {
                this.hasError = false;
                // const request = new ShipmentRequest();
                // request.DeviceId = this.Shipment.customerTrackerId;
                // request.GlobalDeviceId = this.Shipment.trackerId;
                // request.Pins = ''; // this.selectedSensors.map(a => a.Pin).join(',')'';
                // // request.Period = this.selectedTimePeriod.timePeriod;
                // request.TripId = this.Shipment.tripId;
                // this.sensorChartService.GetSensorChart(request).pipe(take(1)).subscribe(n => {
                //     if (n && n.Shipment) {
                //         this.detailStateSvc.loadAlertListSuccess({
                //             list: n.Shipment.Alerts,
                //             itemCount: n.Shipment.Alerts != null ? n.Shipment.Alerts.length : 0,
                //             isPaged: false
                //         });
                //     }
                // });
            }
        });
    }

    getIcon(imageId, imageUrlSVG, imageUrl) {
        return this.imgMapSvc.getImageURL(imageId, imageUrlSVG, imageUrl);
    }

    onAlertSearch(alerts) {
        let list = this.detailSvc.getAlertsBetweenSelectedDates(alerts, this.selectedDateRange);
        this.UpdateAlertsCount(list);
        list = list.filter(k =>
            (!k.Type || (this.checkedAlerts && this.checkedAlerts.indexOf(k.Type) >= 0)));
        this.alertList = list;
    }
}



