import { ILoadingItemsState } from '../models/loading-items-state.interface';
import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as LoadingActions from 'app-modules/core/store/actions/loading.actions';


type Actions =
    LoadingActions.FetchingData |
    LoadingActions.FetchingDataComplete |
    LoadingActions.CloseSpinner;

export function LoadingRequestsReducer(state: ILoadingItemsState = initialState.pendingRequests, action: Actions): ILoadingItemsState {
    switch (action.type) {
        case actionIndex.FETCHING_DATA:
            {
                const items = [...state.items];
                items.push(action.actionType);
                return {
                    items
                };
            }
        case actionIndex.FETCHING_DATA_COMPLETE:
            {
                const items = [...state.items];
                const iO: number = items.indexOf(action.callingActionType);
                if (iO >= 0) {
                    items.splice(iO, 1);
                }

                return {
                    items
                };
            }
        case actionIndex.CLOSE_SPINNER:
            return {
                items: []
            };
        default:
            return state;
    }
}
