<app-shipment-map-view *ngIf="showRoute" [Maximized]="loadShowRouteFullScreen" [Shipment]="showRouteShipment"
    [MapviewHeight]="showRouteHeight" [isFilterPinned]="isFilterPinned" (CloseShowRoute)="showRoute=false">
</app-shipment-map-view>
<div class="col-md-12 {{isFilterPinned?'filter-pinned':''}}">
    <div class="row bg-map px-0 {{collapseMapView}}  {{collapseMapView?'no-map':''}}  {{openningInfoBoxIsList ? 'openningListInfoBox' : ''}}"
        id="mapView">
        <div class="col-md-12  map-parent" [collapse]="collapseMapView">
            <emr-map #xmap [Options]="options" [Box]="box" (MapType)="hideClusters($event)" [Zoom]="zoomIndex"
                (BoundsChange)="onBoundsUpdate($event)" (CenterChange)="onMapUpdate()" (MapClick)="onMapUpdate()"
                (MapDblClick)="onMapUpdate()" (MapRightClick)="onMapUpdate()" (ZoomChange)="onMapZoomUpdate($event)">
                <emr-map-marker-layer *ngIf="showMarkers" Id=1 [MarkerOptions]="markerOptions"
                    [InfoBoxPlacement]="CenterAutoFit" (MarkersCreated)="onMarkersCreated($event)"
                    [Visible]="showMarkers" [IsShowInfoBox]="true" [InfoBoxTemplate]="detailInfoBoxTemplate"
                    [ListBoxTemplate]="listInfoBoxTemplate" [ShowInfoBox]="ShowInfoBox" [HideInfoBox]="HideInfoBox"
                    LoadClusterMode=true [EnableClustering]="clustering" [GridSize]="gridSize" ClusterZoomThreshold=8
                    DynamicMarkerBaseSiz=8 [ClusterIconInfo]="clusterIconInfo" [UseDynamicSizeMarkers]="true"
                    [IconInfo]="iconInfo" (MarkerClick)="onMarkerClick($event)"
                    [ShowSelectedMarkers]="ShowSelectedMarkers" (InfoBoxClick)="onViewLocationClick($event)">
                </emr-map-marker-layer>
            </emr-map>
            <ng-template #detailInfoBoxTemplate let-ml>
                <app-map-info-box-detail-content [shipment]="getDetailTemplateInfo(ml,'shipment')"
                    [goBack]="getDetailTemplateInfo(ml,'goBack')" [isZoomedInfoLevel]="isZoomedInfoLevel"
                    [showInfoBoxZoom]="(zoomIndex == previousZoomIndex)">
                </app-map-info-box-detail-content>
            </ng-template>
            <ng-template #listInfoBoxTemplate let-ml>
                <app-map-info-box-list-content [shipmentInfoList]="getListTemplateInfo(ml)">
                </app-map-info-box-list-content>
            </ng-template>
            <emr-button identifier="ShowClusters" #clusterButton [ngStyle]="{'display':clustersDisplay}"
                cssClass="btn-sm btn-toggle btn-toggle-clusters btnClusterMarkers btnZoomMap btn-toggle-map {{ clustering === true ? 'marked' : ''}}"
                buttonIcon="show-cluster-icon" i18n-title="@@tooltip_mapcluster_button" title="Cluster Markers"
                (onClick)="toggleClustering()">
            </emr-button>
            <emr-button identifier="hiddenButton1" #hiddenButton cssClass="d-none" title="hide">
            </emr-button>
            <emr-button identifier="ResetMapView"
                cssClass="btn-sm btn-toggle btn-toggle-map btnZoomMap btnResetZoomMap {{ isZoomed === true ? '' : 'btn-disable'}}"
                buttonIcon="reset-map-icon" i18n-title="@@reset_zoom" title="Reset Zoom" (onClick)="onResetZoom()">
            </emr-button>
            <emr-button identifier="mapView" *ngIf="!collapseListView"
                cssClass="btn-sm btn-toggle btn-toggle-map btnZoomMap" buttonIcon="expand-icon" buttonText="Map View"
                i18n-buttonText="@@link_mapview" (onClick)="collapseListView = true;updateScreenHeights();">
            </emr-button>
            <emr-button identifier="hybridView" *ngIf="collapseListView"
                cssClass="btn-sm btn-toggle btn-toggle-map btnZoomMap" buttonIcon="compress-icon"
                buttonText="Hybrid View" i18n-buttonText="@@link_hybridview"
                (onClick)="collapseListView = false;updateScreenHeights()">
            </emr-button>
        </div>
    </div>
    <div *ngIf="!IsHistorical" class="row {{collapseMapView?'height-18':'height-8 collapse-icon'}}" #resizeIconBox>
        <div class="col-md-12 px-0">
            <i class="fa {{collapseMapView?'fa-angle-down resize-icon-down':'fa-angle-up resize-icon-up'}} "
                (click)="toggleListView()"></i>
        </div>
    </div>
    <div class="row" id="filter-summary" #filterSummaryBox>
        <div *ngIf="!IsUnAuthenticated"
            class="col-md-12 {{isFilterPinned?'pl-0':''}} pt-1 selected-filters selected-filters-reset">
            <app-shipment-filter-summary [hidden]="shipmentFilter?.quickSearch?.length>0"
                *ngIf="(!IsHistorical) || !AppliedShipmentFilter?.isDefaultState"
                [isDirty]="shipmentFilter && !shipmentFilter.isDefaultState" (resetFilter)="onFilterReset()"
                (clearPeriod)="onClearPeriod()" [showFilterDefaultButton]="showResetToDefault()"
                (loadFilterToInitial)="resetFilterMapViewDefaultState()" [shipmentFilter]="AppliedShipmentFilter"
                [isHugeCustomer]="shipSvc.isHighResultCount" (summaryRemove)="onSummaryRemove($event)">
            </app-shipment-filter-summary>
        </div>
    </div>

    <div class="row" id="quickSearch" #quickSearchBox>
        <div class="col-md-12 {{isFilterPinned?'pl-0':''}}">
            <div class="row mt-1 mb-1 height-md-30">
                <div
                    class="{{!IsHistorical && isFilterPinned && !collapseListView && collapseMapView ? ' col-2 ' : ' col-md-3 col-sm-3 col-3 ' }} ">
                    <div class="row">
                        <div class="col-sm-2" *ngIf="!isFilterPinned && !IsUnAuthenticated">
                            <emr-button identifier="showFilter" cssClass="btn-sm btn-icon filter-button"
                                (onClick)="ShowFilter()" buttonIcon="fa fa-search"></emr-button>
                        </div>
                        <div class="{{isFilterPinned?'col-sm-12':'col-sm-10'}}">
                            <form name=" frmSearialNameSearch" (keyup.enter)="onSerialNameSubmit()"
                                (keyup.tab)="onSerialNameSubmit()" #SearialNameSearch="ngForm" novalidate>
                                <div class="full-width-float-box searchBox">
                                    <emr-typeahead identifier="txtSerialName" name="txtSerialName"
                                        placeholder="Serial # / Shipment Name / PO #" i18n-placeholder="@@serial_number_shipment_name_PO"
                                        [dataSource]="serialOptionList$" typeaheadOptionFieldName="customerTrackerId"
                                        [(ngModel)]="searchInput" (ngModelChange)="onSearchInputChange()"
                                        (fireElementBlur)="customBlur($event)" [typeaheadOptionsCountInScrollableView]=5
                                        [typeaheadIsFirstItemActive]=false [typeaheadSelectFirstItem]=false
                                        [typeaheadAsync]="true" (ItemSelected)="onSearchInputSelect($event)"
                                        typeaheadHideResultsOnBlur=true [dropup]="collapseListView">
                                    </emr-typeahead>
                                    <emr-button identifier="resetQuickSearch" (onClick)="resetQuickSearch()"
                                        identifier="filterQuickResetButton"
                                        cssClass="btn flt-btn reset-filter-btn mb-1 close-btn-quick-search filter-button"
                                        *ngIf="searchInput?.length>0&&showQuickReset" i18n-buttonText="@@link_reset"
                                        buttonText="Reset">
                                    </emr-button>
                                </div>
                                <div class="inline-loader-container" *ngIf="trackSvc.isLoading$ | async">
                                    <app-local-loading-indicator width="35px" height="35px">
                                    </app-local-loading-indicator>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <div class="col-md-2" *ngIf="!IsHistorical">
                    <ul class="nav">
                        <li class="nav-item checkbox-align">
                            <emr-checkbox identifier="AutoRefresh" name="AutoRefresh" i18n="@@label_autorefresh"
                                (change)="setAutoRefresh();" text="Auto Refresh" [(ngModel)]="autoRefresh"
                                *ngIf="!IsUnAuthenticated" cssClass="color-regular"></emr-checkbox>
                        </li>
                    </ul>
                </div>
                <div
                    class="{{ IsHistorical ? 'col-9' : isFilterPinned && !collapseListView && collapseMapView ? ' col-8 ' : ' col-sm-7 col-7 ' }}  height-md-40 text-right pl-0 ">
                    <ul class="nav justify-content-end">
                        <li class="nav-item mx-3">
                            <emr-button identifier="update_all_addresses" cssClass="btn-sm btn-toggle filter-button"
                                buttonText="Update All Addresses" i18n-buttonText="@@label_update_all_addresses"
                                (onClick)="updateAllAddresses()">
                            </emr-button>
                        </li>
                        <li class="nav-item">
                            <emr-button identifier="column_selection" cssClass="btn-sm btn-toggle filter-button"
                                buttonIcon="fa fa-filter btn-blue-color" buttonText="Column Selection"
                                i18n-buttonText="@@label_column_selection" (onClick)="openColumnSelectionModal()">
                            </emr-button>
                        </li>
                        <li class="nav-item">
                            <app-total-records [itemCount]="shipmentListCount" [itemThreshold]="itemsThreshold">
                            </app-total-records>
                        </li>
                        <li class="nav-item" *ngIf="IsHistorical">
                            <emr-button identifier="hist_hybridView_1" [routerLink]="['/mapview']"
                                cssClass="btn-sm btn-toggle filter-button" buttonIcon="map-icon"
                                buttonText="Hybrid View" i18n-buttonText="@@link_hybridview">
                            </emr-button>
                        </li>
                        <li class="nav-item" *ngIf="(!collapseListView && collapseMapView) && !IsHistorical">
                            <emr-button identifier="mapView_1" cssClass="btn-sm btn-toggle filter-button btn-icon mr-2"
                                buttonIcon="map-icon" buttonText="Map View" i18n-buttonText="@@link_mapview"
                                (onClick)="onSwitchMapViewZoomLevel()">
                            </emr-button>
                        </li>
                        <li class="nav-item" *ngIf="!collapseMapView && !IsHistorical">
                            <emr-button identifier="listView_1" cssClass="btn-sm btn-toggle filter-button"
                                buttonIcon="expand-icon" buttonText="List View" i18n-buttonText="@@link_listview"
                                (onClick)="toggleListView()">
                            </emr-button>
                        </li>
                        <li class="nav-item" *ngIf="collapseMapView && !IsHistorical">
                            <emr-button identifier="hybridView_1" cssClass="btn-sm btn-toggle filter-button"
                                buttonIcon="compress-icon" buttonText="Hybrid View" i18n-buttonText="@@link_hybridview"
                                (onClick)="toggleListView()">
                            </emr-button>
                        </li>
                        <li class="ml-2 nav-item">
                            <emr-button identifier="DownloadExcel"
                                cssClass="btn-outline-default mt-0 custom-padding down-btn reset-filter-btn"
                                buttonIcon="fa fa-file-excel-o" (onClick)="onListExcelExportClick()"
                                helpTip="Download list view in excel format"
                                i18n-helpTip="@@tooltip_downlaod_listdata_excel" helpTipPosition="left" container="body"
                                [setDisabled]="shipmentListCount === 0 || IsDownloadInProgress">
                            </emr-button>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row mt-1 mb-1" *ngIf="shipmentListCount > itemsThreshold">
                <div class="col-md-12">
                    <span class="span_max_limit_message" i18n="@@message_listview_limit">The results are capped at 1,000
                        trackers. Results that
                        include more than 1,000 trackers will default
                        to the newest trackers.</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-1 " [collapse]="collapseListView && !collapseMapView">
        <div class="col-md-12  {{isFilterPinned?'pl-0':''}}">
            <div class="text-center" (window:resize)="updateScreenHeights()">
                <app-c-shipment-list [(columnDefs)]="columnDefs" [AllShipmentsList]="shipmentList"
                    [shipments$]="shipmentList$" (rowClick)="onRowClick($event)" [scrollToTop]="ScrollToTableTop"
                    mainDivCssClass="mainDivCssClass" (refreshInfoBox)="updateInfoBoxAddress()"
                    (columnDefsChange)="columnDefsChange($event)" [isHistorical]="IsHistorical"
                    (filterApply)="onTableFilterApply($event)" (showRouteClicked)="onShipmentRouteClick($event)"
                    (shareLocationClicked)="onShareLocation($event)" (showDetailsClicked)="showRoute = false"
                    (updateMapMarkers)="updateMapMarkers($event)" dataDivCssClass="{{ 
                        (!collapseListView && collapseMapView) ? 
                            (shipmentFilter?.quickSearch ? 
                                (shipmentListCount > itemsThreshold ? 'full-grid-view-reset-2' : 'full-grid-view-reset-1') :
                                (shipmentListCount > itemsThreshold ? 'full-grid-view-2' : 'full-grid-view-1')
                            ) : (shipmentListCount > itemsThreshold ? 'grid-div-2' : 'grid-div-1') }}"
                    [dataDivHeight]="tableHeight" [tablefilterHeight]="tablefilterHeight">
                </app-c-shipment-list>
            </div>
        </div>
    </div>
</div>
<div id="mapView-comp" class="sidebar  {{isFilterPinned?'filter-min-height':''}}" *ngIf="displayFilter">
    <div class="w-100">
        <div class="row">
            <div class="col-md-12">
                <div class="bg-white pl-2 pt-2">
                    <emr-button identifier="closeFilter" cssClass="btn mr-1 flt-btn close-filter-btn mb-1"
                        i18n-buttonText="@@close" (onClick)="closeFilter()" buttonText="Close">
                    </emr-button>
                    <!-- <emr-button identifier="pinFilter" cssClass="btn mr-1 flt-btn btn-secondary mb-1"
                        *ngIf="!isFilterPinned" (onClick)="onPinClick()" i18n-buttonText="@@button_pin"
                        buttonText="Pin"></emr-button>
                    <emr-button identifier="hideFilter" cssClass="btn mr-1 flt-btn btn-secondary mb-1"
                        *ngIf="isFilterPinned" (onClick)="onPinClick()" i18n-buttonText="@@button_hide"
                        buttonText="Hide"></emr-button> -->
                    <emr-button identifier="resetFilter" cssClass="btn mr-1 flt-btn reset-filter-btn mb-1"
                        *ngIf="showResetAll()" i18n-buttonText="@@label_clear_filter" (onClick)="onFilterReset()"
                        buttonText="Clear Filters"></emr-button>
                    <emr-button identifier="applyFilter" cssClass="btn mr-1 flt-btn apply-filter-btn mb-1"
                        *ngIf="isFilterDirty || isApplyChange " i18n-buttonText="@@link_apply"
                        (onClick)="onFilterApply()" buttonText="Apply"></emr-button>
                </div>
                <div class="bg-white pl-2 pt-2" *ngIf="!IsHistorical">
                    <emr-button identifier="defaultFilter" *ngIf="!IsUnAuthenticated"
                        cssClass="btn mr-1 flt-btn apply-filter-btn mb-1 customfilter {{showSaveAsDefault() ? '' : 'btndisabled'}}"
                        i18n-buttonText="@@link_save_default_filter" buttonIcon="fa fa-plus"
                        (onClick)="onSaveCustomFilter()" buttonText="Save as Default"></emr-button>
                    <div class="inline-icon-loader-container" *ngIf="isSavingDefaultFilter$ | async">
                        <app-local-loading-indicator width="35px" height="35px">
                        </app-local-loading-indicator>
                    </div>
                </div>
            </div>
        </div>
        <div class="map-view-sidebar">
            <app-shipment-filter [shipmentFilter]="shipmentFilter" ShowLastNDays="{{ (IsHistorical ? null : 90) }}"
                [showPlaceHolder]="IsUnAuthenticated" (isFilterDirty)="UpdateFilterDirty($event)"
                pageFilter="{{ IsHistorical ? historicalFilter :  listViewFilter}}">
            </app-shipment-filter>
        </div>
    </div>
</div>

<ng-template #columnSelectionModal>
    <app-col-selection-popup [columnDefs]="columnDefs" [modalRef]="modalRef"
        (ColumnSelectionChanged)="onColumnChangeAccept($event)">
    </app-col-selection-popup>
</ng-template>

<ng-template #errorModal>
    <emr-modal headerTitle="Download" i18n-headerTitle="@@link_download" [bsModalRef]=errorModalRef>
        <div modal-body>
            <div>
                <p>{{ errorMessage }}</p>
            </div>
            <emr-button identifier="mapviewOk" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok"
                (click)='onOkClick()'>
            </emr-button>
        </div>
    </emr-modal>
</ng-template>
<ng-template #shareModal>
    <emr-modal id="btnFrmShipmentshare" *ngIf="bsModalRef" class="scrollable-modal" headerTitle="Share Tracker Location"
        i18n-headerTitle="@@label_share_tracker_location" [bsModalRef]=bsModalRef>
        <ng-container modal-body>
            <form name="frmShareLocation" #shareLocationForm="ngForm" emrForm autocomplete="off">
                <div class="col-md-12">
                    <div class="row my-2">
                        <div class="col-md-12 text-center text-danger">
                            {{errorMessage}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <emr-textarea ngDefaultControl identifier="txtShare" name="txtShare"
                                [(ngModel)]="emailAddresses" placeholder="Enter email addresses one on each line"
                                EmrEmailValidator helpTip="Share" i18n-helpTip="@@label_share" controlColumns="12"
                                textAreaRows="10" required>
                                <emr-validation-message validatorName="required" message="Email is required"
                                    i18n-message="@@error_email_required"></emr-validation-message>
                                <emr-validation-message validatorName="EmrEmailValidator"
                                    i18n-message="@@validation_email" message="Please enter valid Email	">
                                </emr-validation-message>
                            </emr-textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p>
                                <emr-button identifier="trackerShare" buttonType="submit" buttonText="Submit"
                                    i18n-buttonText="@@link_submit" cssClass="btn-outline-default submit-btn"
                                    [setDisabled]="disableSubmitButton" buttonIcon="fa fa-check"
                                    (onClick)="onShareTracker(shareLocationForm)">
                                </emr-button>
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </emr-modal>
</ng-template>