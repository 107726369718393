<div class="row">
    <div class="col-md-12">
        <div class="card alertbox roboto-regular-font font-12">
            <div class="row card-header p-1">
                <div class="col-md-12 center-align">
                    <div class="font-14 lh-24">
                        <button translate="no" type="button"
                            class="notranslate float-right close close-sm btn-modal custom-btn-info-click click-infobox-close infobox-close-img"
                            aria-label="Close">
                            <span aria-hidden="true"
                                class="custom-btn-info-click click-infobox-close infobox-close-img">
                                <b class="custom-btn-info-click click-infobox-close infobox-close-img">&times;</b>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body p-1 pl-2 text-left alertboxbody">
                <div class="row">
                    <ul style="padding-left: 10px;" class="mb-0">
                        <li style="background-image:url({{shipmentInfo.shipment.ImageUrlSVG}})"
                            *ngFor="let shipmentInfo of shipmentInfoList"
                            class="ulli custom-btn-info-click click-show-a-marker"
                            attr.data-custom-dt="{{shipmentInfo.markerUUID}}">
                            <span class="custom-btn-info-click click-show-a-marker"
                                attr.data-custom-dt="{{shipmentInfo.markerUUID}}"
                                style="font-weight: bolder;font-size: 14px;">{{shipmentInfo.shipment.trackerId}}</span><br />
                            <span class="custom-btn-info-click click-show-a-marker"
                                attr.data-custom-dt="{{shipmentInfo.markerUUID}}" style="font-weight: bolder;"
                                *ngIf="!!shipmentInfo.shipment.tripName">{{ shipmentInfo.shipment.tripName ||
                                'n/a'}}</span><br *ngIf="!!shipmentInfo.shipment.tripName" />
                            <span class="custom-btn-info-click click-show-a-marker"
                                attr.data-custom-dt="{{shipmentInfo.markerUUID}}">{{shipmentInfo.shipment.LastReportedTimestampFormatted}}</span><br />
                            <span class="custom-btn-info-click click-show-a-marker"
                                attr.data-custom-dt="{{shipmentInfo.markerUUID}}"
                                i18n="@@label_temperature">Temperature</span>:&ensp;
                            <span class="custom-btn-info-click click-show-a-marker"
                                attr.data-custom-dt="{{shipmentInfo.markerUUID}}">{{shipmentInfo.shipment.TemperatureString}}</span>
                            <div class="mt-1 custom-btn-info-click click-show-a-marker"
                                attr.data-custom-dt="{{shipmentInfo.markerUUID}}"
                                *ngIf="shpSvc.CheckDatesInPast90DaysForDetails(shipmentInfo.shipment?.LastReportedTimestamp,shipmentInfo.shipment?.ActualEndTime,shipmentInfo.shipment?.ActualStartTime,shipmentInfo.shipment?.tripStateCode)">
                                <a class="notranslate link-button custom-btn-info-click click-show-route-list"
                                    attr.data-custom-dt="{{shipmentInfo.markerUUID}}" i18n="@@show_route">Show Route
                                </a>
                                <a class="notranslate link-button custom-btn-info-click click-show-details-list"
                                    attr.data-custom-dt="{{shipmentInfo.markerUUID}}" i18n="@@details">Details
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>