<div class="iframe-container" *ngIf="embedUrl">
    <iframe
      [src]="embedUrl | safeUrl"
      width="95%"
      class="i-frame-css"
      style="border: 0;"
      frameborder="0"
      allowfullscreen
      (load)="onIframeLoad()"
      (error)="onIframeError()">
    </iframe>
  </div>
  <div *ngIf="hasError" class="error-message">
    Failed to load the report. Please try again later.
  </div>