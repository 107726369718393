import { Injectable } from "@angular/core";
import { CalibrationStatementsComponent } from "app-modules/nist-calibration-statements/components/calibration-statements/calibration-statements.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CalibrationStatementPopup } from "../models/calibration-statement-popup.model";

@Injectable()
export class CalibrationStatementPopupService extends CalibrationStatementPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenCalibrationStatementPopup(): void {
        const initialState = {};

        this.bsModalRef = this.modalSvc.show(
            CalibrationStatementsComponent,
            {
                initialState,
                class: 'max-width-1000 modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}