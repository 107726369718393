import { Directive, HostListener } from "@angular/core";
import { ActivityService } from '../services/activity.service';
import { ShipmentService } from '../services/shipment.service';

@Directive({ selector: '[register-activity]' })
export class RegisterActivity {
    constructor(private actSvc: ActivityService,
        private shipSvc: ShipmentService) { }

    @HostListener('document:click')
    onClick() {
        // console.log('click');
        this.actSvc.ack();
        this.shipSvc.ack();
    }

    @HostListener('document:scroll')
    onScroll() {
        // console.log('scroll');
        this.actSvc.ack();
        this.shipSvc.ack();
    }

    @HostListener('document:mousewheel')
    onMousewheel() {
        // console.log('mousewheel');
        this.actSvc.ack();
        this.shipSvc.ack();
    }

    @HostListener('document:mousemove', ['$event'])
    onMousemove(event: MouseEvent) {
        this.shipSvc.ack();
    }
}
