import { Action } from '@ngrx/store';

import { ITimePeriod } from 'app-modules/core/store/models/time-period.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class ChangeShipmentDetailTrackerStateChartTimePeriod implements Action {
    readonly type = actionIndex.SHIPMENT_DETAIL_TRACKER_STATE_CHART_SELECTED_TIME_PERIOD;
    constructor(public filter: ITimePeriod) { }
}
