import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { TimeZoneInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ITimeZoneState } from 'app-modules/core/store/models/time-zone-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as timeZoneActions from './time-zone.actions';

@Injectable()
export class TimeZoneStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public timeZoneStateSelector = ((state: IAppState) => state.timeZone);
    public isLoadRequiredSelector = createSelector(
        this.timeZoneStateSelector,
        (state: ITimeZoneState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.timeZoneStateSelector,
        (state: ITimeZoneState) => state.isLoading
    );
    public timeZoneListSelector = createSelector(
        this.timeZoneStateSelector,
        (state: ITimeZoneState) => state.timeZoneList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public timeZoneList$ = this.store.select(this.timeZoneListSelector).pipe();

    // Actions
    public loadTimeZones() {
        this.store.dispatch(new timeZoneActions.LoadTimeZones());
    }

    public loadTimeZonesSuccess(timeZoneList: IListInfo<TimeZoneInfo>) {
        this.store.dispatch(new timeZoneActions.LoadTimeZonesSuccess(timeZoneList));
    }

    public loadTimeZonesError(message: string) {
        this.store.dispatch(new timeZoneActions.LoadTimeZonesError(message));
    }

    public cancelLoadTimeZones() {
        this.store.dispatch(new timeZoneActions.CancelLoadTimeZones());
    }
}


