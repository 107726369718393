<div class="table-data-container mb-1">
    <div class="col-md-12 px-1">
        <div class="table-responsive">
            <table class="table hover-table mb-0 table mb-0 custom-table" #table>
                <thead>
                    <tr>
                        <th scope="col" *ngIf="!isHistorical">
                            <p class="">
                                <span>&nbsp;</span>
                            </p>
                        </th>
                        <th scope="col" (click)="sortSelected('TripName','tripName')" class="table-row-header">
                            <span i18n="@@shipment_name">Shipment Name</span>
                            <span *ngIf="(selectedSort$ | async) == 'TripName'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('GlobalDeviceID','serialNumber')"
                            class="table-row-header">
                            <span i18n="@@label_serial_number">Serial Number</span>
                            <span *ngIf="(selectedSort$ | async) == 'GlobalDeviceID'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('TripStateCode','tripStateCode')"
                            class="table-row-header">
                            <span i18n="@@label_trip_status">Shipment Status</span>
                            <span *ngIf="(selectedSort$ | async) == 'TripStateCode'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('SensorTempRange','sensorRange')"
                            class="table-row-header">
                            <span i18n="@@label_temperature_range">Temperature Range</span>
                            <span *ngIf="(selectedSort$ | async) == 'SensorTempRange'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('TripStartLocationName','origin')"
                            class="table-row-header">
                            <span i18n="@@origin">Origin</span>
                            <span *ngIf="(selectedSort$ | async) == 'TripStartLocationName'"
                                class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('TripEndLocationName','destination')"
                            class="table-row-header">
                            <span i18n="@@destination">Destination</span>
                            <span *ngIf="(selectedSort$ | async) == 'TripEndLocationName'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('CarrierName','carrierName')" class="table-row-header">
                            <span i18n="@@carrier">Carrier</span>
                            <span *ngIf="(selectedSort$ | async) == 'CarrierName'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('temperature','temperature')" class="table-row-header">
                            <span i18n="@@last_reported_temp">Last Reported Temperature</span>
                            <span *ngIf="(selectedSort$ | async) == 'temperature'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('CurLocName','AddressGeo')" class="table-row-header">
                            <span i18n="@@last_reported_location">Last Reported Location</span>
                            <span *ngIf="(selectedSort$ | async) == 'CurLocName'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('SatTime','LastReportedTimestamp')"
                            class="table-row-header">
                            <span i18n="@@last_reported_time">Last Reported Time</span>
                            <span *ngIf="(selectedSort$ | async) == 'SatTime'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('ActualStartTime','ActualStartTime')"
                            class="table-row-header">
                            <span i18n="@@actual_start_time">Actual Start Time</span>
                            <span *ngIf="(selectedSort$ | async) == 'ActualStartTime'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('ActualEndTime','ActualEndTime')"
                            class="table-row-header">
                            <span i18n="@@actual_end_time">Actual End Time</span>
                            <span *ngIf="(selectedSort$ | async) == 'ActualEndTime'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <th scope="col" (click)="sortSelected('BatteryPct','battery')" class="table-row-header w-60">
                            <span i18n="@@label_battery">Battery</span>
                            <span *ngIf="(selectedSort$ | async) == 'BatteryPct'" class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th>
                        <!-- OR-2108 -->
                        <!-- <th scope="col" class="table-row-header">
                            <span i18n="@@label_notes">Notes</span>
                            <span *ngIf="(selectedSort$ | async) == 'replace this with notes sort code'"
                                class="header-item-segment">
                                <i *ngIf="!(isDescendingSort$ | async)" class="fa fa-sort-asc" aria-hidden="true"></i>
                                <i *ngIf="(isDescendingSort$ | async)" class="fa fa-sort-desc" aria-hidden="true"></i>
                            </span>
                        </th> -->
                    </tr>
                </thead>
                <ng-container *ngIf="(shipmentSortedList$ | async) as shipments; else emptyList">
                    <!-- <cdk-virtual-scroll-viewport id="full-grid-view-reset" *ngIf="shipments.length>0;else emptyList"
                        itemSize="20" class=" {{dataDivCssClass}}"> -->
                    <tbody id="full-grid-view-reset" *ngIf="shipments?.length > 0; else emptyList"
                        class=" {{dataDivCssClass}}">
                        <!-- <tr *cdkVirtualFor="let shipment of shipments; let i = index; templateCacheSize: 0"  class="cursor-pointer"
                            [class.selected]="shipment === selectedShipment" (click)="onRowClick(shipment)"> -->
                        <tr *ngFor="let shipment of shipments; let i = index;" class="cursor-pointer"
                            [class.selected]="shipment === selectedShipment" (click)="onRowClick(shipment)">
                            <td *ngIf="!isHistorical">
                                <div class="" *ngIf="CheckDatesInPast90Days(shipment.LastReportedTimestamp)">
                                    <emr-button identifier="showRoute"
                                        cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                                        buttonText="Show Route" i18n-buttonText="@@show_route"
                                        (onClick)="onShipmentRouteClick(shipment)">
                                    </emr-button>
                                    <div class="btn-butongroup btn-group" dropdown>
                                        <emr-button identifier="showDetails"
                                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background show-details-btn"
                                            buttonText="Details" i18n-buttonText="@@details"
                                            (onClick)="onShipmentDetailsClick(shipment)">
                                        </emr-button>
                                        <button id="button-split" type="button" dropdownToggle
                                            class="btn btn-outline btn-sm btn-style dropdown-toggle dropdown-toggle-split"
                                            aria-controls="dropdown-split">
                                            <span class="caret"></span>
                                            <span class="sr-only">Split button!</span>
                                        </button>
                                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu"
                                            aria-labelledby="button-split">
                                            <li role="menuitem"><a class="dropdown-item" [routerLink]="[]"
                                                    (click)="onDistanceToClick(shipment.trackerId, shipment.customerTrackerId, shipment.tripId);"
                                                    i18n="@@distance_to">Distance To</a>
                                            </li>
                                            <li *ngIf="shipment.alertSuppressed&&shipment.CanResumeAlerts" role="menuitem"><a
                                                    class="dropdown-item" [routerLink]="[]"
                                                    (click)="onSuspendResumeAlertsClick(shipment);"
                                                    i18n="@@link_resume_alerts">Resume Alerts</a>
                                            </li>
                                            <li *ngIf="!shipment.alertSuppressed&&shipment.CanSuspendAlerts" role="menuitem"><a
                                                    class="dropdown-item" [routerLink]="[]"
                                                    (click)="onSuspendResumeAlertsClick(shipment);"
                                                    i18n="@@link_supress_alerts">Suspend Alerts</a>
                                            </li>
                                            <li *ngIf="!shipment.tripId && allowSetSensors"
                                                role="menuitem">
                                                <emr-button identifier="btnSetSensorsLink"
                                                    cssClass="btn btn-link dropdown-item color-black"
                                                    buttonText="Sensor Ranges" i18n-buttonText="@@label_sensor_ranges"
                                                    (onClick)="onSetTrackerSensorsClick(shipment)">
                                                </emr-button>
                                            </li>
                                            <li *ngIf="!shipment.tripId && userCanCreateShipment"
                                                role="menuitem">
                                                <emr-button identifier="btnCreateShipmentLink"
                                                    cssClass="btn btn-link dropdown-item color-black"
                                                    buttonText="Create Shipment" i18n-buttonText="@@create_shipment"
                                                    (onClick)="onCreateShipmentClick(shipment)">
                                                </emr-button>
                                            </li>
                                            <li *ngIf="shipment.CanDeleteTrip">
                                                <emr-button identifier="deleteShipment_1"
                                                    cssClass="btn btn-link dropdown-item color-black" currentToolTip="Delete"
                                                    buttonText="Delete Shipment" i18n-buttonText="@@label_delete_shipment"
                                                    (onClick)="onDeleteShipmentClick(shipment)">
                                                </emr-button>
                                            </li>
                                            <li role="menuitem">
                                                <a class="dropdown-item" [ngClass]="{
                                                        'btndisabled':!shipment.canCloseShipment
                                                      }" [routerLink]="[]" (click)="onCloseShipmentClick(shipment);"
                                                    i18n="@@close">Close</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data">
                                    <span>{{ shipment.tripId ? shipment.tripName : 'n/a' }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data">
                                    <span class="tripImageURL">
                                        <img
                                            src="{{ calcImageURL(shipment.ImageId, shipment.ImageUrlSVG, shipment.ImageUrl) }}" />
                                    </span>
                                    <span>{{shipment.serialNumber}}</span>
                                </div>
                            </td>
                            <td>
                                <div class="table-row-data" [ngClass]="{
                                        'status-completed':shipment.tripStatus === 'Completed',
                                        'status-progress':shipment.tripStatus === 'In Progress'
                                      }">
                                    <span>{{ shipment.tripId ? shipment.tripStatus : 'n/a' }}</span>
                                </div>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{shipment.sensorRange}}</span>
                                </p>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{ shipment.tripId ? shipment.origin : 'n/a' }}</span>
                                </p>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{ shipment.tripId ? shipment.destination : 'n/a' }}</span>
                                </p>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{shipment.carrierName}}</span>
                                </p>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{shipment.temperature}}</span>
                                </p>
                            </td>
                            <td>
                                <div *ngIf="!shipment?.AddressGeoLoading; else addressLoader">
                                    <p *ngIf="shipment.AddressGeoTimestampFormatted != '' && !shipment.AddressOlderThanThreshold; else viewCurrentLocation"
                                        class="table-row-data">
                                        <span>{{shipment.AddressGeo}}</span>
                                    </p>
                                    <ng-template #viewCurrentLocation>
                                        <p class="table-row-data"
                                            *ngIf="CheckDatesInPast90Days(shipment.LastReportedTimestamp)">
                                            <a class="text-center shipment-custom-link" i18n="@@view_current_location"
                                                (click)="onViewLocationClick(shipment)">
                                                View Current Location
                                            </a>
                                        </p>
                                    </ng-template>
                                </div>
                                <ng-template #addressLoader>
                                    <div class="col-md-12">
                                        <app-local-loading-indicator width="50%" height="28px">
                                        </app-local-loading-indicator>
                                    </div>
                                </ng-template>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{shipment.LastReportedTimestampFormatted}}</span>
                                </p>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{ shipment.tripId ? shipment.actualStartTime : 'n/a' }}</span>
                                </p>
                            </td>
                            <td>
                                <p class="table-row-data">
                                    <span>{{ shipment.tripId ? shipment.actualEndTime : 'n/a' }}</span>
                                </p>
                            </td>
                            <td class="w-60">
                                <p class="table-row-data">
                                    <span>{{shipment.battery}}</span>
                                </p>
                            </td>
                            <!-- <td> 
                            <p class="table-row-data notes-color">
                                <span>{{shipment.notes}}</span>
                            </p>
                        </td> -->
                        </tr>
                        <!-- </cdk-virtual-scroll-viewport> -->
                    </tbody>
                </ng-container>
                <ng-template #emptyList>
                    <tbody id="full-grid-view-reset">
                        <tr>
                            <td colspan="14">
                                <p class="table-row-data text-center" i18n="@@no_data_available">No data available
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </ng-template>

            </table>
        </div>
    </div>
</div>