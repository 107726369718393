export enum StoreOpts {
    LocalStorage = 1,
    Cookie = 2,
    StoreManager = 3
}

export class StoreManager {
    private static appStore = new Map<string, string>();

    public static resolveCandidatesFromCookie(key: string, skipSplit: boolean = false): [string[], boolean] {
        const candidates: string[] = [];
        let isReadFromStore = false;
        if (navigator.cookieEnabled) {
            const regexp: RegExp = new RegExp('[; ]' + key + '=([^\\s;]*)');
            const result: RegExpExecArray = regexp.exec(document.cookie);

            if (result) {
                isReadFromStore = true;
                if (skipSplit) {
                    candidates.push(result[1]);
                } else {
                    candidates.push(...result[1].split(','));
                }
            }
        }

        return [candidates, isReadFromStore];
    }

    public static resolveCandidatesFromLocalStorage(key: string, skipSplit: boolean = false): [string[], boolean] {
        const candidates: string[] = [];
        let isReadFromStore = false;

        try {
            const result = localStorage.getItem(key);
            if (result) {
                isReadFromStore = true;
                if (skipSplit) {
                    candidates.push(result);
                } else {
                    candidates.push(...result.split(','));
                }
            }
        } catch { }

        return [candidates, isReadFromStore];
    }

    public static resolveCandidatesFromLocalStore(key: string, skipSplit: boolean = false): [string[], boolean] {
        const candidates: string[] = [];
        let isReadFromStore = false;
        if (this.appStore.has(key)) {
            const result: string = this.appStore.get(key);
            if (result) {
                isReadFromStore = true;
                if (skipSplit) {
                    candidates.push(result);
                } else {
                    candidates.push(...result.split(','));
                }
            }
        }

        return [candidates, isReadFromStore];
    }

    public static persistToLocalStorage(key: string, value: string) {
        try {
            localStorage.setItem(key, value);
        } catch { }
    }

    public static removeFromLocalStorage(key: string) {
        try {
            localStorage.removeItem(key);
        } catch { }
    }

    public static persistToCookie(key: string, value: string) {
        if (navigator.cookieEnabled) {
            const expTime: Date = new Date();
            expTime.setUTCFullYear(expTime.getUTCFullYear() + 1);

            const cookie: string = key + '=' + value + ';expires=' + expTime.toUTCString() + ';path=/;SameSite=strict';
            document.cookie = cookie;
        }
    }

    public static removeCookie(key: string) {
        if (navigator.cookieEnabled) {
            const expTime: Date = new Date();
            expTime.setMinutes(-1);

            const cookie: string = key + '=;expires=' + expTime.toUTCString() + ';path=/;SameSite=strict';
            document.cookie = cookie;
        }
    }

    public static removeFromStore(key: string) {
        StoreManager.appStore.set(key, null);
    }


    public static StoreValue(key: string, value: string, opts: StoreOpts) {
        // tslint:disable-next-line: no-bitwise
        if (opts & StoreOpts.LocalStorage) {
            StoreManager.persistToLocalStorage(key, value);
        }

        // tslint:disable-next-line: no-bitwise
        if (opts & StoreOpts.Cookie) {
            StoreManager.persistToCookie(key, value);
        }

        // tslint:disable-next-line: no-bitwise
        if (opts & StoreOpts.StoreManager) {
            StoreManager.appStore.set(key, value);
        }
    }

    public static ClearValue(key: string) {
        StoreManager.removeFromLocalStorage(key);
        StoreManager.removeCookie(key);
        StoreManager.removeFromStore(key);        
    }

    //jelax OR-2497
    public static GetValue(key: string):string {
        let result = undefined; //Use undefined to avoid the case of the number 0 and the string 0
        result = result === undefined ? StoreManager.resolveCandidatesFromLocalStorage(key)[0][0] : result;   
        result = result === undefined ? StoreManager.resolveCandidatesFromCookie(key)[0][0] : result;
        result = result === undefined ? StoreManager.appStore.get(key) : result;
        return result;
    }

    //jelax OR-2497
    public static GetArrayValue(key: string): string[] {
        let result = undefined; //Use undefined to avoid the case of the number 0 and the string 0
        result = result === undefined ? StoreManager.resolveCandidatesFromLocalStorage(key)[0] : result;   
        result = result === undefined ? StoreManager.resolveCandidatesFromCookie(key)[0] : result;
        result = result === undefined ? StoreManager.appStore.get(key).split(",") : result;
        return result;
    }

    public static ReadStoreValue(key: string): string {
        let result = undefined; //Use undefined to avoid the case of the number 0 and the string 0
        result = result === undefined ? StoreManager.resolveCandidatesFromLocalStorage(key, true)[0][0] : result;   
        result = result === undefined ? StoreManager.resolveCandidatesFromCookie(key, true)[0][0] : result;
        result = result === undefined ? StoreManager.appStore.get(key) : result;
        return result;

    }
}
