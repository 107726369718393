import { Action } from '@ngrx/store';
import * as actionIndex from 'app-modules/core/store/actions';

import { ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';


export class LoadViewLog implements Action {
    readonly type = actionIndex.LOAD_VIEW_LOG;
    constructor() { }
}

export class LoadViewLogError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_VIEW_LOG_ERROR;
    readonly callingAction = actionIndex.LOAD_VIEW_LOG;
    constructor() { }
}

export class LoadViewLogStateSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_VIEW_LOG_SUCCESS;
    readonly callingAction = actionIndex.LOAD_VIEW_LOG;
    constructor() { }
}

export class CancelLoadViewLog implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_VIEW_LOG;
    readonly callingAction = actionIndex.LOAD_VIEW_LOG;
}
