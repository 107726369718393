<form modal-body name="frmScheduleReport" #ScheduleReportForm="ngForm" class=" py-2" emrForm autocomplete="off">
    <div class="row report-Css">
        <div class="{{ !sendNow && showEmailDetails ? ' col-6' : ' col-12'}}  for-email-popup">
            <div *ngIf="!sendNow" class="row mb-2">
                <div class="col-md-12">
                    <emr-textbox identifier="txtReportName" label="Report Name" i18n-label="@@label_report_name"
                        [(ngModel)]="request.ReportName" name="txtReportName" required placeholder="Report Name"
                        i18n-placeholder="@@label_report_name" [inline]=true labelColumns=3 textBoxColumns="9"
                        cssClass="text-left" ngDefaultControl [controlInline]=false>
                        <emr-validation-message validatorName="required" message="Report Name Required"
                            i18n-message="@@report_name_required"></emr-validation-message>
                    </emr-textbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <emr-textbox ngDefaultControl identifier="txtReportEmails" EmrEmailValidator
                        name="txtReportEmails" label="Email(s)" i18n-label="@@label_email" placeholder="Email(s)"
                        i18n-placeholder="@@label_email" [(ngModel)]="selectedEmails"
                        helpText="To add multiple emails, type email addresses separated with commas."
                        i18n-helpText="@@label_carrier_email_help_text" [inline]=true labelColumns=3
                        textBoxColumns="9" [controlInline]=false [required]="!isValidEmail" cssClass="text-left"
                        (onChange)="validateEmail()">
                        <emr-validation-message validatorName="EmrEmailValidator" i18n-message="@@validation_email"
                            message="Please enter valid Email">
                        </emr-validation-message>
                        <emr-validation-message validatorName="required" i18n-message="@@email_required"
                            message="Please enter/select atleast one e-mail address to send the report.">
                        </emr-validation-message>
                    </emr-textbox>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12  report-multiselect">
                    <emr-multiselect-dropdown identifier="lstRecentContacts" name="lstRecentContacts"
                        ngDefaultControl [options]="recentContactsList" label="Recent Contacts"
                        i18n-label="@@label_recent_contacts" [(ngModel)]="selectedRecentContacts"
                        placeholder="Recent Contacts" i18n-placeholder="@@label_recent_contacts" [inline]=true
                        [controlInline]=false labelColumns=3 controlColumns="9" selectAllText="Select All"
                        i18n-selectAllText="@@select_all" unSelectAllText="UnSelect All"
                        i18n-unSelectAllText="@@unselect_all" searchPlaceholderText="Search"
                        i18n-searchPlaceholderText="@@search" noDataAvailablePlaceholderText="No data available"
                        i18n-noDataAvailablePlaceholderText="@@no_data_available" (onItemSelect)="validateEmail()"
                        [validationIndicatorRequired]=false>
                    </emr-multiselect-dropdown>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-12 report-multiselect">
                    <emr-multiselect-dropdown identifier="lstContacts" name="lstContacts"
                        [options]="contactsList$ | async" label="Contacts" i18n-label="@@label_contacts"
                        ngDefaultControl [(ngModel)]="selectedContacts" placeholder="Contacts"
                        i18n-placeholder="@@label_contacts" [inline]=true [controlInline]=false labelColumns=3
                        controlColumns="9" selectAllText="Select All" i18n-selectAllText="@@select_all"
                        unSelectAllText="UnSelect All" i18n-unSelectAllText="@@unselect_all"
                        searchPlaceholderText="Search" i18n-searchPlaceholderText="@@search"
                        noDataAvailablePlaceholderText="No data available" (onItemSelect)="validateEmail()"
                        i18n-noDataAvailablePlaceholderText="@@no_data_available" [validationIndicatorRequired]=false>
                    </emr-multiselect-dropdown>
                </div>
            </div>
            <div *ngIf="showFormatButton" class="row mt-2">
                <div class="col-md-12">
                    <emr-custom-dropdown ngDefaultControl identifier="lstExportFormat" name="lstExportFormat"
                        [options]="exportFormats" [(ngModel)]="request.ReportFormat" cssClass="default-dropdown"
                        label="Export Format" i18n-label="@@label_export_format" [inline]=true labelColumns=3
                        controlColumns="9">
                    </emr-custom-dropdown>
                </div>
            </div>
            <div class="row mt-2" *ngIf="showEmailDetails">
                <div class="col-md-12">
                    <emr-textbox ngDefaultControl identifier="txtSubject" name="txtSubject" label="Subject"
                        i18n-label="@@label_subject" placeholder="Subject" i18n-placeholder="@@label_subject"
                        [(ngModel)]="request.EmailSubject" [inline]=true labelColumns=3 textBoxColumns="9"
                        [controlInline]=false cssClass="text-left" required>
                        <emr-validation-message validatorName="required" message="Subject Required"
                            i18n-message="@@message_subject_required"></emr-validation-message>
                    </emr-textbox>
                </div>
            </div>
            <div class="row mt-2" *ngIf="showEmailDetails">
                <div class="col-md-12">
                    <emr-textarea ngDefaultControl identifier="txtEmailBody" label="Message"
                        i18n-label="@@label_message" placeholder="Message" i18n-placeholder="@@label_message"
                        [(ngModel)]="request.EmailBody" name="txtEmailBody" [textAreaRows]="4" labelColumns=3
                        textareaColumns="9" cssClass="text-area-input" required>
                        <emr-validation-message validatorName="required" message="Message Required"
                            i18n-message="@@message_body_required"></emr-validation-message>
                    </emr-textarea>
                </div>
            </div>
            <ng-container modal-body *ngIf="(!sendNow && !showEmailDetails); then scheduleDetails">
            </ng-container>
        </div>
        <div *ngIf="!sendNow && showEmailDetails" class="col-6 for-email-popup">
            <ng-container modal-body *ngIf="(!sendNow && showEmailDetails); then scheduleDetails">
            </ng-container>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-md-12 text-center">
            <emr-button *ngIf="sendNow" identifier="btnEmailReport" buttonType="submit"
                cssClass="btn-outline-default mt-0" buttonIcon="fa fa-envelope-o"
                i18n-buttonText="@@label_email_report" buttonText="Email Report"
                (onClick)="onScheduleReportClick(true)">
            </emr-button>
            <emr-button *ngIf="!sendNow" identifier="btnScheduleReport" buttonType="submit"
                cssClass="btn-outline-default mt-0" buttonIcon="fa fa-calendar-check-o"
                [buttonText]="btnScheduleText" (onClick)="onScheduleReportClick(false)">
            </emr-button>
            <emr-button identifier="btnCancel" buttonText="Cancel" i18n-buttonText="@@link_cancel"
                buttonIcon="fa fa-times" cssClass="btn-outline-default mt-0" (onClick)="onCancelClick()">
            </emr-button>
        </div>
    </div>
    <ng-template #scheduleDetails>
        <div class="row {{ !sendNow && showEmailDetails ? '' : 'mt-2'}} ">
            <div class="col-md-12">
                <emr-checkboxlist ngDefaultControl identifier="chkDaysList" label="Days to run this report"
                    i18n-label="@@label_days_to_run" name="chkDaysList" [(ngModel)]="request.ReportDays"
                    [options]="DaysList" [inlineCheckbox]=true labelColumns=3 controlColumns=10
                    checkboxDivCssClass="control-border" required>
                    <emr-validation-message validatorName="required"
                        message=" Please select at least one day to run the report." i18n-message="@@report_day_required">
                    </emr-validation-message>
                </emr-checkboxlist>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12">
                <emr-checkboxlist ngDefaultControl identifier="chkHoursList" label="Time(s) of the day"
                    i18n-label="@@label_times_of_day" name="chkHoursList" [(ngModel)]="request.ReportTime"
                    [options]="HoursList" [inlineCheckbox]=true labelColumns=3 controlColumns=10
                    checkboxDivCssClass="control-border" required>
                    <emr-validation-message validatorName="required"
                        message="Please select at least one hour to run the report." i18n-message="@@report_time_required">
                    </emr-validation-message>
                </emr-checkboxlist>
            </div>
        </div>
    </ng-template>
</form>