<div class="container-fluid bg-data-div_ListView">
    <div class="row">
        <div class="col-md-4 col-12 sidebar">
            <app-shipment-filter></app-shipment-filter>
        </div>
        <div class="col-md-9 col-12 text-center flex-nowrap">
            <div class="row mt-1">
                <div class="col-md-9 col-sm-12 col-12 height-md-40 padding-right-1">
                    <app-pagination [pagingState$]="shipmentPaging$" [itemCount$]="shipmentCount$"
                        (PageSelected)="onPageSelected($event)" (PageSizeSelected)="onPageSizeSelected($event)"></app-pagination>
                </div>
                <div class=" col-md-3 col-sm-6 col-10 height-md-40 padding-left-1 ">
                    <app-shipment-sort-filter></app-shipment-sort-filter>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <app-static-shipment-list [shipments$]="shipmentList$" mainDivCssClass="mainDivCssClass" dataDivCssClass="dataDivCssClass"></app-static-shipment-list>
                </div>
            </div>
        </div>
    </div>
</div>