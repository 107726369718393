<nav class="navbar {{headerHeight>45?'':'navbar-expand-custom'}}  navbar-dark fixed-top bg-nav-header pr-lg-0" #header>
    <span class="navbar p-0">
        <emr-button identifier="navbarToggler" cssClass=" hamberg-menu btn-sm navbar-toggler cust-toggle cursor-pointer"
            aria-expanded="false" (onClick)="isCollapsed = true;sideMenuClick()"
            buttonIcon=" hamberg-menu fa fa-bars fa-2x cust-toggle-icon">
        </emr-button>

        <a class="navbar-brand padding-0 ml-2 cursor-pointer" (click)="onLogoClick()">
            <img class="emr-logo h-100" src="assets/copeland-logo.png" alt="" />
            <span class="logo-vbar"></span>
        </a>
        <a class="position-relative oversight-link navbar-brand-text" [routerLink]=defaultRoute>
            Oversight 2
            <span class="position-absolute oversight-version">{{version}}</span>
        </a>
        <!-- Removed to adjust the header for lower resolutions screens -->
        <!-- <a *ngIf="authState?.authenticated && isCustomerSelected&&headerHeight<45" target="_blank"
            href="{{oversightcargoURL}}" class="position-relative nav-link nav-link-text cursor-pointer ml-5"
            (click)="isCollapsed = true;">
            <i class="fa fa-star-o mx-1"></i>
            <ng-container i18n="@@label_oversight_cargo">Oversight Cargo Services</ng-container>
        </a> -->
    </span>
    <!-- Visible only when header height comes in two line -->
    <span *ngIf="headerHeight>45&&screenWidth>550" class="{{screenWidth>905&&isCustomerSelected?'ml-auto':''}}">
        <span
            *ngIf="isCustomerSelected&&authState?.canCreateShipment && (authState?.canAccessOversight || authState?.isEmersonAdmin)"
            class="nav-item">
            <a id="Create_Shipment_1" class="nav-link nav-link-text cursor-pointer"
                (keydown.Enter)="onCreateShipmentClick()" (click)="isCollapsed = true;onCreateShipmentClick()"
                i18n="@@link_createshipment">Create Shipment</a>
        </span>
        <span class="nav-item" *ngIf="(unAuthCreateShipmentAccess$ | async)">
            <a class="nav-link nav-link-text cursor-pointer" *ngIf="router.isActive('umapview', false)"
                (keydown.Enter)="onUnAuthCreateShipmentClick()"
                (click)="isCollapsed = true;onUnAuthCreateShipmentClick()" i18n="@@create_shipment">Create
                Shipment</a>
        </span>
    </span>

    <span class="ml-auto"
        *ngIf="authState?.authenticated == true&&headerHeight>45&&screenWidth>1100&&isCustomerSelected">
        <a translate="no" *ngIf="isCustomerSelected" routerLink="selectcustomer"
            class="notranslate nav-link nav-link-text pl-sm-0"
            (click)="isCollapsed = true;authSvc.setRequestedURL('mapview')">{{selectedCustomer }}</a></span>
    <span class="{{screenWidth<=1100? 'ml-auto':''}} position-relative" dropdown placement="bottom left"
        *ngIf="authState?.authenticated == true&&headerHeight>45&&screenWidth>905&&isCustomerSelected">
        <a translate="no" *ngIf="isCustomerSelected"
            class="notranslate nav-link nav-link-text pl-sm-0 pt-0 cursor-pointer" dropdownToggle
            (click)="isCollapsed = true;authSvc.setRequestedURL('mapview')">{{ authState?.displayName }}
            <span class="position-absolute oversight-version  pl-15"
                *ngIf="customerTimeZone">{{customerTimeZone}}</span></a>
        <div *dropdownMenu class="dropdown-menu {{isCollapsed?'':'dropdown-menu-right top-26 custom-inset'}}"
            role="menu">
            <a role="menuitem" class="dropdown-item nav-popup-text" [routerLink]="['changepassword']"
                i18n="@@link_changepassword" (click)="isCollapsed = true;">Change
                Password</a>
            <a role="menuitem" class="dropdown-item nav-popup-text" [routerLink]="['signin']"
                (click)='isCollapsed = true;onSignoutClick()' i18n="@@link_signout">Sign Out</a>
        </div>
    </span>
    <!-- Visible only when header height comes in two line -->
    <emr-button identifier="navbarToggler" cssClass="btn-sm navbar-toggler" aria-expanded="false"
        (onClick)="isCollapsed = !isCollapsed" buttonIcon="cust-toggle-icon fa fa-2x fa-bars"></emr-button>

    <div class="collapse navbar-collapse {{headerHeight>45?'text-right nav-right':''}}" [collapse]="isCollapsed">
        <ul class="navbar-nav ml-auto mr-auto mt-2 mt-lg-0 {{ authState?.authenticated == true ? '' : 'navbar-hide'}}"
            [style.visibility]="router.isActive('umapview', false) || isCustomerSelected ? 'visible' : 'hidden'">
            <li *ngIf="authState?.canCreateShipment && (authState?.canAccessOversight || authState?.isEmersonAdmin)"
                [routerLinkActive]="['link-active']" class="nav-item">
                <a id="Create_Shipment" class="nav-link nav-link-text cursor-pointer {{isCollapsed?'':'px-3 py-2'}}"
                    tabindex="0" (keydown.Enter)="onCreateShipmentClick()"
                    (click)="isCollapsed = true;onCreateShipmentClick()" i18n="@@link_createshipment"
                    [hidden]="headerHeight>45&&screenWidth>550">Create Shipment</a>
            </li>
            <li class="nav-item" *ngIf="(unAuthCreateShipmentAccess$ | async)">
                <a class="nav-link nav-link-text cursor-pointer {{isCollapsed?'':'px-3 py-2'}}"
                    *ngIf="router.isActive('umapview', false)" (keydown.Enter)="onUnAuthCreateShipmentClick()"
                    (click)="isCollapsed = true;onUnAuthCreateShipmentClick()" i18n="@@create_shipment">Create
                    Shipment</a>
            </li>
        </ul>
        <span *ngIf="headerHeight>45">
            <a *ngIf="authState?.authenticated && isCustomerSelected" target="_blank" href="{{oversightcargoURL}}"
                class="position-relative nav-link nav-link-text cursor-pointer" (click)="isCollapsed = true;">
                <i class="fa fa-star-o mx-1"></i>
                <ng-container i18n="@@label_oversight_cargo">Oversight Cargo Services</ng-container>
            </a></span>
        <span
            *ngIf="(hasAdminAccess || isLocusAdmin || hasDashboardAccess) && showDashboard && authState?.authenticated && authState?.canAccessOversight &&isCustomerSelected">
            <a translate="no" routerLink="scorecard" class="notranslate nav-link nav-link-text pl-sm-0"
                (click)="isCollapsed = true;" i18n="@@link_scorecards">
                Scorecards</a>
        </span>
        <span *ngIf="authState?.authenticated == true; else unauthCustomer">
            <a translate="no" *ngIf="isCustomerSelected; else selectCustomer" routerLink="selectcustomer"
                class="notranslate nav-link nav-link-text pl-sm-0 surya" [hidden]="headerHeight>45&&screenWidth>1100"
                (click)="isCollapsed = true;authSvc.setRequestedURL('mapview')">
                {{(this.custContext$ | async)?.customer?.Description }}</a>
            <ng-template #selectCustomer>
                <a routerLink="selectcustomer" class="nav-link nav-link-text pl-sm-0"
                    i18n="@@link_select_customer">Select Customer</a>
            </ng-template>
        </span>
        <ng-template #unauthCustomer>
            <span *ngIf="unAuthSvc.getUnAuthenticationToken()" translate="no"
                class="notranslate nav-link nav-link-text pl-sm-0">{{ (this.unAuthCustomer$ |
                async)?.Description
                }}</span>
        </ng-template>
        <span dropdown placement="bottom left" [isDisabled]="!authState||authState?.authenticated == false"
            [style.visibility]="this.locale ? 'visible' : 'hidden'" class="position-relative">
            <a translate="no"
                class="notranslate nav-link nav-link-text pl-sm-0 cursor-pointer {{authState?.authenticated?'pt-0':''}}"
                dropdownToggle (click)="onSigninClick()">
                <span *ngIf="!authState||authState?.authenticated == false; else signinUsername"
                    i18n="@@link_signin">SIGN IN</span>
                <ng-template #signinUsername>{{ authState?.displayName }}
                    <span class="position-absolute oversight-version pl-15"
                        *ngIf="customerTimeZone">{{customerTimeZone}}</span>
                </ng-template>
            </a>
            <div *dropdownMenu class="dropdown-menu {{isCollapsed?'':'dropdown-menu-right top-26 custom-inset'}}"
                role="menu">
                <a role="menuitem" class="dropdown-item nav-popup-text" [routerLink]="['changepassword']"
                    i18n="@@link_changepassword" (click)="isCollapsed = true;">Change
                    Password</a>
                <a role="menuitem" class="dropdown-item nav-popup-text" [routerLink]="['signin']"
                    (click)='isCollapsed = true;onSignoutClick()' i18n="@@link_signout">Sign Out</a>
            </div>
        </span>

        <!-- Recycle Dashboard -->
        <div *ngIf="authState?.authenticated && isCustomerSelected && (hasRecycleDashboardAccess||authState?.isEmersonAdmin)"
            [routerLink]="'/recycling/customer'">
            <a id="recycle-dashboard-icon" class="nav-link nav-link-text mt-1 pl-sm-0 cursor-pointer">
                <i class="fa fa-recycle font-22"></i>
            </a>
        </div>

        <span dropdown #dropdownUSettings="bs-dropdown"
            *ngIf="(!authState||authState?.authenticated == false)||(custSvc.isSelected$|async)" placement="bottom left"
            [autoClose]="false" [insideClick]="true" class="position-relative">
            <a id="uSettingButton" dropdownToggle class="nav-link nav-link-text pl-sm-0 cursor-pointer">
                <i class="fa fa-cog font-22"></i>
            </a>
            <div *dropdownMenu
                class="dropdown-menu dropdown-menu-left mt-1 px-2 uSettingsDiv settings-left {{isCollapsed?'':'dropdown-menu-right top-26 custom-inset'}}"
                role="menu">
                <app-unauth-customer-settings></app-unauth-customer-settings>
            </div>
        </span>

        <a id="localeButton" class="nav-link nav-link-text pl-sm-0" (click)="isCollapsed = true;onLocaleClick()">
            <img src="assets/world-map.png" class="cursor-pointer" alt="" height="24" width="50" />
        </a>
        <span style="min-width: 110px;">
            <a class="btn chat-btn" href="https://copeland.custhelp.com/app/chat/chat_launch"
                (click)="OpenChatWindow()">
                <span><img src="/assets/chat.png" height="20px" width="20px" /></span>
                <span i18n="@@label_livechat">Live Chat</span></a>
        </span>
    </div>



</nav>