import { Injectable } from '@angular/core';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { CalibrationStatement } from 'emr-ng-shared';

@Injectable()
export class CalibrationStatementsService {
    constructor(private oversightSvc: OversightApiService) { }

    public getCalibrationStatement(queryParams) {
        return this.oversightSvc.GetCalibrationStatement(queryParams);
    }

    public emailCalibrationStatement(request: CalibrationStatement[]) {
        return this.oversightSvc.EmailCalibrationStatement(request);
    }
}
