import { Action } from '@ngrx/store';

import { VehicleGroupInfo } from 'emr-ng-shared';
import * as actionIndex from 'app-modules/core/store/actions';

export class LoadVehicleGroups implements Action {
    readonly type = actionIndex.LOAD_VEHICLE_GROUPS;
}

export class LoadVehicleGroupsSuccess implements Action {
    readonly type = actionIndex.LOAD_VEHICLE_GROUPS_SUCCESS;

    constructor(public payload: VehicleGroupInfo[]) { }
}

export class LoadVehicleGroupsError implements Action {
    readonly type = actionIndex.LOAD_VEHICLE_GROUPS_ERROR;

    constructor(public payload: string) { }
}

export class CancelLoadVehicleGroups implements Action {
    readonly type = actionIndex.CANCEL_LOAD_VEHICLE_GROUPS;
}
