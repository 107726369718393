export const ContactTypeCodes = [
    { text: $localize`:@@contact_type_Unknown:Unknown`, value: 0 },
    { text: $localize`:@@contact_type_dispatcher:Dispatcher`, value: 101 },
    { text: $localize`:@@contact_type_destination:Destination`, value: 102 },
    { text: $localize`:@@contact_type_executive:Executive`, value: 103 },
    { text: $localize`:@@contact_type_driver:Driver`, value: 104 },
    { text: $localize`:@@contact_type_customer:Customer`, value: 105 },
    { text: $localize`:@@contact_type_maintenance:Maintenance`, value: 106 },
    { text: $localize`:@@contact_type_locus_lead:Locus Team Lead`, value: 107 },
    { text: $localize`:@@contact_type_locus_executive:LocusExecutive`, value: 108 },
    { text: $localize`:@@contact_type_locus_noc:LocusNOC`, value: 109},
    { text: $localize`:@@contact_type_company_main:CompanyMain`, value: 110},
    { text: $localize`:@@contact_type_company_primary:Company Primary`, value: 111 },
    { text: $localize`:@@contact_type_company_secondary:Company Secondary`, value: 112 },
    { text: $localize`:@@contact_type_company_billing:Company Billing`, value: 113 },
    { text: $localize`:@@contact_type_company_location:Location`, value: 114 },
]

export const ContactMethods = [
    { text: $localize`:@@label_email:Email`, value: 0x01 },
    { text: $localize`:@@contact_method_message:Text Message`, value: 0x02 }
]
