import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { SortOptions } from 'emr-ng-shared';

import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { ShipmentSortService } from 'app-modules/core/services/shipment-sort.service';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

@Component({
    selector: 'app-shipment-sort-filter',
    templateUrl: './shipment-sort-filter.component.html',
    styleUrls: ['./shipment-sort-filter.component.css']
})
export class ShipmentSortFilterComponent implements OnInit, OnDestroy {
    sortListSubscription: Subscription;
    selectedSortSubscription: Subscription;

    sortList: IListInfo<SortOptions>;
    selectedSort: string;

    constructor(private sortSvc: ShipmentSortService) { }

    ngOnInit() {
        this.sortListSubscription = this.sortSvc.sortList$.subscribe(n => this.sortList = n);
        this.selectedSortSubscription = this.sortSvc.selectedSort$.subscribe(n => this.selectedSort = n);
    }

    ngOnDestroy() {
        this.sortListSubscription.unsubscribe();
        this.selectedSortSubscription.unsubscribe();
    }

    onSortSelectionChange() {
        const newSort = this.sortSvc.calculateNewSort(this.selectedSort);
        this.sortSvc.changeShipmentSort(newSort.selectedSort, newSort.isDescending);
    }
}


