// tslint:disable: quotemark object-literal-key-quotes

export const LOCATION_COLORS = {
    "ColorList":  [
                      {
                          "ColorId":  100,
                          "ColorValue":  "Aquamarine",
                          "Description":  $localize`:@@color_Aquamarine:Aquamarine`
                      },
                      {
                          "ColorId":  101,
                          "ColorValue":  "Beige",
                          "Description":  $localize`:@@color_Beige:Beige`
                      },
                      {
                          "ColorId":  102,
                          "ColorValue":  "Blue",
                          "Description":  $localize`:@@color_Blue:Blue`
                      },
                      {
                          "ColorId":  103,
                          "ColorValue":  "BlueViolet",
                          "Description":  $localize`:@@color_BlueViolet:Blue Violet`
                      },
                      {
                          "ColorId":  104,
                          "ColorValue":  "Brown",
                          "Description":  $localize`:@@color_Brown:Brown`
                      },
                      {
                          "ColorId":  105,
                          "ColorValue":  "BurlyWood",
                          "Description":  $localize`:@@color_BurlyWood:Burly Wood`
                      },
                      {
                          "ColorId":  106,
                          "ColorValue":  "CadetBlue",
                          "Description":  $localize`:@@color_CadetBlue:Cadet Blue`
                      },
                      {
                          "ColorId":  107,
                          "ColorValue":  "Chartreuse",
                          "Description":  $localize`:@@color_Chartreuse:Chartreuse`
                      },
                      {
                          "ColorId":  108,
                          "ColorValue":  "Chocolate",
                          "Description":  $localize`:@@color_Chocolate:Chocolate`
                      },
                      {
                          "ColorId":  109,
                          "ColorValue":  "Coral",
                          "Description":  $localize`:@@color_Coral:Coral`
                      },
                      {
                          "ColorId":  110,
                          "ColorValue":  "CornflowerBlue",
                          "Description":  $localize`:@@color_CornflowerBlue:Cornflower Blue`
                      },
                      {
                          "ColorId":  111,
                          "ColorValue":  "Crimson",
                          "Description":  $localize`:@@color_Crimson:Crimson`
                      },
                      {
                          "ColorId":  112,
                          "ColorValue":  "Cyan",
                          "Description":  $localize`:@@color_Cyan:Cyan`
                      },
                      {
                          "ColorId":  113,
                          "ColorValue":  "DarkBlue",
                          "Description":  $localize`:@@color_DarkBlue:Dark Blue`
                      },
                      {
                          "ColorId":  114,
                          "ColorValue":  "DarkCyan",
                          "Description":  $localize`:@@color_DarkCyan:Dark Cyan`
                      },
                      {
                          "ColorId":  115,
                          "ColorValue":  "DarkGoldenRod",
                          "Description":  $localize`:@@color_DarkGoldenRod:Dark Golden Rod`
                      },
                      {
                          "ColorId":  116,
                          "ColorValue":  "DarkGray",
                          "Description":  $localize`:@@color_DarkGray:Dark Gray`
                      },
                      {
                          "ColorId":  117,
                          "ColorValue":  "DarkGreen",
                          "Description":  $localize`:@@color_DarkGreen:Dark Green`
                      },
                      {
                          "ColorId":  118,
                          "ColorValue":  "DarkKhaki",
                          "Description":  $localize`:@@color_DarkKhaki:Dark Khaki`
                      },
                      {
                          "ColorId":  119,
                          "ColorValue":  "DarkOliveGreen",
                          "Description":  $localize`:@@color_DarkOliveGreen:Dark Olive Green`
                      },
                      {
                          "ColorId":  120,
                          "ColorValue":  "DarkOrange",
                          "Description":  $localize`:@@color_DarkOrange:Dark Orange`
                      },
                      {
                          "ColorId":  121,
                          "ColorValue":  "DarkOrchid",
                          "Description":  $localize`:@@color_DarkOrchid:Dark Orchid`
                      },
                      {
                          "ColorId":  122,
                          "ColorValue":  "DarkRed",
                          "Description":  $localize`:@@color_DarkRed:Dark Red`
                      },
                      {
                          "ColorId":  123,
                          "ColorValue":  "DarkSalmon",
                          "Description":  $localize`:@@color_DarkSalmon:Dark Salmon`
                      },
                      {
                          "ColorId":  124,
                          "ColorValue":  "DarkSeaGreen",
                          "Description":  $localize`:@@color_DarkSeaGreen:Dark Sea Green`
                      },
                      {
                          "ColorId":  125,
                          "ColorValue":  "DarkSlateBlue",
                          "Description":  $localize`:@@color_DarkSlateBlue:Dark Slate Blue`
                      },
                      {
                          "ColorId":  126,
                          "ColorValue":  "DarkSlateGray",
                          "Description":  $localize`:@@color_DarkSlateGray:Dark Slate Gray`
                      },
                      {
                          "ColorId":  127,
                          "ColorValue":  "DarkTurquoise",
                          "Description":  $localize`:@@color_DarkTurquoise:Dark Turquoise`
                      },
                      {
                          "ColorId":  128,
                          "ColorValue":  "DarkViolet",
                          "Description":  $localize`:@@color_DarkViolet:Dark Violet`
                      },
                      {
                          "ColorId":  129,
                          "ColorValue":  "DeepPink",
                          "Description":  $localize`:@@color_DeepPink:Deep Pink`
                      },
                      {
                          "ColorId":  130,
                          "ColorValue":  "DeepSkyBlue",
                          "Description":  $localize`:@@color_DeepSkyBlue:Deep Sky Blue`
                      },
                      {
                          "ColorId":  131,
                          "ColorValue":  "DimGray",
                          "Description":  $localize`:@@color_DimGray:Dim Gray`
                      },
                      {
                          "ColorId":  132,
                          "ColorValue":  "DodgerBlue",
                          "Description":  $localize`:@@color_DodgerBlue:Dodger Blue`
                      },
                      {
                          "ColorId":  133,
                          "ColorValue":  "FireBrick",
                          "Description":  $localize`:@@color_FireBrick:Fire Brick`
                      },
                      {
                          "ColorId":  134,
                          "ColorValue":  "ForestGreen",
                          "Description":  $localize`:@@color_ForestGreen:Forest Green`
                      },
                      {
                          "ColorId":  135,
                          "ColorValue":  "Gold",
                          "Description":  $localize`:@@color_Gold:Gold`
                      },
                      {
                          "ColorId":  136,
                          "ColorValue":  "GoldenRod",
                          "Description":  $localize`:@@color_GoldenRod:Golden Rod`
                      },
                      {
                          "ColorId":  137,
                          "ColorValue":  "Gray",
                          "Description":  $localize`:@@color_Gray:Gray`
                      },
                      {
                          "ColorId":  138,
                          "ColorValue":  "Green",
                          "Description":  $localize`:@@color_Green:Green`
                      },
                      {
                          "ColorId":  139,
                          "ColorValue":  "GreenYellow",
                          "Description":  $localize`:@@color_GreenYellow:Green Yellow`
                      },
                      {
                          "ColorId":  140,
                          "ColorValue":  "HotPink",
                          "Description":  $localize`:@@color_HotPink:Hot Pink`
                      },
                      {
                          "ColorId":  141,
                          "ColorValue":  "IndianRed",
                          "Description":  $localize`:@@color_IndianRed:Indian Red`
                      },
                      {
                          "ColorId":  142,
                          "ColorValue":  "Indigo",
                          "Description":  $localize`:@@color_Indigo:Indigo`
                      },
                      {
                          "ColorId":  143,
                          "ColorValue":  "Khaki",
                          "Description":  $localize`:@@color_Khaki:Khaki`
                      },
                      {
                          "ColorId":  144,
                          "ColorValue":  "LawnGreen",
                          "Description":  $localize`:@@color_LawnGreen:Lawn Green`
                      },
                      {
                          "ColorId":  145,
                          "ColorValue":  "LightBlue",
                          "Description":  $localize`:@@color_LightBlue:Light Blue`
                      },
                      {
                          "ColorId":  146,
                          "ColorValue":  "LightCoral",
                          "Description":  $localize`:@@color_LightCoral:Light Coral`
                      },
                      {
                          "ColorId":  147,
                          "ColorValue":  "LightCyan",
                          "Description":  $localize`:@@color_LightCyan:Light Cyan`
                      },
                      {
                          "ColorId":  148,
                          "ColorValue":  "LightGray",
                          "Description":  $localize`:@@color_LightGray:Light Gray`
                      },
                      {
                          "ColorId":  149,
                          "ColorValue":  "LightGreen",
                          "Description":  $localize`:@@color_LightGreen:Light Green`
                      },
                      {
                          "ColorId":  150,
                          "ColorValue":  "LightPink",
                          "Description":  $localize`:@@color_LightPink:Light Pink`
                      },
                      {
                          "ColorId":  151,
                          "ColorValue":  "LightSalmon",
                          "Description":  $localize`:@@color_LightSalmon:Light Salmon`
                      },
                      {
                          "ColorId":  152,
                          "ColorValue":  "LightSeaGreen",
                          "Description":  $localize`:@@color_LightSeaGreen:Light Sea Green`
                      },
                      {
                          "ColorId":  153,
                          "ColorValue":  "LightSkyBlue",
                          "Description":  $localize`:@@color_LightSkyBlue:Light Sky Blue`
                      },
                      {
                          "ColorId":  154,
                          "ColorValue":  "LightSlateGray",
                          "Description":  $localize`:@@color_LightSlateGray:Light Slate Gray`
                      },
                      {
                          "ColorId":  155,
                          "ColorValue":  "LightSteelBlue",
                          "Description":  $localize`:@@color_LightSteelBlue:Light Steel Blue`
                      },
                      {
                          "ColorId":  156,
                          "ColorValue":  "Lime",
                          "Description":  $localize`:@@color_Lime:Lime`
                      },
                      {
                          "ColorId":  157,
                          "ColorValue":  "LimeGreen",
                          "Description":  $localize`:@@color_LimeGreen:Lime Green`
                      },
                      {
                          "ColorId":  158,
                          "ColorValue":  "Magenta",
                          "Description":  $localize`:@@color_Magenta:Magenta`
                      },
                      {
                          "ColorId":  159,
                          "ColorValue":  "Maroon",
                          "Description":  $localize`:@@color_Maroon:Maroon`
                      },
                      {
                          "ColorId":  160,
                          "ColorValue":  "MediumAquaMarine",
                          "Description":  $localize`:@@color_MediumAquaMarine:Medium Aqua Marine`
                      },
                      {
                          "ColorId":  161,
                          "ColorValue":  "MediumBlue",
                          "Description":  $localize`:@@color_MediumBlue:Medium Blue`
                      },
                      {
                          "ColorId":  162,
                          "ColorValue":  "MediumOrchid",
                          "Description":  $localize`:@@color_MediumOrchid:Medium Orchid`
                      },
                      {
                          "ColorId":  163,
                          "ColorValue":  "MediumPurple",
                          "Description":  $localize`:@@color_MediumPurple:Medium Purple`
                      },
                      {
                          "ColorId":  164,
                          "ColorValue":  "MediumSeaGreen",
                          "Description":  $localize`:@@color_MediumSeaGreen:Medium Sea Green`
                      },
                      {
                          "ColorId":  165,
                          "ColorValue":  "MediumSlateBlue",
                          "Description":  $localize`:@@color_MediumSlateBlue:Medium Slate Blue`
                      },
                      {
                          "ColorId":  166,
                          "ColorValue":  "MediumSpringGreen",
                          "Description":  $localize`:@@color_MediumSpringGreen:Medium Spring Green`
                      },
                      {
                          "ColorId":  167,
                          "ColorValue":  "MediumTurquoise",
                          "Description":  $localize`:@@color_MediumTurquoise:Medium Turquoise`
                      },
                      {
                          "ColorId":  168,
                          "ColorValue":  "MediumVioletRed",
                          "Description":  $localize`:@@color_MediumVioletRed:Medium Violet Red`
                      },
                      {
                          "ColorId":  169,
                          "ColorValue":  "MidnightBlue",
                          "Description":  $localize`:@@color_MidnightBlue:Midnight Blue`
                      },
                      {
                          "ColorId":  170,
                          "ColorValue":  "Navy",
                          "Description":  $localize`:@@color_Navy:Navy`
                      },
                      {
                          "ColorId":  171,
                          "ColorValue":  "Olive",
                          "Description":  $localize`:@@color_Olive:Olive`
                      },
                      {
                          "ColorId":  172,
                          "ColorValue":  "OliveDrab",
                          "Description":  $localize`:@@color_OliveDrab:Olive Drab`
                      },
                      {
                          "ColorId":  173,
                          "ColorValue":  "Orange",
                          "Description":  $localize`:@@color_Orange:Orange`
                      },
                      {
                          "ColorId":  174,
                          "ColorValue":  "OrangeRed",
                          "Description":  $localize`:@@color_OrangeRed:Orange Red`
                      },
                      {
                          "ColorId":  175,
                          "ColorValue":  "Orchid",
                          "Description":  $localize`:@@color_Orchid:Orchid`
                      },
                      {
                          "ColorId":  176,
                          "ColorValue":  "PaleGoldenRod",
                          "Description":  $localize`:@@color_PaleGoldenRod:Pale Golden Rod`
                      },
                      {
                          "ColorId":  177,
                          "ColorValue":  "PaleGreen",
                          "Description":  $localize`:@@color_PaleGreen:Pale Green`
                      },
                      {
                          "ColorId":  178,
                          "ColorValue":  "PaleTurquoise",
                          "Description":  $localize`:@@color_PaleTurquoise:Pale Turquoise`
                      },
                      {
                          "ColorId":  179,
                          "ColorValue":  "PaleVioletRed",
                          "Description":  $localize`:@@color_PaleVioletRed:Pale Violet Red`
                      },
                      {
                          "ColorId":  180,
                          "ColorValue":  "Peru",
                          "Description":  $localize`:@@color_Peru:Peru`
                      },
                      {
                          "ColorId":  181,
                          "ColorValue":  "Pink",
                          "Description":  $localize`:@@color_Pink:Pink`
                      },
                      {
                          "ColorId":  182,
                          "ColorValue":  "Plum",
                          "Description":  $localize`:@@color_Plum:Plum`
                      },
                      {
                          "ColorId":  183,
                          "ColorValue":  "PowderBlue",
                          "Description":  $localize`:@@color_PowderBlue:Powder Blue`
                      },
                      {
                          "ColorId":  184,
                          "ColorValue":  "Purple",
                          "Description":  $localize`:@@color_Purple:Purple`
                      },
                      {
                          "ColorId":  185,
                          "ColorValue":  "Red",
                          "Description":  $localize`:@@color_Red:Red`
                      },
                      {
                          "ColorId":  186,
                          "ColorValue":  "RosyBrown",
                          "Description":  $localize`:@@color_RosyBrown:Rosy Brown`
                      },
                      {
                          "ColorId":  187,
                          "ColorValue":  "RoyalBlue",
                          "Description":  $localize`:@@color_RoyalBlue:Royal Blue`
                      },
                      {
                          "ColorId":  188,
                          "ColorValue":  "SaddleBrown",
                          "Description":  $localize`:@@color_SaddleBrown:Saddle Brown`
                      },
                      {
                          "ColorId":  189,
                          "ColorValue":  "Salmon",
                          "Description":  $localize`:@@color_Salmon:Salmon`
                      },
                      {
                          "ColorId":  190,
                          "ColorValue":  "SandyBrown",
                          "Description":  $localize`:@@color_SandyBrown:Sandy Brown`
                      },
                      {
                          "ColorId":  191,
                          "ColorValue":  "SeaGreen",
                          "Description":  $localize`:@@color_SeaGreen:Sea Green`
                      },
                      {
                          "ColorId":  192,
                          "ColorValue":  "Sienna",
                          "Description":  $localize`:@@color_Sienna:Sienna`
                      },
                      {
                          "ColorId":  193,
                          "ColorValue":  "Silver",
                          "Description":  $localize`:@@color_Silver:Silver`
                      },
                      {
                          "ColorId":  194,
                          "ColorValue":  "SkyBlue",
                          "Description":  $localize`:@@color_SkyBlue:Sky Blue`
                      },
                      {
                          "ColorId":  195,
                          "ColorValue":  "SlateBlue",
                          "Description":  $localize`:@@color_SlateBlue:Slate Blue`
                      },
                      {
                          "ColorId":  196,
                          "ColorValue":  "SlateGray",
                          "Description":  $localize`:@@color_SlateGray:SlateGray`
                      },
                      {
                          "ColorId":  197,
                          "ColorValue":  "SpringGreen",
                          "Description":  $localize`:@@color_SpringGreen:Spring Green`
                      },
                      {
                          "ColorId":  198,
                          "ColorValue":  "SteelBlue",
                          "Description":  $localize`:@@color_SteelBlue:Steel Blue`
                      },
                      {
                          "ColorId":  199,
                          "ColorValue":  "Tan",
                          "Description":  $localize`:@@color_Tan:Tan`
                      },
                      {
                          "ColorId":  200,
                          "ColorValue":  "Teal",
                          "Description":  $localize`:@@color_Teal:Teal`
                      },
                      {
                          "ColorId":  201,
                          "ColorValue":  "Thistle",
                          "Description":  $localize`:@@color_Thistle:Thistle`
                      },
                      {
                          "ColorId":  202,
                          "ColorValue":  "Tomato",
                          "Description":  $localize`:@@color_Tomato:Tomato`
                      },
                      {
                          "ColorId":  203,
                          "ColorValue":  "Turquoise",
                          "Description":  $localize`:@@color_Turquoise:Turquoise`
                      },
                      {
                          "ColorId":  204,
                          "ColorValue":  "Violet",
                          "Description":  $localize`:@@color_Violet:Violet`
                      },
                      {
                          "ColorId":  205,
                          "ColorValue":  "Yellow",
                          "Description":  $localize`:@@color_Yellow:Yellow`
                      },
                      {
                          "ColorId":  206,
                          "ColorValue":  "YellowGreen",
                          "Description":  $localize`:@@color_YellowGreen:Yellow Green`
                      }
                  ],
    "ErrorCode":  0,
    "ErrorDescription":  null,
    "LocalizedErrorMessage":  null,
    "ltApiError":  0,
    "DBErrorCode":  0,
    "Count":  0
};
