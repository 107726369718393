import { Component, Input } from '@angular/core';

import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

@Component ({
    selector: 'app-error-message',
    templateUrl: './error-message.component.html',
    styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent {
    @Input() error: IErrorInfo;
}
