import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UserMessagePopupComponent } from 'app-modules/user-account/components/user-message/user-message-popup.component';
import { UserMessagePopup } from 'app-modules/core/models/user-message-popup.model';

@Injectable()
export class UserMessageService extends UserMessagePopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenUserMessagePopup(): void {
        const initialState = {};

        this.bsModalRef = this.modalSvc.show(
            UserMessagePopupComponent,
            {
                initialState,
                class: 'modal-lg modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}


