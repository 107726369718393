import { Injectable, Inject } from '@angular/core';
import { APP_IN_CHINA } from 'base/regionFinder';
import { environment } from 'environments/environment';
import { api } from 'environments/api';
import { CLIMATE_SITE_URL } from '../consts/values';

@Injectable({ providedIn: 'root' })
export class GoogleTagManager {
    isLoaded = false;
    constructor() {
        if (environment.production && !this.isLoaded) {
            (function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':

                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],

                    j: any = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =

                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);

            })(window, document, 'script', 'dataLayer', 'GTM-5BZ65NF');
            this.isLoaded = true;
        }
    }
}
