<div class="col-md-12">
    <!-- <div class="row">
        <div class="col-md-12">
            <label class="label-css" i18n="@@label_matching_locations">
                Matching Location(s) found
            </label>
            <hr class="m-1">
        </div>
    </div> -->
    <div class="row">
        <div class="col-md-12">
            <label class="label-css text-red" i18n="@@same_latitude_longitude">
                The Locations(s) below were near the latitude/longitude coordinates</label>
            <hr class="m-1">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card custom-card m-2" *ngFor="let loc of nearestLocations| slice:0:5; let i = index;">
                <div class="card-body p-2 pl-4">
                    <div class="row">
                        <div class="col-4">
                            <div *ngIf="loc.LocationName?.trim()?.length > 0" class="label-css d-inline">
                                <span>{{loc.LocationName}}</span>
                            </div>
                            <div *ngIf="loc.Address1?.trim()?.length > 0" class="label-css d-inline">
                                <span>{{loc.Address1.trim()+', '}}</span>
                            </div>
                            <div class="label-css d-inline">
                                <span *ngIf="loc.City?.trim()?.length > 0">{{loc.City.trim()+',
                                    '}}</span>
                                <span *ngIf="loc.State?.trim()?.length > 0">{{loc.State.trim()+'
                                    '}}</span>
                                <span *ngIf="loc.Zip?.trim()?.length > 0">{{loc.Zip.trim()}}</span>
                            </div>
                        </div>
                        <div class="align-self-center col-6">
                            <label i18n="@@warning_duplicate_existing_location" class="text-red label-css">
                                If you would like to edit the existing location then please edit this location from Edit
                                Locations in Location Management
                            </label>
                        </div>
                        <div class="text-right col-2 pl-0 align-self-center">
                            <label i18n="@@location_number" class="text-red label-css">Location Number</label>
                            <div class="label-css text-red text-center">{{loc.LocationId}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <hr class="m-1">
            <label class="label-css" i18n="@@continue_with_current_location">
                OR continue with current Location</label>
        </div>
    </div>
    <div *ngIf="CurrentLocation" class="card custom-card m-2 {{selectedIndex===0?'active':''}}"
        (click)="onLocationSelect(0)">
        <div class="card-body p-2 pl-4">
            <div class="row">
                <div class="ml-n1 mr-1" *ngIf="selectedIndex===0">
                    <i class="fa fa-check-circle" aria-hidden="true"></i>
                </div>
                <div class="col-4 px-0">
                    <div *ngIf="CurrentLocation.Name" class="label-css d-inline">
                        <span>{{CurrentLocation.Name.trim()}}</span>
                    </div>
                    <div *ngIf="CurrentLocation.Address1" class="label-css d-inline">
                        <span>{{CurrentLocation.Address1.trim()+', '}}</span>
                    </div>
                    <div class="label-css">
                        <span *ngIf="CurrentLocation.City">{{CurrentLocation.City.trim()+', '}}</span>
                        <span *ngIf="CurrentLocation.State">{{CurrentLocation.State.trim()+' '}}</span>
                        <span *ngIf="CurrentLocation.ZipCode">{{CurrentLocation.ZipCode.trim()}}</span>
                    </div>
                </div>
                <div class="col-auto align-self-center">
                    <label i18n="@@warning_duplicate_location" class="text-red label-css">
                        Warning if you proceed with this then it will create a duplicate location
                    </label>
                </div>
            </div>
        </div>
        <div class="card-footer p-1" *ngIf="!isFromImportLocations">
            <emr-button identifier="edit_location" buttonType="submit" cssClass="btn-sm btn-block m-0"
                buttonText="Edit Location" i18n-buttonText="@@label_edit_location" buttonIcon="fa fa-pencil-square-o"
                (onClick)="onEditNewLocation()"></emr-button>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-md-12">
            <emr-button identifier="continue_location" buttonType="submit"
                cssClass="btn-lg btn-primary quick-link btn-block m-0 mt-2" buttonText="Continue"
                i18n-buttonText="@@label_continue" buttonIcon="fa fa-Plus" (onClick)="onContinueClick()">
            </emr-button>
        </div>
    </div>
</div>