import { Injectable, OnDestroy } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith } from 'rxjs/operators';

import { muteFirst } from 'emr-ng-shared';

import { GetSortOptionsRequest } from 'emr-ng-shared';
import { SortOptions } from 'emr-ng-shared';

import { ShipmentSortStateService } from 'app-modules/core/store/services/shipment-sort-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { OversightApi } from 'emr-ng-shared';
import { IShipmentSortState } from '../store/models/shipment-sort-state.interface';

@Injectable()
export class ShipmentSortService implements OnDestroy {
    sortState: IShipmentSortState;
    sortSubscription: Subscription;

    constructor(
        private sortStateSvc: ShipmentSortStateService,
        private oversightSvc: OversightApiService
    ) {
        this.sortSubscription = sortStateSvc.sortState$.subscribe(n => this.sortState = n);
    }

    ngOnDestroy() {
        if (this.sortSubscription) {
            this.sortSubscription.unsubscribe();
        }
    }

    public isLoadRequired$ = this.sortStateSvc.isLoadRequired$;

    public isLoading$ = this.sortStateSvc.isLoading$;

    public sortList$: Observable<IListInfo<SortOptions>> = muteFirst(
        this.getSortListLoader().pipe(startWith(null)),
        this.sortStateSvc.sortList$
    );

    public selectedSort$ = this.sortStateSvc.selectedSort$;

    public isDescending$ = this.sortStateSvc.isDescending$;

    public descDefaultSortList = ['SatTime', 'ScheduledStartTime', 'ScheduledEndTime', 'ActualStartTime', 'ActualEndTime'];

    public calculateNewSort(selectedSort: string): IShipmentSortState {
        const newSort: IShipmentSortState = {
            ...this.sortState
        };

        if (selectedSort == newSort.selectedSort) {
            newSort.isDescending = !this.sortState.isDescending;
        } else {
            newSort.selectedSort = selectedSort;
            newSort.isDescending = this.descDefaultSortList.indexOf(selectedSort) > -1;
        }

        return newSort;
    }

    private getSortListLoader(): Observable<IListInfo<SortOptions>> {
        return this.sortStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.sortStateSvc.loadShipmentSortList()),
            switchMap(a => this.getShipmentSortList()),
            tap(
                n => this.sortStateSvc.loadShipmentSortListSuccess(n),
                e => this.sortStateSvc.loadShipmentSortListError('')
            ),
            finalize(() => this.sortStateSvc.cancelLoadShipmentSortList()),
            catchError(() => of(emptyList())),
            share()
        );
    }

    private getShipmentSortList(): Observable<IListInfo<SortOptions>> {
        const request = new GetSortOptionsRequest();

        request.EndPoint = OversightApi.GetTrackerStatus;

        return this.oversightSvc.GetSortOptions(request).pipe(
            map(n => {
                return {
                    list: n.Options,
                    itemCount: n.Options.length,
                    isPaged: false
                };
            })
        );
    }

    public changeShipmentSort(selectedSort: string, isDescending: boolean) {
        this.sortStateSvc.changeShipmentSort(selectedSort, isDescending);
    }

    public ChangeShipmentSortWithOutListUpdate(selectedSort: string, isDescending: boolean) {
        this.sortStateSvc.ChangeShipmentSortWithOutListUpdate(selectedSort, isDescending);
    }
}





