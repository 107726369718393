
import { LocationInfo } from 'emr-ng-shared';

export class LocationInfoListItem {
    get text() {
        return (this.locationInfo.LocationName ? this.locationInfo.LocationName :
        this.locationInfo.Description) + (this.locationInfo.Address1 ? ' - ' + this.locationInfo.LocationName : '');
    }
    get value() { return this.locationInfo; }
    constructor(private locationInfo: LocationInfo) { }
}

export class DistanceToTargetDetails {
    StartDestination: LocationInfo;
    TripDestination?: LocationInfo;
    Locations?: LocationInfoListItem[];
    Shipments?: LocationInfoListItem[];
    DefaultLocation: LocationInfo;
}

