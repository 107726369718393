import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { LocationService } from 'app-modules/core/services/location.service';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { LocationInfo, LocationTypeCode } from 'emr-ng-shared';
import { Subscription } from 'rxjs';
class SelectableLocationInfoListItem {
  checked: boolean;
  get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
  get value() { return this.locationInfo.LocationId; }
  constructor(public locationInfo: LocationInfo) { }
}
@Component({
  selector: 'app-shipment-filter-distribution-center',
  templateUrl: './shipment-filter-distribution-center.component.html',
  styleUrls: ['./shipment-filter-distribution-center.component.css']
})
export class ShipmentFilterDistributionCenterComponent implements OnInit {
  // bindable filter
  filterVal: number[];
  @Input() set filter(val) {
    this.filterVal = val;
    this.filterChange.emit(this.filterVal);
  }
  get filter() {
    return this.filterVal;
  }
  @Output() filterChange = new EventEmitter();


  // bindable isDirty
  isDirtyVal: boolean;
  @Input() get isDirty() { return this.isDirtyVal; }
  @Output() isDirtyChange = new EventEmitter();
  // tslint:disable-next-line: adjacent-overload-signatures
  set isDirty(val) {
    this.isDirtyVal = val;
    this.isDirtyChange.emit(this.isDirtyVal);
  }

  locationList: SelectableLocationInfoListItem[];

  private dcListSubscription: Subscription;
  private dcFilterSubscription: Subscription;

  constructor(
    public locationSvc: LocationService,
    private filterSvc: ShipmentFilterService
  ) { }

  public ngOnInit() {
    this.dcListSubscription = this.locationSvc.locationListIncludingCopy$.subscribe(n => {
      const dcLocations = n.list.filter(x=>x.TypeCode === LocationTypeCode.DistributionCenter)
      this.onDCLocationListChange(dcLocations)
    });
    this.dcFilterSubscription = this.filterSvc.distributionCenterFilter$.subscribe(n => this.onDCLocationFilterChange(n));
  }

  public ngOnDestroy() {
    this.dcListSubscription.unsubscribe();
    this.dcFilterSubscription.unsubscribe();
  }

  onDCLocationListChange(locationList: LocationInfo[]) {
    this.locationList = [];
    if (!locationList) { return; }

    locationList.forEach(item => {
      const listItem = new SelectableLocationInfoListItem(item);
      if (this.filter) {
        listItem.checked = this.filter.indexOf(item.LocationId) >= 0;
      }
      this.locationList.push(listItem);
    });
  }

  onDCLocationFilterChange(filter: number[]) {
    this.isDirty = false;
    this.filter = filter;
    if (filter) {
      this.locationList.forEach(item => item.checked = this.filter.indexOf(item.locationInfo.LocationId) >= 0);
    }
  }

}