import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { ShipmentStateService } from 'app-modules/core/store/services/shipment-state.service';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { PagingUtil } from 'app-modules/core/utils/paging-util';

import * as shipPgActions from 'app-modules/core/store/actions/shipment-paging.actions';

@Injectable()
export class ShipmentPagingStateService {
    private pagingUtil = new PagingUtil();

    constructor(
        private store: Store<IAppState>,
        private shipStateSvc: ShipmentStateService
    ) { }

    // Selectors
    public shipmentPagingStateSelector = ((state: IAppState) => state.shipmentPaging);
    public shipmentListPageSelector = createSelector(
        this.shipStateSvc.shipmentListSelector,
        this.shipmentPagingStateSelector,
        (listInfo, paging) => {
            return this.pagingUtil.GetPage(listInfo, paging.pageSize, paging.pageNumber);
        }
    );

    // Observables
    public shipmentPaging$ = this.store.select(this.shipmentPagingStateSelector).pipe();
    public shipmentListPage$ = this.store.select(this.shipmentListPageSelector).pipe();

    // Actions
    public changeShipmentsPage(pageNumber: number) {
        this.store.dispatch(new shipPgActions.ChangeShipmentsPage(pageNumber));
    }

    public changeShipmentsPageSize(pageSize: number) {
        this.store.dispatch(new shipPgActions.ChangeShipmentsPageSize(pageSize));
    }
}

