import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmbedDashboardComponent } from './embed-dashboard/embed-dashboard.component';
import { DomoConnect } from './services/domo-connect.service';
import { DomoUIService } from './services/domo.ui.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { DomoUIStateService } from './store/domo.state.service';
import { DashboardTermsMessagePopupComponent } from './dashboard-terms-message-popup/dashboard-terms-message-popup.component';
import { DashboardTermsMessageService } from './services/dashboard-terms-message-popup-service';
import { SigmaEmbedDashboardComponent } from './sigma-embed-dashboard/sigma-embed-dashboard.component';
import { SigmaDashboardComponent } from './sigma-dashboard/sigma-dashboard.component';
import { SafeUrlPipe } from './models/safe-url.pipe';


@NgModule({
  declarations: [
    EmbedDashboardComponent,
    DashboardTermsMessagePopupComponent,
    SigmaEmbedDashboardComponent,
    SigmaDashboardComponent,
    SafeUrlPipe
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    EmrNgControlsModule
  ],
  providers: [
    DomoConnect,
    DomoUIService,
    DomoUIStateService,
    DashboardTermsMessageService
  ]
})
export class DomoModule { }
