import { Component, OnInit, Input } from '@angular/core';
import { TraxxInfo } from 'emr-ng-shared';

@Component({
  selector: 'app-compare-traxx-info-box',
  templateUrl: './compare-traxx-info-box.component.html',
  styleUrls: ['./compare-traxx-info-box.component.css']
})
export class CompareTraxxInfoBoxComponent implements OnInit {

  @Input() markerInfo: TraxxInfo;
  @Input() trackerId: string;
  @Input() tripId: string;

  constructor() { }

  ngOnInit() {
  }

}
