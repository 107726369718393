import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { ErrorResponse } from 'emr-ng-shared';

import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

/*
    In this pipable operator, if the error code is not zero,
    an ErrorInfo type is emitted on the error channel.  Otherwise
    all other messages are passed through.

*/
export function handleErrorResponse(
    // predicate: (next: T) => boolean,
    // project: (next: T) => any,
    // thisArg?: any
): (source: Observable<ErrorResponse>) => Observable<ErrorResponse> {
    return (source: Observable<ErrorResponse>): Observable<ErrorResponse> => {
        const subject = new Subject<ErrorResponse>();

        const subscription = source.subscribe(
            // Next
            n => {
                if (n.ErrorCode === 0) {
                    subject.next(n);
                } else {
                    subject.error(
                        {
                            code: null,
                            message: n.LocalizedErrorMessage,
                            debugCode: n.ErrorCode,
                            debugMessage: n.ErrorDescription
                        } as IErrorInfo
                    );
                }
            },
            // Error
            e => {
                subject.error(e);
            },
            // Complete
            () => {
                subject.complete();
            }
        );

        return subject;
    };
}



