import { IDashboardState } from '../models/dashboard-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as dashboardActions from './dashboard.actions';

import { emptyList } from '../models/list-info-state.interface';

type Actions =
    authActions.SignOut |
    dashboardActions.LoadDashboards |
    dashboardActions.LoadDashboardsSuccess |
    dashboardActions.LoadDashboardsError |
    dashboardActions.SetDashboardSuccess |
    dashboardActions.SetDashboardError |
    dashboardActions.DeleteDashboardSuccess

export function DashboardReducer(state: IDashboardState = initialState.dashboardList, action: Actions): IDashboardState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.dashboardList
        case actionIndex.LOAD_DASHBOARD:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_DASHBOARD_SUCCESS:
            return {
                ...state,
                dashboardList: action.dashboardList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_DASHBOARD_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.SET_DASHBOARD_SUCCESS :
            const newDashboardsList = state.dashboardList.list.splice(0);
            const listInfo = emptyList();
            const dashboardIndex = newDashboardsList.findIndex(k => k.DashboardID === action.dashboard.DashboardID);
            if (dashboardIndex > -1) {
                newDashboardsList[dashboardIndex] = action.dashboard
            } else {
                newDashboardsList.push(action.dashboard);
            }
            listInfo.itemCount = newDashboardsList.length;
            listInfo.isPaged = false;
            listInfo.list = newDashboardsList;
            return {
                ...state,
                dashboardList: listInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SET_DASHBOARD_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

            case actionIndex.DELETE_DASHBOARD_SUCCESS:
            {
                const newdashboardList = emptyList();
                newdashboardList.list = state.dashboardList.list.filter(list => list.DashboardID !== action.dashboardID );
                newdashboardList.itemCount = newdashboardList.list.length;
                state.dashboardList = newdashboardList;                

                return {
                    ...state,
                    isLoadRequired: false,
                    errorMessage: ''
                };
            }
        default:
            return {
                ...state
            };
    }
}
