import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from "highcharts/highmaps";
import { Options } from "highcharts";
import USMap from "@highcharts/map-collection/countries/us/us-all.geo.json";
import { environment } from 'environments/environment';
import { AggregateType } from 'app-modules/dashboard/components/custom-table/custom-table.component';
import * as _ from 'lodash';
import { Shipment } from 'emr-ng-shared';
import { _supportsShadowDom } from '@angular/cdk/platform';

@Component({
  selector: 'app-map-chart',
  templateUrl: './map-chart.component.html',
  styleUrls: ['./map-chart.component.css']
})
export class MapChartComponent {

  constructor() { }
  chart: any;
  Highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "mapChart";
  noTemperatureRangeColor = "#eaeaea";

  arrayData: any[];
  updateValue: boolean = false;
  shipments: Shipment[] = [];

  @Input() title = '';
  @Input() subTitle = '';
  @Input() hasCustomLegends = false;
  @Input() minInterval = 0;
  @Input() maxInterval = 0;
  @Input() interval = 0;
  @Input() startColor = '';
  @Input() endColor = '';
  @Input() seriesName = '';
  @Input() hoverColor = '';
  @Input() aboveTemperatureRange = 0;
  @Input() belowTemperatureRange = 0;
  @Input() aboveTemperatureRangeColor = '';
  @Input() inTemperatureRangeColor = '';
  @Input() belowTemperatureRangeColor = '';
  @Input() aggregateType: AggregateType;
  @Input() keyProperty: '';
  @Input() avgProperty: '';

  shipmentsCount: number = 0;
  shipmentsAverage: number = 0;

  @Input()
  set chartData(value) {
    if (value && value.length > 0) {
      this.shipments = value;
      if (this.aggregateType === AggregateType.COUNT) {
        const filteredList = this.shipments.filter(x => this.isDefinedValue(x[this.keyProperty]));
        if (filteredList.length > 0) {
          this.arrayData = this.getCountableCollection(filteredList);
          if (this.arrayData.length > 0) {
            this.shipmentsCount = this.shipments.length;
            this.bindMapChart(this.arrayData);
            this.updateValue = true;
          }
        }
      } else if (this.aggregateType === AggregateType.AVG) {
        const filteredList = this.shipments.filter(x => this.isDefinedValue(x[this.avgProperty]));
        if (filteredList.length > 0) {
          this.arrayData = this.getAverageCollection(filteredList);
          if (this.arrayData.length > 0) {
            const kinecticTemp = this.arrayData.map(x => {return Number(x.value)});
            this.shipmentsAverage = Number((_.sum(kinecticTemp)/ kinecticTemp.length).toFixed(2));
            this.bindMapChart(this.arrayData);
            this.updateValue = true;
          }
        }
      }
    } else {
      this.shipmentsCount = 0;
      this.shipmentsAverage = 0;
      this.bindMapChart([]);
      this.updateValue = true;
    }
  };

  navigationOptions = {
    enabled: true,
    buttonOptions: {
      alignTo: "spacingBox"
    }
  };

  chartOptions = {
    chart: {
      map: ''
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    credits: {
      enabled: false
    },
    mapNavigation: this.navigationOptions,
    legend: {},
    colorAxis: {},
    series: [{
      type: '',
      name: '',
      states: {
        hover: {
          color: ''
        }
      },
      dataLabels: {},
      allAreas: true,
      data: []
    }]
  };

  legendOptions = {
    title: {
      text: '',
    },
    align: 'center',
    verticalAlign: 'bottom',
    floating: false,
    layout: 'horizontal',
    symbolRadius: 0,
    symbolHeight: 14,
    symbolPadding: 5,
    margin: 20
  };

  nonLegendOptions = {
    enabled: true
  }

  dataClassOptions = {
    from: 0,
    to: 0,
    name: '',
    color: ''
  };

  colorAxisOptions = {
    minColor: '',
    maxColor: '',
    dataClasses: []
  };

  nonLegendColorAxisOptions = {
    min: 0,
    max: 0,
    tickInterval: 0,
    stops: [],
    labels: {
      format: '{value}',
    }
  }

  dataLabelOptions = {
    enabled: true,
    format: "{point.value}",
    style: {
      fontSize: '9px'
    }
  }

  countryOptions = {
    key: '',
    value: ''
  }

  seriesOptions = {
    type: '',
    name: '',
    states: {
      hover: {
        color: ''
      }
    },
    dataLabels: {},
    allAreas: true,
    data: []
  };

  getAverageCollection(value) {
    let data = [];
    let list = [];
    const prop = this.avgProperty;
    let propType = typeof value[0][prop];
    let groupedList = _.groupBy(value, this.keyProperty);
    _.map(groupedList, function (value, key) {
      list = value.map(a => { return a[prop] ? a[prop] : 0 });
      if (propType != 'number') {
        list = list.map(x => Number(x.toString().replaceAll(/[^0-9&&^.&&^-]/g, "")));
      }
      let averageValue = _.sum(list) / list.length;
      data.push({ 'hc-key': 'us-' + key.toLowerCase(), value: averageValue.toFixed(1) });
    });
    return data;
  }

  checkUndefined(value) {
    return value = value === undefined ? "0" : value;
  }

  getCountableCollection(value) {
    let data = [];
    let groupedList = _.groupBy(value, this.keyProperty);
    _.map(groupedList, function (value, key) {
      data.push({ 'hc-key': 'us-' + key.toLowerCase(), value: value.length });
    });
    return data;
  }

  bindMapChart(data) {
    let options = { ...this.chartOptions };
    if (data) {
      options.chart.map = USMap as any;
      options.title.text = this.title;
      if (this.aggregateType === AggregateType.COUNT) {
        options.subtitle.text = this.subTitle + ' ' + this.shipmentsCount;
      } else {
        options.subtitle.text = this.subTitle + ' ' + this.shipmentsAverage;
      }

      options.mapNavigation = { ...this.navigationOptions };
      if (this.hasCustomLegends) {
        options.legend = { ...this.legendOptions };
        let colorOptions = { ...this.colorAxisOptions };
        colorOptions.maxColor = this.aboveTemperatureRangeColor;
        colorOptions.minColor = this.noTemperatureRangeColor;
        colorOptions.dataClasses = this.getLegendColorOptions();
        options.colorAxis = { ...colorOptions };
      } else {
        options.legend = { ...this.nonLegendOptions };
        let nonlegendColorOptions = { ...this.nonLegendColorAxisOptions };
        nonlegendColorOptions.max = this.maxInterval;
        nonlegendColorOptions.min = this.minInterval;
        nonlegendColorOptions.tickInterval = this.interval;
        let colors = this.getColorOptions(this.startColor, this.endColor, 4);
        nonlegendColorOptions.stops = this.getNonLegendColorOptions(colors);
        options.colorAxis = { ...nonlegendColorOptions };
      }
      let seriesOption = { ...this.seriesOptions };
      seriesOption.type = "map";
      seriesOption.name = this.seriesName;
      seriesOption.states.hover.color = this.hoverColor;
      seriesOption.dataLabels = { ...this.dataLabelOptions };
      seriesOption.data = data;
      let series: any[] = [];
      series.push({ ...seriesOption });
      options.series = series;
    }
    this.updateValue = true;
    this.chartOptions = { ...options };
  }

  rgbToHex(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
      : null;
  }

  getNonLegendColorOptions(colors: string[]) {
    const factor = 1 / (colors.length - 1);
    let ramp = [];
    for (let i = 0; i < colors.length; i++) {
      ramp.push([i * factor, colors[i]]);
    }
    return ramp;
  }

  getLegendColorOptions() {
    let ramp = [];
    ramp.push({ ...this.dataClassOptions, to: 0, name: 'No Data', color: this.noTemperatureRangeColor });
    ramp.push({ ...this.dataClassOptions, from: 0, to: this.belowTemperatureRange, name: 'Below Temperature Range', color: this.belowTemperatureRangeColor });
    ramp.push({ ...this.dataClassOptions, from: this.belowTemperatureRange, to: this.aboveTemperatureRange, name: 'In Temperature Range', color: this.inTemperatureRangeColor });
    ramp.push({ ...this.dataClassOptions, from: this.aboveTemperatureRange, to: 1000, name: 'Above Temperature Range', color: this.aboveTemperatureRangeColor });
    return ramp;
  }

  // returns an array of startColor, colors between according to steps, and endColor
  getColorOptions(startColor, endColor, steps) {
    let ramp = [];
    if (startColor != '' && endColor != '') {
      ramp.push(startColor);
      let startColorRgb = this.hexToRgb(startColor);
      let endColorRgb = this.hexToRgb(endColor);

      let rInc = Math.round((endColorRgb.r - startColorRgb.r) / (steps + 1));
      let gInc = Math.round((endColorRgb.g - startColorRgb.g) / (steps + 1));
      let bInc = Math.round((endColorRgb.b - startColorRgb.b) / (steps + 1));

      for (let i = 0; i < steps; i++) {
        startColorRgb.r += rInc;
        startColorRgb.g += gInc;
        startColorRgb.b += bInc;

        ramp.push(this.rgbToHex(startColorRgb.r, startColorRgb.g, startColorRgb.b));
      }
      ramp.push(endColor);
    }
    return ramp;
  }

  isDefinedValue(value) {
    let isValid = false;
    if (value && value != 'null' && value != 'undefined' && value != '') {
      isValid = true;
    }
    return isValid;
  }
}