import { Component, OnDestroy } from '@angular/core';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { Router } from '@angular/router';
import { ErrorResponse } from 'emr-ng-shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import {
    ForgotPasswordResponseComponent, ForgotPasswordResponseOkEventArgs 
} from '../forgot-password-response/forgot-password-response.component';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnDestroy {
    public error: string;
    bsModalRef: BsModalRef;
    forgotPasswordSubscription: Subscription;
    constructor(
        private authSvc: AuthService,
        private router: Router,
        private modalSvc: BsModalService
    ) {
    }

    onForgotPasswordSubmitted(username: string) {
        this.error = '';
        this.forgotPasswordSubscription = this.authSvc.forgotPassword(username).pipe().subscribe(
            n => {
                if (n.ErrorCode === 0) {
                    this.onForgotPasswordResponse(false, '');
                } else {
                    this.onForgotPasswordFailure(n);
                }
            },
            error => this.onForgotPasswordFailure(error)
        );
    }

    private onForgotPasswordResponse(hasError: boolean, errorMessage: string) {
        this.bsModalRef = this.modalSvc.show(
            ForgotPasswordResponseComponent,
            {
                initialState: {
                    hasError,
                    errorMessage
                },
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
        this.bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onForgotPasswordResponseOk(n));
    }

    private onForgotPasswordFailure(error: ErrorResponse) {
        this.error = error.LocalizedErrorMessage;
    }

    onForgotPasswordResponseOk(args: ForgotPasswordResponseOkEventArgs) {
        this.bsModalRef.hide();
        if (!args.hasError) {
            this.router.navigate(['signin']);
        }
    }

    ngOnDestroy(){
        if(this.forgotPasswordSubscription){
            this.forgotPasswordSubscription.unsubscribe();
        }
    }
}
