import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { EmrUtilService } from 'emr-ng-shared';
import { LocalePersistence } from 'app-modules/core/utils/locale-persistence';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import packageInfo from 'app-modules/../../../package.json';
import { Router } from "@angular/router"
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-menu-link',
    templateUrl: './menu-link.component.html',
    styleUrls: ['./menu-link.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MenuLinkComponent implements OnInit {
    custContext: Subscription;
    private v1RouteURL: string;
    public currentDate;
    @Input() webSiteId: string;
    public version: string = packageInfo.version + '.' + environment.buildNumber;
    @Input() options: string;
    @Input() routeLink: string;
    @Input() routeLinkParam: string;
    @Input() title: string;
    @Input() subMenuId = 0;
    @Input() target: string;
    @Input() cssClass: string;
    @Input() url: string = null;
    @Input() disabled: boolean = false;



    constructor(
        public route: Router,
        private utilSvc: EmrUtilService,
        private showPopups: OversightPopupService,
    ) { }

    ngOnInit() {
    }


    getV1LinkURL() {
        this.v1RouteURL = '';
        this.currentDate = this.utilSvc.DateFormatLocaleChange((new Date()));
        const refParameters = '?' + 'v2redirect=false&' + 'expire=' + this.currentDate;
        if (this.webSiteId) {
            this.v1RouteURL = '/Alerts.aspx/' + this.webSiteId + refParameters;
        } else {
            this.v1RouteURL = '/LaunchPad/SiteList.aspx' + refParameters;
        }
        window.open(environment.oversightV1BaseUrl + this.v1RouteURL, '_blank');
        return false;
    }

    onLocaleClick() {
        const localePersistence = new LocalePersistence(environment.localesPersistKey);
        localePersistence.clearLocale();

        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        const locale = localeResolver.resolveLocale();
        this.route.navigate(['/locale']);
        return false;
    }


    showIconInfo() {
        this.showPopups.showIconInfoPopup(false);
        return false;
    }
    onClick() {
        if (this.options) {
            switch (this.options) {
                case 'locale':
                    return this.onLocaleClick();
                    break;
                case 'v1':
                    return this.getV1LinkURL();
                    break;
                case 'Icons':
                    return this.showIconInfo();
                    break;
            }
        } else if (this.routeLink) {
            if (this.routeLinkParam) {
                if (this.target === "_blank") {
                    window.open(window.location.origin + this.routeLink + '/' + this.routeLinkParam, this.target);
                } else {
                    this.route.navigate([this.routeLink, this.routeLinkParam]);
                }
            } else {
                this.route.navigate([this.routeLink]);
            }
            return false;
        } else if (!this.url) {
            return false;
        }
    }

}
