import { StoreManager } from './store-manager';

export class LocalePersistence {
    constructor(private key: string) { }

    public clearLocale() {
        try {
            this.clearLocaleFromLocalStorage(this.key);
        } catch (e) { console.log(e); }

        try {
            this.clearLocaleFromCookie(this.key);
        } catch (e) { console.log(e); }
    }

    public persistLocale(locale: string) {
        try {
            this.persistLocaleToLocalStorage(this.key, locale);
        } catch (e) { console.log(e); }

        try {
            this.persistLocaleToCookie(this.key, locale);
        } catch (e) { console.log(e); }
    }


    private clearLocaleFromLocalStorage(key) {
        localStorage.removeItem(key);
    }

    private clearLocaleFromCookie(key: string) {
        const cookie: string = key + '= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
        document.cookie = cookie;
    }


    private persistLocaleToLocalStorage(key: string, locale: string) {
        StoreManager.persistToLocalStorage(key, locale);
    }

    private persistLocaleToCookie(key: string, locale: string) {
        StoreManager.persistToCookie(key, locale);
    }
}
