import { combineLatest, Observable } from 'rxjs';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { TimePeriodInfo, TimePeriod } from 'emr-ng-shared';
import { Shipment } from 'app-modules/core/models/shipment.model';


export function isNullTripAvoidTripPeriod(
    timePeriodList$: Observable<IListInfo<TimePeriodInfo>>,
    shipment$: Observable<Shipment>
) {
    return combineLatest(timePeriodList$, shipment$,
        (periods, shipment) => {
        const removeTrip = !(shipment && shipment.tripId);
        return removeTrip ?
        periods.list.filter((n) => n.Period !== TimePeriod.Trip) :
        periods.list;
    });
};
