import { Action } from '@ngrx/store';

import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';
import { Customer, ILatLong } from 'emr-ng-shared';

export class SaveUnAuthTrackers implements ServerRequestAction {
    readonly type = actionIndex.SAVE_UN_AUTH_TRACKERS;
    constructor(public UnAuthTrackers: string) { }
}
export class SaveUnAuthCustomer implements ServerRequestAction {
    readonly type = actionIndex.SAVE_UN_AUTH_CUSTOMER;
    constructor(public UnAuthCustomer: Customer) { }
}
export class SaveUnAuthCreateShipmentAccess implements ServerRequestAction {
    readonly type = actionIndex.UN_AUTH_CREATE_SHIPMENT_ACCESS;
    constructor(public hasAccess?: boolean) { }
}
export class UnAuthCreateShipment implements ServerRequestAction {
    readonly type = actionIndex.UN_AUTH_CREATE_SHIPMENT;
    constructor() { }
}

export class UnAuthCreateShipmentSuccess implements ServerRequestAction {
    readonly type = actionIndex.UN_AUTH_CREATE_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.UN_AUTH_CREATE_SHIPMENT;
    constructor() { }
}

export class UnAuthCreateShipmentError implements ServerRequestAction {
    readonly type = actionIndex.UN_AUTH_CREATE_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.UN_AUTH_CREATE_SHIPMENT;
    constructor() { }
}

export class SaveUnAuthCustomerLocation implements ServerRequestAction {
    readonly type = actionIndex.SAVE_UN_AUTH_CUSTOMER_LOCATION;
    constructor(public location: ILatLong) { }
}

export class ClearUnAuthCustomer implements Action {
    readonly type = actionIndex.CLEAR_UN_AUTH_CUSTOMER;
    constructor() { }
}






