import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UMapViewComponent } from './components/u-list-view/u-list-view.component';
import { UShipmentDetailsComponent } from './components/u-shipment-details/u-shipment-details.component';

const routes: Routes = [
  { path: 'umapview', component: UMapViewComponent },
  { path: 'uShipment', component: UShipmentDetailsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnAuthenticationRoutingModule { }
