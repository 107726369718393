import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { LocaleComponent } from './components/locale/locale.component';

const localeRoutes: Routes = [
    { path: '', component: LocaleComponent }
];

@NgModule ({
    imports: [
        CommonModule,
        RouterModule.forChild(localeRoutes)
    ]
})
export class LocaleRoutingModule {

}
