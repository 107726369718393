import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertSuppressionRoutingModule } from './alert-suppression-routing.module';
import { AlertSuppressionComponent } from './components/alert-suppression/alert-suppression.component';
import { AlertSuppressionPopupComponent } from './components/alert-suppression-popup/alert-suppression-popup.component';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    AlertSuppressionRoutingModule,
    EmrNgControlsModule,
    CollapseModule.forRoot(),
    TooltipModule.forRoot()
  ],
  declarations: [AlertSuppressionComponent, AlertSuppressionPopupComponent],
  exports: [AlertSuppressionComponent, AlertSuppressionPopupComponent]
})
export class AlertSuppressionModule { }

