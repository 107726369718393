import { NgModule } from '@angular/core';
import { CoreModule } from 'app-modules/core/core.module';
import { CommonModule } from '@angular/common';

import { UMapViewComponent } from './components/u-list-view/u-list-view.component';
import { UnAuthenticationRoutingModule } from './un-authentication-routing.module';
import { EmrNgControlsModule } from 'emr-ng-shared';
import { MapViewModule } from 'app-modules/map-view/map-view.module';
import { UnAuhtenticationService } from './services/un-authentication.service';
import { UCreateShipmentComponent } from './components/u-create-shipment/u-create-shipment.component';
import { UShipmentDetailsComponent } from './components/u-shipment-details/u-shipment-details.component';
import { ShipmentDetailModule } from 'app-modules/shipment-detail/shipment-detail.module';

@NgModule({
  imports: [
    CommonModule,
    UnAuthenticationRoutingModule,
    EmrNgControlsModule,
    CoreModule,
    MapViewModule,
    ShipmentDetailModule,
  ],
  providers: [
    UnAuhtenticationService
  ],
  declarations: [
    UMapViewComponent,
    UCreateShipmentComponent,
    UShipmentDetailsComponent
  ],
  exports: [
  ]
  // entryComponents: [
  //   SensorChartResponseComponent
  // ]
})
export class UnAuthenticationModule { }

