import { Customer } from 'emr-ng-shared';

import { ICustomerContextState } from 'app-modules/core/store/models/customer-context-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadCustomers implements ServerRequestAction {
    readonly type = actionIndex.LOAD_CUSTOMERS;
}

export class LoadCustomersSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_CUSTOMERS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_CUSTOMERS;
    constructor(public customerList: IListInfo<Customer>) { }
}

export class LoadCustomersError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_CUSTOMERS_ERROR;
    readonly callingAction = actionIndex.LOAD_CUSTOMERS;
    constructor(public message: string) { }
}

export class CancelLoadCustomers implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.LOAD_CUSTOMERS;
    readonly type = actionIndex.CANCEL_LOAD_CUSTOMERS;
}

export class SelectCustomer implements ServerRequestAction {
    readonly type = actionIndex.SELECT_CUSTOMER;
    constructor(public customerId: number) { }
}

export class SelectCustomerSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SELECT_CUSTOMER_SUCCESS;
    readonly callingAction = actionIndex.SELECT_CUSTOMER;
    constructor(public customerContext: ICustomerContextState) { }
}

export class SelectCustomerError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SELECT_CUSTOMER_ERROR;
    readonly callingAction = actionIndex.SELECT_CUSTOMER;
    constructor(public message: string) { }
}

