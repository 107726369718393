<emr-modal headerTitle="Sensor Range" i18n-headerTitle="@@sensor_range" [bsModalRef]=bsSensorRangeModalRef>
  <div modal-body class="col-md-12 bg-sensor-range">
    <form #sensorRangeForm="ngForm" emrForm>
      <div class="row">
        <div class="col-md-12">
          <emr-textbox ngDefaultControl identifier="txtSensorRangeName" label="Sensor Range Name"
            formGroupCssClass="m-0" i18n-label="@@sensor_range_name" [(ngModel)]="request.Name"
            name="txtSensorRangeName" required labelCssClass="mb-0" controlDivCssClass="w-100"
            placeholder="Sensor Range Name" i18n-placeholder="@@sensor_range_name">
            <emr-validation-message validatorName="required" message="Sensor range name is required"
              i18n-message="@@error_sensorrange_name_required"></emr-validation-message>
          </emr-textbox>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <emr-textbox ngDefaultControl inputType=number identifier="txtHighCriticalTemp" [label]=highCriticalLabel
            [(ngModel)]="request.HighCritical" name="txtHighCriticalTemp" required labelCssClass="mb-0"
            appendText="{{units}}" [inline]=true [controlInline]=true labelColumns=12 textBoxColumns=12
            controlDivCssClass="col-lg-5 col-md-5 pr-0"
            [EmrInvalidValidator]="!(displayCriticalMsg || displayInvalidHighMsg)"
            appendTextDivCssClass="col-lg-6 col-md-6" (onBlur)="validateSensorRange()">
            <emr-validation-message validatorName="required" message="&nbsp;"></emr-validation-message>
            <emr-validation-message validatorName="EmrInvalidValidator" message="&nbsp;"></emr-validation-message>
          </emr-textbox>

          <!-- <label class="label-css mb-0" i18n="@@high_critical_temperature"
            *ngIf="SensorType===Sensors.Temperature||SensorType===Sensors.ProbeTemp">
            High Critical Temperature</label>
          <label class="label-css mb-0" i18n="@@high_critical_humidity" *ngIf="SensorType===Sensors.Humidity">
            High Critical Humidity</label>
          <label class="label-css mb-0" i18n="@@high_critical_carbondioxide" *ngIf="SensorType===Sensors.CarbonDioxide">
            High Critical CO2</label>
        </div>
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-6">
              <input class="text-input form-control" required id="txtHighCriticalTemp" type="number"
                [(ngModel)]="request.HighCritical" name="txtHighCriticalTemp">
            </div>
            <div class="col-md-3 pl-1">
              <div *ngIf="SensorType===Sensors.Temperature||SensorType===Sensors.ProbeTemp">
                <label *ngIf="tempUnits == 'C'" for="txtHighCriticalTemp" class="label-css" i18n="@@temp_C">°C</label>
                <label *ngIf="tempUnits == 'F'" for="txtHighCriticalTemp" class="label-css" i18n="@@temp_F">°F</label>
              </div>
              <label *ngIf="SensorType===Sensors.Humidity" for="txtHighCriticalTemp" class="label-css">%</label>
              <label *ngIf="SensorType===Sensors.CarbonDioxide" for=" txtHighCriticalTemp" class="label-css"
                i18n="@@label_ppm">ppm</label>
            </div>
          </div>
        </div> -->


        </div>
      </div>
      <div class="row mt-2" *ngIf="SensorType===Sensors.Temperature||SensorType===Sensors.ProbeTemp">
        <div class="col-md-12">
          <emr-textbox ngDefaultControl inputType=number identifier="txtHighWarningTemp"
            label="High Warning Temperature" i18n-label="@@high_warning_temperature" [(ngModel)]="request.HighWarn"
            name="txtHighWarningTemp" required labelCssClass="mb-0" appendText="{{tempUnitString}}" [inline]=true
            [controlInline]=true [EmrInvalidValidator]="!(displayWarnMsg || displayInvalidHighMsg)"
            (onBlur)="validateSensorRange()" labelColumns=12 textBoxColumns=12
            controlDivCssClass="col-lg-5 col-md-5 pr-0" appendTextDivCssClass="col-lg-6 col-md-6">
            <emr-validation-message validatorName="required" message="&nbsp;"></emr-validation-message>
            <emr-validation-message validatorName="EmrInvalidValidator" message="&nbsp;"></emr-validation-message>
          </emr-textbox>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <emr-textbox ngDefaultControl inputType=number identifier="txtLowCriticalTemp" [label]=lowCriticalLabel
            [(ngModel)]="request.LowCritical" name="txtLowCriticalTemp" required labelCssClass="mb-0"
            appendText="{{units}}" [inline]=true [controlInline]=true labelColumns=12 textBoxColumns=12
            controlDivCssClass="col-lg-5 col-md-5 pr-0" appendTextDivCssClass="col-lg-6 col-md-6"
            [EmrInvalidValidator]="!(displayCriticalMsg || displayInvalidLowMsg)" (onBlur)="validateSensorRange()">
            <emr-validation-message validatorName="required" message="&nbsp;"></emr-validation-message>
            <emr-validation-message validatorName="EmrInvalidValidator" message="&nbsp;"></emr-validation-message>
          </emr-textbox>

          <!-- <label class=" label-css mb-0" i18n="@@low_critical_temperature"
            *ngIf="SensorType===Sensors.Temperature||SensorType===Sensors.ProbeTemp">Low Critical
            Temperature</label>
          <label class="label-css mb-0" i18n="@@low_critical_humidity" *ngIf="SensorType===Sensors.Humidity">
            Low Critical Humidity</label>
          <label class="label-css mb-0" i18n="@@low_critical_carbondioxide" *ngIf="SensorType===Sensors.CarbonDioxide">
            Low Critical CO2</label>
        </div>
        <div class="col-md-12 col-12">
          <div class="row">
            <div class="col-md-6">
              <input class="text-input form-control" required id="txtLowCriticalTemp" type="number"
                [(ngModel)]="request.LowCritical" name="txtLowCriticalTemp" />
            </div>
            <div class="col-md-3 pl-1">
              <div *ngIf="SensorType===Sensors.Temperature||SensorType===Sensors.ProbeTemp">
                <label *ngIf="tempUnits == 'C'" for="txtLowWarningTemp" class="label-css" i18n="@@temp_C">°C</label>
                <label *ngIf="tempUnits == 'F'" for="txtLowWarningTemp" class="label-css" i18n="@@temp_F">°F</label>
              </div>
              <label *ngIf="SensorType===Sensors.Humidity" for="txtLowWarningTemp" class="label-css">%</label>
              <label *ngIf="SensorType===Sensors.CarbonDioxide" for="txtLowWarningTemp" class="label-css"
                i18n="@@label_ppm">ppm</label>
            </div>
          </div> -->
        </div>
      </div>
      <div class="row" *ngIf="SensorType===Sensors.Temperature||SensorType===Sensors.ProbeTemp">
        <div class="col-md-12">
          <emr-textbox ngDefaultControl inputType=number identifier="txtLowWarningTemp" label="Low Warning Temperature"
            i18n-label="@@low_warning_temperature" [(ngModel)]="request.LowWarn" name="txtLowWarningTemp" required
            labelCssClass="mb-0" appendText="{{tempUnitString}}"
            [EmrInvalidValidator]="!(displayWarnMsg || displayInvalidLowMsg)" (onBlur)="validateSensorRange()"
            [inline]=true [controlInline]=true labelColumns=12 textBoxColumns=12
            controlDivCssClass="col-lg-5 col-md-5 pr-0" appendTextDivCssClass="col-lg-6 col-md-6">
            <emr-validation-message validatorName="required" message="&nbsp;"></emr-validation-message>
            <emr-validation-message validatorName="EmrInvalidValidator" message="&nbsp;"></emr-validation-message>
          </emr-textbox>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <emr-button identifier="addSensorRange" buttonType="submit"
            cssClass="btn-lg btn-primary quick-link btn-block m-0 mt-2" buttonText="Add Range"
            i18n-buttonText="@@add_range" buttonIcon="fa fa-Plus" (onClick)="onAddSensorRange()"></emr-button>
        </div>
      </div>
      <div class="row my-2 font-12 validationindicator">
        <div class="col-md-12">
          <app-error-message [error]="error"></app-error-message>
        </div>
        <div class="col-md-12">
          <span *ngIf="displayWarnMsg" i18n="@@600215">Sensor Range Low Warning cannot be the same as or more than High
            Warning.</span>
        </div>
        <div class="col-md-12">
          <span *ngIf="displayCriticalMsg" i18n="@@600216">Sensor Range Low Critical value cannot be the same as or more
            than the High Critical value.</span>
        </div>
        <div class="col-md-12">
          <span *ngIf="displayInvalidHighMsg" i18n="@@error_invalid_sensor_range_high_values">Sensor Range High
            Warning value must be lower than or equal to High Critical value.</span>
        </div>
        <div class="col-md-12">
          <span *ngIf="displayInvalidLowMsg" i18n="@@error_invalid_sensor_range_low_values">Sensor Range Low Critical
            value must be lower than or equal to Low Warn value.</span>
        </div>
      </div>
    </form>
  </div>
</emr-modal>