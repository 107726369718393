import { IHistoricalShipmentState } from 'app-modules/core/store/models/historical-shipment-state.interface';
import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as detailActions from 'app-modules/core/store/actions/shipment-detail.actions';
import * as historicalShipmentActions from 'app-modules/core/store/actions/shipment-detail-historical-shipment.actions';
import { emptyList } from '../models/list-info-state.interface';


type Actions =
    authActions.SignOut |
    historicalShipmentActions.LoadHistoricalShipmentList |
    historicalShipmentActions.LoadHistoricalShipmentListSuccess |
    historicalShipmentActions.LoadHistoricalShipmentListError |
    detailActions.SelectShipment;

export function ShipmentDetailHistoricalShipmentReducer(
    state: IHistoricalShipmentState = initialState.shipmentDetailHistoricalShipment,
    action: Actions
): IHistoricalShipmentState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailHistoricalShipment;

        case actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_SUCCESS:
            return {
                ...state,
                shipmentList: action.historicalShipmentList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
        case actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_ERROR:
            return {
                ...state,
                errorMessage: action.message
            };
        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
            return {
                ...state,
                shipmentList: emptyList(),
                isLoadRequired: true
            };
        default:
            return state;
    }
}
