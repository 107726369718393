import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MultiTripStateService } from './multi-trip-state-service';
import { map, tap, switchMap, catchError, finalize } from 'rxjs/operators';
import { IListInfo, emptyList } from '../models/list-info-state.interface';
import { MultiTrip, GetMultiTripRequest, GetMultiTripResponse } from 'emr-ng-shared';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { handleErrorResponse } from '../../rxjs/operators/handle-error-response.operator';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';

@Injectable()
export class MultiTripService {
    constructor(
        private multiTripStateSvc: MultiTripStateService,
        private oversightSvc: OversightApiService,
        private detailSvc: ShipmentDetailService,
    ) { }

    public getMultiTripLoader(): Observable<IListInfo<MultiTrip>> {
        return of(null).pipe(
            tap(() => this.multiTripStateSvc.loadMultiTrip()),
            switchMap(() => this.getMultiTrips()),
            tap(
                n => this.multiTripStateSvc.loadMultiTripSuccess(n),
                e => this.multiTripStateSvc.loadMultiTripError(e)
            ),
            finalize(() => this.multiTripStateSvc.cancelLoadMultiTrip()),
            catchError(() => of(emptyList()))
        );
    }

    // public getMultiTripLoader(): Observable<IListInfo<MultiTrip>> {
    //     return of(null).pipe(
    //         tap(() => this.multiTripStateSvc.loadMultiTrip()),
    //         switchMap(() => this.getMultiTrips()),
    //         handleErrorResponse(),
    //         map<GetMultiTripResponse, GetMultiTripResponse>(
    //             n => n
    //         ),
    //         tap(
    //             n => this.multiTripStateSvc.loadMultiTripSuccess(n),
    //             e => this.multiTripStateSvc.loadMultiTripError(e)
    //         ),
    //         finalize(() => this.multiTripStateSvc.cancelLoadMultiTrip()),
    //         catchError(() => of(emptyList()))
    //     );
    // }

    private getMultiTrips(): Observable<IListInfo<MultiTrip>> {
        return this.detailSvc.selectedShipment$.pipe(
            switchMap(n => {
                const request = new GetMultiTripRequest();
                request.DeviceId = n.customerTrackerId;
                if (this.oversightSvc.getUnAuthenticationToken()) {
                    request.GlobalDeviceId = n.trackerId;
                }
                return this.oversightSvc.GetMultiTripData(request);
            }),
            map(n => {
                let multiTripList: IListInfo<MultiTrip> = emptyList();
                if (n.Data) {
                    multiTripList = {
                        list: n.Data,
                        itemCount: n.Data.length,
                        isPaged: false
                    };
                }
                return multiTripList;
            })
        );
    }
}
