import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { label_supplier } from 'app-modules/core/consts/localization';
import { LinkedCustomersService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { CustomerLinkType, DualVisibilityLinkCustomer } from 'emr-ng-shared';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-shipment-filter-supplier-summary',
  templateUrl: './shipment-filter-supplier-summary.component.html',
  styleUrls: ['./shipment-filter-supplier-summary.component.css']
})
export class ShipmentFilterSupplierSummaryComponent implements OnInit {
  supplierList: DualVisibilityLinkCustomer[] = [];
  filter: number[];
  selectedList: DualVisibilityLinkCustomer[] = [];

  supplierListSubscription: Subscription;
  supplierFilterSubscription: Subscription;
  @Input() shipmentFilter: IShipmentFilterState;
  @Input() isHugeCustomer: boolean;
  @Output() summaryRemove = new EventEmitter();

  constructor(
    private linkedCustSvc: LinkedCustomersService,
    private filterSvc: ShipmentFilterService
  ) { }

  ngOnInit() {
    this.supplierListSubscription = this.linkedCustSvc.linkedShipperList$.subscribe(n => this.onSupplierListChange(n.list.filter(x => x.ShipperCustomerActive && x.LinkedTypeCode === CustomerLinkType.PUSH)));
    this.supplierFilterSubscription = this.filterSvc.supplierFilter$.subscribe(n => this.onSupplierFilterChange(n));
  }

  ngOnDestroy() {
    this.supplierListSubscription.unsubscribe();
    this.supplierFilterSubscription.unsubscribe();
  }

  onSupplierListChange(supplierList: DualVisibilityLinkCustomer[]) {
    this.supplierList = supplierList;
    this.filterSupplierList();
  }

  onSupplierFilterChange(filter: number[]) {
    this.filter = filter;
    this.filterSupplierList();
  }

  filterSupplierList() {
    this.selectedList = [];

    if (this.filter) {
      this.supplierList.forEach(item => {
        if (this.filter.indexOf(item.ShipperCustomerID) >= 0) {
          this.selectedList.push(
            item
          );
        }
      });
    }
  }

  clearSelectedFilter(supplierCustomerId: number) {
    this.shipmentFilter.supplierCustomer = this.shipmentFilter.supplierCustomer.filter(k => k !== supplierCustomerId);
    this.filterSvc.changeShipmentFilter(this.shipmentFilter);
    if (this.summaryRemove) {
      this.summaryRemove.emit({ title: label_supplier, value: this.shipmentFilter.supplierCustomer });
    }
  }
}