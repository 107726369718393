import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IPakSenseAuthState } from 'app-modules/core/store/models/paksense-auth-state.interface';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';

import * as PakSenseAuthActions from 'app-modules/core/store/paksense-auth/ps-auth.actions';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { STORE_KEYS } from 'app-modules/core/consts/values';

@Injectable({ providedIn: 'root'})
export class PakSenseAuthStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public authStateSelector = ((state: IAppState) => state.pakSenseAuth);
    public authenticatedSelector = createSelector(
        this.authStateSelector,
        (state: IPakSenseAuthState) => state.authenticated
    );
    public authTokenSelector = createSelector(
        this.authStateSelector,
        (state: IPakSenseAuthState) => state.authToken
    );

    // Observables
    public authState$ = this.store.select(this.authStateSelector).pipe();
    public authenticated$ = this.store.select(this.authenticatedSelector).pipe();
    public authToken$ = this.store.select(this.authTokenSelector).pipe();

    // Actions
    public signIn() {
        this.store.dispatch(new PakSenseAuthActions.PakSenseSignIn());
    }

    public signInSuccess(
        authToken: string,
        username: string,
        displayName: string
    ) {
        if (authToken === "null") {
            authToken = null;
        }
        StoreManager.StoreValue(STORE_KEYS.psAuthToken, authToken,
            // tslint:disable-next-line: no-bitwise
            StoreOpts.LocalStorage | StoreOpts.Cookie | StoreOpts.StoreManager);
        this.store.dispatch(new PakSenseAuthActions.PakSenseSignInSuccess(
            authToken,
            username,
            displayName
        ));
    }

    public signInError(error: IErrorInfo) {
        this.store.dispatch(new PakSenseAuthActions.PakSenseSignInError(error));
    }
}
