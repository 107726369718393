import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share, startWith } from 'rxjs/operators';

import { muteFirst } from 'emr-ng-shared';

import { TripStateInfo } from 'emr-ng-shared';

import { ShipmentStatusStateService } from 'app-modules/core/store/services/shipment-status-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';
import { ShipmentService } from './shipment.service';

@Injectable()
export class ShipmentStatusService {
    constructor(
        private shpService: ShipmentService,
        private statusStateSvc: ShipmentStatusStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.statusStateSvc.isLoadRequired$;

    public isLoading$ = this.statusStateSvc.isLoading$;

    public shipmentStatusList$: Observable<IListInfo<TripStateInfo>> = muteFirst(
        this.getShipmentStatusListLoader().pipe(startWith(null)),
        this.statusStateSvc.shipmentStatusList$
    );

    private getShipmentStatusListLoader(): Observable<IListInfo<TripStateInfo>> {
        return this.statusStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.statusStateSvc.loadShipmentStatuses()),
            switchMap(() => this.getShipmentStatusList()),
            tap(
                n => this.statusStateSvc.loadShipmentStatusesSuccess(n),
                e => this.statusStateSvc.loadShipmentStatusesError('')
            ),
            finalize(() => this.statusStateSvc.cancelLoadShipmentStatuses()),
            catchError(() => of(emptyList())),
            share()
        );
    }


    private getShipmentStatusList(): Observable<IListInfo<TripStateInfo>> {
        if (!this.oversightSvc.getUnAuthenticationToken()) {
            return this.oversightSvc.GetTripStates().pipe(
                map(n => {
                    return {
                        list: n.TripStates,
                        itemCount: n.TripStates.length,
                        isPaged: false
                    };
                })
            );
        } else {
            // return of({ list: [], isPaged: false, itemCount: 0 });
            const ShipmentStatusList = [];
            return this.shpService.shipmentList$.pipe(map(element => {
                element.list.forEach(k => {
                    if (!ShipmentStatusList.find(l => l.TripStateCode === k.tripStateCode)) {
                        ShipmentStatusList.push({ TripStateCode: k.tripStateCode, Description: k.tripStatus })
                    }
                });
                const data = { list: ShipmentStatusList, isPaged: false, itemCount: ShipmentStatusList.length }
                this.statusStateSvc.loadShipmentStatusesSuccess(data);
                return data;
            }));
        }
    }
}





