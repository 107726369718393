<emr-modal headerTitle="{{headerTitle}}" [bsModalRef]=bsModalRef
    [showCloseButton]=showCloseButton>
    <div modal-body>
        <div class="col-12 mt-1">
            <div *ngIf="hasError">
                <p class="validationindicator">{{ errorMessage }}</p>
            </div>
            <div *ngIf="hasError && debugErrorMessage">
                <hr>
                <p class="validationindicator">{{ debugErrorMessage }}</p>
            </div>
            <div *ngIf="sucessMessage" #noError>
                <p class="font-14">{{sucessMessage}}</p>
            </div>
        </div>
        <div class="col-12 text-center">
            <emr-button identifier="responseOk" cssClass="btn button-text btn-outline-default mt-0 mb-2"
                buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok" (click)='onOkClick()'></emr-button>
        </div>
    </div>
</emr-modal>