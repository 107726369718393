import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { intl } from 'environments/intl';
import { LocalePersistence } from '../utils/locale-persistence';
import { BASE_LOCALE_ID } from '../consts/locale-id';
import { AuthService } from '../store/auth/auth.service';

@Injectable()
export class LocaleGuard implements CanActivate {
    constructor(
        private router: Router,
        @Inject(BASE_LOCALE_ID) private baseLocale: string,
        private authSvc: AuthService
    ) {
        this.authSvc.updateRequestedParams();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        const locale = localeResolver.resolveLocale();

        if (Boolean(intl.isInternationalized)) {
            if (!this.baseLocale) {
                return this.canActivateForRoot(route, state, locale);
            } else {
                return this.canActivateForLocale(route, state, locale, this.baseLocale);
            }
        } else {
            return this.canActivateForSingle(route, state, locale);
        }
    }

    canActivateForRoot(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, locale: string): boolean {
        if (!locale) {
            this.navigateToLocale(route);
            return false;
        } else {
            window.location.href = '/' + locale + window.location.pathname + window.location.search;
            return false;
        }
    }

    canActivateForLocale(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, locale: string, baseLocale: string): boolean {
        if (!locale || locale !== baseLocale) {
            const localePersistence = new LocalePersistence(environment.localesPersistKey);
            localePersistence.persistLocale(baseLocale);
            window.location.href = window.location.href;
            return false;
        }

        return true;
    }

    canActivateForSingle(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, locale: string): boolean {
        if (!locale) {
            this.navigateToLocale(route);
            return false;
        } else {
            return true;
        }
    }

    navigateToLocale(route: ActivatedRouteSnapshot) {
        if (route.params["externalAuthToken"]) {
            let params = { a: route.params["externalAuthToken"] };
            if (route.queryParams) {
                params = { ...params, ...route.queryParams };
            }
            this.router.navigate(['locale'], { queryParams: params });
        } else {
            this.router.navigate(['locale']);
        }
    }
}
