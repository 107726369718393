import { Component, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmModalComponent, ConfirmPopupEventArgs } from 'app-modules/core/components/confirm-modal/confirm-modal.component';
import { CreateShipmentTemplateComponent } from 'app-modules/core/components/create-shipment-template/create-shipment-template.component';
import { ErrorMessageResponseComponent, EventArgs } from 'app-modules/core/components/error-message-response/error-message-response.component';
import { label_confirm_message, label_shipment_template, label_yes, link_cancel } from 'app-modules/core/consts/localization';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { ShipmentTemplateService } from 'app-modules/core/store/shipment-template/shipment-template.service';
import { CustomTableColumnDefs, loadColumnDef, TripTemplateInfo, VehicleGroupInfo } from 'emr-ng-shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-shipment-template',
  templateUrl: './shipment-template.component.html',
  styleUrls: ['./shipment-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentTemplateComponent implements OnInit, OnDestroy {

  @ViewChild(NgForm) templateForm: NgForm;
  @ViewChild('button', { static: true }) button;
  templateDetailsSubscription: Subscription;

  randomNumber: number;
  columnDefs: CustomTableColumnDefs[];
  searchInput: string;
  templateList: any[];
  templateData: any[];
  showQuickReset = false;
  loading$: Observable<boolean>;
  PreviousSearchedText: string;
  searchedTemplateId: number;
  shipmentTemplateBSModalRef: BsModalRef;
  deleteBSModalRef: BsModalRef;
  errorMsgModalRef: BsModalRef;

  templateList$: Observable<IListInfo<TripTemplateInfo>>;
  templateListSubscription: Subscription;

  vehicleGroupIds: VehicleGroupInfo[];
  VehicleGroups: VehicleGroupInfo[];

  templates: any[];
  filteredList: number;
  resetFilter: any;

  constructor(
    private renderer: Renderer2,
    private modalSvc: BsModalService,
    private createSvc: CreateShipmentService,
    private templateSvc: ShipmentTemplateService,
  ) {
    this.renderer.addClass(document.body, 'bg-page');
  }

  loadColumnDefinition() {
    this.columnDefs = [
      loadColumnDef({
        title: '', value: '', actionName: 'details', width: '120px',
        isSortable: false, isCustomHtml: true, customHTML: this.button, columnOrder: 1
      }),
      loadColumnDef({ title: 'Name', value: 'Name', width: '33%', columnOrder: 2, isFilter: true }),
      loadColumnDef({ title: 'Origin', value: 'OriginName', width: '33%', columnOrder: 3, isFilter: true, valueField: 'OriginLocationId' }),
      loadColumnDef({ title: 'Destination', value: 'DestinationName', width: '33%', isFilter: true, columnOrder: 4, valueField: 'DestinationLocationId' }),
      // loadColumnDef({ title: 'Temperature Range', value: 'SensorRangeName', width: '25%', isFilter: true, columnOrder: 5 }), // TODO Add back when Temperature Range column returns the correct value
    ];
  }

  ngOnInit(): void {
    this.randomNumber = Math.floor(Math.random() * Math.floor(100));
    this.loadColumnDefinition();

    this.templateData = this.templates;

    this.GetSearchFilterOptions();
  }

  private GetSearchFilterOptions() {

    this.templateListSubscription = this.createSvc.getShipmentTemplateListLoader().subscribe(k => {
      this.templateList = k.list.map(n => {
        let name: string = '';
        if (n.Name) { name = n.Name + ', ' }
        if (n.OriginName) { name += n.OriginName + ', ' }
        if (n.DestinationName) { name += n.DestinationName + ', ' }
        // if (n.SensorRangeName) { name += n.SensorRangeName + ', ' } // TODO Add filter after DB returns current Temperature Range Value
        name = name?.trim();
        if (name?.lastIndexOf(',') === name?.length - 1) {
          name = name.substring(0, name.length - 1)
        }
        return {
          text: name,
          value: n.TripTemplateId
        }
      });
      this.templateData = k.list;
      if (this.searchInput) {
        this.templates = [];
        this.checkForTemplateInLocal(this.templateList.find(k =>
          this.searchedTemplateId ? k.value === this.searchedTemplateId : k.text.toLowerCase() === this.searchInput)?.value);
      } else {
        this.templates = k.list;
        if (k?.list?.length > 0) {
          this.updateColumnCount(k.list);
        }
      }
    });

  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-page');
    if (this.templateDetailsSubscription) {
      this.templateDetailsSubscription.unsubscribe();
      this.templateDetailsSubscription = null;
    }
  }

  openAddTemplateModal() {
    this.shipmentTemplateBSModalRef = this.modalSvc.show(
      CreateShipmentTemplateComponent,
      {
        initialState: {
          isEditTemplate: false,
          isFromManageTemplates: true
        },
        class: 'modal-md max-width-1000 modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );
  }

  onSearchSubmit() {
    this.resetFilter = {clear: true};
    this.searchInput = this.searchInput ? this.searchInput.trim() : this.searchInput;
    if (this.PreviousSearchedText !== this.searchInput) {
      this.PreviousSearchedText = this.searchInput;
      this.checkForTemplateInLocal(this.templateList.find(k => k.text === this.searchInput)?.value);
    }
    this.showQuickReset = true;
  }

  resetQuickSearch() {
    this.resetFilter = {clear: false };
    this.searchedTemplateId = null;
    this.searchInput = this.PreviousSearchedText = null;
    this.showQuickReset = false;
    this.templates = [...this.templateData];
    this.updateColumnCount(this.templates);

  }

  onSearchInputSelect(data: any) {
    this.resetFilter = {clear: true };
    this.searchedTemplateId = null;
    if (this.PreviousSearchedText !== data.item.value) {
      this.PreviousSearchedText = data.item.text;
      this.searchInput = data.item.text;
      this.checkForTemplateInLocal(data.item.value);
      this.showQuickReset = true;
    }
  }

  customBlur() {
    this.searchInput = null;
    this.resetQuickSearch();
  }

  onFilterApply(col: CustomTableColumnDefs) {
    setTimeout(() => {
      this.filteredList = (col?.filteredDataCount) ? col?.filteredDataCount : 0;      
    }, 3);
  }

  onTemplateEdit(data) {
    this.openTemplateModal(data, true);
  }

  onDeleteClick(template: TripTemplateInfo) {

    if (template?.TripTemplateId) {
      this.deleteBSModalRef = this.modalSvc.show(
        ConfirmModalComponent,
        {
          initialState: {
            headerTitle: label_shipment_template,
            showCloseButton: true,
            confirmMessage: label_confirm_message,
            yesText: label_yes,
            noText: link_cancel,
          },
          class: 'modal-md modal-dialog-centered max-width-350 report-max-height',
          ignoreBackdropClick: true
        }
      );

      this.deleteBSModalRef.content.okCancelClick.pipe(take(1)).subscribe(n => this.onOKClick(n, template));
    }
  }

  onOKClick(args: ConfirmPopupEventArgs, template: TripTemplateInfo) {
    setTimeout(() => {
      switch (args.button) {
        case "Yes":
          this.deleteTemplate(template);
          break;
        case "No":
          // Do Nothing
          break;
      }
    }, 30);
    args.modalRef.hide();
  }

  deleteTemplate(template: TripTemplateInfo) {
    this.templateSvc.DeleteShipmentTemplate(template.TripTemplateId).pipe(take(1)).subscribe((n) => {
      if (n) {
        this.openSubmitResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage, n.ErrorCode == 0 ? true : false, label_shipment_template);
      }
    }, (e: IErrorInfo) => {
      this.openSubmitResponse(true, e.message, false, label_shipment_template)
    });
  }

  openSubmitResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null,) {
    this.errorMsgModalRef = this.modalSvc.show(
      ErrorMessageResponseComponent,
      {
        initialState: {
          hasError,
          errorMessage,
          closeOnOk,
          debugErrorMessage,
          sucessMessage: !hasError ? errorMessage : null,
          headerTitle: headerTitle
        },
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    this.errorMsgModalRef.content.ok.pipe(take(1)).subscribe(n => this.onResponseOk(n));
  }

  onResponseOk(args: EventArgs) {
    args.modalRef.hide();

    if (!args.hasError) {
      if (args.closeOnOk && this.errorMsgModalRef) {
        this.errorMsgModalRef.hide();
      } else {
        // Do Nothing
      }
    }
  }

  checkForTemplateInLocal(Id: number) {
    if (!Id) {
      return;
    }
    this.searchedTemplateId = Id;
    this.templates = this.templateData.filter(k => k.TripTemplateId === Id);
    this.updateColumnCount(this.templates)
  }

  updateColumnCount(k) {
    setTimeout(() => {
      this.filteredList = k?.length;
    });
    if (k.length > 0) {
      this.columnDefs.map(k => k.list = [], k.filteredList = [])
      k.map(a => {
        this.columnDefs.map(b => {
          if (!b.isFilter) {
            return b;
          }
          if (a[b.value] && a[b.valueField]?.toString().trim()?.length > 0) {
            const listData = b.list.find(k => a[b.valueField]?.toString()?.toLowerCase() === k.value?.toString()?.toLowerCase());
            if (!listData) {
              b.list.push({ text: a[b.value], value: a[b.valueField], count: 1, checked: false });
            } else {
              listData.count = listData.count + 1;
            }
            b.filteredList = b.list;
          }
        });
      });
    } else {
      this.columnDefs.forEach(b => { b.list = []; });
    }

    this.columnDefs = [...this.columnDefs];
  }

  private openTemplateModal(template: TripTemplateInfo, isEditTemplate = false) {
    let templateDetails = null
    if (template) {
      this.templateDetailsSubscription = this.createSvc.getTemplateDetails(template.TripTemplateId)
        .subscribe(n => {
          templateDetails = n;

          if (this.templateDetailsSubscription) {
            this.templateDetailsSubscription.unsubscribe();
            this.templateDetailsSubscription = null;
          }

          this.shipmentTemplateBSModalRef = this.modalSvc.show(
            CreateShipmentTemplateComponent,
            {
              initialState: {
                isEditTemplate: true,
                templateDetails: templateDetails,
                isFromManageTemplates: true
              },
              class: 'modal-md max-width-1000 modal-dialog-centered',
              ignoreBackdropClick: true
            });
          this.shipmentTemplateBSModalRef.content.addTemplate.pipe(take(1)).subscribe(n => this.onEditTemplate(n, isEditTemplate));
        
        });
      }
  }

  onEditTemplate(args: any, isEditTemplate: boolean) {
    this.shipmentTemplateBSModalRef.hide();
    // Reset previous values
  }

}
