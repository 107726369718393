import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShutdownTrackerComponent } from '../components/shutdown-tracker/shutdown-tracker.component';
import { ShutdownTrackerPopup } from '../models/shutdown-tracker-popup.model';
import { Shipment } from '../models/shipment.model';
import { SetShutDownTrackerRequest } from 'emr-ng-shared';

@Injectable()
export class ShutdownTrackerService extends ShutdownTrackerPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenShutdownTrackerConfirmation(request: SetShutDownTrackerRequest, isListViewAction: boolean, shipment: Shipment, closePopupOnSuccess?: BsModalRef): void {
        const initialState = {
            request: request,
            closePopupOnSuccess,
            isListViewAction,
            shipment
        };

        this.bsModalRef = this.modalSvc.show(
            ShutdownTrackerComponent,
            {
                initialState,
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true,
                keyboard: false
            }
        );
    }
}
