import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DomoAccessTokenModel } from "../models/domo-access-token.model";
import { DomoEmbedToken } from "../models/domo-embed-token.model";
import { ACCESS_TOKEN_URL, EMBED_TOKEN_URL, EMBED_CLIENT_ID, EMBED_SECRET_ID } from "../models/domo-config-consts";

export const DEFAULT_SCOPE_OPTIONS = ['data', 'audit', 'user'];
export const DEFAULT_ACCESS_GRANT_TYPE = 'client_credentials';



interface IHttpOptions {
    headers?: HttpHeaders;
}

@Injectable()
export class DomoConnect {

    constructor(private http: HttpClient) { }
    
    getAccessToken(): Observable<DomoAccessTokenModel> {
        let headerOptions = this.defaultHeaders();
        headerOptions = this.appendBasicAuthHeader(headerOptions);
        const options = this.getRequestOptions(headerOptions);
        return this.mapBody(this.get<DomoAccessTokenModel>(ACCESS_TOKEN_URL, options));
    }

    getEmbedToken(embedTokenId: string, access_token: string, custName: string ): Observable<DomoEmbedToken> {
        let headerOptions = this.defaultHeaders();
        headerOptions = this.appendAuthHeader(headerOptions, access_token);
        const options = this.getRequestOptions(headerOptions);
        const reqOptions = {
            "sessionLength": 1440,
            "authorizations": [
                {
                    "token": embedTokenId,
                    "permissions": [
                        "READ",
                        "FILTER",
                        "EXPORT"
                    ],
                    "filters": [
                        {
                            "column": "Company Name",
                            "operator": "IN",
                            "values": [custName]
                        }
                    ]
                }
            ]
        };

        return this.mapBody(this.post<DomoEmbedToken>(EMBED_TOKEN_URL, reqOptions, options));
    }

    private get<T>(
        url: string,
        options: IHttpOptions
    ): Observable<HttpResponse<T>> {
        return this.http.get<HttpResponse<T>>(url, options);
    }

    private post<T>(
        url: string,
        request: any,
        options: IHttpOptions
    ): Observable<HttpResponse<T>> {
        return this.http.post<HttpResponse<T>>(url, request, options);
    }
    
    private defaultHeaders() {
        return new HttpHeaders()
            .set('Content-Type', 'application/json');
    }

    private appendBasicAuthHeader(headers: HttpHeaders) {
        const authToken = btoa(`${EMBED_CLIENT_ID}:${EMBED_SECRET_ID}`);
        return this.appendAuthHeader(headers, authToken, true);
    }

    private appendAuthHeader(headers: HttpHeaders, authToken: string, isBasic: boolean = false) {
        return headers.set('Authorization', `${isBasic ? 'Basic' :  'Bearer'} ${authToken}`);
    }

    private getRequestOptions(headers: HttpHeaders) {
        return <IHttpOptions>{ headers, withCredentials: true, observe: 'response' };
    }

    private mapBody<T>(obs: Observable<HttpResponse<T>>): Observable<T> {
        return obs.pipe(map(n => n.body));
    }
}