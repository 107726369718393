import { IBusinessRulesState } from 'app-modules/core/store/models/business-rules-state.interface';

import { initialState } from 'app-modules/core/store/reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as groupsActions from './business-rules.actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';
import { emptyList } from '../models/list-info-state.interface';
import * as unauthActions from 'app-modules/core/store/un-auth/un-auth.actions';

type Actions =
    authActions.SignOut |
    groupsActions.CancelLoadBusinessRules |
    groupsActions.LoadBusinessRules |
    groupsActions.LoadBusinessRulesError |
    groupsActions.CheckBusinessRule |
    groupsActions.LoadBusinessRulesSuccess |
    custActions.SelectCustomerSuccess |
    unauthActions.SaveUnAuthCustomer |
    unauthActions.ClearUnAuthCustomer;

export function BusinessRulesReducer(
    state: IBusinessRulesState = initialState.businessRules,
    action: Actions
): IBusinessRulesState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
        case actionIndex.CLEAR_UN_AUTH_CUSTOMER:
            return {
                ...state,
                BusinessRules: emptyList(),
                isLoadRequired: false,
            };
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
        case actionIndex.SAVE_UN_AUTH_CUSTOMER:
            return {
                ...state,
                isLoadRequired: true,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_BUSINESS_RULES:
            return {
                ...state,
                isLoading: true
            };
        case actionIndex.LOAD_BUSINESS_RULES_SUCCESS:
            return {
                ...state,
                BusinessRules: action.businessRules,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_BUSINESS_RULES_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                errorMessage: action.payload
            };

        case actionIndex.CHECK_BUSINESS_RULES_STATE:
            return {
                ...state,
                isLoadRequired: true,
                isLoading: false,
                errorMessage: ''
            };

        default:
            return state;
    }
}
