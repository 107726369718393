import { Component, OnInit, ViewEncapsulation, OnDestroy, Inject } from '@angular/core';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { Observable, of, Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { EmrUtilService, BusinessRuleType, BusinessRuleInfo } from 'emr-ng-shared';
import { LocalePersistence } from 'app-modules/core/utils/locale-persistence';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { Router } from '@angular/router';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { map, switchMap, tap } from 'rxjs/operators';
import packageInfo from 'app-modules/../../../package.json';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { trigger, transition, style, animate } from '@angular/animations';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { BaseURLPicker } from 'app-modules/core/services/baseurl-picker.service';
import { APP_IN_CHINA } from 'base/regionFinder';
import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { FILE_NAMES } from 'app-modules/core/consts/file-names';
import { PakSenseAuthService } from 'app-modules/core/store/paksense-auth/ps-auth.service';
import { BOOK_A_TRAINING_URL, PAKSENSE_UI_REDIRECT_URL, OVERSIGHT_CARGO_SERVICES_URL } from 'app-modules/core/consts/values';
import { LocationsExportService } from 'app-modules/core/services/location-export.service';
import { intl } from 'environments/intl';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css'],
    encapsulation: ViewEncapsulation.None,
    // animations: [
    //     trigger(
    //         'inOutAnimation',
    //         [
    //             transition(
    //                 ':enter',
    //                 [
    //                     style({ height: 0, opacity: 0 }),
    //                     animate('1s ease-out',
    //                         style({ height: 300, opacity: 1 }))
    //                 ]
    //             ),
    //             transition(
    //                 ':leave',
    //                 [
    //                     style({ height: 300, opacity: 1 }),
    //                     animate('1s ease-in',
    //                         style({ height: 0, opacity: 0 }))
    //                 ]
    //             )
    //         ])]
})
export class MenuComponent implements OnInit, OnDestroy {

    cfaSupplierTGURL = `${this.domainPicker.resourceDomain}${FILE_NAMES.cfaSupplierTG}`;
    cfaDistributionCenterTGURL = `${this.domainPicker.resourceDomain}${FILE_NAMES.cfaDistributionCenterTG}`;
    cfaRedistributionTGURL = `${this.domainPicker.resourceDomain}${FILE_NAMES.cfaRedistributionTG}`;
    cfaDirectTGURL = `${this.domainPicker.resourceDomain}${FILE_NAMES.cfaDirectTG}`;
    cfaNondirectTGURL = `${this.domainPicker.resourceDomain}${FILE_NAMES.cfaNondirectTG}`;
    trainingURL = BOOK_A_TRAINING_URL;
    oversightcargoURL = OVERSIGHT_CARGO_SERVICES_URL;

    public showCompareTraxx = environment.allowCompareTraxx;
    public hideSideMenu = true;
    custContext$: Observable<any>;
    private v1RouteURL: string;
    public currentDate;
    private webSiteId;
    public version: string = packageInfo.version + '.' + environment.buildNumber;
    showMainMenu = true;
    subMenu = 0; // To know which sub menu needs to open
    menuText: string = null;
    showRouteBusinessRule = false;
    isLocusAdmin = false;
    hasDashboardAccess = false;
    hasAdminAccess = false;
    authStateSub: Subscription;
    hideMenuSub: Subscription;
    public oversightSecondaryURL;
    public isChineseAppDomain = false;
    isUnAuthenticated = false;
    authState: IAuthState;
    isCustomerSelected = false;
    isChickFilASupplier = false;
    hasRecycleDashboardAccess = false;
    authSub: Subscription;

    isPakSenseAuth$;
    pakSenseURL = `${environment.loggerUI_URL}`;
    // pakAuthToken$ = this.pakAuthSvc.authToken$;
    userName = '';
    showDashboard = environment.showDashboardLink;
    isLocationExportInprogress$ = this.locationExportSvc.isDownloadInProgress$;
    isEDIAPIConsumer: boolean = false;
    public locale: string = '';

    constructor(
        private authSvc: AuthService,
        private pakAuthSvc: PakSenseAuthService,
        private custSvc: CustomersService,
        private utilSvc: EmrUtilService,
        public route: Router,
        private businessruleSvc: BusinessRulesService,
        private showPopups: OversightPopupService,
        private domainPicker: BaseURLPicker,
        private locationExportSvc: LocationsExportService,
        @Inject(APP_IN_CHINA) isAppInChina: boolean
    ) {
        this.isChineseAppDomain = isAppInChina;
    }

    ngOnInit() {
        this.oversightSecondaryURL = this.domainPicker.secondaryDomain;
        this.isLocusAdmin = false;
        this.hasDashboardAccess = false;
        this.hasAdminAccess = false;
        this.authStateSub = this.authSvc.authState$.subscribe(au => {
            this.authState = au;
            this.userName = au.username;
            this.isLocusAdmin = au ? au.isEmersonAdmin : false;
            this.hasDashboardAccess = au ? au.isDashboardUser : false;
            this.hasAdminAccess = au ? au.isAdminUser : false;
            this.isEDIAPIConsumer = au ? au.isEDIAPIConsumer : false;
        });
        this.hideMenuSub = this.authSvc.hideSideMenu.subscribe(k => {
            this.isUnAuthenticated = this.authSvc.getUnAuthenticationToken();
            if (!k) {
                this.showMainMenu = true;
            }
            this.hideSideMenu = k;
        });
        this.custContext$ = this.custSvc.selectedContext$.pipe(
            tap(n => {
                this.webSiteId = null;
                this.isCustomerSelected = n && n.customer && n.customer.CustomerId > 0;
                if (n && n.customer && n.customer.WebsiteId) {
                    this.webSiteId = n.customer.WebsiteId;
                }
            }),
            switchMap(n => {
                if (n && n.customer) {
                    return this.businessruleSvc.businessRulesList$.pipe(map((businessRuleInfo: IListInfo<BusinessRuleInfo>) => {
                        if (businessRuleInfo.list && businessRuleInfo.list.length > 0) {
                            this.showRouteBusinessRule = businessRuleInfo.list.find(l => l.BusinessRuleTypeCode ===
                                BusinessRuleType.EnableRouteDeviation).Enabled;

                            this.isChickFilASupplier = businessRuleInfo.list.find(br => br.BusinessRuleTypeCode ===
                                BusinessRuleType.IsChickFilASupplier)?.Enabled;

                            this.hasRecycleDashboardAccess = businessRuleInfo.list.find(br => br.BusinessRuleTypeCode ===
                            BusinessRuleType.HasRecycleDashboardAccess)?.Enabled;

                            return businessRuleInfo.list.find(l => l.BusinessRuleTypeCode ===
                                BusinessRuleType.DisplayVehicleGroupingInCreateShipment).Enabled;
                        }
                    }));
                } else {
                    return of(false);
                }
            }),
        );
        if (Boolean(intl.isInternationalized)) {
            const localeResolver = new LocaleResolver(environment.localesPersistKey);
            this.locale = '/' + localeResolver.resolveLocale();
        }
        this.authSub = this.authSvc.authenticated$.subscribe(k => {
            if (k) {
                this.isPakSenseAuth$ = this.pakAuthSvc.loggerUserCheck(this.userName);
            } else {
                this.isPakSenseAuth$ = of(false);
            }
        })
    }

    ngOnDestroy() {
        if (this.authStateSub) {
            this.authStateSub.unsubscribe();
        }
        if (this.hideMenuSub) {
            this.hideMenuSub.unsubscribe();
        }
        this.authSub?.unsubscribe();
    }

    onShowSubMenu(Id) {
        this.showMainMenu = false;
        this.subMenu = Id;
    }

    onShowMainMenu() {
        const self = this;
        setTimeout(() => {
            self.showMainMenu = true;
        });
    }

    onLocaleClick() {
        const localePersistence = new LocalePersistence(environment.localesPersistKey);
        localePersistence.clearLocale();

        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        const locale = localeResolver.resolveLocale();
        this.route.navigate(['/locale']);
        return false;
    }

    onLocationsExport() {
        this.locationExportSvc.excelExport(false, "");
    }

    onCalibrationStatementClick() {
        this.showPopups.showCalibrationStatementPopup();
    }
}
