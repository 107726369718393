<div class="row">
  <div class="col-lg-4 col-md-6 col-sm-6 col-10 mx-auto col-10-max">
    <div class="text-left p-4 padding-sm-1">
      <h4>
        <span i18n="@@link_changepassword">Change Password</span>
      </h4>
      <h6>
        <span i18n="@@label_passwordrequirements">Password requirements</span>
      </h6>
      <h6>
        <ul>
        <li i18n="@@label_changepasswordmincharecters">8 characters minimum</li>
        <li i18n="@@label_changepasswordspecialcharecter">At least one special character</li>
      </ul>
      </h6>
      <form class="pt-3" modal-body name="frmresetpwd" #changePasswordForm="ngForm"
        [EmrStringCompareValidator]="['txtnewPassword', 'txtconfirmPassword']" emrForm autocomplete="off">
        <div class="row">
          <div class="col-md-12">
            <emr-textbox identifier="txtPassword" placeholder="Password" i18n-placeholder="@@label_password"
              inputType="password" label="Password" i18n-label="@@label_password" [(ngModel)]="password"
              name="txtPassword" cssClass="sign-in-input" appendIcon="fa fa-key" appendIconClass="input-icon" required
              minlength="4" [inline]=false labelColumns=3 textBoxColumns=9>
              <emr-validation-message validatorName="required" message="Please enter password."
                i18n-message="@@validation_password_required"></emr-validation-message>
              <emr-validation-message validatorName="minlength" message="Please enter at least 8 characters with one special character"
                i18n-message="@@validation_password_minlength"></emr-validation-message>
            </emr-textbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <emr-textbox identifier="txtnewPassword" placeholder="New Password" i18n-placeholder="@@label_new_password"
              inputType="password" [(ngModel)]="newPassword" label="New Password" i18n-label="@@label_new_password"
              name="txtnewPassword" cssClass="sign-in-input" appendIcon="fa fa-key" appendIconClass="input-icon"
              required minlength="8" [inline]=false labelColumns=3 textBoxColumns=9 #newPasswordControl="ngModel"
              pattern="^[^&\\;<>]*[^a-zA-Z0-9&\\;<>][^&\\;<>]*$">
              <emr-validation-message validatorName="required" message="Please enter password."
                i18n-message="@@validation_password_required"></emr-validation-message>
              <emr-validation-message validatorName="minlength" message="Please enter at least 8 characters with one special character"
                i18n-message="@@validation_password_minlength"></emr-validation-message>
            </emr-textbox>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <emr-textbox identifier="txtconfirmPassword" placeholder="Confirm New Password"
              i18n-placeholder="@@label_confirm_new_password" inputType="password" [(ngModel)]="confirmPassword"
              label="Confirm New Password" i18n-label="@@label_confirm_new_password" name="txtconfirmPassword"
              #confirmPasswordControl="ngModel" cssClass="sign-in-input" appendIcon="fa fa-key"
              appendIconClass="input-icon" required minlength="8" [inline]=false labelColumns=3 textBoxColumns=9
              pattern="^[^&\\;<>]*[^a-zA-Z0-9&\\;<>][^&\\;<>]*$">
              <emr-validation-message validatorName="required" message="Please enter password."
                i18n-message="@@validation_password_required"></emr-validation-message>
              <emr-validation-message validatorName="minlength" message="Please enter at least 8 characters with one special character"
                i18n-message="@@validation_password_minlength"></emr-validation-message>
            </emr-textbox>
            <div class="errorMessage"
              *ngIf="confirmPasswordControl.errors && (confirmPasswordControl.dirty || confirmPasswordControl.touched || changePasswordForm.submitted)">
              <p *ngIf="confirmPasswordControl.errors.EmrStringCompareValidator" i18n="@@password_match_error">Passwords
                not matched
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>
              <emr-button identifier="forgotPassword" buttonType="submit" cssClass="font-14 btn-lg btn-primary quick-link btn-block m-0 mt-2"
                buttonText="Submit" i18n-buttonText="@@link_submit" buttonIcon=""
                (onClick)="onForgotPasswordClick($event)"></emr-button>
            </p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <span class="validationindicator">
              <div class="forgotpassword-validation my-1" *ngIf="error!=null">
                <div>{{error}}</div>
              </div>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>