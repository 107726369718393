import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { TripStartOptions } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ITripStartOptionsState } from '../../models/trip-start-options-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as tripStartOptionActions from 'app-modules/core/store/create-shipment/trip-start-options/trip-start-options.actions';

@Injectable()
export class TripStartOptionsStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public tripStartOptionsStateSelector = ((state: IAppState) => state.tripStartOptions);
    public isLoadRequiredSelector = createSelector(
        this.tripStartOptionsStateSelector,
        (state: ITripStartOptionsState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.tripStartOptionsStateSelector,
        (state: ITripStartOptionsState) => state.isLoading
    );
    public tripStartOptionsListSelector = createSelector(
        this.tripStartOptionsStateSelector,
        (state: ITripStartOptionsState) => state.tripStartOptionsList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public tripStartOptionsList$ = this.store.select(this.tripStartOptionsListSelector).pipe();

    // Actions
    public loadTripStartOptions() {
        this.store.dispatch(new tripStartOptionActions.LoadTripStartOptions());
    }

    public loadTripStartOptionsSuccess(tripStartOptionsList: IListInfo<TripStartOptions>) {
        this.store.dispatch(new tripStartOptionActions.LoadTripStartOptionsSuccess(tripStartOptionsList));
    }

    public loadTripStartOptionsError(message: string) {
        this.store.dispatch(new tripStartOptionActions.LoadTripStartOptionsError(message));
    }

    public cancelLoadTripStartOptions() {
        this.store.dispatch(new tripStartOptionActions.CancelLoadTripStartOptions());
    }
}


