import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { filter, tap, switchMap, finalize, share, map, catchError, startWith } from 'rxjs/operators';

import { muteFirst, DualVisibilityLinkCustomer } from 'emr-ng-shared';

import { LinkedCustomerStateService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class LinkedCustomersService {
    constructor(
        private linkedCustomerStateSvc: LinkedCustomerStateService,
        private oversightSvc: OversightApiService
    ) { }

    public linkedCustomerState$ = this.linkedCustomerStateSvc.linkedCustomerState$;

    public isLoadRequired$ = this.linkedCustomerStateSvc.isLoadRequired$;

    public linkedCustomerList$: Observable<IListInfo<DualVisibilityLinkCustomer>> = muteFirst(
        this.initLinkedCustomerList().pipe(startWith(null)),
        this.linkedCustomerStateSvc.linkedCustomerList$
    );

    public linkedCustomersPushList$: Observable<IListInfo<DualVisibilityLinkCustomer>> = muteFirst(
        this.linkedCustomerList$.pipe(startWith(null)),
        this.linkedCustomerStateSvc.linkedCustomersPushListSelector$
    );

    public linkedCustomersPullList$: Observable<IListInfo<DualVisibilityLinkCustomer>> = muteFirst(
        this.linkedCustomerList$.pipe(startWith(null)),
        this.linkedCustomerStateSvc.linkedCustomersPullListSelector$
    );

    private initLinkedCustomerList(): Observable<IListInfo<DualVisibilityLinkCustomer>> {
        return this.linkedCustomerStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.linkedCustomerStateSvc.loadLinkedCustomers()),
            switchMap(() => this.getLinkedCustomerList()),
            tap(
                n => this.linkedCustomerStateSvc.loadLinkedCustomersSuccess(n),
                e => this.linkedCustomerStateSvc.loadLinkedCustomersError(e)
            ),
            finalize(() => this.linkedCustomerStateSvc.cancelLoadLinkedCustomers()),
            catchError(() => {
                this.linkedCustomerStateSvc.cancelLoadLinkedCustomers();
                return of(emptyList());
            }),
            share()
        );
    }

    private getLinkedCustomerList(): Observable<IListInfo<DualVisibilityLinkCustomer>> {
        return this.oversightSvc.GetLinkedCustomers().pipe(
            map((response) => {
                return {
                    list: response.LinkedCustomerList,
                    itemCount: response.LinkedCustomerList.length,
                    isPaged: false
                };
            })
        );
    }

    public linkedShipperList$: Observable<IListInfo<DualVisibilityLinkCustomer>> = muteFirst(
        this.initLinkedShipperList().pipe(startWith(null)),
        this.linkedCustomerStateSvc.linkedShipperList$
    );

    public linkedShipperPushList$: Observable<IListInfo<DualVisibilityLinkCustomer>> = muteFirst(
        this.linkedShipperList$.pipe(startWith(null)),
        this.linkedCustomerStateSvc.linkedShipperPushListSelector$
    );

    private initLinkedShipperList(): Observable<IListInfo<DualVisibilityLinkCustomer>> {
        return this.linkedCustomerStateSvc.isLoadShipperRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.linkedCustomerStateSvc.loadLinkedShippers()),
            switchMap(() => this.getLinkedShipperList()),
            tap(
                n => this.linkedCustomerStateSvc.loadLinkedShipperSuccess(n),
                e => this.linkedCustomerStateSvc.loadLinkedShipperError(e)
            ),
            finalize(() => this.linkedCustomerStateSvc.cancelLoadLinkedShippers()),
            catchError(() => {
                this.linkedCustomerStateSvc.cancelLoadLinkedShippers();
                return of(emptyList());
            }),
            share()
        );
    }

    private getLinkedShipperList(): Observable<IListInfo<DualVisibilityLinkCustomer>> {
        return this.oversightSvc.GetLinkedShippers().pipe(
            map((response) => {
                return {
                    list: response.LinkedCustomerList,
                    itemCount: response.LinkedCustomerList.length,
                    isPaged: false
                };
            })
        );
    }

}