import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export class ConfirmPopupEventArgs {
  modalRef: BsModalRef;
  button: string;
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  headerTitle: string;
  subHeaderTitle: string;
  showCloseButton: boolean;
  confirmMessage: string;
  yesText: string;
  noText: string;

  @Output() okCancelClick = new EventEmitter<ConfirmPopupEventArgs>();

  constructor(public bsModalRef: BsModalRef) { }
  
  ngOnInit(): void {
  }

  onClickYes() {
      const args = new ConfirmPopupEventArgs();
      args.modalRef = this.bsModalRef;
      args.button ="Yes";
      this.okCancelClick.emit(args);
  }
  onClickNo() {
      const args = new ConfirmPopupEventArgs();
      args.modalRef = this.bsModalRef;
      args.button ="No";
      this.okCancelClick.emit(args);
  }
}
