import { Action } from '@ngrx/store';

import { TripStateInfo } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadShipmentStatuses implements Action {
    readonly type = actionIndex.LOAD_SHIPMENT_STATUSES;
}

export class LoadShipmentStatusesSuccess implements Action {
    readonly type = actionIndex.LOAD_SHIPMENT_STATUSES_SUCCESS;
    constructor(public shipmentStatusList: IListInfo<TripStateInfo>) { }
}

export class LoadShipmentStatusesError implements Action {
    readonly type = actionIndex.LOAD_SHIPMENT_STATUSES_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadShipmentStatuses implements Action {
    readonly type = actionIndex.CANCEL_LOAD_SHIPMENT_STATUSES;
}
export class ReloadShipmentStatuses implements Action {
    readonly type = actionIndex.RE_LOAD_SHIPMENT_STATUSES;
    constructor() { }
}

