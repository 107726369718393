import { Inject, Injectable } from "@angular/core";
import { CustomMapServiceFactory, LocationInfo, MapServiceFactory, IBaseGeometryHelper, LocationCollisionStatus, ILatLong, DistanceUnits, LocationCollisionInfo } from "emr-ng-shared";
import { label_geo_fence_geo_overlapped, label_geo_fence_overlapped, label_yes } from "../consts/localization";
import { LocationService } from "./location.service";
import * as _ from 'lodash';


@Injectable({ providedIn: 'root' })
export class LocationCollisionService {
    GeometryHelper: IBaseGeometryHelper;
    locationsUpdated: boolean;
    locationData: LocationInfo[];
    distanceUnits: DistanceUnits;

    constructor(
        public locationSvc: LocationService,
        @Inject(MapServiceFactory) private mapSvc: CustomMapServiceFactory
    ) {
        this.locationSvc.loadMapStaticLibrary();
        this.mapSvc.GetStaticModulesInstance().GetMapGeometryHelper().then((t) => {
            this.locationSvc.loadMapStaticLibrarySuccess();
            this.GeometryHelper = t;
            if (!this.locationsUpdated &&
                this.locationData) {
                this.updateLocationCollisions(this.locationData, this.distanceUnits);
            }
        }).catch(e => this.locationSvc.loadMapStaticLibraryError());
    }

    updateLocationCollisions(locs: LocationInfo[], units: DistanceUnits, minMaxCoOrdinates: any = null) {
        this.locationData = locs;
        this.distanceUnits = units;
        locs?.map(l => {
            l.Collisions = [];
            if (l.HasBoundary) {
                this.updateLocationCollisionStatusText(l, LocationCollisionStatus.NoOverlap);
            }
        });
        if (this.GeometryHelper) {
            this.locationsUpdated = true;
        }
        // TO get the locations with in the given CoOrdinates range
        const locations = locs?.filter(k => !(minMaxCoOrdinates && minMaxCoOrdinates.min && minMaxCoOrdinates.max) ||
            ((k.Latitude >= minMaxCoOrdinates.min.lat && k.Longitude >= minMaxCoOrdinates.min.lng) &&
                (k.Latitude <= minMaxCoOrdinates.max.lat && k.Longitude <= minMaxCoOrdinates.max.lng)));
        let allLocations: LocationInfo[] = [];
        allLocations = locs?.filter(l => l.HasBoundary).map(l => {
            {
                if (this.GeometryHelper) {
                    locations?.forEach(ng => {
                        if (ng.LocationId !== l.LocationId) {
                            // Validate if location ng or its boundary collides
                            const boundary = this.fetchLocationBoundary(l, units);

                            // Validate if location ng is in location l's fence
                            const ngInLFence = this.GeometryHelper.IsInBox({ latitude: ng.Latitude, longitude: ng.Longitude }, boundary);

                            // Validate if location ng's fence and location l's fence collide
                            let fenceCollides: boolean = false;
                            if (ng.HasBoundary) {
                                const ngBoundary = this.fetchLocationBoundary(ng, units);
                                fenceCollides = this.GeometryHelper.IsOverlapping(boundary, ngBoundary);
                            }

                            if (ngInLFence || fenceCollides) {
                                ng.InOthersFence = ngInLFence;
                                ng.FenceCollided = fenceCollides;
                                if (fenceCollides) {
                                    this.updateLocationCollisionStatusText(ng, LocationCollisionStatus.FenceCollided);
                                } else if (ngInLFence && +ng.GeoFenceCollision != +LocationCollisionStatus.FenceCollided) {
                                    this.updateLocationCollisionStatusText(ng, LocationCollisionStatus.InOthersFence);
                                }
                                if (!ng.HasBoundary &&
                                    ngInLFence) {
                                    ng.Collisions.push({ LocationId: l.LocationId, Location: l, InOthersFence: false, FenceCollided: true });
                                }
                                l.Collisions.push({ LocationId: ng.LocationId, Location: ng, InOthersFence: ngInLFence, FenceCollided: fenceCollides });
                            }
                        }
                    });
                }
            }

            return l;
        });
        allLocations = [...allLocations, ...locations?.filter(l => !l.HasBoundary)];
        return _.sortBy(allLocations,['LocationName']);
    }

    fetchLocationBoundary(ng: LocationInfo, units: DistanceUnits) {
        return ng.Boundary?.length > 0 ? ng.Boundary.map(bl => {
            return { latitude: bl.Latitude, longitude: bl.Longitude };
        }) :
            this.GeometryHelper.GetCircleCoordinates({ latitude: ng.Latitude, longitude: ng.Longitude }, ng.RadiusKm, units);
    }

    fetchDefaultFence(cords: ILatLong, radius: number = 1, units: DistanceUnits = DistanceUnits.Kilometers): ILatLong[] {
        return this.GeometryHelper.GetCircleCoordinates(cords, radius, units);
    }

    fetchCollisions(boundary: ILatLong[], locs: LocationInfo[], units: DistanceUnits): LocationCollisionInfo[] {
        const collisionsInfo: LocationCollisionInfo[] = [];
        locs?.forEach(ng => {

            // Validate if location ng is in location l's fence
            const ngInLFence = this.GeometryHelper.IsInBox({ latitude: ng.Latitude, longitude: ng.Longitude }, boundary);

            // Validate if location ng's fence and location l's fence collide
            let fenceCollides: boolean = false;
            if (ng.HasBoundary) {
                const ngBoundary = this.fetchLocationBoundary(ng, units);
                fenceCollides = this.GeometryHelper.IsOverlapping(boundary, ngBoundary);
            }
            if (ngInLFence || fenceCollides) {
                collisionsInfo.push({ LocationId: ng.LocationId, Location: ng, InOthersFence: ngInLFence, FenceCollided: fenceCollides });
            }
        });

        return collisionsInfo;
    }

    private updateLocationCollisionStatusText(location: LocationInfo, status: LocationCollisionStatus) {
        location.GeoFenceCollision = status;
        switch (status) {
            case LocationCollisionStatus.NoOverlap:
                location.BoundaryFilterText = label_yes;
                break;
            case LocationCollisionStatus.InOthersFence:
                location.BoundaryFilterText = label_geo_fence_overlapped;
                break;
            case LocationCollisionStatus.FenceCollided:
                location.BoundaryFilterText = label_geo_fence_geo_overlapped;
                break;
            default:
                location.BoundaryFilterText = '';
                break;
        }
    }
}