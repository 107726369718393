<div class="row filter-component-custom" *ngIf="selectedList.length > 0">
    <div class="col-md-12">
        <div class="row ml-1 mr-1">
            <div class="filter-header-style" i18n="@@origins">Origins</div>
            <div class="filtered-item" *ngFor="let listItem of selectedList">
                <span class="filter-content-style">{{listItem.text}}</span>
                <button class="filter-close-button" (click)="clearSelectedFilter(listItem.value)">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>
    </div>
</div>