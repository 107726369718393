import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-ie-user-message',
  templateUrl: './ie-user-message.component.html',
  styleUrls: ['./ie-user-message.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class IEUserMessageComponent implements OnInit {

  constructor(public bsIEUserMessageModalRef: BsModalRef) { }

  showCloseButton: true
  headerTitle: string;
  ngOnInit(): void {
  }

  onCloseClick() {
    this.bsIEUserMessageModalRef?.hide();
  }
}
