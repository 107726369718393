import { Component, Inject, OnInit, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';

import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {skip, map, debounceTime, throttleTime, take, filter, tap, switchMap, takeUntil} from 'rxjs/operators';

import { environment } from 'environments/environment';

import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { ICustomerContextState } from 'app-modules/core/store/models/customer-context-state.interface';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { LocalePersistence } from 'app-modules/core/utils/locale-persistence';
import {EmrUtilService, Customer, BusinessRuleInfo, BusinessRuleType} from 'emr-ng-shared';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';
import packageInfo from 'app-modules/../../../package.json';
import { APP_IN_CHINA } from 'src/regionFinder';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UCreateShipmentComponent } from 'app-modules/un-authentication/components/u-create-shipment/u-create-shipment.component';
import { PakSenseAuthService } from 'app-modules/core/store/paksense-auth/ps-auth.service';
import { PAKSENSE_UI_REDIRECT_URL, OVERSIGHT_CARGO_SERVICES_URL } from 'app-modules/core/consts/values';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { TimeZoneService } from 'app-modules/core/store/time-zone/time-zone.service';
import {BusinessRulesService} from 'app-modules/core/store/business-rules/business-rules.service';
import {IListInfo} from 'app-modules/core/store/models/list-info-state.interface';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    authState: IAuthState;
    authStateSub: Subscription;
    defaultRoute = '/';
    custIsSelectedSub: Subscription;
    custContext$: Observable<ICustomerContextState>;
    unAuthCustomer$: Observable<Customer>;
    public trackerID = '';
    public previousSearchText = '875078476978';
    private liveAgentId = '573f4000000PmQ3';
    private liveChat: any;
    public isCollapsed = true;
    public locale: string = null;
    public currentDate;
    public env = environment;
    public version: string = packageInfo.version + '.' + this.env.buildNumber;
    private v1RouteURL: string;
    public hideSideMenu = true;
    public hideUSettings = true;
    private subMenuSubscription: Subscription;
    private uSettingsMenuSub: Subscription;
    isPakSenseAuth$ = this.pakAuthSvc.authenticated$;
    pakSenseURL = `${environment.psUI_URL}${PAKSENSE_UI_REDIRECT_URL}`;
    pakAuthToken$ = this.pakAuthSvc.authToken$;
    userName = '';
    private routerSubscription: Subscription;
    canCreateUnAuthShipment = false;
    unAuthCreateShipmentAccess$ = this.unAuthSvc.unAuthCreateShipmentAccess$;
    oversightcargoURL = OVERSIGHT_CARGO_SERVICES_URL;
    headerHeight: number = 40;
    selectedCustomer: string;
    screenWidth: number = 0;
    //When window resize event
    resizeEvent$: Subject<any> = new Subject<any>();
    resizeSubscription: Subscription;
    hasAdminAccess = false;
    isLocusAdmin = false;
    hasDashboardAccess = false;
    @ViewChild('dropdownUSettings') dropdown: BsDropdownDirective;
    @ViewChild('header') Header: ElementRef;
    isCustomerSelected: boolean = false;
    showDashboard: boolean = false;
    customerTimeZone: string;
    preferenceSubscription: Subscription;
    hasRecycleDashboardAccess = false;
    private unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        private authSvc: AuthService,
        private pakAuthSvc: PakSenseAuthService,
        public custSvc: CustomersService,
        public router: Router,
        private showPopups: OversightPopupService,
        private utilSvc: EmrUtilService,
        public unAuthSvc: UnAuthService,
        @Inject(APP_IN_CHINA) private isAppInChina: boolean,
        private modalService: BsModalService,
        private prefSvc: PreferenceService,
        private timeZoneSvc: TimeZoneService,
        private businessRulesService: BusinessRulesService,
    ) {
        this.routerSubscription = router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this.hideSideMenu = true;
                this.authSvc.updateMenuStatus(this.hideSideMenu);
            }
        });
    }

    ngOnInit() {
        this.authStateSub = this.authSvc.authState$.pipe(skip(1)).subscribe(n => {
            this.authState = n;
            this.defaultRoute = this.authSvc.getDefaultRoute();
            this.hasAdminAccess = n ? n.isAdminUser : false;
            this.isLocusAdmin = n ? n.isEmersonAdmin : false;
            this.hasDashboardAccess = n ? n.isDashboardUser : false;
        });
        this.custIsSelectedSub = this.custSvc.isSelected$.subscribe(k => {
            this.updateScreenWidthAndHeight(this.headerHeight < 45, 200);
            this.isCustomerSelected = k;
        });
        this.custContext$ = this.custSvc.selectedContext$.pipe(map(k => {
            if (this.selectedCustomer)
                this.updateScreenWidthAndHeight(this.headerHeight < 45, 200);
            this.selectedCustomer = k?.customer?.Description;
            return k;
        }));
        this.unAuthCustomer$ = this.unAuthSvc.unAuthCustomer$.pipe(map(k => {
            this.updateScreenWidthAndHeight(this.headerHeight < 45, 200);
            return k;
        }));

        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        this.locale = localeResolver.resolveLocale();
        this.subMenuSubscription = this.authSvc.hideSideMenu.subscribe(k => this.hideSideMenu = k);
        this.uSettingsMenuSub = this.unAuthSvc.hideUSettings.subscribe(k => {
            if (k) { this.dropdown.hide(); }
            else {
                setTimeout(() => {
                    this.dropdown.isOpen = true;
                    this.dropdown.show();
                }, 20);
            }
        });
        this.resizeSubscription = this.resizeEvent$.pipe(throttleTime(500), debounceTime(490)).subscribe(k => {
            this.updateScreenWidthAndHeight();
        });

        this.businessRulesService.businessRulesList$.pipe(takeUntil(this.unsubscribeAll)).subscribe((businessRuleInfo: IListInfo<BusinessRuleInfo>) => {
          if (businessRuleInfo.list && businessRuleInfo.list.length > 0) {
            this.hasRecycleDashboardAccess = businessRuleInfo.list.find(br => br.BusinessRuleTypeCode === BusinessRuleType.HasRecycleDashboardAccess)?.Enabled;
            this.showDashboard = environment.showDashboardLink && businessRuleInfo.list.find(br => br.BusinessRuleTypeCode === BusinessRuleType.DisplayScorecard)?.Enabled;
          }
        });
    }

    // Registering Window resize event
    @HostListener('window:resize', ['$event'])

    onResize(event) {
        this.headerHeight = 40;
        this.resizeEvent$.next(event);
    }

    updateScreenWidthAndHeight(bool: boolean = true, timeOut: number = 0) {
        setTimeout(() => {
            if (bool) {
                this.headerHeight = this.Header.nativeElement.offsetHeight;
            }
            this.screenWidth = this.Header.nativeElement.offsetWidth;
        }, timeOut)
    }
    ngOnDestroy() {
        this.authStateSub.unsubscribe();
        if (this.subMenuSubscription) {
            this.subMenuSubscription.unsubscribe();
        }

        if (this.uSettingsMenuSub) {
            this.uSettingsMenuSub.unsubscribe();
        }

        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
        this.resizeSubscription?.unsubscribe();
        this.custIsSelectedSub?.unsubscribe();
        this.preferenceSubscription?.unsubscribe();
    }

    ngAfterViewInit(): void {

        this.preferenceSubscription = combineLatest(this.custSvc.isSelected$,
            this.prefSvc.isPreferenceLoadRequired$).pipe(
                filter(([isSelected, isLoading]) => isSelected && !isLoading),
                switchMap(() => this.prefSvc.preference$.pipe(take(1)))).pipe(map(preferences => {
                    this.customerTimeZone = preferences.timeZoneId;
                })).subscribe();
    }




    OnSelectChange(trackerID) {
        this.trackerID = this.previousSearchText = trackerID;
    }

    onSigninClick() {
        if (!this.authState || !this.authState.authenticated) {
            this.router.navigate(['signin']);
        }
    }

    onSignoutClick() {
        // this.authSvc.signOut();
        this.router.navigate(['signout']);
    }

    onCreateShipmentClick() {
        this.showPopups.showCreateShipmentPopup();
    }

    onLocaleClick() {
        const localePersistence = new LocalePersistence(environment.localesPersistKey);
        localePersistence.clearLocale();

        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        const locale = localeResolver.resolveLocale();
        this.router.navigate(['/locale']);
    }

    getV1LinkURL(websiteId: string) {
        this.v1RouteURL = '';
        this.currentDate = this.utilSvc.DateFormatLocaleChange((new Date()));
        const refParameters = '?' + 'v2redirect=false&' + 'expire=' + this.currentDate;
        if (websiteId) {
            this.v1RouteURL = '/Alerts.aspx/' + websiteId + refParameters;
        } else {
            this.v1RouteURL = '/LaunchPad/SiteList.aspx' + refParameters;
        }
        window.open(environment.oversightV1BaseUrl + this.v1RouteURL, '_blank');
    }

    sideMenuClick() {
        this.authSvc.updateMenuStatus(!this.hideSideMenu);
    }

    onUnAuthCreateShipmentClick() {
        this.modalService.show(
            UCreateShipmentComponent,
            {
                initialState: {},
                class: 'max-width-1000 modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }

    onLogoClick() {
        if (this.authState?.authenticated) {
            this.router.navigate([]).then(result => { window.open(`/signin/trackerlookup`, '_blank'); });
        } else {
            this.router.navigate(['signin', 'trackerlookup']);
        }
    }
    OpenChatWindow() {
        window.open('https://copeland.custhelp.com/app/chat/chat_launch',
            'newwindow',
            'width=500,height=600');
        return false;
    }
}
