<div class="position-class container-fluid" [ngClass]="{'filter-pinned': isFilterPinned }"
  [ngStyle]="{ 'height': shipmentRouteHeight }">
  <div class="row">
    <div class="iconsPlaceHolder pr-2 pt-1">
      <ul class="nav justify-content-end">
        <li class="nav-item ml-2" *ngIf="showRestoreMaximized">
          <emr-button identifier="ShowRouteMaximizeButton"
            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
            buttonIcon="fa {{ Maximized ? 'fa-window-restore' : 'fa-window-maximize' }}"
            title="{{Maximized ? maximize : restore}}" (onClick)="toggleZoom()">
          </emr-button>
        </li>
        <li class="nav-item ml-2">
          <emr-button identifier="ShowRouteCloseButton" buttonIcon="fa fa-times"
            cssClass="close-btn btn-outline btn-sm btn-style label-text text-12 btn-background" buttonText="Close"
            i18n-buttonText="@@close" (onClick)="closeShowRoute()">
          </emr-button>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-12 border-left">
      <app-shipment-route [TraxxList]="TraxxList" [isFromShowRoute]="!isItFromRouteDeviation"
        [isItFromRouteDeviation]="isItFromRouteDeviation" [ParentHeight]="shipmentRouteHeight" [Shipment]="Shipment"
        (dateRangeChange)="onDateRangeChange($event)" [shipmentList]="shipmentList">
      </app-shipment-route>
    </div>
  </div>
</div>