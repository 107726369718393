import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, filter, tap, switchMap, catchError, finalize, share , startWith } from 'rxjs/operators';

import { muteFirst, TripEndOptions } from 'emr-ng-shared';

import { TripEndOptionsStateService } from 'app-modules/core/store/create-shipment/trip-end-options/trip-end-options-state.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IListInfo, emptyList } from 'app-modules/core/store/models/list-info-state.interface';

@Injectable()
export class TripEndOptionsService {
    constructor(
        private TripEndOptionsStateSvc: TripEndOptionsStateService,
        private oversightSvc: OversightApiService
    ) { }

    public isLoadRequired$ = this.TripEndOptionsStateSvc.isLoadRequired$;

    public isLoading$ = this.TripEndOptionsStateSvc.isLoading$;

    public TripEndOptionsList$: Observable<IListInfo<TripEndOptions>> = muteFirst(
        this.getTripEndOptionsListLoader().pipe(startWith(null)),
        this.TripEndOptionsStateSvc.TripEndOptionsList$
    );

    private getTripEndOptionsListLoader(): Observable<IListInfo<TripEndOptions>> {
        return this.TripEndOptionsStateSvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            tap(() => this.TripEndOptionsStateSvc.loadTripEndOptions()),
            switchMap(() => this.getTripEndOptionsList()),
            tap(
                n => this.TripEndOptionsStateSvc.loadTripEndOptionsSuccess(n),
                e => this.TripEndOptionsStateSvc.loadTripEndOptionsError('')
            ),
            finalize(() => this.TripEndOptionsStateSvc.cancelLoadTripEndOptions()),
            catchError(() => {
                this.TripEndOptionsStateSvc.cancelLoadTripEndOptions();
                return of(emptyList());
            }),
            share()
        );
    }

    private getTripEndOptionsList(): Observable<IListInfo<TripEndOptions>> {
        return this.oversightSvc.GetTripEndOptions().pipe(
            map(n => {
                return {
                    list: n.TripEndOptions.sort((a: TripEndOptions, b: TripEndOptions) => {
                        if (a.Description.toLocaleLowerCase() < b.Description.toLocaleLowerCase()) {
                            return -1;
                        }
                    }),
                    itemCount: n.TripEndOptions.length,
                    isPaged: false
                };
            })
        );
    }
}