import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { of } from 'rxjs';
import { tap, map, switchMap, filter, finalize, share, catchError, delay, take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { UpdateShipmentRequest } from 'emr-ng-shared';

import { environment } from 'environments/environment';
import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';

import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { Shipment } from 'app-modules/core/models/shipment.model';
import {
    EditShipmentResponseComponent,
    EditShipmentResponseOkEventArgs
} from 'app-modules/create-shipment/components/edit-shipment-response/edit-shipment-response.component';
import { SensorRangeInfo } from 'emr-ng-shared';
import { EditShipmentService } from 'app-modules/core/services/edit-shipment.service';

@Component({
    selector: 'app-edit-shipment',
    templateUrl: './edit-shipment.component.html',
    styleUrls: ['./edit-shipment.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class EditShipmentComponent implements OnInit, OnDestroy {
    constructor(
        private modalSvc: BsModalService,
        public bsMainModalRef: BsModalRef,
        private shipSvc: ShipmentService,
        private editSvc: EditShipmentService,
    ) { }

    @ViewChild(NgForm) shipmentForm: NgForm;

    bsModalRef: BsModalRef;

    public locale: string = null;

    shipment: Shipment;
    sensorRangeList$: Observable<SensorRangeInfo[]>;
    sensorRangeSubscription: Subscription;
    updateShipmentSubscription: Subscription;

    ngOnInit() {
        // TODO: Set the retailer Id
        this.editSvc.selectLinkedCustomer(null);
        this.sensorRangeList$ = this.editSvc.sensorRangeList$.pipe(map(n => n.list));
        this.sensorRangeSubscription = this.editSvc.sensorRangeList$.subscribe(n => n
            // console.log(n)
        );

        // locale Settings
        const localeResolver = new LocaleResolver(environment.localesPersistKey);
        this.locale = localeResolver.resolveLocale();
    }

    ngOnDestroy() {
        if (this.sensorRangeSubscription) {
            this.sensorRangeSubscription.unsubscribe();
        }
        if (this.updateShipmentSubscription) {
            this.updateShipmentSubscription.unsubscribe();
        }
    }

    onUpdateShipment() {
        this.updateShipmentSubscription = this.shipSvc.updateShipment(this.getUpdateShipmentRequest()).pipe(take(1)).subscribe(
            n => this.openUpdateResponse(false, null, true),
            e => this.openUpdateResponse(true, e, false)
        );
    }

    openUpdateResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean) {
        const bsModalRef = this.modalSvc.show(
            EditShipmentResponseComponent,
            {
                initialState: {
                    hasError,
                    errorMessage,
                    closeOnOk,
                    showCloseButton: false
                },
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onUpdateShipmentResponseOk(n));
    }

    onUpdateShipmentResponseOk(args: EditShipmentResponseOkEventArgs) {
        args.modalRef.hide();

        if (!args.hasError) {
            if (args.closeOnOk) {
                this.bsMainModalRef.hide();
            }
        }
    }

    getUpdateShipmentRequest() {
        const updatedShipment = new UpdateShipmentRequest();
        updatedShipment.TripId = this.shipment.tripId;
        updatedShipment.TripName = this.shipment.tripName;
        updatedShipment.CustomerTrackerId = this.shipment.customerTrackerId;
        updatedShipment.SensorRangeId = this.shipment.SensorTempRangeId;
        return updatedShipment;
    }

    onUpdateShipmentCancel() {
        this.bsMainModalRef.hide();
    }

}






