import { ISensorRangeState } from 'app-modules/core/store/models/sensor-range-state.interface';
import { emptyList } from 'app-modules/core/store/models/list-info-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as rangeActions from 'app-modules/core/store/sensor-range/sensor-range.actions';
import * as custActions from 'app-modules/core/store/actions/customer.actions';

type Actions =
    authActions.SignOut |
    rangeActions.LoadSensorRanges |
    rangeActions.LoadSensorRangesSuccess |
    rangeActions.LoadSensorRangesError |
    rangeActions.CreateSensorRangeSuccess |
    rangeActions.CreateSensorRangeError |
    custActions.SelectCustomerSuccess;

export function SensorRangeReducer(state: ISensorRangeState = initialState.sensorRange, action: Actions): ISensorRangeState {
    switch (action.type) {
        case actionIndex.CREATE_SENSOR_RANGE_SUCCESS:
            const newSensorRangeList = state.sensorRangeList.list.splice(0);
            const listInfo = emptyList();
            newSensorRangeList.push(action.SensorRange);
            listInfo.list = newSensorRangeList;
            listInfo.itemCount = newSensorRangeList.length;
            listInfo.isPaged = false;
            return {
                ...state,
                sensorRangeList: listInfo,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };
            
        case actionIndex.SIGN_OUT:
            return {
                ...state,
                sensorRangeList: emptyList(),
                isLoadRequired: false,
                isLoading: false
            };
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                sensorRangeList: emptyList(),
                isLoadRequired: true,
                isLoading: false
            };
            
        case actionIndex.LOAD_SENSOR_RANGES:
            return {
                ...state,
                sensorRangeList: emptyList(),
                isLoadRequired: true,
                isLoading: true
            };

        case actionIndex.LOAD_SENSOR_RANGES_SUCCESS:
            return {
                ...state,
                sensorRangeList: action.sensorRangeList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.CREATE_SENSOR_RANGE_ERROR:
        case actionIndex.LOAD_SENSOR_RANGES_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
