<div class="row">
  <div class="col-md-12">
    <div class="card alertbox roboto-regular-font font-12">
      <div class="row card-header p-1">
        <div class="col-md-12 left-align">
          <div class="font-14 lh-24">
            <b>
              <span>{{ trackerId }}</span>
            </b>
            <button translate="no" type="button" class="notranslate infobox-close-img float-right close btn-modal" aria-label="Close">
              <span aria-hidden="true" class="infobox-close-img">
                <b class="infobox-close-img">&times;</b>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body p-1 pl-2 text-left">
        <div class="row ">
          <div class="col-md-12 pt-1">
            <b>
              <span i18n="@@label_serial_number">Serial Number</span>:&ensp;
            </b>
            <span>{{ trackerId }}</span>
          </div>
          <!-- <div class="col-md-12">
            <span> {{ markerInfo?.TrackerInfo?.Description}}</span>
          </div> -->
          <div *ngIf="tripId" class="col-md-12 pt-1">
            <b>
              <span>Trip #</span>:&ensp;
            </b>
            <span>{{ tripId }}</span>
          </div>
          <div class="col-md-12">
            <b>
              <span i18n="@@label_date_time">Date/Time</span>:&ensp;
            </b>
            <span> {{ markerInfo?.TrackerInfo?.LastReportedTimestampFormatted}}</span>
          </div>
          <div class="col-md-12">
            <span> ({{ markerInfo?.Latitude }}, {{markerInfo?.Longitude}}) </span>
          </div>
          <div class="col-md-12">
            <b>
              <span i18n="@@label_temperature">Temperature</span>:&ensp;</b>
            <span> {{ markerInfo?.TrackerInfo?.TemperatureString}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>