import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { SensorRangeInfo } from 'emr-ng-shared';

import { IShipmentFilterSensorRange } from 'app-modules/core/store/models/shipment-filter-sensor-range.interface';
import { SensorRangeService } from 'app-modules/core/store/sensor-range/sensor-range.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';

declare type selectableSensorRange = SensorRangeInfo & { checked: boolean };

@Component({
    selector: 'app-shipment-filter-temp',
    templateUrl: './shipment-filter-temp.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterTempComponent implements OnInit, OnDestroy {
    // bindable filter
    searchSensorRangeText: '';
    filterVal: number[];
    @Input() get filter() { return this.filterVal; }
    @Output() filterChange = new EventEmitter();
    set filter(val) {
        this.filterVal = val;
        this.filterChange.emit(this.filterVal);
    }

    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    rangeList: selectableSensorRange[];

    private rangeListSubscription: Subscription;
    private rangeFilterSubscription: Subscription;

    constructor(
        public rangeSvc: SensorRangeService,
        private filterSvc: ShipmentFilterService
    ) { }

    public ngOnInit() {
        this.rangeListSubscription = this.rangeSvc.sensorRangeList$.subscribe(n => this.onSensorRangeListChange(n.list));
        this.rangeFilterSubscription = this.filterSvc.sensorRangeFilter$.subscribe(n => this.onSensorRangeFilterChange(n));
    }

    public ngOnDestroy() {
        this.rangeListSubscription.unsubscribe();
        this.rangeFilterSubscription.unsubscribe();
    }

    onSensorRangeListChange(rangeList: SensorRangeInfo[]) {
        this.rangeList = [];
        if (!rangeList) { return; }
        rangeList.forEach(item => {
            this.rangeList.push({ ...item, checked: this.filter && this.filter.indexOf(item.SensorRangeId) >= 0 });
        });
    }

    onSensorRangeFilterChange(filter: number[]) {
        this.isDirty = false;
        this.filter = filter;
        if (this.filter) {
            this.rangeList.forEach(item => item.checked = this.filter.indexOf(item.SensorRangeId) >= 0);
        }
    }

    // onSelectionChanged(range: selectableSensorRange) {
    //     if (range.selected) {
    //         this.filter.selectedRanges.set(range.SensorRangeId, range.selected);
    //     } else {
    //         this.filter.selectedRanges.delete(range.SensorRangeId);
    //     }
    //     this.isDirty = true;
    // }
}


