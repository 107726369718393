import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class ImportLocationList implements ServerRequestAction {
    readonly type = actionIndex.IMPORT_LOCATIONS;
}

export class ImportLocationListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.IMPORT_LOCATIONS_SUCCESS;
    readonly callingAction = actionIndex.IMPORT_LOCATIONS;
    constructor(public data: string) { }
}

export class ImportLocationListError implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.IMPORT_LOCATIONS;
    readonly type = actionIndex.IMPORT_LOCATIONS_ERROR;
    constructor(public message: string) { }
}

export class CancelImportLocationList implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.IMPORT_LOCATIONS;
    readonly type = actionIndex.CANCEL_IMPORT_LOCATIONS;
}

export class SaveImportLocationList implements ServerRequestAction {
    readonly type = actionIndex.SAVE_IMPORT_LOCATIONS;
}

export class SaveImportLocationListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SAVE_IMPORT_LOCATIONS_SUCCESS;
    readonly callingAction = actionIndex.SAVE_IMPORT_LOCATIONS;
    constructor(public data: string) { }
}

export class SaveImportLocationListError implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.SAVE_IMPORT_LOCATIONS;
    readonly type = actionIndex.SAVE_IMPORT_LOCATIONS_ERROR;
    constructor(public message: string) { }
}

export class CancelSaveImportLocationList implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.SAVE_IMPORT_LOCATIONS;
    readonly type = actionIndex.CANCEL_SAVE_IMPORT_LOCATIONS;
}
