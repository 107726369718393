<div class="c-shipment table-data-container mb-1">
    <div class="col-md-12">
        <emr-custom-table [columnDefs]="columnDefs" [data]="shipmentList$|async" (rowClick)="onRowClick($event)"
            [selectableRow]="true" [showColumnSelection]="false" [divClass]="dataDivCssClass"
            [tBodyId]="'full-grid-view-reset'" [divHeight]="dataDivHeight" [scrollToTop]="scrollToTop"
            (filterApply)="onFilterApply($event)" [applyFilter]="false" [sort]="sort" [calculateColumnCount]="false"
            (columnDefsChange)="onColumnChange($event)" [filterheight]="tablefilterHeight">
        </emr-custom-table>
        <div class="d-none">
            <ng-template #detailsButton let-rowData>
                <div
                    *ngIf="shpSvc.CheckDatesInPast90DaysForDetails(rowData.LastReportedTimestamp,rowData?.ActualEndTime,rowData?.ActualStartTime,rowData?.tripStateCode); else over90DaysActions">
                    <emr-button identifier="showRoute"
                        cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                        buttonText="Show Route" i18n-buttonText="@@show_route"
                        (onClick)="onShipmentRouteClick(rowData)">
                    </emr-button>
                    <div class="btn-butongroup btn-group" dropdown>
                        <emr-button identifier="showDetails"
                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background show-details-btn"
                            buttonText="Details" i18n-buttonText="@@details"
                            (onClick)="onShipmentDetailsClick(rowData)">
                        </emr-button>
                        <button id="button-split" type="button" dropdownToggle
                            class="btn btn-outline btn-sm btn-style dropdown-toggle dropdown-toggle-split"
                            style="position: static;" aria-controls="dropdown-split">
                            <span class="caret"></span>
                            <span class="sr-only">Split button!</span>
                        </button>
                        <ul id="dropdown-split" *dropdownMenu class="dropdown-menu" role="menu"
                            aria-labelledby="button-split">
                            <li role="menuitem"><a class="dropdown-item" [routerLink]="[]"
                                    (click)="onDistanceToClick(rowData.trackerId, rowData.customerTrackerId, rowData.tripId);"
                                    i18n="@@distance_to">Distance To</a>
                            </li>
                            <li *ngIf="rowData.alertSuppressed&&rowData.CanResumeAlerts" role="menuitem"><a
                                    class="dropdown-item" [routerLink]="[]"
                                    (click)="onSuspendResumeAlertsClick(rowData);" i18n="@@link_resume_alerts">Resume
                                    Alerts</a>
                            </li>
                            <li *ngIf="!rowData.alertSuppressed&&rowData.CanSuspendAlerts" role="menuitem"><a
                                    class="dropdown-item" [routerLink]="[]"
                                    (click)="onSuspendResumeAlertsClick(rowData);" i18n="@@link_supress_alerts">Suspend
                                    Alerts</a>
                            </li>
                            <li *ngIf="!rowData.tripId && allowSetSensors" role="menuitem">
                                <emr-button identifier="btnSetSensorsLink"
                                    cssClass="btn btn-link dropdown-item color-black" buttonText="Sensor Ranges"
                                    i18n-buttonText="@@label_sensor_ranges"
                                    (onClick)="onSetTrackerSensorsClick(rowData)">
                                </emr-button>
                            </li>
                            <li *ngIf="!rowData.tripId && userCanCreateShipment" role="menuitem">
                                <emr-button identifier="btnCreateShipmentLink"
                                    cssClass="btn btn-link dropdown-item color-black" buttonText="Create Shipment"
                                    i18n-buttonText="@@create_shipment" (onClick)="onCreateShipmentClick(rowData)">
                                </emr-button>
                            </li>
                            <li *ngIf="rowData.CanDeleteTrip">
                                <emr-button identifier="deleteShipment_1"
                                    cssClass="btn btn-link dropdown-item color-black" currentToolTip="Delete"
                                    buttonText="Delete Shipment" i18n-buttonText="@@label_delete_shipment"
                                    (onClick)="onDeleteShipmentClick(rowData)">
                                </emr-button>
                            </li>
                            <li role="menuitem">
                                <a id="closeShipment_1" class="dropdown-item" [ngClass]="{
                                        'btndisabled':!rowData.canCloseShipment
                                        }" [routerLink]="[]" (click)="onCloseShipmentClick(rowData);"
                                    i18n="@@close">Close</a>
                            </li>
                            <li>
                                <emr-button identifier="addNote_1" cssClass="btn btn-link dropdown-item color-black"
                                    currentToolTip="Add Note" buttonText="Add Note" i18n-buttonText="@@label_add_note"
                                    (onClick)="onEditNote(rowData)">
                                </emr-button>
                            </li>
                            <li *ngIf="rowData.CanShutdownTracker">
                                <emr-button identifier="shutdownTracker"
                                    cssClass="btn btn-link dropdown-item color-black" currentToolTip="ShutDown Tracker"
                                    buttonText="Shutdown Tracker" i18n-buttonText="@@label_shut_down_tracker"
                                    (onClick)="onShutDownTracker(rowData)">
                                </emr-button>
                            </li>
                            <li *ngIf="!this.isUnAuthenticated">
                                <emr-button identifier="addEmailAddressses"
                                    cssClass="btn btn-link dropdown-item color-black" currentToolTip="Share Location"
                                    buttonText="Share Location" i18n-buttonText="@@label_share_location"
                                    (onClick)="onShareLocationClick(rowData)">
                                </emr-button>
                            </li>
                        </ul>
                    </div>
                    <div class="btn-butongroup btn-group" *ngIf="this.isUnAuthenticated">
                        <emr-button identifier="addEmailAddressses"
                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background show-details-btn"
                            buttonText="Share Location" i18n-buttonText="@@label_share_location"
                            (onClick)="onShareLocationClick(rowData)">
                        </emr-button>
                    </div>
                </div>
                <ng-template #over90DaysActions>
                    <div *ngIf="rowData?.LastReportedTimestamp|| !this.isUnAuthenticated">
                        <emr-button identifier="deleteShipment_1" *ngIf="rowData.CanDeleteTrip"
                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                            currentToolTip="Delete" buttonText="Delete Shipment"
                            i18n-buttonText="@@label_delete_shipment" (onClick)="onDeleteShipmentClick(rowData)">
                        </emr-button>
                        <emr-button identifier="closeShipment_1" *ngIf="rowData.canCloseShipment"
                            cssClass="btn-outline btn-sm btn-style label-text text-12 btn-background"
                            currentToolTip="Close" buttonText="Close" i18n-buttonText="@@close"
                            (onClick)="onCloseShipmentClick(rowData)">
                        </emr-button>
                    </div>
                </ng-template>
            </ng-template>
            <ng-template #viewLocation let-rowData>
                <div *ngIf="!rowData?.AddressGeoLoading; else addressLoader">
                    <p *ngIf="rowData.AddressGeoTimestampFormatted != '' && !rowData.AddressOlderThanThreshold; else viewCurrentLocation"
                        class="table-row-data">
                        <span>{{rowData.AddressGeo}}</span>
                    </p>
                    <ng-template #viewCurrentLocation>
                        <p class="table-row-data"
                            *ngIf="CheckDatesInPast90Days(rowData.LastReportedTimestamp,rowData.ActualEndTime,rowData?.tripStateCode)">
                            <a class="text-center shipment-custom-link" i18n="@@view_current_location"
                                (click)="onViewLocationClick(rowData)">
                                View Current Location
                            </a>
                        </p>
                    </ng-template>
                </div>
                <ng-template #addressLoader>
                    <div class="col-md-12">
                        <app-local-loading-indicator width="50%" height="28px">
                        </app-local-loading-indicator>
                    </div>
                </ng-template>
            </ng-template>

            <ng-template #updateETA let-rowData>
                <div *ngIf="!rowData?.TripETALoading; else eTALoader">
                    <p class="table-row-data"
                        *ngIf="!(rowData.TripETADistanceString && !rowData.TripETAOlderThanThreshold) && rowData.destination  && rowData.TripEndLocLat && rowData.TripEndLocLon && (rowData.tripStateCode === tripState.Pending || rowData.tripStateCode === tripState.InProgress); else eTAString">
                        <a class="text-center shipment-custom-link" i18n="@@update_estimate"
                            (click)="onUpdateETAClick(rowData)">
                            Update Estimates
                        </a>
                    </p>
                    <ng-template #eTAString>
                        <p class="table-row-data">
                            <span>{{rowData.destination && rowData.TripEndLocLat && rowData.TripEndLocLon &&
                                rowData.TripETADistanceString ? rowData.TripETADistanceString : 'n/a'}}</span>
                        </p>

                    </ng-template>
                </div>
                <ng-template #eTALoader>
                    <div class="col-md-12">
                        <app-local-loading-indicator width="50%" height="28px">
                        </app-local-loading-indicator>
                    </div>
                </ng-template>
            </ng-template>

            <ng-template #destETA let-rowData>
                <div class="table-row-data">
                    <span>{{ rowData.destination && rowData.TripEndLocLat && rowData.TripEndLocLon &&
                        rowData.TripETADistanceString && rowData.TripETADistanceString != '' &&
                        !rowData.TripETAOlderThanThreshold ? rowData.TripETAFormatted : 'n/a' }}</span>
                </div>
            </ng-template>
            <ng-template #tripStatus let-rowData>
                <div class="table-row-data" [ngClass]="{
                        'status-completed': rowData.tripStateCode === tripState.Completed,
                        'status-progress': rowData.tripStateCode === tripState.InProgress
                    }">
                    <span>{{ rowData.tripId ? rowData.tripStatus : 'n/a' }}</span>
                </div>
            </ng-template>
            <ng-template #shipmentSerial let-rowData>
                <div class="table-row-data pl-0">
                    <span class="tripImageURL">
                        <img src="{{ calcImageURL(rowData.ImageId, rowData.ImageUrlSVG, rowData.ImageUrl) }}" />
                    </span>
                    <span>{{rowData.serialNumber}}</span>
                </div>
            </ng-template>

            <ng-template #notes let-rowData>
                <a class="notes-link" tooltip="{{rowData.notes}}" containerClass="line-break-tooltip"
                    *ngIf="rowData.notes?.length>0;else noData" (click)="onEditNote(rowData)">
                    <span><img src="assets/notes.svg" width="30" height="30"></span>
                </a>
                <ng-template #noData>
                    <span class="">n/a</span>
                </ng-template>
            </ng-template>

            <ng-template #qualityCode let-rowData>
                <span *ngIf="rowData.SmartTagQualityCode"><img src="assets/QualityCode.png" width="25"
                        height="25"></span>
            </ng-template>
        </div>
    </div>
</div>

<ng-template #notesModal>
    <emr-modal id="btnFrmShipmentNotes" *ngIf="bsModalRef" class="scrollable-modal" headerTitle="Notes"
        i18n-headerTitle="@@label_notes" [bsModalRef]=bsModalRef>

        <ng-container modal-body>
            <div class="col-md-12">
                <div class="row my-2">
                    <div class="col-md-12 text-center text-danger">
                        {{errorMessage}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <emr-textarea ngDefaultControl identifier="txtNotes" name="txtNotes" [(ngModel)]="shipmentNote"
                            helpTip="Notes" i18n-helpTip="@@label_notes" controlColumns="12" textAreaRows="10">
                        </emr-textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-center">
                        <p>
                            <emr-button *ngIf="!isHistorical" identifier="trackerNotes" buttonType="submit"
                                buttonText="Submit" i18n-buttonText="@@link_submit"
                                cssClass="btn-outline-default submit-btn" [setDisabled]="disableSubmitButton"
                                buttonIcon="fa fa-check" (onClick)="onSaveNotes()">
                            </emr-button>
                            <!-- <emr-button identifier="resetShipment" buttonType="reset" buttonText="Delete"
                                i18n-buttonText="@@link_delete" cssClass="btn-outline-default reset-btn"
                                buttonIcon="fa fa-repeat">
                            </emr-button> -->
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </emr-modal>
</ng-template>