import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ContactMethods, ContactTypeCodes } from 'app-modules/core/consts/contact-type.model';
import { preferredLanguageDesc } from 'app-modules/core/consts/language-list';
import { label_add_contact, label_edit_contact, label_update_contact } from 'app-modules/core/consts/localization';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { ContactService } from 'app-modules/core/store/contacts/contact.service';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { TimeZoneService } from 'app-modules/core/store/time-zone/time-zone.service';
import { Contact, ContactMethod, ContactType, SetContactRequest, SetContactResponse, TimeZoneInfo } from 'emr-ng-shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-emr-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {

  @ViewChild(NgForm) contactForm: NgForm;
  @Output() saveContact = new EventEmitter<any>();

  contactMethod = ContactMethod
  timeZoneList$: Observable<IListInfo<TimeZoneInfo>>;
  isTimeZoneLoading$: Observable<boolean>;
  contact = new SetContactRequest();
  btnSaveText: string = label_add_contact;
  headerTitle: string = label_add_contact;
  preferredLanguages = preferredLanguageDesc;
  preferredContactMethod = ContactMethods;
  public error: IErrorInfo;
  setContactSub: Subscription;

  constructor(
    private timeZoneSvc: TimeZoneService,
    public bsModalRef: BsModalRef,
    public contactSvc: ContactService,
    ) { }

  ngOnInit() {
    if(this.contact.ContactId){
      this.btnSaveText = label_update_contact;
      this.headerTitle = label_edit_contact
    }
    this.timeZoneList$ = this.timeZoneSvc.timeZoneList$;
    this.isTimeZoneLoading$ = this.timeZoneSvc.isLoading$;
    if(!this.contact.ContactId) {
      this.contact.PreferredContactMethod = ContactMethod.Email;
    }
  }

  private reValidationForm = () => {
    if (this.contactForm) {
      for (const key of Object.keys(this.contactForm.controls)) {
        this.contactForm.controls[key].markAsDirty();
        this.contactForm.controls[key].updateValueAndValidity();
      }
    }
  }

  OnSaveContactClick() {
    this.reValidationForm();
    this.error = null;
    if (this.contactForm.valid) {
      
      this.setContactSub = this.contactSvc.setContact(this.contact).subscribe(
        n => {
          if (n) {
            this.setContactSuccess(n);
          }
        },
        (e: IErrorInfo) => this.setContactFailure(e)
      );
    }
  }

  setContactSuccess(response: SetContactResponse) {
    this.contact.ContactId = response.ContactId;
    this.setContactSub?.unsubscribe();
    this.saveContact.emit(this.contact);
  }

  setContactFailure(error: IErrorInfo) {
    this.error = error;
    this.setContactSub?.unsubscribe();
  }

}
