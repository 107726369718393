import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportRoutingModule } from 'app-modules/reports/report-routing.module';

import { EmrNgControlsModule } from 'emr-ng-shared';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { TripReportComponent } from 'app-modules/reports/components/shipment-report/trip-report.component';
import { CoreModule } from 'app-modules/core/core.module';
import { ScheduledReportsComponent } from './components/scheduled-reports/scheduled-reports.component';
import { ScheduleNewReportComponent } from './components/schedule-new-report/schedule-new-report.component';
import { AlertReportComponent } from './components/alert-report/alert-report.component';
import { FleetReportComponent } from './components/fleet-report/fleet-report/fleet-report.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ReportRoutingModule,
    EmrNgControlsModule,
    BsDropdownModule.forRoot(),
    AccordionModule.forRoot()
  ],
  declarations: [
    TripReportComponent,
    ScheduledReportsComponent,
    ScheduleNewReportComponent,
    AlertReportComponent,
    FleetReportComponent
  ],
  exports: [
    TripReportComponent,
    ScheduledReportsComponent,
    ScheduleNewReportComponent
  ]
})
export class ReportModule { }


