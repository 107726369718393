import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { CustomTableColumnDefs, SensorRangeInfo } from 'emr-ng-shared';
import { IShipmentFilterSensorRange } from 'app-modules/core/store/models/shipment-filter-sensor-range.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { SensorRangeService } from 'app-modules/core/store/sensor-range/sensor-range.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { temperatureRange } from 'app-modules/core/consts/localization';

@Component({
    selector: 'app-shipment-filter-temp-summary',
    templateUrl: 'shipment-filter-temp-summary.component.html',
    styleUrls: ['shipment-filter-temp-summary.component.css']
}) export class ShipmentFilterTempSummaryComponent implements OnInit, OnDestroy {
    rangeList: SensorRangeInfo[] = [];
    filter: number[];
    selectedList: SensorRangeInfo[] = [];

    rangeListSubscription: Subscription;
    rangeFilterSubscription: Subscription;
    @Input() shipmentFilter: IShipmentFilterState;
    @Input() isHugeCustomer: boolean;
    @Output() summaryRemove = new EventEmitter();

    constructor(
        private rangeSvc: SensorRangeService,
        private shipmentFilterService: ShipmentFilterService
    ) { }

    ngOnInit() {
        this.rangeListSubscription =
            // combineLatest(this.rangeSvc.sensorRangeList$,
            //     this.shipmentFilterService.shipmentFilterUpdate$,
            //     (a, n) => { if (n) { this.onRangeListChange(a.list); } }).subscribe();
        this.rangeSvc.sensorRangeList$.subscribe(n => this.onRangeListChange(n.list));
        this.rangeFilterSubscription = this.shipmentFilterService.sensorRangeFilter$.subscribe(n => this.onRangeFilterChange(n));
    }

    ngOnDestroy() {
        this.rangeListSubscription.unsubscribe();
        this.rangeFilterSubscription.unsubscribe();
    }

    onRangeListChange(rangeList: SensorRangeInfo[]) {
        this.rangeList = rangeList;
        this.filterRangeList();
    }

    onRangeFilterChange(filter: number[]) {
        this.filter = filter;
        this.filterRangeList();
    }

    filterRangeList() {
        this.selectedList = [];

        if (this.filter) {
            // const data: CustomTableColumnDefs = this.columnDefs?.find(k => k.title === temperatureRange);
            this.rangeList.forEach(item => {
                if (this.filter.indexOf(item.SensorRangeId) >= 0) {
                    this.selectedList.push(item);
                    // const count = data?.list?.find(a => a.value === item.SensorRangeId)?.count;
                    // this.selectedList.push({ ...item, count } as any);
                }
            });
        }
    }

    clearSelectedFilter(statusCode: number) {
        this.shipmentFilter.sensorRange = this.shipmentFilter.sensorRange.filter(k => k !== statusCode);
        this.shipmentFilterService.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        if (this.summaryRemove) {
            this.summaryRemove.emit({ title: temperatureRange, value: this.shipmentFilter.sensorRange });
        }
    }
}


