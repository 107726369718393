import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { LocationCollisionService } from 'app-modules/core/services/location-collisions.service';
import { LocationViewModel } from 'app-modules/location-management/models/location-view-model';
import { DistanceUnits, EmrUtilService, IBox, IMapOptions, IMarkerMetadata, IMarkerOptions, InfoBoxPlacement, LocationInfo, MapTypeId, Marker, MarkerEventArgs } from 'emr-ng-shared';

@Component({
  selector: 'app-locations-map-view',
  templateUrl: './locations-map-view.component.html',
  styleUrls: ['./locations-map-view.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationsMapViewComponent implements OnInit, OnChanges {

  @Input() SelectedLocation: LocationInfo;
  @Input() distanceUnits: DistanceUnits;
  locationsVM: LocationViewModel[];
  MarkerList: Array<Marker> = []; //A collection of Marker objects created based on this.markerOptions
  // map
  public options: IMapOptions = {
    disableBirdseye: false,
    disableStreetside: false,
    navigationBarMode: 1,
    mapTypeId: MapTypeId.road
  };

  private defaultBox = this.custSvc?.defaultBox;
  public CenterAutoFit = InfoBoxPlacement.InPlaceAutoFit;


  locationMarkers: IMarkerOptions[] = [];
  public box: IBox = this.defaultBox;
  visibleLocationBoundaries: LocationViewModel[];
  mapCenterLat: any;
  mapCenterLong: any;
  bounds: IBox;
  isZoomed: boolean;
  selectedLocation: LocationInfo;
  zoomIndex: number = 8;
  isInfoZoomInAction: any;
  isZoomedInfoLevel: boolean = false;
  isMapLoaded: any;
  previousZoomIndex: number = 0;
  previousBox: IBox;

  ShowInfoBox = new EventEmitter<MarkerEventArgs>();
  HideInfoBox = new EventEmitter<void>();
  allLocationsBox: { maxLongitude: any; maxLatitude: any; minLongitude: any; minLatitude: any; };

  constructor(
    private utilSvc: EmrUtilService,
    private custSvc: CustomersService, 
    private imgSvc: ImageMapService,
    private locationCollisionSvc: LocationCollisionService) 
  { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.SelectedLocation) {
      if (changes.SelectedLocation.currentValue) {
        this.selectedLocation = this.SelectedLocation;
        this.setMapMarkers();
      } else {
        this.resetMapMarkers();
      }
    }
  }

  onResetZoom() {
    if (this.isZoomed) {
      this.isZoomed = false;
      this.box = { ...this.allLocationsBox };
      this.HideInfoBox.emit();
    }
  }

  onMarkersCreated(markers: Array<Marker>) {
    this.MarkerList = markers;
    if (this.SelectedLocation) {        
      this.HideInfoBox.emit();
      const eventArgs = this.getMarkerEventArgsByLocation(this.SelectedLocation);
      this.openInfoBox(eventArgs, false);
    }
  }

  onMarkerClick(e: MarkerEventArgs) {
      if (e.Markers.length == 1 && e.Marker && e.Marker.Metadata) {
          this.selectSpecificLocation(e.Marker);
      }
      this.setBox(this.getLocationByMarker(e.Marker));
  }

  onBoundsUpdate(bounds: IBox) {
      this.mapCenterLat = this.mapCenterLat ? this.mapCenterLat : bounds.center.latitude;
      this.mapCenterLong = this.mapCenterLong ? this.mapCenterLong : bounds.center.longitude;
      if (this.mapCenterLat !== bounds.center.latitude || this.mapCenterLong !== bounds.center.longitude) {
          this.isZoomed = true;
      } else {
          this.isZoomed = false;
      }
      this.bounds = bounds;
  }

  onMapUpdate() {
  }

  //set this.selectedLocation only here
  selectSpecificLocation(locationOrMarker: LocationInfo | Marker) {
    this.selectedLocation = locationOrMarker instanceof LocationInfo ? locationOrMarker : this.getLocationByMarker(locationOrMarker);
  }

  //Find corresponding Location according to Marker
  getLocationByMarker(marker: Marker): LocationInfo {
    const mkLocation = marker.Metadata.get('location');
    return mkLocation;
  }

  
    //Get the data for the detail template
  getDetailTemplateInfo(markerMetadata: Array<IMarkerMetadata>, flag: string): any {
    const sourceMarker = markerMetadata.find(p => p.isSourceMarker);
    return sourceMarker.metadata.get(flag);
  }

  onMapZoomUpdate(zoom: number) {
    this.onMapUpdate();
    if (this.isMapLoaded) {
      this.previousZoomIndex = zoom;
      this.previousBox = this.bounds;
      this.isMapLoaded = false;
    }
    this.zoomIndex = zoom;
    if (!this.isInfoZoomInAction && !this.isZoomedInfoLevel && zoom <= this.previousZoomIndex) {
      this.isZoomedInfoLevel = true;
    }
    this.isInfoZoomInAction = false;
  }

  //Open InfoBox and record event traces as required
  openInfoBox(e: MarkerEventArgs, updateBox: boolean = true) {
    if (e) {
      if (updateBox) {
        this.setBox(this.getLocationByMarker(e.Marker));
        this.zoomIntoMarker();
      }
      setTimeout(() => {
        this.ShowInfoBox.emit(e);
      });
    }
  }

  onViewLocationClick(s) {
    switch (s.linkClass) {
      case 'click-infobox-zoom-in':
        this.openInfoBox(this.getMarkerEventArgsByLocation(this.selectedLocation));
        this.isZoomedInfoLevel = false;
        this.isZoomed = true;
        this.isInfoZoomInAction = true;
        break;
      case 'click-infobox-zoom-out':
        this.openInfoBox(this.getMarkerEventArgsByLocation(this.selectedLocation));
        this.isZoomedInfoLevel = true;
        this.onResetZoom();
        break;
    }
  }

  private zoomIntoMarker() {
    if (!this.selectedLocation) { return; }
    const box = this.utilSvc.getNewBoxParams();
    this.utilSvc.updateBoxParams(box, this.selectedLocation.Latitude, this.selectedLocation.Longitude);
    if (this.selectedLocation.Boundary &&
        this.selectedLocation.Boundary.length > 0) {
      this.selectedLocation.Boundary.forEach(b => this.utilSvc.updateBoxParams(box, b.Latitude, b.Longitude));
    }
    this.box = box;
  }

  private setMapMarkers() {
    var locVMs = [new LocationViewModel(this.selectedLocation, this.locationCollisionSvc, this.distanceUnits, this.imgSvc), ...this.selectedLocation.Collisions?.map(c => new LocationViewModel(c.Location, this.locationCollisionSvc, this.distanceUnits, this.imgSvc, c.InOthersFence, c.FenceCollided))];
    this.locationsVM = locVMs;
    const box = this.utilSvc.getNewBoxParams();
    this.locationMarkers = this.locationsVM.map(l => {
      this.utilSvc.updateBoxParams(box, l.latitude, l.longitude);
      return l.LocationMarker;
    });
    this.visibleLocationBoundaries = this.locationsVM.filter(l => l.HasBoundary).map(l => {
      l.boundaryLocs.forEach(b => this.utilSvc.updateBoxParams(box, b.latitude, b.longitude));
      return l;
    });
    this.isMapLoaded = true;
    this.box = box;
    this.allLocationsBox = box;
  }

  private setBox(loc: LocationInfo) {
    this.isZoomed = true;
  }

  private resetMapMarkers() {
      this.visibleLocationBoundaries = [];
      this.locationsVM = [];
      this.locationMarkers = [];
      this.box = this.defaultBox;
  }

  //Find corresponding Marker according to Location
  private getMarkerByLocation(location: LocationInfo): Marker {
    const result = this.MarkerList.find(marker => {
      const mkLocation = this.getLocationByMarker(marker);
      return mkLocation.LocationId === location.LocationId;
    });
    return result;
  }

  //Build MarkerEventArgs according to Location
  private getMarkerEventArgsByLocation(location: LocationInfo, needSameLocationMarkers?: boolean): MarkerEventArgs {
    const marker = this.getMarkerByLocation(location);
    return marker ? this.getMarkerEventArgsByMarker(marker, needSameLocationMarkers) : null;
  }

  //Build MarkerEventArgs according to Marker
  private getMarkerEventArgsByMarker(marker: Marker, needSameLocationMarkers?: boolean): MarkerEventArgs {
    const markers = needSameLocationMarkers ? this.MarkerList.filter(mk => mk.Location.latitude === marker.Location.latitude && mk.Location.longitude === marker.Location.longitude) : [marker];
    return {
      Marker: marker,
      Location: marker.Location,
      Click: null,
      Pixels: null,
      Markers: markers,
    };
  }
}
