import { Injectable } from '@angular/core';

import { EMPTY, Observable, of } from 'rxjs';
import { tap, switchMap, map, catchError } from 'rxjs/operators';
import { LoginRequest, LoginResponse } from 'emr-ng-shared';


import { handleErrorResponse } from 'app-modules/core/rxjs/operators/handle-error-response.operator';
import { PakSenseAuthStateService } from './ps-auth-state.service';
import { PakSenseApiService } from 'app-modules/core/services/pak-sense-api.service';
import { LoginExternalResponse } from 'app-modules/core/models/ps-login-external-response.model';


@Injectable({ providedIn: 'root' })
export class PakSenseAuthService {
    constructor(
        private authStateSvc: PakSenseAuthStateService,
        private pakSenseAPISvc: PakSenseApiService
    ) { }

    requestedURL: string;
    public authState$ = this.authStateSvc.authState$;
    public authenticated$ = this.authStateSvc.authenticated$;
    public authToken$ = this.authStateSvc.authToken$;

    public signIn(username: string, password: string): Observable<LoginExternalResponse> {
        return this.invokeSignIn(username, password);
    }

    private invokeSignIn(username: string, password: string): Observable<LoginExternalResponse> {
        return of(null).pipe(
            tap(n => this.authStateSvc.signIn()),
            switchMap(n => this.invokeApiSignIn(username, password)),
            tap(
                n => {
                    this.processLoginResponse(n, username);
                },
                e => this.authStateSvc.signInError(e)
            )
        );
    }

    public processLoginResponse(response: LoginExternalResponse | { ApiToken: string }, username: string) {
        // Dispatch sign-in success
        this.authStateSvc.signInSuccess(
            response.ApiToken,
            username,
            username);
    }

    private invokeApiSignIn(username: string, password: string): Observable<LoginExternalResponse> {
        const request = ({
            UserName: username,
            Password: password
        }) as LoginRequest;

        return this.pakSenseAPISvc.Login(request).pipe(
            handleErrorResponse(),
            map<LoginExternalResponse, LoginExternalResponse>(
                n => n
            )
        );
    }

    public loggerUserCheck(userName: string) {
        return of(null).pipe(
            switchMap(n => this.pakSenseAPISvc.loggerUserCheck(userName)),
            tap(
                n => n,
                e => false
            )
        );
    }
}


