import { ShipmentInfo } from 'emr-ng-shared';

import { ChartData } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTrackerStateReport implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT;
}

export class LoadTrackerStateReportSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT;
    constructor(
        public summary: ShipmentInfo,
        public chartData: IListInfo<ChartData>
    ) { }
}

export class LoadTrackerStateReportError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT;

    constructor(public message: string) { }
}

export class CancelLoadTrackerStateReport implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER_STATE_REPORT;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT;
}

