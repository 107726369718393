import { Action } from '@ngrx/store';

import { DashboardInfo, SetDashboardRequest } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestAction, ServerRequestCompleteAction } from '../actions/custom-actions';

export class LoadDashboards implements ServerRequestAction {
    readonly type = actionIndex.LOAD_DASHBOARD;
}

export class LoadDashboardsSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_DASHBOARD_SUCCESS;
    readonly callingAction = actionIndex.LOAD_DASHBOARD;
    constructor(public dashboardList: IListInfo<DashboardInfo>) { }
}

export class LoadDashboardsError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_DASHBOARD_ERROR;
    readonly callingAction = actionIndex.LOAD_DASHBOARD;
    constructor(public message: string) { }
}

export class CancelLoadDashboards implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_DASHBOARD;
    readonly callingAction = actionIndex.LOAD_DASHBOARD;
}

// Save Dashboard
export class SetDashboard implements ServerRequestAction {
    readonly type = actionIndex.SET_DASHBOARD;
    constructor(public request: SetDashboardRequest) { }
}

export class SetDashboardSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_DASHBOARD_SUCCESS;
    readonly callingAction = actionIndex.SET_DASHBOARD;
    constructor(public dashboard: DashboardInfo) { }
}

export class SetDashboardError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_DASHBOARD_ERROR;
    readonly callingAction = actionIndex.SET_DASHBOARD;
    constructor(public message: string) { }
}

export class CancelSetDashboard implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SET_DASHBOARD;
    readonly callingAction = actionIndex.SET_DASHBOARD;
}


// Delete Dashboard
export class DeleteDashboard implements ServerRequestAction {
    readonly type = actionIndex.DELETE_DASHBOARD;
    constructor(public dashboardID: number) { }
}

export class DeleteDashboardSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_DASHBOARD_SUCCESS;
    readonly callingAction = actionIndex.DELETE_DASHBOARD;
    constructor(public dashboardID: number) { }
}

export class DeleteDashboardError implements ServerRequestCompleteAction {
    readonly type = actionIndex.DELETE_DASHBOARD_ERROR;
    readonly callingAction = actionIndex.DELETE_DASHBOARD;
    constructor(public message: string) { }
}

export class CancelDeleteDashboard implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_DELETE_DASHBOARD;
    readonly callingAction = actionIndex.DELETE_DASHBOARD;
}