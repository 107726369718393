<div class="row">
    <div class="col-md-5 col-sm-8 p-0 col-12 mb-3" *ngIf="!IsUnAuthenticated">
        <pagination i18n-firstText="@@first" i18n-previousText="@@previous" i18n-nextText="@@next"
            i18n-lastText="@@last" [boundaryLinks]="itemCount > pageSize" [directionLinks]="itemCount > pageSize"
            [totalItems]="itemCount" [itemsPerPage]="pageSize" [(ngModel)]="pageNumber" [maxSize]="1"
            pageBtnClass="btn button-text btn-sm btn-outline-default custom-padding"
            (pageChanged)="onPageChanged($event)" firstText="First" previousText="Previous" nextText="Next"
            lastText="Last" (numPages)="numPages = $event"></pagination>
    </div>
    <div class="col-md-1 p-0 col-sm-4 col-4" *ngIf="!IsUnAuthenticated">
        <span translate="no" class="notranslate text-input form-control custom-padding">{{pageNumber}} / {{numPages}}</span>
    </div>
    <div class="col-md-3 {{IsUnAuthenticated?'offset-md-9':'pr-0'}} col-sm-4 col-4">
        <span class="text-input form-control">
            <b>
                <span i18n="@@total_records">Total Records:</span>
            </b>
            <span translate="no" class="notranslate">&nbsp;{{itemCount}}</span>
        </span>
    </div>
    <div class="col-md-3 col-sm-4 col-4" *ngIf="!IsUnAuthenticated">
        <emr-custom-dropdown identifier="lstRecordsPerPage" name="lstRecordsPerPage" [options]="pageSizeList.list"
            [(ngModel)]="pageSize" cssClass="default-dropdown" labelCssClass="height-10" [isDropup]="isDropUp"
            (onChange)="onPageSizeChanged()" optionsTextField="LocalizedText" optionsValueField="Value">
        </emr-custom-dropdown>
    </div>
</div>