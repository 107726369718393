<emr-modal class="scrollable-modal" autocomplete="nope" [bsModalRef]=bsModalRef>
    <ng-container modal-header>
        <span class="col-md-12 w-100 modal-title modal-header-title"> {{ headerTitle}}</span>
    </ng-container>
    <ng-container modal-body>
        <form name="templateForm" #shipmentForm="ngForm" emrForm autocomplete="nope">
            <div class="bg-create-template  mx-auto">
                <div class="row">
                    <div class="col-md-12 vmargin-sm mt-2">
                        <div class="row" *ngIf="(linkedShippersPushList$ | async)?.length > 0">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown identifier="lstLinkedShippers" name="lstLinkedShippers"
                                        [options]="linkedShippersPushList$ | async"
                                        optionsValueField="ShipperCustomerID" optionsTextField="Description"
                                        [(ngModel)]="shipment.ShipperID" cssClass="default-dropdown" label="Shipper"
                                        i18n-label="@@ct_report_label_shipper" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [validationIndicatorRequired]=true
                                        [enableSearchFilter]="(linkedShippersPushList$ | async)?.length >25"
                                        SearchPlaceHolder="Search" i18n-SearchPlaceHolder="@@search"
                                        placeholder="Select" i18n-placeholder="@@lable_select">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="DisplayVehicleGroupingInCreateShipment">
                            <div class="col-md-12">
                                <p>
                                    <emr-multiselect-dropdown identifier="lstVehicleGroup" name="lstVehicleGroup"
                                        [options]="VehicleGroups" label="Vehicle Group"
                                        i18n-label="@@label_vehicle_group" [(ngModel)]="vehicleGroupIds"
                                        placeholder="Select Vehicle Group"
                                        i18n-placeholder="@@label_select_vehicle_group" helpTip="Vehicle Group"
                                        i18n-helpTip="@@label_vehicle_group" [inline]=true labelColumns=3
                                        controlColumns={{controlColumns}} optionsValueField="VehicleGroupId"
                                        optionsTextField="Name" selectAllText="Select All"
                                        i18n-selectAllText="@@select_all" unSelectAllText="UnSelect All"
                                        i18n-unSelectAllText="@@unselect_all" searchPlaceholderText="Search"
                                        i18n-searchPlaceholderText="@@search"
                                        noDataAvailablePlaceholderText="No data available"
                                        i18n-noDataAvailablePlaceholderText="@@no_data_available"
                                        [isLoading]="isVehiclesLoading$ | async">
                                    </emr-multiselect-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox ngDefaultControl identifier="txtTemplateName"
                                        name="txtTemplateName_{{randomNumber}}" label="Enter Template Name"
                                        i18n-label="@@label_enter_template_name" placeholder="Template Name"
                                        i18n-placeholder="@@label_Template_name" [(ngModel)]="shipment.TripTemplateName"
                                        required [inline]=true labelColumns=3 textBoxColumns="{{controlColumns}}">
                                        <emr-validation-message validatorName="required"
                                            message="Template Name Required" i18n-message="@@template_name_required">
                                        </emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <p>
                                    <span i18n="@@label_origin">Origin</span>
                                </p>
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstOriginLocations"
                                        name="lstOriginLocations" [options]="originLocations$ | async"
                                        [(ngModel)]="shipment.OriginLocationId" cssClass="default-dropdown"
                                        label="Location" i18n-label="@@label_location" helpTip="Origin Location Name"
                                        required [inline]=true i18n-helpTip="@@label_origin_location_name"
                                        [showPlaceHolder]=false placeholder="Select / Create Location"
                                        i18n-placeholder="@@label_select_create_location" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [createNewItem]=true
                                        createNewItemText="Create New Location"
                                        i18n-createNewItemText="@@label_create_new_location"
                                        (CreateNewItemClick)="openAddLocationModal('origin')"
                                        [validationIndicatorRequired]=true [enableSearchFilter]=true
                                        SearchPlaceHolder="Search Location"
                                        [EmrCompareTwoValuesValidator]="shipment.DestinationLocationId"
                                        [showDropDownCreateToolTip]="isEndLocationUndefined || isCreateOptionsDisbabled"
                                        disabledCreateHelpTip="Cannot create a new when creating a shared shipment"
                                        i18n-disabledCreateHelpTip="@@label_createaddress_disabled_dualvis"
                                        (onChange)="selectDistinctLocation($event,'origin')"
                                        i18n-SearchPlaceHolder="@@label_search_location"
                                        [EmrSecondCompareValues]="child?.selectedStopsLocations"
                                        [isLoading]="isLocationListLoading$ | async">
                                        <emr-validation-message validatorName="required"
                                            message="Start Location Name  Required"
                                            i18n-message="@@start_location_name_required"></emr-validation-message>
                                        <emr-validation-message validatorName="EmrCompareTwoValuesValidator"
                                            i18n-message="@@select_distinct_location_name_required"
                                            message="Please select distinct Origin Location">
                                        </emr-validation-message>
                                        <emr-validation-message validatorName="EmrSecondCompareValuesValidator"
                                            i18n-message="@@select_distinct_origin_destination_from_stop_locat"
                                            message="Trip Stops location can not be used as Origin/Destination location">
                                        </emr-validation-message>
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row" *ngIf="DisplayTripStartOptions">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown identifier="lstStartOptions" name="lstStartOptions"
                                        [options]="tripStartOptionsList$ | async" optionsValueField="TripStartOptionID"
                                        optionsTextField="Description" [(ngModel)]="shipment.TripStartOptionID"
                                        cssClass="default-dropdown" label="Start Option"
                                        i18n-label="@@label_start_option" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}"
                                        helpTip="The start option determines how a shipment starts, whether based on location (default) or an estimated start time."
                                        i18n-helpTip="@@tooltip_start_option" [validationIndicatorRequired]=true
                                        [isLoading]="isTripStartOptionsLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="row section-header">
                                <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                    <span i18n="@@label_additional_stops">Additional Stops</span>
                                    <hr>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>
                                        <app-stop-location-list [locations]=stopLocations
                                            [isEndLocationUndefined]="isEndLocationUndefined || isCreateOptionsDisbabled"
                                            [(tripStops)]="stops" [Shipment]="shipment" label="Airport Departure"
                                            i18n-label="@@label_airport_departure"
                                            [selectedOD]="shipment.OriginLocationId+','+shipment.DestinationLocationId"
                                            (openAddLocationModal)="openAddLocationModal($event)">
                                        </app-stop-location-list>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <p>
                                    <span i18n="@@label_destination"> Destination</span>
                                </p>
                                <hr>
                            </div>
                        </div>
                        <div *ngIf="!(this.shipment.NoDestinationRequired || this.shipment.EndLocationUndefined)">
                            <div class="row">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstDestinationLocations"
                                            name="lstDestinationLocations" [options]="destLocations$ | async"
                                            [(ngModel)]="shipment.DestinationLocationId" cssClass="default-dropdown"
                                            label="Location" [inline]=true i18n-label="@@label_location"
                                            helpTip="Destination Location Name"
                                            i18n-helpTip="@@label_destination_location_name" [showPlaceHolder]=false
                                            placeholder="Select / Create Location"
                                            [showDropDownCreateToolTip]="isEndLocationUndefined || isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Cannot create a new when creating a shared shipment"
                                            i18n-disabledCreateHelpTip="@@label_createaddress_disabled_dualvis"
                                            i18n-placeholder="@@label_select_create_location" [inline]=true
                                            labelColumns=3 controlColumns="{{controlColumns}}" [createNewItem]=true
                                            createNewItemText="Create New Location"
                                            i18n-createNewItemText="@@label_create_new_location"
                                            (CreateNewItemClick)="openAddLocationModal('destination')"
                                            [displayValidationIndicator]=true [enableSearchFilter]=true
                                            SearchPlaceHolder="Search Location"
                                            [EmrCompareTwoValuesValidator]="shipment.OriginLocationId"
                                            i18n-SearchPlaceHolder="@@label_search_location"
                                            (onChange)="selectDistinctLocation($event,'destination')"
                                            [EmrSecondCompareValues]="child?.selectedStopsLocations"
                                            [isLoading]="isLocationListLoading$ | async" required>
                                            <emr-validation-message validatorName="required"
                                                message="Destination Location Name  Required"
                                                i18n-message="@@destination_location_name_required">
                                            </emr-validation-message>
                                            <emr-validation-message validatorName="EmrCompareTwoValuesValidator"
                                                i18n-message="@@select_distinct_location_name_required"
                                                message="Please select distinct Origin Destination">
                                            </emr-validation-message>
                                            <emr-validation-message validatorName="EmrSecondCompareValuesValidator"
                                                i18n-message="@@select_distinct_origin_destination_from_stop_locat"
                                                message="Trip Stops location can not be used as Origin/Destination location">
                                            </emr-validation-message>
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                            <div class="row" *ngIf="DisplayTripEndOptions">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown identifier="lstEndOptions" name="lstEndOptions"
                                            [options]="tripEndOptionsList$ | async" optionsValueField="TripEndOptionID"
                                            optionsTextField="Description" [(ngModel)]="shipment.TripEndOptionID"
                                            cssClass="default-dropdown" label="End Option"
                                            i18n-label="@@label_end_option" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}"
                                            helpTip="The end option determines how a shipment completes, whether based on location (default) or an estimated end time."
                                            i18n-helpTip="@@tooltip_end_option" [validationIndicatorRequired]=true
                                            [isLoading]="isTripEndOptionsLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular">
                                <p>
                                    <span i18n="@@label_sensor_ranges">Sensor Ranges</span>
                                </p>
                                <hr>
                            </div>
                        </div>
                        <div *ngIf="sensorRangeList$ | async as sensors">
                            <div class="row" *ngIf="!shipment.NoTemperatureRequired">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstSensorRange"
                                            name="lstSensorRange" [options]="sensors.Temperature"
                                            [(ngModel)]="TemperatureRangeId" cssClass="default-dropdown"
                                            label="Temperature Range" i18n-label="@@label_temperature_range"
                                            optionsValueField="SensorRangeId" optionsTextField="Description"
                                            helpTip="Temperature Range" i18n-helpTip="@@label_temperature_range"
                                            placeholder="Select Temperature Range"
                                            i18n-placeholder="@@select_temperature_range" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [validationIndicatorRequired]=true
                                            [createNewItem]=true createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Temperature)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-12">
                                    <emr-checkbox ngDefaultControl identifier="chkNoTemperatureRequired"
                                        name="chkNoTemperatureRequired" text="No Temperature Information Required."
                                        i18n-text="@@label_no_temperature_required"
                                        [(ngModel)]="shipment.NoTemperatureRequired"></emr-checkbox>
                                </div>
                            </div> -->
                            <div class="row" *ngIf="!hideHumidity">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstHumiditySensorRange"
                                            name="lstHumiditySensorRange" [options]="sensors.Humidity"
                                            [(ngModel)]="HumiditySensorId" cssClass="default-dropdown" label="Humidity"
                                            i18n-label="@@label_humidity" optionsValueField="SensorRangeId"
                                            optionsTextField="Description" helpTip="Humidity"
                                            i18n-helpTip="@@label_humidity" placeholder="Select Humidity"
                                            i18n-placeholder="@@select_humidity" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [createNewItem]=true
                                            [validationIndicatorRequired]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.Humidity)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstCO2SensorRange"
                                            name="lstCO2SensorRange" [options]="sensors.CarbonDioxide"
                                            [(ngModel)]="CarbonDioxideSensorId" cssClass="default-dropdown" label="CO2"
                                            i18n-label="@@label_carbondioxide" optionsValueField="SensorRangeId"
                                            optionsTextField="Description" helpTip="CO2"
                                            i18n-helpTip="@@label_carbondioxide" placeholder="Select CO2"
                                            i18n-placeholder="@@select_co2" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [createNewItem]=true
                                            [validationIndicatorRequired]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.CarbonDioxide)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstProbSensorRange"
                                            name="lstProbSensorRange" [options]="sensors.ProbeTemp"
                                            [(ngModel)]="ProbSensorId" cssClass="default-dropdown" label="Prob Temp"
                                            i18n-label="@@label_probetemp" optionsValueField="SensorRangeId"
                                            optionsTextField="Description" helpTip="Prob Temp"
                                            i18n-helpTip="@@label_probetemp" placeholder="Select Prob Temp"
                                            i18n-placeholder="@@select_probtemp" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [createNewItem]=true
                                            [validationIndicatorRequired]=true
                                            createNewItemText="Create New Sensor Range"
                                            i18n-createNewItemText="@@create_new_sensor_range"
                                            [showDropDownCreateToolTip]="isCreateOptionsDisbabled"
                                            disabledCreateHelpTip="Please contact cargoaccountmanagement@copeland.com if you would like to add a new sensor range"
                                            i18n-disabledCreateHelpTip="@@label_createsensorrange_disabled_dualvis"
                                            (CreateNewItemClick)="openAddSensorRangeModal(sensorType.ProbeTemp)"
                                            [enableSearchFilter]=true [isLoading]="isSensorListLoading$ | async">
                                        </emr-custom-dropdown>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstCoolDownType"
                                        name="lstCoolDownType" [options]="coolDownTypeList$ | async"
                                        [(ngModel)]="shipment.CooldownTypeCode" cssClass="default-dropdown"
                                        label="Cool Down" i18n-label="@@label_cool_down" helpTip="Cool Down"
                                        i18n-helpTip="@@label_cool_down" placeholder="Select Cool Down"
                                        i18n-placeholder="@@label_select_cool_down" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [validationIndicatorRequired]=true
                                        optionsValueField="CooldownTypeCode" optionsTextField="Description"
                                        [isLoading]="isCoolDownLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <div class="row section-header">
                            <div class="col-md-12 roboto-regular-font font-16 color-regular"
                                (click)="isAlertContactCollapsed = !isAlertContactCollapsed">
                                <p>
                                    <span i18n="@@label_additional_alert_contacts_optional">Additional Alert
                                        Contacts
                                        (OPTIONAL)
                                    </span>
                                </p>
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p *ngIf="!IsCarrierDropDown;else CarrierDropdown">
                                    <emr-textbox ngDefaultControl identifier="txtCarrierName"
                                        name="txtCarrierName_{{randomNumber}}" label="Carrier Name"
                                        i18n-label="@@label_carrier_name" placeholder="Carrier Name"
                                        i18n-placeholder="@@label_carrier_name" [(ngModel)]="shipment.CarrierName"
                                        name="txtCarrierName" helpTip="Carrier Name" i18n-helpTip="@@label_carrier_name"
                                        [inline]=true labelColumns=3 textBoxColumns="{{controlColumns}}"
                                        [minlength]="IsCarrierFieldRequired ? 2 : 0"
                                        required="{{IsCarrierFieldRequired}}"></emr-textbox>
                                    <emr-validation-message validatorName="required"
                                        i18n-message="@@carrier_name_required" message="Carrier name required">
                                    </emr-validation-message>
                                    <emr-validation-message validatorName="minlength"
                                        i18n-message="@@carrier_min_length"
                                        message="Carrier value must be min 2 characters">
                                    </emr-validation-message>
                                </p>
                                <ng-template #CarrierDropdown>
                                    <p>
                                        <emr-custom-dropdown ngDefaultControl identifier="lstCarrierName"
                                            name="lstCarrierName" [options]="carrierList"
                                            [(ngModel)]="shipment.CarrierName" cssClass="default-dropdown"
                                            [displayValidationIndicator]="IsCarrierFieldRequired" label="Carrier Name"
                                            i18n-label="@@label_carrier_name" optionsValueField="Value"
                                            optionsTextField="Value" helpTip="Carrier Name"
                                            i18n-helpTip="@@label_carrier_name" placeholder="Select Carrier Name"
                                            i18n-placeholder="@@select_carrier_name"
                                            required="{{IsCarrierFieldRequired}}" [inline]=true labelColumns=3
                                            controlColumns="{{controlColumns}}" [validationIndicatorRequired]=true
                                            [createNewItem]=false [enableSearchFilter]=true
                                            [isLoading]="isSensorListLoading$ | async">
                                            <emr-validation-message validatorName="required"
                                                i18n-message="@@carrier_name_required" message="Carrier name required">
                                            </emr-validation-message>
                                        </emr-custom-dropdown>
                                    </p>
                                </ng-template>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-textbox ngDefaultControl identifier="txtCarrierEmails" EmrEmailValidator
                                        name="txtCarrierEmails_{{randomNumber}}" label="Carrier Email(s)"
                                        i18n-label="@@label_carrier_emails" placeholder="Carrier Email(s)"
                                        i18n-placeholder="@@label_carrier_emails" [(ngModel)]="shipment.EmailAddresses"
                                        name="txtCarrierEmails_{{randomNumber}}"
                                        helpText="To add multiple emails, type email addresses separated with commas."
                                        i18n-helpText="@@label_carrier_email_help_text" helpTip="Carrier Email(s)"
                                        i18n-helpTip="@@label_carrier_emails" [inline]=true labelColumns=3
                                        textBoxColumns="{{controlColumns}}">
                                        <emr-validation-message validatorName="EmrEmailValidator"
                                            i18n-message="@@validation_email" message="Please enter valid Email	">
                                        </emr-validation-message>
                                    </emr-textbox>
                                </p>
                            </div>
                        </div>
                        <div class="row">
                            <label class="label-css col-md-3" for="chklstEscalationLevels">
                                <span class="cursor-pointer" i18n-tooltip="@@label_alert_escalation_levels"
                                    tooltip="Alert Escalation Levels" placement="top"
                                    i18n="@@label_alert_escalation_levels">
                                    Alert Escalation Levels</span>
                            </label>
                            <div class="col-md-9">
                                <div class="row mlr-0">
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_1"
                                            name="chklstEscalationLevels_1" text="Level 1"
                                            i18n-text="@@AlertEscalationLevel_1" inline=true [(ngModel)]="shipment.L1">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_2"
                                            name="chklstEscalationLevels_2" text="Level 2"
                                            i18n-text="@@AlertEscalationLevel_2" inline=true [(ngModel)]="shipment.L2">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_3"
                                            name="chklstEscalationLevels_3" text="Level 3"
                                            i18n-text="@@AlertEscalationLevel_3" inline=true [(ngModel)]="shipment.L3">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_4"
                                            name="chklstEscalationLevels_4" text="Level 4"
                                            i18n-text="@@AlertEscalationLevel_4" inline=true [(ngModel)]="shipment.L4">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_5"
                                            name="chklstEscalationLevels_5" text="Level 5"
                                            i18n-text="@@AlertEscalationLevel_5" inline=true [(ngModel)]="shipment.L5">
                                        </emr-checkbox>
                                    </div>
                                    <div class="col-md-2">
                                        <emr-checkbox ngDefaultControl identifier="chklstEscalationLevels_6"
                                            name="chklstEscalationLevels_6" text="Level 6"
                                            i18n-text="@@AlertEscalationLevel_6" inline=true [(ngModel)]="shipment.L6">
                                        </emr-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p>
                                    <emr-custom-dropdown ngDefaultControl identifier="lstTimeZone" name="lstTimeZone"
                                        [options]="(timeZoneList$ | async).list" [(ngModel)]="shipment.AlertTimeZone"
                                        cssClass="default-dropdown" label="Alert Time Zone"
                                        i18n-label="@@label_alert_time_zone" optionsValueField="TimeZoneId"
                                        optionsTextField="FullName" helpTip="Alert Time Zone"
                                        i18n-helpTip="@@label_alert_time_zone" placeholder="Select Time Zone"
                                        i18n-placeholder="@@label_select_time_zone" [inline]=true labelColumns=3
                                        controlColumns="{{controlColumns}}" [enableSearchFilter]=true
                                        SearchPlaceHolder="Search Time Zone" i18n-SearchPlaceHolder="@@search_time_zone"
                                        [isLoading]="isTimeZoneLoading$ | async">
                                    </emr-custom-dropdown>
                                </p>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <p>
                                    <emr-button identifier="btnUpdateTemplate" buttonType="submit"
                                        buttonText="{{SaveBtnText}}" cssClass="btn-outline-default submit-btn"
                                        [setDisabled]="disableSubmitButton" (onClick)="onUpdateClick()"
                                        buttonIcon="fa fa-check"></emr-button>
                                    <emr-button identifier="btnCancel" buttonText="Cancel"
                                        i18n-buttonText="@@link_cancel" cssClass="btn-outline-default reset-btn"
                                        (onClick)="onCancelClick()" buttonIcon="fa fa-repeat"> </emr-button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
</emr-modal>