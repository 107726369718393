import { Injectable } from '@angular/core';

import { Store, Action } from '@ngrx/store';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import * as LoadingActions from 'app-modules/core/store/actions/loading.actions';
import * as actionIndex from 'app-modules/core/store/actions/index';
import { map, delay, filter, tap } from 'rxjs/operators';
import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

const showSpinnerActions = [
    actionIndex.SIGN_IN,
    actionIndex.PAK_SENSE_SIGN_IN,
    actionIndex.LOAD_CUSTOMERS,
    actionIndex.SELECT_CUSTOMER,
    actionIndex.LOAD_SHIPMENTS,
    // actionIndex.LOAD_SHIPMENT_ADDRESS,
    actionIndex.CREATE_SHIPMENT,
    // actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS,
    actionIndex.CREATE_SHIPMENT_LOAD_CARRIER,
    actionIndex.CREATE_SHIPMENT_CREATE_LOCATION,
    // actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES,
    actionIndex.UPDATE_SHIPMENT,
    actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART,
    actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT,
    actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT,
    actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE,
    actionIndex.EDIT_SHIPMENT_LOAD_SENSOR_RANGES,
    actionIndex.LOAD_DISTANCE_TO,
    actionIndex.LOAD_VIEW_LOG,
    actionIndex.LOAD_SHOW_DISTANCE,
    // actionIndex.LOAD_PREFERENCE,
    // actionIndex.LOAD_TRACKERS,
    actionIndex.CREATE_SENSOR_RANGE,
    // actionIndex.LOAD_LOCATIONS,
    actionIndex.CREATE_LOCATION,
    // actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS,
    // actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS,
    // actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES,
    actionIndex.CREATE_SENSOR_RANGE,
    // actionIndex.LOAD_SENSOR_RANGES,
    actionIndex.LOAD_ALERT_SUSPENSE_STATUS,
    actionIndex.UPDATE_ALERT_SUSPENSE_STATUS,
    actionIndex.VALIDATE_SHIPMENT,
    // actionIndex.LOAD_SHIPMENT_TEMPLATES,
    actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS,
    actionIndex.LOAD_LINKED_CUSTOMERS,
    actionIndex.FORGOT_PASSWORD,
    actionIndex.CHANGE_PASSWORD,
    actionIndex.CLOSE_SHIPMENT,
    actionIndex.LOAD_SHIPMENTS_BYID,
    actionIndex.IMPORT_LOCATIONS,
    actionIndex.SAVE_IMPORT_LOCATIONS,
    actionIndex.LOAD_SHIPMENT_BY_SERIAL,
    actionIndex.LOAD_SAVED_ROUTE,
    actionIndex.SAVE_ROUTE,
    actionIndex.LOAD_COMPARE_TRAXX,
    actionIndex.LOAD_SAVED_ROUTE_DEVIATION,
    actionIndex.GET_SAVED_ROUTE_DEVIATION,
    actionIndex.LOAD_USER_SETTINGS,
    actionIndex.LOAD_USER_SETTINGS_WITH_STORE,
    actionIndex.SAVE_USER_SETTINGS,
    actionIndex.DELETE_ROUTE,
    actionIndex.CLOSE_ALERT,
    actionIndex.DELETE_SHIPMENT,
    actionIndex.SAVE_SHUTDOWN_TRACKER,
    // actionIndex.LOAD_TRACKER_SENSORS,
    actionIndex.UN_AUTH_CREATE_SHIPMENT,
    actionIndex.LOAD_TRACKER_SENSORS,
    actionIndex.SET_TRACKER_SENSOR_RANGE,
    actionIndex.SCHEDULE_REPORT,
    actionIndex.LOAD_SCHEDULE_REPORTS,
    actionIndex.SAVE_TRACKER_NOTE,
    actionIndex.VALIDATE_LOCATION,
    actionIndex.DELETE_LOCATION_GEOFENCE,
    actionIndex.Get_LOCATION_BOUNDARY,
    actionIndex.SAVE_LOCATION_GEOFENCE,
    actionIndex.SET_CONTACT,
    actionIndex.GET_LOCATION_CONTACT,
    actionIndex.SET_LOCATION_CONTACT,
    actionIndex.DELETE_LOCATION_CONTACT,
    actionIndex.DISPLAY_LOADER,
    actionIndex.SET_SHIPMENT_TEMPLATE,
    actionIndex.DELETE_LOCATION,
    actionIndex.DISPLAY_LOADER,
    actionIndex.LOAD_DASHBOARD,
    actionIndex.SET_DASHBOARD,
    actionIndex.DELETE_DASHBOARD,
    actionIndex.LOAD_CUSTOMER_DASHBOARD,
    actionIndex.SET_CUSTOMER_DASHBOARD,
    actionIndex.DELETE_CUSTOMER_DASHBOARD,
    actionIndex.LOAD_ALERT_SUSPENSE_STATUS,
    actionIndex.SHARE_TRACKER
];

const hideSpinnerActions = [
    actionIndex.SIGN_IN_SUCCESS,
    actionIndex.SIGN_IN_ERROR,
    actionIndex.PAK_SENSE_SIGN_IN_SUCCESS,
    actionIndex.PAK_SENSE_SIGN_IN_ERROR,
    actionIndex.LOAD_CUSTOMERS_ERROR,
    actionIndex.LOAD_CUSTOMERS_SUCCESS,
    actionIndex.SELECT_CUSTOMER_ERROR,
    actionIndex.SELECT_CUSTOMER_SUCCESS,
    actionIndex.LOAD_SHIPMENTS_SUCCESS,
    actionIndex.LOAD_SHIPMENTS_ERROR,
    actionIndex.CANCEL_LOAD_SHIPMENTS,
    // actionIndex.LOAD_SHIPMENT_ADDRESS_SUCCESS,
    // actionIndex.LOAD_SHIPMENT_ADDRESS_ERROR,
    // actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS_SUCCESS,
    // actionIndex.CREATE_SHIPMENT_LOAD_LOCATIONS_ERROR,
    actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_LOCATIONS,
    actionIndex.CREATE_SHIPMENT_LOAD_CARRIER_SUCCESS,
    actionIndex.CREATE_SHIPMENT_ADDORUPDATE_CARRIER_SUCCESS,
    actionIndex.CREATE_SHIPMENT_REMOVE_CARRIER_SUCCESS,
    actionIndex.CREATE_SHIPMENT_LOAD_CARRIER_ERROR,
    actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_CARRIER,
    actionIndex.CREATE_SHIPMENT_CREATE_LOCATION_ERROR,
    actionIndex.CREATE_SHIPMENT_CREATE_LOCATION_SUCCESS,
    // actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_SUCCESS,
    // actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES_ERROR,
    // actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_SHIPMENT_TEMPLATES,
    actionIndex.CREATE_SHIPMENT_ERROR,
    actionIndex.CREATE_SHIPMENT_SUCCESS,
    actionIndex.UPDATE_SHIPMENT_SUCCESS,
    actionIndex.UPDATE_SHIPMENT_ERROR,
    actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS_SUCCESS,
    actionIndex.SHIPMENT_DETAIL_LOAD_ALERTS_ERROR,
    actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_ALERTS,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_SUCCESS,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_ERROR,
    actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_TRACKER,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_SUCCESS,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_REPORT_ERROR,
    actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_SUCCESS,
    actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_TRACKER_STATE_REPORT_ERROR,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_SUCCESS,
    actionIndex.SHIPMENT_DETAIL_LOAD_TRACKER_STATE_CHART_ERROR,
    actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_SUCCESS,
    actionIndex.SHIPMENT_DETAIL_LOAD_EXPORT_SENSOR_REPORT_ERROR,
    actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE_SUCCESS,
    actionIndex.CREATE_SHIPMENT_CREATE_SENSOR_RANGE_ERROR,
    actionIndex.EDIT_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS,
    actionIndex.EDIT_SHIPMENT_LOAD_SENSOR_RANGES_ERROR,
    actionIndex.EDIT_SHIPMENT_CANCEL_LOAD_SENSOR_RANGES,
    actionIndex.LOAD_DISTANCE_TO_SUCCESS,
    actionIndex.LOAD_DISTANCE_TO_ERROR,
    actionIndex.LOAD_VIEW_LOG_SUCCESS,
    actionIndex.LOAD_VIEW_LOG_ERROR,
    actionIndex.CANCEL_DISTANCE_TO,
    actionIndex.LOAD_SHOW_DISTANCE_SUCCESS,
    actionIndex.LOAD_SHOW_DISTANCE_ERROR,
    actionIndex.CANCEL_SHOW_DISTANCE,
    // actionIndex.LOAD_PREFERENCE_SUCCESS,
    // actionIndex.LOAD_PREFERENCE_ERROR,
    // actionIndex.CANCEL_LOAD_PREFERENCE,
    // actionIndex.LOAD_TRACKERS_SUCCESS,
    // actionIndex.LOAD_TRACKERS_ERROR,
    // actionIndex.CANCEL_LOAD_TRACKERS,
    // actionIndex.LOAD_LOCATIONS_ERROR,
    // actionIndex.LOAD_LOCATIONS_SUCCESS,
    // actionIndex.CANCEL_LOAD_LOCATIONS,
    actionIndex.CREATE_LOCATION_ERROR,
    actionIndex.CREATE_LOCATION_SUCCESS,
    // actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES_SUCCESS,
    // actionIndex.CREATE_SHIPMENT_LOAD_SENSOR_RANGES_ERROR,
    // actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_SENSOR_RANGES,
    actionIndex.CREATE_SENSOR_RANGE_SUCCESS,
    actionIndex.CREATE_SENSOR_RANGE_ERROR,
    // actionIndex.CANCEL_LOAD_SENSOR_RANGES,
    //actionIndex.LOAD_SENSOR_RANGES_SUCCESS,
    //actionIndex.LOAD_SENSOR_RANGES_ERROR, 
    actionIndex.LOAD_ALERT_SUSPENSE_STATUS_ERROR,
    actionIndex.LOAD_ALERT_SUSPENSE_STATUS_SUCCESS,
    actionIndex.UPDATE_ALERT_SUSPENSE_STATUS_SUCCESS,
    actionIndex.UPDATE_ALERT_SUSPENSE_STATUS_ERROR,
    // actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS_ERROR,
    // actionIndex.CREATE_SHIPMENT_LOAD_TRACKERS_SUCCESS,
    actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_TRACKERS,
    actionIndex.VALIDATE_SHIPMENT_ERROR,
    actionIndex.VALIDATE_SHIPMENT_SUCCESS,
    actionIndex.CANCEL_VALIDATE_SHIPMENT,
    actionIndex.LOAD_SHIPMENT_TEMPLATES_SUCCESS,
    actionIndex.LOAD_SHIPMENT_TEMPLATES_ERROR,
    actionIndex.CANCEL_LOAD_SHIPMENT_TEMPLATES,
    actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_SUCCESS,
    actionIndex.CREATE_SHIPMENT_LOAD_TEMPLATE_DETAILS_ERROR,
    actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_TEMPLATE_DETAILS,
    actionIndex.LOAD_LINKED_CUSTOMERS_SUCCESS,
    actionIndex.LOAD_LINKED_CUSTOMERS_ERROR,
    actionIndex.CANCEL_LOAD_LINKED_CUSTOMERS,
    // actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS_ERROR,
    // actionIndex.CREATE_SHIPMENT_LOAD_PULL_TRACKERS_SUCCESS,
    // actionIndex.CREATE_SHIPMENT_CANCEL_LOAD_PULL_TRACKERS,
    actionIndex.FORGOT_PASSWORD_SUCCESS,
    actionIndex.FORGOT_PASSWORD_ERROR,
    actionIndex.CANCEL_FORGOT_PASSWORD,
    actionIndex.CHANGE_PASSWORD_ERROR,
    actionIndex.CANCEL_CHANGE_PASSWORD,
    actionIndex.CLOSE_SHIPMENT_SUCCESS,
    actionIndex.CLOSE_SHIPMENT_ERROR,
    actionIndex.LOAD_SHIPMENTS_BYID_SUCCESS,
    actionIndex.LOAD_SHIPMENTS_BYID_ERROR,
    actionIndex.CANCEL_LOAD_SHIPMENTS_BYID,
    actionIndex.IMPORT_LOCATIONS_SUCCESS,
    actionIndex.IMPORT_LOCATIONS_ERROR,
    actionIndex.SAVE_IMPORT_LOCATIONS_SUCCESS,
    actionIndex.SAVE_IMPORT_LOCATIONS_ERROR,
    actionIndex.CANCEL_CREATE_LOCATION,
    actionIndex.LOAD_SHIPMENT_BY_SERIAL_SUCCESS,
    actionIndex.LOAD_SHIPMENT_BY_SERIAL_ERROR,
    actionIndex.CANCEL_LOAD_SHIPMENT_BY_SERIAL,
    actionIndex.LOAD_SAVED_ROUTE_SUCCESS,
    actionIndex.LOAD_SAVED_ROUTE_ERROR,
    actionIndex.CANCEL_LOAD_SAVED_ROUTE,
    actionIndex.SAVE_ROUTE_SUCCESS,
    actionIndex.SAVE_ROUTE_ERROR,
    actionIndex.LOAD_COMPARE_TRAXX_COMPLETE,
    actionIndex.LOAD_SAVED_ROUTE_DEVIATION_SUCCESS,
    actionIndex.LOAD_SAVED_ROUTE_DEVIATION_ERROR,
    actionIndex.GET_SAVED_ROUTE_DEVIATION_SUCCESS,
    actionIndex.GET_SAVED_ROUTE_DEVIATION_ERROR,
    actionIndex.LOAD_USER_SETTINGS_SUCCESS,
    actionIndex.LOAD_USER_SETTINGS_ERROR,
    actionIndex.LOAD_USER_SETTINGS_SUCCESS_WITH_STORE,
    actionIndex.LOAD_USER_SETTINGS_WITH_STORE_ERROR,
    actionIndex.CANCEL_LOAD_USER_SETTINGS,
    actionIndex.SAVE_USER_SETTINGS_SUCCESS,
    actionIndex.SAVE_USER_SETTINGS_ERROR,
    actionIndex.DELETE_ROUTE_SUCCESS,
    actionIndex.DELETE_ROUTE_ERROR,
    actionIndex.CLOSE_ALERT_SUCCESS,
    actionIndex.CLOSE_ALERT_ERROR,
    actionIndex.DELETE_SHIPMENT_SUCCESS,
    actionIndex.DELETE_SHIPMENT_ERROR,
    actionIndex.SAVE_SHUTDOWN_TRACKER_SUCCESS,
    actionIndex.SAVE_SHUTDOWN_TRACKER_ERROR,
    // actionIndex.LOAD_TRACKER_SENSORS_ERROR,
    // actionIndex.LOAD_TRACKER_SENSORS_SUCCESS,
    // actionIndex.CANCEL_LOAD_TRACKER_SENSORS
    actionIndex.UN_AUTH_CREATE_SHIPMENT_SUCCESS,
    actionIndex.UN_AUTH_CREATE_SHIPMENT_ERROR,
    actionIndex.LOAD_TRACKER_SENSORS_ERROR,
    actionIndex.LOAD_TRACKER_SENSORS_SUCCESS,
    actionIndex.CANCEL_LOAD_TRACKER_SENSORS,
    actionIndex.SET_TRACKER_SENSOR_RANGE_SUCCESS,
    actionIndex.SET_TRACKER_SENSOR_RANGE_ERROR,
    actionIndex.CANCEL_SET_TRACKER_SENSOR_RANGE,
    actionIndex.SCHEDULE_REPORT_SUCCESS,
    actionIndex.SELECT_CUSTOMER_ERROR,
    actionIndex.CANCEL_SCHEDULE_REPORT,
    actionIndex.LOAD_SCHEDULE_REPORTS_SUCCESS,
    actionIndex.LOAD_SCHEDULE_REPORTS_ERROR,
    actionIndex.CANCEL_LOAD_SCHEDULE_REPORTS,
    actionIndex.SAVE_TRACKER_NOTE_SUCCESS,
    actionIndex.SAVE_TRACKER_NOTE_ERROR,
    actionIndex.VALIDATE_LOCATION_SUCCESS,
    actionIndex.VALIDATE_LOCATION_ERROR,
    actionIndex.CANCEL_VALIDATE_LOCATION,
    actionIndex.DELETE_LOCATION_GEOFENCE_SUCCESS,
    actionIndex.DELETE_LOCATION_GEOFENCE_ERROR,
    actionIndex.CANCEL_DELETE_LOCATION_GEOFENCE,
    actionIndex.Get_LOCATION_BOUNDARY_SUCCESS,
    actionIndex.Get_LOCATION_BOUNDARY_ERROR,
    actionIndex.CANCEL_Get_LOCATION_BOUNDARY,
    actionIndex.SAVE_LOCATION_GEOFENCE_SUCCESS,
    actionIndex.SAVE_LOCATION_GEOFENCE_ERROR,
    actionIndex.CANCEL_SAVE_LOCATION_GEOFENCE,
    actionIndex.GET_LOCATION_CONTACT_SUCCESS,
    actionIndex.GET_LOCATION_CONTACT_ERROR,
    actionIndex.CANCEL_GET_LOCATION_CONTACT,
    actionIndex.SET_CONTACT_SUCCESS,
    actionIndex.SET_CONTACT_ERROR,
    actionIndex.CANCEL_SET_CONTACT,
    actionIndex.SET_LOCATION_CONTACT_SUCCESS,
    actionIndex.SET_LOCATION_CONTACT_ERROR,
    actionIndex.CANCEL_SET_LOCATION_CONTACT,
    actionIndex.DELETE_LOCATION_CONTACT_SUCCESS,
    actionIndex.DELETE_LOCATION_CONTACT_ERROR,
    actionIndex.CANCEL_DELETE_LOCATION_CONTACT,    
    actionIndex.CANCEL_LOADER,
    actionIndex.SET_SHIPMENT_TEMPLATE_SUCCESS,
    actionIndex.SET_SHIPMENT_TEMPLATE_ERROR,
    actionIndex.CANCEL_SET_SHIPMENT_TEMPLATE,
    actionIndex.DELETE_LOCATION_SUCCESS,
    actionIndex.DELETE_LOCATION_ERROR,
    actionIndex.CANCEL_DELETE_LOCATION,
    actionIndex.CANCEL_LOADER,
    actionIndex.LOAD_DASHBOARD_SUCCESS,
    actionIndex.LOAD_DASHBOARD_ERROR,
    actionIndex.CANCEL_LOAD_DASHBOARD,
    actionIndex.SET_DASHBOARD_SUCCESS,
    actionIndex.SET_DASHBOARD_ERROR,
    actionIndex.CANCEL_SET_DASHBOARD,
    actionIndex.DELETE_DASHBOARD_SUCCESS,
    actionIndex.DELETE_DASHBOARD_ERROR,
    actionIndex.CANCEL_DELETE_DASHBOARD,
    actionIndex.LOAD_CUSTOMER_DASHBOARD_SUCCESS,
    actionIndex.LOAD_CUSTOMER_DASHBOARD_ERROR,
    actionIndex.CANCEL_LOAD_CUSTOMER_DASHBOARD,
    actionIndex.SET_CUSTOMER_DASHBOARD_SUCCESS,
    actionIndex.SET_CUSTOMER_DASHBOARD_ERROR,
    actionIndex.CANCEL_SET_CUSTOMER_DASHBOARD,
    actionIndex.DELETE_CUSTOMER_DASHBOARD_SUCCESS,
    actionIndex.DELETE_CUSTOMER_DASHBOARD_ERROR,
    actionIndex.CANCEL_DELETE_CUSTOMER_DASHBOARD,
    actionIndex.LOAD_STATIC_MAP_LIBRARY_ERROR,
    actionIndex.LOAD_STATIC_MAP_LIBRARY_SUCCESS,
    actionIndex.SHARE_TRACKER_SUCCESS,
    actionIndex.SHARE_TRACKER_ERROR
];

@Injectable()
export class LoadingEffects {

    private requestQueue: any[] = [];

    
    showSpinner = createEffect(() => this.actions.pipe(
        ofType<ServerRequestAction>(...showSpinnerActions),
        delay(0),
        map((a: Action) => {
            return new LoadingActions.FetchingData(a.type);
        })
    ));


    
    hideSpinner = createEffect(() => this.actions.pipe(
        ofType<ServerRequestCompleteAction>(...hideSpinnerActions),
        delay(100),
        map((a) => new LoadingActions.FetchingDataComplete(a.type, a.callingAction))
    ));

    constructor(private actions: Actions, private store: Store<IAppState>) { }

}
