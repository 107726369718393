import { NgForm } from '@angular/forms';
import { Component, EventEmitter, Input, Output, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';

@Component({
    selector: 'app-forgot-password-form',
    templateUrl: './forgot-password-form.component.html'
})
export class ForgotPasswordFormComponent implements OnInit {
    @Input() error: string;
    @Output() submitted = new EventEmitter<string>();
    @ViewChild(NgForm) forgotPasswordForm: NgForm;
    username = '';

    constructor() { }

    ngOnInit() {
    }

    onForgotPasswordClick(event) {
        if (!this.forgotPasswordForm.invalid) {
            this.error = '';
            this.submitted.emit(this.username);
        }
    }
}

