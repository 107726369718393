import { Component, Output, EventEmitter } from '@angular/core';

@Component ({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrls: ['./language-selector.component.css']
})
export class LanguageSelectorComponent {
    @Output() localeSelected = new EventEmitter<string>();

    onLocaleSelect(locale: string) {
        this.localeSelected.emit(locale);
    }
}
