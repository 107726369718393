import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { environment } from 'environments/environment';

@Component ({
    selector: 'app-sign-out',
    templateUrl: './sign-out.component.html',
    styleUrls: ['./sign-out.component.css']
})
export class SignOutComponent implements OnInit {
    constructor(
        private authSvc: AuthService,
        private router: Router
    ) { }

    ngOnInit() {
        this.authSvc.setUserLoginState(false);
        this.authSvc.signOut();
        this.router.navigate(['signin']);
    }
}
