import { ITrackerState } from 'app-modules/core/store/models/tracker-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as trackActions from 'app-modules/core/store/actions/tracker.actions';
import * as customerActions from 'app-modules/core/store/actions/customer.actions';
import * as shipmentActions from 'app-modules/core/store/actions/shipment.actions';
import { emptyList } from '../models/list-info-state.interface';

type Actions =
    shipmentActions.CreateShipmentSuccess |
    shipmentActions.CloseShipmentSuccess |
    customerActions.SelectCustomerSuccess |
    authActions.SignOut |
    trackActions.LoadTrackers |
    trackActions.LoadTrackersSuccess |
    trackActions.LoadTrackersError |
    shipmentActions.DeleteShipmentSuccess;

export function TrackerReducer(state: ITrackerState = initialState.tracker, action: Actions): ITrackerState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.tracker;

        case actionIndex.CREATE_SHIPMENT_SUCCESS:
        // case actionIndex.CLOSE_SHIPMENT_SUCCESS:
        case actionIndex.SELECT_CUSTOMER_SUCCESS:
            return {
                ...state,
                isLoadRequired: true
            };

        case actionIndex.LOAD_TRACKERS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_TRACKERS_SUCCESS:
            return {
                ...state,
                trackerList: action.trackerList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_TRACKERS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.DELETE_SHIPMENT_SUCCESS:
            {
                const req = action.deleteReq;
                const trackersList = emptyList();
                trackersList.list = state.trackerList.list.map(list => {
                    if (list.trackerId === req.GlobalDeviceId) {
                        list = { 
                            ...list, 
                            shipmentName: null,
                            customerTrackerId: list.trackerId,
                            receiverPO: null
                        }; // remove shipment name
                    }
                    return list;
                });
                trackersList.itemCount = trackersList.list.length;

                return {
                    ...state,
                    trackerList: trackersList,
                    isLoadRequired: false,
                    errorMessage: ''
                };
            }

        default:
            return {
                ...state
            };
    }
}
