import { ImageMapService } from "app-modules/core/services/image-map.service";
import { LocationCollisionService } from "app-modules/core/services/location-collisions.service";
import { DistanceUnits, IDrawOptions, ILatLong, IMarkerOptions, LocationInfo, PolygonType } from "emr-ng-shared";

export class LocationViewModel {
    LocationMarker: IMarkerOptions;
    BoundaryDrawOptions: IDrawOptions;
    HasBoundary: boolean;
    latitude: number;
    longitude: number;
    boundaryLocs: ILatLong[];
    isZoomed: boolean = false;
    locationId: number;
  
    constructor(private loc: LocationInfo, 
      private locationCollisionSvc: LocationCollisionService, 
      units: DistanceUnits, 
      private imgSvc: ImageMapService, 
      private isInOtherFence: boolean = false,
      private isFenceColliding: boolean = false) {
      this.locationId = this.loc.LocationId;
      this.latitude = this.loc.Latitude;
      this.longitude = this.loc.Longitude;
      this.HasBoundary = this.loc.Boundary?.length > 0 || this.loc.RadiusKm > 0;
      const boundaryLocation = this.HasBoundary ? this.locationCollisionSvc.fetchLocationBoundary(this.loc, units) : [];
      this.boundaryLocs = boundaryLocation;
      this.BoundaryDrawOptions = this.getBoundaryDrawingOptions();
      this.LocationMarker = this.getLocationMarker();
    }

    private getBoundaryDrawingOptions() {
      return {
          strokeColor: this.isFenceColliding ? 'red' : 'blue',
          boundaryLocation: this.boundaryLocs,
          polygonType: PolygonType.FreeFlow
      };
    }

    private getLocationMarker() {
      const metaData = new Map<string, any>();
      metaData.set('location', this.loc);
      metaData.set('pixelOffset', { x: 0, y: 30 });
      metaData.set('defaultOffSet', { x: 0, y: 30 });
      const iconId = this.isInOtherFence ? 'yr' : 'y';
      return {      
        position: {
            latitude: this.loc.Latitude,
            longitude: this.loc.Longitude
        },
        icon: this.imgSvc.getImageURL(iconId, '', ''),
        anchor: { x: 15, y: 30 },
        draggable: false,
        title: this.loc.LocationName,
        metadata: metaData
      };
    }
  }