import { IShipmentStatusState } from 'app-modules/core/store/models/shipment-status-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as statusActions from 'app-modules/core/store/actions/shipment-status.actions';

type Actions =
    authActions.SignOut |
    statusActions.LoadShipmentStatuses |
    statusActions.LoadShipmentStatusesSuccess |
    statusActions.LoadShipmentStatusesError |
    statusActions.ReloadShipmentStatuses;

export function ShipmentStatusReducer(state: IShipmentStatusState = initialState.shipmentStatus, action: Actions): IShipmentStatusState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
        case actionIndex.RE_LOAD_SHIPMENT_STATUSES:
            return initialState.shipmentStatus;

        case actionIndex.LOAD_SHIPMENT_STATUSES:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_SHIPMENT_STATUSES_SUCCESS:
            return {
                ...state,
                shipmentStatusList: action.shipmentStatusList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_SHIPMENT_STATUSES_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
