export const preferredLanguageDesc = [
{ value:"en", text: $localize`:@@lang_english:English`},
{ value:"es", text: $localize`:@@lang_spanish:Spanish`},
{ value:"af", text: $localize`:@@lang_afrikaans:Afrikaans`},
{ value:"sq", text: $localize`:@@lang_albanian:Albanian`},
{ value:"ar", text: $localize`:@@lang_arabic:Arabic`},
{ value:"hy", text: $localize`:@@lang_armenian:Armenian ALPHA`},
{ value:"az", text: $localize`:@@lang_azerbaijani:Azerbaijani ALPHA`},
{ value:"eu", text: $localize`:@@lang_basque:Basque ALPHA`},
{ value:"be", text: $localize`:@@lang_belarusian:Belarusian`},
{ value:"bg", text: $localize`:@@lang_bulgarian:Bulgarian`},
{ value:"ca", text: $localize`:@@lang_catalan:Catalan`},
{ value:"zh-CN", text: $localize`:@@lang_chinese_simplified:Chinese (Simplified)`},
{ value:"zh-TW", text: $localize`:@@lang_chinese_traditional:Chinese (Traditional)`},
{ value:"hr", text: $localize`:@@lang_croatian:Croatian`},
{ value:"cs", text: $localize`:@@lang_czech:Czech`},
{ value:"da", text: $localize`:@@lang_danish:Danish`},
{ value:"nl", text: $localize`:@@lang_dutch:Dutch`},
{ value:"et", text: $localize`:@@lang_estonian:Estonian`},
{ value:"tl", text: $localize`:@@lang_filipino:Filipino`},
{ value:"fi", text: $localize`:@@lang_finnish:Finnish`},
{ value:"fr", text: $localize`:@@lang_french:French`},
{ value:"gl", text: $localize`:@@lang_galician:Galician`},
{ value:"ka", text: $localize`:@@lang_georgian:Georgian ALPHA`},
{ value:"de", text: $localize`:@@lang_german:German`},
{ value:"el", text: $localize`:@@lang_greek:Greek`},
{ value:"ht", text: $localize`:@@lang_haitian_Creole:Haitian Creole ALPHA`},
{ value:"iw", text: $localize`:@@lang_hebrew:Hebrew`},
{ value:"hi", text: $localize`:@@lang_hindi:Hindi`},
{ value:"hu", text: $localize`:@@lang_hungarian:Hungarian`},
{ value:"is", text: $localize`:@@lang_icelandic:Icelandic`},
{ value:"id", text: $localize`:@@lang_indonesian:Indonesian`},
{ value:"ga", text: $localize`:@@lang_irish:Irish`},
{ value:"it", text: $localize`:@@lang_italian:Italian`},
{ value:"ja", text: $localize`:@@lang_japanese:Japanese`},
{ value:"ko", text: $localize`:@@lang_korean:Korean`},
{ value:"lv", text: $localize`:@@lang_latvian:Latvian`},
{ value:"lt", text: $localize`:@@lang_lithuanian:Lithuanian`},
{ value:"mk", text: $localize`:@@lang_macedonian:Macedonian`},
{ value:"ms", text: $localize`:@@lang_malay:Malay`},
{ value:"mt", text: $localize`:@@lang_maltese:Maltese`},
{ value:"no", text: $localize`:@@lang_norwegian:Norwegian`},
{ value:"fa", text: $localize`:@@lang_persian:Persian`},
{ value:"pl", text: $localize`:@@lang_polish:Polish`},
{ value:"pt", text: $localize`:@@lang_portuguese:Portuguese`},
{ value:"ro", text: $localize`:@@lang_romanian:Romanian`},
{ value:"ru", text: $localize`:@@lang_russian:Russian`},
{ value:"sr", text: $localize`:@@lang_serbian:Serbian`},
{ value:"sk", text: $localize`:@@lang_slovak:Slovak`},
{ value:"sl", text: $localize`:@@lang_slovenian:Slovenian`},
{ value:"sw", text: $localize`:@@lang_swahili:Swahili`},
{ value:"sv", text: $localize`:@@lang_swedish:Swedish`},
{ value:"th", text: $localize`:@@lang_thai:Thai`},
{ value:"tr", text: $localize`:@@lang_turkish:Turkish`},
{ value:"uk", text: $localize`:@@lang_:ukrainian`},
{ value:"ur", text: $localize`:@@lang_urdu:Urdu ALPHA`},
{ value:"vi", text: $localize`:@@lang_vietnamese:Vietnamese`},
{ value:"cy", text: $localize`:@@lang_welsh:Welsh`},
{ value:"yi", text: $localize`:@@lang_yiddish:Yiddish`},
]