import { Action } from '@ngrx/store';

import { TripStartOptions } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTripStartOptions implements Action {
    readonly type = actionIndex.LOAD_TRIP_START_OPTIONS;
}

export class LoadTripStartOptionsSuccess implements Action {
    readonly type = actionIndex.LOAD_TRIP_START_OPTIONS_SUCCESS;
    constructor(public tripStartOptionsList: IListInfo<TripStartOptions>) { }
}

export class LoadTripStartOptionsError implements Action {
    readonly type = actionIndex.LOAD_TRIP_START_OPTIONS_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadTripStartOptions implements Action {
    readonly type = actionIndex.CANCEL_LOAD_TRIP_START_OPTIONS;
}

