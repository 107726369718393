import { Action } from '@ngrx/store';

import { CreateShipmentRequest, TripTemplateInfo } from 'emr-ng-shared';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class ReloadShipmentTemplates implements ServerRequestAction {
    readonly type = actionIndex.RELOAD_SHIPMENT_TEMPLATES;
}

export class LoadShipmentTemplates implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SHIPMENT_TEMPLATES;
    readonly callingAction = actionIndex.CREATE_SHIPMENT_LOAD_SHIPMENT_TEMPLATES;
}

export class LoadShipmentTemplatesSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_TEMPLATES_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_TEMPLATES;

    constructor(public shipmentTemplateList: IListInfo<TripTemplateInfo>) { }
}

export class LoadShipmentTemplatesError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_TEMPLATES_ERROR;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_TEMPLATES;

    constructor(public message: string) { }
}

export class CancelLoadShipmentTemplates implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_SHIPMENT_TEMPLATES;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_TEMPLATES;
}

export class CreateTripTemplateSuccess implements Action {
    readonly type = actionIndex.CREATE_TRIP_TEMPLATE_SUCCESS;

    constructor(public ShipmentTemplate: TripTemplateInfo) { }
}

export class SetShipmentTemplate implements ServerRequestAction {
    readonly type = actionIndex.SET_SHIPMENT_TEMPLATE;
}

export class SetShipmentTemplateSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_SHIPMENT_TEMPLATE_SUCCESS;
    readonly callingAction = actionIndex.SET_SHIPMENT_TEMPLATE;
    constructor(public request: CreateShipmentRequest, public isDelete: boolean) { }
}

export class SetShipmentTemplateError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SET_SHIPMENT_TEMPLATE_ERROR;
    readonly callingAction = actionIndex.SET_SHIPMENT_TEMPLATE;
}

export class CancelSetShipmentTemplate implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_SET_SHIPMENT_TEMPLATE;
    readonly callingAction = actionIndex.SET_SHIPMENT_TEMPLATE;
}
