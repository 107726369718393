import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomTableColumnDefs } from 'emr-ng-shared';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
  selector: 'app-col-selection-popup',
  templateUrl: './col-selection-popup.component.html',
  styleUrls: ['./col-selection-popup.component.css']
})
export class ColSelectionPopupComponent implements OnInit {

  @Input() columnDefs: CustomTableColumnDefs[];
  @Input() modalRef: BsModalRef;
  @Output() ColumnSelectionChanged = new EventEmitter<string[]>();

  columnCheckboxes: CustomTableColumnDefs[];
  completedTripColumnCheckboxes: CustomTableColumnDefs[];
  temperatureColumnCheckboxes: CustomTableColumnDefs[];
  humidityColumnCheckboxes: CustomTableColumnDefs[];
  probeColumnCheckboxes: CustomTableColumnDefs[];
  carbonColumnCheckboxes: CustomTableColumnDefs[];
  fuelLevelColumnCheckboxes: CustomTableColumnDefs[];
  selectedColumns: string[];
  selectedCompletedTripColumns: string[];
  selectedHumidityTripColumns: string[];
  selectedProbeTripColumns: string[];
  selectedFuelLevelTripColumns: string[];
  selectedCarbonTripColumns: string[];
  selectedTempTripColumns: string[];

  constructor() { }

  ngOnInit(): void {
    this.selectedColumns = [];
    this.selectedCompletedTripColumns = [];
    this.selectedHumidityTripColumns = [];
    this.selectedProbeTripColumns = [];
    this.selectedFuelLevelTripColumns = [];
    this.selectedCarbonTripColumns = [];
    this.selectedTempTripColumns = [];
    this.columnCheckboxes = [];
    this.completedTripColumnCheckboxes = [];
    this.temperatureColumnCheckboxes = [];
    this.humidityColumnCheckboxes = [];
    this.probeColumnCheckboxes = [];
    this.carbonColumnCheckboxes = [];
    this.fuelLevelColumnCheckboxes = [];
    if (this.columnDefs && this.columnDefs.length > 0) {
      this.columnDefs = _.sortBy(this.columnDefs, ['columnSelectionOrder']);
      this.columnDefs.map(k => {
        if (k.isVisible && k.title) {
          if (k.groupCategory === 1) {
            this.selectedCompletedTripColumns.push(k.title);
          } else if (k.groupCategory === 2) {
            this.selectedTempTripColumns.push(k.title);
          } else if (k.groupCategory === 3) {
            this.selectedHumidityTripColumns.push(k.title);
          } else if (k.groupCategory === 4) {
            this.selectedCarbonTripColumns.push(k.title);
          } else if (k.groupCategory === 5) {
            this.selectedProbeTripColumns.push(k.title);
          } else if (k.groupCategory === 6) {
            this.selectedFuelLevelTripColumns.push(k.title);
          } else {
            this.selectedColumns.push(k.title);
          }
        }
        if (k.title) {
          if (k.groupCategory === 1) {
            this.completedTripColumnCheckboxes.push(k);
          }
          else if (k.groupCategory === 2) {
            this.temperatureColumnCheckboxes.push(k);
          }
          else if (k.groupCategory === 3) {
            this.humidityColumnCheckboxes.push(k);
          }
          else if (k.groupCategory === 4) {
            this.carbonColumnCheckboxes.push(k);
          }
          else if (k.groupCategory === 5) {
            this.probeColumnCheckboxes.push(k);
          }
          else if (k.groupCategory === 6) {
            this.fuelLevelColumnCheckboxes.push(k);
          } else {
            this.columnCheckboxes.push(k);
          }
        }
      });
    }

  }

  updateColumnsSelection(setChecked: boolean) {
    const selectedCols = [];
    const selectedCompletedTripCols = [];
    if (setChecked) {
      this.columnDefs.map(col => {
        if (col.title) {
          if (col.groupCategory === 1 || col.groupCategory === 2 || col.groupCategory === 3 || col.groupCategory === 4 || col.groupCategory === 5 || col.groupCategory === 6) {
            selectedCompletedTripCols.push(col.title);
          } else {
            selectedCols.push(col.title);
          }
        }
      });
    }
    this.selectedColumns = selectedCols;
    this.selectedCompletedTripColumns = selectedCompletedTripCols;
  }

  onColumnChangeAccept() {
    this.ColumnSelectionChanged.emit([...this.selectedColumns, ...this.selectedCompletedTripColumns, ...this.selectedTempTripColumns,
    ...this.selectedHumidityTripColumns, ...this.selectedCarbonTripColumns, ...this.selectedProbeTripColumns, ...this.selectedFuelLevelTripColumns]);
    this.modalRef.hide();
  }

}
