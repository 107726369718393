import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmModalComponent, ConfirmPopupEventArgs } from 'app-modules/core/components/confirm-modal/confirm-modal.component';
import { ContactComponent } from 'app-modules/core/components/contact/contact.component';
import { ErrorMessageResponseComponent, EventArgs } from 'app-modules/core/components/error-message-response/error-message-response.component';
import { label_location_Contact, label_un_assign_location_contact, label_un_assign_message, label_yes, link_cancel } from 'app-modules/core/consts/localization';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { LocationService } from 'app-modules/core/services/location.service';
import { ContactList, ContactService } from 'app-modules/core/store/contacts/contact.service';
import { Contact, LocationInfo, SaveLocationContactRequest, SaveLocationContactResponse, SetContactRequest } from 'emr-ng-shared';
import { ltLocale } from 'ngx-bootstrap/chronos';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

export class ContactsList {
  get text() {
    return ((this.contact.FullName ? this.contact.FullName + ' - ' : '') + this.contact.EmailAddress);
  }
  get value() { return this.contact.ContactId; }
  constructor(private contact: Contact) { }
}


@Component({
  selector: 'app-location-contact',
  templateUrl: './location-contact.component.html',
  styleUrls: ['./location-contact.component.css']
})
export class LocationContactComponent implements OnInit {

  @ViewChild(NgForm, { static: true }) frmLocationContact: NgForm;
  public isFormSubmitted = false;

  contacts: Contact[];
  locationID: number;
  location: LocationInfo;
  request = new SaveLocationContactRequest();
  contactsList$: Observable<ContactsList[]>;
  saveLocationContactSub: Subscription;
  deleteLocContactSub: Subscription;
  locationListSub: Subscription;
  constructor(
    public contactSvc: ContactService,
    private locationSvc: LocationService,
    public bsModalRef: BsModalRef,
    private modalSvc: BsModalService,
    private renderer: Renderer2,
  ) {
    this.renderer.addClass(document.body, 'bg-location-contact');
   }

  ngOnInit(): void {
    this.contactsList$ = this.contactSvc.contactList$.pipe(map(n => {
      this.contacts = n.list;
      return n.list.map(item => new ContactsList(item));
    }));

    if (!this.location?.ContactLoaded) {
      this.locationSvc.getLocationContact({ LocationId: this.locationID, IncludeCopiedFrom: true })
        .pipe(take(1))
        .subscribe(n => {
          this.location.Contacts = n?.list ?? [];
        },
          e => {
          });
    }

    this.findLocation();
  }

  ngOnDestroy() {
    this.deleteLocContactSub?.unsubscribe();
    this.saveLocationContactSub?.unsubscribe();
    this.locationListSub?.unsubscribe();
    this.renderer.removeClass(document.body, 'bg-location-contact');
  }

  findLocation() {
    this.locationListSub = this.locationSvc.locationListIncludingCopy$.subscribe(n => {
      this.location = n?.list.filter(l => l.LocationId == this.locationID)?.[0];
    });
  }

  OnAssignContactClick() {
    if (this.frmLocationContact.valid && this.location?.LocationId) {
      this.request.LocationId = this.location?.LocationId
      let contact = this.contacts?.filter(k => k.ContactId === this.request.ContactId);
      if (contact?.length === 1) {
        this.saveLocationContactSub = this.locationSvc.saveLocationContact(this.request, contact[0]).subscribe(
          n => {
            if (n.ErrorCode !== 0) {
              this.openErrorResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage,
                n.ErrorCode == 0 ? true : false, label_location_Contact);
            } else {
              this.request.ContactId = null;
            }
          },
          (e: IErrorInfo) => this.openErrorResponse(true, e.message, false, label_location_Contact)
        );
      }
    }
  }

  openErrorResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null) {
    const bsModalRef = this.modalSvc.show(
      ErrorMessageResponseComponent,
      {
        initialState: {
          hasError,
          errorMessage,
          closeOnOk,
          debugErrorMessage,
          sucessMessage: !hasError ? errorMessage : null,
          headerTitle: headerTitle
        },
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onResponseOk(n));
  }


  onResponseOk(args: EventArgs) {
    args.modalRef.hide();

    if (!args.hasError) {
      if (args.closeOnOk && this.bsModalRef) {
        this.bsModalRef.hide();
      } else {
        // Do Nothing
      }
    }
  }

  onEditContactClick(contact: Contact) {
    this.openAddContactClick(true, contact);
  }

  onDeleteContactClick(contact: Contact) {
    const bsDeleteModalRef = this.modalSvc.show(
      ConfirmModalComponent,
      {
        initialState: {
          headerTitle: label_un_assign_location_contact,
          showCloseButton: true,
          confirmMessage: label_un_assign_message,
          yesText: label_yes,
          noText: link_cancel,
        },
        class: 'modal-md modal-dialog-centered max-width-350 report-max-height',
        ignoreBackdropClick: true
      }
    );

    bsDeleteModalRef.content.okCancelClick.pipe(take(1)).subscribe(n => this.onOKClick(n, contact));
  }

  onOKClick(args: ConfirmPopupEventArgs, contact: Contact) {
    setTimeout(() => {
      switch (args.button) {
        case "Yes":
          this.deleteContact(contact);
          break;
        case "No":
          // Do Nothing
          break;
      }
    }, 30);
    args.modalRef.hide();
  }

  deleteContact(contact: Contact) {
    let deleterequest = new SaveLocationContactRequest();
    deleterequest.ContactId = contact.ContactId;
    deleterequest.LocationId = this.location?.LocationId
    if (deleterequest.ContactId && deleterequest.LocationId) {
      this.deleteLocContactSub = this.locationSvc.deleteLocationContact(deleterequest).subscribe(
        n => {
          if (n.ErrorCode !== 0) {
            this.openErrorResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage,
              n.ErrorCode == 0 ? true : false, label_un_assign_location_contact);
          }
        },
        (e: IErrorInfo) => this.openErrorResponse(true, e.message, false, label_un_assign_location_contact)
      );
    }
  }

  openAddContactClick(isEditContact: boolean = false, contact: Contact = null) {
    let request = new SetContactRequest();
    request.IsLocationContact = true;
    if (isEditContact) {
      request = this.contactSvc.getContactRequest(contact);
      request.LocationID = this.locationID;
    }
    
    request.IsLocationContact = true;
    request.LocationName = this.location?.LocationName

    const bsContactModalRef = this.modalSvc.show(
      ContactComponent,
      {
        initialState: {
          contact: request
        },
        class: 'modal-md modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    bsContactModalRef.content.saveContact?.pipe(take(1)).subscribe(n => this.onAddContact(n, bsContactModalRef, isEditContact));
  }

  onAddContact(request: SetContactRequest, bsContactModalRef: BsModalRef, isEditContact: boolean = false) {
    if (isEditContact) {
      let contact = this.contactSvc.getContactInfo(request);
      this.locationSvc.updateLocationContact(this.locationID, contact);
    } else {
      setTimeout(() => {
        this.request.ContactId = request?.ContactId;
      }, 50);
    }
    bsContactModalRef?.hide();
  }

}
