import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ShipmentRoutePopupComponent } from 'app-modules/shipment-route/components/shipment-route-popup/shipment-route-popup.component';
import { ShipmentRoutePopup } from 'app-modules/core/models/shipment-route-popup.model';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { GetRouteDeviationResponseModel } from 'emr-ng-shared';

@Injectable()
export class ShipmentRouteService extends ShipmentRoutePopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService,
        private detailSvc: ShipmentDetailService) {
        super();
    }

    OpenShipmentRoutePopup(shipment?: Shipment): void {
        // const initialState = {
        //     serialNumber: shipment
        // };
        // this.detailSvc.selectShipment(shipment);
        const initialState = { Shipment: shipment };
        this.bsModalRef = this.modalSvc.show(
            ShipmentRoutePopupComponent,
            {
                // initialState: initialState,
                class: 'max-width-1300 modal-dialog-centered',
                ignoreBackdropClick: true,
                initialState
            }
        );
    }

    OpenShipmentRouteDeviationPopup(getRouteDeviationResponse: GetRouteDeviationResponseModel): void {
        const initialState = {
            getRouteDeviationResponse,
            isItFromRouteDeviation: true,
        };
        this.bsModalRef = this.modalSvc.show(
            ShipmentRoutePopupComponent,
            {
                initialState,
                class: 'max-width-1300 modal-dialog-centered',
                ignoreBackdropClick: true,
            }
        );
    }
}

