import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { shipmentName, serialNumber, temperatureRange, destination, origin, carrier, lastReportedTemp, shipmentStatus } from 'app-modules/core/consts/localization';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { ShipmentService } from 'app-modules/core/services/shipment.service';
import { getDefaultShipmentFilter, IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { CustomTableColumnDefs, loadColumnDef, TimePeriod, TripState } from 'emr-ng-shared';
import { environment } from 'environments/environment';

import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AggregateType } from '../custom-table/custom-table.component';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.css']
})
export class DocumentTableComponent implements OnInit, OnDestroy {
  public shipmentList$: Observable<Shipment[]>;
  isDashboardShipmentsLoading$: Observable<Boolean>;
  public shipmentList: Shipment[];
  public dataDivHeight: string;
  columnDefs: CustomTableColumnDefs[];
  public sort: any;
  tripState = TripState;
  preferenceSubscription: Subscription;
  aboveTemperatureRange: number;
  belowTemperatureRange: number;
  tempUnits = '';
  aggregateCount: any;
  aggregateAvg: any;
  startDate: any;
  endDate: any;
  selectedMonth: any = "";
  public placement: string = 'bottom';
  public containerValue: string = 'body';
  public placeholder: string = 'select Month';
  public dateTimeFormat: string = "MMMM, YYYY";
  public disableOtherMonths: boolean = false;
  constructor(
    public shpSvc: ShipmentService,
    public prefSvc: PreferenceService
  ) {
  }
  @ViewChild('tripStatus', { static: true }) tripStatus;
  @ViewChild('aggregateTemperature', { static: true }) aggregateTemperature;

  ngOnInit() {
    this.aggregateCount = AggregateType.COUNT;
    this.aggregateAvg = AggregateType.AVG;
    this.dataDivHeight = `calc(100vh - ${200}px)`;
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    this.setDateRange(y, m - 1);
    this.bindDashboard();
  }

  bindDashboard() {
    this.columnDefs = JSON.parse(localStorage.getItem(environment.columnDefs));
    let filter = {
      ...getDefaultShipmentFilter(), period: {
        timePeriod: TimePeriod.SpecificRange, dateRange: {
          startDateTime: this.startDate,
          endDateTime: this.endDate
        }
      }, status: [TripState.Completed]
    };
    this.shipmentList$ = this.shpSvc.getDashboardShipmentListLoader(filter as IShipmentFilterState).pipe(map(n => {
      this.shipmentList = n.list;
      return n.list;
    }));
    this.isDashboardShipmentsLoading$ = this.shpSvc.isDashboardShipmentsLoading$;
    this.preferenceSubscription = this.prefSvc.preference$.subscribe(n => {
      this.tempUnits = n.temperatureUnitString;
      if (this.tempUnits.toUpperCase().includes('C')) {
        this.aboveTemperatureRange = environment.temperatureAboveCentiRange;
        this.belowTemperatureRange = environment.temperatureBelowCentiRange;
      } else {
        this.aboveTemperatureRange = environment.temperatureAboveForenRange;
        this.belowTemperatureRange = environment.temperatureBelowForenRange;
      }

    });
    this.loadColumnDefinition();
  }

  ngOnDestroy() {
    if (this.preferenceSubscription) {
      this.preferenceSubscription.unsubscribe();
    }
  }

  loadColumnDefinition() {
    const list: CustomTableColumnDefs[] = [
      loadColumnDef({
        title: origin, value: 'origin', isFilter: true, valueField: 'originId',
        sortableColumn: 'origin', columnOrder: 1, groupOrder: 1, width: '15%', align: 'left'
      }),
      loadColumnDef({
        title: destination, value: 'destination', isFilter: true, valueField: 'destinationId',
        columnOrder: 2, width: '15%', sortableColumn: 'destination', groupOrder: 2, nullValue: '', align: 'left'
      }),
      loadColumnDef({ title: carrier, value: 'carrierName', isFilter: true, groupOrder: 3, sortableColumn: 'carrierName', valueField: 'carrierName', columnOrder: 3, width: '13%', nullValue: '', align: 'left' }),
      loadColumnDef({
        title: 'No of Shipments', value: 'tripName', isFilter: false, width: '13%', sortableColumn: 'tripName', valueField: 'tripName', columnOrder: 4,
        shouldAggregate: true, aggregateType: AggregateType.COUNT, aggregateUnit: '', isSortable: false, nullValue: '', align: 'center'
      }),
      loadColumnDef({
        title: 'Average Temperature', value: 'temperature', width: '15%', sortableColumn: 'temperature', isFilter: false, columnOrder: 5, nullValue: '',
        shouldAggregate: true, aggregateType: AggregateType.AVG, aggregateUnit: '', customHTML: this.aggregateTemperature, isCustomAggregateHtml: true, isSortable: false, align: 'right'
      }),
      loadColumnDef({
        title: 'Average High Alerts', value: 'HighCriticalAlertCount', width: '14%', sortableColumn: 'HighCriticalAlertCount', isFilter: false, columnOrder: 6,
        shouldAggregate: true, aggregateType: AggregateType.AVG, aggregateUnit: '', isSortable: false, nullValue: '', align: 'right'
      }),
      loadColumnDef({
        title: 'Average Low Alerts', value: 'LowCriticalAlertCount', width: '15%', sortableColumn: 'LowCriticalAlertCount', isFilter: false, columnOrder: 7,
        shouldAggregate: true, aggregateType: AggregateType.AVG, aggregateUnit: '', isSortable: false, nullValue: '', align: 'right'
      })
    ];
    this.columnDefs = list;
  }

  onFilterApply(column: CustomTableColumnDefs) {
  }

  onColumnChange(data) {
    this.columnDefs = data.columns;
    if (this.columnDefs && this.columnDefs.length > 0) {
      if (data.isColumnChange) {
        const colData = JSON.stringify(data.columns.map(k => {
          return { value: k.value, columnOrder: k.columnOrder, isVisible: k.isVisible };
        }));
      }
    }
  }

  onRowClick(event) {

  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any): void => {
      const date = new Date(event.date), y = date.getFullYear(), m = date.getMonth();
      this.setDateRange(y, m);
      this.bindDashboard();
      container._store.dispatch(container._actions.select(event.date));
    };
    container.setViewMode('month');
  }

  setDateRange(year, month) {
    this.selectedMonth = new Date(year, month);
    this.startDate = new Date(year, month, 1);
    this.endDate = new Date(year, month + 1, 0);
  }
}