import { Component, Input, OnInit } from '@angular/core';
import { AggregateType } from 'app-modules/dashboard/components/custom-table/custom-table.component';
import * as Highcharts from 'highcharts';
import * as _ from 'lodash';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {

  constructor() { }
  Highcharts: typeof Highcharts = Highcharts;
  shipmentsCount: number = 0;
  aboveRange = "Above Temperature Range";
  belowRange = "Below Temperature Range";
  inRange = "In Temperature Range";

  @Input() title = '';
  @Input() subTitle = '';
  @Input() seriesName = '';
  @Input() aggregateType: AggregateType;
  @Input() aboveTemperatureRange = 0;
  @Input() belowTemperatureRange = 0;
  @Input() property = '';
  @Input()
  set chartData(shipments) {
    let data = _.cloneDeep(this.defaultRange);
    if (shipments && shipments.length > 0) {
      this.shipmentsCount = shipments.length;
      let propType = typeof shipments[0][this.property];
      let listed = [];
      listed = shipments.map(a => { return a[this.property] ? a[this.property] : 0 });
      if (propType != 'number') {
        listed = listed.map(x => Number(x.toString().replaceAll(/[^0-9&&^.&&^-]/g, "")));
      }
      let object;
      listed.forEach(value => {
        if (value > this.aboveTemperatureRange) {
          object = data.filter(x => x.name === this.aboveRange)[0];
          object.y = object.y + 1;
        } else if (value < this.belowTemperatureRange) {
          object = data.filter(x => x.name === this.belowRange)[0];
          object.y = object.y + 1;
        } else if (value >= this.belowTemperatureRange && value <= this.aboveTemperatureRange) {
          object = data.filter(x => x.name === this.inRange)[0];
          object.y = object.y + 1;
        }
      });
      this.bindPieChart(data);
    } else {
      this.shipmentsCount = 0;
      this.bindPieChart(data);
    }
  };

  defaultRange = [{
    name: this.aboveRange,
    y: 0,
    color: 'rgb(255, 146, 146)'
  }, {
    name: this.belowRange,
    y: 0,
    color: 'rgb(155, 252, 255)'
  }, {
    name: this.inRange,
    y: 0,
    color: 'rgb(164, 255, 184)'
  }];

  pieChartOptions = {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  }

  pieChartSeriesOptions = {
    name: '',
    colorByPoint: true,
    data: []
  }

  chartOptions = {
    chart: {},
    title: {
      text: ''
    },
    subtitle: {
      text: '',
    },
    credits: {
      enabled: false
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.y} ( {point.percentage:.1f}% )</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} ( {point.percentage:.1f} % )'
        }
      }
    },
    series: []
  }

  ngOnInit(): void {
    this.bindPieChart('');
  }

  bindPieChart(value) {
    let options = { ...this.chartOptions };
    options.title.text = this.title;
    options.subtitle.text = this.subTitle + ' ' + this.shipmentsCount;
    options.chart = { ...this.pieChartOptions };
    let seriesOption = { ...this.pieChartSeriesOptions };
    seriesOption.name = this.seriesName;
    seriesOption.data = value;

    let series: any[] = [];
    series.push({ ...seriesOption });
    options.series = series;

    this.chartOptions = options;
  }

}
