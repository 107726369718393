import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as unAuthActions from 'app-modules/core/store/un-auth/un-auth.actions';

type Actions =
    authActions.SignIn |
    authActions.SignInSuccess |
    authActions.SignInError |
    authActions.SignOut |
    authActions.RefreshToken |
    unAuthActions.SaveUnAuthTrackers;

export function AuthReducer(state: IAuthState = initialState.auth, action: Actions): IAuthState {
    switch (action.type) {
        case (actionIndex.SIGN_OUT):
            return {
                authenticated: false,
                authToken: null,
                refreshToken: null,
                username: null,
                displayName: null,
                rememberMe: false,
                IsPasswordChangeRequired: false,
                isEmersonAdmin: false,
                isDashboardUser: false,
                canCreateShipment: false,
                providerUserKey: null,
                canAccessOversight: false,
                canAccessPakSense: false,
                canEditTripName: false,
                isLocusUserSupportAdmin: false,
                isLocusUser: false,
                isAdminUser: false,
                isEDIAPIConsumer: false,
                isLocusSupport: false,
                error: null
            };

        case (actionIndex.SIGN_IN):
            return {
                ...state,
                rememberMe: false,
                error: null
            };

        case (actionIndex.SIGN_IN_SUCCESS):
            return {
                ...state,
                authenticated: true,
                authToken: action.authToken,
                refreshToken: action.refreshToken,
                username: action.username,
                displayName: action.displayName,
                isEmersonAdmin: action.isEmersonAdmin,
                isDashboardUser: action.isDashboardUser,
                canCreateShipment: action.canCreateShipment,
                rememberMe: action.rememberMe,
                providerUserKey: action.providerUserKey,
                IsPasswordChangeRequired: action.IsPasswordChangeRequired,
                canAccessOversight: action.canAccessOversight,
                canAccessPakSense: action.canAccessPakSense,
                canEditTripName: action.canEditTripName,
                isLocusUserSupportAdmin: action.isLocusUserSupportAdmin,
                isLocusUser: action.isLocusUser,
                isAdminUser: action.isAdminUser,
                isEDIAPIConsumer: action.isEDIAPIConsumer,
                isLocusSupport: action.isLocusSupport,
                error: null
            };

        case (actionIndex.SIGN_IN_ERROR):
            return {
                ...state,
                error: action.error
            };

        case (actionIndex.REFRESH_TOKEN):
            return {
                ...state,
                authToken: action.authToken,
            };
        case (actionIndex.SAVE_UN_AUTH_TRACKERS):
            return {
                ...state,
                authenticated: false,
            };
        default:
            return state;
    }
}
