import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'nearest-locations',
    templateUrl: './nearest-locations.component.html',
    styleUrls: ['./nearest-locations.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class NearestLocationsComponent implements OnInit, OnDestroy {
    @Input() nearestLocations: any;
    @Input() isFromImportLocations = false;
    @Input() CurrentLocation: any;
    @Output() continueClick = new EventEmitter<boolean>();
    selectedIndex: number;

    ngOnInit(): void {

    }
    ngOnDestroy(): void {

    }

    onContinueClick() {
        this.continueClick.emit(this.selectedIndex === 0)
    }
    onLocationSelect(i: number) {
        if (this.selectedIndex === i) {
            this.selectedIndex = null;
        } else {
            this.selectedIndex = i
        }
    }
}
