import { Action } from '@ngrx/store';

import { DualVisibilityLinkCustomer, ValidateDVShipmentRequest, ValidateDVShipmentResponse } from 'emr-ng-shared';

import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';

import * as actionIndex from '../actions/index';

export class SelectLinkedCustomer implements Action {
    readonly type = actionIndex.CREATE_SHIPMENT_SELECT_LINKED_CUSTOMER;

    constructor(public linkedCustomer: DualVisibilityLinkCustomer) { }
}

export class ValidateDVShipment implements ServerRequestAction {
    readonly type = actionIndex.VALIDATE_SHIPMENT;

    constructor(public request: ValidateDVShipmentRequest) { }
}

export class ValidateDVShipmentSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.VALIDATE_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.VALIDATE_SHIPMENT;

    constructor(public response: ValidateDVShipmentResponse) { }
}

export class CancelValidateDVShipment implements ServerRequestCompleteAction {
    readonly callingAction = actionIndex.VALIDATE_SHIPMENT;
    readonly type = actionIndex.CANCEL_VALIDATE_SHIPMENT;
}

export class ValidateDVShipmentError implements ServerRequestCompleteAction {
    readonly type = actionIndex.VALIDATE_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.VALIDATE_SHIPMENT;

    constructor(public message: string) { }
}

// Tracker Sensors

export class LoadTrackerSensors implements ServerRequestAction {
    readonly type = actionIndex.LOAD_TRACKER_SENSORS;
}

export class LoadTrackerSensorsSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_TRACKER_SENSORS_SUCCESS;
    readonly callingAction = actionIndex.LOAD_TRACKER_SENSORS;
}

export class LoadTrackerSensorsError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_TRACKER_SENSORS_ERROR;
    readonly callingAction = actionIndex.LOAD_TRACKER_SENSORS;
}

export class CancelLoadTrackerSensors implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_TRACKER_SENSORS;
    readonly callingAction = actionIndex.LOAD_TRACKER_SENSORS;
}

// End Tracker Sensors

