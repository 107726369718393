import { TimelineInfo } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTimeline implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TIMELINE;
}

export class LoadTimelineSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TIMELINE_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TIMELINE;
    constructor(public timelineList: IListInfo<TimelineInfo>) { }
}

export class LoadTimelineError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_TIMELINE_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TIMELINE;

    constructor(public message: string) { }
}

export class CancelLoadTimeline implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_TIMELINE;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_TIMELINE;
}

