import { ITimePeriodState } from '../models/time-period-state.interface';

import { initialState } from './initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as shipmentDetailPeriodActions from 'app-modules/core/store/actions/shipment-detail-time-period.actions';
import * as shipmentDetailActions from 'app-modules/core/store/actions/shipment-detail.actions';

type Actions =
    authActions.SignOut |
    shipmentDetailPeriodActions.ShipmentDetailLoadTimePeriods |
    shipmentDetailPeriodActions.ShipmentDetailLoadTimePeriodsSuccess |
    shipmentDetailPeriodActions.ShipmentDetailLoadTimePeriodsError |
    shipmentDetailActions.SelectShipment;

export function ShipmentDetailTimePeriodReducer(
    state: ITimePeriodState = initialState.shipmentDetailTimePeriod,
    action: Actions
): ITimePeriodState {
    switch (action.type) {
        case actionIndex.SIGN_OUT:
            return initialState.shipmentDetailTimePeriod;

        case actionIndex.SHIPMENT_DETAIL_LOAD_TIME_PERIODS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.SHIPMENT_DETAIL_LOAD_TIME_PERIODS_SUCCESS:
            return {
                ...state,
                timePeriodList: action.timePeriodList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.SHIPMENT_DETAIL_LOAD_TIME_PERIODS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        case actionIndex.SHIPMENT_DETAIL_SELECT_SHIPMENT:
            return {
                ...state,
                isLoadRequired: true
            };

        default:
            return {
                ...state
            };
    }
}
