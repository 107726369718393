import { ErrorResponse } from 'emr-ng-shared';
import { IListInfo } from './list-info-state.interface';

export interface IListInfoWithErrorResponse<T> extends IListInfo<T> {
    list: T[];
    itemCount: number;
    isPaged: boolean;
    itemThreshold?: number;
    ErrorCode?: number;
    ErrorDescription?: string;
    LocalizedErrorMessage?: string;
}

export function emptyList(): IListInfoWithErrorResponse<any> {
    return { list: [], itemCount: 0, isPaged: true };
}

export function setListInfoWithErrorResponse(inputList, isPage: boolean): IListInfoWithErrorResponse<any> {
    return {
        list: inputList, itemCount: inputList ? inputList.length : 0, isPaged: isPage,
        ErrorCode: 0, ErrorDescription: null, LocalizedErrorMessage: null
    };
}
