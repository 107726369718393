import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { TimePeriodInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { ITimePeriodState } from 'app-modules/core/store/models/time-period-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as periodActions from 'app-modules/core/store/time-period/time-period.actions';

@Injectable()
export class TimePeriodStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public timePeriodStateSelector = ((state: IAppState) => state.timePeriod);

    public isLoadRequiredSelector = createSelector(
        this.timePeriodStateSelector,
        (state: ITimePeriodState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.timePeriodStateSelector,
        (state: ITimePeriodState) => state.isLoading
    );
    public timePeriodListSelector = createSelector(
        this.timePeriodStateSelector,
        (state: ITimePeriodState) => state.timePeriodList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public timePeriodList$ = this.store.select(this.timePeriodListSelector).pipe();

    // Actions
    public loadTimePeriods() {
        this.store.dispatch(new periodActions.LoadTimePeriods());
    }

    public loadTimePeriodsSuccess(timePeriodList: IListInfo<TimePeriodInfo>) {
        this.store.dispatch(new periodActions.LoadTimePeriodsSuccess(timePeriodList));
    }

    public loadTimePeriodsError(message: string) {
        this.store.dispatch(new periodActions.LoadTimePeriodsError(message));
    }

    public cancelLoadTimePeriods() {
        this.store.dispatch(new periodActions.CancelLoadTimePeriods());
    }
}


