<div class="row bg-data-div-show-log">
    <div class="col-md-12 p-2">
        <div class="row" *ngIf="(viewLogResponse$|async) as log">
            <div class="col-md-12">
                <div *ngIf="!log.ErrorDescription else logDetailsError">
                    <div *ngIf="log.Changes && log.Changes.length > 0 else logNoRecords">
                        <ul class="nav list-group log-group">
                            <ng-container *ngFor="let change of log.Changes;">
                                <ng-container [ngSwitch]="change.Type">
                                    <li class="list-group-item" *ngSwitchCase=0>
                                        <span class="header" i18n="@@log_default">Default</span> :
                                        <div><span class="content">{{change.Created.When | dateTime: 'MM/dd/yyyy
                                                hh:mm:ss
                                                a'}} by
                                                {{change.Created.Who}}</span></div>
                                    </li>
                                    <li class="list-group-item" *ngSwitchCase=1>
                                        <span class="header" i18n="@@log_created">Created</span> :
                                        <div><span class="content">{{change.Created.When | dateTime: 'MM/dd/yyyy
                                                hh:mm:ss
                                                a'}} by
                                                {{change.Created.Who}}</span></div>
                                    </li>
                                    <li class="list-group-item" *ngSwitchCase=2>
                                        <span class="header" i18n="@@log_closed">Closed</span> :
                                        <div><span class="content">{{change.Created.When | dateTime: 'MM/dd/yyyy
                                                hh:mm:ss
                                                a'}} by
                                                {{change.Created.Who}}</span></div>
                                    </li>
                                    <li class="list-group-item" *ngSwitchCase=3>
                                        <span class="header" i18n="@@log_changed_trip">Changed Trip Name</span> :
                                        <div><span class="content">{{change.Created.When | dateTime: 'MM/dd/yyyy
                                                hh:mm:ss
                                                a'}} by
                                                {{change.Created.Who}}</span><br><span
                                                class="change">{{change.ChangeText}}</span></div>
                                    </li>
                                    <li class="list-group-item" *ngSwitchCase=4>
                                        <span class="header" i18n="@@log_trip_started">Trip Started</span> :
                                        <div><span class="content">{{change.Created.When | dateTime: 'MM/dd/yyyy
                                                hh:mm:ss
                                                a'}} by
                                                {{change.Created.Who}}</span></div>
                                    </li>
                                    <li class="list-group-item" *ngSwitchCase=5>
                                        <span class="header" i18n="@@log_changed_origin">Changed Origin</span> :
                                        <div><span class="content">{{change.Created.When | dateTime: 'MM/dd/yyyy
                                                hh:mm:ss
                                                a'}} by
                                                {{change.Created.Who}}</span><br><span
                                                class="change">{{change.ChangeText}}</span></div>
                                    </li>
                                    <li class="list-group-item" *ngSwitchCase=6>
                                        <span class="header" i18n="@@log_changed_destination">Changed
                                            Destination</span> :
                                        <div><span class="content">{{change.Created.When | dateTime: 'MM/dd/yyyy
                                                hh:mm:ss
                                                a'}} by
                                                {{change.Created.Who}}</span><br><span
                                                class="change">{{change.ChangeText}}</span></div>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </div>
                    <ng-template #logNoRecords>
                        <div class="text-center mb-3">
                            No Records Found
                        </div>
                    </ng-template>
                </div>
                <ng-template #logDetailsError>
                    <div class="text-center mb-3">
                        {{log.ErrorDescription}}
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-12 text-center">
                <emr-button identifier="closeButton" cssClass="btn button-text btn button-text btn-outline-default mt-0"
                    buttonText="Close" i18n-buttonText="@@close" (onClick)="onCloseClick()">
                </emr-button>
            </div>
        </div>
    </div>
</div>