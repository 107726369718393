import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { CreateShipmentRequest, TripTemplateInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as templateActions from 'app-modules/core/store/shipment-template/shipment-template.actions';

@Injectable()
export class ShipmentTemplateStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public shipmentTemplateStateSelector = ((state: IAppState) => state.shipmentTemplate);
    public isLoadRequiredSelector = createSelector(
        this.shipmentTemplateStateSelector,
        (state) => state.isLoadRequired
    );

    public isLoadingSelector = createSelector(
        this.shipmentTemplateStateSelector,
        (state) => state.isLoading
    );

    public shipmentTemplatesSelector = createSelector(
        this.shipmentTemplateStateSelector,
        (state) => state.shipmentTemplateList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public shipmentTemplateList$ = this.store.select(this.shipmentTemplatesSelector).pipe();

    // Actions
    public reloadShipmentTemplates() {
        this.store.dispatch(new templateActions.ReloadShipmentTemplates());
    }

    public loadShipmentTemplates() {
        this.store.dispatch(new templateActions.LoadShipmentTemplates());
    }

    public loadShipmentTemplatesSuccess(listInfo: IListInfo<TripTemplateInfo>) {
        this.store.dispatch(new templateActions.LoadShipmentTemplatesSuccess(listInfo));
    }

    public loadShipmentTemplatesError(message: string) {
        this.store.dispatch(new templateActions.LoadShipmentTemplatesError(message));
    }

    public cancelLoadShipmentTemplates() {
        this.store.dispatch(new templateActions.CancelLoadShipmentTemplates());
    }

    public createTripTemplateSuccess(templateId: number, templateName: string) {
        const tripTemplate: TripTemplateInfo = {
            TripTemplateId: templateId,
            Name: templateName
        };
        this.store.dispatch(new templateActions.CreateTripTemplateSuccess(tripTemplate));
    }

    // SetScheduledReports List
    cancelSetShipmentTemplate(): void {
        this.store.dispatch(new templateActions.CancelSetShipmentTemplate());
    }
    setShipmentTemplateError(): void {
        this.store.dispatch(new templateActions.SetShipmentTemplateError());
    }
    setShipmentTemplateSuccess(request: CreateShipmentRequest, isDelete: boolean = false): void {
        this.store.dispatch(new templateActions.SetShipmentTemplateSuccess(request, isDelete));
    }
    setShipmentTemplate() {
        this.store.dispatch(new templateActions.SetShipmentTemplate());
    }
}


