import { Action } from '@ngrx/store';

import { SortOptions } from 'emr-ng-shared';

import { ServerRequestAction, ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadShipmentSortList implements ServerRequestAction {
    readonly type = actionIndex.LOAD_SHIPMENT_SORT_LIST;
}

export class LoadShipmentSortListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_SORT_LIST_SUCCESS;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_SORT_LIST;
    constructor(public sortList: IListInfo<SortOptions>) { }
}

export class LoadShipmentSortListError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_SHIPMENT_SORT_LIST_ERROR;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_SORT_LIST;
    constructor(public message: string) { }
}

export class CancelLoadShipmentSortList implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_SHIPMENT_SORT_LIST;
    readonly callingAction = actionIndex.LOAD_SHIPMENT_SORT_LIST;
}

export class ChangeShipmentSort implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_SORT;
    constructor(public selectedSort: string, public isDescending: boolean) { }
}
export class ChangeShipmentSortWithOutListUpdate implements Action {
    readonly type = actionIndex.CHANGE_SHIPMENT_SORT_WITH_OUT_LIST_UPDATE;
    constructor(public selectedSort: string, public isDescending: boolean) { }
}

