
//Date calculation
//days is the number of days to be added. Subtracting days is a negative number
export function dateAdd(date: Date | string, days: number) {
    const tempDate = new Date(date);
    tempDate.setDate(tempDate.getDate() + days);
    return tempDate;

}

//Is it a string in json format
export function isJSONString(str: string) {
    try {
        const obj = JSON.parse(str);
        return (obj && typeof obj === "object");
    } catch (e) {
        return false;
    }
}