import { Component, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ConfirmModalComponent, ConfirmPopupEventArgs } from 'app-modules/core/components/confirm-modal/confirm-modal.component';
import { ErrorMessageResponseComponent, EventArgs } from 'app-modules/core/components/error-message-response/error-message-response.component';
import { ReportSchedulerComponent } from 'app-modules/core/components/report-scheduler/report-scheduler.component';
import { label_confirm_message, label_email_report, label_schedule_report, label_yes, link_cancel } from 'app-modules/core/consts/localization';
import { reportTypeDesc } from 'app-modules/core/consts/report-type';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { ScheduleReportService } from 'app-modules/core/store/schedule-report/schedule-report.service';
import { Any, BusinessRuleType, DateTimeObject, ReportType, ScheduledReports, ScheduleReportRequest } from 'emr-ng-shared';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { ScheduleNewReportComponent } from '../schedule-new-report/schedule-new-report.component';

@Component({
  selector: 'app-scheduled-reports',
  templateUrl: './scheduled-reports.component.html',
  styleUrls: ['./scheduled-reports.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ScheduledReportsComponent implements OnInit {

  @ViewChild(NgForm, { static: true }) frmScheduleNewReport: NgForm;
  public isFormSubmitted = false;
  scheduledReportsList$: Observable<ScheduledReports[]>;
  selectedReport: ScheduledReports;
  bsModalRef: BsModalRef;
  HoursList: Any[];
  DaysList: Any[];
  dateTimeObject: DateTimeObject = new DateTimeObject();
  userPreferenceSubscription: Subscription;
  authStateSub: Subscription;
  reportListCount: number = 0;
  lastOpenedGroup = null;
  reportTypeList: any[];
  selectedReportTypeSub = new BehaviorSubject<ReportType[]>(null);
  selectedReportType$ = this.selectedReportTypeSub.asObservable();
  selectedReportType: ReportType;
  businessRuleSub: Subscription;

  constructor(
    public reportSvc: ScheduleReportService,
    private renderer: Renderer2,
    private modalSvc: BsModalService,
    public preferenceSvc: PreferenceService,
    private authSvc: AuthService,
    private businessRuleSvc: BusinessRulesService,
  ) {
    this.renderer.addClass(document.body, 'bg-scheduled-report');
  }

  ngOnInit() {
    this.reportTypeList = this.reportSvc.scheduledReportTypesList;
    this.DaysList = this.reportSvc.DaysList;
    this.userPreferenceSubscription = this.preferenceSvc.getDateTimeObject().pipe(take(1)).subscribe(k => {
      this.HoursList = this.reportSvc.GetHoursList(k?.showMeridian);
    });
    this.scheduledReportsList$ = 
      combineLatest(this.selectedReportType$, this.reportSvc.scheduledReportList$)
        .pipe(
          map(([reportTypes, listInfo]) => this.reportSvc.getFilteredReports(listInfo?.list, reportTypes)),
          tap(list => this.reportListCount = list.length)
        );

    
    this.businessRuleSub = this.businessRuleSvc.businessRulesList$.pipe(take(1)).subscribe(n => {
      
      let index = this.reportTypeList.findIndex(k => k.value === ReportType.InboundOutboundReport);
      if (n.list && n.list.length > 0) {
        if (n.list.find(k => k.BusinessRuleTypeCode === BusinessRuleType.EndLocationUndefined)?.Enabled && index <= -1) {
          this.reportTypeList.push({ text: reportTypeDesc[ReportType.InboundOutboundReport], value: ReportType.InboundOutboundReport });
        }
        else {
          if (index > -1) {
            this.reportTypeList.splice(index);
          }
        }
      }
    });

    this.authStateSub = this.authSvc.authState$.subscribe(au => {
      
      let arrayIndex = this.reportTypeList.findIndex(l => l.value === ReportType.InProgressShipment);
      if (au?.isLocusUser) {
        if (arrayIndex <= -1) {
          this.reportTypeList.push({ text: reportTypeDesc[ReportType.InProgressShipment], value: ReportType.InProgressShipment })
        }
      }
      else {
        if (arrayIndex > -1) {
          this.reportTypeList.splice(arrayIndex);
        }
      }
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'bg-scheduled-report');
    if (this.userPreferenceSubscription) {
      this.userPreferenceSubscription.unsubscribe();
    }

    if(this.authStateSub) {
      this.authStateSub.unsubscribe();
    }

    if(this.businessRuleSub) {
      this.businessRuleSub.unsubscribe();
    }
  }

  onReportTypeChange(){
    this.selectedReportTypeSub.next( this.selectedReportType ? [this.selectedReportType] : this.reportTypeList.map(t => t.value));
  }

  onRowClick(scheduledReport: ScheduledReports) {
    this.selectedReport = scheduledReport;
  }

  getScheduleReportTypeId(reportTypeId: number) {
    if (reportTypeId && !isNaN(reportTypeId)) {
      return reportTypeDesc[reportTypeId];
    } else {
      return reportTypeDesc["0"];
    }
  }

  onEditReportClick(scheduledReport: ScheduledReports) {
    if (scheduledReport) {
      const request = this.reportSvc.getScheduleReportReq(scheduledReport);
      this.bsModalRef = this.modalSvc.show(
        ScheduleNewReportComponent,
        {
          initialState: {
            selectedReport: scheduledReport.ReportType,
            sendNow: false,
            isNewReport: false,
            isEmailReport: false,
            request: request,
            headerTitle: `${ label_schedule_report} - ${reportTypeDesc[scheduledReport.ReportType]}`,
            selectedEmails: scheduledReport.SendTo
          },
          class: 'modal-md modal-dialog-centered max-width-1100 report-max-height',
          ignoreBackdropClick: true
        }
      );
    }
  }

  onDeleteReportClick(scheduledReport: ScheduledReports) {
    this.bsModalRef = this.modalSvc.show(
      ConfirmModalComponent,
      {
        initialState: {
          headerTitle: label_schedule_report,
          showCloseButton: true,
          confirmMessage: label_confirm_message,
          yesText: label_yes,
          noText: link_cancel,
        },
        class: 'modal-md modal-dialog-centered max-width-350 report-max-height',
        ignoreBackdropClick: true
      }
    );

    this.bsModalRef.content.okCancelClick.pipe(take(1)).subscribe(n => this.onOKClick(n, scheduledReport));
  }

  onOKClick(args: ConfirmPopupEventArgs, scheduledReport: ScheduledReports) {
    setTimeout(() => {
      switch (args.button) {
        case "Yes":
          this.deleteReport(scheduledReport);
          break;
        case "No":
          // Do Nothing
          break;
      }
    }, 30);
    args.modalRef.hide();
  }

  deleteReport(scheduledReport: ScheduledReports) {

    this.reportSvc.DeleteScheduleReport(scheduledReport.ScheduledReportsId).pipe(take(1)).subscribe((n) => {
      if (n) {
        this.openSubmitResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage, n.ErrorCode == 0 ? true : false, label_schedule_report);
      }
    }, (e: IErrorInfo) => {
      this.openSubmitResponse(true, e.message, false, label_schedule_report)
    });
  }

  openSubmitResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null,) {
    const bsModalRef = this.modalSvc.show(
      ErrorMessageResponseComponent,
      {
        initialState: {
          hasError,
          errorMessage,
          closeOnOk,
          debugErrorMessage,
          sucessMessage: !hasError ? errorMessage : null,
          headerTitle: headerTitle
        },
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onResponseOk(n));
  }

  onResponseOk(args: EventArgs) {
    args.modalRef.hide();

    if (!args.hasError) {
      if (args.closeOnOk && this.bsModalRef) {
        this.bsModalRef.hide();
      } else {
        // Do Nothing
      }
    }
  }

  getDays(reportDays: string) {
    return reportDays?.split(',').map(
      (n) => {
        return this.DaysList.find(d => d.value === +n)?.text;
      }).join(', ');
  }

  getTime(reportTime: string) {
    return reportTime?.split(',').map(
      (n) => {
        return this.HoursList.find(t => t.value === +n)?.text;
      }).join(', ');
  }

  reloadScheduledReports() {
    this.reportSvc.ReloadScheduledReports();
  }

  onNewReportClick(emailNow: boolean) {
    this.reValidationForm();

    if (this.frmScheduleNewReport.valid) {
      const modalCss = 'modal-md modal-dialog-centered  report-max-height ' + (emailNow ? ' max-width-900' : ' max-width-1100');
      this.bsModalRef = this.modalSvc.show(
        ScheduleNewReportComponent,
        {
          initialState: {
            selectedReport: this.selectedReportType,
            isNewReport: true,
            isEmailReport: emailNow,
            headerTitle: `${ emailNow ? label_email_report : label_schedule_report} - ${reportTypeDesc[this.selectedReportType]}`
          },
          class: modalCss,
          ignoreBackdropClick: true
        }
      );
    }
  }

  private reValidationForm = () => {
    for (const key in this.frmScheduleNewReport?.controls) {
      this.frmScheduleNewReport.controls[key].markAsDirty();
      this.frmScheduleNewReport.controls[key].updateValueAndValidity();
    }
  }

}
