import { Injectable } from '@angular/core';
import { LoginRequest } from 'emr-ng-shared';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { LOGGER_USER_VERIFICATION_URL, PAKSENSE_AUTH_URL } from '../consts/values';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LoginExternalResponse } from '../models/ps-login-external-response.model';

interface IHttpOptions {
    headers?: HttpHeaders;
}

@Injectable({ providedIn: 'root' })
export class PakSenseApiService {

    constructor(private http: HttpClient) { }

    Login(loginRequest: LoginRequest): Observable<LoginExternalResponse> {
        return this.Post<LoginExternalResponse>(`${environment.psAPI_URL}${PAKSENSE_AUTH_URL}`, loginRequest)
            .pipe(
                map((n: HttpResponse<LoginExternalResponse>) => n.body)
            );
    }

    loggerUserCheck(userName: string): Observable<boolean> {
        return this.Get<boolean>(`${environment.loggerAPI_URL}${LOGGER_USER_VERIFICATION_URL}`, userName)
            .pipe(
                map((n: any) => n?.isValid)
            );
    }

    Post<T>(
        fullAPIURL: string,
        request: any
    ): Observable<HttpResponse<T>> {
        const options = this.getRequestOptions();
        return this.http.post<HttpResponse<T>>(fullAPIURL, request, options);
    }


    Get<T>(
        fullAPIURL: string,
        param: any
    ): Observable<HttpResponse<T>> {
        return this.http.get<HttpResponse<T>>(fullAPIURL + '/' + param);
    }

    private getRequestOptions() {
        const headerOptions = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('X-LT-ApiKey', '297AE4A7-3A8B-4E54-8F51-CD7D9D19C937');

        return <IHttpOptions>{ headers: headerOptions, withCredentials: true, observe: 'response' };
    }

}
