import { Action } from '@ngrx/store';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { TimePeriodInfo } from 'emr-ng-shared';

import * as actionIndex from 'app-modules/core/store/actions';

export class LoadTimePeriods implements Action {
    readonly type = actionIndex.LOAD_TIME_PERIODS;
}

export class LoadTimePeriodsSuccess implements Action {
    readonly type = actionIndex.LOAD_TIME_PERIODS_SUCCESS;
    constructor(public timePeriodList: IListInfo<TimePeriodInfo>) { }
}

export class LoadTimePeriodsError implements Action {
    readonly type = actionIndex.LOAD_TIME_PERIODS_ERROR;
    constructor(public message: string) { }
}

export class CancelLoadTimePeriods implements Action {
    readonly type = actionIndex.CANCEL_LOAD_TIME_PERIODS;
}

