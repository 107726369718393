import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { label_distribution_center } from 'app-modules/core/consts/localization';
import { LocationService } from 'app-modules/core/services/location.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { LocationInfo, LocationTypeCode } from 'emr-ng-shared';
import { Subscription } from 'rxjs';
class SelectableLocationInfoListItem {
  checked: boolean;
  get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
  get value() { return this.locationInfo.LocationId; }
  constructor(public locationInfo: LocationInfo) { }
}
@Component({
  selector: 'app-shipment-filter-distribution-center-summary',
  templateUrl: './shipment-filter-distribution-center-summary.component.html',
  styleUrls: ['./shipment-filter-distribution-center-summary.component.css']
})
export class ShipmentFilterDistributionCenterSummaryComponent implements OnInit {
  dcLocations: LocationInfo[] = [];
  filter: number[];
  selectedList: SelectableLocationInfoListItem[] = [];

  dcListSubscription: Subscription;
  dcFilterSubscription: Subscription;
  @Input() shipmentFilter: IShipmentFilterState;
  @Input() isHugeCustomer: boolean;
  @Output() summaryRemove = new EventEmitter();

  constructor(
    public locationSvc: LocationService,
    private filterSvc: ShipmentFilterService
  ) { }

  ngOnInit() {
    this.dcListSubscription = this.locationSvc.locationListIncludingCopy$.subscribe(n => {
      const dcLocations = n.list.filter(x=>x.TypeCode === LocationTypeCode.DistributionCenter)
      this.onDCLocationListChange(dcLocations)
    });
    this.dcFilterSubscription = this.filterSvc.distributionCenterFilter$.subscribe(n => this.onDCLocationFilterChange(n));
  }

  ngOnDestroy() {
    this.dcListSubscription?.unsubscribe();
    this.dcFilterSubscription?.unsubscribe();
  }

  onDCLocationListChange(dcLocationList: LocationInfo[]) {
    this.dcLocations = dcLocationList;
    this.filterDCLocationList();
  }

  onDCLocationFilterChange(filter: number[]) {
    this.filter = filter;
    this.filterDCLocationList();
  }

  filterDCLocationList() {
    this.selectedList = [];

    if (this.filter) {
      this.dcLocations.forEach(item => {
        if (this.filter.indexOf(item.LocationId) >= 0) {
          this.selectedList.push(new SelectableLocationInfoListItem(item));
        }
      });
    }
  }

  clearSelectedFilter(dcLocationId: number) {
    this.shipmentFilter.dcTripStopLocation = this.shipmentFilter.dcTripStopLocation.filter(k => k !== dcLocationId);
    this.filterSvc.changeShipmentFilter(this.shipmentFilter);
    if (this.summaryRemove) {
      this.summaryRemove.emit({ title: label_distribution_center, value: this.shipmentFilter.dcTripStopLocation });
    }
  }
}

