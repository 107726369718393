import { Injectable } from '@angular/core';

import { createSelector, Store } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';

import * as UnAuthActions from 'app-modules/core/store/un-auth/un-auth.actions';
import { Customer, ILatLong } from 'emr-ng-shared';

@Injectable()
export class UnAuthStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public unauthStateSelector = ((state: IAppState) => state.unAuthTrackers);

    
    public unAuthCustomerSelector = createSelector(
        this.unauthStateSelector,
        (state) => state.Customer
    );

    public unAuthCreateShipmentAccess = createSelector(
        this.unauthStateSelector,
        (state) => state.HasCreateShipmentAccess
    );

    public unAuthCustomerLocationSelector = createSelector(
        this.unauthStateSelector,
        (state) => state.DefaultLocation
    );

    public unAuthCustomerNavTokenSelector = createSelector(
        this.unAuthCustomerSelector,
        (state) => state?.CustomerNavToken
    );

    // Observables
    public unAuthState$ = this.store.select(this.unauthStateSelector).pipe();
    public unAuthCustomer$ = this.store.select(this.unAuthCustomerSelector).pipe();
    public unAuthCustomerNavToken$ = this.store.select(this.unAuthCustomerNavTokenSelector).pipe();
    public unAuthCreateShipmentAccess$ = this.store.select(this.unAuthCreateShipmentAccess).pipe();
    public unAuthCustomerLocation$ = this.store.select(this.unAuthCustomerLocationSelector);

    // Actions
    public SaveUnAuthTrackers(unAuthTrackers: string) {
        this.store.dispatch(new UnAuthActions.SaveUnAuthTrackers(unAuthTrackers));
    }

    public SaveUnAuthCustomer(unAuthCustomer: Customer) {
        this.store.dispatch(new UnAuthActions.SaveUnAuthCustomer(unAuthCustomer));
    }

    public SaveUnAuthCustomerLocation(location: ILatLong) {
        this.store.dispatch(new UnAuthActions.SaveUnAuthCustomerLocation(location));
    }
0
    public SaveUnAuthCreateShipmentAccess(hasAccess?: boolean) {
        this.store.dispatch(new UnAuthActions.SaveUnAuthCreateShipmentAccess(hasAccess));
    }

    public UnAuthCreateShipment() {
        this.store.dispatch(new UnAuthActions.UnAuthCreateShipment());
    }

    public UnAuthCreateShipmentSuccess() {
        this.store.dispatch(new UnAuthActions.UnAuthCreateShipmentSuccess());
    }

    public UnAuthCreateShipmentError() {
        this.store.dispatch(new UnAuthActions.UnAuthCreateShipmentError());
    }
    
    public clearUnAuthCustomer() {
        this.store.dispatch(new UnAuthActions.ClearUnAuthCustomer());
    }
}
