import { Action } from '@ngrx/store';
import * as actionIndex from 'app-modules/core/store/actions';

import { ServerRequestCompleteAction } from 'app-modules/core/store/actions/custom-actions';


export class LoadDistancetTo implements Action {
    readonly type = actionIndex.LOAD_DISTANCE_TO;
    constructor() { }
}

export class LoadDistanceToError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_DISTANCE_TO_ERROR;
    readonly callingAction = actionIndex.LOAD_DISTANCE_TO;
    constructor() { }
}

export class LoadDistancetToStateSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_DISTANCE_TO_SUCCESS;
    readonly callingAction = actionIndex.LOAD_DISTANCE_TO;
    constructor() { }
}

export class CancelLoadDistancetTo implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_DISTANCE_TO;
    readonly callingAction = actionIndex.LOAD_DISTANCE_TO;
}
