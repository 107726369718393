import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentRouteRoutingModule } from 'app-modules/shipment-route/shipment-route-routing.module';
import { ShipmentRouteComponent } from 'app-modules/shipment-route/components/shipment-route/shipment-route.component';
import { ShipmentRoutePopupComponent } from './components/shipment-route-popup/shipment-route-popup.component';

import { EmrNgMapApiModule, EmrNgControlsModule, EmrUtilService } from 'emr-ng-shared';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { BreadcrumbInfoBoxContentComponent } from './components/breadcrumb-info-box-content/breadcrumb-info-box-content.component';
import { CoreModule } from 'app-modules/core/core.module';
import { ShipmentRouteMapViewComponent } from './components/shipment-route-map-view/shipment-route-map-view.component';
import { BreadcrumbInfoBoxListContentComponent } from './components/breadcrumb-info-box-list-content/breadcrumb-info-box-list-content.component';
import { NearbyLocationsPopupComponent } from './components/nearby-locations-popup/nearby-locations-popup.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ShipmentRouteRoutingModule,
    EmrNgControlsModule,
    EmrNgMapApiModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [
    ShipmentRouteComponent,
    ShipmentRoutePopupComponent,
    BreadcrumbInfoBoxContentComponent,
    ShipmentRouteMapViewComponent,
    BreadcrumbInfoBoxListContentComponent,
    NearbyLocationsPopupComponent
  ],
  // providers: [  //jelax OR-2497
  //   {
  //     provide: BingRouteDeviationService,
  //     deps: [APP_IN_CHINA, ShipmentDetailStateService, EmrUtilService],
  //     useFactory: RouteDeviationService
  //   }
  // ],
  exports: [
    ShipmentRouteComponent,
    ShipmentRoutePopupComponent,
    ShipmentRouteMapViewComponent
  ]
})
export class ShipmentRouteModule { }

// export function RouteDeviationService(isInChina: boolean,
//   detailStateSvc: ShipmentDetailStateService,
//   utilSvc: EmrUtilService) {
//   return isInChina ?
//     new BaiduRouteDeviationService(detailStateSvc, utilSvc) :
//     new BingRouteDeviationService(detailStateSvc, utilSvc);
// }


