// @ts-nocheck
export const environment = {
 production:'##__trackers.production__##' == 'true',
 localesPersistKey: '##__trackers.localesPersistKey__##',
 oversightV1BaseUrl: '##__trackers.oversightV1BaseUrl__##',
 oversightV2SiteUrl: '##__trackers.oversightV2SiteUrl__##',
 oversightCNSiteUrl: '##__trackers.oversightCNSiteUrl__##',
 ediApiBaseUrl: '##__trackers.ediApiBaseUrl__##',
 mockEdiApiBaseUrl: '##__trackers.mockEdiApiBaseUrl__##',
 psAPI_URL: '##__trackers.psAPI_URL__##',
 psUI_URL: '##__trackers.psUI_URL__##',
 bingApiKey: '##__trackers.bingApiKey__##',
 baiduApiKey: '##__trackers.baiduApiKey__##',
 buildNumber: '##__trackers.buildNumber__##',
 authPersistentData: '##__trackers.authPersistentData__##',
 selectedCustomer: '##__trackers.selectedCustomer__##',
 isBrowerRefreshed: '##__trackers.isBrowerRefreshed__##',
 unAuthTrackers: '##__trackers.unAuthTrackers__##',
 unAuthUserSettings: '##__trackers.unAuthUserSettings__##',
 AuthUserSettings: '##__trackers.AuthUserSettings__##',
 loadBaiduMapsSettings: '##__trackers.loadBaiduMapsSettings__##',
 enableLateFilter: '##__trackers.enableLateFilter__##' === 'true',
 autoRefreshMapEventsDelay: parseInt('##__trackers.autoRefreshMapEventsDelay__##'),
 autoRefreshIdleTime: parseInt('##__trackers.autoRefreshIdleTime__##'),
 refreshDelay: parseInt('##__trackers.refreshDelay__##'),
 refreshSubDelay: parseInt('##__trackers.refreshSubDelay__##'),
 allowCompareTraxx: '##__trackers.allowCompareTraxx__##' === 'true',
 OversightImageURL: '##__trackers.OversightImageURL__##',
 CNOversightImageURL: '##__trackers.CNOversightImageURL__##',
 locationsTemplateURL: '##__trackers.locationsTemplateURL__##',
 loadBaiduMaps: '##__trackers.loadBaiduMaps__##'.toLowerCase() == 'true',
 disableExternalAppsInChina:'##__trackers.disableExternalAppsInChina__##'.toLowerCase() == 'true',
 useCNResourceURLs: '##__trackers.useCNResourceURLs__##'.toLowerCase() == 'true',
 showEstimatedCrumbs: '##__trackers.showEstimatedCrumbs__##',
 showAlertIconsShipmentList: '##__trackers.showAlertIconsShipmentList__##'.toLowerCase() == 'true',
 testTrackerSensors: '##__trackers.testTrackerSensors__##' === 'true',
 showCreateShipmentInDetails: '##__trackers.showCreateShipmentInDetails__##'.toLowerCase() == 'true',
 showAllTempValues:'##__trackers.showAllTempValues__##'.toLowerCase() == 'true',
 columnDefs: '##__trackers.columnDefs__##',
 sortOrder: '##__trackers.sortOrder__##',
 temperatureAboveCentiRange: parseFloat('##__trackers.temperatureAboveCentiRange__##'),
 temperatureBelowCentiRange: parseFloat('##__trackers.temperatureBelowCentiRange__##'),
 temperatureAboveForenRange: parseFloat('##__trackers.temperatureAboveForenRange__##'),
 temperatureBelowForenRange: parseFloat('##__trackers.temperatureBelowForenRange__##'),
 showDashboardLink:'##__trackers.showDashboardLink__##'.toLowerCase() == 'true',
 showDashboardResetFields: '##__trackers.showDashboardResetFields__##'.toLowerCase() == 'true',
 showBanner:'##__trackers.showBanner__##',
 loggerAPI_URL: '##__trackers.loggerAPI_URL__##',
 loggerUI_URL:'##__trackers.loggerUI_URL__##',
 recycleDashboardApiUrl: '##__trackers.recycleDashboardApiUrl__##',
};

