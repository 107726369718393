// tslint:disable-next-line: no-reference
/// <reference path='../../node_modules/bingmaps/types/MicrosoftMaps/Microsoft.Maps.All.d.ts' />

import { NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, PlatformLocation } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnCa from '@angular/common/locales/en-CA';
import localeEnCaExtra from '@angular/common/locales/extra/en-CA';
import localeEnHk from '@angular/common/locales/en-HK';
import localeEnHkExtra from '@angular/common/locales/extra/en-HK';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeEsMx from '@angular/common/locales/es-MX';
import localeEsMxExtra from '@angular/common/locales/extra/es-MX';
import localeEsUs from '@angular/common/locales/es-US';
import localeEsUsExtra from '@angular/common/locales/extra/es-US';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import localeJa from '@angular/common/locales/ja';
import localeJaExtra from '@angular/common/locales/extra/Ja';
import localeKo from '@angular/common/locales/ko';
import localeKoExtra from '@angular/common/locales/extra/ko';
import localePl from '@angular/common/locales/pl';
import localePlExtra from '@angular/common/locales/extra/pl';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import localeTr from '@angular/common/locales/tr';
import localeTrExtra from '@angular/common/locales/extra/tr';
import localePt from '@angular/common/locales/pt';
import localePtExtra from '@angular/common/locales/extra/pt';
import localeZh from '@angular/common/locales/zh';
import localeZhExtra from '@angular/common/locales/extra/zh';
import localeTh from '@angular/common/locales/th';
import localeAr from '@angular/common/locales/ar';
import localeVi from '@angular/common/locales/vi';
import localeViExtra from '@angular/common/locales/extra/vi';
import { DatePipe } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from 'environments/environment';

import { LocaleResolver } from 'app-modules/core/utils/locale-resolver';
import { CoreModule } from 'app-modules/core/core.module';
import { UserAccountModule } from 'app-modules/user-account/user-account.module';
import { DashboardModule } from 'app-modules/dashboard/dashboard.module';
import { LocaleModule } from 'app-modules/locale/locale.module';
import { CustomerModule } from 'app-modules/customer/customer.module';
import { MapViewModule } from 'app-modules/map-view/map-view.module';
import { ListViewModule } from 'app-modules/list-view/list-view.module';
import { CreateShipmentModule } from 'app-modules/create-shipment/create-shipment.module';

import { ICreateShipmentPopup } from 'app-modules/core/models/create-shipment-popup.interface';
import { CreateShipmentPopup } from 'app-modules/create-shipment/services/create-shipment-popup';
import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { ShipmentDetailModule } from 'app-modules/shipment-detail/shipment-detail.module';
import { ShipmentChartModule } from 'app-modules/shipment-chart/shipment-chart.module';
import { ShipmentDetailPopup } from 'app-modules/core/models/shipment-detail-popup.model';
import { ShipmentDetailPopupService } from 'app-modules/shipment-detail/services/shipment-detail-popup.service';
import { ShipmentRoutePopup } from 'app-modules/core/models/shipment-route-popup.model';
import { ShipmentRouteService } from 'app-modules/shipment-route/services/shipment-route-popup.service';
import { ShipmentRouteModule } from 'app-modules/shipment-route/shipment-route.module';
import { IEditShipmentPopup } from 'app-modules/core/models/edit-shipment-popup.interface';
import { EditShipmentPopup } from 'app-modules/create-shipment/services/edit-shipment-popup';
import { ShowDistancePopup } from 'app-modules/core/models/show-distance-popup.model';
import { ShowDistanceService } from 'app-modules/show-distance/services/show-distance-popup.service';
import { ShowDistanceModule } from 'app-modules/show-distance/show-distance.module';
import { AlertSuppressionPopup } from 'app-modules/core/models/alert-suppression-popup.model';
import { AlertSuppressionService } from 'app-modules/alert-suppression/services/alert-suppression-popup.service';
import { AlertSuppressionModule } from 'app-modules/alert-suppression/alert-suppression.module';
import { CloseShipmentPopup } from 'app-modules/dashboard/services/close-shipment-popup';
import { ICloseShipmentPopup } from 'app-modules/core/models/close-shipment-popup.interface';
import { IMinMaxChartValuesPopup } from 'app-modules/core/models/minmax-chart-values-popup.interface';
import { MinMaxChartValuesPopup } from 'app-modules/shipment-detail/services/minmax-chart-values-popup.service';
import { UnAuthenticationModule } from 'app-modules/un-authentication/un-authentication.module';
import { EmrNgControlsModule, FullstoryModule, FullstoryConfig } from 'emr-ng-shared';
import {
  BingMapAPILoaderConfig,
  BingMapAPILoader, BaiDuMapAPILoaderConfig, BaiDuMapAPILoader, MapAPILoader, WindowRef, DocumentRef,
  BingMapServiceFactoryFactory, BaiDuMapServiceFactoryFactory, MapServiceFactory, CustomMapServiceFactory, AnimationService,
  BaiDuAnimationService, BingAnimationService
} from 'emr-ng-shared';

import { httpInterceptorProviders } from 'app-modules/core/http-interceptors';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouteEditorModule } from 'app-modules/route-editor/route-editor.module';
import { UserMessagePopup } from 'app-modules/core/models/user-message-popup.model';
import { UserMessageService } from 'app-modules/user-account/services/user-message-popup.service';
import { LocationManagementModule } from 'app-modules/location-management/location-management.module';
import { ShipmentFilterModule } from 'app-modules/shipment-filter/shipment-filter.module';
import { IconInfoPopup } from 'app-modules/core/models/icon-info-popup.model';
import { IconInfoService } from 'app-modules/core/services/icon-info-popup.service';
import { CompareTraxxModule } from 'app-modules/compare-traxx/compare-traxx.module';
import { BASE_LOCALE_ID } from 'app-modules/core/consts/locale-id';
import { HttpClientModule } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { LoadingEffects } from 'app-modules/core/store/effects/loading.effects';
import * as reducerIndex from 'app-modules/core/store/reducers'
import { ShipmentAddressUpdatedEffect } from 'app-modules/core/store/effects/shipmentAddressUpdate.effect';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { APP_IN_CHINA } from 'src/regionFinder';
import { StoreManager } from 'app-modules/core/utils/store-manager';
import { DcEditShipmentModule } from 'app-modules/dc-edit-shipment/dc-edit-shipment.module';
import { DeleteTripPopup } from 'app-modules/core/models/delete-trip-popup.model';
import { DeleteTripService } from 'app-modules/core/services/delete-trip-popup.service';
import { ShutdownTrackerPopup } from 'app-modules/core/models/shutdown-tracker-popup.model';
import { ShutdownTrackerService } from 'app-modules/core/services/shutdown-tracker-popup.service';

import { HistoricalViewComponent } from './modules/historical-view/historical-view.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ReportModule } from 'app-modules/reports/report.module';
import { DomoModule } from 'app-modules/domo/domo.module';
import { IEUserMessageService } from 'app-modules/core/services/ie-user-message-popup.service';
import { IEUserMessagePopup } from 'app-modules/core/models/ie-user-message-popup.model';
import { StoreModule } from '@ngrx/store';
import { ShipmentTemplateModule } from 'app-modules/shipment-template/shipment-template.module';
import { DashboardTermsMessagePopup } from 'app-modules/core/models/dashboard-terms-message-popup.model';
import { DashboardTermsMessageService } from 'app-modules/domo/services/dashboard-terms-message-popup-service';
import { ShowViewlogComponent } from './modules/show-viewlog/components/show-viewlog/show-viewlog.component';
import { ShowViewlogPopupComponent } from './modules/show-viewlog/components/show-viewlog-popup/show-viewlog-popup.component';
import { ShowViewLogPopup } from 'app-modules/core/models/show-view-logs-popup.model';
import { ShowViewLogService } from 'app-modules/show-viewlog/services/show-viewlog-popup.service';
import { CalibrationStatementsModule } from 'app-modules/nist-calibration-statements/calibration-statements.module';
import { CalibrationStatementPopupService } from 'app-modules/core/services/calibration-statement-popup.service';
import { CalibrationStatementPopup } from 'app-modules/core/models/calibration-statement-popup.model';


const localeResolver = new LocaleResolver(environment.localesPersistKey);
const locale = localeResolver.resolveLocale();

const defalutLocale = ['en', 'en-us'];
const localeEnMap = {
  de: localeDe,
  en: localeEn,
  'en-ca': localeEnCa,
  'en-hk': localeEnHk,
  es: localeEs,
  'es-mx': localeEsMx,
  'es-us': localeEsUs,
  fr: localeFr,
  it: localeIt,
  ja: localeJa,
  ko: localeKo,
  pl: localePl,
  pt: localePt,
  ru: localeRu,
  tr: localeTr,
  zh: localeZh,
  th: localeEn,
  ar: localeAr,
  vi: localeVi
};

const localeEnExtraMap = {
  de: localeDeExtra,
  en: localeEnExtra,
  'en-ca': localeEnCaExtra,
  'en-hk': localeEnHkExtra,
  es: localeEsExtra,
  'es-mx': localeEsMxExtra,
  'es-us': localeEsUsExtra,
  fr: localeFrExtra,
  it: localeItExtra,
  ja: localeJaExtra,
  ko: localeKoExtra,
  pl: localePlExtra,
  pt: localePtExtra,
  ru: localeRuExtra,
  tr: localeTrExtra,
  zh: localeZhExtra,
  th: localeTh,
  ar: localeAr,
  vi: localeViExtra
};

if (locale) {
  const localeId = locale.toLocaleLowerCase();

  // "en" and "en-us" are registered by default
  // If locale is other than default, register it.
  if (defalutLocale.indexOf(localeId) < 0) {
    registerLocaleData(localeEnMap[localeId], locale, localeEnExtraMap[localeId]);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HistoricalViewComponent,
    ShowViewlogComponent,
    ShowViewlogPopupComponent
  ],
  imports: [
    BrowserModule,
    FullstoryModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000, closeButton: true,
      positionClass: 'toast-top-right',
    }),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    EffectsModule.forRoot([LoadingEffects, ShipmentAddressUpdatedEffect]),
    UserAccountModule,
    DashboardModule,
    LocaleModule,
    CustomerModule,
    MapViewModule,
    ListViewModule,
    CreateShipmentModule,
    ShipmentDetailModule,
    ShipmentChartModule,
    ShipmentRouteModule,
    ShowDistanceModule,
    AlertSuppressionModule,
    RouteEditorModule,
    UnAuthenticationModule,
    LocationManagementModule,
    CompareTraxxModule,
    StoreModule.forRoot(reducerIndex.reducers,
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        }
      }),
    !environment.production ? StoreDevtoolsModule.instrument({
      name: 'Copeland Dev Tools',
      maxAge: 1000,
    }) : [],
    ShipmentFilterModule,
    DcEditShipmentModule,
    VirtualScrollerModule,
    ReportModule,
    DomoModule,
    ShipmentTemplateModule,
    CalibrationStatementsModule
  ],
  providers: [
    httpInterceptorProviders,
    DatePipe,
    {
      provide: BASE_LOCALE_ID,
      useFactory: (s: PlatformLocation) => trimSlashFromUrl(s.getBaseHrefFromDOM()),
      deps: [PlatformLocation]
    },
    BsLocaleService,
    { provide: ICreateShipmentPopup, useClass: CreateShipmentPopup },
    { provide: CalibrationStatementPopup, useClass: CalibrationStatementPopupService },
    { provide: ShipmentDetailPopup, useClass: ShipmentDetailPopupService },
    { provide: ShipmentRoutePopup, useClass: ShipmentRouteService },
    { provide: IEditShipmentPopup, useClass: EditShipmentPopup },
    { provide: ShowDistancePopup, useClass: ShowDistanceService },
    { provide: AlertSuppressionPopup, useClass: AlertSuppressionService },
    { provide: ICloseShipmentPopup, useClass: CloseShipmentPopup },
    { provide: IMinMaxChartValuesPopup, useClass: MinMaxChartValuesPopup },
    { provide: BaiDuMapAPILoader, useFactory: buildBaiDuMapAPILoader }, //jelax OR-2497
    { provide: BingMapAPILoader, useFactory: buildBingMapAPILoader }, //jelax OR-2497
    { provide: MapServiceFactory, deps: [BaiDuMapAPILoader, BingMapAPILoader, NgZone], useFactory: buildMapServiceFactory }, //jelax OR-2497
    { provide: UserMessagePopup, useClass: UserMessageService },
    { provide: IconInfoPopup, useClass: IconInfoService },
    { provide: DeleteTripPopup, useClass: DeleteTripService },
    { provide: ShutdownTrackerPopup, useClass: ShutdownTrackerService },
    { provide: FullstoryConfig, deps: [APP_IN_CHINA], useFactory: getFullstoryConfig },
    { provide: IEUserMessagePopup, useClass: IEUserMessageService },
    { provide: AnimationService, deps: [APP_IN_CHINA], useFactory: buildAnimationService },
    { provide: DashboardTermsMessagePopup, useClass: DashboardTermsMessageService },
    { provide: ShowViewLogPopup, useClass: ShowViewLogService },
    OversightPopupService
  ],
  bootstrap: [AppComponent],
  exports: [ShipmentRouteModule]
})
export class AppModule { }

export function trimSlashFromUrl(baseUrl: string) {
  if (!baseUrl) {
    return null;
  } else if (baseUrl[baseUrl.length - 1] === '/') {
    const trimmedUrl = baseUrl.replace(/^\/|\/$/g, '');
    return trimmedUrl;
  }
}

export function getFullstoryConfig(isAppInChina: boolean) {
  const config = new FullstoryConfig();
  config.fsOrg = 'M0VDF';
  config.fsIsInChina = environment.disableExternalAppsInChina ? isAppInChina : false;
  return config;
}

export function buildBaiDuMapAPILoader(): BaiDuMapAPILoader {
  const bd: BaiDuMapAPILoaderConfig = new BaiDuMapAPILoaderConfig();
  bd.apiKey = environment.baiduApiKey;
  // replace with your Baidu map key
  // the usage of this key outside this plunker is illegal.
  //gc.enableClustering = true;
  return new BaiDuMapAPILoader(bd, new WindowRef(), new DocumentRef());
}

export function buildBingMapAPILoader(): BingMapAPILoader {
  const bc: BingMapAPILoaderConfig = new BingMapAPILoaderConfig();
  bc.apiKey = environment.bingApiKey;
  bc.branch = 'release';
  if (locale === 'zh') {
    bc.locale = 'zh-Hans';
  } else {
    bc.locale = locale;
  }
  return new BingMapAPILoader(bc, new WindowRef(), new DocumentRef());
}

export function buildMapServiceFactory(baiDuMapAPILoader: BaiDuMapAPILoader, bingMapAPILoader: BingMapAPILoader, zone: NgZone): CustomMapServiceFactory {
  const customMapServiceFactory = new CustomMapServiceFactory(baiDuMapAPILoader, bingMapAPILoader, zone);
  customMapServiceFactory.isLoadBaiduMapsFn = () => StoreManager.GetValue(environment.loadBaiduMapsSettings) === '1';
  return customMapServiceFactory;
}

function buildAnimationService(isAppInChina: boolean): AnimationService {
  return isAppInChina ? new BaiDuAnimationService() : new BingAnimationService();
}