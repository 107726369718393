import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { TripStateInfo } from 'emr-ng-shared';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IShipmentStatusState } from 'app-modules/core/store/models/shipment-status-state.interface';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as statusActions from 'app-modules/core/store/actions/shipment-status.actions';

@Injectable()
export class ShipmentStatusStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public shipmentStatusStateSelector = ((state: IAppState) => state.shipmentStatus);
    public isLoadRequiredSelector = createSelector(
        this.shipmentStatusStateSelector,
        (state: IShipmentStatusState) => state.isLoadRequired
    );
    public isLoadingSelector = createSelector(
        this.shipmentStatusStateSelector,
        (state: IShipmentStatusState) => state.isLoading
    );
    public shipmentStatusListSelector = createSelector(
        this.shipmentStatusStateSelector,
        (state: IShipmentStatusState) => state.shipmentStatusList
    );

    // Observables
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector).pipe();
    public isLoading$ = this.store.select(this.isLoadingSelector).pipe();
    public shipmentStatusList$ = this.store.select(this.shipmentStatusListSelector).pipe();

    // Actions
    public loadShipmentStatuses() {
        this.store.dispatch(new statusActions.LoadShipmentStatuses());
    }

    public loadShipmentStatusesSuccess(shipmentStatusList: IListInfo<TripStateInfo>) {
        this.store.dispatch(new statusActions.LoadShipmentStatusesSuccess(shipmentStatusList));
    }

    public loadShipmentStatusesError(message: string) {
        this.store.dispatch(new statusActions.LoadShipmentStatusesError(message));
    }

    public cancelLoadShipmentStatuses() {
        this.store.dispatch(new statusActions.CancelLoadShipmentStatuses());
    }

    public reLoadShipmentStatuses() {
        this.store.dispatch(new statusActions.ReloadShipmentStatuses());
    }
}


