<emr-modal headerTitle="Edit Shipment" i18n-headerTitle="@@link_edit_shipment" [bsModalRef]=bsMainModalRef>
    <form modal-body name="frmEditShipment" #shipmentForm="ngForm" autocomplete="off">
        <div class="bg-data-div_EditShipment mx-auto">
            <div class="row">
                <div class="col-md-12 vmargin-sm">
                    <div class="row section-header">
                        <div class="col-md-12 roboto-regular-font font-16 color-regular">
                            <p>
                                <span i18n="@@label_details">Details</span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div class="row section-item" *ngIf="!shipment.NoTemperatureRequired">
                        <div class="col-md-12">
                            <p>
                                <emr-custom-dropdown identifier="lstSensorRange" name="lstSensorRange" [options]="sensorRangeList$ | async" [(ngModel)]="shipment.SensorTempRangeId"
                                    cssClass="default-dropdown" label="Temperature Range" i18n-label="@@label_temperature_range"
                                    optionsValueField="SensorRangeId" optionsTextField="Description" helpTip="Temperature Range"
                                    i18n-helpTip="@@label_temperature_range" placeholder="Select Temperature Range" i18n-placeholder="@@select_temperature_range"
                                    required [inline]=true labelColumns=3 controlColumns=9 [validationIndicatorRequired]=true>
                                    <emr-validation-message validatorName="required" message="Temperature Range Required" i18n-message="@@temperature_range_required"></emr-validation-message>
                                </emr-custom-dropdown>
                            </p>
                        </div>
                    </div>
                    <div class="row section-header">
                        <div class="col-md-12 roboto-regular-font font-16 color-regular">
                            <p>
                                <span i18n="@@label_finish">Finish</span>
                            </p>
                            <hr>
                        </div>
                    </div>
                    <div class="row section-item">
                        <div class="col-md-12">
                            <p>
                                <emr-button identifier="shipmentUpdate" buttonType="submit" buttonText="Update" i18n-buttonText="@@link_update" cssClass="btn-outline-default" (onClick)="onUpdateShipment()"></emr-button>
                                <emr-button identifier="shipmentCancel" buttonText="Cancel" i18n-buttonText="@@link_cancel" cssClass="btn-outline-default" (onClick)="onUpdateShipmentCancel()"></emr-button>
                            </p>
                        </div>
                    </div>
                    <div class="row section-item">
                        <div class="col-md-12">
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</emr-modal>
