<form name="frmCreateShipmentStops" #stopsForm="ngForm" emrForm autocomplete="off" class="w-100"
  [ngClass]="{ 'ng-submitted': isStopsFormSubmitted}">
  <div class="row">
    <div class="col-md-4 pl-1 pt-2 pr-1 custom-card" *ngFor="let location of Stops; let i = index;">
      <div class="card cust-card br-1 {{location.IsInValid?'border-red-1':''}}">
        <span class="pull-right count-span clickable close-icon" data-effect="fadeOut">
          <div class="btn btn-primary count-button rounded-circle btn-sm">{{i+1}}</div>
        </span>
        <div class="card-body pl-2 pr-2 pb-3 pt-2">

          <div class="row mb-2">
            <div class="col-md-12">
              <emr-checkbox ngDefaultControl identifier="chkAirFreight_{{i}}" name="chkAirFreight_{{i}}"
                [setDisabled]="(AirportDeparture&&!location.AirportDeparture)||location.IsEdit"
                (change)="AirFreightChange(location,i)" text="Air Freight" i18n-text="@@label_air_freight"
                [(ngModel)]="location.AirportDeparture" tooltip="You can select one Air Freight stop per shipment"
                i18n-tooltip="@@air_freight_per_shipment">
              </emr-checkbox>
            </div>
          </div>
          <div *ngIf="!location.IsEdit;else LocationLabel">
            <div class="row">
              <p class="col-md-12 w-100">
                <emr-custom-dropdown identifier="lstStopLocationsDeparture_{{i}}"
                  label="{{location.AirportDeparture?label:''}}" name="lstStopLocations_{{i}}" [options]="stopLocations"
                  cssClass="default-dropdown" placeholder="Select / Create Location" [showPlaceHolder]=false
                  i18n-placeholder="@@label_select_create_location" [(ngModel)]="location.LocationId"
                  [createNewItem]=true createNewItemText="Create New Location"
                  i18n-createNewItemText="@@label_create_new_location"
                  [EmrCompareTwoValuesValidator]="selectedStopsLocations" (onChange)="onLocationSelect(location)"
                  (CreateNewItemClick)="openAddStopLocationModal('tripstop',i,0)"
                  [showDropDownCreateToolTip]="showDropDownCreateToolTip" [EmrSecondCompareValues]="selectedOD"
                  disabledCreateHelpTip="Cannot create a new when creating a shared shipment"
                  i18n-disabledCreateHelpTip="@@label_createaddress_disabled_dualvis" [validationIndicatorRequired]=true
                  [enableSearchFilter]=true SearchPlaceHolder="Search Location" required
                  i18n-SearchPlaceHolder="@@label_search_location" [isLoading]="isLocationListLoading$ | async">
                  <emr-validation-message validatorName="required" message="Please select Location"
                    i18n-message="@@select_location_name_required"></emr-validation-message>
                  <emr-validation-message validatorName="EmrCompareTwoValuesValidator"
                    i18n-message="@@select_distinct_location" message="Please select Distinct stop location">
                  </emr-validation-message>
                  <emr-validation-message validatorName="EmrSecondCompareValuesValidator"
                    i18n-message="@@select_distinct_stop_location_from_origin_destinat"
                    message="Origin/Destination locations can not be used as Trip Stop location">
                  </emr-validation-message>
                </emr-custom-dropdown>
              </p>
            </div>
          </div>
          <ng-template #LocationLabel>
            <div class="form-group">
              <label class="label-css" *ngIf="location.AirportDeparture">{{location.AirportDeparture?label:''}}</label>
              <div class="col-md-12 mb-3x">
                <div class=" form-group text-as-dropdown" *ngIf="!(isLocationListLoading$ | async)">
                  <span class="span-default">{{location.LocationName}}</span>
                </div>
                <div id="small-overlay" class="applySpinner  text-as-spinner lt-15 rt-15 pos-sticky"
                  *ngIf="isLocationListLoading$ | async">
                  <div style="width:100%;height:100%;margin:auto;">
                    <i class="fa fa-spinner spin-frames"></i>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <div *ngIf="location.AirportDeparture">
            <div class="row">
              <div class="col-md-12">
                <p>
                  <emr-textbox inputType=number ngDefaultControl identifier="txtEstimatedFlightMinutes"
                    i18n-label="@@label_estimated_flight_minutes" label="Estimated Flight Minutes"
                    [setDisabled]=location.IsEdit [(ngModel)]="Shipment.EstimatedFlightMinutes" required
                    name="txtEstimatedFlightMinutes" (onChange)="estimateChange(location)" [inline]=true labelColumns=7
                    textBoxColumns=5 cssClass="p-0" textBoxDivCssClass="p-0" [EmrMinMaxValueValidator]
                    [minimumValue]="0">
                    <emr-validation-message validatorName="required" message="Required" i18n-message="@@label_required">
                    </emr-validation-message>
                    <emr-validation-message validatorName="minimumValue" message="&nbsp;"></emr-validation-message>
                  </emr-textbox>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>
                  <emr-textbox inputType=number ngDefaultControl identifier="txtEstimatedLoadingMinutes"
                    label="Estimated Loading Minutes" i18n-label="@@label_estimated_loading_minutes"
                    [setDisabled]=location.IsEdit [(ngModel)]="Shipment.EstimatedLoadingMinutes" required
                    name="txtEstimatedLoadingMinutes" (onChange)="estimateChange(location)" [inline]=true labelColumns=7
                    textBoxColumns=5 cssClass="p-0" textBoxDivCssClass="p-0" EmrMinMaxValueValidator [minimumValue]="0">
                    <emr-validation-message validatorName="required" message="Required" i18n-message="@@label_required">
                    </emr-validation-message>
                    <emr-validation-message validatorName="minimumValue" message="&nbsp;"></emr-validation-message>
                  </emr-textbox>
                </p>
              </div>
            </div>
            <div *ngIf="!location.IsEdit;else ArrivalLocationLabel">
              <div class="row">
                <p class="col-md-12 w-100">
                  <emr-custom-dropdown identifier="lstStopLocationsArrival_{{i}}" label="Airport Arrival"
                    i18n-label="@@label_airport_arrival" name="lstStopLocationsArrival_{{i}}" [options]="stopLocations"
                    cssClass="default-dropdown" placeholder="Select / Create Location" [showPlaceHolder]=false
                    i18n-placeholder="@@label_select_create_location" [(ngModel)]="location.ArrivalLocationId"
                    [createNewItem]=true createNewItemText="Create New Location"
                    i18n-createNewItemText="@@label_create_new_location" (onChange)="onLocationSelect(location,true)"
                    (CreateNewItemClick)="openAddStopLocationModal('tripstop',i,1)"
                    [showDropDownCreateToolTip]="isEndLocationUndefined"
                    [EmrCompareTwoValuesValidator]="selectedStopsLocations+','+location.LocationId"
                    disabledCreateHelpTip="Cannot create a new when creating a shared shipment"
                    i18n-disabledCreateHelpTip="@@label_createaddress_disabled_dualvis"
                    [validationIndicatorRequired]=true [enableSearchFilter]=true SearchPlaceHolder="Search Location"
                    required i18n-SearchPlaceHolder="@@label_search_location" [EmrSecondCompareValues]="selectedOD"
                    [isLoading]="isLocationListLoading$ | async">
                    <emr-validation-message validatorName="required" message="Please select Location"
                      i18n-message="@@select_location_name_required"></emr-validation-message>
                    <emr-validation-message validatorName="EmrCompareTwoValuesValidator"
                      i18n-message="@@select_distinct_location" message="Please select Distinct stop location">
                    </emr-validation-message>
                    <!-- <emr-validation-message validatorName="EmrDistinctValuesValidator"
                      i18n-message="@@select_distinct_location" message="Please select Distinct stop location">
                    </emr-validation-message> -->
                    <emr-validation-message validatorName="EmrSecondCompareValuesValidator"
                      i18n-message="@@select_distinct_stop_location_from_origin_destinat"
                      message="Origin/Destination locations can not be used as Trip Stop location">
                    </emr-validation-message>
                  </emr-custom-dropdown>
                </p>
              </div>
            </div>
            <ng-template #ArrivalLocationLabel>
              <div class="form-group">
                <label class="label-css" i18n="@@label_airport_arrival">Airport Departure</label>
                <div class="col-md-12 mb-3x">
                  <div class=" form-group text-as-dropdown" *ngIf="!(isLocationListLoading$ | async)">
                    <span class="span-default">{{location.ArrivalLocationName}}</span>
                  </div>
                  <div id="small-overlay" class="applySpinner text-as-spinner lt-15 rt-15 pos-sticky"
                    *ngIf="isLocationListLoading$ | async">
                    <div style="width:100%;height:100%;margin:auto;">
                      <i class="fa fa-spinner spin-frames"></i>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="row">
            <div class="col-md-12 text-right">
              <emr-button identifier="EditStop_{{i}}" buttonType="button"
                cssClass="btn-sm clr-green btn-outline-default mt-1 mb-0 width-auto" *ngIf="location.IsEdit"
                [setDisabled]="editMode" buttonText="Edit Stop" i18n-buttonText="@@link_edit_stop"
                buttonIcon="fa fa-pencil" (onClick)="editStop(location)">
              </emr-button>
              <emr-button identifier="SaveStop_{{i}}" buttonType="submit"
                cssClass="btn-sm clr-blue btn-outline-default mt-1 mb-0 width-auto" *ngIf="!location.IsEdit"
                buttonText="Save" i18n-buttonText="@@link_save" buttonIcon="fa fa-check" (onClick)="formSubmit();">
              </emr-button>
              <emr-button identifier="DeleteStop_{{i}}" buttonType="button"
                cssClass="btn-sm clr-red btn-outline-default mt-1 mb-0 width-auto" buttonText="Delete Stop"
                i18n-buttonText="@@link_delete_stop" buttonIcon="fa fa-trash-o" (onClick)="DeleteStop(location,i)">
              </emr-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-button {{Stops?.length==0?'col-md-12 ':' '}} {{Stops?.length==0||Stops?.length%3?'':'right'}}">
      <emr-button identifier="AddNewStop" buttonType="submit" cssClass="btn-sm btn-outline-default ml-1 width-auto"
        [setDisabled]="!stopsForm.valid||editMode" i18n-buttonText="@@link_add_new_stop" buttonText="Add New Stop"
        buttonIcon="fa fa-plus" (onClick)="createNewStop()"></emr-button>
    </div>
  </div>
</form>