import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-local-loading-indicator',
  templateUrl: './local-loading-indicator.component.html',
  styleUrls: ['./local-loading-indicator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalLoadingIndicatorComponent implements OnInit {

  @Input() width: string;
  @Input() height: string;
  @Input() applyOverlay = false;
  @Input() overrideLeft = null;
  @Input() overrideTop = null;

  constructor() { }

  ngOnInit() {
  }

}
