import { Action } from '@ngrx/store';
import { BusinessRuleInfo } from 'emr-ng-shared';
import * as actionIndex from 'app-modules/core/store/actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

export class LoadBusinessRules implements ServerRequestAction {
    readonly type = actionIndex.LOAD_BUSINESS_RULES;
}

export class LoadBusinessRulesSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_BUSINESS_RULES_SUCCESS;
    readonly callingAction = actionIndex.LOAD_BUSINESS_RULES;

    constructor(public businessRules: IListInfo<BusinessRuleInfo>) { }
}

export class LoadBusinessRulesError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_BUSINESS_RULES_ERROR;
    readonly callingAction = actionIndex.LOAD_BUSINESS_RULES;

    constructor(public payload: string) { }
}

export class CancelLoadBusinessRules implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_BUSINESS_RULES;
    readonly callingAction = actionIndex.LOAD_BUSINESS_RULES;
}

export class CheckBusinessRule implements ServerRequestCompleteAction {
    readonly type = actionIndex.CHECK_BUSINESS_RULES_STATE;
    readonly callingAction = actionIndex.LOAD_BUSINESS_RULES;
}
