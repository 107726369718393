import { FleetTrakerType, TripActionStatus } from 'app-modules/core/store/schedule-report/schedule-report.service';
import { Any, TimePeriod, TripState } from 'emr-ng-shared';

export class FleetReportParams {
    TimePeriod: TimePeriod;
    FromDate: Date;
    ThruDate: Date;
    OutOfTempRange: boolean;
    ShowNextStopInfo: boolean;
    ShowInactive: boolean;
    ShowTags: boolean;
    TraxxGoOnly: boolean;
    TripStatus: TripActionStatus;
    TripState: Any[];
    Is3PL: FleetTrakerType;
}
