import { ServerRequestAction, ServerRequestCompleteAction } from '../actions/custom-actions';
import * as actionIndex from 'app-modules/core/store/actions';


export class LoadCompareTraxx implements ServerRequestAction {
    readonly type = actionIndex.LOAD_COMPARE_TRAXX;
}

export class LoadCompareTraxxComplete implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_COMPARE_TRAXX_COMPLETE;
    readonly callingAction = actionIndex.LOAD_COMPARE_TRAXX;
    constructor() { }
}
