import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { IShipmentFilterLocation } from 'app-modules/core/store/models/shipment-filter-location.interface';
import { LocationInfo } from 'emr-ng-shared';
import { LocationService } from 'app-modules/core/services/location.service';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';

class SelectableLocationInfoListItem {
    checked: boolean;
    get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
    get value() { return this.locationInfo.LocationId; }
    constructor(public locationInfo: LocationInfo) { }
}

@Component({
    selector: 'app-shipment-filter-destination',
    templateUrl: './shipment-filter-destination.component.html',
    styleUrls: ['../../styles/shipment-filter.css']
})
export class ShipmentFilterDestinationComponent implements OnInit, OnDestroy {
    // bindable filter
    filterVal: number[];
    searchDestinationText = '';
    @Input() get filter() {
        return this.filterVal;
    }
    @Output() filterChange = new EventEmitter();
    // tslint:disable-next-line: adjacent-overload-signatures
    set filter(val) {
        this.filterVal = val;
        this.filterChange.emit(this.filterVal);
    }

    // bindable isDirty
    isDirtyVal: boolean;
    @Input() get isDirty() { return this.isDirtyVal; }
    @Output() isDirtyChange = new EventEmitter();
    // tslint:disable-next-line: adjacent-overload-signatures
    set isDirty(val) {
        this.isDirtyVal = val;
        this.isDirtyChange.emit(this.isDirtyVal);
    }

    locationList: SelectableLocationInfoListItem[];

    private destinationListSubscription: Subscription;
    private destinationFilterSubscription: Subscription;

    constructor(
        public locationSvc: LocationService,
        private filterSvc: ShipmentFilterService
    ) { }

    public ngOnInit() {
        this.destinationListSubscription = this.locationSvc.destinationList$.subscribe(n => this.onDestinationListChange(n));
        this.destinationFilterSubscription = this.filterSvc.destinationFilter$.subscribe(n => this.onDestinationFilterChange(n));
    }

    public ngOnDestroy() {
        this.destinationListSubscription.unsubscribe();
        this.destinationFilterSubscription.unsubscribe();
    }

    onDestinationListChange(locationList: IListInfo<LocationInfo>) {
        this.locationList = [];
        if (!locationList) { return; }

        locationList.list.forEach(item => {
            const listItem = new SelectableLocationInfoListItem(item);
            if (this.filter) {
                listItem.checked = this.filter.indexOf(item.LocationId) >= 0;
            }
            this.locationList.push(listItem);
        });
    }

    onDestinationFilterChange(filter: number[]) {
        this.isDirty = false;
        this.filter = filter;
        if (filter) {
            this.locationList.forEach(item => item.checked = this.filter.indexOf(item.locationInfo.LocationId) >= 0);
        }
    }

    // onSelectionChange(listItem: SelectableLocationInfoListItem) {
    //     if (listItem.selected) {
    //         this.filter.selectedLocations.set(listItem.locationInfo.LocationId, listItem.selected);
    //     } else {
    //         this.filter.selectedLocations.delete(listItem.locationInfo.LocationId);
    //     }
    //     this.isDirty = true;
    // }
}


