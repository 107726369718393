import { ObjectViewType, ReportType, TimePeriod, TripState } from 'emr-ng-shared';

export class TripReportParams {
    ObjectViewType?: ObjectViewType;
    Name?: string;
    ReportType: ReportType;
    TimePeriod: TimePeriod;
    FromDate: Date;
    ThruDate: Date;
    TripState?: number;
}