import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, Input, Output, EventEmitter, TemplateRef, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import * as FileSaver from 'file-saver';
import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { ShipmentDetailStateService } from 'app-modules/core/store/services/shipment-detail-state.service';
import {
    ExportTrackerStateReportHeaders, responseType, GetShipmentResponse, TimePeriod,
    Sensor, SensorListInfo, SensorReportScheduleRequest, ReportBackgroundColorType,
    EmrUtilService, ShipmentRequest, DateRange, DateTimeObject, BusinessRuleType, CustomMapServiceFactory, MapServiceFactory
} from 'emr-ng-shared';

import { ISensorChart } from 'app-modules/core/store/models/sensor-chart-state.interface';
import { SensorChartService } from 'app-modules/core/services/sensor-chart.service';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    MinMaxChartValuesComponent, MinMaxValueEventArgs
} from 'app-modules/shipment-detail/components/set-minmax/minmax-chart-values.component';
import { take } from 'rxjs/operators';
import {
    SensorChartResponseComponent, SensorChartResponseOkEventArgs
} from 'app-modules/shipment-detail/components/sensor/sensor-chart-response/sensor-chart-response.component';
import { AxisRange } from './models/AxisRange';
import { SensorChartDataService } from 'app-modules/shipment-detail/services/sensor-chart-data.service';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { ITraxxState } from 'app-modules/core/store/models/traxx-state.interface';
import { Shipment as CurrentShipment } from 'app-modules/core/models/shipment.model';
import { DateTimeFormat } from '../shipment-detail/shipment-detail.component';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { ShipmentDetailsCommonData } from 'app-modules/shipment-detail/models/ShipmentDetailsCommonData';
import _ from 'lodash';

export class SensorInfoListItem {
    get text() { return this.sensorListInfo.SensorName; }
    get value() { return this.sensorListInfo; }
    get count() { return this.shockCount }
    constructor(private sensorListInfo: SensorListInfo, private shockCount: number) { }
}

@Component({
    selector: 'app-sensor-state',
    templateUrl: './sensor-chart.component.html',
    styleUrls: ['./sensor-chart.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class SensorStateComponent implements OnInit, OnDestroy {
    // =====Properties or Initial values=====
    // selectedShipment: Shipment = new Shipment();

    @ViewChild('errorModal') public errorModalTemplate: TemplateRef<any>;

    isSensorFilterDirty = false;
    isTemperatureSelected = true;

    public error: string;
    shipmentSensorList: SensorInfoListItem[] = [];
    // selectedSensor: SensorListInfo = {};
    // selectedSensors: SensorListInfo[] = null;
    // selectedColor = ReportBackgroundColorType.None;

    showTemp = false;
    showHumidity = false;
    showProbe = false;
    showCO2 = false;
    showSHK = false;
    hasTempAxis = false;
    overrideAxisRange: AxisRange;
    isSHKSelected = false;

    sensorChartData: ISensorChart;
    shipmentChartData: any;
    noChartDataMsg: boolean;

    chartData: any = [];
    alertData: any = [];
    locationData: any = [];
    plotLabels = [];
    // showAlertsAndEvents = false;

    showNoSensorsMessage = false;
    detailsCommonData: ShipmentDetailsCommonData;
    showPlotLines = false;
    plotLines: any;
    alertCount: number;
    shockAlertCount: number;

    @Input() inputMinvalueYOverride; // Min, Max values to override
    @Input() inputMaxvalueYOverride;
    @Input() ShowTempInfo = false;
    @Input() mktString: string;
    @Input() downloadProgress;
    @Input() downloadCompleted;
    @Input() emailSent;
    @Input() latestShipmentRequest: ShipmentRequest;
    @Input() set shipmentDetailsCommonData(data: ShipmentDetailsCommonData) {
        if (data) {
            this.detailsCommonData = data;
            this.isSHKSelected = this.detailsCommonData?.selectedSensors?.filter(k => k.Pin === 'SHK')?.length == 1;
        }
    }
    chartDataRange: any;
    selectedDateRange: DateRange;
    @Input()
    set DateRange(dateRange: DateRange) {
        this.selectedDateRange = dateRange;
        this.chartDataRange = dateRange ? {
            min: Date.parse(dateRange.startDateTime.toString()),
            max: Date.parse(dateRange.endDateTime.toString())
        } : null;
        if (dateRange && this.getShipmentResponseData?.Shipment?.Alerts?.length > 0) {
            const alerts = this.detailSvc.getAlertsBetweenSelectedDates(this.getShipmentResponseData?.Shipment?.Alerts, dateRange);
            this.alertCount = alerts?.filter(k => k.Pin !== 'SHK')?.length;
            this.shockAlertCount = alerts?.filter(k => k.Pin === 'SHK')?.length;
        }
    };
    minvalueYOverride = null;
    maxvalueYOverride = null;
    @Output() updateMinMaxValues = new EventEmitter<MinMaxValueEventArgs>();

    // temperatureInfo: Sensor = null;
    selectedPeriodRangeString = null;
    bsModalRef: BsModalRef;
    errorModalRef: BsModalRef;
    errorMessage: string = null;
    IsDownloadInProgress = false;
    IsPDFDownloadInProgress = false;
    // isBindingDataStarted = false;

    selectedShipmentSubscription: Subscription;
    sensorReportScheduleSubscription: Subscription;
    bussinessSubscription: Subscription;
    getUserSettingSubscription: Subscription;
    getUserSetSubscription: Subscription;
    getShipmentResponseData: GetShipmentResponse;
    isChartBinding$ = this.sensorChartSvc.isChartBinding$;
    isChartLoading$ = this.sensorSvc.isChartLoading$;
    isTempInfoLoading$ = this.sensorSvc.isTempInfoLoading$;
    isChartBinding = false;
    isChartBindingSubscription: Subscription;
    EstimatedCrumbRule = false;
    @Input() download_progress_message;
    @Input() download_progress_title;
    lastSelectedSensors: SensorListInfo[];
    @Input() TraxxCopy: ITraxxState;
    ShowHumidityInSensorChart = false;

    // =====Page Events=====

    constructor(
        private detailSvc: ShipmentDetailService,
        private detailStateSvc: ShipmentDetailStateService,
        private oversightSvc: OversightApiService,
        private sensorSvc: SensorChartService,
        private modalSvc: BsModalService,
        private utilSvc: EmrUtilService,
        private sensorChartSvc: SensorChartDataService,
        private toastr: ToastrService,
        private businessRuleService: BusinessRulesService,
        @Inject(MapServiceFactory) private mapSvc: CustomMapServiceFactory
        // private bindingDataSvc: BindingDataStateService
    ) { }

    @Input() resetSensors = true;
    @Input() filterDateRange: DateRange = null;
    @Input() selectedShipment: Shipment;
    @Input() tempUnits: string;
    @Input() TraxxList: ITraxxState;
    @Input() shipment: CurrentShipment;
    @Input() chartDateTimeFormat: DateTimeFormat;
    @Input()
    set ShipmentResponseData(value) {
        if (value) {
            this.error = null;
            this.locationData = [];
            this.getShipmentResponseData = value;
            if (this.getShipmentResponseData?.Shipment?.Alerts?.length > 0) {
                const alerts = this.detailSvc.getAlertsBetweenSelectedDates(this.getShipmentResponseData?.Shipment?.Alerts, this.selectedDateRange);
                this.alertCount = alerts?.filter(k => k.Pin !== 'SHK')?.length;
                this.shockAlertCount = alerts?.filter(k => k.Pin === 'SHK')?.length;
            }
            this.minvalueYOverride = this.inputMinvalueYOverride;
            this.maxvalueYOverride = this.inputMaxvalueYOverride;
            if (value.Shipment && value.Shipment.Sensors && value.Shipment.Sensors.length > 0) {
                this.shipmentSensorList = value.Shipment.Sensors.map(s => {
                    if (s.Pin === 'G0') {
                        this.isTemperatureSelected = true;
                    }
                    return new SensorInfoListItem({ ...s, SensorName: s.Name }, s.Pin === "SHK" ? this.shockAlertCount : null);
                });
                if (this.detailsCommonData.selectedSensors.length > 0) {
                    if (this.resetSensors) {
                        this.resetSensors = false;
                    }
                }
                if (this.resetSensors) {
                    this.detailsCommonData.selectedSensors = this.shipmentSensorList.filter(a => a.value?.Pin !== 'SHK').map(a => a.value);
                } else {
                    this.detailsCommonData.selectedSensors =
                        this.shipmentSensorList
                            .filter(a => this.detailsCommonData.selectedSensors
                                .findIndex((s) => s.SensorName === a.value.SensorName && a.value.Pin === s.Pin) > -1)
                            .map(a => a.value);
                }
                this.isSensorFilterDirty = false;
                this.showNoSensorsMessage = true;
            } else {
                this.shipmentSensorList = [];
                this.showNoSensorsMessage = true;
            }
            this.generateChart(this.getShipmentResponseData);
        } else {
            this.updateSensorChartData(null);
        }
    }
    IsUnAuthenticated = false;
    ShowGeoFence = false;

    noSensorsMessage() {
        return this.shipmentSensorList && this.shipmentSensorList.length === 0 && this.showNoSensorsMessage;
    }

    public ngOnInit() {
        this.isChartBindingSubscription = this.sensorChartSvc.isChartBinding$.subscribe(b => {
            this.updateSensorChartData(this.sensorChartSvc.shipmentChartData);
            this.updateSensorsLegends();
            this.isChartBinding = b;
        });
        this.IsUnAuthenticated = this.detailSvc.getUnAuthenticationToken();
        this.bussinessSubscription = this.businessRuleService.businessRulesList$.pipe(take(1)).subscribe(k => {
            const ShowEstimatedCrumbsBusinessRule = k.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.ShowEstimatedCrumbs);
            if (ShowEstimatedCrumbsBusinessRule) {
                this.EstimatedCrumbRule = ShowEstimatedCrumbsBusinessRule.Enabled;
                if (localStorage.getItem(environment.showEstimatedCrumbs) === null) {
                    localStorage.setItem(environment.showEstimatedCrumbs, 'true');
                }
            }
            const showLocationColorsInSensorChart = k.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.ShowLocationColorsInSensorChart);
            if (showLocationColorsInSensorChart && showLocationColorsInSensorChart.Enabled) {
                this.detailsCommonData.selectedColor = 2
            } else {
                this.detailsCommonData.selectedColor = 0;
            }
            this.ShowHumidityInSensorChart = k.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.ShowHumidityInSensorChart)?.Enabled;
            if (this.shipmentSensorList?.length > 0) {
                this.updateHumiditySensor();
                this.updateSensorsLegends();
            }
        });
    }

    public ngOnDestroy() {
        if (this.selectedShipmentSubscription) {
            this.selectedShipmentSubscription.unsubscribe();
        }
        if (this.isChartBindingSubscription) {
            this.isChartBindingSubscription.unsubscribe();
        }
        if (this.bussinessSubscription) {
            this.bussinessSubscription.unsubscribe();
        }
        if (this.getUserSettingSubscription) {
            this.getUserSettingSubscription.unsubscribe();
        }
        if (this.getUserSetSubscription) {
            this.getUserSetSubscription.unsubscribe();
        }
    }

    onSensorRangeChange(event: any) {
        this.sensorChartSvc.cleanChartDetails();
        const filterData = _.cloneDeep(this.getShipmentResponseData);
        filterData.Shipment.Sensors = filterData.Shipment.Sensors.filter(x => this.detailsCommonData?.selectedSensors.filter(y => y.Pin === x.Pin).length > 0);
        this.shipmentChartData = _.cloneDeep(this.sensorChartSvc.processSensorChartData(filterData, this.tempUnits));
        this.sensorChartSvc.setChartData(this.shipmentChartData);
        this.isTemperatureSelected = true;
        if (this.detailsCommonData.selectedSensors &&
            this.detailsCommonData.selectedSensors.filter((item) => item.Pin === 'G0').length === 0) {
            this.isTemperatureSelected = false;
            this.detailsCommonData.selectedColor =
                this.detailsCommonData.selectedColor === ReportBackgroundColorType.ShowSensorRangeBackgroundColor ?
                    ReportBackgroundColorType.None : this.detailsCommonData.selectedColor;
        }
        // If Shock sensor selected then need to uncheck remaining
        if (this.detailsCommonData?.selectedSensors?.find(k => k.Pin === 'SHK')) {
            if (!this.isSHKSelected) {
                this.detailsCommonData.selectedSensors = this.detailsCommonData.selectedSensors.filter(k => k.Pin === 'SHK');
                // this.detailsCommonData.showAlertsAndEvents = false;
                this.isSHKSelected = true;
            }
            else if (this.detailsCommonData?.selectedSensors?.length > 1) {
                this.isSHKSelected = false;
                this.detailsCommonData.showAlertsAndEvents = false;
                this.detailsCommonData.selectedSensors = this.detailsCommonData.selectedSensors.filter(k => k.Pin !== 'SHK');
            }
        } else {
            this.isSHKSelected = false;
        }
        this.updateSensorsLegends();
    }

    private updateSensorsLegends() {
        this.showTemp = this.detailsCommonData.selectedSensors && this.detailsCommonData.selectedSensors
            .filter((item) => item.Pin === 'G0').length > 0;
        this.showHumidity = this.detailsCommonData.selectedSensors && this.detailsCommonData.selectedSensors
            .filter((item) => item.Pin === 'HU').length > 0;
        this.showProbe = this.detailsCommonData.selectedSensors && this.detailsCommonData.selectedSensors
            .filter((item) => item.Pin === 'PRB').length > 0;
        this.showCO2 = this.detailsCommonData.selectedSensors && this.detailsCommonData.selectedSensors
            .filter((item) => item.Pin === 'CO2').length > 0;
        this.showSHK = this.detailsCommonData.selectedSensors && this.detailsCommonData.selectedSensors
            .filter((item) => item.Pin === 'SHK').length > 0;
        this.hasTempAxis = this.showTemp || this.showProbe || this.showSHK;
        this.showPlotLines = this.showTemp || (this.detailsCommonData.selectedSensors?.length == 1 && (this.showHumidity || this.showProbe || this.showCO2 || this.showSHK));
        this.plotLines = this.shipmentChartData?.plotLines?.sensorPlotLines?.find(k => {
            if (this.detailsCommonData.selectedSensors?.length == 1 && this.detailsCommonData.selectedSensors[0].Pin == k.Pin) {
                return k;
            }
            else if (this.showTemp && k.Pin == 'G0') {
                return k;
            }
            return null;
        })

    }

    // =====Methods=====

    // =====Min Max=====
    // TODO: Need to revisit the functionality implementation
    onMinMaxChartValuesClick() {
        const InitialState = {
            shipment: this.selectedShipment,
            minValue: this.minvalueYOverride,
            maxValue: this.maxvalueYOverride,
        };
        this.bsModalRef = this.modalSvc.show(
            MinMaxChartValuesComponent,
            {
                initialState: InitialState,
                class: 'modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
        this.bsModalRef.content.updateMinMaxValues.pipe(take(1)).subscribe(n => this.onSaveChartValues(n));
    }

    onSaveChartValues(n: MinMaxValueEventArgs) {
        this.updateMinMaxValues.emit({
            radAutomatic: n.radAutomatic,
            minValue: n.minValue,
            maxValue: n.maxValue
        });
        this.minvalueYOverride = n.minValue;
        this.maxvalueYOverride = n.maxValue;
        this.bsModalRef.hide();
        this.overrideAxisRange = n.radAutomatic ?
            null :
            {
                minValue: parseInt(n.minValue, 10),
                maxValue: parseInt(n.maxValue, 10)
            } as AxisRange;
        this.hasTempAxis = this.showTemp || this.showProbe || this.showSHK;
    }


    // =====Sensor Chart=====

    // onSelectedShipmentSubscription(shipment: Shipment) {
    //     if (this.selectedShipment && this.selectedShipment.tripId) {
    //         this.selectedTimePeriod.timePeriod = TimePeriod.Trip;
    //     }
    // }

    ShowGenerateButton() {
        this.isSensorFilterDirty = true;
    }

    generateChart(shipmentResponse: GetShipmentResponse) {
        // this.detailStateSvc.loadExportSensorReport();
        this.error = null;
        this.noChartDataMsg = false;
        const formattedDates: string[] = [];

        if (shipmentResponse &&
            shipmentResponse.SelectedPeriodRange) {

            if (shipmentResponse.SelectedPeriodRange.From) {
                formattedDates.push(shipmentResponse.SelectedPeriodRange.From);
            }

            if (shipmentResponse.SelectedPeriodRange.To) {
                formattedDates.push(shipmentResponse.SelectedPeriodRange.To);
            }
        }
        this.selectedPeriodRangeString = formattedDates.join(' - ');
        if (this.minvalueYOverride || this.maxvalueYOverride) {
            this.overrideAxisRange = {
                minValue: +this.minvalueYOverride,
                maxValue: +this.maxvalueYOverride
            } as AxisRange;
        } else {
            this.overrideAxisRange = null;
        }
        this.updateHumiditySensor();
        this.updateSensorsLegends();
    }

    private updateHumiditySensor() {
        const bussinessSubscriptionNew = this.businessRuleService.businessRulesList$.pipe(take(1)).subscribe(k => {
            this.ShowHumidityInSensorChart = k.list.find(l => l.BusinessRuleTypeCode === BusinessRuleType.ShowHumidityInSensorChart)?.Enabled;
            const humiditySensor = this.shipmentSensorList?.find(k => k?.value?.Pin === 'HU');
            if (humiditySensor) {
                if (this.getShipmentResponseData?.Shipment?.Sensors?.length > 0) {
                    const index = this.detailsCommonData.selectedSensors.findIndex(k => k.Pin === 'HU');
                    if (!this.ShowHumidityInSensorChart && index >= 0) {
                        this.detailsCommonData.selectedSensors.splice(index, 1);
                    }
                }
            }
            bussinessSubscriptionNew?.unsubscribe();
        });

    }

    private updateSensorChartData(shipmentChartData) {
        this.shipmentChartData = shipmentChartData;
        if (this.shipmentChartData) {
            this.noChartDataMsg = this.sensorChartSvc.shipmentChartData.noChartData;
            this.plotLabels = this.sensorChartSvc.shipmentChartData.locationLabels;
        } else {
            this.noChartDataMsg = false;
            this.plotLabels = [];
            this.shipmentChartData = { clearChart: true };
        }
    }

    onClickExportPDF() {
        const Toast = this.toastr.info(this.download_progress_message, this.download_progress_title, {
            disableTimeOut: true, tapToDismiss: false, enableHtml: true,
            progressBar: true, positionClass: 'toast-bottom-right', toastClass: 'navigation-popup-dynamic'
        });
        const acceptType = 'application/pdf';
        const extraHeaders = new ExportTrackerStateReportHeaders();
        this.IsPDFDownloadInProgress = true;
        extraHeaders.Accept = acceptType;
        extraHeaders.responseType = responseType.blob;
        let queryParams = {
            globalDeviceId: '', period: {}, tripId: 0, pins: '', from: '', thru: '', bgColor: this.detailsCommonData.selectedColor,
            showAlertsAndEvents: this.detailsCommonData.showAlertsAndEvents, isNew: false, isMultiTrip: false, tripState: 0,
            previousTripEndDtm: '', actualStartDtm: '', actualEndDtm: '', scheduledStartDtm: '', scheduledEndDtm: '',
            lastReportedDtm: '', modelNumber: '', isDefaultToLast14Days: false, hasDatesOutsideOfDefault: false
        };
        queryParams.globalDeviceId = this.selectedShipment.trackerId;
        queryParams.pins = this.detailsCommonData.selectedSensors.map(k => k['Pin']).join(',');
        queryParams.tripId = this.selectedShipment.tripId;
        // if (this.getShipmentResponseData.SelectedPeriodRange.Period === TimePeriod.SpecificRange) {
        //     queryParams.from = this.getShipmentResponseData.SelectedPeriodRange.FromDtm ?
        //         this.utilSvc.DateFormatLocaleChange(this.getShipmentResponseData.SelectedPeriodRange.FromDtm) : null;
        //     queryParams.thru = this.getShipmentResponseData.SelectedPeriodRange.ToDtm ?
        //         this.utilSvc.DateFormatLocaleChange(this.getShipmentResponseData.SelectedPeriodRange.ToDtm) : null;
        // }
        // queryParams.period = this.getShipmentResponseData.SelectedPeriodRange.Period;

        queryParams = this.setMultiTripQueryParams(queryParams);
        return this.sensorSvc.exportPDFSensorReport(queryParams, extraHeaders).subscribe(response => {
            const blob = new Blob([response], {
                type: acceptType
            });
            const fileName = this.selectedShipment.trackerId + '.pdf';
            FileSaver.saveAs(blob, fileName);
            this.toastr.remove(Toast.toastId);
            this.toastr.success(this.downloadCompleted, '', {
                positionClass: 'toast-bottom-right', timeOut: 2000
            });
            this.IsPDFDownloadInProgress = false;
        }, e => {
            this.toastr.remove(Toast.toastId);
            this.toastr.success(e.message, '', {
                positionClass: 'toast-bottom-right', timeOut: 2000
            });
            this.IsPDFDownloadInProgress = false;
        });
    }

    onExcelExportClick() {
        const Toast = this.toastr.info(this.download_progress_message, this.download_progress_title, {
            disableTimeOut: true, tapToDismiss: false, enableHtml: true,
            progressBar: true, positionClass: 'toast-bottom-right', toastClass: 'navigation-popup-dynamic'
        });
        this.IsDownloadInProgress = true;
        const acceptType = 'application/vnd.ms-excel';
        const extraHeaders = new ExportTrackerStateReportHeaders();
        extraHeaders.Accept = acceptType;
        extraHeaders.responseType = responseType.blob;
        let queryParams = {
            globalDeviceId: '', period: {}, tripId: 0, pins: '', from: '', thru: '', bgColor: this.detailsCommonData.selectedColor,
            showAlertsAndEvents: this.detailsCommonData.showAlertsAndEvents, isNew: false, isMultiTrip: false, tripState: 0,
            previousTripEndDtm: '', actualStartDtm: '', actualEndDtm: '', scheduledStartDtm: '', scheduledEndDtm: '',
            lastReportedDtm: '', modelNumber: '', isDefaultToLast14Days: false, hasDatesOutsideOfDefault: false
        };
        queryParams.globalDeviceId = this.selectedShipment.trackerId;
        queryParams.pins = this.detailsCommonData.selectedSensors.map(k => k['Pin']).join(',');
        queryParams.tripId = this.selectedShipment.tripId;
        // if (this.getShipmentResponseData.SelectedPeriodRange.Period === TimePeriod.SpecificRange) {
        //     queryParams.from = this.getShipmentResponseData.SelectedPeriodRange.FromDtm ?
        //         this.utilSvc.DateFormatLocaleChange(this.getShipmentResponseData.SelectedPeriodRange.FromDtm) : null;
        //     queryParams.thru = this.getShipmentResponseData.SelectedPeriodRange.ToDtm ?
        //         this.utilSvc.DateFormatLocaleChange(this.getShipmentResponseData.SelectedPeriodRange.ToDtm) : null;
        // }
        // queryParams.period = this.getShipmentResponseData.SelectedPeriodRange.Period;

        queryParams = this.setMultiTripQueryParams(queryParams);
        return this.sensorSvc.exportExcelSensorReport(queryParams, extraHeaders).subscribe(response => {
            const blob = new Blob([response], {
                type: acceptType
            });
            const fileName = this.selectedShipment.trackerId + '.xlsx';
            FileSaver.saveAs(blob, fileName);
            this.toastr.remove(Toast.toastId);
            this.toastr.success(this.downloadCompleted, '', {
                positionClass: 'toast-bottom-right', timeOut: 2000
            });
            this.IsDownloadInProgress = false;
        }, (e: IErrorInfo) => {
            this.openSubmitResponse(e.message);
            this.toastr.remove(Toast.toastId);
            this.toastr.success(e.message, '', {
                positionClass: 'toast-bottom-right', timeOut: 2000
            });
            this.IsDownloadInProgress = false;
        });
    }

    setMultiTripQueryParams(queryParams: any) {
        if (this.latestShipmentRequest) {
            queryParams.IsBD09 = this.latestShipmentRequest.IsBD09;
            queryParams.isNew = this.latestShipmentRequest.IsNew;
            queryParams.isMultiTrip = this.latestShipmentRequest.IsMultiTrip;
            queryParams.tripState = this.latestShipmentRequest.TripState;
            queryParams.previousTripEndDtm = this.latestShipmentRequest.PreviousTripEndDtmString ?
                this.latestShipmentRequest.PreviousTripEndDtmString : '';
            queryParams.futureTripEndDtm = this.latestShipmentRequest.FutureTripEndDtmString ?
                this.latestShipmentRequest.FutureTripEndDtmString : '';
            queryParams.actualEndDtm = this.latestShipmentRequest.ActualEndDtmString ?
                this.latestShipmentRequest.ActualEndDtmString : '';
            queryParams.actualStartDtm = this.latestShipmentRequest.ActualStartDtmString ?
                this.latestShipmentRequest.ActualStartDtmString : '';
            queryParams.period = this.latestShipmentRequest.Period ? this.latestShipmentRequest.Period : '';

            if (this.latestShipmentRequest.Period === TimePeriod.SpecificRange) {
                queryParams.from = this.latestShipmentRequest.From;
                queryParams.thru = this.latestShipmentRequest.Thru;
            }

            //OR-2667 , scheduledStartDtm:'', scheduledEndDtm:''
            queryParams.scheduledStartDtm = this.latestShipmentRequest.ScheduledStartTimeString ?
                this.latestShipmentRequest.ScheduledStartTimeString : '';
            queryParams.scheduledEndDtm = this.latestShipmentRequest.ScheduledEndTimeString ?
                this.latestShipmentRequest.ScheduledEndTimeString : '';

            //OR-2683 
            queryParams.lastReportedDtm = this.latestShipmentRequest.LastReportedDtmString ?
                this.latestShipmentRequest.LastReportedDtmString : '';
            queryParams.modelNumber = this.latestShipmentRequest.ModelNumber ?
                this.latestShipmentRequest.ModelNumber : '';
            queryParams.modelName = this.latestShipmentRequest.ModelName ?
                this.latestShipmentRequest.ModelName : '';
            queryParams.isDefaultToLast14Days = this.latestShipmentRequest.IsDefaultToLast14Days;
            queryParams.hasDatesOutsideOfDefault = this.latestShipmentRequest.HasDatesOutsideOfDefault ?
                this.latestShipmentRequest.HasDatesOutsideOfDefault : false;

            if (this.selectedDateRange) {
                queryParams.period = TimePeriod.SpecificRange;
                queryParams.from = this.selectedDateRange.startDateTime ?
                    this.utilSvc.DateFormatLocaleChange(this.selectedDateRange.startDateTime) : '';
                queryParams.thru = this.selectedDateRange.endDateTime ?
                    this.utilSvc.DateFormatLocaleChange(this.selectedDateRange.endDateTime) : '';
            }
        }
        return queryParams;
    }

    openSubmitResponse(errorMessage: string) {
        this.errorMessage = errorMessage;
        this.errorModalRef = this.modalSvc.show(
            this.errorModalTemplate,
            {
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }

    onOkClick() {
        this.errorMessage = null;
        this.errorModalRef.hide();
    }

    // onEmailReportClick() {
    //     this.detailStateSvc.loadExportSensorReport();
    //     this.error = null;
    //     const request = new SensorReportScheduleRequest();
    //     request.TrackerId = this.selectedShipment.trackerId;
    //     request.ReportBackgroundColorType = this.selectedColor;
    //     request.Pin = this.selectedSensors[0].Pin;

    //     this.sensorReportScheduleSubscription = this.oversightSvc.SensorReportSchedule(request).subscribe(n => {
    //         this.openSensorChartResponse(n.LocalizedErrorMessage);
    //         this.sensorReportScheduleSubscription.unsubscribe();
    //     });
    // }

    onSendEmailReport() {
        this.toastr.success(this.emailSent, '', {
            positionClass: 'toast-bottom-right', timeOut: 2000
        });

        const request = new ShipmentRequest();
        request.IsBD09 = this.mapSvc.isLoadBaiduMapsFn();
        request.DeviceId = this.selectedShipment.customerTrackerId;
        request.GlobalDeviceId = this.selectedShipment.trackerId;
        request.Pins = this.detailsCommonData.selectedSensors.map(a => a.Pin).join(',');
        request.BackgroundColor = this.detailsCommonData.selectedColor;
        request.Period = this.getShipmentResponseData.SelectedPeriodRange.Period;
        // request.Period = this.selectedTimePeriod.timePeriod;
        request.TripId = this.selectedShipment.tripId;
        request.IsIncludingAlertsAndEvents = this.detailsCommonData.showAlertsAndEvents;
        let ShowEstimatedCrumb = true;
        if (this.selectedShipment.Is4G) {
            const data = localStorage.getItem(environment.showEstimatedCrumbs);
            if (data !== null) {
                ShowEstimatedCrumb = (data === 'true');
            }
        }
        request.IsEstimatingCrumbs = this.selectedShipment && !!this.selectedShipment.Is4G && !this.IsUnAuthenticated
            && this.EstimatedCrumbRule ? ShowEstimatedCrumb : false;

        if (this.latestShipmentRequest) {
            request.IsBD09 = this.latestShipmentRequest.IsBD09;
            request.Period = this.latestShipmentRequest.Period;
            request.IsNew = this.latestShipmentRequest.IsNew;
            request.IsMultiTrip = this.latestShipmentRequest.IsMultiTrip;
            request.TripState = this.latestShipmentRequest.TripState;
            request.PreviousTripEndDtm = this.latestShipmentRequest.PreviousTripEndDtm;
            request.FutureTripEndDtm = this.latestShipmentRequest.FutureTripEndDtm;
            request.ActualStartDtm = this.latestShipmentRequest.ActualStartDtm;
            request.ActualEndDtm = this.latestShipmentRequest.ActualEndDtm;
            request.ScheduledStartDtm = this.latestShipmentRequest.ScheduledStartDtm;
            request.ScheduledEndDtm = this.latestShipmentRequest.ScheduledEndDtm;
            request.LastReportedDtm = this.latestShipmentRequest.LastReportedDtm;
            request.ModelNumber = this.latestShipmentRequest.ModelNumber;
            request.ModelName = this.latestShipmentRequest.ModelName;
            request.IsDefaultToLast14Days = this.latestShipmentRequest.IsDefaultToLast14Days;
            request.HasDatesOutsideOfDefault = this.latestShipmentRequest.HasDatesOutsideOfDefault;
            if (this.latestShipmentRequest.Period === TimePeriod.SpecificRange) {
                request.From = this.latestShipmentRequest.From;
                request.Thru = this.latestShipmentRequest.Thru;
            }
        }
        if (this.selectedDateRange) {
            request.Period = TimePeriod.SpecificRange;
            request.From = this.selectedDateRange.startDateTime ?
                this.utilSvc.DateFormatLocaleChange(this.selectedDateRange.startDateTime) : '';
            request.Thru = this.selectedDateRange.endDateTime ?
                this.utilSvc.DateFormatLocaleChange(this.selectedDateRange.endDateTime) : '';
        }

        this.sensorReportScheduleSubscription = this.oversightSvc.EmailShipmentDetail(request).subscribe(n => {
            // this.toastr.success(this.emailSent, '', {
            //     positionClass: 'toast-bottom-right', timeOut: 2000
            // });
            if (this.sensorReportScheduleSubscription) {
                this.sensorReportScheduleSubscription.unsubscribe();
            }
        }, e => {
            this.toastr.success(e.message, '', {
                positionClass: 'toast-bottom-right', timeOut: 2000
            });
        });
    }

    openSensorChartResponse(errorMessage: string) {
        this.detailStateSvc.loadExportSensorReportSuccess();
        const bsModalRef = this.modalSvc.show(
            SensorChartResponseComponent,
            {
                initialState: {
                    errorMessage
                },
                class: 'modal-md modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );

        bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onSensorChartResponseOk(n));
    }

    onSensorChartResponseOk(args: SensorChartResponseOkEventArgs) {
        args.modalRef.hide();
    }
}
