<div class="row mx-1">
    <div class="col-md-12">
        <div>
            <emr-checkbox identifier="shipments-filter-alert-above-temp" text="Above Temperature" i18n-text="@@above_temp" cssClass="line-height-25"
                [(ngModel)]="filter.aboveTemp" (ngModelChange)="onSelectionChanged()">
            </emr-checkbox>
        </div>
        <div>
            <emr-checkbox identifier="shipments-filter-alert-below-temp" text="Below Temperature" i18n-text="@@below_temp" cssClass="line-height-25"
                [(ngModel)]="filter.belowTemp" (ngModelChange)="onSelectionChanged()">
            </emr-checkbox>
        </div>
        <div *ngIf="enableLateFilter">
            <emr-checkbox identifier="shipments-filter-alert-late" text="Late" i18n-text="@@late" cssClass="line-height-25"
                [(ngModel)]="filter.late" (ngModelChange)="onSelectionChanged()">
            </emr-checkbox>
        </div>
        <!-- <div>
            <emr-checkbox identifier="shipments-filter-alert-moving" text="Moving" i18n-text="@@moving" cssClass="line-height-25"
                [(ngModel)]="filter.moving" (ngModelChange)="onSelectionChanged()">
            </emr-checkbox>
        </div> -->
        <div>
            <emr-checkbox identifier="shipments-filter-alert-notMoving" text="Not Moving" i18n-text="@@not_moving" cssClass="line-height-25"
                [(ngModel)]="filter.notMoving" (ngModelChange)="onSelectionChanged()">
            </emr-checkbox>
        </div>
</div>
</div>