import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShipmentViewComponent } from './components/shipment-view/shipment-view.component';
import { ShipmentDetailModule } from 'app-modules/shipment-detail/shipment-detail.module';
import { CoreModule } from 'app-modules/core/core.module';
import { FormsModule } from '@angular/forms';
import { EmrNgControlsModule, EmrNgMapApiModule } from 'emr-ng-shared';



@NgModule({
  declarations: [
    ShipmentViewComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    EmrNgControlsModule,
    EmrNgMapApiModule,
    ShipmentDetailModule
  ]
})
export class DcEditShipmentModule { }
