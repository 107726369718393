import { Component, EventEmitter, OnDestroy, OnInit, Optional, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { alertSeverityDesc } from 'app-modules/core/consts/alert-type';
import { add_template, Destination, edit_template, label_shipment_template, Origin, Origin_Destination, save_template } from 'app-modules/core/consts/localization';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { StopLocation } from 'app-modules/core/models/stop-location.model';
import { CreateShipmentService } from 'app-modules/core/services/create-shipment.service';
import { ShipmentDetailService } from 'app-modules/core/services/shipment-detail.service';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { CoolDownTypeService } from 'app-modules/core/store/cool-down-type/cool-down-type.service';
import { LinkedCustomersService } from 'app-modules/core/store/create-shipment/linked-customers/linked-customer.service';
import { TripStartOptionsService } from 'app-modules/core/store/create-shipment/trip-start-options/trip-start-options.service';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { TripStopModel } from 'app-modules/core/store/models/trip-stop.model';
import { ShipmentTemplateService } from 'app-modules/core/store/shipment-template/shipment-template.service';
import { TimeZoneService } from 'app-modules/core/store/time-zone/time-zone.service';
import { VehicleGroupsService } from 'app-modules/core/store/vehicle-group/vehicle-groups.service';
import { BusinessRuleType, CoolDownTypeInfo, CreateShipmentRequest, DualVisibilityLinkCustomer, GetTripTemplateDetailsResponse, LocationInfo, Lookup, SensorRangeInfo, SensorType, TimeZoneInfo, TripStartOptions, TripTemplateInfo, VehicleGroupInfo } from 'emr-ng-shared';
import { environment } from 'environments/environment';
import _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ErrorMessageResponseComponent, EventArgs } from '../error-message-response/error-message-response.component';
import { AddLocationEventArgs, LocationComponent } from '../location/location.component';
import { SensorRangeComponent, SensorRangeEventArgs } from '../sensor-range/components/sensor-range/sensor-range.component';
import { StopLocationListComponent } from '../stop-location-list/stop-location-list.component';


export class LocationInfoListItem {
  get text() { return this.locationInfo.LocationName + ' - ' + this.locationInfo.Address1; }
  get value() { return this.locationInfo.LocationId; }
  get name() { return this.locationInfo.LocationName; }
  constructor(private locationInfo: LocationInfo) { }
}

export class AddTemplateEventArgs {
  context?: any;
}

@Component({
  selector: 'app-create-shipment-template',
  templateUrl: './create-shipment-template.component.html',
  styleUrls: ['./create-shipment-template.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CreateShipmentTemplateComponent implements OnInit, OnDestroy {

  @ViewChild(NgForm) templateForm: NgForm;
  @ViewChild(StopLocationListComponent) child: StopLocationListComponent;

  SaveBtnText = add_template;
  isEditTemplate = false;
  isFromManageTemplates = false;
  headerTitle: string = label_shipment_template;
  shipment = new CreateShipmentRequest();
  controlColumns = 9;
  disableSubmitButton = false;
  DisplayTripStartOptions = false;
  randomNumber: number;
  isAlertContactCollapsed = true;
  isEndLocationUndefined = false;
  isCreateOptionsDisbabled = false;

  isVehiclesLoading$: Observable<Boolean>;
  vehicleGroupIds: VehicleGroupInfo[];
  VehicleGroups: VehicleGroupInfo[];
  DisplayVehicleGroupingInCreateShipment: boolean = false;

  sensorRangeList$: any;
  isSensorListLoading$: Observable<Boolean>;

  isDistinctOriginStopLocation = true;
  isDistinctDestinationStopLocation = true;
  originLocations$: Observable<LocationInfoListItem[]>;
  stops: TripStopModel[] = [];
  tripStopsCopy: TripStopModel[] = [];
  stopLocations: LocationInfoListItem[];
  stopLocations$: Observable<LocationInfoListItem[]>;
  destLocations$: Observable<LocationInfoListItem[]>;
  isLocationListLoading$: Observable<Boolean>;

  timeZoneList$: Observable<IListInfo<TimeZoneInfo>>;
  isTimeZoneLoading$: Observable<boolean>;

  tripStartOptionsList$: Observable<TripStartOptions[]>;
  isTripStartOptionsLoading$: Observable<boolean>;

  coolDownTypeList$: Observable<CoolDownTypeInfo[]>;
  isCoolDownLoading$: Observable<boolean>;
  public locationModalRef: BsModalRef;
  public sensorRangeModalRef: BsModalRef;
  public errorMsgModalRef: BsModalRef;

  templateDetails: GetTripTemplateDetailsResponse;
  // To support Multi sensors
  showCO2Sensor = false;
  showProbSensor = false;
  showHumiditySensor = false;

  TemperatureRangeId: number;
  HumiditySensorId: number;
  CarbonDioxideSensorId: number;
  ProbSensorId: number;

  linkedShippersPushList$: Observable<DualVisibilityLinkCustomer[]>;

  revalidateStop = new EventEmitter<StopLocation>();
  @Output() addTemplate = new EventEmitter<AddLocationEventArgs>();

  businessRulesListSubscription: Subscription;
  stopLocationsSubscription: Subscription;
  vehicleGroupListSubscription: Subscription;

  originListSub: Subscription;
  destListSub: Subscription;
  sensorSub: Subscription;

  sensorType = SensorType;
  IsCarrierDropDown = false;
  IsCarrierFieldRequired = false;
  hideHumidity = false;
  carrierList: Lookup[];
  carrierListSubscription: Subscription;

  constructor(
    private modalSvc: BsModalService,
    private createSvc: CreateShipmentService,
    @Optional() public bsModalRef: BsModalRef,
    private timeZoneSvc: TimeZoneService,
    private coolDownSvc: CoolDownTypeService,
    private tripStartSvc: TripStartOptionsService,
    private businessruleSvc: BusinessRulesService,
    private linkedCustSvc: LinkedCustomersService,
    private vehicleGroupSvc: VehicleGroupsService,
    private templateSvc: ShipmentTemplateService,
    private detailSvc: ShipmentDetailService,
  ) { }

  ngOnDestroy(): void {
    if (this.originListSub) {
      this.originListSub.unsubscribe();
      this.originListSub = null;
    }
    if (this.destListSub) {
      this.destListSub.unsubscribe();
      this.destListSub = null;
    }
    if (this.stopLocationsSubscription) {
      this.stopLocationsSubscription.unsubscribe();
      this.stopLocationsSubscription = null;
    }
    if (this.businessRulesListSubscription) {
      this.businessRulesListSubscription.unsubscribe();
      this.businessRulesListSubscription = null;
    }

    if (this.vehicleGroupListSubscription) {
      this.vehicleGroupListSubscription.unsubscribe();
      this.vehicleGroupListSubscription = null;
    }
    if (this.carrierListSubscription) {
      this.carrierListSubscription?.unsubscribe();
      this.carrierListSubscription = null;
    }
  }

  ngOnInit(): void {

    this.getBusinessRules();

    this.shipment.TripStops = [];
    this.randomNumber = Math.floor(Math.random() * Math.floor(100));

    this.isLocationListLoading$ = this.createSvc.isLoadingLocationsList$;
    this.isSensorListLoading$ = this.createSvc.isLoadingSensorsList$;
    this.isCoolDownLoading$ = this.coolDownSvc.isLoading$;
    this.isTimeZoneLoading$ = this.timeZoneSvc.isLoading$;
    this.sensorRangeList$ = this.createSvc.sensorRangeList$;
    this.timeZoneList$ = this.timeZoneSvc.timeZoneList$;
    this.coolDownTypeList$ = this.coolDownSvc.coolDownTypeList$.pipe(map(n => n.list));

    this.isVehiclesLoading$ = this.vehicleGroupSvc.isLoading$;
    this.vehicleGroupListSubscription = this.vehicleGroupSvc.getVehicleGroupList().subscribe(k => {
      this.VehicleGroups = k;
    });

    this.linkedShippersPushList$ = this.linkedCustSvc.linkedShipperPushList$.pipe(map(n =>
      n.list.filter(itm => itm.ShipperCustomerActive ||
        itm.ShipperCustomerID === this.shipment.LinkedCustomerId)));

    this.isTripStartOptionsLoading$ = this.tripStartSvc.isLoading$;
    this.tripStartOptionsList$ = this.tripStartSvc.tripStartOptionsList$.pipe(map(n => {
      this.setBusinessRuleValues(0);
      return n.list;
    }));

    this.originLocations$ = this.createSvc.originList$.pipe(map(n => n.list.map(item => new LocationInfoListItem(item))));
    this.stopLocationsSubscription =
      this.createSvc.stopList$.subscribe(n => this.stopLocations = n.list.map(item => new LocationInfoListItem(item)));

    this.destLocations$ = this.createSvc.destinationList$.pipe(map(n => {
      return n.list.map(item => new LocationInfoListItem(item));
    }));

    this.carrierListSubscription = this.createSvc.loadCarrierList().subscribe(k => {
      this.carrierList = k;
    });

    this.bindTripTemplateDetails(this.templateDetails);
  }

  // Initialize values based on BusinessRule
  setBusinessRuleValues(value) {
    this.shipment.TripStartOptionID = this.DisplayTripStartOptions ? (value ? value : 0) : null;
  }

  getBusinessRules() {
    this.businessRulesListSubscription = this.businessruleSvc.businessRulesList$.subscribe(n => {
      if (n.list && n.list.length > 0) {
        this.DisplayVehicleGroupingInCreateShipment = n.list.find(k => k.BusinessRuleTypeCode ===
          BusinessRuleType.DisplayVehicleGroupingInCreateShipment)?.Enabled;
        this.DisplayTripStartOptions = n.list.find(k => k.BusinessRuleTypeCode ===
          BusinessRuleType.DisplayTripStartOptions)?.Enabled;
        this.IsCarrierDropDown = !!n.list.find(k => k.BusinessRuleTypeCode ===
          BusinessRuleType.CarrierNameLookupDropdown)?.Enabled;
        this.IsCarrierFieldRequired = !!n.list.find(k => k.BusinessRuleTypeCode ===
          BusinessRuleType.CarrierNameRequired)?.Enabled;
        this.hideHumidity = !!n.list.find(k => k.BusinessRuleTypeCode ===
          BusinessRuleType.HideHumidity)?.Enabled;
      }
    });
  }

  bindTripTemplateDetails(templateDetails: GetTripTemplateDetailsResponse) {
    if (templateDetails) {

      const request = new CreateShipmentRequest;
      request.TripStops = [];
      if (templateDetails.VehicleGroupIds && this.DisplayVehicleGroupingInCreateShipment) {
        const vehicleGroupIds = [];
        templateDetails.VehicleGroupIds.forEach(element => {
          const isVehicle = this.VehicleGroups.find(k => k.VehicleGroupId === element);
          if (isVehicle) {
            const vehicle = new VehicleGroupInfo();
            vehicle.VehicleGroupId = element;
            vehicle.Name = isVehicle.Name;
            vehicleGroupIds.push(vehicle);
          }
        });
        // Delay Assignments...
        setTimeout(() => {
          this.vehicleGroupIds = vehicleGroupIds;
        });
      } else {
        this.vehicleGroupIds = null;
      }

      const templateInfo = templateDetails.TemplateInfo ? templateDetails.TemplateInfo : null;

      if (templateInfo) {

        // const tripEndOptionID = templateInfo.TripEndOptionID !== undefined &&
        //   templateInfo.TripEndOptionID !== null && this.DisplayTripEndOptions ?
        //   templateInfo.TripEndOptionID : null;
        // this.setBusinessRuleDestinationValues(tripEndOptionID);

        request.ShipperID = templateInfo.ShipperCustomerID;
        request.TripTemplateName = templateInfo.Name;
        request.TripTemplateID = templateInfo.TripTemplateId ? templateInfo.TripTemplateId : null;
        request.OriginLocationId = templateInfo.OriginLocationId ?
          templateInfo.OriginLocationId : null;
        request.DestinationLocationId = templateInfo.DestinationLocationId ?
          templateInfo.DestinationLocationId : null;
        if (request.DestinationLocationId === request.OriginLocationId && request.DestinationLocationId !== null) {
          setTimeout(() => { request.OriginLocationId = null; }, 30);
        }
        this.TemperatureRangeId = this.HumiditySensorId = this.CarbonDioxideSensorId = this.ProbSensorId = null;
        this.TemperatureRangeId = templateInfo.SensorRangeId ? templateInfo.SensorRangeId : null;
        if (templateInfo.SensorRangeList && templateInfo.SensorRangeList.length > 0) {
          templateInfo.SensorRangeList.forEach(a => {
            switch (a.TypeCode) {
              case (SensorType.Temperature):
                this.TemperatureRangeId = a.SensorRangeId;
                break;
              case (SensorType.Humidity):
                this.HumiditySensorId = a.SensorRangeId;
                break;
              case (SensorType.CarbonDioxide):
                this.CarbonDioxideSensorId = a.SensorRangeId;
                break;
              case (SensorType.ProbeTemp):
                this.ProbSensorId = a.SensorRangeId;
                break;
            }
          });
        }
        request.NoTemperatureRequired = false;
        request.CooldownTypeCode = templateInfo.CooldownTypeCode !== undefined ? templateInfo.CooldownTypeCode : null;
        request.AlertTimeZone = templateInfo.AlertTimeZone ? templateInfo.AlertTimeZone : null;
        request.CarrierName = templateInfo.CarrierName ? templateInfo.CarrierName : null;
        request.EmailAddresses = templateInfo.EmailAddresses ? templateInfo.EmailAddresses : null;
        request.L1 = templateInfo ? templateInfo.L1 : false;
        request.L2 = templateInfo ? templateInfo.L2 : false;
        request.L3 = templateInfo ? templateInfo.L3 : false;
        request.L4 = templateInfo ? templateInfo.L4 : false;
        request.L5 = templateInfo ? templateInfo.L5 : false;
        request.L6 = templateInfo ? templateInfo.L6 : false;
        request.EstimatedFlightMinutes = templateInfo &&
          templateInfo.EstimatedFlightMinutes ? templateInfo.EstimatedFlightMinutes : null;
        request.EstimatedLoadingMinutes = templateInfo &&
          templateInfo.EstimatedLoadingMinutes ? templateInfo.EstimatedLoadingMinutes : null;
        request.TripStops = [];
        this.stops = [];
        if (templateDetails.Contacts) {
          const stops = this.createSvc.tripContactsToStopsModel(templateDetails.Contacts, this.stopLocations);
          stops.map((a, i) => {
            a.Index = i;
            if (a.AirportArrival || a.AirportDeparture) {
              if (!a.ArrivalLocationId || !a.LocationId)
                a.IsInValid = true;
              else
                a.IsInValid = false;
            }
            if (a.AirportArrival && !a.AirportDeparture) {
              a.AirportDeparture = true;
            }
          });
          this.stops = stops;
          this.tripStopsCopy = _.cloneDeep(stops);
        }

        this.shipment = request;

        this.SaveBtnText = this.shipment.TripTemplateID ? save_template : add_template;

        const tripStartOptionID = templateInfo.TripStartOptionID !== undefined &&
          templateInfo.TripStartOptionID !== null && this.DisplayTripStartOptions ?
          templateInfo.TripStartOptionID : null;

        setTimeout(() => {
          this.setBusinessRuleValues(tripStartOptionID);
        }, 200);
      }
    }
  }

  private reValidationForm = () => {
    for (const key in this.templateForm.controls) {
      this.templateForm.controls[key].markAsDirty();
      this.templateForm.controls[key].updateValueAndValidity();
    }
  }

  onUpdateClick() {
    this.reValidationForm();
    if (this.templateForm.valid && (!this.child || this.child.stopsForm.valid)) {
      const stops = this.detailSvc.getUpdatedTripStops(this.stops, this.tripStopsCopy);
      this.shipment.TripStops = this.createSvc.tripStopModelToStops(stops);

      if (this.vehicleGroupIds) {
        this.shipment.VehicleGroupIds = this.vehicleGroupIds.map(i => i.VehicleGroupId);
      }

      if (this.shipment.OriginLocationId > 0) {
        this.originListSub = this.originLocations$.pipe(take(1)).subscribe(o => {
          this.shipment.OriginName = o.find(l => l.value === this.shipment.OriginLocationId)?.name
        });
      }

      if (this.shipment.DestinationLocationId > 0) {
        this.destListSub = this.destLocations$.pipe(take(1)).subscribe(o => {
          this.shipment.DestinationName = o.find(l => l.value === this.shipment.DestinationLocationId)?.name
        });
      }

      this.shipment.SensorRangeId = this.shipment.NoTemperatureRequired ? null : this.shipment.SensorRangeId;
      this.shipment.SensorRangeInfo = [];

      let sensorRangeInfo = new SensorRangeInfo();
      sensorRangeInfo.TypeCode = SensorType.Temperature;
      sensorRangeInfo.SensorRangeId = this.TemperatureRangeId ? this.TemperatureRangeId : 0;
      this.shipment.SensorRangeInfo.push(sensorRangeInfo);

      sensorRangeInfo = new SensorRangeInfo();
      sensorRangeInfo.TypeCode = SensorType.Humidity;
      sensorRangeInfo.SensorRangeId = this.HumiditySensorId ? this.HumiditySensorId : 0;
      this.shipment.SensorRangeInfo.push(sensorRangeInfo);

      sensorRangeInfo = new SensorRangeInfo();
      sensorRangeInfo.TypeCode = SensorType.CarbonDioxide;
      sensorRangeInfo.SensorRangeId = this.CarbonDioxideSensorId ? this.CarbonDioxideSensorId : 0;
      this.shipment.SensorRangeInfo.push(sensorRangeInfo);

      sensorRangeInfo = new SensorRangeInfo();
      sensorRangeInfo.TypeCode = SensorType.ProbeTemp;
      sensorRangeInfo.SensorRangeId = this.ProbSensorId ? this.ProbSensorId : 0;
      this.shipment.SensorRangeInfo.push(sensorRangeInfo);

      if (this.shipment.EmailAddresses) {
        this.shipment.EmailAddresses = this.shipment.EmailAddresses.split(',').map(k => k.trim()).join(',');
      }
      this.templateSvc.UpdateShipmentTemplate(this.shipment).pipe(take(1)).subscribe((n) => {
        if (n) {
          this.openSubmitResponse(n.ErrorCode == 0 ? false : true, n.LocalizedErrorMessage, n.ErrorCode == 0 ? true : false,
            this.headerTitle, n.ErrorCode == 100 ? n.ErrorDescription : n.LocalizedErrorMessage);
        }
      }, (e: IErrorInfo) => {
        this.openSubmitResponse(true, e.message, false, this.headerTitle, e.debugMessage)
      });
    }
  }

  openSubmitResponse(hasError: boolean, errorMessage: string, closeOnOk: boolean, headerTitle: string, debugErrorMessage: string = null,) {
    const bsModalRef = this.modalSvc.show(
      ErrorMessageResponseComponent,
      {
        initialState: {
          hasError,
          errorMessage,
          closeOnOk,
          debugErrorMessage,
          sucessMessage: !hasError ? errorMessage : null,
          headerTitle: headerTitle
        },
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    bsModalRef.content.ok.pipe(take(1)).subscribe(n => this.onResponseOk(n));
  }

  onResponseOk(args: EventArgs) {
    args.modalRef.hide();

    if (!args.hasError) {
      if (args.closeOnOk && this.bsModalRef) {
        this.bsModalRef.hide();
      } else {
        // Do Nothing
      }
    }
  }

  onCancelClick() {
    this.bsModalRef.hide();
  }

  selectDistinctLocation(selectedLocation: any, locationType: string) {
    this.isDistinctOriginStopLocation = true;
    this.isDistinctDestinationStopLocation = true;
  }

  openAddLocationModal(locationType: any) {
    let locationTypes: any[];
    let passShipperCustomerID = null;
    let i: number;
    let IsArrival: boolean;
    if (typeof locationType === 'object') {
      i = locationType['i'];
      IsArrival = locationType['IsArrival'];
      locationType = locationType['data']
    }

    const originItem = { text: Origin, value: 'Origin' };
    const bothItem = { text: Origin_Destination, value: 'Both' };
    const destItem = { text: Destination, value: 'Destination' };

    switch (locationType) {
      case 'origin':
        locationTypes = [
          bothItem,
          originItem
        ];
        passShipperCustomerID = true;
        break;
      case 'tripstop':
        locationTypes = [
          bothItem,
          originItem,
          destItem
        ];
        break;
      case 'destination':
        locationTypes = [
          bothItem,
          destItem
        ];
        passShipperCustomerID = true;
        break;
    }

    this.locationModalRef = this.modalSvc.show(
      LocationComponent,
      {
        initialState: {
          locationTypes,
          context: locationType,
          passShipperCustomerID
        },
        class: 'modal-md modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    this.locationModalRef.content.addLocation.pipe(take(1)).subscribe(n => this.onAddLocation(n, i, IsArrival));
  }

  onAddLocation(args: AddLocationEventArgs, i: number, IsArrival: boolean) {
    switch (args.context) {
      case 'origin':
        this.shipment.OriginLocationId = args.request.LocationId;
        break;
      case 'destination':
        this.shipment.DestinationLocationId = args.request.LocationId;
        break;
      case 'tripstop':
        if (!this.stops ||
          i < 0 ||
          this.stops.length < i) {
          return;
        }
        const stop = this.stops[i];
        const selectedLocation = this.stopLocations ? this.stopLocations.find(k => k.value === args.request.LocationId) : null;
        const locationName = selectedLocation ? selectedLocation.text : null;
        if (IsArrival) {
          stop.ArrivalLocationId = args.request.LocationId;
          stop.ArrivalLocationName = locationName;
        } else {
          stop.LocationId = args.request.LocationId;
          stop.LocationName = locationName;
        }
        this.revalidateStop.emit({ Stop: stop, Index: i, IsArrival });
        break;
    }

    this.locationModalRef.hide();
  }

  openAddSensorRangeModal(type) {
    this.sensorRangeModalRef = this.modalSvc.show(
      SensorRangeComponent,
      {
        initialState: { SensorType: type },
        class: 'modal-sm modal-dialog-centered',
        ignoreBackdropClick: true
      }
    );

    this.sensorRangeModalRef.content.addSensorRange.subscribe(n => this.onAddSensorRange(n));
  }

  onAddSensorRange(args: SensorRangeEventArgs) {
    setTimeout(() => {
      switch (args.request.SensorTypeCode) {
        case SensorType.Temperature:
          this.TemperatureRangeId = args.request.SensorRangeId;
          break;
        case SensorType.Humidity:
          this.HumiditySensorId = args.request.SensorRangeId;
          break;
        case SensorType.CarbonDioxide:
          this.CarbonDioxideSensorId = args.request.SensorRangeId;
          break;
        case SensorType.ProbeTemp:
          this.ProbSensorId = args.request.SensorRangeId;
          break;
      }
    }, 50);
    // setTimeout(() => { this.shipment.SensorRangeId = args.request.SensorRangeId; }, 50);
    this.sensorRangeModalRef.hide();
    // request.RetailerId = this.shipment.LinkedCustomerId;
  }

}
