
import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';

import * as actionIndex from 'app-modules/core/store/actions';
import { IPreferenceState } from 'app-modules/core/store/models/preference-state.interface';

export class LoadPreference implements ServerRequestAction {
    readonly type = actionIndex.LOAD_PREFERENCE;
}

export class LoadPreferenceSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_PREFERENCE_SUCCESS;
    readonly callingAction = actionIndex.LOAD_PREFERENCE;
    constructor(public preference: IPreferenceState) { }
}

export class LoadPreferenceError implements ServerRequestCompleteAction {
    readonly type = actionIndex.LOAD_PREFERENCE_ERROR;
    readonly callingAction = actionIndex.LOAD_PREFERENCE;

    constructor(public message: string) { }
}

export class CancelLoadPreference implements ServerRequestCompleteAction {
    readonly type = actionIndex.CANCEL_LOAD_PREFERENCE;
    readonly callingAction = actionIndex.LOAD_PREFERENCE;
}
