import { Action } from '@ngrx/store'; 
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import * as actionIndex from 'app-modules/core/store/actions';
import { FormattedStrings } from 'emr-ng-shared';

export class LoadPageSize implements Action {
    readonly type = actionIndex.LOAD_PAGE_SIZE;
}

export class LoadPageSizeSuccess implements Action {
    readonly type = actionIndex.LOAD_PAGE_SIZE_SUCCESS;
    constructor(public pageSizeList: IListInfo<FormattedStrings>) { }
}

export class LoadPageSizeError implements Action {
    readonly type = actionIndex.LOAD_PAGE_SIZE_ERROR;
    constructor(public message: string) { }
}

export class CancelPageSize implements Action {
    readonly type = actionIndex.CANCEL_LOAD_PAGE_SIZE;
}





