<div class="col-12 px-2">
    <form name="frmLocationChange" #addressChangeForm="ngForm" emrForm autocomplete="off" class="">
        <div class="row">
            <div class="col-12">
                <app-address-search identifier="txtSearchLocationNew" name="txtSearchLocationNew"
                    label="Address Search" i18n-label="@@label_address_search" placeholder="Address Search"
                    i18n-placeholder="@@label_address_search" appendIcon="fa fa-search"
                    [isChinaAddress]="isChinaAddress" formGroupCssClass="col-12" [resetAddress]="resetAddressField"
                    (addressSelected)="onAddressSelected($event)">
                </app-address-search>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 pr-0">
                <emr-textbox ngDefaultControl inputType=number identifier="txtNewLocLat" label="Latitude"
                    i18n-label="@@sensor_report_header_latitude" [(ngModel)]="newLocRequest.Latitude" name="txtLocLat"
                    labelCssClass="mb-0 col-12" (ngModelChange)="onCoordinatesChange()" required type="number"
                    [range]="{ min: -90, max: 90 }" (onBlur)="onCoordinatesChanged()">
                    <emr-validation-message validatorName="required" message="Latitude Required"
                        i18n-message="@@latitude_required"></emr-validation-message>
                    <emr-validation-message validatorName="range" message="Latitude should be between -90 and 90"
                        i18n-message="@@latitude_range_validation"></emr-validation-message>
                </emr-textbox>
            </div>
            <div class="col-md-4 pl-0">
                <emr-textbox ngDefaultControl inputType=number identifier="txtNewLocLong" label="Longitude"
                    i18n-label="@@sensor_report_header_longitude" [(ngModel)]="newLocRequest.Longitude"
                    name="txtLocLong" labelCssClass="mb-0 col-12" (ngModelChange)="onCoordinatesChange()" required
                    type="number" [range]="{ min: -180, max: 180 }"  (onBlur)="onCoordinatesChanged()">
                    <emr-validation-message validatorName="required" message="Longitude Required"
                        i18n-message="@@longitude_required"></emr-validation-message>
                    <emr-validation-message validatorName="range" message="Longitude should be between -180 and 180"
                        i18n-message="@@longitude_range_validation"></emr-validation-message>
                </emr-textbox>
            </div>
            <div class="col-md-4 pl-0">
                <div class="col-12 pl-0">
                    <emr-button identifier="btnValidateAddress" buttonText="Find Address" buttonType="submit"
                        i18n-buttonText="@@label_find_address" cssClass="btn btn-outline-default w-100 m-0 btn-find"
                        (onClick)="onfindAddressClick()" [setDisabled]="!newLocRequest.ValidationRequired">
                    </emr-button>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!!address">
            <div class="col-md-12 address-search">
                <div class="col-md-12 pr-0">
                    <label class="label-css font-14" i18n="@@label_selected_address">
                        Selected Address
                    </label><span>:</span>
                    <div class="label-css">
                    <span class="label-css">{{ address.FormattedAddress }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showNearestLocations">
            <div class="col-md-12">
                <div class="color-red label-css"><i class="fa fa-exclamation-triangle color-red" aria-hidden="true"></i>
                    <span class="color-red" i18n="@@message_warn_duplicate_location"> Warning Matching locations already
                        exists</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <emr-button identifier="btnBack"
                    cssClass="btn-lg btn-primary quick-link btn-block m-0 my-2" buttonText="Back" [setDisabled]="disableClick"
                    i18n-buttonText="@@label_back" (onClick)="onBackClick()">
                </emr-button>
            </div>
            <div class="col-md-6" *ngIf="!showNearestLocations">
                <emr-button identifier="btnUpdatelocation" buttonType="submit" [setDisabled]="!address"
                    cssClass="btn-lg btn-primary quick-link btn-block m-0 my-2" buttonText="Update Location"
                    i18n-buttonText="@@label_update_location" (onClick)="onUpdateLocation()">
                </emr-button>
            </div>
            <div class="col-md-6" *ngIf="showNearestLocations">
                <emr-button identifier="btnConfirmlocation" buttonType="submit" [setDisabled]="!address"
                    cssClass="btn-lg btn-primary quick-link btn-block m-0 my-2" buttonText="Confirm Location"
                    i18n-buttonText="@@label_Confirm_location" (onClick)="onConfirmClick()">
                </emr-button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 address-search">
                <div *ngIf="showNearestLocations" class="default-dropdown">
                    <div class="address-options-div cursor-default">
                        <a *ngFor="let loc of nearestLocations| slice:0:5; let i = index;"
                            [ngStyle]="{'color': '#757575de' }"
                            class=" dropdown-item dropdown-text alt-text cursor-default">
                            <span>
                                <div *ngIf="loc.LocationName?.trim()?.length > 0" class="label-css">
                                    <span>{{loc.LocationName}}</span>
                                </div>
                                <div *ngIf="loc.Address1?.trim()?.length > 0" class="label-css">
                                    <span>{{loc.Address1.trim()+', '}}</span>
                                </div>
                                <div class="label-css">
                                    <span *ngIf="loc.City?.trim()?.length > 0">{{loc.City.trim()+', '}}</span>
                                    <span *ngIf="loc.State?.trim()?.length > 0">{{loc.State.trim()+' '}}</span>
                                    <span *ngIf="loc.Zip?.trim()?.length > 0">{{loc.Zip.trim()}}</span>
                                </div>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 pb-2">
                <span class="validationindicator font-12">
                    <app-error-message [error]="error"></app-error-message>
                </span>
            </div>
        </div>
    </form>
</div>