import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IAuthState } from 'app-modules/core/store/models/auth-state.interface';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import * as loadingActions from 'app-modules/core/store/actions/loading.actions';
import * as AuthActions from 'app-modules/core/store/auth/auth.actions';
import { Fullstory } from 'emr-ng-shared';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { StoreManager, StoreOpts } from 'app-modules/core/utils/store-manager';
import { STORE_KEYS } from 'app-modules/core/consts/values';

@Injectable()
export class AuthStateService {
    constructor(private store: Store<IAppState>,
        private fullStory: Fullstory) { }

    // Selectors
    public authStateSelector = ((state: IAppState) => state.auth);
    public authenticatedSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.authenticated
    );
    public authTokenSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.authToken
    );
    public isEmersonAdminSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.isEmersonAdmin
    );
    public canCreateShipmentSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.canCreateShipment
    );
    public canAccessOversight = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.canAccessOversight
    );
    public rememberMeSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.rememberMe
    );
    public isLocusUserSupportAdminSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.isLocusUserSupportAdmin
    );
    public refreshTokenSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.refreshToken
    );

    public isPasswordChangeRequiredSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.IsPasswordChangeRequired
    );

    public canEditTripName = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.canEditTripName
    );

    public isDashboardUserSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.isDashboardUser
    );
    
    public isAdminUserSelector = createSelector(
    this.authStateSelector,
    (state: IAuthState) => state.isAdminUser
    );
    

    public isEDIAPIConsumerSelector = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.isEDIAPIConsumer
    );

    public isLocusSupport = createSelector(
        this.authStateSelector,
        (state: IAuthState) => state.isLocusSupport
    );


    // Observables
    public authState$ = this.store.select(this.authStateSelector).pipe();
    public authenticated$ = this.store.select(this.authenticatedSelector).pipe();
    public authToken$ = this.store.select(this.authTokenSelector).pipe();
    public rememberMe$ = this.store.select(this.rememberMeSelector).pipe();
    public refreshToken$ = this.store.select(this.refreshTokenSelector).pipe();
    public isEmersonAdmin$ = this.store.select(this.isEmersonAdminSelector).pipe();
    public isDashboardUser$ = this.store.select(this.isDashboardUserSelector).pipe();
    public isAdminUser$ = this.store.select(this.isAdminUserSelector).pipe();
    public isEDIAPIConsumer$ = this.store.select(this.isEDIAPIConsumerSelector).pipe();
    public canCreateShipment$ = this.store.select(this.canCreateShipmentSelector).pipe();
    public isPasswordChangeRequired$ = this.store.select(this.isPasswordChangeRequiredSelector).pipe();
    public canAccessOversight$ = this.store.select(this.canAccessOversight).pipe();
    public canEditTripName$ = this.store.select(this.canEditTripName).pipe();
    public isLocusUserSupportAdmin$ = this.store.select(this.isLocusUserSupportAdminSelector).pipe();
    private isUserSignedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    // Actions
    public signIn() {
        this.store.dispatch(new AuthActions.SignIn());
    }

    public signInSuccess(
        authToken: string,
        refreshToken: string,
        username: string,
        displayName: string,
        temperatureUnits: string,
        distanceUnits: string,
        dateFormatEnum: number,
        dateFormatString: string,
        isEmersonAdmin: boolean,
        canCreateShipment: boolean,
        rememberMe: boolean,
        providerUserKey: string,
        IsPasswordChangeRequired: boolean,
        canAccessOversight: boolean,
        canAccessPakSense?: boolean,
        canEditTripName?: boolean,
        isLocusUserSupportAdmin?: boolean,
        isLocusUser?: boolean,
        isDashboardUser?: boolean,
        isAdminUser?:boolean,
        isEDIAPIConsumer?:boolean,
        isLocusSupport?: boolean
    ) {
        sessionStorage.removeItem(environment.unAuthTrackers);
        this.fullStory.login(providerUserKey, {
            email: username
        });
        this.store.dispatch(new AuthActions.SignInSuccess(
            authToken,
            refreshToken,
            username,
            displayName,
            temperatureUnits,
            distanceUnits,
            dateFormatEnum,
            dateFormatString,
            isEmersonAdmin,
            canCreateShipment,
            rememberMe,
            providerUserKey,
            IsPasswordChangeRequired,
            canAccessOversight,
            canAccessPakSense,
            canEditTripName,
            isLocusUserSupportAdmin,
            isLocusUser,
            isDashboardUser,
            isAdminUser,
            isEDIAPIConsumer,
            isLocusSupport
        ));
    }

    public signInError(error: IErrorInfo) {
        this.store.dispatch(new AuthActions.SignInError(error));
    }

    public signOut() {
        localStorage.setItem(environment.authPersistentData, null);
        localStorage.setItem(environment.selectedCustomer, null);
        StoreManager.StoreValue(STORE_KEYS.psAuthToken, null,
            // tslint:disable-next-line: no-bitwise
            StoreOpts.LocalStorage | StoreOpts.Cookie | StoreOpts.StoreManager);
        this.store.dispatch(new AuthActions.SignOut());
        this.fullStory.logout();
        setTimeout(() => this.store.dispatch(new loadingActions.CloseSpinner()));
    }

    public RefreshToken(authToken: string) {
        this.store.dispatch(new AuthActions.RefreshToken(
            authToken
        ));
    }

    public changePassword() {
        this.store.dispatch(new AuthActions.ChangePassword());
    }

    public changePasswordSuccess() {
        this.store.dispatch(new AuthActions.ChangePasswordSuccess());
    }

    public changePasswordError(message: string) {
        this.store.dispatch(new AuthActions.ChangePasswordError(message));
    }

    public cancelChangePassword() {
        this.store.dispatch(new AuthActions.CancelChangePassword());
    }
    public forgotPassword() {
        this.store.dispatch(new AuthActions.ForgotPassword());
    }

    public forgotPasswordSuccess() {
        this.store.dispatch(new AuthActions.ForgotPasswordSuccess());
    }

    public forgotPasswordError(message: string) {
        this.store.dispatch(new AuthActions.ForgotPasswordError(message));
    }

    public cancelForgotPassword() {
        this.store.dispatch(new AuthActions.CancelForgotPassword());
    }

    public setUserLoginState(value: boolean): void {
        this.isUserSignedIn$.next(value);
    }

    public getUserLoginState(): Observable<boolean> {
        return this.isUserSignedIn$;
    }
}
