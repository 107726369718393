import { Injectable } from '@angular/core';

import { Store, createSelector } from '@ngrx/store';

import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import {
    ChangeShipmentFilter, CustomChangeShipmentFilter,
    ChangeShipmentFilterWithOutShipmentListUpdate,
    UpdateIsDefaultFilterState
} from 'app-modules/core/store/shipment-filter/shipment-filter.actions';

@Injectable()
export class ShipmentFilterStateService {
    constructor(private store: Store<IAppState>) { }

    // Selectors
    public shipmentFilterStateSelector = ((state: IAppState) => state.shipmentFilter);
    public trackerStateSelector = ((state: IAppState) => state.tracker);
    public timePeriodStateSelector = ((state: IAppState) => state.timePeriod);
    public serialNumberFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.serialNumber
    );
    public selectedTrackerSelector = createSelector(
        this.serialNumberFilterSelector,
        this.trackerStateSelector,
        (item, list) => list.trackerList.list.find(a => a.customerTrackerId === item)
    );
    public statusFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.status
    );
    public periodFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.period
    );
    public selectedPeriodSelector = createSelector(
        this.periodFilterSelector,
        this.timePeriodStateSelector,
        (item, list) => list.timePeriodList.list.find(a => a.Period === item.timePeriod)
    );
    public alertTypeFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.alert
    );
    public originFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.origin
    );
    public distributionCenterFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.dcTripStopLocation
    );
    public destinationFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.destination
    );
    public sensorRangeFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.sensorRange
    );
    public carrierFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.carrier
    );
    public supplierFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.supplierCustomer
    );
    public shipmentNameFilterSelector = createSelector(
        this.shipmentFilterStateSelector,
        (state: IShipmentFilterState) => state.shipmentName
    );
    public isSerialShipmentNameSearch = createSelector(
        this.serialNumberFilterSelector,
        this.shipmentNameFilterSelector,
        (sno: string, tripName: string) => !!(sno || tripName)
    );

    // Observables
    public shipmentFilter$ = this.store.select(this.shipmentFilterStateSelector).pipe();
    public serialNumberFilter$ = this.store.select(this.serialNumberFilterSelector).pipe();
    public shipmentNameFilter$ = this.store.select(this.shipmentNameFilterSelector).pipe();
    public selectedTracker$ = this.store.select(this.selectedTrackerSelector).pipe();
    public statusFilter$ = this.store.select(this.statusFilterSelector).pipe();
    public periodFilter$ = this.store.select(this.periodFilterSelector).pipe();
    public selectedPeriod$ = this.store.select(this.selectedPeriodSelector).pipe();
    public alertTypeFilter$ = this.store.select(this.alertTypeFilterSelector).pipe();
    public originFilter$ = this.store.select(this.originFilterSelector).pipe();
    public distributionCenterFilter$ = this.store.select(this.distributionCenterFilterSelector).pipe();
    public destinationFilter$ = this.store.select(this.destinationFilterSelector).pipe();
    public sensorRangeFilter$ = this.store.select(this.sensorRangeFilterSelector).pipe();
    public carrierFilter$ = this.store.select(this.carrierFilterSelector).pipe();
    public supplierFilter$ = this.store.select(this.supplierFilterSelector).pipe();
    public isSerialShipmentNameSearch$ = this.store.select(this.isSerialShipmentNameSearch).pipe();

    // Actions
    public changeShipmentFilter(filter: IShipmentFilterState) {
        this.store.dispatch(new ChangeShipmentFilter(filter));
    }

    public changeShipmentFilterWithOutShipmentListUpdate(filter: IShipmentFilterState) {
        this.store.dispatch(new ChangeShipmentFilterWithOutShipmentListUpdate(filter));
    }

    public customChangeShipmentFilter() {
        this.store.dispatch(new CustomChangeShipmentFilter());
    }

    public setIsDefaultFilterState(isDefault: boolean) {
        this.store.dispatch(new UpdateIsDefaultFilterState(isDefault));
    }
}

