import { ILatLong } from "emr-ng-shared";

export class FindAddressRequest {
    location: ILatLong;
    fullAddress: string;
    AddressLine1?: string;
    City?: string;
    StateDescription?: string;
    CountryCode?: string;
    PostalCode?: string;
    isAddressLoadRequired: boolean;
}