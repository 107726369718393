import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { CustomTableColumnDefs, TripStateInfo } from 'emr-ng-shared';
import { combineLatest, Subscription } from 'rxjs';
import { IShipmentFilterStatus } from 'app-modules/core/store/models/shipment-filter-status.interface';
import { ShipmentStatusService } from 'app-modules/core/services/shipment-status.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { shipmentStatus } from 'app-modules/core/consts/localization';
import { Shipment } from 'app-modules/core/models/shipment.model';

@Component({
    selector: 'app-shipment-filter-status-summary',
    templateUrl: 'shipment-filter-status-summary.component.html',
    styleUrls: ['shipment-filter-status-summary.component.css']
}) export class ShipmentFilterStatusSummaryComponent implements OnInit, OnDestroy {
    statusList: TripStateInfo[] = [];
    filter: number[];
    selectedList: TripStateInfo[] = [];
    @Input() shipmentFilter: IShipmentFilterState;
    @Input() isHugeCustomer: boolean;
    @Output() summaryRemove = new EventEmitter();

    statusListSubscription: Subscription;
    statusFilterSubscription: Subscription;

    constructor(
        private statusSvc: ShipmentStatusService,
        private shipmentFilterService: ShipmentFilterService
    ) { }

    ngOnInit() {
        this.statusListSubscription = this.statusSvc.shipmentStatusList$.subscribe(n => this.onStatusListSubscription(n.list));
        this.statusFilterSubscription = this.shipmentFilterService.statusFilter$.subscribe(n => this.onStatusFilterSubscription(n));
    }

    ngOnDestroy() {
        if (this.statusListSubscription) {
            this.statusListSubscription.unsubscribe();
        }
        if (this.statusFilterSubscription) {
            this.statusFilterSubscription.unsubscribe();
        }
    }

    onStatusListSubscription(statusList: TripStateInfo[]) {
        this.statusList = statusList;
        this.filterStatusList();
    }

    onStatusFilterSubscription(filter: number[]) {
        this.filter = filter;
        this.filterStatusList();
    }

    filterStatusList() {
        this.selectedList = [];

        if (this.filter) {
            this.statusList.forEach(item => {
                if (this.filter.indexOf(item.TripStateCode) >= 0) {
                    this.selectedList.push(item);
                }
            });
        }
    }

    clearSelectedFilter(statusCode: number) {
        this.shipmentFilter.status = this.shipmentFilter.status.filter(k => k !== statusCode);
        this.shipmentFilterService.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        if (this.summaryRemove) {
            this.summaryRemove.emit({ title: shipmentStatus, value: this.shipmentFilter.status });
        }
    }
}


