import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation, OnDestroy } from '@angular/core';

import { Observable, combineLatest, Subscription } from 'rxjs';

import { BusinessRuleType, CustomTableColumnDefs, TimePeriodInfo } from 'emr-ng-shared';

import { IShipmentFilterSerialNumber } from 'app-modules/core/store/models/shipment-filter-serial-number.interface';
import { IShipmentFilterShipmentName } from 'app-modules/core/store/models/shipment-filter-shipment-name.interface';
import { ITimePeriod } from 'app-modules/core/store/models/time-period.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { LocationService } from 'app-modules/core/services/location.service';
import { SensorRangeService } from 'app-modules/core/store/sensor-range/sensor-range.service';
import { CarrierService } from 'app-modules/core/services/carrier.service';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-shipment-filter-summary',
    templateUrl: './shipment-filter-summary.component.html',
    styleUrls: ['../../styles/shipment-filter.css', './shipment-filter-summary.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class ShipmentFilterSummaryComponent implements OnInit, OnDestroy {
    @Input() showFilterDefaultButton = false;
    @Input() shipmentFilter: IShipmentFilterState;
    @Output() clearPeriod = new EventEmitter();
    @Output() resetFilter = new EventEmitter();
    @Output() loadFilterToInitial = new EventEmitter();
    @Input() isHugeCustomer = false;
    isFormLoading$: Observable<Boolean>;
    @Output() summaryRemove = new EventEmitter();

    serialNumberFilter$: Observable<string>;
    shipmentNameFilter$: Observable<string>;
    periodFilter$: Observable<ITimePeriod>;
    selectedPeriod$: Observable<TimePeriodInfo>;
    isSerialShipmentNameSearchSub: Subscription;
    IsUnAuthenticated: boolean;
    isFilterDirty: boolean = false;
    isSerialShipmentNameSearch = false;
    constructor(
        private shpFltrSvc: ShipmentFilterService,
        private locationSvc: LocationService,
        private rangeSvc: SensorRangeService,
        public carrierSvc: CarrierService) {
        this.serialNumberFilter$ = shpFltrSvc.serialNumberFilter$;
        this.periodFilter$ = shpFltrSvc.periodFilter$;
        this.selectedPeriod$ = shpFltrSvc.selectedPeriod$;
        this.shipmentNameFilter$ = shpFltrSvc.shipmentNameFilter$;
        this.isSerialShipmentNameSearchSub = shpFltrSvc.isSerialShipmentNameSearch$.subscribe(b => this.isSerialShipmentNameSearch = b);
    }

    @Input()
    set isDirty(value: boolean) {
        this.isFilterDirty = value;
    }
    ngOnInit(): void {
        this.IsUnAuthenticated = this.shpFltrSvc.GetUnAuthenticationToken();
        this.isFormLoading$ = combineLatest(
            this.locationSvc.isLoading$,
            this.rangeSvc.isLoading$,
            this.carrierSvc.isLoading$,
            (isLocationListLoading, isSensorListLoading, isCarrierListLoading) => {
                return isSensorListLoading || isLocationListLoading || isCarrierListLoading;
            });
        // console.log('ShipmentFilterSummaryComponent ngOnInit');
    }

    ngOnDestroy() {
        if (this.isSerialShipmentNameSearchSub) {
            this.isSerialShipmentNameSearchSub.unsubscribe();
            this.isSerialShipmentNameSearchSub = null;
        }
    }

    onClearClick() {
        this.clearPeriod.emit();
    }

    onFilterReset() {
        this.resetFilter.emit();
    }
    onLoadFilterToInitial() {
        this.loadFilterToInitial.emit();
    }
    public onSummaryRemove(data) {
        if (this.summaryRemove) {
            this.summaryRemove.emit(data);
        }
    }

    clearSelectedFilter(name: string,value:string) {
        this.shipmentFilter[name] = this.shipmentFilter[name]?.filter(k => k !== value);
        this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);

        this.onSummaryRemove({ title: name, value: this.shipmentFilter[name] });
    }
}


