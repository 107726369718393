import { Component, OnInit } from '@angular/core';
import { GetHistoryTripRequest } from 'emr-ng-shared';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-show-viewlog-popup',
  templateUrl: './show-viewlog-popup.component.html',
  styleUrls: ['./show-viewlog-popup.component.css']
})
export class ShowViewlogPopupComponent implements OnInit {

  constructor(
    public bsShowViewLogModalRef: BsModalRef
  ) { }

  getHistoryTripRequest: GetHistoryTripRequest;

  ngOnInit() {
  }

  onPopupCancel() {
    this.bsShowViewLogModalRef.hide();
  }
}
