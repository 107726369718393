<app-banner></app-banner>
<app-header></app-header>
<div class="container-fluid margin-top-46" register-activity>
    <div class="row">
        <div class="w-100">
            <app-menu></app-menu>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div id="overlay" *ngIf="isLoading$ | async">
    <div style="width:100%;height:100%;margin:auto; margin-top:43vh" class="spinner">
        <div></div>
        <div></div>
    </div>
</div>
<div class="modal-backdrop show" *ngIf="!hideSideMenu"></div>