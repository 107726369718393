<emr-modal autocomplete="off" [headerTitle]="headerTitle"
    [bsModalRef]=bsModalRef>
    <div modal-body class="col-md-12 bg-report-schedule">
        <div class="row">
            <div class="col-12">
                <div class="" >
                    <app-trip-report
                        *ngIf="selectedReport === reportType.ComprehensiveTripReport || 
                                selectedReport === reportType.TripEndSummary || 
                                selectedReport === reportType.TripStopSummaryExtendedReport ||
                                selectedReport === reportType.InboundOutboundReport"
                        [isNewScheduleReport]="isNewReport" [(reportRequestParams)]="reportParams"
                        [validate]="isFormSubmitted" [selectedReportType]="selectedReport" [bindEditParams]="editParams" >
                    </app-trip-report>
                    <app-alert-report 
                        *ngIf="selectedReport === reportType.AlertStatus  ||
                               selectedReport === reportType.AlertTrackerFleet"
                        [isNewScheduleReport]="isNewReport" [savedParams]="editParams" [(reportRequestParams)]="reportParams"
                        [validate]="isFormSubmitted" [selectedReportType]="selectedReport">
                    </app-alert-report>
                    <app-fleet-report 
                        *ngIf="selectedReport === reportType.FleetCurrentStatus  ||
                                selectedReport === reportType.InProgressShipment" [isNewScheduleReport]="isNewReport"
                        [(reportRequestParams)]="reportParams" [validate]="isFormSubmitted" [selectedReportType]="selectedReport"
                        [bindEditParams]="editParams">
                    </app-fleet-report>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-report-scheduler [request]="request" [reportParams]="reportParams"
                    [isNewScheduleReport]="isNewReport" [sendNow]="isEmailReport"
                    [showFormatButton]="showFormatButton"
                    [selectedEmails]="selectedEmails"
                    (onValidate)="onReportSubmit($event)">
                </app-report-scheduler>
            </div>
        </div>
    </div>
</emr-modal>