import { ICreateShipmentPopup } from 'app-modules/core/models/create-shipment-popup.interface';
import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { CreateShipmentComponent } from 'app-modules/create-shipment/components/create-shipment/create-shipment.component';

@Injectable()
export class CreateShipmentPopup extends ICreateShipmentPopup {

    bsModalRef: BsModalRef;

    constructor(private modalSvc: BsModalService) {
        super();
    }

    OpenCreateShipmentPopup(serialNumber?: string, customerTrackerId?: string, isMultiTripSerial?: boolean): void {

        const createShipmentInitialState = {
            serialNumber: serialNumber,
            customerTrackerId: customerTrackerId,
            isMultiTripSerial: isMultiTripSerial
        };

        this.bsModalRef = this.modalSvc.show(
            CreateShipmentComponent,
            {
                initialState: createShipmentInitialState,
                class: 'max-width-1000 modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }
}

