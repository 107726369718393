export class Tracker {
    trackerId: string;
    customerTrackerId: string;
    groupIndex: number;
    groupName: string;
    color: string;
    lastFourChars: string;
    shipmentName: string;
    receiverPO: string;
}
