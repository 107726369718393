import {
    Component, OnInit, OnDestroy, Renderer2, Input, ViewChild,
    ViewEncapsulation, EventEmitter, TemplateRef, ElementRef, AfterViewInit, ChangeDetectorRef
} from '@angular/core';
import { formatDate } from '@angular/common';

import { Observable, of, Subscription, combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { map, mergeMap, withLatestFrom, tap, filter, take } from 'rxjs/operators';

import {
    BusinessRuleType, MapTypeId, MarkerTypeId, IMapOptions, IMarkerIconInfo, IBox, Marker,
    IMarkerOptions, MarkerEventArgs, EmrUtilService, UserSettingsType, SetUserSettingRequest, CustomTableColumnDefs,
    ExportTrackerStateReportHeaders, responseType, InfoBoxPlacement, IMarkerMetadata, ILatLong, ShareTrackerRequest
} from 'emr-ng-shared';
import * as FileSaver from 'file-saver';

import { Shipment } from 'app-modules/core/models/shipment.model';
import { ShipmentService } from 'app-modules/core/services/shipment.service';

import { OversightPopupService } from 'app-modules/core/services/oversight-popup-service';
import { IShipmentDetailsInfo } from 'app-modules/core/models/shipment-detail-info.interface';

import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import {
    IShipmentFilterState, getMapViewDefaultShipmentFilter, getSelectedDefaultShipmentFilter,
    setDefaultShipmentFilter, getDefaultShipmentFilter, getDefaultUnAuthShipmentFilter
} from 'app-modules/core/store/models/shipment-filter-state.interface';
import { TrackerService } from 'app-modules/core/services/tracker.service';
import { Tracker } from 'app-modules/core/models/tracker.model';

import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { TimePeriodPageFilter } from 'app-modules/core/store/time-period/time-period.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { CustomersService } from 'app-modules/core/services/customer.service';
import { UserSettingsService } from 'app-modules/core/store/user-settings/user-settings.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageMapService } from 'app-modules/core/services/image-map.service';
import { IErrorInfo } from 'app-modules/core/models/error-info.interface';
import { downloadCompleted, downloadProgress } from 'app-modules/core/consts/localization';
import { ShipmentListComponent } from 'app-modules/core/components/c-shipment-list/shipment-list.component';
import { UnAuthService } from 'app-modules/core/store/un-auth/un-auth.service';
import { USER_SETTING_KEY_CUSTOM_FILTERS, USER_SETTING_SECTION_SETTINGS } from 'app-modules/core/consts/user-setting';
import { BusinessRulesService } from 'app-modules/core/store/business-rules/business-rules.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MapViewComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() IsHistorical = false;
    combineSubscription: Subscription;
    businessRulesListSubscription: Subscription;
    shipmentFilter: IShipmentFilterState;
    shipmentFilterSubscription: Subscription;
    isFilterDirty = false;
    isFilterPinned = false;
    Trackers: Tracker[];
    Trackers$: Observable<Tracker[]>;
    isApplyChange = false;
    AppliedShipmentFilter: IShipmentFilterState;
    CustomShipmentFilter: IShipmentFilterState;
    PreviousSearchedText: any;
    isZoomed = false;
    isMapLoaded = false;
    zoomIndex = 8;
    previousZoomIndex = 0;
    mapCenterLat: number;
    mapCenterLong: number;
    autoRefresh = false;
    trackerListSub: Subscription;
    showMarkers = false;
    markerOptions: IMarkerOptions[] = [];
    LastTwoMarkerEvent = new Array<MarkerEventArgs>(2);
    ShowInfoBox = new EventEmitter<MarkerEventArgs>();
    ShowSelectedMarkers = new EventEmitter<string[]>();
    HideInfoBox = new EventEmitter<void>();
    ScrollToTableTop = new EventEmitter<void>();
    allTrackersBox: any;
    shipmentAddressSub: Subscription;
    IsDownloadInProgress: boolean = false;
    isHistoricalFilterApplied$ = new BehaviorSubject(false);
    apiShipmentList$: Observable<Shipment[]>;
    shipmentAPIResultsList$: Observable<Shipment[]>;
    listViewFilter = TimePeriodPageFilter.ListViewFilter;
    historicalFilter = TimePeriodPageFilter.HistoricalFilter;
    savingDefaultFilter = new BehaviorSubject<boolean>(false);
    isSavingDefaultFilter$ = this.savingDefaultFilter.asObservable();
    clustersDisplay = '';
    public isZoomedInfoLevel: boolean = true;
    public isInfoZoomInAction: boolean = false;
    loadShowRouteFullScreen = false;
    showRouteHeight = '';
    showRoute = false;
    selectedShipmentMarkerOptions: IMarkerOptions;
    isLoadRequired: boolean;
    isLoadRequiredSub: Subscription;
    showRouteShipment: Shipment;
    // serialNumberFilter$: Observable<IShipmentFilterSerialNumber>;
    // shipmentNameFilter$: Observable<IShipmentFilterShipmentName>;
    // periodFilter$: Observable<ITimePeriod>;
    // selectedPeriod$: Observable<TimePeriodInfo>;
    previousAppliedShipmentSerial: string;
    tableHeight: string;
    tablefilterHeight: string;
    itemsThreshold = 1000;
    apiResultsCount: number;
    filteredList: Shipment[];
    bsModalRef: BsModalRef;
    emailAddresses: string;

    private MarkerListChanged: Subject<void> = new Subject<void>();
    MarkerList: Array<Marker> = []; //A collection of Marker objects created based on this.markerOptions
    MarkersUpdated = false;
    openMarkerRevisit: Array<Shipment> = [];
    @ViewChild(ShipmentListComponent)
    private shipmentListComponent: ShipmentListComponent;
    unAuthCustomerLocSub: Subscription;
    filterApplied: boolean = false;
    autoEnableRefresh: boolean = false;
    @ViewChild(NgForm) shareLocationForm: NgForm;

    constructor(
        public shpSvc: ShipmentService,
        public shipSvc: ShipmentService,
        private renderer: Renderer2,
        private shpFltrSvc: ShipmentFilterService,
        private showPopups: OversightPopupService,
        public trackSvc: TrackerService,
        private toastr: ToastrService,
        private utilSvc: EmrUtilService,
        private authSvc: AuthService,
        private custSvc: CustomersService,
        private userSettingsSvc: UserSettingsService,
        private modalService: BsModalService,
        private imgMapSvc: ImageMapService,
        public unAuthSvc: UnAuthService,
        private cdr: ChangeDetectorRef,
        private businessruleSvc: BusinessRulesService,
        private modalSvc: BsModalService
    ) {
        this.iconInfo = null;
        this.renderer.addClass(document.body, 'bodyCss');
    }

    openningInfoBoxIsList: boolean = false;
    shipmentList$: Observable<Shipment[]>;
    searchInput: string;

    isLoadingSubscription: Subscription;
    TrackerSubscription: Subscription;
    customerSubscription: Subscription;
    unAuthScubscription: Subscription;
    selectedShipment: Shipment;
    serialOptionList$: Observable<Tracker[]>;

    isLoading: boolean;
    shipmentList: Shipment[];
    shipmentListCount: number = 0;

    collapseMapView = false;
    collapseListView = false;
    displayFilter = false;
    isItDefaultState = false;
    showQuickReset = false;

    IsUnAuthenticated = false;
    customerID: string;
    errorMessage: string = null;
    errorModalRef: BsModalRef;
    userSettingsSubscription: Subscription;
    getShipmentSubscription: Subscription;
    loadAddressSubscription: Subscription;
    saveUserSubscription: Subscription;
    @ViewChild('hiddenButton') hiddenButton: any;
    @ViewChild('clusterButton') clusterButton: any;
    @ViewChild('filterSummaryBox') filterSummaryBox: ElementRef;
    @ViewChild('resizeIconBox') resizeIconBox: ElementRef;
    @ViewChild('quickSearchBox') quickSearchBox: ElementRef;
    columnDefs: CustomTableColumnDefs[];
    @ViewChild('columnSelectionModal') columnSelectionModal: TemplateRef<any>;
    @ViewChild('errorModal') public errorModalTemplate: TemplateRef<any>;
    @ViewChild('shareModal') shareModal: TemplateRef<any>;
    modalRef: BsModalRef;
    mapShipmentSubscription: Subscription;
    isInitialDataLoaded = false;
    previousBounds; IBox;


    public options: IMapOptions = {
        disableBirdseye: false,
        disableStreetside: false,
        navigationBarMode: 1,
        zoom: 3,
        mapTypeId: MapTypeId.road
    };

    private defaultBox = this.custSvc?.defaultBox;

    public box: IBox = this.defaultBox;
    public previousBox: IBox;
    public bounds: IBox;
    public CenterAutoFit = InfoBoxPlacement.InPlaceAutoFit;

    public clusterIconInfo = {
        id: 'myClusterMarker',
        markerType: MarkerTypeId.FontMarker,
        fontName: 'FontAwesome',
        fontSize: 24,
        color: 'red',
        markerOffsetRatio: { x: 0.5, y: 1 },
        text: '\uF276'
    };

    public clustering = false;
    public gridSize = 50;

    public ranges: Map<number, string> = new Map<number, string>([
        [10, 'rgba(20, 180, 20, 0.5)'],
        [20, 'rgba(255, 210, 40, 0.5)'],
        [Number.MAX_SAFE_INTEGER, 'rgba(255, 40, 40, 0.5)']
    ]);


    private markerTypeId = MarkerTypeId;

    public iconInfo: IMarkerIconInfo = {
        id: 'mymarker',
        markerType: MarkerTypeId.FontMarker,
        fontName: 'FontAwesome',
        fontSize: 50,
        size: {
            height: 13,
            width: 13
        },
        color: 'red',
        markerOffsetRatio: { x: 0.5, y: 1 },
        text: '\uF267'
    };

    getCurrentStatus(): boolean {
        return this.collapseListView === true && this.collapseMapView === false;
    }

    ngOnInit() {
        this.filterApplied = false;
        this.isLoadingSubscription = this.shipSvc.isLoading$.subscribe(n => this.isLoading = n);
        this.isLoadRequiredSub = this.shipSvc.isLoadRequired$.subscribe(n => this.isLoadRequired = n);

        this.IsUnAuthenticated = this.shipSvc.getUnAuthenticationToken();
        this.initializeAPIBinding();

        if (!this.IsUnAuthenticated) {
            this.checkBusinessRule();
            this.customerSubscription = this.custSvc.selectedContext$.subscribe(a => {
                this.customerID = a && a.customer && a.customer.CustomerId ? a.customer.CustomerId.toString() : '0';
            });

            this.userSettingsSubscription = this.userSettingsSvc.shipmentFilterSettings$.subscribe(k => {
                if (k) {
                    this.CustomShipmentFilter = _.cloneDeep(getSelectedDefaultShipmentFilter(JSON.parse(k)));
                    if (this.isInitialDataLoaded) {
                        this.onFilterApply();
                    } else {
                        this.shpFltrSvc.changeShipmentFilter({ ...this.CustomShipmentFilter, IsUserDefaultFilter: true });
                    }
                } else {
                    const defaultFilter = getMapViewDefaultShipmentFilter(this.shipSvc.isDefaultInProgressOnly);
                    this.shpFltrSvc.changeShipmentFilter(defaultFilter);
                }
            });
        } else {
            this.unAuthScubscription = this.unAuthSvc.unAuthCustomer$.subscribe(a => {
                this.customerID = a && a.CustomerId ? a.CustomerId.toString() : '0';
            });
            this.unAuthCustomerLocSub = this.unAuthSvc.unAuthCustomerLocation$.subscribe(l => {
                if (l && this.custSvc.isValidCoordinates(l.latitude, l.longitude)) {
                    this.defaultBox = this.custSvc.getDefaultBox(l.latitude, l.longitude);
                };
            });
        }

        if (!this.IsHistorical) {
            this.isHistoricalFilterApplied$.next(true);
        } else {
            this.LoadHistoricalView();
        }

        // tslint:disable-next-line: deprecation
        this.shipmentList$ = combineLatest(
            this.shipmentAPIResultsList$,
            this.shpFltrSvc.shipmentFilter$,
            (apiList, fltr) => {
                if ((this.isLoadRequired || this.isLoading)) {
                    if (fltr.quickSearch || fltr.serialNumber || fltr.shipmentName || fltr.plateNumber || fltr.containerNumber || fltr.driverPhoneNumber) {
                        return [];
                    }
                    return this.filteredList;
                }
                let lst = apiList;
                this.quickSearchLookUp(lst);
                this.updateScreenHeights();
                this.AppliedShipmentFilter = _.cloneDeep(fltr);
                this.shipSvc.setShipmentFilter(this.AppliedShipmentFilter);
                this.shipmentFilter = _.cloneDeep(fltr);
                const quickSearchText = fltr.quickSearch;
                if (quickSearchText) {
                    this.previousAppliedShipmentSerial = null;
                    lst = this.filterBySerials(lst, quickSearchText);
                    setTimeout(() => {
                        this.hiddenButton.elementClick();
                        this.clusterButton.elementFocus();
                    });
                } else {
                    // For filter Summary close event
                    if (this.IsUnAuthenticated) {
                        lst = this.applyLocalFilter(lst, this.shipmentFilter);
                    }
                }
                if (this.checkFilterDefaultState(fltr)) {
                    this.AppliedShipmentFilter.isDefaultState = true;
                    this.shipmentFilter.isDefaultState = true;
                }
                if (!fltr.quickSearch) {
                    const formattedSearch = `[<${fltr.serialNumber}><${fltr.shipmentName}><${fltr.plateNumber}><${fltr.containerNumber}><${fltr.driverPhoneNumber}>]`;
                    lst = this.shipSvc.applyLocalFilter(lst, this.shipmentFilter, formattedSearch !== this.previousAppliedShipmentSerial);
                    this.previousAppliedShipmentSerial = formattedSearch;
                }
                if (!(this.isLoadRequired || this.isLoading)) {
                    this.showMarkers = true;
                    if (this.MarkerList.length > 0 && this.MarkersUpdated) {
                        this.refreshMarkerVisibility(lst);
                    }
                    else {
                        const tempSub = this.MarkerListChanged.subscribe(() => {
                            this.refreshMarkerVisibility(lst);
                            setTimeout(() => tempSub?.unsubscribe());
                        });
                    }
                }
                this.shipmentListCount =
                    this.itemsThreshold === lst.length && this.apiResultsCount > this.itemsThreshold ?
                        this.apiResultsCount : lst.length;

                if (this.shipmentListCount <= 0) {
                    this.box = this.defaultBox;
                    if (this.defaultBox?.center?.latitude == 0 || this.defaultBox?.center?.latitude == 0) {
                        this.zoomIndex = 1;
                    } else {
                        this.zoomIndex = 16;
                    }
                    this.isZoomed = true;
                }
                else {
                    this.onResetZoom();
                }

                this.isInitialDataLoaded = true;
                this.filteredList = lst;
                return lst;
            }
        );
        this.setAutoRefresh();
        if (this.IsUnAuthenticated) {
            this.Trackers$ = this.trackSvc.reloadTrackerLookUp();
            this.loadDefaultUnAuthFilter();
        }

        this.shipmentAddressSub = this.shipSvc.OnShipmentAddressUpdated.subscribe((shipments) => {
            if (this.isMatchingInfoBox(shipments)) {
                this.updateInfoBoxAddress();
            }
        });

        const authStateSub = this.authSvc.authState$.pipe().subscribe(n => {
            const userCanCreateShipment = n?.canCreateShipment && (n?.canAccessOversight || n?.isEmersonAdmin);
            if (userCanCreateShipment && this.authSvc.getRequestedParams()) {
                this.showPopups.showCreateShipmentPopup();
            }
            authStateSub?.unsubscribe();
        });
        this.shipSvc.mapAck();
    }

    // Fetch the data from gettrackerstatelist and autocompletetrackerlist and update the receiverpo in trakers from shipments
    quickSearchLookUp(shipments) {
        this.combineSubscription = this.trackSvc.trackerList$
            .subscribe((trackers) => {
                if (trackers?.list?.length > 0) {
                    if (shipments?.length > 0) {
                        shipments.filter(x => x.ReceiverPONum != null).forEach(shipment => {
                            var tracker = trackers.list.find(x => x.shipmentName == shipment.tripName && x.trackerId == shipment.trackerId && !x.receiverPO);
                            if (tracker) {
                                tracker.receiverPO = shipment.ReceiverPONum;
                                if (tracker.customerTrackerId.indexOf(shipment.ReceiverPONum) == -1)
                                    tracker.customerTrackerId = tracker.customerTrackerId + (tracker.receiverPO ? ' - ' + tracker.receiverPO : '');
                            }
                        });
                    }
                    this.Trackers$ = of(trackers.list);
                }
                this.combineSubscription?.unsubscribe();
            });
    }

    initializeAPIBinding() {
        this.apiShipmentList$ = this.shipSvc.shipmentList$.pipe(
            withLatestFrom(this.shipSvc.isLoading$, this.shipSvc.isLoadRequired$, this.shipSvc.shipmentListComeFromApi$),
            tap(([n, loading, loadRequired]) => {
                // this.showMarkers = false;
                // this.box = this.defaultBox;
                if (loadRequired) {
                    this.clearMarkers(true);
                }
            }),
            filter(([n, loading, loadRequired]) => !(loading || loadRequired)),
            map(([n, loading, loadRequired, shipmentListComeFromApi]) => {
                this.shipmentList = [...n.list];
                this.shipmentListCount = n.itemCount;
                this.apiResultsCount = n.itemCount;
                this.itemsThreshold = this.shipSvc.ResultThreshold;
                if (this.selectedShipment) {
                    this.previousBounds = this.bounds;
                }
                return { list: n.list, comeFromApi: shipmentListComeFromApi };
            }),
            map(x => {
                return x.list;
            })
        );

        this.shipmentAPIResultsList$ = this.isHistoricalFilterApplied$.pipe(
            mergeMap(n => {
                if (n) {
                    return this.apiShipmentList$;
                } else {
                    this.shipmentList = [];
                    return of([] as Shipment[]);
                }
            })
        );
    }

    ngAfterViewInit() {
        if (this.IsHistorical) {
            this.ShowFilter();
            this.isApplyChange = true;
        }
        this.cdr.detectChanges();
    }

    refreshMarkerVisibility(lst: Shipment[]) {
        const visibleShipments = lst?.map(m => m.Id);
        const visibleIds = visibleShipments ? visibleShipments : [];
        this.showMarkers = true;
        // this.markerOptions?.forEach(mo => {
        //     mo.visible = visibleIds.some(id => mo.id === id);
        // });
        this.HideInfoBox.emit();
        this.ShowSelectedMarkers.emit(visibleIds);
    }

    loadDefaultUnAuthFilter() {
        this.shpFltrSvc.setUnAuthDefaultState();
    }

    hideClusters(mapType: string) {  //jelax OR-2497
        // Promise.resolve(null).then(()=>{
        //     if (mapType.toUpperCase() === 'BAIDU') {
        //         this.clustersDisplay = 'none';
        //     }
        // });
    }

    resetFilterMapViewDefaultState() {
        let filter: IShipmentFilterState
        if (this.CustomShipmentFilter) {
            filter = this.CustomShipmentFilter;
        } else {
            filter = getMapViewDefaultShipmentFilter(this.shipSvc.isDefaultInProgressOnly);
        }
        if (this.shipSvc.isHighResultCount) {
            this.shpFltrSvc.changeShipmentFilter(filter);
        } else {
            this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(filter);
        }

        this.isFilterDefaultState();
        this.isFilterDirty = false;
        this.ScrollToTableTop.emit();
    }

    filterBySerials(lst: Shipment[], quickSearch: string): Shipment[] {
        return lst.filter(k => k.serialNumber === quickSearch
            || k.tripName?.toLowerCase() === quickSearch?.toLowerCase()
            || k.PlateNum?.toLowerCase() === quickSearch?.toLowerCase()
            || k.ContainerNum?.toLowerCase() === quickSearch?.toLowerCase()
            || k.DriverPhoneNum?.toLowerCase() === quickSearch?.toLowerCase()
            || k.ReceiverPONum?.toLowerCase() === quickSearch?.toLowerCase());
    }

    public updateMapMarkers(list: Shipment[]) {
        if (!this.IsHistorical) {
            this.MarkersUpdated = false;
            this.setLastTwoMarkerEvent(null);
            this.readyMarkerOptions(list);
            const box = this.utilSvc.getNewBoxParams();
            if (list && list.length > 0) {
                list.forEach(shipment => {
                    if (this.custSvc.isValidCoordinates(shipment.latitude, shipment.longitude)) {
                        this.utilSvc.updateBoxParams(box, shipment.latitude, shipment.longitude);
                    }
                });
                // no box formed
                if (box.maxLatitude === null) {
                    this.box = this.defaultBox;
                } else {
                    this.box = box;
                }
                this.allTrackersBox = this.box;
                this.SetMapZoomLevel();
                // If shipment list length 1 then need to open Infobox directly
                if (list?.length == 0 || (list && list.length > 1)) {
                    this.HideInfoBox.emit();
                }
            } else {
                this.box = this.defaultBox;
                this.allTrackersBox = this.defaultBox;
                this.SetMapZoomLevel();
            }
            this.isMapLoaded = true;
            this.previousBox = this.box;

            if (list && list.length === 1) {
                setTimeout(() => {
                    this.onRowClick(list[0]);
                    this.onResetZoom();
                }, 200)
            }
        }
        if (this.selectedShipment) {
            setTimeout(() => {
                this.onRowClick(this.selectedShipment);
            }, 100)
        }
    }

    ngOnDestroy() {
        // this.shipmentListSubscription.unsubscribe();
        this.isLoadingSubscription.unsubscribe();
        this.isLoadRequiredSub.unsubscribe();

        this.clearMarkers();
        if (this.IsUnAuthenticated) {
            this.shpFltrSvc.updateFilterToInitialState();
        }
        this.renderer.removeClass(document.body, 'bodyCss');
        if (this.shipmentFilterSubscription) {
            this.shipmentFilterSubscription.unsubscribe();
        }
        if (this.TrackerSubscription) {
            this.TrackerSubscription.unsubscribe();
        }
        this.shipSvc.onDestroy();
        this.shipSvc.clearAutoRefresh();
        if (this.trackerListSub) {
            this.trackerListSub.unsubscribe();
        }
        if (this.shipmentAddressSub) {
            this.shipmentAddressSub.unsubscribe();
        }
        if (this.customerSubscription) {
            this.customerSubscription.unsubscribe();
        }
        if (this.unAuthScubscription) {
            this.unAuthScubscription.unsubscribe();
        }
        if (this.userSettingsSubscription) {
            this.userSettingsSubscription.unsubscribe();
        }
        if (this.getShipmentSubscription) {
            this.getShipmentSubscription.unsubscribe();
        }
        if (this.loadAddressSubscription) {
            this.loadAddressSubscription.unsubscribe();
        }
        if (this.saveUserSubscription) {
            this.saveUserSubscription.unsubscribe();
        }

        if (this.combineSubscription) {
            this.combineSubscription?.unsubscribe();
        }
        this.shpFltrSvc.clearShipmentFilterWithoutShipmentListUpdate();

        this.unAuthCustomerLocSub?.unsubscribe();
    }

    public updateScreenHeights() {
        this.tableHeight = null;
        this.tablefilterHeight = null;
        // Full List View....
        if (!this.collapseListView && this.collapseMapView) {
            setTimeout(() => {
                const filterBoxHeight = this.filterSummaryBox.nativeElement.offsetHeight;
                const quickSearchBoxHeight = this.quickSearchBox.nativeElement.offsetHeight;
                const resizeIconBoxHeight = this.resizeIconBox ? this.resizeIconBox.nativeElement.offsetHeight : 0;
                this.tableHeight = `calc(100vh - ${filterBoxHeight + quickSearchBoxHeight + resizeIconBoxHeight + 53}px - var(--banner-height))`;
                this.tablefilterHeight = `calc(100vh - ${filterBoxHeight + quickSearchBoxHeight + resizeIconBoxHeight + 203}px - var(--banner-height))`;
            }, 100);
        } else if (!this.collapseMapView && this.collapseListView) {
            // Full Map View
            setTimeout(() => {
                const docStyle = getComputedStyle(document.documentElement);
                //get variable
                const totalHeaderHeight: number = parseInt(docStyle.getPropertyValue('--total-header-height'));
                const height = window.innerHeight -
                    (document.getElementById('filter-summary').getBoundingClientRect().height +
                        document.getElementById('quickSearch').getBoundingClientRect().height + totalHeaderHeight + 5);
                document.getElementById('mapView').style.height = height + 'px';
            }, 100);
        } else {
            // Hybrid view
            setTimeout(() => {
                const height = (window.innerHeight / 2) -
                    (document.getElementById('filter-summary').getBoundingClientRect().height + 10);
                document.getElementById('mapView').style.height = height + 'px';
                const filterBoxHeight = this.filterSummaryBox.nativeElement.offsetHeight;
                const quickSearchBoxHeight = this.quickSearchBox.nativeElement.offsetHeight;
                const resizeIconBoxHeight = this.resizeIconBox ? this.resizeIconBox.nativeElement.offsetHeight : 0;
                this.tableHeight = `calc(100vh - ${filterBoxHeight + quickSearchBoxHeight + resizeIconBoxHeight + height + 53}px - var(--banner-height))`;
                this.tablefilterHeight = `calc(100vh - ${filterBoxHeight + quickSearchBoxHeight + resizeIconBoxHeight + height + 203}px - var(--banner-height))`;
            }, 100);
        }
        this.loadShowRouteFullScreen = true;
        this.showRouteHeight = 'calc(100vh - 45px - var(--banner-height))';
    }

    public onClearPeriod(): boolean {
        return false;
    }

    onSaveCustomFilter() {
        if (this.showSaveAsDefault()) {
            this.savingDefaultFilter.next(true);
            const request: SetUserSettingRequest = {
                Section: USER_SETTING_SECTION_SETTINGS,
                Key: USER_SETTING_KEY_CUSTOM_FILTERS, SettingType: UserSettingsType.String,
                Value: setDefaultShipmentFilter(this.shipmentFilter)
            };
            this.userSettingsSvc.saveUserSettings(request)
                .pipe(take(1)).subscribe();
            setTimeout(() => { this.savingDefaultFilter.next(false); }, 1000);
        }
    }

    private clearMarkers(skipListUpdate: boolean = false) {
        // this.shipmentList$..splice(0);
        if (!skipListUpdate) {
            this.shipmentList$ = new Observable<Shipment[]>();
        }
        this.showMarkers = false;
        this.setLastTwoMarkerEvent(null);
        this.markerOptions = [];
    }

    private isMatchingInfoBox(shipments: Array<Shipment>): boolean {
        if (!this.selectedShipment) { return false; }

        if (this.selectedShipment.tripId) {
            return shipments.some(s => s.trackerId === this.selectedShipment.trackerId && s.tripId === this.selectedShipment.tripId);
        } else {
            return shipments.some(s => s.trackerId === this.selectedShipment.trackerId);
        }

        return false;
    }

    public updateInfoBoxAddress() {
        if (!this.selectedShipment && !this.LastTwoMarkerEvent[0]) { return; }
        this.selectedShipment.AddressGeoLoading = false;
        this.openInfoBox(this.selectedShipment ? this.getMarkerEventArgsbyShipment(this.selectedShipment) : this.LastTwoMarkerEvent[0], false);
    }

    onViewLocationClick(s) {
        this.hideHamburgerMenu();
        switch (s.linkClass) {
            case 'click-infobox-zoom-in':
                this.openInfoBox(this.getMarkerEventArgsbyShipment(this.selectedShipment), false);
                this.isZoomedInfoLevel = false;
                this.zoomInCrumbLevel();
                this.isZoomed = true;
                this.isInfoZoomInAction = true;
                break;
            case 'click-infobox-zoom-out':
                this.openInfoBox(this.getMarkerEventArgsbyShipment(this.selectedShipment), false);
                this.isZoomedInfoLevel = true;
                this.setMapDefaultLevel();
                break;
            case 'click-show-route':
                this.onShipmentRouteClick(this.selectedShipment);
                break;
            case 'click-show-details':
                this.onShipmentDetailsClick(this.selectedShipment);
                break;
            case 'click-show-share':
                this.onShareLocation(this.selectedShipment);
                break;
            case 'click-infobox-close':
                this.setLastTwoMarkerEvent(null);
                // this.openningInfoBoxIsList = false;
                this.shipSvc.isinfoBoxVisible(false);
                this.shipSvc.checkAutoRefresh();
                break;
            case 'click-show-a-marker':
                const marker = this.MarkerList.find(p => p.UUID === s.Data);
                this.selectSpecificShipment(marker);
                marker.Metadata.set('goBack', true);
                this.openInfoBox(this.getMarkerEventArgsbyMarker(marker));
                break;
            case 'click-infobox-back-to-list':
                this.openInfoBox(this.LastTwoMarkerEvent[1]);
                break;
            case 'click-show-route-list':
                const showRouteMarker = this.MarkerList.find(p => p.UUID === s.Data);
                this.selectSpecificShipment(showRouteMarker);
                this.onShipmentRouteClick(this.selectedShipment);
                break;
            case 'click-show-details-list':
                const showDetailsMarker = this.MarkerList.find(p => p.UUID === s.Data);
                this.selectSpecificShipment(showDetailsMarker);
                this.onShipmentDetailsClick(this.selectedShipment);
                break;
            default: {
                this.selectedShipment.AddressGeoLoading = true;
                this.openInfoBox(this.selectedShipment ? this.getMarkerEventArgsbyShipment(this.selectedShipment) : this.LastTwoMarkerEvent[0], false);
                this.loadAddressSubscription = this.shipSvc.loadObservableShipmentAddress(this.selectedShipment.trackerId).subscribe(k => {
                    this.updateInfoBoxAddress();
                });
            }
        }
    }

    onShareLocation(shipment: Shipment) {
        this.errorMessage = null;
        this.emailAddresses = null
        this.bsModalRef = this.modalSvc.show(this.shareModal,
            {
                class: 'modal-lg modal-dialog-centered',
                ignoreBackdropClick: true
            })
    }

    onShareTracker(shareLocationForm: NgForm) {
        if (shareLocationForm.valid) {
            const request = new ShareTrackerRequest();
            request.TrackerId = this.selectedShipment.trackerId;
            request.EmailAddresses = this.emailAddresses.trim();
            request.URL = environment.oversightV2SiteUrl;
            request.ShipmentName = this.selectedShipment.tripName;
            this.shpSvc.ShareTracker(request).pipe(take(1)).subscribe(
                n => {
                    if (n && n.ErrorCode === 0) {
                        this.bsModalRef.hide();
                        this.emailAddresses = null;
                    } else {
                        this.errorMessage = n.LocalizedErrorMessage;
                    }
                })
        }
    }

    updateAllAddresses() {
        if (this.filteredList) {
            this.shipSvc.updateAddresses(this.filteredList).pipe(take(1)).subscribe(n => {
                if (n?.list?.length && n.list.some(t => t.needsMarkerUpdate)) {
                    const tempSub = this.MarkerListChanged.subscribe(() => {
                        this.refreshMarkerVisibility(this.filteredList);
                        setTimeout(() => tempSub?.unsubscribe());
                    });
                    this.updateMapMarkers(this.shipmentList);
                }
            });
        }
    }

    zoomInCrumbLevel() {
        if (this.selectedShipment) {
            this.previousBox = this.bounds;
            const minLatBoxPadding = 0.001;
            const minLongBoxPadding = 0.001;
            this.box = {
                maxLatitude: this.selectedShipment.latitude + minLatBoxPadding,
                maxLongitude: this.selectedShipment.longitude + minLongBoxPadding,
                minLatitude: this.selectedShipment.latitude - minLatBoxPadding,
                minLongitude: this.selectedShipment.longitude - minLongBoxPadding
            };
        }
    }

    setMapDefaultLevel() {
        if (this.selectedShipment) {
            const minLatBoxPadding = ((this.previousBox.maxLatitude - this.previousBox.minLatitude) / (this.previousZoomIndex > 3 ? 4 : 3));
            const minLongBoxPadding =
                ((this.previousBox.maxLongitude - this.previousBox.minLongitude) / (this.previousZoomIndex > 3 ? 4 : 3));
            this.box = {
                maxLatitude: this.selectedShipment.latitude + minLatBoxPadding,
                maxLongitude: this.selectedShipment.longitude + minLongBoxPadding,
                minLatitude: this.selectedShipment.latitude - minLatBoxPadding,
                minLongitude: this.selectedShipment.longitude - minLongBoxPadding
            };
        }
    }

    private setLastTwoMarkerEvent(markerEvent: MarkerEventArgs) {
        if (markerEvent === null) {
            this.LastTwoMarkerEvent.splice(0);
        }
        this.LastTwoMarkerEvent.splice(1);
        this.LastTwoMarkerEvent.unshift(markerEvent);
    }

    onRowClick(shipment: Shipment) {
        this.openMarkerRevisit = [];
        this.selectSpecificShipment(shipment);
        if (!(shipment.latitude && shipment.longitude)) {
            return;
        }
        const markerArgs = this.getMarkerEventArgsbyShipment(shipment);
        if (markerArgs) {
            markerArgs.Marker?.Metadata?.set('goBack', false);
            this.box = {
                maxLatitude: this.selectedShipment.latitude + 0.001,
                maxLongitude: this.selectedShipment.longitude + 0.001,
                minLatitude: this.selectedShipment.latitude - 0.001,
                minLongitude: this.selectedShipment.longitude - 0.001
            };
            this.openInfoBox(markerArgs);
            if (this.previousBounds) {
                setTimeout(() => {
                    this.box = this.previousBounds;
                    this.bounds = this.previousBounds;
                    this.previousBounds = null;
                })
            }
        } else if (!markerArgs && !this.MarkersUpdated) {
            this.openMarkerRevisit.push(shipment);
        }
    }

    SetMapZoomLevel() {
        const minBoxPadding = 0.01;
        if (
            ((this.box.maxLatitude - this.box.minLatitude) / 2) < minBoxPadding &&
            ((this.box.maxLongitude - this.box.minLongitude) / 2) < minBoxPadding
        ) {
            const centerLat = this.box.minLatitude + (this.box.maxLatitude - this.box.minLatitude);
            const centerLon = this.box.minLongitude + (this.box.maxLongitude - this.box.minLongitude);
            this.box = {
                maxLatitude: centerLat + minBoxPadding,
                maxLongitude: centerLon + minBoxPadding,
                minLatitude: centerLat - minBoxPadding,
                minLongitude: centerLon - minBoxPadding
            };
        }
        this.mapCenterLat = null;
        this.mapCenterLong = null;
        this.isZoomed = false;
    }

    onShipmentRouteClick(shipment?: Shipment) {
        this.showRouteShipment = shipment;
        this.showRoute = true;
    }

    onShipmentDetailsClick(shipment: Shipment, isFromUnAuthUrl?: boolean) {
        const ShipmentDetails: IShipmentDetailsInfo = {
            tripId: shipment.tripId,
            trackerId: shipment.trackerId,
            customerTrackerId: shipment.customerTrackerId,
            isMultiTrip: shipment.isMultiTrip,
            IsDVShipment: shipment.IsDVShipment,
            tripStateCode: shipment.tripStateCode,
            ModelName: shipment.ModelName,
            ModelNumber: shipment.ModelNumber,
            Is4G: shipment.Is4G,
            ActualStartTime: shipment.ActualStartTime,
            ActualEndTime: shipment.ActualEndTime,
            ScheduledStartTime: shipment.ScheduledStartTime,
            ScheduledEndTime: shipment.ScheduledEndTime,
            LastReportedTimestamp: shipment.LastReportedTimestamp

        };
        this.showPopups.showShipmentDetailPopup(ShipmentDetails, isFromUnAuthUrl);
    }

    setAutoRefresh() {
        this.shipSvc.startAutoRefresh(this.autoRefresh);
    }

    onSwitchMapViewZoomLevel() {
        this.collapseMapView = false;
        this.collapseListView = true;
        this.updateScreenHeights();
    }

    toggleClustering() {
        this.clustering = !this.clustering;
    }

    onResetZoom() {
        if (this.isZoomed) {
            this.isZoomed = false;
            this.box = this.allTrackersBox ? { ...this.allTrackersBox } : this.box;
            this.setLastTwoMarkerEvent(null);
            this.HideInfoBox.emit();
            this.SetMapZoomLevel();
        }
    }

    onMarkersCreated(markers: Array<Marker>) {
        this.MarkerList = markers;
        this.MarkerListChanged.next();
        this.MarkersUpdated = true;
        if (this.openMarkerRevisit?.length) {
            const shipment = this.openMarkerRevisit.pop();
            this.onRowClick(shipment);
        }
    }

    onMarkerClick(e: MarkerEventArgs) {
        this.hideHamburgerMenu();
        if (e.Markers.length == 1 && e.Marker && e.Marker.Metadata) {
            this.selectSpecificShipment(e.Marker);
        }
        this.setBox(this.getShipmentByMarker(e.Marker));
        this.setLastTwoMarkerEvent(e);
    }

    onBoundsUpdate(bounds: IBox) {
        this.hideHamburgerMenu();
        this.mapCenterLat = this.mapCenterLat ? this.mapCenterLat : bounds.center.latitude;
        this.mapCenterLong = this.mapCenterLong ? this.mapCenterLong : bounds.center.longitude;
        if (this.mapCenterLat !== bounds.center.latitude || this.mapCenterLong !== bounds.center.longitude) {
            this.isZoomed = true;
        } else {
            this.isZoomed = false;
        }
        this.shipSvc.mapAck();
        this.bounds = bounds;
    }

    onMapUpdate() {
        this.shipSvc.mapAck();
        this.hideHamburgerMenu();
    }

    onMapZoomUpdate(zoom: number) {
        this.onMapUpdate();
        if (this.isMapLoaded) {
            this.previousZoomIndex = zoom;
            this.previousBox = this.bounds;
            this.isMapLoaded = false;
        }
        this.zoomIndex = zoom;
        if (!this.isInfoZoomInAction && !this.isZoomedInfoLevel && zoom <= this.previousZoomIndex) {
            this.isZoomedInfoLevel = true;
        }
        this.isInfoZoomInAction = false;
        if (this.LastTwoMarkerEvent[0]) {
            this.openInfoBox(this.LastTwoMarkerEvent[0], false);
        }
    }

    toggleListView() {
        this.closeShowRoute();
        this.collapseMapView = !this.collapseMapView;
        if (this.collapseMapView === true) {
            this.collapseListView = false;
        }
        this.updateScreenHeights();
    }

    zoomIconClass(toggleParam: boolean): string {
        return !toggleParam ?
            'expand-icon' :
            'compress-icon';
    }

    isSelected(toggleParam: boolean): string {
        return !toggleParam ?
            '' :
            'selected';
    }

    onFilterReset() {
        this.closeShowRoute();
        this.ScrollToTableTop.emit();
        this.previousAppliedShipmentSerial = null;
        if (!this.IsUnAuthenticated &&
            (this.IsHistorical || this.shipSvc.isHighResultCount)) {
            if (this.shipmentFilter.serialNumber ||
                this.shipmentFilter.shipmentName ||
                this.shipmentFilter.plateNumber ||
                this.shipmentFilter.containerNumber ||
                this.shipmentFilter.driverPhoneNumber ||
                this.shipmentFilter.quickSearch) {
                this.shipmentFilter = {
                    ...this.shipmentFilter, shipmentName: null, serialNumber: null, plateNumber: null, containerNumber: null, driverPhoneNumber: null, supplierCustomer: null,
                    dcTripStopLocation: null, mostRecentPostDC: null, originCity: null, originState: null, originCountry: null, originZip: null, destinationCity: null, destinationCountry: null, destinationState: null, destinationZip: null
                };
                if (this.IsHistorical) {
                    this.resetHistoricalView();
                } else {
                    this.resetQuickSearch();
                }
            } else {
                if (!this.IsHistorical) {
                    this.shpFltrSvc.clearShipmentFilter();
                } else {
                    this.resetHistoricalView();
                }
            }
        } else if (!this.IsUnAuthenticated) {
            this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(getDefaultShipmentFilter());
            this.columnDefs?.map(k => {
                k.filterAppliedModel = k.filterModel = null;
                k.list?.map(l => l.isChecked = false);
            });
        } else {
            this.shpFltrSvc.clearShipmentFilterWithoutShipmentListUpdate();
        }
        // this.closeFilter();
        this.isFilterDefaultState();
        this.isFilterDirty = false;
    }

    onFilterApply() {
        this.closeShowRoute();
        this.ScrollToTableTop.emit();
        this.shipmentFilter.quickSearch = this.searchInput = null;
        if (!this.IsUnAuthenticated &&
            !(this.shipmentFilter.serialNumber || this.shipmentFilter.shipmentName ||
                this.shipmentFilter.plateNumber || this.shipmentFilter.containerNumber || this.shipmentFilter.driverPhoneNumber)) {
            this.clearMarkers(true);
            this.shpFltrSvc.changeShipmentFilter(this.shipmentFilter);
        } else {
            this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        }
        // this.closeFilter();
        this.isFilterDefaultState();
        this.isHistoricalFilterApplied(true);
        this.isApplyChange = false;
        this.isFilterDirty = false;
    }

    private isHistoricalFilterApplied(filterApplied: boolean) {
        if (this.IsHistorical && this.filterApplied !== filterApplied) {
            this.filterApplied = filterApplied;
            this.isHistoricalFilterApplied$.next(filterApplied);
        }
    }

    isFilterDefaultState() {
        const isEqual = this.checkFilterDefaultState(this.shipmentFilter);
        this.shpFltrSvc.setFilterStateAsDefault(isEqual);
    }

    private checkFilterDefaultState(fltr: IShipmentFilterState) {
        let defaultFilter = getDefaultShipmentFilter();
        if (this.IsUnAuthenticated) {
            defaultFilter = getDefaultUnAuthShipmentFilter();
        } else if (this.IsHistorical) {
            defaultFilter = this.getDefaultHistoricalFilter();
        }
        const omittedProperties = ['IsHistorical', 'isDefaultState', 'IsUserDefaultFilter'];

        const isEqual = _.isEqual(
            _.omit(fltr, omittedProperties),
            _.omit(defaultFilter, omittedProperties)
        );
        return isEqual;
    }

    showResetAll(): boolean {
        this.isItDefaultState = !this.shipmentFilter || !this.shipmentFilter.isDefaultState;

        return this.shipmentFilter && !this.shipmentFilter.isDefaultState;
    }

    showSaveAsDefault(fltr = this.shipmentFilter): boolean {
        const omittedProperties = ['IsHistorical', 'isDefaultState', 'IsUserDefaultFilter'];
        const isEqual = _.isEqual(
            _.omit(fltr, omittedProperties),
            _.omit(this.CustomShipmentFilter, omittedProperties)
        );

        return !this.IsHistorical && !isEqual;
    }

    showResetToDefault(): boolean {
        if (!this.IsUnAuthenticated && !this.IsHistorical) {
            if (this.CustomShipmentFilter) {
                return this.showSaveAsDefault(this.AppliedShipmentFilter);
            } else {
                const defaultFilter = getMapViewDefaultShipmentFilter(this.shipSvc.isDefaultInProgressOnly);
                defaultFilter.isDefaultState = false;
                const omittedProperties = ['IsHistorical', 'isDefaultState', 'IsUserDefaultFilter'];
                return !_.isEqual(_.omit(this.shipmentFilter, omittedProperties), _.omit(defaultFilter, omittedProperties));
            }
        } else { return false; }
    }

    closeFilter() {
        this.isFilterPinned = false;
        setTimeout(() => {
            this.displayFilter = false;
        });
    }
    onPinClick() {
        this.isFilterPinned = !this.isFilterPinned;
        if (!this.isFilterPinned) {
            this.closeFilter();
        }
    }

    //#region Quick search related Functionality Start
    onSerialNameSubmit() {
        this.searchInput = this.searchInput ? this.searchInput.trim() : this.searchInput;
        if (this.PreviousSearchedText !== this.searchInput) {
            this.PreviousSearchedText = this.searchInput;
            this.checkForTrackerInLocal(this.searchInput);
        }
        this.showQuickReset = true;
        this.isApplyChange = this.searchInput?.length > 0;
    }

    onSearchInputSelect(data: any) {
        if (this.PreviousSearchedText !== data.item.customerTrackerId) {
            this.PreviousSearchedText = data.item.customerTrackerId;
            this.searchInput = data.item.customerTrackerId;
            this.checkForTrackerInLocal(data.item.trackerId, true);
        }
        this.showQuickReset = true;
        this.isApplyChange = true;
    }

    checkForTrackerInLocal(serialNumber: string, isGlobalDeviceId: boolean = false) {
        if (!serialNumber) {
            return;
        }
        this.closeShowRoute();
        this.ScrollToTableTop.emit();
        const filteredShipment = this.shipmentList.find(k => k.serialNumber === serialNumber ||
            (!isGlobalDeviceId && k.tripName?.toLowerCase() === serialNumber.toLowerCase())
            || (k.PlateNum === serialNumber) || (k.ContainerNum === serialNumber) || (k.DriverPhoneNum === serialNumber));
        this.shipmentFilter.quickSearch = serialNumber;
        this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        if (!filteredShipment || filteredShipment.isMultiTrip) {
            if (this.IsHistorical) {
                this.isFilterDefaultState();
                this.isHistoricalFilterApplied(true);
                this.isApplyChange = false;
            }
            this.getShipmentSubscription = this.shipSvc.GetShipmentBySerial(serialNumber, !isGlobalDeviceId).pipe(take(1)).subscribe();
        }
    }

    resetQuickSearch() {
        this.closeShowRoute();
        this.ScrollToTableTop.emit();
        this.searchInput = this.PreviousSearchedText = null;
        this.shipmentFilter.quickSearch = '';
        this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        this.showQuickReset = false;
        this.isFilterDirty = false;
        this.isApplyChange = false;

        if (this.box === this.defaultBox) {
            this.isZoomed = true;
            this.zoomIndex = 8;
            this.previousZoomIndex = this.zoomIndex;
            this.onResetZoom();
        }
    }

    customBlur(data: any) {
        setTimeout(() => {
            if (this.PreviousSearchedText !== this.searchInput) {
                this.onSerialNameSubmit();
            }
            this.PreviousSearchedText = this.searchInput;
        }, 200);
    }

    // resetQuickSearchButton(): boolean {
    //     return this.searchInput && this.searchInput.length > 0 && this.showQuickReset && !this.isItDefaultState;
    // }

    onSearchInputChange() {
        this.showQuickReset = false;
        this.isApplyChange = false;
        if (!this.searchInput || this.searchInput.length === 0) {
            return this.Trackers$;
        }
        let serialOptions = [];
        this.serialOptionList$ = Observable.create(sub => {
            sub.next(this.searchInput);
        }).pipe(
            mergeMap((token: string) => {
                return this.Trackers$.pipe(
                    map(trackers => {
                        try {
                            const pattern = new RegExp(`${this.searchInput.trim()}`, 'i');
                            if (this.searchInput.trim().length <= 4) {
                                serialOptions = _.union(serialOptions, trackers.filter(n => pattern.test(n.lastFourChars)));
                            }
                            serialOptions = _.union(serialOptions, trackers.filter(n => n.shipmentName !== null &&
                                (n.trackerId + (n.shipmentName ? ' - ' + n.shipmentName : '')).toLowerCase()
                                    .indexOf(this.searchInput.toLowerCase().trim()) >= 0));
                            serialOptions = _.union(serialOptions, trackers.filter(n => pattern.test(n.trackerId)));
                            serialOptions = _.union(serialOptions, trackers.filter(n => n.shipmentName !== null &&
                                n.shipmentName.toLowerCase().indexOf(this.searchInput.toLowerCase().trim()) >= 0));
                            serialOptions = _.union(serialOptions, trackers.filter(n => n.receiverPO !== null &&
                                n.receiverPO.toLowerCase().indexOf(this.searchInput.toLowerCase().trim()) >= 0));
                            return serialOptions;
                        } catch (e) {
                            return null;
                        }
                    })
                );
            })
        );
    }
    //#endregion Quick search related Functionality End

    UpdateFilterDirty(isDirty: boolean) {
        this.isFilterDirty = isDirty;
    }

    ShowFilter() {
        if (!this.isFilterPinned) {
            this.isFilterPinned = true;
            this.displayFilter = !this.displayFilter;
            this.isApplyChange = !_.isEqual(this.shipmentFilter, this.AppliedShipmentFilter) || !!this.shipmentFilter?.quickSearch;
            this.updateScreenHeights();
        }
    }

    applyLocalFilter(fullList: Shipment[], filterObj: IShipmentFilterState) {
        return fullList.filter(k => {
            return (this.shipSvc.CheckDateBetweenFilters(k, filterObj.period)) &&
                (
                    !filterObj.status ||
                    filterObj.status.length === 0 ||
                    filterObj.status.some(a => a === k.tripStateCode)
                );
            // &&request.PeriodReportedInterval === 17 ||
        });
    }

    hideHamburgerMenu() {
        this.authSvc.updateMenuStatus(true);
    }

    //set this.selectedShipment only here
    selectSpecificShipment(shipmentOrMarker: Shipment | Marker) {
        this.selectedShipment = shipmentOrMarker instanceof Shipment ? shipmentOrMarker : this.getShipmentByMarker(shipmentOrMarker);
    }

    //Set the visual frame of the map according to shipment
    setBox(shipment: Shipment) {
        this.shipSvc.isinfoBoxVisible(true);
        this.isZoomed = true;
    }

    //Prepare MarkerOptions according to shipmentList
    readyMarkerOptions(shipmentList: Shipment[]) {
        const markerOptionList: Array<IMarkerOptions> = new Array<IMarkerOptions>();
        if (shipmentList && shipmentList.length > 0) {
            shipmentList.forEach(shipment => {
                if (!(shipment.latitude && shipment.longitude)) { return; }
                const metaData = new Map<string, any>();
                const anchor = { x: shipment.ImageAnchorLeft, y: shipment.ImageAnchorTop };
                const icon = this.imgMapSvc.getImageURL(shipment.ImageId, shipment.ImageUrlSVG, shipment.ImageUrl);
                metaData.set('shipment', shipment);
                metaData.set('pixelOffset', { x: 0, y: shipment.ImageAnchorTop });
                metaData.set('defaultOffSet', { x: 0, y: shipment.ImageAnchorTop });
                const markerOptions = {
                    id: shipment.Id,
                    position: {
                        latitude: shipment.latitude,
                        longitude: shipment.longitude
                    },
                    icon,
                    title: shipment.tripName ? shipment.tripName : shipment.serialNumber,
                    anchor,
                    metadata: metaData
                };
                if (shipment.ImageAnchorLeft === undefined ||
                    shipment.ImageAnchorLeft === null ||
                    shipment.ImageAnchorTop === undefined ||
                    shipment.ImageAnchorTop === null) {
                    delete markerOptions.anchor;
                }
                markerOptionList.push(markerOptions);
            });
            if (markerOptionList.length > 0) {
                markerOptionList[0].isFirst = true;
                markerOptionList[markerOptionList.length - 1].isLast = true;
            }
        }

        this.markerOptions = markerOptionList.splice(0);
    }
    //Build MarkerEventArgs according to Shipment
    getMarkerEventArgsbyShipment(shipment: Shipment, needSameLocationMarkers?: boolean): MarkerEventArgs {
        const marker = this.getMarkerByShipment(shipment);
        return marker ? this.getMarkerEventArgsbyMarker(marker, needSameLocationMarkers) : null;
    }

    //Build MarkerEventArgs according to Marker
    getMarkerEventArgsbyMarker(marker: Marker, needSameLocationMarkers?: boolean): MarkerEventArgs {
        const markers = needSameLocationMarkers ? this.MarkerList.filter(mk => mk.Location.latitude === marker.Location.latitude && mk.Location.longitude === marker.Location.longitude) : [marker];
        return {
            Marker: marker,
            Location: marker.Location,
            Click: null,
            Pixels: null,
            Markers: markers,
        };
    }

    //Find corresponding Marker according to Shipment
    getMarkerByShipment(shipment: Shipment): Marker {
        const result = this.MarkerList.find(marker => {
            const mkshipment = this.getShipmentByMarker(marker);
            return mkshipment.tripId === shipment.tripId && mkshipment.customerTrackerId === shipment.customerTrackerId;
        });
        return result;
    }
    //Find corresponding Shipment according to Marker
    getShipmentByMarker(marker: Marker): Shipment {
        const mkshipment = marker.Metadata.get('shipment');
        return mkshipment;
    }

    //Open InfoBox and record event traces as required
    openInfoBox(e: MarkerEventArgs, isRecord: boolean = true) {
        if (e) {
            this.setBox(this.getShipmentByMarker(e.Marker));
            setTimeout(() => {
                this.ShowInfoBox.emit(e);
                if (isRecord) {
                    this.setLastTwoMarkerEvent(e);  //record event traces
                }
            });
        }
    }

    //Get the data for the list template
    getListTemplateInfo(markerMetadatas: Array<IMarkerMetadata>): any {
        this.openningInfoBoxIsList = true;
        return markerMetadatas.map(m => {
            return {
                markerUUID: m.markerUUID,
                shipment: m.metadata.get('shipment')
            };
        });
    }
    //Get the data for the detail template
    getDetailTemplateInfo(markerMetadatas: Array<IMarkerMetadata>, flag: string): any {
        this.openningInfoBoxIsList = false;
        const sourceMarker = markerMetadatas.find(p => p.isSourceMarker);
        return sourceMarker.metadata.get(flag);
    }


    openColumnSelectionModal() {
        this.modalRef = this.modalService.show(
            this.columnSelectionModal,
            {
                class: `${this.IsHistorical ? 'modal-xl' : 'modal-lg'} modal-dialog-centered`,
                ignoreBackdropClick: true,
                keyboard: false
            },
        );
    }

    onColumnChangeAccept(selectedColumns) {
        this.columnDefs.map(a => {
            if (a.title) {
                a.isVisible = false;
            }
        });
        selectedColumns?.map(k => this.columnDefs.find(a => a.title === k).isVisible = true);
        const colData = JSON.stringify(this.columnDefs.map(k => {
            return { value: k.value, columnOrder: k.columnOrder, isVisible: k.isVisible };
        }));
        localStorage.setItem(environment.columnDefs, colData);
    }

    columnDefsChange(data) {
        this.columnDefs = data.columns;
        if (this.columnDefs && this.columnDefs.length > 0) {
            if (data.isColumnChange) {
                const colData = JSON.stringify(data.columns.map(k => {
                    return { value: k.value, columnOrder: k.columnOrder, isVisible: k.isVisible };
                }));
                localStorage.setItem(environment.columnDefs, colData);
            }
        }
        if (data.sort) {
            localStorage.setItem(environment.sortOrder, JSON.stringify(data.sort));
        }
    }

    onTableFilterApply(column: CustomTableColumnDefs) {
        const currentFilter = { ...this.shipmentFilter };
        switch (column.value) {
            case 'tripStatus':
                currentFilter.status = column.filterAppliedModel;
                break;
            case 'sensorRange':
                currentFilter.sensorRange = column.filterAppliedModel;
                break;
            case 'carrierName':
                currentFilter.carrier = column.filterAppliedModel;
                break;
            case 'origin':
                currentFilter.origin = column.filterAppliedModel;
                break;
            case 'destination':
                currentFilter.destination = column.filterAppliedModel;
                break;
            case 'TripStartLocationCity':
                currentFilter.originCity = column.filterAppliedModel;
                break;
            case 'TripStartLocationState':
                currentFilter.originState = column.filterAppliedModel;
                break;
            case 'TripStartLocationCountry':
                currentFilter.originCountry = column.filterAppliedModel;
                break;
            case 'TripStartLocationZipCode':
                currentFilter.originZip = column.filterAppliedModel;
                break;
            case 'TripEndLocationCity':
                currentFilter.destinationCity = column.filterAppliedModel;
                break;
            case 'TripEndLocationState':
                currentFilter.destinationState = column.filterAppliedModel;
                break;
            case 'TripEndLocationCountry':
                currentFilter.destinationCountry = column.filterAppliedModel;
                break;
            case 'TripEndLocationZipCode':
                currentFilter.destinationZip = column.filterAppliedModel;
                break;
            case 'SupplierName':
                currentFilter.supplierCustomer = column.filterAppliedModel;
                break;
            case 'DCTripStop':
                currentFilter.dcTripStopLocation = column.filterAppliedModel;
                break;
            case 'MostRecentPostDC':
                currentFilter.mostRecentPostDC = column.filterAppliedModel;
                break;
        }
        if (column.value === 'DCTripStop' || column.value === 'SupplierName') {
            this.shpFltrSvc.changeShipmentFilter(currentFilter);
        } else {
            this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(currentFilter);
        }
        this.ScrollToTableTop.emit();
    }

    onSummaryRemove(data) {
        const filterColumn = this.columnDefs.find(k => k.title === data?.title);
        if (filterColumn && filterColumn.filterAppliedModel?.length > 0) {
            filterColumn.filterAppliedModel = data?.value;
        }
        if (this.IsHistorical && this.checkFilterDefaultState(this.shipmentFilter)) {
            this.isHistoricalFilterApplied(false);
        }
    }

    onListExcelExportClick() {
        const Toast = this.toastr.info(downloadProgress, '', {
            disableTimeOut: true, tapToDismiss: false, enableHtml: true,
            progressBar: true, positionClass: 'toast-bottom-right', toastClass: 'navigation-popup-dynamic'
        });
        this.IsDownloadInProgress = true;
        const acceptType = 'application/vnd.ms-excel';
        const extraHeaders = new ExportTrackerStateReportHeaders();
        extraHeaders.Accept = acceptType;
        extraHeaders.responseType = responseType.blob;
        const sortedList = this.shipmentListComponent.sortedList();
        const trackerContentList = this.shipSvc.getTrackerStatusListOfSelectedColumns(sortedList, this.columnDefs.filter(x => x.isVisible && x.title?.trim() != ""));
        const properties = this.columnDefs.filter(x => x.isVisible && x.title?.trim() != "").map(x => {
            return { title: x.title, value: x.value }
        });

        const shipmentfilterSubscription = this.shipSvc.getShipmentRequest().subscribe(k => {
            let content = { getTrackerStatusRequest: k, Properites: properties };
            this.shipSvc.exportExcelListViewReport(JSON.stringify(content), extraHeaders).subscribe(response => {
                const blob = new Blob([response], {
                    type: acceptType
                });

                const fileName = 'trackers-' + this.customerID + '-' + formatDate(new Date(), 'ddMMyyyhhmm', 'en_US') + '.xlsx';
                FileSaver.saveAs(blob, fileName);
                this.toastr.remove(Toast.toastId);
                this.toastr.success(downloadCompleted, '', {
                    positionClass: 'toast-bottom-right', timeOut: 2000
                });
                this.IsDownloadInProgress = false;
            }, (e: IErrorInfo) => {
                this.openSubmitResponse(e.message);
                this.toastr.remove(Toast.toastId);
                this.toastr.success(e.message, '', {
                    positionClass: 'toast-bottom-right', timeOut: 2000
                });
                this.IsDownloadInProgress = false;
            });
            shipmentfilterSubscription?.unsubscribe();
        });
    }

    openSubmitResponse(errorMessage: string) {
        this.errorMessage = errorMessage;
        this.errorModalRef = this.modalService.show(
            this.errorModalTemplate,
            {
                class: 'modal-sm modal-dialog-centered',
                ignoreBackdropClick: true
            }
        );
    }

    onOkClick() {
        this.errorMessage = null;
        this.errorModalRef.hide();
    }

    private resetHistoricalView() {
        this.LoadHistoricalView();
        this.isHistoricalFilterApplied(false);
        this.isApplyChange = true;
        setTimeout(() => {
            this.AppliedShipmentFilter.isDefaultState = true;
            this.shipmentFilter.isDefaultState = true;
        });
    }

    private LoadHistoricalView() {
        this.collapseMapView = true;
        const defaultFilter = this.getDefaultHistoricalFilter();
        defaultFilter.period = getDefaultShipmentFilter().period;
        this.shpFltrSvc.changeShipmentFilterWithoutShipmentListUpdate(defaultFilter);
        this.shpFltrSvc.setFilterStateAsDefault(true);
    }

    private getDefaultHistoricalFilter() {
        const defaultFilter = getDefaultShipmentFilter();
        defaultFilter.period = null;
        defaultFilter.IsHistorical = true;
        defaultFilter.isDefaultState = true;
        return defaultFilter;
    }

    private closeShowRoute() {
        if (this.showRoute) {
            this.showRoute = false;
        }
    }

    checkBusinessRule() {
        this.businessRulesListSubscription = this.businessruleSvc.businessRulesList$.subscribe(k => {
            if (k.list && k.list.length > 0) {
                this.autoEnableRefresh = k.list.find(br => br.BusinessRuleTypeCode ===
                    BusinessRuleType.AutoEnableRefreshForListView)?.Enabled;
                if (this.autoEnableRefresh && !this.autoRefresh) {
                    this.autoRefresh = true;
                    this.setAutoRefresh();
                }
                else {
                    this.autoRefresh = false;
                    this.setAutoRefresh();
                }
            }
        });
    }
}
