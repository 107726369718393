import { AlertStatus } from 'emr-ng-shared';
import { TrackerHistory } from 'emr-ng-shared';

import { ServerRequestCompleteAction, ServerRequestAction } from 'app-modules/core/store/actions/custom-actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';

import * as actionIndex from 'app-modules/core/store/actions';


export class LoadHistoricalShipmentList implements ServerRequestAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT;
}

export class LoadHistoricalShipmentListSuccess implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_SUCCESS;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT;
    constructor(public historicalShipmentList: IListInfo<TrackerHistory>) { }
}

export class LoadHistoricalShipmentListError implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT_ERROR;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT;

    constructor(public message: string) { }
}

export class CancelLoadHistoricalShipmentList implements ServerRequestCompleteAction {
    readonly type = actionIndex.SHIPMENT_DETAIL_CANCEL_LOAD_HISTORICAL_SHIPMENT;
    readonly callingAction = actionIndex.SHIPMENT_DETAIL_LOAD_HISTORICAL_SHIPMENT;
}

