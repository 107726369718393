import { Injectable } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { filter, tap, switchMap, finalize, share, startWith, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { muteFirst } from 'emr-ng-shared';

import { GetVehicleGroupRequest } from 'emr-ng-shared';
import { GetVehicleGroupResponse } from 'emr-ng-shared';
import { VehicleGroupInfo } from 'emr-ng-shared';

import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { VehicleGroupsStateService } from './vehicle-groups-state.service';
import { CreateShipmentStateService } from 'app-modules/core/store/create-shipment/create-shipment-state.service';

import * as VehicleGroupsActions from './vehicle-groups.actions';
import * as actionIndex from 'app-modules/core/store/actions/index';
import { CustomerStateService } from 'app-modules/core/store/services/customer-state.service';

@Injectable()
export class VehicleGroupsService {
    constructor(
        private custStateSvc: CustomerStateService,
        private createStateSvc: CreateShipmentStateService,
        private store: Store<IAppState>,
        private oversightSvc: OversightApiService,
        private vehicleGroupsStatesvc: VehicleGroupsStateService) { }


        
    public isLoading$ = this.vehicleGroupsStatesvc.isLoading$;

    public isLoadRequired$ = this.vehicleGroupsStatesvc.isLoadRequired$.pipe(
        filter(isloadRequired => isloadRequired),
        tap(() => this.store.dispatch({ type: actionIndex.LOAD_VEHICLE_GROUPS })),
        switchMap(() => this.getVehicleGroupList()),
        tap(responseData => this.store.dispatch({ type: actionIndex.LOAD_VEHICLE_GROUPS_SUCCESS, payload: responseData })),
        finalize(() => this.store.dispatch({ type: actionIndex.CANCEL_LOAD_VEHICLE_GROUPS })),
        share()
    );
    // public vehicleGroupList$: Observable<VehicleGroupInfo[]> = this.getVehicleGroupList();

    public vehicleGroupList$ = muteFirst(
        this.getVehicles().pipe(startWith(null)),
        this.vehicleGroupsStatesvc.vehicleGroupList$
    );

    public getVehicleGroupList(): Observable<VehicleGroupInfo[]> {
        return this.createStateSvc.selectedLinkedCustomer$.pipe(
            switchMap(n => {
                if (n && n.CustomerId) {
                    const request = new GetVehicleGroupRequest();
                    request.RetailerId = Boolean(n) ? n.CustomerId : null;
                    return this.oversightSvc.GetVehicleGroup(request).pipe(
                        map((response: GetVehicleGroupResponse) => response.VehicleGroupList)
                    );
                } else {
                    return this.vehicleGroupList$;
                }
            }));
    }

    private getVehicles(): Observable<VehicleGroupInfo[]> {
        return this.vehicleGroupsStatesvc.isLoadRequired$.pipe(
            filter(isloadRequired => isloadRequired),
            switchMap(() => {
                return this.oversightSvc.GetVehicleGroup(new GetVehicleGroupRequest()).pipe(
                    map((response: GetVehicleGroupResponse) => response.VehicleGroupList));
            }),
            tap(
                responseData => { this.vehicleGroupsStatesvc.loadVehicleGroupsSuccess(responseData); }),
            finalize(() => this.vehicleGroupsStatesvc.cancelLoadVehicleGroups()),
            share()
        );
    }

}


