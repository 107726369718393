import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HighchartsChartModule } from 'highcharts-angular';
import { SensorChartComponent } from 'app-modules/shipment-chart/components/sensor-chart/sensor-chart.component';
import { MapChartComponent } from 'app-modules/shipment-chart/components/map-chart/map-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';

@NgModule({
  imports: [
    CommonModule,
    HighchartsChartModule
  ],
  declarations: [
    SensorChartComponent,
    MapChartComponent,
    PieChartComponent
  ],
  exports: [
    SensorChartComponent,
    MapChartComponent,
    PieChartComponent
  ]
})
export class ShipmentChartModule { }
