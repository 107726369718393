import { ITimePeriodState } from 'app-modules/core/store/models/time-period-state.interface';

import { initialState } from '../reducers/initial-state';

import * as actionIndex from 'app-modules/core/store/actions/index';
import * as authActions from 'app-modules/core/store/auth/auth.actions';
import * as periodActions from 'app-modules/core/store/time-period/time-period.actions';

type Actions =
    authActions.SignOut |
    periodActions.LoadTimePeriods |
    periodActions.LoadTimePeriodsSuccess |
    periodActions.LoadTimePeriodsError;

export function TimePeriodReducer(state: ITimePeriodState = initialState.timePeriod, action: Actions): ITimePeriodState {
    switch (action.type) {
        // case actionIndex.SIGN_OUT:
        //     return initialState.timePeriod;

        case actionIndex.LOAD_TIME_PERIODS:
            return {
                ...state,
                isLoading: true
            };

        case actionIndex.LOAD_TIME_PERIODS_SUCCESS:
            return {
                ...state,
                timePeriodList: action.timePeriodList,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: ''
            };

        case actionIndex.LOAD_TIME_PERIODS_ERROR:
            return {
                ...state,
                isLoadRequired: false,
                isLoading: false,
                errorMessage: action.message
            };

        default:
            return {
                ...state
            };
    }
}
