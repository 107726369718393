import { LabelValueModel } from 'emr-ng-shared';
import { TrackerStatechartMarker } from './tracker-state-chart-marker.model';

export class TrackerStatechartChartData {
    x: number;
    y: number;
    Location: string;
    formattedString: string;
    SatTimeString: string;
    LocationLocale: string;
    Latitude: number;
    Longitude: number;
    labels: LabelValueModel[];
    displayValue: string;
    breadcrumbId: number;
    marker: TrackerStatechartMarker;
}
