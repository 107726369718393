<div class="w-100  col-12 mt-2">
    <div class="row">
        <!-- #region ForShipment Route alert filtering -->
        <div class="col-md-auto mr-4">
            <span i18n="@@total_records">Total Records</span>
            <span class="checkbox-count">{{alertList?.length > 0 ? alertList?.length : 0}}</span>
        </div>
        <div class="col-md-8">
            <emr-checkboxlist identifier="showAlertOptions" name="showAlertOptions" [(ngModel)]="checkedAlerts"
                [options]="alertCounts" (onChange)="onAlertSearch(alertListCopy)" [inline]=true returnType="array"
                textCssClass="align-baseline" [optionImageUrl]="'CalcImageURL'" [isImage]=true
                [optionsValueField]="'Type'" [setDisabled]="false" [showCount]=true [optionCountField]="'Count'"
                [optionsTextField]="'Name'" repeatDivClass="col-md-6 d-inline-block pb-2">
            </emr-checkboxlist>
        </div>
        <!-- #endregion  -->
        <div class="col-md-12 px-0 mt-3">
            <div class="main-container mb-2">
                <div class="table-data-container table-height">
                    <div class="col-md-12 px-1">
                        <div class="table-responsive tablediv">
                            <table class="table table-striped fixed-header-table alerts-table">
                                <thead>
                                    <tr>
                                        <th scope="col" class="table-row-header">
                                            <span i18n="@@alert_number">Alert Number</span>
                                        </th>
                                        <th scope="col" class="table-row-header">
                                            <span i18n="@@severity">Severity</span>
                                        </th>
                                        <th scope="col" class="table-row-header" width="90">
                                            <span i18n="@@alert_type">Alert Type</span>
                                        </th>
                                        <th scope="col" class="table-row-header">
                                            <span i18n="@@alert_duration">Alert Duration</span>
                                        </th>
                                        <th scope="col" class="table-row-header">
                                            <span i18n="@@escalation">Escalation</span>
                                        </th>
                                        <th scope="col" class="table-row-header" width="200">
                                            <span i18n="@@details">Details</span>
                                        </th>
                                        <th scope="col" class="table-row-header">
                                            <span i18n="@@opened">Opened</span>
                                        </th>
                                        <th scope="col" class="table-row-header">
                                            <span i18n="@@condition_duration">Condition Duration</span>
                                        </th>
                                        <th scope="col" class="table-row-header">
                                            <span i18n="@@status">Status</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="alertList && alertList.length > 0; else emptyList">
                                    <tr *ngFor="let alert of alertList; let i = index;">
                                        <td>
                                            <p class="table-row-data">
                                                <span class="color-header-1">{{ alert.Id }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data">
                                                <img
                                                    src="{{ getIcon(alert.ImageId, alert.ImageUrlSVG, alert.ImageUrl) }}">
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data serial-number-color">
                                                <span>{{ alert.TypeString }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data">
                                                <span>{{ alert.TimeSinceAlert }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data">
                                                <span>{{ alert.EscalationLevelString }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data notes-color">
                                                <span *ngIf="alert?.Sensor?.Type==23;else otherAlert">
                                                    {{alert?.Sensor?.MostRecentRangeValueString ?
                                                    alert?.Sensor?.MostRecentRangeValueString : '' }}</span>
                                                <ng-template #otherAlert>{{ alert.Detail ? alert.Detail :
                                                    ''}}</ng-template>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data notes-color">
                                                <span>{{ alert.StartTimeString }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data notes-color"
                                                [ngClass]="{'red':alert.AlertEndTime === null}">
                                                <span>{{ alert.ConditionDurationString }}</span>
                                            </p>
                                        </td>
                                        <td>
                                            <p class="table-row-data pl-0">
                                                <span
                                                    *ngIf="alert.Status === 100 && (isAuthenticated$ | async) && ((!canEditTripName)||isLocusAdmin); else showStatusString"
                                                    (click)="onCloseAlertClick(alert)" title="Close Alert"
                                                    i18n-title="@@close_alert"
                                                    class="status-open btn button-text btn-outline-default mt-0 close-button px-1"
                                                    i18n="@@close">Close</span>
                                                <ng-template #showStatusString>
                                                    <span
                                                        class="{{ alert.Status === 200 ? 'status-closed' : 'status-open' }}"
                                                        [ngClass]="{ 'ml-0': !((isAuthenticated$ | async) && ((!canEditTripName)||isLocusAdmin)) }">
                                                        {{ alert.StatusString }}
                                                    </span>
                                                </ng-template>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                                <ng-template #emptyList>
                                    <tbody>
                                        <tr>
                                            <td colspan="9">
                                                <p class="table-row-data text-center" i18n="@@no_data_available">No data
                                                    available</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </ng-template>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #alertResponseTemplate>
    <emr-modal headerTitle="Alert Summary" i18n-headerTitle="@@alerts_summary" [showCloseButton]=false>
        <div modal-body>
            <div class="col-md-12 m-1">
                <div *ngIf="hasError; else noError">
                    <p>{{ errorMessage }}</p>
                </div>
                <ng-template #noError>
                    <p i18n="@@route_created_successfully">Alert Closed</p>
                </ng-template>
            </div>
            <div class="col-12 text-center">
                <emr-button identifier="routeResponseOk" buttonType="button" buttonText="Ok" i18n-buttonText="@@link_ok"
                    (click)='modalRef.hide()' cssClass="btn button-text btn button-text btn-outline-default mt-0 mb-2">
                </emr-button>
            </div>
        </div>
    </emr-modal>
</ng-template>