import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { reportTypeDesc } from 'app-modules/core/consts/report-type';
import { ScheduledReportParams } from 'app-modules/core/models/scheduled-report-params.model';
import { PreferenceService } from 'app-modules/core/services/preference.service';
import { AuthService } from 'app-modules/core/store/auth/auth.service';
import { FleetTrakerType, ScheduleReportService, TripActionStatus } from 'app-modules/core/store/schedule-report/schedule-report.service';
import { TimePeriodService } from 'app-modules/core/store/time-period/time-period.service';
import { FleetReportParams } from 'app-modules/reports/models/fleet-report.model';
import {
  TrackerType, DateTimeObject, EmrUtilService, ReportType, ScheduleReportRequest, TimePeriod, TimePeriodInfo, TKeyValuePair,
  UserSettingsType,
  TripState
} from 'emr-ng-shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fleet-report',
  templateUrl: './fleet-report.component.html',
  styleUrls: ['./fleet-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FleetReportComponent implements OnInit {
  @ViewChild(NgForm, { static: true }) frmFleetReport: NgForm;

  @Input() selectedReportType: ReportType;
  public reportType = ReportType;
  reportParams = new FleetReportParams();
  dateTimeObject = new DateTimeObject();
  dateTimePreferenceSubscription: Subscription;
  public isDateRangeValid = true;

  public isFormSubmitted = false;

  private _validate = false;
  prevTripStates: any;
  @Input() public set validate(val: any) {
    this.reValidationForm();
    this.isFormSubmitted = !val?.validate;
    this._validate = val;
  };
  public get validate(): any {
    return this._validate;
  }

  @Input()
  set bindEditParams(val: TKeyValuePair[]) {
    this.bindFormReportParams(val);
  }

  tripStateList: any[];
  trackerTypeList: any[];
  minDate: Date = new Date(2008, 0, 1);
  maxDate: Date = new Date(9999, 11, 31);
  public periodList: TimePeriodInfo[];
  private periodListSubscription: Subscription;

  @Input() public isNewScheduleReport = false;

  @Output() reportRequestParamsChange = new EventEmitter();
  reportRequest: ScheduleReportRequest;
  @Input() set reportRequestParams(val) {
    this.reportRequest = val;
  }

  constructor(
    private prefSvc: PreferenceService,
    public periodSvc: TimePeriodService,
    private utilSvc: EmrUtilService,
    public datepipe: DatePipe,
    public reportSvc: ScheduleReportService,
    private authSvc: AuthService,) {
    this.tripStateList = this.reportSvc.tripStateList;
    this.trackerTypeList = this.reportSvc.fleetTrackerTypeList;
  }

  ngAfterViewInit() {
    this.initParams();
  }

  ngOnInit(): void {
    this.dateTimePreferenceSubscription = this.prefSvc.getDateTimeObject().subscribe(k => {
      this.dateTimeObject = k;
    });
    this.periodListSubscription = this.periodSvc.reportTimePeriodList$.subscribe(n => {
      this.onPeriodListSubscription(n.list);
      this.initParams();
    });
  }


  ngOnDestroy() {
    if (this.dateTimePreferenceSubscription) {
      this.dateTimePreferenceSubscription.unsubscribe();
    }
    if (this.periodListSubscription) {
      this.periodListSubscription.unsubscribe();
    }
  }

  private setInitValues() {
    switch (this.selectedReportType) {
      case ReportType.FleetCurrentStatus:
        this.reportParams.TraxxGoOnly = true;
        this.isFormSubmitted = false;
        setTimeout(() => {
          this.reportParams.TripState = this.reportSvc.tripStateList;
        }, 500)
        break;
      // case ReportType.FleetCurrentStatusAggregate:
      //   this.reportParams.Is3PL = FleetTrakerType.Is3PL;
      //   break;
      case ReportType.InProgressShipment:
        break;
    }
    this.reportParams.TimePeriod = TimePeriod.Yesterday;
    this.ParamsChange();
  }

  initParams() {
    if (this.isNewScheduleReport) {
      this.setInitValues();
      this.ParamsChange(true);
    }
  }

  ParamsChange(resetSubject = false) {
    const request = this.getScheduledReportParams(resetSubject);
    this.reValidationForm();
    this.reportRequestParamsChange.emit(request);
  }

  onPeriodChange() {
    this.isFormSubmitted = false;
    if (this.reportParams.TimePeriod !== TimePeriod.SpecificRange) {
      this.reportParams.FromDate = null;
      this.reportParams.ThruDate = null;
      this.frmFleetReport.controls['txtStartDate']?.clearValidators();
      this.frmFleetReport.controls['txtEndDate']?.clearValidators();
      this.isDateRangeValid = true;
    } else {
      this.isDateRangeValid = false;
    }
    this.reValidationForm();
    this.ParamsChange(true);
  }

  public onDateChanged() {
    if (this.reportParams.FromDate && isNaN(this.reportParams.FromDate.getTime())) {
      this.reportParams.FromDate = new Date(this.minDate);
    }

    if (this.reportParams.ThruDate && isNaN(this.reportParams.ThruDate.getTime())) {
      this.reportParams.ThruDate = new Date(this.maxDate);
    }

    this.isDateRangeValid = !((this.reportParams.ThruDate != null && this.reportParams.FromDate != null)
      && ((this.reportParams.FromDate >= this.reportParams.ThruDate)));

    this.ParamsChange(true);
  }

  private reValidationForm = () => {
    for (const key in this.frmFleetReport?.controls) {
      this.frmFleetReport.controls[key].markAsDirty();
      this.frmFleetReport.controls[key].updateValueAndValidity();
    }
  }

  onPeriodListSubscription(periodList: TimePeriodInfo[]) {
    this.periodList = periodList;
  }

  onOutOfTempRangeChange() {
    this.ParamsChange();
  }

  onShowNextStopChange() {
    this.ParamsChange();
  }

  onShowInactiveChange() {
    this.ParamsChange();
  }

  onShowTagsChange() {
    this.ParamsChange();
  }

  onTraxxGoOnlyChange() {
    this.ParamsChange();
  }

  onTripStateChange() {
    this.ParamsChange();
  }

  onFleetTrakerTypeChange() {
    this.ParamsChange();
  }

  public getScheduledReportParams(resetSubject = false): ScheduledReportParams {
    this.reValidationForm();
    let requestParams = new ScheduledReportParams();
    requestParams.ReportParams = [];
    requestParams.IsValid = this.frmFleetReport.valid && this.isDateRangeValid;
    if (resetSubject) {
      requestParams.EmailSubject = reportTypeDesc[this.selectedReportType];
    }

    if (this.reportParams.TimePeriod === TimePeriod.SpecificRange) {
      if (this.reportParams.FromDate && this.reportParams.ThruDate) {
        let dateFormat = this.dateTimeObject ? (this.dateTimeObject?.dateFormatString + ', ' + (this.dateTimeObject.showMeridian ? 'h:mm a' : 'HH:mm')) : 'MM/dd/yyyy, h:mm a';
        if (resetSubject) {
          requestParams.EmailSubject = requestParams.EmailSubject + ': ' +
            this.utilSvc.DateFormatLocaleChange(this.reportParams.FromDate, dateFormat) + ' - ' +
            this.utilSvc.DateFormatLocaleChange(this.reportParams.ThruDate, dateFormat);
        }
        requestParams.ReportParams.push({ Key: "ReportFromDate", Value: this.utilSvc.DateFormatLocaleChange(this.reportParams.FromDate), Type: UserSettingsType.DateTime });
        requestParams.ReportParams.push({ Key: "ReportThruDate", Value: this.utilSvc.DateFormatLocaleChange(this.reportParams.ThruDate), Type: UserSettingsType.DateTime });
      }
    }
    else {
      let timePeriodDisplayName = this.periodList?.find(p => p.Period === this.reportParams.TimePeriod)?.DisplayName;
      if (resetSubject) {
        requestParams.EmailSubject = requestParams.EmailSubject + (timePeriodDisplayName ? ' - ' + timePeriodDisplayName : '');
      }
      requestParams.ReportParams.push({ Key: "NamedDateRange", Value: this.reportParams?.TimePeriod?.toString() });
    }

    switch (this.selectedReportType) {
      case ReportType.FleetCurrentStatus:
        let tripStateCode = new Set<any>();
        if (this.reportParams.TripState?.length > 0) {
          this.reportParams.TripState.forEach(e => tripStateCode.add(e.value));
        }

        requestParams.ReportParams.push({ Key: "fileName", Value: "CurrentStatusReport" }),
          requestParams.ReportParams.push({ Key: "IsEmailedReport", Value: true }),
          // requestParams.ReportParams.push({ Key: "tripStatus", Value: this.reportParams.TripStatus }),
          requestParams.ReportParams.push({ Key: "tripStateCodes", Value: tripStateCode?.size > 0 ? Array.from(tripStateCode) : null });
        requestParams.ReportParams.push({ Key: "isShowNextStop", Value: this.reportParams.ShowNextStopInfo ? this.reportParams.ShowNextStopInfo : false });
        // requestParams.ReportParams.push({ Key: "includeClosedTrips", Value: this.reportParams.TripStatus === TripActionStatus.Closed ? true : null });
        break;
      // case ReportType.FleetCurrentStatusAggregate:
      //   requestParams.ReportParams.push({
      //     Key: "is3pL", Value: this.reportParams.Is3PL === FleetTrakerType.Is3PL ? true :
      //       (this.reportParams.Is3PL === FleetTrakerType.Non3PL) ? false : null
      //   });
      //   requestParams.ReportParams.push({ Key: "currentShowInactive", Value: this.reportParams.ShowInactive ? true : false });
      //   requestParams.ReportParams.push({ Key: "currentTrackerType", Value: this.getTrackerType() });
      //   requestParams.ReportParams.push({ Key: "traxxGoOnly", Value: this.reportParams.TraxxGoOnly });
      //   requestParams.ReportParams.push({ Key: "showTags", Value: this.reportParams.ShowTags });
      //   requestParams.ReportParams.push({ Key: "IsEmailedReport", Value: true });
      //   break;
      case ReportType.InProgressShipment:
        if (this.reportParams.OutOfTempRange === true) {
          requestParams.ReportParams.push({ Key: "outOfTempRange", Value: this.reportParams.OutOfTempRange });
        }
        break;
    }

    return requestParams;
  }

  getTrackerType() {
    if (this.reportParams.TraxxGoOnly) {
      return this.reportParams.ShowTags ? [TrackerType.TraxxGo, TrackerType.SmartTag]
        : [TrackerType.TraxxGo];
    }
    else {
      return this.reportParams.ShowTags ?
        null : [TrackerType.Unknown,
        TrackerType.SmartTraxxSatellite,
        TrackerType.SmartTraxx,
        TrackerType.SolarTraxxSatellite,
        TrackerType.SolarTraxx,
        TrackerType.SmartTraxx3PL,
        TrackerType.SmartTraxxQuakePermanent,
        TrackerType.SmartTraxxQuakeSatWiFi,
        TrackerType.SmartTraxx3PLSat,
        TrackerType.OracleCmd,
        TrackerType.TraxxGo];
    }
  }

  bindFormReportParams(val: TKeyValuePair[]) {
    if (this.isNewScheduleReport || !val || val.length <= 0) { return; }

    const namedDateRange = val.find(kv => kv.Key === "NamedDateRange");
    if (namedDateRange) {
      if (isNaN(parseInt(namedDateRange.Value))) {
        this.reportParams.TimePeriod = parseInt(TimePeriod[namedDateRange.Value]);
      } else {
        this.reportParams.TimePeriod = parseInt(namedDateRange.Value);
      }
    } else {
      this.reportParams.TimePeriod = TimePeriod.SpecificRange;
      const fromParam = val.find(kv => kv.Key === "ReportFromDate");
      const thruParam = val.find(kv => kv.Key === "ReportThruDate");
      this.reportParams.FromDate = new Date(Date.parse(fromParam?.Value));
      this.reportParams.ThruDate = new Date(Date.parse(thruParam?.Value));
    }
    switch (this.selectedReportType) {
      case ReportType.FleetCurrentStatus:
        let setUnassigned = false;
        let tripStateCodes = val.find(kv => kv.Key === "tripStateCodes")?.Value;
        if (tripStateCodes?.length > 0) {
          this.reportParams.TripState = [];
          this.prevTripStates = [];
          tripStateCodes?.forEach(e => {
            setUnassigned = (e === TripState.Default);
            let level = this.reportSvc?.tripStateList?.find(l => l.value == e);
            if (level) {
              this.prevTripStates.push(level)
            }
          })
          setTimeout(() => {
            this.reportParams.TripState = this.prevTripStates;
          }, 500);
        }
        setTimeout(() => {
          this.reportParams.TripStatus = val.find(kv => kv.Key === "tripStatus")?.Value;
        }, 500);
        this.reportParams.ShowNextStopInfo = val.find(kv => kv.Key === "isShowNextStop")?.Value;
        this.reportParams.TripStatus = val.find(kv => kv.Key === "includeClosedTrips")?.Value ? TripActionStatus.Closed : null;
        if (setUnassigned) {
          setTimeout(() => {
            this.reportParams.TripStatus = TripActionStatus.Unassigned;
          }, 500);
        } else if (!this.reportParams.TripStatus && tripStateCodes?.length > 0) {
          setTimeout(() => {
            this.reportParams.TripStatus = TripActionStatus.TripStatus;
          }, 500);
        }
        break;
      // case ReportType.FleetCurrentStatusAggregate:
      //   let is3PLVal = val.find(kv => kv.Key === "is3pL")?.Value;
      //   setTimeout(() => {
      //     this.reportParams.Is3PL = is3PLVal === true ? FleetTrakerType.Is3PL : (is3PLVal === false ? FleetTrakerType.Non3PL : null);
      //   }, 500);
      //   this.reportParams.ShowInactive = val.find(kv => kv.Key === "currentShowInactive")?.Value;
      //   this.reportParams.TraxxGoOnly = val.find(kv => kv.Key === "traxxGoOnly")?.Value;
      //   this.reportParams.ShowTags = val.find(kv => kv.Key === "showTags")?.Value;
      //   break;
      case ReportType.InProgressShipment:
        this.reportParams.OutOfTempRange = val.find(kv => kv.Key === "outOfTempRange")?.Value;
        break;
    }

    setTimeout(() => {
      this.ParamsChange();
    }, 500);
  }
}
