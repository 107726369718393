import { Store, createFeatureSelector, createSelector } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { IAppState } from 'app-modules/core/store/models/app-state.interface';
import { IBusinessRulesState } from 'app-modules/core/store/models/business-rules-state.interface';
import * as businessActions from './business-rules.actions';
import { IListInfo } from 'app-modules/core/store/models/list-info-state.interface';
import { BusinessRuleInfo } from 'emr-ng-shared';
import { BusinessRuleType } from 'emr-ng-shared';
import { GetBusinessRulesRequest } from 'emr-ng-shared';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class BusinessRulesStateService {
    public businessRulesStateSelector = ((state: IAppState) => state.businessRules);

    public isLoadingSelector = createSelector(
        this.businessRulesStateSelector,
        (state: IBusinessRulesState) => state.isLoading
    );

    public isLoadRequiredSelector = createSelector(
        this.businessRulesStateSelector,
        (state: IBusinessRulesState) => state.isLoadRequired
    );

    public businessRulesSelector = createSelector(
        this.businessRulesStateSelector,
        (state: IBusinessRulesState) => state.BusinessRules
    );

    public isLoading$ = this.store.select(this.isLoadingSelector);  //jelax OR-2497
    public isLoadRequired$ = this.store.select(this.isLoadRequiredSelector);
    public BusinessRulesList$ = this.store.select(this.businessRulesSelector);

    public BusinessRuleFromStore(request: GetBusinessRulesRequest): Observable<BusinessRuleInfo> {
        return this.BusinessRulesList$.pipe(
            map((n: IListInfo<BusinessRuleInfo>) => {
                return n.list.find(k => k.BusinessRuleTypeCode === request.BusinessRuleTypeCode);
            })
        );
    }

    constructor(private store: Store<IAppState>) { }

    public loadBusinessRules() {
        this.store.dispatch(new businessActions.LoadBusinessRules());
    }

    public loadBusinessRulesSuccess(rule: IListInfo<BusinessRuleInfo>) {
        this.store.dispatch(new businessActions.LoadBusinessRulesSuccess(rule));
    }

    public loadBusinessRulesError(message: string) {
        this.store.dispatch(new businessActions.LoadBusinessRulesError(message));
    }

    public cancelBusinessRule() {
        this.store.dispatch(new businessActions.CancelLoadBusinessRules());
    }
}
