import { NgForm } from '@angular/forms';
import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'app-modules/core/store/auth/auth.service';

export class ChangePasswordValueEventArgs {
    password = '';
    newPassword = '';
}

@Component({
    selector: 'app-change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['./change-password-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit {
    @Input() error: string;
    @Output() submitted = new EventEmitter<ChangePasswordValueEventArgs>();
    @ViewChild(NgForm) changePasswordForm: NgForm;
    password = '';
    newPassword = '';
    confirmPassword = '';
    isPasswordChangeRequired$: Observable<boolean>;

    constructor(private authSvc: AuthService) { }

    ngOnInit() {
        this.isPasswordChangeRequired$ = this.authSvc.isPasswordChangeRequired$;
    }

    onForgotPasswordClick(event) {
        if (!this.changePasswordForm.invalid && this.newPassword === this.confirmPassword) {
            this.error = '';
            this.submitted.emit({
                password: this.password,
                newPassword: this.newPassword
            });
        }
    }
}

