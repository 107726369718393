import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteEditorComponent } from './components/route-editor/route-editor.component';
import { RouteDeviationComponent } from './components/route-deviation/route-deviation.component';
import { LocaleGuard } from 'app-modules/core/guards/locale-guard';
import { CustomerGuard } from 'app-modules/core/guards/customer-guard';
import { AuthGuard } from 'app-modules/core/guards/auth-guard';

const routes: Routes = [
  { path: '', component: RouteEditorComponent },
  { path: 'routedeviation', component: RouteDeviationComponent, canActivate: [LocaleGuard, AuthGuard, CustomerGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RouteEditorRoutingModule { }
