import { IMAGE_MAP_VALUE } from '../consts/image-mappings';
import { Injectable } from '@angular/core';
import { BaseURLPicker } from './baseurl-picker.service';

export const  errorMsg = (imageId, imageUrlSVG, imageUrl) =>
`Unable to find for the following config:
    ImageId: ${imageId},
    SVG URL: ${imageUrlSVG},
    PNG URL: ${imageUrl}`;

@Injectable({ providedIn: 'root' })
export class ImageMapService {

    constructor(private baseURLProvider: BaseURLPicker) { }

    getImageURL(imageId, imageUrlSVG, imageUrl) {
        const urlByImageId = imageId && IMAGE_MAP_VALUE[imageId];
        if (urlByImageId) {
            const finalUrl = `${this.baseURLProvider.resourceDomain}${urlByImageId}`;
            return finalUrl;
        } else if (imageId && (imageUrlSVG || imageUrl)) { // Avoid logging if imageId is null or both imageURLs are null
            // Adding this error would let us know if we have missed any image mappings
            console.error(errorMsg(imageId, imageUrlSVG, imageUrl));
        }
        return imageUrlSVG ? imageUrlSVG : imageUrl;
    }
}
