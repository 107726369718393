import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { CarrierInfo } from 'emr-ng-shared';
import { IShipmentFilterCarrier } from 'app-modules/core/store/models/shipment-filter-carrier.interface';
import { ShipmentFilterService } from 'app-modules/core/store/shipment-filter/shipment-filter.service';
import { CarrierService } from 'app-modules/core/services/carrier.service';
import { IShipmentFilterState } from 'app-modules/core/store/models/shipment-filter-state.interface';
import { carrier } from 'app-modules/core/consts/localization';

@Component({
    selector: 'app-shipment-filter-carrier-summary',
    templateUrl: 'shipment-filter-carrier-summary.component.html',
    styleUrls: ['shipment-filter-carrier-summary.component.css']
}) export class ShipmentFilterCarrierSummaryComponent implements OnInit, OnDestroy {
    carrierList: CarrierInfo[] = [];
    filter: string[];
    selectedList: CarrierInfo[] = [];

    carrierListSubscription: Subscription;
    carrierFilterSubscription: Subscription;
    @Input() shipmentFilter: IShipmentFilterState;
    @Input() isHugeCustomer: boolean;
    @Output() summaryRemove = new EventEmitter();

    constructor(
        private carrierSvc: CarrierService,
        private shipmentFilterService: ShipmentFilterService
    ) { }

    ngOnInit() {
        this.carrierListSubscription = this.carrierSvc.carrierList$.subscribe(n => this.onCarrierListChange(n.list));
        this.carrierFilterSubscription = this.shipmentFilterService.carrierFilter$.subscribe(n => this.onCarrierFilterChange(n));
    }

    ngOnDestroy() {
        this.carrierListSubscription.unsubscribe();
        this.carrierFilterSubscription.unsubscribe();
    }

    onCarrierListChange(carrierList: CarrierInfo[]) {
        this.carrierList = carrierList;
        this.filterCarrierList();
    }

    onCarrierFilterChange(filter: string[]) {
        this.filter = filter;
        this.filterCarrierList();
    }

    filterCarrierList() {
        this.selectedList = [];

        if (this.filter) {
            this.carrierList.forEach(item => {
                if (this.filter.indexOf(item.Description) >= 0) {
                    this.selectedList.push(item);
                }
            });
        }
    }

    clearSelectedFilter(statusCode: string) {
        this.shipmentFilter.carrier = this.shipmentFilter.carrier.filter(k => k !== statusCode);
        this.shipmentFilterService.changeShipmentFilterWithoutShipmentListUpdate(this.shipmentFilter);
        if (this.summaryRemove) {
            this.summaryRemove.emit({ title: carrier, value: this.shipmentFilter.carrier });
        }
    }
}


