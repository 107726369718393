import { ErrorResponse } from 'emr-ng-shared';

export interface ICustomInfoWithErrorResponse<T> {
    Info: T;
    ErrorCode?: number;
    ErrorDescription?: string;
    LocalizedErrorMessage?: string;
}

export function setCustomInfoWithErrorResponse(info, errorCode, errorDescription, localizedErrorMessage): ICustomInfoWithErrorResponse<any> {
    return {
        Info: info,
        ErrorCode: errorCode, ErrorDescription: errorDescription, LocalizedErrorMessage: localizedErrorMessage
    };
}
