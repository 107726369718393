import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { muteFirst } from 'emr-ng-shared';

import {
    IShipmentFilterState, getDefaultShipmentFilter, getDefaultUnAuthShipmentFilter
} from 'app-modules/core/store/models/shipment-filter-state.interface';
import { ITimePeriod } from 'app-modules/core/store/models/time-period.interface';
import { TimePeriodInfo } from 'emr-ng-shared';
import { IShipmentFilterSerialNumber } from 'app-modules/core/store/models/shipment-filter-serial-number.interface';
import { IShipmentFilterShipmentName } from 'app-modules/core/store/models/shipment-filter-shipment-name.interface';
import { Tracker } from 'app-modules/core/models/tracker.model';
import { IShipmentFilterStatus } from 'app-modules/core/store/models/shipment-filter-status.interface';
import { IShipmentFilterAlertType } from 'app-modules/core/store/models/shipment-filter-alert-type.interface';

import { ShipmentFilterStateService } from 'app-modules/core/store/shipment-filter/shipment-filter-state.service';
import { TrackerService } from 'app-modules/core/services/tracker.service';
import { ShipmentStatusService } from 'app-modules/core/services/shipment-status.service';
import { TimePeriodService } from 'app-modules/core/store/time-period/time-period.service';
import { AlertTypeService } from 'app-modules/core/services/alert-type.service';
import { IShipmentFilterLocation } from 'app-modules/core/store/models/shipment-filter-location.interface';
import { LocationService } from 'app-modules/core/services/location.service';
import { IShipmentFilterSensorRange } from 'app-modules/core/store/models/shipment-filter-sensor-range.interface';
import { SensorRangeService } from 'app-modules/core/store/sensor-range/sensor-range.service';
import { CarrierService } from 'app-modules/core/services/carrier.service';
import { IShipmentFilterCarrier } from 'app-modules/core/store/models/shipment-filter-carrier.interface';
import { initialState } from 'app-modules/core/store/reducers/initial-state';
import { OversightApiService } from 'app-modules/core/services/oversight-api.service';
import { LinkedCustomersService } from '../create-shipment/linked-customers/linked-customer.service';
@Injectable()
export class ShipmentFilterService {
    constructor(
        private shpFltrStateSvc: ShipmentFilterStateService,
        private trackSvc: TrackerService,
        private statusSvc: ShipmentStatusService,
        private periodSvc: TimePeriodService,
        private alertSvc: AlertTypeService,
        private locationSvc: LocationService,
        private rangeSvc: SensorRangeService,
        private carrierSvc: CarrierService,
        private ovsService: OversightApiService,
        private linkedCustSvc: LinkedCustomersService
    ) { }

    public shipmentFilter$ = this.shpFltrStateSvc.shipmentFilter$;
    public isSerialShipmentNameSearch$ = this.shpFltrStateSvc.isSerialShipmentNameSearch$;

    public serialNumberFilter$: Observable<string> = muteFirst(
        this.trackSvc.trackerList$,
        this.shpFltrStateSvc.serialNumberFilter$
    );

    public shipmentNameFilter$: Observable<string> = muteFirst(
        this.trackSvc.trackerList$,
        this.shpFltrStateSvc.shipmentNameFilter$
    );

    public selectedTracker$: Observable<Tracker> = muteFirst(
        this.trackSvc.trackerList$,
        this.shpFltrStateSvc.selectedTracker$
    );

    public statusFilter$: Observable<number[]> = muteFirst(
        this.statusSvc.shipmentStatusList$,
        this.shpFltrStateSvc.statusFilter$
    );

    public periodFilter$: Observable<ITimePeriod> = this.shpFltrStateSvc.periodFilter$;

    public shipmentFilterUpdate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public selectedPeriod$: Observable<TimePeriodInfo> = muteFirst(
        this.periodSvc.timePeriodList$,
        this.shpFltrStateSvc.selectedPeriod$
    );

    public alertFilter$: Observable<IShipmentFilterAlertType> = muteFirst(
        this.alertSvc.alertTypeList$,
        this.shpFltrStateSvc.alertTypeFilter$
    );

    public originFilter$: Observable<number[]> = muteFirst(
        this.locationSvc.originList$,
        this.shpFltrStateSvc.originFilter$
    );

    public distributionCenterFilter$: Observable<number[]> = muteFirst(
        this.locationSvc.locationList$,
        this.shpFltrStateSvc.distributionCenterFilter$
    );

    public destinationFilter$: Observable<number[]> = muteFirst(
        this.locationSvc.destinationList$,
        this.shpFltrStateSvc.destinationFilter$
    );

    public sensorRangeFilter$: Observable<number[]> = muteFirst(
        this.rangeSvc.sensorRangeList$,
        this.shpFltrStateSvc.sensorRangeFilter$
    );

    public carrierFilter$: Observable<string[]> = muteFirst(
        this.carrierSvc.carrierList$,
        this.shpFltrStateSvc.carrierFilter$
    );

    public supplierFilter$: Observable<number[]> = muteFirst(
        this.linkedCustSvc.linkedShipperList$,
        this.shpFltrStateSvc.supplierFilter$
    );

    public getUnAuthenticationToken(): boolean {
        return this.ovsService.getUnAuthenticationToken();
    }

    public changeShipmentFilter(filter: IShipmentFilterState) {
        filter = { ...filter, isDefaultState: false };
        this.shpFltrStateSvc.changeShipmentFilter(filter);
    }

    public changeShipmentFilterForQuickSearch(filter: IShipmentFilterState) {
        const search = filter.quickSearch;
        let IsHistorical = false;
        if (filter.IsHistorical) {
            IsHistorical = filter.IsHistorical;
        }
        filter = getDefaultShipmentFilter();
        filter.quickSearch = search;
        filter.IsHistorical = IsHistorical;
        this.shpFltrStateSvc.changeShipmentFilter(filter);
    }

    public changeShipmentFilterWithoutShipmentListUpdate(filter: IShipmentFilterState) {
        filter.isDefaultState = !!filter.quickSearch;
        this.shpFltrStateSvc.changeShipmentFilterWithOutShipmentListUpdate(filter);
    }

    public setFilterStateAsDefault(isDefault: boolean) {
        this.shpFltrStateSvc.setIsDefaultFilterState(isDefault);
    }

    public updateFilterToInitialState() {
        this.shpFltrStateSvc.changeShipmentFilterWithOutShipmentListUpdate(getDefaultShipmentFilter());
    }

    public setUnAuthDefaultState() {
        this.shpFltrStateSvc.changeShipmentFilterWithOutShipmentListUpdate(getDefaultUnAuthShipmentFilter());
    }


    public clearShipmentFilter() {
        this.shpFltrStateSvc.changeShipmentFilter(getDefaultShipmentFilter());
    }

    public clearShipmentFilterWithoutShipmentListUpdate() {
        this.shpFltrStateSvc.changeShipmentFilterWithOutShipmentListUpdate(getDefaultUnAuthShipmentFilter());
    }

    public GetUnAuthenticationToken() {
        return this.ovsService.getUnAuthenticationToken();
    }

}


